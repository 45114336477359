import * as Amplify from 'aws-amplify';
import * as siteSlice from 'store/sitesSlice';
import * as reduxStore from 'store/store';
import * as mutations from 'graphql/mutations';
import * as userUtils from 'common/userUtils';
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import * as API from 'types/API';
import * as Utils from 'common/utils/dateUtils';
import { sleep } from './utils/miscUtils';

function getSiteGroupName(siteID: string): string {
  const prefix = 'useraccessgroup_';
  //const siteNameNoSpace = siteName.replaceAll(' ', '');
  //return prefix + siteID + '_' + siteNameNoSpace;
  return prefix + siteID;
}

async function createGroup(
  name: string,
  description: string
): Promise<boolean> {
  let status = false;

  try {
    const groupName = name;
    const resp = await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.createGroup, {
        groupName: groupName,
        description: description,
      })
    );

    const data = _.get(resp, 'data', undefined) as
      | API.CreateGroupMutation
      | undefined;

    if (data && data.createGroup === groupName) {
      status = true;
    }
  } catch (e) {
    console.error('Failed to create group:' + name + ' error:', e);
  }

  return status;
}

async function deleteGroup(name: string): Promise<boolean> {
  let status = false;

  try {
    const groupName = name;
    const resp = await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.deleteGroup, {
        groupName: groupName,
      })
    );

    const data = _.get(resp, 'data', undefined) as
      | API.DeleteGroupMutation
      | undefined;

    if (data && data.deleteGroup === groupName) {
      status = true;
    }
  } catch (e) {
    console.error('Failed to create group:' + name + ' error:', e);
  }

  return status;
}

async function updateGroupDescription(
  name: string,
  description: string
): Promise<boolean> {
  let status = false;

  console.info(`Update group ${name} descriprion to ${description}`);

  try {
    const groupName = name;
    const resp = await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.updateGroup, {
        groupName: groupName,
        description: description,
      })
    );

    const data = _.get(resp, 'data', undefined) as
      | API.UpdateGroupMutation
      | undefined;

    if (data && data.updateGroup === groupName) {
      status = true;
    }
  } catch (e) {
    console.error('Failed to create group:' + name + ' error:', e);
  }

  return status;
}

/**
 * Checks to see if the accessgroup has been setup for the site
 * @param siteID in the format 'b09644cd-cf82-4e0f-957f-81dde7be3c93'
 */
export async function setupSiteAuthorisation(siteID: string): Promise<void> {
  //console.info('setupSiteAuthorisation:', siteID);
  const site = siteSlice.selectById(reduxStore.store.getState(), siteID);
  if (site) {
    let create = true;

    const groupName = getSiteGroupName(siteID);

    let groupsCanAccess: string[] = [];
    if (site.groupsCanAccess) {
      groupsCanAccess = [...site.groupsCanAccess];
    }

    if (groupsCanAccess.includes(groupName)) {
      create = false;
    }

    if (create) {
      try {
        console.info('Creating auth group for ' + site.name + ' id:', siteID);
        try {
          await createGroup(groupName, site.name);
        } catch (e) {
          console.info("Couldn't create site group:", e);
        }
        groupsCanAccess.push(groupName);
        await Amplify.API.graphql(
          Amplify.graphqlOperation(mutations.updateOmniviaSite, {
            input: { id: siteID, groupsCanAccess: groupsCanAccess },
          })
        );
      } catch (e) {
        console.error('Failed to setup site authorisation error:', e);
      }
    }
  }
}

async function assignIoTPolicy(username: string): Promise<boolean> {
  //
  let status = false;

  console.info('assignIoTPolicy for:', username);

  try {
    await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.authoriseUserIotPolicies, {
        username: username,
      })
    );

    status = true;
  } catch (e) {
    console.error('Failed to assign policy:' + username + ' error:', e);
  }

  return status;
}

/**
 * Remove the site auth group from cognito
 * @param siteID in the format 'b09644cd-cf82-4e0f-957f-81dde7be3c93'
 */
export async function removeSiteAuthorisationGroup(
  siteID: string
): Promise<void> {
  console.info('removeSiteAuthorisationGroup:', siteID);

  const groupName = getSiteGroupName(siteID);

  await deleteGroup(groupName);
}

/**
 * Update the site auth group descrption
 * @param siteID in the format 'b09644cd-cf82-4e0f-957f-81dde7be3c93'
 */
export async function updateSiteAuthorisationGroup(
  siteID: string
): Promise<void> {
  const site = siteSlice.selectById(reduxStore.store.getState(), siteID);
  if (!site) return;

  const groupName = getSiteGroupName(siteID);

  await updateGroupDescription(groupName, site.name);
}

export async function setupAllSiteAuthorisation(): Promise<void> {
  if (userUtils.getCurrentUserAccessLevel() === userUtils.UAG.ADMIN) {
    const site = siteSlice.getAll(reduxStore.store.getState());
    site.forEach(async (site): Promise<void> => {
      if (site && site.id) {
        await setupSiteAuthorisation(site.id);
      }
    });
  }
}

/**
 *
 * Create and assign a IoT policy to a user so they can access SCUs over AWS iOT
 *
 * Get all groups assigned to the user so we know all sites the user has been assigned to.
 * The siteID is extracted from the group name.
 * The associated SCU serial number is then obtained from the sites linked SCU field
 *
 * Once a list of SCU serial number is know, a IoT policy is created giving the user access
 * to the AWS IoT devices.
 *
 * @param username cognito username (format 0be83033-5722-431b-99f7-4f18a21ed99e)
 */
export async function setupUserIotAuthorisation(
  username: string
): Promise<void> {
  console.info('Updating IoT Auth for user:', username);
  await assignIoTPolicy(username);
}

export async function addUserAccessForSite(
  username: string,
  siteID: string
): Promise<void> {
  const groupName = getSiteGroupName(siteID);
  await userUtils.addUserToGroup(username, groupName);
}

export async function removeUserAccessForSite(
  username: string,
  siteID: string
): Promise<void> {
  const groupName = getSiteGroupName(siteID);
  await userUtils.removeUserFromGroup(username, groupName);
}

/**
 * Since only the current useer knows their cognitoID we have to
 * call the IoT auth once the user first logs in.
 *
 * Sleeps have been added to throttle the API usage as I have seen
 * AWS reject setup sometimes due to too many API calls
 */
export async function checkCurrentUserIotConfigAuth(
  authData: unknown,
  username: string,
  attributes: unknown
): Promise<void> {
  const userData = userUtils.getUserConfig_currentUser(attributes);

  if (userData.callIoTInitialConfig) {
    console.info('Current user requires IoT authorisation');
    await sleep(500);
    await setupUserIotAuthorisation(username);
    console.info('Current user requires IoT authorisation:done');

    await sleep(500);
    userData.callIoTInitialConfig = false;
    await Auth.updateUserAttributes(authData, {
      'custom:userconfigjson': JSON.stringify(userData),
    });
    console.info('custom:userconfigjson updated');

    await sleep(500);

    console.info('checkCurrentUserIotConfigAuth finsihed');
  }
}
