/** @module Installation */

import React from 'react';
import { invalidID, iRoom, iRoutingEvent, iDevice } from 'types/manifest-types';
import { Device, teINPUT_METHOD } from './device';
import { Product } from 'components/floorplan/product';
import { ALARM_TYPES } from 'types/event-enums';
import {
  teDEVICE_MODELS_ROOM_UNIT,
  teRU_NOTIFY_FLAG_BITS,
} from 'types/manifest-enums';
import * as manifestUtils from 'components/installation/manifestUtils';
import * as careGroupUtils from './careGroupUtils';
import * as audit from 'common/IoT/IoTAudit';
import * as eventEnums from 'types/event-enums';
import * as ManifestUtils from 'components/installation/manifestUtils';
import { getCareGroupIDFromSequenceId } from './careGroupUtils';

export interface iRoomMetaData {
  communal: boolean; // whether this is a communal room
  tags: string; // any tags for grouping rooms
  groups: number; // bitmask of which groups the resident beloing to
}

export const iRoomMetaDataDefaults: iRoomMetaData = {
  communal: false,
  tags: '',
  groups: 0,
};

/*
 * Room device class
 * @class
 * @hideconstructor
 * @implements iRoom
 */

export class Room extends Device implements iRoom {
  /* iRoom start */
  ID = invalidID;
  Name = '';
  DefaultCareGroupId = 0;
  DefaultTechnicalCareGroupId = 0;
  HomeDeviceId = -1;
  RoomNumber = -1;
  Volume: 0 | 1 | 2 | 3 | 4 = 0;
  ToneVolume: 0 | 1 | 2 | 3 | 4 = 0;
  MicVolume: 0 | 1 | 2 | 3 | 4 = 0;
  Inactivity = 0;
  Intruder = 0;
  Empty = 0;
  EnableOK = 0;
  LastOK = 0;
  FlagOK = 0;
  AwayState = 0;
  LinkedRoomNumber = 0;
  WifiMode: 0 | 1 | 2 = 0;

  /** Added in R5.10 */
  MetaData = JSON.stringify(iRoomMetaDataDefaults);
  LastSpokenTo = 0;
  LastSeen = 0;
  OKTimeLeftBeforeReminder = 3600;
  OKReminderPeriod = 180;

  /** Added in R5.11 */
  RaiseResidentOKAlarm = 0;

  /** Added in R5.12 */
  TestModeEnabledEPOCH = 0;
  IntruderStateChangeEPOCH = 0;
  IntruderState = 0;
  EntryExitTimeout = 30;
  DivertDoorToManager = 0;
  HomeAwaySetIntruder = 1;
  AwayDivertDoorToManager = 0;
  /* iRoom end */

  RedButtonEnabled = 1;
  oldRedButton = 1;

  peripheralRoutingEvents: iRoutingEvent[] = [];

  constructor(
    roomValues: iRoom,
    deviceValues: iDevice,
    routingEvents: iRoutingEvent[],
    peripheralRoutingEvents: iRoutingEvent[],
    keys: string[],
    tableName: string
  ) {
    super(deviceValues, routingEvents);
    Object.assign(this, roomValues);
    this.peripheralRoutingEvents = peripheralRoutingEvents;
    this.productManifest.manifestObjectKeys = keys;
    this.productManifest.tablename = tableName;
    this.productManifest.dataChanged = false;
    this.productManifest.tableID = this.ID;
    this.ID = deviceValues.ID;
    this.RedButtonEnabled = 1;

    if (
      deviceValues.HwModel >= teDEVICE_MODELS_ROOM_UNIT.DEVICE_MODEL_AUDIO_ONLY
    ) {
      this.InputAccessor = {
        Method: teINPUT_METHOD.INPUT_METHOD_SINGLE_EVENT_WITH_POLARITY,
        RoutingEventTypes: [
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1,
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2,
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3,
        ],
      };
    } else {
      this.InputAccessor = {
        Method: teINPUT_METHOD.INPUT_METHOD_SINGLE_EVENT_WITH_POLARITY,
        RoutingEventTypes: [
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1,
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2,
        ],
      };
    }

    this.pullHardwiredInputsFromRoutingEvents();

    const routingEvent = this.getRoutingEvent(
      ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1
    );
    if (routingEvent) {
      if (
        routingEvent.AltAlarmTypeId === ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT
      ) {
        this.RedButtonEnabled = 0;
      }
    }

    this.oldRedButton = this.RedButtonEnabled;

    this.DefaultAlarmSequenceId = careGroupUtils.getCareGroupSequenceId(
      this.DefaultCareGroupId
    );
    this.DefaultTechnicalSequenceId = careGroupUtils.getCareGroupSequenceId(
      this.DefaultTechnicalCareGroupId
    );

    if (this.DefaultTechnicalSequenceId == -1) {
      console.info(
        `this.DefaultCareGroupId = ${this.DefaultCareGroupId} DefaultTechnicalSequenceId = ${this.DefaultTechnicalSequenceId} for rm:${this.RoomNumber}`
      );
    }
  }

  toolTip(): JSX.Element {
    const prod = Product.getProductfromManifestDeviceType(this);
    return (
      <div>
        <p>
          Room Unit - {this.Name} {this.isCommunal() ? ' (Communal Area) ' : ''}
        </p>
        <ul>
          <li>Room Number: {this.RoomNumber.toString()}</li>

          <li>Device ID: {this.HomeDeviceId.toString()}</li>
          <li>MAC Address:{this.MacAddressHex}</li>
          <li>
            Part Code:
            {prod?.productCode}
          </li>
          <li>
            Part Description:
            {prod?.name}
          </li>
        </ul>
      </div>
    );
  }

  /**
   * Set all alarms (apart from technical) for a resident to be sent to the specific care group
   * @param careGroupId The ID from the CARE_GROUP table
   */
  setResidentCareGroup = (careGroupId: number): boolean => {
    let status = false;

    console.info('setResidentCareGroup resident:', this.RoomNumber);

    this.DefaultCareGroupId = careGroupId;
    const seqId = careGroupUtils.getCareGroupSequenceId(careGroupId);
    if (seqId == -1) return false;

    this.DefaultAlarmSequenceId = seqId;
    status = this.setRoutingEventsCareGroup(careGroupId);

    // residents can have calls raised form their local inputs (red button / hw inputs) or from other radio peripherals.
    // The routing event table maps the alarm to a resident via the ROUTING_EVENT.ResidentId field
    // routingEvents is an array of the ROUTING_EVENT rows where the ROUTING_EVENT.AlarmSourceDeviceId == the Resident.HomeDeviceId
    // peripheralRoutingEvents is an array of the ROUTING_EVENT rows where the ROUTING_EVENT.ResidentId == the Resident.ID and ROUTING_EVENT.AlarmSourceDeviceId != the Resident.HomeDeviceId
    const manifest = manifestUtils.getManifestInstance();
    for (const peripheral of this.peripheralRoutingEvents) {
      const trig = manifest.triggers.find(
        (e) => e.ID == peripheral.AlarmSourceDeviceId
      );
      if (trig) {
        trig.setRoutingEventsCareGroup(careGroupId);
      }
    }

    return status;
  };

  /**
   * Set a specific alarm type for a resident to be sent to the specific care group.
   * @param careGroupId The ID from the CARE_GROUP table
   */
  setResidentCareGroupSpecificAlarm = (
    careGroupId: number,
    alarmType: ALARM_TYPES
  ): boolean => {
    let status = false;

    status = this.setRoutingEventsCareGroup_specificAlarmType(
      careGroupId,
      alarmType
    );

    // residents can have calls raised form their local inputs (red button / hw inputs) or from other radio peripherals.
    // The routing event table maps the alarm to a resident via the ROUTING_EVENT.ResidentId field
    // routingEvents is an array of the ROUTING_EVENT rows where the ROUTING_EVENT.AlarmSourceDeviceId == the Resident.HomeDeviceId
    // peripheralRoutingEvents is an array of the ROUTING_EVENT rows where the ROUTING_EVENT.ResidentId == the Resident.ID and ROUTING_EVENT.AlarmSourceDeviceId != the Resident.HomeDeviceId
    const manifest = manifestUtils.getManifestInstance();
    for (const peripheral of this.peripheralRoutingEvents) {
      const trig = manifest.triggers.find(
        (e) => e.ID == peripheral.AlarmSourceDeviceId
      );
      if (trig) {
        // setRoutingEventsCareGroup will not set any technical alarms
        if (trig.getRoutingEvent(alarmType, true) !== undefined) {
          trig.setRoutingEventsCareGroup_specificAlarmType(
            careGroupId,
            alarmType
          );
        }
      }
    }

    return status;
  };

  /**
   * Set a specific alarm type for a resident to be sent to the specific care group.
   * If deviceId is set then the alarm type from a specific device will be set
   * @param careGroupId The ID from the CARE_GROUP table
   */
  setResidentCareGroupSpecificDevice = (
    careGroupId: number,
    deviceId: number
  ): boolean => {
    let status = false;

    if (deviceId !== this.ID) {
      // not for us.
      return true;
    }

    status = this.setRoutingEventsCareGroup_specificAlarmType(
      careGroupId,
      deviceId
    );

    // residents can have calls raised form their local inputs (red button / hw inputs) or from other radio peripherals.
    // The routing event table maps the alarm to a resident via the ROUTING_EVENT.ResidentId field
    // routingEvents is an array of the ROUTING_EVENT rows where the ROUTING_EVENT.AlarmSourceDeviceId == the Resident.HomeDeviceId
    // peripheralRoutingEvents is an array of the ROUTING_EVENT rows where the ROUTING_EVENT.ResidentId == the Resident.ID and ROUTING_EVENT.AlarmSourceDeviceId != the Resident.HomeDeviceId
    const manifest = manifestUtils.getManifestInstance();
    for (const peripheral of this.peripheralRoutingEvents) {
      const trig = manifest.triggers.find(
        (e) => e.ID == peripheral.AlarmSourceDeviceId
      );
      if (trig) {
        trig.setRoutingEventsCareGroup_specificAlarmType(careGroupId, deviceId);
      }
    }

    return status;
  };

  /** return a hex string MACAddr from a number */
  /** should this be here? there's something similar in device.... */
  static getHEXMACaddr = (v: number): string => {
    let hex = '';
    if (v) hex = v.toString(16);
    return hex;
  };

  generateChangeSQL(): { update: string; revert: string } {
    if (this.oldRedButton != this.RedButtonEnabled) {
      if (this.RedButtonEnabled == 0) {
        this.updateRoutingEventAlarmType(
          ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1,
          ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT
        );
      } else {
        this.updateRoutingEventAlarmType(
          ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1,
          ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1
        );
      }
    }
    this.oldRedButton = this.RedButtonEnabled;

    const sql = manifestUtils.generateChangeSQL(
      this,
      this.productManifest,
      super.generateChangeSQL()
    );

    return sql;
  }

  getAllAlarmRoutingEvents(): iRoutingEvent[] {
    return this.RoutingEvents;
  }

  getPeripheralRoutingEventbyID(id: number): iRoutingEvent | undefined {
    return this.peripheralRoutingEvents.find((f) => f.ID == id);
  }

  async applyManifestChanges(): Promise<void> {
    await super.applyManifestChanges();
    return manifestUtils.applyManifestChanges(this, this.productManifest);
  }

  /**
   * Sets the last spoken field in the DB and generates an audit event
   * @param username : the username is logged in the audit event
   * @returns Promise will be resolved on timeout or when response received from the SCU
   */
  setSpokenTo = async (
    username: string
  ): Promise<audit.teCLOUD_ERROR_CODES> => {
    const errorCode: audit.teCLOUD_ERROR_CODES = await audit.setSpokenTo(
      this.ID,
      username
    );

    if (errorCode == audit.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE) {
      // optimistic update of the data.  This will be update when the manifest is uploaded in
      // a couple of seconds.
      this.LastSpokenTo = new Date().getTime() / 1000;
    }
    return errorCode;
  };

  /**
   * Sets the last seen field in the DB and generates an audit event
   * @param username : the username is logged in the audit event
   * @returns Promise will be resolved on timeout or when response received from the SCU
   */
  setSeen = async (username: string): Promise<audit.teCLOUD_ERROR_CODES> => {
    const errorCode: audit.teCLOUD_ERROR_CODES = await audit.setLastSeen(
      this.ID,
      username
    );

    if (errorCode == audit.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE) {
      // optimistic update of the data.  This will be update when the manifest is uploaded in
      // a couple of seconds.
      this.LastSeen = new Date().getTime() / 1000;
    }

    return errorCode;
  };

  setMetaData = (data: iRoomMetaData): void => {
    this.MetaData = JSON.stringify(data);
    // console.log(this.MetaData);
  };

  getMetaData = (): iRoomMetaData => {
    if (this.MetaData && this.MetaData.length > 0) {
      try {
        const m = JSON.parse(this.MetaData);
        // make sure we merge with the defaults in case there are missing fields due to new features
        const md: iRoomMetaData = { ...iRoomMetaDataDefaults, ...m };
        return md;
      } catch (e) {
        return iRoomMetaDataDefaults;
      }
    }

    return iRoomMetaDataDefaults;
  };

  /**
   * addToGroup
   * @param groupId - a group from 1 to 30
   */
  addToGroup = (groupId: number): void => {
    if (ManifestUtils.isValidGroupId(groupId)) {
      const md = this.getMetaData();
      const bit = 1 << (groupId - 1);
      md.groups = md.groups | bit;
      // console.log(md.groups, md);
      this.setMetaData(md);
    }
  };

  /**
   * removeFromGroup
   * @param groupId - a group from 1 to 30
   */
  removeFromGroup = (groupId: number): void => {
    if (ManifestUtils.isValidGroupId(groupId)) {
      const md = this.getMetaData();
      const bit = 1 << (groupId - 1);
      md.groups = md.groups & ~bit;
      this.setMetaData(md);
    }
  };

  /**
   * isInGroup
   * @param groupId - a group from 1 to 30
   */
  isInGroup = (groupId: number): boolean => {
    if (ManifestUtils.isValidGroupId(groupId)) {
      const md = this.getMetaData();
      const bit = 1 << (groupId - 1);
      const is: boolean = (md.groups & bit) > 0;
      return is;
    }

    return false;
  };

  isCommunal = (): boolean => {
    return this.getMetaData().communal;
  };

  volumetoString = (vol: number): string => {
    const r = ['1', '2', '3', '4', '5'];
    if (vol > 4) vol = 4;
    return r[vol];
  };

  stringToVolume = (vol: string): number => {
    let v = Number(vol) - 1;
    if (v > 4) v = 4;
    if (v < 0) v = 0;
    return v;
  };

  /**
   *  We have a special case in which a switch can be connected to the hardwired inputs to arm and disarm the intruder system
   *  The switch is latched, in 1 state it arms , the other it disarms the system
   */
  setHardwiredInputRoutingEvents_checkIfLatchedArmDisarm = (
    newEvent: iRoutingEvent
  ): void => {
    const sourceEvent = this.getAlarmRoutingEventbyID(newEvent.ID);

    if (sourceEvent) {
      sourceEvent.AlarmTypeId = newEvent.AlarmTypeId;
      sourceEvent.AltAlarmTypeId = newEvent.AltAlarmTypeId;
      sourceEvent.CareSequenceId = newEvent.CareSequenceId;
      sourceEvent.Covert = newEvent.Covert;
      sourceEvent.Flags = newEvent.Flags;
      sourceEvent.GuardPeriod = newEvent.GuardPeriod;
      sourceEvent.PreAlarmDelay = newEvent.PreAlarmDelay;
      sourceEvent.AlarmDisabled = newEvent.AlarmDisabled;

      if (this.isRoomHardwiredInputEvent(sourceEvent.AlarmTypeId)) {
        if (
          this.isRoomHardwiredInputEvent(sourceEvent.AlarmTypeId) &&
          newEvent.AltAlarmTypeId ==
            eventEnums.ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM_DISARM_TOGGLE
        ) {
          // Special case for intruder:
          //  convert ALARM_TYPE_INTRUDER_ARM_DISARM_TOGGLE to seperate ALARM_TYPE_INTRUDER_ARM & ALARM_TYPE_INTRUDER_DISARM events
          let openEvent = eventEnums.ALARM_TYPES.ALARM_TYPE_INTRUDER_DISARM;
          let closeEvent = eventEnums.ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM;

          if (
            newEvent.Flags &
            (1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_POLARITY)
          ) {
            // Normally closed
            openEvent = eventEnums.ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM;
            closeEvent = eventEnums.ALARM_TYPES.ALARM_TYPE_INTRUDER_DISARM;
          }

          sourceEvent.Flags |=
            1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_INTRUDER;

          const re = this.getHardwiredInputAlarmRoutingOpenCloseEvents(
            sourceEvent.AlarmTypeId
          );

          if (re) {
            re.openRoutingEvent.AltAlarmTypeId = openEvent;
            re.openRoutingEvent.CareSequenceId = newEvent.CareSequenceId;
            re.openRoutingEvent.Covert = newEvent.Covert;
            re.openRoutingEvent.Flags = newEvent.Flags;
            re.openRoutingEvent.GuardPeriod = newEvent.GuardPeriod;
            re.openRoutingEvent.PreAlarmDelay = newEvent.PreAlarmDelay;
            re.openRoutingEvent.AlarmDisabled = newEvent.AlarmDisabled;

            re.closeRoutingEvent.AltAlarmTypeId = closeEvent;
            re.closeRoutingEvent.CareSequenceId = newEvent.CareSequenceId;
            re.closeRoutingEvent.Covert = newEvent.Covert;
            re.closeRoutingEvent.Flags = newEvent.Flags;
            re.closeRoutingEvent.GuardPeriod = newEvent.GuardPeriod;
            re.closeRoutingEvent.PreAlarmDelay = newEvent.PreAlarmDelay;
            re.closeRoutingEvent.AlarmDisabled = newEvent.AlarmDisabled;
          }
        } else {
          const re = this.getHardwiredInputAlarmRoutingOpenCloseEvents(
            sourceEvent.AlarmTypeId
          );

          if (re) {
            re.openRoutingEvent.AltAlarmTypeId =
              eventEnums.ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT;
            re.openRoutingEvent.Flags = 0;

            re.closeRoutingEvent.AltAlarmTypeId =
              eventEnums.ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT;
            re.closeRoutingEvent.Flags = 0;
          }
        }
      }
    }
  };
}
