import TablePaged from 'components/tables/TablePaged';
import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps } from '../tile';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest } from '../../installation/manifest';
import * as ManifestTypes from 'types/manifest-types';
import * as ManifestEnums from 'types/manifest-enums';
import { SCUSQLInjection } from 'common/IoT/IoTSCUSQLInjection';
import * as IotMsgsCfg from 'common/IoT/IoTMessageDefines_CONFIG_QUERY';
import { Column } from 'react-table';
import * as Icons from 'grommet-icons';
import * as IoTOAD from 'common/IoT/IoTOAD';
import * as IoTMsgDefines_DEVICES from 'common/IoT/IoTMessageDefines_DEVICES';
import * as IoTMsgDefines from 'common/IoT/IoTMessageDefines';
import * as Wait from 'components/dialogues/waitDialogue';
import { Text, Box, Layer, Paragraph, Form, Button } from 'grommet';
import * as _ from 'lodash';
import * as FormFields from 'components/forms/formFieldsOld';
import * as S3 from 'common/S3Bucket';
import { Device } from 'components/installation/device';
import * as OAD from 'common/IoT/Helpers/OAD';
import {
  Mutex,
  //MutexInterface,
  //Semaphore,
  //SemaphoreInterface,
  //withTimeout,
} from 'async-mutex';
import * as Utils from 'common/utils/dateUtils';
import { sleep } from 'common/utils/miscUtils';

interface iVersionOptions {
  versions: { name: string; id: string }[];
}

interface iTableData {
  Device: ManifestTypes.iDevice;
  Name: string;
  DeviceSW: number;
  CoProSW: number;
  AppProSW: number;
  DevicePending: number;
  CoProPending: number;
  AppProPending: number;
  UnitNumber: number;
  UnitType: string;
}

enum teACTION_STATES {
  E_STATE_IDLE = 0,
  E_STATE_QUERY_ALL,
  E_STATE_QUERY_SELECTED,
  E_STATE_CLEAR_PENDING,
  E_STATE_ACTIVATING,
}

enum teOAD_EVENTS {
  E_EVENT_POLL = 0,
  E_EVENT_QUERY_ALL,
  E_EVENT_QUERY_SELECTED,
  E_EVENT_RELOAD_VERSIONS,
  E_EVENT_GET_OAD_STATUS,
  E_EVENT_CLEAR_PENDING,
  E_EVENT_ACTION_OK,
  E_EVENT_ACTION_FAIL_NO_RESPONSE,
  E_EVENT_ACTION_FAIL_BUSY,
  E_EVENT_OAD_STATUS_UPDATE,
  E_EVENT_UPGRADE_DEVICE,
  E_EVENT_UPGRADE_ALL_DEVICES,
  E_EVENT_ACTIVATE_DEVICE,
  E_EVENT_ACTIVATE_ALL_DEVICES,
  E_EVENT_MANIFEST_UPDATE,
}

const SQLInjection = SCUSQLInjection.Instance;

let querySelectList: iTableData[] = [];
let clearPendingList: iTableData[] = [];
let activatePendingList: iTableData[] = [];
let upgradePendingList: iTableData[] = [];

let currentProcessor: IoTOAD.tePROCESSOR = IoTOAD.tePROCESSOR.E_PROCESSOR_APP;
let manifestDirty = false;
const mutex = new Mutex();
let pageActive = false;

let stateHandlers: {
  (event: teOAD_EVENTS, eventData: unknown): Promise<void>;
}[] = [];

interface iProcessorType {
  processor: IoTOAD.tePROCESSOR;
}

const processorTypeFields: FormFields.iField[] = [
  {
    label: 'Processor',
    id: 'processor',
    type: 'select',
    options: [
      {
        name: 'RF Data',
        id: IoTOAD.tePROCESSOR.E_PROCESSOR_DATA,
      },
      {
        name: 'RF Audio',
        id: IoTOAD.tePROCESSOR.E_PROCESSOR_AUDIO,
      },
      {
        name: 'Application',
        id: IoTOAD.tePROCESSOR.E_PROCESSOR_APP,
      },
    ],
  },
];

interface iSoftwareVersion {
  upgradeVersion: string;
}

const softwareVersionsFields: FormFields.iField[] = [
  {
    label: 'Upgrade version',
    id: 'upgradeVersion',
    type: 'select',
    options: [
      {
        name: 'R4.47',
        id: 'R4.47',
      },
    ],
  },
];

const SWVersions = (): JSX.Element => {
  const [allData, setAllData] = useState<iTableData[]>([]);
  const [, SetChildEvent] = useState('');
  const manifest = new Manifest();
  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [oadSTatusText, setOadSTatusText] = useState('Idle');
  const [pullStatusText, setPullStatusText] = useState('Idle');
  const queryTimerId = React.useRef<undefined | NodeJS.Timeout>(undefined);
  const [selectedRows, setSelectedRows, selectedRowsRef] = useState<
    iTableData[]
  >([]);
  const [oadActionState, setOadActionState, oadActionStateRef] = useState(
    teACTION_STATES.E_STATE_IDLE
  );
  const [showPullDialogue, setShowPullDialogue] = useState(false);
  const [showProcessorDialogue, setShowProcessorDialogue] = useState(false);

  const [upgradeVersion, setUpgradeVersion] = useState<iSoftwareVersion>({
    upgradeVersion: 'R4.47',
  });
  const [processorType, setProcessorType] = useState<iProcessorType>({
    processor: IoTOAD.tePROCESSOR.E_PROCESSOR_DATA,
  });

  const stopStatusTimer = () => {
    if (queryTimerId.current) {
      clearTimeout(queryTimerId.current);
      queryTimerId.current = undefined;
    }
  };

  const startStatusTimer = (timeout: number) => {
    if (queryTimerId.current) {
      clearTimeout(queryTimerId.current);
    }

    queryTimerId.current = setTimeout(() => {
      queryTimerId.current = undefined;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      oadStateMachine(teOAD_EVENTS.E_EVENT_GET_OAD_STATUS);
    }, timeout);
  };

  const setState = (newState: teACTION_STATES) => {
    setOadActionState(newState);
    if (newState == teACTION_STATES.E_STATE_IDLE) {
      if (manifestDirty) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        oadStateMachine(teOAD_EVENTS.E_EVENT_MANIFEST_UPDATE);
      }
    }
  };

  const updateInfo = async () => {
    manifestDirty = false;

    if (manifest.devices.length === 0) return;

    console.info('SW Update Info');
    let tsCLOUD_MSG_QUERY_CFG_RUNSQL_RESP:
      | IotMsgsCfg.tsCLOUD_MSG_QUERY_CFG_RUNSQL_RESP
      | undefined = undefined;

    try {
      const sqlResponse = await SQLInjection.sendSQL(
        'select * from OAD;',
        false,
        false,
        false,
        null
      );

      if (sqlResponse) {
        console.info('sqlResponse');
        tsCLOUD_MSG_QUERY_CFG_RUNSQL_RESP = sqlResponse.msgHeader
          ?.msg as IotMsgsCfg.tsCLOUD_MSG_QUERY_CFG_RUNSQL_RESP;
      }
    } catch (error) {
      console.error('SQ versions failed to get data form the SCU');
    }

    if (tsCLOUD_MSG_QUERY_CFG_RUNSQL_RESP) {
      console.info(tsCLOUD_MSG_QUERY_CFG_RUNSQL_RESP);
      const oadTable: ManifestTypes.iOAD[] = (
        tsCLOUD_MSG_QUERY_CFG_RUNSQL_RESP.sqlData as {
          rows: ManifestTypes.iOAD[];
        }
      ).rows;
      const data: iTableData[] = [];
      for (const index in oadTable) {
        const row = oadTable[index];
        const device = manifest.devices.find((e) => e.ID === row.DeviceID);
        const isTrigger =
          manifest.triggers.find((e) => e.ID === row.DeviceID) !== undefined;

        if (isTrigger) continue;

        if (device) {
          const entry: iTableData = {
            Device: device,
            Name: manifest.getDeviceNameByMac(device.MacAddress),
            DeviceSW: row.DeviceSW,
            CoProSW: row.CoProSW,
            AppProSW: row.AppProSW,
            DevicePending: row.DevicePending,
            CoProPending: row.CoProPending,
            AppProPending: row.AppProPending,
            UnitNumber: manifest.getUnitNumberByMac(device.MacAddress),
            UnitType: manifest.getDeviceDescriptionByMac(device.MacAddress),
          };
          data.push(entry);
        }
      }

      setAllData(data);
    }
  };

  const oadStateMachine = async (
    event: teOAD_EVENTS,
    eventData: unknown = undefined
  ) => {
    if (!pageActive) {
      console.error('SW page not active');
      return;
    }

    console.info(
      `Got event ${event} in state ${oadActionStateRef.current} Muxtex is ${
        mutex.isLocked() ? 'LOCKED' : 'OPEN'
      }`
    );
    const release = await mutex.acquire();
    console.info(`Got mutex`);

    if (!pageActive) {
      console.error('SW page not active after mutex');
      release();
      return;
    }

    try {
      await stateHandlers[oadActionStateRef.current](event, eventData);
    } finally {
      console.info(`Release mutex`);
      release();
    }
  };

  const getStatus = async (updateStatus = true): Promise<void> => {
    let resp = undefined;
    try {
      const response = await IoTOAD.oadStatus();

      resp = response.msgHeader
        ?.msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_STATUS_RESP;

      console.info('Status Resposne:', resp);

      if (updateStatus) {
        let statusText = 'Idle';
        if (resp.state < IoTOAD.teOAD_STATES.OAD_STATE_MAX) {
          statusText = IoTOAD.oadStateText[resp.state];
        }
        statusText += '\r\nQueued items: ' + resp.downloadQueueSize;

        if (resp.state == IoTOAD.teOAD_STATES.OAD_STATE_QUERYING_SW) {
          statusText =
            statusText +
            `\r\nCurrent device ${resp.unitIndex}/${resp.totalUnits}  (Device ID:${resp.deviceId})`;
        }

        if (
          resp.state == IoTOAD.teOAD_STATES.OAD_STATE_BROADCAST ||
          resp.state == IoTOAD.teOAD_STATES.OAD_STATE_WAIT_RX_CHECK ||
          resp.state == IoTOAD.teOAD_STATES.OAD_STATE_FILL_IN_BROADCAST
        ) {
          const PKT_WEIGHTING = 9;
          let imageProgress = 0;
          if (resp.totalPackets > 0 && resp.totalUnits > 0) {
            const unit = resp.unitIndex > 0 ? resp.unitIndex - 1 : 0;
            imageProgress =
              (PKT_WEIGHTING * 100 + (unit * 100) / resp.totalUnits) /
              (PKT_WEIGHTING + 1);
            if (resp.state == IoTOAD.teOAD_STATES.OAD_STATE_BROADCAST) {
              imageProgress =
                (((resp.packetId * 100) / resp.totalPackets) * PKT_WEIGHTING) /
                (PKT_WEIGHTING + 1);
            }
          }

          if (resp.imageId < IoTOAD.imageNames.length) {
            statusText += `\r\nCurrent Image: ${
              IoTOAD.imageNames[resp.imageId]
            }, progress:${Math.floor(imageProgress)}%`;
          }
        }

        const hours = String(Math.floor(resp.time / 3600)).padStart(2, '0');
        const mins = String(Math.floor((resp.time / 60) % 60)).padStart(2, '0');
        const seconds = String(Math.floor(resp.time % 60)).padStart(2, '0');

        let time = `Running time: ${hours}:${mins}:${seconds}`;
        if (
          resp.state == IoTOAD.teOAD_STATES.OAD_STATE_ACTIVATING ||
          resp.state == IoTOAD.teOAD_STATES.OAD_STATE_POST_ACTIVATE
        ) {
          time = `Time until the system resets: ${hours}:${mins}:${seconds}`;
        }

        if (resp.state != IoTOAD.teOAD_STATES.OAD_STATE_IDLE) {
          statusText += '\r\n' + time;
        }

        setOadSTatusText('SW upgrade module status: ' + statusText);

        let pullStateText = 'Idle';
        if (resp.pullState < IoTOAD.tePULLSTATE.E_OAD_PULL_STATE_MAX) {
          pullStateText = IoTOAD.oadPullStateText[resp.pullState];
        }

        let version = 'Upgrade version loaded: ';
        version +=
          resp.upgradeVersion !== '' ? resp.upgradeVersion.trim() : 'None';

        setPullStatusText(
          version + '\r\n' + 'SW upgrade server pull status: ' + pullStateText
        );
      }
    } catch (e) {
      console.info('No response from the SCU');
    }

    oadStateMachine(teOAD_EVENTS.E_EVENT_OAD_STATUS_UPDATE, resp);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        oadStateMachine(teOAD_EVENTS.E_EVENT_MANIFEST_UPDATE);
      },
      executeOnStartup: false,
    },
  ];

  const query = async (mac: number): Promise<void> => {
    try {
      const response = await IoTOAD.oadQuery(mac);

      const resp = response.msgHeader
        ?.msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_QUERY_RESP;
      if (
        resp &&
        resp.errorcode == IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        oadStateMachine(teOAD_EVENTS.E_EVENT_ACTION_OK);
      } else {
        oadStateMachine(teOAD_EVENTS.E_EVENT_ACTION_FAIL_BUSY);
      }
    } catch (e) {
      console.info('No response from the SCU');
      oadStateMachine(teOAD_EVENTS.E_EVENT_ACTION_FAIL_NO_RESPONSE);
    }
  };

  //TODO we need to ensure don't send application clear for non-omnivia devvices
  const clearPending = async (
    device: ManifestTypes.iDevice,
    processor: IoTOAD.tePROCESSOR
  ): Promise<void> => {
    const mac = device.MacAddress;
    setOadSTatusText(
      `Device SW Status: Clearing pending versions on device ID ${device.ID}`
    );

    try {
      const response = await IoTOAD.oadClear(mac, processor);
      const resp = response.msgHeader
        ?.msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_CLEAR_RESP;
      if (
        resp &&
        resp.errorcode == IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        oadStateMachine(teOAD_EVENTS.E_EVENT_ACTION_OK);
      } else {
        oadStateMachine(teOAD_EVENTS.E_EVENT_ACTION_FAIL_BUSY);
      }
    } catch (e) {
      console.info('No response from the SCU');
      oadStateMachine(teOAD_EVENTS.E_EVENT_ACTION_FAIL_NO_RESPONSE);
    }
  };

  const parseVersions = (
    device: ManifestTypes.iDevice,
    version: number,
    pending: boolean
  ): string => {
    if (
      device.Type === ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_WARDEN_HANDSET &&
      pending
    ) {
      return '---';
    }

    if (version === 0) return '---';
    if (version === -1) return 'Invalid';
    if (version === -1) return 'Error';
    if (version === -3) return 'No reply';
    if (version === -4) return 'In progress';

    if (version < 0) version *= -1;

    const major = Math.floor(version / 1000);
    const minor = Math.floor((version % 1000) / 10);
    const build = version % 10;

    return `R${major}.${minor}b${build}`;
  };

  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        id: 'ID',
        Header: 'ID',
        accessor: (row) => row.Device.ID,
        width: '40px',
      },
      {
        id: 'UnitType',
        Header: 'Type',
        accessor: (row) => row.UnitType,
        width: '50px',
      },
      {
        id: 'UnitNumber',
        Header: 'Unit ID',
        accessor: (row) => (row.UnitNumber == 0 ? '' : row.UnitNumber),
        width: '60px',
      },
      {
        id: 'Mac',
        Header: 'Mac',
        accessor: (row) => Device.MACaddrToString(row.Device.MacAddress, 16),
        width: '110px',
      },
      {
        Header: 'Name',
        accessor: 'Name',
      },
      {
        Header: 'RF Data Version',
        accessor: (row) => parseVersions(row.Device, row.DeviceSW, false),
      },
      {
        Header: 'RF Data Pending',
        accessor: (row) => parseVersions(row.Device, row.DevicePending, true),
      },
      {
        Header: 'RF Audio Version',
        accessor: (row) => parseVersions(row.Device, row.CoProSW, false),
      },

      {
        Header: 'RF Audio Pending',
        accessor: (row) => parseVersions(row.Device, row.CoProPending, true),
      },
      {
        Header: 'App Version',
        accessor: (row) => parseVersions(row.Device, row.AppProSW, false),
      },

      {
        Header: 'App Pending',
        accessor: (row) => parseVersions(row.Device, row.AppProPending, true),
      },
    ],
    []
  );

  const initialState = React.useMemo(
    () => ({
      pageSize: 40,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const issuePullRequest = async (upgradeVersion: string) => {
    let message = 'Failed to issue pull request';

    setDialogueProps({
      show: true,
      dialogueText: 'Please wait...',
      showSpinner: true,
      showOkButton: false,
    });

    const url: string = await S3.getSignedURL(
      'getObject',
      `softwareReleases/${upgradeVersion}/wh_upgrade2.tgz`
    );
    console.info('URL:', url);

    try {
      const response = await IoTOAD.oadUpdatePullRequest(url, upgradeVersion);
      getStatus();

      if (response && response.msgHeader?.msg) {
        console.info('tsCLOUD_MSG_DEVICES_OAD_PULL_UPDATE_RESP:', response);
        const errorcode = (
          response.msgHeader
            .msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_PULL_UPDATE_RESP
        ).errorcode;
        if (errorcode == IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE) {
          if (queryTimerId.current === undefined) {
            getStatus();
          }
          message = '';
        }
      }
    } catch (error) {
      console.error('failed to get data form the SCU');
      message =
        'Failed to issue pull request as the system controller is offline';
    }

    if (message != '') {
      setDialogueProps({
        show: true,
        dialogueText: message,
        showSpinner: false,
        showOkButton: true,
        okButtonPress() {
          setDialogueProps(undefined);
        },
      });
    } else {
      setDialogueProps(undefined);
    }
  };

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: oadActionState !== teACTION_STATES.E_STATE_IDLE,
      items: [
        {
          label: 'Query selected devices',
          icon: <Icons.SearchAdvanced size="medium" />,
          onClick: () => {
            oadStateMachine(teOAD_EVENTS.E_EVENT_QUERY_SELECTED);
          },
          disabled:
            selectedRowsRef.current.length === 0 ||
            oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
        {
          label: 'Query all devices',
          icon: <Icons.Search size="medium" />,
          onClick: () => {
            oadStateMachine(teOAD_EVENTS.E_EVENT_QUERY_ALL);
          },
          disabled:
            selectedRowsRef.current.length !== 0 ||
            oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
        {
          label: 'Reload versions',
          icon: <Icons.Refresh size="medium" />,
          onClick: () => {
            oadStateMachine(teOAD_EVENTS.E_EVENT_RELOAD_VERSIONS);
          },
          disabled: oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
        {
          label: 'Get OAD Status',
          icon: <Icons.StatusInfo size="medium" />,
          onClick: () => {
            oadStateMachine(teOAD_EVENTS.E_EVENT_GET_OAD_STATUS);
          },
          disabled: oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
        {
          label: 'Clear Pending Update',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            setShowProcessorDialogue(true);
          },
          disabled:
            selectedRows.length === 0 ||
            oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
        {
          label: 'Load new update onto the SCU',
          icon: <Icons.CloudSoftware size="medium" />,
          onClick: () => {
            setShowPullDialogue(true);
          },
          disabled: oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },

        {
          label: 'Download Selected Devices',
          icon: <Icons.DownloadOption size="medium" />,
          onClick: () => {
            setDialogueProps({
              show: true,
              dialogueText:
                selectedRows.length === 1
                  ? `Are you sure you want to upgrade device id ${selectedRows[0].Device.ID}`
                  : `Are you sure you want to upgrade ${selectedRows.length} devices`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress() {
                oadStateMachine(teOAD_EVENTS.E_EVENT_UPGRADE_DEVICE);
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
          },
          disabled:
            selectedRows.length === 0 ||
            oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
        {
          label: 'Broadcast Download to ALL Devices',
          icon: <Icons.Download size="medium" />,
          onClick: () => {
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to upgrade ALL devices`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress() {
                oadStateMachine(teOAD_EVENTS.E_EVENT_UPGRADE_ALL_DEVICES);
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
          },
          disabled:
            selectedRows.length !== 0 ||
            oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
        {
          label: 'Activate Selected Devices',
          icon: <Icons.DocumentUpdate size="medium" />,
          onClick: () => {
            //if (selectedRows.length === 1) {
            setDialogueProps({
              show: true,
              dialogueText:
                selectedRows.length === 1
                  ? `Are you sure you want to activate device id ${selectedRows[0].Device.ID}?`
                  : `Are you sure you want to activate ${selectedRows.length} devices?`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress() {
                oadStateMachine(teOAD_EVENTS.E_EVENT_ACTIVATE_DEVICE);
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
            //}
          },
          disabled:
            selectedRows.length === 0 ||
            oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
        {
          label: 'Activate ALL Devices',
          icon: <Icons.DocumentUpdate size="medium" />,
          onClick: () => {
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to activate ALL devices`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress() {
                oadStateMachine(teOAD_EVENTS.E_EVENT_ACTIVATE_ALL_DEVICES);
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
          },
          disabled:
            selectedRows.length !== 0 ||
            oadActionStateRef.current != teACTION_STATES.E_STATE_IDLE,
        },
      ],
    }),
    [selectedRows, oadActionState] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const clearPendingAll = async (
    selectedList: iTableData[],
    processor: IoTOAD.tePROCESSOR
  ): Promise<void> => {
    clearPendingList = _.cloneDeep(selectedList);
    if (clearPendingList.length == 0) return;
    const item = clearPendingList[0];
    currentProcessor = processor;
    clearPending(item.Device, processor);

    setState(teACTION_STATES.E_STATE_CLEAR_PENDING);
  };

  /*************************************************
   *    E_STATE_IDLE
   *************************************************/

  const handle_E_STATE_IDLE = async (
    event: teOAD_EVENTS,
    eventData: unknown
  ) => {
    switch (event) {
      case teOAD_EVENTS.E_EVENT_POLL:
        break;

      case teOAD_EVENTS.E_EVENT_QUERY_ALL:
        query(0);
        setState(teACTION_STATES.E_STATE_QUERY_ALL);
        break;

      case teOAD_EVENTS.E_EVENT_QUERY_SELECTED:
        querySelectList = _.cloneDeep(selectedRowsRef.current);
        if (querySelectList.length > 0) {
          query(querySelectList[0].Device.MacAddress);
          setState(teACTION_STATES.E_STATE_QUERY_SELECTED);
        }
        break;

      case teOAD_EVENTS.E_EVENT_RELOAD_VERSIONS:
        await updateInfo();
        break;

      case teOAD_EVENTS.E_EVENT_GET_OAD_STATUS:
        /*await*/ getStatus();
        break;

      case teOAD_EVENTS.E_EVENT_CLEAR_PENDING:
        {
          const processor = eventData as IoTOAD.tePROCESSOR;
          clearPendingAll(selectedRowsRef.current, processor);
        }
        break;

      case teOAD_EVENTS.E_EVENT_OAD_STATUS_UPDATE:
        {
          stopStatusTimer();

          const statusResp =
            eventData as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_STATUS_RESP;

          if (statusResp !== undefined) {
            if (
              statusResp.state != IoTOAD.teOAD_STATES.OAD_STATE_IDLE ||
              statusResp.downloadQueueSize > 0 ||
              (statusResp.pullState !=
                IoTOAD.tePULLSTATE.E_OAD_PULL_STATE_IDLE &&
                statusResp.pullState !=
                  IoTOAD.tePULLSTATE.E_OAD_PULL_STATE_COMPLETE)
            ) {
              startStatusTimer(5000);
            }
          } else {
            setDialogueProps({
              show: true,
              dialogueText: 'The SCU is offline, please try again',
              dialogueTextLine2:
                'If a SW activation has just been performed, please\r\nwait 5 minutes to allow the process to complete.',
              showSpinner: false,
              showOkButton: true,
              okButtonPress() {
                setDialogueProps(undefined);
              },
            });
          }
        }
        break;

      case teOAD_EVENTS.E_EVENT_UPGRADE_DEVICE:
        {
          upgradePendingList = _.cloneDeep(selectedRowsRef.current);
          for (const index in upgradePendingList) {
            const id = upgradePendingList[index].Device.ID;
            const ok = await OAD.upgradeDevice(id, setDialogueProps, false);
            if (ok && pageActive) {
              await sleep(1000);
            } else {
              break;
            }
          }

          setDialogueProps(undefined);
        }
        break;

      case teOAD_EVENTS.E_EVENT_UPGRADE_ALL_DEVICES:
        {
          await OAD.upgradeDevice(
            IoTOAD.OAD_ALL_DEVICES,
            setDialogueProps,
            true
          );
        }
        break;

      case teOAD_EVENTS.E_EVENT_ACTIVATE_DEVICE:
        {
          activatePendingList = _.cloneDeep(selectedRowsRef.current);
          if (activatePendingList.length > 0) {
            // Move state and start teh timer,  the timeout will kick the activation process
            setState(teACTION_STATES.E_STATE_ACTIVATING);
            startStatusTimer(100);
          }
        }
        break;

      case teOAD_EVENTS.E_EVENT_ACTIVATE_ALL_DEVICES:
        {
          await OAD.activateDevice(
            IoTOAD.OAD_ALL_DEVICES,
            setDialogueProps,
            true
          );
        }
        break;

      case teOAD_EVENTS.E_EVENT_MANIFEST_UPDATE:
        {
          stopStatusTimer();
          await updateInfo();
          /*await*/ getStatus();
        }
        break;
    }
  };

  /*************************************************
   *    STATE_QUERY_ALL
   *************************************************/

  const handle_E_STATE_QUERY_ALL = async (
    event: teOAD_EVENTS,
    eventData: unknown
  ) => {
    let message = '';
    switch (event) {
      case teOAD_EVENTS.E_EVENT_OAD_STATUS_UPDATE:
        {
          stopStatusTimer();

          const statusResp =
            eventData as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_STATUS_RESP;

          if (statusResp !== undefined) {
            if (statusResp.state != IoTOAD.teOAD_STATES.OAD_STATE_IDLE) {
              startStatusTimer(1000);
            } else {
              message = 'Complete';
            }
          } else {
            message = 'No reponse from the SCU, it may be offline';
          }
        }
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_OK:
        startStatusTimer(500);
        break;

      case teOAD_EVENTS.E_EVENT_GET_OAD_STATUS:
        /*await*/ getStatus();
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_FAIL_NO_RESPONSE:
        message = 'No response from the SCU, it maybe offline';
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_FAIL_BUSY:
        message = "The system is currently busy and can't action the query";
        break;

      case teOAD_EVENTS.E_EVENT_MANIFEST_UPDATE:
        {
          manifestDirty = true;
        }
        break;

      default:
        // ignore
        break;
    }

    if (message !== '') {
      setDialogueProps({
        show: true,
        dialogueText: message,
        showSpinner: false,
        showOkButton: true,
        okButtonPress() {
          setDialogueProps(undefined);
          updateInfo();
          setState(teACTION_STATES.E_STATE_IDLE);
        },
      });
    }
  };

  /*************************************************
   *    E_STATE_QUERY_SELECTED
   *************************************************/

  const handle_E_STATE_QUERY_SELECTED = async (
    event: teOAD_EVENTS,
    eventData: unknown
  ) => {
    let message = '';
    switch (event) {
      case teOAD_EVENTS.E_EVENT_OAD_STATUS_UPDATE:
        {
          stopStatusTimer();

          const statusResp =
            eventData as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_STATUS_RESP;

          if (statusResp !== undefined) {
            if (statusResp.state != IoTOAD.teOAD_STATES.OAD_STATE_IDLE) {
              startStatusTimer(2000);
            } else {
              // we are now idle, query the next device
              querySelectList.shift();
              if (querySelectList.length > 0) {
                query(querySelectList[0].Device.MacAddress);
              } else {
                message = 'Complete';
              }
            }
          } else {
            message = 'No reponse from the SCU, it may be offline';
          }
        }
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_OK:
        startStatusTimer(100);
        break;

      case teOAD_EVENTS.E_EVENT_GET_OAD_STATUS:
        /*await*/ getStatus();
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_FAIL_NO_RESPONSE:
        message = 'No response from the SCU, it maybe offline';
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_FAIL_BUSY:
        message = "The system is currently busy and can't action the query";
        break;

      case teOAD_EVENTS.E_EVENT_MANIFEST_UPDATE:
        {
          manifestDirty = true;
        }
        break;

      default:
        // ignore
        break;
    }

    if (message !== '') {
      setDialogueProps({
        show: true,
        dialogueText: message,
        showSpinner: false,
        showOkButton: true,
        okButtonPress() {
          setDialogueProps(undefined);
          updateInfo();
          setState(teACTION_STATES.E_STATE_IDLE);
        },
      });
    }
  };

  /*************************************************
   *    E_STATE_CLEAR_PENDING
   *************************************************/

  const handle_E_STATE_CLEAR_PENDING = async (
    event: teOAD_EVENTS,
    eventData: unknown
  ) => {
    let message = '';
    switch (event) {
      case teOAD_EVENTS.E_EVENT_OAD_STATUS_UPDATE:
        {
          stopStatusTimer();

          const statusResp =
            eventData as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_STATUS_RESP;

          if (statusResp !== undefined) {
            if (statusResp.state != IoTOAD.teOAD_STATES.OAD_STATE_IDLE) {
              startStatusTimer(1000);
              return;
            } else {
              // we are now idle, clear the next device
              clearPendingList.shift();
              if (clearPendingList.length > 0) {
                const item = clearPendingList[0];
                clearPending(item.Device, currentProcessor);
                return;
              }
            }
          } else {
            message = 'No reponse from the SCU, it may be offline';
          }
        }
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_OK:
        getStatus(false);
        return;
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_FAIL_NO_RESPONSE:
        // if the device is offline then it will not respond, so move onto the next device
        console.error(
          'No clear repsonse from id:',
          clearPendingList[0].Device.ID
        );
        clearPendingList.shift();
        if (clearPendingList.length > 0) {
          const item = clearPendingList[0];
          clearPending(item.Device, currentProcessor);
          return;
        }
        break;

      case teOAD_EVENTS.E_EVENT_ACTION_FAIL_BUSY:
        message = "The system is currently busy and can't action the query";
        break;

      case teOAD_EVENTS.E_EVENT_MANIFEST_UPDATE:
        {
          manifestDirty = true;
        }
        break;

      default:
        return;
    }

    if (message !== '') {
      setDialogueProps({
        show: true,
        dialogueText: message,
        showSpinner: false,
        showOkButton: true,
        okButtonPress() {
          setDialogueProps(undefined);
        },
      });
    } else {
      await updateInfo();
      getStatus();
      setDialogueProps(undefined);
    }

    setState(teACTION_STATES.E_STATE_IDLE);
  };

  /*************************************************
   *    E_STATE_ACTIVATING
   *************************************************/

  const handle_E_STATE_ACTIVATING = async (
    event: teOAD_EVENTS,
    eventData: unknown
  ) => {
    let message = '';

    switch (event) {
      case teOAD_EVENTS.E_EVENT_MANIFEST_UPDATE:
        {
          manifestDirty = true;
        }
        break;

      case teOAD_EVENTS.E_EVENT_GET_OAD_STATUS:
        getStatus();
        break;

      case teOAD_EVENTS.E_EVENT_OAD_STATUS_UPDATE:
        {
          const statusResp =
            eventData as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_STATUS_RESP;

          if (statusResp !== undefined) {
            if (statusResp.state != IoTOAD.teOAD_STATES.OAD_STATE_IDLE) {
              startStatusTimer(1500);
              return;
            } else {
              // we are now idle, do the next device
              if (activatePendingList.length > 0) {
                const id = activatePendingList[0].Device.ID;

                setDialogueProps({
                  show: true,
                  dialogueText: `Please wait...\nActivating device ID ${id}`,
                  showSpinner: true,
                  showOkButton: false,
                });

                const ok = await OAD.activateDevice(id, undefined, false);
                activatePendingList.shift();
                if (ok) {
                  startStatusTimer(500);
                } else {
                  message = `Activate failed for device ID ${id}`;
                }
              } else {
                message = 'Complete';
              }
            }
          } else {
            message = 'No reponse from the SCU, it may be offline';
          }

          if (message !== '') {
            setDialogueProps({
              show: true,
              dialogueText: message,
              showSpinner: false,
              showOkButton: true,
              okButtonPress() {
                setDialogueProps(undefined);
                updateInfo();
                setState(teACTION_STATES.E_STATE_IDLE);
              },
            });
          }
        }
        break;
    }
  };

  useEffect(() => {
    querySelectList = [];
    stateHandlers = [
      handle_E_STATE_IDLE,
      handle_E_STATE_QUERY_ALL,
      handle_E_STATE_QUERY_SELECTED,
      handle_E_STATE_CLEAR_PENDING,
      handle_E_STATE_ACTIVATING,
    ];

    S3.getJSON('softwareReleases/softwareManifest.json').then(
      (document_json) => {
        console.info('document_json:', document_json);

        softwareVersionsFields[0].options = (
          document_json as iVersionOptions
        ).versions;
      }
    );
    pageActive = true;
    setState(teACTION_STATES.E_STATE_IDLE);
    oadStateMachine(teOAD_EVENTS.E_EVENT_MANIFEST_UPDATE);
    startStatusTimer(500);

    return () => {
      stopStatusTimer();
      mutex.release();
      pageActive = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const upgradeVersionLayer = (
    <Layer
      style={{ whiteSpace: 'pre-wrap' }}
      background="dialogBackground"
      modal={true}
    >
      <Box pad="small" gap="small" align="center">
        <Paragraph textAlign="center">Please set the upgrade version</Paragraph>

        <Form<iSoftwareVersion>
          value={upgradeVersion}
          onChange={(nextValue) => {
            setUpgradeVersion(nextValue);
          }}
          onSubmit={async () => {
            setShowPullDialogue(false);
            issuePullRequest(upgradeVersion.upgradeVersion);
          }}
        >
          <Box direction="column">
            <FormFields.AlignedFormFields
              displayfields={softwareVersionsFields}
            />
          </Box>

          <Box direction="row" gap="medium" justify="center">
            <Button
              label="OK"
              size="medium"
              plain={false}
              margin="small"
              type="submit"
            />

            <Button
              label="Cancel"
              size="medium"
              plain={false}
              margin="small"
              onClick={() => {
                setShowPullDialogue(false);
              }}
            />
          </Box>
        </Form>
      </Box>
    </Layer>
  );

  const processorLayer = (
    <Layer
      style={{ whiteSpace: 'pre-wrap' }}
      background="dialogBackground"
      modal={true}
    >
      <Box pad="small" gap="small" align="center">
        <Paragraph textAlign="center">
          Please select which image to clear
        </Paragraph>

        <Form<iProcessorType>
          value={processorType}
          onChange={(nextValue) => {
            setProcessorType(nextValue);
          }}
          onSubmit={async () => {
            setShowProcessorDialogue(false);
            oadStateMachine(
              teOAD_EVENTS.E_EVENT_CLEAR_PENDING,
              processorType.processor
            );
          }}
        >
          <Box direction="column">
            <FormFields.AlignedFormFields displayfields={processorTypeFields} />
          </Box>

          <Box direction="row" gap="medium" justify="center">
            <Button
              label="OK"
              size="medium"
              plain={false}
              margin="small"
              type="submit"
            />

            <Button
              label="Cancel"
              size="medium"
              plain={false}
              margin="small"
              onClick={() => {
                setShowProcessorDialogue(false);
              }}
            />
          </Box>
        </Form>
      </Box>
    </Layer>
  );

  return (
    <Tile
      title="Device SW Versions"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      menuProps={menuItems}
      waitDiaglogueProps={dialogueProps}
    >
      <>
        {showPullDialogue && upgradeVersionLayer}
        {showProcessorDialogue && processorLayer}

        <Text style={{ whiteSpace: 'pre-wrap' }}>{pullStatusText}</Text>
        <br />
        <Text style={{ whiteSpace: 'pre-wrap' }}>{oadSTatusText}</Text>
        <Box margin={'medium'}>
          <TablePaged
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columns={columns}
            initialState={initialState}
            data={allData}
            reportDescription={{
              header: '',
              filename: 'DeviceVersions',
            }}
            showCheckboxes={true}
            onSelectRow={(rows: iTableData[]) => {
              setSelectedRows(rows);
            }}
          />
        </Box>
      </>
    </Tile>
  );
};

export default SWVersions;
