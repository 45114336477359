/** @module Installation */

import { iDevice, invalidID, iRoutingEvent } from 'types/manifest-types';
import {
  teDEVICE_TYPES,
  teRU_NOTIFY_FLAG_BITS,
  teDEVICE_MODELS_ROOM_UNIT,
  teDEVICE_MODELS_ROUTER,
  teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES,
  teDEVICE_MODELS_HANDSET,
} from '../../types/manifest-enums';
import { ALARM_TYPES } from 'types/event-enums';
import * as _ from 'lodash';
import * as manifestUtils from 'components/installation/manifestUtils';
import { Product } from 'components/floorplan/product';
import { SCUSQLInjection } from 'common/IoT/IoTSCUSQLInjection';
import * as AlarmUtils from 'common/alarmUtils';
import { getCareGroupSequenceId } from './careGroupUtils';
import * as eventEnums from 'types/event-enums';

/*
 * Base device class
 * @class
 * @hideconstructor
 * @implements iDevice
 */

const deviceNames: string[] = [
  '',
  '',
  '',
  'Handset',
  'Pendant',
  'Room Unit',
  'Gateway',
  'GW Audio',
  'APEX Door',
  'Pullcord',
  'Smoke',
  'Door',
  'Wireless Input Module',
  'Falls Pendant',
  'MAP Main',
  'MAP Audio',
  'VRU Main',
  'Panic Alarm',
  'SIP door',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'Personal Trigger',
  'Personal ALB',
  'Personal AP',
  'Temperature Extreme',
  'Flood',
  'Carbon Monoxide',
  'Smoke',
  'Door Contact',
  'Pullcord',
  'Enuresis',
  'Bed/Chair',
  'Medication Dispenser',
  'Epilepsy',
  'Wireless Call Terminal',
  'Fall',
  'PIR',
  'Carer',
  'PIR Entry/Exit',
  'Intruder Arm/Disarm',
  'Panic Bogus Caller',
  'Natural Gas',
];

// DEVICE_TYPE_TUNSTALL_START = 32,
// DEVICE_TYPE_TUNSALL_PERSONAL_TRIGGER = = DEVICE_TYPE_TUNSTALL_START,
// DEVICE_TYPE_TUNSTALL_PERSONAL_AUTO_LB,
// DEVICE_TYPE_TUNSTALL_PERSONAL_AUTO_PRESENCE,
// DEVICE_TYPE_TUNSTALL_TEMP_EXTREME,
// DEVICE_TYPE_TUNSTALL_FLOOD,
// DEVICE_TYPE_TUNSTALL_CARBON_MONOX,
// DEVICE_TYPE_TUNSTALL_SMOKE,
// DEVICE_TYPE_TUNSTALL_DOOR_CONTACT,
// DEVICE_TYPE_TUNSTALL_PULLCORD,
// DEVICE_TYPE_TUNSTALL_ENURESIS,
// DEVICE_TYPE_TUNSTALL_BED_CHAIR,
// DEVICE_TYPE_TUNSTALL_MEDICATION_DISPENSER,
// DEVICE_TYPE_TUNSTALL_EPILEPSY,
// DEVICE_TYPE_TUNSTALL_WIRELESS_CALL_TERMINAL,
// DEVICE_TYPE_TUNSTALL_FALL,
// DEVICE_TYPE_TUNSTALL_PIR,
// DEVICE_TYPE_TUNSTALL_CARER,
// DEVICE_TYPE_TUNSTALL_PROPERTY_EXIT,
// DEVICE_TYPE_TUNSTALL_INTRUDER_ARMDISARM,
// DEVICE_TYPE_TUNSTALL_PANIC_BOGUSCALLER,
// DEVICE_TYPE_TUNSTALL_NATURAL_GAS,
// DEVICE_TYPE_TUNSTALL_END = DEVICE_TYPE_TUNSTALL_NATURAL_GAS,
// DEVICE_TYPE_MAX,

export enum teDEVICE_INPUTS {
  DEVICE_INPUT_1 = 0,
  DEVICE_INPUT_2,
  DEVICE_INPUT_3,
}

interface iHWInputAlarmTypeMappping {
  singleEvent: eventEnums.ALARM_TYPES;
  openEvent: eventEnums.ALARM_TYPES;
  closeEvent: eventEnums.ALARM_TYPES;
}

export const inputAlarmTypeMapping: iHWInputAlarmTypeMappping[] = [
  {
    singleEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1,
    openEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_OPEN_EVENT,
    closeEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT,
  },
  {
    singleEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2,
    openEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_OPEN_EVENT,
    closeEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_CLOSE_EVENT,
  },
  {
    singleEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3,
    openEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_OPEN_EVENT,
    closeEvent: eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_CLOSE_EVENT,
  },
];

export enum teINPUT_METHOD {
  // The device does not have any inputs
  INPUT_METHOD_NONE = 0,
  // The device is hardcoded if the input is normally open or closed.
  INPUT_METHOD_SINGLE_EVENT,
  // The device input can be configured to be normally open or closed
  INPUT_METHOD_SINGLE_EVENT_WITH_POLARITY,
  // The device sends an alarm event when the input is opened and closed.
  //It is the respoisibility of the SCU to raise the alarm for the desired event.
  INPUT_METHOD_MULTI_EVENT,
}

export enum teHARDWIRED_INPUT_POLARITY {
  POLARITY_NORMALLY_OPEN = 0,
  POLARITY_NORMALLY_CLOSED,
  POLARITY_NOT_USED,
}

export interface iInputAccessor {
  Method: teINPUT_METHOD;
  RoutingEventTypes: ALARM_TYPES[];
}

export const getDeviceDescription = (type: teDEVICE_TYPES): string => {
  return deviceNames[type];
};

export class Device implements iDevice {
  ID = invalidID;
  MacAddress = 0;
  MacAddressHex = '';
  Type = 0;
  Location = 0;
  HwModel = 0;
  PollsMissed = 0;
  ParentID = 0;
  RSSI = 0;
  Depth = 0;
  NetworkJoins = 0;
  RaisePollFails = 0;
  PrefParentID = 0;
  Personality = 0;
  IPAddress = '';
  ClusterGW = 0;
  TunstallReceiver = 0; // TunstallReceiver Only in R5.03 software and upwards
  DateAdded = ''; // DateAdded added in R5.19
  AddedBy = ''; // AddedBy added in R5.19

  HardwiredInputsAlarmType: ALARM_TYPES[] = [];
  HardwiredInputsPolarity: teHARDWIRED_INPUT_POLARITY[] = [];
  HardwiredInputsSupported = 0;

  InputAccessor: iInputAccessor = {
    Method: teINPUT_METHOD.INPUT_METHOD_NONE,
    RoutingEventTypes: [],
  };
  RoutingEvents: iRoutingEvent[] = [];

  deviceManifest: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  productManifest: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  DefaultAlarmSequenceId: number = AlarmUtils.DEFAULT_ALARM_CARE_SEQUANCEID;
  DefaultTechnicalSequenceId: number =
    AlarmUtils.DEFAULT_TECH_ALARM_CARE_SEQUANCEID;

  constructor(values: iDevice, routingEvents: iRoutingEvent[]) {
    Object.assign(this, values);
    // convert the mac address to hex on construction
    this.MacAddressHex = this.getDeviceMACaddr();

    // RoutingEvents is a JSON object which is read only
    // Create new objects from the JSON
    this.RoutingEvents = [];
    for (const re of routingEvents) {
      // Ensure we create a new object for each entry by creating an empty object {}
      // We make typescript happy by telling it we know what we are doing by casting the empty object as a iRoutingEvent.
      // The Object.assign then copies all the properties from re into the empty object so that it does actually become a iRoutingEvent object
      const tmpReoutingEvent = Object.assign({} as iRoutingEvent, re);
      this.RoutingEvents.push(tmpReoutingEvent);
    }

    this.deviceManifest.manifestObjectKeys = _.keys(values);
    this.deviceManifest.dataChanged = false;
    this.deviceManifest.tablename = 'DEVICE_LIST';
    this.deviceManifest.tableID = this.ID;
  }

  toolTip(): JSX.Element {
    throw new Error('toolTip should be implemented by inheriting class');
  }

  getDeviceDescription(): string {
    return Product.getProductBaseDesignator(
      Product.getProductfromManifestDeviceType(this)
    );
  }

  static MACaddrToString(mac: number, base: number): string {
    if (mac === null || mac === undefined) return '';

    // A mac address is 64bits.
    // JavaScript represents numbers using IEEE-754 double-precision (64 bit) format so the 64th bit is a sign bit
    // The javascript tostring does not handle this so we need to use Bigint and force as a unsigned int
    return BigInt.asUintN(64, BigInt(mac)).toString(base);
  }

  static MACaddrStringToNumber(macString: string): number {
    // A mac address is 64bits.
    // JavaScript represents numbers using IEEE-754 double-precision (64 bit) format so the 64th bit is a sign bit
    // Convert to a number by initially parsing using a BigInt
    const bigmac = BigInt.asIntN(64, BigInt('0x' + macString)).toString();

    return parseInt(bigmac, 10);
  }

  static EthernetCapable(device: iDevice): boolean {
    if (
      device.Type === teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT &&
      device.HwModel === teDEVICE_MODELS_ROOM_UNIT.DEVICE_MODEL_VRU
    ) {
      return true;
    }

    if (
      device.Type == teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER &&
      device.HwModel >= teDEVICE_MODELS_ROUTER.DEVICE_MODEL_MAP
    ) {
      return true;
    }

    if (
      device.Type ==
        teDEVICE_TYPES.DEVICE_TYPE_APEX_DOOR_PANEL_AND_INTERFACES &&
      device.HwModel ==
        teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES.DEVICE_MODEL_MAI
    ) {
      return true;
    }

    return false;
  }

  static isVRU(device: iDevice): boolean {
    if (
      device.Type === teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT &&
      device.HwModel === teDEVICE_MODELS_ROOM_UNIT.DEVICE_MODEL_VRU
    ) {
      return true;
    }

    return false;
  }

  static isEvolutionPlusHandset(device: iDevice): boolean {
    if (
      device.Type === teDEVICE_TYPES.DEVICE_TYPE_WARDEN_HANDSET &&
      device.HwModel ===
        teDEVICE_MODELS_HANDSET.DEVIE_HANDSET_MODEL_OMNIVIA_WIFI
    ) {
      return true;
    }

    return false;
  }

  /** get the device's mac addr as HEX*/
  getDeviceMACaddr(): string {
    let ma = '';
    if (this.ID != invalidID) {
      ma = Device.MACaddrToString(this.MacAddress, 16);
    }
    return ma;
  }

  pullHardwiredInputsFromRoutingEvents(): void {
    this.HardwiredInputsAlarmType = [];
    this.HardwiredInputsPolarity = [];
    this.HardwiredInputsSupported = 0;

    // populate the input ararys
    if (this.InputAccessor.Method != teINPUT_METHOD.INPUT_METHOD_NONE) {
      this.HardwiredInputsSupported =
        this.InputAccessor.RoutingEventTypes.length;

      let inNum: teDEVICE_INPUTS = teDEVICE_INPUTS.DEVICE_INPUT_1;
      //for (const index in this.InputAccessor.RoutingEventTypes)
      for (let i = 0; i < this.InputAccessor.RoutingEventTypes.length; i++) {
        const input = this.getInputAlarmType(inNum);
        let alarm = ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT;
        let pol = teHARDWIRED_INPUT_POLARITY.POLARITY_NOT_USED;

        if (input.found) {
          alarm = input.alarmType;
          pol = input.polarity;
        }

        this.HardwiredInputsAlarmType.push(alarm);
        this.HardwiredInputsPolarity.push(pol);

        inNum++;
      }
    }
  }

  pushHardwiredInputsToRoutingEvents(): void {
    for (let index = 0; index < this.HardwiredInputsSupported; index++) {
      const input = teDEVICE_INPUTS.DEVICE_INPUT_1 + index;
      this.setInputAlarmType(
        input,
        this.HardwiredInputsAlarmType[index],
        this.HardwiredInputsPolarity[index]
      );
    }
  }

  getRoutingEvent(
    alarmType: ALARM_TYPES,
    checkAlternatioveAlarmType?: boolean
  ): iRoutingEvent | undefined {
    const useAltAlarm =
      checkAlternatioveAlarmType !== undefined &&
      checkAlternatioveAlarmType === true;

    const routingEvent = this.RoutingEvents.find((item) => {
      const checkType = useAltAlarm ? item.AltAlarmTypeId : item.AlarmTypeId;

      return item.AlarmSourceDeviceId == this.ID && checkType == alarmType;
    });
    return routingEvent;
  }

  getHardwiredInputRoutingEvents(): iRoutingEvent[] {
    const r = this.RoutingEvents.filter((f) => {
      return (
        (f.AlarmTypeId == eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1 &&
          this.HardwiredInputsSupported >= 1) ||
        (f.AlarmTypeId == eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2 &&
          this.HardwiredInputsSupported >= 2) ||
        (f.AlarmTypeId == eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3 &&
          this.HardwiredInputsSupported >= 3)
      );
    });

    return r;
  }

  isRoomHardwiredInputEvent(event: ALARM_TYPES): boolean {
    const mapping: iHWInputAlarmTypeMappping | undefined =
      inputAlarmTypeMapping.find((e) => e.singleEvent === event);

    return mapping !== undefined;
  }

  getAlarmRoutingEventbyID(id: number): iRoutingEvent | undefined {
    return this.RoutingEvents.find((f) => f.ID == id);
  }

  /** return only routing events that generate alarms */
  getAlarmRoutingEvents(): iRoutingEvent[] | undefined {
    const routingEvents = this.RoutingEvents.filter(
      (item) =>
        item.AlarmTypeId !== eventEnums.ALARM_TYPES.ALARM_TYPE_TAG_STATUS_REPORT
    );
    return routingEvents;
  }

  getHardwiredInputAlarmRoutingOpenCloseEvents(
    hwInputEventPolarity: ALARM_TYPES
  ):
    | { openRoutingEvent: iRoutingEvent; closeRoutingEvent: iRoutingEvent }
    | undefined {
    const mapping: iHWInputAlarmTypeMappping | undefined =
      inputAlarmTypeMapping.find((e) => e.singleEvent === hwInputEventPolarity);

    if (mapping) {
      const openRoutingEvent = this.RoutingEvents.find(
        (e) => e.AlarmTypeId == mapping.openEvent
      );
      const closeRoutingEvent = this.RoutingEvents.find(
        (e) => e.AlarmTypeId == mapping.closeEvent
      );

      if (openRoutingEvent && closeRoutingEvent) {
        return { openRoutingEvent, closeRoutingEvent };
      }
    }

    return undefined;
  }

  /*
   *  careGroupUtils imports mainfest and Manifest imports devices so
   *  to break the circular depandanices pass in from the caller
   */
  setRoutingEventsCareGroup(careGroupId: number): boolean {
    let status = false;
    const CareSequenceId = getCareGroupSequenceId(careGroupId);

    if (CareSequenceId != -1) {
      for (const obj of this.RoutingEvents) {
        const isTechnical = AlarmUtils.alarmIsTechnical(obj.AltAlarmTypeId);
        if (!isTechnical) {
          status = true;
          obj.CareSequenceId = CareSequenceId;
        }
      }
    }

    return status;
  }

  setRoutingEventsCareGroup_specificAlarmType(
    careGroupId: number,
    alarmType: ALARM_TYPES,
    deviceId?: number | undefined
  ): boolean {
    let status = false;
    const newCareSequenceId = getCareGroupSequenceId(careGroupId);

    if (newCareSequenceId != -1) {
      for (const obj of this.RoutingEvents) {
        if (
          obj.AltAlarmTypeId == alarmType &&
          (deviceId === undefined || obj.AlarmSourceDeviceId === deviceId)
        ) {
          status = true;
          obj.CareSequenceId = newCareSequenceId;
        }
      }
    }

    return status;
  }

  setRoutingEventsCareGroup_specificDevice(
    careGroupId: number,
    deviceId: number
  ): boolean {
    let status = false;
    const CareSequenceId = getCareGroupSequenceId(careGroupId);

    if (CareSequenceId != -1) {
      for (const obj of this.RoutingEvents) {
        if (obj.AlarmSourceDeviceId === deviceId) {
          status = true;
          obj.CareSequenceId = CareSequenceId;
        }
      }
    }

    return status;
  }

  setRoutingEventAlarmType(
    sourceAlarmType: ALARM_TYPES,
    raisedAlarmType: ALARM_TYPES,
    alarmCareSequenceId: number,
    technicleCareSequenceId: number,
    flags?: number
  ): boolean {
    let status = false;

    for (const obj of this.RoutingEvents) {
      let careSequence = alarmCareSequenceId;
      let tech = 0;

      if (AlarmUtils.alarmIsTechnical(raisedAlarmType)) {
        careSequence = technicleCareSequenceId;
        tech = 1;
      }

      // If the alarm is set as no alarm event then dont make any changes to the careSequence
      // and IsTechnical as they will not be used.  Saves unecassary SQL statements been sent.
      if (raisedAlarmType == ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT) {
        careSequence = obj.CareSequenceId;
        tech = obj.IsTechnical;
      }

      if (
        obj.AlarmSourceDeviceId == this.ID &&
        obj.AlarmTypeId === sourceAlarmType &&
        // Only set if something has changed otherwise we could
        // overwrite caresequenceid if this has been changed as well
        (obj.AltAlarmTypeId !== raisedAlarmType ||
          obj.IsTechnical != tech ||
          obj.CareSequenceId != careSequence ||
          obj.Flags !== flags)
      ) {
        status = true;

        obj.AltAlarmTypeId = raisedAlarmType;
        obj.IsTechnical = tech;
        obj.CareSequenceId = careSequence;

        if (flags !== undefined) {
          obj.Flags = flags;
        }
      }
    }

    return status;
  }

  updateRoutingEventAlarmType(
    sourceAlarmType: ALARM_TYPES,
    raisedAlarmType: ALARM_TYPES
  ): boolean {
    let status = false;

    for (const obj of this.RoutingEvents) {
      if (
        obj.AlarmSourceDeviceId == this.ID &&
        obj.AlarmTypeId === sourceAlarmType
      ) {
        status = true;
        obj.AltAlarmTypeId = raisedAlarmType;
      }
    }

    return status;
  }

  setInputAlarmType(
    input: teDEVICE_INPUTS,
    alarmType: ALARM_TYPES,
    polarity?: teHARDWIRED_INPUT_POLARITY
  ): boolean {
    let normallyClosed = undefined;
    if (
      polarity !== undefined &&
      polarity != teHARDWIRED_INPUT_POLARITY.POLARITY_NOT_USED
    ) {
      normallyClosed =
        polarity == teHARDWIRED_INPUT_POLARITY.POLARITY_NORMALLY_OPEN
          ? false
          : true;
    }

    if (this.InputAccessor.Method == teINPUT_METHOD.INPUT_METHOD_NONE) {
      return false;
    }

    if (
      this.InputAccessor.Method !=
        teINPUT_METHOD.INPUT_METHOD_SINGLE_EVENT_WITH_POLARITY &&
      normallyClosed === undefined
    ) {
      return false;
    }

    let status = false;

    if (this.InputAccessor.Method == teINPUT_METHOD.INPUT_METHOD_MULTI_EVENT) {
      // We have 2 events per input, event for open and an event for close
      if (input >= this.InputAccessor.RoutingEventTypes.length / 2) {
        return false;
      }

      /**
       * For devices that send up both the open and close event for the hardwired intputs
       * and let the SCU control the input polarity we do the followng:
       *    - Normally open input:  Set the open event = NONE and the close event = alarmType
       *    - Normally close input: Set the open event = alarmType  and the close event = NONE
       */
      const openIndex = input * 2;
      const openSourceType = this.InputAccessor.RoutingEventTypes[openIndex];
      const closeSourceType =
        this.InputAccessor.RoutingEventTypes[openIndex + 1];

      if (normallyClosed === true) {
        this.setRoutingEventAlarmType(
          openSourceType,
          alarmType,
          this.DefaultAlarmSequenceId,
          this.DefaultTechnicalSequenceId
        );

        status = this.setRoutingEventAlarmType(
          closeSourceType,
          ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT,
          this.DefaultAlarmSequenceId,
          this.DefaultTechnicalSequenceId
        );
      } else {
        this.setRoutingEventAlarmType(
          openSourceType,
          ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT,
          this.DefaultAlarmSequenceId,
          this.DefaultTechnicalSequenceId
        );

        status = this.setRoutingEventAlarmType(
          closeSourceType,
          alarmType,
          this.DefaultAlarmSequenceId,
          this.DefaultTechnicalSequenceId
        );
      }
    } else {
      if (input >= this.InputAccessor.RoutingEventTypes.length) {
        return false;
      }
      const sourceAlarmType = this.InputAccessor.RoutingEventTypes[input];
      let flags = 0;
      const re = this.getRoutingEvent(sourceAlarmType);
      if (re == undefined) return false;
      flags = re.Flags;

      if (
        this.InputAccessor.Method ===
        teINPUT_METHOD.INPUT_METHOD_SINGLE_EVENT_WITH_POLARITY
      ) {
        if (normallyClosed) {
          flags =
            flags | (1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_POLARITY);
        } else {
          flags =
            flags & ~(1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_POLARITY);
        }
        status = this.setRoutingEventAlarmType(
          sourceAlarmType,
          alarmType,
          this.DefaultAlarmSequenceId,
          this.DefaultTechnicalSequenceId,
          flags
        );
      }
    }

    return status;
  }

  getInputAlarmType(input: teDEVICE_INPUTS): {
    found: boolean;
    alarmType: ALARM_TYPES;
    polarity: teHARDWIRED_INPUT_POLARITY;
  } {
    let alarmType = ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT;
    let polarity = teHARDWIRED_INPUT_POLARITY.POLARITY_NOT_USED;
    let found = false;
    if (this.InputAccessor.Method == teINPUT_METHOD.INPUT_METHOD_MULTI_EVENT) {
      // We have 2 events per input, event for open and event for close
      if (input < this.InputAccessor.RoutingEventTypes.length / 2) {
        const openIndex = input * 2;
        const openSourceType = this.InputAccessor.RoutingEventTypes[openIndex];
        const closeSourceType =
          this.InputAccessor.RoutingEventTypes[openIndex + 1];

        const openRE = this.getRoutingEvent(openSourceType);
        const closeRE = this.getRoutingEvent(closeSourceType);

        if (openRE && closeRE) {
          alarmType = closeRE.AltAlarmTypeId;
          found = true;
          polarity = teHARDWIRED_INPUT_POLARITY.POLARITY_NORMALLY_OPEN;
          if (closeRE.AltAlarmTypeId == ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT) {
            polarity = teHARDWIRED_INPUT_POLARITY.POLARITY_NORMALLY_CLOSED;
            alarmType = openRE.AltAlarmTypeId;
          }
        }
      }
    } else {
      if (input < this.InputAccessor.RoutingEventTypes.length) {
        const sourceAlarmType = this.InputAccessor.RoutingEventTypes[input];
        const re = this.getRoutingEvent(sourceAlarmType);
        if (re) {
          alarmType = re.AltAlarmTypeId;
          found = true;
          if (
            this.InputAccessor.Method ==
            teINPUT_METHOD.INPUT_METHOD_SINGLE_EVENT_WITH_POLARITY
          ) {
            polarity = teHARDWIRED_INPUT_POLARITY.POLARITY_NORMALLY_OPEN;
            if (
              re.Flags &
              (1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_POLARITY)
            ) {
              polarity = teHARDWIRED_INPUT_POLARITY.POLARITY_NORMALLY_CLOSED;
            }
          }
        }
      }
    }

    return { found, alarmType, polarity };
  }

  generateChangeSQL(): { update: string; revert: string } {
    let changed = false;

    let sql = manifestUtils.generateChangeSQLForTable(
      this.RoutingEvents,
      'ROUTING_EVENT'
    );
    let update = sql.update;
    let revert = sql.revert;

    if (sql.update != '') {
      changed = true;
    }

    sql = manifestUtils.generateChangeSQL(this, this.deviceManifest, {
      update: '',
      revert: '',
    });

    if (sql.update != '') {
      changed = true;
      update += sql.update;
      revert += sql.revert;
    }

    this.deviceManifest.dataChanged = changed;

    return { update, revert };
  }

  async applyManifestChanges(): Promise<void> {
    if (this.deviceManifest.dataChanged) {
      await manifestUtils.despatchToManifestStore(
        'ROUTING_EVENT',
        this.RoutingEvents
      );
      await manifestUtils.despatchToManifestStore(
        this.deviceManifest.tablename,
        this,
        this.deviceManifest.manifestObjectKeys
      );

      this.deviceManifest.dataChanged = false;
    }
  }

  setEthernetConfig(useEthernet: boolean): void {
    this.ParentID = 0;
    this.Depth = 0;
    this.RSSI = 0;

    if (useEthernet) {
      this.ClusterGW = 1;
    } else {
      this.ClusterGW = 0;
    }
  }

  // Using a static method as we will be using this function when we have added a device to
  // the site.  Since we have just added it it will not be in the manifest yet and as such we will
  // not have a manifest record
  static async setIgnorePollFail(deviceID: number): Promise<boolean> {
    const sql = `update DEVICE_LIST set PollsMissed = -1 where ID=${deviceID};`;
    const SQLInjection = SCUSQLInjection.Instance;
    let status = false;

    console.info('setIgnorePollFail sql:', sql);

    try {
      await SQLInjection.sendSQL(sql, true, false, false, null);
      status = true;
    } catch (e) {
      console.error('SQL update error: ', e);
    }
    return status;
  }

  // Using a static method as we will be using this function when we have added a device to
  // the site.  Since we have just added it it will not be in the manifest yet and as such we will
  // not have a manifest record
  static async setInputAlarmtypes(
    deviceID: number,
    input1: ALARM_TYPES,
    input2: ALARM_TYPES
  ): Promise<boolean> {
    let careSequence = AlarmUtils.DEFAULT_ALARM_CARE_SEQUANCEID;
    let tech = 0;
    if (AlarmUtils.alarmIsTechnical(input1)) {
      careSequence = AlarmUtils.DEFAULT_TECH_ALARM_CARE_SEQUANCEID;
      tech = 1;
    }

    let sql = `update ROUTING_EVENT set AltAlarmTypeId=${input1},CareSequenceId=${careSequence},IsTechnical=${tech} where AlarmSourceDeviceId=${deviceID} AND AlarmTypeId=${ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1};`;
    const SQLInjection = SCUSQLInjection.Instance;
    let status = false;

    try {
      await SQLInjection.sendSQL(sql, true, false, false, null);

      careSequence = AlarmUtils.DEFAULT_ALARM_CARE_SEQUANCEID;
      tech = 0;
      if (AlarmUtils.alarmIsTechnical(input2)) {
        careSequence = AlarmUtils.DEFAULT_TECH_ALARM_CARE_SEQUANCEID;
        tech = 1;
      }

      sql = `update ROUTING_EVENT set AltAlarmTypeId=${input2},CareSequenceId=${careSequence},IsTechnical=${tech} where AlarmSourceDeviceId=${deviceID} AND AlarmTypeId=${ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2};`;
      await SQLInjection.sendSQL(sql, true, false, false, null);

      status = true;
    } catch (e) {
      console.error('SQL update error: ', e);
    }
    return status;
  }

  setRoutingEventsAsCovert_specificAlarmType(
    silent: boolean,
    alarmType: ALARM_TYPES,
    deviceId?: number | undefined
  ): boolean {
    let status = false;

    this.RoutingEvents = this.RoutingEvents.map((obj) => {
      if (
        (obj.Covert !== undefined,
        obj.AltAlarmTypeId == alarmType &&
          (deviceId === undefined || obj.AlarmSourceDeviceId === deviceId))
      ) {
        status = true;
        const newObj = {
          ...obj,
          Covert: silent ? 1 : 0,
        };
        return newObj;
      }
      return obj;
    });

    return status;
  }
}
