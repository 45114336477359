import React, { createContext, useContext, FC } from 'react';

//type MyComponentProps = React.PropsWithChildren<unknown>;

/** the event types supported */
export enum DMeventTypes {
  SITESELECTION = 'SiteSelection',
  SCULOADED = 'SCULoaded',
  MANIFEST = 'Manifest',
  FLOORPLANS = 'FloorPlans',
  LOCAITON = 'Location',
  BATTERY = 'Battery',
  EVENTS = 'EVENTS',
  SITELOADED = 'SiteLoaded',
}

/** the interface to describe the returns */
export interface DMContextInterface {
  /** Dummy Function to guide on how to return functions in a context provdier in typescript */
  dummyFn: (a: string, callback: (e: unknown) => void) => void;
}
const defaultState: DMContextInterface = {
  dummyFn: (e) => {
    console.info(e);
  },
};

const DataManagerContext = createContext<DMContextInterface>(defaultState);
export const useDataManagerProvider = (): DMContextInterface =>
  useContext(DataManagerContext);

const DataManagerProvider: FC = ({ children }) => {
  /** Dummy Function to guide on how to return functions in a context provdier in typescript */
  const dummyFn = (a: string, callback: (e: unknown) => void) => {
    console.log('dummy function:', callback);
  };

  return (
    <DataManagerContext.Provider
      value={{
        dummyFn,
      }}
    >
      {children}
    </DataManagerContext.Provider>
  );
};

export default DataManagerProvider;
