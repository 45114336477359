import TablePaged, { dateFilter } from 'components/tables/TablePaged';

import React, { useMemo, useState } from 'react';
//import useStateRef from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps } from '../../tile';
import * as alarmEvents from 'components/systemevents/alarmEvents';
import {
  dateToIsoString_dateonly,
  formatDateWithSeconds,
  dateWithinDateRange,
} from 'common/utils/dateUtils';
import {
  systemEventStates,
  systemEventTopics,
  emitEvent,
} from 'store/eventDispatcher';
import { generateCallReportForCurrentSite } from 'common/reports';

import { Column, Row, Cell } from 'react-table';

import * as Icons from 'grommet-icons';
import * as Wait from 'components/dialogues/waitDialogue';
import { useWinstonLogger } from 'winston-react';
import { Text, Tip } from 'grommet';
import * as UserUtils from 'common/userUtils';
import * as DataSeq from 'store/dataSequencer';
import * as IoTAlarms from 'common/IoT/IoTAlarms';
import DateRange from 'components/configuration/eventsLoadDataRange';
import { AUDIT_EVENTS } from 'types/event-enums';
import i18next from 'i18n';
import { Manifest } from 'components/installation/manifest';

const manifest = new Manifest();

const RecentAlarms = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');
  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [selectedAlarm, setSelectedAlarm] = useState<
    alarmEvents.iAlarmSummary | undefined
  >();
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const clearAlarm = async (alarmId: number) => {
    try {
      setDialogueProps({
        show: true,
        dialogueText: 'Please wait...',
        showSpinner: true,
        showOkButton: false,
      });
      const resp = await IoTAlarms.clearAlarm(alarmId, null);
      console.info('GOT RESP:', resp);
    } catch (e) {
      setDialogueProps({
        show: true,
        dialogueText: 'No response from the SCU, it maybe offline',
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      return;
    }

    setDialogueProps({
      show: true,
      dialogueText: 'Alarm cleared',
      showSpinner: false,
      showOkButton: true,
      okButtonPress: () => {
        setDialogueProps(undefined);
      },
    });
  };

  const columns = React.useMemo<Column<alarmEvents.iAlarmSummary>[]>(
    () => [
      {
        Header: 'Date/Time',
        id: 'raisedAt',
        width: 120,
        //accessor: (row: alarmEvents.iAlarmSummary) => formatDateWithSeconds(row.raisedAt),

        accessor: (row) => new Date(row.raisedAt),
        sortType: 'datetime',
        filter: dateFilter,

        // Using the cell access so that the sortType: 'datetime' works
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const date = data.value;
          if (date) return date ? formatDateWithSeconds(date) : 'Unknown';
        },

        getCellExportValue: (row: Row<alarmEvents.iAlarmSummary>) => {
          return formatDateWithSeconds(row.values.raisedAt);
        },
      },
      {
        Header: 'Alarm ID',
        // The recent alarms will show handset visit information which has the alarm ID set a 0.
        // The visit information is generated by the handset scanning a NFC tag on the VRU
        // This is then sent from the SCU as a AUDIT_EVENTS.AUDIT_EVENT_GENERIC_LOG
        accessor: (row) => {
          return row.alarmId > 0 ? row.alarmId : '';
        },
        width: 65,
      },
      {
        Header: 'Room',
        id: 'roomNumber',
        accessor: 'roomNumber',
        maxWidth: 60,
        minWidth: 60,
        width: 60,
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const text = data.row.original.roomNumber;
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Source',
        accessor: 'source',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const text = data.row.original.source;
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Alarm Type',
        accessor: 'alarmType',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const text = data.row.original.alarmType;
          const alarmSummary = data.row.original;
          if (alarmSummary && alarmSummary.raisedDeviceId) {
            const trigger = manifest.triggers.find(
              (e) => e.ID == alarmSummary.raisedDeviceId
            );
            if (trigger) {
              const info = `Peripheral Type: ${
                trigger.Description
              }\r\nIdentity: ${trigger.getIdentity()}`;
              return (
                <Tip
                  dropProps={{ margin: 'large' }}
                  content={
                    <Text style={{ whiteSpace: 'pre-wrap' }}>{info}</Text>
                  }
                >
                  <Text truncate={true}>{text}</Text>
                </Tip>
              );
            }
          }

          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Care Group',
        accessor: 'careGroupDesc',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const text = data.row.original.careGroupDesc;
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Answered By',
        accessor: (row) => {
          return row.CancelReason ? row.CancelReason : row.answeredBy;
        },
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const text = data.row.original.CancelReason
            ? data.row.original.CancelReason
            : data.row.original.answeredBy;

          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Time to Answer',
        accessor: (row) => {
          return row.timeToAnswer_seconds.toString() + ' s';
        },

        maxWidth: 90,
        minWidth: 90,
        width: 90,
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const text = data.row.original.timeToAnswer_seconds.toString() + ' s';
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Duration',
        accessor: (row) => {
          return row.callDuration_seconds.toString() + ' s';
        },
        maxWidth: 60,
        minWidth: 60,
        width: 60,
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const text = data.row.original.callDuration_seconds.toString() + ' s';
          return <Text truncate={true}>{text}</Text>;
        },
      },
    ],
    []
  );

  const loadDataEvent = () => {
    //const oldest = alarmEvents.getOldestEntry();
    //console.info('recentAlarms.tsx Loadevent, oldest:', oldest);
  };

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: [],
      pageSize: 10,
      sortBy: [
        {
          id: 'raisedAt',
          desc: true,
        },
      ],
    }),
    []
  );

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.ALARMEVENTS,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
  ];

  const clearAlarmRequest = (alarm: alarmEvents.iAlarmSummary) => {
    const events = alarmEvents.getAlarmAuditTrail(alarm.alarmId);
    const cleared = events.find(
      (e) => e.eventId == AUDIT_EVENTS.AUDIT_EVENT_ALARM_CLEAR
    );

    if (cleared) {
      setDialogueProps({
        show: true,
        dialogueText: `${alarm.alarmType} alarm in room Number ${alarm.roomNumber} has already been cleared`,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => setDialogueProps(undefined),
      });
      return;
    }

    setDialogueProps({
      show: true,
      dialogueText: 'Are you sure you want to clear the alarm?',
      dialogueTextLine2: `${alarm.alarmType} alarm in room Number ${alarm.roomNumber}.`,
      showSpinner: false,
      showOkButton: true,
      okButtonPress: async () => {
        setDialogueProps({
          show: true,
          dialogueText:
            'Manually clearing the alarm means no further action will be taken and the alarm will not be signalled to the onsite care team or remote monitoring centre. Continue?',
          dialogueTextLine2: `${alarm.alarmType} alarm in room Number ${alarm.roomNumber}.`,
          showSpinner: false,
          showOkButton: true,
          okButtonPress: async () => {
            await clearAlarm(alarm.alarmId);
          },
          showCancelButton: true,
          cancelButtonPress: () => setDialogueProps(undefined),
        });
      },
      showCancelButton: true,
      cancelButtonPress: () => setDialogueProps(undefined),
    });
  };

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: i18next.t('Generate 7 Day Report', {}),
          icon: <Icons.Analytics size="medium" />,
          onClick: async () => {
            logger.info('recentAlarms.generateReport');
            setDialogueProps({
              show: true,
              dialogueText: 'Please Wait...',
              showSpinner: true,
              showOkButton: false,
            });
            await generateCallReportForCurrentSite(7);
            setDialogueProps(undefined);
          },
          disabled: false,
        },
        {
          label: i18next.t('Generate 3 Month Report', {}),
          icon: <Icons.Analytics size="medium" />,
          onClick: async () => {
            logger.info('recentAlarms.generateReport');
            setDialogueProps({
              show: true,
              dialogueText: 'Please Wait...',
              showSpinner: true,
              showOkButton: false,
            });
            await generateCallReportForCurrentSite(93);
            setDialogueProps(undefined);
          },
          disabled: false,
        },

        {
          label: i18next.t('Manually Clear The Alarm', {}),
          icon: <Icons.StatusCritical size="medium" />,
          onClick: async () => {
            if (selectedAlarm) {
              clearAlarmRequest(selectedAlarm);
            }
          },
          disabled: selectedAlarm == undefined,
          accessLevel: UserUtils.UAG.TECHNICAL,
        },
        {
          label: 'Get All Alarms',
          icon: <Icons.DocumentDownload size="medium" />,
          onClick: async () => {
            setDialogueProps({
              show: true,
              dialogueText: 'Please Wait...',
              showSpinner: true,
              showOkButton: false,
            });
            await DataSeq.getMoreData(360);
            setDialogueProps(undefined);
          },
          disabled: false,
          accessLevel: UserUtils.UAG.ELITE,
        },
      ],
    }),
    [selectedAlarm] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const select = async (
    rowData: alarmEvents.iAlarmSummary,
    selectionInfo: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (!selectionInfo.alreadySelected && rowData.alarmId > 0) {
      logger.info('recentAlarms.selectAlarm', { alarmID: rowData.alarmId });
      setSelectedAlarm(rowData);
      emitEvent(
        systemEventTopics.ALARMEVENTS,
        systemEventStates.SELECTED,
        rowData.alarmId,
        true
      );
    }
  };

  const datedAlarms = useMemo(() => {
    return alarmEvents.alarmSummaries.filter((a) =>
      dateWithinDateRange(
        dateToIsoString_dateonly(start),
        dateToIsoString_dateonly(end),
        dateToIsoString_dateonly(new Date(a.raisedAt))
      )
    );
  }, [start, end]);

  return (
    <>
      <Tile
        title="Recent Alarms"
        eventListeners={events}
        setChildEvent={SetChildEvent}
        waitDiaglogueProps={dialogueProps}
        menuProps={menuItems}
      >
        <>
          <DateRange
            setDialogueProps={setDialogueProps}
            start={start}
            setStart={setStart}
            end={end}
            setEnd={setEnd}
          />
          <TablePaged
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columns={columns}
            initialState={initialState}
            data={datedAlarms}
            showCheckboxes={false}
            onLastSelectedRow={select}
            currentSelectedID={selectedAlarm?.alarmId}
            selectorKeyName="alarmId"
            reportDescription={{
              header: '',
              filename: 'RecentAlarms',
            }}
          />
        </>
      </Tile>
    </>
  );
};

export default RecentAlarms;
