import React from 'react';
import { Spinner, Box, Text } from 'grommet';
import styled from 'styled-components';

const BoxCenter = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 100%;
`;

export type WidgetLoadingProps = {
  show: boolean;
  content?: string;
};

const WidgetLoading = (props: WidgetLoadingProps): JSX.Element => {
  if (props.show) {
    return (
      <>
        <BoxCenter>
          <Spinner alignSelf="center" />
          <Text>{props.content ?? 'Loading...'}</Text>
        </BoxCenter>
      </>
    );
  }
  return <></>;
};

export default WidgetLoading;
