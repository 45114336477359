import { Manifest } from './manifest';
import { Room } from './room';
import { iSelectKeyValue } from '../forms/formFields';

/**
 * Link two rooms together
 * This means a door entry call will appear on both room units at the same time
 * This can also be used to link a room unit to the managers handset so that door calls to the manger appear on the room unit
 * @param roomNumber1 - the first room number
 * @param roomNumber2 - the second room number or 10000 if we are linking the unit to the managers handset visitor calls
 */
export const linkRooms = (
  m: Manifest,
  roomNumber1: number,
  roomNumber2: number
): boolean => {
  let success = false;
  let r1: Room | undefined = undefined;
  let r2: Room | undefined = undefined;

  r1 = m.getRoomByRoomNumber(roomNumber1);
  if (roomNumber2 != 10000) r2 = m.getRoomByRoomNumber(roomNumber2);

  //unlink any existing room links first, both for r1 and r2
  if (r1) {
    if (r1.LinkedRoomNumber != 0) {
      const tmp = m.getRoomByRoomNumber(r1.LinkedRoomNumber);
      if (tmp) tmp.LinkedRoomNumber = 0;
    }
  }
  if (r2) {
    if (r2.LinkedRoomNumber != 0) {
      const tmp = m.getRoomByRoomNumber(r2.LinkedRoomNumber);
      if (tmp) tmp.LinkedRoomNumber = 0;
    }
  }

  // if we are linking to the managers handset door calls then unlink any previous units linked to this
  if (roomNumber2 == 10000) {
    const tmp = m.rooms.filter((f) => f.LinkedRoomNumber == 10000);
    if (tmp) {
      tmp.forEach((e) => (e.LinkedRoomNumber = 0));
    }
  }

  // now do the new link
  if (r1) {
    r1.LinkedRoomNumber = roomNumber2;
    if (r2) {
      r2.LinkedRoomNumber = roomNumber1;
    }
    success = true;
  }

  return success;
};

/**
 * Unlink a room
 * @param roomNumber1 - the room to unlink
 */
export const unlinkRoom = (m: Manifest, roomNumber: number): boolean => {
  const success = true;

  let r: Room | undefined = undefined;

  r = m.getRoomByRoomNumber(roomNumber);
  if (r && r.LinkedRoomNumber != 10000 && r.LinkedRoomNumber != 0) {
    const tmp = m.getRoomByRoomNumber(r.LinkedRoomNumber);
    if (tmp) tmp.LinkedRoomNumber = 0;
  }

  if (r) r.LinkedRoomNumber = 0;
  return success;
};

/**
 * getList of Rooms for select options
 */
export const getRoomListForLinks = (
  m: Manifest,
  ignoreRoom?: number
): iSelectKeyValue[] | undefined => {
  const ret: iSelectKeyValue[] = [
    { key: 0, value: 'None' },
    { key: 10000, value: 'Manager Door Calls' },
  ];
  m.rooms.forEach((r) => {
    const tmp: iSelectKeyValue = {
      key: r.RoomNumber,
      value: 'Room: ' + r.RoomNumber.toString(),
    };
    if (ignoreRoom && ignoreRoom != r.RoomNumber) ret.push(tmp);
  });

  ret.sort((a, b) => (a.key as number) - (b.key as number));

  return ret;
};
