/** @module Installation */

import { iGroupHandset, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';
//import handset from 'assets/img/handset.svg';
//import { manifest } from 'components/floorplan/site';

/*
 * Handset groups
 * @class
 * @hideconstructor
 * @implements iGroupHandset
 */
const MAX_HANDSET_ID = 31;
export class GroupHandset implements iGroupHandset {
  /** iGroupHandset start */
  ID = invalidID;
  Description = '';
  Mask = 0;
  AvailableMask = 0;
  LoggedOnMask = 0;
  /** iGroupHandset end */

  // Got software version incase we need to do processsing differently for
  // different software revsions in the future
  softwareVersion = 0;

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(
    values: iGroupHandset,
    keys: string[],
    tableName: string,
    softwareVersion: number
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
  }

  setDescription(desc: string): void {
    this.Description = desc;
  }

  addHandsettoGroup(handsetDBId: number, loggedOn: boolean): boolean {
    if (handsetDBId >= MAX_HANDSET_ID) return false;
    this.Mask = this.Mask | (1 << handsetDBId);

    if (loggedOn) {
      this.LoggedOnMask = this.LoggedOnMask | (1 << handsetDBId);
      this.AvailableMask = this.LoggedOnMask;
    } else {
      this.LoggedOnMask = this.LoggedOnMask & ~(1 << handsetDBId);
      this.AvailableMask = this.LoggedOnMask;
    }

    return true;
  }

  removeHandsetFromGroup(handsetDBId: number): boolean {
    if (handsetDBId >= MAX_HANDSET_ID) return false;
    this.Mask = this.Mask & ~(1 << handsetDBId);
    this.LoggedOnMask = this.LoggedOnMask & ~(1 << handsetDBId);
    this.AvailableMask = this.LoggedOnMask;
    return true;
  }

  /*
   Returns a list of ENDPOINT_HANDSET.ID that are in the group
   From ENDPOINT_HANDSET table you can get the name of the handset and deviceID
   Our manifest class that defined ENDPOINT_HANDSET is in Handset.ts
  */
  getHandsetsInGroup(): number[] {
    const handsetIds = [];
    for (let index = 0; index < 32; index++) {
      if (this.Mask & (1 << index)) {
        // const i = manifest.handsets.find((h) => {
        //   h.productManifest.tableID;
        // });
        // if (i) {
        //   handsetIds.push(i.ID);
        // }
        handsetIds.push(index);
      }
    }
    return handsetIds;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });

    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }
}
