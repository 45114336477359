import * as Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import * as appUserSlice from 'store/appUserSlice';
import * as reduxStore from 'store/store';
import * as mutations from 'graphql/mutations';
import logger from 'common/logger';
import * as LocalStore from 'common/localStorage';
import _ from 'lodash';
import { Auth } from 'aws-amplify';

Amplify.Amplify.configure(awsconfig);

interface iCognitoUserAttribute {
  Name: string;
  Value: string;
}

export interface iUserConfig {
  reportInterval: eReportSchedule;
  callIoTInitialConfig: boolean;
  receiveSiteAlertsSMS: boolean;
  receiveSiteAlertsEmail: boolean;
  smsAlertPhoneNumber: string;
  brand: string;
}

export interface iUser {
  username: string;
  emailVerified: boolean;
  name: string;
  email: string;
  cognitoId: string;
  userConfig: iUserConfig;
}

export enum eReportSchedule {
  E_REPORT_INTERVAL_NONE = 0,
  E_REPORT_INTERVAL_DAILY,
  E_REPORT_INTERVAL_WEEKLY,
  E_REPORT_INTERVAL_INVALID,
}

export const userConfigDefault: iUserConfig = {
  reportInterval: eReportSchedule.E_REPORT_INTERVAL_NONE,
  callIoTInitialConfig: false,
  receiveSiteAlertsSMS: false,
  receiveSiteAlertsEmail: false,
  smsAlertPhoneNumber: '',
  brand: '',
};

export enum UAG {
  ADMIN = 'admin',
  ELITE = 'elite',
  TECHNICAL = 'technical',
  MAINTENANCE = 'maintenance',
  MANAGER = 'manager',
  BASIC = 'basic',
  NONE = 'none',
}

export type SelectOptionsUserLevels = { label: UAG; value: UAG };
export const userTypeOptions: SelectOptionsUserLevels[] = [
  { value: UAG.ADMIN, label: UAG.ADMIN },
  { value: UAG.ELITE, label: UAG.ELITE },
  { value: UAG.TECHNICAL, label: UAG.TECHNICAL },
  { value: UAG.MAINTENANCE, label: UAG.MAINTENANCE },
  { value: UAG.MANAGER, label: UAG.MANAGER },
  { value: UAG.BASIC, label: UAG.BASIC },
  { value: UAG.NONE, label: UAG.NONE },
];

export function getReportIntervalString(value: eReportSchedule): string {
  let name = 'None';
  if (value === eReportSchedule.E_REPORT_INTERVAL_DAILY) {
    name = 'Daily';
  } else if (value === eReportSchedule.E_REPORT_INTERVAL_WEEKLY) {
    name = 'Weekly';
  }
  return name;
}

export async function addUserToGroup(
  username: string,
  groupname: string
): Promise<void> {
  logger.info('userUtils.addUserToGroup', {
    username: username,
    groupname: groupname,
  });

  try {
    const apiName = 'AdminQueries';
    const path = '/addUserToGroup';
    const myInit = {
      body: {
        username: username,
        groupname: groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Amplify.Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    await Amplify.API.post(apiName, path, myInit);
  } catch (e) {
    logger.error('userUtils.addUserToGroup.error', {
      username: username,
      groupname: groupname,
      erroor: e,
    });

    console.error("couldn't add user to group:" + groupname + ' error:', e);
  }
}
//
export async function removeUserFromGroup(
  username: string,
  groupname: string
): Promise<void> {
  logger.info('userUtils.removeUserFromGroup', {
    username: username,
    groupname: groupname,
  });

  try {
    const apiName = 'AdminQueries';
    const path = '/removeUserFromGroup';
    const myInit = {
      body: {
        username: username,
        groupname: groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Amplify.Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    await Amplify.API.post(apiName, path, myInit);
  } catch (e) {
    logger.error('userUtils.removeUserFromGroup.error', {
      username: username,
      groupname: groupname,
      erroor: e,
    });
    console.error(
      'Failed to remove user from group:' + groupname + ' error:',
      e
    );
  }
}

function getCognitoAttribute(
  attributes: iCognitoUserAttribute[],
  name: string
): string {
  for (const index in attributes) {
    const att = attributes[index];
    if (att.Name == name) {
      return att.Value;
    }
  }
  return '';
}

function getUserConfigFromAttributes(attributes: iCognitoUserAttribute[]) {
  let userConfig: iUserConfig | null | undefined = null;

  const configString = getCognitoAttribute(attributes, 'custom:userconfigjson');

  if (configString !== '') {
    userConfig = JSON.parse(configString);
  }

  if (!userConfig || userConfig === null) {
    userConfig = userConfigDefault;
  }

  // merge the userConfigDefault into userConfig so that if we have added new field
  // we default the ones not present.
  // Items in both userConfigDefault and userConfig will take the value from userConfig
  userConfig = { ...userConfigDefault, ...userConfig };

  return userConfig;
}

export async function listUsers(): Promise<iUser[]> {
  const apiName = 'AdminQueries';
  const path = '/listUsers';
  const userArray: iUser[] = [];
  let nextToken;

  try {
    do {
      const myInit: { [key: string]: unknown } = {
        queryStringParameters: {
          token: nextToken,
        },
        headers: {
          'Content-Type': 'application/json',
          //'Access-Control-Allow-Credentials': 'true',
          //'Content-Type': 'application/x-www-form-urlencoded',
          //'Access-Control-Allow-Origin': '*',
          //'Access-Control-Allow-Headers': '*',

          Authorization: `${(await Amplify.Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };
      //console.info('API header:', myInit);
      const { NextToken, ...rest } = await Amplify.API.get(
        apiName,
        path,
        myInit
      );
      nextToken = NextToken;
      for (const index in rest.Users) {
        const cogUser = rest.Users[index];

        //console.info('cogUser:', cogUser);

        if (cogUser.Enabled) {
          userArray.push({
            username: cogUser.Username,
            emailVerified:
              getCognitoAttribute(cogUser.Attributes, 'email_verified') ===
              'true',
            name: getCognitoAttribute(cogUser.Attributes, 'name'),
            email: getCognitoAttribute(cogUser.Attributes, 'email'),
            cognitoId: getCognitoAttribute(
              cogUser.Attributes,
              'custom:cognitoIdentityId'
            ),

            userConfig: getUserConfigFromAttributes(cogUser.Attributes),
          });
        } else {
          console.info(
            'listUsers, user disabled:',
            cogUser.Attributes[3].Value
          );
        }
      }
    } while (nextToken);

    userArray.sort(function (a, b) {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  } catch (e) {
    console.error('Failed to userlist. Error:', e);
  }

  return userArray;
}

export async function listGroups(): Promise<string[]> {
  const apiName = 'AdminQueries';
  const path = '/listGroups';
  const response: string[] = [];

  const myInit: { [key: string]: unknown } = {
    queryStringParameters: {},
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Amplify.Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  const { ...rest } = await Amplify.API.get(apiName, path, myInit);
  const groupsArray = rest as {
    Groups: [
      {
        GroupName: string;
        CreationDate: string;
        Description: string;
        LastModifiedDate: string;
        UserPoolId: string;
        Precedence: number;
        RoleArn: string;
      }
    ];
  };
  groupsArray.Groups.forEach((data) => response.push(data.GroupName));

  return response;
}

export async function listGroupsForUser(username: string): Promise<string[]> {
  const apiName = 'AdminQueries';
  const path = '/listGroupsForUser';
  const response: string[] = [];

  try {
    const myInit: { [key: string]: unknown } = {
      queryStringParameters: {
        username: username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Amplify.Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { ...rest } = await Amplify.API.get(apiName, path, myInit);

    const groupsArray = rest as {
      Groups: [{ GroupName: string; Description: string }];
    };
    groupsArray.Groups.forEach((data) => response.push(data.GroupName));
    return response;
  } catch (e) {
    console.error("Couldn't get groups for: " + username + ' Error:', e);
  }
  return response;
}

export async function disableUser(username: string): Promise<void> {
  console.info('Disable user:', username);

  logger.info('userUtils.disableUser', {
    username: username,
  });

  const apiName = 'AdminQueries';
  const path = '/disableUser';
  const myInit: { [key: string]: unknown } = {
    body: {
      username: username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Amplify.Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  await Amplify.API.post(apiName, path, myInit);
  return;
}

/**
 * Get the name of the current logged in user
 * @returns full name
 */
export function getCurrentUsersName(): string {
  return appUserSlice.getUsersName(reduxStore.store.getState());
}

/**
 * Get the email address of the current logged in user
 * @returns email address
 */
export function getCurrentUsersEmailAddress(): string {
  return appUserSlice.getUsersEmailAddress(reduxStore.store.getState());
}

/**
 * Check if the current user has verified their email address
 * @returns true if verified, false otherwise
 */
export function hasCurrentUserVerifiedTheirEmailAddress(): boolean {
  return appUserSlice.userHasVerifiedTheirEmailAddress(
    reduxStore.store.getState()
  );
}
/**
 * Utility helper function to return the corresponding UserAccessGroups enum.
 * If the group array passed in has multiple UserAccessGroups in, the first match item will be returned
 * Used by the appUserslice
 *
 * @param groups string array of groups as returned from cognito
 * @returns UserAccessGroups mapping
 */
export function getAccessLevelFromGroups(groups: string[]): UAG {
  const accessGroups = Object.keys(UAG);
  let found = UAG.NONE;

  for (const index in accessGroups) {
    //const group = accessGroups[index];
    if (groups.includes(Object.values(UAG)[index])) {
      found = Object.values(UAG)[index];
      break;
    }
  }
  return found;
}

export async function getUser(username: string): Promise<iUser | null> {
  const apiName = 'AdminQueries';
  const path = '/getUser';

  const myInit: { [key: string]: unknown } = {
    queryStringParameters: {
      username: username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Amplify.Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  let user: iUser | null = null;
  try {
    const response = await Amplify.API.get(apiName, path, myInit);

    const apiResponse = response as {
      Username: string;
      UserAttributes: [{ Name: string; Value: string }];
    };

    user = {
      username: response.Username,
      emailVerified:
        getCognitoAttribute(apiResponse.UserAttributes, 'email_verified') ===
        'true',
      name: getCognitoAttribute(apiResponse.UserAttributes, 'name'),
      email: getCognitoAttribute(apiResponse.UserAttributes, 'email'),
      cognitoId: getCognitoAttribute(
        apiResponse.UserAttributes,
        'custom:cognitoIdentityId'
      ),
      userConfig: getUserConfigFromAttributes(apiResponse.UserAttributes),
    };
    logger.info('userUtils.getUser', {
      username: username,
      detail: user,
    });

    console.info('Get user:', user);
  } catch (e) {
    console.info('Failed to get username: ' + username + ' error:', e);
    logger.error('userUtils.getUser.error', {
      username: username,
      error: e,
    });
  }
  return user;
}

/**
 * Get user access group
 * @returns access level, one of UserAccessGroups
 */
export function getCurrentUserAccessLevel(): UAG {
  const groups = appUserSlice.getUserGroups(reduxStore.store.getState());
  const al = getAccessLevelFromGroups(groups);

  if (al === UAG.ADMIN || al === UAG.ELITE) {
    const override = localStorage.getItem(
      LocalStore.teLocalStorageItems.E_LS_KEY_OVERRIDE_USER_LEVEL
    );

    if (override) {
      return override as UAG;
    }
  }

  return al;
}

/**
 * Get groups the current user is in
 * @returns a list of groups that the current user is in
 */
export function getCurrentUsersGroups(): string[] {
  return appUserSlice.getUserGroups(reduxStore.store.getState());
}

/** check that the user is equal to or greater than the minimum access level required
 * returns true if the user meets the minimum level
 * ADMIN = 'admin',
 * ELITE = 'elite',
 * TECHNICAL = 'technical',
 * MAINTENANCE = 'maintenance',
 * MANAGER = 'manager',
 * BASIC = 'basic',
 * NONE = 'none',
 */
export function minimumAccessLevel(minRequired: UAG): boolean {
  const userLevel = getCurrentUserAccessLevel();
  switch (minRequired) {
    case UAG.BASIC:
      return userLevel != UAG.NONE; // all levels other than none meet this
      break;
    case UAG.MANAGER:
      return !(userLevel == UAG.NONE || userLevel == UAG.BASIC); // all levels other than none or basic meet this
      break;
    case UAG.MAINTENANCE:
      return (
        userLevel == UAG.MAINTENANCE ||
        userLevel == UAG.TECHNICAL ||
        userLevel == UAG.ELITE ||
        userLevel == UAG.ADMIN
      ); // all levels other than none, basic, manager meet this
      break;
    case UAG.TECHNICAL:
      return (
        userLevel == UAG.TECHNICAL ||
        userLevel == UAG.ELITE ||
        userLevel == UAG.ADMIN
      ); // levels technical, elite, or admin
      break;
    case UAG.ELITE:
      return userLevel == UAG.ELITE || userLevel == UAG.ADMIN; // levels technical, elite, or admin
      break;
    case UAG.ADMIN:
      return userLevel == UAG.ADMIN;
    default:
      return false;
  }
}

/**
 * Get a list of sites a user has access to
 * @returns a list of groups that the current user is in
 */
export function getSitesFromGroups(groups: string[]): string[] {
  const userGroups: string[] = [];

  const prefix = 'useraccessgroup_';
  groups.forEach((group) => {
    if (group.startsWith(prefix)) {
      const fields = group.split('_');
      userGroups.push(fields[1]);
    }
  });

  return userGroups;
}

/**
 * Get a list of sites the user has access to
 * @returns a list of groups that the current user is in
 */
export function getCurrentUsersSites(): string[] {
  const groups = appUserSlice.getUserGroups(reduxStore.store.getState());
  return getSitesFromGroups(groups);
}

/**
 * Get a cognito username of the current user
 * @returns cognito id
 */
export function getCurrentCognitoUsername(): string {
  return appUserSlice.getUserId(reduxStore.store.getState());
}

async function clearUsersAccessRoles(cognitoUsername: string): Promise<void> {
  const accessGroups = Object.keys(UAG);

  const userGroups = await listGroupsForUser(cognitoUsername);

  logger.info('userUtils.clearUsersAccessRoles', {
    cognitoUsername: cognitoUsername,
  });

  if (userGroups) {
    for (const index in accessGroups) {
      const found = userGroups.find(
        (element) => element === Object.values(UAG)[index]
      );
      if (found) {
        console.info('Removing from ', Object.values(UAG)[index]);
        await removeUserFromGroup(cognitoUsername, Object.values(UAG)[index]);
      }
    }
  }
}

export async function setUserAccessRights(
  cognitousername: string,
  accessgroup: UAG
): Promise<void> {
  logger.info('userUtils.setUserAccessRights', {
    cognitoUsername: cognitousername,
    accessgroup: accessgroup,
  });

  await clearUsersAccessRoles(cognitousername);
  await addUserToGroup(cognitousername, accessgroup);
}

export async function updateUserConfig(
  cognitousername: string,
  value: iUserConfig
): Promise<boolean> {
  let status = false;

  //console.info('updateUserConfig:', value);

  logger.info('userUtils.updateUserConfig', {
    cognitoUsername: cognitousername,
    detail: value,
  });

  try {
    await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.updateUserAttribute, {
        username: cognitousername,
        key: 'custom:userconfigjson',
        value: JSON.stringify(value),
      })
    );

    status = true;
  } catch (e) {
    logger.error('userUtils.updateUserConfig.error', {
      cognitoUsername: cognitousername,
      detail: value,
      error: e,
    });
    console.error(
      'Failed to update user config for :' + cognitousername + ' error:',
      e
    );
  }

  return status;
}

export function getUserConfig_currentUser(attributes: unknown): iUserConfig {
  const configString = _.get(attributes, 'custom:userconfigjson', '');
  const apiAttributes: iCognitoUserAttribute[] = [
    {
      Name: 'custom:userconfigjson',
      Value: configString,
    },
  ];

  return getUserConfigFromAttributes(apiAttributes);
}

export async function logout(): Promise<void> {
  await Amplify.Auth.signOut();
  window.location.href = '/';
  setTimeout(() => window.location.reload(), 100);
}

export async function createUser(
  fullname: string,
  email: string,
  password: string
): Promise<{ success: boolean; error: string; username: string }> {
  const response = { success: false, error: '', username: '' };

  //console.info('updateUserConfig:', value);

  logger.info('userUtils.createUser', {
    detail: { fullname: fullname, email: email, password: password },
  });

  try {
    const graphqlResponse = (await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.createUser, {
        fullname: fullname,
        email: email,
        password: password,
      })
    )) as { data: { createUser: string } };

    console.info('createUser response:response:', graphqlResponse);

    const status = JSON.parse(graphqlResponse.data.createUser) as {
      success: boolean;
      error: string;
      username: string;
    };

    return status;
  } catch (e) {
    logger.error('userUtils.createUser.error', {
      error: e,
    });
  }

  return response;
}

export async function deleteUser(
  cognitoUserName: string
): Promise<{ success: boolean; error: string; username: string }> {
  const response = { success: false, error: '', username: '' };

  logger.info('userUtils.deleteUser', {
    detail: { cognitoUserName: cognitoUserName },
  });

  try {
    const graphqlResponse = (await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.deleteUser, {
        username: cognitoUserName,
      })
    )) as { data: { deleteUser: string } };

    console.info('deleteUser response:response:', graphqlResponse);

    const status = JSON.parse(graphqlResponse.data.deleteUser) as {
      success: boolean;
      error: string;
      username: string;
    };

    return status;
  } catch (e) {
    logger.error('userUtils.deleteUser.error', {
      error: e,
    });
  }

  return response;
}

export async function setUserPassword(
  cognitoUserName: string,
  password: string
): Promise<{ success: boolean; error: string; username: string }> {
  const response = { success: false, error: '', username: '' };

  logger.info('userUtils.setUserPassword', {
    detail: { cognitoUserName: cognitoUserName, password: password },
  });

  try {
    const graphqlResponse = (await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.setUserPasswd, {
        username: cognitoUserName,
        password: password,
        permanent: false,
      })
    )) as { data: { setUserPasswd: string } };

    console.info('setUserPassword response:response:', graphqlResponse);

    const status = JSON.parse(graphqlResponse.data.setUserPasswd) as {
      success: boolean;
      error: string;
      username: string;
    };

    return status;
  } catch (e) {
    console.info('setUserPassword error:', e);
    logger.error('userUtils.setUserPasswd.error', {
      error: e,
    });
  }

  return response;
}

/**
 * Send a email to users.  This API will only be actioned for Admin or Elite users
 * @param to Comma separated list of recipients email addresses that will appear on the To: field
 * @param bcc Comma separated list of recipients email addresses that will appear on the Bcc: field
 * @param subject The subject of the email
 * @param text  The plaintext version of the message 
 * @param html  The HTML version of the message 

 * @returns 
 */
export async function sendEmail(
  to: string,
  bcc: string,
  subject: string,
  text: string,
  html: string
): Promise<boolean> {
  logger.info('userUtils.sendEmail', {
    detail: { to, bcc, subject, text, html },
  });

  try {
    const graphqlResponse = (await Amplify.API.graphql(
      Amplify.graphqlOperation(mutations.sendEmail, {
        to,
        bcc,
        subject,
        text,
        html,
      })
    )) as { data: { sendEmail: string } };

    console.info('sendEmail response:response:', graphqlResponse);

    return true;
  } catch (e) {
    console.info('setUserPassword error:', e);
    logger.error('userUtils.setUserPassword.error', {
      error: e,
    });
  }

  return false;
}

/**
 * The cognito attributes for a logged in user are stored in the session and are never updated until
 * the user logs out and back in again.  The function does a refresh to get around this
 */
export async function getCurrentUserConfigAttributes(): Promise<iUserConfig> {
  const session = await Auth.currentSession();
  const user = await Auth.currentAuthenticatedUser();
  const refreshToken = session.getRefreshToken();

  return new Promise((resolve, reject) => {
    return user.refreshSession(
      refreshToken,
      async (err: unknown, data: unknown) => {
        if (err) {
          reject();
        } else {
          const config = getUserConfig_currentUser({
            'custom:userconfigjson': _.get(
              data,
              'idToken.payload.custom:userconfigjson',
              ''
            ),
          });

          await reduxStore.store.dispatch(
            appUserSlice.setCognitoUserConfiguration(config)
          );
          resolve(config);
        }
      }
    );
  });
}

export async function setCurrentUserConfigAttributes(
  config: iUserConfig
): Promise<void> {
  const user = await Auth.currentAuthenticatedUser();

  await Auth.updateUserAttributes(user, {
    'custom:userconfigjson': JSON.stringify(config),
  });

  await reduxStore.store.dispatch(
    appUserSlice.setCognitoUserConfiguration(config)
  );
}
