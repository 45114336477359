import {
  IoTMessageHandler,
  Message,
  MessageCallbackFunction,
} from './IotMessageHandler';
import { ZenObservable } from 'zen-observable-ts';
import * as IOTResponse from 'common/IoT/IoTResponseEvent';
import logger from 'common/logger';
import * as EventDispatcher from '../../store/eventDispatcher';

const IotMessageHandler = IoTMessageHandler.Instance;
export const defaultTimeout = 5000;

export interface ResponseCallbackDescriptor {
  transID: number;
  type: number;
  opcode: number;
  timeout: NodeJS.Timeout;
  processCb: (message: Message) => void;
  processCbScope: unknown;
  ignoreTransactionId?: boolean;
}

interface CallbackDescriptor {
  type: number;
  callback: MessageCallbackFunction;
  callbackScope: unknown;
}

export class SCUIoTMessaging {
  subscription: {
    serial: string;
    subscription: ZenObservable.Subscription;
  } | null = null;
  registeredCallbacks: CallbackDescriptor[] = [];
  responseCallbacks: ResponseCallbackDescriptor[] = [];
  SCUSerialNumber = '';
  subscriptionErrorCount = 0;

  private static _Instance: SCUIoTMessaging;
  public static get Instance(): SCUIoTMessaging {
    if (SCUIoTMessaging._Instance) {
      return SCUIoTMessaging._Instance;
    } else {
      return new SCUIoTMessaging();
    }
  }

  constructor() {
    if (SCUIoTMessaging._Instance) {
      return SCUIoTMessaging._Instance;
    }
    SCUIoTMessaging._Instance = this;
  }

  /**
   * send a messages to the current SCU
   * @param messageType Message type
   * @param messageOpcode Message opcode
   * @param rplyopcode Use to tell the SCU the reply topic, not always used and can be undefined. If undefined no response event will be setup
   * @param transactionId Allow the upper layers to tie a response to a request
   * @param msgObject Application message object specific to the messageType and messageOpcode
   * @param timeout Time to wait for a reply until a timeout is event is generted. If set to 0 no response will be waited for
   * @param responseTopic response event topic to emit for a timeout or response
   * @param userData Application specific data passed in the emitted event
   */
  sendMessage = async (
    messageType: number,
    messageOpcode: number,
    rplyopcode: number | undefined,
    transactionId: number,
    msgObject: unknown,
    timeout: number,
    responseTopic: string,
    userData: unknown,
    ignoreResponseTransactionId?: boolean
  ): Promise<IOTResponse.iIotResponseEventDetail> => {
    const promise: Promise<IOTResponse.iIotResponseEventDetail> = new Promise(
      (resolve, reject) => {
        if (this.SCUSerialNumber !== '') {
          let rplyTopic = '';
          let responseRegistered = false;
          if (rplyopcode !== undefined) {
            rplyTopic = `TX/msg/${messageType}/${rplyopcode}`;
          }

          if (rplyopcode != undefined && timeout > 0) {
            const entry: ResponseCallbackDescriptor = {
              transID: transactionId,
              type: messageType,
              opcode: rplyopcode,
              ignoreTransactionId: ignoreResponseTransactionId,
              timeout: setTimeout(() => {
                const newArray = [];
                for (const index in this.responseCallbacks) {
                  const entry = this.responseCallbacks[index];
                  if (
                    entry.transID != transactionId ||
                    entry.type != messageType ||
                    entry.opcode != rplyopcode
                  ) {
                    newArray.push(entry);
                  }
                }
                this.responseCallbacks = newArray;

                const detail: IOTResponse.iIotResponseEventDetail = {
                  transId: transactionId,
                  topic: responseTopic,
                  userData: userData,
                  msgHeader: null,
                };

                logger.info('IoT.messaging.timeout', {
                  iotDetail: detail,
                });

                IOTResponse.emitEvent(
                  responseTopic,
                  IOTResponse.IoTState.TIMEOUT,
                  detail
                );

                reject('TIMEOUT');
              }, timeout),
              processCb: function (message: Message) {
                const detail: IOTResponse.iIotResponseEventDetail = {
                  transId: transactionId,
                  topic: responseTopic,
                  userData: userData,
                  msgHeader: message,
                };

                logger.info('IoT.messaging.response', {
                  iotDetail: detail,
                });

                IOTResponse.emitEvent(
                  responseTopic,
                  IOTResponse.IoTState.RESPONSE,
                  detail
                );

                resolve(detail);
              },
              processCbScope: this,
            };

            this.registerResponseWait(entry);
            responseRegistered = true;
          }

          // Send the message after any response waits have been registered
          // otherwise it is possible to miss the response

          logger.info(`IoT.messaging.${responseTopic}`, {
            iotDetail: {
              scuSerial: this.SCUSerialNumber,
              messageType: messageType,
              messageOpcode: messageOpcode,
              rplyTopic: rplyTopic,
              transactionId: transactionId,
              msgObject: msgObject,
            },
          });

          IotMessageHandler.publish(
            this.SCUSerialNumber,
            messageType,
            messageOpcode,
            rplyTopic,
            transactionId,
            msgObject
          );

          if (!responseRegistered) {
            const detail: IOTResponse.iIotResponseEventDetail = {
              transId: transactionId,
              topic: responseTopic,
              userData: userData,
              msgHeader: null,
            };
            resolve(detail);
          }
        } else {
          console.error('No SCUSerialNumber set');
          reject('SCU_NOT_SELECTED');
        }
      }
    );

    return promise;
  };

  /*
   * @desc Register for a message type
   * @param {Int} message type of initerest
   * @param {function} message eCallback:
   */
  registerMessageTypeCallback(
    messageType: number,
    callback: MessageCallbackFunction,
    callbackScope: unknown
  ): number {
    const length = this.registeredCallbacks.push({
      type: messageType,
      callback: callback,
      callbackScope: callbackScope,
    });

    return length - 1;
  }

  /*
   * @desc unRegister for a message type
   * @param {Int} message type of initerest
   * @param {function} message eCallback
   * @param {callbackScope} function scope
   */
  unRegisterMessageTypeCallback(index: number): void {
    if (index >= 0 && index < this.registeredCallbacks.length) {
      this.registeredCallbacks.splice(index, 1);
    }
  }

  /*
   * @desc Alows incoming messages to be inspected and on a matching message calls the specified function
   */
  registerResponseWait(waitDescription: ResponseCallbackDescriptor): void {
    this.responseCallbacks.push(waitDescription);
  }

  processSCUMessage(message: Message): void {
    if (this.subscriptionErrorCount > 0) {
      EventDispatcher.emitEvent(
        EventDispatcher.systemEventTopics.IOT,
        EventDispatcher.systemEventStates.ONLINE,
        null,
        true
      );
    }

    this.subscriptionErrorCount = 0;

    logger.debug('SCU.message.got', message);
    for (const index in this.registeredCallbacks) {
      const entry = this.registeredCallbacks[index];
      if (entry.type == message.type) {
        entry.callback.call(entry.callbackScope, message);
      }
    }

    const newArray = [];
    for (const index in this.responseCallbacks) {
      const entry = this.responseCallbacks[index];
      //console.info('Response entry:', entry);

      if (
        ((entry.ignoreTransactionId && entry.ignoreTransactionId === true) ||
          entry.transID == message.transid) &&
        entry.type == message.type &&
        entry.opcode == message.opcode
      ) {
        //console.info('Response entry match:', entry);
        if (entry.timeout) {
          clearTimeout(entry.timeout);
        }
        entry.processCb.call(entry.processCbScope, message);
      } else {
        newArray.push(entry);
      }
    }
    this.responseCallbacks = newArray;
  }

  subscriptionDisconnected(): void {
    console.error(`Subscription for ${this.SCUSerialNumber} disconnected`);
  }

  subscriptionError(error: unknown): void {
    console.error(
      `Subscription for ${this.SCUSerialNumber} error count ${this.subscriptionErrorCount}`
    );

    console.error(error);

    logger.info('IoT.messaging.subscriptionError', {
      SCUSerialNumber: this.SCUSerialNumber,
      errorCount: this.subscriptionErrorCount,
    });

    this.subscriptionErrorCount++;

    if (this.subscriptionErrorCount < 10) {
      setTimeout(function () {
        SCUIoTMessaging.Instance.subscribe(
          SCUIoTMessaging.Instance.SCUSerialNumber
        );
      }, 1000);
    } else if (
      this.subscriptionErrorCount >= 10 &&
      this.subscriptionErrorCount < 70
    ) {
      setTimeout(function () {
        SCUIoTMessaging.Instance.subscribe(
          SCUIoTMessaging.Instance.SCUSerialNumber
        );
      }, 60000);
    }

    if (this.subscriptionErrorCount == 2) {
      EventDispatcher.emitEvent(
        EventDispatcher.systemEventTopics.IOT,
        EventDispatcher.systemEventStates.OFFLINE,
        null,
        true
      );
    }
  }

  subscribe(serialNumber: string): void {
    const sub = IotMessageHandler.subscribe(
      serialNumber,
      this,
      this.processSCUMessage,
      this.subscriptionDisconnected,
      this.subscriptionError
    );

    this.subscription = { serial: serialNumber, subscription: sub };
  }

  /*
  Function block to unsubscribe from the previous SCU
  and subscribe to the new SCU
  */
  setSCUSerialNumber(newSCUSerialNumber: string): void {
    if (newSCUSerialNumber !== '') {
      this.SCUSerialNumber = newSCUSerialNumber;
    }
    console.info('SCU changed to serial:', this.SCUSerialNumber);

    let subScribe = true;
    if (
      this.subscription &&
      this.SCUSerialNumber === this.subscription.serial
    ) {
      console.info('Already subscribed, not rescribing:', this.SCUSerialNumber);
      subScribe = false;
    } else if (
      this.subscription &&
      this.SCUSerialNumber !== this.subscription.serial
    ) {
      this.subscriptionErrorCount = 0;
      console.info('Unsubscribed from the old SCU:', this.subscription.serial);
      IotMessageHandler.unsubscribe(this.subscription.subscription);
      this.subscription = null;
    }

    if (
      this.SCUSerialNumber &&
      this.SCUSerialNumber !== '' &&
      subScribe === true
    ) {
      this.subscribe(this.SCUSerialNumber);
    }
  }
}

export default SCUIoTMessaging;
