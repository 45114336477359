import React, { useState, useEffect } from 'react';
import useStateRef from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps } from 'components/dashboard/tile';
import { Box, Text, Form, Button } from 'grommet';
//import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';

//import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
import { iField } from 'components/forms/formFields';
//import { Manifest } from 'components/installation/manifest';
import { getManifestInstance } from 'components/installation/manifestUtils';
import {
  AlignedFormFields,
  iSelectKeyValue,
} from 'components/forms/formFields';
import { Device } from 'components/installation/device';
import {
  teVRUParameters,
  teHandsetParameters,
} from 'common/IoT/DeviceParameters';

import {
  getDeviceParameter,
  setDeviceParameter,
} from 'common/IoT/Helpers/DeviceManagement';

interface iParameterForm {
  Device: number;
  Parameter: number | undefined;
  Value: string;
}

const blankparameter: iParameterForm = {
  Device: 0,
  Parameter: undefined,
  Value: '',
};

const DeviceE2Configuration = (): JSX.Element => {
  //const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');
  const [vrus, setVRUs] = useState<iSelectKeyValue[]>([]);
  const [HSs, setHSs] = useState<iSelectKeyValue[]>([]);
  const [vruParameters, setVRUParameters] = useState<iSelectKeyValue[]>([]);
  const [hsParameters, setHSParameters] = useState<iSelectKeyValue[]>([]);

  const [VRUParameter, setVRUParameter, VRUParameterRef] =
    useStateRef(blankparameter);
  const [HSParameter, setHSParameter, HSParameterRef] =
    useStateRef(blankparameter);

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();

  const loadData = () => {
    const m = getManifestInstance();
    let devices = m.devices
      .filter((d) => Device.isVRU(d))
      .map((d) => {
        const field: iSelectKeyValue = {
          key: d.MacAddress,
          value: m.getDeviceNameByMac(d.MacAddress),
        };

        return field;
      });
    setVRUs(devices);

    devices = m.devices
      .filter((d) => Device.isEvolutionPlusHandset(d))
      .map((d) => {
        const field: iSelectKeyValue = {
          key: d.MacAddress,
          value: m.getDeviceNameByMac(d.MacAddress),
        };

        return field;
      });
    setHSs(devices);

    let keys: iSelectKeyValue[] = [];
    _.forIn(teVRUParameters, (key, value) => {
      if (isNaN(Number(value))) {
        keys.push({ key: key, value: value });
      }
    });
    setVRUParameters(keys);

    keys = [];
    _.forIn(teHandsetParameters, (key, value) => {
      if (isNaN(Number(value))) {
        keys.push({ key: key, value: value });
        console.info(`Key:${key}  v:${value}`);
      }
    });
    setHSParameters(keys);
  };

  const get = async (getParam: iParameterForm): Promise<string | undefined> => {
    if (getParam.Parameter === undefined) return;

    const value = await getDeviceParameter(
      getParam.Device,
      getParam.Parameter,
      setDialogueProps
    );

    console.info(
      `Got mac:0x${getParam.Device.toString(16)} param:${
        getParam.Parameter
      } = ${value}`
    );
    return value;
  };

  const save = async (setParam: iParameterForm) => {
    if (setParam.Parameter === undefined) return;

    const ok = await setDeviceParameter(
      setParam.Device,
      setParam.Parameter,
      setParam.Value,
      setDialogueProps
    );

    console.info(
      `Set mac:0x${setParam.Device.toString(16)} param:${
        setParam.Parameter
      }  OK=${ok}`
    );
  };

  const events: iTileEvent[] = [
    // {
    //   topic: eventDispatcher.systemEventTopics.MANIFEST,
    //   state: eventDispatcher.systemEventStates.UPDATED,
    //   callback: () => {
    //     loadData();
    //   },
    //   executeOnStartup: true,
    // },
  ];

  const fieldForm = (
    devices: iSelectKeyValue[],
    parameters: iSelectKeyValue[]
  ): iField[] => {
    //const SCU5110 = m.isInSoftwareVersionRange({ min: '5.11.0' });

    return [
      {
        label: 'Device',
        id: 'Device',
        type: 'select',
        options: devices,
        column: 1,
        readOnly: false,
      },

      {
        label: 'Parameter',
        id: 'Parameter',
        type: 'select',
        options: parameters,
        column: 1,
        readOnly: false,
      },
      {
        label: 'Value',
        id: `Value`,
        type: 'text',

        //validate: /^[0-9]+$/,
        column: 1,
      },
    ];
  };

  const ContextButtons = <Box direction="row" gap="large"></Box>;

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Refresh',
          icon: <Icons.Refresh size="medium" />,
          onClick: () => {
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const doVRUGet = async () => {
    const value = await get(VRUParameterRef.current);
    console.info('got value:', value);
    const copy = _.cloneDeep(VRUParameterRef.current);
    if (value != undefined) {
      copy.Value = value;
      setVRUParameter(copy);
    } else {
      copy.Value = '';
      setVRUParameter(copy);
    }
  };

  const doHSGet = async () => {
    const value = await get(HSParameterRef.current);
    console.info('got value:', value);
    const copy = _.cloneDeep(HSParameterRef.current);
    if (value != undefined) {
      copy.Value = value;
      setHSParameter(copy);
    } else {
      copy.Value = '';
      setVRUParameter(copy);
    }
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title="Device E2 Configuration"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <Box direction="column">
        <Form
          value={VRUParameter}
          onChange={async (nextValue) => {
            let doget = false;
            console.info(nextValue);
            if (
              nextValue.Parameter != VRUParameterRef.current.Parameter &&
              nextValue.Device != 0
            ) {
              doget = true;
            }
            if (nextValue) setVRUParameter(nextValue);

            if (doget) {
              await doVRUGet();
            }
          }}
        >
          <Box margin="10px" direction="row" width={'50%'}>
            <Text>VRU Configuration</Text>
            <AlignedFormFields displayfields={fieldForm(vrus, vruParameters)} />
            <Button
              plain={false}
              size="small"
              label="Get"
              onClick={async () => {
                await doVRUGet();
              }}
            ></Button>
            <Button
              plain={false}
              size="small"
              label="Save"
              onClick={async () => {
                await save(VRUParameterRef.current);
              }}
            ></Button>
          </Box>
        </Form>

        <Form
          value={HSParameter}
          onChange={async (nextValue) => {
            let doget = false;
            console.info(nextValue);
            if (
              nextValue.Parameter != HSParameterRef.current.Parameter &&
              nextValue.Device != 0
            ) {
              doget = true;
            }

            if (nextValue) setHSParameter(nextValue);

            if (doget) {
              await doHSGet();
            }
          }}
        >
          <Box margin="10px" direction="row" width={'50%'}>
            <Text>HS Configuration</Text>
            <AlignedFormFields displayfields={fieldForm(HSs, hsParameters)} />
            <Button
              plain={false}
              size="small"
              label="Get"
              onClick={async () => {
                await doHSGet();
              }}
            ></Button>
            <Button
              plain={false}
              size="small"
              label="Save"
              onClick={async () => {
                await save(HSParameterRef.current);
              }}
            ></Button>
          </Box>
        </Form>
      </Box>
    </Tile>
  );
};

export default DeviceE2Configuration;
