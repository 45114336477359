import React, { useState } from 'react';
import useStateRef from 'react-usestateref';
import Tile, {
  iTileEvent,
  iMenuProps,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Button } from 'grommet';

import { Manifest, teErrorCode } from 'components/installation/manifest';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import TablePaged from 'components/tables/TablePaged';
import { Column, Cell } from 'react-table';
import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
import SelectCell from 'components/tables/cellPresentationComponents/selectCell';
import { useWinstonLogger } from 'winston-react';
import * as ManifestTypes from 'types/manifest-types';

const manifest = new Manifest();

interface iTableData extends Partial<ManifestTypes.iDoorsAndOtherInteraces> {
  ID: number;
  Name: string;
  CameraId: number;
  Url: string;
}

const blankTableEntry: iTableData = {
  ID: 0,
  Name: '',
  CameraId: 0,
  Url: '',
};

let _allData: iTableData[] = [blankTableEntry];
let _originalData: iTableData[] = [];

const AssignDoorCamera = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [renderCount, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useStateRef<number[]>([]);
  const [, setCameraList, cameraListRef] = useStateRef<
    { value: number; text: string }[]
  >([]);

  const loadData = () => {
    _allData = _.cloneDeep(
      manifest.getDoors().map((item): iTableData => {
        const cameraConfig = manifest.cameraConfigs.find(
          (e) => e.ID === item.CameraId
        );

        const tableData: iTableData = {
          ID: item.ID,
          Name: item.Name,
          CameraId: item.CameraId,
          Url: cameraConfig ? cameraConfig.Url : '',
        };
        return tableData;
      })
    );

    _originalData = _.cloneDeep(_allData);
    setChanges([]);

    const _cameraList: { value: number; text: string }[] = [
      // SCU uses -1 to set the camera as unassigned
      { value: -1, text: `Not Assigned` },
    ];
    for (const index in manifest.cameraConfigs) {
      const camera = manifest.cameraConfigs[index];

      if (camera.Url && camera.Url.length > 10) {
        _cameraList.push({ value: camera.ID, text: `Camera ${camera.ID + 1}` });
      }
    }

    setCameraList(_cameraList);

    setRenderCount((old) => old + 1);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
  ];

  /**
   * @param id    input number
   * @param property field to change
   * @param value new value
   */
  const setProperty = (id: number, property: string, value: unknown) => {
    const record = _allData.find((e) => e.ID == id);
    if (record) {
      const originalRecord = _originalData.find((e) => e.ID == id);

      const setIt = () => {
        if (!changesRef.current.includes(id)) {
          changesRef.current.push(id);

          const cameraConfig = manifest.cameraConfigs.find(
            (e) => e.ID === record.CameraId
          );

          _.set(record, 'Url', cameraConfig ? cameraConfig.Url : '');

          setRenderCount((old) => old + 1);
        }
      };

      _.set(record, property, value);
      setRenderCount((old) => old + 1);

      if (originalRecord) {
        if (_.get(originalRecord, property, 0) != value) {
          setIt();
        } else {
          _.pull(changesRef.current, id);
          setRenderCount((old) => old + 1);
        }
      }
    }
  };

  function getProperty(id: number, property: string): unknown {
    let input = _allData.find((e) => e.ID == id);
    if (input === undefined) {
      input = blankTableEntry;
    }
    const value = _.get(input, property, 0);
    return value;
  }

  /**
   * The aim of all the cell components is that they do not render unnecessarily
   * All the cell components use React.memo to memorise the component.
   *
   * React.memo will only re-generate the compoent if a prop changes therefore we pass a reRenderValue prop
   * to the component to allow us to signal to React.memo that it needs to be re-rendered.
   *
   * The components use callback to set/get their data and not state varibles
   */
  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        disableFilters: true,
        id: 'ID',
        Header: 'Door ID',
        accessor: 'ID',
        width: '10px',
      },
      {
        disableFilters: true,
        id: 'Name',
        Header: 'Door Name',
        accessor: 'Name',
      },
      {
        disableFilters: true,
        id: 'CameraId',
        Header: 'Associated Camera',
        accessor: (row) => row.CameraId + 1,
        Cell: (props: Cell<iTableData>) => (
          <SelectCell
            id={props.row.original.ID}
            reRenderValue={props.row.original.CameraId}
            valueProperty={`CameraId`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={cameraListRef.current}
          />
        ),
      },
      {
        disableFilters: true,
        id: 'Url',
        Header: 'Camera URL',
        accessor: (row) => {
          return row.Url ? row.Url : '';
        },
      },
    ],
    [renderCount] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : [],
      pageSize: 25,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const save = () => {
    logger.info('AssignDoorCamera.save', {
      changedIDs: changesRef.current,
    });

    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _allData.find((e) => e.ID === id);
      const manifestRecord = manifest.deviceInterfaces.find((e) => e.ID === id);

      if (!stateRecord || !manifestRecord) continue;

      manifestRecord.CameraId = stateRecord.CameraId;
    }

    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setChanges([]);
    };

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
          setChanges([]);
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('AssignDoorCamera.saveFailed', {
            status: status,
            error: {},
          });
        }
        complete(str);
      })
      .catch((error) => {
        console.error('sys param save fail:', error);
        logger.info('AssignDoorCamera.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        complete(str);
      });
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? changes.length : false}
      tip="Save changes"
      disabled={changes.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('AssignDoorCamera.discardChanges');
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="Camera Association"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={_allData ? _allData : []}
          showCheckboxes={false}
          showControls={false}
        />
      </>
    </Tile>
  );
};

export default AssignDoorCamera;
