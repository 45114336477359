import React, { useState, useEffect } from 'react';
import PracticalSidebar from './practicalSidebar.tsx';
import InstallSidebarItems from 'components/sidebar/installSidebarItems.tsx';
import NavSidebarItems from 'components/sidebar/navSidebarItems.tsx';

import Header from './header';
import Footer from './footer';
import HelpHeader from './helpHeader';
import './layout.css';
import { Grommet, Box } from 'grommet';
import whtheme from './whtheme';
import tunstalltheme from './tunstalltheme';
import { showMobile } from 'common/deviceDetect';
import { getCurrentUserConfigAttributes } from 'common/userUtils';
import { useLocation } from 'react-router-dom';

// drag and drop
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BRAND_TUNSTALL, BRAND_WIRELESSHEALTH } from './brands';

const LoggedinLayout = ({ children }) => {
  const location = useLocation();
  const helpPage = location.pathname.startsWith('/help');
  //const darkMode = !helpPage; // standard pages dark, help page light
  const darkMode = false;
  const [brand, setBrand] = useState('');

  const mobileLayout = () => {
    return (
      <>
        <Box direction="row" pad="none" overflow="hidden">
          <Box width="100%" overflow="hidden" pad={{ left: 'small' }}>
            {children}
          </Box>
        </Box>
      </>
    );
  };

  const desktopLayout = () => {
    return (
      <>
        {/* standard non-help page  page layout */}
        {!helpPage && (
          <>
            <Header brand={brand} />
            <Box
              direction="row"
              pad={{ left: 'none', top: '1px', right: 'small' }}
              overflow="hidden"
            >
              <Box>
                <PracticalSidebar title="Menu" collapsible={true}>
                  <NavSidebarItems />
                  <InstallSidebarItems overflow="auto" />
                </PracticalSidebar>
              </Box>
              <Box width="100%" overflow="hidden" pad={{ left: 'large' }}>
                {children}
              </Box>
            </Box>
          </>
        )}

        {/* otherwise Help page layout */}
        {helpPage && (
          <>
            <HelpHeader />
            <Box direction="row" pad="none" overflow="hidden">
              <Box width="100%" overflow="hidden" pad={{ left: 'small' }}>
                {children}
              </Box>
            </Box>
          </>
        )}
        <Footer />
      </>
    );
  };

  const brandToTheme = () => {
    switch (brand) {
      case BRAND_WIRELESSHEALTH:
        return whtheme;

      case BRAND_TUNSTALL:
        return tunstalltheme;
    }
  };

  useEffect(() => {
    getCurrentUserConfigAttributes().then((cfg) => {
      if (cfg.brand && cfg.brand != '') {
        setBrand(cfg.brand);
      } else {
        setBrand(BRAND_WIRELESSHEALTH);
      }
      //setBrand(BRAND_WIRELESSHEALTH);
    });
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {brand != '' && (
        <Grommet
          theme={brandToTheme()}
          themeMode={darkMode ? 'dark' : 'light'}
          full
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {showMobile() && mobileLayout()}
          {!showMobile() && desktopLayout()}
        </Grommet>
      )}
    </DndProvider>
  );
};

export default LoggedinLayout;
