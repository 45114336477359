import {
  teCLOUD_MESSAGE_TYPES,
  teCLOUD_ERROR_CODES,
} from './IoTMessageDefines';

import {
  teCLOUD_MSG_TY_AUDIT,
  tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_REQ,
  tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_RES,
  tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_REQ,
  tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_RES,
} from './IoTMessageDefines_AUDIT';

import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';

export { teCLOUD_ERROR_CODES } from './IoTMessageDefines';
export { teCLOUD_REMOTECALL_EVENTS_STATUS_CODES } from './IoTMessageDefines_CALL';
export type { tsE_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT } from './IoTMessageDefines_CALL';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

const _setLastSeen = async (
  deviceid: number,
  username: string
): Promise<iIotResponseEventDetail> => {
  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_AUDIT;
  const opcode = teCLOUD_MSG_TY_AUDIT.E_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_AUDIT.E_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_RES;
  const msg: tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_REQ = {
    deviceid: deviceid,
    username: username,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    '_setLastSeen',
    undefined
  );
};

export const setLastSeen = async (
  deviceid: number,
  username: string
): Promise<teCLOUD_ERROR_CODES> => {
  let errorCode = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_TIMEOUT;

  const promise = new Promise<teCLOUD_ERROR_CODES>((resolve) => {
    (async () => {
      try {
        const resp = await _setLastSeen(deviceid, username);
        const scuRes = resp.msgHeader
          ?.msg as tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_RES;
        if (scuRes) {
          errorCode = scuRes.errorcode;
          console.info(`setLastSeen got response: errorCode:${errorCode}`);
        } else {
          console.error('setLastSeen no response');
        }
      } catch (error) {
        console.error('setLastSeen error:', error);
      }

      resolve(errorCode);
    })();
  });
  return promise;
};

const _setSpokenTo = async (
  deviceid: number,
  username: string
): Promise<iIotResponseEventDetail> => {
  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_AUDIT;
  const opcode = teCLOUD_MSG_TY_AUDIT.E_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_AUDIT.E_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_RES;
  const msg: tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_REQ = {
    deviceid: deviceid,
    username: username,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    '_setSpokenTo',
    undefined
  );
};

export const setSpokenTo = async (
  deviceid: number,
  username: string
): Promise<teCLOUD_ERROR_CODES> => {
  let errorCode = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_TIMEOUT;

  const promise = new Promise<teCLOUD_ERROR_CODES>((resolve) => {
    (async () => {
      try {
        const resp = await _setSpokenTo(deviceid, username);
        const scuRes = resp.msgHeader
          ?.msg as tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_RES;
        if (scuRes) {
          errorCode = scuRes.errorcode;
          console.info(`setSpokenTo got errorCode:${errorCode}`);
        } else {
          console.error('setSpokenTo no response');
        }
      } catch (error) {
        console.error('setSpokenTo error:', error);
      }

      resolve(errorCode);
    })();
  });
  return promise;
};
