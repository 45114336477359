import Tile, { iTileEvent } from 'components/dashboard/tile';
import { Text, Box, Select, Button } from 'grommet';
import React, { useState } from 'react';
import * as eventDispatcher from 'store/eventDispatcher';
import { manifest } from 'components/floorplan/site';
import * as IoTTest from 'common/IoT/IoTTest';
import { Room } from 'components/installation/room';

let nonEmptyGroups: string[] = [];
let selectedGroupID = -1;
let selectedTimeInSec = -1;

const getGroups = async () => {
  nonEmptyGroups = [];
  nonEmptyGroups.push('All Rooms');
  nonEmptyGroups.push('All Residents');
  const rg = await manifest.getResidentGroups();
  for (let index = 0; index < 30; index++) {
    if (manifest.groupHasRooms(index + 1)) {
      const push = rg ? rg.at(index)?.name : 'group ' + index.toString();
      nonEmptyGroups.push(String(push));
    }
  }
};

const getTimeInSec = (time: string) => {
  selectedTimeInSec = Number(time.split(' ')[0]) * 60;
};

const getGroupIDByName = async (name: string) => {
  const rg = await manifest.getResidentGroups();
  for (let index = 0; index < 30; index++) {
    if (rg ? rg[index].name == name : false) {
      selectedGroupID = rg ? rg[index].groupID : -1;
    }
  }
  if (name == 'All Rooms') {
    selectedGroupID = -2;
  }
  if (name == 'All Residents') {
    selectedGroupID = -3;
  }
};

const setTesting = (groupID: number, time: number) => {
  const epochNow = new Date(Date.now()).getTime() / 1000;
  console.log('testing -- ' + epochNow);
  manifest.getRoomsInAGroup(groupID).forEach(async (element: Room) => {
    console.log('testing -- ' + element.TestModeEnabledEPOCH);
    await IoTTest.residentTestMode(element.productManifest.tableID, true, time);
    console.log(
      `testing - room:${element.RoomNumber} deviceId:${element.ID} resident table id:${element.productManifest.tableID} time:${time}`
    );
  });
  if (groupID == -2) {
    manifest.rooms.forEach(async (element) => {
      await IoTTest.residentTestMode(
        element.productManifest.tableID,
        true,
        time
      );
      console.log(
        `testing - room:${element.RoomNumber} deviceId:${element.ID} resident table id:${element.productManifest.tableID} time:${time}`
      );
    });
  }
  if (groupID == -3) {
    manifest.rooms.forEach(async (element) => {
      if (element.isCommunal() == false) {
        await IoTTest.residentTestMode(
          element.productManifest.tableID,
          true,
          time
        );
        console.log(
          `testing - room:${element.RoomNumber} deviceId:${element.ID} resident table id:${element.productManifest.tableID} time:${time}`
        );
      }
    });
  }
};

const ServiceTestMode = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  function updateInfo() {
    getGroups();
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        updateInfo();
      },
      executeOnStartup: true,
    },
  ];

  return (
    <Tile
      title="Service Test Mode"
      eventListeners={events}
      setChildEvent={SetChildEvent}
    >
      <>
        <Box margin={{ top: '15px' }}>
          <Text>
            Service test mode allows rooms to be placed into test mode. When in
            test mode, all alarms will display and/or sound at the unit for a
            short duration. They will automatically cancel and will not go
            through as a call
          </Text>
        </Box>
        <Box width="100%" align="center">
          <Box direction="row" margin={{ top: '50px' }}>
            <Text margin={{ right: '10px' }} size="large">
              Select Rooms -
            </Text>
            <Select
              placeholder="Select a resident group..."
              labelKey="Description"
              valueKey={{ key: 'Group' }}
              options={nonEmptyGroups}
              value={selectedGroup}
              size="medium"
              onChange={({ option }) => {
                setSelectedGroup(option);
                getGroupIDByName(option);
              }}
            />
          </Box>
          <Box direction="row" margin={{ top: '50px' }}>
            <Text margin={{ right: '10px' }} size="large">
              Select Duration -
            </Text>
            <Select
              placeholder="Select test mode duration..."
              labelKey="Description"
              valueKey={{ key: 'Group' }}
              options={[
                '5 minutes',
                '10 minutes',
                '15 minutes',
                '20 minutes',
                '30 minutes',
                '40 minutes',
                '50 minutes',
                '60 minutes',
              ]}
              value={selectedTime}
              size="medium"
              onChange={({ option }) => {
                setSelectedTime(option);
                getTimeInSec(option);
              }}
            />
          </Box>
          <Button
            label="Enable Test Mode"
            margin={{ top: '30px' }}
            size="medium"
            onClick={() => {
              setTesting(selectedGroupID, selectedTimeInSec);
            }}
          ></Button>
        </Box>
      </>
    </Tile>
  );
};

export default ServiceTestMode;
