import React, { useEffect, useState } from 'react';
import Tile, { iTileEvent, contextButtonStyle } from '../../tile';
import { Form, FormField, Text, TextInput, Button, Box } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest, teErrorCode } from '../../../installation/manifest';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import * as Icons from 'grommet-icons';
import { useWinstonLogger } from 'winston-react';
import ScheduleEdit, {
  iScheduleCustomDataProps,
} from 'components/dashboard/commonWidgets/schedules/scheduleEdit';
import { teSCHEDULES_OBJECT_TYPES } from 'types/manifest-enums';
import { useDrop } from 'react-dnd';
import { DNDtypes } from 'types/dndtypes';
import { DoorAndDeviceInterface } from 'components/installation/doorAndDeviceInterface';
import useStateRef from 'react-usestateref';
import { Schedule } from 'components/installation/schedule';
import * as ManifestEnums from 'types/manifest-enums';

interface iConfig {
  ringTimeoutSeconds: number;
}

let unchangedConfig: iConfig | undefined = undefined;
const manifest = new Manifest();

const AccessPINs = (): JSX.Element => {
  const logger = useWinstonLogger();
  const configInit: iConfig = {
    ringTimeoutSeconds: 0,
  };

  const [config, setConfig] = useState<iConfig>(configInit);
  const [changes, setChanges, changesRef] = useStateRef(false);

  const [, SetChildEvent] = useState('');
  const [dataloaded, setDataloaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueText, setDialogueText] = useState('');
  const [doors, setDoors] = useState<DoorAndDeviceInterface[] | undefined>([]);
  const [schedules, setSchedules] = useState<Schedule[]>();

  function updateInfo() {
    if (manifest.systemParameters.length > 0) {
      const config: iConfig = configInit;
      const sp_RingTimeout = manifest.getSystemParameter(
        'DOOR_PANEL_RING_TIMEOUT'
      );
      if (sp_RingTimeout) {
        config.ringTimeoutSeconds = parseInt(sp_RingTimeout.Value, 10);
      }
    }

    unchangedConfig = _.cloneDeep(config);

    setConfig(config);
    if (!changes) {
      setDoors(manifest.getDoors());
      setSchedules(
        _.cloneDeep(
          manifest.getSchedulesByType(
            teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_TRADES_ACCESS
          )
        )
      );
    }
    setDataloaded(true);
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: updateInfo,
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: updateInfo,
      executeOnStartup: false,
    },
  ];

  function save(configToSave: iConfig): void {
    logger.info('AccessPINs.save', {
      config: configToSave,
    });

    const sp_RingTimeout = manifest.getSystemParameter(
      'DOOR_PANEL_RING_TIMEOUT'
    );

    if (sp_RingTimeout) {
      sp_RingTimeout.setFromNumber(configToSave.ringTimeoutSeconds);

      setDialogueText('Please wait.....');
      setSaving(true);
      setShowDialogue(true);

      const complete = (str: string) => {
        console.log('complete');
        setSaving(false);
        setChanges(false);
        setDialogueText(str);
      };

      let str = 'Save failed';
      manifest
        .save()
        .then((status) => {
          if (status === teErrorCode.E_OK) {
            str = 'Save Completed Successfully';
          } else if (status === teErrorCode.E_CONNECTION_FAIL) {
            str = 'Connection failed';
            logger.info('AccessPINs.saveFailed', {
              status: status,
              error: {},
            });
          }
          complete(str);
        })
        .catch((error) => {
          console.error('trades save fail:', error);
          logger.info('AccessPINs.saveFailed', {
            status: teErrorCode.E_ERROR,
            error: error,
          });
          complete(str);
        });
    }
  }

  const configChanged = () => {
    return !_.isEqual(unchangedConfig, config) || changesRef.current == true;
  };

  const waitProps: Wait.DialogueProps = {
    show: showDialogue,
    dialogueText: dialogueText,
    showSpinner: saving,
    showOkButton: !saving,
    okButtonPress: () => {
      setShowDialogue(false);
    },
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={configChanged() ? 'brand' : 'grey-4'}
      badge={configChanged()}
      tip="Save changes"
      disabled={configChanged() ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save(config);
      }}
    />
  );

  // const menuItems = React.useMemo<iMenuProps>(
  //   () => ({
  //     disabled: false,
  //     items: [
  //       {
  //         label: 'Discard Changes',
  //         icon: <Icons.Clear size="medium" />,
  //         onClick: () => {
  //           logger.info('AccessPINs.discardChanges');
  //           updateInfo();
  //         },
  //         disabled: false,
  //       },
  //     ],
  //   }),
  //   [] // eslint-disable-line react-hooks/exhaustive-deps
  // );

  const AssignPanels = (props: iScheduleCustomDataProps) => {
    const [{ isOver, canDrop }, drop] = useDrop({
      accept: DNDtypes.DOOR,
      drop: (item) => {
        console.log(item);
        // Add door panel to list
        const DeviceId = (item as { dragitem: DoorAndDeviceInterface }).dragitem
          .DeviceId;
        let p = props.data;
        if (!p) p = '';
        if (!p.includes(DeviceId.toString())) {
          if (p.length != 0) p = p + ',';
          p = p + DeviceId;
          props.setData(p);
          setChanges(true);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });
    const isActive = isOver && canDrop;

    // convert csv list to a door array
    const dps: DoorAndDeviceInterface[] = [];

    const a = props.data ? props.data.split(',') : [];
    a.forEach((i) => {
      const d = Number(i);
      const panel = doors?.find((f) => f.DeviceId == d);
      if (panel) dps.push(panel);
    });

    const removePanel = (d: number) => {
      let n = '';
      a.forEach((i) => {
        if (i != d.toString()) {
          if (n.length != 0) n = n + ',';
          n = n + i.toString();
        }
      });
      setChanges(true);
      props.setData(n);
    };

    const addAllPanels = () => {
      let n = '';
      manifest.getDoors().forEach((item) => {
        if (item.Type !== ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR) {
          if (n.length != 0) n = n + ',';
          n = n + item.DeviceId;
        }
      });
      setChanges(true);
      props.setData(n);
    };

    return (
      <div ref={drop}>
        <Box>
          <Text weight="bold">
            The following door entry panels should use this access PIN:
          </Text>
        </Box>

        <Box
          direction="row"
          height="180px"
          background={isActive ? 'white' : '#eee'}
          wrap={true}
        >
          {dps.map((m, index) => (
            <Box
              key={index}
              border="all"
              margin="3px"
              pad="3px"
              background="lightgreen"
              color="white"
              height="30px"
              round={{ size: '5px' }}
            >
              <Box direction="row">
                <Text weight="bold">{m.Name}</Text>
                <Box margin={{ top: '2px', left: '4px' }}>
                  <Icons.Close
                    size="16"
                    cursor="pointer"
                    onClick={() => removePanel(m.DeviceId)}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box direction="row">
          <Text>
            Drag and drop door panels from the left menu into the box above
            or....
          </Text>

          <Button
            margin={{ top: '-10px', left: '4px' }}
            label="Add All Panels"
            size="medium"
            plain={false}
            pad="4px"
            onClick={() => addAllPanels()}
          />
        </Box>
        <Box>
          <Text weight="bold">
            Note: only traditional doors panels are currently supported. IP
            panels will be supported soon.
          </Text>
        </Box>
      </div>
    );
  };

  const PinName = (props: iScheduleCustomDataProps) => {
    const [pinText, setPinText] = useState(props.data);
    return (
      <Box
        width="300px"
        margin={{ top: '4px', bottom: '10px' }}
        direction="row"
      >
        <Text truncate style={{ width: '120px' }}>
          PIN Name
        </Text>
        <TextInput
          size="medium"
          width="200px"
          value={pinText}
          style={{ padding: '6px' }}
          onChange={(event) => {
            setPinText(event.target.value);
          }}
          onBlur={(event) => props.setData(pinText)}
        />
      </Box>
    );
  };

  useEffect(() => {
    updateInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tile
      title="Door Access Configuration"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={waitProps}
      ContextButtons={ContextButtons}
      // menuProps={menuItems}
    >
      <>
        {dataloaded && (
          <>
            <Form
              validate="change"
              value={config}
              onChange={(nextValue) => {
                setConfig(nextValue);
              }}
            >
              <Box direction="column" pad="xsmall" justify="center">
                <Box direction="row" pad="xsmall" justify="center">
                  <ScheduleEdit
                    title="Access Entry Pins"
                    addNewText="Add New PIN and Schedule"
                    enabledAllWeekText="No Access Entry PINs Configured"
                    schedules={schedules ?? []}
                    save={() => setChanges(true)}
                    objectID={undefined}
                    objectIDLabel="Access PIN Code"
                    objectIDNewDefault={1234}
                    objectType={
                      teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_TRADES_ACCESS
                    }
                    data1={AssignPanels}
                    data2={PinName}
                    scheduleName={(m) => m.Data2}
                    showData2BeforeData1={true}
                    disabled={false}
                  />
                </Box>
                <Box direction="row" pad="xsmall" justify="center">
                  <FormField
                    name="ringTimeoutSeconds"
                    label="Ringing Timeout (Seconds)"
                    direction="row"
                  >
                    <Box width="110px">
                      <TextInput
                        name="ringTimeoutSeconds"
                        type="number"
                        // Set this style to make the time icon white
                        style={{ colorScheme: 'dark' }}
                        min={30}
                        max={300}
                      />
                    </Box>
                  </FormField>
                </Box>
              </Box>
            </Form>
          </>
        )}
      </>
    </Tile>
  );
};

export default AccessPINs;
