/** @module Installation */

import React from 'react';
import { invalidID, iRoutingEvent, iDevice } from 'types/manifest-types';
import { Device, teINPUT_METHOD } from './device';
import { Product } from 'components/floorplan/product';
import { teDEVICE_TYPES } from 'types/manifest-enums';

/** return true if the device type is gateway interface */
export const isGatewayInterface = (type: teDEVICE_TYPES): boolean => {
  if (
    type == teDEVICE_TYPES.DEVICE_TYPE_GATEWAY ||
    type == teDEVICE_TYPES.DEVICE_TYPE_AUDIO_ADAPTOR
  ) {
    return true;
  }
  return false;
};

export class GatewayInterface extends Device {
  ID = invalidID;
  Description = '';

  constructor(deviceValues: iDevice, routingEvents: iRoutingEvent[]) {
    super(deviceValues, routingEvents);
    this.ID = deviceValues.ID;

    this.Description = Product.getProductfromManifestDeviceType(this).name;

    this.InputAccessor = {
      Method: teINPUT_METHOD.INPUT_METHOD_NONE,
      RoutingEventTypes: [],
    };
  }

  generateChangeSQL(): { update: string; revert: string } {
    return super.generateChangeSQL();
  }

  async applyManifestChanges(): Promise<void> {
    return super.applyManifestChanges();
  }

  getIdentity(): string {
    return this.MacAddressHex.substring(this.MacAddressHex.length - 6); // WH
  }

  toolTip(): JSX.Element {
    const identity = this.getIdentity();

    return (
      <div>
        <p>
          {this.getDeviceDescription()} - {this.Description}
        </p>
        <ul>
          <li>Device ID: {this.ID.toString()}</li>
          <li>Identity:{identity}</li>
          <li></li>
        </ul>
      </div>
    );
  }
}
