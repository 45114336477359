/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSignedUrl_get_or_put = /* GraphQL */ `
  query GetSignedUrl_get_or_put(
    $accessType: S3URLType!
    $filename: String!
    $expirePeriod: Int!
  ) {
    getSignedUrl_get_or_put(
      accessType: $accessType
      filename: $filename
      expirePeriod: $expirePeriod
    )
  }
`;
export const generateCallReport = /* GraphQL */ `
  query GenerateCallReport(
    $scuId: String!
    $siteName: String!
    $reportDurationDays: Int!
    $email: String!
  ) {
    generateCallReport(
      scuId: $scuId
      siteName: $siteName
      reportDurationDays: $reportDurationDays
      email: $email
    )
  }
`;
export const generateAccessTokens = /* GraphQL */ `
  query GenerateAccessTokens($notUsed: String) {
    generateAccessTokens(notUsed: $notUsed) {
      maker
      biblio
    }
  }
`;
export const getOmniviaClient = /* GraphQL */ `
  query GetOmniviaClient($id: ID!) {
    getOmniviaClient(id: $id) {
      id
      name
      description
      addr1
      addr2
      addr3
      city
      county
      country
      postcode
      contactEmail
      contactTelephone
      timeZone
      brand
      tags
      modifiedBy
      createdAt
      updatedAt
      _deleted
      linkedSites {
        items {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listOmniviaClients = /* GraphQL */ `
  query ListOmniviaClients(
    $filter: ModelOmniviaClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOmniviaClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        brand
        tags
        modifiedBy
        createdAt
        updatedAt
        _deleted
        linkedSites {
          items {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const clientByBrand = /* GraphQL */ `
  query ClientByBrand(
    $brand: String
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientByBrand(
      brand: $brand
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        brand
        tags
        modifiedBy
        createdAt
        updatedAt
        _deleted
        linkedSites {
          items {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOmniviaSite = /* GraphQL */ `
  query GetOmniviaSite($id: ID!) {
    getOmniviaSite(id: $id) {
      id
      name
      description
      addr1
      addr2
      addr3
      city
      county
      country
      postcode
      contactEmail
      contactTelephone
      timeZone
      maintenanceEmail
      pollFailEmail
      stuckAlarmMinAge
      stuckAlarmMaxAge
      stuckAlarmMinTimeBetweenSMS
      stuckAlarmLastAlert
      siteAlertsEnabled
      brand
      tags
      floorPlanFolder
      modifiedBy
      createdAt
      updatedAt
      _deleted
      groupsCanAccess
      linkedClient {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        brand
        tags
        modifiedBy
        createdAt
        updatedAt
        _deleted
        linkedSites {
          items {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      linkedSCUs {
        items {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listOmniviaSites = /* GraphQL */ `
  query ListOmniviaSites(
    $id: ID
    $filter: ModelOmniviaSiteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOmniviaSites(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        maintenanceEmail
        pollFailEmail
        stuckAlarmMinAge
        stuckAlarmMaxAge
        stuckAlarmMinTimeBetweenSMS
        stuckAlarmLastAlert
        siteAlertsEnabled
        brand
        tags
        floorPlanFolder
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedClient {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          brand
          tags
          modifiedBy
          createdAt
          updatedAt
          _deleted
          linkedSites {
            nextToken
          }
        }
        linkedSCUs {
          items {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const siteByName = /* GraphQL */ `
  query SiteByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        maintenanceEmail
        pollFailEmail
        stuckAlarmMinAge
        stuckAlarmMaxAge
        stuckAlarmMinTimeBetweenSMS
        stuckAlarmLastAlert
        siteAlertsEnabled
        brand
        tags
        floorPlanFolder
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedClient {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          brand
          tags
          modifiedBy
          createdAt
          updatedAt
          _deleted
          linkedSites {
            nextToken
          }
        }
        linkedSCUs {
          items {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const siteByBrand = /* GraphQL */ `
  query SiteByBrand(
    $brand: String
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteByBrand(
      brand: $brand
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        maintenanceEmail
        pollFailEmail
        stuckAlarmMinAge
        stuckAlarmMaxAge
        stuckAlarmMinTimeBetweenSMS
        stuckAlarmLastAlert
        siteAlertsEnabled
        brand
        tags
        floorPlanFolder
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedClient {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          brand
          tags
          modifiedBy
          createdAt
          updatedAt
          _deleted
          linkedSites {
            nextToken
          }
        }
        linkedSCUs {
          items {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOmniviaSCU = /* GraphQL */ `
  query GetOmniviaSCU($id: ID!) {
    getOmniviaSCU(id: $id) {
      id
      name
      description
      serialNumber
      localEthernetIP
      localWiFiIP
      ethernetMAC
      wifiMAC
      softwareVersion
      mainOutgoingPOTSNumber
      backupOutgoingPOTSNumber
      remoteAccessPort
      productNumber
      lastSeen
      unitTime
      modifiedBy
      createdAt
      updatedAt
      _deleted
      groupsCanAccess
      linkedSite {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        maintenanceEmail
        pollFailEmail
        stuckAlarmMinAge
        stuckAlarmMaxAge
        stuckAlarmMinTimeBetweenSMS
        stuckAlarmLastAlert
        siteAlertsEnabled
        brand
        tags
        floorPlanFolder
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedClient {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          brand
          tags
          modifiedBy
          createdAt
          updatedAt
          _deleted
          linkedSites {
            nextToken
          }
        }
        linkedSCUs {
          items {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      linkedEvents {
        items {
          id
          alertGeneratedState
          omniviaSCUEventLinkedSCUId
          eventId
          transactionId
          eventIdText
          eventString
          raisedAt
          eventJSON
          aggregationId
          unitTime
          modifiedBy
          createdAt
          updatedAt
          expirationUnixTime
          omniviaSCUEventLinkedSCUIdEventId
          linkedSCU {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
        }
        nextToken
      }
      linkedFaults {
        items {
          id
          omniviaFaultsLinkedSCUId
          faultState
          faultType
          dateRaised
          dateCleared
          dateAlertLastGenerated
          deviceID
          faultSpecificText
          friendlyString
          clearedBy
          associatedRoomNumber
          identity
          faultUnitClassification
          createdAt
          updatedAt
          expirationUnixTime
          linkedSCU {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
        }
        nextToken
      }
    }
  }
`;
export const listOmniviaSCUS = /* GraphQL */ `
  query ListOmniviaSCUS(
    $filter: ModelOmniviaSCUFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOmniviaSCUS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const scuByID = /* GraphQL */ `
  query ScuByID(
    $id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scuByID(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const scuBySerialNumber = /* GraphQL */ `
  query ScuBySerialNumber(
    $serialNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scuBySerialNumber(
      serialNumber: $serialNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const scuByMainTelephone = /* GraphQL */ `
  query ScuByMainTelephone(
    $mainOutgoingPOTSNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scuByMainTelephone(
      mainOutgoingPOTSNumber: $mainOutgoingPOTSNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const scuByBackupTelephone = /* GraphQL */ `
  query ScuByBackupTelephone(
    $backupOutgoingPOTSNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scuByBackupTelephone(
      backupOutgoingPOTSNumber: $backupOutgoingPOTSNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOmniviaSCUEvent = /* GraphQL */ `
  query GetOmniviaSCUEvent(
    $omniviaSCUEventLinkedSCUId: ID!
    $raisedAt: String!
    $transactionId: Int!
    $eventId: Int!
  ) {
    getOmniviaSCUEvent(
      omniviaSCUEventLinkedSCUId: $omniviaSCUEventLinkedSCUId
      raisedAt: $raisedAt
      transactionId: $transactionId
      eventId: $eventId
    ) {
      id
      alertGeneratedState
      omniviaSCUEventLinkedSCUId
      eventId
      transactionId
      eventIdText
      eventString
      raisedAt
      eventJSON
      aggregationId
      unitTime
      modifiedBy
      createdAt
      updatedAt
      expirationUnixTime
      omniviaSCUEventLinkedSCUIdEventId
      linkedSCU {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
    }
  }
`;
export const listOmniviaSCUEvents = /* GraphQL */ `
  query ListOmniviaSCUEvents(
    $omniviaSCUEventLinkedSCUId: ID
    $raisedAtTransactionIdEventId: ModelOmniviaSCUEventPrimaryCompositeKeyConditionInput
    $filter: ModelOmniviaSCUEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOmniviaSCUEvents(
      omniviaSCUEventLinkedSCUId: $omniviaSCUEventLinkedSCUId
      raisedAtTransactionIdEventId: $raisedAtTransactionIdEventId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        alertGeneratedState
        omniviaSCUEventLinkedSCUId
        eventId
        transactionId
        eventIdText
        eventString
        raisedAt
        eventJSON
        aggregationId
        unitTime
        modifiedBy
        createdAt
        updatedAt
        expirationUnixTime
        omniviaSCUEventLinkedSCUIdEventId
        linkedSCU {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const eventBySCUandAggregation = /* GraphQL */ `
  query EventBySCUandAggregation(
    $omniviaSCUEventLinkedSCUId: ID
    $aggregationId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySCUandAggregation(
      omniviaSCUEventLinkedSCUId: $omniviaSCUEventLinkedSCUId
      aggregationId: $aggregationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertGeneratedState
        omniviaSCUEventLinkedSCUId
        eventId
        transactionId
        eventIdText
        eventString
        raisedAt
        eventJSON
        aggregationId
        unitTime
        modifiedBy
        createdAt
        updatedAt
        expirationUnixTime
        omniviaSCUEventLinkedSCUIdEventId
        linkedSCU {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const eventBySCUandDateandEvent = /* GraphQL */ `
  query EventBySCUandDateandEvent(
    $omniviaSCUEventLinkedSCUIdEventId: String
    $raisedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySCUandDateandEvent(
      omniviaSCUEventLinkedSCUIdEventId: $omniviaSCUEventLinkedSCUIdEventId
      raisedAt: $raisedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertGeneratedState
        omniviaSCUEventLinkedSCUId
        eventId
        transactionId
        eventIdText
        eventString
        raisedAt
        eventJSON
        aggregationId
        unitTime
        modifiedBy
        createdAt
        updatedAt
        expirationUnixTime
        omniviaSCUEventLinkedSCUIdEventId
        linkedSCU {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const eventBySCUandDate = /* GraphQL */ `
  query EventBySCUandDate(
    $omniviaSCUEventLinkedSCUId: ID
    $raisedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySCUandDate(
      omniviaSCUEventLinkedSCUId: $omniviaSCUEventLinkedSCUId
      raisedAt: $raisedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertGeneratedState
        omniviaSCUEventLinkedSCUId
        eventId
        transactionId
        eventIdText
        eventString
        raisedAt
        eventJSON
        aggregationId
        unitTime
        modifiedBy
        createdAt
        updatedAt
        expirationUnixTime
        omniviaSCUEventLinkedSCUIdEventId
        linkedSCU {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOmniviaFaults = /* GraphQL */ `
  query GetOmniviaFaults($id: ID!) {
    getOmniviaFaults(id: $id) {
      id
      omniviaFaultsLinkedSCUId
      faultState
      faultType
      dateRaised
      dateCleared
      dateAlertLastGenerated
      deviceID
      faultSpecificText
      friendlyString
      clearedBy
      associatedRoomNumber
      identity
      faultUnitClassification
      createdAt
      updatedAt
      expirationUnixTime
      linkedSCU {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
    }
  }
`;
export const listOmniviaFaults = /* GraphQL */ `
  query ListOmniviaFaults(
    $id: ID
    $filter: ModelOmniviaFaultsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOmniviaFaults(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        omniviaFaultsLinkedSCUId
        faultState
        faultType
        dateRaised
        dateCleared
        dateAlertLastGenerated
        deviceID
        faultSpecificText
        friendlyString
        clearedBy
        associatedRoomNumber
        identity
        faultUnitClassification
        createdAt
        updatedAt
        expirationUnixTime
        linkedSCU {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const faultsByDateRaised = /* GraphQL */ `
  query FaultsByDateRaised(
    $omniviaFaultsLinkedSCUId: ID
    $dateRaised: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaFaultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faultsByDateRaised(
      omniviaFaultsLinkedSCUId: $omniviaFaultsLinkedSCUId
      dateRaised: $dateRaised
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        omniviaFaultsLinkedSCUId
        faultState
        faultType
        dateRaised
        dateCleared
        dateAlertLastGenerated
        deviceID
        faultSpecificText
        friendlyString
        clearedBy
        associatedRoomNumber
        identity
        faultUnitClassification
        createdAt
        updatedAt
        expirationUnixTime
        linkedSCU {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const faultsByTypeandStateandDeviceId = /* GraphQL */ `
  query FaultsByTypeandStateandDeviceId(
    $omniviaFaultsLinkedSCUId: ID
    $faultTypeFaultStateDeviceID: ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaFaultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faultsByTypeandStateandDeviceId(
      omniviaFaultsLinkedSCUId: $omniviaFaultsLinkedSCUId
      faultTypeFaultStateDeviceID: $faultTypeFaultStateDeviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        omniviaFaultsLinkedSCUId
        faultState
        faultType
        dateRaised
        dateCleared
        dateAlertLastGenerated
        deviceID
        faultSpecificText
        friendlyString
        clearedBy
        associatedRoomNumber
        identity
        faultUnitClassification
        createdAt
        updatedAt
        expirationUnixTime
        linkedSCU {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const faultsByState = /* GraphQL */ `
  query FaultsByState(
    $omniviaFaultsLinkedSCUId: ID
    $faultState: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaFaultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faultsByState(
      omniviaFaultsLinkedSCUId: $omniviaFaultsLinkedSCUId
      faultState: $faultState
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        omniviaFaultsLinkedSCUId
        faultState
        faultType
        dateRaised
        dateCleared
        dateAlertLastGenerated
        deviceID
        faultSpecificText
        friendlyString
        clearedBy
        associatedRoomNumber
        identity
        faultUnitClassification
        createdAt
        updatedAt
        expirationUnixTime
        linkedSCU {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOmniviaLiveEventsLog = /* GraphQL */ `
  query GetOmniviaLiveEventsLog($id: ID!, $mac: String!) {
    getOmniviaLiveEventsLog(id: $id, mac: $mac) {
      id
      mac
      omniviaSCUSerial
      deviceType
      expirationUnixTime
      deviceId
      associatedAlarmId
      batteryVoltageApplication
      handset {
        batteryVoltageNative
        batteryCurrent
        isCharging
        isScreenOn
        chargerProtectionStateOld
        chargerProtectionStateNew
        currentBatteryCapacity
        batteryTemperature
        maxChargeLvl
        isChargerEnabled
        isOvertemp
        cycleOnTime
        cycleOffTime
      }
      parentMac
      rssi_db
      addtionalReceiverList {
        parentMac
        rssi_db
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOmniviaLiveEventsLogs = /* GraphQL */ `
  query ListOmniviaLiveEventsLogs(
    $id: ID
    $mac: ModelStringKeyConditionInput
    $filter: ModelOmniviaLiveEventsLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOmniviaLiveEventsLogs(
      id: $id
      mac: $mac
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        deviceId
        associatedAlarmId
        batteryVoltageApplication
        handset {
          batteryVoltageNative
          batteryCurrent
          isCharging
          isScreenOn
          chargerProtectionStateOld
          chargerProtectionStateNew
          currentBatteryCapacity
          batteryTemperature
          maxChargeLvl
          isChargerEnabled
          isOvertemp
          cycleOnTime
          cycleOffTime
        }
        parentMac
        rssi_db
        addtionalReceiverList {
          parentMac
          rssi_db
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const liveEventsLogByDate = /* GraphQL */ `
  query LiveEventsLogByDate(
    $mac: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaLiveEventsLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    liveEventsLogByDate(
      mac: $mac
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        deviceId
        associatedAlarmId
        batteryVoltageApplication
        handset {
          batteryVoltageNative
          batteryCurrent
          isCharging
          isScreenOn
          chargerProtectionStateOld
          chargerProtectionStateNew
          currentBatteryCapacity
          batteryTemperature
          maxChargeLvl
          isChargerEnabled
          isOvertemp
          cycleOnTime
          cycleOffTime
        }
        parentMac
        rssi_db
        addtionalReceiverList {
          parentMac
          rssi_db
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const liveEventsLogBySiteAndDate = /* GraphQL */ `
  query LiveEventsLogBySiteAndDate(
    $omniviaSCUSerial: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaLiveEventsLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    liveEventsLogBySiteAndDate(
      omniviaSCUSerial: $omniviaSCUSerial
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        deviceId
        associatedAlarmId
        batteryVoltageApplication
        handset {
          batteryVoltageNative
          batteryCurrent
          isCharging
          isScreenOn
          chargerProtectionStateOld
          chargerProtectionStateNew
          currentBatteryCapacity
          batteryTemperature
          maxChargeLvl
          isChargerEnabled
          isOvertemp
          cycleOnTime
          cycleOffTime
        }
        parentMac
        rssi_db
        addtionalReceiverList {
          parentMac
          rssi_db
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOmniviaBatteryLog = /* GraphQL */ `
  query GetOmniviaBatteryLog($id: ID!, $mac: String!) {
    getOmniviaBatteryLog(id: $id, mac: $mac) {
      id
      mac
      omniviaSCUSerial
      deviceType
      expirationUnixTime
      batteryVoltageApplication
      handset {
        batteryVoltageNative
        batteryCurrent
        isCharging
        isScreenOn
        chargerProtectionStateOld
        chargerProtectionStateNew
        currentBatteryCapacity
        batteryTemperature
        maxChargeLvl
        isChargerEnabled
        isOvertemp
        cycleOnTime
        cycleOffTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOmniviaBatteryLogs = /* GraphQL */ `
  query ListOmniviaBatteryLogs(
    $id: ID
    $mac: ModelStringKeyConditionInput
    $filter: ModelOmniviaBatteryLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOmniviaBatteryLogs(
      id: $id
      mac: $mac
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        batteryVoltageApplication
        handset {
          batteryVoltageNative
          batteryCurrent
          isCharging
          isScreenOn
          chargerProtectionStateOld
          chargerProtectionStateNew
          currentBatteryCapacity
          batteryTemperature
          maxChargeLvl
          isChargerEnabled
          isOvertemp
          cycleOnTime
          cycleOffTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const batteryLogByDate = /* GraphQL */ `
  query BatteryLogByDate(
    $mac: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaBatteryLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batteryLogByDate(
      mac: $mac
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        batteryVoltageApplication
        handset {
          batteryVoltageNative
          batteryCurrent
          isCharging
          isScreenOn
          chargerProtectionStateOld
          chargerProtectionStateNew
          currentBatteryCapacity
          batteryTemperature
          maxChargeLvl
          isChargerEnabled
          isOvertemp
          cycleOnTime
          cycleOffTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const batteryLogBySiteAndDate = /* GraphQL */ `
  query BatteryLogBySiteAndDate(
    $omniviaSCUSerial: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaBatteryLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batteryLogBySiteAndDate(
      omniviaSCUSerial: $omniviaSCUSerial
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        batteryVoltageApplication
        handset {
          batteryVoltageNative
          batteryCurrent
          isCharging
          isScreenOn
          chargerProtectionStateOld
          chargerProtectionStateNew
          currentBatteryCapacity
          batteryTemperature
          maxChargeLvl
          isChargerEnabled
          isOvertemp
          cycleOnTime
          cycleOffTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOmniviaLocationHistory = /* GraphQL */ `
  query GetOmniviaLocationHistory($id: ID!, $mac: String!) {
    getOmniviaLocationHistory(id: $id, mac: $mac) {
      id
      mac
      omniviaSCUSerial
      deviceType
      expirationUnixTime
      parentMac
      rssi_db
      addtionalReceiverList {
        parentMac
        rssi_db
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOmniviaLocationHistories = /* GraphQL */ `
  query ListOmniviaLocationHistories(
    $id: ID
    $mac: ModelStringKeyConditionInput
    $filter: ModelOmniviaLocationHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOmniviaLocationHistories(
      id: $id
      mac: $mac
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        parentMac
        rssi_db
        addtionalReceiverList {
          parentMac
          rssi_db
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const historyLogByDate = /* GraphQL */ `
  query HistoryLogByDate(
    $mac: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaLocationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    historyLogByDate(
      mac: $mac
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        parentMac
        rssi_db
        addtionalReceiverList {
          parentMac
          rssi_db
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const historyLogBySiteAndDate = /* GraphQL */ `
  query HistoryLogBySiteAndDate(
    $omniviaSCUSerial: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaLocationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    historyLogBySiteAndDate(
      omniviaSCUSerial: $omniviaSCUSerial
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mac
        omniviaSCUSerial
        deviceType
        expirationUnixTime
        parentMac
        rssi_db
        addtionalReceiverList {
          parentMac
          rssi_db
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
