export const tunstallcolors = {
  global: {
    colors: {
      /* BEGIN: Color Palette Definition */
      ruby: {
        dark: '#d4111e',
        light: '#f58990',
      },
      'ruby!': '#EF3F4C',
      gold: {
        dark: '#df9007',
        light: '#e7b86b',
      },
      'gold!': '#F9B644',
      amethyst: {
        dark: '#9B59B6',
        light: '#C39BD3',
      },
      'amethyst!': '#AF7AC5',
      practical1: '#CBDB2A',
      practical2: '#81FCED',
      practical3: '#54565B',
      practical4: '#00BBDD',
      'grey-1': '#525f6a',
      'grey-2': '#CECCC6',
      'grey-3': '#737069',
      'grey-4': '#52504C',
      'grey-9': '#3d4d5a',
      red: '#D2222D',
      amber: '#FFBF00',
      green: '#238823',
      aqua: '#54F5C1',
      blue: '#1E90FF',
      dialogBackground: '#eeeeee',
      lighterText: '#888888',

      /* END: Color Palette Definition */
      /* BEGIN: Mapping Colors to Grommet Namespaces */
      background: {
        dark: '#eeeeee',
        light: '#eeeeee',
      },
      'background-back': {
        dark: 'grey-4',
        light: 'grey-1',
      },
      'background-front': {
        dark: 'grey-3',
        light: 'grey-2',
      },
      brand: '#dc3545',
      control: {
        dark: 'brand',
        light: 'brand',
      },
      input: {
        background: 'blue',
      },
      text: {
        dark: '#333333',
        // light: '#722f2f',
      },
    },
    focus: {
      border: {
        color: 'gold',
      },
    },
    /* END: Mapping Colors to Grommet Namespaces */
  },
  /* BEGIN: Mapping Colors to Components */
  anchor: {
    color: {
      dark: 'practical4',
      light: 'amethyst!',
    },
  },
  /* END: Mapping Colors to Components */
};
