import { iSelectKeyValue } from 'components/forms/formFields';
import { ALARM_TYPES, iAlarmTypeDescription } from '../../types/event-enums';
import { ALARMS_TYPE_DESCRIPTIONS } from './alarmText';

export const getAlarmDescription = (alarmType: ALARM_TYPES): string => {
  const text = ALARMS_TYPE_DESCRIPTIONS.find((e) => e.value == alarmType)?.text;
  return text ? text : 'unknown';
};

/**
 * alarm_types_value_text
 * @param list array of alarm types
 * @return an array of iAlarmTypeDecriptions   value: text:
 */
export const alarm_types_value_text = (
  list: ALARM_TYPES[]
): iAlarmTypeDescription[] => {
  let ret: iAlarmTypeDescription[] = [];
  list.forEach((a) => {
    const text = getAlarmDescription(a);
    ret.push({ value: a, text: text });
  });

  // keep top 5 in position and then sort the rest alphabetically
  const len = ret.length;
  if (len > 6) {
    const top = ret.slice(0, 6);
    const bottom = ret.slice(6, len);
    bottom.sort((a, b) => a.text.localeCompare(b.text));
    ret = top.concat(bottom);
  }

  return ret;
};

/**
 * alarm_types_iSelectKeyValues
 * this is used by form fields options select boxes
 * @param list array of alarm types
 * @return an array of iSelectKeyValue
 */
export const alarm_types_iSelectKeyValues = (
  list: ALARM_TYPES[]
): iSelectKeyValue[] => {
  let ret: iSelectKeyValue[] = [];
  list.forEach((a) => {
    const text = getAlarmDescription(a);
    ret.push({ key: a.toString(), value: text });
  });

  // keep top 5 in position and then sort the rest alphabetically
  const len = ret.length;
  if (len > 6) {
    const top = ret.slice(0, 6);
    const bottom = ret.slice(6, len);
    bottom.sort((a, b) => a.value.localeCompare(b.value));
    ret = top.concat(bottom);
  }

  return ret;
};
