import React, { useMemo } from 'react';
import useState from 'react-usestateref';
import Tile, {
  iTileEvent,
  iMenuProps,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Button } from 'grommet';

import { Manifest, teErrorCode } from 'components/installation/manifest';
import * as eventEnums from 'types/event-enums';
import * as ManifestEnums from 'types/manifest-enums';
import * as Device from 'components/installation/device';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import TablePaged from 'components/tables/TablePaged';
import { Column, Row } from 'react-table';
import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
import * as AlarmEvents from 'components/systemevents/alarmEvents';
import SelectCell from 'components/tables/cellPresentationComponents/selectCell';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
import CheckBoxCell from 'components/tables/cellPresentationComponents/checkBoxCell';
import IDCell from 'components/tables/cellPresentationComponents/idCell';
import { useWinstonLogger } from 'winston-react';
import { ALARMS_TYPE_DESCRIPTIONS } from 'common/alarmTypesTextFiltered/alarmText';
import {
  alarm_types_iSelectKeyValues,
  alarm_types_value_text,
  getAlarmDescription,
} from 'common/alarmTypesTextFiltered/alarmTypeUtils';
import { ALARM_TYPES_VRU_HWIP } from 'common/alarmTypesTextFiltered/alarmTypesVRU_HWIP';

const manifest = new Manifest();

interface iTableData {
  ID: number;
  RoomNumber: number;
  HardwiredInputsAlarmType: eventEnums.ALARM_TYPES[];
  HardwiredInputsPolarity: Device.teHARDWIRED_INPUT_POLARITY[];
  HardwiredInputsSupported: number;
  Name: string;
  EnableOK: number;
  Inactivity: number;
  Intruder: number;
  Volume: number;
  ToneVolume: number;
  Type: number;
  HwModel: number;
  MacAddressHex: string;
  RedButtonEnabled: number;
  MacAddress: number;
}

const blankTableEntry: iTableData = {
  ID: 0,
  RoomNumber: 0,
  HardwiredInputsAlarmType: [0, 0, 0],
  HardwiredInputsPolarity: [0, 0, 0],
  HardwiredInputsSupported: 0,
  Name: 'blank',
  EnableOK: 0,
  Inactivity: 0,
  Intruder: 0,
  Volume: 0,
  ToneVolume: 0,
  Type: 0,
  HwModel: 0,
  MacAddressHex: '',
  RedButtonEnabled: 0,
  MacAddress: 0,
};

let _allData: iTableData[] = [];

const volumeOptions = [
  { value: 0, text: '1' },
  { value: 1, text: '2' },
  { value: 2, text: '3' },
  { value: 3, text: '4' },
  { value: 4, text: '5' },
];

const RoomUnitConfigTable = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useState<number[]>([]);

  const loadData = () => {
    for (const room of manifest.rooms) {
      room.pullHardwiredInputsFromRoutingEvents();
    }
    _allData = _.cloneDeep(manifest.rooms);

    //console.log(_allData);
    setChanges([]);
    setRenderCount((old) => old + 1);
  };

  const [selectedDevice, setSelectedDevice] = useState<
    iTableData | undefined
  >();

  const alarmTypesList: eventEnums.iAlarmTypeDescription[] = useMemo(() => {
    return alarm_types_value_text(ALARM_TYPES_VRU_HWIP);
  }, []);

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
  ];

  function getPolarityText(pol: Device.teHARDWIRED_INPUT_POLARITY): string {
    let text = '';

    const index = eventEnums.INPUT_POLARITIES.findIndex((object) => {
      return object.value === pol;
    });

    if (index != -1) {
      text = eventEnums.INPUT_POLARITIES[index].text;
    }

    return text;
  }

  /**
   * @param id    room unit device id
   * @param property item to change in the room unit object
   * @param value new value
   */
  const setProperty = (id: number, property: string, value: unknown) => {
    const room = _allData.find((e) => e.ID == id);
    if (room) {
      const manifestRecord = manifest.rooms.find((e) => e.ID == id);

      const setIt = () => {
        if (!changesRef.current.includes(id)) {
          changesRef.current.push(id);
          setRenderCount((old) => old + 1);
        }
      };

      _.set(room, property, value);
      setRenderCount((old) => old + 1);

      if (manifestRecord) {
        if (_.get(manifestRecord, property, 0) != value) {
          if (property === 'RedButtonEnabled' && value == 0) {
            setDialogueProps({
              show: true,
              dialogueText: 'Are you sure you want to disable the Red Button?',
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress: () => {
                setDialogueProps(undefined);
                setIt();
              },
              cancelButtonPress: () => {
                _.set(room, property, 1);
                setDialogueProps(undefined);
              },
            });
          } else {
            setIt();
          }
        } else {
          _.pull(changesRef.current, id);
          setRenderCount((old) => old + 1);
        }
      }
    }
  };

  function getProperty(id: number, property: string): unknown {
    let room = _allData.find((e) => e.ID == id);
    if (room === undefined) {
      room = blankTableEntry;
    }
    const value = _.get(room, property, 0);
    return value;
  }

  const inputFilter = React.useMemo(
    () => (rows: Row<iTableData>[], id: string[], filterValue: string) => {
      let input = Device.teDEVICE_INPUTS.DEVICE_INPUT_1;
      if (id[0].includes('in2')) {
        input = Device.teDEVICE_INPUTS.DEVICE_INPUT_2;
      } else if (id[0].includes('in3')) {
        input = Device.teDEVICE_INPUTS.DEVICE_INPUT_3;
      }

      return rows.filter((row) => {
        let text = getAlarmDescription(
          row.original.HardwiredInputsAlarmType[input]
        );
        if (id[0].includes('w')) {
          text = getPolarityText(row.original.HardwiredInputsPolarity[input]);
        }
        const escapedText = filterValue.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        const exp = new RegExp(escapedText, 'i');

        return exp.test(text);
      });
    },
    []
  );

  const validateRoomNumber = (
    id: number,
    newValue: unknown
  ): number | undefined => {
    const unitId = newValue as number;

    const deviceInfo = manifest.unitIdInUse(unitId);
    const currentTable = _allData.find(
      (e) => e.RoomNumber == unitId && e.ID != id
    );

    if (deviceInfo === undefined && currentTable === undefined)
      return undefined;

    const originalRecord = manifest.rooms.find((e) => e.ID == id);

    let deviceid = 0;
    let mac = 0;
    if (deviceInfo !== undefined) {
      mac = deviceInfo.mac;
      deviceid = deviceInfo.deviceId;
    } else if (currentTable !== undefined) {
      mac = currentTable.MacAddress;
      deviceid = currentTable.ID ? currentTable.ID : 0;
    }

    let assignedToName = manifest.getDeviceNameByMac(mac);
    assignedToName += ` ID:${deviceid}`;

    logger.info('roomUnitsConfigTable.invalidUnitId', { unitId: unitId });
    setDialogueProps({
      show: true,
      dialogueText: `The room number ${unitId} is already in use.`,
      dialogueTextLine2: assignedToName,
      showSpinner: false,
      showOkButton: true,
      okButtonPress: () => setDialogueProps(undefined),
    });

    return originalRecord?.RoomNumber;
  };

  const getRoomToolTip = React.useCallback((id: unknown) => {
    return manifest.rooms.find((room) => room.ID == id)?.toolTip();
  }, []);

  /**
   * Called from TablePaged when a row is selected
   * Takes a copy of the row into a useState
   * @param rowData
   * @param selectionInfo
   */
  const selectDevice = async (
    rowData: iTableData,
    selectionInfo?: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (selectionInfo === undefined || !selectionInfo.alreadySelected) {
      console.info('room Selected:', rowData);
      logger.info('roomUnitsConfigTabel.selectDevice', { device: rowData });

      // Perform a deepclone to ensure we don't copy object refs which will lead to
      // modifying the orginal data
      setSelectedDevice(_.cloneDeep(rowData));
    }
  };

  const copyInputsToAllResidents = (device: iTableData, communal: boolean) => {
    console.info('copyInputsToAllResidents from:', device);

    for (const index in _allData) {
      const room = _allData[index];
      if (room && room.MacAddress != device.MacAddress) {
        const destinationRoom = manifest.rooms.find(
          (e) => e.MacAddress == room.MacAddress
        );
        if (destinationRoom && destinationRoom.isCommunal() == communal) {
          console.info(`Copy room ${device.ID} to ${room.ID}`);

          const alarmTypes = _.cloneDeep(device.HardwiredInputsAlarmType);
          setProperty(room.ID, 'HardwiredInputsAlarmType', alarmTypes);

          const pol = _.cloneDeep(device.HardwiredInputsPolarity);
          setProperty(room.ID, 'HardwiredInputsPolarity', pol);
        }
      }
    }
  };

  const copyInputsToAllareas = (device: iTableData) => {
    console.info('copyInputsToAllAreasfrom:', device);
    for (const index in _allData) {
      const room = _allData[index];
      if (room && room.MacAddress != device.MacAddress) {
        console.info(`Copy room ${device.ID} to ${room.ID}`);

        const alarmTypes = _.cloneDeep(device.HardwiredInputsAlarmType);
        setProperty(room.ID, 'HardwiredInputsAlarmType', alarmTypes);

        const pol = _.cloneDeep(device.HardwiredInputsPolarity);
        setProperty(room.ID, 'HardwiredInputsPolarity', pol);
      }
    }
  };

  /**
   * The aim of all the cell components is that they do not render unnecessarily
   * All the cell components use React.memo to memorise the component.
   *
   * React.memo will only re-generate the compoent if a prop changes therefore we pass a reRenderValue prop
   * to the component to allow us to signal to React.memo that it needs to be re-rendered.
   *
   * The components use callback to set/get their data and not state varibles
   */
  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',

        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <IDCell
            id={props.row.values.ID}
            changesRef={changesRef}
            reRenderValue={changesRef.current.includes(props.row.values.ID)}
          />
        ),
      },
      {
        width: '300px',
        Header: 'Name',
        accessor: 'Name',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Name}
            valueProperty={`Name`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="text"
            /*
             * Passing tooltip as a memorised function so that the components do not re-render unnecessarily
             */
            toolTip={getRoomToolTip}
          />
        ),
      },
      {
        id: 'Room',
        Header: 'Room',
        accessor: 'RoomNumber',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.RoomNumber}
            valueProperty={`RoomNumber`}
            getProperty={getProperty}
            setProperty={setProperty}
            validator={validateRoomNumber}
            inputType="number"
          />
        ),
      },

      {
        Header: 'OK Monitoring',
        accessor: 'EnableOK',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <CheckBoxCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.EnableOK}
            valueProperty={`EnableOK`}
            getProperty={getProperty}
            setProperty={setProperty}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          return getProperty(row.original.ID, `EnableOK`) ? 'Yes' : 'No';
        },
      },
      {
        Header: 'Intruder',
        accessor: 'Intruder',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <CheckBoxCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Intruder}
            valueProperty={`Intruder`}
            getProperty={getProperty}
            setProperty={setProperty}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          return getProperty(row.original.ID, `Intruder`) ? 'Yes' : 'No';
        },
      },
      {
        Header: 'Inactivity',
        accessor: 'Inactivity',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <CheckBoxCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Inactivity}
            valueProperty={`Inactivity`}
            getProperty={getProperty}
            setProperty={setProperty}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          return getProperty(row.original.ID, `Inactivity`) ? 'Yes' : 'No';
        },
      },

      {
        Header: 'Speech Vol',
        accessor: 'Volume',

        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <SelectCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Volume}
            valueProperty={`Volume`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={volumeOptions}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          return (getProperty(row.original.ID, `Volume`) as number) + 1;
        },
      },

      {
        Header: 'Tone Vol',
        accessor: 'ToneVolume',

        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <SelectCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.ToneVolume}
            valueProperty={`ToneVolume`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={volumeOptions}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          return (getProperty(row.original.ID, `ToneVolume`) as number) + 1;
        },
      },

      {
        Header: 'Red Button',
        accessor: 'RedButtonEnabled',
        headerToolTip:
          'Enable or Disable if the alarm button \r\non the front of the unit will raise alarms',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <CheckBoxCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.RedButtonEnabled}
            valueProperty={`RedButtonEnabled`}
            getProperty={getProperty}
            setProperty={setProperty}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          return getProperty(row.original.ID, `RedButtonEnabled`) === true
            ? 'Yes'
            : 'No';
        },
      },

      {
        id: 'in1',
        Header: 'Input 1',
        accessor: (row) =>
          row.HardwiredInputsAlarmType[Device.teDEVICE_INPUTS.DEVICE_INPUT_1],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <SelectCell
            id={props.row.values.ID}
            reRenderValue={
              props.row.original.HardwiredInputsAlarmType[
                Device.teDEVICE_INPUTS.DEVICE_INPUT_1
              ]
            }
            valueProperty={`HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_1}]`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={alarmTypesList}
            searchBox={true}
            alwaysDisplayDropDown={true}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_1}]`
          ) as number;

          return getAlarmDescription(value);
        },
        filter: inputFilter,
      },

      {
        id: 'in1w',
        Header: 'IP1 Wiring',
        headerToolTip:
          'Set if the input 1 is wired as\r\nnormally open or normally closed',
        accessor: (row: iTableData) =>
          row.HardwiredInputsPolarity[Device.teDEVICE_INPUTS.DEVICE_INPUT_1],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <SelectCell
            id={props.row.values.ID}
            reRenderValue={
              props.row.original.HardwiredInputsPolarity[
                Device.teDEVICE_INPUTS.DEVICE_INPUT_1
              ]
            }
            valueProperty={`HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_1}]`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={eventEnums.INPUT_POLARITIES}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_1}]`
          ) as number;

          return getPolarityText(value);
        },
        filter: inputFilter,
      },

      {
        id: 'in2',
        Header: 'Input2',
        accessor: (row: iTableData) =>
          row.HardwiredInputsAlarmType[Device.teDEVICE_INPUTS.DEVICE_INPUT_2],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <SelectCell
            id={props.row.values.ID}
            reRenderValue={
              props.row.original.HardwiredInputsAlarmType[
                Device.teDEVICE_INPUTS.DEVICE_INPUT_2
              ]
            }
            valueProperty={`HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_2}]`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={alarmTypesList}
            searchBox={true}
            alwaysDisplayDropDown={true}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_2}]`
          ) as number;

          return getAlarmDescription(value);
        },
        filter: inputFilter,
      },

      {
        id: 'in2w',
        Header: 'IP2 Wiring',
        headerToolTip:
          'Set if the input 2 is wired as\r\nnormally open or normally closed',
        accessor: (row: iTableData) =>
          row.HardwiredInputsPolarity[Device.teDEVICE_INPUTS.DEVICE_INPUT_2],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <SelectCell
            id={props.row.values.ID}
            reRenderValue={
              props.row.original.HardwiredInputsPolarity[
                Device.teDEVICE_INPUTS.DEVICE_INPUT_2
              ]
            }
            valueProperty={`HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_2}]`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={eventEnums.INPUT_POLARITIES}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_2}]`
          ) as number;

          return getPolarityText(value);
        },
        filter: inputFilter,
      },

      {
        id: 'in3',
        Header: 'Input 3',
        accessor: (row: iTableData) =>
          row.HardwiredInputsAlarmType[Device.teDEVICE_INPUTS.DEVICE_INPUT_3],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => {
          if (
            props.row.original.HwModel >
            ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V1
          ) {
            return (
              <SelectCell
                id={props.row.values.ID}
                reRenderValue={
                  props.row.original.HardwiredInputsAlarmType[
                    Device.teDEVICE_INPUTS.DEVICE_INPUT_3
                  ]
                }
                valueProperty={`HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_3}]`}
                getProperty={getProperty}
                setProperty={setProperty}
                options={alarmTypesList}
                searchBox={true}
                alwaysDisplayDropDown={true}
              />
            );
          } else {
            return <></>;
          }
        },
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_3}]`
          ) as number;

          return getAlarmDescription(value);
        },
        filter: inputFilter,
      },

      {
        id: 'in3w',
        Header: 'IP3 Wiring',
        headerToolTip:
          'Set if the input 3 is wired as\r\nnormally open or normally closed',
        accessor: (row: iTableData) =>
          row.HardwiredInputsPolarity[Device.teDEVICE_INPUTS.DEVICE_INPUT_3],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => {
          if (
            props.row.original.HwModel >
            ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V1
          ) {
            return (
              <SelectCell
                id={props.row.values.ID}
                reRenderValue={
                  props.row.original.HardwiredInputsPolarity[
                    Device.teDEVICE_INPUTS.DEVICE_INPUT_3
                  ]
                }
                valueProperty={`HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_3}]`}
                getProperty={getProperty}
                setProperty={setProperty}
                options={eventEnums.INPUT_POLARITIES}
              />
            );
          } else {
            return <></>;
          }
        },
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_3}]`
          ) as number;

          return getPolarityText(value);
        },
        filter: inputFilter,
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? ['Inactivity', 'Intruder']
        : [
            'RedButtonEnabled',
            'in1',
            'in1w',
            'in2',
            'in2w',
            'in3',
            'in3w',
            'Inactivity',
            'Intruder',
          ],
      pageSize: 16,
      sortBy: [
        {
          id: 'Room',
          desc: false,
        },
      ],
    }),
    []
  );

  const save = () => {
    logger.info('roomUnitsConfigTable.save', {
      changedIDs: changesRef.current,
    });
    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _allData.find((e) => e.ID === id);
      let room = manifest.rooms.find((e) => e.ID === id);
      if (!stateRecord || !room) continue;
      room = _.merge(room, stateRecord);
      room.pushHardwiredInputsToRoutingEvents();
    }

    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setChanges([]);
    };

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('roomUnitsConfigTable.saveFailed', {
            status: status,
            error: {},
          });
        }

        complete(str);
      })
      .catch((error) => {
        logger.info('roomUnitsConfigTable.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        console.error('arc # save fail:', error);
        complete(str);
      });
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? changes.length : false}
      tip="Save changes"
      disabled={changes.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Copy inputs to all resident rooms',
          icon: <Icons.Duplicate size="medium" />,
          onClick: () => {
            if (selectedDevice) {
              logger.info('roomUnitsConfigTable.copyInputsToAllResidents', {
                deviceID: selectedDevice?.ID,
                roomNumber: selectedDevice?.RoomNumber,
              });
              copyInputsToAllResidents(selectedDevice, false);
            }
          },
          disabled: selectedDevice === undefined,
        },
        {
          label: 'Copy inputs to all communal rooms',
          icon: <Icons.Duplicate size="medium" />,
          onClick: () => {
            if (selectedDevice) {
              logger.info('roomUnitsConfigTable.copyInputsToAllCommunal', {
                deviceID: selectedDevice?.ID,
                roomNumber: selectedDevice?.RoomNumber,
              });
              copyInputsToAllResidents(selectedDevice, true);
            }
          },
          disabled: selectedDevice === undefined,
        },
        {
          label: 'Copy inputs to all areas',
          icon: <Icons.Duplicate size="medium" />,
          onClick: () => {
            if (selectedDevice) {
              logger.info('roomUnitsConfigTable.copyInputsToAllAreas', {
                deviceID: selectedDevice?.ID,
                roomNumber: selectedDevice?.RoomNumber,
              });
              copyInputsToAllareas(selectedDevice);
            }
          },
          disabled: selectedDevice === undefined,
        },
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('roomUnitsConfigTable.discardChanges');
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [selectedDevice] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //console.info('Re-render config table');

  return (
    <Tile
      title="Room Unit Configuration"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={_allData ? _allData : []}
          showCheckboxes={false}
          reportDescription={{
            header: '',
            filename: 'RoomUnitConfig',
          }}
          onLastSelectedRow={selectDevice}
          currentSelectedID={selectedDevice?.ID}
          selectorKeyName="ID"
        />
      </>
    </Tile>
  );
};

export default RoomUnitConfigTable;
