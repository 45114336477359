import React, { useState } from 'react';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../tile';
import {
  Form,
  FormField,
  TextInput,
  Button,
  CheckBoxGroup,
  Box,
} from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest, teErrorCode } from '../../installation/manifest';
import { Lock } from 'grommet-icons';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import * as Icons from 'grommet-icons';
import { useWinstonLogger } from 'winston-react';

enum teDaysOfWeek {
  Monday = 0,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

const dayOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

interface iConfig {
  to: string;
  from: string;
  daysEnabled: string[];
  tradesPinCode: string;
  ringTimeoutSeconds: number;
}

let unchangedConfig: iConfig | undefined = undefined;

const DoorEntryConfigOld = (): JSX.Element => {
  const logger = useWinstonLogger();
  const configInit: iConfig = {
    to: '',
    from: '',
    daysEnabled: [],
    tradesPinCode: '',
    ringTimeoutSeconds: 0,
  };

  const [config, setConfig] = useState<iConfig>(configInit);

  const [, SetChildEvent] = useState('');
  const [dataloaded, setDataloaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueText, setDialogueText] = useState('');
  const manifest = new Manifest();

  function updateInfo() {
    if (manifest.systemParameters.length > 0) {
      const config: iConfig = configInit;
      config.daysEnabled = [];

      const sp_startHour = manifest.getSystemParameter('TRADESMAN_ON_HOUR');
      const sp_startMinute = manifest.getSystemParameter('TRADESMAN_ON_MINUTE');
      const sp_stopHour = manifest.getSystemParameter('TRADESMAN_OFF_HOUR');
      const sp_stopMinute = manifest.getSystemParameter('TRADESMAN_OFF_MINUTE');
      const sp_mon = manifest.getSystemParameter('TRADESMAN_MONDAY');
      const sp_tues = manifest.getSystemParameter('TRADESMAN_TUESDAY');
      const sp_wed = manifest.getSystemParameter('TRADESMAN_WEDNESDAY');
      const sp_thur = manifest.getSystemParameter('TRADESMAN_THURSDAY');
      const sp_fri = manifest.getSystemParameter('TRADESMAN_FRIDAY');
      const sp_sat = manifest.getSystemParameter('TRADESMAN_SATURDAY');
      const sp_sun = manifest.getSystemParameter('TRADESMAN_SUNDAY');
      const sp_pin = manifest.getSystemParameter('TRADESMAN_PIN');
      const sp_RingTimeout = manifest.getSystemParameter(
        'DOOR_PANEL_RING_TIMEOUT'
      );

      if (
        sp_startHour &&
        sp_startMinute &&
        sp_stopHour &&
        sp_stopMinute &&
        sp_mon &&
        sp_tues &&
        sp_wed &&
        sp_thur &&
        sp_fri &&
        sp_sat &&
        sp_sun &&
        sp_pin &&
        sp_RingTimeout
      ) {
        config.from =
          sp_startHour.Value.padStart(2, '0') +
          ':' +
          sp_startMinute.Value.padStart(2, '0');
        config.to =
          sp_stopHour.Value.padStart(2, '0') +
          ':' +
          sp_stopMinute.Value.padStart(2, '0');

        if (parseInt(sp_mon.Value))
          config.daysEnabled.push(dayOptions[teDaysOfWeek.Monday]);
        if (parseInt(sp_tues.Value))
          config.daysEnabled.push(dayOptions[teDaysOfWeek.Tuesday]);
        if (parseInt(sp_wed.Value))
          config.daysEnabled.push(dayOptions[teDaysOfWeek.Wednesday]);
        if (parseInt(sp_thur.Value))
          config.daysEnabled.push(dayOptions[teDaysOfWeek.Thursday]);
        if (parseInt(sp_fri.Value))
          config.daysEnabled.push(dayOptions[teDaysOfWeek.Friday]);
        if (parseInt(sp_sat.Value))
          config.daysEnabled.push(dayOptions[teDaysOfWeek.Saturday]);
        if (parseInt(sp_sun.Value))
          config.daysEnabled.push(dayOptions[teDaysOfWeek.Sunday]);

        config.ringTimeoutSeconds = parseInt(sp_RingTimeout.Value, 10);

        config.tradesPinCode = sp_pin.Value;
      }

      unchangedConfig = _.cloneDeep(config);
      setConfig(config);
      setDataloaded(true);
    }
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: updateInfo,
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: updateInfo,
      executeOnStartup: false,
    },
  ];

  function save(configToSave: iConfig): void {
    console.info('***************Saving:', configToSave);

    logger.info('doorEntryConfig.save', {
      config: configToSave,
    });

    const from = configToSave.from.split(':');
    const to = configToSave.to.split(':');

    const sp_startHour = manifest.getSystemParameter('TRADESMAN_ON_HOUR');
    const sp_startMinute = manifest.getSystemParameter('TRADESMAN_ON_MINUTE');
    const sp_stopHour = manifest.getSystemParameter('TRADESMAN_OFF_HOUR');
    const sp_stopMinute = manifest.getSystemParameter('TRADESMAN_OFF_MINUTE');

    const sp_RingTimeout = manifest.getSystemParameter(
      'DOOR_PANEL_RING_TIMEOUT'
    );

    const sp_mon = manifest.getSystemParameter('TRADESMAN_MONDAY');
    const sp_tues = manifest.getSystemParameter('TRADESMAN_TUESDAY');
    const sp_wed = manifest.getSystemParameter('TRADESMAN_WEDNESDAY');
    const sp_thur = manifest.getSystemParameter('TRADESMAN_THURSDAY');
    const sp_fri = manifest.getSystemParameter('TRADESMAN_FRIDAY');
    const sp_sat = manifest.getSystemParameter('TRADESMAN_SATURDAY');
    const sp_sun = manifest.getSystemParameter('TRADESMAN_SUNDAY');
    const sp_pin = manifest.getSystemParameter('TRADESMAN_PIN');

    if (
      sp_startHour &&
      sp_startMinute &&
      sp_stopHour &&
      sp_stopMinute &&
      sp_mon &&
      sp_tues &&
      sp_wed &&
      sp_thur &&
      sp_fri &&
      sp_sat &&
      sp_sun &&
      sp_pin &&
      sp_RingTimeout
    ) {
      sp_startHour.setFromSring(from[0]);
      sp_startMinute.setFromSring(from[1]);
      sp_stopHour.setFromSring(to[0]);
      sp_stopMinute.setFromSring(to[1]);

      configToSave.daysEnabled.includes(dayOptions[teDaysOfWeek.Monday])
        ? sp_mon.setFromNumber(1)
        : sp_mon.setFromNumber(0);
      configToSave.daysEnabled.includes(dayOptions[teDaysOfWeek.Tuesday])
        ? sp_tues.setFromNumber(1)
        : sp_tues.setFromNumber(0);
      configToSave.daysEnabled.includes(dayOptions[teDaysOfWeek.Wednesday])
        ? sp_wed.setFromNumber(1)
        : sp_wed.setFromNumber(0);
      configToSave.daysEnabled.includes(dayOptions[teDaysOfWeek.Thursday])
        ? sp_thur.setFromNumber(1)
        : sp_thur.setFromNumber(0);
      configToSave.daysEnabled.includes(dayOptions[teDaysOfWeek.Friday])
        ? sp_fri.setFromNumber(1)
        : sp_fri.setFromNumber(0);
      configToSave.daysEnabled.includes(dayOptions[teDaysOfWeek.Saturday])
        ? sp_sat.setFromNumber(1)
        : sp_sat.setFromNumber(0);
      configToSave.daysEnabled.includes(dayOptions[teDaysOfWeek.Sunday])
        ? sp_sun.setFromNumber(1)
        : sp_sun.setFromNumber(0);

      sp_RingTimeout.setFromNumber(configToSave.ringTimeoutSeconds);
      sp_pin.Value = configToSave.tradesPinCode;

      setDialogueText('Please wait.....');
      setSaving(true);
      setShowDialogue(true);

      const complete = (str: string) => {
        setDialogueText(str);
        setSaving(false);
      };

      let str = 'Save failed';
      manifest
        .save()
        .then((status) => {
          if (status === teErrorCode.E_OK) {
            str = 'Save Completed Successfully';
          } else if (status === teErrorCode.E_CONNECTION_FAIL) {
            str = 'Connection failed';
            logger.info('doorEntryConfig.saveFailed', {
              status: status,
              error: {},
            });
          }
          complete(str);
        })
        .catch((error) => {
          console.error('trades save fail:', error);
          logger.info('doorEntryConfig.saveFailed', {
            status: teErrorCode.E_ERROR,
            error: error,
          });
          complete(str);
        });
    }
  }

  const configChanged = () => {
    return !_.isEqual(unchangedConfig, config);
  };

  const waitProps: Wait.DialogueProps = {
    show: showDialogue,
    dialogueText: dialogueText,
    showSpinner: saving,
    showOkButton: !saving,
    okButtonPress: () => {
      setShowDialogue(false);
    },
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={configChanged() ? 'brand' : 'grey-4'}
      badge={configChanged()}
      tip="Save changes"
      disabled={configChanged() ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save(config);
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Discard Changes',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('doorEntryConfig.discardChanges');
            updateInfo();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="Door Configuration"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={waitProps}
      ContextButtons={ContextButtons}
      menuProps={menuItems}
    >
      <>
        {dataloaded && (
          <>
            <p>Trades Entry</p>
            <Form
              validate="change"
              value={config}
              onChange={(nextValue) => {
                setConfig(nextValue);
              }}
            >
              <Box direction="row" pad="xsmall" justify="center">
                <FormField name="from" label="from" direction="row">
                  <Box width="110px">
                    <TextInput
                      name="from"
                      type="time"
                      // Set this style to make the time icon white
                      style={{ colorScheme: 'dark' }}
                    />
                  </Box>
                </FormField>

                <FormField name="to" label="until" direction="row">
                  <Box width="110px">
                    <TextInput
                      name="to"
                      type="time"
                      // Set this style to make the time icon white
                      style={{ colorScheme: 'dark' }}
                    />
                  </Box>
                </FormField>
                <FormField
                  name="tradesPinCode"
                  direction="row"
                  label="pin"
                  validate={{
                    regexp: /^[0-9]{4,4}$/,
                    message: '4 digits',
                  }}
                >
                  <Box width="110px">
                    <TextInput
                      name="tradesPinCode"
                      type="text"
                      reverse={true}
                      icon={<Lock />}
                      minLength={4}
                      maxLength={4}
                    />
                  </Box>
                </FormField>
              </Box>

              <Box>
                <FormField name="daysEnabled">
                  <CheckBoxGroup
                    name="daysEnabled"
                    gap="small"
                    options={dayOptions}
                    direction="row"
                  />
                </FormField>
              </Box>

              <p>Call Timeout</p>

              <Box direction="row" pad="xsmall" justify="center">
                <FormField
                  name="ringTimeoutSeconds"
                  label="Ringing Timeout (Seconds)"
                  direction="row"
                >
                  <Box width="110px">
                    <TextInput
                      name="ringTimeoutSeconds"
                      type="number"
                      // Set this style to make the time icon white
                      style={{ colorScheme: 'dark' }}
                      min={30}
                      max={300}
                    />
                  </Box>
                </FormField>
              </Box>
            </Form>
          </>
        )}
      </>
    </Tile>
  );
};

export default DoorEntryConfigOld;
