import { teCLOUD_ERROR_CODES } from './IoTMessageDefines';

export enum teCLOUD_MSG_TY_CFG_QUERY {
  E_CLOUD_MSG_QUERY_CFG_CONSOLE_INPUT = 0,
  E_CLOUD_MSG_QUERY_CFG_CONSOLE_OUTPUT,
  E_CLOUD_MSG_QUERY_CFG_GET_SYS_PARAM_REQ,
  E_CLOUD_MSG_QUERY_CFG_SET_SYS_PARAM_REQ,
  E_CLOUD_MSG_QUERY_CFG_GETSET_SYS_PARAM_RES,
  E_CLOUD_MSG_QUERY_CFG_RUNSQL_REQ,
  E_CLOUD_MSG_QUERY_CFG_RUNSQL_RESP,
  E_CLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_REQ,
  E_CLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_RES,
  E_CLOUD_MSG_QUERY_CFG_SET_DEVICE_PARAM_REQ,
  E_CLOUD_MSG_QUERY_CFG_SET_DEVICE_PARAM_RES,
  E_CLOUD_MSG_QUERY_CFG_MAX,
}

export interface tsCLOUD_MSG_CFG_QUERY_CONSOLE_INPUT {
  u32LineSize: number;
  line: string;
}

export interface tsCLOUD_MSG_CFG_QUERY_CONSOLE_OUTPUT {
  u32LineSize: number;
  line: string;
}

export interface tsCLOUD_MSG_CFG_QUERY_GET_SYSTEM_PARAMETERS {
  systemParameterNumber: number;
}

export interface tsCLOUD_MSG_CFG_QUERY_SET_SYSTEM_PARAMETERS {
  systemParameterNumber: number;
  value: string;
}

export interface tsCLOUD_MSG_QUERY_CFG_GETSET_SYS_PARAM_RES {
  systemParameterNumber: number;
  errorcode: teCLOUD_ERROR_CODES;
  value: string;
}

export interface tsCLOUD_MSG_QUERY_CFG_RUNSQL_REQ {
  forceBackup: boolean;
  deviceChangedRepopulateCaches: boolean;
  actionManifestUpload: boolean;
  sql: string;
}

export interface tsCLOUD_MSG_QUERY_CFG_RUNSQL_RESP {
  errorcode: number;
  query: boolean;
  sqlData: unknown;
}

export interface tsCLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_REQ {
  mac: number;
  parameter: number;
  deviceprocessor: number;
}

export interface tsCLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_RES {
  mac: number;
  parameter: number;
  deviceprocessor: number;
  type: number;
  found: boolean;
  value: string;
}

/*  TYPE IS NOT USED FOR VRUS
   E2_INTEGER = 0,
   E2_STRING,
   E2_STRUCT,
*/
export interface tsCLOUD_MSG_QUERY_CFG_SET_DEVICE_PARAM_REQ {
  mac: number;
  parameter: number;
  deviceprocessor: number;
  type: number;
  value: string;
}

export interface tsCLOUD_MSG_QUERY_CFG_SET_DEVICE_PARAM_RES {
  mac: number;
  parameter: number;
  deviceprocessor: number;
  set: number;
}
