import { Box, Button, Text, Layer, FileInput, Spinner } from 'grommet';
import React, { useEffect, useState } from 'react';
import * as ExcelJS from 'exceljs';

let _headersMatched = false;

export const ImportExcel = (props: {
  title: string;
  numberOfColumns: number; // how many columns of data we expect
  expectedHeaders: string[]; // the text expected in the first column to locate the row header
  doImport: (data: string[][]) => Promise<void>; // import function (async) for actioning the spreadsheet data
  display: boolean; // whether to display the form
  setDisplay: (arg0: boolean) => void; // function to set the display visibility
  allowAppend: boolean; // whether to allow append to existing
}): React.ReactElement => {
  const [file, setFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [importComplete, setImportComplete] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importItems, setImportItems] = useState<string[][]>([]);

  const importAllItems = async () => {
    if (file && importItems && importItems.length > 0) {
      setImporting(true);
      props.doImport(importItems).then(async () => {
        setImportComplete(true);
        setImporting(false);
        setFile(null);
        props.setDisplay(false);
      });
    }
  };

  const filesChanged = (e: any, data: { files: File[] } | undefined) => {
    console.log('files changed');
    if (data) {
      const fileList = data.files;
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];
        console.log(file.name);
      }
      setFile(fileList[0]);
      // read the file...
      if (fileList[0]) {
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(fileList[0]);

        const items: any[] = [];
        _headersMatched = false;

        reader.onload = () => {
          const buffer = reader.result;
          if (buffer) {
            wb.xlsx.load(buffer as ArrayBuffer).then((workbook) => {
              //console.log(workbook, 'workbook instance');
              // workbook.eachSheet((sheet, id) => {
              const sheet = workbook.getWorksheet(1);

              if (sheet)
                sheet.eachRow((row, rowIndex) => {
                  if (rowIndex != 1) {
                    if (_headersMatched) {
                      // convert row to array of text
                      const r: string[] = [];
                      for (let c = 1; c <= props.numberOfColumns; c++) {
                        r.push(row.getCell(c).text);
                      }
                      // console.log(r);
                      items.push(r);
                    } else if (
                      row.actualCellCount >= props.numberOfColumns
                      // row.getCell(1).text == props.firstRowHeader
                    ) {
                      const num = props.expectedHeaders.length;
                      if (row.actualCellCount >= num) {
                        let match = true;
                        for (let h = 1; h <= num; h++) {
                          if (
                            row.getCell(h).text != props.expectedHeaders[h - 1]
                          )
                            match = false;
                        }
                        console.log('found header', row.actualCellCount);
                        _headersMatched = match;
                      }
                    }
                  }
                });

              // console.log(items);
              setImportItems(items);
            });
            // });
          }
        };
      }
    }
  };

  useEffect(() => {
    if (!props.display) {
      setImportItems([]);
      setFile(null);
    }
  }, [props.display]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.display && (
        <Layer
          className={'waitdialog'}
          background="dialogBackground"
          style={{ whiteSpace: 'pre-wrap' }}
          modal={false}
          onClickOutside={() => props.setDisplay(false)}
        >
          <Box pad="none" gap="none" border="all">
            <Box margin="none" border="bottom" background="brand">
              <Text
                size="medium"
                margin="small"
                alignSelf="center"
                color="white"
              >
                {props.title}
              </Text>
            </Box>
            <Box direction="column" margin="none" pad="none" width="large">
              <Box pad="small" margin="none" height="100%">
                <Box height="100%" pad="xsmall">
                  <Box direction="row" width="100%">
                    <FileInput
                      id="fileInput"
                      name="fileInput"
                      maxSize={2000000}
                      multiple={false}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={(event, data: { files: File[] } | undefined) =>
                        filesChanged(event, data)
                      }
                      messages={{
                        dropPrompt: ' ',
                        browse: 'Browse for .XLSX spreadsheet...',
                      }}
                    />
                  </Box>
                </Box>

                {file && importComplete == false && (
                  <Box
                    margin="5px"
                    pad="small"
                    // background="#eee"
                    border={{
                      color: 'black',
                      size: '3px',
                      style: 'solid',
                      side: 'all',
                    }}
                  >
                    {importing && <Spinner margin="small" />}
                    <Text weight="bold" truncate>
                      {file.name}
                    </Text>

                    <Text weight="bold">
                      {`File Size:  ${
                        file.size < 1024000
                          ? `${Math.round(file.size / 1024)} KB`
                          : `${Math.round(file.size / 1024000)} MB`
                      }`}
                    </Text>
                    <Text weight="bold" truncate>
                      {_headersMatched
                        ? `Headers Matched OK: Number of items: ${importItems.length}`
                        : 'Headers not found or incorrect, check that the file matches an export file layout'}
                    </Text>
                  </Box>
                )}
                <Box direction="row" width="80%">
                  <Button
                    label="Append to Existing Data"
                    size="small"
                    margin="small"
                    disabled={
                      !(importItems && importItems.length > 0) ||
                      importing ||
                      !props.allowAppend
                    }
                    onClick={() => {
                      importAllItems();
                    }}
                  />
                  <Button
                    label="Replace Existing Data"
                    size="small"
                    margin="small"
                    disabled={
                      !(importItems && importItems.length > 0) || importing
                    }
                    onClick={async () => {
                      // await props.clearExistingData();
                      await importAllItems();
                    }}
                  />
                  <Button
                    label="Cancel/Close"
                    size="small"
                    margin="small"
                    onClick={() => {
                      props.setDisplay(false);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
