/** @module Installation */

import React from 'react';
import { iAccessPoint, invalidID, iRoutingEvent } from 'types/manifest-types';
import { Device, teINPUT_METHOD } from './device';
import { iDevice } from '../../types/manifest-types';
import { Product } from 'components/floorplan/product';
import { ALARM_TYPES } from 'types/event-enums';
import { teDEVICE_MODELS_ROUTER } from 'types/manifest-enums';
import * as manifestUtils from 'components/installation/manifestUtils';

/*
 * Access Point device class
 * @class
 * @hideconstructor
 * @implements iRoom
 */

export class AccessPoint extends Device implements iAccessPoint {
  ID = invalidID;
  Name = '';
  DeviceId = invalidID;
  UnitId = 9000;

  constructor(
    APValues: iAccessPoint,
    deviceValues: iDevice,
    routingEvents: iRoutingEvent[],
    keys: string[],
    tableName: string
  ) {
    super(deviceValues, routingEvents);
    Object.assign(this, APValues);
    this.productManifest.manifestObjectKeys = keys;
    this.productManifest.tablename = tableName;
    this.productManifest.dataChanged = false;
    this.productManifest.tableID = this.ID;
    this.ID = deviceValues.ID;

    if (this.Name === undefined || this.Name === null) {
      this.Name = ``;
    }

    if (deviceValues.HwModel == teDEVICE_MODELS_ROUTER.DEVICE_MODEL_MAP) {
      this.InputAccessor = {
        Method: teINPUT_METHOD.INPUT_METHOD_MULTI_EVENT,
        RoutingEventTypes: [
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_OPEN_EVENT,
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT,
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_OPEN_EVENT,
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_CLOSE_EVENT,
        ],
      };
      this.pullHardwiredInputsFromRoutingEvents();
    }
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(
      this,
      this.productManifest,
      super.generateChangeSQL()
    );
    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    await super.applyManifestChanges();
    return manifestUtils.applyManifestChanges(this, this.productManifest);
  }

  toolTip(): JSX.Element {
    const prod = Product.getProductfromManifestDeviceType(this);
    return (
      <div>
        <p>Access Point - {this.Name}</p>
        <ul>
          <li>Device ID: {this.DeviceId.toString()}</li>
          <li>MAC Address:{this.getDeviceMACaddr()}</li>
          <li>
            Part Code:
            {prod?.productCode}
          </li>
          <li>
            Part Description:
            {prod?.name}
          </li>
        </ul>
      </div>
    );
  }
}
