import React, { useState } from 'react';
import Tile, { iMenuProps, contextButtonStyle } from '../tile';
import { Form, Text, Button, Box } from 'grommet';
import _ from 'lodash';
import * as Icons from 'grommet-icons';
import i18next from 'i18n';
import * as UserUtils from 'common/userUtils';
import * as FormFields from 'components/forms/formFieldsOld';

// We only use a subset of fields from the iUserConfig
type tUserConfig = Pick<
  UserUtils.iUserConfig,
  | 'receiveSiteAlertsSMS'
  | 'receiveSiteAlertsEmail'
  | 'smsAlertPhoneNumber'
  | 'reportInterval'
>;

const defaults: tUserConfig = {
  receiveSiteAlertsSMS: false,
  receiveSiteAlertsEmail: false,
  smsAlertPhoneNumber: '',
  reportInterval: UserUtils.eReportSchedule.E_REPORT_INTERVAL_NONE,
};

let unchangedConfig: tUserConfig = defaults;

const userReportIntervalOptions = [
  {
    id: UserUtils.eReportSchedule.E_REPORT_INTERVAL_NONE,
    name: 'No',
  },
  {
    id: UserUtils.eReportSchedule.E_REPORT_INTERVAL_DAILY,
    name: UserUtils.getReportIntervalString(
      UserUtils.eReportSchedule.E_REPORT_INTERVAL_DAILY
    ),
  },
  {
    id: UserUtils.eReportSchedule.E_REPORT_INTERVAL_WEEKLY,
    name: UserUtils.getReportIntervalString(
      UserUtils.eReportSchedule.E_REPORT_INTERVAL_WEEKLY
    ),
  },
];

const siteAlertTooltip = (
  <Text style={{ whiteSpace: 'pre-wrap' }}>
    {i18next.t('site alerts user info', {})}
  </Text>
);

const fields: FormFields.iField[] = [
  {
    label: 'Receive site alerts by Email',
    id: 'receiveSiteAlertsEmail',
    type: 'checkbox',
    tooltip: siteAlertTooltip,
  },
  {
    label: 'Receive site alerts by SMS',
    id: 'receiveSiteAlertsSMS',
    type: 'checkbox',
    tooltip: siteAlertTooltip,
  },
  {
    label: 'SMS Phone Number',
    id: 'smsAlertPhoneNumber',
    type: 'text',
    tooltip: siteAlertTooltip,
  },
  {
    label: 'Receive Call Reports',
    id: 'reportInterval',
    type: 'select',
    options: userReportIntervalOptions,
    formBoxWidth: 'small',
    tooltip: (
      <Text style={{ whiteSpace: 'pre-wrap' }}>
        {i18next.t('call report info', {})}
      </Text>
    ),
  },
];

const UserSettings = (): JSX.Element => {
  const [config, setConfig] = useState<tUserConfig>(defaults);

  const [, SetChildEvent] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);

  async function updateInfo() {
    const userCfg: UserUtils.iUserConfig =
      await UserUtils.getCurrentUserConfigAttributes();

    unchangedConfig = _.cloneDeep(userCfg);

    if (
      unchangedConfig.reportInterval >=
      UserUtils.eReportSchedule.E_REPORT_INTERVAL_INVALID
    ) {
      unchangedConfig.reportInterval =
        UserUtils.eReportSchedule.E_REPORT_INTERVAL_NONE;
    }

    setConfig(unchangedConfig);
    setShowSpinner(false);
  }

  function convertToInternationalPhoneNumber(phoneNumber: string) {
    let internationalPhoneNumber = phoneNumber;
    if (phoneNumber.startsWith('+') == false) {
      const countryCode = '+44';
      const cleanedPhoneNumber = phoneNumber.replace(/^0+/, ''); // Remove leading zeros
      internationalPhoneNumber = countryCode + cleanedPhoneNumber;
    }
    return internationalPhoneNumber;
  }

  const save = async (): Promise<void> => {
    console.info('Saving user settings');

    const phoneNumber = convertToInternationalPhoneNumber(
      config.smsAlertPhoneNumber
    );

    // Perform read mondify write
    const userCfg: UserUtils.iUserConfig =
      await UserUtils.getCurrentUserConfigAttributes();

    const dataCopy = _.cloneDeep(userCfg);

    dataCopy.receiveSiteAlertsSMS = config.receiveSiteAlertsSMS;
    dataCopy.receiveSiteAlertsEmail = config.receiveSiteAlertsEmail;
    dataCopy.smsAlertPhoneNumber = phoneNumber;
    dataCopy.reportInterval = config.reportInterval;

    UserUtils.setCurrentUserConfigAttributes(dataCopy).then(async () => {
      await updateInfo();
    });
  };

  const configChanged = () => {
    return !_.isEqual(unchangedConfig, config);
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={configChanged() ? 'brand' : 'grey-4'}
      badge={configChanged()}
      tip="Save changes"
      disabled={configChanged() ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        setShowSpinner(true);
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Discard Changes',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            updateInfo();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  React.useEffect(() => {
    updateInfo();
    // UseEffect's cleanup function
    return () => {
      console.info('Unmount');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title="User Settings"
      setChildEvent={SetChildEvent}
      ContextButtons={ContextButtons}
      menuProps={menuItems}
      showSpinner={showSpinner}
      spinnerText=""
    >
      <>
        <Form<tUserConfig>
          value={config}
          onReset={() => setConfig(unchangedConfig)}
          onChange={(nextValue) => {
            setConfig(nextValue);
          }}
        >
          <Box direction="column">
            <FormFields.AlignedFormFields displayfields={fields} />
          </Box>
        </Form>
      </>
    </Tile>
  );
};

export default UserSettings;
