import * as eventDispatcher from 'store/eventDispatcher';
import store from 'store/store';
import * as clientslice from 'store/clientsSlice';
import * as sitesslice from 'store/sitesSlice';
import * as siteList from 'components/sites/siteList';
import * as appUserSlice from 'store/appUserSlice';
import * as siteplanslice from 'store/sitePlanSlice';
import * as scuslice from 'store/scusSlice';
import { SCUIoTMessaging } from '../common/IoT/SCUIoTMessaging';
import * as manifestSlice from 'store/manifestSlice';
import * as faultsslice from 'store/scuFaultsSlice';
import * as eventsslice from 'store/scuEventsSlice';
import * as liveeventlogslice from 'store/liveEventsLogSlice';
import * as API from 'types/API';
import * as userUtils from 'common/userUtils';
import * as siteAuth from 'common/siteAuthUtils';
import * as IotCfgChange from 'common/IoT/IoTConfigChange';
import { Manifest } from 'components/installation/manifest';
import * as alarmEvents from 'components/systemevents/alarmEvents';
import logger from 'common/logger';
import { isEmpty } from 'common/utils/miscUtils';

const manifest = new Manifest();

logger.debug('dataSequencer module load');

enum teLOAD_STATES {
  E_STATE_IDLE = 0,
  E_SITES_LOADING,
  E_STATE_USER_AUTHORISED,
  E_STATE_USER_NOT_AUTHORISED,
}

let m_eState = teLOAD_STATES.E_STATE_IDLE;

const m_maxInitalEvents = 1500;
const m_secondBatchEventsDaysToLoad = 14;
const m_liveEventsDaysToLoad = 2;

type tCompletionionEvents = {
  [key: string]: boolean;
};

const m_completionionEvents: tCompletionionEvents = {
  gotFloorPlan: false,
  gotManifest: false,
  gotFaults: false,
  gotEvents: false,
  gotLiveEvents: false,
};
let m_loadedEventSent = false;

function registerForStateEvent(
  topic: eventDispatcher.systemEventTopics,
  event: eventDispatcher.systemEventStates
): string {
  const eventString = eventDispatcher.subjectString(topic, event);
  eventDispatcher.registerForEvent(topic, event, (e) => {
    // eslint-disable-next-line  @typescript-eslint/no-use-before-define
    loadStateMachine(eventString, e);
  });
  return eventString;
}

const userAuthorisedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.USER,
  eventDispatcher.systemEventStates.AUTHORISED
);

const userNotAuthorisedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.USER,
  eventDispatcher.systemEventStates.NOTAUTHORISED
);

const siteLoadedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.SITE,
  eventDispatcher.systemEventStates.LOADED
);

const siteSelectedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.SITE,
  eventDispatcher.systemEventStates.SELECTED
);

const siteLoadErrorEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.SITE,
  eventDispatcher.systemEventStates.LOADERROR
);

const scusLoadEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.SCU,
  eventDispatcher.systemEventStates.LOADED
);

const scuSelectedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.SCU,
  eventDispatcher.systemEventStates.SELECTED
);

const manifestLoadedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.MANIFEST,
  eventDispatcher.systemEventStates.LOADED
);

const faultsLoadedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.FAULTS,
  eventDispatcher.systemEventStates.LOADED
);

const eventsLoadedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.SITEEVENTS,
  eventDispatcher.systemEventStates.LOADED
);

const liveEventsLoadedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.LIVEEVENTS,
  eventDispatcher.systemEventStates.LOADED
);

const floorPlanLoadedEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.FLOORPLANS,
  eventDispatcher.systemEventStates.LOADED
);

const manifestRefreshNeededEvent = registerForStateEvent(
  eventDispatcher.systemEventTopics.MANIFEST,
  eventDispatcher.systemEventStates.REFRESHNEEDED
);

/**
 * Dummy function so that it can be called in App.js and stop warning that module isn't used.
 */
export function init(): void {
  logger.debug('dataSequencer init');
}

export async function getMoreData(days: number): Promise<void> {
  const selectedSCU = scuslice.getSelected(store.getState());
  if (selectedSCU) {
    await store.dispatch(
      eventsslice.fetchMore({
        SCUId: selectedSCU.id,
        daysToFetch: days,
        startDate: alarmEvents.getOldestEntry(),
      })
    );
  }
}

export async function getMoreDataInRange(
  startDate: Date,
  endDate: Date,
  maxEvents: number
): Promise<number> {
  const selectedSCU = scuslice.getSelected(store.getState());

  const days =
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

  let recordsReturned = 0;
  console.info('Days to get:', days);
  console.info('startDate date:', startDate);
  console.info('endDate date:', endDate);

  if (selectedSCU) {
    // eventsslice.fetchMore works backwards.
    // The date range that will be pulled = (startDate-days) until startDate
    const resp = await store.dispatch(
      eventsslice.fetchMore({
        SCUId: selectedSCU.id,
        daysToFetch: days,
        startDate: endDate,
        maxEvents: maxEvents,
      })
    );

    if (Array.isArray(resp.payload)) {
      recordsReturned = resp.payload.length;
    }
  }
  return recordsReturned;
}

function loadSite(selectid: string) {
  if (!isEmpty(selectid)) {
    console.info('***** Site selection change:', selectid);
    IotCfgChange.unregister();
    manifest.clear();

    const siteSelected = sitesslice.getSelected(store.getState());

    Object.keys(m_completionionEvents).forEach((key) => {
      m_completionionEvents[key] = false;
    });
    m_loadedEventSent = false;

    if (siteSelected && !isEmpty(siteSelected.floorPlanFolder)) {
      store.dispatch(
        siteplanslice.fetch(siteSelected.floorPlanFolder as string)
      );
    } else {
      console.error('No floor plan set in the sites database');
    }

    if (siteSelected) {
      store.dispatch(scuslice.clearSelected({}));
      store.dispatch(scuslice.fetch(siteSelected));
    }
  }
}

function loadSCU(allSCUS?: API.OmniviaSCU[]) {
  if (allSCUS && allSCUS.length > 0) {
    store.dispatch(scuslice.select({ id: allSCUS[0].id }));
  } else {
    console.info('NO SCUS for site');
    siteList.selectionHasChanged(sitesslice.getSelected(store.getState()));
  }
}

function loadManifest(selectedSCU?: API.OmniviaSCU) {
  if (selectedSCU && !isEmpty(selectedSCU.id)) {
    console.info('***** SCU selection change:', selectedSCU.id);

    // to force the site to upload another manifest call:
    //const manifest = IoTSCUManifest.Instance;
    //manifest.requestManifestUpload(scuSelected.serialNumber);

    SCUIoTMessaging.Instance.setSCUSerialNumber(selectedSCU.serialNumber);

    store.dispatch(manifestSlice.fetch(selectedSCU.serialNumber));
    siteList.selectionHasChanged(sitesslice.getSelected(store.getState()));
  }
}

async function checkLoadedCompleted(event: string) {
  console.info('checkLoadedCompleted: event:', event);

  if (event == floorPlanLoadedEvent) {
    m_completionionEvents.gotFloorPlan = true;

    if (sitesslice.siteHasSCUAssigned()) {
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.SITEEVENTS,
        eventDispatcher.systemEventStates.INITIAL_LOAD_COMPLETE,
        null,
        true
      );
    }
  }

  if (event == manifestLoadedEvent) {
    m_completionionEvents.gotManifest = true;
    IotCfgChange.register();

    const selectedSCU = scuslice.getSelected(store.getState());
    if (selectedSCU) {
      store.dispatch(faultsslice.fetch(selectedSCU));
      store.dispatch(
        eventsslice.fetchAll({
          SCUId: selectedSCU.id,
          maxEvents: m_maxInitalEvents,
        })
      );
      store.dispatch(
        liveeventlogslice.fetch({
          SCUSerialNumber: selectedSCU.serialNumber,
          daysToFetch: m_liveEventsDaysToLoad,
        })
      );
    }
  }

  if (event == faultsLoadedEvent) {
    m_completionionEvents.gotFaults = true;
  }

  if (event == eventsLoadedEvent && !m_completionionEvents.gotEvents) {
    m_completionionEvents.gotEvents = true;

    // Now load in the remaining events, we will have already load the initial days
    const selectedSCU = scuslice.getSelected(store.getState());
    if (selectedSCU) {
      store.dispatch(
        eventsslice.fetchMore({
          SCUId: selectedSCU.id,
          daysToFetch: m_secondBatchEventsDaysToLoad,
          startDate: alarmEvents.getOldestEntry(),
        })
      );
    }
  }

  if (event == liveEventsLoadedEvent) {
    m_completionionEvents.gotLiveEvents = true;
  }

  console.info('load events: ', m_completionionEvents);

  let complete = true;
  Object.keys(m_completionionEvents).forEach((key) => {
    if (m_completionionEvents[key] == false) {
      complete = false;
    }
  });

  if (complete) {
    m_loadedEventSent = true;
    console.info('Load complete sending SITEEVENTS COMPLETE');
    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.SITEEVENTS,
      eventDispatcher.systemEventStates.COMPLETE,
      null,
      true
    );
  }
}

async function loadStateMachine(
  event: string,
  eventMsg: eventDispatcher.iEventParams
) {
  console.info(`Event ${event} in state ${m_eState} `);

  switch (m_eState) {
    case teLOAD_STATES.E_STATE_IDLE:
      if (event == userAuthorisedEvent) {
        m_eState = teLOAD_STATES.E_SITES_LOADING;
        store.dispatch(clientslice.fetch());
        store.dispatch(sitesslice.fetch());
      } else if (event == userNotAuthorisedEvent) {
        m_eState = teLOAD_STATES.E_STATE_USER_NOT_AUTHORISED;
      }
      break;

    case teLOAD_STATES.E_SITES_LOADING:
      if (event == siteLoadedEvent) {
        // Ensure that sites have groups created for them
        // and that the site row has the groupsCanAccess with the group
        // name in.
        if (userUtils.getCurrentUserAccessLevel() == userUtils.UAG.ADMIN) {
          await siteAuth.setupAllSiteAuthorisation();
        }

        siteList.load(sitesslice.selectAll(store.getState()));
        m_eState = teLOAD_STATES.E_STATE_USER_AUTHORISED;
        // UI triggers off the appDataLoaded
        store.dispatch(appUserSlice.appDataLoaded(true));
      } else if (event == siteLoadErrorEvent) {
        m_eState = teLOAD_STATES.E_STATE_USER_AUTHORISED;
      }
      break;

    case teLOAD_STATES.E_STATE_USER_AUTHORISED:
      if (event == siteSelectedEvent) {
        loadSite(eventMsg.detail as string);
      } else if (event == scusLoadEvent) {
        loadSCU(scuslice.getAll(store.getState()));
      } else if (event == scuSelectedEvent) {
        loadManifest(scuslice.getSelected(store.getState()));
      }

      if (!m_loadedEventSent) {
        checkLoadedCompleted(event);
      }

      if (event == manifestRefreshNeededEvent) {
        console.info('manifestRefreshNeededEvent:', eventMsg);
        store.dispatch(manifestSlice.fetch(eventMsg.detail as string));
      }
      break;

    case teLOAD_STATES.E_STATE_USER_NOT_AUTHORISED:
      break;

    default:
      // ignore
      break;
  }
}
