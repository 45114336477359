import React from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent } from '../../tile';

import RecentAlarmsTable from './recentAlarmsTable';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RecentAlarmsOverview = (props: any): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const loadDataEvent = () => {
    // console.info('recentAlarmsOverview.tsx Loadevent');
  };

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.ALARMEVENTS,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
  ];

  return (
    <>
      <Tile
        title="Recent Alarms"
        eventListeners={events}
        setChildEvent={SetChildEvent}
      >
        <>
          <RecentAlarmsTable room={props.view?.room} />
        </>
      </Tile>
    </>
  );
};

export default RecentAlarmsOverview;
