import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
//import { useWinstonLogger } from 'winston-react';
import releaseNote from 'changelog';
import { Box } from 'grommet';
import './version.scss';
// the release note = system version screen
const Version = () => {
  //const logger = useWinstonLogger();

  const [markdown, setMarkdown] = useState('');

  console.log('version');

  useEffect(() => {
    setMarkdown(releaseNote);
    //logger.info('webapp.system.releasehistory');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      className="versioncontainer"
      align="start"
      margin={{ left: '20px' }}
      style={{ textAlign: 'start' }}
      height="90vh"
      overflow="auto"
    >
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </Box>
  );
};

export default Version;
