import React from 'react';
import { Header as GrommetHeader } from 'grommet';

const HelpHeader = () => {
  return (
    <GrommetHeader
      background="grey-9"
      justify="start"
      pad={{ left: 'medium', top: 'small', bottom: 'small' }}
    ></GrommetHeader>
  );
};

export default HelpHeader;
