import {
  formatDate,
  formatDateOnly,
  formatTimeOnly,
} from 'common/utils/dateUtils';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import store from 'store/store';
import * as sitesslice from 'store/sitesSlice';

export interface iExcelColumn {
  columnName: string;
  columnWidth: number;
  fieldName: string;
  type?: 'TEXT' | 'DATE' | 'TIME' | 'DATETIME';
}

export interface iExcelSchema {
  reportName: string; // name of the report in the tables export button that the user clicks on
  reportDescription: string; // explanation of the report
  filteredData: boolean; // whether to use a tables filtered data or all data true=filtered
  fileName: string; // excel file name to create
  workSheetName?: string; // worksheet name
  title: string; // Title on worksheet
  columns: iExcelColumn[]; // column information
  headerRows?: (sheet: ExcelJS.Worksheet) => void; // callback to add header rows
  fillBackColor?: string; // what color to fill the table header with
  fillTextColor?: string; // what color for the table header text
}

/** Function to create an spreadsheet using a predefined schema and passed data */
export const createExcel = async (
  schema: iExcelSchema,
  data: any[]
): Promise<void> => {
  const wb = new ExcelJS.Workbook();
  const sheets: ExcelJS.Worksheet[] = [];
  let rowValues = [];

  sheets.push(wb.addWorksheet(schema.workSheetName ?? 'Sheet 1'));

  let Row = sheets[0].addRow([schema.title]);

  Row.getCell(1).style.font = {
    size: 16,
    bold: true,
  };

  sheets[0].addRow(['']);

  // Add any specific header rows
  if (schema.headerRows) schema.headerRows(sheets[0]);
  sheets[0].addRow(['']);

  const tableStartRow = sheets[0].rowCount;

  // Add the column headers and set the column widths

  for (let i = 0; i < schema.columns.length; i++) {
    rowValues[i] = schema.columns[i].columnName;
    // Set the column widths

    sheets[0].getColumn(i + 1).width = schema.columns[i].columnWidth;
  }
  Row = sheets[0].addRow(rowValues);
  // sheets[0].addRow(['']);

  for (let i = 1; i < schema.columns.length + 1; i++) {
    Row.getCell(i).style.font = {
      size: 11,
      color: { argb: 'ffffff' },
    };

    Row.getCell(i).style.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: schema.fillBackColor ?? '00ffff' },
    };

    Row.getCell(i).alignment = {
      vertical: 'middle',
      horizontal: 'left',
      wrapText: true,
    };
  }

  // Add the data
  for (let d = 0; d < data.length; d++) {
    const rowdata = data[d];
    rowValues = [];
    for (let i = 0; i < schema.columns.length; i++) {
      const s = schema.columns[i];
      const val = rowdata[`${s.fieldName}`];
      if (s.type && s.type == 'DATE') rowValues[i] = formatDateOnly(val);
      else if (s.type && s.type == 'DATETIME') rowValues[i] = formatDate(val);
      else if (s.type && s.type == 'TIME') rowValues[i] = formatTimeOnly(val);
      else rowValues[i] = val ?? '';
    }
    Row = sheets[0].addRow(rowValues);
  }

  // set each cell of each row to have a thin border
  sheets[0].eachRow((row, index) => {
    if (index > tableStartRow)
      row.eachCell((cell: ExcelJS.Cell) => {
        cell.style.font = { size: 9 };

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        cell.alignment = {
          vertical: 'middle',
          horizontal: 'left',
          wrapText: true,
        };
      });
  });

  const selectedSite = sitesslice.getSelected(store.getState());

  wb.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: '' });

    const site = selectedSite ? selectedSite.name : '';
    FileSaver.saveAs(
      blob,
      `${schema.fileName}-${site}.xlsx` // set name of file
    );
  });
};
