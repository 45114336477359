import React, { FC, useState, useRef } from 'react';
import { Tip, TextInput, Box } from 'grommet';
import { CellProps } from './commonCellProps';

const InputCell: FC<CellProps> = ({
  id,
  valueProperty,
  hasChanged,
  getProperty,
  setProperty,
  validator,
  inputType,
  toolTip,
  min,
  max,
  step,
}) => {
  const value = getProperty(id, valueProperty);
  const [, setComponentRenderCount] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  let _toolTip: string | JSX.Element | undefined = undefined;

  // console.log('input cell', hasChanged, value);

  if (typeof toolTip === 'function') {
    _toolTip = toolTip(id);
  } else if (toolTip) {
    _toolTip = toolTip;
  }

  const handleValidate = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (validator) {
      timeoutRef.current = setTimeout(() => {
        const revertValue = validator(id, getProperty(id, valueProperty));
        timeoutRef.current = undefined;
        if (revertValue !== undefined) {
          setProperty(id, valueProperty, revertValue);
          setComponentRenderCount((old) => old + 1);
        }
      }, 1000);
    }
  };

  const cell = (
    <Box background={hasChanged ? 'gold' : 'grey-0'}>
      <TextInput
        type={inputType}
        min={min}
        max={max}
        step={step}
        value={value}
        // style={{
        //   background: hasChanged ? 'gold' : 'grey-0',
        // }}
        onChange={(event) => {
          if (
            inputType != 'number' ||
            (event.target.value >= (min ?? 0) &&
              event.target.value <= (max ?? 9999999999))
          ) {
            setProperty(id, valueProperty, event.target.value);
            setComponentRenderCount((old) => old + 1);
            handleValidate();
          }
        }}
      />
    </Box>
  );

  return (
    <>
      {_toolTip && <Tip content={_toolTip}>{cell}</Tip>}
      {_toolTip === undefined && <>{cell}</>}
    </>
  );
};

const memorised = React.memo(InputCell);

export default memorised;
