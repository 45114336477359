// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function emptyObject(value: any): boolean {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

export const isISODate = (dateStr: string): boolean => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateStr)) return false;
  return true;
};

export const formatDate = (dateStr: string | Date | undefined): string => {
  let formatted = '';
  if (!dateStr) return formatted;

  const dateoptions: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  formatted = new Date(dateStr).toLocaleString([], dateoptions);

  return formatted;
};

export const getYear = (dateStr: string): string => {
  let formatted = '';
  if (!dateStr) return formatted;

  if (isISODate(dateStr)) {
    formatted = dateStr.substring(0, 4);
  } else {
    formatted = dateStr;
  }

  return formatted;
};

export const formatDateWithSeconds = (
  string: string | Date | undefined | null
): string => {
  if (!string) return '';
  const dateoptions: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(string).toLocaleString([], dateoptions);
};

export const formatDateOnly = (
  string: string | Date | undefined
): Date | string => {
  if (!string) return '';
  const dateoptions: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  return new Date(string).toLocaleString([], dateoptions);
};

export const formatTimeOnly = (
  string: string | Date | undefined
): Date | string => {
  if (!string) return '';
  const dateoptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(string).toLocaleString([], dateoptions);
};

export const formatTimeOnlyHHMM = (
  string: string | Date | undefined
): string => {
  if (!string) return '';
  const dateoptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(string).toLocaleString([], dateoptions);
};

export const getDateStringFromEpoch = (milliseconds: number): string => {
  const date = new Date();
  date.setTime(milliseconds * 1000);
  return date.toString();
};

export const getDateFromEpoch = (milliseconds: number): Date => {
  const date = new Date();
  date.setTime(milliseconds * 1000);
  return date;
};

export const formatDateFromEpoch = (milliseconds: number): string => {
  const date = new Date();
  date.setTime(milliseconds * 1000);
  return formatDate(date.toString());
};

export const formatDateSetMilliSecondsToZero = (
  string: string | Date | undefined | null
): string => {
  if (!string) return '';
  const ret = new Date(string);
  ret.setMilliseconds(0);
  return ret.toISOString();
};

// returns a Date object with the current date but the hours and minutes set to the parameter
// parameter in the format HH:MM
export const dateTodaySetTimeHHMM = (string: string): Date => {
  if (!string || string.length != 5 || !string.includes(':')) return new Date();

  const hoursStart = Number(string.substring(0, 2));
  const minutesStart = Number(string.substring(3, 5));

  const date = new Date();
  date.setHours(hoursStart, minutesStart, 0, 0);

  return date;
};

/**
 * Checks whether a date is today
 * @param dateToCheck
 * @returns
 */
export const dateIsToday = (dateToCheck: string | Date): boolean => {
  const today = new Date();
  const check = new Date(dateToCheck);
  return (
    check.getDate() == today.getDate() &&
    check.getMonth() == today.getMonth() &&
    check.getFullYear() == today.getFullYear()
  );
};

/**
 * Returns the number of hours between two dates
 * @param date1 - earlier date
 * @param date2 - later date
 * @returns
 */
export const dateHoursBetweenDates = (
  date1: string | Date,
  date2: string | Date
): number => {
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();
  const hours = Math.floor(Math.abs(d2 - d1) / 3600000);
  return hours;
};

/**
 * checks whether a date sites within a range
 * @param from - start of the range
 * @param to - end of the range
 * @param dateToCheck - the date/time to check ISOstring or Date object
 * @returns true if within the date range, false if not
 */
export const dateWithinDateRange = (
  from: string | Date,
  to: string | Date,
  dateToCheck: string | Date
): boolean => {
  const start = new Date(from).getTime();
  const end = new Date(to).getTime();

  const check = new Date(dateToCheck).getTime();

  // console.log(
  //   from,
  //   start,
  //   to,
  //   end,
  //   dateToCheck,
  //   check,
  //   check >= start && check <= end
  // );

  return check >= start && check <= end;
};

/**
 * returns true if a date/time is after a specified from date/time
 * @param from - the date/time that the check date must be after , string or Date object
 * @param dateToCheck - The date to check, either ISO string or Date object
 * @returns
 */
export const dateIsAfterDateTime = (
  from: string | Date,
  dateToCheck: string | Date
): boolean => {
  const start = new Date(from).getTime();
  const check = new Date(dateToCheck).getTime();

  return check >= start;
};

export const dateToIsoString_dateonly = (date: Date): string => {
  return (
    date.getFullYear() +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0')
  );
};

export function dateAddMonths(date: Date, months: number): Date {
  const month = (date.getMonth() + months) % 12;
  //create a new Date object that gets the last day of the desired month
  const last = new Date(date.getFullYear(), month + 1, 0);

  //compare dates and set appropriately
  if (date.getDate() <= last.getDate()) {
    date.setMonth(month);
  } else {
    date.setMonth(month, last.getDate());
  }

  return date;
}

/**
 * SubtractDays
 * @param date - Date to adjust
 * @param days - Adjust date back by unmber of days
 * @returns
 */
export function dateSubtractDays(date: Date, days: number): Date {
  date.setDate(date.getDate() - days);
  return date;
}

// return the current time and data as a human readable string
export const dateNowReadable = (): string => {
  return (
    new Date().toLocaleDateString() +
    ' ' +
    new Date().toLocaleTimeString('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
    })
  );
};

// return the current time and data as a human readable string
export const dateNowISO = (): string => {
  return new Date().toISOString();
};

/**
 * Returns the nunber of seconds from midnight of the passed Date object
 * @param {Date} time Only the hours,minutes and seconds are used from the object
 * @returns
 */
export const dateGetSecondsFromMidnight = (time: Date): number => {
  const today = new Date();

  //console.info('Date:', JSON.stringify(time));

  const timeToCalculate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds()
  );

  const midnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0
  );

  const msSinceMidnight = timeToCalculate.getTime() - midnight.getTime();
  return Math.round(msSinceMidnight / 1000);
};

/**
 * Returns a today date object with the seconds from midnight set as the time
 * @returns
 */
export const dateTodaySetSecondsFromMidnight = (
  secondsFromMidnight: number
): Date => {
  const midnight = dateTodaySetTimeHHMM('00:00');

  midnight.setSeconds(secondsFromMidnight);
  return midnight;
};
