import { teCLOUD_MESSAGE_TYPES } from './IoTMessageDefines';
import { teCLOUD_MSG_TY_CFG_QUERY } from './IoTMessageDefines_CONFIG_QUERY';
import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
//import logger from 'common/logger';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

export enum teDeviceProcessors {
  PROCESSOR_VRU_802154_DATA = 1,
  PROCESSOR_VRU_APPLICATION = 16,
  PROCESSOR_EVOLUTION_HANDSET_APPLICATION = 3,
}

export enum teVRUParameters {
  E2_PARAM_WIFI_ENABLED = 0,
  E2_PARAM_WUT_SHORT_ADDRESS = 3,
  E2_PARAM_DIGITNET_NETWORKID = 4,
  E2_PARAM_DIGINET_MAC_ADDRESS = 5,
  E2_PARAM_VISITOR_PRIVACY = 10,
  E2_PARAM_MIC_INPUT_SCALE = 14,

  E2_PARAM_AGC_ENABLED = 28,
  E2_PARAM_AGC_TARGET = 29,
  E2_PARAM_AGC_DIGITAL_COMPRESSION_AND_GAIN = 30,
  E2_PARAM_AGC_LIMITER_ENABLE = 31,
  E2_PARAM_AEC_ENABLE = 32,
  E2_PARAM_AEC_MODE = 33,
  E2_PARAM_NOISE_SUPRESSION_ENABLE = 34,
  E2_PARAM_NOISE_SUPRESSION_MODE = 35,
  E2_PARAM_WIFI_ALWAYS_ON = 41,
  E2_PARAM_LED_PWM_DAY = 49,
  E2_PARAM_LED_PWM_NIGHT = 50,
  E2_PARAM_DAY_START_HR = 53,
  E2_PARAM_DAY_START_MIN = 54,
  E2_PARAM_DAY_END_HR = 55,
  E2_PARAM_DAY_END_MIN = 56,

  E2_PARAM_LOG_TO_STDIO = 60,
  E2_PARAM_RESIDENT_AWAY = 66,
  E2_PARAM_SPL_VOLUME_AEC_MAX = 73,
  E2_PARAM_MIC_INPUT_SCALE_DOOR_CALL = 74,
  E2_PARAM_LOG_TO_FLASH = 76,
  E2_PARAM_MAX_NETWORK_VIDEO_LATENCY = 77,
  E2_PARAM_ICE_SID = 83,
  E2_PARAM_ICE_KEY = 84,
  E2_PARAM_PAGING_FREQ_OFFSET = 85,
  E2_PARAM_COMPANION_APP_ENABLED = 86,
  E2_DEVICE_CONFIG_SYNC = 87,
  E2_OK_ON_HOUR = 88,
  E2_OK_ON_MIN = 89,
  E2_OK_OFF_HOUR = 90,
  E2_OK_OFF_MIN = 91,
  E2_OK_MONITORING_ENABLED = 92,
  E2_ROOM_NUMBER = 93,
  E2_DEVICE_COMPANIONS_REGISTERED = 95,
  E2_PARAM_COMPANION_ROAMING_ENABLED = 97,
  E2_PARAM_DISABLE_GW_ETHERNET_LINK = 98,
  E2_PARAM_DIGINET_UNIT_TYPE = 99,
  E2_PARAM_VIDEOCAMERA_INSTALLED = 101,

  E2_PARAM_VIDEO_CALL_SPK_VOLUME = 102,
  E2_PARAM_VIDEO_CALL_AGC_TARGET = 103,
  E2_PARAM_VIDEO_CALL_AGC_COMP_AND_GAIN = 104,
  E2_PARAM_VIDEO_CALL_AGC_MODE = 105,
  E2_PARAM_VIDEO_CALL_AGC_LIMITER_ENABLED = 106,
  E2_PARAM_VIDEO_CALL_VOX_SWITCH_COUNT = 107,

  E2_PARAM_VIDEO_CALL_SPK_VOLUME_SLAVE = 110,
  E2_PARAM_VIDEO_CALL_AGC_TARGET_SLAVE = 111,
  E2_PARAM_VIDEO_CALL_AGC_COMP_AND_GAIN_SLAVE = 112,
  E2_PARAM_VIDEO_CALL_AGC_MODE_SLAVE = 113,
  E2_PARAM_VIDEO_CALL_AGC_LIMITER_ENABLED_SLAVE = 114,
  E2_PARAM_VIDEO_CALL_VOX_IDLE_TIMEOUT = 115,
  E2_PARAM_VIDEO_CALL_VOX_ACTIVE_TIMEOUT = 116,
  E2_PARAM_VIDEO_CALL_VOX_SPK_MIN_NOISE = 117,
  E2_PARAM_VIDEO_CALL_VOX_MAX_NOISE_LVL = 118,
  E2_PARAM_VIDEO_CALL_VOX_RMS_FORGET = 119,
  E2_PARAM_VIDEO_CALL_VOX_NOISE_FORGET = 120,
  E2_PARAM_VIDEO_CALL_VOX_SPEAKER_NOISE_THRESH = 121,
  E2_PARAM_VIDEO_CALL_SLAVE_USE_VOX = 122,
  E2_PARAM_DOOR_CALL_AGC_TARGET = 123,
  E2_PARAM_DOOR_CALL_AGC_COMP_AND_GAIN = 124,
  E2_PARAM_DOOR_CALL_AGC_MODE = 125,
  E2_PARAM_DOOR_CALL_AGC_LIMITER_ENABLED = 126,
  E2_PARAM_DOOR_CALL_VOX_SPEAKER_SWITCHING = 127,
  E2_PARAM_DOOR_CALL_INCOMING_VOICE_NOISE_REDUCTION = 128,
  E2_PARAM_VIDEO_CALL_INCOMING_VOICE_NOISE_REDUCTION = 129,
  E2_PARAM_VIDEO_CALL_MASTER_USE_VOX = 130,
  E2_PARAM_ALARM_CALL_INCOMING_VOICE_NOISE_REDUCTION = 131,
  E2_PARAM_ALARM_CALL_VOX_SPEAKER_SWITCHING = 132,

  E2_PARAM_DOOR_SCREEN_ON_TIME_AFTER_OPEN = 137,
  E2_PARAM_CARE_PRIVACY = 138,
  E2_PARAM_VISITOR_PRIVACY_DURATION_SECONDS = 140,
  E2_PARAM_CARE_PRIVACY_DURATION_SECONDS = 141,
  E2_PARAM_ENABLE_ONSCREEN_CARE_PRIVACY = 142,
  E2_PARAM_BRAND_DIRECTORY = 167,
}

export enum teHandsetParameters {
  E2_PARAM_LOGLEVEL = 0,
  E2_PARAM_GWI_PKT_DEBUG = 1,
  E2_PARAM_MIN_RSSI_TO_SCAN = 3,
  E2_PARAM_MIN_RSSI = 4,
  E2_PARAM_WAKE_PERIOD_MS = 5,
  E2_PARAM_HS_NAME = 7,
  E2_PARAM_PASS = 10,
  E2_PARAM_WIFI_PREFIX = 11,
  E2_PARAM_WIFI_IP = 12,
  E2_PARAM_WIFI_PERIODIC_SCAN_TIME = 16,
  E2_PARAM_RSSI_LEVEL_TO_SWITCH = 17,
  E2_PARAM_RSSI_TO_DISCONNECT = 18,
  E2_PARAM_RSSI_MARGIN_TO_SWITCH = 19,
  E2_PARAM_ENGINEERING_PIN = 21,
  E2_PARAM_INSTALLER_PIN = 24,
  E2_PARAM_MIC_VOICE_DETECTION_THRESHOLD = 26,
  E2_PARAM_SHOW_DETAILED_WIFI_STATS = 27,
  E2_PARAM_FAST_WAKE_PERIOD_MS = 28,
  E2_PARAM_SCU_NO_CONTACT_ALERT_MUTE_TIME_MINS = 29,
  E2_PARAM_SCU_NO_CONTACT_ALERT_VOLUME = 30,
  E2_PARAM_SCU_NO_PKTS_TIMEOUT_BEFORE_NO_CONNECTION_SECONDS = 31,
  E2_PARAM_SCU_NO_CONTACT_ALERT_ENABLED = 32,
  E2_PARAM_BRANDING = 33,
  E2_PARAM_WIFI_PKT_LOSS_BAD_THRESHOLD = 34,
  E2_PARAM_WIFI_SWITCH_MIN_TIME_MS_PACKETLOSS_BAD = 35,
  E2_PARAM_WIFI_SWITCH_MIN_TIME_MS_PACKETLOSS_GOOD = 36,
  E2_PARAM_WIFI_PACKETLOSS_BAD_DEBOUNCE = 37,
  E2_PARAM_WIFI_PACKETLOSS_BAD_NO_PKTS_RECEIVED = 38,
  E2_PARAM_WIFI_PKT_LOSS_GETTING_BAD_THRESHOLD = 39,
  E2_PARAM_WIFI_PACKETLOSS_GETTING_BAD_DEBOUNCE = 40,
  E2_PARAM_WIFI_MIN_TIME_BELOW_RSSI_TO_DISCONNECT = 41,
  E2_PARAM_WIFI_INITIAL_TIME_TO_IGNORE_IGNORE_PACKET_LOSS = 42,
  E2_PARAM_WIFI_SWITCH_MIN_TIME_MS_PACKETLOSS_BAD_HSHS = 43,
  E2_PARAM_WIFI_SWITCH_MIN_TIME_MS_PACKETLOSS_GOOD_HSHS = 44,
  E2_PARAM_WIFI_PKT_LOSS_GETTING_BAD_THRESHOLD_HSHS = 45,
  E2_PARAM_WIFI_PACKETLOSS_GETTING_BAD_DEBOUNCE_HSHS = 46,
  E2_PARAM_WIFI_PKT_LOSS_BAD_THRESHOLD_HSHS = 47,
  E2_PARAM_WIFI_PACKETLOSS_BAD_DEBOUNCE_HSHS = 48,
  E2_PARAM_WIFI_IGNORE_PACKET_LOSS = 49,
  E2_PARAM_SCU_NO_CONTACT_ALERT_REPEAT_TIME_SECONDS = 50,
  E2_PARAM_NOTIFICATION_VOLUME = 51,
  E2_PARAM_LOW_BATTERY_WARNING_PERCENT = 52,
}

export class DeviceParameters {
  private static _Instance: DeviceParameters;
  public static get Instance(): DeviceParameters {
    if (DeviceParameters._Instance) {
      return DeviceParameters._Instance;
    } else {
      return new DeviceParameters();
    }
  }

  constructor() {
    if (DeviceParameters._Instance) {
      return DeviceParameters._Instance;
    }
    DeviceParameters._Instance = this;
  }

  /*
  mac: number;
  parameter: number;
  deviceprocessor: number;
  */

  async getDeviceParameter(
    mac: number,
    parameterNumber: number,
    deviceprocessor: teDeviceProcessors,
    userData: unknown
  ): Promise<iIotResponseEventDetail> {
    console.info('getDeviceParameter:', parameterNumber);

    const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CONFIGURATION_QUERY;
    const opcode =
      teCLOUD_MSG_TY_CFG_QUERY.E_CLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_REQ;
    const rplyopcode =
      teCLOUD_MSG_TY_CFG_QUERY.E_CLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_RES;
    const msg = {
      mac: mac,
      parameter: parameterNumber,
      deviceprocessor: deviceprocessor as number,
    };
    const transId = MessageHandler.getNextGlobalTransactionId();
    return SCUMessaging.sendMessage(
      type,
      opcode,
      rplyopcode,
      transId,
      msg,
      defaultTimeout,
      'getDeviceParameter',
      userData
    );
  }

  async setDeviceParameter(
    mac: number,
    parameterNumber: number,
    deviceprocessor: teDeviceProcessors,
    value: string,
    userData: unknown
  ): Promise<iIotResponseEventDetail> {
    console.info('setDeviceParameter:', parameterNumber, ' to ', value);

    const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CONFIGURATION_QUERY;
    const opcode =
      teCLOUD_MSG_TY_CFG_QUERY.E_CLOUD_MSG_QUERY_CFG_SET_DEVICE_PARAM_REQ;
    const rplyopcode =
      teCLOUD_MSG_TY_CFG_QUERY.E_CLOUD_MSG_QUERY_CFG_SET_DEVICE_PARAM_RES;

    const msg = {
      mac: mac,
      parameter: parameterNumber,
      deviceprocessor: deviceprocessor as number,
      value: value,
      type: 3, // not used on VRUs so set to E2_UNKNOWN_DATA
    };

    const transId = MessageHandler.getNextGlobalTransactionId();
    return await SCUMessaging.sendMessage(
      type,
      opcode,
      rplyopcode,
      transId,
      msg,
      defaultTimeout,
      'setDeviceParameter',
      userData
    );
  }
}

export default DeviceParameters;
