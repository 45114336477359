/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOmniviaBatteryLog = /* GraphQL */ `
  subscription OnCreateOmniviaBatteryLog($omniviaSCUSerial: String) {
    onCreateOmniviaBatteryLog(omniviaSCUSerial: $omniviaSCUSerial) {
      id
      mac
      omniviaSCUSerial
      deviceType
      expirationUnixTime
      batteryVoltageApplication
      handset {
        batteryVoltageNative
        batteryCurrent
        isCharging
        isScreenOn
        chargerProtectionStateOld
        chargerProtectionStateNew
        currentBatteryCapacity
        batteryTemperature
        maxChargeLvl
        isChargerEnabled
        isOvertemp
        cycleOnTime
        cycleOffTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOmniviaLocationHistory = /* GraphQL */ `
  subscription OnCreateOmniviaLocationHistory($omniviaSCUSerial: String) {
    onCreateOmniviaLocationHistory(omniviaSCUSerial: $omniviaSCUSerial) {
      id
      mac
      omniviaSCUSerial
      deviceType
      expirationUnixTime
      parentMac
      rssi_db
      addtionalReceiverList {
        parentMac
        rssi_db
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOmniviaSCUEvent = /* GraphQL */ `
  subscription OnCreateOmniviaSCUEvent($omniviaSCUEventLinkedSCUId: ID) {
    onCreateOmniviaSCUEvent(
      omniviaSCUEventLinkedSCUId: $omniviaSCUEventLinkedSCUId
    ) {
      id
      alertGeneratedState
      omniviaSCUEventLinkedSCUId
      eventId
      transactionId
      eventIdText
      eventString
      raisedAt
      eventJSON
      aggregationId
      unitTime
      modifiedBy
      createdAt
      updatedAt
      expirationUnixTime
      omniviaSCUEventLinkedSCUIdEventId
      linkedSCU {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateOmniviaSCU = /* GraphQL */ `
  subscription OnUpdateOmniviaSCU($id: ID) {
    onUpdateOmniviaSCU(id: $id) {
      id
      name
      description
      serialNumber
      localEthernetIP
      localWiFiIP
      ethernetMAC
      wifiMAC
      softwareVersion
      mainOutgoingPOTSNumber
      backupOutgoingPOTSNumber
      remoteAccessPort
      productNumber
      lastSeen
      unitTime
      modifiedBy
      createdAt
      updatedAt
      _deleted
      groupsCanAccess
      linkedSite {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        maintenanceEmail
        pollFailEmail
        stuckAlarmMinAge
        stuckAlarmMaxAge
        stuckAlarmMinTimeBetweenSMS
        stuckAlarmLastAlert
        siteAlertsEnabled
        brand
        tags
        floorPlanFolder
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedClient {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          brand
          tags
          modifiedBy
          createdAt
          updatedAt
          _deleted
          linkedSites {
            nextToken
          }
        }
        linkedSCUs {
          items {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      linkedEvents {
        items {
          id
          alertGeneratedState
          omniviaSCUEventLinkedSCUId
          eventId
          transactionId
          eventIdText
          eventString
          raisedAt
          eventJSON
          aggregationId
          unitTime
          modifiedBy
          createdAt
          updatedAt
          expirationUnixTime
          omniviaSCUEventLinkedSCUIdEventId
          linkedSCU {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
        }
        nextToken
      }
      linkedFaults {
        items {
          id
          omniviaFaultsLinkedSCUId
          faultState
          faultType
          dateRaised
          dateCleared
          dateAlertLastGenerated
          deviceID
          faultSpecificText
          friendlyString
          clearedBy
          associatedRoomNumber
          identity
          faultUnitClassification
          createdAt
          updatedAt
          expirationUnixTime
          linkedSCU {
            id
            name
            description
            serialNumber
            localEthernetIP
            localWiFiIP
            ethernetMAC
            wifiMAC
            softwareVersion
            mainOutgoingPOTSNumber
            backupOutgoingPOTSNumber
            remoteAccessPort
            productNumber
            lastSeen
            unitTime
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateOmniviaClient = /* GraphQL */ `
  subscription OnCreateOmniviaClient {
    onCreateOmniviaClient {
      id
      name
      description
      addr1
      addr2
      addr3
      city
      county
      country
      postcode
      contactEmail
      contactTelephone
      timeZone
      brand
      tags
      modifiedBy
      createdAt
      updatedAt
      _deleted
      linkedSites {
        items {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateOmniviaClient = /* GraphQL */ `
  subscription OnUpdateOmniviaClient {
    onUpdateOmniviaClient {
      id
      name
      description
      addr1
      addr2
      addr3
      city
      county
      country
      postcode
      contactEmail
      contactTelephone
      timeZone
      brand
      tags
      modifiedBy
      createdAt
      updatedAt
      _deleted
      linkedSites {
        items {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteOmniviaClient = /* GraphQL */ `
  subscription OnDeleteOmniviaClient {
    onDeleteOmniviaClient {
      id
      name
      description
      addr1
      addr2
      addr3
      city
      county
      country
      postcode
      contactEmail
      contactTelephone
      timeZone
      brand
      tags
      modifiedBy
      createdAt
      updatedAt
      _deleted
      linkedSites {
        items {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateOmniviaSite = /* GraphQL */ `
  subscription OnCreateOmniviaSite {
    onCreateOmniviaSite {
      id
      name
      description
      addr1
      addr2
      addr3
      city
      county
      country
      postcode
      contactEmail
      contactTelephone
      timeZone
      maintenanceEmail
      pollFailEmail
      stuckAlarmMinAge
      stuckAlarmMaxAge
      stuckAlarmMinTimeBetweenSMS
      stuckAlarmLastAlert
      siteAlertsEnabled
      brand
      tags
      floorPlanFolder
      modifiedBy
      createdAt
      updatedAt
      _deleted
      groupsCanAccess
      linkedClient {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        brand
        tags
        modifiedBy
        createdAt
        updatedAt
        _deleted
        linkedSites {
          items {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      linkedSCUs {
        items {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateOmniviaSite = /* GraphQL */ `
  subscription OnUpdateOmniviaSite {
    onUpdateOmniviaSite {
      id
      name
      description
      addr1
      addr2
      addr3
      city
      county
      country
      postcode
      contactEmail
      contactTelephone
      timeZone
      maintenanceEmail
      pollFailEmail
      stuckAlarmMinAge
      stuckAlarmMaxAge
      stuckAlarmMinTimeBetweenSMS
      stuckAlarmLastAlert
      siteAlertsEnabled
      brand
      tags
      floorPlanFolder
      modifiedBy
      createdAt
      updatedAt
      _deleted
      groupsCanAccess
      linkedClient {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        brand
        tags
        modifiedBy
        createdAt
        updatedAt
        _deleted
        linkedSites {
          items {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      linkedSCUs {
        items {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteOmniviaSite = /* GraphQL */ `
  subscription OnDeleteOmniviaSite {
    onDeleteOmniviaSite {
      id
      name
      description
      addr1
      addr2
      addr3
      city
      county
      country
      postcode
      contactEmail
      contactTelephone
      timeZone
      maintenanceEmail
      pollFailEmail
      stuckAlarmMinAge
      stuckAlarmMaxAge
      stuckAlarmMinTimeBetweenSMS
      stuckAlarmLastAlert
      siteAlertsEnabled
      brand
      tags
      floorPlanFolder
      modifiedBy
      createdAt
      updatedAt
      _deleted
      groupsCanAccess
      linkedClient {
        id
        name
        description
        addr1
        addr2
        addr3
        city
        county
        country
        postcode
        contactEmail
        contactTelephone
        timeZone
        brand
        tags
        modifiedBy
        createdAt
        updatedAt
        _deleted
        linkedSites {
          items {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          nextToken
        }
      }
      linkedSCUs {
        items {
          id
          name
          description
          serialNumber
          localEthernetIP
          localWiFiIP
          ethernetMAC
          wifiMAC
          softwareVersion
          mainOutgoingPOTSNumber
          backupOutgoingPOTSNumber
          remoteAccessPort
          productNumber
          lastSeen
          unitTime
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedSite {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            maintenanceEmail
            pollFailEmail
            stuckAlarmMinAge
            stuckAlarmMaxAge
            stuckAlarmMinTimeBetweenSMS
            stuckAlarmLastAlert
            siteAlertsEnabled
            brand
            tags
            floorPlanFolder
            modifiedBy
            createdAt
            updatedAt
            _deleted
            groupsCanAccess
          }
          linkedEvents {
            nextToken
          }
          linkedFaults {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateOmniviaFaults = /* GraphQL */ `
  subscription OnCreateOmniviaFaults {
    onCreateOmniviaFaults {
      id
      omniviaFaultsLinkedSCUId
      faultState
      faultType
      dateRaised
      dateCleared
      dateAlertLastGenerated
      deviceID
      faultSpecificText
      friendlyString
      clearedBy
      associatedRoomNumber
      identity
      faultUnitClassification
      createdAt
      updatedAt
      expirationUnixTime
      linkedSCU {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateOmniviaFaults = /* GraphQL */ `
  subscription OnUpdateOmniviaFaults {
    onUpdateOmniviaFaults {
      id
      omniviaFaultsLinkedSCUId
      faultState
      faultType
      dateRaised
      dateCleared
      dateAlertLastGenerated
      deviceID
      faultSpecificText
      friendlyString
      clearedBy
      associatedRoomNumber
      identity
      faultUnitClassification
      createdAt
      updatedAt
      expirationUnixTime
      linkedSCU {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteOmniviaFaults = /* GraphQL */ `
  subscription OnDeleteOmniviaFaults {
    onDeleteOmniviaFaults {
      id
      omniviaFaultsLinkedSCUId
      faultState
      faultType
      dateRaised
      dateCleared
      dateAlertLastGenerated
      deviceID
      faultSpecificText
      friendlyString
      clearedBy
      associatedRoomNumber
      identity
      faultUnitClassification
      createdAt
      updatedAt
      expirationUnixTime
      linkedSCU {
        id
        name
        description
        serialNumber
        localEthernetIP
        localWiFiIP
        ethernetMAC
        wifiMAC
        softwareVersion
        mainOutgoingPOTSNumber
        backupOutgoingPOTSNumber
        remoteAccessPort
        productNumber
        lastSeen
        unitTime
        modifiedBy
        createdAt
        updatedAt
        _deleted
        groupsCanAccess
        linkedSite {
          id
          name
          description
          addr1
          addr2
          addr3
          city
          county
          country
          postcode
          contactEmail
          contactTelephone
          timeZone
          maintenanceEmail
          pollFailEmail
          stuckAlarmMinAge
          stuckAlarmMaxAge
          stuckAlarmMinTimeBetweenSMS
          stuckAlarmLastAlert
          siteAlertsEnabled
          brand
          tags
          floorPlanFolder
          modifiedBy
          createdAt
          updatedAt
          _deleted
          groupsCanAccess
          linkedClient {
            id
            name
            description
            addr1
            addr2
            addr3
            city
            county
            country
            postcode
            contactEmail
            contactTelephone
            timeZone
            brand
            tags
            modifiedBy
            createdAt
            updatedAt
            _deleted
          }
          linkedSCUs {
            nextToken
          }
        }
        linkedEvents {
          items {
            id
            alertGeneratedState
            omniviaSCUEventLinkedSCUId
            eventId
            transactionId
            eventIdText
            eventString
            raisedAt
            eventJSON
            aggregationId
            unitTime
            modifiedBy
            createdAt
            updatedAt
            expirationUnixTime
            omniviaSCUEventLinkedSCUIdEventId
          }
          nextToken
        }
        linkedFaults {
          items {
            id
            omniviaFaultsLinkedSCUId
            faultState
            faultType
            dateRaised
            dateCleared
            dateAlertLastGenerated
            deviceID
            faultSpecificText
            friendlyString
            clearedBy
            associatedRoomNumber
            identity
            faultUnitClassification
            createdAt
            updatedAt
            expirationUnixTime
          }
          nextToken
        }
      }
    }
  }
`;
