/** @module FloorPlans */

import { fabric } from 'fabric';
import { teDEVICE_TYPES } from 'types/manifest-enums';
import { Point } from 'types/plan-types';
import { teDEVICE_MODELS } from 'types/manifest-enums';
import {
  Equipment,
  System,
  Location,
  ProductCategory,
  defaultEquipmentValues,
} from 'types/product-types';
import { iDevice } from 'types/manifest-types';
import { products } from 'data/product-definitions';
import { invalidID } from 'types/manifest-types';
import { INSTALLSTATUS } from 'types/product-types';

/**
 * Creates a product on a plan containing the product informationa and the location
 * @class
 * @implements {Equipment}
 * @implements {Location}
 * @hideconstructor
 */
export class Product implements Equipment, Location {
  productCode = '';
  assignable = false;
  name = '';
  locationName = '';
  description = '';
  physicalID = invalidID;
  category = ProductCategory.NONE;
  firstFix = false;
  designItem = true;
  system = System.NONE;
  designator = '';
  voltage = 0;
  batterypowered = false;
  powerConsumptionmA = 0;
  deviceType = teDEVICE_TYPES.DEVICE_TYPE_MAX;
  deviceModel = teDEVICE_MODELS.DEVICE_MODEL_MAX;
  obsolete = false;
  replaceProductCode = '';
  requiredOthers = undefined;
  cat5Required = false;
  cat5Connection = { connectedTo: '', port: 0 };
  cat5Ports = 0;
  MAIRequiredNumber = 0;
  MAIConnection = { connectedTo: '' };
  MAPRequired = false;
  MAPConnection = { connectedTo: '' };
  inputs = [];
  outputs = [];
  unitNumber = 0;

  id = 0;
  uniqueID = '';
  angle = 0;
  calloutLength = 0;
  plan = 0;
  x = 0;
  y = 0;
  commonArea = false;
  locatabilityName = '';
  designNotes = '';
  serviceNotes = '';
  installStatus = INSTALLSTATUS.DESIGN;

  /*
   * @param productCode?: string i.e. PROD-3134
   * @param designator?: string i.e. VRU
   * */
  constructor(productCode?: string, designator?: string) {
    Object.assign(this, defaultEquipmentValues);
    if (designator) {
      this.designator = designator;
    } else if (productCode) {
      this.productCode = productCode;
    }
  }

  /** Set the plan full location details of the product, embellished info*/
  setLocation(location: Location): void;
  /** Set the plan location coords (only) of the product, i.e. XY and plan number*/
  setLocation(point: Point): void;
  setLocation(item: Point | Location): void {
    Object.assign(this, item);
  }

  /* Returns true if the product has a MAC address
   * For example DDP, VPC, PoE...  a MAC address is not applicable
   */
  productUsesMACAddress(): boolean {
    if (this.assignable && this.assignable == true) {
      return true;
    }
    return false;
  }

  /* returns whether we have a physcial ID, note although a valid number it may not be a vlid mac address from the manifest */
  hasPhysicalID(): boolean {
    return this.physicalID != invalidID && this.physicalID > 0;
  }

  /** Get the products location details */
  getLocation(): Location {
    const loc: Location = {
      x: this.x,
      y: this.y,
      plan: this.plan,
      id: this.id,
      uniqueID: this.uniqueID,
      angle: this.angle,
      calloutLength: this.calloutLength,
      commonArea: this.commonArea,
      locationName: this.locationName,
      designNotes: this.designNotes,
      serviceNotes: this.serviceNotes,
      installStatus: this.installStatus,
      locatabilityName: this.locatabilityName,
      cat5Connection: this.cat5Connection,
    };
    return loc;
  }

  /** update the product with the latest definition data in the product file
   *  useful for any changes that have been made to a product
   */
  refreshWithLatestProductFile(): void {
    // transition any cabinet SCUs to rackmount SCUs
    if (this.productCode == 'PROD-3163') this.productCode = 'PROD-3208';
    const equip = products.find(
      (p) => p.productCode == this.productCode
    ) as Equipment;
    Object.assign(this, equip);
    //ensure we update any status change
    if (this.physicalID > 0) this.installStatus = INSTALLSTATUS.COMMISSIONED;
  }

  /** update specific fields of the product
   */
  modifyProduct(p: Partial<Product>): void {
    Object.assign(this, p);
  }

  /** utility function to match a manifest device to an equipment type
   *  useful for assigning installed products to the plan
   */
  static getProductfromManifestDeviceType(device: Partial<iDevice>): Equipment {
    let p: Equipment = defaultEquipmentValues;
    for (const item of products) {
      if (
        item.deviceType == device.Type &&
        item.deviceModel == device.HwModel
      ) {
        p = item;
        break;
      }
    }

    return p;
  }

  /** utility function to get the base name of the equipment, i.e. without any colour varient
   */
  static getProductBaseName(product: Equipment): string {
    return product.baseName ? product.baseName : product.name;
  }

  /** utility function to get the base designator of the equipment, i.e. without any colour varient
   */
  static getProductBaseDesignator(product: Equipment): string {
    return product.baseDesignator ? product.baseDesignator : product.designator;
  }

  draw(): fabric.Group {
    let colour = 'purple';
    let baseColour = 'purple';
    switch (this.installStatus) {
      case INSTALLSTATUS.DESIGN:
        colour = 'purple';
        break;
      case INSTALLSTATUS.FITTED:
        colour = 'grey';
        break;
      case INSTALLSTATUS.COMMISSIONED:
        colour = 'green';
        baseColour = 'green';
        break;
    }
    let communalColour = 'transparent'; // change border colour of communal devices
    if (this.commonArea) {
      communalColour = baseColour;
    }

    // const circle = new fabric.Circle({
    //   left: this.x - 4,
    //   top: this.y - 4,
    //   fill: colour,
    //   radius: 8,
    // });

    const arrow = new fabric.Polyline(
      [
        {
          x: this.x,
          y: this.y,
        },
        {
          x: this.x + 10,
          y: this.y,
        },
        {
          x: this.x,
          y: this.y + 10,
        },
        {
          x: this.x,
          y: this.y,
        },
      ],
      { fill: colour, strokeWidth: 1 }
    );

    let xPos = this.x + 20 + this.calloutLength;
    // if no length specified then add a default
    if (this.calloutLength == 0) xPos += 20;

    const polyline = new fabric.Polyline(
      [
        {
          x: this.x + 2,
          y: this.y + 2,
        },
        {
          x: this.x + 20,
          y: this.y + 20,
        },
        {
          x: xPos,
          y: this.y + 20,
        },
      ],
      {
        fill: 'transparent',
        stroke: colour,
        strokeWidth: 2,
      }
    );

    const flipText = this.angle > 90 && this.angle < 270;

    const text = new fabric.Text(this.uniqueID, {
      left: xPos + 6,
      top: this.y + 6,
      fontFamily: 'Verdana',
      fontSize: 18,
      fill: 'white',
    });

    if (flipText) {
      text.flipY = true;
      text.flipX = true;
    }

    // create normal rect
    const rect = new fabric.Rect({
      left: xPos,
      top: this.y,
      fill: baseColour,
      width: 60 + (this.uniqueID.length - 4) * 10,
      height: 30,
      borderColor: baseColour,
      stroke: baseColour,
      strokeWidth: 1,
      hasBorders: true,
    });

    // create communal stripe
    const outerRect = new fabric.Rect({
      left: xPos + 65 + (this.uniqueID.length - 4) * 10,
      top: this.y,
      fill: communalColour,
      width: 10,
      height: 30,
      borderColor: communalColour,
      stroke: communalColour,
      strokeWidth: 1,
      hasBorders: true,
    });

    // Location Name / Design Notes
    const name = new fabric.Textbox(
      this.locationName + ' ' + this.designNotes,
      {
        left: xPos,
        top: this.y + 40,
        width: 120,
        fontFamily: 'Verdana',
        fontSize: 16,
        fill: 'black',
        stroke: 'black',
        backgroundColor: 'white',
      }
    );

    if (flipText) {
      name.flipY = true;
      name.flipX = true;
    }

    const group = new fabric.Group([rect, text, polyline, arrow, outerRect], {
      hasControls: false,
      name: `${this.designator}${this.id}`,
      hoverCursor: 'pointer',
      selectionBackgroundColor: '#00BBDD',
    });

    if (this.locationName.length > 0 || this.designNotes.length > 0) {
      group.addWithUpdate(name);
    }

    group.angle = this.angle;
    return group;
  }
}
