/**
 * createBrowserHistory is in a sperate module to allow
 * it to be accessed from non react modules.
 */

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;
