import React, { useState } from 'react';
import {
  useTable,
  useSortBy,
  useRowSelect,
  toggleRowSelected,
  usePagination,
  useFilters,
} from 'react-table';
import { useExportData } from 'react-table-plugins';
import Papa from 'papaparse';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { matchSorter } from 'match-sorter';
import { BsSkipForward } from 'react-icons/bs';
import { BsSkipBackward } from 'react-icons/bs';
import { BsSkipStart } from 'react-icons/bs';
import { BsSkipEnd } from 'react-icons/bs';
import { AiOutlineFileText } from 'react-icons/ai';
import { AiOutlineFilePdf } from 'react-icons/ai';
import styled from 'styled-components';
import {
  Button,
  Tip,
  Text,
  TableHeader,
  TableRow,
  CheckBox,
  Box,
} from 'grommet';
import logger from 'common/logger';
import { isEmpty } from 'common/utils/miscUtils';
import { formatDateWithSeconds } from 'common/utils/dateUtils';

const TABLE = styled.table`
  width: 100%;
  height: 95%;
`;
const TD = styled.td`
  font-size: 12px;
`;

const TH = styled.th`
  font-size: 14px;
  border-bottom: 1px solid grey;
  // border-top: 1px solid grey;
`;

const TR = styled.tr`
  color: ${(props) =>
    props.highlight
      ? ({ theme }) => theme.global.colors.gold.dark
      : ({ theme }) => theme.global.colors.text.dark};
`;

const ToolbarButton = styled(Button)`
  margin: 6px;
  color: ${({ theme }) => theme.global.colors.background.practical2};
`;

const PageControlButton = styled(Button)`
  margin: 4px;
  color: ${({ theme }) => theme.global.colors.background.practical1};
`;

const FilterInput = styled.input`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.global.colors.background.dark};
  color: ${({ theme }) => theme.global.colors.gold.dark};
  border: 0.5px solid ${({ theme }) => theme.global.colors.gold.dark};
  padding: 4px 2px 2px 4px;
  margin: 0px 4px 1px 4px;
  font-size: 12px;
  width: 96%;
`;

export const dateFilter = (rows, columnIds, filterValue) => {
  const newRows = rows.filter((e) => {
    const date = e.values[columnIds[0]];
    if (date) {
      const dateString = formatDateWithSeconds(date);
      return dateString.includes(filterValue);
    }
  });

  return newRows;
};

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Box pad="none" direction="row" gap="none">
          <CheckBox ref={resolvedRef} {...rest} pad="none" />
        </Box>
      </>
    );
  }
);

function getExportFileBlob(header, { columns, data, fileType, fileName }) {
  // console.info('header:', header);
  // console.info('columns:', columns);

  logger.info('table.export', {
    filename: fileName,
    fileType: fileType,
    header: header,
  });

  if (fileType === 'csv') {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString =
      '"' + header + '"\r\n' + Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: 'text/csv' });
  }
  // else if (fileType === "xlsx") {
  // XLSX example

  //   const header = columns.map((c) => c.exportValue);
  //   const compatibleData = data.map((row) => {
  //     const obj = {};
  //     header.forEach((col, index) => {
  //       obj[col] = row[index];
  //     });
  //     return obj;
  //   });

  //   let wb = XLSX.utils.book_new();
  //   let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
  //     header,
  //   });
  //   XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
  //   XLSX.writeFile(wb, `${fileName}.xlsx`);

  //   // Returning false as downloading of file is already taken care of
  //   return false;
  // }

  //PDF example
  if (fileType === 'pdf') {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new JsPDF('l', 'mm', [297, 210]);

    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 11,
        halign: 'left',
        valign: 'center',
        fontSize: 8,
      },
      addPageContent: function () {
        doc.text(header, 15, 10);
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

/**
 * @desc Generate a table
 * @param {Object} columns the columns to display
 * @param {Object} initialState e.g. for a defauilt sort or columns that should be hidden from view
 * @param {Object} data the data items array
 * @param {Object} currentSelectedID the currently selected row id
 * @param {Object} selectorKeyName Name of the object key to use as a selection id
 * @param {function} onSelectRow callback to be called a row is selected or table global is selected
 * @param {function} onLastSelectedRow callback when the user clicks a row
 * @param {function} clearSelected clear any selected rows
 * @param {boolean} showCheckboxes show selection checkboxes if true
 * @param {boolean} allowRowDrop set to true to allow rows to be a drop source - NOT YET IMPLEMENTED
 * @param {boolean} allowRowDrag set to true to allow rows and be a drag source - NOT YET IMPLEMENTED
 * @param {object} reportDescription table export info {header: string,filename: string}
 * @param {array} filters array of { id: string; value: string } to dynamiclly set the column filters
 * @param {boolean} showControls set to false to hide the data export and page controls
 * @param {boolean} showControlsExports set to false to hide the data export controls
 * @returns JSX.
 */
const TablePaged = ({
  columns,
  initialState,
  data,
  currentSelectedID,
  selectorKeyName,
  onSelectRow,
  onLastSelectedRow,
  clearSelected,
  showCheckboxes = true,
  //allowRowDrop = false,
  allowRowDrag = false,
  reportDescription = {
    header: '',
    filename: 'allData',
  },
  filters,
  showControls = true,
  showControlsExports = true,
}) => {
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const [lastSelectedRow, setLastSelectedRow] = useState(null);

  const skipPageResetRef = React.useRef(false);

  //const didMount = useRef(false); // used to track if its the first render or not

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      Filter: DefaultColumnFilter,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"

      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function getExportFileName(/*{ fileType, all }*/) {
    return reportDescription.filename;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //rows,
    selectedFlatRows,

    toggleAllRowsSelected,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    //setPageSize,
    exportData,
    setAllFilters,
    state: { pageIndex /*, pageSize */ },
    //state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      // initialState: {
      //   hiddenColumns: hiddenColumns,
      // },
      initialState: initialState,
      //autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      getExportFileBlob: ({ columns, data, fileType, fileName }) =>
        getExportFileBlob(reportDescription.header, {
          columns,
          data,
          fileType,
          fileName,
        }),
      getExportFileName,
      onSelectRow, // callback custom function to handle events when a row selection alters
      autoResetPage: !skipPageResetRef.current,
    },
    getRowId,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useExportData,

    (hooks) => {
      if (showCheckboxes) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection

          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            // eslint-disable-next-line react/display-name
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            // eslint-disable-next-line react/display-name
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            width: 1,
          },
          ...columns,
        ]);
      }
    }
  );

  React.useEffect(() => {
    if (filters) {
      setAllFilters(filters);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, /*preFilteredRows,*/ setFilter },
  }) {
    //const count = preFilteredRows.length;

    return (
      <FilterInput
        value={filterValue || ''}
        onChange={(e) => {
          toggleAllRowsSelected(false);
          if (clearSelected) {
            clearSelected();
          }
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Filter`}
      />
    );
  }

  React.useEffect(() => {
    // only run if its not the first mount (render)
    //if (didMount.current) {
    if (onSelectRow && selectedFlatRows) {
      onSelectRow(
        selectedFlatRows.map((row) => {
          return row.original;
        })
      );
    }
    //} else didMount.current = true;
  }, [selectedFlatRows]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    toggleAllRowsSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // If the table data chanegs then ensure all rows are deselected

  React.useEffect(() => {
    skipPageResetRef.current = true;
  }, []);

  // Render the UI for your table
  return (
    <div width="100%">
      {showControls && (
        <span>
          {showControlsExports && (
            <>
              <Tip content={<Text>Export Full Table Data in CSV format</Text>}>
                <ToolbarButton
                  className="btn-round btn-simple btn-icon btn-noborder"
                  id="csv"
                  onClick={() => exportData('csv', true)}
                >
                  <i>
                    <AiOutlineFileText size={20} />
                  </i>
                </ToolbarButton>
              </Tip>
              <Tip
                content={<Text>Export Only Filtered Table in CSV format</Text>}
              >
                <ToolbarButton
                  id="csv2"
                  onClick={() => exportData('csv', false)}
                >
                  <i>
                    <AiOutlineFileText size={20} />
                  </i>
                </ToolbarButton>
              </Tip>
              <Tip content={<Text>Export Table in PDF format</Text>}>
                <ToolbarButton id="pdf" onClick={() => exportData('pdf', true)}>
                  <i>
                    <AiOutlineFilePdf size={20} />
                  </i>
                </ToolbarButton>
              </Tip>
            </>
          )}

          <PageControlButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <i>
              <BsSkipStart size={22} />
            </i>
          </PageControlButton>
          <PageControlButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <i>
              <BsSkipBackward size={22} />
            </i>
          </PageControlButton>
          <PageControlButton
            onClick={() => nextPage(0)}
            disabled={!canNextPage}
          >
            <i>
              <BsSkipForward size={22} />
            </i>
          </PageControlButton>
          <PageControlButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <i>
              <BsSkipEnd size={22} />
            </i>
          </PageControlButton>

          <Box style={{ marginTop: '-10px' }}>
            <Text
              size="small"
              // margin="small"
            >
              Page {pageIndex + 1} of {pageOptions.length}
            </Text>
          </Box>
        </span>
      )}
      {/* <div className="resultsTable noselect"> */}
      <TABLE {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <TH key={column.id} style={{ width: column.width }}>
                  <span
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.headerToolTip && (
                      <Tip
                        content={column.headerToolTip}
                        dropProps={{
                          margin: { top: 'large' },
                          style: { whiteSpace: 'pre-wrap' },
                        }}
                      >
                        {column.render('Header')}
                      </Tip>
                    )}

                    {!column.headerToolTip && column.render('Header')}
                  </span>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </TH>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row, index) =>
              prepareRow(row) || (
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <Row
                  index={index}
                  rowdata={data[row.id]}
                  row={row}
                  toggleRowSelected={toggleRowSelected}
                  onLastSelectedRow={onLastSelectedRow}
                  currentSelectedID={currentSelectedID}
                  allowRowDrag={allowRowDrag}
                  page={page}
                  lastSelectedRow={lastSelectedRow}
                  setLastSelectedRow={setLastSelectedRow}
                  selectorKeyName={selectorKeyName}
                  {...row.getRowProps()}
                />
              )
          )}
        </tbody>
      </TABLE>
      {/* </div> */}
    </div>
  );
};

// Row
const Row = ({
  row,
  //index,
  rowdata,
  onLastSelectedRow,
  currentSelectedID,
  //toggleRowSelected,
  allowRowDrag = false,
  /*
  page,
  lastSelectedRow,
  setLastSelectedRow,*/
  selectorKeyName,
}) => {
  //const { appendTrackArraytoPlaylist } = usePlaylistUtils();
  //const { getSelectedTracks, loadSelectedTracks, playlist } = useTrack();
  const dragRef = React.useRef(null);

  //const id = rowdata.selectorID;

  /*
  let dragType = DNDItemTypes.INVALID;
  if (allowRowDrag) {
    dragType = DNDItemTypes.TRACK;
  }
  */

  //const opacity = isDragging ? 0.4 : 1;
  const opacity = 1;
  if (allowRowDrag) {
    // eslint-disable-next-line no-undef
    drag(dragRef);
  }

  // Row styling
  // highlight the row is it is selected
  let highlight = row.isSelected;

  if (
    !isEmpty(selectorKeyName) &&
    !isEmpty(rowdata[selectorKeyName]) &&
    !isEmpty(currentSelectedID) &&
    currentSelectedID === rowdata[selectorKeyName]
  ) {
    highlight = true;
  }

  // Handle row selection events
  const selectrow = (e, row, rowdata, column) => {
    let alreadySelected = false;
    if (
      !isEmpty(selectorKeyName) &&
      !isEmpty(rowdata[selectorKeyName]) &&
      !isEmpty(currentSelectedID) &&
      rowdata[selectorKeyName] === currentSelectedID
    ) {
      alreadySelected = true;
    }

    if (onLastSelectedRow) {
      onLastSelectedRow(rowdata, {
        rowid: row.id,
        columnNameClicked: column.id,
        alreadySelected: alreadySelected,
      });
    }
  };

  /*
  const getRowOnScreenIndex = (page, row) => {
    if (page && row) {
      for (let index in page) {
        if (page[index].index === row.index) {
          return parseInt(index);
        }
      }
    }
    return null;
  };
  */

  return (
    <TR
      style={{ opacity }}
      ref={dragRef}
      {...row.getRowProps()}
      key={row.id}
      highlight={highlight}
      disabled={false}
    >
      {row.cells.map((cell) => {
        return (
          <TD
            key={cell.id}
            {...cell.getCellProps()}
            onClick={(e) => selectrow(e, row, rowdata, cell.column)}
          >
            {cell.render('Cell')}
          </TD>
        );
      })}
    </TR>
  );
};

export default TablePaged;
