/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @module AlarmEvents*/

import {
  allEvents,
  alarmEvents,
  getDuration,
  translateEvent,
  nonAlarmEvents,
} from './events';
import * as EventTypes from 'types/eventTypes';
import {
  AUDIT_EVENTS,
  ALARM_TYPES,
  teGENERIC_LOG_TYPE,
} from 'types/event-enums';
import * as eventDispatcher from 'store/eventDispatcher';
import * as utils from 'common/utils/dateUtils';
import * as _ from 'lodash';
import { Manifest } from 'components/installation/manifest';
import { getCareGroupIDFromSequenceId } from 'components/installation//careGroupUtils';
import * as eventEnums from 'types/event-enums';
//import logger from 'common/logger';
//import { iAUDIT_EVENT_AGGREGATION_ALARM } from '../../types/eventTypes';
import { OmniviaSCUEvent } from 'types/API';
import { ALARMS_TYPE_DESCRIPTIONS } from 'common/alarmTypesTextFiltered/alarmText';

export interface iAlarmSummary {
  alarmId: number;
  callType: string;
  raisedAt: string;
  selectedAt?: string;
  clearedAt?: string;
  roomNumber: number;
  alarmType: string;
  alarmTypeID: number;
  source: string;
  answeredBy: string;
  timeToAnswer_seconds: number;
  callDuration_seconds: number;
  addtionalInfo: string;
  CancelReason?: string;
  raisedTo: string;
  originalAlarmType: number;
  careGroupID: number;
  careGroupDesc: string;
  raisedDeviceId: number;
}

export interface iAlarmEventSummary {
  eventRaisedAtSCU: string;
  eventId: number;
  eventText: string;
  transactionId: number;
  eventData: unknown | undefined; // could type as iAUDIT_EVENT_ALARM_RAISE which has all fields
  uiDescription: string;
}

/**
 * Alarm Count by Type of Alarm
 */
export interface iAlarmTypeCount {
  type: ALARM_TYPES;
  description: string;
  total: number;
}

/**
 * Alarm Count by source of alarm
 * */
export interface iAlarmSourceCount {
  source: string;
  description: string;
  total: number;
}

interface iHandsetRoomVisit {
  handsetDeviceId: number;
  handsetName: string;
  raisedTime: string;
  roomname: string;
  roomnumber: number;
  roomDeviceId: number;
  auditEvent: OmniviaSCUEvent;
}

export let alarms: EventTypes.iAUDIT_EVENT_ALARM_RAISE[] = [];
export let alarmSummaries: iAlarmSummary[] = [];
export let m_visits: iHandsetRoomVisit[] = [];

const hardwiredInputAlarmTypes = [
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1,
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2,
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3,
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_OPEN_EVENT,
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT,
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_OPEN_EVENT,
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_CLOSE_EVENT,
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_OPEN_EVENT,
  ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_CLOSE_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_OPEN_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_CLOSE_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_OPEN_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_CLOSE_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_OPEN_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_CLOSE_EVENT,
];

let oldestEventDate = new Date();

const addVisitsIntoAlarmSummary = (visits: iHandsetRoomVisit[]) => {
  for (const visit of visits) {
    const callObject: iAlarmSummary = {
      alarmId: 0,
      callType: '',
      raisedAt: visit.raisedTime,
      roomNumber: visit.roomnumber,
      source: visit.roomname,
      raisedDeviceId: visit.roomDeviceId,
      alarmType: 'Visit by ' + visit.handsetName,
      alarmTypeID: 0,
      answeredBy: '',
      timeToAnswer_seconds: 0,
      callDuration_seconds: 0,
      addtionalInfo: '',
      raisedTo: '',
      originalAlarmType: 0,
      careGroupID: 0,
      careGroupDesc: '',
    };
    alarmSummaries.push(callObject);
  }
};

const addVisitsIntoAlarmEvents = (visits: iHandsetRoomVisit[]) => {
  for (const visit of visits) {
    for (const alarmSummary of alarmSummaries) {
      if (alarmSummary.roomNumber == visit.roomnumber) {
        const alarmDate = new Date(alarmSummary.raisedAt).getTime();
        const visitDate = new Date(visit.raisedTime).getTime();
        const hoursInAdvance = (visitDate - alarmDate) / (1000 * 60 * 60);
        // console.info(
        //   `GENERIC Found alarm for room ${alarmSummary.roomNumber} hoursInAdvance:${hoursInAdvance}`
        // );

        if (visitDate >= alarmDate && hoursInAdvance <= 12) {
          const alarm = alarmEvents.find(
            (alarm) => alarm.id == alarmSummary.alarmId
          );
          if (alarm) {
            alarm.events.push(visit.auditEvent);
          }
        }
      }
    }
  }
};

const decipherAlarms = (): iAlarmSummary[] => {
  alarmSummaries = [];
  const agregatedEvents = alarmEvents;

  for (const index in agregatedEvents) {
    const alarmEvents = agregatedEvents[index].events;

    // sort the events by transactionId so that we have the correct order
    alarmEvents.sort(function (a, b) {
      return a.transactionId - b.transactionId;
    });

    const raisedEvent = alarmEvents.find(
      (element) =>
        element.eventIdText == 'Alarm Raise' ||
        element.eventIdText == 'Alarm ReRaise'
    );

    if (raisedEvent && raisedEvent.raisedAt) {
      const newDate = new Date(raisedEvent.raisedAt);
      if (newDate < oldestEventDate) {
        oldestEventDate = newDate;
      }
    }

    if (raisedEvent && raisedEvent.eventJSON) {
      //console.info('alarmEvents:', alarmEvents);

      // find the first destination raise so we can find the care sequence
      const DestinationRaise = alarmEvents.find(
        (element) =>
          element.eventId == AUDIT_EVENTS.AUDIT_EVENT_ALARM_DEST_RAISE
      );
      let careSequenceId = 1;

      try {
        // For pre R5.19 SCUs need to the careSequenceId from the desitination raise.
        if (DestinationRaise && DestinationRaise.eventJSON) {
          careSequenceId = JSON.parse(
            DestinationRaise.eventJSON
          ).CareSequenceId;
        } else if (raisedEvent && raisedEvent.eventJSON) {
          // As of SCU R5.19 the raise event has been fixed so the CareGroupID field is correct.
          // So we can get the careSequenceId from either event.
          // We are checking the raise as when an alarm fails due to a call sequence been empty there is no DestinationRaise event
          const _json = JSON.parse(raisedEvent.eventJSON);
          if (_json.CareGroupID !== undefined) {
            careSequenceId = _json.CareGroupID;
          }
        }
      } catch (error) {
        console.error('Failed to parse careSequenceId, error:', error);
      }

      const FailEvent = alarmEvents.find(
        (element) => element.eventIdText == 'Alarm Fail'
      );

      const SelectEvent = alarmEvents.find(
        (element) => element.eventIdText == 'Alarm Select'
      );

      const clearedEvent = alarmEvents.find(
        (element) => element.eventIdText == 'Alarm Clear'
      );

      const forceClearEvent = alarmEvents.find(
        (element) =>
          element.eventIdText == 'Forced Clear' || // AUDIT_EVENT_FORCED_CLEAR :by Handset
          element.eventIdText == 'Alarm force clear' || //AUDIT_EVENT_ALARM_FORCE_CLEAR : console
          element.eventIdText == 'Alarm user clear' || // AUDIT_EVENT_USER_CLEAR : cancel button on the fall pendant
          element.eventIdText == 'System Forced Clear' || // AUDIT_EVENT_FORCED_CLEAR :by the sofwtare
          element.eventIdText == 'VRU Forced Clear' || // AUDIT_EVENT_FORCED_CLEAR :cancel button on VRU screen
          element.eventId ==
            AUDIT_EVENTS.AUDIT_EVENT_ALARM_FORCE_CLEAR_AUTO_TEST || // AUDIT_EVENT_ALARM_FORCE_CLEAR_AUTO_TEST :by the system
          element.eventId ==
            AUDIT_EVENTS.AUDIT_EVENT_ALARM_FORCE_CLEAR_SERVICE_TEST // AUDIT_EVENT_ALARM_FORCE_CLEAR_SERVICE_TEST :by the system
      );

      const eventJson = JSON.parse(raisedEvent.eventJSON);
      const careGroup = getCareGroupIDFromSequenceId(careSequenceId);

      let lowBattery = false;
      if (
        eventJson.AddtionalInfo !== undefined &&
        eventJson.AddtionalInfo !== ''
      ) {
        const addInfo = JSON.parse(eventJson.AddtionalInfo);
        if (addInfo.lowbattery !== undefined) lowBattery = addInfo.lowbattery;
      }

      let alarmType = eventJson.AltTypeDesc;
      if (lowBattery) {
        alarmType = alarmType + ' (Low Battery)';
      }

      const callObject: iAlarmSummary = {
        alarmId: agregatedEvents[index].id,
        callType: 'Alarm',
        raisedAt: raisedEvent.raisedAt,
        roomNumber: eventJson.OwnerRoomNumber,
        source: eventJson.OwnerDeviceName,
        raisedDeviceId: eventJson.RaisedDeviceID,
        alarmType: alarmType,
        alarmTypeID: eventJson.AltTypeID,
        answeredBy: '',
        timeToAnswer_seconds: 0,
        callDuration_seconds: 0,
        addtionalInfo: '',
        raisedTo: '',
        originalAlarmType: eventJson.AlarmTypeID,
        careGroupID: careGroup ? careGroup.ID : careSequenceId,
        careGroupDesc: careGroup ? careGroup.Description : 'Unknown',
      };

      for (const shownOnIndex in alarmEvents) {
        const eventJSON = alarmEvents[shownOnIndex].eventJSON;
        if (eventJSON && typeof eventJSON !== 'undefined') {
          if (alarmEvents[shownOnIndex].eventIdText === 'Handset Notified') {
            const shownOneventJson = JSON.parse(eventJSON);
            if (
              !callObject.raisedTo.includes(shownOneventJson.OwnerDeviceName)
            ) {
              callObject.raisedTo += shownOneventJson.OwnerDeviceName + ',';
            }
          }
        }
      }

      // Look for care group destination we raise the alarm to.
      // Default is the handset group so do list these as the individual handsets will be listed.
      for (const shownOnIndex in alarmEvents) {
        const eventString = alarmEvents[shownOnIndex].eventString;
        if (
          alarmEvents[shownOnIndex].eventIdText === 'Alarm Destination Raise' &&
          eventString
        ) {
          // The eventString is in the format Alarm notification to 'Default'
          // and we need the name in quotes ''
          const pattern = /Alarm notification to '(\w+)'/;
          const result = pattern.exec(eventString);

          if (result && result.length == 2 && result[1] != 'Default') {
            const shownOn = result[1];

            if (!callObject.raisedTo.includes(shownOn)) {
              callObject.raisedTo += shownOn + ',';
            }
          }
        }
      }

      if (raisedEvent.eventIdText == 'Alarm ReRaise') {
        callObject.addtionalInfo += 'Alarm re-raised due to previous failure. ';
      }

      if (FailEvent) {
        callObject.timeToAnswer_seconds = 0;
        callObject.callDuration_seconds = 0;
        callObject.clearedAt = FailEvent.raisedAt;

        // remove (alarm id> from the end of the string
        if (FailEvent.eventString) {
          const removeString = ` (${callObject.alarmId})`;
          translateEvent(FailEvent, undefined);
          callObject.addtionalInfo += FailEvent.eventString.replace(
            removeString,
            ''
          );
          if (FailEvent.eventJSON) {
            const failEventJson = JSON.parse(FailEvent.eventJSON);
            if (failEventJson) {
              callObject.answeredBy = failEventJson.DestinationData ?? '';
            }
          }
        }
      } else if (forceClearEvent) {
        callObject.timeToAnswer_seconds = 0;
        callObject.callDuration_seconds = 0;
        callObject.clearedAt = forceClearEvent.raisedAt;

        if (forceClearEvent.eventString) {
          // remove (alarm id> from the end of the string
          const removeString = ` (${callObject.alarmId})`;
          translateEvent(forceClearEvent, undefined);
          callObject.CancelReason = forceClearEvent.eventString.replace(
            removeString,
            ''
          );
        }
      } else {
        if (SelectEvent && SelectEvent.eventJSON) {
          const selectEventJson = JSON.parse(SelectEvent.eventJSON);
          callObject.answeredBy = selectEventJson.DestinationData;
          callObject.selectedAt = SelectEvent.raisedAt;
          callObject.timeToAnswer_seconds = getDuration(
            raisedEvent.raisedAt,
            SelectEvent.raisedAt
          );
        } else if (clearedEvent) {
          callObject.timeToAnswer_seconds = getDuration(
            raisedEvent.raisedAt,
            clearedEvent.raisedAt
          );
        }

        if (clearedEvent) {
          if (SelectEvent) {
            callObject.callDuration_seconds = getDuration(
              SelectEvent.raisedAt,
              clearedEvent.raisedAt
            );
          }

          callObject.clearedAt = utils.formatDateWithSeconds(
            clearedEvent.raisedAt
          );
        }
      }
      alarmSummaries.push(callObject);
    }
  }

  return alarmSummaries;
};

const parseVisitInformation = (
  e: OmniviaSCUEvent
): iHandsetRoomVisit | undefined => {
  try {
    if (e.eventId == AUDIT_EVENTS.AUDIT_EVENT_GENERIC_LOG && e.eventJSON) {
      const genericEvent: EventTypes.iAUDIT_EVENT_GENERIC_LOG = JSON.parse(
        e.eventJSON
      ) as EventTypes.iAUDIT_EVENT_GENERIC_LOG;

      //console.log('GENERIC AUDIT EVENT', e);

      const addtionalInfo = JSON.parse(
        genericEvent.AddtionalInfo
      ) as EventTypes.iAUDIT_EVENT_GENERIC_LOG_TYPE_ADDTIONAL_INFO;

      if (addtionalInfo.type == teGENERIC_LOG_TYPE.GENERIC_LOG_TYPE_NFC_READ) {
        const nfcReadRaw: EventTypes.iAUDIT_EVENT_GENERIC_LOG_TYPE_NFC_READ_RAW =
          addtionalInfo.data as unknown as EventTypes.iAUDIT_EVENT_GENERIC_LOG_TYPE_NFC_READ_RAW;

        if (
          !_.has(nfcReadRaw, 'a') ||
          !_.has(nfcReadRaw, 'b') ||
          !_.has(nfcReadRaw, 'c') ||
          !_.has(nfcReadRaw, 'd') ||
          !_.has(nfcReadRaw, 'e') ||
          !_.has(nfcReadRaw, 'f')
        ) {
          console.error(
            'NFC read does not have the expected fields:',
            nfcReadRaw
          );
          return undefined;
        }

        const nfcRead: EventTypes.iAUDIT_EVENT_GENERIC_LOG_TYPE_NFC_READ_FORMATTED =
          {
            mac: nfcReadRaw.a,
            serial: nfcReadRaw.b,
            worksorder: nfcReadRaw.c,
            bomrevision: nfcReadRaw.d,
            productiondate: nfcReadRaw.e,
            productcode: nfcReadRaw.f,
          };

        const roomMAC = parseInt(nfcRead.mac);

        const room = Manifest.Instance.rooms.find(
          (room) => room.MacAddress == roomMAC
        );

        const handset = Manifest.Instance.handsets.find(
          (handset) => handset.ID == genericEvent.RaisedDeviceID
        );

        if (room && handset) {
          //m_visits.push();
          return {
            handsetDeviceId: genericEvent.RaisedDeviceID,
            handsetName: handset.Description,
            raisedTime: genericEvent.RaisedTime, //new Date(genericEvent.RaisedTime),
            roomname: room.Name,
            roomnumber: room.RoomNumber,
            roomDeviceId: room.ID,
            auditEvent: e,
          };
        }
      }
    }
  } catch (e) {
    console.error('Failed to parse GENERIC AUDIT EVENT:', e);
  }

  return undefined;
};

/** parse all the event data to filter only the alarm type events
 *  we determine this from the aggregation id being present
 */
export const getAlarms = (): EventTypes.iAUDIT_EVENT_ALARM_RAISE[] => {
  alarms = [];

  oldestEventDate = new Date();

  // create array of visits
  m_visits = [];

  nonAlarmEvents.find((e) => {
    const visit = parseVisitInformation(e);
    if (visit) {
      m_visits.push(visit);
    }
  });

  allEvents.forEach((e) => {
    if (
      e.aggregationId &&
      e.aggregationId > 0 && // alarms have a numeric aggregation ID of 1 or more
      //e.eventId == AUDIT_EVENTS.AUDIT_EVENT_ALARM_RAISE &&
      e.eventJSON
    ) {
      alarms.push(JSON.parse(e.eventJSON));
    }
  });

  decipherAlarms();
  addVisitsIntoAlarmSummary(m_visits);
  addVisitsIntoAlarmEvents(m_visits);

  eventDispatcher.emitEvent(
    eventDispatcher.systemEventTopics.ALARMEVENTS,
    eventDispatcher.systemEventStates.PROCESSED,
    null,
    false
  );
  // logger.debug(alarms);

  return alarms;
};

export const getLiveAlarms = (): iAlarmSummary[] => {
  return _.orderBy(
    alarmSummaries.filter((f) => f.clearedAt === undefined),
    'alarmId',
    'desc'
  );
};

/** obtain the events/stages for a particular alarm id */
export const getAlarmStagesForID = (
  alarmId: number
): EventTypes.iAUDIT_EVENT_ALARM_RAISE[] => {
  const stages: EventTypes.iAUDIT_EVENT_ALARM_RAISE[] = [];

  allEvents.forEach((e) => {
    if (
      e.aggregationId &&
      e.aggregationId == alarmId &&
      // e.eventId &&
      // e.eventId == AUDITEVENTS.AUDITEVENTALARMRAISE &&
      e.eventJSON
    ) {
      stages.push(JSON.parse(e.eventJSON));
    }
  });

  return stages;
};

/**  return just the alarm raise events */
export const getAlarmRaises = (): EventTypes.iAUDIT_EVENT_ALARM_RAISE[] => {
  const raises = alarms.filter(
    (e) =>
      e.EventType == AUDIT_EVENTS.AUDIT_EVENT_ALARM_RAISE &&
      // don't include any cancelled alarms
      !alarms.find((f) => {
        f.AlarmID == e.AlarmID &&
          (f.EventType == AUDIT_EVENTS.AUDIT_EVENT_ALARM_FORCE_CLEAR ||
            f.EventType == AUDIT_EVENTS.AUDIT_EVENT_USER_CLEAR);
      })
  );

  raises.sort(function (a, b) {
    const dateA = new Date(a.RaisedTime);
    const dateB = new Date(b.RaisedTime);
    if (dateA === dateB) return 0;
    if (dateA < dateB) return -1;
    return 1;
  });

  return raises;
};

/** get the total number of alarms */
export const getNumberofAlarms = (): number => {
  const raises = getAlarmRaises();
  return raises ? raises.length : 0;
};

// parse the alarms to generate a summary count of alarms by each type
export const getTotalAlarmsByType = (): iAlarmTypeCount[] => {
  const sum: iAlarmTypeCount[] = [];

  const raises = getAlarmRaises();

  raises.forEach((a) => {
    const i = sum.findIndex((o) => o.type === a.AltTypeID);
    if (i == -1) {
      sum.push({ type: a.AltTypeID, description: a.AltTypeDesc, total: 1 });
    } else {
      sum[i].total = sum[i].total + 1;
    }
  });

  //console.log(sum);
  return sum;
};

/**  parse the alarms to generate a summary count of alarms by each source*/
export const getAlarmsBySource = (): iAlarmSourceCount[] => {
  const sum: iAlarmSourceCount[] = [];

  const raises = getAlarmRaises();

  raises.forEach((a) => {
    const src = 'Room ' + a.OwnerRoomNumber + ' - ' + a.OwnerDeviceName;
    const i = sum.findIndex((o) => o.source === src);

    if (i == -1) {
      sum.push({
        source: src,
        description: a.AltTypeDesc,
        total: 1,
      });
    } else {
      sum[i].total = sum[i].total + 1;
    }
  });

  //logger.debug(sum);
  return sum;
};

export const getAlarmAuditTrail = (alarmId: number): iAlarmEventSummary[] => {
  const agregatedEvents = alarmEvents.find((e) => e.id === alarmId)?.events;
  const eventSummary: iAlarmEventSummary[] = [];
  if (agregatedEvents) {
    for (const index in agregatedEvents) {
      const alarmEvent = agregatedEvents[index];
      const eventJSON = alarmEvent.eventJSON
        ? JSON.parse(alarmEvent.eventJSON)
        : {};

      let scuTime = _.get(eventJSON, 'RaisedTime', undefined);
      if (scuTime === undefined) scuTime = new Date().toISOString();

      const visit = parseVisitInformation(alarmEvent);

      const summaryEvent: iAlarmEventSummary = {
        eventRaisedAtSCU: scuTime,
        eventId: alarmEvent.eventId,
        eventText: alarmEvent.eventString ? alarmEvent.eventString : '',
        transactionId: alarmEvent.transactionId,
        eventData: eventJSON,
        uiDescription:
          visit !== undefined
            ? 'Visit By ' + visit.handsetName
            : _.get(eventJSON, 'FriendlyString', ''),
      };

      /*  REMOVED AS NOW HANDLED IN THE TRANSLATIONS 
      if (alarmEvent.eventId == AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_CC_FAIL) {
        // SCU reports hangup without answer incorrectly.
        const eventString = `Control centre call failed due to no answer (${alarmId})`;
        summaryEvent = {
          eventRaisedAtSCU: scuTime,
          eventId: AUDIT_EVENTS.AUDIT_EVENT_AST_NO_ANSWER,
          eventText: eventString,
          transactionId: alarmEvent.transactionId,
          eventData: eventJSON,
          uiDescription: eventString,
        };
      }
      */

      if (
        !eventSummary.find(
          (e) =>
            e.uiDescription === summaryEvent.uiDescription &&
            e.eventRaisedAtSCU === summaryEvent.eventRaisedAtSCU
        )
      ) {
        eventSummary.push(summaryEvent);
      }
    }

    // TODO NEED TO HANDLE SCU HW EVENTS  AUDIT_EVENTS.AUDIT_EVENT_SU_HWIN_EVENT

    /*
     * The SCU sends events about hardwired input state changes.
     * These events are not linked to any alarm.
     *
     * If the original alarm was due to a hardwired input search through the events and find
     * all AUDIT_EVENT_DEVICE_HWIN_EVENT -10 seconds and +60 minutes of the raise.
     */
    const oneHour = 1000 * 60 * 60;
    const tenSeconds = 1000 * 10;
    const alarmSummary = alarmSummaries.find((e) => e.alarmId === alarmId);
    if (
      alarmSummary &&
      hardwiredInputAlarmTypes.includes(alarmSummary.originalAlarmType)
    ) {
      const room = Manifest.Instance.rooms.find(
        (room) => room.RoomNumber === alarmSummary.roomNumber
      );
      if (room) {
        const eventRaised = new Date(alarmSummary.raisedAt).getTime();

        for (const allEventsIndex in allEvents) {
          const event = allEvents[allEventsIndex];
          const hwEventTime = new Date(event.raisedAt).getTime();

          if (
            event.eventId === AUDIT_EVENTS.AUDIT_EVENT_HWIN_EVENT &&
            hwEventTime >= eventRaised - tenSeconds &&
            hwEventTime <= eventRaised + oneHour
          ) {
            const eventJsonString = event.eventJSON ? event.eventJSON : '';

            const eventJson = JSON.parse(
              eventJsonString
            ) as EventTypes.iAUDIT_EVENT_DEVICE_HWIN_EVENT;

            if (eventJson.RaisedDeviceID === room.ID) {
              const summaryEvent: iAlarmEventSummary = {
                eventRaisedAtSCU: eventJson.RaisedTime,
                eventId: event.eventId,
                eventText: event.eventString ? event.eventString : '',
                transactionId: event.transactionId,
                eventData: eventJson,
                uiDescription: eventJson.FriendlyString,
              };
              eventSummary.push(summaryEvent);
            }
          }
        }
      }
    }
  }

  const sorted = eventSummary.sort(function (a, b) {
    return a.transactionId - b.transactionId;
  });

  return sorted;
};

// export const getAlarmText = (alarm: number): string => {
//   let text = 'unknown';

//   const index = ALARMS_TYPE_DESCRIPTIONS.findIndex((object) => {
//     return object.value === alarm;
//   });

//   if (index != -1) {
//     text = ALARMS_TYPE_DESCRIPTIONS[index].text;
//   }

//   return text;
// };

export const getOldestEntry = (): Date => {
  return oldestEventDate;
};
