/** @module deviceView */

import TablePaged from 'components/tables/TablePaged';
import React, { useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../tile';
import { Manifest, teErrorCode } from '../../installation/manifest';
import * as EventDispatcher from 'store/eventDispatcher';
import * as Icons from 'grommet-icons';
import { Column, Cell } from 'react-table';
import * as ManifestTypes from 'types/manifest-types';
import _ from 'lodash';
import * as Wait from 'components/dialogues/waitDialogue';
import * as IoTPing from 'common/IoT/IoTPingDevice';
import * as IoTReset from 'common/IoT/IoTResetDevice';
import * as IoTSCUReset from 'common/IoT/IoTSCUReset';
import * as IoTTunnel from 'common/IoT/IoTSCURestartTunnel';
import { Product } from 'components/floorplan/product';
import { Device } from 'components/installation/device';
import { Box, Tip, Layer, Button, Paragraph, Form, Text } from 'grommet';
import * as IoTMessageDefines_DEVICES from 'common/IoT/IoTMessageDefines_DEVICES';
import * as IoTMessageDefines_SYSTEM from 'common/IoT/IoTMessageDefines_SYSTEM';
import * as IoTMessageDefines from 'common/IoT/IoTMessageDefines';
import * as ManifestEnums from 'types/manifest-enums';
import { useWinstonLogger } from 'winston-react';
import * as FormFields from 'components/forms/formFieldsOld';
import CheckBoxCell from 'components/tables/cellPresentationComponents/checkBoxCell';
import * as IoTDeviceAddDelete from 'common/IoT/IoTAddDelete';
import * as IoTMsgDefines_DEVICES from 'common/IoT/IoTMessageDefines_DEVICES';
import * as IoTMsgDefines from 'common/IoT/IoTMessageDefines';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';
import GWIConfigLayer from './gwiConfigLayer';
import * as IoTComissioning from 'common/IoT/IoTCommissioning';
import * as systemFaults from 'components/systemevents/faults';
import { UAG } from 'common/userUtils';

const m = new Manifest();

interface iDeviceInfo extends ManifestTypes.iDevice {
  pingStatus?: string;
  associatedUnitId?: number;
  Name: string;
}

const HWModels_room = [
  {
    name: 'Obsolete Entrotec Room Unit',
    id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V1,
  },
  {
    name: 'Audio Only Room Unit',
    id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V2,
  },
  {
    name: 'Video Room Unit',
    id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V3,
  },
];

const HWModels_ap = [
  { name: 'Repeater', id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V1 },
  {
    name: 'Multi-Access Point (MAP)',
    id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V2,
  },
  {
    name: 'Multi-Access Point LS (MLS)',
    id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V3,
  },
];

const HWModels_interfaces = [
  {
    name: 'Carelocate Lift Interface',
    id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V1,
  },
  {
    name: 'Carelocate Door Interface (DPI)',
    id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V2,
  },
  {
    name: 'Multi-Access Device Interface (MAI)',
    id: ManifestEnums.teDEVICE_MODELS.DEVICE_MODEL_V3,
  },
];

let swapDeviceDisplayFields: FormFields.iField[] = [];

interface iDeviceSwapFields {
  newMac: string;
  oldMac: string;
  hwModel: ManifestEnums.teDEVICE_MODELS;
}

let originalData: iDeviceInfo[] = [];

const DeviceView = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [allData, setAllData, allDataRef] = useStateRef<iDeviceInfo[]>([]);
  const [, SetChildEvent] = useState('');
  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [tableFilters, setTableFilters] = useState<
    { id: string; value: string }[] | undefined
  >();
  const [selectedDevice, setSelectedDevice] = useState<
    iDeviceInfo | undefined
  >();
  const [showSwapDeviceDialogue, setShowSwapDeviceDialogue] = useState(false);
  const [deviceSwapFields, setDeviceSwapFields] = useState<
    iDeviceSwapFields | undefined
  >();

  const [, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useStateRef<number[]>([]);

  const canPing = (device: iDeviceInfo): boolean => {
    return device.Type != ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR
      ? true
      : false;
  };

  const [GWIConfigComplete, setGWIConfigComplete] = useState(false);
  const [showGWIConfigLayer, setShowGWIConfigLayer, showGWIConfigLayerRef] =
    useStateRef(false);

  /**
   * Called from TablePaged when a row is selected
   * Takes a copy of the device into a useState
   * @param rowData
   * @param selectionInfo
   */
  const selectDevice = async (
    rowData: iDeviceInfo,
    selectionInfo?: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (selectionInfo === undefined || !selectionInfo.alreadySelected) {
      console.info('device Selected:', rowData);
      logger.info('deviceview.selectDevice', { device: rowData });

      // Perform a deepclone to ensure we don't copy object refs which will lead to
      // modifying the orginal data
      setSelectedDevice(_.cloneDeep(rowData));
    }
  };

  /**
   * Save any changes we have applied to the manifest
   * A dialogue will be shown to the user if showCompleteDialogue true or if the save fails
   * @param showCompleteDialogue
   * @returns true on success, false otherise
   */
  const save = async (
    showCompleteDialogue: boolean,
    repopulatecaches: boolean
  ): Promise<boolean> => {
    let ok = false;
    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });
    let str = 'Save failed';
    try {
      const status = await m.save(repopulatecaches);
      if (status === teErrorCode.E_OK) {
        str = 'Save Completed Successfully';
        ok = true;
      } else if (status === teErrorCode.E_CONNECTION_FAIL) {
        str = 'Connection failed';
        logger.info('deviceview.save.failed', {
          status: status,
          error: {},
        });
      }
    } catch (error) {
      console.info('Save failed');
      logger.info('deviceview.save.failed', {
        status: teErrorCode.E_ERROR,
        error: error,
      });
    }

    if (showCompleteDialogue || !ok) {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
    }

    return ok;
  };

  /**
   * Send the swap device changes to the site.
   * We instruct the manifest to signal to the SCU to repopulate all caches after the changes
   * @param swapFields
   * @returns void
   */
  const saveSwapDevice = async (
    swapFields: iDeviceSwapFields
  ): Promise<void> => {
    const mac = Device.MACaddrStringToNumber(swapFields.oldMac);
    const newmac = Device.MACaddrStringToNumber(swapFields.newMac);

    logger.info('deviceview.swapdevice', {
      detail: swapFields,
    });

    const room = m.rooms.find((e) => e.MacAddress == mac);
    if (room) {
      room.MacAddress = newmac;
      await save(true, true);
      return;
    }

    const ap = m.accessPoints.find((e) => e.MacAddress == mac);
    if (ap) {
      ap.MacAddress = newmac;
      await save(true, true);
      return;
    }

    const intf = m.deviceInterfaces.find((e) => e.MacAddress == mac);
    if (intf) {
      intf.MacAddress = newmac;
      await save(true, true);
      return;
    }
  };

  /**
   * Send ping IoT message for the specified mac address
   * We always expect a response from SCU even if the device did not repsond to the ping
   * If no response is received then the SCU will be offline
   *
   * @param mac
   * @param displayDialogue   Display the please wait dialogue
   */
  const ping = async (
    mac: number,
    displayDialogue: boolean
  ): Promise<{ gotDeviceResponse: boolean; gotSCUResponse: boolean }> => {
    let gotResponse = false;
    let gotSCUResponse = false;

    if (displayDialogue) {
      setDialogueProps({
        show: true,
        dialogueText: `Waiting for a response...`,
        showSpinner: true,
      });
    }

    try {
      const response = await IoTPing.pingDevice(
        mac,
        IoTPing.tePROCESSOR.E_PROCESSOR_DATA,
        null
      );

      const resp = response.msgHeader
        ?.msg as IoTMessageDefines_DEVICES.tsCLOUD_MSG_DEVICES_DEVICE_PING_RESP;
      if (
        resp &&
        resp.errorcode ==
          IoTMessageDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        gotResponse = true;
      }
      gotSCUResponse = true;
    } catch (e) {
      console.info('No response from ', mac);
    }

    setAllData((old) => {
      const entry = old.find((e) => e.MacAddress === mac);
      if (entry) {
        entry.pingStatus = gotResponse ? 'Ok' : 'Fail';
      }
      return _.cloneDeep(old);
    });
    if (displayDialogue) {
      setDialogueProps(undefined);
    }

    return { gotDeviceResponse: gotResponse, gotSCUResponse: gotSCUResponse };
  };

  /**
   * Loop though all the device and send a ping request for each.
   * The function waits for the a ping response before sending the
   * next ping request
   * @returns
   */
  const pingAll = async (): Promise<void> => {
    console.info('Ping all');
    let filterFails = false;
    const _allData = allDataRef.current;

    setTableFilters([]);

    for (const index in _allData) {
      const device = _allData[index];
      if (canPing(device)) {
        setDialogueProps({
          show: true,
          dialogueText: `Pinging device id ${device.ID}`,
          showSpinner: true,
        });
        const pingResp = await ping(device.MacAddress, false);
        if (!pingResp.gotSCUResponse) {
          setDialogueProps({
            show: true,
            dialogueText: `The site is currently offline, please try later`,
            showSpinner: false,
            showOkButton: true,
            okButtonPress() {
              setDialogueProps(undefined);
            },
          });
          return;
        }

        if (!pingResp.gotDeviceResponse) {
          filterFails = true;
        }
      }
    }

    if (filterFails) {
      setTableFilters([
        {
          id: 'pingStatus',
          value: 'Fail',
        },
      ]);
    }

    setDialogueProps(undefined);
  };

  /**
   * Send a reset request to the SCU for the specified device.
   * A dialogue will be shown once complete
   * @param device
   */
  const resetDevice = async (device: iDeviceInfo) => {
    let message = '';

    setDialogueProps({
      show: true,
      dialogueText: `Resetting device, please wait...`,
      showSpinner: false,
      showOkButton: false,
    });

    try {
      const response = await IoTReset.resetDevice(device.MacAddress, null);

      const resp = response.msgHeader
        ?.msg as IoTMessageDefines_SYSTEM.tsCLOUD_MSG_SYSTEM_RESET_RESP;
      if (
        resp &&
        resp.errorcode ==
          IoTMessageDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        message = 'Reset Complete';
      } else {
        message = `Failed to reset device, error code ${resp.errorcode}`;
      }
    } catch (e) {
      console.info('No reset response from the SCU');
      message = 'The site is offline at this time.  Please try later';
    }

    setDialogueProps({
      show: true,
      dialogueText: message,
      showSpinner: false,
      showOkButton: true,
      okButtonPress() {
        setDialogueProps(undefined);
      },
    });
  };

  const resetSCU = async () => {
    let message = '';
    let dialogueTextLine2: string | undefined = undefined;

    setDialogueProps({
      show: true,
      dialogueText: `Resetting SCU, please wait...`,
      showSpinner: false,
      showOkButton: false,
    });

    try {
      const response = await IoTSCUReset.scuReset(0x12349876, null);

      const resp = response.msgHeader
        ?.msg as IoTMessageDefines_SYSTEM.tsCLOUD_MSG_SYSTEM_RESET_RESP;
      if (
        resp &&
        resp.errorcode ==
          IoTMessageDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        message = 'Reset Requested.';
        dialogueTextLine2 = 'Please wait 2 minutes while the SCU restarts.';
      } else {
        message = `Failed to reset, error code ${resp.errorcode}`;
      }
    } catch (e) {
      console.info('No reset response from the SCU');
      message = 'The site is offline at this time.  Please try later';
    }

    setDialogueProps({
      show: true,
      dialogueText: message,
      dialogueTextLine2: dialogueTextLine2,
      showSpinner: false,
      showOkButton: true,
      okButtonPress() {
        setDialogueProps(undefined);
      },
    });
  };

  const restartTunnel = async () => {
    let message = '';
    const dialogueTextLine2: string | undefined = undefined;

    setDialogueProps({
      show: true,
      dialogueText: `Requesting Tunnel Reset, please wait...`,
      showSpinner: false,
      showOkButton: false,
    });

    try {
      const response = await IoTTunnel.scuRestartTunnel(null);

      const resp = response.msgHeader
        ?.msg as IoTMessageDefines_SYSTEM.E_CLOUD_MSG_SYSTEM_RESTART_TUNNEL_RESP;
      if (
        resp &&
        resp.errorcode ==
          IoTMessageDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        message = 'Tunnel Restart Requested.';
      } else {
        message = `Failed to restart tunnel, error code ${resp.errorcode}`;
      }
    } catch (e) {
      console.info('No response from the SCU');
      message = 'The site is offline at this time.  Please try later';
    }

    setDialogueProps({
      show: true,
      dialogueText: message,
      dialogueTextLine2: dialogueTextLine2,
      showSpinner: false,
      showOkButton: true,
      okButtonPress() {
        setDialogueProps(undefined);
      },
    });
  };

  const saveTableData = async () => {
    logger.info('deviceview.saveTableData', {
      changedIDs: changesRef.current,
    });
    let requireRepopulatecaches = false;

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const data = allDataRef.current.find((e) => e.ID === id);

      if (data === undefined) continue;

      const room = m.rooms.find((e) => e.MacAddress === data.MacAddress);
      if (room) {
        room.Name = data.Name;
        if (data.associatedUnitId) {
          room.RoomNumber = data.associatedUnitId;
          if (room.ClusterGW != data.ClusterGW) {
            console.info(
              `ClusterGW changed for ID:${room.ID} ${room.ClusterGW} -> ${data.ClusterGW}`
            );
            room.setEthernetConfig(data.ClusterGW ? true : false);
            requireRepopulatecaches = true;
          }
        }
        continue;
      }

      const ap = m.accessPoints.find((e) => e.MacAddress === data.MacAddress);
      if (ap) {
        ap.Name = data.Name;
        if (data.associatedUnitId) {
          ap.UnitId = data.associatedUnitId;
        }
        if (ap.ClusterGW != data.ClusterGW) {
          console.info(
            `ClusterGW changed for ID:${ap.ID} ${ap.ClusterGW} -> ${data.ClusterGW}`
          );
          ap.setEthernetConfig(data.ClusterGW ? true : false);
          requireRepopulatecaches = true;
        }
        continue;
      }

      const intf = m.deviceInterfaces.find(
        (e) => e.MacAddress === data.MacAddress
      );
      if (intf) {
        intf.Name = data.Name;
        if (intf.ClusterGW != data.ClusterGW) {
          console.info(
            `ClusterGW changed for ID:${intf.ID} ${intf.ClusterGW} -> ${data.ClusterGW}`
          );
          intf.setEthernetConfig(data.ClusterGW ? true : false);
          requireRepopulatecaches = true;
        }

        if (data.associatedUnitId) {
          intf.UnitId = data.associatedUnitId;
        }
        continue;
      }
    }

    await save(true, requireRepopulatecaches);
    setChanges([]);
  };

  /**
   * Setup the swap device fields and show the form layer
   * @param device
   */
  const swapDevice = (device: iDeviceInfo): void => {
    const swapDevice: iDeviceSwapFields = {
      oldMac: Device.MACaddrToString(device.MacAddress, 16),
      newMac: Device.MACaddrToString(device.MacAddress, 16),
      hwModel: device.HwModel,
    };
    setDeviceSwapFields(swapDevice);
    let HWModels = HWModels_interfaces;
    if (
      device.Type === ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER
    ) {
      HWModels = HWModels_ap;
    } else if (
      device.Type === ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT
    ) {
      HWModels = HWModels_room;
    }
    swapDeviceDisplayFields = [
      { label: 'Old MAC Address', id: 'oldMac', type: 'readonly' },
      { label: 'New MAC Address', id: 'newMac', type: 'text' },
      {
        label: 'HW Model',
        id: 'hwModel',
        type: 'select',
        options: HWModels,
      },
    ];

    setShowSwapDeviceDialogue(true);
  };

  /**
   * @param id    room unit device id
   * @param property item to change in the room unit object
   * @param value new value
   */
  const setProperty = (id: number, property: string, value: unknown) => {
    const device = allDataRef.current.find((e) => e.ID == id);

    if (device) {
      const originalRecord = originalData.find((e) => e.ID == id);

      const setIt = () => {
        if (!changesRef.current.includes(id)) {
          changesRef.current.push(id);
          setRenderCount((old) => old + 1);
        }
      };

      _.set(device, property, value);
      setRenderCount((old) => old + 1);

      if (originalRecord) {
        if (_.get(originalRecord, property, 0) != value) {
          setIt();
        } else {
          _.pull(changesRef.current, id);
        }
      } else {
        console.error("Couldn't find original record id:", id);
      }
    } else {
      console.error("Couldn't find device id:", id);
    }
  };

  function getProperty(id: number, property: string): unknown {
    const device = allDataRef.current.find((e) => e.ID == id);
    if (device === undefined) {
      return '';
    }

    if (property === 'ClusterGW') {
      if (
        device.Personality ===
        ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_GWI
      ) {
        return 1;
      }
    }

    const value = _.get(device, property, 0);
    return value;
  }

  /*
  const validateRoomNumber = (
    id: number,
    newValue: unknown
  ): number | undefined => {
    const unitId = newValue as number;
    const otherUnit = allDataRef.current.find(
      (e) => e.associatedUnitId == unitId && e.ID != id
    );

    const originalRecord = originalData.find((e) => e.ID == id);
    let revertValue = undefined;

    if (
      newValue == 9000 &&
      originalRecord?.Type ===
        ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER
    ) {
      // there can be multiple units with the same special system unitid 9000
      return undefined;
    }

    if (otherUnit && originalRecord) {
      revertValue = originalRecord.associatedUnitId;
      logger.info('deviceView.invalidUnitId', { unitId: unitId });
      setDialogueProps({
        show: true,
        dialogueText: `The unit ID ${unitId} is already in use.`,
        dialogueTextLine2: `Assigned to: ${otherUnit.Name} , ID:${otherUnit.ID}`,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => setDialogueProps(undefined),
      });
    }
    console.info('Revert Value:', revertValue);
    return revertValue;
  };*/

  const deleteDevice = async (device: iDeviceInfo): Promise<void> => {
    setDialogueProps({
      show: true,
      dialogueText: `Please wait...`,
      showSpinner: true,
      showOkButton: false,
    });

    let message = '';
    try {
      const resp = (
        await IoTDeviceAddDelete.deleteDevice(device.MacAddress, null)
      ).msgHeader?.msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_DELETE_RESP;

      console.info('delete respone:', resp);

      if (
        resp.errorcode !== IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        logger.error('Failed to delete device', {
          device: device,
          error: resp.errorcode,
        });
        message = `Failed to delete device with the identity ${Device.MACaddrToString(
          device.MacAddress,
          16
        )}`;
      } else {
        // clear all faults associated with this device
        await systemFaults.clearfaultsfordevice(device.ID);

        try {
          setDialogueProps({
            show: true,
            dialogueText: `Device deleted, synchronising...`,
            showSpinner: true,
            showOkButton: false,
          });
          await IoTSCUManifest.Instance.requestManifestUpload(null);
        } catch (error) {
          console.error('Error waiting for manifest request, error:', error);
        }
      }
    } catch (e) {
      console.error('delete failed with error:', e);
      logger.error('devcie delete failed', { error: e });
      message = `The SCU did not respond. It maybe offline.`;
    }

    if (message !== '') {
      setDialogueProps({
        show: true,
        dialogueText: message,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => setDialogueProps(undefined),
      });
    } else {
      setDialogueProps(undefined);
    }
  };

  const columns = React.useMemo<Column<iDeviceInfo>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        width: 20,

        /* eslint-disable react/prop-types */
        Cell: (data: Cell<iDeviceInfo>) => {
          const colour = undefined;
          /*
          if (changesRef.current.includes(data.row.original.ID)) {
            colour = 'red';
          }*/

          return (
            <>
              <Box
                pad="none"
                direction="row"
                justify="center"
                align="center"
                style={{ color: colour }}
              >
                {data.value}
                {canPing(data.row.original) && (
                  <Tip
                    dropProps={{ margin: 'large' }}
                    content={<Text>Ping the device</Text>}
                  >
                    <Icons.Send
                      size="15px"
                      onClick={() => {
                        ping(data.row.original.MacAddress, true);
                      }}
                    />
                  </Tip>
                )}
              </Box>
            </>
          );
        },
      },

      {
        Header: 'Name',
        accessor: 'Name',
        /* eslint-disable react/prop-types */

        /*Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Name}
            valueProperty={`Name`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="text"
          />
        ),*/
      },
      {
        Header: 'Identity',
        accessor: (row) => {
          if (row.Type == ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR) {
            return row.IPAddress;
          } else {
            return Device.MACaddrToString(row.MacAddress, 16);
          }
        },
        width: 30,
      },
      // {
      //   Header: 'Date Assigned',
      //   accessor: (row) => {

      //       return Device.;

      //   },
      //   width: 30,
      // },
      {
        Header: 'Device Type',
        accessor: (row) => {
          let baseName = Product.getProductBaseName(
            Product.getProductfromManifestDeviceType(row)
          );

          switch (row.Personality) {
            case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_GWI:
              baseName += ' (GWI)'; // GW Interface
              break;

            case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MCI:
              baseName += ' (MCI)'; // Call point interface
              break;

            case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MDI:
              baseName += ' (MDI)'; // Door interface
              break;

            case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MLI:
              baseName += ' (MLI)'; // Lift interface
              break;

            case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MPI:
              baseName += ' (MPI)'; // Pull cord interface
              break;
          }
          return baseName;
        },
      },
      {
        Header: 'Unit/Room Id',
        id: 'associatedUnitId',
        accessor: 'associatedUnitId',
        width: '80px',
        /* eslint-disable react/prop-types */
        /*
        Cell: ({ ...props }) => {
          const scuSWVersion = m.getSCUSoftwareVersion();

          // Ability to set UnitId on repeaters/access points was only introduced in R4.46
          if (
            props.row.original.Type ==
              ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER &&
            scuSWVersion < 44600
          ) {
            return props.row.original.associatedUnitId;
          } else {
            return (
              <InputCell
                id={props.row.values.ID}
                reRenderValue={props.row.original.associatedUnitId}
                valueProperty={`associatedUnitId`}
                getProperty={getProperty}
                setProperty={setProperty}
                validator={validateRoomNumber}
                inputType="number"
              />
            );
          }
        },*/
      },
      {
        Header: 'IP Address',
        accessor: (row) => {
          let bridge = false;
          if (row.ClusterGW) {
            bridge = true;
          } else if (
            row.Personality ===
            ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_GWI
          ) {
            bridge = true;
          }

          if (bridge) {
            return m.getDeviceIPAddress(row.MacAddress);
          }

          return '';
        },
      },

      {
        Header: 'Ethernet',
        width: '80px',
        accessor: 'ClusterGW',
        Cell: ({ ...props }) => {
          if (Device.EthernetCapable(props.row.original)) {
            return (
              <CheckBoxCell
                id={props.row.values.ID}
                reRenderValue={props.row.original.ClusterGW}
                valueProperty={`ClusterGW`}
                getProperty={getProperty}
                setProperty={setProperty}
              />
            );
          }
          return <></>;
        },
      },
      {
        Header: 'Ping Status',
        width: '80px',
        id: 'pingStatus',
        accessor: (row) => (row.pingStatus ? row.pingStatus : ''),
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Load the necassary from the manifest.
   */
  const loadDataEvent = (): void => {
    const devices: iDeviceInfo[] = _.cloneDeep(m.devices) as iDeviceInfo[];
    const responseArray: iDeviceInfo[] = [];

    for (const index in devices) {
      const device = devices[index];
      const room = m.getRoomByDeviceID(device.ID);
      if (room) {
        device.associatedUnitId = room.RoomNumber;
        device.Name = room.Name;
        responseArray.push(device);
        continue;
      }

      const ap = m.getAccessPointByDeviceMac(device.MacAddress);
      if (ap) {
        device.associatedUnitId = ap.UnitId;
        device.Name = ap.Name;
        responseArray.push(device);
        continue;
      }

      const intf = m.getInterfaceByDeviceMac(device.MacAddress);
      if (intf) {
        device.associatedUnitId = intf.UnitId;
        device.Name = intf.Name;
        responseArray.push(device);
        continue;
      }

      const hs = m.getHandsetByDeviceMac(device.MacAddress);
      if (hs) {
        device.associatedUnitId = 0;
        device.Name = hs.Description;
        responseArray.push(device);
        continue;
      }

      const gwi = m.getGWIDeviceMac(device.MacAddress);
      if (gwi) {
        device.associatedUnitId = 0;
        device.Name = gwi.Description;
        responseArray.push(device);
        continue;
      }
    }

    const unassigned = m.getUnassignedDevices();
    for (const index in unassigned) {
      const unassDevice = unassigned[index];
      if (
        unassDevice.Type ==
          ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT ||
        unassDevice.Type ==
          ManifestEnums.teDEVICE_TYPES
            .DEVICE_TYPE_APEX_DOOR_PANEL_AND_INTERFACES
      ) {
        const device: iDeviceInfo = _.cloneDeep(unassDevice) as iDeviceInfo;

        device.associatedUnitId = 0;
        device.Name =
          'Unassigned:' + Device.MACaddrToString(unassDevice.MacAddress, 16);
        responseArray.push(device);
      }
    }

    setAllData(responseArray);
    originalData = _.cloneDeep(responseArray);
    setChanges([]);
  };

  const sendCommissionRequest = async (
    ip: boolean,
    timeout: number
  ): Promise<void> => {
    setDialogueProps({
      show: true,
      dialogueText: `Please wait...`,
      showSpinner: true,
      showOkButton: false,
    });

    try {
      const msg = await IoTComissioning.commissionReq(
        ip,
        ip === false,
        timeout,
        null
      );

      const resp = msg.msgHeader
        ?.msg as IoTComissioning.tsCLOUD_MSG_DEVICES_COMISSION_RESP;
      if (
        resp &&
        resp.errorcode ==
          IoTDeviceAddDelete.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        console.info('Commission start successfull');
        setDialogueProps({
          show: true,
          dialogueText: `Commissioning started for ${Math.floor(
            timeout / 60
          )} minutes`,
          showSpinner: false,
          showOkButton: true,
          okButtonPress() {
            setDialogueProps(undefined);
          },
        });
      } else {
        setDialogueProps({
          show: true,
          dialogueText: `Failed to start commissioning, error:${IoTDeviceAddDelete.getErrorText(
            resp?.errorcode
          )}`,
          showSpinner: false,
          showOkButton: true,
          okButtonPress: () => {
            setDialogueProps(undefined);
          },
        });
      }
    } catch (e) {
      setDialogueProps({
        show: true,
        dialogueText: `The site is currently offline, please try later`,
        showSpinner: false,
        showOkButton: true,
        okButtonPress() {
          setDialogueProps(undefined);
        },
      });
    }
  };

  const initialState = React.useMemo(
    () => ({
      pageSize: 50,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const events: iTileEvent[] = [
    {
      topic: EventDispatcher.systemEventTopics.MANIFEST,
      state: EventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        loadDataEvent();
      },
      executeOnStartup: true,
    },
    {
      topic: EventDispatcher.systemEventTopics.MANIFEST,
      state: EventDispatcher.systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },

    // When we add a GWI, a manifest upload is request.
    // DOWNLOAD complete will be emitted when the new manifest is downloaded from S3
    {
      topic: EventDispatcher.systemEventTopics.MANIFEST,
      state: EventDispatcher.systemEventStates.DOWNLOADCOMPLETE,
      callback: () => {
        if (showGWIConfigLayerRef.current == true) {
          setGWIConfigComplete(true);
        }
      },
      executeOnStartup: false,
    },
  ];

  const gwiConfigured = () => {
    const gwi = allData.find((e) => e.ID == 1);
    return gwi !== undefined ? true : false;
  };

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Ping all',
          icon: <Icons.Send size="medium" />,
          onClick: () => pingAll(),
          disabled: false,
        },
        {
          label: 'Swap device',
          icon: <Icons.Update size="medium" />,
          onClick: () => {
            if (selectedDevice) swapDevice(selectedDevice);
          },
          disabled:
            selectedDevice &&
            selectedDevice.Type !==
              ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR
              ? false
              : true,
        },
        {
          label: 'Reset Device',
          icon: <Icons.Power size="medium" />,
          onClick: () => {
            if (selectedDevice === undefined) return;
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to reset ${selectedDevice.Name}`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress() {
                resetDevice(selectedDevice);
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
          },
          disabled:
            selectedDevice &&
            selectedDevice.Type !==
              ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR
              ? false
              : true,
        },
        {
          label: 'Delete Device',
          icon: <Icons.Trash size="medium" />,
          onClick: () => {
            if (selectedDevice) {
              setDialogueProps({
                show: true,
                dialogueText: `Are you sure you want to delete this device?`,
                dialogueTextLine2: `Identity: ${Device.MACaddrToString(
                  selectedDevice.MacAddress,
                  16
                )}`,
                showSpinner: false,
                showOkButton: true,
                showCancelButton: true,
                okButtonPress() {
                  logger.info('deviceView.deleteDevice', {
                    device: selectedDevice,
                  });
                  deleteDevice(selectedDevice);
                },
                cancelButtonPress() {
                  setDialogueProps(undefined);
                },
              });
            }
          },
          disabled: selectedDevice === undefined,
        },

        {
          label: 'Commission Wired',
          icon: <Icons.Gateway size="medium" />,
          onClick: () => {
            if (gwiConfigured() === false) {
              setGWIConfigComplete(false);
              setShowGWIConfigLayer(true);
            } else {
              sendCommissionRequest(true, 60 * 15);
            }
          },
          disabled: false,
        },
        {
          label: 'Commission Wireless',
          icon: <Icons.Gateway size="medium" />,
          onClick: () => {
            sendCommissionRequest(false, 60 * 15);
          },
          disabled: false,
        },
        {
          label: 'Reset SCU',
          icon: <Icons.Power size="medium" />,
          onClick: async () => {
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to Reset the SCU?`,
              dialogueTextLine2: ``,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress() {
                logger.info('deviceView.resetscu');
                resetSCU();
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
          },
          disabled: false,
        },
        {
          label: 'Restart Remote Access Tunnel',
          icon: <Icons.View size="medium" />,
          onClick: async () => {
            logger.info('deviceView.restartTunnel');
            await restartTunnel();
          },
          disabled: false,
          accessLevel: UAG.ADMIN,
        },

        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('deviceView.discardChanges');
            loadDataEvent();
          },
          disabled: false,
          accessLevel: UAG.ADMIN,
        },
      ],
    }),
    [selectedDevice, allData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? changes.length : false}
      tip="Save changes"
      disabled={changes.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        saveTableData();
      }}
    />
  );

  const swapDeviceLayer = (
    <Layer
      style={{ whiteSpace: 'pre-wrap' }}
      background="dialogBackground"
      modal={true}
    >
      <Box pad="small" gap="small" align="center">
        <Paragraph textAlign="center">
          Please enter the MAC address to swap
        </Paragraph>

        <Form<iDeviceSwapFields>
          value={deviceSwapFields}
          onChange={(nextValue) => {
            setDeviceSwapFields(nextValue);
          }}
          onSubmit={async (event) => {
            console.log('onSubmit value:', event.value);
            console.log('onSubmit touched:', event.touched);

            const newMac = Device.MACaddrStringToNumber(event.value.newMac);
            const device = m.devices.find((e) => e.MacAddress == newMac);
            if (device !== undefined) {
              setDialogueProps({
                show: true,
                dialogueText: 'New MAC address already in use',
                showSpinner: false,
                showOkButton: true,
                okButtonPress: () => {
                  setDialogueProps(undefined);
                },
              });
              return;
            }

            await saveSwapDevice(event.value);
            setDeviceSwapFields(undefined);
            setShowSwapDeviceDialogue(false);
            setSelectedDevice(undefined);
          }}
        >
          <Box direction="column">
            <FormFields.AlignedFormFields
              displayfields={swapDeviceDisplayFields}
            />
          </Box>

          <Box direction="row" gap="medium" justify="center">
            <Button
              label="OK"
              size="medium"
              plain={false}
              margin="small"
              type="submit"
            />

            <Button
              label="Cancel"
              size="medium"
              plain={false}
              margin="small"
              onClick={() => {
                setShowSwapDeviceDialogue(false);
              }}
            />
          </Box>
        </Form>
      </Box>
    </Layer>
  );

  useEffect(() => {
    if (GWIConfigComplete == true) {
      setShowGWIConfigLayer(false);
      setGWIConfigComplete(false);
      // We have just added a GWI, start comissioning
      sendCommissionRequest(true, 60 * 15);
    }
  }, [GWIConfigComplete]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title="Device Management"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      showLastEvent={true}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        {showGWIConfigLayer && (
          <GWIConfigLayer
            show={showGWIConfigLayer}
            setComplete={setGWIConfigComplete}
          />
        )}
        {showSwapDeviceDialogue && deviceSwapFields && swapDeviceLayer}

        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={allData ? allData : []}
          showCheckboxes={false}
          onLastSelectedRow={selectDevice}
          currentSelectedID={selectedDevice?.ID}
          selectorKeyName="ID"
          reportDescription={{
            header: '',
            filename: 'DeviceList',
          }}
          filters={tableFilters}
        />
      </>
    </Tile>
  );
};

export default DeviceView;
