import { ALARM_TYPES } from 'types/event-enums';

export const ALARM_TYPES_VRU_HWIP: ALARM_TYPES[] = [
  ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT,
  ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_2, // Pullcord
  ALARM_TYPES.ALARM_TYPE_FIRE,
  ALARM_TYPES.ALARM_TYPE_SMOKE,
  ALARM_TYPES.ALARM_TYPE_HIGH_TEMPERATURE,
  ALARM_TYPES.ALARM_TYPE_MAINS_POWER,
  ALARM_TYPES.ALARM_TYPE_MAINS_POWER_RESTORED,
  ALARM_TYPES.ALARM_TYPE_ENURESIS,
  ALARM_TYPES.ALARM_TYPE_BED_OCCUPANCY,
  ALARM_TYPES.ALARM_TYPE_BOGUS_CALLER_TRIGGER,
  ALARM_TYPES.ALARM_TYPE_CHAIR_OCCUPANCY,
  ALARM_TYPES.ALARM_TYPE_BEDCHAIR_ABSENCE,
  ALARM_TYPES.ALARM_TYPE_BEDCHAIR_NOT_IN,
  ALARM_TYPES.ALARM_TYPE_BEDCHAIR_NOT_UP,
  ALARM_TYPES.ALARM_TYPE_BEDCHAIR_OTHER,
  ALARM_TYPES.ALARM_TYPE_MEDICATION_DISPENSER,
  ALARM_TYPES.ALARM_TYPE_MEDICATION_DOSE_MISSED,
  ALARM_TYPES.ALARM_TYPE_SEIZURE,
  ALARM_TYPES.ALARM_TYPE_PASSIVE_INFRA_RED_PIR_DETECTOR,
  ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_1,
  ALARM_TYPES.ALARM_TYPE_FIRE_DOOR_OPEN,
  ALARM_TYPES.ALARM_TYPE_DOOR_OPEN,
  ALARM_TYPES.ALARM_TYPE_DOOR_CLOSED,
  ALARM_TYPES.ALARM_TYPE_DOOR_LEFT_OPEN,
  ALARM_TYPES.ALARM_TYPE_DOOR_CONTACT,
  ALARM_TYPES.ALARM_TYPE_ASSISTANCE_NEEDED,
  ALARM_TYPES.ALARM_TYPE_EMERGENCY,
  ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_1, // Pendant
  ALARM_TYPES.ALARM_TYPE_PANIC_BUTTON,
  ALARM_TYPES.ALARM_TYPE_CARBON_MONOXIDE_GAS,
  ALARM_TYPES.ALARM_TYPE_LOW_TEMPERATURE,
  ALARM_TYPES.ALARM_TYPE_ENVIRONMENTAL_MONITOR,
  ALARM_TYPES.ALARM_TYPE_NATURAL_GAS,
  ALARM_TYPES.ALARM_TYPE_FLOOD,
  ALARM_TYPES.ALARM_TYPE_INACTIVITY,
  ALARM_TYPES.ALARM_TYPE_INTRUDER,
  ALARM_TYPES.ALARM_TYPE_WANDERING,
  ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM_DISARM_TOGGLE,
  ALARM_TYPES.ALARM_TYPE_TAMPER,
  ALARM_TYPES.ALARM_TYPE_NURSE_CALL,
  ALARM_TYPES.ALARM_TYPE_CARER_ALARM,
  ALARM_TYPES.ALARM_TYPE_PRESSURE_MAT,
  ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_1,
  ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_2,
  ALARM_TYPES.ALARM_TYPE_FALL_MANUAL_PRESS,
  ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1, // "Red Button"
];
