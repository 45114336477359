import React, { useEffect, useState } from 'react';
import semver from 'semver';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest } from 'components/installation/manifest';
import { versionToString } from 'components/installation/manifestUtils';

const SCUversion = (props: {
  children: React.ReactNode;
  minVersion: string;
}): JSX.Element => {
  const [SCUSoftwareVerion, setSCUSoftwareVersion] = useState('0.0.0');
  const manifest = new Manifest();

  const loadDataEvent = (/*e: eventDispatcher.iEventParams*/) => {
    setSCUSoftwareVersion(versionToString(manifest.swVersion));
  };

  const meetsMinimumVersion = semver.gte(SCUSoftwareVerion, props.minVersion);

  // console.log(meetsMinimumVersion, SCUSoftwareVerion, props.minVersion);

  useEffect(() => {
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.PROCESSED,
      loadDataEvent
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.UPDATED,
      loadDataEvent
    );

    if (manifest) setSCUSoftwareVersion(versionToString(manifest.swVersion));

    return () => {
      // Remove registrations on exit

      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.PROCESSED,
        loadDataEvent
      );
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.UPDATED,
        loadDataEvent
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{meetsMinimumVersion && props.children}</>;
};

export default SCUversion;
