/*
   Container for all local stroage keys we are using so we have them listed
   in single place and to ensure duplicates are not used.
*/

export enum teLocalStorageItems {
  E_LS_KEY_OVERRIDE_USER_LEVEL = 'overrideUserLevel',
  E_LS_KEY_USER_SELECTIONS = 'UserSelections',
  E_RESIDENT_CALLING_PHONE_NO = 'ResidenCallingPhoneNumber',
  E_LS_LASTPAGE_HISTORY = 'LastPageHistory',
  E_LS_LASTRESIDENTGROUP = 'LastResidentGroup',
}
