import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../tile';
import { Button, Box, Form, Select } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import _ from 'lodash';
import * as Wait from 'components/dialogues/waitDialogue';
import * as Icons from 'grommet-icons';
import * as API from 'types/API';
import * as clientsslice from 'store/clientsSlice';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as Utils from 'common/utils/dateUtils';
import * as FormFields from 'components/forms/formFieldsOld';
import { getDifferences } from 'common/utils/miscUtils';

const blank: API.UpdateOmniviaClientInput = {
  id: '',
  name: '',
  description: '',
  addr1: '',
  addr2: '',
  addr3: '',
  city: '',
  county: '',
  country: '',
  postcode: '',
  contactEmail: '',
  contactTelephone: '',
  timeZone: 'Europe/London',
};

const ClientEdit = (): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [changes, setChanges] = useState<unknown>({});
  const allClients = useSelector(clientsslice.getAll);

  const [selectedClient, setSelectedClient, selectedClientRef] =
    useState<API.UpdateOmniviaClientInput>(blank);
  const [clientOptions, setClientOptions] = useState(allClients);

  const fields: FormFields.iField[] = [
    { label: 'ID', id: 'id', type: 'readonly' },
    { label: 'Name', id: 'name', type: 'text' },
    {
      label: 'Description',
      id: 'description',
      type: 'text',
      formBoxWidth: 'large',
    },
    { label: 'Address 1', id: 'addr1', type: 'text' },
    { label: 'Address 2', id: 'addr2', type: 'text' },
    { label: 'Address 3', id: 'addr3', type: 'text' },
    { label: 'City', id: 'city', type: 'text' },
    { label: 'County', id: 'county', type: 'text' },
    { label: 'Country', id: 'country', type: 'text' },
    { label: 'Postcode', id: 'postcode', type: 'text', formBoxWidth: 'small' },
    { label: 'Email', id: 'contactEmail', type: 'text' },
    { label: 'Telephone', id: 'contactTelephone', type: 'text' },
  ];

  function loadData(client: API.OmniviaClient | undefined) {
    setChanges({});

    /*
     * FormFields generates errors if any fields are null.
     * Remove null fields fromn the object so they can be replaced with a blank string
     */
    let clientcopy = _.omitBy(client, _.isNull) as API.UpdateOmniviaClientInput;

    // Remove properties from API.OmniviaClient that are not in API.UpdateOmniviaClientInput so we
    // can use the object as input to the graphql mutation
    clientcopy = _.omit(clientcopy, [
      '__typename',
      'linkedSites',
    ]) as API.UpdateOmniviaClientInput;

    const newRecord = _.cloneDeep(blank) as API.UpdateOmniviaClientInput;

    setSelectedClient(_.merge(newRecord, clientcopy));
    setDialogueProps(undefined);
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.CLIENT,
      state: eventDispatcher.systemEventStates.EDIT,
      callback: (event) => {
        loadData(event.detail as API.OmniviaClient);
      },
      executeOnStartup: false,
    },
    {
      topic: eventDispatcher.systemEventTopics.CLIENT,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: (event) => {
        loadData(event.detail as API.OmniviaClient);
      },
      executeOnStartup: false,
    },
  ];

  async function save() {
    const diff = getDifferences(
      allClients.find((e) => e.id == selectedClientRef.current.id),
      selectedClientRef.current
    );

    console.info('Diffs:', diff);
    await store.dispatch(
      clientsslice.update({ id: selectedClient.id, changes: diff })
    );
  }

  const ContextButtons = (
    <Box direction="row" gap="large">
      <Select
        alignSelf="end"
        placeholder="Select a client..."
        labelKey="name"
        valueKey={{ key: 'id', reduce: true }}
        options={clientOptions}
        value={selectedClient?.id ? selectedClient.id : 'Select a client...'}
        size="medium"
        onChange={({ value: nextValue }) => {
          loadData(allClients.find((e) => e.id === nextValue));
        }}
        onClose={() => setClientOptions(allClients)}
        onSearch={(text) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp(escapedText, 'i');
          setClientOptions(clientOptions.filter((o) => exp.test(o.name)));
        }}
      />

      <Button
        plain={false}
        size="small"
        label="Save"
        color={!_.isEmpty(changes) ? 'brand' : 'grey-4'}
        badge={!_.isEmpty(changes) ? true : false}
        tip="Save changes"
        disabled={_.isEmpty(changes)}
        style={contextButtonStyle}
        onClick={() => {
          save();
        }}
      />
    </Box>
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            loadData(
              allClients.find((e) => e.id == selectedClientRef.current.id)
            );
          },
          disabled: _.isEmpty(changes) ? true : false,
        },
        {
          label: 'Create New',
          icon: <Icons.Add size="medium" />,
          onClick: async () => {
            setDialogueProps({
              show: true,
              dialogueText: 'Please Wait...',
              showSpinner: true,
              showOkButton: false,
            });

            const newClient = _.cloneDeep(blank);
            newClient.name = 'NewClient_' + Utils.formatDate(new Date());
            setSelectedClient(newClient);
            await store.dispatch(
              clientsslice.add({
                changes: _.omit(newClient, ['id', '__typename']),
              })
            );

            //setDialogueProps will get cleared when we get a CLIENT#UPDATE event
          },
          disabled: false,
        },
        {
          label: 'Delete Client',
          icon: <Icons.Trash size="medium" />,
          onClick: async () => {
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to delete ${selectedClient.name}?`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress: async () => {
                await store.dispatch(
                  clientsslice.deleteRecord({ id: selectedClient.id })
                );
                setDialogueProps({
                  show: true,
                  dialogueText: `Please wit`,
                  showSpinner: false,
                  showOkButton: false,
                });
              },
              cancelButtonPress: () => {
                setDialogueProps(undefined);
              },
            });
          },
          disabled: selectedClient && selectedClient.id !== '' ? false : true,
        },
      ],
    }),
    [selectedClient, changes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setClientOptions(allClients);
  }, [allClients]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title="Omnivia Client"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <Form<API.UpdateOmniviaClientInput>
          value={selectedClient}
          onReset={() => setSelectedClient(blank)}
          onChange={(nextValue) => {
            // console.log('Change', nextValue, touched);
            setSelectedClient(nextValue);
            setChanges(
              getDifferences(
                allClients.find((e) => e.id == selectedClient.id),
                selectedClientRef.current
              )
            );
          }}
          onSubmit={(event) =>
            console.log('onSubmit', event.value, event.touched)
          }
        >
          <Box direction="column">
            <FormFields.AlignedFormFields displayfields={fields} />
          </Box>
        </Form>
      </>
    </Tile>
  );
};

export default ClientEdit;
