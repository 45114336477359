import { emitEvent, systemEventStates } from './eventDispatcher';

export function createExtraReducers(topic, builder, fetch, select, adapter) {
  return (
    builder
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetch.pending, (state, action) => {
        emitEvent(topic, systemEventStates.LOADING, null, true);
        state.loadStatus = 'loading';
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetch.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload);
        emitEvent(topic, systemEventStates.LOADED, state, true);
        state.dataLoaded = true;
        state.loadStatus = 'idle';
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetch.rejected, (state, action) => {
        emitEvent(topic, systemEventStates.LOADERROR, null, true);
        state.loadStatus = 'error';
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(select.pending, (state, action) => {
        state.selectedId = '';
        state.selectStatus = 'loading';
      })
      .addCase(select.fulfilled, (state, action) => {
        adapter.setOne(state, action.payload);
        state.selectedId = action.payload.id;
        state.selectStatus = 'idle';
        emitEvent(topic, systemEventStates.SELECTED, action.payload.id, true);
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(select.rejected, (state, action) => {
        state.selectStatus = 'error';
      })
  );
}
