import Tile, { iTileEvent } from 'components/dashboard/tile';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Box,
  Text,
  Tip,
  Tabs,
  Tab,
} from 'grommet';
import React, { useState } from 'react';
import * as Icons from 'grommet-icons';
import { generateTestReport } from '../../../reports/reportTypes/serviceTestReport';
import { generateCoverageReport } from 'components/reports/reportTypes/coverageReport';
import * as UserUtils from 'common/userUtils';
import { useWinstonLogger } from 'winston-react';
import * as Wait from 'components/dialogues/waitDialogue';
import { iReportCard } from './types';
import { callReports } from './callsReports';
import { serviceReports } from './serviceReports';
import { arcReports } from './arcReports';

const ReportsCards = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const logger = useWinstonLogger();
  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();

  const generateCard = (r: iReportCard, index: number): JSX.Element => {
    const u = UserUtils.minimumAccessLevel(r.accessLevel);
    if (u)
      return (
        <Card
          key={`Report-${r.title}${index}`}
          height="small"
          width="small"
          background="light-1"
          margin="6px"
        >
          <CardHeader
            pad={{
              top: '6px',
              left: '2px',
              right: '2px',
              bottom: '8px',
            }}
            justify="center"
            background={r.color}
          >
            <Text weight="bold">{r.title}</Text>
          </CardHeader>
          <CardBody pad="1px">
            <Text>{r.description}</Text>
          </CardBody>
          <CardFooter
            pad={{
              top: '0px',
              left: '2px',
              right: '2px',
              bottom: '0px',
            }}
            background="light-2"
            justify="end"
          >
            <Tip content={<Text>{r.tip ?? 'Run Report'}</Text>}>
              <Button
                margin="none"
                icon={<Icons.Run color="plain" size="20px" />}
                hoverIndicator
                onClick={async () => {
                  logger.info(
                    'recentAlarms.generateReport.' + r.title.replace(/\s/g, '')
                  );
                  setDialogueProps({
                    show: true,
                    dialogueText:
                      'Please Wait Whilst the Report Is Being Prepared...',
                    showSpinner: true,
                    showOkButton: false,
                  });
                  await r.run();
                  setDialogueProps(undefined);
                }}
              />
            </Tip>
          </CardFooter>
        </Card>
      );
    return <></>;
  };

  const events: iTileEvent[] = [];

  return (
    <Tile
      title="Reports"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
    >
      <Tabs>
        <Tab title="Calls">
          <Box margin="16px" direction="row" wrap>
            {callReports &&
              callReports.map((r, index) => generateCard(r, index))}
          </Box>
        </Tab>
        <Tab title="Alarm Monitoring Centre (ARC)">
          <Box margin="16px" direction="row" wrap>
            {arcReports && arcReports.map((r, index) => generateCard(r, index))}
          </Box>
        </Tab>

        <Tab title="Service">
          <Box margin="16px" direction="row" wrap>
            {serviceReports &&
              serviceReports.map((r, index) => generateCard(r, index))}
          </Box>
        </Tab>
      </Tabs>
    </Tile>
  );
};
export default ReportsCards;
