/** @module ManifestSitreDataSync */

import { siteData, updateStore, manifest } from '../site';
import logger from 'common/logger';
import * as eventDispatcher from 'store/eventDispatcher';
import { AccessPoint } from '../../installation/accesspoint';
import { DoorAndDeviceInterface } from '../../installation/doorAndDeviceInterface';
import { Room } from 'components/installation/room';
import { Product } from '../product';
import { INSTALLSTATUS } from '../../../types/product-types';
import { invalidID } from '../../../types/manifest-types';

export type ManifestMatches = {
  physicalID: string;
  manifestUnitName: string;
  planName: string;
  uniqueID: string;
};

/** Purge invalid IDS
 * Go through the site data and look for physical IDs that are not in the manifest
 * This is possible indicator that equipment has been swapped onsite resulting in a new physical ID
 * The purge of the ID then means the match function will later try and match against the name etc...
 * TODO: need to exclude SCU
 * */
export const purgeInvalidPhysicalIDs = (): Product[] => {
  const purges: Product[] = [];

  siteData.equipment.forEach((e) => {
    // only check ones with a physical ID

    if (e.physicalID == 6066005661563386) {
      console.log('MAI Rest WC');
    }

    if (e.hasPhysicalID()) {
      const match = manifest.getDeviceByMac(e.physicalID);
      // possible onsite swap has occured to set our physical ID to invalid!!
      if (match.ID == invalidID) {
        // take a shallow copy
        const prod = new Product();
        Object.assign(prod, e);
        purges.push(prod);
        e.physicalID = invalidID;
      }
    }
  });

  if (purges && purges.length > 0)
    logger.info('mergeFromManifest.matchedNames', { purges: purges });
  return purges;
};

/** performs a smart match against the uniqueId to see if we can match up room units in the manifest
 *
 *  i.e. matches VRU1 against Manifest names like Apt1 or Room1 - uses local compre so case insenstive
 */
export const smartMatch = (e: Product, name: string): boolean => {
  let match = false;
  const roomSynonyms = ['Apt', 'Apt ', 'Room', 'Room ', 'Flat', 'Flat '];
  const roomDesignators = ['VRU', 'RUb', 'RUw'];
  let replace = '';

  // check if we are one of the room unit types
  if (e.uniqueID) {
    roomDesignators.forEach((r) => {
      if (e.uniqueID.startsWith(r)) replace = r;
    });
  }

  // if we are a room unit type then look for a possible smart match
  if (replace && replace.length > 2) {
    roomSynonyms.forEach((n) => {
      const candidate = e.uniqueID.replace(replace, n);
      // zero means equivalent
      if (candidate.toUpperCase() === name.toUpperCase()) {
        match = true;
      }
    });
  }
  return match;
};

/** Do the match...
 *  (1) Where the plandata unit is commissioned (has Physical ID) but the name or room number is out of date with the manifest
 *      This might occur if the name/ number has been changed from another source such as a hanbset
 *  (2) Check for assigned units in the manifest that do don't have a plan data match, then check to see if the locationName in the
 *      plandata is a good match to the name in the manifest, if so assign the physical id and match them up
 */
export const doMatching = (
  devices: Room[] | AccessPoint[] | DoorAndDeviceInterface[]
): [boolean, ManifestMatches[]] => {
  let updated = false;
  const matches: ManifestMatches[] = [];

  devices.forEach((device) => {
    // Do we aleady have a commissioned match in the plandata?
    const match = siteData.equipment.find(
      (e) => e.physicalID === device.MacAddress
    );

    const unitNumber =
      device instanceof Room ? device.RoomNumber : device.UnitId;
    const unitName = device.Name;

    // physical ID matches with Manifest which means we just need to look for any name changes etc and update the plandata with those
    if (match !== undefined) {
      if (unitNumber != match.unitNumber) {
        logger.info('mergeFromManifest.deviceNumber', {
          was: match.unitNumber,
          updatedTo: unitNumber,
        });
        match.unitNumber = unitNumber;
        updated = true;
      }
      if (device.Name != match.locationName) {
        logger.info('mergeFromManifest.deviceName', {
          was: match.locationName,
          updatedTo: unitName,
        });
        match.locationName = unitName;
        updated = true;
      }
    }

    // no match on physical ID  so we need to look for possible name matches to assign the physical ID in the site data
    // check whether the names are identical and match by name
    // or do a smartmatch by using room synonymns such as APT, ROOM
    else if (match == undefined) {
      const nameMatch = siteData.equipment.find(
        (e) =>
          unitName &&
          unitName.length > 2 &&
          (e.locationName == unitName || smartMatch(e, unitName))
      );
      if (nameMatch != undefined) {
        // Found a name match

        matches.push({
          physicalID: device.getDeviceMACaddr(),
          manifestUnitName: unitName,
          planName: nameMatch.locationName,
          uniqueID: nameMatch.uniqueID,
        });

        logger.info('mergeFromManifest.matchedNames', {
          manifestUnitName: unitName,
          planName: nameMatch.locationName,
          uniqueID: nameMatch.uniqueID,
        });

        nameMatch.physicalID = device.MacAddress;
        nameMatch.locationName = unitName;
        nameMatch.unitNumber = unitNumber;
        nameMatch.installStatus = INSTALLSTATUS.COMMISSIONED;
        updated = true;
      }
    }
  });

  if (matches && matches.length > 0)
    logger.info('mergeFromManifest.matchedNames', { matches: matches });

  return [updated, matches];
};

/** Check the manifest for assigned devices (room units, access points and device interfaces).
 *  (1) Where the plandata unit is commissioned (has Physical ID) but the name or room number is out of date with the manifest
 *      This might occur if the name/ number has been changed from another source such as a hanbset
 *  (2) Check for assigned units in the manifest that do don't have a plan data match, then check to see if the locationName in the
 *      plandata is a good match to the name in the manifest, if so assign the physical id and match them up
 */
export const mergeManifestIntoSiteData = (): boolean => {
  let updated = false;

  // Sync Room Unit Data
  let [u] = doMatching(manifest.rooms);
  updated = u;

  // MAPS / MLS
  [u] = doMatching(manifest.accessPoints);
  updated = updated ? true : u;

  // MAI
  [u] = doMatching(manifest.deviceInterfaces);
  updated = updated ? true : u;

  if (updated) updateStore(true);
  return updated;
};

/** Check the manifest for assigned devices in the manifest that do not have commissioned
 *  status in the plandata - try and match location names
 *  checks VRUs, access points and MAIs
 */
export const syncAssignedUnitsLatestManifestData = (): boolean => {
  let updated = false;

  updated = false;
  return updated;
};

/** Register event such that if the manifest changes we refresh our plandata */
eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.MANIFEST,
  eventDispatcher.systemEventStates.PROCESSED,
  mergeManifestIntoSiteData
);
