/** @module FloorPlans */

import React, { useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';

import { Button, Grid, Text, Select } from 'grommet';
import { PlanModeEnum } from '../../types/plan-types';
import { products } from 'data/product-definitions';
import {
  Equipment,
  ProductCategory,
  ProductCategorySelect,
} from 'types/product-types';
import * as eventDispatcher from 'store/eventDispatcher';
import { siteData } from './site';
import { rescale } from './canvasSite';
import { createInstallStatusSheet } from './createXLSX';
import { useWinstonLogger } from 'winston-react';
import cablingXLSX from './exports/cablingXLSX';
import * as UsersUtils from 'common/userUtils';
import * as UserUtils from 'common/userUtils';
import * as _ from 'lodash';
import { generateARCUnitDetailsReport } from 'components/reports/reportTypes/arcUnitDetails';

interface productToolbarProps {
  currentPlan: number;
  planmode: PlanModeEnum;
  updatePlanMode: (planmode: PlanModeEnum, designator?: string) => void;
  productDefinition: Equipment | undefined;
  savePlan: () => void;
  exportPDF: () => void;
  exportPlanData: () => void;
  showCableDialogBox: (arg0: boolean) => void;
}

/**
 * Creats the toolbar icons for editing a plan
 */
const ProductToolbar = (props: productToolbarProps): JSX.Element => {
  const logger = useWinstonLogger();
  const [, setModified] = useState('');
  const [productCatagory, setProductCatagory, productCatagoryRef] = useStateRef(
    ProductCategory.ROOM
  );

  const productsFiltered = products.filter(
    (f) => f.designItem == true && f.category == productCatagoryRef.current
  );

  // Get a unique list of product catagories
  const productCatagories = _.uniq(
    products
      .filter((f) => f.designItem == true)
      .map((item) => {
        return item.category;
      })
  );

  // Only show drop down options for catagories used in the product list
  const productCatagoriesOptions = ProductCategorySelect.filter((e) =>
    productCatagories.includes(e.key)
  );

  let col_array: string[] = [];
  let area_array: {
    name: string;
    start: number[];
    end: number[];
  }[] = [];

  logger.debug(siteData.modified);

  const updateGridAreaArray = () => {
    col_array = [];
    let _col = 0;
    area_array = [];

    area_array.push({ name: 'Select', start: [0, 0], end: [(_col += 2), 0] });
    _col++;
    area_array.push(
      { name: 'Save', start: [_col, 0], end: [_col++, 0] },
      { name: 'PDF', start: [_col, 0], end: [_col++, 0] },
      { name: 'XL', start: [_col, 0], end: [_col++, 0] },
      { name: 'AUTOWIRE', start: [_col, 0], end: [_col++, 0] },
      { name: 'SCALE', start: [_col, 0], end: [_col++, 0] },
      { name: 'ARC', start: [_col, 0], end: [_col++, 0] },
      { name: 'DATA', start: [_col, 0], end: [_col++, 0] }
    );

    // area for the product catagory
    // Skip a coloumn so that there is a space before the product catagory drop down
    _col++;
    area_array.push({
      name: 'CAT_SELECT',
      start: [_col, 0],
      end: [(_col += 2), 0],
    });
    _col++;

    // setup the areas array
    for (let i = 0; i < productsFiltered.length; i++) {
      const area = {
        name: `Area${productsFiltered[i].designator}`,
        start: [_col, 0],
        end: [_col++, 0],
      };
      area_array.push(area);
    }

    for (let i = 0; i < _col; i++) {
      col_array.push('xxsmall');
    }
  };

  const siteUpdated = () => {
    setModified(Date.now().toString());
  };

  useEffect(() => {
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.FLOORPLANS,
      eventDispatcher.systemEventStates.UPDATED,
      siteUpdated
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.FLOORPLANS,
      eventDispatcher.systemEventStates.SAVED,
      siteUpdated
    );

    // UseEffect's cleanup function
    return () => {
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.FLOORPLANS,
        eventDispatcher.systemEventStates.UPDATED,
        siteUpdated
      );
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.FLOORPLANS,
        eventDispatcher.systemEventStates.SAVED,
        siteUpdated
      );
    };
  }, []);

  // Just update the grid area array everytime we render, keep it simple!
  updateGridAreaArray();

  return (
    <>
      {/* <p>{modified}</p> */}
      <Grid
        columns={col_array}
        rows={['xxsmall']}
        gap="none"
        margin="small"
        areas={area_array}
      >
        <Select
          gridArea="Select"
          alignSelf="center"
          labelKey="text"
          value={props.planmode}
          valueKey={{ key: 'key', reduce: true }}
          options={[
            { text: 'View', key: PlanModeEnum.VIEW },
            { text: 'Edit', key: PlanModeEnum.EDIT },
            { text: 'Design', key: PlanModeEnum.DESIGN },
          ]}
          size="small"
          onChange={({ value: nextValue }) => {
            console.info('user.mode', { selected: nextValue });
            props.updatePlanMode(nextValue, '');
          }}
        />

        <Button
          gridArea="Save"
          plain={false}
          // icon={<Send />}
          size="small"
          label="Save"
          color={
            siteData.modified && siteData.modified > 0 ? 'brand' : 'grey-4'
          }
          badge={
            siteData.modified && siteData.modified > 0
              ? siteData.modified
              : false
          }
          tip={{ content: <Text>Save plan (Press S)</Text> }}
          style={{ padding: 2, width: 46, height: 46, margin: 2, fontSize: 12 }}
          onClick={() => {
            logger.info('productToolbar.savePlan');
            props.savePlan();
          }}
        />
        <Button
          gridArea="PDF"
          plain={false}
          // icon={<Send />}
          size="small"
          label="PDF"
          color="grey-3"
          tip={{ content: <Text>Export Page to PDF</Text> }}
          style={{ padding: 2, width: 46, height: 46, margin: 2, fontSize: 12 }}
          disabled={props.currentPlan == -1}
          onClick={() => {
            logger.info('productToolbar.exportPDF');
            props.exportPDF();
          }}
        />
        {UserUtils.minimumAccessLevel(UsersUtils.UAG.ELITE) && (
          <Button
            gridArea="XL"
            plain={false}
            // icon={<Send />}
            size="small"
            label="XLSX"
            color="grey-3"
            tip={{ content: <Text>Export Plan to Excel</Text> }}
            style={{
              padding: 2,
              width: 46,
              height: 46,
              margin: 2,
              fontSize: 12,
            }}
            onClick={() => {
              logger.info('productToolbar.exportInstallSheet');
              createInstallStatusSheet();
              cablingXLSX();
            }}
          />
        )}
        {UserUtils.minimumAccessLevel(UsersUtils.UAG.ELITE) && (
          <Button
            gridArea="AUTOWIRE"
            plain={false}
            // icon={<Send />}
            size="small"
            label="Wire"
            color="grey-3"
            tip={{ content: <Text>Cable up POE units on this floor</Text> }}
            style={{
              padding: 2,
              width: 46,
              height: 46,
              margin: 2,
              fontSize: 12,
            }}
            disabled={props.currentPlan == -1}
            onClick={() => {
              logger.info('productToolbar.autowire');
              props.showCableDialogBox(true);
            }}
          />
        )}
        {UserUtils.minimumAccessLevel(UsersUtils.UAG.ELITE) && (
          <Button
            gridArea="SCALE"
            plain={false}
            // icon={<Send />}
            size="small"
            label="Scale"
            color="grey-3"
            tip={{
              content: (
                <Text>
                  Rescale the products on previous map to suit new resolution
                </Text>
              ),
            }}
            style={{
              padding: 2,
              width: 46,
              height: 46,
              margin: 2,
              fontSize: 12,
            }}
            disabled={props.currentPlan == -1}
            onClick={() => {
              rescale();
            }}
          />
        )}
        {UserUtils.minimumAccessLevel(UsersUtils.UAG.ELITE) && (
          <Button
            gridArea="ARC"
            plain={false}
            // icon={<Send />}
            size="small"
            label="ARC"
            color="grey-3"
            tip={{
              content: (
                <Text>
                  Generate a spreadsheet of the equipment IDs for the ARC (Alarm
                  receiving centre)
                </Text>
              ),
            }}
            style={{
              padding: 2,
              width: 46,
              height: 46,
              margin: 2,
              fontSize: 12,
            }}
            disabled={false}
            onClick={() => {
              generateARCUnitDetailsReport();
            }}
          />
        )}
        {UserUtils.minimumAccessLevel(UsersUtils.UAG.ELITE) && (
          <Button
            gridArea="DATA"
            plain={false}
            // icon={<Send />}
            size="small"
            label="Data"
            color="grey-3"
            tip={{
              content: <Text>Download the plandata JSON file</Text>,
            }}
            style={{
              padding: 2,
              width: 46,
              height: 46,
              margin: 2,
              fontSize: 12,
            }}
            disabled={false}
            onClick={() => {
              props.exportPlanData();
            }}
          />
        )}

        {props.planmode == PlanModeEnum.DESIGN && (
          <Select
            gridArea="CAT_SELECT"
            alignSelf="center"
            labelKey="text"
            value={[productCatagory]} // don't understand why I have to pass an array, if pass a number throws typescript error
            valueKey={{ key: 'key', reduce: true }}
            options={productCatagoriesOptions}
            size="small"
            onChange={({ value: nextValue }) => {
              setProductCatagory(nextValue);
            }}
          />
        )}
        {props.planmode == PlanModeEnum.DESIGN &&
          productsFiltered.map((item, index) => (
            <Button
              plain={false}
              key={index}
              color={
                item.designator == props.productDefinition?.designator
                  ? 'gold'
                  : 'grey-3'
              }
              gridArea={`Area${item.designator}`}
              active={props.planmode == PlanModeEnum.DESIGN}
              size="small"
              label={item.designator}
              style={{
                padding: 2,
                width: 46,
                height: 46,
                margin: 2,
                fontSize: 12,
              }}
              tip={{ content: <Text>{item.name}</Text> }}
              onClick={() => {
                logger.info('productToolbar.planmode.draw');
                if (item.designator == props.productDefinition?.designator) {
                  props.updatePlanMode(PlanModeEnum.DESIGN, '');
                } else {
                  props.updatePlanMode(PlanModeEnum.DESIGN, item.designator);
                }
              }}
            />
          ))}
      </Grid>
    </>
  );
};

export default ProductToolbar;
