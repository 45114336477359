import React, { useState, useEffect, useRef, useCallback } from 'react';
import useStateRef from 'react-usestateref';
import {
  Box,
  Button,
  TextInput,
  Text,
  Accordion,
  AccordionPanel,
} from 'grommet';
import * as Wait from 'components/dialogues/waitDialogue';
import * as IoTRemoteDial from 'common/IoT/IoTTRemoteDialing';
import { getCurrentUsersName } from 'common/userUtils';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { useInterval } from 'hooks/useInterval';
import { teLocalStorageItems } from 'common/localStorage';
import { Phone } from 'grommet-icons';
import logger from 'common/logger';
import SCUversion from 'components/wrappers/SCUversion';

export const nanoid = customAlphabet(alphanumeric, 24);

// Using a global so that when unmounting the component we still have a
// callid to send to the SCU to disconnect
let _CurrentCallId = '';

const ResidentCalling = (props: {
  resident: number;
  titleText?: string;
  startOpen?: boolean;
  setResident: (e: number) => void;
  setCallOpen: (e: boolean) => void;
}): JSX.Element => {
  const [statusMsg, setStatusMsg] = useState('');
  const [open, setOpen] = useState(props.startOpen ? [0] : [-1]);

  // const [expand, setExpand] = useState(false);
  // const myTel = useRef<HTMLInputElement>(null);
  const [myTel, setMyTel] = useState('');

  // const [callUID, SetCallUID] = useState('');
  const [currentResident, setCurrentResident, currentResidentRef] =
    useStateRef(-1);
  const [
    pendingCurrentResident,
    setPendingCurrentResident,
    pendingCurrentResidentRef,
  ] = useStateRef(-1);
  // _CurrentCallId = callUID;

  useInterval(() => {
    if (_CurrentCallId !== '') {
      IoTRemoteDial.sendHeartbeat(_CurrentCallId);
    }
  }, 20000);

  const setToDisconnected = () => {
    _CurrentCallId = '';
    props.setResident(-1);
    setPendingCurrentResident(-1);
    setCurrentResident(-1);
    props.setCallOpen(false);
  };

  async function remoteDial(telephoneNumber: string, username: string) {
    if (_CurrentCallId == '') {
      _CurrentCallId = username + '_' + nanoid();

      if (window && window.localStorage)
        window.localStorage.setItem(
          teLocalStorageItems.E_RESIDENT_CALLING_PHONE_NO,
          telephoneNumber
        );

      const errorCode: IoTRemoteDial.teCLOUD_ERROR_CODES =
        await IoTRemoteDial.requestCallBack(
          _CurrentCallId,
          username,
          telephoneNumber,
          IoTRemoteDial.TELEPHONE_LINES.TELEPHONE_LINE_SIP_1
        );

      logger.info('resident.dial.responseCode', { code: errorCode });
      if (errorCode == IoTRemoteDial.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE) {
        logger.info('resident.calling.dial.success');
        props.setResident(-1);
        props.setCallOpen(true);
      } else {
        logger.info('resident.calling.dial.fail');
        _CurrentCallId = '';
        setStatusMsg('Could not connect at the moment');
        setToDisconnected();
      }
    }
  }

  async function connectResident(
    unitId: number, // room number
    vrutext: string,
    username: string
  ) {
    logger.info('resident.connect.room', { room: props.resident });

    const errorCode: IoTRemoteDial.teCLOUD_ERROR_CODES =
      await IoTRemoteDial.connectResident(
        unitId,
        vrutext,
        username,
        _CurrentCallId,
        false
      );

    logger.info('resident.connect.responseCode', { code: errorCode });
  }

  async function disconnectResident(username: string) {
    const errorCode: IoTRemoteDial.teCLOUD_ERROR_CODES =
      await IoTRemoteDial.disconnectResident(username, _CurrentCallId);

    logger.info('resident.disconnect.responseCode', { code: errorCode });
  }

  async function disconnectRemoteCall(username: string, unmounting: boolean) {
    if (_CurrentCallId != '') {
      logger.info('resident.calling.disconnect', { callid: _CurrentCallId });

      await IoTRemoteDial.disconnectRemoteCall(username, _CurrentCallId);
      if (!unmounting) {
        // setToDisconnected();
      }
    }
  }

  const dialEvent = (
    msg: IoTRemoteDial.tsE_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT
  ) => {
    // logger.info('resident.calling.dialevent', {
    //   ...msg,
    //   eventCode:
    //     IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES[msg.eventcode],
    //   ourCall: _CurrentCallId,
    // });
    // only listen to our own calls
    if (msg.calluid == _CurrentCallId) {
      logger.info('resident.calling.dialevent', {
        ...msg,
        eventCode:
          IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES[msg.eventcode],
      });

      switch (msg.eventcode) {
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_DIALING:
          setStatusMsg('Connecting');
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_DIALING_TIMEOUT:
          setStatusMsg('Could not connect at the moment');
          setToDisconnected();
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_DIALING_FAILED_NO_RESOURCES:
          setStatusMsg('Could not connect at the moment');
          setToDisconnected();
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_CONNECTED_IDLE_TIMEOUT:
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_CLEARDOWN_HEARTBEAT_TIMEOUT:
          setStatusMsg('Call timeout');
          setToDisconnected();
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_REMOTE_CALL_CONNECTED:
          setStatusMsg('Connected - Please Select A Room');
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_LINE_HUNGUP:
          setStatusMsg('Call ended');
          setToDisconnected();
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_RESIDENT_CONNECTED:
          setStatusMsg(`Call open to Room ${currentResidentRef.current}`);
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_RESIDENT_CLEAR_STARTED:
          // setStatusMsg(`Clearing Call to Room`);
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_RESIDENT_CLEAR_COMPLETE:
          setStatusMsg(`Connected - Please Select A Room`);
          if (pendingCurrentResidentRef.current != -1) {
            setCurrentResident(pendingCurrentResidentRef.current);
            connectResident(
              pendingCurrentResidentRef.current,
              'Calling from Insights',
              getCurrentUsersName()
            );
            setPendingCurrentResident(-1);
          } else {
            setCurrentResident(-1);
          }
          break;
        case IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES
          .E_REMOTECALL_STATUS_EVT_RESIDENT_PRIVACY:
          setStatusMsg(`Room has privacy set`);
          props.setResident(-1);
          setCurrentResident(-1);
          break;
      }
    }
  };

  useEffect(() => {
    // changed resident
    console.log(
      'resident.calling',
      props.resident,
      currentResident,
      pendingCurrentResident
    );
    if (props.resident != currentResident) {
      // end any current call

      // We are not currently connected to a resident
      if (currentResident == -1) {
        setCurrentResident(props.resident);
        if (props.resident != -1) {
          connectResident(
            props.resident,
            'Calling from Insights',
            getCurrentUsersName()
          );
        }
      }
      // We are currently connected to a resident to pend the connect whilst awaiting a disconnect
      else {
        disconnectResident(getCurrentUsersName()).then(() => {
          setPendingCurrentResident(props.resident);
        });
        if (props.resident != -1) {
          setPendingCurrentResident(props.resident);
        }
      }
    }
  }, [props.resident]);

  useEffect(() => {
    const regIndex = IoTRemoteDial.registerForDialStatusEvents(dialEvent);

    if (window && window.localStorage) {
      const phoneno = window.localStorage.getItem(
        teLocalStorageItems.E_RESIDENT_CALLING_PHONE_NO
      );
      console.log(phoneno);
      if (phoneno) setMyTel(phoneno);
    }

    return () => {
      console.info('use effect clear up callId:', _CurrentCallId);
      if (_CurrentCallId.length > 1) {
        console.info('Disconnect the call callUID:', _CurrentCallId);
        disconnectRemoteCall(getCurrentUsersName(), true).then(() =>
          console.info('DropDone')
        );
      }

      IoTRemoteDial.unregisterDialStatusEventCallabck(regIndex);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SCUversion minVersion="5.10.0">
      <Accordion width="400px" activeIndex={open} onActive={(e) => setOpen(e)}>
        <AccordionPanel
          label={
            <Box margin="10px" direction="row">
              <Phone color={_CurrentCallId.length > 1 ? 'green' : 'blue'} />
              <Text
                style={{ marginLeft: '6px' }}
                size="mediumplus"
                weight="bold"
              >
                {props.titleText
                  ? props.titleText
                  : 'Resident Calling  ' +
                    (props.resident != -1 && _CurrentCallId != ''
                      ? `Room ${props.resident}`
                      : '')}
              </Text>
            </Box>
          }
        >
          <Box
            direction="row"
            margin="6px"
            border={{ position: 'bottom' }}
            height="50px"
            pad="10px"
            width="500px"
          >
            {/* <Button
        plain={false}
        size="small"
        label="Send Heartbeat"
        onClick={async () => {
          await IoTRemoteDial.sendHeartbeat(callUID);
        }}
      /> */}

            <Box direction="row" margin={{ right: '20px' }}>
              <Text truncate style={{ width: '200px', marginTop: '4px' }}>
                My Phone No.
              </Text>
              <TextInput
                size="small"
                width="100px"
                value={myTel}
                style={{ padding: '6px' }}
                disabled={_CurrentCallId.length > 1}
                onChange={(event) =>
                  setMyTel(event.target.value.replace(/ /g, ''))
                }
              />
              <Button
                margin={{ left: '10px' }}
                plain={false}
                size="small"
                label={_CurrentCallId.length > 1 ? 'Disconnect' : 'Connect'}
                disabled={myTel.length < 2}
                onClick={() => {
                  if (_CurrentCallId.length > 1) {
                    disconnectRemoteCall(getCurrentUsersName(), false);
                  } else remoteDial(myTel, getCurrentUsersName());
                }}
              />
            </Box>
          </Box>
          <Text>{statusMsg}</Text>
        </AccordionPanel>
      </Accordion>
    </SCUversion>
  );
};

export default ResidentCalling;
