import React, { useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../tile';
import { Button, Box, Form, Select } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import _ from 'lodash';
import * as Wait from 'components/dialogues/waitDialogue';
import * as Icons from 'grommet-icons';
import * as API from 'types/API';
import * as sitesslice from 'store/sitesSlice';
import * as scusslice from 'store/scusSlice';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as FormFields from 'components/forms/formFieldsOld';
import * as reduxStore from 'store/store';
import { getDifferences } from 'common/utils/miscUtils';

const blank: API.UpdateOmniviaSCUInput = {
  id: '',
  serialNumber: '',
  name: '',
  description: '',
  mainOutgoingPOTSNumber: '',
  backupOutgoingPOTSNumber: '',
  remoteAccessPort: 0,
  productNumber: 'PROD-3208',
  omniviaSCULinkedSiteId: '',
};

let scuOriginal: API.UpdateOmniviaSCUInput | undefined = undefined;

const SCUEdit = (): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [changes, setChanges] = useState<unknown>({});
  const allSites = useSelector(sitesslice.getAll);

  const [selectedSCU, setSelectedSCU, selectedSCURef] =
    useStateRef<API.UpdateOmniviaSCUInput>(blank);

  const [selectedSite, setSelectedSite, selectedSiteRef] = useStateRef<
    API.OmniviaSite | undefined
  >();

  const [siteOptions, setSiteOptions] = useState(allSites);

  const fields: FormFields.iField[] = [
    { label: 'ID', id: 'id', type: 'readonly' },
    { label: 'Serial Number', id: 'serialNumber', type: 'text' },
    { label: 'Name', id: 'name', type: 'text' },
    {
      label: 'Description',
      id: 'description',
      type: 'text',
      formBoxWidth: 'large',
    },
    {
      label: 'SCU Telephone Number',
      id: 'mainOutgoingPOTSNumber',
      type: 'text',
    },
    {
      label: 'Backup SCU Telephone Number',
      id: 'backupOutgoingPOTSNumber',
      type: 'text',
    },
    { label: 'Remote Access Port', id: 'remoteAccessPort', type: 'number' },
    { label: 'Product Number', id: 'productNumber', type: 'text' },
  ];

  async function loadData(site: API.OmniviaSite | undefined) {
    setChanges({});

    console.info('scuEdit site:', site);

    let scu = _.omitBy(blank, _.isNull) as API.UpdateOmniviaSCUInput;
    if (
      site &&
      site.linkedSCUs &&
      site.linkedSCUs.items &&
      site.linkedSCUs.items.length > 0 &&
      site.linkedSCUs.items[0]
    ) {
      const scuId = site.linkedSCUs.items[0].id;

      // The store will only have the SCU for the currently selected site.
      // Need to fetch the SCU to edit from the DB as it may be different from the current selected site
      await store.dispatch(scusslice.getSCUInfo({ id: scuId }));

      /*
       * FormFields generates errors if any fields are null.
       * Remove null fields fromn the object so they can be replaced with a blank string
       */
      scu = _.omitBy(
        scusslice.selectById(
          reduxStore.store.getState(),
          site.linkedSCUs.items[0].id
        ),
        _.isNull
      ) as API.UpdateOmniviaSCUInput;

      // _.omitBy(site.linkedSCUs.items[0], _.isNull) as API.UpdateOmniviaSCUInput;
    }

    // Remove properties from API.OmniviaSite that are not in API.omniviaSCULinkedSiteId so we
    // can use the object as input to the graphql mutation
    const scucopy = _.omit(scu, [
      '__typename',
      'omniviaSCULinkedSiteId',
    ]) as API.UpdateOmniviaSCUInput;

    scuOriginal = _.cloneDeep(scucopy);
    scucopy.omniviaSCULinkedSiteId = site ? site.id : '';
    const newRecord = _.cloneDeep(blank) as API.UpdateOmniviaSCUInput;

    setSelectedSCU(_.merge(newRecord, scucopy));
    setDialogueProps(undefined);

    setSelectedSite(site);

    console.info('scu:', scucopy);
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.SITE,
      state: eventDispatcher.systemEventStates.EDIT,
      callback: (event) => {
        loadData(event.detail as API.OmniviaSite);
      },
      executeOnStartup: false,
    },
    {
      topic: eventDispatcher.systemEventTopics.SCU,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        if (selectedSiteRef.current) {
          loadData(selectedSiteRef.current);
        }
      },
      executeOnStartup: false,
    },
  ];

  async function save() {
    if (scuOriginal === undefined) {
      return;
    }

    if (selectedSiteRef.current === undefined) {
      return;
    }

    if (scuOriginal.id == '') {
      return;
    }

    if (selectedSCURef.current.backupOutgoingPOTSNumber === '') {
      selectedSCURef.current.backupOutgoingPOTSNumber = 'Not yet advised';
    }

    if (selectedSCURef.current.mainOutgoingPOTSNumber === '') {
      selectedSCURef.current.mainOutgoingPOTSNumber = 'Not yet advised';
    }

    let diff = getDifferences(scuOriginal, selectedSCURef.current);
    diff = _.omit(diff, ['omniviaSCULinkedSiteId']);

    if (
      diff.omniviaSCULinkedSiteId &&
      diff.omniviaSCULinkedSiteId != selectedSiteRef.current.id
    ) {
      return;
    }

    //if (diff.omniviaSCULinkedSiteId == '')
    //  diff = _.omit(diff, ['omniviaSCULinkedSiteId']);

    // if a new record we will need to update this
    diff = _.omit(diff, ['omniviaSCULinkedSiteId']);

    console.info('Changes:', diff);

    await store.dispatch(
      scusslice.update({
        id: selectedSCU.id,
        changes: diff,
      })
    );
  }

  /*********************************************************
   * TODO: Add SCU
   *
   * Currently you need to go into AWS App Sync Web console and run the following mutation
   * obviously changing the data for that of the new SCU and linked site!
   *
   *   mutation MyMutation {
   *     createOmniviaSCU(input: {
   *                      serialNumber: "3200-8659-05-2022-0005",
   *                      productNumber: "PROD-3200",
   *                      mainOutgoingPOTSNumber: "none",
   *                      backupOutgoingPOTSNumber: "none",
   *                      omniviaSCULinkedSiteId: "5fe96f06-1fec-40ff-9ddf-710fe59121b4",
   *                      name: "Adlington Otley",
   *                      remoteAccessPort: 50049}) {
   *     {
   *        id
   *     }
   *   }
   */

  const ContextButtons = (
    <Box direction="row" gap="large">
      <Select
        alignSelf="end"
        placeholder="Select a site..."
        labelKey="name"
        valueKey={{ key: 'id', reduce: true }}
        options={siteOptions}
        value={selectedSite?.id ? selectedSite.id : 'Select a site...'}
        size="medium"
        onChange={({ value: nextValue }) => {
          const site = allSites.find((e) => e.id === nextValue);
          eventDispatcher.emitEvent(
            eventDispatcher.systemEventTopics.SITE,
            eventDispatcher.systemEventStates.EDIT,
            site,
            true
          );
        }}
        onClose={() => setSiteOptions(allSites)}
        onSearch={(text) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp(escapedText, 'i');
          setSiteOptions(siteOptions.filter((o) => exp.test(o.name)));
        }}
      />
      <Button
        plain={false}
        size="small"
        label="Save"
        color={!_.isEmpty(changes) ? 'brand' : 'grey-4'}
        badge={!_.isEmpty(changes) ? true : false}
        tip="Save changes"
        disabled={
          _.isEmpty(changes) ||
          scuOriginal === undefined ||
          scuOriginal.id == ''
        }
        style={contextButtonStyle}
        onClick={() => {
          save();
        }}
      />
    </Box>
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            if (
              selectedSiteRef !== undefined &&
              selectedSiteRef.current != undefined
            ) {
              const _selectedSite: API.OmniviaSite = selectedSiteRef.current;
              loadData(allSites.find((e) => e.id == _selectedSite.id));
            }
          },
          disabled: _.isEmpty(changes) ? true : false,
        },
        /*
        {
          label: 'Create New',
          icon: <Icons.Add size="medium" />,
          onClick: async () => {
            setDialogueProps({
              show: true,
              dialogueText: 'Please Wait...',
              showSpinner: true,
              showOkButton: false,
            });

            const newSCU = _.cloneDeep(blank);
            newSCU.name = 'NewSCU_' + Utils.formatDate(new Date());
            setSelectedSCU(newSCU);
            await store.dispatch(
              sitesslice.add({
                changes: _.omit(newSCU, ['id', 'omniviaSCULinkedSiteId']),
              })
            );

            //setDialogueProps will get cleared when we get a SITE#UPDATE event
          },
          disabled: false,
        },*/
      ],
    }),
    [selectedSCU, changes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setSiteOptions(allSites);
  }, [allSites]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title="Omnivia SCU"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        {scuOriginal !== undefined && scuOriginal.id !== '' && (
          <Form<API.UpdateOmniviaSCUInput>
            value={selectedSCU}
            onReset={() => setSelectedSCU(blank)}
            onChange={(nextValue) => {
              // console.log('Change', nextValue, touched);
              setSelectedSCU(nextValue);
              setChanges(
                getDifferences(
                  allSites.find((e) => e.id == selectedSCU.id),
                  selectedSiteRef.current
                )
              );
            }}
            onSubmit={(event) =>
              console.log('onSubmit', event.value, event.touched)
            }
          >
            <Box direction="column">
              <FormFields.AlignedFormFields displayfields={fields} />
            </Box>
          </Form>
        )}
      </>
    </Tile>
  );
};

export default SCUEdit;
