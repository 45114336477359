export enum teCLOUD_MSG_TY_TEST {
  E_CLOUD_MSG_TEST_RAISE_ALARM_REQ = 0,
  E_CLOUD_MSG_TEST_RAISE_ALARM_RES,
  E_CLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_REQ,
  E_CLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_RES,
  E_CLOUD_MSG_TEST_MAX,
}

export interface tsCLOUD_MSG_TEST_RAISE_ALARM_REQ {
  deviceid: number;
  alarmtype: number;
}

export interface tsCLOUD_MSG_TEST_RAISE_ALARM_RES {
  errorcode: number;
}

export interface tsCLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_REQ {
  residenttableid: number;
  enabled: boolean;
  duration: number; //seconds
}

export interface tsCLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_RES {
  errorcode: number;
}
