import { generateCallReportForCurrentSite } from 'common/reports';
import { iReportCard } from './types';
import * as UserUtils from 'common/userUtils';

export const callReports: iReportCard[] = [
  {
    title: '7 Day Calls Report',
    color: 'aqua',
    description:
      'Detailed report of all calls including alarms, social calls, door callers',
    accessLevel: UserUtils.UAG.BASIC,
    run: async (): Promise<void> => {
      await generateCallReportForCurrentSite(7);
    },
  },
  {
    title: '30 Day Calls Report',
    color: 'aqua',
    description:
      'Detailed report of all calls including alarms, social calls, door callers',
    accessLevel: UserUtils.UAG.BASIC,
    run: async (): Promise<void> => {
      await generateCallReportForCurrentSite(31);
    },
  },
  {
    title: '3 Month Calls Report',
    color: 'aqua',
    description:
      'Detailed report of all calls including alarms, social calls, door callers',
    accessLevel: UserUtils.UAG.BASIC,
    run: async (): Promise<void> => {
      await generateCallReportForCurrentSite(93);
    },
  },
];
