import React from 'react';
import { Footer as GrommetFooter, Text } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';

const Footer = () => {
  const menuHrefClick = (href: string) => {
    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.VIEW,
      eventDispatcher.systemEventStates.SELECTED,
      href,
      true
    );
  };

  return (
    <GrommetFooter background="light-4" justify="center" pad="none">
      <span>
        <Text
          textAlign="center"
          size="small"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            menuHrefClick('http://practicalcontrol.co.uk');
          }}
        >
          © 2020 - {new Date().getFullYear()}, All Rights Reserved {}
          Practical Control Ltd
        </Text>
        <Text
          margin={{ left: '50px' }}
          color="black"
          weight="bold"
          onClick={() => {
            menuHrefClick('/views/version');
          }}
          style={{ cursor: 'pointer' }}
          size="small"
        >
          Version: {process.env.REACT_APP_VERSION}
        </Text>
      </span>
    </GrommetFooter>
  );
};

export default Footer;
