import { iReportCard } from './types';
import * as UserUtils from 'common/userUtils';
import { generateTestReport } from 'components/reports/reportTypes/serviceTestReport';
import { generateCoverageReport } from 'components/reports/reportTypes/coverageReport';

export const serviceReports: iReportCard[] = [
  {
    title: 'Service Test Report',
    color: 'gold',
    description: 'Report of the last time each room was in test mode',
    accessLevel: UserUtils.UAG.TECHNICAL,
    run: async (): Promise<void> => {
      await generateTestReport();
    },
  },
  {
    title: '7 Day Coverage Report',
    color: 'gold',
    description: 'Report of alarm coverage in the last 7 days',
    accessLevel: UserUtils.UAG.TECHNICAL,
    run: async (): Promise<void> => {
      await generateCoverageReport(7);
    },
  },
  {
    title: '14 Day Coverage Report',
    color: 'gold',
    description: 'Report of alarm coverage in the last 14 days',
    accessLevel: UserUtils.UAG.TECHNICAL,
    run: async (): Promise<void> => {
      await generateCoverageReport(14);
    },
  },
];
