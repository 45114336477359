import React from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent } from '../tile';
import { Box } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import TablePaged from 'components/tables/TablePaged';
import { Column } from 'react-table';
import * as API from 'types/API';
import * as clientsslice from 'store/clientsSlice';
import { useSelector } from 'react-redux';

const Clients = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const allClients = useSelector(clientsslice.getAll);
  const [selectedClient, setSelectedClient] = useState<
    API.OmniviaClient | undefined
  >();

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.CLIENT,
      state: eventDispatcher.systemEventStates.LOADED,
      callback: () => {
        console.info('clients.tsx CLIENT LOADED');
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.CLIENT,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        console.info('clients.tsx CLIENT UPDATED');
      },
      executeOnStartup: false,
    },
  ];

  const columns = React.useMemo<Column<API.OmniviaClient>[]>(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
        disableExport: true,
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
      },
      {
        id: 'description',
        Header: 'Description',
        accessor: 'description',
      },
      {
        id: 'address',
        Header: 'Address',
        accessor: (row) => {
          let addr: string = row.addr1 ? row.addr1 + ',' : '';
          addr += row.addr2 ? row.addr2 + ',' : '';
          addr += row.addr3 ? row.addr3 + ',' : '';
          addr += row.city ? row.city + ',' : '';
          addr += row.postcode ? row.postcode : '';
          return addr;
        },
      },
      {
        id: 'contactTelephone',
        Header: 'contactTelephone',
        accessor: 'contactTelephone',
      },
      {
        id: 'contactEmail',
        Header: 'contactEmail',
        accessor: 'contactEmail',
      },
    ],
    []
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: ['id'],
      pageSize: 15,
      sortBy: [
        {
          id: 'name',
          desc: false,
        },
      ],
    }),
    []
  );

  const select = (
    rowData: API.OmniviaClient,
    selectionInfo: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ) => {
    if (!selectionInfo.alreadySelected) {
      setSelectedClient(rowData);
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.CLIENT,
        eventDispatcher.systemEventStates.EDIT,
        rowData,
        true
      );
    }
  };

  return (
    <Tile
      title="Omnivia Clients"
      eventListeners={events}
      setChildEvent={SetChildEvent}
    >
      <Box>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={allClients}
          showCheckboxes={false}
          reportDescription={{
            header: '',
            filename: 'Omnivia Clients',
          }}
          onLastSelectedRow={select}
          currentSelectedID={selectedClient?.id}
          selectorKeyName="id"
        />
      </Box>
    </Tile>
  );
};

export default Clients;
