import * as Wait from 'components/dialogues/waitDialogue';
import { Dispatch } from 'react';
import * as IoTOAD from 'common/IoT/IoTOAD';
import * as IoTMsgDefines_DEVICES from 'common/IoT/IoTMessageDefines_DEVICES';
import * as IoTMsgDefines from 'common/IoT/IoTMessageDefines';

export {
  teCLOUD_ERROR_CODES,
  getErrorText,
} from 'common/IoT/IoTMessageDefines';
export { teDEVICE_TYPES, teDEVICE_MODELS } from 'types/manifest-enums';
export type { tsCLOUD_MSG_DEVICES_ADD_RESP } from 'common/IoT/IoTMessageDefines_DEVICES';

export const activateDevice = async (
  deviceId: number = IoTOAD.OAD_ALL_DEVICES,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined> | undefined,
  clearDialogue: boolean
): Promise<boolean> => {
  const activationTime = 360;

  const promise = new Promise<boolean>((resolve) => {
    (async () => {
      if (setDialogueProps)
        setDialogueProps({
          show: true,
          dialogueText:
            deviceId === IoTOAD.OAD_ALL_DEVICES
              ? 'Please Wait...'
              : `Please wait\nActivating device ID ${deviceId}`,
          showSpinner: true,
          showOkButton: false,
        });

      let message = 'Failed to issue activate request';
      try {
        const response = await IoTOAD.oadActivateRequest(
          deviceId,
          activationTime,
          false
        );

        if (response && response.msgHeader?.msg) {
          console.info('tsCLOUD_MSG_DEVICES_OAD_ACTIVATE_RESP:', response);
          const errorcode = (
            response.msgHeader
              .msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_ACTIVATE_RESP
          ).errorcode;
          if (
            errorcode == IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
          ) {
            message = '';
          }
        }
      } catch (error) {
        console.error('failed to get data form the SCU');
        message =
          'Failed to issue activate request as the system controller is offline';
      }

      if (message != '') {
        if (setDialogueProps)
          setDialogueProps({
            show: true,
            dialogueText: message,
            showSpinner: false,
            showOkButton: true,
            okButtonPress() {
              setDialogueProps(undefined);
              resolve(false);
            },
          });
      } else {
        if (setDialogueProps && clearDialogue) setDialogueProps(undefined);
        resolve(true);
      }
    })();
  });

  return promise;
};

export const upgradeDevice = async (
  deviceId: number = IoTOAD.OAD_ALL_DEVICES,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined> | undefined,
  clearDialogue: boolean
): Promise<boolean> => {
  const pktRate = deviceId === IoTOAD.OAD_ALL_DEVICES ? 1000 : 300;
  const promise = new Promise<boolean>((resolve) => {
    (async () => {
      if (setDialogueProps)
        setDialogueProps({
          show: true,
          dialogueText: `Please wait...\nActioning download for device ID ${deviceId}`,
          showSpinner: true,
          showOkButton: false,
        });

      let message = 'Failed to issue download request';
      try {
        const response = await IoTOAD.oadDownloadRequest(
          true,
          deviceId,
          IoTOAD.OAD_ALL_TYPES,
          IoTOAD.OAD_ALL_MODELS,
          pktRate
        );

        if (response && response.msgHeader?.msg) {
          console.info('tsCLOUD_MSG_DEVICES_OAD_DOWNLOAD_RESP:', response);
          const errorcode = (
            response.msgHeader
              .msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_DOWNLOAD_RESP
          ).errorcode;
          if (
            errorcode == IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
          ) {
            message = '';
          } else if (
            errorcode == IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_QUEUED
          ) {
            //message =
            //  'The request has been queued. It will start once the current download completes';
            message = '';
          }
        }
      } catch (error) {
        console.error('failed to get data form the SCU');
        message =
          'Failed to issue download request as the system controller is offline';
      }

      if (message != '') {
        if (setDialogueProps)
          setDialogueProps({
            show: true,
            dialogueText: message,
            showSpinner: false,
            showOkButton: true,
            okButtonPress() {
              setDialogueProps(undefined);
              resolve(false);
            },
          });
      } else {
        if (setDialogueProps && clearDialogue) setDialogueProps(undefined);
        resolve(true);
      }
    })();
  });

  return promise;
};
