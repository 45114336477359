import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../../tile';
import { Button, Box, Tabs, Tab, Form, Text, Tip } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest, teErrorCode } from '../../../installation/manifest';
import { EndpointCCDTMF } from 'components/installation/endpoint_cc_dtmf';
import TablePaged from 'components/tables/TablePaged';
import { Column, Cell } from 'react-table';
import _ from 'lodash';
import * as Wait from 'components/dialogues/waitDialogue';
import SelectCell from 'components/tables/cellPresentationComponents/selectCell';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
import * as Icons from 'grommet-icons';
import { useWinstonLogger } from 'winston-react';
import { iEndpoint_CC_SIP, iEndpointCCDTMF } from 'types/manifest-types';
import { AlignedFormFields } from 'components/forms/formFields';
import {
  arcDigitalForm,
  loadArcDigital,
  iARCDigitalForm,
} from './arcDigitalFormFields';
import { nonAlarmEvents } from '../../../systemevents/events';
import { AUDIT_EVENTS } from 'types/event-enums';
import useStateRef from 'react-usestateref';
import { saveArcDigital } from './arcDigitalFormFields';
import { iAUDIT_EVENT_SIP_REGISTRATION_STATUS } from 'types/eventTypes';
import {
  formatDate,
  formatDateWithSeconds,
  dateHoursBetweenDates,
} from 'common/utils/dateUtils';
import { getReasonPhrase } from 'http-status-codes';

const manifest = new Manifest();
let _analogData: iEndpointCCDTMF[] = [];
let _digitalData: iEndpoint_CC_SIP[] = [];

let _auditEventsARC1: iAUDIT_EVENT_SIP_REGISTRATION_STATUS[] = [];
let _auditEventsARC2: iAUDIT_EVENT_SIP_REGISTRATION_STATUS[] = [];

const blankTableEntry: iEndpointCCDTMF = {
  ID: 0,
  Description: '',
  Number: '',
  Line: 1,
  InUse: 0,
  LoggedOn: 0,
};

const lineOptions = [
  { value: 1, text: 'Line 1' },
  { value: 2, text: 'Line 2' },
];

const ARCDetails = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');
  const [saving, setSaving] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueText, setDialogueText] = useState('');
  const [changes, setChanges, changesRef] = useState<number[]>([]);
  const [renderCount, setRenderCount] = useState(0);
  const [index, setIndex] = React.useState(0);
  const onActive = (nextIndex: number) => setIndex(nextIndex);
  const [arcD1, setARCD1, arcD1Ref] = useStateRef<iARCDigitalForm>();
  const [arcD2, setARCD2, arcD2Ref] = useStateRef<iARCDigitalForm>();
  // const [arc1Status, setARC1Status] = useStateRef('');
  // const [arc2Status, setARC2Status] = useStateRef('');

  function getManifestData() {
    // Ignore special entries, ID 8001 and 8002
    let manifestData = manifest.endpointCCDTMF.filter(
      (e) => e.ID <= 5
    ) as iEndpointCCDTMF[];

    // The telephone is appended with 'w' to fix a asterisk bug.
    // We remove this when presenting to the user
    manifestData = manifestData.map((e) => {
      return { ...e, Number: EndpointCCDTMF.getTelephoneNumber(e.Number) };
    });

    return manifestData;
  }

  const loadAuditEvents = () => {
    const e = nonAlarmEvents.filter(
      (f) => f.eventId == AUDIT_EVENTS.AUDIT_EVENT_SIP_REGISTRATION_STATUS
    );

    _auditEventsARC1 = [];
    _auditEventsARC2 = [];

    e.sort(function (a, b) {
      const dateA = new Date(a.raisedAt);
      const dateB = new Date(b.raisedAt);
      if (dateA === dateB) return 0;
      if (dateA < dateB) return 1;
      return -1;
    });

    console.log('SIP', e);

    if (arcD1Ref.current && arcD1Ref.current.RegistryURI != '') {
      const matches = e.filter(
        (f) =>
          f.eventJSON &&
          arcD1Ref.current &&
          f.eventJSON.includes(arcD1Ref.current.RegistryURI)
      );

      const res = matches.slice(0, 10);
      res.forEach((m) => {
        if (m.eventJSON) {
          const json: iAUDIT_EVENT_SIP_REGISTRATION_STATUS = JSON.parse(
            m.eventJSON
          );
          _auditEventsARC1.push(json);
        }
      });
    }

    if (arcD2Ref.current && arcD2Ref.current.RegistryURI != '') {
      const matches = e.filter(
        (f) =>
          f.eventJSON &&
          arcD2Ref.current &&
          f.eventJSON.includes(arcD2Ref.current.RegistryURI)
      );

      const res = matches.slice(0, 10);
      res.forEach((m) => {
        if (m.eventJSON) {
          const json: iAUDIT_EVENT_SIP_REGISTRATION_STATUS = JSON.parse(
            m.eventJSON
          );
          _auditEventsARC2.push(json);
        }
      });
    }

    console.log('SIP EVENTS', _auditEventsARC1, _auditEventsARC2);
    setRenderCount((old) => old + 1);
  };

  function loadData() {
    _analogData = _.cloneDeep(getManifestData());
    _digitalData = _.cloneDeep(manifest.endPointCCSIP);

    if (_digitalData) {
      if (_digitalData[0]) setARCD1(loadArcDigital(_digitalData[0]));
      if (_digitalData[1]) setARCD2(loadArcDigital(_digitalData[1]));
    }

    setChanges([]);
    setRenderCount((old) => old + 1);
  }

  const setProperty = (id: number, property: string, value: unknown) => {
    const row = _analogData.find((e) => e.ID == id);
    if (row) {
      _.set(row, property, value);

      const manifestRecord = getManifestData().find((e) => e.ID == id);
      if (manifestRecord) {
        if (_.get(manifestRecord, property, 0) != value) {
          if (!changesRef.current.includes(id)) {
            changesRef.current.push(id);
            setRenderCount((old) => old + 1);
          }
        } else {
          _.pull(changesRef.current, id);
          setRenderCount((old) => old + 1);
        }
      }
    }
  };

  function getProperty(id: number, property: string): unknown {
    let row = _analogData.find((e) => e.ID == id);
    if (row === undefined) {
      row = blankTableEntry;
    }
    let value = _.get(row, property, 1);

    if (property === 'Line' && (value === null || value === 0)) {
      value = 1;
    }

    return value;
  }

  const arcStatusColour = (
    index: number,
    status: number,
    raisedTime: string
  ): string => {
    let ret = 'red';
    if (status == 1) {
      ret = index == 0 ? 'amber' : 'green'; // only allow the most recent bar to be amber
      // amber if the most recent one is over 2 hours old
      if (dateHoursBetweenDates(raisedTime, new Date()) < 2) ret = 'green';
    }
    return ret;
  };

  const getArcStatusResponseCode = (addInfo: string): string => {
    let res = '';
    if (addInfo) {
      const json = JSON.parse(addInfo);
      if (json) {
        res = json.status + ' - ' + getReasonPhrase(json.status);
      }
    }
    return res;
  };

  const GetARCStatus = (props: {
    e: iAUDIT_EVENT_SIP_REGISTRATION_STATUS[];
    arcNum: number;
    renderCount: number; // used to force a re-render
  }) => {
    //console.log('SIP PROPS', props.e);
    return (
      <Box
        margin={{ left: '18px', top: '8px' }}
        align="start"
        direction="row"
        key={`Status${props.renderCount}`}
      >
        <Text weight="bold">Last Registration/Heartbeat:</Text>
        {props.e && props.e.length < 1 && (
          <Box direction="row">
            <Text weight="bold" margin={{ left: '30px' }}>
              {'Status: NO CONNECTION'}
            </Text>
            <Box
              background="red"
              width="16px"
              height="14px"
              margin={{ top: '2px', left: '6px' }}
            />
          </Box>
        )}
        {props.e && props.e[0] && (
          <Box direction="row">
            <Text weight="bold" margin={{ left: '10px' }}>
              {formatDate(props.e[0].RaisedTime)}
            </Text>
            <Text weight="bold" margin={{ left: '30px' }}>
              Status:
            </Text>

            {props.e &&
              props.e.map((m, index) => (
                <Tip
                  key={`status${props.arcNum}${m.RaisedTime}${index}`}
                  content={
                    <Box>
                      <Text>{formatDateWithSeconds(m.RaisedTime)}</Text>
                      <Text>
                        Response: {getArcStatusResponseCode(m.AddtionalInfo)}
                      </Text>
                    </Box>
                  }
                >
                  <Box
                    background={arcStatusColour(
                      index,
                      m.StatusCode,
                      m.RaisedTime
                    )}
                    width="16px"
                    height="14px"
                    margin={{ top: '2px', left: '6px' }}
                  />
                </Tip>
              ))}
          </Box>
        )}
      </Box>
    );
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: false,
    },
    {
      topic: eventDispatcher.systemEventTopics.SITEEVENTS,
      state: eventDispatcher.systemEventStates.LOADED,
      callback: () => {
        loadAuditEvents();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.SITEEVENTS,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadAuditEvents();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.SITE,
      state: eventDispatcher.systemEventStates.SELECTED,
      callback: () => {
        _auditEventsARC1 = [];
        _auditEventsARC2 = [];
      },
      executeOnStartup: false,
    },
  ];

  function save(): void {
    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _analogData.find((e) => e.ID === id);
      const manifestRecord = manifest.endpointCCDTMF.find((e) => e.ID === id);

      if (!stateRecord || !manifestRecord) continue;

      _.merge(manifestRecord, stateRecord);
      manifestRecord.setTelephoneNumber(stateRecord.Number);

      if (manifestRecord.Line === 0 || manifestRecord.Line === null) {
        manifestRecord.Line = 1;
      }

      console.info('arcTelephoneNumbers.save manifestRecord:', manifestRecord);
    }

    logger.info('arcTelephoneNumbers.save', { changedIDs: changesRef.current });

    if (manifest.endPointCCSIP && manifest.endPointCCSIP[0])
      saveArcDigital(arcD1, manifest.endPointCCSIP[0]);
    if (manifest.endPointCCSIP && manifest.endPointCCSIP[1])
      saveArcDigital(arcD2, manifest.endPointCCSIP[1]);

    setDialogueText('Please wait.....');
    setSaving(true);
    setShowDialogue(true);

    const complete = (str: string) => {
      setDialogueText(str);
      setSaving(false);
    };

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
        }

        complete(str);
      })
      .catch((error) => {
        console.error('arc # save fail:', error);
        complete(str);
      });
  }

  const columns = React.useMemo<Column<iEndpointCCDTMF>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        disableExport: true,
        disableFilters: true,
      },
      {
        Header: 'Description',
        accessor: 'Description',
        disableFilters: true,
        Cell: (_analogData: Cell<iEndpointCCDTMF>) => (
          <InputCell
            id={_analogData.row.original.ID}
            reRenderValue={_analogData.row.original.Description}
            valueProperty={`Description`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="text"
          />
        ),
      },
      {
        Header: 'Telephone Number',
        //accessor: (row) => (row.Number ? row.Number : ''),
        accessor: 'Number',
        disableFilters: true,
        Cell: (_analogData: Cell<iEndpointCCDTMF>) => (
          <InputCell
            id={_analogData.row.original.ID}
            reRenderValue={_analogData.row.original.Number}
            valueProperty={`Number`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="text"
            toolTip={'Add w to the number string to insert a delay.'}
          />
        ),
      },
      {
        Header: 'Line',
        accessor: 'Line',
        disableFilters: true,
        width: 60,
        Cell: (_analogData: Cell<iEndpointCCDTMF>) => (
          <SelectCell
            id={_analogData.row.values.ID}
            reRenderValue={_analogData.row.original.Line}
            valueProperty={`Line`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={lineOptions}
          />
        ),
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: ['ID'],
      pageSize: 5,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const waitProps: Wait.DialogueProps = {
    show: showDialogue,
    dialogueText: dialogueText,
    showSpinner: saving,
    showOkButton: !saving,
    okButtonPress: () => setShowDialogue(false),
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? changes.length : false}
      tip="Save changes"
      disabled={changes.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => loadData(),
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const SCU5180 = manifest.isInSoftwareVersionRange({ min: '5.18.0' });

  useEffect(() => {
    loadAuditEvents();
  }, []);

  return (
    <Tile
      title="ARC Configuration"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={waitProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <Tabs activeIndex={index} onActive={onActive} alignControls="start">
        <Tab title="Digital Monitoring Centre 1" disabled={!SCU5180}>
          {arcD1 && (
            <>
              {/* <Box
                margin={{ left: '18px', top: '8px' }}
                align="start"
                direction="row"
              >
                <Text weight="bold">Last Registration/Heartbeat:</Text>
                <Text weight="bold" margin={{ left: '10px' }}>
                  {arc1Status}
                </Text>
              </Box> */}
              <GetARCStatus
                e={_auditEventsARC1}
                arcNum={1}
                renderCount={renderCount}
              />
              <Form
                value={arcD1}
                onChange={(nextValue) => {
                  if (nextValue) setARCD1(nextValue);
                  if (!changesRef.current.includes(1000))
                    changesRef.current.push(1000);
                }}
                // onReset={() => setValue({})}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={async () => {}}
              >
                <Box margin="10px" width="100%">
                  <AlignedFormFields
                    displayfields={arcDigitalForm(arcD1, manifest)}
                    columnLayouts={[
                      { columnPercentWidth: 65, labelWidth: '180px' },
                      { columnPercentWidth: 35, labelWidth: '140px' },
                    ]}
                  />
                </Box>
              </Form>
            </>
          )}
        </Tab>
        <Tab title="Digital Monitoring Centre 2" disabled={!SCU5180}>
          {arcD2 && (
            <>
              {/* <Box
                margin={{ left: '18px', top: '8px' }}
                align="start"
                direction="row"
              >
                <Text weight="bold">Last Registration/Heartbeat:</Text>
                <Text weight="bold" margin={{ left: '10px' }}>
                  {arc2Status}
                </Text>
              </Box> */}
              <GetARCStatus
                e={_auditEventsARC2}
                arcNum={2}
                renderCount={renderCount}
              />
              <Form
                value={arcD2}
                onChange={(nextValue) => {
                  if (nextValue) setARCD2(nextValue);
                  if (!changesRef.current.includes(2000))
                    changesRef.current.push(2000);
                }}
                // onReset={() => setValue({})}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={async () => {}}
              >
                <Box margin="10px" width="100%">
                  <AlignedFormFields
                    displayfields={arcDigitalForm(arcD2, manifest)}
                    columnLayouts={[
                      { columnPercentWidth: 65, labelWidth: '180px' },
                      { columnPercentWidth: 35, labelWidth: '140px' },
                    ]}
                  />
                </Box>
              </Form>
            </>
          )}
        </Tab>
        <Tab title="Analog Monitoring Centres">
          <Box>
            <TablePaged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={columns}
              initialState={initialState}
              data={_analogData}
              showCheckboxes={false}
              showControls={false}
              reportDescription={{
                header: '',
                filename: 'ControlCentreTelephoneNUmbers',
              }}
            />
          </Box>
        </Tab>
      </Tabs>
    </Tile>
  );
};

export default ARCDetails;
