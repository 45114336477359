import TablePaged from 'components/tables/TablePaged';

import React, { useState, useEffect } from 'react';

import Tile, { iTileEvent } from '../tile';
import { formatDateWithSeconds } from 'common/utils/dateUtils';
import { Manifest } from '../../installation/manifest';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import { Column } from 'react-table';
import * as Wait from 'components/dialogues/waitDialogue';
import * as liveEvents from 'components/systemevents/liveEvents';
import { Device } from 'components/installation/device';
import { Tip } from 'grommet';
import GetMACAddressDialogue from 'components/dialogues/getMACAddress';

let m_liveEventsDaysToLoad = 120;
const m = new Manifest();
let devicedescription = '';

const DeviceLocationHistory = (): JSX.Element => {
  const [allData, setAllData] = useState<
    liveEvents.iLocationHistory[] | undefined
  >();
  const [, SetChildEvent] = useState('');
  const [tableFilters, setTableFilters] = useState<
    { id: string; value: string }[] | undefined
  >();

  const [showGetMacDialogue, setShowGetMacDialogue] = useState(false);
  const [macAddress, setMacAddress] = useState('');

  const [dialogueProps /*setDialogueProps*/] = useState<
    Wait.DialogueProps | undefined
  >();

  const columns = React.useMemo<Column<liveEvents.iLocationHistory>[]>(
    () => [
      {
        Header: 'Time',
        accessor: (row) =>
          row.createdAt ? formatDateWithSeconds(row.createdAt) : '',
        width: 60,
      },
      {
        Header: 'Parent',
        accessor: (row) => {
          if (row.parentMac !== undefined && row.parentMac != null) {
            //const mac = Device.MACaddrStringToNumber(row.parentMac);
            //console.info('MAC:', mac);

            const mac = parseInt(row.parentMac);

            let parentName = m.getDeviceNameByMac(mac);
            const macString = Device.MACaddrToString(mac, 16);

            parentName += ` (${macString.substring(
              macString.length - 6,
              macString.length
            )})`;
            return parentName;
          }
          return '';
        },
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => {
          const mac = parseInt(props.row.original.parentMac);
          const parentName = m.getDeviceNameByMac(mac);
          const device = m.getDeviceByMac(mac);
          const macString = Device.MACaddrToString(mac, 16);
          const unitID = m.getUnitNumberByMac(mac);
          return (
            <>
              <Tip
                dropProps={{ margin: 'large' }}
                content={
                  <>
                    <li>Unit Id: {unitID} </li>
                    <li>MAC: {'0x' + macString} </li>
                    <li>Device ID: {device.ID}</li>
                  </>
                }
              >
                {parentName}
              </Tip>
            </>
          );
        },

        width: 50,
      },
      {
        Header: 'RSSI',
        accessor: (row) => (row.rssi_db ? row.rssi_db : ''),

        width: 50,
      },
      {
        Header: 'Battery (mV)',
        accessor: (row) =>
          row.batteryVoltageApplication ? row.batteryVoltageApplication : '',
        width: 50,
      },

      //
    ],
    [allData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      // hiddenColumns: ['ID'],
      pageSize: 25,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.WIRELESS_DEVICE_LOCATION,
      state: systemEventStates.SELECTED,
      callback: async (e) => {
        const mac = e.detail as number | undefined;
        console.info('**************mac:', mac);
        if (mac == undefined || mac == 0) {
          m_liveEventsDaysToLoad = 365;
          setTableFilters([]);
          setAllData([]);
          setShowGetMacDialogue(true);
        } else {
          const macString = Device.MACaddrToString(mac, 16);
          m_liveEventsDaysToLoad = 120;
          setMacAddress(macString);
        }
      },
      executeOnStartup: false,
    },
  ];

  useEffect(() => {
    if (macAddress == '') return;

    const macString = macAddress;
    const mac = parseInt(macAddress, 16);
    devicedescription = `Device Identity ..${macString.substring(
      macString.length - 6,
      macString.length
    )}`;
    console.info('Device MAC = ', mac);
    liveEvents
      .fetchLocationHistory(mac, m_liveEventsDaysToLoad)
      .then((resp) => {
        console.info('Got data:', resp);

        const entries = resp.filter((e) => {
          const mac = parseInt(e.parentMac);
          return m.getDeviceNameByMac(mac) !== '';
        });

        setAllData(entries);
      });
  }, [macAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title={`Location History for ${devicedescription}`}
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      showLastEvent={true}
    >
      <>
        <GetMACAddressDialogue
          mac={'00124b001bxxxxxx'}
          show={showGetMacDialogue}
          setShow={setShowGetMacDialogue}
          setMac={setMacAddress}
        />

        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={allData ? allData : []}
          showCheckboxes={false}
          reportDescription={{
            header: '',
            filename: 'LocationHistory',
          }}
          filters={tableFilters}
        />
      </>
    </Tile>
  );
};

export default DeviceLocationHistory;
