import TablePaged from 'components/tables/TablePaged';
import React from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../tile';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest, teErrorCode } from 'components/installation/manifest';
import _ from 'lodash';
import * as UserUtils from 'common/userUtils';
import { Button, Box, Tip, Text } from 'grommet';
import * as Wait from 'components/dialogues/waitDialogue';
import { iSystemParameter } from 'types/manifest-types';
import { Column } from 'react-table';
import * as Icons from 'grommet-icons';
import IDCell from 'components/tables/cellPresentationComponents/idCell';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
import { useWinstonLogger } from 'winston-react';
import * as SPDefs from 'data/systemParameterDefinitions';
import { SystemParameter } from 'components/installation/systemParameter';

const SystemParameterEditor = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');
  const manifest = new Manifest();

  const [saving, setSaving] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueText, setDialogueText] = useState('');
  const [systemParameters, setSystemParameters, systemParametersRef] = useState<
    iSystemParameter[]
  >([]);

  const [, setParameterChanges, parameterChangesRef] = useState<number[]>([]);

  const getParametersForAccessLevel = (): SystemParameter[] => {
    // ensure we take a deep copy so that we are not changing the manifest data
    let params = _.cloneDeep(manifest.systemParameters);
    const userAccessLevel = UserUtils.getCurrentUserAccessLevel();

    console.info(
      'getParametersForAccessLevel. userAccessLevel:',
      userAccessLevel
    );

    params = params.filter((p) => {
      const spDef = SPDefs.systemParameterDefinitions.find(
        (e) => e.ID === p.ID
      );
      if (spDef) {
        return UserUtils.minimumAccessLevel(spDef.AccessLevel);
      } else {
        console.error(
          `Couldn't find system parameter ${p.ID} in the systemParameterDefinitions`
        );
      }
      return false;
    });

    return params;
  };

  const loadData = () => {
    setParameterChanges([]);
    setSystemParameters(getParametersForAccessLevel());
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        // Tile parent will force a re-render so we don't need to do anything
        if (parameterChangesRef.current.length == 0) {
          loadData();
        }
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        // Tile parent will force a re-render so we don't need to do anything
        if (parameterChangesRef.current.length == 0) {
          loadData();
        }
      },
      executeOnStartup: false,
    },
  ];

  /**
   * Update our state with the change.
   * Warning: Do not access the systemParameters directly in this function as
   * it will not be re-rendered and as such always be emtpy.   It is NOT re-rendered
   * as the columns are memorized (useMemo) with no dependancy.
   *
   * We use the 'old' feature of useState to gain access to the data.
   *
   * We need to keep the table from resetting the pageIndex when we
   * Update data. So we can keep track of that flag with a ref.
   *
   * Note: We always use ids to find the array enteries as the systemParameters
   * array can be a subset of the manifest.systemParameters array depending on the what
   * paramateres we are showing the user.
   *
   * @param id
   * @param value
   */
  const updateParameter = (id: number, property: string, _value: unknown) => {
    const value = _value as string;

    console.info(`updateParameter ${id} ${property} = ${value}`);
    const manifestRecord = manifest.systemParameters.find((e) => e.ID === id);
    if (!manifestRecord) return;

    setSystemParameters((old) =>
      old.map((row, index) => {
        if (row.ID === id) {
          if (value === manifestRecord.Value) {
            _.pull(parameterChangesRef.current, id);
          } else if (!parameterChangesRef.current.includes(id)) {
            parameterChangesRef.current.push(id);
          }
          return {
            ...old[index],
            ['Value']: value,
          };
        }
        return row;
      })
    );
  };

  const validateValue = (id: number, value: unknown) => {
    const spDef = SPDefs.systemParameterDefinitions.find((e) => e.ID === id);

    if (spDef === undefined) return undefined;

    const manifestRecord = manifest.systemParameters.find((e) => e.ID === id);
    if (!manifestRecord) return undefined;

    if (SPDefs.dataTypeIsANumber(spDef.DataType)) {
      if (spDef.minValue !== undefined) {
        if ((value as number) < spDef.minValue) return manifestRecord.Value;
      }

      if (spDef.maxValue !== undefined) {
        if ((value as number) > spDef.maxValue) return manifestRecord.Value;
      }
    } else if (spDef.DataType === SPDefs.eDataTypes.DATA_TYPE_TEXT) {
      if (spDef.minValue !== undefined) {
        if ((value as string).length < spDef.minValue)
          return manifestRecord.Value;
      }

      if (spDef.maxValue !== undefined) {
        if ((value as string).length > spDef.maxValue)
          return manifestRecord.Value;
      }
    }

    return undefined;
  };

  const getParameter = (id: number, property: string) => {
    const stateRecord = systemParametersRef.current.find((e) => e.ID === id);
    if (!stateRecord) return '';

    return _.get(stateRecord, property);
  };

  const resetParameter = (id: number) => {
    const manifestRecord = manifest.systemParameters.find((e) => e.ID === id);
    if (!manifestRecord) return;
    updateParameter(id, 'Value', manifestRecord.Value);
  };

  const setParameterDefault = (id: number) => {
    const manifestRecord = manifest.systemParameters.find((e) => e.ID === id);
    if (!manifestRecord) return;
    updateParameter(id, 'Value', manifestRecord.DefaultValue);
  };

  const save = () => {
    logger.info('systemParameterEditor.save', {
      changedIDs: parameterChangesRef.current,
    });

    for (const index in parameterChangesRef.current) {
      const id = parameterChangesRef.current[index];
      const stateRecord = systemParameters.find((e) => e.ID === id);
      const manifestRecord = manifest.systemParameters.find((e) => e.ID === id);

      if (!stateRecord || !manifestRecord) continue;
      setDialogueText('Please wait.....');
      setSaving(true);
      setShowDialogue(true);
      manifestRecord.setFromSring(stateRecord.Value);
    }

    const complete = (str: string) => {
      setDialogueText(str);
      setSaving(false);
    };

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
          setParameterChanges([]);
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('systemParameterEditor.saveFailed', {
            status: status,
            error: {},
          });
        }
        complete(str);
      })
      .catch((error) => {
        console.error('sys param save fail:', error);
        logger.info('systemParameterEditor.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        complete(str);
      });
  };

  const getParameterToolTip = (id: number) => {
    const spDef = SPDefs.systemParameterDefinitions.find((e) => e.ID === id);
    if (spDef === undefined) return;

    let fieldName = '';
    if (spDef.DataType === SPDefs.eDataTypes.DATA_TYPE_TEXT) {
      fieldName = ' length';
    }

    return (
      <div>
        <p>{spDef.Description}</p>
        <ul>
          <li>Default: {spDef.DefaultValue}</li>
          {spDef.minValue !== undefined && (
            <li>
              {`Minimum${fieldName}: `}
              {spDef.minValue}
            </li>
          )}
          {spDef.maxValue !== undefined && (
            <li>
              {`Maximum${fieldName}: `}
              {spDef.maxValue}
            </li>
          )}
        </ul>
      </div>
    );
  };

  const columns = React.useMemo<Column<iSystemParameter>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        maxWidth: 50,
        minWidth: 50,
        width: 20,
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <IDCell
            id={props.row.values.ID}
            changesRef={parameterChangesRef}
            reRenderValue={parameterChangesRef.current.includes(
              props.row.values.ID
            )}
          />
        ),
      },
      {
        Header: 'Name',
        accessor: (row) => {
          const spDef = SPDefs.systemParameterDefinitions.find(
            (e) => e.ID === row.ID
          );

          if (spDef) return spDef.ShortName;
          return row.ShortName;
        },

        maxWidth: 70,
        minWidth: 70,
        width: 70,
      },
      {
        Header: 'Value',
        accessor: 'Value',
        maxWidth: 70,
        minWidth: 70,
        width: 70,

        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <Box pad="none" direction="row" justify="center" align="center">
            <InputCell
              id={props.row.values.ID}
              valueProperty={'Value'}
              getProperty={getParameter}
              setProperty={updateParameter}
              inputType="text"
              reRenderValue={props.row.values.value}
              toolTip={getParameterToolTip(props.row.values.ID)}
              validator={validateValue}
            />

            <Tip content={<Text>Set to default</Text>}>
              <Icons.Revert
                size="15px"
                onClick={() => {
                  if (props && props.row) {
                    setParameterDefault(props.row.values.ID);
                  }
                }}
              />
            </Tip>
            <Tip content={<Text>Discard Change</Text>}>
              <Icons.Clear
                size="15px"
                onClick={() => {
                  if (props && props.row) {
                    resetParameter(props.row.values.ID);
                  }
                }}
              />
            </Tip>
          </Box>
        ),
      },

      {
        Header: 'Description',
        accessor: (row) => {
          const spDef = SPDefs.systemParameterDefinitions.find(
            (e) => e.ID === row.ID
          );

          if (spDef) return spDef.Description;
          return row.Description;
        },
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: [],
      pageSize: 20,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
      filters: [],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const waitProps: Wait.DialogueProps = {
    show: showDialogue,
    dialogueText: dialogueText,
    showSpinner: saving,
    showOkButton: !saving,
    okButtonPress: () => {
      setShowDialogue(false);
    },
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={parameterChangesRef.current.length > 0 ? 'brand' : 'grey-4'}
      badge={
        parameterChangesRef.current.length > 0
          ? parameterChangesRef.current.length
          : false
      }
      tip="Save changes"
      disabled={parameterChangesRef.current.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Discard Changes',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('systemParameterEditor.discardChanges');
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="System Parameters"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={waitProps}
      ContextButtons={ContextButtons}
      menuProps={menuItems}
    >
      <>
        {manifest.systemParameters.length > 0 && (
          <div>
            <TablePaged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={columns}
              initialState={initialState}
              data={systemParameters}
              showCheckboxes={false}
              reportDescription={{
                header: '',
                filename: 'SystemParameters',
              }}
            />
          </div>
        )}
      </>
    </Tile>
  );
};

export default SystemParameterEditor;
