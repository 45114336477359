import React, { useState, useEffect } from 'react';

import {
  AppsRounded,
  Group,
  Nodes,
  PhoneVertical,
  Search,
  Split,
} from 'grommet-icons';
import { MenuItem, SubMenu } from 'components/sidebar/ProSidebar';
import { Manifest } from 'components/installation/manifest';
import { DNDtypes } from '../../types/dndtypes';
import { WirelessDEVICE_TYPES, Trigger } from 'components/installation/trigger';
import { getDeviceDescription } from 'components/installation/device';
import { Room } from 'components/installation/room';
import styled from 'styled-components';
import * as eventDispatcher from 'store/eventDispatcher';
import { TextInput, Text } from 'grommet';
import { matchSorter } from 'match-sorter';
import * as Site from 'components/floorplan/site';
import * as ManifestEnums from 'types/manifest-enums';
import { iResidentGroup } from 'types/manifest-types';

const Badge = styled.p`
  padding: 0px 8px;
  font-size: 9px;
  height: 24px;
  letter-spacing: 1px;
  border-radius: 15px;
  color: #ffffff;
  background: #777777;
`;

const YellowBadge = styled(Badge)`
  color: #000000;
  background: #ffd331;
`;

/*
const RedBadge = styled(Badge)`
  color: #ffffff;
  background: #d63030;
`;
*/

const InstallSidebarItems = (): JSX.Element => {
  const manifest = new Manifest();
  const [filter, setFilter] = React.useState('');
  const [rGroups, setRGroups] = useState<iResidentGroup[] | undefined>([]);

  const [, SetNewLoad] = useState('');
  // const [rooms, setRooms] = useState<Room[]>();
  // const [APs, setAPs] = useState<AccessPoint[]>();
  // const [doors, setDoors] = useState<iDoorsAndOtherInteraces[]>();
  // const [handsets, setHandsets] = useState<Handset[]>();
  // const [triggers, setTriggers] = useState<Trigger[]>();
  const clickhandler = () => {
    console.info('Sidebar click');
  };

  const loadDataEvent = (e: eventDispatcher.iEventParams) => {
    // setRooms(manifest.rooms);
    // setHandsets(manifest.handsets);
    // setAPs(manifest.accessPoints);
    // setTriggers(manifest.triggers);
    SetNewLoad(e.timestamp);

    if (
      e.subject ===
        eventDispatcher.subjectString(
          eventDispatcher.systemEventTopics.MANIFEST,
          eventDispatcher.systemEventStates.PROCESSED
        ) ||
      e.subject ===
        eventDispatcher.subjectString(
          eventDispatcher.systemEventTopics.RESIDENTGROUP,
          eventDispatcher.systemEventStates.UPDATED
        )
    ) {
      manifest.getResidentGroups().then((r) => setRGroups(r));
    }
  };

  // Handle dashboard screen changes for clicking on a wirelesss device
  const emitWirelessDashboardSelect = (trigger?: Trigger) => {
    console.info('Clicked on:', trigger);

    let identity = undefined;
    if (trigger) {
      identity = trigger.getIdentity();
    }

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.DASHBOARD,
      eventDispatcher.systemEventStates.SELECTED,
      {
        name: 'Pendants & Peripherals Dashboard',
        macAddress: identity,
      },
      true
    );

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.WIRELESS_DEVICE,
      eventDispatcher.systemEventStates.SELECTED,
      identity,
      true
    );
  };

  // Handle dashboard screen changes for clicking on a room unit
  const emitRoomsDashboardSelect = (room?: Room) => {
    console.info('Clicked on:' + room);

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.DASHBOARD,
      eventDispatcher.systemEventStates.SELECTED,
      { name: 'Room Dashboard', room: room?.RoomNumber },
      true
    );

    // eventDispatcher.emitEvent(
    //   eventDispatcher.systemEventTopics.DEVICE,
    //   eventDispatcher.systemEventStates.SELECTED,
    //   room?.MacAddress,
    //   false
    // );

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.ROOM,
      eventDispatcher.systemEventStates.SELECTED,
      room,
      true
    );
  };

  const emitResidentGroupDashboardSelect = (group: iResidentGroup) => {
    console.info('Clicked on:' + group.name);

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.DASHBOARD,
      eventDispatcher.systemEventStates.SELECTED,
      { name: 'Room Group', group: group.groupID },
      true
    );

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.RESIDENTGROUP,
      eventDispatcher.systemEventStates.SELECTED,
      { group: group.groupID },
      true
    );
  };

  useEffect(() => {
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.SITEEVENTS,
      eventDispatcher.systemEventStates.INITIAL_LOAD_COMPLETE,
      loadDataEvent
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.FLOORPLANS,
      eventDispatcher.systemEventStates.UPDATED,
      loadDataEvent
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.PROCESSED,
      loadDataEvent
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.UPDATED,
      loadDataEvent
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.RESIDENTGROUP,
      eventDispatcher.systemEventStates.UPDATED,
      loadDataEvent
    );

    return () => {
      // Remove registrations on exit
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.SITEEVENTS,
        eventDispatcher.systemEventStates.INITIAL_LOAD_COMPLETE,
        loadDataEvent
      );

      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.FLOORPLANS,
        eventDispatcher.systemEventStates.UPDATED,
        loadDataEvent
      );

      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.PROCESSED,
        loadDataEvent
      );
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.UPDATED,
        loadDataEvent
      );
      eventDispatcher.registerForEvent(
        eventDispatcher.systemEventTopics.RESIDENTGROUP,
        eventDispatcher.systemEventStates.UPDATED,
        loadDataEvent
      );
    };
  });

  if (manifest.manifestLoaded == false) {
    return <></>;
  }

  return (
    <>
      <TextInput
        placeholder="filter"
        size="small"
        icon={<Search />}
        reverse={true}
        value={filter}
        style={{ margin: '3px', width: '200px', marginLeft: '20px' }}
        onChange={(event) => setFilter(event.target.value)}
      />
      <SubMenu title="Rooms" icon={<Group color="amethyst" />}>
        {manifest.rooms &&
          matchSorter(manifest.rooms, filter, {
            keys: ['HomeDeviceID', 'RoomNumber', 'MacAddressHex', 'Name'],

            threshold: matchSorter.rankings.CONTAINS,
          })
            .sort((a, b) => {
              return a.RoomNumber - b.RoomNumber;
            })
            .map((item, index) => (
              <MenuItem
                key={`room${index}-${item.HomeDeviceId}`}
                clickCB={clickhandler}
                tooltip={item.toolTip()}
                dragitem={item}
                dragtype={DNDtypes.ROOM}
              >
                <Text
                  color={
                    Site.isOnDesignPlanMAC(item.MacAddress) == undefined
                      ? 'gold'
                      : 'grey-0'
                  }
                  onClick={() => {
                    emitRoomsDashboardSelect(item);
                  }}
                >
                  {item.RoomNumber} - {item.Name ?? '*Unnamed'}
                </Text>
              </MenuItem>
            ))}
      </SubMenu>
      <SubMenu title="Room Groups" icon={<Group color="yellow" />}>
        {rGroups &&
          rGroups.map((item, index) => (
            <MenuItem
              key={`group${index}-${item.name}`}
              clickCB={clickhandler}
              dragitem={item}
              dragtype={DNDtypes.ROOM}
            >
              <Text
                // color={
                //   Site.isOnDesignPlanMAC(item.description) == undefined
                //     ? 'gold'
                //     : 'grey-0'
                // }
                onClick={() => {
                  emitResidentGroupDashboardSelect(item);
                }}
              >
                {item.groupID} - {item.name ?? '*Unnamed'}
              </Text>
            </MenuItem>
          ))}
      </SubMenu>
      <SubMenu
        title="Pendants & Peripherals"
        icon={<AppsRounded color="amethyst" />}
        defaultOpen={filter.length > 0}
        onOpenChange={() => emitWirelessDashboardSelect()}
      >
        {WirelessDEVICE_TYPES.map((type, i) => {
          const total = matchSorter(manifest.getTriggersByType(type), filter, {
            keys: ['ID', 'Description', 'MacAddressHex'],
            threshold: matchSorter.rankings.CONTAINS,
          }).length;
          if (
            // only show peripherals that are installed (ensures we don't have a huge list of non-relevant peripherals)
            total > 0
          ) {
            return (
              <SubMenu
                title={getDeviceDescription(type)}
                key={`peripherals${i}-${type}`}
                defaultOpen={filter.length > 0}
                suffix={
                  <span>
                    <YellowBadge>{total}</YellowBadge>
                  </span>
                }
              >
                {manifest.triggers &&
                  matchSorter(manifest.getTriggersByType(type), filter, {
                    keys: ['ID', 'Description', 'MacAddressHex'],

                    threshold: matchSorter.rankings.CONTAINS,
                  })
                    .sort((a: Trigger, b: Trigger) => {
                      if (
                        a.parentRoom === undefined ||
                        b.parentRoom === undefined
                      )
                        return 0;
                      if (a.parentRoom.RoomNumber < b.parentRoom.RoomNumber)
                        return -1;
                      if (a.parentRoom.RoomNumber > b.parentRoom.RoomNumber)
                        return 1;
                      return 0;
                    })
                    .map((item, index) => (
                      <MenuItem
                        key={`trigger${index}-${item.MacAddressHex}`}
                        clickCB={() => emitWirelessDashboardSelect(item)}
                        tooltip={item.toolTip()}
                      >
                        {`Room: ${
                          item.parentRoom ? item.parentRoom.RoomNumber : ''
                        } (${
                          item.MacAddressHex.length > 6
                            ? item.MacAddressHex.substring(
                                item.MacAddressHex.length - 6,
                                item.MacAddressHex.length
                              )
                            : parseInt(item.MacAddressHex, 16).toString()
                        })`}
                      </MenuItem>
                    ))}
              </SubMenu>
            );
          }
        })}
      </SubMenu>
      <SubMenu title="Doors" icon={<Split color="white" />}>
        {manifest.deviceInterfaces &&
          manifest.getDoors().map((item, index) => {
            return (
              <MenuItem
                key={`door${index}-${item.DeviceId}`}
                clickCB={clickhandler}
                tooltip={item.toolTip()}
                dragitem={
                  item.Type !==
                  ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR
                    ? item
                    : undefined
                }
                dragtype={
                  item.Type !==
                  ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR
                    ? DNDtypes.DOOR
                    : undefined
                }
              >
                {item.Name}
              </MenuItem>
            );
          })}
      </SubMenu>
      <SubMenu title="Access Points" icon={<Nodes color="ruby" />}>
        {manifest.accessPoints &&
          matchSorter(manifest.accessPoints, filter, {
            keys: ['deviceID', 'MacAddressHex', 'Name'],
            threshold: matchSorter.rankings.CONTAINS,
          })
            .sort((a, b) => {
              const c = a.Name?.toLowerCase();
              const d = b.Name?.toLowerCase();

              return c < d ? -1 : c > d ? 1 : 0;
            })
            .map((item, index) => (
              <MenuItem
                key={`AP${index}-${item.DeviceId}`}
                clickCB={clickhandler}
                tooltip={item.toolTip()}
                dragitem={item}
                dragtype={DNDtypes.ACCESSPOINT}
              >
                <Text
                  color={
                    Site.isOnDesignPlanMAC(item.MacAddress) == undefined
                      ? 'gold'
                      : 'grey-0'
                  }
                >
                  {item.Name ?? '*Unnamed'}
                </Text>
              </MenuItem>
            ))}
      </SubMenu>
      <SubMenu title="Handsets" icon={<PhoneVertical color="ruby" />}>
        {manifest.handsets &&
          manifest.handsets.map((item, index) => (
            <MenuItem
              key={`handset${index}-${item.DeviceId}`}
              clickCB={clickhandler}
              tooltip={item.toolTip()}
            >
              {item.Description}
            </MenuItem>
          ))}
      </SubMenu>

      <SubMenu title="Lifts" icon={<AppsRounded color="white" />}>
        {manifest.deviceInterfaces &&
          manifest.getLifts().map((item, index) => (
            <MenuItem
              key={`lifts${index}-${item.DeviceId}`}
              clickCB={clickhandler}
              tooltip={item.toolTip()}
            >
              {item.Name}
            </MenuItem>
          ))}
      </SubMenu>
      <SubMenu title="Visual PullCords" icon={<AppsRounded color="white" />}>
        {manifest.deviceInterfaces &&
          manifest.getStandalonePullcords().map((item, index) => (
            <MenuItem
              key={`vpc${index}-${item.DeviceId}`}
              clickCB={clickhandler}
              tooltip={item.toolTip()}
            >
              {item.Name}
            </MenuItem>
          ))}
      </SubMenu>

      <SubMenu title="Callpoints" icon={<AppsRounded color="white" />}>
        {manifest.deviceInterfaces &&
          manifest.getCallPoints().map((item, index) => (
            <MenuItem
              key={`CP${index}-${item.DeviceId}`}
              clickCB={clickhandler}
              tooltip={item.toolTip()}
            >
              {item.Name}
            </MenuItem>
          ))}
      </SubMenu>
    </>
  );
};

export default InstallSidebarItems;
