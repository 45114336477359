import React, { useEffect, useState } from 'react';
import { LayoutManager } from 'components/dashboard/layoutManager';

import store, * as ReduxStore from 'store/store';
import * as UsersSlice from 'store/appUserSlice';
import * as EventDispatcher from 'store/eventDispatcher';
import * as LocalStore from 'common/localStorage';
import { getSelected } from 'store/sitesSlice';
import { Box, Layer } from 'grommet';
import { LinkNext, LinkPrevious } from 'grommet-icons';

interface iLastPage {
  sitename: string;
  page: iDashBoardEvent;
}

let sitename = getSelected(store.getState())?.name ?? 'invalid site';

let dashHistory: iDashBoardEvent[] = [];
let dashIndex = 0;
// let dashPointer = 0;

let newSite = false;

export const getCurrentPageName = (): string => {
  return dashHistory && dashHistory[0]
    ? dashHistory[0].name
    : 'Overview Dashboard';
};

export const refreshHistory = (site: string): void => {
  const lastPageString: string | null = localStorage.getItem(
    LocalStore.teLocalStorageItems.E_LS_LASTPAGE_HISTORY
  );
  sitename = site;
  dashIndex = 0;
  // dashPointer = -1;
  dashHistory = [];
  if (lastPageString) {
    const lp = (JSON.parse(lastPageString) as iLastPage[]).find(
      (f) => f.sitename == sitename
    );
    if (lp) {
      dashHistory.push(lp.page);
    }
  }
  newSite = true;
};

export interface iDashBoardEvent {
  name: string;
  room?: number;
  macAddress?: string;
  deviceID?: string;
  group?: number;
}

export const iDashBoardEventDefaults: iDashBoardEvent = {
  name:
    dashHistory.length > 0
      ? dashHistory[dashIndex]?.name
      : 'Overview Dashboard', // make sure this selects the overview if there is no dashHistory
};

const Dashboard = (): JSX.Element => {
  const [value, setValue] = useState<iDashBoardEvent>(iDashBoardEventDefaults);

  const updateHistory = () => {
    sitename = getSelected(store.getState())?.name ?? 'invalid site';
    const multiSiteLastPageString: string | null = localStorage.getItem(
      LocalStore.teLocalStorageItems.E_LS_LASTPAGE_HISTORY
    );
    const multiSiteLastPage: iLastPage[] = multiSiteLastPageString
      ? JSON.parse(multiSiteLastPageString)
      : [
          {
            sitename: sitename,
            page: dashHistory[dashHistory.length - 1],
          },
        ];
    let found = false;
    multiSiteLastPage.forEach((element) => {
      if (element.sitename == sitename) {
        element.page = dashHistory[dashHistory.length - 1];
        found = true;
      }
    });
    if (!found) {
      multiSiteLastPage.push({
        sitename: sitename,
        page: dashHistory[dashHistory.length - 1],
      });
    }
    localStorage.setItem(
      LocalStore.teLocalStorageItems.E_LS_LASTPAGE_HISTORY,
      JSON.stringify(multiSiteLastPage)
    );
  };

  const navigate = (e: EventDispatcher.iEventParams) => {
    const v = e.detail as iDashBoardEvent | undefined;
    if (v && dashIndex > -1) {
      setValue(v);
      // console.log(dashIndex + '- dash index');
      // console.log(dashPointer + '- dash pointer');
      // console.log(dashHistory);
      if (dashHistory.length >= 20) {
        dashHistory = dashHistory.slice(1, 19);
      }
      // dashHistory.push(v);
      updateHistory();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const goToLastPage = () => {
    if (dashIndex > 0) {
      dashIndex--;
    }
    const last = dashHistory[dashIndex];
    EventDispatcher.emitEvent(
      EventDispatcher.systemEventTopics.DASHBOARD,
      EventDispatcher.systemEventStates.NAVIGATE,
      last,
      true
    );
  };

  // eslint-disable-next-line no-unused-vars
  const goToNextPage = () => {
    if (dashIndex < dashHistory.length - 1) {
      dashIndex++;
    }
    const next = dashHistory[dashIndex];

    EventDispatcher.emitEvent(
      EventDispatcher.systemEventTopics.DASHBOARD,
      EventDispatcher.systemEventStates.NAVIGATE,
      next,
      true
    );
  };

  const refreshNeeded = () => {
    const storedDash = UsersSlice.getPersistantData(
      ReduxStore.store.getState()
    ).selectedDashboard;

    console.info('refreshNeeded, dash:', storedDash);
    if (dashHistory.length >= 20) {
      dashHistory = dashHistory.slice(1, 19);
    }
    if (storedDash) dashHistory.push(storedDash);
    // console.log(dashHistory);
    dashIndex = dashHistory.length - 1;
    // console.log(dashIndex);
    if (storedDash) {
      setValue(storedDash);
    }
    if (newSite) {
      newSite = false;
    }
    updateHistory();
  };

  useEffect(() => {
    /**
     * REFRESH NEEDED will be emitted when the someone requests a dashboard changed and we are already
     * on the dashboard view
     */
    EventDispatcher.registerForEvent(
      EventDispatcher.systemEventTopics.DASHBOARD,
      EventDispatcher.systemEventStates.REFRESHNEEDED,
      refreshNeeded
    );
    EventDispatcher.registerForEvent(
      EventDispatcher.systemEventTopics.DASHBOARD,
      EventDispatcher.systemEventStates.NAVIGATE,
      navigate
    );
    return () => {
      // Remove registrations on exit
      EventDispatcher.unRegisterForEvent(
        EventDispatcher.systemEventTopics.DASHBOARD,
        EventDispatcher.systemEventStates.REFRESHNEEDED,
        refreshNeeded
      );
      EventDispatcher.unRegisterForEvent(
        EventDispatcher.systemEventTopics.DASHBOARD,
        EventDispatcher.systemEventStates.NAVIGATE,
        navigate
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layer
        position="top-right"
        margin={{ top: '18px', right: '4px' }}
        style={{ whiteSpace: 'pre-wrap' }}
        plain
        full={false}
        modal={false}
      >
        <Box
          direction="row"
          background="grey-9"
          pad="4px"
          round="6px"
          border={{ color: dashHistory.length > 1 ? '#eee' : '#555' }}
          animation="slideLeft"
        >
          <LinkPrevious
            color={dashIndex > 0 ? 'cyan' : '#666'}
            size="20px"
            style={{
              cursor: 'pointer',
              marginLeft: '8px',
              marginRight: '8px',
            }}
            onClick={() => {
              goToLastPage();
              // setLastEvent(new Date());
            }}
          />
          <LinkNext
            color={dashIndex < dashHistory.length - 1 ? 'cyan' : '#666'}
            size="20px"
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={() => {
              goToNextPage();
              // setLastEvent(new Date());
            }}
          />
        </Box>
      </Layer>
      <LayoutManager view={value} />
    </>
  );
};

export default Dashboard;
