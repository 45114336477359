import Tile, { iTileEvent } from 'components/dashboard/tile';
import { Text, Box, Select, Tip } from 'grommet';
import React, { useEffect, useState } from 'react';
import { Edit, Phone } from 'grommet-icons';
import * as eventDispatcher from 'store/eventDispatcher';
import ResidentCalling from '../resident/residentCalling';
import { manifest } from 'components/floorplan/site';
import { Room } from 'components/installation/room';
import { teLocalStorageItems } from 'common/localStorage';

interface iGroupOption {
  index: number;
  callID: number;
  description: string;
}

const Announcements = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const [resident, setResident] = useState(-1);
  const [nonEmptyGroups, setNonEmptyGroups] = useState<iGroupOption[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<
    iGroupOption | undefined
  >();
  const [callOpen, setCallOpen] = useState(false);
  const [dps, setDps] = useState<Room[]>([]);

  const getGroups = async () => {
    const n: iGroupOption[] = [];
    n.push({ index: -1, callID: 8000, description: 'All Rooms' });
    const rg = await manifest.getResidentGroups();
    for (let index = 0; index < 30; index++) {
      if (manifest.groupHasRooms(index + 1)) {
        const push: string = rg
          ? rg[index].name
          : 'group ' + (index + 1).toString();
        n.push({ index: index + 1, callID: 8001 + index, description: push });
      }
    }
    if (n) setNonEmptyGroups(n);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        getGroups();
      },
      executeOnStartup: true,
    },
  ];

  useEffect(() => {
    getGroups();
    if (window && window.localStorage) {
      const grp = window.localStorage.getItem(
        teLocalStorageItems.E_LS_LASTRESIDENTGROUP
      );
      if (grp) {
        const pGrp: iGroupOption = JSON.parse(grp) as iGroupOption;
        if (pGrp) setSelectedGroup(pGrp);
      }
    }
  }, []);

  return (
    <Tile
      title="Announcements"
      eventListeners={events}
      setChildEvent={SetChildEvent}
    >
      <>
        <Box margin={{ left: '0px', top: '10px' }} align="center">
          <Text size="large">
            Live annoucements can be broadcast to either all rooms or a group of
            rooms
          </Text>
          <Text size="mediumplus" margin={{ top: '20px' }}>
            1. First Connect to the site
          </Text>
          <ResidentCalling
            resident={resident}
            titleText="Connect to Site"
            startOpen={true}
            setResident={setResident}
            setCallOpen={setCallOpen}
          />
        </Box>
        <Box margin={{ left: '5px', right: '5px', top: '30px' }} align="center">
          <Text size="mediumplus" margin={{ bottom: '10px' }}>
            2. Choose which rooms to make an announcement to and then click on
            Announce and speak the announcement. When finished simply drop the
            call or click on disconnect.
          </Text>
          <Box direction="row">
            <Select
              disabled={resident !== -1}
              width="250px"
              placeholder="Select a resident group..."
              labelKey="description"
              valueKey={{ key: 'callID' }}
              options={nonEmptyGroups}
              value={selectedGroup}
              size="medium"
              onChange={({ option }) => {
                setSelectedGroup(option);
                const o: iGroupOption = option as iGroupOption;
                const grp = o.index;
                setDps(grp !== -1 ? manifest.getRoomsInAGroup(grp) : []); // if set to -1 then its AllRooms not a group
              }}
            />
            <Phone
              style={{
                marginLeft: '10px',
              }}
              color={
                callOpen && selectedGroup
                  ? resident !== -1
                    ? 'green'
                    : 'blue'
                  : 'grey'
              }
              cursor={callOpen ? 'pointer' : 'not-allowed'}
              onClick={() => {
                if (resident != -1) {
                  setResident(-1); // drop call
                } else {
                  if (selectedGroup) setResident(selectedGroup.callID);
                }
              }}
            />
            <Text
              color={
                callOpen && selectedGroup
                  ? resident !== -1
                    ? 'green'
                    : 'blue'
                  : 'grey'
              }
            >
              Announce
            </Text>
          </Box>
          {dps && selectedGroup && selectedGroup.index !== -1 && (
            <Box margin={{ top: '15px', bottom: '0px' }} direction="row">
              <Text weight="bold">
                Rooms in group: {selectedGroup?.description}
              </Text>
              <Edit
                style={{
                  marginLeft: '10px',
                }}
                color={
                  selectedGroup && selectedGroup.index !== -1 ? 'green' : 'grey'
                }
                size="16px"
                cursor="pointer"
                onClick={() => {
                  window.localStorage.setItem(
                    teLocalStorageItems.E_LS_LASTRESIDENTGROUP,
                    JSON.stringify(selectedGroup)
                  );
                  eventDispatcher.emitEvent(
                    eventDispatcher.systemEventTopics.DASHBOARD,
                    eventDispatcher.systemEventStates.SELECTED,
                    { name: 'Room Group', group: selectedGroup.index },
                    true
                  );

                  eventDispatcher.emitEvent(
                    eventDispatcher.systemEventTopics.RESIDENTGROUP,
                    eventDispatcher.systemEventStates.SELECTED,
                    { group: selectedGroup.index },
                    true
                  );
                }}
              />
              <Text>edit this group</Text>
            </Box>
          )}
          <Box
            direction="row"
            style={{ minHeight: '100px', width: '100vw' }}
            margin={{ top: '10px' }}
            border="all"
            round={{ size: '15px' }}
            wrap={true}
            pad={{ top: '10px', left: '10px' }}
          >
            {dps &&
              dps.map((m, index) => (
                <Box
                  key={index}
                  border="all"
                  margin="3px"
                  pad="3px"
                  background={m.isCommunal() ? 'aqua' : 'blue'}
                  color="white"
                  height="30px"
                  round={{ size: '5px' }}
                >
                  <Tip content={<Text>{m.toolTip()}</Text>}>
                    <Box direction="row">
                      <Text weight="bold">{m.Name}</Text>
                      <Box margin={{ top: '2px', left: '4px' }}></Box>
                    </Box>
                  </Tip>
                </Box>
              ))}
          </Box>
        </Box>
      </>
    </Tile>
  );
};

export default Announcements;
