import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import * as Icons from 'grommet-icons';
import * as Wait from 'components/dialogues/waitDialogue';
import { Text, DateInput, Box } from 'grommet';
import * as DataSeq from 'store/dataSequencer';
import * as eventDispatcher from 'store/eventDispatcher';
import * as events from 'components/systemevents/events';

interface iDateRangeProps {
  setDialogueProps: (value: Wait.DialogueProps | undefined) => void;
  start: Date;
  end: Date;
  setStart: (d: Date) => void;
  setEnd: (d: Date) => void;
}

const DateRange = (props: iDateRangeProps): JSX.Element => {
  const dateFormat = new Intl.DateTimeFormat('en-GB');
  const maxEvents = 40000;

  // const [start, setStart] = useState(new Date());
  // const [end, setEnd] = useState(new Date());

  const getData = async (startDate: Date, endDate: Date) => {
    props.setDialogueProps({
      show: true,
      dialogueText: 'Please Wait...',
      showSpinner: true,
      showOkButton: false,
    });
    const recordsPulled = await DataSeq.getMoreDataInRange(
      startDate,
      endDate,
      maxEvents
    );

    if (recordsPulled >= maxEvents) {
      props.setDialogueProps({
        show: true,
        dialogueText:
          // 'Downloading all the records for the specified date range was not feasible, and therefore it is recommended to reduce the range.',
          'Downloaded the available data',
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          props.setDialogueProps(undefined);
        },
      });
    } else {
      props.setDialogueProps(undefined);
    }
  };

  const updateOldest = () => props.setStart(events.getOldestEntry());

  useEffect(() => {
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.ALARMEVENTS,
      eventDispatcher.systemEventStates.PROCESSED,
      updateOldest
    );
    props.setStart(events.getOldestEntry());
    return () => {
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.ALARMEVENTS,
        eventDispatcher.systemEventStates.PROCESSED,
        updateOldest
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box direction="row" gap="small" justify="center">
        <Text>From</Text>

        <DateInput
          value={props.start.toISOString()}
          icon={<Icons.Calendar size="small" />}
          buttonProps={{
            size: 'xsmall',
            plain: true,
            label: <Text>{`${dateFormat.format(props.start)}`}</Text>,
          }}
          onChange={async ({ value: nextValue }) => {
            if (!Array.isArray(nextValue)) {
              const newDate = new Date(nextValue);
              props.setStart(newDate);
            }
          }}
        />

        <Text>To</Text>
        <DateInput
          value={props.end.toISOString()}
          icon={<Icons.Calendar size="small" />}
          buttonProps={{
            size: 'small',
            plain: true,
            label: <Text>{`${dateFormat.format(new Date(props.end))}`}</Text>,
          }}
          onChange={async ({ value: nextValue }) => {
            if (!Array.isArray(nextValue)) {
              const newDate = new Date(nextValue);
              if (newDate.getTime() < props.start.getTime()) {
                props.setEnd(props.start);
              } else {
                props.setEnd(newDate);
                await getData(props.start, newDate);
              }
            }
          }}
        />
      </Box>
    </>
  );
};

export default DateRange;
