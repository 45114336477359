import { tePROCESSOR } from './IoTMessageDefines_DEVICES';

/*******teCLOUD_MSG_TY_SYSTEM */
export enum teCLOUD_MSG_TY_SYSTEM {
  E_CLOUD_MSG_SYSTEM_PREAUTH_URL_REQ = 0,
  E_CLOUD_MSG_SYSTEM_PREAUTH_URL_RESP,
  E_CLOUD_MSG_SYSTEM_MANIFEST_UPLOAD_REQ,
  E_CLOUD_MSG_SYSTEM_BACKUP_UPLOAD_REQ,
  E_CLOUD_MSG_SYSTEM_MANIFEST_UPLOAD_RESP,
  E_CLOUD_MSG_SYSTEM_GET_ALARMS_ACTIVE_REQ,
  E_CLOUD_MSG_SYSTEM_GET_ALARMS_ACTIVE_RESP,
  E_CLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_REQ,
  E_CLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_RESP,
  E_CLOUD_MSG_SYSTEM_RESET_REQ,
  E_CLOUD_MSG_SYSTEM_RESET_RESP,
  E_CLOUD_MSG_SYSTEM_DEVICE_RESET_REQ,
  E_CLOUD_MSG_SYSTEM_DEVICE_RESET_RESP,
  E_CLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_REQ,
  E_CLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP,
  E_CLOUD_MSG_SYSTEM_CLEAR_ALARM_REQ,
  E_CLOUD_MSG_SYSTEM_CLEAR_ALARM_RESP,
  E_CLOUD_MSG_SYSTEM_RESTART_TUNNEL_REQ,
  E_CLOUD_MSG_SYSTEM_RESTART_TUNNEL_RESP,
  E_CLOUD_MSG_SYSTEM_MAX,
}

export interface tsCLOUD_MSG_SYSTEM_PREAUTH_URL_REQ {
  mac: number;
  processor: tePROCESSOR;
}

export interface tsCLOUD_MSG_SYSTEM_PREAUTH_URL_RESP {
  objectKey: string;
  url: string;
}

//export interface tsCLOUD_MSG_SYSTEM_MANIFEST_UPLOAD_REQ {}
//export interface tsCLOUD_MSG_SYSTEM_MANIFEST_UPLOAD_RESP {}
//export interface tsCLOUD_MSG_SYSTEM_GET_ALARMS_ACTIVE_REQ {}

export interface tsCLOUD_MSG_SYSTEM_GET_ALARMS_ACTIVE_RESP {
  alarmsactive: number;
}

export interface tsCLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_REQ {
  autotest: boolean;
}

export interface tsCLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_RESP {
  errorcode: number;
}

export interface tsCLOUD_MSG_SYSTEM_RESET_REQ {
  passcode: number;
}

export interface tsCLOUD_MSG_SYSTEM_RESET_RESP {
  errorcode: number;
}

export interface tsCLOUD_MSG_SYSTEM_DEVICE_RESET_REQ {
  mac: number;
  passcode: number;
}

export interface tsCLOUD_MSG_SYSTEM_DEVICE_RESET_RESP {
  mac: number;
  errorcode: number;
}

//export interface tsCLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_REQ{}

export interface iAlarmQueueItem {
  alarmid: number;
  state: number;
  ownermac: number;
  sourcemac: number;
  sendermac: number;

  ownerdeviceid: number;
  sourcedeviceid: number;
  senderdeviceid: number;

  alarmtype: number;
  originalalarmtype: number;
  alarmstring: string;
}

export interface tsCLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP {
  alarms: iAlarmQueueItem[];
}

export interface tsCLOUD_MSG_SYSTEM_CLEAR_ALARM_REQ {
  alarmid: number;
  autotest: boolean;
}

export interface tsCLOUD_MSG_SYSTEM_CLEAR_ALARM_RESP {
  errorcode: number;
}

export interface E_CLOUD_MSG_SYSTEM_RESTART_TUNNEL_RESP {
  errorcode: number;
}
