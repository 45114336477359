import { ALARM_TYPES } from 'types/event-enums';

export const ALARM_TYPES_ACCESSPOINT_SCU: ALARM_TYPES[] = [
  ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT,
  ALARM_TYPES.ALARM_TYPE_MAINS_POWER,
  ALARM_TYPES.ALARM_TYPE_BATTERY,
  ALARM_TYPES.ALARM_TYPE_SMOKE,
  ALARM_TYPES.ALARM_TYPE_FIRE,
  ALARM_TYPES.ALARM_TYPE_FLOOD,
  ALARM_TYPES.ALARM_TYPE_DOOR_OPEN,
  ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1, // "Red Button"
  ALARM_TYPES.ALARM_TYPE_EMERGENCY,
  ALARM_TYPES.ALARM_TYPE_CARBON_MONOXIDE_GAS,
  ALARM_TYPES.ALARM_TYPE_HIGH_TEMPERATURE,
  ALARM_TYPES.ALARM_TYPE_LOW_TEMPERATURE,
  ALARM_TYPES.ALARM_TYPE_ENVIRONMENTAL_MONITOR,
  ALARM_TYPES.ALARM_TYPE_TAMPER,
  ALARM_TYPES.ALARM_TYPE_PANIC_BUTTON,
];
