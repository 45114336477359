import React, { useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';

import Tile, {
  iTileEvent,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Manifest, teErrorCode } from 'components/installation/manifest';
import {
  systemEventStates,
  systemEventTopics,
  iEventParams,
} from 'store/eventDispatcher';

import { AddCircle, Edit, SubtractCircle } from 'grommet-icons';
import { Trigger } from 'components/installation/trigger';
import { iDevice, iRoutingEvent } from 'types/manifest-types';
import * as Wait from 'components/dialogues/waitDialogue';
import {
  Box,
  Button,
  Form,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Tabs,
  Text,
  Tip,
} from 'grommet';

import AddPeripheralDialogue from 'components/dialogues/addPeripheral';
import * as systemFaults from 'components/systemevents/faults';
import { Room } from 'components/installation/room';
import * as utils from 'common/utils/dateUtils';
import {
  AlignedFormFields,
  iSelectKeyValue,
} from 'components/forms/formFields';
import logger from 'common/logger';
import * as eventEnums from 'types/event-enums';
import RoutingEventEdit, {
  iREDialogProps,
} from 'components/dashboard/commonWidgets/routingEventEdit';
import {
  getAllCareGroups,
  getCareGroupIDFromSequenceId,
  getCareGroupSequenceId,
} from 'components/installation/careGroupUtils';
import { roomForm, iRoomForm } from './residentFormFields';
// import { manifest } from 'components/floorplan/site';
import { teSCHEDULES_OBJECT_TYPES } from 'types/manifest-enums';
import ScheduleEdit from 'components/dashboard/commonWidgets/schedules/scheduleEdit';
import { globalInactivityPeriod, globalOkPeriod } from './residentUtils';

import UserLevel from 'components/wrappers/UserLevel';
import * as userUtils from 'common/userUtils';
import ResidentService from './residentService';
import SCUversion from 'components/wrappers/SCUversion';
import { Device } from 'components/installation/device';
import { Schedule } from 'components/installation/schedule';
import _ from 'lodash';
import { unlinkRoom, linkRooms } from '../../../installation/manifestRoomUtils';
import { Paragraph } from 'grommet';
import { getAlarmDescription } from 'common/alarmTypesTextFiltered/alarmTypeUtils';
import { alarm_types_iSelectKeyValues } from '../../../../common/alarmTypesTextFiltered/alarmTypeUtils';
import { ALARM_TYPES_VRU_HWIP } from 'common/alarmTypesTextFiltered/alarmTypesVRU_HWIP';

const m = new Manifest();

export interface iREDialogShowProps {
  show: boolean;
  wirelessSensor: boolean;
  alarmTypes?: iSelectKeyValue[];
  activeTab: number;
  message?: string;
  device?: Device;
  r?: iRoutingEvent[];
}

export type TriggersViewProps = {
  lowBatteryOnly?: boolean;
};

const Resident = (props: any): JSX.Element => {
  const [room, setRoom, roomRef] = useStateRef<iRoomForm>();
  const [roomObj, setRoomObj] = useState<Room>();
  const [wirelessSensors, setWirelessSensors] = useState<
    Trigger[] | undefined
  >();
  const [index, setIndex] = React.useState(0);
  const onActive = (nextIndex: number) => setIndex(nextIndex);

  // Routing Event Dialog
  // const [showREDialog, setShowREDialog] = useState(false);
  const [reDialogProps, setREDialogProps] = useState<iREDialogProps>({
    productCode: '',
    device: undefined,
    show: false,
    wirelessSensor: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setShow: () => {},
    events: [],
    message: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    save: () => {},
    disabled: false,
  });

  // const [roomWirelessSensors, setRoomWirelessSensors] = useState<
  //   Trigger[] | undefined
  // >();

  const [, SetChildEvent] = useState('');
  const [changes, setChanges, changesRef] = useStateRef(false);
  const [, setSelectedDevice] = useState<Trigger | undefined>();
  const [careGroups, setCareGroups] = useState<iSelectKeyValue[]>([]);
  const [okSchedules, setOkSchedules] = useState<Schedule[]>();
  const [inactivitySchedules, setInactivitySchedules] = useState<Schedule[]>();

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();

  const [showAddPeripheralDialogue, setShowAddPeripheralDialogue] =
    useState(false);

  const globalOk = globalOkPeriod(m);
  const globalInactivity = globalInactivityPeriod(m);

  const loadRoom = () => {
    console.log('Room Load', roomRef.current?.RoomNumber);
    let rN = roomRef.current?.RoomNumber;
    if (!rN) {
      rN = props.view?.room;
    }

    if (!rN) rN = -1;
    console.log('Room Load2', rN);
    const _room = m.getRoomByRoomNumber(rN);

    if (_room) {
      const meta = _room.getMetaData();

      const triggers = m.triggers.filter(
        (t) => t.parentRoom?.RoomNumber == _room.RoomNumber
      );

      const r: iRoomForm = {
        ID: _room.productManifest.tableID,
        Name: _room.Name,
        DeviceID: _room.HomeDeviceId,
        CareGroup:
          getCareGroupIDFromSequenceId(
            _room.getRoutingEvent(
              eventEnums.ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1
            )?.CareSequenceId ?? 1
          )?.ID.toString() ?? '',
        RoomNumber: _room.RoomNumber,
        AwayState: _room.AwayState == 1,
        AwayDescription: _room.AwayState == 1 ? 'Away' : 'Home',
        EnableOK: _room.EnableOK == 1,
        Inactivity: _room.Inactivity == 1,
        Intruder: _room.Intruder == 1,
        Empty: _room.Empty == 1,
        Communal: meta ? meta.communal : false,
        PollingActive: _room.RaisePollFails == 1,
        tags: _room.getMetaData().tags,
        lastOKPress: utils.formatDateFromEpoch(_room?.LastOK),
        speechVolume: _room.volumetoString(_room.Volume),
        toneVolume: _room.volumetoString(_room.ToneVolume),
        LinkedRoomNumber: _room.LinkedRoomNumber,
        okAudibleReminders: _room.OKTimeLeftBeforeReminder > 0,
        okReminderFrequency: Math.round(_room.OKReminderPeriod / 60).toString(),
        okReminderBeforeEnd: Math.round(
          _room.OKTimeLeftBeforeReminder / 60
        ).toString(),
        RaiseResidentOKAlarm: _room.RaiseResidentOKAlarm == 1,
        TestModeEnabledEPOCH: _room.TestModeEnabledEPOCH,
        IntruderEntryExitTimeout: _room.EntryExitTimeout,
        DivertDoorToManager: _room.DivertDoorToManager == 1,
        HomeAwaySetIntruder: _room.HomeAwaySetIntruder == 1,
        AwayDivertDoorToManager: _room.AwayDivertDoorToManager == 1,
      };

      // logger.debug('resident.load.room', { formdata: r, room: _room });
      setRoomObj(_room);
      setRoom(r);
      setWirelessSensors(triggers);
      setOkSchedules(
        _.cloneDeep(
          m.getSchedulesByTypeAndId(
            teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_RESIDENT_OK,
            r.DeviceID ?? -1
          )
        )
      );
      setInactivitySchedules(
        _.cloneDeep(
          m.getSchedulesByTypeAndId(
            teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_INACTIVITY,
            r.DeviceID ?? -1
          )
        )
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadDataEvent = (e: iEventParams) => {
    // If we have made changes then do not reload the data
    if (changesRef.current == true) {
      logger.debug('Ignore loadDataEvent as we have changes pending');
      return;
    }
    loadRoom();
  };

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.LOADED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
  ];

  const modifyRoutingEvents = (
    wirelessSensor: boolean,
    events: iRoutingEvent[]
  ) => {
    if (room) {
      const r = m.getRoomByRoomNumber(room.RoomNumber ?? -1);
      logger.info('resident.modify.routingEvents', { routingEvents: events });
      if (r) {
        console.log('here');
        events.map((e) => {
          let ev = undefined;

          if (wirelessSensor) {
            // Wireless Peripheral
            console.log('here -wireless');

            const trig = m.triggers.find((f) => f.ID == e.AlarmSourceDeviceId);
            if (trig) {
              ev = trig.getAlarmRoutingEventbyID(e.ID);
              if (ev) {
                ev.AlarmTypeId = e.AlarmTypeId;
                ev.AltAlarmTypeId = e.AltAlarmTypeId;
                ev.CareSequenceId = e.CareSequenceId;
                ev.Covert = e.Covert;
                ev.Flags = e.Flags;
                ev.GuardPeriod = e.GuardPeriod;
                ev.PreAlarmDelay = e.PreAlarmDelay;
                ev.AlarmDisabled = e.AlarmDisabled;
                // console.log('EV', ev);
              }
            }
          } else {
            console.log('here - wired');
            r.setHardwiredInputRoutingEvents_checkIfLatchedArmDisarm(e);
          }
        });
      }
      setChanges(true);
    }
  };

  const save = async () => {
    if (room) {
      const r = m.getRoomByRoomNumber(room.RoomNumber ?? -1);

      logger.info('resident.save', { new: room });

      if (r && room) {
        r.Name = room.Name;
        r.EnableOK = room.EnableOK ? 1 : 0;
        r.Inactivity = room.Inactivity ? 1 : 0;
        r.Intruder = room.Intruder ? 1 : 0;
        r.Empty = room.Empty ? 1 : 0;
        r.Volume = r.stringToVolume(room.speechVolume) as 0 | 1 | 2 | 3 | 4;
        r.ToneVolume = r.stringToVolume(room.toneVolume) as 0 | 1 | 2 | 3 | 4;
        // if the room linkage haa changed then address the unlinking / linking required
        if (r.LinkedRoomNumber != room.LinkedRoomNumber) {
          if (room.LinkedRoomNumber == 0) unlinkRoom(m, room.RoomNumber); // remove link
          if (room.LinkedRoomNumber > 0)
            linkRooms(m, room.RoomNumber, room.LinkedRoomNumber); // add new link
        }
        r.LinkedRoomNumber = room.LinkedRoomNumber;
        const a = r.getMetaData();
        a.communal = room.Communal;
        r.setMetaData(a);
        r.RaisePollFails = room.PollingActive ? 1 : 0;

        r.OKReminderPeriod = Number(room.okReminderFrequency) * 60;
        r.OKTimeLeftBeforeReminder = Number(room.okReminderBeforeEnd) * 60;

        r.RaiseResidentOKAlarm = room.RaiseResidentOKAlarm ? 1 : 0;

        r.EntryExitTimeout = room.IntruderEntryExitTimeout;

        r.DivertDoorToManager = room.DivertDoorToManager ? 1 : 0;
        r.HomeAwaySetIntruder = room.HomeAwaySetIntruder ? 1 : 0;
        r.AwayDivertDoorToManager = room.AwayDivertDoorToManager ? 1 : 0;

        // Get the care sewquence id and see if it has changed
        const csID = getCareGroupSequenceId(Number(room.CareGroup));
        if (
          r.getRoutingEvent(eventEnums.ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1)
            ?.CareSequenceId != csID
        ) {
          // it's changed so set the resident to the new caregroup
          r.setResidentCareGroup(Number(room.CareGroup));
        }

        // The room class has a front end API to set Hardwired inputs
        // On a manifest save the routing events are overwritten by that saved in room class
        // We are actually modifying the underlying RoutingEvents so tell the room pull what we have set.
        r.pullHardwiredInputsFromRoutingEvents();

        //console.log(r.getHardwiredInputRoutingEvents());

        // Do any routing events

        // PollingActive: false,

        const complete = (str: string) => {
          setDialogueProps({
            show: true,
            dialogueText: str,
            showSpinner: false,
            showOkButton: true,
            okButtonPress: () => {
              setDialogueProps(undefined);
            },
          });
          setChanges(false);
        };
        setDialogueProps({
          show: true,
          dialogueText: 'Please Wait',
          showSpinner: true,
          showOkButton: false,
        });
        let str = 'Save failed';
        m.save().then((status) => {
          if (status === teErrorCode.E_OK) {
            str = 'Save Completed Successfully';
          } else if (status === teErrorCode.E_CONNECTION_FAIL) {
            str = 'Connection failed';
            logger.info('roomunit.saveFailed', {
              status: status,
              error: {},
            });
          }

          complete(str);
        });
      }
    }

    // setRoom(r);
  };

  const unassignDevice = (device: iDevice) => {
    logger.info('resident.unassignDevice', { device: device });
    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setSelectedDevice(undefined);
    };

    let str = 'Save failed';
    m.deleteTrigger(device.ID)
      .then(async (status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';

          // clear all faults associated with this device
          await systemFaults.clearfaultsfordevice(device.ID);
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
        }
        complete(str);
      })
      .catch((error) => {
        console.error('Unassign fail:', error);
        complete(str);
      });
  };

  const unassignClick = (t: Trigger) => {
    if (room) {
      const str1 = `Are you sure you want to unassign the "${
        t.Description
      }" with the idenitity ${t.getIdentity()}?`;

      setDialogueProps({
        show: true,
        dialogueText: str1,
        dialogueTextLine2: '',
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          unassignDevice(t);
        },
        showCancelButton: true,
        cancelButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
    }
  };

  /**
   * Show the Routing Events Dialog Box
   * @param show
   * @param r
   */
  const showREDialog = (props: iREDialogShowProps) => {
    if (props.show) {
      if (props.r) {
        setREDialogProps({
          ...reDialogProps,
          events: props.r,
          changes: setChanges,
          device: props.device,
          wirelessSensor: props.wirelessSensor,
          alarmTypes: props.alarmTypes,
          message: props.message ?? '',
          setShow: showREDialog,
          save: modifyRoutingEvents,
          activeTab: props.activeTab,
          show: true,
        });
      }
    } else {
      setREDialogProps({
        ...reDialogProps,
        events: [],
        wirelessSensor: false,
        message: '',
        setShow: showREDialog,
        save: modifyRoutingEvents,
        show: false,
      });
    }
  };

  const WirelessSensors = () => {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell scope="col" border="bottom">
              <span>
                <Text weight="bold">Wireless Devices</Text>
              </span>
            </TableCell>
            <TableCell scope="col" border="bottom"></TableCell>
          </TableRow>
        </TableHeader>
        <Box align="start" height={{ max: '400px' }} overflow="auto">
          <TableBody>
            {room && (
              <TableRow>
                <TableCell style={{ lineHeight: '0.7rem' }}>
                  <span>
                    <Tip
                      content={
                        <Text>
                          Assign a new device to the room. You will need the
                          device identity code to do this.
                        </Text>
                      }
                      dropProps={{ align: { left: 'right' } }}
                    >
                      <AddCircle
                        color={'green'}
                        size="18px"
                        cursor="pointer"
                        onClick={() => setShowAddPeripheralDialogue(true)}
                      />
                    </Tip>
                    <Text style={{ marginLeft: '6px' }}>Assign New Device</Text>
                  </span>
                </TableCell>
              </TableRow>
            )}

            {room &&
              wirelessSensors?.map((t, index) => (
                <TableRow key={index}>
                  <TableCell style={{ lineHeight: '0.7rem' }}>
                    <span>
                      <Tip
                        content={<Text>Edit settings</Text>}
                        dropProps={{ align: { left: 'right' } }}
                      >
                        <Edit
                          size="16px"
                          cursor="pointer"
                          onClick={() =>
                            showREDialog({
                              show: true,
                              wirelessSensor: true,
                              activeTab: 0,
                              message: `Each event that can be raised by the ${t.Description} is individually configurable. `,
                              r: t.getAlarmRoutingEvents(),
                              device: t,
                            })
                          }
                        />
                      </Tip>
                      <Tip
                        content={
                          <Text>Remove/Unassign this device from the room</Text>
                        }
                        dropProps={{ align: { left: 'right' } }}
                      >
                        <SubtractCircle
                          size="16px"
                          cursor="pointer"
                          color="red"
                          onClick={() => unassignClick(t)}
                          style={{ marginLeft: '6px' }}
                        />
                      </Tip>
                      <Tip content={t.toolTip()}>
                        <Text style={{ marginLeft: '6px' }}>
                          {t.Description}
                        </Text>
                      </Tip>
                    </span>
                  </TableCell>
                  <TableCell style={{ lineHeight: '0.7rem' }}>
                    {/* {t.MacAddressHex.length > 6
                  ? t.MacAddressHex.substring(t.MacAddressHex.length - 6, 6)
                  : parseInt(t.MacAddressHex, 16).toString()} */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Box>
      </Table>
    );
  };

  const WiredSensors = () => {
    const numberOfInputs = roomObj?.HardwiredInputsSupported ?? 0;
    const inputs = [
      eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1,
      eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2,
      eventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3,
    ];

    const InputRows = [];

    for (let i = 0; i < numberOfInputs; i++) {
      let desc = '';
      const events = roomObj?.getHardwiredInputRoutingEvents();
      if (events) {
        const hw = events.find((f) => f.AlarmTypeId == inputs[i]);
        if (hw) desc = getAlarmDescription(hw.AltAlarmTypeId);
      }

      InputRows.push(
        <TableRow>
          <TableCell style={{ lineHeight: '0.7rem' }}>
            {room && roomObj && (
              <span>
                <Tip
                  content={<Text>Edit settings</Text>}
                  dropProps={{ align: { left: 'right' } }}
                >
                  <Edit
                    size="16px"
                    cursor="pointer"
                    onClick={() =>
                      showREDialog({
                        show: true,
                        wirelessSensor: false,
                        alarmTypes:
                          alarm_types_iSelectKeyValues(ALARM_TYPES_VRU_HWIP),
                        activeTab: i,
                        message:
                          'Each hardwired input is individually configurable',
                        device: undefined,
                        r: roomObj.getHardwiredInputRoutingEvents(),
                      })
                    }
                  />
                </Tip>
                <Text style={{ marginLeft: '6px' }} weight="bold">
                  Input {i + 1}:
                </Text>
                <Text style={{ marginLeft: '6px' }}>{desc}</Text>
              </span>
            )}
          </TableCell>
          <TableCell style={{ lineHeight: '0.7rem' }}></TableCell>
        </TableRow>
      );
    }

    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell scope="col" border="bottom">
              <Text style={{ marginLeft: '6px' }} weight="bold">
                Wired Devices
              </Text>
            </TableCell>
            <TableCell scope="col" border="bottom"></TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>{InputRows}</TableBody>
      </Table>
    );
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes ? 'brand' : 'grey-4'}
      badge={changes ? true : false}
      tip="Save changes"
      disabled={changes ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  useEffect(() => {
    if (props.view.room) {
      console.log('UseEffect Room', props.view.room);
      if (room) room.RoomNumber = props.view.room;
      loadRoom();

      const cg = getAllCareGroups().map((c) => {
        return {
          key: c.careGroup.ID.toString(),
          value: c.careGroup.Description,
        };
      });
      // logger.debug('resident.load.room', { caregroups: cg });

      setCareGroups(cg);
      setChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Tile
      title={`Room ${room && room.RoomNumber ? room.RoomNumber : ''}`}
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      ContextButtons={ContextButtons}
      showLastEvent={true}
    >
      <>
        <RoutingEventEdit {...reDialogProps} />
        <AddPeripheralDialogue
          show={showAddPeripheralDialogue}
          setShow={setShowAddPeripheralDialogue}
          setDialogueProps={setDialogueProps}
          defaultRoom={roomObj}
        />
        <Tabs activeIndex={index} onActive={onActive} alignControls="start">
          <Tab title="Resident">
            <Form
              value={room}
              onChange={(nextValue) => {
                if (!nextValue.okAudibleReminders)
                  nextValue.okReminderBeforeEnd = '0';
                if (
                  nextValue.okAudibleReminders &&
                  nextValue.okReminderBeforeEnd == '0'
                )
                  nextValue.okReminderBeforeEnd = '60';
                if (nextValue) setRoom(nextValue);
                setChanges(true);
                // console.info(room);
              }}
              // onReset={() => setValue({})}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onSubmit={async () => {}}
            >
              <Box margin="10px">
                <AlignedFormFields
                  displayfields={roomForm(careGroups, room, m)}
                  columnLayouts={[
                    { columnPercentWidth: 27, labelWidth: '120px' },
                    { columnPercentWidth: 23, labelWidth: '180px' },
                    { columnPercentWidth: 25, labelWidth: '190px' },
                  ]}
                />
              </Box>
            </Form>
            <Box margin="10px" border={{ side: 'bottom' }}></Box>
            <Box
              pad={{ left: '10px' }}
              align="start"
              width="100%"
              direction="row"
            >
              <Box margin="4px" width="400px">
                {room && (
                  <ScheduleEdit
                    title="Resident OK Schedule"
                    schedules={okSchedules ?? []}
                    save={() => setChanges(true)}
                    objectID={room.DeviceID ?? -1}
                    objectType={
                      teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_RESIDENT_OK
                    }
                    disabled={room.EnableOK ? false : true}
                    enabledAllWeekText={
                      'Using global resident OK schedule ' + globalOk
                    }
                    // hide={room?.EnableOK ?? false}
                  />
                )}
              </Box>
              <Box margin="4px" width="400px">
                {room && (
                  <ScheduleEdit
                    title="Inactivity Schedule"
                    schedules={inactivitySchedules ?? []}
                    save={() => setChanges(true)}
                    objectID={room.DeviceID ?? -1}
                    objectType={
                      teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_INACTIVITY
                    }
                    disabled={room.Inactivity ? false : true}
                    enabledAllWeekText={
                      'Using global inactivity schedule ' + globalInactivity
                    }

                    // hide={room?.EnableOK ?? false}
                  />
                )}
              </Box>
            </Box>
          </Tab>
          <Tab title="Devices">
            <Box
              pad={{ left: '10px' }}
              align="start"
              width="100%"
              direction="row"
            >
              <Box margin="4px" width="380px">
                <WirelessSensors />
              </Box>
              <Box margin="4px" width="380px">
                <WiredSensors />
              </Box>
            </Box>
          </Tab>
          <SCUversion minVersion="5.12.0">
            <UserLevel minUserLevel={userUtils.UAG.MANAGER}>
              <Tab title="Service">
                <Box
                  pad={{ left: '10px' }}
                  align="start"
                  width="100%"
                  direction="row"
                >
                  <ResidentService room={room} />
                </Box>
              </Tab>
            </UserLevel>
          </SCUversion>
        </Tabs>
      </>
    </Tile>
  );
};

export default Resident;
