/** @module ManifestSiteDataSync */

import { iSite } from 'types/plan-types';
import logger from 'common/logger';
import { Manifest, teErrorCode } from 'components/installation/manifest';
import * as ManifestEnums from 'types/manifest-enums';
import _ from 'lodash';
import * as IoTAssign from 'common/IoT/IoTAssignDevice';

const manifest = new Manifest();

const updatePreviousAssignedUnits = (siteData: iSite) => {
  for (const index in siteData.equipment) {
    const equipment = siteData.equipment[index];
    if (equipment.unitNumber === undefined || equipment.unitNumber <= 0)
      continue;
    if (equipment.locationName === undefined || equipment.locationName == '')
      continue;

    if (
      equipment.deviceType ==
      ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER
    ) {
      const device = manifest.accessPoints.find(
        (e) => e.MacAddress == equipment.physicalID
      );

      if (device) {
        device.Name = equipment.locationName;
        device.UnitId = equipment.unitNumber;
      }
    } else if (
      equipment.deviceType == ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT
    ) {
      const device = manifest.rooms.find(
        (e) => e.MacAddress == equipment.physicalID
      );

      if (device) {
        device.Name = equipment.locationName;
        device.RoomNumber = equipment.unitNumber;
        //if the plan has communal set then also set commnual on the manifest
        if (equipment.commonArea) {
          const a = device.getMetaData();
          a.communal = true;
          device.setMetaData(a);
        }
      }
    } else if (
      equipment.deviceType ==
      ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_APEX_DOOR_PANEL_AND_INTERFACES
    ) {
      const device = manifest.deviceInterfaces.find(
        (e) => e.MacAddress == equipment.physicalID
      );

      if (device) {
        device.Name = equipment.locationName;
        device.UnitId = equipment.unitNumber;
      }
    }
  }
};

const assignUnits = async (siteData: iSite): Promise<boolean> => {
  // take a deep copy incase manifest is update from the site
  const unassignedDevices = _.cloneDeep(manifest.getUnassignedDevices());
  for (const index in unassignedDevices) {
    const device = unassignedDevices[index];

    if (device.Type == ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER) {
      // we don't need any IoT messages sent to the site for routers as the SCU already creates
      // a default id of 9000.
      // The updatePreviousAssignedUnits will catch these updates
      continue;
    }

    const equipment = siteData.equipment.find(
      (e) => e.physicalID == device.MacAddress
    );

    if (equipment) {
      if (equipment.unitNumber === undefined || equipment.unitNumber <= 0)
        continue;
      if (equipment.locationName === undefined || equipment.locationName == '')
        continue;

      try {
        console.info(
          `Assigning device id ${device.ID} mac:${device.MacAddress} to unit id ${equipment.unitNumber}`
        );

        logger.info('mergeIntoManifest.assigningDevice', {
          deviceID: device.ID,
          mac: device.MacAddress,
          unitId: equipment.unitNumber,
        });

        await IoTAssign.assignDevice(
          device.MacAddress,
          equipment.unitNumber,
          equipment.locationName,
          null
        );
      } catch (e) {
        console.error('No response to the assign request, scu offline');
        return false;
      }
    }
  }

  return true;
};

export const mergeSiteDataIntoManifest = async (
  siteData: iSite
): Promise<boolean> => {
  console.info('sitedata to merge:', siteData);

  updatePreviousAssignedUnits(siteData);
  let success = await assignUnits(siteData);

  // Now save the manifest,  this will result in a manifest upload request been issued to the site
  if (success) {
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          console.info('mergeIntoManifest Save ok');
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          logger.info('mergeIntoManifest.saveFailed', {
            status: status,
            error: {},
          });
          success = false;
        }
      })
      .catch((error) => {
        logger.info('mergeIntoManifest.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        console.error('save fail:', error);
        success = false;
      });
  }

  return success;
};
