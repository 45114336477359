import React from 'react';
import { UAG } from 'common/userUtils';
import RecentAlarmsOverview from '../commonWidgets/calls/recentAlarmsOverview';
import RecentDoorCallsOverview from '../commonWidgets/calls/recentDoorCallsOverview';
import LowBatteryPeripheralsOverview from '../commonWidgets/lowBatteryPeripheralsOverview';
import ResidentNotOKOverview from '../commonWidgets/residentNotOKOverview';
import { WIDGETCAT, iWidgetEntry } from './widgetTypes';

export const commonWidgetLibrary: Array<iWidgetEntry> = [
  {
    name: 'RecentAlarmsOverview',
    description: 'Recent Alarms Overview',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <RecentAlarmsOverview />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'RecentDoorCallsOverview',
    description: 'Recent Door Calls Overview',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <RecentDoorCallsOverview />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'LowBatteryPeripheralsOverview',
    description: 'Low Battery Peripherals',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <LowBatteryPeripheralsOverview />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'ResidentNotOKOverview',
    description: 'Residents Not Ok',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <ResidentNotOKOverview />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
];
