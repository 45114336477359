import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import * as dbAccess from 'store/dbAccess';
import * as queries from 'graphql/queries';
import { createExtraReducers } from 'store/commonReducers';
import { RootState } from 'store/store';
import { OmniviaFaults, OmniviaSCU, UpdateOmniviaFaultsInput } from 'types/API';
import {
  systemEventTopics,
  systemEventStates,
  emitEvent,
} from './eventDispatcher';
import { initialSliceState } from 'store/commonSlice';
import * as Amp from 'aws-amplify';

export const getAllActiveFaults = /* GraphQL */ `
  query FaultsByState(
    $omniviaFaultsLinkedSCUId: ID
    $faultState: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaFaultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faultsByState(
      omniviaFaultsLinkedSCUId: $omniviaFaultsLinkedSCUId
      faultState: $faultState
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        faultState
        faultType
        dateRaised
        dateCleared
        dateAlertLastGenerated
        deviceID
        faultSpecificText
        friendlyString
        clearedBy
        createdAt
        updatedAt
        expirationUnixTime
      }
      nextToken
    }
  }
`;

export const getOmniviaSCUFaults = /* GraphQL */ `
  query GetOmniviaSCU($id: ID!, $filter: ModelOmniviaFaultsFilterInput) {
    getOmniviaSCU(id: $id) {
      linkedFaults(limit: 2000, filter: $filter) {
        items {
          id
          faultState
          faultType
          dateRaised
          dateCleared
          dateAlertLastGenerated
          deviceID
          faultSpecificText
          friendlyString
          clearedBy
          createdAt
          updatedAt
          expirationUnixTime
        }
        nextToken
      }
    }
  }
`;

const dbAccessInfo = {
  DAOname: 'SCUFaults',
  allQuery: getAllActiveFaults,
  allSelector: 'faultsByState',
  allSort: function (a: OmniviaFaults, b: OmniviaFaults) {
    if (a.dateRaised < b.dateRaised) {
      return -1;
    }
    if (a.dateRaised > b.dateRaised) {
      return 1;
    }
    return 0;
  },

  selectQuery: queries.getOmniviaFaults,
  selectSelector: 'getOmniviaFaults',
};

const adapter = createEntityAdapter({
  //selectId: (object) => object.id, // id is the default
});

const getState = (state: RootState): RootState => state;
const initialState = adapter.getInitialState(initialSliceState);

// Thunk functions
/*
export const fetch = createAsyncThunk(
  'scufaults/fetch',
  async (SCU: OmniviaSCU) => {
    const date = utils.dateAddMonths(new Date(), -6);
    const dateInPast = utils.dateToIsoString_dateonly(date);

    const response = await dbAccess.loadData(dbAccessInfo, {
      id: SCU.id,
      filter: { dateRaised: { ge: dateInPast } },
    });
    return response;
  }
);*/

// Only pull active faults
export const fetch = createAsyncThunk(
  'scufaults/fetch',
  async (SCU: OmniviaSCU) => {
    console.info('scufaults/fetch: scu.id', SCU.id);
    const response = await dbAccess.loadData(dbAccessInfo, {
      omniviaFaultsLinkedSCUId: SCU.id,
      faultState: { eq: 'FAULT_ACTIVE' },
      limit: 1000,
    });

    return response;
  }
);

export const select = createAsyncThunk(
  'scufaults/select',
  async (fault: OmniviaFaults) => {
    const response = await dbAccess.select(dbAccessInfo, { id: fault.id });
    return response;
  }
);

export const update = createAsyncThunk(
  'scufaults/update',
  async (faultChanges: UpdateOmniviaFaultsInput) => {
    const updateOmniviaFaults = /* GraphQL */ `
      mutation UpdateOmniviaFaults(
        $input: UpdateOmniviaFaultsInput!
        $condition: ModelOmniviaFaultsConditionInput
      ) {
        updateOmniviaFaults(input: $input, condition: $condition) {
          id
          faultState
          faultType
          dateRaised
          dateCleared
          dateAlertLastGenerated
          deviceID
          faultSpecificText
          friendlyString
          clearedBy
          createdAt
          updatedAt
          expirationUnixTime
        }
      }
    `;

    const variables = {
      input: faultChanges,
    };

    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(updateOmniviaFaults, variables)
    )) as { data: { updateOmniviaFaults: OmniviaFaults } };

    return response.data.updateOmniviaFaults;
  }
);

const slice = createSlice({
  name: 'scufaults',
  initialState,
  reducers: {
    // eslint-disable-next-line
    clearSelected(state, action) {
      state.selectedId = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(update.fulfilled, (state, action) => {
      const fault = action.payload;
      state.entities[fault.id] = fault;
      emitEvent(
        systemEventTopics.FAULTS,
        systemEventStates.UPDATED,
        fault.id,
        true
      );
    });

    createExtraReducers(
      systemEventTopics.FAULTS,
      builder,
      fetch,
      select,
      adapter
    );
  },
});

export const { selectAll, selectById } = adapter.getSelectors<RootState>(
  (state: RootState) => state.scufaults
);

export const { clearSelected } = slice.actions;

export default slice.reducer;

export const getSelected = createSelector([getState], (state: RootState) => {
  return selectById(state, state.scufaults.selectedId);
});

export const getAll = createSelector([getState], (state: RootState) => {
  return selectAll(state);
});

export const getSlice = createSelector([getState], (state: RootState) => {
  return state.scufaults;
});
