import * as MsgDefines_DEVICES from './IoTMessageDefines_DEVICES';
import * as MsgDefines from './IoTMessageDefines';
import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
//import logger from 'common/logger';
import { Device } from 'components/installation/device';
import * as ManifestEnums from 'types/manifest-enums';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

export { teCLOUD_ERROR_CODES, getErrorText } from './IoTMessageDefines';
export {
  teDEVICE_TYPES,
  teDEVICE_MODELS,
  teBSI_LOCATIONS,
  teDEVICE_MODELS_HANDSET,
} from 'types/manifest-enums';
export type { tsCLOUD_MSG_DEVICES_ADD_RESP } from './IoTMessageDefines_DEVICES';

/**
 * Add a device to the SCU database.  To see the change issue a manifets upload
 * on completion
 * @param mac 64bit MAC address of the dveice you want to delete
 * @param parentID device ID of a parent, only used for triggers
 * @param unitId this is the room number for rooms , accesspoints and interfaces
 * @param deviceType device type as defined in types/manifest-enums.ts : teDEVICE_TYPES
 * @param hwmodel device HW model as defined in types/manifest-enums.ts : teDEVICE_MODELS
 * @param gwInterface the very first device added to the system must be a MAP/MLS or MAI which is nominated as the gateway interface
 * @param name the text that will be shown on the handste : Must be no more than 20 chars
 * @param userData  arbitrary data passed in the response event to allow user to link request and response
 * @returns
 */
export async function addDevice(
  mac: number,
  parentID: number,
  unitId: number,
  deviceType: ManifestEnums.teDEVICE_TYPES,
  hwmodel: ManifestEnums.teDEVICE_MODELS,
  gwInterface: boolean,
  name: string,
  location: number,
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_ADD_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_ADD_RESP;

  const msg: MsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_ADD_REQ = {
    mac: Device.MACaddrToString(mac, 10),
    parentID: parentID,
    unitId: unitId,
    type: deviceType,
    hwmodel: hwmodel,
    gwInterface: gwInterface,
    name: name,
    location: location,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    3000,
    'addDevice',
    userData
  );
}

/**
 * Delete a device from the SCU database.  To see the change issue a manifets upload
 * on completion
 *
 * @param mac 64bit MAC address of the dveice you want to delete
 * @param userData arbitrary data passed in the response event to allow user to link request and response
 * @returns
 */
export async function deleteDevice(
  mac: number,
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_DELETE_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_DELETE_RESP;

  const msg: MsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_DELETE_REQ = {
    mac: Device.MACaddrToString(mac, 10),
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    3000,
    'deleteDevice',
    userData
  );
}
