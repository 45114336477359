import React, { useEffect, useState } from 'react';
import Tile, { iTileEvent, contextButtonStyle } from '../../tile';
import { Text, TextInput, Button, Box, Tip } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest, teErrorCode } from '../../../installation/manifest';
import * as Wait from 'components/dialogues/waitDialogue';
import * as Icons from 'grommet-icons';
import { useWinstonLogger } from 'winston-react';
import { useDrop } from 'react-dnd';
import { DNDtypes } from 'types/dndtypes';
import useStateRef from 'react-usestateref';
import { iResidentGroup } from 'types/manifest-types';
import { Room } from 'components/installation/room';

const manifest = new Manifest();

// export const updateSelectedGroup = (groupId: number): void => {
//   selectedGroup = groupId;
// };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const ResidentGroups = (props: any): JSX.Element => {
  const logger = useWinstonLogger();

  const [changes, setChanges, changesRef] = useStateRef(false);

  const [residentGroups, setResidentGroups] = useState<
    iResidentGroup[] | undefined
  >([]);
  const [, SetChildEvent] = useState('');
  const [selectedGroup, setSelectedGroup, selectedRef] = useStateRef(-1);
  const [dps, setDps] = useState<Room[]>([]);
  const [groupName, setGroupName] = useState('');
  const [dataloaded, setDataloaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueText, setDialogueText] = useState('');

  const loadGroup = () => {
    //console.log('RG: loadgroup', props.group, selectedGroup);
    setDps(manifest.getRoomsInAGroup(props.group));
    setSelectedGroup(props.group);
    setDataloaded(true);
    setChanges(false);
    setGroupName(residentGroups ? residentGroups[props.group - 1]?.name : '');
    // console.log('RG:', props.group, manifest.getRoomsInAGroup(props.group));
  };

  const updateInfo = async () => {
    if (!changes) {
      // loadGroup();
      const rg = await manifest.getResidentGroups();
      setResidentGroups(rg);
      setGroupName(rg ? rg[selectedRef.current - 1]?.name : '');
      // console.log('RG:', rg, selectedRef.current);
    }
    setDataloaded(true);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: updateInfo,
      executeOnStartup: false,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: updateInfo,
      executeOnStartup: false,
    },
  ];

  const save = (): void => {
    logger.info('residentGroups.save');
    manifest.setGroupName(selectedGroup, groupName).then(() => {
      setDialogueText('Please wait.....');
      setSaving(true);
      setShowDialogue(true);

      // amend all the rooms to add them to the group
      //console.log(dps, selectedRef.current);
      dps.forEach((r) => {
        manifest.rooms
          .find((manifestRoom) => manifestRoom.ID == r.ID)
          ?.addToGroup(selectedRef.current);
      });

      const complete = (str: string) => {
        //console.log('RG: complete');
        setSaving(false);
        setChanges(false);
        setDialogueText(str);
      };

      let str = 'Save failed';
      manifest
        .save()
        .then((status) => {
          if (status === teErrorCode.E_OK) {
            str = 'Save Completed Successfully';
          } else if (status === teErrorCode.E_CONNECTION_FAIL) {
            str = 'Connection failed';
            logger.info('ResidentGroup.saveFailed', {
              status: status,
              error: {},
            });
          }
          complete(str);
        })
        .catch((error) => {
          console.error('Room Group save fail:', error);
          logger.info('RoomGroup.saveFailed', {
            status: teErrorCode.E_ERROR,
            error: error,
          });
          complete(str);
        });
    });
  };

  const configChanged = () => {
    return changesRef.current == true;
  };

  const waitProps: Wait.DialogueProps = {
    show: showDialogue,
    dialogueText: dialogueText,
    showSpinner: saving,
    showOkButton: !saving,
    okButtonPress: () => {
      setShowDialogue(false);
    },
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={configChanged() ? 'brand' : 'grey-4'}
      badge={configChanged()}
      tip="Save changes"
      disabled={configChanged() ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const AssignRooms = () => {
    const [{ isOver, canDrop }, drop] = useDrop({
      accept: DNDtypes.ROOM,
      drop: (item) => {
        //console.log('RG: drop', item, selectedRef.current);
        const room = (item as { dragitem: Room }).dragitem;
        // room.addToGroup(selectedRef.current);   // This is now done at the point of aving to ensure any new manifests do not trample
        dps.push(room);
        setChanges(true);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });
    const isActive = isOver && canDrop;
    // console.log('RG:', dps, dataloaded, changes);

    const removeRoom = (room: Room) => {
      setChanges(true);
      //console.log('RG: remove', room, selectedGroup);
      room.removeFromGroup(selectedGroup);
      setDps(manifest.getRoomsInAGroup(selectedGroup));
    };

    const removeAllRooms = () => {
      manifest.rooms.forEach((element) => {
        element.removeFromGroup(selectedGroup);
        setDps([]);
      });
      setChanges(true);
    };

    const addAllRooms = () => {
      manifest.rooms.forEach((element) => {
        if (!element.isInGroup(selectedGroup)) {
          element.addToGroup(selectedGroup);
          dps.push(element);
        }
      });
      setChanges(true);
    };

    const addCommunalRooms = () => {
      manifest.rooms.forEach((element) => {
        if (element.isCommunal() && !element.isInGroup(selectedGroup)) {
          element.addToGroup(selectedGroup);
          dps.push(element);
        }
      });
      setChanges(true);
    };

    const addResidentiallRooms = () => {
      manifest.rooms.forEach((element) => {
        if (!element.isCommunal() && !element.isInGroup(selectedGroup)) {
          element.addToGroup(selectedGroup);
          dps.push(element);
        }
      });
      setChanges(true);
    };
    return (
      <div ref={drop}>
        <Box>
          <Text weight="bold">The following rooms are in this group: - </Text>
        </Box>

        <Box
          direction="row"
          style={{ minHeight: '100px', width: '100vw' }}
          border="all"
          round={{ size: '15px' }}
          background={isActive ? 'white' : '#eee'}
          wrap={true}
          pad={{ top: '10px', left: '10px' }}
        >
          {dataloaded &&
            dps.map((m, index) => (
              <Box
                key={index}
                border="all"
                margin="3px"
                pad="3px"
                background={m.isCommunal() ? 'aqua' : 'blue'}
                color="white"
                height="30px"
                round={{ size: '5px' }}
              >
                <Tip content={<Text>{m.toolTip()}</Text>}>
                  <Box direction="row">
                    <Text weight="bold">{m.Name}</Text>
                    <Box margin={{ top: '2px', left: '4px' }}>
                      <Icons.Close
                        size="16"
                        cursor="pointer"
                        onClick={() => removeRoom(m)}
                      />
                    </Box>
                  </Box>
                </Tip>
              </Box>
            ))}
        </Box>
        <Box direction="column">
          <Box margin="16px">
            <Text weight="bold">
              Drag and drop rooms from the left menu into the box above or....
            </Text>
          </Box>
          <Box direction="row" justify="center">
            <Button
              margin={{ top: '0px', left: '0px' }}
              label="Add All Rooms"
              size="medium"
              plain={false}
              pad="4px"
              onClick={() => addAllRooms()}
            />

            <Button
              margin={{ top: '0px', left: '8px' }}
              label="Add All Communal Rooms"
              size="medium"
              plain={false}
              pad="4px"
              onClick={() => addCommunalRooms()}
            />

            <Button
              margin={{ top: '0px', left: '8px' }}
              label="Add All Residential Rooms"
              size="medium"
              plain={false}
              pad="4px"
              onClick={() => addResidentiallRooms()}
            />

            <Button
              margin={{ top: '0px', left: '8px' }}
              label="Remove All Rooms"
              size="medium"
              plain={false}
              pad="4px"
              onClick={() => removeAllRooms()}
            />
          </Box>
        </Box>
      </div>
    );
  };

  const title = 'Room Group: ' + groupName;

  useEffect(() => {
    loadGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.group]);

  useEffect(() => {
    updateInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tile
      title={title}
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={waitProps}
      ContextButtons={ContextButtons}
      // menuProps={menuItems}
    >
      <>
        {dataloaded && (
          <>
            <Box direction="column" pad="xsmall" justify="center">
              <Box direction="row" width="400px" margin={{ bottom: '20px' }}>
                <Text
                  weight="bold"
                  style={{ width: '140px', marginTop: '4px' }}
                >
                  Group Name
                </Text>
                <TextInput
                  size="small"
                  width="250px"
                  value={groupName}
                  style={{ padding: '6px' }}
                  // disabled={_CurrentCallId.length > 1}
                  onChange={(event) => {
                    setGroupName(event.target.value);
                    setChanges(true);
                  }}
                />
              </Box>
              <Box direction="row" pad="xsmall">
                <Box
                  direction="column"
                  pad="xsmall"
                  justify="start"
                  height="800px"
                  overflow="auto"
                >
                  <AssignRooms />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </>
    </Tile>
  );
};

export default ResidentGroups;
