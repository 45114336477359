import { teCLOUD_MESSAGE_TYPES } from './IoTMessageDefines';
import {
  teCLOUD_MSG_TY_CFG_QUERY,
  tsCLOUD_MSG_QUERY_CFG_RUNSQL_REQ,
} from './IoTMessageDefines_CONFIG_QUERY';
import { IoTMessageHandler, Message } from './IotMessageHandler';
import { SCUIoTMessaging } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
import logger from 'common/logger';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

export interface CompleteCallbackFunction {
  (userData: unknown, response: Message, error: string): void;
}

export class SCUSQLInjection {
  private static _Instance: SCUSQLInjection;
  public static get Instance(): SCUSQLInjection {
    if (SCUSQLInjection._Instance) {
      return SCUSQLInjection._Instance;
    } else {
      return new SCUSQLInjection();
    }
  }

  constructor() {
    if (SCUSQLInjection._Instance) {
      return SCUSQLInjection._Instance;
    }
    SCUSQLInjection._Instance = this;
  }

  async forceBackup(
    repopulateCaches: boolean,
    forceUpload?: boolean
  ): Promise<void> {
    await this.sendSQL(
      'select * from SYSTEM_DB_VERSION',
      true,
      repopulateCaches,
      forceUpload !== undefined ? forceUpload : true,
      null
    );
  }

  async checkConnection(): Promise<boolean> {
    let status = false;
    try {
      await this.sendSQL(
        'select * from SYSTEM_DB_VERSION',
        false,
        false,
        false,
        null,
        5000
      );
      status = true;
    } catch (e) {
      logger.info('IoT.SQL.connectionFail');
      console.error('SQL checkConnection failed: ', e);
      status = false;
    }

    return status;
  }

  /**
   * Execute a SQL string on the site database.
   * When performing updates/deletes/inserts on devices it will be necessary to set the deviceChangedRepopulateCaches
   * to true.  This will instruct the necessary modules that the device information has changed.  This will be only needed
   * in the following situations:
   *  - Devices are added or deleted
   *  - Device personality has changed : i.e. a MAI is set as a MPI
   *  - Device has been enabled or disabled as a ethernet gateway cluster
   *  - Device MAC addresses have changed
   *
   *  When making a update, ensure you set forceBAckup so the database is copied to the SD card
   *
   *  If the update consists of multiple SQL statements and hence multiple calls to sendSQL, set the forceBAckup or
   *  deviceChangedRepopulateCaches on the last call.
   *
   *  If a E_CLOUD_MSG_QUERY_CFG_RUNSQL_RESP response is not received within 10 seconds then the callback will be called with
   *  the error set as 'timeout'.
   *
   *  Possible error codes set in the callback:
   *     - 'ok'
   *     - 'invalid sql'
   *     - 'timeout'
   *
   * @param {string} SQL - SQL statement to run, can be an select,update,delete or insert
   * @param {boolean} forceBackup - Request the DB to be backed up after the change
   * @param {boolean} deviceChangedRepopulateCaches - Reload audio routing tables and devices caches
   * @param {boolean} actionManifestUpload - Action a manifest upload after running the SQL
   * @param {object} userData - data that gets sent back in the completeCb to allow user to tie up the request/response
   */
  async sendSQL(
    SQL: string,
    forceBackup: boolean,
    deviceChangedRepopulateCaches: boolean,
    actionManifestUpload: boolean,
    userData: unknown,
    timeout?: number
  ): Promise<iIotResponseEventDetail> {
    console.info('sendSQL:', SQL);

    if (!timeout) timeout = 10000;

    const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CONFIGURATION_QUERY;
    const opcode = teCLOUD_MSG_TY_CFG_QUERY.E_CLOUD_MSG_QUERY_CFG_RUNSQL_REQ;
    const rplyopcode =
      teCLOUD_MSG_TY_CFG_QUERY.E_CLOUD_MSG_QUERY_CFG_RUNSQL_RESP;

    const msg: tsCLOUD_MSG_QUERY_CFG_RUNSQL_REQ = {
      forceBackup: forceBackup,
      deviceChangedRepopulateCaches: deviceChangedRepopulateCaches,
      actionManifestUpload: actionManifestUpload,
      sql: SQL,
    };

    const transId = MessageHandler.getNextGlobalTransactionId();

    logger.info('IoT.SQL.send', { sql: SQL, transId: transId });

    return await SCUMessaging.sendMessage(
      type,
      opcode,
      rplyopcode,
      transId,
      msg,
      timeout,
      'sendSQL',
      userData
    );
  }
}

export default SCUSQLInjection;
