/* MESSAGE TYPES */
export enum teCLOUD_MESSAGE_TYPES {
  E_CLOUD_TYPE_DEVICES = 100,
  E_CLOUD_TYPE_EVENTS,
  E_CLOUD_TYPE_CONFIGURATION_QUERY,
  E_CLOUD_TYPE_SYSTEM,
  E_CLOUD_TYPE_TEST,
  E_CLOUD_TYPE_CALL,
  E_CLOUD_TYPE_AUDIT,
  E_CLOUD_TYPE_MAX,
}

/* Global errorcodes */
export enum teCLOUD_ERROR_CODES {
  E_CLOUD_ERROR_NONE = 0,
  E_CLOUD_ERROR_INVALID_PASSCODE,
  E_CLOUD_ERROR_NOT_IN_DB,
  E_CLOUD_ERROR_TIMEOUT,
  E_CLOUD_ERROR_INVALID_DEVICE,
  E_CLOUD_ERROR_WUT_NOT_IN_DB,
  E_CLOUD_ERROR_INVALID_PARAMETER,
  E_CLOUD_ERROR_DISCOVERY_IN_PROGRESS,
  E_CLOUD_ERROR_DISCOVERY_PAUSED,
  E_CLOUD_ERROR_DISCOVERY_IDLE,
  E_CLOUD_ERROR_NO_GWICONFIGURED,
  E_CLOUD_ERROR_OAD_ACTIVE,
  E_CLOUD_ERROR_GENERAL,
  E_CLOUD_ERROR_UNIT_ID_IN_USE,
  E_CLOUD_ERROR_INVALID_UNIT_ID,
  E_CLOUD_ERROR_DEVICE_ALREADY_IN_DB,
  E_CLOUD_ERROR_NOT_AN_OMNIVIA_SYSTEM,
  E_CLOUD_ERROR_RESOURCE_IN_USE,
  E_CLOUD_ERROR_INVALID_STATE,
  E_CLOUD_ERROR_QUEUED,
}

export const teCLOUD_ERROR_CODES_STRINGS = [
  'No Error',
  'Invalid passcode',
  'The device is not in the configuration database',
  'Timeout',
  'Invalid device type',
  'Wake up transmitter not in the DB',
  'Inavlid parameter',
  'Discovery in progress',
  'Discovery paused',
  'Discovery idle',
  'No gateway interface configured',
  'Upgrade already in porgress',
  'General Error',
  'The Unit ID/Room number is already in use',
  'Invalid Unit ID/Room number',
  'The device is already in the database',
  'Not an omnivia system',
  'Resource in use',
  'The resource in not in the correct state',
  'Requested Queued',
];

export const getErrorText = (err: teCLOUD_ERROR_CODES | undefined): string => {
  if (err === undefined) err = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_GENERAL;
  if (err >= teCLOUD_ERROR_CODES_STRINGS.length)
    err = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_GENERAL;

  return teCLOUD_ERROR_CODES_STRINGS[err];
};
