import { ALARMS_TYPE_DESCRIPTIONS } from 'common/alarmTypesTextFiltered/alarmText';
import { teDEVICE_MODELS, teDEVICE_TYPES } from 'types/manifest-enums';
import { Equipment, OUTPUTMETHOD, System } from 'types/product-types';
import { ProductCategory } from '../types/product-types';

/** The product defintion data */

/** tODO: refactror to remove baseNAme and baseDesignator, simplify name and designator fields to remove colour and add a colour field */

export const products: Array<Equipment> = [
  {
    productCode: 'PROD-3164',
    name: 'Visual Alert Pullcord',
    designator: 'VPC',
    voltage: 12,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: true,
    requiredOthers: undefined,
    MAIRequiredNumber: 1,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3143',
    assignable: true,
    name: 'Video Room Unit',
    designator: 'VRU',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.ROOM,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V3,
    requiredOthers: [{ productCode: 'PROD-3167', qty: 1 }],
    cat5Required: true,
    inputs: [
      {
        inputname: 'input1',
        mandatory: false,
        wiring: 'N/O',
        alarmType: ALARMS_TYPE_DESCRIPTIONS[0],
        minDuration: 60,
      },
      {
        inputname: 'input2',
        mandatory: false,
        wiring: 'N/O',
        alarmType: ALARMS_TYPE_DESCRIPTIONS[0],
        minDuration: 60,
      },
      {
        inputname: 'input3',
        mandatory: false,
        wiring: 'N/O',
        alarmType: ALARMS_TYPE_DESCRIPTIONS[0],
        minDuration: 60,
      },
    ],
    outputs: [
      {
        outputname: '12V permanent',
        mandatory: false,
        method: OUTPUTMETHOD.TWELVEVOLTPERMANENT,
      },
      {
        outputname: '12V pulsed',
        mandatory: false,
        method: OUTPUTMETHOD.TWELVEVOLTPULSED,
      },
      {
        outputname: 'Out2',
        mandatory: false,
        method: OUTPUTMETHOD.RELAYNO,
      },
    ],
  },
  {
    productCode: 'PROD-3143-CAM',
    assignable: true,
    name: 'Video Room Unit with Camera',
    designator: 'VRUc',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.ROOM,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V3,
    requiredOthers: [{ productCode: 'PROD-3167', qty: 1 }],
    cat5Required: true,
    inputs: [
      {
        inputname: 'input1',
        mandatory: false,
        wiring: 'N/O',
        alarmType: ALARMS_TYPE_DESCRIPTIONS[0],
        minDuration: 60,
      },
      {
        inputname: 'input2',
        mandatory: false,
        wiring: 'N/O',
        alarmType: ALARMS_TYPE_DESCRIPTIONS[0],
        minDuration: 60,
      },
      {
        inputname: 'input3',
        mandatory: false,
        wiring: 'N/O',
        alarmType: ALARMS_TYPE_DESCRIPTIONS[0],
        minDuration: 60,
      },
    ],
    outputs: [
      {
        outputname: '12V permanent',
        mandatory: false,
        method: OUTPUTMETHOD.TWELVEVOLTPERMANENT,
      },
      {
        outputname: '12V pulsed',
        mandatory: false,
        method: OUTPUTMETHOD.TWELVEVOLTPULSED,
      },
      {
        outputname: 'Out2',
        mandatory: false,
        method: OUTPUTMETHOD.RELAYNO,
      },
    ],
  },
  {
    productCode: 'PROD-3167',
    name: 'Video Room Unit Backbox',
    designator: 'VBB',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.ROOM,
    firstFix: true,
    designItem: false,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3208',
    name: 'Omnivia System Control Unit Rack',
    designator: 'SCU',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_GATEWAY,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V2,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3168',
    name: 'Carelocate gateway controller',
    designator: 'GWC',
    voltage: 240,
    powerConsumptionmA: 0,
    system: System.CARELOCATE,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: true,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3069',
    name: 'Digital Door Panel',
    designator: 'DDP',
    voltage: 24,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.DOORENTRY,
    firstFix: false,
    designItem: true,
    requiredOthers: [{ productCode: 'PROD-3179', qty: 1 }],
    MAIRequiredNumber: 2,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3179',
    name: 'Digital Door Panel Backbox',
    designator: 'DBB',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.DOORENTRY,
    firstFix: true,
    designItem: false,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3064',
    assignable: true,
    name: 'Gateway Interface',
    designator: 'GWI',
    voltage: 24,
    powerConsumptionmA: 0,
    system: System.CARELOCATE,
    category: ProductCategory.WIRELESSACCESS,
    firstFix: false,
    designItem: true,
    requiredOthers: undefined,
    MAIRequiredNumber: 2,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3134-W',
    assignable: true,
    name: 'Multi-Access Point (White)',
    baseName: 'Multi-Access Point',
    designator: 'MAPw',
    baseDesignator: 'MAP',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.WIRELESSACCESS,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V2,
    cat5Required: true,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3134-B',
    assignable: true,
    name: 'Multi-Access Point (Black)',
    baseName: 'Multi-Access Point',
    designator: 'MAPb',
    baseDesignator: 'MAP',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.WIRELESSACCESS,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V2,
    cat5Required: true,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3142-W',
    assignable: true,
    name: 'Multi-Access Point LS (White)',
    baseName: 'Multi-Access Point LS',
    designator: 'MLSw',
    baseDesignator: 'MLS',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.WIRELESSACCESS,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V3,
    requiredOthers: undefined,
    cat5Required: true,
    cat5Ports: 1,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3142-B',
    assignable: true,
    name: 'Multi-Access Point LS (Black)',
    baseName: 'Multi-Access Point LS',
    designator: 'MLSb',
    baseDesignator: 'MLS',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.WIRELESSACCESS,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V3,
    requiredOthers: undefined,
    cat5Required: true,
    cat5Ports: 1,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'WH-RP-ETSI',
    assignable: true,
    name: 'Repeater',
    designator: 'REP',
    voltage: 24,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.WIRELESSACCESS,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    obsolete: true,
    replaceProductCode: 'PROD-3142-B',
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3151',
    name: 'Battery Backed Supply 48V',
    designator: 'PSU48',
    voltage: 240,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.POWER,
    firstFix: false,
    designItem: true,
    requiredOthers: [{ productCode: 'P10825', qty: 4 }],
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3162',
    name: 'POE distribution rack 40way',
    designator: 'POE',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: true,
    requiredOthers: [{ productCode: 'PROD-3185', qty: 2 }],
    cat5Ports: 40,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3210',
    name: 'Street POE distribution rack 16way',
    designator: 'POEst',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: true,
    requiredOthers: [
      { productCode: 'PROD-3185', qty: 2 },
      { productCode: 'PROD-3134-B', qty: 1 },
      { productCode: 'PROD-3151', qty: 1 },
      { productCode: 'P10825', qty: 4 },
    ],
    cat5Ports: 16,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3169',
    name: 'Call Point - 1 button',
    designator: 'OBT',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.LIFT,
    firstFix: false,
    designItem: true,
    requiredOthers: [{ productCode: 'PROD-3215', qty: 1 }],
    MAIRequiredNumber: 1,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3215',
    name: 'Call Point BackBox Assembly',
    designator: 'CBB',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.LIFT,
    firstFix: true,
    designItem: false,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3147-W',
    assignable: true,
    name: 'Multi-Access Device Interface (White)',
    baseName: 'Multi-Access Device Interface',
    designator: 'MAIw',
    baseDesignator: 'MAI',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.WIRELESSACCESS,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_APEX_DOOR_PANEL_AND_INTERFACES,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V3,
    requiredOthers: undefined,
    cat5Required: true,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3147-B',
    assignable: true,
    name: 'Multi-Access Device Interface (Black)',
    baseName: 'Multi-Access Device Interface',
    designator: 'MAIb',
    baseDesignator: 'MAI',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.WIRELESSACCESS,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_APEX_DOOR_PANEL_AND_INTERFACES,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V3,
    requiredOthers: undefined,
    cat5Required: true,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3153',
    name: 'Wall Panic Alarm',
    designator: 'WPA',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_PANIC,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3155',
    name: 'Room 12V supply (Battery Backed)',
    designator: 'PSU12',
    voltage: 240,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.POWER,
    firstFix: false,
    designItem: true,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3156',
    name: 'Wireless Pullcord',
    designator: 'WPC',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_PULLCORD,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3157',
    name: 'Wired Basic Pullcord',
    designator: 'PCD',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: true,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3152',
    assignable: true,
    name: 'Audio only Room Unit (Black)',
    baseName: 'Audio only Room Unit',
    designator: 'RUb',
    baseDesignator: 'RU',
    voltage: 24,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.ROOM,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V2,
    requiredOthers: [{ productCode: 'PROD-3178', qty: 1 }],
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3178',
    name: 'Audio only Room Unit Backbox (Black)',
    baseName: 'Audio only Room Unit Backbox',
    designator: 'RBBb',
    baseDesignator: 'RBB',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.ROOM,
    firstFix: true,
    designItem: false,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3176',
    assignable: true,
    name: 'Audio only Room Unit (White)',
    baseName: 'Audio only Room Unit',
    designator: 'RUw',
    baseDesignator: 'RU',
    voltage: 24,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.ROOM,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V2,
    requiredOthers: [{ productCode: 'PROD-3177', qty: 1 }],
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3177',
    name: 'Audio only Room Unit Backbox (White)',
    baseName: 'Audio only Room Unit Backbox',
    designator: 'RBBw',
    baseDesignator: 'RBB',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.ROOM,
    firstFix: true,
    designItem: false,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'ENTROTEC',
    name: 'Obsolete old Entrotec unit',
    assignable: true,
    designator: 'ENT',
    voltage: 24,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.ROOM,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3165',
    name: 'Wireless Door Sensor',
    designator: 'WDS',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_WIM,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3211',
    name: 'Over door light',
    designator: 'ODL',
    voltage: 24,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: true,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3158',
    name: 'Fall Detector Pendant',
    designator: 'FDP',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_FALL,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3159',
    name: 'CareLocate Pendant',
    designator: 'CLP',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_PENDANT,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3204',
    name: 'Universal sensor',
    designator: 'UVS',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_WIM,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V2,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3204',
    name: 'Universal sensor:EPILEPSY', // will never be sold
    designator: 'UVS',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_WIM,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V3,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3185',
    name: 'Ethernet Patch Kit',
    designator: 'PATCH',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: false,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3146',
    name: 'MAP Pole Mount Kit',
    designator: 'POLL',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: true,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'P10825',
    name: '12V 18Ah lead acid battery',
    designator: 'BAT',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.OMNIVIA,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: false,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3202',
    name: 'SIP Door Panel Surface Mount',
    designator: 'SDP',
    voltage: 48,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.DOORENTRY,
    firstFix: false,
    designItem: true,
    requiredOthers: undefined,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3078',
    name: 'Remote Door Control',
    designator: 'RDC',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_DOOR_ENTRY,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'PROD-3161',
    name: 'Inspire Staff HAndset',
    designator: 'HS',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_WARDEN_HANDSET,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V2,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'OUTPUT',
    name: 'General Output',
    designator: 'OUTP',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.THIRDPARTYOUTPUT,
    firstFix: false,
    designItem: true,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_MAX,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_MAX,
    MAPRequired: true,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T1',
    name: 'Tunstall Personal Trigger',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PERSONAL_TRIGGER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T2',
    name: 'Tunstall Personal Trigger ALB',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PERSONAL_AUTO_LB,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },

  {
    productCode: 'T3',
    name: 'Tunstall Personal Trigger AP',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PERSONAL_AUTO_PRESENCE,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },

  {
    productCode: 'T4',
    name: 'Tunstall Temperature Extremes Sensor',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_TEMP_EXTREME,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T5',
    name: 'Tunstall Flood Detector',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_FLOOD,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T6',
    name: 'Tunstall Carbon Monoxide Detector',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_CARBON_MONOX,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T7',
    name: 'Tunstall Smoke Detector',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_SMOKE,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T8',
    name: 'Tunstall Door Contact',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_DOOR_CONTACT,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },

  {
    productCode: 'T9',
    name: 'Tunstall Pullcord',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PULLCORD,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T10',
    name: 'Tunstall Enuresis Sensor',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_ENURESIS,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T11',
    name: 'Tunstall Bed/Chair Occupancy Sensor',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_BED_CHAIR,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T12',
    name: 'Tunstall Medication Dispenser',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_MEDICATION_DISPENSER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T13',
    name: 'Tunstall Epilepsy Sensor',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_EPILEPSY,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },

  {
    productCode: 'T14',
    name: 'Wireless Call Terminal',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_WIRELESS_CALL_TERMINAL,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T15',
    name: 'Tunstall Fall Detector',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_FALL,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T16',
    name: 'Tunstall PIR (Standard)',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PIR,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T17',
    name: 'Tunstall Carer',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_CARER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T18',
    name: 'Tunstall Property Exit Sensor',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PROPERTY_EXIT,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T19',
    name: 'Tunstall Arm/Disarm',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_INTRUDER_ARMDISARM,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T20',
    name: 'Tunstall Panic/Bogus Caller',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PANIC_BOGUSCALLER,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'T21',
    name: 'Tunstall Natural Gas Detector',
    designator: '869',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.PERIPHERAL_TUNSTALL,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_NATURAL_GAS,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'GWI',
    name: 'GWI Data',
    designator: 'GWI',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_GATEWAY,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
  {
    productCode: 'GWI',
    name: 'GWI Audio',
    designator: 'GWI',
    voltage: 0,
    powerConsumptionmA: 0,
    system: System.ALL,
    category: ProductCategory.SYSTEM,
    firstFix: false,
    designItem: false,
    deviceType: teDEVICE_TYPES.DEVICE_TYPE_AUDIO_ADAPTOR,
    deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
    requiredOthers: undefined,
    inputs: [],
    outputs: [],
  },
];
