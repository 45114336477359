import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  emitEvent,
  systemEventStates,
  systemEventTopics,
} from './eventDispatcher';
import { getJSON } from 'common/S3Bucket';
import { RootState } from './store';
import { iSliceState } from 'store/commonSlice';
import { WritableDraft } from 'immer/dist/internal';
import * as _ from 'lodash';
import * as IoTMessageDefines from 'common/IoT/IoTMessageDefines';
import * as MsgDefines_System from 'common/IoT/IoTMessageDefines_SYSTEM';
import { SCUIoTMessaging } from 'common/IoT/SCUIoTMessaging';
import * as IoTMsgHandler from 'common/IoT/IotMessageHandler';

let subscribed = false;

export const TABLE_IDS = new Map<string, string>([
  ['ALARM_TYPES', 'ID'],
  ['CAMERA_CONFIG', 'ID'],
  ['CARE_GROUP', 'ID'],
  ['CARE_SEQUENCE', 'ID'],
  ['CARE_SEQUENCE_INFO', 'ID'],
  ['DEVICE_LIST', 'ID'],
  ['DEVICE_NEIGHBOUR', 'ID'],
  ['DOOR_PANEL', 'ID'],
  ['ENDPOINT_CC_DTMF', 'ID'],
  ['ENDPOINT_CC_IP', 'ID'],
  ['ENDPOINT_CC_SIP', 'ID'],
  ['ENDPOINT_HANDSET', 'ID'],
  ['ENDPOINT_POTS', 'ID'],
  ['ENDPOINT_VOIP', 'ID'],
  ['ENUM_TYPES_ALARM', 'ID'],
  ['ENUM_TYPES_DESTINATION', 'ID'],
  ['ENUM_TYPES_DEVICE', 'ID'],
  ['GROUP_HANDSET', 'ID'],
  ['GROUP_VOIP', 'ID'],
  ['INSTALLER_SHELL', 'ID'],
  ['LOCATABILITY', 'ID'],
  ['OAD', 'DeviceID'],
  ['RESIDENT', 'ID'],
  ['ROUTER', 'ID'],
  ['ROUTING_EVENT', 'ID'],
  ['ROUTING_RESOURCE', 'ID'],
  ['SCHEDULES', 'ID'],
  ['SYSTEM_DB_VERSION', 'ID'],
  ['SYSTEM_PARAMETERS', 'ID'],
  ['TEXT', 'ID'],
  ['TRIGGER_LEVEL', 'ID'],
  ['CLOUD_META_DATA', 'ID'],
]);

export interface iManifestState extends iSliceState {
  manifest: unknown;
}

const initialState: iManifestState = {
  dataLoaded: false,
  loadStatus: 'idle',
  selectStatus: 'idle',
  selectedId: '',
  manifest: {},
};

const getState = (state: RootState): RootState => state;

export const getSlice = createSelector([getState], (state) => {
  return state.manifest;
});

function subscribeToNewEvents(): void {
  if (subscribed) {
    return;
  }

  subscribed = true;

  console.info(' manaifest subscribeToNewEvents');

  SCUIoTMessaging.Instance.registerMessageTypeCallback(
    IoTMessageDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM,
    (msg: IoTMsgHandler.Message) => {
      if (
        msg.type ==
          IoTMessageDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM &&
        msg.opcode ==
          MsgDefines_System.teCLOUD_MSG_TY_SYSTEM
            .E_CLOUD_MSG_SYSTEM_MANIFEST_UPLOAD_RESP
      ) {
        // The SCU has uploadewd a new
        console.info('The SCU has just uploaded a new manifest');

        emitEvent(
          systemEventTopics.MANIFEST,
          systemEventStates.REFRESHNEEDED,
          msg.srcid,
          true
        );
      }
    },
    null
  );
}

// Thunk functions
export const fetch = createAsyncThunk(
  'manifest/fetch',
  async (SCUSerialNumber: string) => {
    console.info('downloading manifest for :', SCUSerialNumber);

    const document_json = await getJSON(
      `db_manifests/${SCUSerialNumber}/dbManifest.json`
    );
    console.info('manifest download complete for :', SCUSerialNumber);
    console.log(document_json);
    subscribeToNewEvents();
    return document_json;
  }
);

function updateManifestTable(
  state: WritableDraft<iManifestState>,
  changeDesc: { tableName: string; change: unknown }
) {
  const storeObj = state.manifest as unknown;
  const changeObj = changeDesc.change as unknown[];
  const idKey = TABLE_IDS.get(changeDesc.tableName);
  let changed = false;

  const table = _.get(storeObj, changeDesc.tableName, undefined) as unknown[];
  if (table == undefined) {
    console.error('No table specified');
    return;
  }
  if (!Array.isArray(table)) {
    console.error('table is not an array');
    return;
  }

  for (const changeIndex in changeObj) {
    const change = changeObj[changeIndex];
    const id = _.get(change, `${idKey}`, undefined);

    for (const index in table) {
      const row = table[index] as unknown;
      const rowid = _.get(row, `${idKey}`, undefined);
      if (rowid === id) {
        if (!_.isEqual(row, change)) {
          // merge the changes instead of overwriting so only the changes can be sent instead of the full row
          _.merge(row, change);
          changed = true;
          /*
          const obj = _.set(
            state.manifest as any,
            `${changeDesc.tableName}[${index}]`,
            change
          );*/
        }
        break;
      }
    }
  }

  if (changed) {
    console.info('Update manifest, change:', changeObj);
  }
}

function deleteRowManifestTable(
  state: WritableDraft<iManifestState>,
  changeDesc: { tableName: string; change: unknown }
) {
  const storeObj = state.manifest as unknown;
  const changeObj = changeDesc.change as unknown[];
  const idKey = TABLE_IDS.get(changeDesc.tableName);

  const table = _.get(storeObj, changeDesc.tableName, undefined) as unknown;
  if (table == undefined) return;
  if (!Array.isArray(table)) return;

  for (const changeIndex in changeObj) {
    const change = changeObj[changeIndex];
    const id = _.get(change, `${idKey}`, undefined);

    for (const index in table) {
      const row = table[index] as unknown;
      const rowid = _.get(row, `${idKey}`, undefined);
      if (rowid === id) {
        console.info(`Removed index ${index} row:`, row);
        _.pullAt(table, [parseInt(index)]);
        return;
      }
    }
  }
}

function insertRowManifestTable(
  state: WritableDraft<iManifestState>,
  changeDesc: { tableName: string; change: unknown }
) {
  const storeObj = state.manifest as unknown;
  const changeObj = changeDesc.change as unknown[];
  const idKey = TABLE_IDS.get(changeDesc.tableName);

  const table = _.get(storeObj, changeDesc.tableName, undefined) as unknown;
  if (table == undefined) return;
  if (!Array.isArray(table)) return;

  for (const changeIndex in changeObj) {
    // eslint-disable-next-line no-unused-vars,   @typescript-eslint/ban-types
    const change = changeObj[changeIndex] as object;
    console.info(
      `idKey:${idKey} Index:${changeIndex} change:${JSON.stringify(change)} `
    );
    table.push(_.cloneDeep(change));
  }

  console.info(
    `manifest table:`,
    JSON.stringify(_.get(storeObj, changeDesc.tableName, undefined))
  );
}

const slice = createSlice({
  name: 'manifest',
  initialState,
  reducers: {
    // eslint-disable-next-line
    setLoading(state, action) {
      state.loadStatus = 'loading';
    },

    update(
      state,
      action: PayloadAction<{
        tableName: string;
        change: unknown;
      }>
    ) {
      updateManifestTable(state, action.payload);
    },

    deleteRow(
      state,
      action: PayloadAction<{
        tableName: string;
        change: unknown;
      }>
    ) {
      deleteRowManifestTable(state, action.payload);
    },

    insertRow(
      state,
      action: PayloadAction<{
        tableName: string;
        change: unknown;
      }>
    ) {
      insertRowManifestTable(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line
      .addCase(fetch.pending, (state, action) => {
        state.loadStatus = 'loading';
        state.dataLoaded = false;
        emitEvent(
          systemEventTopics.MANIFEST,
          systemEventStates.LOADING,
          null,
          true
        );
      })
      // eslint-disable-next-line
      .addCase(fetch.fulfilled, (state, action) => {
        state.manifest = action.payload;
        emitEvent(
          systemEventTopics.MANIFEST,
          systemEventStates.LOADED,
          null,
          true
        );
        emitEvent(
          systemEventTopics.MANIFEST,
          systemEventStates.DOWNLOADCOMPLETE,
          null,
          true
        );
        state.dataLoaded = true;
        state.loadStatus = 'idle';
      })
      // eslint-disable-next-line
      .addCase(fetch.rejected, (state, action) => {
        console.info('Manifest fetch error:', action);
        state.loadStatus = 'error';
        emitEvent(
          systemEventTopics.MANIFEST,
          systemEventStates.LOADERROR,
          null,
          true
        );
      });
  },
});

export const { setLoading, update, deleteRow, insertRow } = slice.actions;

export default slice.reducer;
