/** @module GWIConfigLayer */

import React, { useState, useEffect } from 'react';
//import _ from 'lodash';
import WaitDialogue, * as Wait from 'components/dialogues/waitDialogue';
import { Device } from 'components/installation/device';
import { Box, Layer, Button, Form, Text } from 'grommet';
import * as ManifestEnums from 'types/manifest-enums';
//import { useWinstonLogger } from 'winston-react';
import * as FormFields from 'components/forms/formFieldsOld';
import * as IoTDeviceAddDelete from 'common/IoT/IoTAddDelete';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';
import { teBSI_LOCATIONS } from 'types/manifest-enums';

const GWI_TYPE_OPTIONS = [
  { id: 0, name: 'MAP' },
  { id: 1, name: 'MLS' },
  { id: 2, name: 'MAI' },
];

const GWI_TYPES_MAPPING: {
  type: number;
  hwmodel: number;
}[] = [
  {
    type: ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER,
    hwmodel: ManifestEnums.teDEVICE_MODELS_ROUTER.DEVICE_MODEL_MAP,
  },
  {
    type: ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER,
    hwmodel: ManifestEnums.teDEVICE_MODELS_ROUTER.DEVICE_MODEL_MLS,
  },
  {
    type: ManifestEnums.teDEVICE_TYPES
      .DEVICE_TYPE_APEX_DOOR_PANEL_AND_INTERFACES,
    hwmodel:
      ManifestEnums.teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES.DEVICE_MODEL_MAI,
  },
];

interface iGwiFields {
  mac: string;
  gwiType: number;
  name: string;
}

const gwiFields: FormFields.iField[] = [
  {
    label: 'Hardware Type',
    id: 'gwiType',
    type: 'select',
    options: GWI_TYPE_OPTIONS,
  },
  {
    label: 'MAC Address',
    id: 'mac',
    type: 'text',
  },
  {
    label: 'Location Description',
    id: 'name',
    type: 'text',
  },
];

export type iGWILayerProps = {
  show: boolean;
  setComplete: (done: boolean) => void;
};

const GWIConfigLayer = (props: iGWILayerProps): JSX.Element => {
  const [data, setData] = useState<iGwiFields>({
    mac: '158d0000',
    gwiType: 0,
    name: 'Node 0',
  });

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();

  const addDevice = async (
    mac: number,
    unitId: number,
    name: string,
    type: IoTDeviceAddDelete.teDEVICE_TYPES,
    hwModel: IoTDeviceAddDelete.teDEVICE_MODELS.DEVICE_MODEL_V2,
    gwi: boolean
  ): Promise<void> => {
    setDialogueProps({
      show: true,
      dialogueText: 'Please wait...',
      showSpinner: true,
      showOkButton: false,
    });

    try {
      const resp = await IoTDeviceAddDelete.addDevice(
        mac,
        0,
        unitId,
        type,
        hwModel,
        gwi,
        name,
        teBSI_LOCATIONS.LOCATION_NO_LOCATION_INFORMATION
      );
      console.info('add device respone:', resp);

      const addRes = resp.msgHeader
        ?.msg as IoTDeviceAddDelete.tsCLOUD_MSG_DEVICES_ADD_RESP;
      if (
        addRes &&
        addRes.errorcode ==
          IoTDeviceAddDelete.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        console.info('Add device successfull');
        IoTSCUManifest.Instance.requestManifestUpload(null);
        setDialogueProps({
          show: true,
          dialogueText: 'Device added, synchronising...',
          showSpinner: true,
          showOkButton: false,
        });
      } else {
        setDialogueProps({
          show: true,
          dialogueText: `Failed to add the device, error:${IoTDeviceAddDelete.getErrorText(
            addRes?.errorcode
          )}`,
          showSpinner: false,
          showOkButton: true,
          okButtonPress: () => {
            setDialogueProps(undefined);
            props.setComplete(true);
          },
        });
      }

      // addDevice error codes:
      // E_CLOUD_ERROR_DEVICE_ALREADY_IN_DB
      // E_CLOUD_ERROR_INVALID_UNIT_ID
      // E_CLOUD_ERROR_UNIT_ID_IN_USE
      // E_CLOUD_ERROR_NOT_IN_DB // for triggers, parent id not in db
      // E_CLOUD_ERROR_GENERAL // db write issue

      // GWI:
      //   E_CLOUD_ERROR_NOT_AN_OMNIVIA_SYSTEM
      //   E_CLOUD_ERROR_INVALID_DEVICE  (wrong device type)
      //   E_CLOUD_ERROR_DEVICE_ALREADY_IN_DB
      //
      //
      //
    } catch (e) {
      setDialogueProps({
        show: true,
        dialogueText: 'No SCU response, it maybe offline',
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
          props.setComplete(true);
        },
      });
    }
  };

  useEffect(() => {
    if (props.show == false) {
      setDialogueProps(undefined);
    }
  }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.show) {
    return <></>;
  }

  return (
    <>
      {dialogueProps && <WaitDialogue {...dialogueProps} />}

      <Layer
        style={{ whiteSpace: 'pre-wrap' }}
        background="dialogBackground"
        modal={true}
      >
        <Box pad="small" gap="small" align="center">
          <Text textAlign="center">
            {`Please configure the node 0 device.
This can be any device of type MAP, MLS or a MAI.
Only use a MAI if not using it as a Door/Lift/Visual Pull Cord or CallPoint interface 
The unit ID for this device will be 9000`}
          </Text>

          <Form<iGwiFields>
            value={data}
            onChange={(nextValue) => {
              setData(nextValue);
            }}
            onSubmit={async () => {
              addDevice(
                Device.MACaddrStringToNumber(data.mac),
                9000,
                data.name,
                GWI_TYPES_MAPPING[data.gwiType].type,
                GWI_TYPES_MAPPING[data.gwiType].hwmodel,
                true
              );
            }}
          >
            <Box direction="column">
              <FormFields.AlignedFormFields displayfields={gwiFields} />
            </Box>

            <Box direction="row" gap="medium" justify="center">
              <Button
                label="OK"
                size="medium"
                plain={false}
                margin="small"
                type="submit"
              />

              <Button
                label="Cancel"
                size="medium"
                plain={false}
                margin="small"
                onClick={() => {
                  props.setComplete(true);
                }}
              />
            </Box>
          </Form>
        </Box>
      </Layer>
    </>
  );
};

export default GWIConfigLayer;
