//import { iGroupHandset, invalidID } from 'types/manifest-types';
import { teErrorCode } from './manifest';
//import { Handset } from './handset';
import { GroupHandset } from './groupHandset';
import { CareGroup } from './careGroup';
import { CareSequence } from './careSequence';
//import { iRoutingResource } from '../../types/manifest-types';
import { teDESTINATION_TYPES } from 'types/manifest-enums';
import { RoutingResource } from './routingResource';
import { EndpointCCDTMF } from './endpoint_cc_dtmf';
import { EndpointCCSIP } from './endpoint_cc_sip';
import { Room } from './room';
import { ALARM_TYPES } from 'types/event-enums';
import { getManifestInstance } from './manifestUtils';

/*
 * Modules Notes:
 * Don't forget that any data returned from the module is a referance.
 * Changing the data changes the data in the underlying access class instances and will be
 * saved when you call saveToSCU().
 *
 * When run saveToSCU(), the manifest utils comparez all the manifest table instances
 * to the manifest JSON downloaded from the backend. SQL updates are then created
 * and sent to the site.
 *
 * After the SQL has executed, the local manifest is updated, and manifest update events
 * will be automatically generated.
 *
 *
 * We are not using a global manifest referance to avoid circular referances.  Functions
 * get the instance when they need it
 */

export interface iCareGroupInfo {
  careGroup: CareGroup;
  sequences: CareSequence[]; // 10 Entries
}

export const getCareGroupSequenceId = (careGroupId: number): number => {
  const manifest = getManifestInstance();
  let careSeqId = -1;
  const careGroup = manifest.careGroups.find((e) => e.ID === careGroupId);
  if (careGroup) {
    careSeqId = careGroup.CareSequenceId;
  }

  return careSeqId;
};

export const getCareGroupIDFromSequenceId = (
  careSequenceId: number
): CareGroup | undefined => {
  const manifest = getManifestInstance();
  const careGroup = manifest.careGroups.find(
    (e) => e.CareSequenceId === careSequenceId
  );
  return careGroup;
};

/*
 * Returns 11 care groups.
 *
 * If the iCareGroupInfo.sequences[].ResourceID == CareSequence.CARE_SEQ_ENTRY_NOT_USED then it is set
 *
 * Use each iCareGroupInfo.sequences to find the destination name and type:
 *    sequences[].getDestinationDescription()
 *    sequences[].getDestinationType()
 *
 * To set each caregroup description call iCareGroupInfo.careGroup.setDescription()
 */
export const getAllCareGroups = (): iCareGroupInfo[] => {
  const manifest = getManifestInstance();
  const careGroupInfoArray: iCareGroupInfo[] = [];
  for (const group of manifest.careGroups) {
    const sequences = manifest.careSequences.filter(
      (e) => e.CareSequenceId == group.CareSequenceId
    );
    careGroupInfoArray.push({ careGroup: group, sequences: sequences });
  }
  return careGroupInfoArray;
};

/*
 * There are 10 handset groups, these will all be returned regardless if there
 * are handsets in them or not.
 *
 * Use the getAllHandsetsInAGroup on each entry to return the list of handsets in each group
 *
 * To change the a group name, call setDescription of the object
 */
export const getAllHandsetGroups = (): GroupHandset[] => {
  const manifest = getManifestInstance();
  return manifest.groupHandsets;
};

export interface iDestinationListItem {
  ID: string;
  type: teDESTINATION_TYPES;
  typeName: string;
  label: string;
  fullLabel: string;
}

export const getDestinationDetails = (
  dst: RoutingResource,
  handsets: GroupHandset[],
  EndpointCCDTMF: EndpointCCDTMF[],
  EndPointCCSIP: EndpointCCSIP[]
): iDestinationListItem => {
  if (dst) {
    const dstID = dst.DestinationId;
    let entryDescription = 'invalid';
    let typeName = '';

    switch (dst.DestinationType) {
      case teDESTINATION_TYPES.DESTINATION_TYPE_HANDSET:
        {
          const desc = handsets.find((e) => e.ID == dstID)?.Description;
          if (desc) {
            entryDescription = desc;
            typeName = 'Handset Group';
          }
        }
        break;

      case teDESTINATION_TYPES.DESTINATION_TYPE_CC_DTMF:
        {
          const desc = EndpointCCDTMF.find((e) => e.ID == dstID)?.Description;
          if (desc) {
            entryDescription = desc;
            typeName = 'Analog ARC';
          }
        }
        break;

      case teDESTINATION_TYPES.DESTINATION_TYPE_CC_IP_BS8521:
        {
          const desc = EndPointCCSIP.find((e) => e.ID == dstID)?.Description;
          if (desc) {
            entryDescription = desc;
            typeName = 'IP ARC';
          }
        }
        break;

      case teDESTINATION_TYPES.DESTINATION_TYPE_RESIDENT:
        entryDescription = 'UnSet';
        break;
    }
    if (dst.DestinationType != teDESTINATION_TYPES.DESTINATION_TYPE_RESIDENT)
      return {
        ID: dst.ID.toString(),
        type: dst.DestinationType,
        typeName: typeName,
        label: entryDescription,
        fullLabel: `(${typeName}) ${entryDescription}`,
      };
  }

  return {
    ID: '-1',
    type: teDESTINATION_TYPES.DESTINATION_TYPE_MAX,
    typeName: 'invalid',
    label: 'invalid',
    fullLabel: 'invalid',
  };
};

export const getFullDestinationList = (): iDestinationListItem[] => {
  const manifest = getManifestInstance();
  const destinations: iDestinationListItem[] | undefined = [];

  manifest.routingResources.forEach((dst) => {
    if (dst) {
      const d = getDestinationDetails(
        dst,
        manifest.groupHandsets,
        manifest.endpointCCDTMF,
        manifest.endPointCCSIP
      );

      if (d.ID != '-1') destinations.push(d);
    }
  });

  return destinations;
};

// Get a care sequence object from the manifest
export const getCareSequence = (ID: number): CareSequence | undefined => {
  const manifest = getManifestInstance();
  return manifest.careSequences.find((s) => s.ID == ID);
};

/**
 * Return a list of handsets that are in the group
 * @param group
 * @returns List of Handset objects in that group
 */

// Not used

// export const getAllHandsetsInAGroup = (group: GroupHandset): Handset[] => {
//   const hsIDs = group.getHandsetsInGroup();
//   const handsetArray: Handset[] = [];
//   for (const hsID of hsIDs) {
//     const hsObject = manifest.handsets.find((e) => e.ID == hsID);
//     if (hsObject) {
//       handsetArray.push(hsObject);
//     } else {
//       console.error('Handset ${hsID} not found in the manifest');
//     }
//   }
//   return handsetArray;
// };

// Not used
// export const addHandsetsToAGroup = (group: GroupHandset, hs: Handset) => {
//   group.addHandsettoGroup(hs.ID, hs.LoggedOn ? true : false);
// };

// export const removeHandsetFromGroup = (group: GroupHandset, hs: Handset) => {
//   group.removeHandsetFromGroup(hs.ID);
// };

/**
 * Nothing special about saveToSCU, it just a convenience wrapper around manifest.save
 *
 * Send all all changes to the SCU.
 * If manifest changes have been made otherthan care group changes, these will also be saved
 *
 * After the SQL has completed the local Manifest will be updated and events generated to broadcast
 * that a change has happened
 */
export const saveToSCU = async (): Promise<teErrorCode> => {
  const manifest = getManifestInstance();
  let status = teErrorCode.E_ERROR;

  try {
    status = await manifest.save();
    if (status == teErrorCode.E_OK) {
      console.info('manifest save was successful with status:', status);
    }
  } catch (error) {
    console.error('manifest save failed with error:', error);
    status = teErrorCode.E_ERROR;
  }

  return status;
};

/**
 * Apply the care group to all alarms for a resident, including any trigger peripherals
 * @param careGroup
 * @param residentList Array of 1 or more residents.
 */
export const applyCareGroupToResidents = (
  careGroup: number,
  residentList: Room[]
): void => {
  const manifest = getManifestInstance();

  for (const _room of residentList) {
    // incase the residentList passed is a copy of the manifast instances we get the instance from the manifest
    const room = manifest.rooms.find((e) => e.ID === _room.ID);
    if (room) {
      room.setResidentCareGroup(careGroup);
    }
  }
};

/**
 * Apply the care group to the specified alarm type
 *
 * @param careGroup
 * @param alarmType
 */
export const applyCareGroupToAlarmType = (
  careGroup: number,
  alarmType: ALARM_TYPES
): void => {
  const manifest = getManifestInstance();

  for (const _device of manifest.rooms) {
    _device.setResidentCareGroupSpecificAlarm(careGroup, alarmType);
    // NOTE , triggers will have been handled by the Room.setResidentCareGroupSpecificAlarm function
  }

  for (const _device of manifest.accessPoints) {
    _device.setRoutingEventsCareGroup_specificAlarmType(careGroup, alarmType);
  }

  for (const _device of manifest.deviceInterfaces) {
    _device.setRoutingEventsCareGroup_specificAlarmType(careGroup, alarmType);
  }

  for (const _device of manifest.gatewayInterfaces) {
    _device.setRoutingEventsCareGroup_specificAlarmType(careGroup, alarmType);
  }
};

/**
 * Apply the care group to a specified device.  This device may be a room unit, access point , interface or radio peripheral
 * @param careGroup
 * @param deviceId
 * @returns
 */

export const applyCareGroupToDevice = (
  careGroup: number,
  deviceId: number
): void => {
  const manifest = getManifestInstance();
  const room = manifest.rooms.find((e) => e.ID === deviceId);
  if (room) {
    room.setRoutingEventsCareGroup_specificDevice(careGroup, deviceId);
    return;
  }

  const ap = manifest.accessPoints.find((e) => e.ID === deviceId);
  if (ap) {
    ap.setRoutingEventsCareGroup_specificDevice(careGroup, deviceId);
    return;
  }

  const intf = manifest.deviceInterfaces.find((e) => e.ID === deviceId);
  if (intf) {
    intf.setRoutingEventsCareGroup_specificDevice(careGroup, deviceId);
    return;
  }

  const dev = manifest.triggers.find((e) => e.ID === deviceId);
  if (dev) {
    dev.setRoutingEventsCareGroup_specificDevice(careGroup, deviceId);
    return;
  }

  const gwi = manifest.gatewayInterfaces.find((e) => e.ID === deviceId);
  if (gwi) {
    gwi.setRoutingEventsCareGroup_specificDevice(careGroup, deviceId);
    return;
  }
};

export const setRoutingEventsAsCovert_specificAlarmType = (
  silent: boolean,
  alarmType: ALARM_TYPES,
  deviceId?: number | undefined
): void => {
  const manifest = getManifestInstance();

  if (manifest.getSCUSoftwareVersion() >= 50800) {
    for (const room of manifest.rooms) {
      if (room) {
        room.setRoutingEventsAsCovert_specificAlarmType(
          silent,
          alarmType,
          deviceId
        );
      }
    }
  } else {
    console.info(
      'Feasture not supported: current version:',
      manifest.getSCUSoftwareVersion()
    );
  }
};
