import TablePaged from 'components/tables/TablePaged';

import React, { useState } from 'react';

import Tile, { iTileEvent } from '../tile';
//import { formatDateWithSeconds } from 'common/utils/dateUtils';
import { Manifest } from '../../installation/manifest';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import { Column, Cell } from 'react-table';
import { Trigger } from 'components/installation/trigger';
import { iDevice } from 'types/manifest-types';
import _ from 'lodash';
import styled from 'styled-components';
import { Text, Tip } from 'grommet';
import * as UserUtils from 'common/userUtils';
import { Product } from 'components/floorplan/product';
import { iDeviceDefaults } from '../../../types/manifest-types';

const m = new Manifest();

interface BatteryProps {
  level: number;
}

const replaceSoonBatteryVoltage_mV = 2900;
const urgentBatteryVoltage_mV = 2550;

const BATTERY = styled.span<BatteryProps>`
  padding: ${(props) => (props.level > 0 ? '4px' : '0px')};
  background-color: ${(props) =>
    props.level < replaceSoonBatteryVoltage_mV
      ? props.level < urgentBatteryVoltage_mV
        ? ({ theme }) => theme.global.colors.red
        : ({ theme }) => theme.global.colors.amber
      : ({ theme }) => theme.global.colors.green};
  color: ${(props) =>
    props.level < replaceSoonBatteryVoltage_mV
      ? props.level < urgentBatteryVoltage_mV
        ? ({ theme }) => theme.global.colors.text.dark
        : ({ theme }) => theme.global.colors.text.light
      : ({ theme }) => theme.global.colors.text.dark};
`;

const batteryLevelText = (level: number): string => {
  if (level < urgentBatteryVoltage_mV) return 'Needs urgent replacement';
  if (level < replaceSoonBatteryVoltage_mV) return 'Replace soon';
  return 'Battery Good';
};

const LowBatteryPeripheralsOverview = (): JSX.Element => {
  const [allData, setAllData] = useState<Trigger[] | undefined>();
  const [, SetChildEvent] = useState('');
  const [selectedDevice, setSelectedDevice] = useState<Trigger | undefined>();
  const [tableFilters] = useState<
    { id: string; value: string }[] | undefined
  >();

  // **From Trigger
  // ID = invalidID;
  // Description = '';
  // InUse = false;
  // LoggedOn = false;
  // MicVolume = 0; //not used
  // FeatureMask = 0;
  // lastEvent: OmniviaLiveEventsLog | any = [];
  // **From Device
  // ID = invalidID;
  // MacAddress = 0;
  // MacAddressHex = '';
  // Type = 0;
  // HwModel = 0;
  // PollsMissed = 0;
  // ParentID = 0;
  // RSSI = 0;
  // Depth = 0;
  // NetworkJoins = 0;
  // RaisePollFails = 0;
  // PrefParentID = 0;
  // Personality = 0;
  // IPAddress = '';
  // ClusterGW = false;

  // **From OmniviaLiveEventsLog
  // id: string,
  // mac: string,
  // omniviaSCUSerial: string,
  // deviceType: number,
  // expirationUnixTime: number,
  // deviceId: number,
  // associatedAlarmId?: number | null,
  // batteryVoltageApplication?: number | null,
  // handset?: HandsetChargeInfo | null,
  // parentMac?: string | null,
  // rssi_db?: number | null,
  // addtionalReceiverList?:  Array<ReceiverList | null > | null,
  // createdAt?: string | null,
  // updatedAt?: string | null,

  const columns = React.useMemo<Column<Trigger>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        width: 20,
      },
      {
        Header: 'Room',
        accessor: (row) =>
          m.rooms.find((r) => r.HomeDeviceId == row.ParentID)?.RoomNumber,
        width: 40,
      },
      {
        Header: 'Description',
        accessor: 'Description',
        width: 120,
        Cell: (s: Cell<Trigger>) => {
          const d: iDevice = {
            ...iDeviceDefaults,
            HwModel: s.row.original.HwModel,
            Type: s.row.original.Type,
          };
          return (
            <Tip
              content={
                <Text>
                  {Product.getProductfromManifestDeviceType(d).productCode}
                </Text>
              }
            >
              <Text>{s.row.original.Description}</Text>
            </Tip>
          );
        },
      },
      {
        id: 'Identity',
        Header: 'Identity',
        accessor: (row) => {
          return row.getIdentity();
        },
        // row.MacAddressHex.substr(row.MacAddressHex.length - 6, 6),
        width: 40,
      },
      {
        Header: 'Battery',
        accessor: (row) =>
          row.lastEvent ? row.lastEvent.batteryVoltageApplication : '',
        Cell: (s: Cell<Trigger>) => (
          <Tip content={<Text>{batteryLevelText(s.value)}</Text>}>
            <BATTERY level={s.value}>{s.value}</BATTERY>
          </Tip>
        ),
        width: 50,
      },
    ],
    [allData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const loadDataEvent = () => {
    let triggers: Trigger[] = _.cloneDeep(m.triggers);

    triggers = triggers.filter((e) => {
      if (e.lastEvent && e.lastEvent.batteryVoltageApplication) {
        return (
          e.lastEvent.batteryVoltageApplication < replaceSoonBatteryVoltage_mV
        );
      }
      return false;
    });

    setAllData(triggers);
  };

  const initialState = React.useMemo(
    () => ({
      // hiddenColumns: ['ID'],
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? ['ID']
        : ['ID', 'ProductCode', 'Parent', 'RSSI'],
      pageSize: 10,
      sortBy: [
        {
          id: 'lastEvent.batteryVoltageApplication',
          desc: true,
        },
      ],
    }),
    []
  );

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.LOADED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
  ];

  const selectDevice = async (
    rowData: iDevice,
    selectionInfo: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (!selectionInfo.alreadySelected) {
      const manifestRecord = m.triggers.find((e) => e.ID === rowData.ID);
      console.info('Device selected:', manifestRecord);
      setSelectedDevice(manifestRecord);
    } else {
      setSelectedDevice(undefined);
    }
  };

  return (
    <Tile
      title={'Low Battery Pendants & Peripherals'}
      eventListeners={events}
      setChildEvent={SetChildEvent}
      showLastEvent={true}
    >
      <>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={allData ? allData : []}
          showCheckboxes={false}
          onLastSelectedRow={selectDevice}
          currentSelectedID={selectedDevice?.ID}
          selectorKeyName="ID"
          reportDescription={{
            header: '',
            filename: 'WirelessDevices',
          }}
          filters={tableFilters}
        />
      </>
    </Tile>
  );
};

export default LowBatteryPeripheralsOverview;
