import Amplify, { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import aws_config from '../../aws-exports';
import { ZenObservable } from 'zen-observable-ts';
import logger from 'common/logger';

export interface Message {
  srcid: string;
  dstid: string;
  replytpc: string;
  type: number;
  opcode: number;
  transid: number;
  msgid: number;
  ts: string;
  msg: unknown;
}

interface MessageHistory extends Message {
  historytimestamp: number;
  historyID: number;
}

export interface MessageCallbackFunction {
  (msg: Message): void;
}

const envStart = aws_config.aws_user_files_s3_bucket.lastIndexOf('-');
const amplifyenv = aws_config.aws_user_files_s3_bucket.slice(envStart + 1);

// Apply plugin with configuration
const createPubSubClient = async () =>
  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: aws_config.aws_appsync_region,
      aws_pubsub_endpoint:
        'wss://a12vmg0bcnm4fa-ats.iot.eu-west-1.amazonaws.com/mqtt',
    })
  );

export class IoTMessageHandler {
  private maxTransactionNumber: number = 65535;
  private nextTrandsactionId: number =
    Math.floor(Math.random() * this.maxTransactionNumber) + 1;
  private messageHistory: MessageHistory[] = [];
  private subscribeId = 0;
  private messageTxId = 0;

  private static _Instance: IoTMessageHandler;
  public static get Instance(): IoTMessageHandler {
    if (IoTMessageHandler._Instance) {
      return IoTMessageHandler._Instance;
    } else {
      return new IoTMessageHandler();
    }
  }

  constructor() {
    if (IoTMessageHandler._Instance) {
      return IoTMessageHandler._Instance;
    }
    IoTMessageHandler._Instance = this;
    this.subscribeId = 0;

    createPubSubClient();
  }

  getNextGlobalTransactionId(): number {
    this.nextTrandsactionId++;

    if (this.nextTrandsactionId >= this.maxTransactionNumber) {
      this.nextTrandsactionId = 1;
    }
    return this.nextTrandsactionId;
  }

  isIoTMsgValid(msg: Message): boolean {
    if (
      typeof msg.type == 'undefined' ||
      typeof msg.opcode == 'undefined' ||
      typeof msg.transid == 'undefined' ||
      typeof msg.msgid == 'undefined' ||
      typeof msg.ts == 'undefined' ||
      typeof msg.msg == 'undefined'
    ) {
      return false;
    }

    return true;
  }

  isIoTMsgRepeated(
    msg: Message,
    history: MessageHistory[],
    historyID: number
  ): { repeated: boolean; newHistory: MessageHistory[] } {
    const workingHistory: MessageHistory[] = [];
    let repeated = false;

    //console.info('isIoTMsgRepeated msg:', msg);

    for (const index in history) {
      const msgEntry: MessageHistory = history[index];

      const dateNow = Date.now();
      const ageDifMs = dateNow - msgEntry.historytimestamp;
      if (ageDifMs < 60000) {
        //console.info('isIoTMsgRepeated msgEntry:', msgEntry);

        if (
          msgEntry.ts === msg.ts &&
          msgEntry.msgid === msg.msgid &&
          msgEntry.type == msg.type &&
          msgEntry.opcode == msg.opcode &&
          msgEntry.historyID == historyID
        ) {
          repeated = true;
          console.info('Repeated message: ', msg);
        }

        workingHistory.push(msgEntry);
      }
    }

    if (repeated == false) {
      // We take a quick copy of the messgae so that we don't update
      // the source object passed from the pubsub
      const copyofmsg: MessageHistory = JSON.parse(JSON.stringify(msg));
      const dateNow = Date.now();
      copyofmsg['historytimestamp'] = dateNow;
      copyofmsg['historyID'] = historyID;

      /*
        console.info(
          'push messgae with histiory id:',
          historyID,
          ' copy:',
          copyofmsg
        );*/
      workingHistory.push(copyofmsg);
    }

    return { repeated: repeated, newHistory: workingHistory };
  }

  historyCheck(
    scope: unknown,
    messageCallback: MessageCallbackFunction,
    msg: Message,
    historyID: number
  ): void {
    //console.info('messageHistory:', messageHistory);
    if (this.isIoTMsgValid(msg)) {
      const resp = this.isIoTMsgRepeated(msg, this.messageHistory, historyID);

      this.messageHistory = resp.newHistory;
      if (!resp.repeated) {
        messageCallback.call(scope, msg);
      }
    } else {
      console.error('Invalid Iot Message received:', msg);
    }
  }

  /**
   * @desc subscribe to messages sent from a SCU
   * @param {String} SCUSerialNumber: Serial number of the SCU or * for all SCUs
   * @param {any} scope: Class instance for the callbacks
   * @param {function} messageCallback:
   * @param {function} subscriptionClosedCallback:
   * @param {function} errorCallback:
   * @return The subscription object which should be saved and passed to unsubscribe
   */
  subscribe(
    SCUSerialNumber: string,
    scope: unknown,
    messageCallback: MessageCallbackFunction,
    subscriptionClosedCallback: () => void,
    errorCallback: (error: unknown) => void
  ): ZenObservable.Subscription {
    // TODO:  We probably want to optimise the topic to only subscribe to message the web application is interested in
    let topic = `CLOUD_API/v1/sites/${SCUSerialNumber}/TX/msg/#`;
    console.info('Amplify env:', amplifyenv);
    if (amplifyenv === 'dev') {
      topic = `CLOUD_API_DEV/v1/sites/${SCUSerialNumber}/TX/msg/#`;
    } else if (amplifyenv === 'test') {
      topic = `CLOUD_API_TEST/v1/sites/${SCUSerialNumber}/TX/msg/#`;
    }

    console.info('Subscribing to:', topic);
    logger.info('IoT.messaginghandler.subscribe', {
      topic: topic,
    });

    const id = this.subscribeId;
    this.subscribeId = this.subscribeId + 1;
    return PubSub.subscribe(topic).subscribe({
      next: (data) => this.historyCheck(scope, messageCallback, data.value, id),
      error: (error) => errorCallback.call(scope, error),
      complete: () => subscriptionClosedCallback.call(scope),
    });
  }

  unsubscribe = (subscription: ZenObservable.Subscription): void => {
    console.info('Unsubscribe:', subscription);
    subscription.unsubscribe();
  };

  /**
     * @desc publish to messages to a SCU
     * @param {String} SCUSerialNumber: Serial number of the SCU
     * @param {Int} messageType: Message type.
     * @param {Int} messageOpcode: Message opcode
     * @param {String} replytpc: Use to tell the SCU the reply topic, not always used and can be empty string ''
     * @param {Int} transactionId: Allow the upper layers to tie a response to a request
     * @param {Object} msgObject: Application message object specific to the messageType and messageOpcode
     * @return the sent promise
     * 
     * Thi sfunction constructs the following JSON Message structure:
           {
              "srcid": <string>,
              "dstid": <string>,
              "replytpc": <string>,
              "type": <int>,
              "opcode": <int>,
              "transid": <int>,
              "msgid": <int>,
              "ts": <string>,
              "msg": {
                 <Message specific fields>
              }
           }
     */
  publish = (
    SCUSerialNumber: string,
    messageType: number,
    messageOpcode: number,
    replytpc: string,
    transactionId: number,
    msgObject: unknown
  ): Promise<void[]> => {
    this.messageTxId++;

    const sendMsg = {
      srcid: 'AWS_APP',
      dstid: SCUSerialNumber,
      replytpc: replytpc,
      type: messageType,
      opcode: messageOpcode,
      transid: transactionId,
      msgid: this.messageTxId,
      ts: new Date().toISOString(),
      msg: msgObject,
    };

    console.info('IoT Send:', sendMsg);

    // Topics are in the context of the SCU, ie RX as it is a message the CSU is recieving
    let topic = `CLOUD_API/v1/sites/${SCUSerialNumber}/RX/msg/${messageType}/${messageOpcode}`;

    if (amplifyenv === 'dev') {
      topic = `CLOUD_API_DEV/v1/sites/${SCUSerialNumber}/RX/msg/${messageType}/${messageOpcode}`;
    } else if (amplifyenv === 'test') {
      topic = `CLOUD_API_TEST/v1/sites/${SCUSerialNumber}/RX/msg/${messageType}/${messageOpcode}`;
    }

    //onsole.info('Sending: ', sendMsg);
    //console.info('to: ', topic);

    return PubSub.publish(topic, sendMsg);
  };
}

export default IoTMessageHandler;
