// Added symbol-observable to stop warnign from Redux
// eslint-disable-next-line
import Symbol_observable from 'symbol-observable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';

// This si the AWS exports that needs changing for different back ends. Note this is also in app.js, but thbis is the one that takes effect
// import awsExports from './aws-exports-production'; // production
import awsExports from './aws-exports'; // development

import { Router } from 'react-router-dom';
import WithProvider from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import DataManagerProvider from './providers/DataManagerProvider';
import { WinstonProvider } from 'winston-react';
import logger from 'common/logger';
import appHistory from 'common/appHistory';

import './i18n';

Amplify.configure(awsExports);

ReactDOM.render(
  <Provider store={store}>
    <WinstonProvider logger={logger}>
      <DataManagerProvider>
        <React.Fragment>
          <Router history={appHistory}>
            <WithProvider />
          </Router>
        </React.Fragment>
      </DataManagerProvider>
    </WinstonProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
