import React, { useEffect, useState } from 'react';
import * as Icons from 'grommet-icons';
import { MenuItem, SubMenu } from 'components/sidebar/ProSidebar';
import * as UserUtils from 'common/userUtils';
import * as eventDispatcher from 'store/eventDispatcher';
import * as ReduxStore from 'store/store';
import * as UsersSlice from 'store/appUserSlice';
import { Manifest } from 'components/installation/manifest';

interface Imenuitem {
  label: string;
  href?: string;
  icon: React.ReactElement;
  accessLevel: UserUtils.UAG;
  minSCUversion?: string;
  maxSCUversion?: string;
  subMenuItems?: Imenuitem[];
  dashBoardSelect?: string;
  requiresManifest?: boolean;
}

const items: Imenuitem[] = [
  {
    label: 'Overview',
    requiresManifest: true,
    dashBoardSelect: 'Overview Dashboard',
    icon: <Icons.Apps color="white" />,
    accessLevel: UserUtils.UAG.BASIC,
  },
  {
    label: 'Calls and Monitoring',
    icon: <Icons.History color="aqua" />,
    accessLevel: UserUtils.UAG.BASIC,
    requiresManifest: true,
    subMenuItems: [
      {
        label: 'Alarm History',
        dashBoardSelect: 'Alarm call history',
        icon: <Icons.Notification color="white" />,
        accessLevel: UserUtils.UAG.BASIC,
      },
      {
        label: 'Door Call History',
        dashBoardSelect: 'Door call history',
        icon: <Icons.Login color="white" />,
        accessLevel: UserUtils.UAG.BASIC,
      },
      {
        label: "I'm Ok Monitoring",
        dashBoardSelect: 'OK Monitoring',
        icon: <Icons.StatusGood color="aqua" />,
        accessLevel: UserUtils.UAG.BASIC,
      },
      {
        label: 'Announcements',
        dashBoardSelect: 'Announcements',
        icon: <Icons.Announce color="blue" />,
        accessLevel: UserUtils.UAG.BASIC,
      },
    ],
  },
  {
    label: 'Reports',
    requiresManifest: true,
    dashBoardSelect: 'Reports Dashboard',
    icon: <Icons.Table color="gold" />,
    accessLevel: UserUtils.UAG.BASIC,
  },

  {
    label: 'Settings',
    icon: <Icons.SettingsOption color="white" />,
    accessLevel: UserUtils.UAG.MANAGER,
    requiresManifest: true,
    subMenuItems: [
      {
        label: 'Door Entry',
        dashBoardSelect: 'Door Config Dashboard',
        icon: <Icons.Login color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },
      {
        label: 'Handsets',
        dashBoardSelect: 'Handset Config Dashboard',
        icon: <Icons.PhoneVertical color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },
      {
        label: 'Call Sequences',
        dashBoardSelect: 'Care Groups',
        icon: <Icons.PhoneVertical color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },

      {
        label: 'System Control Unit',
        icon: <Icons.HostMaintenance color="white" />,
        accessLevel: UserUtils.UAG.TECHNICAL,
        dashBoardSelect: 'SCU Dashboard',
      },
      {
        label: 'All room units',
        dashBoardSelect: 'All Room Units Dashboard',
        icon: <Icons.Group color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },
      {
        label: 'All interface units',
        dashBoardSelect: 'All Interfaces Dashboard',
        icon: <Icons.Link color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },
      {
        label: 'All access points',
        dashBoardSelect: 'All Accesspoints Dashboard',
        icon: <Icons.Nodes color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },

      {
        label: 'Global Monitoring',
        dashBoardSelect: 'Global Monitoring Settings',
        icon: <Icons.Clock color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },

      {
        label: 'User Settings',
        dashBoardSelect: 'User Settings Config Dashboard',
        icon: <Icons.UserSettings color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },
      {
        label: 'ARC Configuration',
        dashBoardSelect: 'ARC Telephone Numbers Dashboard',
        icon: <Icons.Phone color="aqua" />,
        accessLevel: UserUtils.UAG.TECHNICAL,
      },
      {
        label: 'Alarm Types',
        dashBoardSelect: 'Alarm Types and Priorities',
        icon: <Icons.Notification color="aqua" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },
    ],
  },

  {
    label: 'Service',
    icon: <Icons.Troubleshoot color="white" />,
    accessLevel: UserUtils.UAG.TECHNICAL,
    requiresManifest: true,
    subMenuItems: [
      {
        label: 'Service Test Mode',
        icon: <Icons.Configure color="white" />,
        accessLevel: UserUtils.UAG.TECHNICAL,
        dashBoardSelect: 'Service Test Mode',
      },
      {
        label: 'Faults',
        icon: <Icons.Alert color="red" />,
        accessLevel: UserUtils.UAG.TECHNICAL,
        dashBoardSelect: 'Faults Dashboard',
      },

      {
        label: 'Software Versions',
        icon: <Icons.Install color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
        dashBoardSelect: 'SW Dashboard',
      },
      {
        label: 'Device Management',
        dashBoardSelect: 'Devices Dashboard',
        icon: <Icons.Device color="white" />,
        accessLevel: UserUtils.UAG.TECHNICAL,
      },
      {
        label: 'Unassigned devices',
        dashBoardSelect: 'Unassigned Dashboard',
        icon: <Icons.Unlink color="white" />,
        accessLevel: UserUtils.UAG.TECHNICAL,
      },

      {
        label: 'System Parameters',
        dashBoardSelect: 'System Parameters',
        icon: <Icons.System color="white" />,
        accessLevel: UserUtils.UAG.TECHNICAL,
      },
      {
        label: 'Site Alerts',
        dashBoardSelect: 'Site Alert Config Dashboard',
        icon: <Icons.CircleAlert color="white" />,
        accessLevel: UserUtils.UAG.ELITE, // UserUtils.UAG.TECHNICAL
      },
    ],
  },

  {
    label: 'Admin',
    icon: <Icons.HostMaintenance color="white" />,
    accessLevel: UserUtils.UAG.ELITE,
    subMenuItems: [
      {
        label: 'Client',
        dashBoardSelect: 'Client Admin',
        icon: <Icons.Organization color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
      },
      {
        label: 'Site',
        dashBoardSelect: 'Site Admin',
        icon: <Icons.Home color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
      },
      {
        label: 'User',
        dashBoardSelect: 'User Admin',
        icon: <Icons.UserAdmin color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
      },
      {
        label: 'Release Notes',
        href: '/views/version',
        icon: <Icons.Notes color="white" />,
        accessLevel: UserUtils.UAG.MANAGER,
      },
    ],
  },
  {
    label: 'Design / Installation',
    href: '/views/design/plan',
    icon: <Icons.Cluster color="white" />,
    accessLevel: UserUtils.UAG.TECHNICAL,
  },
  {
    label: 'AutoTest',
    requiresManifest: true,
    icon: <Icons.HostMaintenance color="white" />,
    accessLevel: UserUtils.UAG.ELITE,
    minSCUversion: '5.11.0',
    subMenuItems: [
      {
        label: 'Alarm AutoTester',
        dashBoardSelect: 'AutoTestAlarms',
        icon: <Icons.Compare color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
      },
    ],
  },

  {
    label: 'Development',
    requiresManifest: true,
    icon: <Icons.Github color="white" />,
    accessLevel: UserUtils.UAG.ELITE,

    subMenuItems: [
      {
        label: 'GWConsole',
        href: '/views/tools/gwconsole',
        icon: <Icons.Terminal color="white" />,
        accessLevel: UserUtils.UAG.ADMIN,
      },
      {
        label: 'Handset Charge Info',
        icon: <Icons.Trigger color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
        dashBoardSelect: 'Handset Dashboard',
      },

      {
        label: 'DB Tables',
        icon: <Icons.Database color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
        dashBoardSelect: 'DB Tables',
      },

      {
        label: 'Device E2',
        icon: <Icons.Disc color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
        dashBoardSelect: 'Device E2',
      },

      {
        label: 'In Development',
        icon: <Icons.Code color="white" />,
        accessLevel: UserUtils.UAG.ELITE,
        dashBoardSelect: 'In Development',
      },
    ],
  },
];

const NavSidebarItems = (): JSX.Element => {
  const [, setRefreshCount] = useState(0);
  //const darkMode = true;
  const menuHrefClick = (href: string) => {
    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.VIEW,
      eventDispatcher.systemEventStates.SELECTED,
      href,
      true
    );
  };
  const manifest = new Manifest();
  let oldManifestLoaded = false;

  const menuDashBoardClick = (dash: string) => {
    console.info('Change dashboard to :', dash);

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.DASHBOARD,
      eventDispatcher.systemEventStates.SELECTED,
      { name: dash },
      true
    );
  };

  const menuAccessAllowed = (item: Imenuitem) => {
    const u = UserUtils.minimumAccessLevel(item.accessLevel);
    let v = true;

    // if a manifest is required then check the SCU software version
    if (item.requiresManifest !== undefined && item.requiresManifest === true) {
      v = manifest.isInSoftwareVersionRange({
        min: item.minSCUversion ?? '0.0.0',
        max: item.maxSCUversion ?? '999.999.999',
      });
    }

    // console.log(item.label, u, v);

    return u && v;
  };

  const refreshNeeded = () => {
    if (manifest.manifestLoaded != oldManifestLoaded) {
      oldManifestLoaded = manifest.manifestLoaded;
      setRefreshCount((old) => old + 1);
    }
  };

  useEffect(() => {
    const persistantData = UsersSlice.getPersistantData(
      ReduxStore.store.getState()
    );
    if (persistantData.selectedView) {
      menuHrefClick(persistantData.selectedView);
    } else {
      menuHrefClick('/views/dashboard');
    }

    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.SITE,
      eventDispatcher.systemEventStates.SELECTED,
      refreshNeeded
    );

    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.LOADED,
      refreshNeeded
    );

    return () => {
      // Remove registrations on exit
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.SITE,
        eventDispatcher.systemEventStates.SELECTED,
        refreshNeeded
      );

      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.LOADED,
        refreshNeeded
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * If the menu items contains a submenu then Recursively cal teh function to render the children
   * @param item Menu item to render
   * @param index index in the ietms array or submenu array
   * @returns
   */
  const parseMenuItem = (
    item: Imenuitem,
    index: number
  ): JSX.Element | undefined => {
    if (menuAccessAllowed(item) == false) return;
    if (
      item.requiresManifest !== undefined &&
      item.requiresManifest === true &&
      manifest.manifestLoaded == false
    ) {
      return;
    }
    if (item.subMenuItems === undefined) {
      return (
        <MenuItem
          icon={item.icon}
          key={index}
          clickCB={() => {
            if (item.dashBoardSelect) {
              menuDashBoardClick(item.dashBoardSelect);
            } else if (item.href) {
              menuHrefClick(item.href);
            }
          }}
        >
          {item.label}
        </MenuItem>
      );
    } else {
      return (
        <SubMenu title={item.label} icon={item.icon} key={index}>
          {item.subMenuItems.map((item, subindex) =>
            parseMenuItem(item, subindex * index)
          )}
        </SubMenu>
      );
    }
  };

  return <>{items.map((item, index) => parseMenuItem(item, index))}</>;
};

export default NavSidebarItems;
