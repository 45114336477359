import * as MsgDefines_DEVICES from './IoTMessageDefines_DEVICES';
import * as MsgDefines from './IoTMessageDefines';
import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
//import logger from 'common/logger';
import { Device } from 'components/installation/device';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

export {
  tePROCESSOR,
  tePULLSTATE,
  OAD_ALL_TYPES,
  OAD_ALL_MODELS,
  OAD_ALL_DEVICES,
} from './IoTMessageDefines_DEVICES';
export { teCLOUD_ERROR_CODES } from './IoTMessageDefines';
export { teDEVICE_TYPES, teDEVICE_MODELS } from 'types/manifest-enums';

export enum teOAD_STATES {
  OAD_STATE_IDLE = 0,
  OAD_STATE_COMPLETE, // Functionally identical to the IDLE state, but indicates download done
  OAD_STATE_QUERYING_SW, // Querying round to get software versions from everyone
  OAD_STATE_BROADCAST, // Broadcasting a binary image
  OAD_STATE_WAIT_RX_CHECK, // Waiting for responses to packet queries
  OAD_STATE_FILL_IN_BROADCAST, // Sending out extra broadcasts to fill in any blanks
  OAD_STATE_ACTIVATING, // Sending out activation messages to devices
  OAD_STATE_POST_ACTIVATE, // Dead period after finished sending out activation message
  OAD_STATE_PAUSED, // Pause state when an alarm is active
  OAD_STATE_MAX,
}

export const oadStateText: string[] = [
  'Idle',
  'Download complete',
  'Querying software versions',
  'Download in progress (broadcasting data)',
  'Verifing',
  'Download in progress (broadcasting missing data)',
  'Activating new software',
  'Reset wait',
  'Paused, alarm in progress',
];

export const oadPullStateText: string[] = [
  'Idle',
  'In progress',
  'Complete',
  'Download fail',
  'Checksum fail',
];

export const imageNames: string[] = [
  'RF GWI DATA', // OAD_OBJECT_JENNET_COORDINATOR
  'Repeater DATA', //OAD_OBJECT_JENNET_ROUTER_V1_JENNIC
  'AUDIO', //OAD_OBJECT_AUDIO_COPROCESSOR
  'Room Unit DATA', //OAD_OBJECT_ROOM_UNIT_V1_JENNIC
  'Room Unit DATA', // OAD_OBJECT_ROOM_UNIT_V2_JENNIC
  'Door I/F DATA', // OAD_OBJECT_DOOR_PANEL_V1_JENNIC
  'Door I/F DATA', // OAD_OBJECT_DOOR_PANEL_V2_JENNIC
  'MAP/MLS/MAI DATA', //OAD_OBJECT_MFAP_V1_JENNIC
  'MAP/MLS/MAI APP', // OAD_OBJECT_MFAP_V1_ESP32
  'MAP/MLS/MAI/VRU AUDIO', //OAD_OBJECT_MFAP_AUDIO_COPROCESSOR
  'VRU DATA', // OAD_OBJECT_ROOM_UNIT_V3_JENNIC
  'VRU APP', // OAD_OBJECT_VRU_V1_CM3
];

/**
 * Query a devices SW versions
 *
 * @param {number} mac - 64bit MAC address of the device to query, or 0 for all
 * @param {object} userData - data that gets sent back in the completion events to allow user to tie up the request/response
 */
export async function oadQuery(
  mac: number,
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_OAD_QUERY_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_QUERY_RESP;

  const msg: MsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_QUERY_REQ = {
    mac: Device.MACaddrToString(mac, 10),
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    3000,
    'oadQuery',
    userData
  );
}

/**
 * Send a ping request to a device.
 * @param {object} userData - data that gets sent back in the completion events to allow user to tie up the request/response
 */
export async function oadStatus(
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_STATUS_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_STATUS_RESP;

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    {},
    3000,
    'oadStatus',
    userData
  );
}

/**
 * Clear the pending SW version on the devices specified processor
 *
 * @param {number} mac - 64bit MAC address of the device to query
 * @param {tePROCESSOR} processor - Processor pending version to clear
 * @param {object} userData - data that gets sent back in the completion events to allow user to tie up the request/response
 */
export async function oadClear(
  mac: number,
  processor: MsgDefines_DEVICES.tePROCESSOR,
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_OAD_CLEAR_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_CLEAR_RESP;

  const msg: MsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_CLEAR_REQ = {
    mac: Device.MACaddrToString(mac, 10),
    processor: processor,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    10000,
    'oadClear',
    userData
  );
}

/**
 * Issue software update pull request to the SCU
 *
 * @param {url} string - presigned url to the software update
 * @param {version} string - version identifier of the upgrade
 * @param {object} userData - data that gets sent back in the completion events to allow user to tie up the request/response
 */
export async function oadUpdatePullRequest(
  url: string,
  version: string,
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_PULL_UPDATE_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_PULL_UPDATE_RESP;

  const msg: MsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_PULL_UPDATE_REQ = {
    signed_url: url,
    version: version,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    5000,
    'oadUpdatePullRequest',
    userData
  );
}

/**
 * Download a new software version to the specified device or all devices
 * A oadUpdatePullRequest must have been previously made so that the SCU holds the software upgrade package
 *
 * @param start Set to false to retry the download, devices will not clear the previous version just append data if not completed
 * @param deviceId Set to OAD_ALL_DEVICES for all devices, otherwise set the the database ID
 * @param objectType Set to OAD_ALL_TYPES, otherwise type as per ManifestEnums.teDEVICE_TYPES
 * @param hwModel Set to OAD_ALL_MODELS, otherwise type as per ManifestEnums.teDEVICE_MODELS
 * @param interPacketInterval Time between packet sends in milliSeconds
 * @param userData data that gets sent back in the completion events to allow user to tie up the request/response
 * @returns tsCLOUD_MSG_DEVICES_OAD_DOWNLOAD_RESP
 */
export async function oadDownloadRequest(
  start: boolean,
  deviceId: number,
  objectType: number,
  hwModel: number,
  interPacketInterval: number,
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_DOWNLOAD_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_DOWNLOAD_RESP;

  const msg: MsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_DOWNLOAD_REQ = {
    start: start,
    deviceId: deviceId,
    objectType: objectType,
    hwModel: hwModel,
    interPacketInterval: interPacketInterval,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    5000,
    'oadDownloadRequest',
    userData
  );
}

/**
 * After all devices have been downloaded, requests them to reset and start runing the new version
 * @param deviceId Set to OAD_ALL_DEVICES for all devices, otherwise set the the database ID
 * @param activationTimeout Time after issuing the request to devices resetting in seconds.  This must be long enough for the SCU to be able to send to all devices. Suggest 360seconds
 * @param allowRollBack Let devices swap to a version reveision that is earlier than currently running
 * @param userData  data that gets sent back in the completion events to allow user to tie up the request/response
 * @returns tsCLOUD_MSG_DEVICES_OAD_ACTIVATE_RESP
 */
export async function oadActivateRequest(
  deviceId: number,
  activationTimeout: number, // in seconds
  allowRollBack: boolean,
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_ACTIVATE_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES
      .E_CLOUD_MSG_DEVICES_OAD_ACTIVATE_RESP;

  const msg: MsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_OAD_ACTIVATE_REQ = {
    deviceId: deviceId,
    activationTimeout: activationTimeout, // in seconds
    allowRollBack: allowRollBack,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    5000,
    'oadActivateRequest',
    userData
  );
}
