import { teCLOUD_MESSAGE_TYPES } from 'common/IoT/IoTMessageDefines';
import { teCLOUD_MSG_TY_SYSTEM } from 'common/IoT/IoTMessageDefines_SYSTEM';
import { IoTMessageHandler } from 'common/IoT/IotMessageHandler';
import { store } from 'store/store';
//import * as manifestSlice from 'store/manifestSlice';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
import { SCUIoTMessaging } from 'common/IoT/SCUIoTMessaging';
import { SCUSQLInjection } from 'common/IoT/IoTSCUSQLInjection';
//import logger from 'common/logger';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;
const SQLInjection = SCUSQLInjection.Instance;
const timeout = 30000;

export class IoTSCUManifest {
  private static _Instance: IoTSCUManifest;
  inProgress = false;
  promise: Promise<iIotResponseEventDetail> | null = null;

  public static get Instance(): IoTSCUManifest {
    if (IoTSCUManifest._Instance) {
      return IoTSCUManifest._Instance;
    } else {
      return new IoTSCUManifest();
    }
  }

  constructor() {
    if (IoTSCUManifest._Instance) {
      return IoTSCUManifest._Instance;
    }
    IoTSCUManifest._Instance = this;
  }
  /*
  async handleUploadRequestResponse(waitDescriptor: unknown, message: Message) {
    const SCUS = store.getState().scus;
    console.info('Got upload response:', waitDescriptor, message);
    const descriptor = waitDescriptor as WaitDescriptor;
    if (descriptor.scuId !== SCUS.selectedId) {
      console.info('ignore response, for scuId:', descriptor.scuId);
      console.info('ignore response, wanted scuId:', SCUS.selectedId);
    } else {
      store.dispatch(manifestSlice.fetch(descriptor.SCUSerialNumber));
    }
  }*/

  async requestManifestUpload(
    userDetail: unknown
  ): Promise<iIotResponseEventDetail> {
    if (this.inProgress && this.promise != null) {
      console.info('requestManifestUpload already in progress');
      return this.promise;
    }

    const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM;
    const opcode = teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_MANIFEST_UPLOAD_REQ;
    const rplyopcode =
      teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_MANIFEST_UPLOAD_RESP;

    const transId = MessageHandler.getNextGlobalTransactionId();
    const msg = {};

    const SCUS = store.getState().scus;

    console.info(
      `Send manifest upload request: SCU ID: ${SCUS.selectedId} transaction id:${transId}`
    );

    this.inProgress = true;

    try {
      this.promise = SCUMessaging.sendMessage(
        type,
        opcode,
        rplyopcode,
        transId,
        msg,
        timeout,
        'requestManifestUpload',
        userDetail,
        true // set ignoreResponseTransactionId to true so we use any upload request complete
      );
    } catch (error) {
      this.inProgress = false;
      this.promise = null;
      throw error;
    }

    try {
      await this.promise;
    } catch (error) {
      this.inProgress = false;
      this.promise = null;
      throw error;
    }

    const ret = this.promise;
    this.inProgress = false;
    this.promise = null;

    return ret;
  }

  async forceManifestUpload() {
    await SQLInjection.forceBackup(false);
  }
}
