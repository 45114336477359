import React, { useMemo } from 'react';
import useState from 'react-usestateref';
import Tile, {
  iTileEvent,
  iMenuProps,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Button, Text } from 'grommet';

import { Manifest, teErrorCode } from 'components/installation/manifest';
import * as ManifestTypes from 'types/manifest-types';
import * as eventEnums from 'types/event-enums';
import * as ManifestEnums from 'types/manifest-enums';
import * as Device from 'components/installation/device';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import TablePaged from 'components/tables/TablePaged';
import { Column, Row } from 'react-table';
import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
import SelectCell from 'components/tables/cellPresentationComponents/selectCell';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
import IDCell from 'components/tables/cellPresentationComponents/idCell';
import { useWinstonLogger } from 'winston-react';
import { Product } from 'components/floorplan/product';
import {
  alarm_types_value_text,
  getAlarmDescription,
} from 'common/alarmTypesTextFiltered/alarmTypeUtils';
import { iAlarmTypeDescription } from '../../../types/event-enums';
import { ALARM_TYPES_ACCESSPOINT_MAP } from '../../../common/alarmTypesTextFiltered/alarmTypesMAP_HWIP';

const manifest = new Manifest();

interface iTableData extends ManifestTypes.iAccessPoint {
  HardwiredInputsAlarmType: eventEnums.ALARM_TYPES[];
  HardwiredInputsPolarity: Device.teHARDWIRED_INPUT_POLARITY[];
  HardwiredInputsSupported: number;
  Type: number;
  HwModel: number;
  MacAddress: number;
}

const blankTableEntry: iTableData = {
  ID: 0,
  Name: 'blank',
  DeviceId: 0,
  UnitId: 0,
  HardwiredInputsAlarmType: [0, 0, 0],
  HardwiredInputsPolarity: [0, 0, 0],
  HardwiredInputsSupported: 0,
  Type: 0,
  HwModel: 0,
  MacAddress: 0,
};

let _allData: iTableData[] = [];

const AccessPointConfigTable = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useState<number[]>([]);

  const loadData = () => {
    //ManifestTypes.iDoorsAndOtherInteraces[]

    for (const device of manifest.accessPoints) {
      device.pullHardwiredInputsFromRoutingEvents();
    }

    _allData = _.cloneDeep(manifest.accessPoints);

    setChanges([]);
    setRenderCount((old) => old + 1);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
  ];

  function getPolarityText(pol: Device.teHARDWIRED_INPUT_POLARITY): string {
    let text = '';

    const index = eventEnums.INPUT_POLARITIES.findIndex((object) => {
      return object.value === pol;
    });

    if (index != -1) {
      text = eventEnums.INPUT_POLARITIES[index].text;
    }

    return text;
  }

  const deviceHasInputs = (device: iTableData): boolean => {
    if (
      device.HwModel == ManifestEnums.teDEVICE_MODELS_ROUTER.DEVICE_MODEL_MAP
    ) {
      return true;
    }

    return false;
  };

  /**
   * @param id    room unit device id
   * @param property item to change in the room unit object
   * @param value new value
   */
  const setProperty = (id: number, property: string, value: unknown) => {
    const device = _allData.find((e) => e.ID == id);
    if (device) {
      const manifestRecord = manifest.accessPoints.find((e) => e.ID == id);

      const setIt = () => {
        if (!changesRef.current.includes(id)) {
          changesRef.current.push(id);
          setRenderCount((old) => old + 1);
        }
      };

      _.set(device, property, value);
      setRenderCount((old) => old + 1);

      if (manifestRecord) {
        if (_.get(manifestRecord, property, 0) != value) {
          setIt();
        } else {
          _.pull(changesRef.current, id);
          setRenderCount((old) => old + 1);
        }
      } else {
        console.error('Manifest record not found for device:', device);
      }
    }
  };

  function getProperty(id: number, property: string): unknown {
    let device = _allData.find((e) => e.ID == id);
    if (device === undefined) {
      device = blankTableEntry;
    }
    const value = _.get(device, property, 0);
    return value;
  }

  const inputFilter = React.useMemo(
    () => (rows: Row<iTableData>[], id: string[], filterValue: string) => {
      let input = Device.teDEVICE_INPUTS.DEVICE_INPUT_1;
      if (id[0].includes('in2')) {
        input = Device.teDEVICE_INPUTS.DEVICE_INPUT_2;
      } else if (id[0].includes('in3')) {
        input = Device.teDEVICE_INPUTS.DEVICE_INPUT_3;
      }

      return rows.filter((row) => {
        let text = getAlarmDescription(
          row.original.HardwiredInputsAlarmType[input]
        );
        if (id[0].includes('w')) {
          text = getPolarityText(row.original.HardwiredInputsPolarity[input]);
        }
        const escapedText = filterValue.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        const exp = new RegExp(escapedText, 'i');

        return exp.test(text);
      });
    },
    []
  );

  const validateUnitId = (
    id: number,
    newValue: unknown
  ): number | undefined => {
    const unitId = newValue as number;

    // We allow multiple units to have 9000 as this defines the system
    if (unitId == 9000) return undefined;

    const deviceInfo = manifest.unitIdInUse(unitId);
    const currentTable = _allData.find((e) => e.UnitId == unitId && e.ID != id);

    if (deviceInfo === undefined && currentTable === undefined)
      return undefined;

    const originalRecord = manifest.accessPoints.find((e) => e.ID == id);

    let deviceid = 0;
    let mac = 0;
    if (deviceInfo !== undefined) {
      mac = deviceInfo.mac;
      deviceid = deviceInfo.deviceId;
    } else if (currentTable !== undefined) {
      mac = currentTable.MacAddress;
      deviceid = currentTable.ID ? currentTable.ID : 0;
    }
    let assignedToName = manifest.getDeviceNameByMac(mac);
    assignedToName += ` ID:${deviceid}`;

    logger.info('accessPointConfigTable.invalidUnitId', { unitId: unitId });
    setDialogueProps({
      show: true,
      dialogueText: `The unit ID ${unitId} is already in use.`,
      dialogueTextLine2: assignedToName,
      showSpinner: false,
      showOkButton: true,
      okButtonPress: () => setDialogueProps(undefined),
    });

    return originalRecord?.UnitId;
  };

  const getDeviceToolTip = React.useCallback((id: unknown) => {
    return manifest.accessPoints.find((e) => e.ID == id)?.toolTip();
  }, []);

  const alarmTypesList: iAlarmTypeDescription[] = useMemo(() => {
    return alarm_types_value_text(ALARM_TYPES_ACCESSPOINT_MAP);
  }, []);

  /**
   * The aim of all the cell components is that they do not render unnecessarily
   * All the cell components use React.memo to memorise the component.
   *
   * React.memo will only re-generate the component if a prop changes therefore we pass a reRenderValue prop
   * to the component to allow us to signal to React.memo that it needs to be re-rendered.
   *
   * The components use callback to set/get their data and not state varibles
   */
  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        width: '60px',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <IDCell
            id={props.row.values.ID}
            changesRef={changesRef}
            reRenderValue={changesRef.current.includes(props.row.values.ID)}
          />
        ),
      },

      {
        Header: 'Type',
        accessor: (row) => {
          return Product.getProductBaseName(
            Product.getProductfromManifestDeviceType(row)
          );
        },
      },

      {
        width: '300px',
        Header: 'Name',
        accessor: 'Name',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Name}
            valueProperty={`Name`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="text"
            /*
             * Passing tooltip as a memorised function so that the components do not re-render unnecessarily
             */
            toolTip={getDeviceToolTip}
          />
        ),
      },
      {
        id: 'UnitId',
        Header: 'Unit ID',
        accessor: 'UnitId',
        width: '80px',
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.UnitId}
            valueProperty={`UnitId`}
            getProperty={getProperty}
            setProperty={setProperty}
            validator={validateUnitId}
            inputType="number"
            toolTip={
              <Text style={{ whiteSpace: 'pre-wrap' }}>
                {
                  'The unit ID is used by the alarm receiving montioring centre to identify a device.\r\nIt is akin to a room number for room units'
                }
              </Text>
            }
          />
        ),
      },
      {
        id: 'in1',
        Header: 'Input 1',
        accessor: (row) =>
          row.HardwiredInputsAlarmType[Device.teDEVICE_INPUTS.DEVICE_INPUT_1],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => {
          if (deviceHasInputs(props.row.original)) {
            return (
              <SelectCell
                id={props.row.values.ID}
                reRenderValue={
                  props.row.original.HardwiredInputsAlarmType[
                    Device.teDEVICE_INPUTS.DEVICE_INPUT_1
                  ]
                }
                valueProperty={`HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_1}]`}
                getProperty={getProperty}
                setProperty={setProperty}
                // options={ALARMS_TYPE_DESCRIPTIONS}
                options={alarmTypesList}
                searchBox={true}
                alwaysDisplayDropDown={true}
                // toolTip={
                //   <Text style={{ whiteSpace: 'pre-wrap' }}>
                //     {
                //       ' '
                //     }
                //   </Text>
                // }
              />
            );
          }
          return <></>;
        },
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_1}]`
          ) as number;

          return getAlarmDescription(value);
        },
        filter: inputFilter,
      },

      {
        id: 'in1w',
        Header: 'Input 1 Wiring',
        headerToolTip:
          'Set if the input 1 is wired as\r\nnormally open or normally closed',
        accessor: (row: iTableData) =>
          row.HardwiredInputsPolarity[Device.teDEVICE_INPUTS.DEVICE_INPUT_1],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => {
          if (deviceHasInputs(props.row.original)) {
            return (
              <SelectCell
                id={props.row.values.ID}
                reRenderValue={
                  props.row.original.HardwiredInputsPolarity[
                    Device.teDEVICE_INPUTS.DEVICE_INPUT_1
                  ]
                }
                valueProperty={`HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_1}]`}
                getProperty={getProperty}
                setProperty={setProperty}
                options={eventEnums.INPUT_POLARITIES}
              />
            );
          }
          return <></>;
        },
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_1}]`
          ) as number;

          return getPolarityText(value);
        },
        filter: inputFilter,
      },

      {
        id: 'in2',
        Header: 'Input 2',
        accessor: (row: iTableData) =>
          row.HardwiredInputsAlarmType[Device.teDEVICE_INPUTS.DEVICE_INPUT_2],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => {
          if (deviceHasInputs(props.row.original)) {
            return (
              <SelectCell
                id={props.row.values.ID}
                reRenderValue={
                  props.row.original.HardwiredInputsAlarmType[
                    Device.teDEVICE_INPUTS.DEVICE_INPUT_2
                  ]
                }
                valueProperty={`HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_2}]`}
                getProperty={getProperty}
                setProperty={setProperty}
                options={alarmTypesList}
                searchBox={true}
                alwaysDisplayDropDown={true}
                // toolTip={
                //   <Text style={{ whiteSpace: 'pre-wrap' }}>
                //     {
                //       ''
                //     }
                //   </Text>
                // }
              />
            );
          }
          return <></>;
        },
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsAlarmType[${Device.teDEVICE_INPUTS.DEVICE_INPUT_2}]`
          ) as number;

          return getAlarmDescription(value);
        },
        filter: inputFilter,
      },

      {
        id: 'in2w',
        Header: 'Input 2 Wiring',
        headerToolTip:
          'Set if the input 2 is wired as\r\nnormally open or normally closed',
        accessor: (row: iTableData) =>
          row.HardwiredInputsPolarity[Device.teDEVICE_INPUTS.DEVICE_INPUT_2],
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => {
          if (deviceHasInputs(props.row.original)) {
            return (
              <SelectCell
                id={props.row.values.ID}
                reRenderValue={
                  props.row.original.HardwiredInputsPolarity[
                    Device.teDEVICE_INPUTS.DEVICE_INPUT_2
                  ]
                }
                valueProperty={`HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_2}]`}
                getProperty={getProperty}
                setProperty={setProperty}
                options={eventEnums.INPUT_POLARITIES}
              />
            );
          }
          return <></>;
        },
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(
            row.original.ID,
            `HardwiredInputsPolarity[${Device.teDEVICE_INPUTS.DEVICE_INPUT_2}]`
          ) as number;

          return getPolarityText(value);
        },
        filter: inputFilter,
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : ['in1', 'in1w', 'in2', 'in2w'],
      pageSize: 25,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const save = () => {
    logger.info('accessPointConfigTable.save', {
      changedIDs: changesRef.current,
    });
    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _allData.find((e) => e.ID === id);
      let device = manifest.accessPoints.find((e) => e.ID === id);
      if (!stateRecord || !device) {
        console.error('access point not found during save');
        continue;
      }
      device = _.merge(device, stateRecord);
      device.pushHardwiredInputsToRoutingEvents();
    }

    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setChanges([]);
    };

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('accessPointConfigTable.saveFailed', {
            status: status,
            error: {},
          });
        }

        complete(str);
      })
      .catch((error) => {
        logger.info('accessPointConfigTable.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        console.error('arc # save fail:', error);
        complete(str);
      });
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? changes.length : false}
      tip="Save changes"
      disabled={changes.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('accessPointConfigTable.discardChanges');
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="Access Point Configuration"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={_allData ? _allData : []}
          showCheckboxes={false}
          reportDescription={{
            header: '',
            filename: 'AccessPointConfig',
          }}
        />
      </>
    </Tile>
  );
};

export default AccessPointConfigTable;
