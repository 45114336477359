/** @module Site */

import { Plan } from 'components/floorplan/plan';
import { iSite, Point } from 'types/plan-types';
import { Product } from 'components/floorplan/product';
import { plainToClass, plainToInstance } from 'class-transformer';
import { products } from 'data/product-definitions';
import {
  Equipment,
  includeOtherProduct,
  INSTALLSTATUS,
} from 'types/product-types';
import { store } from 'store/store';
import * as siteplanslice from 'store/sitePlanSlice';

import { Device } from 'components/installation/device';
import * as eventDispatcher from 'store/eventDispatcher';
import logger from 'common/logger';
import { Manifest } from 'components/installation/manifest';
import * as ManifestEnums from 'types/manifest-enums';
import {
  iARCDetail,
  iDevice,
  iARCDetailType,
} from '../../types/manifest-types';
import { mergeSiteDataIntoManifest } from './sync/MergeSiteDataIntoManifest';
import { ProductCategory } from '../../types/product-types';
import { mergeManifestIntoSiteData } from './sync/MergeManifestIntoSiteData';
import { Room } from 'components/installation/room';
import { AccessPoint } from 'components/installation/accesspoint';
import { DoorAndDeviceInterface } from 'components/installation/doorAndDeviceInterface';

/**
 * Handles all site equipment layout data  and associated floorplans
 */
export let siteData: iSite = { plans: [], name: '', equipment: [] };
export const manifest = new Manifest();

export const getJSON = (): iSite => {
  return <iSite>store.getState().siteplan.planData;
};

// used by head up show a selection of MAC addresses in the selection box
export type MACLIST = {
  macnumber: number;
  macHEX: string;
  display: string;
  unitName: string;
  unitNumber: number;
};

/** Pull site layout data and image filenames from S3 */
export async function loadSite(): Promise<void> {
  const siteJSON = getJSON();
  siteData = { plans: [], name: '', equipment: [] };

  logger.debug('floorplan.site.loadSite', {
    siteJSONInfo: {
      name: siteJSON.name,
      revision: siteJSON.revision,
      savedByUser: siteJSON.savedByUser,
      lastSaved: siteJSON.lastSaved,
    },
  });

  console.log(siteJSON);
  siteData.equipment = []; //clear any existing equipment
  if (siteJSON) {
    siteData = {
      ...siteData,
      name: siteJSON.name,
      modified: 0,
      lastSaved: siteJSON.lastSaved,
      savedByUser: siteJSON.savedByUser,
      quoteRef: siteJSON.quoteRef,
      revision: siteJSON.revision,
    };
    siteJSON.equipment.forEach((item) => {
      const p = plainToInstance(Product, item);
      p.uniqueID = `${p.designator}${p.id}`;
      // bring the product uptodate with the latest product data definitions etc...
      p.refreshWithLatestProductFile();
      if (p.hasPhysicalID() && p.installStatus == INSTALLSTATUS.DESIGN)
        p.installStatus = INSTALLSTATUS.COMMISSIONED;
      if (!p.hasPhysicalID() && p.installStatus != INSTALLSTATUS.DESIGN)
        p.installStatus = INSTALLSTATUS.DESIGN;
      siteData.equipment.push(p);
    });

    // Load plans
    siteJSON.plans.forEach((item) => {
      const p = plainToInstance(Plan, item);
      siteData.plans.push(p);
      // logger.debug(p);
    });
  }
}

export async function updateStore(storeModify?: boolean): Promise<void> {
  logger.info('floorplan.site.updateStore', { storeModify: storeModify });
  if (storeModify) {
    if (siteData.modified && siteData.modified >= 0) siteData.modified++;
    else siteData.modified = 1;
  }

  const JSONdata = JSON.stringify(siteData);

  await store.dispatch(
    siteplanslice.updatePlanData({
      planData: JSON.parse(JSONdata),
    })
  );

  eventDispatcher.emitEvent(
    eventDispatcher.systemEventTopics.FLOORPLANS,
    eventDispatcher.systemEventStates.UPDATED,
    null,
    false
  );
}

/** Store site layout data back to S3. */
export async function storeSite(): Promise<void> {
  logger.info('floorplan.site.storeSite', { modified: siteData.modified });
  if (siteData.modified) {
    console.log('storeSite*');
    siteData.lastSaved = new Date().toISOString();
    if (siteData.quoteRef == undefined) siteData.quoteRef = 'Qxxxx';
    if (siteData.revision) siteData.revision++;
    else siteData.revision = 1;
    await updateStore();
    siteData.modified = 0;
    await store.dispatch(siteplanslice.save());

    // Check for assigned units that need adding into the manifest
    mergeSiteDataIntoManifest(siteData);
  } else {
    console.log('no changes, so dont storeSite*');
  }
}

/** return equipment list for a particlaur plan/floor */
export const getEquipmentOnThisPlanOnly = (planNumber: number): Product[] => {
  return siteData.equipment.filter((f) => f.plan == planNumber);
};

/** find the last id number of a particular type of equipment */
export const findLastid = (designator: string): number => {
  let last = 0;
  siteData.equipment.forEach((item) => {
    if (item.designator == designator && item.id > last) last = item.id;
  });

  return last;
};

/** find the next in the list of a particular type of equipment on a particular floor
 * name: designator+id e.g. VRU23
 * plannumber: the number of the plan we are on
 */
export const findNext = (
  equip: Product,
  direction: 'forwards' | 'backwards'
): Product | undefined => {
  // get a filtered list of only products of that designator type on that floor
  const prod = siteData.equipment.filter(
    (item) => item.designator == equip.designator && item.plan == equip.plan
  );

  // sort the products into id order
  prod.sort((a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  });

  // get the index of the current product
  let i = prod.findIndex((f) => f.uniqueID == equip.uniqueID);

  // if we're not at the end of the list and we wish to move forwards then get the next in the list
  if (direction == 'forwards' && i < prod.length - 1) i++;

  // if we're not at the end of the list and we wish to move forwards then get the next in the list
  if (direction == 'backwards' && i > 0) i--;

  return prod[i];
};

/** Add a piece of equipment to the plan
 * @param: Point - location on the plan(s)
 * @param: Equipment - productDefinition - the equipment to add
 * @param: iDevice - optional physical device if adding from a real install. the MACAddr is carried across
 * @param: planidOverride - optional ID to be used for the plan rather than the auto generated one, useful for matching a real room number
 * @return: Product - the Product object that was added
 */
export const addEquipment = (
  point: Point,
  productDefinition: Equipment,
  device?: Device,
  planidOverride?: number
): Product => {
  const p = plainToClass(Product, productDefinition);
  p.setLocation({
    plan: point.plan,
    id: planidOverride ?? findLastid(productDefinition.designator) + 1,
    uniqueID: `${productDefinition.designator}${
      planidOverride ?? findLastid(productDefinition.designator) + 1
    }`,
    x: point.x,
    y: point.y,
    angle: 0,
    calloutLength: 10,
    commonArea: false,
    installStatus: INSTALLSTATUS.DESIGN,
    locatabilityName: '',
    designNotes: '',
    serviceNotes: '',
  });

  // If we have a real installed product then set its physical ID, i.e. MAC Address
  if (device) {
    p.physicalID = device.MacAddress;
  }

  siteData.equipment.push(p);
  updateStore(true);

  if (device) {
    // if a real device then force a new sync with manifest as this will copy across any other fields
    mergeManifestIntoSiteData();
  }

  logger.info('site.addEquipment', {
    detail: {
      Product: p,
    },
  });

  return p;
};

/** Find the equipment index in the equipment array from "VRU23" for example */
export const getIndexFromUniqueID = (uniqueID: string): number => {
  let index = -1;
  index = siteData.equipment.findIndex((e) => e.uniqueID == uniqueID);
  return index;
};

/** Find the equipment index in the equipment array from RF MACAddress or SCU Serial */
export const getIndexFromPhysicalID = (physicalID: number): number => {
  let index = -1;
  index = siteData.equipment.findIndex((e) => e.physicalID == physicalID);
  return index;
};

/** Find the equipment product in the equipment array from RF MACAddress or SCU Serial */
export const getProductFromPhysicalID = (
  physicalID: number
): Product | undefined => {
  const p = siteData.equipment.find((e) => e.physicalID == physicalID);
  return p;
};

/** Find the Product in the equipment array from "VRU23" for example */
export const getProductFromUniqueID = (
  finduniqueID: string
): Product | undefined => {
  const p = siteData.equipment.find((e) => e.uniqueID == finduniqueID);
  return p;
};

/** Remove a piece of equipment from the site plan */
/** TODO : when removing equipment consider the effect on the manifest for a real installation, do we alos need a user prompt if a real install */
export const removeEquipment = (id: number): void => {
  console.log(id);
  if (id >= 0 && id < siteData.equipment.length) {
    siteData.equipment.splice(id, 1);
  }

  updateStore(true);
  console.log(siteData.equipment);
};

/** Move a piece of equipment's position on the site plan */
/** @param findID: string is the indentifier ie.e VRU23 */
export const moveEquipment = (
  offsetX: number,
  offsetY: number,
  findID: string
): void => {
  const index = getIndexFromUniqueID(findID);
  if (index >= 0 && index < siteData.equipment.length) {
    const e = siteData.equipment[index];
    const point: Point = { plan: e.plan, x: e.x + offsetX, y: e.y + offsetY };
    e.setLocation(point);
    console.log(e);
  }
  updateStore(true);
};

/** modify a equiment/product with partial product data*/
export const modifyEquipment = (id: string, data: Partial<Product>): void => {
  const index = getIndexFromUniqueID(id);
  if (index >= 0 && index < siteData.equipment.length) {
    siteData.equipment[index].modifyProduct(data);
    updateStore(true);
  }
};

/** mac Address is currently on the plan design already */
export const isOnDesignPlanMAC = (macAddress: number): Product | undefined => {
  const product = siteData.equipment.find((d) => d.physicalID == macAddress);

  return product;
};

/** unit number is currently on the plan design already */
/* also pass the unique ID of product you want to exclude */
export const isOnDesignPlanUnitNumber = (
  unitNumber: number,
  uniqueID: string | undefined
): Product | undefined => {
  const product = siteData.equipment.find(
    (d) => d.unitNumber == unitNumber && d.uniqueID != uniqueID
  );
  return product;
};

/** Check the manifest for assigned devices (room units, access points and device interfaces)
 *  that are not matched to items on the site plan
 */
export const getUnMatchedAssignedDevices = (): iDevice[] => {
  const devices: iDevice[] = [];
  const possibleDevices: iDevice[] = [];
  possibleDevices.push(...manifest.rooms);
  possibleDevices.push(...manifest.accessPoints);
  possibleDevices.push(...manifest.deviceInterfaces);
  // look for unmatched MAC address
  possibleDevices.map((r) => {
    if (
      siteData.equipment.find((e) => e.physicalID == r.MacAddress) == undefined
    ) {
      devices.push(r);
    }
  });

  return devices;
};

/**
 *
 * Get MAC addresses from the manifest that don't yet appear on the plan
 */
export const getUnusedMACs = (
  devices: Room[] | AccessPoint[] | DoorAndDeviceInterface[]
): MACLIST[] => {
  const MACS: MACLIST[] = [];

  devices.forEach((r) => {
    if (
      siteData.equipment.find((e) => e.physicalID == r.MacAddress) == undefined
    ) {
      const unitNumber = r instanceof Room ? r.RoomNumber : r.UnitId;
      const unitName = r.Name;
      MACS.push({
        macnumber: r.MacAddress,
        macHEX: r.MacAddressHex,
        display: r.MacAddressHex + ' (' + r.Name + ')',
        unitName: unitName,
        unitNumber: unitNumber,
      });
    }
  });

  return MACS;
};

/** Return a list of available MAC addresses for assignment/matching
 *  These are from a merge of :-
 *  (1) Unassigned units in the manifest
 *  (2) Assigned units in the manifest but not matched to an equipment item on the site plandata
 *
 *  Item (1) is filtered to ensure that any recent changes to the design plan to match up equipment are removed form the available list
 */
export const getAvailableMACs = (e: Product): MACLIST[] => {
  // devices.push(...manifest.getUnassignedDevices());

  const MACS: MACLIST[] = [];
  if (e.designator.startsWith('VRU'))
    MACS.push(...getUnusedMACs(manifest.rooms));
  if (e.designator.startsWith('MAP') || e.designator.startsWith('MLS'))
    MACS.push(...getUnusedMACs(manifest.accessPoints));
  if (e.designator.startsWith('MAI'))
    MACS.push(...getUnusedMACs(manifest.deviceInterfaces));

  // De-dupe the MAC Addresses so that we don't have multiples
  // multiples can occur due to MAPS/MLS being returned in both lists above - they are never considered assigned so appear in the unassigned list as well

  const uniqueMACS = Array.from(
    new Map(MACS.map((item) => [item['macnumber'], item])).values()
  );

  return uniqueMACS;
};

/** Get any units that are fed from a particular MLS or POE by CAT5
 *  In the case of an MLS it would be just one product or undefined if none
 *  In the case of a POE it would be multiple products or undefined if none
 */
export const getCat5ConnectedUnits = (me: Product): Product[] => {
  const p = siteData.equipment.filter(
    (d) => d.cat5Connection && d.cat5Connection.connectedTo == me.uniqueID
  );
  return p;
};

/** get a list of unused POE connections and MLS available on this plan (floor)
 *  This is used in the design plan head up display for connecting equipment
 *  @param plan - the plan number (e.g. floor)
 *  @param POEonly - only include POE units (i.e. exclude MLS and others)
 * */
export const getAvailableCat5Connections = (
  plan: number,
  POEonly: boolean
): Product[] => {
  // get full list of MLS units on the plan on this floor
  const ports = siteData.equipment.filter(
    (e) =>
      e.plan == plan &&
      e.cat5Ports > 0 &&
      (POEonly == false ||
        (POEonly == true && Product.getProductBaseDesignator(e) == 'POE'))
  );
  // now filter to ones that don't already have a connection
  const availablePorts = ports.filter((e) => {
    const p = getCat5ConnectedUnits(e);
    // return available if nothing connected or maximum number of connections not exceeded
    return p.length < e.cat5Ports;
  });

  return availablePorts;
};

/** get a list of unused MAI connections for VPC/Lift/DDP etc
 *  This is used in the design plan head up display for connecting equipment
 *  @param plan - the plan number (e.g. floor)
 * */
export const getAvailableMAIConnections = (plan: number): Product[] => {
  // get full list of MLS units on the plan on this floor
  const mais = siteData.equipment.filter(
    (e) =>
      e.plan == plan &&
      e.designator.length > 2 &&
      e.designator.startsWith('MAI')
  );
  // now filter to ones that don't already have a connection
  const availableMAIs = mais.filter((e) => {
    const p = siteData.equipment.filter(
      (d) => d.MAIConnection && d.MAIConnection.connectedTo == e.uniqueID
    );
    // return available if nothing connected or maximum number of connections not exceeded
    return p.length == 0;
  });

  return availableMAIs;
};

/** get a list of unused MAI connections for VPC/Lift/DDP etc
 *  This is used in the design plan head up display for connecting equipment
 *  @param plan - the plan number (e.g. floor)
 * */
export const getAvailableMAPConnections = (plan: number): Product[] => {
  // get full list of MLS units on the plan on this floor
  const maps = siteData.equipment.filter(
    (e) =>
      e.plan == plan &&
      e.designator.length > 2 &&
      e.designator.startsWith('MAP')
  );
  // now filter to ones that don't already have a connection
  const availableMAPs = maps.filter((e) => {
    const p = siteData.equipment.filter(
      (d) => d.MAPConnection && d.MAPConnection.connectedTo == e.uniqueID
    );
    // return available if nothing connected or maximum number of connections not exceeded
    return p.length == 0;
  });

  return availableMAPs;
};

/** Get the equipment totals for a plan number or all plans
 * @param plan - if specified then equipment for that floor otherwise all equipment
 * */
export const getEquipmentTotals = (
  plan?: number
): { equip: Equipment; total: number }[] => {
  let equip = siteData.equipment;
  if (plan != undefined) {
    equip = siteData.equipment.filter(
      (e) => e.plan == plan && e.category != ProductCategory.THIRDPARTYOUTPUT
    );
  }

  const sum: { equip: Equipment; total: number }[] = [];

  equip.forEach((e) => {
    const i = sum.findIndex(
      (f) => f.equip && e.productCode === f.equip.productCode
    );

    if (i == -1) {
      sum.push({
        equip: products.find(
          (p) => p.productCode == e.productCode
        ) as Equipment,
        total: 1,
      });
    } else {
      sum[i].total = sum[i].total + 1;
    }
  });

  return sum;
};

export type EquipTotal = {
  equip: Equipment;
  planTotal: { planNumber: number; total: number }[];
};

/** Get the equipment totals for a plan number or all plans
 * @param plan - if specified then equipment for that floor otherwise all equipment
 * */
export const getEquipmentTotalsByEachFloor = (): EquipTotal[] => {
  const equip = siteData.equipment;
  const sum: EquipTotal[] = [];

  equip
    .filter((f) => f.category != ProductCategory.THIRDPARTYOUTPUT)
    .forEach((e) => {
      const i = sum.findIndex(
        (s) => s.equip && e.productCode === s.equip.productCode
      );

      const otherProducts =
        e.requiredOthers as unknown as includeOtherProduct[];

      if (e.requiredOthers) {
        for (let i = 0; i < otherProducts.length; i++) {
          const product = otherProducts[i];
          const y = sum.findIndex(
            (s) => s.equip && product.productCode === s.equip.productCode
          );
          if (y == -1) {
            const tot: EquipTotal = {
              equip: products.find(
                (p) => p.productCode == product.productCode
              ) as Equipment,
              planTotal: [],
            };
            siteData.plans.forEach((sp, index) => {
              const count = index == e.plan ? product.qty : 0; // add 1 if its the plan the equipment is on, or zero if not
              tot.planTotal.push({ planNumber: index, total: count });
            });
            sum.push(tot);
          } else {
            sum[y].planTotal[e.plan].total += product.qty;
          }
        }
      }

      if (i == -1) {
        //create a new row to be added to the totals table
        const tot: EquipTotal = {
          equip: products.find(
            (p) => p.productCode == e.productCode
          ) as Equipment,
          planTotal: [],
        };

        // push a new entry for each floor
        siteData.plans.forEach((sp, index) => {
          const count = index == e.plan ? 1 : 0; // add 1 if its the plan the equipment is on, or zero if not
          tot.planTotal.push({ planNumber: index, total: count });
        });

        sum.push(tot);
      } else {
        sum[i].planTotal[e.plan].total += 1;
      }
    });

  return sum;
};

/** convert BSIID to 4 digit string */
const BSIID_pad = (num: number): string => {
  const s = '0000' + num;
  return s.substring(s.length - 4);
};

// Generate an equipment list used for providing an ARC with the equipment identities
export const getARCEquipmentList = (): iARCDetail[] => {
  const equip: iARCDetail[] = [];

  // get the rooms first
  manifest.rooms.forEach((r) => {
    const p = getProductFromPhysicalID(r.MacAddress);
    const communal = p ? p.commonArea : false;
    equip.push({
      equipID: BSIID_pad(r.RoomNumber),
      description: r.Name,
      audioEnabled: 'Yes',
      type: communal ? 'Communal' : 'Room',
    });
  });

  // then any MAPs that are non 9000
  manifest.accessPoints.forEach((m) => {
    if (m.UnitId != 9000)
      equip.push({
        equipID: BSIID_pad(m.UnitId),
        description: m.Name,
        audioEnabled: 'No',
        type: 'Communal',
      });
  });

  // then any MAIs ( doors, lifts, pullcords)
  manifest.deviceInterfaces.forEach((m) => {
    // Door and Lifts have audio

    let type: iARCDetailType = 'Communal';
    let audio = false;
    switch (m.Personality) {
      case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MDI:
        audio = true;
        type = 'Door Panel';
        break;
      case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MLI:
        audio = true;
        type = 'Lift';
        break;
      case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MPI:
        audio = false;
        type = 'Assist Pullcord';
        break;
      case ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MCI:
        audio = true;
        type = 'Call Point';
        break;
    }

    // Handle any SIP door panels
    if (m.Type == ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR) {
      audio = true;
      type = 'IP Door Panel';
    }

    equip.push({
      equipID: BSIID_pad(m.UnitId),
      description: m.Name,
      audioEnabled: audio ? 'Yes' : 'No',
      type: type,
    });
  });

  // then add in the system controller
  equip.push({
    equipID: BSIID_pad(9000),
    description: 'System Control Unit',
    audioEnabled: 'No',
    type: 'System',
  });

  // sort the list by ID
  equip.sort((a, b) => {
    return a.equipID > b.equipID ? 1 : a.equipID < b.equipID ? -1 : 0;
  });

  return equip;
};

export const getLocatabilityName = (physicalID: number | undefined): string => {
  let location = '';
  if (physicalID) {
    const p = getProductFromPhysicalID(physicalID);
    if (p) {
      if (p.locatabilityName) {
        location = p.locatabilityName;
      }
    }

    if (location == '') {
      location = manifest.getDeviceNameByMac(physicalID);
    }
  }
  return location;
};
