/** @module Installation */

import React from 'react';
import { invalidID, iRoom, iRoutingEvent } from 'types/manifest-types';
import { Device, teINPUT_METHOD } from './device';
import { iDevice } from 'types/manifest-types';
import { teDEVICE_TYPES, teDEVICE_MODELS_WIM } from 'types/manifest-enums';
import { ALARM_TYPES } from 'types/event-enums';
import { Product } from 'components/floorplan/product';
import * as API from 'types/API';
import { formatDate } from 'common/utils/dateUtils';

export const WirelessDEVICE_TYPES = [
  teDEVICE_TYPES.DEVICE_TYPE_PENDANT,
  teDEVICE_TYPES.DEVICE_TYPE_PULLCORD,
  teDEVICE_TYPES.DEVICE_TYPE_SMOKE,
  teDEVICE_TYPES.DEVICE_TYPE_WIM,
  teDEVICE_TYPES.DEVICE_TYPE_FALL,
  teDEVICE_TYPES.DEVICE_TYPE_PANIC,
  teDEVICE_TYPES.DEVICE_TYPE_DOOR_ENTRY,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PERSONAL_AUTO_LB,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PERSONAL_AUTO_PRESENCE,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_TEMP_EXTREME,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_FLOOD,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_CARBON_MONOX,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_SMOKE,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_DOOR_CONTACT,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PULLCORD,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_ENURESIS,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_BED_CHAIR,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_MEDICATION_DISPENSER,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_EPILEPSY,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_WIRELESS_CALL_TERMINAL,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_FALL,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PIR,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_CARER,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PROPERTY_EXIT,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_INTRUDER_ARMDISARM,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_PANIC_BOGUSCALLER,
  teDEVICE_TYPES.DEVICE_TYPE_TUNSTALL_NATURAL_GAS,
];

/** return true if the device type is one of the wireless devices/triggers */
export const isWirelessDevice = (type: teDEVICE_TYPES): boolean => {
  return WirelessDEVICE_TYPES.includes(type);
};

/*
 * Trigger device class - this covers all wireless alarm triggers such as pendants. falls pendants, panic alarms, pullcords, WIM etc...
 * @class
 * @hideconstructor
 * @implements iRoom
 */

export class Trigger extends Device {
  ID = invalidID;
  Description = '';
  InUse = false;
  LoggedOn = false;
  MicVolume = 0; //not used
  FeatureMask = 0;
  lastEvent: API.OmniviaLiveEventsLog | undefined;
  dataChanged = false;
  parentRoom: iRoom | undefined = undefined;

  constructor(
    deviceValues: iDevice,
    parentRoom: iRoom | undefined,
    routingEvents: iRoutingEvent[]
  ) {
    super(deviceValues, routingEvents);
    this.ID = deviceValues.ID;
    this.parentRoom = parentRoom;

    this.Description = Product.getProductfromManifestDeviceType(this).name;
    // if (parentRoom) {
    //   this.Description = this.Description + ' - ' + parentRoom.Name;
    // }

    if (deviceValues.HwModel == teDEVICE_MODELS_WIM.DEVICE_WIM_MODEL_2_4GHZ) {
      this.InputAccessor = {
        Method: teINPUT_METHOD.INPUT_METHOD_SINGLE_EVENT,
        RoutingEventTypes: [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1],
      };
    } else if (
      deviceValues.HwModel ==
        teDEVICE_MODELS_WIM.DEVICE_WIM_MODEL_UNIVERSAL_869 ||
      deviceValues.HwModel ==
        teDEVICE_MODELS_WIM.DEVICE_WIM_MODEL_UNIVERSAL_EPILEPSY
    ) {
      this.InputAccessor = {
        Method: teINPUT_METHOD.INPUT_METHOD_SINGLE_EVENT,
        RoutingEventTypes: [
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1,
          ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2,
        ],
      };
    }
  }

  generateChangeSQL(): { update: string; revert: string } {
    return super.generateChangeSQL();
  }

  async applyManifestChanges(): Promise<void> {
    return super.applyManifestChanges();
  }

  /**
   * GetIdentity
   * @returns the identity of the trigger in hex (Wireless Health MACAdddress) or decimal (Tunstall ID)
   */
  getIdentity(): string {
    if (this.MacAddressHex.length > 6) {
      return this.MacAddressHex.substring(this.MacAddressHex.length - 6); // WH
    } else {
      return parseInt(this.MacAddressHex, 16).toString(); // Tunstall
    }
  }

  toolTip(): JSX.Element {
    let roomNumber = '';
    if (this.parentRoom) {
      roomNumber = this.parentRoom.RoomNumber.toString();
    }

    const identity = this.getIdentity();

    return (
      <div>
        <p>
          {this.getDeviceDescription()} - {this.Description}
        </p>
        <ul>
          <li>Room Number: {roomNumber}</li>
          <li>Device ID: {this.ID.toString()}</li>
          <li>Identity: {identity}</li>
          <li>Assigned: {formatDate(this.DateAdded)}</li>
          {this.AddedBy != '' && <li>By: {this.AddedBy}</li>}
        </ul>
      </div>
    );
  }
}
