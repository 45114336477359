export const roundNumber = (number: number, decimal_digit: number): number => {
  const powerOften = Math.pow(10, decimal_digit);
  return Math.round(number * powerOften) / powerOften;
};

/** containsonlyDigits
 *  returns true if the string contains only digits
 *
 **/
export const containsOnlyDigits = (str: string): boolean =>
  /^[+-]?\d+(\.\d+)?$/.test(str);

export const isWithinRange = (
  str: string,
  min: number,
  max: number
): boolean => {
  if (containsOnlyDigits(str)) {
    const n = Number(str);
    return n >= min && n < max;
  }
  return false;
};

export const isBoolean = (str: string) => {
  return str === '1' || str === '0';
};
