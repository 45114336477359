import { get, isEqual, keys, reduce, set } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isEmpty(value: any): any {
  return (
    (typeof value == 'string' && !value.trim()) ||
    typeof value == 'undefined' ||
    value === null
  );
}

/*
 *  creates a sleep delay, use as await sleep(1000) for 1 second etc...
 */
export function sleep(ms: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function basicuuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function splitStringIntoArray(lines: string): string[] {
  return lines.split('\n').filter((element) => element);
}

/**
 * Compare the modifiedObject object with the originalObject.
 * It returns a new object with just the properties that have changed
 * If a property is in the original but not in the modified it will be ignored
 *
 * The differeances can be used to create the graphql mutation to update
 * the database
 *
 * If the store property is null, treat this the same as and empty string
 * @returns New object with changes
 */

/*  TYPESCRIPT
export function getDifferences(
  originalObject: unknown,
  modifiedObject: unknown
) {
  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function getDifferences(originalObject: any, modifiedObject: any) {
  if (!originalObject) return modifiedObject;

  if (originalObject && modifiedObject) {
    const _keys = keys(modifiedObject);
    const difference = reduce(
      _keys,
      function (result, key) {
        const old = get(originalObject, key);
        const newValue = get(modifiedObject, key);
        if (!(old === null && newValue === '') && !isEqual(old, newValue)) {
          set(result, key, newValue);
        }
        return result;
      },
      {}
    );
    return difference;
  }
  return {};
}
