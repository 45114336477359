import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import * as eventDispatcher from 'store/eventDispatcher';
import * as dbAccess from './dbAccess';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { createExtraReducers } from './commonReducers.js';
import store, { RootState } from 'store/store';
import { OmniviaSite } from 'types/API';
import { initialSliceState } from 'store/commonSlice';
import logger from 'common/logger';
import * as API from 'types/API';
import * as Amp from 'aws-amplify';
import _ from 'lodash';
import * as SiteAuth from 'common/siteAuthUtils';

const dbAccessInfo = {
  DAOname: 'Sites',
  allQuery: queries.listOmniviaSites,
  allSelector: 'listOmniviaSites',
  allSort: 'name',
  selectQuery: queries.getOmniviaSite,
  selectSelector: 'getOmniviaSite',
};

const getState = (state: RootState) => state;

export const getSlice = createSelector([getState], (state: RootState) => {
  return state.sites;
});

const adapter = createEntityAdapter<OmniviaSite>({
  //selectId: (object) => object.id, // id is the default
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const { selectAll, selectById } = adapter.getSelectors(
  (state: RootState) => state.sites
);

const initialState = adapter.getInitialState(initialSliceState);

// Thunk functions
export const fetch = createAsyncThunk('sites/fetch', async () => {
  const response = await dbAccess.loadData(dbAccessInfo, {
    limit: 2000,
  });
  return response;
});

export const select = createAsyncThunk(
  'sites/select',
  async (site: { id: string }) => {
    const response = await dbAccess.select(dbAccessInfo, { id: site.id });
    console.info('*************** SELECT SITE:', site.id);
    logger.info('sitesSlice.select', { siteSelectedID: site.id });
    return response;
  }
);

export const update = createAsyncThunk(
  'sites/update',
  async (update: { id: string; changes: unknown }) => {
    const inputVars = _.cloneDeep(update.changes) as API.UpdateOmniviaSiteInput;
    _.set(inputVars, 'id', update.id);
    const variables = {
      input: inputVars,
    };

    console.info('sites/update vars:', variables);

    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(mutations.updateOmniviaSite, variables)
    )) as { data: { updateOmniviaSite: OmniviaSite } };

    console.info('sites/update:', response);
    return response.data.updateOmniviaSite;
  }
);

export const add = createAsyncThunk(
  'sites/add',
  async (add: { changes: unknown }) => {
    const variables = {
      input: add.changes,
    };

    console.info('sites/add vars:', variables);

    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(mutations.createOmniviaSite, variables)
    )) as { data: { createOmniviaSite: OmniviaSite } };

    console.info('sites/add:', response);

    return response.data.createOmniviaSite;
  }
);

export const deleteRecord = createAsyncThunk(
  'sites/deleteRecord',
  async (deleteVars: { id: string }) => {
    const variables = {
      input: deleteVars,
    };
    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(mutations.deleteOmniviaSite, variables)
    )) as { data: { deleteOmniviaSite: OmniviaSite } };

    console.info('sites/delete:', response);
    return response.data.deleteOmniviaSite;
  }
);

const slice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    // eslint-disable-next-line
    clearSelected(state, action) {
      state.selectedId = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(update.fulfilled, (state, action) => {
      const site = action.payload;
      //state.entities[site.id] = site;

      adapter.setOne(state, action.payload);
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.SITE,
        eventDispatcher.systemEventStates.UPDATED,
        site,
        true
      );
    });

    builder.addCase(update.rejected, () => {
      console.info('error:');
    });

    builder.addCase(add.fulfilled, (state, action) => {
      const site = action.payload;
      //state.entities[site.id] = site;
      adapter.addOne(state, action.payload);
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.SITE,
        eventDispatcher.systemEventStates.UPDATED,
        site,
        true
      );
      setTimeout(
        async () => await SiteAuth.setupSiteAuthorisation(site.id),
        10
      );
    });

    builder.addCase(deleteRecord.fulfilled, (state, action) => {
      const site = action.payload;
      //state.entities[site.id] = site;
      adapter.removeOne(state, action.payload.id);
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.SITE,
        eventDispatcher.systemEventStates.UPDATED,
        undefined,
        true
      );
      setTimeout(
        async () => await SiteAuth.removeSiteAuthorisationGroup(site.id),
        10
      );
    });

    createExtraReducers(
      eventDispatcher.systemEventTopics.SITE,
      builder,
      fetch,
      select,
      adapter
    );
  },
});

export const { clearSelected } = slice.actions;

export default slice.reducer;

export const getSelected = createSelector([getState], (state: RootState) => {
  return selectById(state, state.sites.selectedId);
});

export const getAll = createSelector([getState], (state: RootState) => {
  return selectAll(state);
});

export const siteHasSCUAssigned = (): boolean => {
  const selectedSite = getSelected(store.getState());
  let siteHasSCUAssigned = false;
  if (
    selectedSite &&
    selectedSite.linkedSCUs &&
    selectedSite.linkedSCUs.items
  ) {
    siteHasSCUAssigned = true;
  }

  return siteHasSCUAssigned;
};
