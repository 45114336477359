import * as Site from 'components/floorplan/site';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { concat } from 'lodash';
import { Equipment } from 'types/product-types';
import { Device } from 'components/installation/device';
import { ProductCategory } from '../../types/product-types';

type EquipTotal = {
  equip?: Equipment;
  planTotal: { planNumber: number; total: number }[];
};

/** Function to create an spreadsheet detailing the current installation status */
export const createInstallStatusSheet = async (): Promise<void> => {
  const wb = new ExcelJS.Workbook();
  const SummaryWS = wb.addWorksheet('Summary');
  const InstallWS = wb.addWorksheet('Installation Status');
  const fillColour = 'd58feb';
  let row: string[] = [];
  const headers: string[] = ['Key', 'Code', 'Description'];
  let contents: string[] = [];
  let contents_: string[] = [];
  let Row;
  let equipTotals: EquipTotal[] = [];
  Site.siteData.plans.forEach((item, index) => {
    headers.push(Site.siteData.plans[index].planName);
    equipTotals = Site.getEquipmentTotalsByEachFloor();
    Row = InstallWS.addRow([item.planName]);
    Row.getCell(1).border = {
      top: { style: 'thick' },
      left: { style: 'thick' },
      bottom: { style: 'thick' },
      right: { style: 'thick' },
    };
    Row.getCell(1).style.fill = {
      // set colour of floor name to blue
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: fillColour },
    };
    Row.getCell(1).style.font = {
      // set floor name to bold
      bold: true,
    };
    Row = InstallWS.addRow([
      'Product code',
      'Description',
      'Plan ID',
      'Room',
      'Communal',
      'MAC address',
      'Installation Status',
      'Design Notes',
      'Service Notes',
    ]);
    for (let i = 1; i < 10; i++) {
      Row.getCell(i).style.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: fillColour },
      };
    }

    Site.siteData.equipment.forEach((equip) => {
      if (
        equip.plan == index &&
        equip.category != ProductCategory.THIRDPARTYOUTPUT
      ) {
        let macAddress = 'N/A';
        if (equip.productUsesMACAddress()) {
          macAddress = equip.physicalID
            ? Device.MACaddrToString(equip.physicalID, 16)
            : 'Unassigned';
        }
        if (equip.productCode)
          row = [
            equip.productCode,
            equip.name,
            `${equip.designator}${equip.id}`,
            equip.locationName,
            equip.commonArea?.toString(),
            macAddress,
            equip.installStatus,
            equip.designNotes,
            equip.serviceNotes,
          ];
        InstallWS.addRow(row);
      }
    });
  });

  equipTotals.forEach((e) => {
    let sum = 0;
    for (let i = 0; i < Site.siteData.plans.length; i++) {
      contents.push(e.planTotal[i].total.toString());
    }
    for (let i = 0; i < contents.length; i++) {
      sum += parseInt(contents[i]);
    }
    contents.push(sum.toString());
    contents_.push(
      e.equip?.designator ?? '',
      e.equip?.productCode ?? '',
      e.equip?.name ?? ''
    );
    SummaryWS.addRow(concat(contents_, contents));
    contents = [];
    contents_ = [];
  });

  headers.push('Total');
  Row = SummaryWS.insertRow(1, headers);
  for (let i = 1; i < headers.length + 1; i++) {
    Row.getCell(i).style.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: fillColour },
    };
  }

  // set the width of columns A to G
  const colA = InstallWS.getColumn(1);
  colA.width = 13;
  let colB = InstallWS.getColumn(2);
  colB.width = 35;
  let colC = InstallWS.getColumn(3);
  colC.width = 9;
  const colD = InstallWS.getColumn(4);
  colD.width = 9;
  const colE = InstallWS.getColumn(5);
  colE.width = 11;
  const colF = InstallWS.getColumn(6);
  colF.width = 18;
  const colG = InstallWS.getColumn(7);
  colG.width = 18;
  const colH = InstallWS.getColumn(8);
  colH.width = 40;
  const colI = InstallWS.getColumn(9);
  colI.width = 40;

  colB = SummaryWS.getColumn(2);
  colB.width = 14;
  colC = SummaryWS.getColumn(3);
  colC.width = 35;

  // set each cell of each row to have a thin border
  InstallWS.eachRow((row) => {
    row.eachCell((cell: ExcelJS.Cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });

  // set each cell of each row to have a thin border
  SummaryWS.eachRow((row) => {
    row.eachCell((cell: ExcelJS.Cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });

  wb.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: '' });
    FileSaver.saveAs(
      blob,
      `${Site.siteData.quoteRef} ${Site.siteData.name} - Wireless Health Equipment R${Site.siteData.revision}.xlsx` // set name of file
    );
  });
};
