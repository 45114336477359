export enum teCLOUD_MSG_TY_EVENTS {
  E_CLOUD_MSG_EVENTS_NEW_EVENT = 0,
  E_CLOUD_MSG_EVENTS_NEW_AUDIT,
  E_CLOUD_MSG_EVENTS_ACK,
  E_CLOUD_MSG_EVENTS_CONFIG_CHANGE,
  E_CLOUD_MSG_EVENTS_MAX,
}

export interface tsE_CLOUD_MSG_EVENTS_CONFIG_CHANGE {
  created: string;
  sqlData: string[];
}
