import React, { useState } from 'react';
import 'types/event-enums';
import Tile, { iTileEvent } from '../../tile';
import { Manifest } from 'components/installation/manifest';
import { Grid, Text, Box, Tip, Image } from 'grommet';
import { Handset } from 'components/installation/handset';
// import { batteryEventTarget } from 'components/systemevents/batteryEvents';
import styled from 'styled-components';
import handsetOnChargesvg from 'assets/img/handsetOnCharge.svg';
import handsetScreenOnsvg from 'assets/img/handsetScreenOn.svg';
import handset from 'assets/img/handset.svg';
// import { TileChildProps } from '../tile';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';

const manifest = new Manifest();

// const Overlay = styled.div`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   margin-left: 10px;
//   margin-top: 0px;
//   display: 'flex';
// `;

const HandsetImage = styled(Image)`
  padding: 4px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
`;

const HandsetsOverview = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  //*****rework this fn to only use new event data....
  const newDataEvent = () => {
    let gotData = true;
    manifest.handsets.forEach((h) => {
      h.getHandsetChargeInfoHistory();
      if (h.BatteryEvents.length > 0) {
        gotData = true;
      }
    });

    if (gotData) {
      setShowSpinner(false);
    } else {
      setShowSpinner(true);
    }
  };

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.PROCESSED,
      callback: newDataEvent,
      executeOnStartup: true,
    },
  ];

  return (
    <Tile
      title="All Handsets"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      showLastEvent={true}
      showSpinner={showSpinner}
    >
      <>
        {!showSpinner && (
          <Grid
            columns={['flex', 'flex', 'flex', 'flex', 'flex']}
            rows={['flex', 'flex', 'flex']}
            gap="none"
            margin="none"
            areas={[
              { name: 'HS1', start: [0, 0], end: [0, 0] },
              { name: 'HS2', start: [1, 0], end: [1, 0] },
              { name: 'HS3', start: [2, 0], end: [2, 0] },
              { name: 'HS4', start: [3, 0], end: [3, 0] },
              { name: 'HS5', start: [4, 0], end: [4, 0] },

              { name: 'HS6', start: [0, 1], end: [0, 1] },
              { name: 'HS7', start: [1, 1], end: [1, 1] },
              { name: 'HS8', start: [2, 1], end: [2, 1] },
              { name: 'HS9', start: [3, 1], end: [3, 1] },
              { name: 'HS10', start: [4, 1], end: [4, 1] },

              { name: 'HS11', start: [0, 2], end: [0, 2] },
              { name: 'HS12', start: [1, 2], end: [1, 2] },
              { name: 'HS13', start: [2, 2], end: [2, 2] },
              { name: 'HS14', start: [3, 2], end: [3, 2] },
              { name: 'HS15', start: [4, 2], end: [4, 2] },
            ]}
          >
            {manifest.handsets &&
              manifest.handsets.map((h, index) => (
                <Box
                  pad="none"
                  margin={{ bottom: '3px' }}
                  key={index}
                  gridArea={`HS${index + 1}`}
                >
                  <Tip
                    dropProps={{
                      align: { left: 'right' },
                      margin: { left: 'none' },
                    }}
                    content={
                      <Box margin="none">
                        <Text>
                          MacAddress: {h.getDeviceMACaddr().substring(8)}
                        </Text>
                        <Text>
                          {h.lastKnownLocation &&
                            `The handset was last seen in the ${h.lastKnownLocation} area.`}

                          {h.lastKnownLocation == '' &&
                            `There is no location information for this handset.`}
                        </Text>
                      </Box>
                    }
                  >
                    <Box>
                      <Text>{h.Description}</Text>
                      {h.BatteryEvents && h.BatteryEvents.length > 0 && (
                        <>
                          {Handset.onCharge(h) && (
                            <HandsetImage
                              src={handsetOnChargesvg}
                              width="100"
                              alignSelf="center"
                            />
                          )}
                          {Handset.screenOn(h) && !Handset.onCharge(h) && (
                            <HandsetImage src={handsetScreenOnsvg} />
                          )}
                          {!Handset.onCharge(h) && !Handset.screenOn(h) && (
                            <HandsetImage src={handsetScreenOnsvg} />
                          )}

                          <Text>{Handset.chargeLevelText(h)}</Text>
                        </>
                      )}

                      {!h.BatteryEvents ||
                        (h.BatteryEvents.length == 0 && (
                          <HandsetImage src={handset} />
                        ))}
                    </Box>
                  </Tip>
                </Box>
              ))}
          </Grid>
        )}
      </>
    </Tile>
  );
};

export default HandsetsOverview;
