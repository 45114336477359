import {
  teCLOUD_MESSAGE_TYPES,
  teCLOUD_ERROR_CODES,
} from './IoTMessageDefines';
import {
  teCLOUD_MSG_TY_TEST,
  tsCLOUD_MSG_TEST_RAISE_ALARM_REQ,
  tsCLOUD_MSG_TEST_RAISE_ALARM_RES,
  tsCLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_REQ,
  tsCLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_RES,
} from './IoTMessageDefines_TEST';
import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
import { ALARM_TYPES } from 'types/event-enums';
import logger from 'common/logger';

export type { teCLOUD_ERROR_CODES } from './IoTMessageDefines';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

const _raiseAlarm = async (
  deviceId: number,
  alarmType: number,
  userData: unknown
): Promise<iIotResponseEventDetail> => {
  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_TEST;
  const opcode = teCLOUD_MSG_TY_TEST.E_CLOUD_MSG_TEST_RAISE_ALARM_REQ;
  const rplyopcode = teCLOUD_MSG_TY_TEST.E_CLOUD_MSG_TEST_RAISE_ALARM_RES;
  const msg: tsCLOUD_MSG_TEST_RAISE_ALARM_REQ = {
    deviceid: deviceId,
    alarmtype: alarmType,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'raiseAlarm',
    userData
  );
};

export const raiseAlarm = async (
  deviceId: number,
  alarmType: ALARM_TYPES,
  userData?: unknown
): Promise<teCLOUD_ERROR_CODES> => {
  let errorCode = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_TIMEOUT;
  const promise = new Promise<teCLOUD_ERROR_CODES>((resolve) => {
    (async () => {
      try {
        const resp = await _raiseAlarm(deviceId, alarmType, userData);
        const scuRes = resp.msgHeader?.msg as tsCLOUD_MSG_TEST_RAISE_ALARM_RES;
        if (scuRes) {
          errorCode = scuRes.errorcode;
          if (errorCode != teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE) {
            logger.info('IoT.IoTTest.raiseAlarm', {
              error: errorCode,
            });
          }
        } else {
          logger.error('IoT.IoTTest.raiseAlarm', {
            error: 'No Response',
          });
        }
      } catch (error) {
        logger.error('IoT.IoTTest.raiseAlarm', {
          error: error,
        });
      }

      resolve(errorCode);
    })();
  });
  return promise;
};

const _residentTestMode = async (
  residenttableid: number,
  enabled: boolean,
  duration: number,
  userData?: unknown
): Promise<iIotResponseEventDetail> => {
  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_TEST;
  const opcode =
    teCLOUD_MSG_TY_TEST.E_CLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_TEST.E_CLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_RES;
  const msg: tsCLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_REQ = {
    residenttableid: residenttableid,
    enabled: enabled,
    duration: duration,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'residentTestMode',
    userData
  );
};

export const residentTestMode = async (
  residenttableid: number,
  enabled: boolean,
  duration: number, // seconds
  userData?: unknown
): Promise<teCLOUD_ERROR_CODES> => {
  let errorCode = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_TIMEOUT;

  const promise = new Promise<teCLOUD_ERROR_CODES>((resolve) => {
    (async () => {
      try {
        const resp = await _residentTestMode(
          residenttableid,
          enabled,
          duration,
          userData
        );
        const scuRes = resp.msgHeader
          ?.msg as tsCLOUD_MSG_ACTIVATE_RESIDENT_TEST_MODE_RES;
        if (scuRes) {
          errorCode = scuRes.errorcode;
          if (errorCode != teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE) {
            logger.info('IoT.IoTTest.residentTestMode', {
              error: errorCode,
            });
          }
        } else {
          logger.error('IoT.IoTTest.residentTestMode', {
            error: 'No Response',
          });
        }
      } catch (error) {
        logger.error('IoT.IoTTest.residentTestMode', { error: error });
      }

      resolve(errorCode);
    })();
  });
  return promise;
};
