/** @module FloorPlans */

import { fabric } from 'fabric';
import { Terminal } from 'types/cable-types';
import * as Cabling from './cabling';
import { CableSpec } from '../../types/cable-types';

const drawCable = (
  spec: CableSpec,
  from: Terminal,
  to: Terminal,
  canvas: fabric.Canvas
) => {
  const polyline = new fabric.Polyline(
    [
      {
        x: from.position.x,
        y: from.position.y,
      },
      {
        x: from.position.x,
        y: from.position.y - 8,
      },
      {
        x: to.position.x,
        y: from.position.y - 8,
      },
      {
        x: to.position.x,
        y: to.position.y,
      },
    ],
    {
      fill: 'transparent',
      stroke: spec.planColour,
      strokeWidth: 2,
      strokeDashArray: spec.planDashArray,
    }
  );

  const group = new fabric.Group([polyline], {
    hasControls: false,
    name: `${from.cableTag}`,
    hoverCursor: 'pointer',
    selectable: false,
  });

  canvas.add(group);
};

export const showCables = (planNumber: number, canvas: fabric.Canvas): void => {
  // get the cables for the plan
  Cabling.generateCables(planNumber);

  // draw each cable

  const sch = Cabling.cableSchedule;
  sch.forEach((c) => {
    if (
      c.showOnPlan &&
      c.terminalTo.position.x != 0 &&
      c.terminalTo.position.y != 0 &&
      c.cableSpec
    ) {
      drawCable(c.cableSpec, c.terminalFrom, c.terminalTo, canvas);
    }
  });
};

/*  Currently not used
export const removeCables = (
  planNumber: number,
  canvas: fabric.Canvas
): void => {
  const p = planNumber;
};
*/
