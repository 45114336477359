import React, { useEffect, useState } from 'react';
import 'types/event-enums';
import Tile, { iTileEvent } from 'components/dashboard/tile';
import { Manifest } from 'components/installation/manifest';
import { iHandSetChargeEvent } from 'types/manifest-types';

import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import TablePaged from 'components/tables/TablePaged';

const manifest = new Manifest();

interface iTableData {
  name: string;
  event: iHandSetChargeEvent;
}

let hsChangeInfo: iTableData[] = [];

const HandsetChargeTable = (): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const loadDataEvent = () => {
    hsChangeInfo = [];

    manifest.handsets.forEach((h) => {
      const bEvents = h.getHandsetChargeInfoHistory();

      const hsInfoArray = bEvents.map((ev) => {
        return { event: ev, name: h.Description };
      });

      hsChangeInfo = hsChangeInfo.concat(hsInfoArray);
    });
  };

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.SITEEVENTS,
      state: systemEventStates.COMPLETE,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
  ];

  /*
  batteryVoltageNative: number,
  batteryCurrent: number,
  isCharging: boolean,
  isScreenOn: boolean,
  chargerProtectionStateOld: number,
  chargerProtectionStateNew: number,
  currentBatteryCapacity: number,
  batteryTemperature: number,
  maxChargeLvl: number,
  isChargerEnabled: boolean,
  isOvertemp: boolean,
  cycleOnTime: number,
  cycleOffTime: number,
*/

  const columns = React.useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'event.Timestamp',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Android Voltage',
        accessor: 'event.Info.batteryVoltageNative',
      },
      {
        Header: 'mA',
        accessor: 'event.Info.batteryCurrent',
      },
      {
        Header: '%',
        accessor: 'event.Info.currentBatteryCapacity',
      },
      {
        Header: 'onCharge',
        accessor: (row: iTableData) =>
          row.event.Info.isCharging ? 'Yes' : 'No',
      },
      {
        Header: 'isScreenOn',
        accessor: (row: iTableData) =>
          row.event.Info.isScreenOn ? 'Yes' : 'No',
      },
      {
        Header: 'isChargerEnabled',
        accessor: (row: iTableData) =>
          row.event.Info.isChargerEnabled ? 'Yes' : 'No',
      },
      {
        Header: 'cycleOnTime',
        accessor: 'event.Info.cycleOnTime',
      },
    ],
    []
  );

  const initialState = React.useMemo(
    () => ({
      pageSize: 25,
      sortBy: [
        {
          id: 'Time',
          desc: false,
        },
      ],
      filters: [],
    }),
    []
  );

  useEffect(() => {
    //loadDataEvent();
    //SetChildEvent('STARTED');
  }, []);

  return (
    <Tile
      title="Charging History Table"
      eventListeners={events}
      setChildEvent={SetChildEvent}
    >
      <>
        <div>
          <TablePaged
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columns={columns}
            initialState={initialState}
            data={hsChangeInfo}
            showCheckboxes={false}
            reportDescription={{
              header: '',
              filename: 'HandsetChargeTable',
            }}
          />
        </div>
      </>
    </Tile>
  );
};

export default HandsetChargeTable;
