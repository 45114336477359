import { iExcelColumn, iExcelSchema } from 'common/excel/createExcel';
import { Manifest } from 'components/installation/manifest';
import { createExcel } from 'common/excel/createExcel';
import {} from 'common/utils/dateUtils';
import { headerRows } from 'common/excel/headerRows';
import { Worksheet } from 'exceljs';
import * as Site from 'components/floorplan/site';
import store from 'store/store';
import * as sitesslice from 'store/sitesSlice';

const manifest = new Manifest();

// const eightDigitPad = (num: number): string => {
//   const s = '00000000' + num;
//   return s.substring(s.length - 8);
// };

const reportColumns: iExcelColumn[] = [
  {
    columnName: 'Equipment ID',
    columnWidth: 28,
    fieldName: 'equipID',
    type: 'TEXT',
  },
  {
    columnName: 'Description',
    columnWidth: 30,
    fieldName: 'description',
    type: 'TEXT',
  },
  {
    columnName: 'Type',
    columnWidth: 14,
    fieldName: 'type',
    type: 'TEXT',
  },
  {
    columnName: 'Audio Enabled',
    columnWidth: 14,
    fieldName: 'audioEnabled',
    type: 'TEXT',
  },
];

export const arcHeaderRows = (sheet: Worksheet): void => {
  // call generic header first
  headerRows(sheet);
  const selectedSite = sitesslice.getSelected(store.getState());

  const BSIID = manifest.getSystemParameter('BSI_SCHEME_ID')?.Value;

  let address = selectedSite?.addr1 ?? '';
  address = address.concat(
    ' ',
    selectedSite?.addr2 != null ? selectedSite?.addr2 : '',
    ' ',
    selectedSite?.addr3 != null ? selectedSite?.addr3 : '',
    ' ',
    selectedSite?.city != null ? selectedSite?.city : '',
    ' ',
    selectedSite?.county != null ? selectedSite?.county : '',
    ' ',
    selectedSite?.postcode != null ? selectedSite?.postcode : ''
  );

  let Row = sheet.addRow(['Site Address', address]);
  Row.getCell(1).style.font = {
    size: 11,
    bold: true,
  };

  Row = sheet.addRow(['BSI ANALOG PROTOCOL', 'BS 8521:2009']);
  Row.getCell(1).style.font = {
    size: 11,
    bold: true,
  };

  Row = sheet.addRow([
    'BSI ID',
    BSIID !== '9999' ? BSIID : 'To be advised by monitoring centre',
  ]);

  let ARCtel = '';

  ARCtel = manifest.endpointCCDTMF[0].Number;
  if (ARCtel === null || ARCtel === undefined) {
    ARCtel = '';
  }
  ARCtel = ARCtel.replace('w', '');

  Row = sheet.addRow(['ARC Telephone No:', ARCtel]);

  Row = sheet.addRow(['BSI DIGITAL IP PROTOCOL', 'BS 8521-2']);
  Row.getCell(1).style.font = {
    size: 11,
    bold: true,
  };

  const IPARC1 = manifest.endPointCCSIP[0];
  const IPARC2 = manifest.endPointCCSIP[1];

  Row = sheet.addRow([
    'ARC1: ' + IPARC1.Description,
    IPARC1.RegistryURI,
    'SCHEME ID: ',
    IPARC1.UnitId,
  ]);
  Row = sheet.addRow([
    'ARC2: ' + IPARC2.Description,
    IPARC2.RegistryURI,
    'SCHEME ID: ',
    IPARC2.UnitId,
  ]);
};

export const exampleReportDetails: iExcelSchema = {
  reportName: 'arcUnitReport', // name of the report in the tables export button that the user clicks on
  reportDescription: 'Details of all unit IDs for submission to the ARC', // explanantion of the report
  filteredData: false, // whether to use a tables filtered data or all data true=filtered
  fileName: 'ARC Equipment Details Report', // excel file name to create
  workSheetName: 'Units', // worksheet name
  title: 'ARC Equipment Details Report', // Title on worksheet
  columns: reportColumns, // column information
  headerRows: arcHeaderRows,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let data: any[] = [];

const assembleData = (): void => {
  data = Site.getARCEquipmentList();
};

// rename this to the specific report name
export const generateARCUnitDetailsReport = async (): Promise<void> => {
  assembleData();
  await createExcel(exampleReportDetails, data);
};
