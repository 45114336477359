import { UAG } from 'common/userUtils';
import { iLayoutView } from './widgetTypes';
//

export const desktopDashboards: Array<iLayoutView> = [
  {
    name: 'Blank',
    accessLevel: UAG.BASIC,
    layouts: [],
  },

  {
    name: 'Overview Dashboard',
    accessLevel: UAG.BASIC,
    layouts: [
      { i: 'HandsetOverview', x: 0, y: 0, w: 12, h: 11 },
      { i: 'AlarmsByType', x: 12, y: 0, w: 12, h: 11 },
      { i: 'RecentAlarmsOverview', x: 0, y: 11, w: 12, h: 12 },
      { i: 'RecentDoorCallsOverview', x: 12, y: 11, w: 12, h: 12 },

      { i: 'LowBatteryPeripheralsOverview', x: 0, y: 23, w: 12, h: 12 },
      { i: 'ResidentNotOKOverview', x: 12, y: 23, w: 12, h: 12 },

      //{ i: 'ResidentOK', x: 0, y: 50, w: 24, h: 14 },
    ],
  },
  {
    name: 'OK Monitoring',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'ResidentOK', x: 0, y: 0, w: 20, h: 20 }],
  },
  {
    name: 'Announcements',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'Announcements', x: 0, y: 0, w: 20, h: 20 }],
  },

  {
    name: 'Global Monitoring Settings',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'GlobalMonitoringSettings', x: 0, y: 0, w: 10, h: 6 }],
  },
  {
    name: 'Alarm call history',
    accessLevel: UAG.BASIC,
    layouts: [
      { i: 'RecentAlarms', x: 0, y: 0, w: 20, h: 18 },
      { i: 'AlarmAuditTrail', x: 0, y: 13, w: 20, h: 25 },
    ],
  },
  {
    name: 'Alarm Types and Priorities',
    accessLevel: UAG.TECHNICAL,
    layouts: [{ i: 'AlarmTypes', x: 0, y: 0, w: 20, h: 24 }],
  },
  {
    name: 'Door call history',
    accessLevel: UAG.BASIC,
    layouts: [
      { i: 'DoorCalls', x: 0, y: 0, w: 20, h: 18 },
      { i: 'AlarmAuditTrail', x: 0, y: 13, w: 20, h: 25 },
    ],
  },

  {
    name: 'Pendants & Peripherals Dashboard',
    accessLevel: UAG.BASIC,

    layouts: [
      { i: 'TriggersViewLowBattery', x: 0, y: 0, w: 20, h: 12 },
      { i: 'TriggersView', x: 0, y: 10, w: 20, h: 26 },
    ],
    props: [{ lowBatteryOnly: true }, {}],
  },
  {
    name: 'Room Dashboard',
    accessLevel: UAG.BASIC,

    layouts: [
      { i: 'Resident', x: 0, y: 0, w: 24, h: 16 },
      { i: 'RecentAlarmsOverview', x: 0, y: 11, w: 12, h: 12 },
      { i: 'RecentDoorCallsOverview', x: 12, y: 11, w: 12, h: 12 },
    ],
    props: [{}, { disableFilters: true }, { disableFilters: true }],
  },

  {
    name: 'Room Group',
    accessLevel: UAG.BASIC,
    layouts: [{ i: 'ResidentGroups', x: 0, y: 0, w: 18, h: 16 }],
  },

  {
    name: 'Handset Dashboard', // not used, dev stuff
    accessLevel: UAG.ADMIN,
    layouts: [
      { i: 'HandsetOverview', x: 0, y: 0, w: 10, h: 10 },
      { i: 'HandsetChargeGraph', x: 0, y: 0, w: 20, h: 20 },
    ],
  },
  {
    name: 'Handset Config Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'HandsetConfigTable', x: 0, y: 0, w: 12, h: 20 }],
  },
  {
    name: 'Care Groups',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'CareGroups', x: 0, y: 0, w: 18, h: 18 }],
  },
  {
    name: 'Reports Dashboard',
    accessLevel: UAG.BASIC,
    layouts: [{ i: 'Reports', x: 0, y: 0, w: 18, h: 20 }],
  },
  {
    name: 'Service Test Mode',
    accessLevel: UAG.MANAGER,
    layouts: [
      { i: 'ServiceTestMode', x: 0, y: 0, w: 18, h: 12 },
      { i: 'RoomsInTestMode', x: 0, y: 20, w: 18, h: 12 },
    ],
  },
  {
    name: 'User Admin',
    accessLevel: UAG.ELITE,
    layouts: [{ i: 'UserAdmin', x: 0, y: 0, w: 24, h: 19 }],
  },
  {
    name: 'Client Admin',
    accessLevel: UAG.ELITE,
    layouts: [
      { i: 'Clients', x: 0, y: 0, w: 24, h: 20 },
      { i: 'ClientEdit', x: 0, y: 20, w: 20, h: 24 },
    ],
  },
  {
    name: 'Site Admin',
    accessLevel: UAG.ELITE,
    layouts: [
      { i: 'Sites', x: 0, y: 0, w: 24, h: 20 },
      { i: 'SiteEdit', x: 0, y: 20, w: 20, h: 24 },
      { i: 'SCUEdit', x: 0, y: 44, w: 20, h: 24 },
    ],
  },
  {
    name: 'Faults Dashboard',
    accessLevel: UAG.TECHNICAL,
    layouts: [{ i: 'SystemFaults', x: 0, y: 0, w: 20, h: 20 }],
  },
  {
    name: 'Devices Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'DeviceList', x: 0, y: 0, w: 22, h: 60 }],
  },

  {
    name: 'SW Dashboard',
    accessLevel: UAG.TECHNICAL,
    layouts: [{ i: 'SWVersions', x: 0, y: 0, w: 25, h: 38 }],
  },

  {
    name: 'Door Config Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [
      { i: 'AccessPINs', x: 0, y: 0, w: 16, h: 12 },
      { i: 'CameraUrls', x: 0, y: 12, w: 16, h: 15 },
      { i: 'AssignDoorCamera', x: 0, y: 30, w: 16, h: 15 },
    ],
  },

  {
    name: 'All Room Units Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'RoomUnitConfigTable', x: 0, y: 0, w: 26, h: 18 }],
  },

  {
    name: 'ARC Telephone Numbers Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'ArcTelephoneNumbers', x: 0, y: 0, w: 20, h: 16 }],
  },

  {
    name: 'System Parameters',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'SystemParameterEditor', x: 0, y: 0, w: 26, h: 26.5 }],
  },

  {
    name: 'Site Alert Config Dashboard',
    accessLevel: UAG.TECHNICAL,
    layouts: [{ i: 'SiteAlertSettings', x: 0, y: 0, w: 12, h: 11 }],
  },

  {
    name: 'User Settings Config Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'UserSettings', x: 0, y: 0, w: 14, h: 10 }],
  },

  {
    name: 'All Interfaces Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'InterfaceConfigTable', x: 0, y: 0, w: 26, h: 36 }],
  },

  {
    name: 'All Accesspoints Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'AccessPointConfigTable', x: 0, y: 0, w: 26, h: 36 }],
  },

  {
    name: 'Unassigned Dashboard',
    accessLevel: UAG.MANAGER,
    layouts: [{ i: 'UnassignedConfigTable', x: 0, y: 0, w: 26, h: 36 }],
  },

  {
    name: 'SCU Dashboard',
    accessLevel: UAG.TECHNICAL,
    layouts: [{ i: 'SCUInputConfig', x: 0, y: 0, w: 15, h: 8 }],
  },

  {
    name: 'Location History',
    accessLevel: UAG.BASIC,
    layouts: [{ i: 'DeviceLocationHistory', x: 0, y: 0, w: 20, h: 22 }],
  },

  {
    name: 'In Development',
    accessLevel: UAG.ELITE,
    layouts: [
      // { i: 'VRUAlarmRing', x: 14, y: 0, w: 10, h: 10 },
      // { i: 'HandsetChargeTable', x: 0, y: 90, w: 26, h: 23 },
      // { i: 'AlarmTest', x: 0, y: 120, w: 26, h: 23 },
      { i: 'ResidentGroups', x: 0, y: 0, w: 26, h: 16 },

      //{ i: 'AlarmsByType', x: 14, y: 11, w: 10, h: 10 },
    ],
  },

  {
    name: 'DB Tables',
    accessLevel: UAG.ELITE,
    layouts: [{ i: 'RoutingEventsTable', x: 0, y: 0, w: 26, h: 23 }],
  },

  {
    name: 'AutoTestAlarms',
    accessLevel: UAG.ELITE,
    layouts: [{ i: 'AutoTest Alarms', x: 0, y: 0, w: 22, h: 20 }],
  },

  {
    name: 'Device E2',
    accessLevel: UAG.ELITE,
    layouts: [{ i: 'DeviceE2Configuration', x: 0, y: 0, w: 26, h: 23 }],
  },
];
