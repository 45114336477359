import React from 'react';
import { Route } from 'react-router-dom';

import { Page, PageContent } from 'grommet';

// Help Pages

import HelpDesignPlan from 'views/help/helpDesignPlan';

export default function HelpPages() {
  return (
    <Page kind="wide" margin="none" padding="none">
      <PageContent>
        <Route path="/help/designplan" exact component={HelpDesignPlan} />
      </PageContent>
    </Page>
  );
}
