import TablePaged, { dateFilter } from 'components/tables/TablePaged';

import React, { useEffect, useMemo, useState } from 'react';

import Tile, { iTileEvent } from '../../tile';
import * as doorCalls from 'components/systemevents/doorEvents';
import { formatDateWithSeconds } from 'common/utils/dateUtils';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import { Column, Row, Cell } from 'react-table';
import _ from 'lodash';
import { Room } from 'components/installation/room';
import { Text } from 'grommet';

const RecentDoorCallsTable = (props: any): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const [tableFilters, setTableFilters] = useState<
    { id: string; value: string }[] | undefined
  >();

  // EventType: number;
  //   RaisedDeviceID: number;
  //   AlarmID: number;
  //   RaisedTime: string;
  //   OwnerDeviceName: string;
  //   FriendlyString: string;

  const columns = React.useMemo<Column<doorCalls.iDoorCall>[]>(
    () => [
      {
        Header: 'Date/Time',
        id: 'RaisedTime',
        //accessor: (row: alarmEvents.iAlarmSummary) => formatDateWithSeconds(row.raisedAt),

        accessor: (row) => new Date(row.RaisedTime),
        sortType: 'datetime',
        filter: dateFilter,

        // Using the cell access so that the sortType: 'datetime' works
        Cell: (data: Cell<doorCalls.iDoorCall>) => {
          const date = data.value;
          if (date) return date ? formatDateWithSeconds(date) : 'Unknown';
        },

        getCellExportValue: (row: Row<doorCalls.iDoorCall>) => {
          return formatDateWithSeconds(row.original.RaisedTime);
        },
        disableFilters: props.disableFilters,
      },
      {
        Header: 'Door Panel',
        accessor: 'OwnerDeviceName',
        Cell: (data: Cell<doorCalls.iDoorCall>) => (
          <Text truncate={true}>{data.row.original.OwnerDeviceName}</Text>
        ),
        disableFilters: props.disableFilters,
      },
      {
        Header: 'Call To',
        id: 'callTo',

        accessor: (row) => (row.CallData ? row.CallData.Name : ''),
        Cell: (data: Cell<doorCalls.iDoorCall>) => (
          <Text truncate={true}>
            {data.row.original.CallData ? data.row.original.CallData.Name : ''}
          </Text>
        ),
        disableFilters: props.disableFilters,
      },
      {
        Header: 'Door Opened',
        accessor: (row) => (row.CallData?.OpenedDoor == true ? 'yes' : 'no'),
        getCellExportValue: (row: Row<doorCalls.iDoorCall>) => {
          console.info('Row:', row);
          return row.original.CallData &&
            row.original.CallData.OpenedDoor === true
            ? 'Yes'
            : 'No';
        },
        disableFilters: props.disableFilters,
      },
    ],
    [doorCalls] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const data = useMemo(() => {
    // console.log('Door Call Overview', props);
    if (props.room && props.data) {
      return props.data.filter(
        (f: doorCalls.iDoorCall) => f.AddtionalInfo == props.room
      );
    } else return props.data;
  }, [props]);

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: [],
      pageSize: 10,
      sortBy: [
        {
          id: 'RaisedTime',
          desc: true,
        },
      ],
    }),
    []
  );

  return (
    <TablePaged
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      initialState={initialState}
      data={data}
      showCheckboxes={false}
      reportDescription={{
        header: '',
        filename: 'RecentDoorCalls',
      }}
      filters={tableFilters}
    />
  );
};

export default RecentDoorCallsTable;
