import React from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../tile';
import { Form, Button, Box, Text } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import * as Icons from 'grommet-icons';
import {
  DeviceParameters,
  teDeviceProcessors,
  teVRUParameters,
} from 'common/IoT/DeviceParameters';
import * as FormFields from 'components/forms/formFieldsOld';
import * as IoTQuery from 'common/IoT/IoTMessageDefines_CONFIG_QUERY';
import { Manifest } from 'components/installation/manifest';
import * as ManifestEnums from 'types/manifest-enums';

const deviceParameters = DeviceParameters.Instance;

interface iRingParameters {
  pwmDay: number;
  pwmNight: number;
}

const fields: FormFields.iField[] = [
  {
    label: 'Day Brightness %',
    id: 'pwmDay',
    type: 'number',
    formBoxWidth: 'xsmall',
  },
  {
    label: 'Night Brightness %',
    id: 'pwmNight',
    type: 'number',
    formBoxWidth: 'xsmall',
  },
];

const manifest = Manifest.Instance;
const blank = { pwmDay: 0, pwmNight: 0 };

const VRUAlarmRing = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const [values, SetValues, valuesRef] = useState(blank);
  const [unchangedValues, SetUnchangedValues, unchangedValuesRef] =
    useState(blank);
  const [dialogueProps, setDialogueProps, dialoguePropsRef] = useState<
    Wait.DialogueProps | undefined
  >();

  const [selectedMac, SetSelectedMac, selectedMacRef] = useState<
    number | undefined
  >();

  const [selectedDescription, SetSelectedDescription] = useState('');

  const deviceOffLine = (): void => {
    console.info(`Device offline`);
    SetSelectedMac(undefined);
    setDialogueProps({
      show: true,
      dialogueText: `Device not responding at this time.`,
      dialogueTextLine2: `Please try later.`,
      showSpinner: false,
      showOkButton: true,
      okButtonPress: () => setDialogueProps(undefined),
    });

    SetValues(blank);
    SetUnchangedValues(blank);
  };

  async function updateInfo(macAddress: number) {
    if (dialoguePropsRef.current !== undefined) return;

    const device = manifest.getDeviceByMac(macAddress);
    if (
      device.Type !== ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT ||
      device.HwModel !==
        ManifestEnums.teDEVICE_MODELS_ROOM_UNIT.DEVICE_MODEL_VRU
    ) {
      return;
    }

    console.info('Get VRU alarm ring info for MAC:', macAddress);
    const values: iRingParameters = blank;

    setDialogueProps({
      show: true,
      dialogueText: `Please wait....`,
      showSpinner: true,
      showOkButton: false,
    });

    try {
      const resp = await deviceParameters.getDeviceParameter(
        macAddress,
        teVRUParameters.E2_PARAM_LED_PWM_DAY,
        teDeviceProcessors.PROCESSOR_VRU_APPLICATION,
        null
      );

      const msg = resp.msgHeader
        ?.msg as IoTQuery.tsCLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_RES;
      if (msg) {
        values.pwmDay = parseInt(msg.value, 10);
      }
    } catch (error) {
      deviceOffLine();
      return;
    }

    try {
      const resp = await deviceParameters.getDeviceParameter(
        macAddress,
        teVRUParameters.E2_PARAM_LED_PWM_NIGHT,
        teDeviceProcessors.PROCESSOR_VRU_APPLICATION,
        null
      );

      const msg = resp.msgHeader
        ?.msg as IoTQuery.tsCLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_RES;
      if (msg) {
        values.pwmNight = parseInt(msg.value, 10);
      }
    } catch (error) {
      deviceOffLine();
      return;
    }

    console.info('Ring values:', values);
    SetValues(values);
    SetUnchangedValues(values);
    SetSelectedMac(macAddress);
    setDialogueProps(undefined);

    let desc = '';
    const room = manifest.rooms.find((e) => e.MacAddress === macAddress);
    if (room) desc = room.Name;
    SetSelectedDescription(desc);
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.DEVICE,
      state: eventDispatcher.systemEventStates.SELECTED,
      callback: (e) => updateInfo(e.detail as number),
      executeOnStartup: false,
    },
  ];

  async function save() {
    if (!selectedMacRef.current) return;

    setDialogueProps({
      show: true,
      dialogueText: `Please wait....`,
      showSpinner: true,
      showOkButton: false,
    });

    try {
      await deviceParameters.setDeviceParameter(
        selectedMacRef.current,
        teVRUParameters.E2_PARAM_LED_PWM_DAY,
        teDeviceProcessors.PROCESSOR_VRU_APPLICATION,
        valuesRef.current.pwmDay.toString(),
        null
      );

      //const msg = resp.msgHeader
      //  ?.msg as IoTQuery.tsCLOUD_MSG_QUERY_CFG_SET_DEVICE_PARAM_RES;
    } catch (error) {
      deviceOffLine();
      return;
    }

    try {
      await deviceParameters.setDeviceParameter(
        selectedMacRef.current,
        teVRUParameters.E2_PARAM_LED_PWM_NIGHT,
        teDeviceProcessors.PROCESSOR_VRU_APPLICATION,
        valuesRef.current.pwmNight.toString(),
        null
      );

      //const msg = resp.msgHeader
      //  ?.msg as IoTQuery.tsCLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_RES;
    } catch (error) {
      deviceOffLine();
      return;
    }
    setDialogueProps(undefined);
    SetUnchangedValues(valuesRef.current);
  }

  const configChanged = () => {
    return !_.isEqual(unchangedValues, values);
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={configChanged() ? 'brand' : 'grey-4'}
      badge={configChanged()}
      tip="Save changes"
      disabled={configChanged() ? false : true}
      style={contextButtonStyle}
      onClick={async () => {
        await save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Discard Changes',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            SetValues(unchangedValuesRef.current);
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="VRU Alarm Ring"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      ContextButtons={ContextButtons}
      menuProps={menuItems}
      waitDiaglogueProps={dialogueProps}
    >
      <>
        {!selectedMac && <p>Please select a room unit</p>}
        {selectedMac && (
          <>
            <Text>
              {selectedDescription + ' MAC:0x' + selectedMac.toString(16)}
            </Text>

            <Form<iRingParameters>
              value={values}
              onChange={(nextValue) => {
                if (nextValue.pwmDay > 100) nextValue.pwmDay = 100;
                if (nextValue.pwmDay < 0) nextValue.pwmDay = 0;
                if (nextValue.pwmNight > 100) nextValue.pwmNight = 100;
                if (nextValue.pwmNight < 0) nextValue.pwmNight = 0;

                SetValues(nextValue);
              }}
              onSubmit={(event) =>
                console.log('onSubmit', event.value, event.touched)
              }
            >
              <Box direction="column">
                <FormFields.AlignedFormFields displayfields={fields} />
              </Box>
            </Form>
          </>
        )}
      </>
    </Tile>
  );
};

export default VRUAlarmRing;
