'use client';

import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Keyboard,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
  Text,
  Tip,
} from 'grommet';

import {
  DocumentExcel as IconDocumentExcel,
  ChapterPrevious as IconChapterPrevious,
  ChapterNext as IconChapterNext,
  CaretPrevious as IconCaretPrevious,
  CaretNext as IconCaretNext,
  Clear as IconClear,
} from 'grommet-icons';

import {
  flexRender,
  useReactTable,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  ColumnFiltersState,
  FilterFn,
  SortingFn,
  sortingFns,
  Column,
  Table as TanTable,
} from '@tanstack/react-table';

import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils';
import styled from 'styled-components';
import Action, { iAction } from './action';

// import { appEventStates, emitEvent } from '';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const SmallButton = styled(Button)`
  margin: 3px;
  padding: 2px;
  height: 18px;
  width: 18px;
`;

const ExportButton = styled(Button)`
  margin: 4px;
  margin-right: 14px;
  padding: 2px;
  height: 18px;
  width: 18px;
`;

const HeaderBox = styled(Box)`
  min-height: 40px;
  padding: 0px;
  margin: 2px;
`;

export interface iRowEvent {
  rowData: unknown;
  colName: string;
}

export interface iTableOptions {
  showItemsTotal?: boolean; // whether to display ( xxxxx items ) in the header along the page controls
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickExportExcel?: (rowData: any[]) => void; // fucntion to call when clicking on the export to Excel option, if none then no export button is shown
  backgroundEvenRow?: string;
  backgroundOddRow?: string;
}

export interface iTableView {
  viewName: string;
  viewColumns: string[];
  dataFilter: (data: unknown[]) => unknown[];
}

// const MENU_ID = 'menu-id';

let view: iTableView = {
  viewName: 'Full',
  viewColumns: [],
  dataFilter: (d) => {
    return d;
  },
};

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;

  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      box-sizing="border-box"
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

function Filter({
  column,
  table,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: TanTable<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === 'number' ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ''
          }`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ''
          }`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <Box direction="row" margin={{ bottom: '3px' }} align="Center">
        <Box width="80%">
          <DebouncedInput
            type="text"
            value={(columnFilterValue ?? '') as string}
            onChange={(value) => column.setFilterValue(value)}
            placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
            list={column.id + 'list'}
          />
        </Box>
        {column.getFilterValue() != '' &&
          (column.getFilterValue() as string)?.length > 0 && (
            <SmallButton
              pad="none"
              margin={{ left: '3px' }}
              icon={<IconClear size="20px" color="amethyst" />}
              onClick={() => column.setFilterValue('')}
            />
          )}
      </Box>
      <div className="h-1" />
    </>
  );
}

let currentRow = 0;

/**
 * Base Table
 *
 * Events : The following events are generated by the table using the passed eventTopic
 *
 * Row click - upon a row click a 'SELECTED' event is emitted with the original row data and columnname thet was cliked on
 *
 * @param props
 * @returns
 */
export function BaseTable(props: {
  columns: any[];
  data: unknown[];
  pageSize?: number;
  rowAction: iAction[]; // action icons at the start of a row
  eventTopic: string; // topic to be used for emitting events such as clicking on a row
  options?: iTableOptions; // custom options
  views?: iTableView[]; // used to defined custom column views of the table
}): JSX.Element {
  // const rerender = React.useReducer(() => ({}), {})[1];
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState('');
  // const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});

  console.log(
    `RENDER: ${props.eventTopic} table, items: ${
      props.data ? props.data.length : 0
    }`
  );

  const filteredData = useMemo(() => {
    if (props.views && view.viewName != 'Full') {
      const v = props.views?.find((f) => {
        return f.viewName == view.viewName;
      });
      if (v != undefined) {
        return v.dataFilter(props.data);
      }
    }

    return props.data;
  }, [view, props.data]);

  const table = useReactTable({
    data: filteredData,
    columns: props.columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      sorting,
      columnVisibility,
    },
    initialState: {
      pagination: {
        pageSize: props.pageSize ?? 30,
      },
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  // if a view is set then setup the column visibility and order
  const setView = (newView: iTableView) => {
    if (newView != undefined) {
      if (newView.viewName == 'Full') {
        table.toggleAllColumnsVisible(true);
        table.resetColumnOrder(true);
      } else {
        const cols = table.getAllColumns();
        const v = props.views?.find((f) => {
          return f.viewName == newView.viewName;
        });
        if (v != undefined) {
          if (v.viewColumns.length > 0) {
            for (let c = 0; c < cols.length; c++) {
              const vis =
                v.viewColumns.findIndex((f) => {
                  return f == cols[c].id;
                }) > -1;
              // console.log(cols[c].id, vis);
              cols[c].toggleVisibility(vis);
            }
            table.setColumnOrder(v.viewColumns);
          } else {
            table.toggleAllColumnsVisible(true);
            table.resetColumnOrder(true);
          }
        }
      }
      view = newView;
    }
  };

  const viewOptions: iTableView[] = [
    {
      viewName: 'Full',
      viewColumns: [],
      dataFilter: (d) => {
        return d;
      },
    },
  ];

  const setCurrentRow = (x: number, columnName: string) => {
    if (x >= 0 && x < table.getRowModel().rows.length) {
      currentRow = x;

      const data: iRowEvent = {
        rowData: table.getRowModel().rows[x].original,
        colName: columnName,
      };

      table.getRowModel().rows[x].toggleSelected();

      // emitEvent(props.eventTopic, appEventStates.SELECTED, data, false);  //TOFIX
    }
  };

  if (props.views) viewOptions.push(...props.views);

  return (
    <Keyboard
      onDown={() => {
        setCurrentRow(currentRow + 1, '');
      }}
      onUp={() => {
        setCurrentRow(currentRow - 1, '');
      }}
    >
      <Box
        pad="none"
        margin="none"
        onContextMenu={(e) => e.preventDefault()}
        height="100%"
        width="100%"
      >
        <HeaderBox alignSelf="start" direction="row">
          {props.options && props.options.onClickExportExcel && (
            <Tip content="Export to Excel">
              <ExportButton
                icon={<IconDocumentExcel size="24px" color="amethyst" />}
                onClick={() => {
                  const rowData = table
                    .getFilteredRowModel()
                    .rows.map((r) => r.original);
                  if (props.options && props.options.onClickExportExcel)
                    props.options.onClickExportExcel(rowData);
                }}
              />
            </Tip>
          )}
          <SmallButton
            icon={<IconChapterPrevious size="24px" color="amethyst" />}
            margin={{ left: '60px' }}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          />
          <SmallButton
            icon={<IconCaretPrevious size="22px" color="amethyst" />}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          />
          <SmallButton
            icon={<IconCaretNext size="22px" color="amethyst" />}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          />
          <SmallButton
            icon={<IconChapterNext size="24px" color="amethyst" />}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          />

          <Box
            direction="row"
            margin={{ left: 'medium', top: 'xsmall' }}
            width="100%"
          >
            <Text margin={{ left: 'xsmall' }} weight="bold">
              Page:
            </Text>
            <Text margin={{ left: 'xsmall' }} weight="bold">
              {table.getState().pagination.pageIndex + 1}
            </Text>
            <Text margin={{ left: 'xsmall' }}>of</Text>
            <Text margin={{ left: 'xsmall', right: 'large' }} weight="bold">
              {table.getPageCount()}
            </Text>
            {props.options && props.options.showItemsTotal && (
              <Text margin={{ left: 'xsmall', right: 'large' }} weight="bold">
                {`( ${table.getFilteredRowModel().rows.length} 
              items )`}
              </Text>
            )}
          </Box>

          {props.views && (
            <Box direction="row">
              <Text
                margin={{ left: '40px', top: '8px', right: '15px' }}
                weight="bold"
                size="medium"
              >
                {' '}
                View
              </Text>
              <Select
                size="medium"
                margin="none"
                width="200px"
                plain={true}
                options={viewOptions}
                defaultValue="Full"
                onChange={({ option }) => setView(option)}
              />
            </Box>
          )}
        </HeaderBox>
        <Box overflow="scroll" pad="1px">
          <Table>
            <TableHeader
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                // background: 'brand',
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  <TableCell pad="none" margin="none">
                    <Tip content="clear all filters">
                      <SmallButton
                        pad="none"
                        margin="none"
                        icon={<IconClear size="20px" color="amethyst" />}
                        onClick={() => table.setColumnFilters([])}
                      />
                    </Tip>
                  </TableCell>
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      key={header.id}
                      width={`${header.getSize()}px`}
                      pad={{
                        left: '6px',
                        top: '2px',
                        bottom: '4px',
                      }}
                      margin="none"
                    >
                      <Text weight="bold" color="black">
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div style={{ width: '70%' }}>
                                <Filter
                                  column={header.column}
                                  table={table}
                                  key={`Filter${header.id}`}
                                />
                              </div>
                            ) : null}
                          </>
                        )}
                      </Text>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row, index) => (
                <TableRow key={row.id}>
                  {props.rowAction && (
                    <TableCell key={`RA{cell.id}`}>
                      <Box direction="row" pad={{ top: '6px' }}>
                        {props.rowAction.map((act, index) => (
                          <Action
                            key={`${row.id}${index}`}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data={row.original as any[]}
                            action={act}
                          />
                        ))}
                      </Box>
                    </TableCell>
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      style={{ boxShadow: 'none' }}
                      background={
                        row.getIsSelected()
                          ? '#D1E5F4'
                          : index % 2 == 0
                          ? props.options?.backgroundEvenRow ?? 'white'
                          : props.options?.backgroundOddRow ?? 'grey-0'
                      }
                      pad={{
                        left: '6px',
                        top: '2px',
                        bottom: '3px',
                      }}
                      margin="none"
                      width={`${cell.column.columnDef.size}px`}
                      key={cell.id}
                      onClick={(e) => {
                        e.preventDefault();

                        setCurrentRow(index, cell.column.id);
                      }}
                    >
                      <Text color={'grey-7'} size={'14px'}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Text>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id}>
                  <TableCell />
                  {footerGroup.headers.map((header) => (
                    <TableCell key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          </Table>
        </Box>
      </Box>
    </Keyboard>
  );
}
