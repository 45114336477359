import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import * as dbAccess from './dbAccess';
import * as utils from '../common/utils/dateUtils';

import {
  OmniviaSCUEvent,
  OnCreateOmniviaSCUEventSubscription,
  ModelSortDirection,
} from 'types/API';

import { RootState } from './store';

import * as subscriptions from 'graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';
import Observable, { ZenObservable } from 'zen-observable-ts';
import { GraphQLResult } from '@aws-amplify/api';
import { store } from 'store/store';
import { AUDIT_EVENTS } from 'types/event-enums';
import { initialSliceState } from 'store/commonSlice';
import {
  emitEvent,
  systemEventStates,
  systemEventTopics,
} from './eventDispatcher';

import logger from 'common/logger';

/******************************************************
   GRAPHQL DB ACCESS
******************************************************/
let subscriptionObject: ZenObservable.Subscription;
let subscribed = false;
let currentSCUID = '';

const eventBySCUandDate = /* GraphQL */ `
  query EventBySCUandDate(
    $omniviaSCUEventLinkedSCUId: ID
    $raisedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySCUandDate(
      omniviaSCUEventLinkedSCUId: $omniviaSCUEventLinkedSCUId
      raisedAt: $raisedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertGeneratedState
        omniviaSCUEventLinkedSCUId
        eventId
        transactionId
        eventIdText
        eventString
        raisedAt
        eventJSON
        aggregationId
        unitTime
        modifiedBy
        createdAt
        updatedAt
        expirationUnixTime
        omniviaSCUEventLinkedSCUIdEventId
      }
      nextToken
    }
  }
`;

const eventBySCUandDateandEvent = /* GraphQL */ `
  query EventBySCUandDateandEvent(
    $omniviaSCUEventLinkedSCUIdEventId: String
    $raisedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySCUandDateandEvent(
      omniviaSCUEventLinkedSCUIdEventId: $omniviaSCUEventLinkedSCUIdEventId
      raisedAt: $raisedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertGeneratedState
        omniviaSCUEventLinkedSCUId
        eventId
        transactionId
        eventIdText
        eventString
        raisedAt
        eventJSON
        aggregationId
        unitTime
        modifiedBy
        createdAt
        updatedAt
        expirationUnixTime
        omniviaSCUEventLinkedSCUIdEventId
      }
      nextToken
    }
  }
`;

export const eventBySCUandAggregation = /* GraphQL */ `
  query EventBySCUandAggregation(
    $omniviaSCUEventLinkedSCUId: ID
    $aggregationId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOmniviaSCUEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySCUandAggregation(
      omniviaSCUEventLinkedSCUId: $omniviaSCUEventLinkedSCUId
      aggregationId: $aggregationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alertGeneratedState
        omniviaSCUEventLinkedSCUId
        eventId
        transactionId
        eventIdText
        eventString
        raisedAt
        eventJSON
        aggregationId
        unitTime
        modifiedBy
        createdAt
        updatedAt
        expirationUnixTime
        omniviaSCUEventLinkedSCUIdEventId
      }
      nextToken
    }
  }
`;

const dbAccessInfo = {
  DAOname: 'SCUEvents',
  allQuery: eventBySCUandDateandEvent,
  allSelector: 'eventBySCUandDateandEvent',
  allSort: function (a: OmniviaSCUEvent, b: OmniviaSCUEvent) {
    if (a.raisedAt < b.raisedAt) {
      return -1;
    }
    if (a.raisedAt > b.raisedAt) {
      return 1;
    }
    return 0;
  },

  selectQuery: eventBySCUandAggregation,
  selectSelector: 'eventBySCUandAggregation',
};

function subscribeToNewEvents(scuId: string) {
  currentSCUID = scuId;

  if (subscribed) {
    subscriptionObject.unsubscribe();
    subscribed = false;
  }

  console.info('Events subscription to SCU id:', currentSCUID);

  // Subscribe to creation of Todo
  const subscription = API.graphql(
    graphqlOperation(subscriptions.onCreateOmniviaSCUEvent, {
      omniviaSCUEventLinkedSCUId: scuId,
    })
  );

  if (subscription instanceof Observable) {
    subscribed = true;
    subscriptionObject = subscription.subscribe({
      next: (payload: {
        value: GraphQLResult<OnCreateOmniviaSCUEventSubscription>;
      }) => {
        if (payload.value.data) {
          const newEvent = payload.value.data?.onCreateOmniviaSCUEvent;
          //console.info('Sub payload:', newEvent);
          if (newEvent?.omniviaSCUEventLinkedSCUId == currentSCUID) {
            store.dispatch(
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              slice.actions.newEvent(newEvent as OmniviaSCUEvent)
            );
          } else {
            console.error('*** Event for not our SCU:', newEvent);
          }
        }
      },
    });
  }
}

/******************************************************
   Redux stuff
******************************************************/
const getState = (state: RootState): RootState => state;

const adapter = createEntityAdapter({
  //selectId: (object) => object.id, // id is the default
});

const initialState = adapter.getInitialState(initialSliceState);

// Thunk functions
export const fetch = createAsyncThunk(
  'scuevents/fetch',
  async (fetchInfo: { SCUId: string; daysToFetch: number }) => {
    const dateOffset = 24 * 60 * 60 * 1000 * fetchInfo.daysToFetch;
    const date = new Date();
    date.setTime(date.getTime() - dateOffset);
    const dateInPast = utils.dateToIsoString_dateonly(date);

    let indexString: string =
      fetchInfo.SCUId + '#' + AUDIT_EVENTS.AUDIT_EVENT_ALARM_RAISE;

    const response_raise = await dbAccess.loadData(
      dbAccessInfo,
      {
        omniviaSCUEventLinkedSCUIdEventId: indexString,
        raisedAt: { ge: dateInPast },
        limit: 10000,
        sortDirection: ModelSortDirection.ASC,
      },
      true
    );

    indexString = fetchInfo.SCUId + '#' + AUDIT_EVENTS.AUDIT_EVENT_ALARM_SELECT;

    const response_select = await dbAccess.loadData(
      dbAccessInfo,
      {
        omniviaSCUEventLinkedSCUIdEventId: indexString,
        raisedAt: { ge: dateInPast },
        limit: 10000,
        sortDirection: ModelSortDirection.ASC,
      },
      true
    );

    indexString =
      fetchInfo.SCUId + '#' + AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RAISE;
    const response_doorRaise = await dbAccess.loadData(
      dbAccessInfo,
      {
        omniviaSCUEventLinkedSCUIdEventId: indexString,
        raisedAt: { ge: dateInPast },
        limit: 10000,
        sortDirection: ModelSortDirection.ASC,
      },
      true
    );

    indexString =
      fetchInfo.SCUId + '#' + AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_SELECT;
    const response_doorSelect = await dbAccess.loadData(
      dbAccessInfo,
      {
        omniviaSCUEventLinkedSCUIdEventId: indexString,
        raisedAt: { ge: dateInPast },
        limit: 10000,
        sortDirection: ModelSortDirection.ASC,
      },
      true
    );

    const response_simple = await dbAccess.select(dbAccessInfo, {
      omniviaSCUEventLinkedSCUId: fetchInfo.SCUId,
      aggregationId: { eq: 0 },
      limit: 10000,
      sortDirection: ModelSortDirection.ASC,
    });

    const allData = response_raise.concat(
      response_select,
      response_doorRaise,
      response_doorSelect,
      response_simple
    );
    subscribeToNewEvents(fetchInfo.SCUId);

    return allData;
  }
);

const getData = async (
  daysToFetch: number,
  SCUId: string,
  startDate?: Date,
  limit?: number
): Promise<unknown[]> => {
  const oneDay = 24 * 60 * 60 * 1000;
  const dateOffset = oneDay * daysToFetch;

  if (limit === undefined) limit = 10000;

  console.info(`getData ${daysToFetch} previous to ${startDate}`);

  if (!startDate) {
    startDate = new Date();
  }

  const dateInPast = new Date(startDate.getTime() - dateOffset);
  const dateInPast_string = utils.dateToIsoString_dateonly(dateInPast);

  const firstDate = new Date(startDate.getTime() + oneDay);
  const firstDate_string = utils.dateToIsoString_dateonly(firstDate);

  console.info(
    `Get events between ${dateInPast_string} and ${firstDate_string}`
  );

  const betweenArray: Array<string> = [dateInPast_string, firstDate_string];
  return await dbAccess.loadDataQuery(
    eventBySCUandDate,
    'eventBySCUandDate',
    null,
    {
      omniviaSCUEventLinkedSCUId: SCUId,
      raisedAt: { between: betweenArray },
      limit: limit,
      sortDirection: ModelSortDirection.DESC,
    },
    true
  );
};

// Thunk functions
export const fetchAll = createAsyncThunk(
  'scuevents/fetchAll',
  async (fetchInfo: { SCUId: string; maxEvents: number }) => {
    console.info('scuevents/fetchAll start');

    const response = await dbAccess.loadDataQuery(
      eventBySCUandDate,
      'eventBySCUandDate',
      null,
      {
        omniviaSCUEventLinkedSCUId: fetchInfo.SCUId,
        limit: fetchInfo.maxEvents,
        sortDirection: ModelSortDirection.DESC,
      },
      true
    );

    console.info('scuevents/fetchAll finished, records:', response.length);
    subscribeToNewEvents(fetchInfo.SCUId);

    return response;
  }
);
/*
export const fetchAll = createAsyncThunk(
  'scuevents/fetchAll',
  async (fetchInfo: {
    SCUId: string;
    daysToFetch: number;
    startDate?: Date; // if not specified today will be used
  }) => {
    console.info('scuevents/fetchAll start');

    const response = await getData(
      fetchInfo.daysToFetch,
      fetchInfo.SCUId,
      fetchInfo.startDate
    );
    console.info('scuevents/fetchAll finished, records:', response.length);
    subscribeToNewEvents(fetchInfo.SCUId);

    return response;
  }
);*/

// Thunk functions
export const fetchMore = createAsyncThunk(
  'scuevents/fetchMore',
  async (fetchInfo: {
    SCUId: string;
    daysToFetch: number;
    startDate?: Date; // if not specified today will be used
    maxEvents?: number;
  }) => {
    console.info('scuevents/fetchMore start date:', fetchInfo.startDate);

    const response = await getData(
      fetchInfo.daysToFetch,
      fetchInfo.SCUId,
      fetchInfo.startDate,
      fetchInfo.maxEvents
    );
    console.info('scuevents/fetchMore finished, records:', response.length);

    return response;
  }
);

export const select = createAsyncThunk(
  'scuevents/select',
  async (selectInfo: { SCUId: string; aggregationId: number }) => {
    console.info(
      'Select alarm id ' +
        selectInfo.aggregationId +
        ' for scu id:' +
        selectInfo.SCUId
    );

    const response = await dbAccess.select(dbAccessInfo, {
      omniviaSCUEventLinkedSCUId: selectInfo.SCUId,
      aggregationId: { eq: selectInfo.aggregationId },
      limit: 10000,
      sortDirection: ModelSortDirection.ASC,
    });

    return { id: selectInfo.aggregationId, resp: response };
  }
);

// Thunk functions
export const fetchEventType = createAsyncThunk(
  'scuevents/fetchEventType',
  async (fetchInfo: {
    SCUId: string;
    daysToFetch: number;
    eventType: AUDIT_EVENTS;
  }) => {
    const dateOffset = 24 * 60 * 60 * 1000 * fetchInfo.daysToFetch;
    const date = new Date();
    date.setTime(date.getTime() - dateOffset);
    const dateInPast = utils.dateToIsoString_dateonly(date);

    const indexString: string = fetchInfo.SCUId + '#' + fetchInfo.eventType;

    console.info('*************** fetchEventType:', fetchInfo.eventType);

    const response = await dbAccess.loadData(
      dbAccessInfo,
      {
        omniviaSCUEventLinkedSCUIdEventId: indexString,
        raisedAt: { ge: dateInPast },
        limit: 10000,
        sortDirection: ModelSortDirection.ASC,
      },
      true
    );

    console.info('*************** fetchEventType response:', response);

    return response;
  }
);

const slice = createSlice({
  name: 'scuevents',
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    clearSelected(state, action) {
      state.selectedId = '';
    },
    newEvent(state, action) {
      logger.debug('New event:', action.payload);
      adapter.addOne(state, action.payload);
      emitEvent(
        systemEventTopics.SITEEVENTS,
        systemEventStates.UPDATED,
        action.payload,
        true
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetch.pending, (state, action) => {
        state.loadStatus = 'loading';
        state.dataLoaded = false;
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADING,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetch.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload);
        state.dataLoaded = true;
        state.loadStatus = 'idle';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADED,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetch.rejected, (state, action) => {
        state.loadStatus = 'error';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADERROR,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetchAll.pending, (state, action) => {
        state.loadStatus = 'loading';
        state.dataLoaded = false;
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADING,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetchAll.fulfilled, (state, action) => {
        // set all will wipe the old data out
        adapter.setAll(state, action.payload);
        state.dataLoaded = true;
        state.loadStatus = 'idle';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADED,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetchMore.fulfilled, (state, action) => {
        // append to the data already present
        adapter.setMany(state, action.payload);
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADED,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetchAll.rejected, (state, action) => {
        state.loadStatus = 'error';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADERROR,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetchEventType.pending, (state, action) => {
        state.loadStatus = 'loading';
        state.dataLoaded = false;
        emitEvent(systemEventTopics.SITEEVENTS, systemEventStates.LOADING),
          null,
          true;
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetchEventType.fulfilled, (state, action) => {
        adapter.setMany(state, action.payload);
        state.dataLoaded = true;
        state.loadStatus = 'idle';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADED,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(fetchEventType.rejected, (state, action) => {
        state.loadStatus = 'error';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADERROR,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(select.pending, (state, action) => {
        state.selectedId = '';
        state.selectStatus = 'loading';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADING,
          null,
          true
        );
      })
      .addCase(select.fulfilled, (state, action) => {
        adapter.setMany(state, action.payload.resp);
        state.selectedId = action.payload.id.toString();
        state.selectStatus = 'idle';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADED,
          null,
          true
        );
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .addCase(select.rejected, (state, action) => {
        state.selectStatus = 'error';
        emitEvent(
          systemEventTopics.SITEEVENTS,
          systemEventStates.LOADERROR,
          null,
          true
        );
      });
  },
});

export const { selectAll, selectById } = adapter.getSelectors<RootState>(
  (state: RootState) => state.scuevents
);

export const { clearSelected, newEvent } = slice.actions;

export default slice.reducer;

export const getSelected = createSelector([getState], (state: RootState) => {
  return selectById(state, state.scuevents.selectedId);
});

export const getAll = createSelector([getState], (state: RootState) => {
  return selectAll(state);
});

export const getSlice = createSelector([getState], (state: RootState) => {
  return state.scuevents;
});
