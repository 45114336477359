import { ALARM_TYPES, iAlarmTypeDescription } from 'types/event-enums';

// Mapping of alarm types to text

export const ALARMS_TYPE_DESCRIPTIONS: iAlarmTypeDescription[] = [
  { value: ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT, text: 'Disabled' },
  { value: ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1, text: 'Red Button' },
  { value: ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_2, text: 'Pullcord' },
  { value: ALARM_TYPES.ALARM_TYPE_PRESSURE_MAT, text: 'Pressure mat' },
  { value: ALARM_TYPES.ALARM_TYPE_DOOR_CONTACT, text: 'Door contact' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PASSIVE_INFRA_RED_PIR_DETECTOR,
    text: 'PIR (Standard)',
  },
  { value: ALARM_TYPES.ALARM_TYPE_BOUNDARY_BREACH, text: 'Boundary breach' },
  { value: ALARM_TYPES.ALARM_TYPE_SMOKE, text: 'Smoke' },
  { value: ALARM_TYPES.ALARM_TYPE_FIRE, text: 'Fire' },
  {
    value: ALARM_TYPES.ALARM_TYPE_BOGUS_CALLER_TRIGGER,
    text: 'Bogus caller trigger',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_1, text: 'Pendant' }, // Pendant
  {
    value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_2,
    text: 'Personal trigger 2',
  },
  { value: ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_1, text: 'Fall' },
  { value: ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_2, text: 'Fall 2' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PERSONAL_ATTACK_1,
    text: 'Personal attack 1',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_PERSONAL_ATTACK_2,
    text: 'Personal attack 2',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PANIC_BUTTON, text: 'Panic button' },
  {
    value: ALARM_TYPES.ALARM_TYPE_CARBON_MONOXIDE_GAS,
    text: 'Carbon monoxide gas',
  },
  { value: ALARM_TYPES.ALARM_TYPE_NATURAL_GAS, text: 'Natural gas' },
  { value: ALARM_TYPES.ALARM_TYPE_INTRUDER, text: 'Intruder' },
  {
    value: ALARM_TYPES.ALARM_TYPE_AUTOMATIC_PERSON_DOWN,
    text: 'Automatic person down',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_1, text: 'PIR (Entry/Exit)' },
  { value: ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_2, text: 'Property exit 2' },
  {
    value: ALARM_TYPES.ALARM_TYPE_HIGH_TEMPERATURE,
    text: 'High temperature',
  },
  { value: ALARM_TYPES.ALARM_TYPE_LOW_TEMPERATURE, text: 'Low temperature' },
  {
    value: ALARM_TYPES.ALARM_TYPE_TEMPERATURE_RATE_OF_RISE,
    text: 'Temperature rate of rise',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_MEDICATION_DISPENSER,
    text: 'Medication Dispensed',
  },
  { value: ALARM_TYPES.ALARM_TYPE_ENURESIS, text: 'Enuresis' },
  { value: ALARM_TYPES.ALARM_TYPE_BED_OCCUPANCY, text: 'Not in Bed/Chair' },
  { value: ALARM_TYPES.ALARM_TYPE_CHAIR_OCCUPANCY, text: 'Bed/Chair Absence' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PROPERTY_OCCUPANCY_1,
    text: 'Property occupancy 1',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_PROPERTY_OCCUPANCY_2,
    text: 'Property occupancy 2',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BATHROOM_OCCUPANCY,
    text: 'Bathroom occupancy',
  },
  { value: ALARM_TYPES.ALARM_TYPE_SEIZURE, text: 'Seizure' },
  { value: ALARM_TYPES.ALARM_TYPE_INACTIVITY, text: 'Inactivity' },
  {
    value: ALARM_TYPES.ALARM_TYPE_ENVIRONMENTAL_MONITOR,
    text: 'Environmental monitor',
  },
  { value: ALARM_TYPES.ALARM_TYPE_BED_MONITOR, text: 'Not up Bed/Chair' },
  { value: ALARM_TYPES.ALARM_TYPE_CHAIR_MONITOR, text: 'Bed/Chair Other' },
  { value: ALARM_TYPES.ALARM_TYPE_FLOOD, text: 'Flood' },
  { value: ALARM_TYPES.ALARM_TYPE_BATH_LEVEL, text: 'Bath level' },
  {
    value: ALARM_TYPES.ALARM_TYPE_LIGHTING_CIRCUIT,
    text: 'Lighting circuit',
  },
  { value: ALARM_TYPES.ALARM_TYPE_HEATING, text: 'Heating' },
  { value: ALARM_TYPES.ALARM_TYPE_MAINS_POWER, text: 'Mains fail' },
  {
    value: ALARM_TYPES.ALARM_TYPE_SYSTEM_POWER_SUPPLY,
    text: 'System power supply',
  },
  { value: ALARM_TYPES.ALARM_TYPE_BATTERY, text: 'Battery low' },
  { value: ALARM_TYPES.ALARM_TYPE_DUTY_SWITCH, text: 'Duty switch' },
  { value: ALARM_TYPES.ALARM_TYPE_DOOR_OPEN, text: 'Door open' },
  { value: ALARM_TYPES.ALARM_TYPE_FIRE_DOOR_OPEN, text: 'Fire door open' },
  { value: ALARM_TYPES.ALARM_TYPE_SYSTEM_STATUS, text: 'System status' },
  {
    value: ALARM_TYPES.ALARM_TYPE_AUTOMATIC_PERIODIC_TEST_CALL,
    text: 'Automatic periodic test call',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_1,
    text: 'Telephone line fail',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_2,
    text: 'Telephone line 2 fail',
  },
  { value: ALARM_TYPES.ALARM_TYPE_RADIO_RECEIVER, text: 'Radio receiver' },
  {
    value: ALARM_TYPES.ALARM_TYPE_IP_COMMUNICATION_LINK,
    text: 'IP communication link',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SERIAL_DATA_LINK,
    text: 'Serial data link',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SYSTEM_UNDER_TEST,
    text: 'System under test',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SERVICE_PROVIDER_IDS,
    text: 'Service provider IDs',
  },
  { value: ALARM_TYPES.ALARM_TYPE_SERVICE_CALL, text: 'Service call' },
  {
    value: ALARM_TYPES.ALARM_TYPE_ACKNOWLEDGEMENT_MESSAGE,
    text: 'Acknowledgement message',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_PRESENCE_MARKING,
    text: 'Presence marking',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SERVICE_COMPLETED,
    text: 'Service completed',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_RESIDENT_UNIT_POLL_FAILURE,
    text: 'Room Poll Fail',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_ROUTER_POLL_FAILURE,
    text: 'Router Fail',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TRIGGER_POLL_FAILURE,
    text: 'Trigger Fail',
  },
  { value: ALARM_TYPES.ALARM_TYPE_SOCIAL_CALL, text: 'Social Call' },
  { value: ALARM_TYPES.ALARM_TYPE_TAG_STATUS_REPORT, text: 'Tag Status' },
  { value: ALARM_TYPES.ALARM_TYPE_TAG_ACK, text: 'Tag ACK' },
  { value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1, text: 'Input 1' },
  { value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2, text: 'Input 2' },
  { value: ALARM_TYPES.ALARM_TYPE_DOOR_CALL, text: 'Door Panel Call' },
  {
    value: ALARM_TYPES.ALARM_TYPE_DOOR_POLL_FAILURE,
    text: 'Door Panel Poll Fail',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_AUDIO_ADAPTOR_POLL_FAILURE,
    text: 'Audio Adaptor Poll Fail',
  },
  { value: ALARM_TYPES.ALARM_TYPE_RESIDENT_OK, text: 'Resident ok' },
  { value: ALARM_TYPES.ALARM_TYPE_INCOMING_POTS_CALL, text: 'Incoming call' },
  { value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3, text: 'Input 3' },
  { value: ALARM_TYPES.ALARM_TYPE_TAG_TALK_ACTIVE, text: 'Tag Talk Active' },
  {
    value: ALARM_TYPES.ALARM_TYPE_TAG_TALK_INACTIVE,
    text: 'Tag Talk Inactive',
  },
  { value: ALARM_TYPES.ALARM_TYPE_TAG_OPEN, text: 'Tag Open' },
  { value: ALARM_TYPES.ALARM_TYPE_TAG_END, text: 'Tag End' },
  { value: ALARM_TYPES.ALARM_TYPE_RESIDENT_HOME, text: 'Resident Home' },
  { value: ALARM_TYPES.ALARM_TYPE_RESIDENT_AWAY, text: 'Resident Away' },
  { value: ALARM_TYPES.ALARM_TYPE_WANDERING, text: 'Resident Wandering' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PAGE_ACKNOWLEDGE,
    text: 'Page Acknowledgment',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_CANCEL_REQUEST,
    text: 'Cancel',
  },
  { value: ALARM_TYPES.ALARM_TYPE_MESSAGE_REQUEST, text: 'Message Request' },
  {
    value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_OPEN_EVENT,
    text: 'Input 1 open event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT,
    text: 'Input 1 close event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_OPEN_EVENT,
    text: 'Input 2 open event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_CLOSE_EVENT,
    text: 'Input 2 close event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_OPEN_EVENT,
    text: 'Input 3 open event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_CLOSE_EVENT,
    text: 'Input 3 close event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_DEVICE_RESET_EVENT,
    text: 'Device reset event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_OPEN_EVENT,
    text: 'SCU Input 1 open event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_CLOSE_EVENT,
    text: 'SCU Input 1 close event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_OPEN_EVENT,
    text: 'SCU Input 2 open event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_CLOSE_EVENT,
    text: 'SCU Input 2 close event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_OPEN_EVENT,
    text: 'SCU Input 3 open event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_CLOSE_EVENT,
    text: 'SCU Input 3 close event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TUNSTALL_TRIGGER_EVENT,
    text: 'Tunstall trigger event',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_DETECTOR_FAULT,
    text: 'Detectior Fault',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_DETECTOR_EOL,
    text: 'Detectior End Of Life',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_ASSISTANCE_NEEDED,
    text: 'Assist',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BUTTON_FAULT,
    text: 'Button fault',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_DOOR_CLOSED,
    text: 'Door Closed',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_MEDICATION_DOSE_MISSED,
    text: 'Medication Missed',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_NURSE_CALL_FAULT,
    text: 'Pear push fault',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_NURSE_CALL,
    text: 'Call',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TAMPER,
    text: 'Tamper',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_MAINS_POWER_RESTORED,
    text: 'Mains Power Restored',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_NOT_IN,
    text: 'Not in Bed or Chair',
  },

  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_NOT_UP,
    text: 'Not up from bed or chair',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_ABSENCE,
    text: 'Absence from bed or chair',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_OTHER,
    text: 'Bed chair other',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_FALL_NOT_WORN,
    text: 'Fall not worn',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_FALL_CANCELLED,
    text: 'Fall Canceled',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_CARER_ALARM,
    text: 'Carer',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_RESIDENT_NOT_OK,
    text: 'Resident not OK',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM_DISARM_TOGGLE,
    text: 'Intruder Arm/Disarm Switch',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM,
    text: 'Intruder Arm Only',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_INTRUDER_DISARM,
    text: 'Intruder Disarm Only',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_EMERGENCY,
    text: 'Emergency',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_NOT_WORN,
    text: 'Not Worn',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_DOOR_LEFT_OPEN,
    text: 'Door Left Open',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_RESTORED,
    text: 'Telephone Line Restored',
  },

  {
    value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_BATTERY_LOW,
    text: 'Pendant battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TEMP_EXT_BATTERY_LOW,
    text: 'Temperature battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_FLOOD_BATTERY_LOW,
    text: 'Flood battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_CO_BATTERY_LOW, text: 'CO battery low' },
  {
    value: ALARM_TYPES.ALARM_TYPE_SMOKE_BATTERY_LOW,
    text: 'Smoke battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_DOOR_BATTERY_LOW, text: 'Door battery low' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PULLCORD_BATTERY_LOW,
    text: 'Pullcord battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_ENURESIS_BATTERY_LOW,
    text: 'Enuresis battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_BATTERY_LOW,
    text: 'Bed/Chair battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_FALL_BATTERY_LOW, text: 'Fall battery low' },
  { value: ALARM_TYPES.ALARM_TYPE_PIR_BATTERY_LOW, text: 'PIR battery low' },
  {
    value: ALARM_TYPES.ALARM_TYPE_CARER_BATTERY_LOW,
    text: 'Carer battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_NATURAL_GAS_BATTERY_LOW,
    text: 'Natural gas battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PES_BATTERY_LOW, text: 'PES battery low' },
  {
    value: ALARM_TYPES.ALARM_TYPE_ARMDISARM_BATTERY_LOW,
    text: 'Arm/Disarm battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BOGUS_CALL_BATTERY_LOW,
    text: 'Bogus battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_MEDICATION_BATTERY_LOW,
    text: 'Medication battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_EPILEPSY_BATTERY_LOW,
    text: 'Epilepsy battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_WNC_BATTERY_LOW, text: 'WNC battery low' },
  { value: ALARM_TYPES.ALARM_TYPE_WNC_CANCEL_REQ, text: 'WNC cancel' },
  { value: ALARM_TYPES.ALARM_TYPE_CO_DEVICE_FAULT, text: 'CO device fault' },
  { value: ALARM_TYPES.ALARM_TYPE_MEDICATION_FAULT, text: 'Medication fault' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PULLCORD_POLL_FAILURE,
    text: 'Pullcord poll fail',
  },
  { value: ALARM_TYPES.ALARM_TYPE_SMOKE_POLL_FAILURE, text: 'Smoke poll fail' },
  { value: ALARM_TYPES.ALARM_TYPE_WIM_POLL_FAILURE, text: 'WIM poll fail' },
  { value: ALARM_TYPES.ALARM_TYPE_WIM_BATTERY_LOW, text: 'WIM battery low' },
  { value: ALARM_TYPES.ALARM_TYPE_FALL_POLL_FAILURE, text: 'Fall poll fail' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PANIC_BATTERY_LOW,
    text: 'Panic battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PANIC_POLL_FAILURE, text: 'Panic poll fail' },
  {
    value: ALARM_TYPES.ALARM_TYPE_FALL_MANUAL_PRESS,
    text: 'Manual Press-Fall Detector',
  },
];
