import React, { useState, useEffect, useMemo } from 'react';
import useStateRef from 'react-usestateref';
import Tile, { iTileEvent } from '../../tile';
import { Box, Button, TextInput } from 'grommet';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import * as IoTTest from 'common/IoT/IoTTest';
import { ALARM_TYPES } from 'types/event-enums';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import * as IoTAlarms from 'common/IoT/IoTAlarms';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import * as alarmEvents from 'components/systemevents/alarmEvents';
import * as eventEnums from 'types/event-enums';
import { formatDateWithSeconds } from 'common/utils/dateUtils';
import { Text } from 'grommet';
import { Column, Row, Cell } from 'react-table';
import TablePaged, { dateFilter } from 'components/tables/TablePaged';
import { teCLOUD_ERROR_CODES } from '../../../../common/IoT/IoTMessageDefines';
import { Manifest } from 'components/installation/manifest';
import { useInterval } from 'hooks/useInterval';
import { a } from 'aws-amplify';
import * as Utils from 'common/utils/dateUtils';
import { getAlarmDescription } from 'common/alarmTypesTextFiltered/alarmTypeUtils';

export const nanoid = customAlphabet(alphanumeric, 24);

const manifest = new Manifest();

let _manageLocked = false;

interface iAlarmTestHistory {
  testTime: string;
  autoClear: boolean;
  clearCommandSent: boolean;
  sourceDeviceId: number;
  ownerDeviceId: number;
  alarmType: ALARM_TYPES;
  alarmId: number;
  status:
    | 'REQUESTED'
    | 'RAISED'
    | 'LIVE'
    | 'CLEARDOWN SENT'
    | 'CLEARED'
    | 'FAILED'
    | 'NOT RAISED';
  failedMessage: string;
  alarmSummary: alarmEvents.iAlarmSummary;
}

const testHistory: iAlarmTestHistory[] = [];

const AutoTestAlarms = (): JSX.Element => {
  const [childEvent, SetChildEvent] = useState('');
  const [dialogueProps, setDialogueProps] = useStateRef<
    Wait.DialogueProps | undefined
  >();
  const [roomNumber, setRoomNumber] = useState('');

  // Put a request in the Queue
  const requestAlarm = (
    deviceId: number,
    alarmType: ALARM_TYPES,
    autoClear: boolean
  ) => {
    testHistory.push({
      testTime: new Date(Date.now()).toISOString(),
      autoClear: autoClear,
      clearCommandSent: false,
      sourceDeviceId: deviceId,
      ownerDeviceId: 0,
      alarmType: alarmType,
      alarmId: -1,
      status: 'REQUESTED',
      failedMessage: '',
      alarmSummary: {
        alarmId: 0,
        callType: '',
        raisedAt: '',
        roomNumber: 0,
        alarmType: '',
        alarmTypeID: 0,
        source: '',
        answeredBy: '',
        timeToAnswer_seconds: 0,
        callDuration_seconds: 0,
        addtionalInfo: '',
        raisedTo: '',
        originalAlarmType: 0,
        careGroupDesc: '',
        careGroupID: 1,
        raisedDeviceId: 0,
      },
    });
  };

  async function raiseAlarm(alarm: iAlarmTestHistory) {
    const errorCode: IoTTest.teCLOUD_ERROR_CODES = await IoTTest.raiseAlarm(
      alarm.sourceDeviceId,
      alarm.alarmType
    );
    console.info(`raiseAlarm response errorcode:${errorCode}`);

    if (errorCode == teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE) {
      // successfully raised
      alarm.testTime = new Date(Date.now() - 4000).toISOString();
      alarm.status = 'RAISED';
    } else {
      alarm.testTime = new Date().toISOString();
      alarm.status = 'FAILED';
      alarm.failedMessage = `Could not raise alarm : errorCode ${errorCode}`;
    }
  }

  async function clearAlarm(alarm: iAlarmTestHistory) {
    if (!alarm.clearCommandSent) {
      console.log(`Clearing Alarm: ${alarm.alarmId}`);
      alarm.clearCommandSent = true;
      alarm.status = 'CLEARDOWN SENT';
      const resp = await IoTAlarms.clearAlarm(alarm.alarmId, null, true);
      console.log(resp);
    } else console.log('clear previously sent');
  }

  async function clearAllAlarms() {
    testHistory.forEach(async (t) => {
      if (t.status == 'LIVE') {
        await clearAlarm(t);
      }
    });
  }

  const requestAlarmsForAllRooms = async () => {
    manifest.rooms.forEach(async (r, index) => {
      if (index < 6)
        await requestAlarm(
          r.HomeDeviceId,
          ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1,
          true
        );
    });
  };

  const requestAlarmsForAllRoomInputs = async (
    deviceId: number,
    autoClear: boolean
  ) => {
    requestAlarm(deviceId, ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1, autoClear);
    requestAlarm(deviceId, ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1, autoClear);
    requestAlarm(deviceId, ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2, autoClear);
    requestAlarm(deviceId, ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3, autoClear);
  };

  const columns = React.useMemo<Column<iAlarmTestHistory>[]>(
    () => [
      {
        Header: 'Test Time',
        id: 'testTime',
        width: 120,

        accessor: (row) => new Date(row.testTime),
        sortType: 'datetime',
        filter: dateFilter,

        // Using the cell access so that the sortType: 'datetime' works
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const date = data.value;
          if (date) return date ? formatDateWithSeconds(date) : 'Unknown';
        },

        getCellExportValue: (row: Row<iAlarmTestHistory>) => {
          return formatDateWithSeconds(row.values.testTime);
        },
      },
      {
        Header: 'Alarm ID',
        accessor: 'alarmId',
        width: 65,
      },
      {
        Header: 'Date/Time',
        id: 'raisedAt',
        width: 120,
        //accessor: (row: alarmEvents.iAlarmSummary) => formatDateWithSeconds(row.raisedAt),

        accessor: (row) => new Date(row.alarmSummary.raisedAt),
        sortType: 'datetime',
        filter: dateFilter,

        // Using the cell access so that the sortType: 'datetime' works
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const date = data.value;
          if (date) return date ? formatDateWithSeconds(date) : 'Unknown';
        },

        getCellExportValue: (row: Row<iAlarmTestHistory>) => {
          return formatDateWithSeconds(row.values.alarmSummary.raisedAt);
        },
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        maxWidth: 60,
        minWidth: 60,
        width: 120,
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const text = data.row.original.status;
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Room',
        id: 'roomNumber',
        accessor: 'alarmSummary',
        maxWidth: 60,
        minWidth: 60,
        width: 60,
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const text = data.row.original.alarmSummary.roomNumber;
          return <Text truncate={true}>{text}</Text>;
        },
      },

      {
        Header: 'Alarm Type',
        accessor: 'alarmSummary',
        maxWidth: 100,
        minWidth: 100,
        width: 120,
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const d = data.row.original;
          let text = getAlarmDescription(d.alarmType);
          if (d.alarmSummary.alarmType != text) {
            text = text + '(' + d.alarmSummary.alarmType + ')';
          }
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Care Group',
        id: 'CareGroup',
        accessor: 'alarmSummary',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const text = data.row.original.alarmSummary.careGroupDesc;
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Raised To',
        id: 'RaisedTo',
        accessor: 'alarmSummary',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const text = data.row.original.alarmSummary.raisedTo;
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Answered By',
        accessor: (row) => {
          return row.alarmSummary.CancelReason
            ? row.alarmSummary.CancelReason
            : row.alarmSummary.answeredBy;
        },
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const text = data.row.original.alarmSummary.CancelReason
            ? data.row.original.alarmSummary.CancelReason
            : data.row.original.alarmSummary.answeredBy;
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Time to Answer',
        accessor: (row) => {
          return row.alarmSummary.timeToAnswer_seconds.toString() + ' s';
        },

        maxWidth: 90,
        minWidth: 90,
        width: 90,
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const text =
            data.row.original.alarmSummary.timeToAnswer_seconds.toString() +
            ' s';
          return <Text truncate={true}>{text}</Text>;
        },
      },
      {
        Header: 'Duration',
        accessor: (row) => {
          return row.alarmSummary.callDuration_seconds.toString() + ' s';
        },
        maxWidth: 60,
        minWidth: 60,
        width: 60,
        Cell: (data: Cell<iAlarmTestHistory>) => {
          const text =
            data.row.original.alarmSummary.callDuration_seconds.toString() +
            ' s';
          return <Text truncate={true}>{text}</Text>;
        },
      },
      // {
      //   Header: 'Source Id',
      //   accessor: 'sourceDeviceId',
      //   maxWidth: 60,
      //   minWidth: 60,
      //   width: 60,
      //   Cell: (data: Cell<iAlarmTestHistory>) => {
      //     const text = data.row.original.sourceDeviceId;
      //     return <Text truncate={true}>{text}</Text>;
      //   },
      // },
      // {
      //   Header: 'Owner Id',
      //   accessor: 'ownerDeviceId',
      //   maxWidth: 60,
      //   minWidth: 60,
      //   width: 60,
      //   Cell: (data: Cell<iAlarmTestHistory>) => {
      //     const text = data.row.original.ownerDeviceId;
      //     return <Text truncate={true}>{text}</Text>;
      //   },
      // },
    ],
    [childEvent]
  );

  // manage any tests
  const manageTests = async () => {
    if (_manageLocked) return;
    _manageLocked = true;

    const liveAlarms = alarmEvents.getLiveAlarms();
    const raised = testHistory.filter((t) => t.status == 'RAISED');

    raised.forEach((t) => {
      // console.log(t);
      const summary = liveAlarms.find(
        (a) => a.originalAlarmType == t.alarmType && a.raisedAt >= t.testTime
      );

      // console.log(summary);

      if (summary) {
        // check we don't already have this alarm ID in our test queue
        const f = testHistory.find((t) => t.alarmId == summary.alarmId);
        // console.log(f);
        if (f == undefined) {
          // console.log(`setting alarm ${summary?.alarmId} to LIVE`);

          t.alarmId = summary?.alarmId;
          t.alarmSummary = summary;
          t.status = 'LIVE';
        }
      } else {
        // handle ones not raised because they are disabled
        // console.log(
        //   eventEnums.getAlarmDescription(t.alarmType),
        //   t.alarmSummary.originalAlarmType,
        //   eventEnums.getAlarmDescription(
        //     eventEnums.ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT
        //   )
        // );
        if (
          getAlarmDescription(Number(t.alarmSummary.originalAlarmType)) ==
            getAlarmDescription(
              eventEnums.ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT
            ) &&
          new Date(Date.now()).getTime() - new Date(t.testTime).getTime() >
            20000
        ) {
          t.status = 'NOT RAISED';
          SetChildEvent(t.testTime);
        }
      }
    });

    // go through the live alarms in test history and look for events
    const live = testHistory.filter((t) => t.status == 'LIVE');

    live.forEach(async (t) => {
      const summary = alarmEvents.alarmSummaries.find(
        (a) => a.alarmId == t.alarmId
      );
      if (summary) {
        t.alarmSummary = summary;

        // auto clear down if set to autoclear and its been raisd to a destination
        if (t.autoClear && t.alarmSummary.raisedTo != '') {
          await clearAlarm(t);
        }
      }
    });

    // go through the alarms we've cleared down and check they have been removed form the queue
    const clearRequested = testHistory.filter(
      (t) => t.status == 'CLEARDOWN SENT'
    );

    clearRequested.forEach(async (t) => {
      const summary = liveAlarms.find(
        (a) => a.originalAlarmType == t.alarmType && a.raisedAt >= t.testTime
      );

      if (!summary) {
        t.status = 'CLEARED';
        SetChildEvent(t.alarmId.toString());
      }
    });

    const currentlyRaised = raised.length;
    let newlyRaised = 0;
    const MAXRAISED = 5;

    testHistory.forEach(async (t) => {
      // raise any request in our queue but throttle to ensure we don't raise too many
      if (
        t.status == 'REQUESTED' &&
        currentlyRaised + newlyRaised < MAXRAISED
      ) {
        newlyRaised = newlyRaised + 1;
        await raiseAlarm(t);
      }
    });

    _manageLocked = false;
  };

  const loadDataEvent = async () => {
    // await manageTests();
  };

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.ALARMEVENTS,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
  ];

  // manage the running tests
  useInterval(async () => {
    await manageTests();
  }, 500);

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: [],
      pageSize: 10,
      sortBy: [
        {
          id: 'raisedAt',
          desc: true,
        },
      ],
    }),
    []
  );

  useEffect(() => {}, []);

  return (
    <Tile
      title="AutoTest Alarms"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
    >
      <>
        <Text>Room number: </Text>
        <TextInput
          size="small"
          width="100px"
          value={roomNumber}
          style={{ padding: '6px', margin: '10px' }}
          onChange={(event) => setRoomNumber(event.target.value)}
        />

        <Button
          plain={false}
          size="small"
          label="Raise Red Button Alarm"
          onClick={async () => {
            const r = Number(roomNumber);
            if (r > 0) {
              const room = manifest.getRoomByRoomNumber(r);
              if (room)
                await await requestAlarm(
                  room.HomeDeviceId,
                  ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1,
                  true
                );
            }
          }}
        />
        <Button
          plain={false}
          size="small"
          label="Raise All HW Inputs"
          onClick={async () => {
            const r = Number(roomNumber);
            if (r > 0) {
              const room = manifest.getRoomByRoomNumber(r);
              if (room) {
                await await requestAlarmsForAllRoomInputs(
                  room.HomeDeviceId,
                  true
                );
              }
            }
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Raise Alarms from All Rooms"
          onClick={async () => {
            await requestAlarmsForAllRooms();
          }}
        />
        <Button
          plain={false}
          size="small"
          label="Clear Test Alarms"
          onClick={async () => {
            await clearAllAlarms();
          }}
        />
        <Button
          plain={false}
          size="small"
          label="Clear All Alarms"
          onClick={async () => {
            await IoTAlarms.clearAllAlarms(null);
          }}
        />

        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={testHistory}
          showCheckboxes={false}
          selectorKeyName="alarmId"
          reportDescription={{
            header: '',
            filename: 'RecentAlarms',
          }}
        />
      </>
    </Tile>
  );
};

export default AutoTestAlarms;
