/** @module Installation */

import React from 'react';
import * as Device from './device';
import * as ManifestTypes from 'types/manifest-types';
import * as ManifestEnums from 'types/manifest-enums';
import * as ManifestUtils from 'components/installation/manifestUtils';
import * as EventEnums from 'types/event-enums';
import * as Product from 'components/floorplan/product';
import { Manifest } from './manifest';
import * as AlarmUtils from 'common/alarmUtils';

const m_personalities: string[] = [
  'MAI',
  'Lift Interface',
  'Door Interface',
  'Call point interface',
  'Gateway interface',
  'Pull cord interface',
];

/*
 * DeviceInterface device class - this is a mixture of the door panel and the connected acess point
 *
 * Confusingly the SQL database on the SCU has the table named as DOOR_PANEL
 * The DOOR_PANEL table actually consists of:-
 * Door Panels (Connected to MAIs)
 * Door Panels (Connected to old door panel interfaces)
 * 2N IP panels
 * VPC MAI interfaces
 * 1 Button call point interfaces
 * LIft panel interfaces
 *
 * @class
 * @hideconstructor
 * @implements iRoom
 */

export class DoorAndDeviceInterface
  extends Device.Device
  implements ManifestTypes.iDoorsAndOtherInteraces
{
  ID = ManifestTypes.invalidID;
  Name = '';
  DeviceId = ManifestTypes.invalidID;
  UnitId = 0; // used as the room number for BSI
  Volume = 0;
  MicVolume = 0; //not used
  CareGroupId = 0;
  TechnicalCareGroupId = 0;
  RelayModes = 0;
  CameraId = 0;
  manifestObjectKeys: string[] = [];
  tablename = '';
  dataChanged = false;
  fixedTriggerAlarmType = EventEnums.ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1;

  tableID = ManifestTypes.invalidID;

  constructor(
    InterfaceValues: ManifestTypes.iDoorsAndOtherInteraces,
    deviceValues: ManifestTypes.iDevice,
    routingEvents: ManifestTypes.iRoutingEvent[],
    keys: string[],
    tableName: string
  ) {
    super(deviceValues, routingEvents);
    Object.assign(this, InterfaceValues);
    this.productManifest.manifestObjectKeys = keys;
    this.productManifest.tablename = tableName;
    this.productManifest.dataChanged = false;
    this.productManifest.tableID = this.ID;
    this.ID = deviceValues.ID;

    if (
      deviceValues.HwModel ==
      ManifestEnums.teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES.DEVICE_MODEL_MAI
    ) {
      this.InputAccessor = {
        Method: Device.teINPUT_METHOD.INPUT_METHOD_MULTI_EVENT,
        RoutingEventTypes: [
          EventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_OPEN_EVENT,
          EventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT,
          EventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_OPEN_EVENT,
          EventEnums.ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_CLOSE_EVENT,
        ],
      };

      this.pullHardwiredInputsFromRoutingEvents();
      const fixedTriggerRoutingEvent = this.getRoutingEvent(
        EventEnums.ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1
      );
      if (fixedTriggerRoutingEvent) {
        this.fixedTriggerAlarmType = fixedTriggerRoutingEvent.AltAlarmTypeId;
      }
    }
  }

  /**
 * 
 * TODO: for device personalities  NEED TO HANDLE OLD HARDWARE 1 BUTTON
 * 
 *          case DEVICE_PERSONALITY_MLI:
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_FIXED_TRIGGER_1, ALARM_TYPE_FIXED_TRIGGER_1, SPEECH_CFG_NO_OVERIDES);
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT, ALARM_TYPE_NO_ALARM_EVENT, SPEECH_CFG_NO_OVERIDES);
            break;

         case DEVICE_PERSONALITY_MPI:
            // We use BSI alarm type ALARM_TYPE_FIXED_TRIGGER_2 and as pull cord
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_FIXED_TRIGGER_1, ALARM_TYPE_FIXED_TRIGGER_2, SPEECH_CFG_NON_SPEECH);
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT, ALARM_TYPE_CANCEL_REQUEST, SPEECH_CFG_NON_SPEECH);
            break;

         case DEVICE_PERSONALITY_MCI:
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_FIXED_TRIGGER_1, ALARM_TYPE_DOOR_CALL, SPEECH_CFG_NO_OVERIDES);
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT, ALARM_TYPE_NO_ALARM_EVENT, SPEECH_CFG_NO_OVERIDES);
            break;

         case DEVICE_PERSONALITY_MDI:
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_FIXED_TRIGGER_1, ALARM_TYPE_NO_ALARM_EVENT, SPEECH_CFG_NO_OVERIDES);
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT, ALARM_TYPE_NO_ALARM_EVENT, SPEECH_CFG_NO_OVERIDES);
            break;

         default:
            // ignore
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_FIXED_TRIGGER_1, ALARM_TYPE_NO_ALARM_EVENT, SPEECH_CFG_NO_OVERIDES);
            CM_EVENT_bSetAltAlarmType(psDevice->u32Uid, ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT, ALARM_TYPE_NO_ALARM_EVENT, SPEECH_CFG_NO_OVERIDES);
            break;
  */

  generateChangeSQL(): { update: string; revert: string } {
    if (
      this.HwModel ==
      ManifestEnums.teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES.DEVICE_MODEL_MAI
    ) {
      this.setRoutingEventAlarmType(
        EventEnums.ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1,
        this.fixedTriggerAlarmType,
        AlarmUtils.DEFAULT_ALARM_CARE_SEQUANCEID,
        AlarmUtils.DEFAULT_TECH_ALARM_CARE_SEQUANCEID
      );
    }

    const sql = ManifestUtils.generateChangeSQL(
      this,
      this.productManifest,
      super.generateChangeSQL()
    );
    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    super.applyManifestChanges();
    return ManifestUtils.applyManifestChanges(this, this.productManifest);
  }

  getDescription(): string {
    if (
      this.HwModel !==
      ManifestEnums.teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES.DEVICE_MODEL_MAI
    ) {
      return this.getDeviceDescription();
    }

    return m_personalities[this.Personality];
  }

  isDoor(): boolean {
    // 1button cal points can be either  DEVICE_MODEL_V1 |  DEVICE_MODEL_V3
    if (
      this.Personality ===
      ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MCI
    ) {
      return false;
    }

    if (
      this.HwModel !==
      ManifestEnums.teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES.DEVICE_MODEL_MAI
    ) {
      return true;
    }

    if (
      this.Personality ===
      ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MDI
    ) {
      return true;
    }

    return false;
  }

  isLift(): boolean {
    if (
      this.Personality ===
      ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MLI
    ) {
      return true;
    }

    return false;
  }

  isPullcord(): boolean {
    if (
      this.Personality ===
      ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MPI
    ) {
      return true;
    }

    return false;
  }

  isCallPoint(): boolean {
    if (
      this.Personality ===
      ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MCI
    ) {
      return true;
    }

    return false;
  }

  isGatewayInterface(): boolean {
    if (
      this.Personality ===
      ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_GWI
    ) {
      return true;
    }

    return false;
  }

  isMAI(): boolean {
    if (
      this.Personality ===
      ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_NOT_USED
    ) {
      return true;
    }

    return false;
  }

  hasEthernetInterface(): boolean {
    if (
      this.HwModel ===
      ManifestEnums.teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES.DEVICE_MODEL_MAI
    ) {
      return true;
    }

    if (this.Type === ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR) {
      return true;
    }

    return false;
  }

  toolTip(): JSX.Element {
    const product = Product.Product.getProductfromManifestDeviceType(this);
    return (
      <div>
        <p>{this.Name}</p>
        <ul>
          <li>Device ID: {this.ID.toString()}</li>
          {this.Type !== ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR && (
            <li>MAC Address:{this.getDeviceMACaddr()}</li>
          )}

          {this.hasEthernetInterface() && (
            <li>
              IP Address:
              {Manifest.Instance.getDeviceIPAddress(this.MacAddress)}
            </li>
          )}

          {product?.productCode != '' && (
            <>
              <li>
                Part Code:
                {product.productCode}
              </li>
              <li>
                Part Description:
                {product.name}
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }
}
