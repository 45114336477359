import TablePaged from 'components/tables/TablePaged';

import React, { useEffect, useState } from 'react';

import Tile, { iTileEvent, iMenuProps } from '../tile';
import { formatDate, formatDateWithSeconds } from 'common/utils/dateUtils';
import { Manifest, teErrorCode } from '../../installation/manifest';
import {
  systemEventStates,
  systemEventTopics,
  iEventParams,
  emitEvent,
} from 'store/eventDispatcher';
import * as Icons from 'grommet-icons';
import { Column, Cell } from 'react-table';
import { Trigger } from 'components/installation/trigger';
import { iDevice } from 'types/manifest-types';
import _ from 'lodash';
import * as Wait from 'components/dialogues/waitDialogue';
import styled from 'styled-components';
import { Text, Tip } from 'grommet';
import * as UserUtils from 'common/userUtils';
import { Product } from 'components/floorplan/product';
import { iDeviceDefaults } from '../../../types/manifest-types';
import AddPeripheralDialogue from 'components/dialogues/addPeripheral';
import * as systemFaults from 'components/systemevents/faults';

const m = new Manifest();

interface BatteryProps {
  level: number;
}

const replaceSoonBatteryVoltage_mV = 2900;
const urgentBatteryVoltage_mV = 2550;

const BATTERY = styled.span<BatteryProps>`
  padding: ${(props) => (props.level > 0 ? '4px' : '0px')};
  background-color: ${(props) =>
    props.level < replaceSoonBatteryVoltage_mV
      ? props.level < urgentBatteryVoltage_mV
        ? ({ theme }) => theme.global.colors.red
        : ({ theme }) => theme.global.colors.amber
      : ({ theme }) => theme.global.colors.green};
  color: ${(props) =>
    props.level < replaceSoonBatteryVoltage_mV
      ? props.level < urgentBatteryVoltage_mV
        ? ({ theme }) => theme.global.colors.text.dark
        : ({ theme }) => theme.global.colors.text.light
      : ({ theme }) => theme.global.colors.text.dark};
`;

const batteryLevelText = (level: number): string => {
  if (level < urgentBatteryVoltage_mV) return 'Needs urgent replacement';
  if (level < replaceSoonBatteryVoltage_mV) return 'Replace soon';
  return 'Battery Good';
};

export type TriggersViewProps = {
  lowBatteryOnly?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TriggersView = (props: any): JSX.Element => {
  const [allData, setAllData] = useState<Trigger[] | undefined>();
  const [, SetChildEvent] = useState('');
  const [selectedDevice, setSelectedDevice] = useState<Trigger | undefined>();
  const [tableFilters, setTableFilters] = useState<
    { id: string; value: string }[] | undefined
  >();

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();

  const [showAddPeripheralDialogue, setShowAddPeripheralDialogue] =
    useState(false);

  let showOnlyLowBattery = false;
  let pageSize = 25;

  if (props.lowBatteryOnly !== undefined) {
    showOnlyLowBattery = props.lowBatteryOnly;
    if (showOnlyLowBattery) pageSize = 10;
  }

  // **From Trigger
  // ID = invalidID;
  // Description = '';
  // InUse = false;
  // LoggedOn = false;
  // MicVolume = 0; //not used
  // FeatureMask = 0;
  // lastEvent: OmniviaLiveEventsLog | any = [];
  // **From Device
  // ID = invalidID;
  // MacAddress = 0;
  // MacAddressHex = '';
  // Type = 0;
  // HwModel = 0;
  // PollsMissed = 0;
  // ParentID = 0;
  // RSSI = 0;
  // Depth = 0;
  // NetworkJoins = 0;
  // RaisePollFails = 0;
  // PrefParentID = 0;
  // Personality = 0;
  // IPAddress = '';
  // ClusterGW = false;

  // **From OmniviaLiveEventsLog
  // id: string,
  // mac: string,
  // omniviaSCUSerial: string,
  // deviceType: number,
  // expirationUnixTime: number,
  // deviceId: number,
  // associatedAlarmId?: number | null,
  // batteryVoltageApplication?: number | null,
  // handset?: HandsetChargeInfo | null,
  // parentMac?: string | null,
  // rssi_db?: number | null,
  // addtionalReceiverList?:  Array<ReceiverList | null > | null,
  // createdAt?: string | null,
  // updatedAt?: string | null,

  const columns = React.useMemo<Column<Trigger>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        width: 20,
      },
      {
        Header: 'Room',
        id: 'Room',
        filter: 'equals',
        accessor: (row) =>
          m.rooms.find((r) => r.HomeDeviceId == row.ParentID)?.RoomNumber,
        width: 40,
      },
      {
        Header: 'Description',
        accessor: 'Description',
        width: 100,
      },
      {
        Header: 'Product Code',
        id: 'ProductCode',
        accessor: (row) => {
          const d: iDevice = {
            ...iDeviceDefaults,
            HwModel: row.HwModel,
            Type: row.Type,
          };
          return Product.getProductfromManifestDeviceType(d).productCode;
        },
        width: 40,
      },
      {
        id: 'Identity',
        Header: 'Identity',
        accessor: (row) => {
          // SN added to prove we are g tunstall triggers.
          // Need to use the common MAC address formatter
          // and augment that to cope with Tunstall ids
          return row.getIdentity();
          // if (row.MacAddressHex.length > 6) {
          //   return row.MacAddressHex.substring(row.MacAddressHex.length - 6, 6);
          // } else {
          //   return parseInt(row.MacAddressHex, 16).toString();
          // }
        },
        width: 50,
      },

      {
        Header: 'Last Event',
        accessor: (row) =>
          row.lastEvent ? formatDateWithSeconds(row.lastEvent.createdAt) : '',
        width: 60,
      },
      {
        Header: 'Battery',
        accessor: (row) =>
          row.lastEvent ? row.lastEvent.batteryVoltageApplication : '',
        Cell: (s: Cell<Trigger>) => (
          <Tip content={<Text>{batteryLevelText(s.value)}</Text>}>
            <BATTERY level={s.value}>{s.value}</BATTERY>
          </Tip>
        ),
        width: 50,
      },
      {
        Header: 'Date Assigned',
        accessor: (row) => {
          if (row.DateAdded !== '') {
            return formatDate(row.DateAdded);
          }
          return '';
        },
        width: 50,
      },
      {
        Header: 'Assigned By',
        accessor: 'AddedBy',
        width: 50,
      },
      // {
      //   Header: 'Parent',
      //   accessor: (row) => {
      //     if (row.lastEvent !== undefined && row.lastEvent.parentMac != null) {
      //       const mac = parseInt(row.lastEvent.parentMac);
      //       return m.getDeviceNameByMac(mac);
      //     }
      //     return '';
      //   },

      //   width: 50,
      // },
      // {
      //   Header: 'RSSI',
      //   accessor: (row) => (row.lastEvent ? row.lastEvent.rssi_db : ''),

      //   width: 50,
      // },
    ],
    [allData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const loadDataEvent = (e: iEventParams) => {
    console.info('Triggers loadDataEvent trigger:', e.subject);
    let triggers = _.cloneDeep(m.triggers);
    if (showOnlyLowBattery) {
      triggers = triggers.filter((e) => {
        if (e.lastEvent && e.lastEvent.batteryVoltageApplication) {
          return (
            e.lastEvent.batteryVoltageApplication < replaceSoonBatteryVoltage_mV
          );
        }
        return false;
      });
    }

    setAllData(triggers);
  };

  const initialState = React.useMemo(
    () => ({
      // hiddenColumns: ['ID'],
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : ['ProductCode', 'Parent', 'RSSI'],
      pageSize: pageSize,
      sortBy: [
        {
          id: 'lastEvent.batteryVoltageApplication',
          desc: true,
        },
      ],
    }),
    [pageSize]
  );

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.LOADED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    // {
    //   topic: systemEventTopics.WIRELESS_DEVICE,
    //   state: systemEventStates.SELECTED,
    //   callback: (e) => {
    //     if (showOnlyLowBattery) return;

    //     const mac = e.detail as number | undefined;
    //     if (mac == undefined || mac == 0) {
    //       setTableFilters([]);
    //     } else {
    //       const triggerMac = Device.MACaddrToString(mac, 16);
    //       setTableFilters([
    //         {
    //           id: 'Identity',
    //           value: triggerMac.substr(triggerMac.length - 6, 6),
    //         },
    //       ]);
    //     }
    //   },
    //   executeOnStartup: false,
    // },
    // {
    //   topic: systemEventTopics.ROOM,
    //   state: systemEventStates.SELECTED,
    //   callback: (e) => {
    //     const unit = e.detail as Room | undefined;
    //     console.log(unit);
    //     if (unit === undefined || unit.RoomNumber === undefined) {
    //       setTableFilters([]);
    //     } else {
    //       setTableFilters([
    //         {
    //           id: 'Room',
    //           value: unit.RoomNumber.toString(),
    //         },
    //       ]);
    //     }
    //   },
    //   executeOnStartup: true,
    // },
  ];

  const selectDevice = async (
    rowData: iDevice,
    selectionInfo: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (!selectionInfo.alreadySelected) {
      const manifestRecord = m.triggers.find((e) => e.ID === rowData.ID);
      console.info('Device selected:', manifestRecord);
      setSelectedDevice(manifestRecord);
    } else {
      setSelectedDevice(undefined);
    }
  };

  const unassignDevice = (device: iDevice) => {
    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setSelectedDevice(undefined);
    };

    let str = 'Save failed';
    m.deleteTrigger(device.ID)
      .then(async (status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';

          // clear all faults associated with this device
          await systemFaults.clearfaultsfordevice(device.ID);
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
        }
        complete(str);
      })
      .catch((error) => {
        console.error('Unassign fail:', error);
        complete(str);
      });
  };

  const unassignClick = () => {
    if (selectedDevice) {
      const room = m.rooms.find(
        (r) => r.HomeDeviceId == selectedDevice.ParentID
      )?.RoomNumber;

      const str1 = `Are you sure you want to unassign the "${
        selectedDevice.Description
      }" with the idenitity ${selectedDevice.MacAddressHex.substr(
        selectedDevice.MacAddressHex.length - 6,
        6
      )}?`;
      const str2 = `The device is assigned to Room ${room} `;

      setDialogueProps({
        show: true,
        dialogueText: str1,
        dialogueTextLine2: str2,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          unassignDevice(selectedDevice);
        },
        showCancelButton: true,
        cancelButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
    }
  };

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Unassign',
          icon: <Icons.Detach size="medium" />,
          onClick: unassignClick,
          disabled: selectedDevice ? false : true,
        },

        {
          label: 'Assign Device',
          icon: <Icons.AddCircle size="medium" />,
          onClick: () => {
            setShowAddPeripheralDialogue(true);
          },
          disabled: false,
        },
        {
          label: 'View Location History',
          icon: <Icons.Location size="medium" />,
          accessLevel: UserUtils.UAG.ELITE,
          onClick: () => {
            console.info('Selected device:', selectedDevice?.MacAddress);
            emitEvent(
              systemEventTopics.DASHBOARD,
              systemEventStates.SELECTED,
              { name: 'Location History' },
              false
            );

            // To ensure the dashboard gets the event, send after a short delay
            setTimeout(() => {
              emitEvent(
                systemEventTopics.WIRELESS_DEVICE_LOCATION,
                systemEventStates.SELECTED,
                selectedDevice?.MacAddress,
                false
              );
            }, 500);
          },
          disabled: false,
        },
      ],
    }),
    [selectedDevice] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (props.view?.macAddress) {
      // const triggerMac = Device.MACaddrToString(props.view.macAddress, 16);
      setTableFilters([
        {
          id: 'Identity',
          value: props.view?.macAddress,
        },
      ]);
    } else if (props.view?.room) {
      setTableFilters([
        {
          id: 'Room',
          value: props.view.room.toString(),
        },
      ]);
    } else {
      setTableFilters([]);
    }
  }, [props]);

  return (
    <Tile
      title={
        showOnlyLowBattery
          ? 'Low Battery Pendants & Peripherals'
          : 'All Pendants & Peripherals'
      }
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      showLastEvent={true}
    >
      <>
        <AddPeripheralDialogue
          show={showAddPeripheralDialogue}
          setShow={setShowAddPeripheralDialogue}
          setDialogueProps={setDialogueProps}
        />
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={allData ? allData : []}
          showCheckboxes={false}
          onLastSelectedRow={selectDevice}
          currentSelectedID={selectedDevice?.ID}
          selectorKeyName="ID"
          reportDescription={{
            header: '',
            filename: 'WirelessDevices',
          }}
          filters={tableFilters}
        />
      </>
    </Tile>
  );
};

export default TriggersView;
