import { tePROCESSOR } from './IoTMessageDefines_DEVICES';
import {
  teCLOUD_MSG_TY_SYSTEM,
  tsCLOUD_MSG_SYSTEM_RESET_REQ,
} from './IoTMessageDefines_SYSTEM';
import {
  teCLOUD_ERROR_CODES,
  teCLOUD_MESSAGE_TYPES,
} from './IoTMessageDefines';

import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
//import logger from 'common/logger';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

// Re-export the tePROCESSOR from IoTMessageDefines
export { tePROCESSOR as tePROCESSOR };
export { teCLOUD_ERROR_CODES as teCLOUD_ERROR_CODES };

export async function scuReset(
  passCode: number,
  userData: unknown
): Promise<iIotResponseEventDetail> {
  console.info('scuReset');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM;
  const opcode = teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_RESET_REQ;
  const rplyopcode = teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_RESET_RESP;

  const msg: tsCLOUD_MSG_SYSTEM_RESET_REQ = {
    passcode: passCode,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'scuReset',
    userData
  );
}
