import React, { useState } from 'react';
import useStateRef from 'react-usestateref';
import Tile, {
  iTileEvent,
  iMenuProps,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Button, Text } from 'grommet';

import { Manifest, teErrorCode } from 'components/installation/manifest';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import TablePaged from 'components/tables/TablePaged';
import { Column, Cell } from 'react-table';
import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
import { useWinstonLogger } from 'winston-react';

const manifest = new Manifest();

interface iTableData {
  ID: number;
  Url: string;
}

const blankTableEntry: iTableData = {
  ID: 0,
  Url: ' ',
};

let _allData: iTableData[] = [];
let _originalData: iTableData[] = [];

const CameraUrls = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useStateRef<number[]>([]);

  const loadData = () => {
    _allData = _.cloneDeep(manifest.cameraConfigs);
    _originalData = _.cloneDeep(_allData);
    setChanges([]);
    setRenderCount((old) => old + 1);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
  ];

  /**
   * @param id    input number
   * @param property field to change
   * @param value new value
   */
  const setProperty = (id: number, property: string, value: unknown) => {
    const input = _allData.find((e) => e.ID == id);
    if (input) {
      const originalRecord = _originalData.find((e) => e.ID == id);

      const setIt = () => {
        if (!changesRef.current.includes(id)) {
          changesRef.current.push(id);
          setRenderCount((old) => old + 1);
        }
      };

      _.set(input, property, value);
      setRenderCount((old) => old + 1);

      if (originalRecord) {
        if (_.get(originalRecord, property, 0) != value) {
          setIt();
        } else {
          _.pull(changesRef.current, id);
          setRenderCount((old) => old + 1);
        }
      }
    }
  };

  function getProperty(id: number, property: string): unknown {
    let input = _allData.find((e) => e.ID == id);
    if (input === undefined) {
      input = blankTableEntry;
    }
    const value = _.get(input, property, 0);
    return value ? value : '';
  }

  const toolTip = (): JSX.Element => {
    return (
      <Text style={{ whiteSpace: 'pre-wrap' }}>{`Do not enter the rtsp prefix.
For example, for a Hik vision camera with IP address 172.16.2.1, the URL would be
<username>:<password>@172.16.2.1:554/h264/Streaming/Channels/102`}</Text>
    );
  };

  /**
   * The aim of all the cell components is that they do not render unnecessarily
   * All the cell components use React.memo to memorise the component.
   *
   * React.memo will only re-generate the compoent if a prop changes therefore we pass a reRenderValue prop
   * to the component to allow us to signal to React.memo that it needs to be re-rendered.
   *
   * The components use callback to set/get their data and not state varibles
   */
  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        disableFilters: true,
        id: 'ID',
        Header: 'Camera',
        accessor: (row) => row.ID + 1,
        width: '10px',
      },
      {
        disableFilters: true,
        id: 'Url',
        Header: 'RTSP URL',
        accessor: 'Url',
        Cell: (props: Cell<iTableData>) => (
          <InputCell
            id={props.row.original.ID}
            reRenderValue={props.row.original.Url}
            valueProperty={`Url`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="text"
            toolTip={toolTip()}
          />
        ),
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : [],
      pageSize: 10,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const save = () => {
    logger.info('CameraUrls.save', {
      changedIDs: changesRef.current,
    });

    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _allData.find((e) => e.ID === id);
      const manifestRecord = manifest.cameraConfigs.find((e) => e.ID === id);

      if (!stateRecord || !manifestRecord) continue;

      if (manifestRecord.Url != stateRecord.Url) {
        manifestRecord.setUrl(stateRecord.Url);
      }
    }

    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setChanges([]);
    };

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          const sp_pollPeriod = manifest.getSystemParameter(
            'INTER_POLL_POLL_TIME'
          );
          let pollPeriod = 20;
          if (sp_pollPeriod) {
            pollPeriod = parseInt(sp_pollPeriod.Value);
          }

          str = `Save Completed Successfully\r\nThe new settings can take up to ${pollPeriod} minutes to take effect.`;
          setChanges([]);
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('CameraUrls.saveFailed', {
            status: status,
            error: {},
          });
        }
        complete(str);
      })
      .catch((error) => {
        console.error('sys param save fail:', error);
        logger.info('CameraUrls.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        complete(str);
      });
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? changes.length : false}
      tip="Save changes"
      disabled={changes.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('CameraUrls.discardChanges');
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="Camera URLs"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={_allData ? _allData : []}
          showCheckboxes={false}
          showControls={true}
          reportDescription={{
            header: '',
            filename: 'CameraUrls',
          }}
        />
      </>
    </Tile>
  );
};

export default CameraUrls;
