import { deepMerge } from 'grommet/utils';
import { dark } from 'grommet/themes';
import { whcolors } from './whcolors';
import SearchInput from './searchInput';

const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '14px',
    },

    input: {
      padding: '4px',
    },
  },
  button: {
    border: {
      width: '2px',
      radius: '0',
    },
    padding: {
      vertical: '0rem',
      horizontal: '0rem',
    },
  },
  tip: {
    content: {
      background: 'white',
      elevation: 'small',
      margin: 'xsmall',
      pad: { vertical: 'xsmall', horizontal: 'small' },
      round: 'xsmall',
    },
  },

  text: {
    xsmall: {
      size: '10px',
      height: '12px',
    },
    small: {
      size: '12px',
      height: '16px',
    },
    medium: {
      size: '14px',
      height: '20px',
    },
    mediumtight: {
      size: '14px',
      height: '0px',
    },
    mediumplus: {
      size: '16px',
      height: '20px',
    },
    large: {
      size: '20px',
      height: '24px',
    },
    xlarge: {
      size: '26px',
      height: '32px',
    },
    xxlarge: {
      size: '34px',
      height: '40px',
    },
  },

  formField: {
    label: {
      text: 'medium',
      color: 'black',
      margin: { bottom: '6px', top: '0px', right: '6px', left: '6px' },
    },
    border: {
      // color: '#eee',
      position: 'none',
    },
    focus: { border: { color: 'grey-9' } },
    margin: { bottom: '0px', top: '0px' },
  },

  select: {
    options: {
      text: {
        color: '#cccccc',
      },
      container: {
        background: 'grey-9',
      },

      // container: {
      //   background: 'grey-9',
      // },
      searchInput: SearchInput,
    },
  },

  tab: {
    active: { color: 'white', background: { color: 'grey-9' } },
    color: 'black',
    hover: { color: 'black' },
    border: { color: 'grey-3' },
    pad: '4px',
  },

  layer: {
    background: 'dialogBackground',
    container: { elevation: 'small' },
    zIndex: 20,
  },

  calendar: {
    extend: 'background: white',
  },
};

const whtheme = deepMerge(dark, theme, whcolors);

export default whtheme;
