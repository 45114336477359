import React from 'react';
import { renderToString } from 'react-dom/server';
import { sendEmail } from 'common/userUtils';
import i18next from 'i18n';
import { iNewUserInput } from '../dashboard/desktopWidgets/userAdmin';

const textStyleBold = {
  fontFamily: 'Calibri',
  fontSize: '11pt',
  fontWeight: 'bold',
};

const textStyleNormal = {
  fontFamily: 'Calibri',
  fontSize: '11pt',
};

const textStyleMinor = {
  fontFamily: 'Arial',
  fontSize: '9pt',
};

export const NewUserEmail = (newUser: iNewUserInput): JSX.Element => {
  return (
    <div>
      <p style={textStyleBold}>
        {i18next.t('new user email salutation', {
          name: newUser.fullname.split(' ')[0],
        })}
      </p>
      <p style={textStyleNormal}>{i18next.t('new user email introduction')}</p>
      <a href="insights.wirelesshealth.co.uk">insights.wirelesshealth.co.uk</a>
      <p style={textStyleNormal}>
        {i18next.t('new user email login username', { user: newUser })}
      </p>
      <p style={textStyleNormal}>
        {i18next.t('new user email login password', { user: newUser })}
      </p>
      <p style={textStyleMinor}>
        {i18next.t('new user email footer', { user: newUser })}
      </p>
      <hr />
      <p style={textStyleMinor}>
        {i18next.t('email disclaimer', { user: newUser })}
      </p>
    </div>
  );
};

export const SendNewUserEmail = (newUser: iNewUserInput): void => {
  const body = renderToString(NewUserEmail(newUser));
  sendEmail(
    newUser.email,
    'insights@wirelesshealth.co.uk',
    'Registered with Wireless Health Insights',
    '',
    body
  );
};
