import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Views
import Dashboard from 'views/Dashboard';
import Plan from 'views/DesignPlan';
import Version from 'views/version';

//import { getCurrentUserAccessLevel, UAG } from 'common/userUtils';

export default function Views() {
  return (
    <>
      <Route path="/views/design/plan" exact component={Plan} />
      <Route path="/views/dashboard" exact component={Dashboard} />
      <Route path="/views/version" exact component={Version} />
      <Redirect from="/" to="/views/dashboard" exact />
    </>
  );
}
