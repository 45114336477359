import {
  tePROCESSOR,
  teCLOUD_MSG_TY_DEVICES,
  tsCLOUD_MSG_DEVICES_DEVICE_PING_REQ,
} from './IoTMessageDefines_DEVICES';
import {
  teCLOUD_ERROR_CODES,
  teCLOUD_MESSAGE_TYPES,
} from './IoTMessageDefines';
import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
//import logger from 'common/logger';
import { Device } from 'components/installation/device';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

// Re-export the tePROCESSOR from IoTMessageDefines
export { tePROCESSOR as tePROCESSOR };
export { teCLOUD_ERROR_CODES as teCLOUD_ERROR_CODES };

/**
 * Send a ping request to a device.
 *
 * @param {number} mac - 64bit MAC address of the device to ping
 * @param {tePROCESSOR} processor - processor on the device to ping data
 * @param {object} userData - data that gets sent back in the completeCb to allow user to tie up the request/response
 */
export async function pingDevice(
  mac: number,
  processor: tePROCESSOR,
  userData: unknown
): Promise<iIotResponseEventDetail> {
  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode = teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_DEVICE_PING_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_DEVICE_PING_RESP;

  const msg: tsCLOUD_MSG_DEVICES_DEVICE_PING_REQ = {
    mac: Device.MACaddrToString(mac, 10),
    processor: processor,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    10000,
    'pingDevice',
    userData
  );
}
