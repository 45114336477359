/** @module Installation */

import { iRoutingResource, invalidID } from 'types/manifest-types';
import { teDESTINATION_TYPES } from 'types/manifest-enums';
import * as manifestUtils from 'components/installation/manifestUtils';

/*
 * Care sequence end point resource.  READONLY
 *
 * Defines what end points can be used in a call sequence
 *
 * @class
 * @hideconstructor
 * @implements iRoutingResource
 */

export class RoutingResource implements iRoutingResource {
  CARE_SEQ_ENTRY_NOT_USED = 1;

  /** iRoutingResource start */
  ID = invalidID;
  DestinationType = teDESTINATION_TYPES.DESTINATION_TYPE_MAX;
  DestinationId = 0;
  Available = 0;
  /** iRoutingResource end */

  // Got software version incase we need to do processsing differently for
  // different software revsions in the future
  softwareVersion = 0;

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(
    values: iRoutingResource,
    keys: string[],
    tableName: string,
    softwareVersion: number
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
  }
}
