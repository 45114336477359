import { UAG } from 'common/userUtils';
import { iLayoutView } from './widgetTypes';

// export interface iLayoutView {
//   name: string;
//   accessLevel: UAG;
//   layouts: ReactGridLayout.Layout[];
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   props?: any[];
// }

export const mobileDashboards: Array<iLayoutView> = [
  {
    name: 'Overview Dashboard',
    accessLevel: UAG.BASIC,
    layouts: [
      { i: 'Page 1', x: 0, y: 0, w: 0, h: 0 },
      { i: 'Page 2', x: 0, y: 0, w: 0, h: 0 },
    ],
  },
];
