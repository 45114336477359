/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @module DoorEvents*/

import * as SystemEvents from './events';
import {
  //iAUDIT_EVENT_ALARM_RAISE,
  iAUDIT_EVENT_DOOR_CALL_CLEAR,
  iAUDIT_EVENT_DOOR_CALL_CLEAR_OPEN,
  iAUDIT_EVENT_DOOR_CALL_RAISE,
  iAUDIT_EVENT_DOOR_CALL_RAISE_RU,
  iAUDIT_EVENT_DOOR_CALL_RU_SELECT,
  iAUDIT_EVENT_DOOR_CALL_SELECT,
  iAUDIT_EVENT_DOOR_TRADES_ALLOW,
  iAUDIT_EVENT_DOOR_TRADES_DENY,
} from 'types/eventTypes';
import { AUDIT_EVENTS } from 'types/event-enums';
//import { ALARM_TYPES } from 'types/event-enums';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest } from 'components/installation/manifest';
import { OmniviaSCUEvent } from 'types/API';
import { iAUDIT_EVENT_ALARM_DEST_RAISE } from '../../types/eventTypes';
//import DoorCalls from '../dashboard/desktopWidgets/doorCalls';

// export const doorCall_TYPES: AUDIT_EVENTS[] = [
//   AUDIT_EVENT_ALARM_DEST_RAISE,
//   AUDIT_EVENT_HS_NOTIFIED,
//   AUDIT_EVENT_DOOR_CALL_RAISE,
//   AUDIT_EVENT_DOOR_CALL_SELECT,
//   AUDIT_EVENT_DOOR_CALL_RAISE_RU,
//   AUDIT_EVENT_DOOR_CALL_RU_SELECT,
//   AUDIT_EVENT_DOOR_CALL_CLEAR_OPEN,
//   AUDIT_EVENT_DOOR_CALL_CLEAR,
//   AUDIT_EVENT_DOOR_CALL_FAIL,
//   AUDIT_EVENT_DOOR_EXIT,
//   AUDIT_EVENT_DOOR_FIRE_SWITCH,
//   AUDIT_EVENT_DOOR_CANCEL_FIRE,
//   AUDIT_EVENT_DOOR_TRADES_ALLOW,
//   AUDIT_EVENT_DOOR_TRADES_PIN,
//   AUDIT_EVENT_DOOR_TRADES_DENY,
//   AUDIT_EVENT_DOOR_CALL_NO_CONCIERGE,
//   AUDIT_EVENT_DOOR_CALL_NO_RESIDENT,
//   AUDIT_EVENT_DOOR_CANCEL,
//   AUDIT_EVENT_DOOR_CALL_KICKED,
//   AUDIT_EVENT_DOOR_CALL_CC_FAIL,
//   AUDIT_EVENT_DOOR_CALL_ANSWER_TIMEOUT,
//   AUDIT_EVENT_DOOR_CALL_SPEECH_TIMEOUT,
// ];

export interface iRoomData {
  CallType: 'Room';
  DestinationDeviceID: number;
  DestinationData: string;
  AddtionalInfo: string;
  Name: string;
  RaiseTime?: string;
  SelectTime?: string;
  ClearTime?: string;
  OpenedDoor?: boolean;
  ResidentCancelledTime?: string;
}

/** Default values for door call for ease of use in creating objects */
export const defaultRoomDataValues: iRoomData = {
  CallType: 'Room',
  DestinationDeviceID: 0,
  DestinationData: '',
  AddtionalInfo: '',
  Name: '',
};

export interface iHandsetData {
  CallType: 'Handset';
  NotifiedTime: string;
  RaiseTime: string;
  SelectTime: string;
  ClearTime: string;
  OpenedDoor: boolean;
  Name: string;
}

export interface iARCData {
  CallType: 'ARC';
  RaiseTime: string;
  SelectTime: string;
  ClearTime: string;
  OpenedDoor: boolean;
  Name: string;
}

export interface iBasicDoorEvent {
  EventType: number;
  AlarmID: number;
}

type CallDataType = iRoomData | iHandsetData | iARCData;
type DoorCallRaise =
  | iAUDIT_EVENT_DOOR_CALL_RAISE
  | iAUDIT_EVENT_DOOR_CALL_RAISE_RU;
type DoorCallPIN =
  | iAUDIT_EVENT_DOOR_TRADES_ALLOW
  | iAUDIT_EVENT_DOOR_TRADES_DENY;

export interface iDoorPINAdditionaInfo {
  pin: string;
  doordeviceId: number;
  doorname: string;
  pinname: string;
  schedulebid: number;
}

/**
 * Door Call - with full lifecycle
 * */
export interface iDoorCall {
  EventType: number;
  RaisedDeviceID: number;
  AlarmID: number;
  RaisedTime: string;
  OwnerDeviceName: string;
  DestinationDeviceName: string;
  FriendlyString: string;
  CallData?: CallDataType; // Later set the calldata type to an array to handle multiple attempts for the alarm call
  AnswerTimeout?: string;
  CancelledAtDoorTime?: string;
  AnsweredBy: string; // Resident , RDC or companion
  AddtionalInfo?: string; // contains room number in a door call
}

/** Default values for door call for ease of use in creating objects */
export const defaultDoorCallValues: iDoorCall = {
  EventType: 0,
  RaisedDeviceID: 0,
  AlarmID: 0,
  RaisedTime: '',
  OwnerDeviceName: '',
  DestinationDeviceName: '',
  FriendlyString: '',
  AnsweredBy: '',
};

export let doorCalls: iDoorCall[] = [];
const manifest = new Manifest();
let oldestEventDate = new Date();

/** Pick out the details form the Resident Call and populate the call details
 */
const decipherResidentCall = (stages: OmniviaSCUEvent[]) => {
  oldestEventDate = new Date();

  let call: iDoorCall = {
    ...defaultDoorCallValues,
  };

  stages.forEach((s) => {
    const data = JSON.parse(s.eventJSON ?? '');

    if (data) {
      /*
      console.info(
        `decipherResidentCall, alarmid:${
          (data as iBasicDoorEvent).AlarmID
        } event:${(data as iBasicDoorEvent).EventType}  data:${s.eventJSON}`
      );*/

      const newDate = new Date(s.raisedAt);
      if (newDate < oldestEventDate) {
        oldestEventDate = newDate;
      }

      switch ((data as iBasicDoorEvent).EventType) {
        case AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RAISE_RU:
          {
            const room = manifest.getRoomByDeviceID(
              (data as iAUDIT_EVENT_DOOR_CALL_RAISE_RU).DestinationDeviceID
            );

            const roomDetails = room ? `Room ${room.RoomNumber}` : '';
            const cdata = {
              ...data,
              Name: roomDetails,
            };
            call = {
              ...data,
              DestinationDeviceName: roomDetails,
            };
            call.CallData = cdata;
          }
          break;
        case AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RU_SELECT:
          {
            let answerByString = 'Resident';

            const companionSelectEvent = stages.find(
              (element) =>
                element.eventIdText?.trim() === 'Companion door call select'
            );

            const rdcSelectEvent = stages.find(
              (element) =>
                element.eventIdText?.trim() === 'RDC door call select'
            );

            if (call.CallData) {
              call.CallData = {
                ...call.CallData,
                SelectTime: (data as iAUDIT_EVENT_DOOR_CALL_RU_SELECT)
                  .RaisedTime,
              };
            }
            if (companionSelectEvent) {
              answerByString = 'Resident mobile companion';
            } else if (rdcSelectEvent) {
              answerByString = 'Resident RDC';
            }
            call.AnsweredBy = answerByString;
          }
          break;

        case AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_CLEAR:
          {
            if (call.CallData) {
              call.CallData = {
                ...call.CallData,
                ClearTime: (data as iAUDIT_EVENT_DOOR_CALL_RU_SELECT)
                  .RaisedTime,
              };
            }
          }
          break;

        case AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_CLEAR_OPEN:
          {
            if (call.CallData) {
              call.CallData = {
                ...call.CallData,
                ClearTime: (data as iAUDIT_EVENT_DOOR_CALL_RU_SELECT)
                  .RaisedTime,
                OpenedDoor: true,
              };
            }
          }
          break;
      }
    }
  });

  doorCalls.push(call);
};

/** Pick out the details form the Handset/ARC Call and populate the call details
 */
const decipherHandsetorARCCall = (stages: OmniviaSCUEvent[]) => {
  let call: iDoorCall = {
    ...defaultDoorCallValues,
  };

  stages.forEach((s) => {
    const data = JSON.parse(s.eventJSON ?? '');

    if (data) {
      const newDate = new Date(s.raisedAt);
      if (newDate < oldestEventDate) {
        oldestEventDate = newDate;
      }

      switch ((data as iBasicDoorEvent).EventType) {
        case AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RAISE:
          {
            const cdata = {
              ...data,
              Name: 'Concierge',
            };

            call = {
              ...data,
            };
            call.CallData = cdata;
          }
          break;
        case AUDIT_EVENTS.AUDIT_EVENT_ALARM_DEST_RAISE:
          {
            if (call.CallData) {
              call.CallData = {
                ...call.CallData,
                RaiseTime: (data as iAUDIT_EVENT_ALARM_DEST_RAISE).RaisedTime,
              };
            }
          }
          break;
        case AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_SELECT:
          {
            const eventJSON = data as iAUDIT_EVENT_DOOR_CALL_SELECT;
            let answerByString = '';

            // DestinationData has only been added to iAUDIT_EVENT_DOOR_CALL_SELECT in SCU versions >= R4.50
            // If DestinationData not present then parse the friendlyString to get the name
            if (
              eventJSON.DestinationData !== undefined &&
              eventJSON.DestinationData !== null &&
              eventJSON.DestinationData !== ''
            ) {
              answerByString = eventJSON.DestinationData;
            } else {
              const friendlyString = eventJSON.FriendlyString;

              // remove the (alarm id) and 'Door call selected' from the string so we are left with the name who selected
              answerByString = friendlyString
                .replace(` (${call.AlarmID})`, '')
                .replace('Door call selected by ', '')
                .replace(/'/g, '');
            }

            const companionSelectEvent = stages.find(
              (element) =>
                element.eventIdText?.trim() === 'Companion door call select'
            );

            const rdcSelectEvent = stages.find(
              (element) =>
                element.eventIdText?.trim() === 'RDC door call select'
            );

            if (call.CallData) {
              call.CallData = {
                ...call.CallData,
                SelectTime: (data as iAUDIT_EVENT_DOOR_CALL_SELECT).RaisedTime,
              };
            }

            if (companionSelectEvent) {
              answerByString = 'Resident mobile companion';
            } else if (rdcSelectEvent) {
              answerByString = 'Resident RDC';
            }
            call.AnsweredBy = answerByString;
          }
          break;

        case AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_CLEAR:
          {
            if (call.CallData) {
              call.CallData = {
                ...call.CallData,
                ClearTime: (data as iAUDIT_EVENT_DOOR_CALL_CLEAR).RaisedTime,
              };
            }
          }
          break;

        case AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_CLEAR_OPEN:
          {
            if (call.CallData) {
              call.CallData = {
                ...call.CallData,
                ClearTime: (data as iAUDIT_EVENT_DOOR_CALL_CLEAR_OPEN)
                  .RaisedTime,
                OpenedDoor: true,
              };
            }
          }
          break;
      }
    }
  });
  doorCalls.push(call);
};

/** parse all the event data to filter only the alarm type events
 *  we determine this from the aggregation id being present
 */
export const getDoorCalls = (): iDoorCall[] => {
  doorCalls = [];

  // Get all door call raised events
  const dcalls = SystemEvents.getEventsByType(
    [
      AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RAISE, // Handsets and ARCs
      AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RAISE_RU, // Rooms,
      // AUDIT_EVENTS.AUDIT_EVENT_DOOR_TRADES_ALLOW,
      // AUDIT_EVENTS.AUDIT_EVENT_DOOR_TRADES_DENY,
    ],
    false,
    true
  ) as DoorCallRaise[];

  // Get all door entry PIN events
  const dPINs = SystemEvents.getEventsByType(
    [
      AUDIT_EVENTS.AUDIT_EVENT_DOOR_TRADES_ALLOW,
      AUDIT_EVENTS.AUDIT_EVENT_DOOR_TRADES_DENY,
    ],
    false,
    true
  ) as DoorCallPIN[];

  // run through all alarm IDS and the get all events for that alarm ID
  dcalls.forEach((d) => {
    //let newDoorCall: iDoorCalls={EventType:0, RaisedDeviceID:0, AlarmID: json, };
    const doorCallId: number = d.AlarmID ?? 0;
    const stages = SystemEvents.allEvents.filter(
      (e) => e.aggregationId == doorCallId
    );

    // sort the events by transactionId so that we have the correct order
    stages.sort(function (a, b) {
      return a.transactionId - b.transactionId;
    });

    if (d.EventType == AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RAISE_RU)
      decipherResidentCall(stages);
    else decipherHandsetorARCCall(stages);
  });
  // Deduce the destination of the alarm
  // We'll need to lookup the careSequences to establish the destination

  // Add this code in once the door panel sends audit events up for allowed and denied
  //Add in the PIN events
  dPINs.forEach((p) => {
    let addInfo = '';
    // console.log(p);

    if (p.EventType == AUDIT_EVENTS.AUDIT_EVENT_DOOR_TRADES_ALLOW)
      addInfo = (p as iAUDIT_EVENT_DOOR_TRADES_ALLOW).AddtionalInfo;
    const pinname = addInfo ? JSON.parse(addInfo).pinname : '';

    const call: iDoorCall = {
      ...defaultDoorCallValues,
      RaisedTime: p.RaisedTime,
      OwnerDeviceName: p.OwnerDeviceName,
      CallData: {
        ...defaultRoomDataValues,
        Name:
          p.EventType == AUDIT_EVENTS.AUDIT_EVENT_DOOR_TRADES_ALLOW
            ? pinname + ' ' + 'PIN'
            : 'PIN not allowed',
        SelectTime: p.RaisedTime,
        OpenedDoor: p.EventType == AUDIT_EVENTS.AUDIT_EVENT_DOOR_TRADES_ALLOW,
      },
    };
    doorCalls.push(call);
  });

  dcalls.sort(function (a, b) {
    const dateA = new Date(a.RaisedTime);
    const dateB = new Date(b.RaisedTime);
    if (dateA === dateB) return 0;
    if (dateA < dateB) return -1;
    return 1;
  });

  return doorCalls;
};

export const getOldestEntry = (): Date => {
  return oldestEventDate;
};

// export interface iAUDIT_EVENT_DOOR_CALL_RAISE {
//   EventType: number;
//   RaisedDeviceID: number;
//   AlarmID: number;
//   RaisedTime: string;
//   OwnerDeviceName: string;
//   FriendlyString: string;
// }

// export interface iAUDIT_EVENT_DOOR_CALL_SELECT {
//   EventType: number;
//   AlarmID: number;
//   RaisedTime: string;
//   FriendlyString: string;
// }

// export interface iAUDIT_EVENT_DOOR_CALL_RAISE_RU {
//   EventType: number;
//   RaisedDeviceID: number;
//   DestinationDeviceID: number;
//   DestinationData: string;
//   AlarmID: number;
//   RaisedTime: string;
//   OwnerDeviceName: string;
//   AddtionalInfo: string;
//   FriendlyString: string;
// }

/** Register for events.... */

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.SITEEVENTS,
  eventDispatcher.systemEventStates.PROCESSED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    getDoorCalls();
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.SITEEVENTS,
  eventDispatcher.systemEventStates.UPDATED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    // e will have the new record
    getDoorCalls();
  }
);

// Door calls to residents:
// ========================

// Door call with open:
// AUDIT_EVENT_DOOR_CALL_RAISE_RU
// AUDIT_EVENT_DOOR_CALL_RU_SELECT
// AUDIT_EVENT_DOOR_CALL_CLEAR_OPEN

// Door call without open:
// AUDIT_EVENT_DOOR_CALL_RAISE_RU
// AUDIT_EVENT_DOOR_CALL_RU_SELECT
// AUDIT_EVENT_DOOR_CALL_CLEAR

// Door cancelled at door :
// AUDIT_EVENT_DOOR_CALL_RAISE_RU
// AUDIT_EVENT_DOOR_CANCEL

// Door call cancelled at room :
// AUDIT_EVENT_DOOR_CALL_RAISE_RU
// AUDIT_EVENT_DOOR_CALL_FAIL

// Door call timeout
// AUDIT_EVENT_DOOR_CALL_RAISE_RU
// AUDIT_EVENT_DOOR_CALL_ANSWER_TIMEOUT

// Door calls to HS:
// =================

// Door call with open:
// AUDIT_EVENT_DOOR_CALL_RAISE
// AUDIT_EVENT_ALARM_DEST_RAISE
// AUDIT_EVENT_HS_NOTIFIED  <- one for each handset
// AUDIT_EVENT_DOOR_CALL_SELECT
// AUDIT_EVENT_DOOR_CALL_CLEAR_OPEN

// Door call without open :
// AUDIT_EVENT_DOOR_CALL_RAISE
// AUDIT_EVENT_ALARM_DEST_RAISE
// AUDIT_EVENT_HS_NOTIFIED  <- one for each handset
// AUDIT_EVENT_DOOR_CALL_SELECT
// AUDIT_EVENT_DOOR_CALL_CLEAR

// Door call cleared at door:
// AUDIT_EVENT_DOOR_CALL_RAISE
// AUDIT_EVENT_ALARM_DEST_RAISE
// AUDIT_EVENT_HS_NOTIFIED  <- one for each handset
// AUDIT_EVENT_DOOR_CANCEL

// Door call timeout:
// AUDIT_EVENT_DOOR_CALL_RAISE
// AUDIT_EVENT_ALARM_DEST_RAISE
// AUDIT_EVENT_HS_NOTIFIED  <- one for each handset
// AUDIT_EVENT_DOOR_CALL_ANSWER_TIMEOUT

// Door calls to ARC:
// =================

// AUDIT_EVENT_DOOR_CALL_RAISE
// AUDIT_EVENT_ALARM_DEST_RAISE
// AUDIT_EVENT_DOOR_CALL_SELECT
// AUDIT_EVENT_DOOR_CALL_CLEAR_OPEN

// door to ARC without open
// AUDIT_EVENT_DOOR_CALL_RAISE
// AUDIT_EVENT_ALARM_DEST_RAISE
// AUDIT_EVENT_DOOR_CALL_SELECT
// AUDIT_EVENT_DOOR_CALL_CLEAR

// door to ARC timeout
// AUDIT_EVENT_DOOR_CALL_RAISE
// AUDIT_EVENT_ALARM_DEST_RAISE
// AUDIT_EVENT_DOOR_CALL_ANSWER_TIMEOUT

// door to ARC cancel at door
// AUDIT_EVENT_DOOR_CALL_RAISE
// AUDIT_EVENT_ALARM_DEST_RAISE
// AUDIT_EVENT_DOOR_CANCEL
