/**
 * Handles all site events
 *
 * @module BatteryEvents
 */

import store from 'store/store';
import { OmniviaLiveEventsLog } from 'types/API';
import * as liveEventsLogSlice from 'store/liveEventsLogSlice';
import * as scuSlice from 'store/scusSlice';
import { iHandSetChargeEvent } from 'types/manifest-types';
import {
  formatDateSetMilliSecondsToZero,
  dateToIsoString_dateonly,
  formatDate,
} from 'common/utils/dateUtils';
import * as eventDispatcher from 'store/eventDispatcher';
import * as dataItems from 'common/GetDataItems';

export let allLiveEvents: OmniviaLiveEventsLog[] = [];

export interface iLocationHistory {
  id: string;
  mac: string;
  parentMac: string;
  rssi_db: number;
  createdAt: string;
  batteryVoltageApplication: number;
}

let SCUSerialNumber = '';
let SCUAlive = false;

export const getSiteAlive = (): boolean => {
  return SCUAlive;
};

// TODO : write a function to detect is the site is alive, use an indicator such as the last event was within 1 hour
export const isSiteAlive = (): boolean => {
  const e = allLiveEvents[allLiveEvents.length - 1];
  if (e && e.createdAt) {
    const d = new Date(e.createdAt);
    const HOUR = 1000 * 60 * 60;
    const anHourAgo = new Date(Date.now() - HOUR);
    const alive = d > anHourAgo;
    //console.log('ALIVE', alive, d, anHourAgo);
    return alive;
  }
  return false;
};

// Handle registration of listeners on a new data load

/**
 *
 * @param events: list of live events
 * @param selectedSCUId: the currently selected SCU serial number
 */
export const load = (
  events: OmniviaLiveEventsLog[],
  currentSelectedSCUSerialNumber: string
): number => {
  //console.info('Live Events load:', events);
  SCUSerialNumber = currentSelectedSCUSerialNumber;
  allLiveEvents = events;

  SCUAlive = isSiteAlive();

  return allLiveEvents.length;
};

export const fetch = (daysToFetch?: number): void => {
  if (!daysToFetch) {
    daysToFetch = 1;
  }

  SCUAlive = false;

  store.dispatch(
    liveEventsLogSlice.fetch({
      SCUSerialNumber: SCUSerialNumber,
      daysToFetch: daysToFetch,
    })
  );
};

/** returns any data from the live log for the handset with the supplied mac address  */
export const getHandsetChargeInfo = (mac: string): iHandSetChargeEvent[] => {
  const chargeinfo: iHandSetChargeEvent[] = [];

  allLiveEvents.forEach((item) => {
    if (item.handset && item.mac == mac) {
      const ts =
        formatDateSetMilliSecondsToZero(item.updatedAt) ??
        '1971-01-01T00:00:00.000Z';
      chargeinfo.push({ Info: item.handset, Timestamp: ts });
    }
  });

  return chargeinfo;
};

/** returns any data from the live log for the supplied mac address  */
export const getLiveEvents = (mac: string): OmniviaLiveEventsLog[] => {
  let info: OmniviaLiveEventsLog[] = [];

  info = allLiveEvents.filter((item) => item.mac == mac);

  return info;
};

/** returns the last (lastest) data from the live log */
export const getLastLiveEventTime = (): string => {
  let info = 'Site has never been connected';
  const e = allLiveEvents[allLiveEvents.length - 1];
  if (e && e.createdAt) {
    info = 'Last Connected: ' + formatDate(e.createdAt);
  }
  return info;
};

/** returns the last (lastest) data from the live log for the supplied mac address  */
export const getLastLiveEvent = (mac: string): OmniviaLiveEventsLog => {
  let info: unknown = {};

  const i = getLiveEvents(mac);
  if (i) {
    info = i[i.length - 1];
  }

  return info as OmniviaLiveEventsLog;
};

function getAllDataFromTheStore() {
  const scuSelected = scuSlice.getSelected(store.getState());
  if (scuSelected) {
    load(
      liveEventsLogSlice.selectAll(store.getState()),
      scuSelected.serialNumber
    );
  }
}

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.SITE,
  eventDispatcher.systemEventStates.SELECTED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    // console.log('ALIVE: clear live events');
    SCUAlive = false;
    allLiveEvents = [];
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.LIVEEVENTS,
  eventDispatcher.systemEventStates.LOADED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    getAllDataFromTheStore();
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.LIVEEVENTS,
  eventDispatcher.systemEventStates.UPDATED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    // e will have the new record
    getAllDataFromTheStore();
  }
);
// Thunk functions
export const fetchLocationHistory = async (
  macAddress: number,
  daysToFetch: number
): Promise<iLocationHistory[]> => {
  const dateOffset = 24 * 60 * 60 * 1000 * daysToFetch;
  const date = new Date();
  date.setTime(date.getTime() - dateOffset);
  const dateInPast: string = dateToIsoString_dateonly(date);

  console.info('Get live log for:', macAddress);

  const query = /* GraphQL */ `
    query LiveEventsLogByDate(
      $mac: String
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOmniviaLiveEventsLogFilterInput
      $limit: Int
      $nextToken: String
    ) {
      liveEventsLogByDate(
        mac: $mac
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          mac
          deviceType
          deviceId
          batteryVoltageApplication
          parentMac
          rssi_db
          createdAt
        }
        nextToken
      }
    }
  `;

  const response = await dataItems.GetDataItems({
    query: query,
    variables: {
      limit: 5000,
      mac: macAddress,
      sortDirection: 'DESC',
      createdAt: { gt: dateInPast },
    },
    recurse: true,
    log: false,
    selector: 'liveEventsLogByDate',
  });
  return response;
};
