/** @module FloorPlans */

import { iSite, Point } from 'types/plan-types';
import { Product } from 'components/floorplan/product';

import { Equipment, INSTALLSTATUS, Location } from 'types/product-types';
import * as Site from 'components/floorplan/site';
import { fabric } from 'fabric';
import { getImage } from 'common/S3Bucket';
import jsPDF from 'jspdf';
import { Device } from 'components/installation/device';
import * as eventDispatcher from 'store/eventDispatcher';
import { createEquipmentTable, updateEquipmentTable } from './equipmentTable';
import template from 'assets/img/a4LandscapeTemplate.png';
import { createTitleBlock } from './titleBlock';
import logger from 'common/logger';
import { defaultLocationValues } from '../../types/product-types';
import FileSaver from 'file-saver';
import { showCables } from './canvasCabling';

export let canvas: fabric.Canvas | undefined = undefined;

/**
 * Handles all Canvas GUI elements for the site equipment layout data and associated floorplans
 */

/** the index of the current floor plan */
let currentPlanIndex = 0;
/** the currently selected equipment */
let currentEquipmentIndex = -1;

// 72DPI A4 landscape = 842 x 595
// 150DPI A4 landscape = 1754x1240
// 300DPI A4 landscape = 3508 x 2480
const height = 2480;
const width = 3508;

export const getCurrentPlanIndex = (): number => {
  return currentPlanIndex;
};

export const getCurrentEquipmentIndex = (): number => {
  return currentEquipmentIndex;
};

/** Pull site layout data and get the site images from S3 */
export async function loadSite(): Promise<void> {
  // Get the JSON data in first
  await Site.loadSite();

  // Now convert the plans into images
  Site.siteData.plans.forEach(async (item) => {
    item.planImageURL = <string>(
      await getImage(
        `floorplans/${Site.siteData.name}/${item.planImageFileName}`
      )
    );
    // get the plan image
  });
}

/** Store site layout data back to S3. */
export async function storeSite(): Promise<void> {
  await Site.storeSite();
}

export const getSiteData = (): iSite => {
  return Site.siteData;
};

export const initCanvas = (): fabric.Canvas => {
  canvas = new fabric.Canvas('canvas', {
    height: height,
    width: width,
    backgroundColor: 'white',
    //preserveObjectStacking: true,
    renderOnAddRemove: true,
  });
  return canvas;
};

export const clearCanvas = (): void => {
  if (canvas) canvas.remove(...canvas.getObjects());
};

export const resetCanvas = (): void => {
  if (canvas) canvas.setViewportTransform([0.5, 0, 0, 0.5, 0, 0]);
};

/** Populate plan */
export const populatePlan = (planNumber: number): void => {
  try {
    if (planNumber < 0 || planNumber > Site.siteData.plans.length) {
      throw `Invalid Plan number: ${planNumber}`;
    }
    clearCanvas();

    currentPlanIndex = planNumber;
    const plan = Site.siteData.plans[planNumber];

    fabric.Image.fromURL(plan.planImageURL, (img) => {
      img.set({
        left: plan.Xoffset,
        top: plan.Yoffset,
        scaleX: 1, //plan.scale,
        scaleY: 1, //plan.scale,
        selectable: false,
        evented: false, // disable events
      });
      canvas?.add(img);
      canvas?.sendToBack(img);
    });

    fabric.Image.fromURL(template, (img) => {
      img.set({
        left: 0,
        top: 0,
        scaleX: 1, //plan.scale,
        scaleY: 1, //plan.scale,
        selectable: false,
        evented: false, // disable events
      });
      canvas?.add(img);
      //canvas?.bringForward(img);
    });

    // populate the equipment
    Site.siteData.equipment.forEach((item) => {
      if (item.plan == planNumber) canvas?.add(item.draw());
    });

    // show the equipment table
    if (canvas) {
      canvas.selection = false;
      const zoom = canvas.getZoom();
      resetCanvas();
      canvas.setZoom(zoom); // retain previous zoom level
      showCables(planNumber, canvas);
      createEquipmentTable(canvas, currentPlanIndex);
      updateEquipmentTable(canvas, currentPlanIndex);
      createTitleBlock(canvas, currentPlanIndex);
      canvas.hoverCursor = 'crosshair';
    }
  } catch (err) {
    console.log(`!!! PLAN LOAD ERROR ${err}`);
  }
};

/** Add a piece of equipment to the plan
 * TODO: When adding doorpanel, VPC, lift etc then auto add an MAI nearby on the plan??
 * @param: Point - location on the plan(s)
 * @param: Equipment - productDefinition - the equipment to add
 * @param: Device - optional real installed device matching this plan item
 * @param: planidOverride - optional ID to be used for the plan rather than the auto generated one, useful for matching a real room number
 * @return: Product - the Product object that was added
 */
export const addEquipment = (
  point: Point,
  productDefinition: Equipment,
  device?: Device,
  planidOverride?: number
): Product => {
  logger.info('canvasSite.addEquipment', {
    detail: {
      point: point,
      productDefinition: productDefinition,
      device: device,
      planidOverride: planidOverride,
    },
  });

  const p = Site.addEquipment(point, productDefinition, device, planidOverride);
  const g = p.draw();
  canvas?.add(g);
  console.log(p);
  canvas && updateEquipmentTable(canvas, currentPlanIndex);
  return p;
};

/** Take a canvas event and get a point object with the X,Y */
export const getEventPointer = (event: fabric.IEvent): Point => {
  let p: Point = { plan: 0, x: 0, y: 0 };
  if (canvas) {
    const pointer = canvas.getPointer(event.e);
    p = { plan: currentPlanIndex, x: pointer.x, y: pointer.y };
  }
  return p;
};

/** Select equipment */
export const selectEquipment = (nameID: string | undefined): void => {
  let e = -1;
  if (nameID) {
    e = Site.getIndexFromUniqueID(nameID);
  }
  logger.info('canvasSite.selectEquipment', {
    detail: {
      nameID: nameID,
      index: e,
    },
  });

  // event.target?.set('borderColor', 'purple');
  canvas?.renderAll();
  currentEquipmentIndex = e;
};

/** Remove a piece of equipment from the site plan */
export const removeEquipment = (): void => {
  if (currentEquipmentIndex >= 0) {
    logger.info('canvasSite.removeEquipment', {
      detail: {
        index: currentEquipmentIndex,
      },
    });

    Site.removeEquipment(currentEquipmentIndex);
    if (canvas) {
      canvas.remove(canvas.getActiveObject());
      updateEquipmentTable(canvas, currentPlanIndex);
    }
  }
};

/** Move a piece of equipment */
export const moveEquipment = (
  offsetX: number,
  offsetY: number,
  findID: string
): void => {
  logger.info('canvasSite.moveEquipment', {
    detail: {
      offsetX: offsetX,
      offsetY: offsetY,
      findID: findID,
    },
  });
  Site.moveEquipment(offsetX, offsetY, findID);
};

/** Locate equipment from its physical ID (MAC Address) */
export const locateEquipment = (physicalID: number): Location => {
  let loc: Location = {
    ...defaultLocationValues,
  };
  const e = Site.getIndexFromPhysicalID(physicalID);
  if (e != -1) loc = Site.siteData.equipment[e].getLocation();
  currentEquipmentIndex = e;
  return loc;
};

/** Create pdf */
export const createPDF = (): void => {
  console.log('create pdf');
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'px', // set the unit of measurement
    format: 'a4', // set paper size format
    compress: false,
    //userUnit: 1,
  });

  //ensure we are fully zoomed in
  canvas?.setZoom(1);

  const width = pdf.internal.pageSize.getWidth();
  const height = pdf.internal.pageSize.getHeight();

  logger.info('canvasSite.createPDF');
  if (canvas) {
    const imgData = canvas.toDataURL({ format: 'image/png', quality: 1 });

    if (imgData) {
      pdf.addImage({
        imageData: imgData,
        format: 'PNG',
        x: 0,
        y: 0,
        width: width,
        height: height,
        compression: 'FAST',
      });
      // FileSaver.saveAs(imgData, 'canvas.png');
      pdf.save(
        `${Site.siteData.quoteRef} ${Site.siteData.name} ${Site.siteData.plans[currentPlanIndex].planName} - Wireless Health GA R${Site.siteData.revision}`
      );
    }
  }
};

/** Create plandata.json locally */
export const exportPlanData = (): void => {
  console.log('download sitedata and manifest jsons');
  FileSaver.saveAs(
    new Blob([JSON.stringify(Site.siteData)]),
    `plandata ${Site.siteData.name} R${Site.siteData.revision}.json`
  );
  FileSaver.saveAs(
    new Blob([JSON.stringify(Site.manifest.getJSON())]),
    `manifest ${Site.siteData.name} R${Site.siteData.revision}.json`
  );
};

/** call to refresh the UI with the product, i.e. if we have altered some UI aspects fo the product
 *  We also update the store as this is a common additional requirement when calling a change to the product UI
 */
export const refreshProductUI = (equip: Product): void => {
  //remove existing equipment
  const obj = canvas?.getObjects().find((f) => f.name == equip.uniqueID);
  if (obj) canvas?.remove(obj);
  //re-draw equipment
  canvas?.add(equip.draw());
  Site.updateStore(true);
};

/** Alter the installation status */
export const setinstallStatus = (installStatus: INSTALLSTATUS): void => {
  if (currentEquipmentIndex >= 0) {
    logger.info('canvasSite.setinstallStatus', installStatus);

    const equip: Product = Site.siteData.equipment[currentEquipmentIndex];
    equip.installStatus = installStatus;
    //re-draw existing equipment
    refreshProductUI(equip);
  }
};

/** Alter whether this is a common are unit */
export const setCommon = (): void => {
  Site.siteData.equipment[currentEquipmentIndex].commonArea =
    !Site.siteData.equipment[currentEquipmentIndex].commonArea;
  const equip: Product = Site.siteData.equipment[currentEquipmentIndex];
  equip.commonArea = Site.siteData.equipment[currentEquipmentIndex].commonArea;
  //re-draw existing equipment
  refreshProductUI(equip);
};

/** Alter the rotation of the product */
export const rotateGroup = (degrees: number): void => {
  let angle = Site.siteData.equipment[currentEquipmentIndex].angle;
  angle += degrees;
  if (angle == 360) angle = 0;
  if (angle > 360) angle = degrees;
  Site.siteData.equipment[currentEquipmentIndex].angle = angle;

  const equip: Product = Site.siteData.equipment[currentEquipmentIndex];
  //re-draw existing equipment
  refreshProductUI(equip);
};

/** Alter the callout length of the product */
export const alterCalloutLength = (amount: number): void => {
  let len = Site.siteData.equipment[currentEquipmentIndex].calloutLength;
  if (len == 0) len = 10;

  len += amount;

  // bounds
  if (len > 500) len = 500;
  if (len < 10) len = 10;

  Site.siteData.equipment[currentEquipmentIndex].calloutLength = len;

  const equip: Product = Site.siteData.equipment[currentEquipmentIndex];
  //re-draw existing equipment
  refreshProductUI(equip);
};

/** Move to next piece of equipment with the same designator type */
export const nextEquipment = (direction: 'forwards' | 'backwards'): void => {
  const current = Site.siteData.equipment[currentEquipmentIndex];
  const next = Site.findNext(current, direction);

  if (next) {
    currentEquipmentIndex = Site.getIndexFromUniqueID(next.uniqueID);
    canvas?.getObjects().forEach((obj) => {
      const Name = obj.name;
      if (Name == next.uniqueID) {
        canvas?.setActiveObject(obj);
        canvas?.renderAll();
      }
    });
  }
};

// temporary function to rescale product positions on previoulsy planned sites to take account of new resolution
export const rescale = (): void => {
  Site.siteData.equipment.forEach((e) => {
    e.x = e.x * 2;
    e.y = e.y * 2;
  });
  Site.updateStore(true);
  // reload the plan UI
  populatePlan(currentPlanIndex);
};

// temporary function to offset product positions on previoulsy planned sites to take account of new arrow rather than circle
export const rePosition = (): void => {
  Site.siteData.equipment.forEach((e) => {
    e.x = e.x - 4;
    e.y = e.y - 4;
  });
  Site.updateStore(true);
  // reload the plan UI
  populatePlan(currentPlanIndex);
};

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.FLOORPLANS,
  eventDispatcher.systemEventStates.LOADED,
  () => {
    loadSite();
  }
);

// eventDispatcher.registerForEvent(
//   eventDispatcher.systemEventTopics.FLOORPLANS,
//   eventDispatcher.systemEventStates.UPDATED,
//   (e) => {
//     // e will have the new record
//     loadSite();
//   }
// );
