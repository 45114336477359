import React from 'react';
import HandsetsOverview from '../desktopWidgets/handset/handsetsOverview';
import HandsetChargeGraph from '../desktopWidgets/handset/handsetChargeGraph';
import PieChart from '../desktopWidgets/pieChart';
import RecentAlarms from '../desktopWidgets/alarms/recentAlarms';
import PlanSpy from '../desktopWidgets/planSpy';
import DoorCalls from '../desktopWidgets/doorCalls';
import RecentFaults from '../desktopWidgets/recentFaults';
import ServiceTestMode from '../desktopWidgets/serviceTestMode';
import TriggersView from '../desktopWidgets/triggersView';
import UserAdmin from '../desktopWidgets/userAdmin';
import ResidentOK from '../desktopWidgets/resident/residentOK';
import SystemParameterEditor from '../desktopWidgets/systemParameterEditor';
import HandsetChargeTable from '../desktopWidgets/handset/handsetChargeTable';
import AccessPINs from '../desktopWidgets/doorEntry/accessPINs';
import ArcDetails from '../desktopWidgets/arc/arcConfiguration';
import RoomUnitConfigTable from '../desktopWidgets/resident/roomUnitsConfigTable';
import AlarmAuditTrail from '../desktopWidgets/alarms/alarmAudit';
import Sites from '../desktopWidgets/sites';
import SiteEdit from '../desktopWidgets/siteEdit';
import Clients from '../desktopWidgets/clients';
import ClientEdit from '../desktopWidgets/clientEdit';
import VRUAlarmRing from '../desktopWidgets/vruAlarmRing';
import { UAG } from 'common/userUtils';
import DeviceView from '../desktopWidgets/deviceView';
import SWVersions from '../desktopWidgets/swVersions';
import HandsetSettings from '../desktopWidgets/handset/handsetSettings';
import InterfaceConfigTable from '../desktopWidgets/interfaceConfigTable';
import AccessPointConfigTable from '../desktopWidgets/accessPointConfigTable';
import HandsetConfigTable from '../desktopWidgets/handset/handsetConfigTable';
import UnassignedConfigTable from '../desktopWidgets/unassignedConfigTable';
import SCUInputConfig from '../desktopWidgets/scuInputsConfig';
import CameraUrls from '../desktopWidgets/cameraUrls';
import AssignDoorCamera from '../desktopWidgets/assignDoorCamera';
import { WIDGETCAT, iWidgetEntry } from './widgetTypes';
import SCUEdit from '../desktopWidgets/scuEdit';
import UserSettings from '../desktopWidgets/userSettings';
import SiteAlertSettings from '../desktopWidgets/siteAlertSettings';
import DeviceLocationHistory from '../commonWidgets/deviceLocationHistoryView';
import CareGroupsConfigTable from '../desktopWidgets/callSequences/careGroupsConfigTable';
import AlarmTest from '../desktopWidgets/development/alarmTest';
import RoutingEventsTable from '../desktopWidgets/development/routingEventsTable';
import AutoTestAlarms from '../desktopWidgets/automatedTest/autotest_alarms';
import Resident from '../desktopWidgets/resident/resident';
import GlobalMonitoringSettings from '../desktopWidgets/resident/globalMonitoringSettings';
import DoorEntryConfigOld from '../desktopWidgets/doorEntryConfigOld';
import AlarmTypes from '../desktopWidgets/alarms/alarmTypes';
import ResidentNotOKOverview from '../commonWidgets/residentNotOKOverview';
import ResidentGroups from '../desktopWidgets/resident/residentGroups';
import DeviceE2Configuration from '../desktopWidgets/development/deviceE2Configuration';
import Announcements from '../desktopWidgets/announcements/announcements';
import RoomsInTestMode from '../desktopWidgets/roomsInTestMode';
import ReportsCards from '../desktopWidgets/reports/reportsCards';

export const desktopWidgetLibrary: Array<iWidgetEntry> = [
  {
    name: 'RecentAlarms',
    description: 'List of Recent Alarms',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <RecentAlarms />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'AlarmTypes',
    description: 'Alarm Types and Priorities',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <AlarmTypes />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'DoorCalls',
    description: 'List of Recent Door Calls',
    category: WIDGETCAT.DOORS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <DoorCalls />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'HandsetOverview',
    description: 'All Handsets and their status',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <HandsetsOverview />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'HandsetChargeGraph',
    description: 'Graph of Handset Charge over time',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <HandsetChargeGraph />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  {
    name: 'AlarmsByType',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <PieChart />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'Reports',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <ReportsCards />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'SystemFaults',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <RecentFaults />,
      },
    ],
    accessLevel: UAG.TECHNICAL,
  },
  {
    name: 'ServiceTestMode',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <ServiceTestMode />,
      },
    ],
    accessLevel: UAG.TECHNICAL,
  },
  {
    name: 'RoomsInTestMode',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <RoomsInTestMode />,
      },
    ],
    accessLevel: UAG.TECHNICAL,
  },
  {
    name: 'PlanSpy',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <PlanSpy />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'TriggersView',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <TriggersView />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'TriggersViewLowBattery',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <TriggersView />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'UserAdmin',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <UserAdmin />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  {
    name: 'ResidentOK',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <ResidentNotOKOverview />,
      },
      {
        minimumSCUSoftwareVersion: '5.11.0',
        widget: <ResidentOK />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'Announcements',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <Announcements />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'GlobalMonitoringSettings',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <GlobalMonitoringSettings />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'SystemParameterEditor',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <SystemParameterEditor />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'HandsetChargeTable',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <HandsetChargeTable />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  // {
  //   name: 'DoorEntryConfigOld',
  //   description: '',
  //   category: WIDGETCAT.GENERAL,
  //   widget: [
  //     {
  //       minimumSCUSoftwareVersion: '0.0.0',
  //       widget: <DoorEntryConfigOld />,
  //     },
  //   ],
  //   accessLevel: UAG.MANAGER,
  // },
  {
    name: 'AccessPINs',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <DoorEntryConfigOld />,
      },
      {
        minimumSCUSoftwareVersion: '5.10.0',
        widget: <AccessPINs />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'ArcTelephoneNumbers',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <ArcDetails />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'Resident',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <Resident />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'ResidentGroups',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '5.10.0',
        widget: <ResidentGroups />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'RoomUnitConfigTable',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <RoomUnitConfigTable />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },

  {
    name: 'AlarmAuditTrail',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <AlarmAuditTrail />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'Sites',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <Sites />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  {
    name: 'SiteEdit',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <SiteEdit />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  {
    name: 'Clients',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <Clients />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  {
    name: 'ClientEdit',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <ClientEdit />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  {
    name: 'VRUAlarmRing',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <VRUAlarmRing />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'DeviceList',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <DeviceView />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'SWVersions',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <SWVersions />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  {
    name: 'HandsetSettings',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <HandsetSettings />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'CareGroups',
    description: '',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <CareGroupsConfigTable />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'InterfaceConfigTable',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <InterfaceConfigTable />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'AccessPointConfigTable',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <AccessPointConfigTable />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'HandsetConfigTable',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <HandsetConfigTable />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'UnassignedConfigTable',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <UnassignedConfigTable />,
      },
    ],
    accessLevel: UAG.TECHNICAL,
  },
  {
    name: 'SCUInputConfig',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <SCUInputConfig />,
      },
    ],
    accessLevel: UAG.TECHNICAL,
  },
  {
    name: 'CameraUrls',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <CameraUrls />,
      },
    ],
    accessLevel: UAG.TECHNICAL,
  },
  {
    name: 'AssignDoorCamera',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <AssignDoorCamera />,
      },
    ],
    accessLevel: UAG.TECHNICAL,
  },
  {
    name: 'SCUEdit',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <SCUEdit />,
      },
    ],
    accessLevel: UAG.ELITE,
  },
  {
    name: 'UserSettings',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <UserSettings />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'SiteAlertSettings',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <SiteAlertSettings />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'DeviceLocationHistory',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <DeviceLocationHistory />,
      },
    ],
    accessLevel: UAG.MANAGER,
  },
  {
    name: 'AutoTest Alarms',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <AutoTestAlarms />,
      },
    ],
    accessLevel: UAG.ADMIN,
  },
  {
    name: 'AlarmTest',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <AlarmTest />,
      },
    ],
    accessLevel: UAG.ADMIN,
  },
  {
    name: 'RoutingEventsTable',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <RoutingEventsTable />,
      },
    ],
    accessLevel: UAG.ADMIN,
  },

  {
    name: 'DeviceE2Configuration',
    description: '',
    category: WIDGETCAT.GENERAL,
    widget: [
      {
        minimumSCUSoftwareVersion: '5.0.0',
        widget: <DeviceE2Configuration />,
      },
    ],
    accessLevel: UAG.ADMIN,
  },
];
