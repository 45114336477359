import { Manifest } from 'components/installation/manifest';

import {
  createExcel,
  iExcelColumn,
  iExcelSchema,
} from 'common/excel/createExcel';
import { headerRows } from 'common/excel/headerRows';
import { alarmSummaries, iAlarmSummary } from '../../systemevents/alarmEvents';
import { Room } from 'components/installation/room';
import { ALARM_TYPES } from 'types/event-enums';
import { getAlarmDescription } from 'common/alarmTypesTextFiltered/alarmTypeUtils';
import {
  dateTodaySetTimeHHMM,
  dateIsAfterDateTime,
  dateSubtractDays,
} from 'common/utils/dateUtils';
import { AccessPoint } from 'components/installation/accesspoint';
import { Worksheet } from 'exceljs';
import { formatDate } from '../../../common/utils/dateUtils';
import { Product } from 'components/floorplan/product';
import { DoorAndDeviceInterface } from '../../installation/doorAndDeviceInterface';
import { Trigger } from 'components/installation/trigger';
import { SystemParameter } from '../../installation/systemParameter';
import { getProductFromPhysicalID } from 'components/floorplan/site';

const manifest = new Manifest();

// iAlarmSummary
// alarmId: number;
// callType: string;
// raisedAt: string;
// selectedAt?: string;
// clearedAt?: string;
// roomNumber: number;
// alarmType: string;
// source: string;
// answeredBy: string;
// timeToAnswer_seconds: number;
// callDuration_seconds: number;
// addtionalInfo: string;
// CancelReason?: string;
// raisedTo: string;
// originalAlarmType: number;
// careGroupID: number;
// careGroupDesc: string;
// raisedDeviceId: number;

export interface iCOVERAGE_ITEM {
  name: string;
  description: string;
  product: string;
  designator: string;
  productCode: string;
  roomNumber: number;
  originalAlarmType: number;
  alarmType: string;
  identity: string;
  alarms: iAlarmSummary[];
  total: number;
  lastAlarmDateTime: string;
}

let summary: iCOVERAGE_ITEM[] = [];

const reportColumns: iExcelColumn[] = [
  {
    columnName: 'Room Number',
    columnWidth: 16,
    fieldName: 'roomNumber',
    type: 'TEXT',
  },
  {
    columnName: 'Identity',
    columnWidth: 9,
    fieldName: 'identity',
    type: 'TEXT',
  },
  {
    columnName: 'Product',
    columnWidth: 28,
    fieldName: 'product',
    type: 'TEXT',
  },
  {
    columnName: 'Product SKU',
    columnWidth: 16,
    fieldName: 'productCode',
    type: 'TEXT',
  },
  {
    columnName: 'Plan',
    columnWidth: 12,
    fieldName: 'designator',
    type: 'TEXT',
  },
  {
    columnName: 'Name',
    columnWidth: 25,
    fieldName: 'name',
    type: 'TEXT',
  },
  {
    columnName: 'Description',
    columnWidth: 30,
    fieldName: 'description',
    type: 'TEXT',
  },
  {
    columnName: 'Last Raised',
    columnWidth: 20,
    fieldName: 'lastAlarmDateTime',
    type: 'DATETIME',
  },
  {
    columnName: 'Number Raised',
    columnWidth: 14,
    fieldName: 'total',
    type: 'TEXT',
  },
];

const alarmToFilterOut: ALARM_TYPES[] = [
  ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT,
  ALARM_TYPES.ALARM_TYPE_IP_COMMUNICATION_LINK,
  ALARM_TYPES.ALARM_TYPE_INTRUDER,
  ALARM_TYPES.ALARM_TYPE_INACTIVITY,
  ALARM_TYPES.ALARM_TYPE_RESIDENT_UNIT_POLL_FAILURE,
  ALARM_TYPES.ALARM_TYPE_RESIDENT_NOT_OK,
  ALARM_TYPES.ALARM_TYPE_RESIDENT_OK,
  ALARM_TYPES.ALARM_TYPE_RESIDENT_HOME,
  ALARM_TYPES.ALARM_TYPE_RESIDENT_AWAY,
  ALARM_TYPES.ALARM_TYPE_ROUTER_POLL_FAILURE,
  ALARM_TYPES.ALARM_TYPE_DOOR_POLL_FAILURE,
  ALARM_TYPES.ALARM_TYPE_TRIGGER_POLL_FAILURE,
  ALARM_TYPES.ALARM_TYPE_BATTERY,
  ALARM_TYPES.ALARM_TYPE_SYSTEM_UNDER_TEST,
  ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_2,
  ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_2,
  ALARM_TYPES.ALARM_TYPE_DOOR_CALL,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_CLOSE_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_OPEN_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_CLOSE_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_OPEN_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_CLOSE_EVENT,
  ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_OPEN_EVENT,
];

const addAlarmEntry = (
  device: Room | AccessPoint | DoorAndDeviceInterface | Trigger,
  type: ALARM_TYPES,
  originalType: ALARM_TYPES
) => {
  const alt = getAlarmDescription(type);
  const orig = getAlarmDescription(originalType);

  // filter out the alarms we don't want
  let add = !(
    (alarmToFilterOut.includes(type) || device.ID == 1) // device id=1 means Node 0 Access Point, we don't want to show alarms from that
  );

  // special filter for pullcord issue
  if (type == ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_2 && type == originalType)
    add = false;

  const equip = Product.getProductfromManifestDeviceType(device);
  const prod = getProductFromPhysicalID(device.MacAddress);

  // special filter for fall detectors
  if (
    (type == ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1 ||
      type == ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2) &&
    equip.productCode == 'PROD-3158'
  )
    add = false;

  if (!add) return;

  const roomNo =
    device instanceof Room
      ? device.RoomNumber //Room
      : device instanceof Trigger
      ? device.parentRoom?.RoomNumber //Trigger uses parent room
      : device.UnitId; // Access Points and Interfaces

  summary.push({
    name:
      device instanceof Trigger ? device.parentRoom?.Name ?? '' : device.Name,
    designator: prod ? prod.uniqueID : '',
    product: equip.name,
    productCode: equip.productCode,
    roomNumber: roomNo ?? 0,
    originalAlarmType: originalType,
    alarmType: alt,
    description: alt == orig ? alt : orig + ': ' + alt,
    identity:
      device instanceof Trigger
        ? device.getIdentity() // For triggers we need to convert dependent on manufacturers format, Tunstall / WH
        : device.MacAddressHex.length > 6
        ? device.MacAddressHex.substring(device.MacAddressHex.length - 6)
        : '',
    total: 0,
    alarms: [],
    lastAlarmDateTime: '',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let data: any[] = [];
let startDate: Date = new Date();

// Add an SCU input to the possible alarms list
const addSCUInput = (inputNo: number, param: SystemParameter | undefined) => {
  if (
    param &&
    param.Value &&
    Number(param.Value) != ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT
  ) {
    const type = Number(param.Value);
    console.log(type);
    const alarmDesc = `Input ${inputNo} :  ${getAlarmDescription(type)}`;
    summary.push({
      name: 'System Control Unit',
      product: 'SCU',
      designator: 'SCU1',
      productCode: 'PROD-3208',
      roomNumber: 0,
      originalAlarmType: type,
      alarmType: getAlarmDescription(type),
      description: alarmDesc,
      identity: '',
      total: 0,
      alarms: [],
      lastAlarmDateTime: '',
    });
  }
};

// Go through all room units and check for alarms
const assembleData = (startDate: Date): void => {
  summary = [];
  // Assemble all possible alarms from rooms
  manifest.rooms.forEach((room) => {
    room.getAlarmRoutingEvents()?.forEach((e) => {
      addAlarmEntry(room, e.AltAlarmTypeId, e.AlarmTypeId);
    });
  });

  // Assemble all possible alarms from triggers
  manifest.triggers.forEach((t) => {
    t.getAlarmRoutingEvents()?.forEach((e) => {
      addAlarmEntry(t, e.AltAlarmTypeId, e.AlarmTypeId);
    });
  });

  // Assemble all possible alarms from access points
  manifest.accessPoints.forEach((ap) => {
    ap.getAlarmRoutingEvents()?.forEach((e) => {
      addAlarmEntry(ap, e.AltAlarmTypeId, e.AlarmTypeId);
    });
  });

  // Assemble all possible alarms from interface units
  manifest.deviceInterfaces.forEach((ap) => {
    ap.getAlarmRoutingEvents()?.forEach((e) => {
      addAlarmEntry(ap, e.AltAlarmTypeId, e.AlarmTypeId);
    });
  });

  addSCUInput(1, manifest.getSystemParameter('SCU_INPUT_1_ALARM_TYPE'));
  addSCUInput(2, manifest.getSystemParameter('SCU_INPUT_2_ALARM_TYPE'));
  addSCUInput(3, manifest.getSystemParameter('SCU_INPUT_3_ALARM_TYPE'));

  alarmSummaries.forEach((a) => {
    let r: iCOVERAGE_ITEM | undefined = summary.find(
      (f) =>
        f.roomNumber == a.roomNumber &&
        f.originalAlarmType == a.originalAlarmType
    );

    if (a.roomNumber == 0) {
      // SCU == 0
      r = summary.find(
        (f) =>
          f.roomNumber == a.roomNumber && f.originalAlarmType == a.alarmTypeID
      );
    }

    if (r && dateIsAfterDateTime(startDate, a.raisedAt)) {
      r.alarms.push(a);
      r.lastAlarmDateTime = a.raisedAt;
      r.total = r.alarms.length;
    }
  });

  summary.sort(function (a, b) {
    if (a.roomNumber < b.roomNumber) {
      return -1;
    }
    if (a.roomNumber > b.roomNumber) {
      return 1;
    }
    return 0;
  });

  summary.forEach((e) => {
    if (e.total == 0) e.alarmType = e.alarmType + '**';
  });

  data = summary;
};

export const coverageHeaderRows = (sheet: Worksheet): void => {
  // call generic header first
  headerRows(sheet);

  const Row = sheet.addRow([
    'Alarms in Range:',
    formatDate(startDate) + ' to ' + formatDate(new Date()),
  ]);
  Row.getCell(1).style.font = {
    size: 11,
    bold: true,
  };
};

export const coverageReportDetails: iExcelSchema = {
  reportName: 'coverageReport', // name of the report in the tables export button that the user clicks on
  reportDescription: 'Coverage Report report', // explanantion of the report
  filteredData: false, // whether to use a tables filtered data or all data true=filtered
  fileName: 'alarm coverage report', // excel file name to create
  workSheetName: 'Coverage', // worksheet name
  title: 'Alarm Coverage Report', // Title on worksheet
  columns: reportColumns, // column information
  headerRows: coverageHeaderRows,
};

// rename this to the specific report name
export const generateCoverageReport = async (
  numberOfDays: number
): Promise<void> => {
  const today = dateTodaySetTimeHHMM('00:00');
  startDate = dateSubtractDays(today, numberOfDays);

  assembleData(startDate);
  await createExcel(
    {
      ...coverageReportDetails,
      title: `${numberOfDays} Day Alarm Coverage Report`,
    },
    data
  );
};
