/** @module Installation */

import { iCareSequence, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';
import { RoutingResource } from 'components/installation/routingResource';
import { GroupHandset } from './groupHandset';
import { EndpointCCSIP } from './endpoint_cc_sip';
import { EndpointCCDTMF } from './endpoint_cc_dtmf';
import { teDESTINATION_TYPES } from 'types/manifest-enums';
import { iDestinationListItem } from './careGroupUtils';

/*
 * A Care Group
 * @class
 * @hideconstructor
 * @implements iCareGroup
 */

export class CareSequence implements iCareSequence {
  CARE_SEQ_ENTRY_NOT_USED = 1;

  /** iCareSequence start */
  ID = invalidID;
  CareSequenceId = invalidID;
  Position = 0;
  Retries = 0;
  ResourceId = this.CARE_SEQ_ENTRY_NOT_USED;
  /** iCareSequence end */

  // Got software version incase we need to do processsing differently for
  // different software revsions in the future
  softwareVersion = 0;
  routingResources: RoutingResource[] = [];
  groupHandsets: GroupHandset[] = [];
  endpointCCDTMF: EndpointCCDTMF[] = [];
  endPointCCSIP: EndpointCCSIP[] = [];

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(
    values: iCareSequence,
    keys: string[],
    tableName: string,
    softwareVersion: number,
    routingResources: RoutingResource[],
    groupHandsets: GroupHandset[],
    endpointCCDTMF: EndpointCCDTMF[],
    endPointCCSIP: EndpointCCSIP[]
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
    this.routingResources = routingResources;
    this.groupHandsets = groupHandsets;
    this.endpointCCDTMF = endpointCCDTMF;
    this.endPointCCSIP = endPointCCSIP;

    // Fix for the unsigned storage in the SCU.
    // If Retries == -1 then this is the same as 255 when stored as a unsigned int in C
    // It doesn't matter to the SCU if the database contains -1 or 255 it is the same
    // Convert -1 to 255 so consistent
    // Note 255 if a special case which means repeat forever
    if (this.Retries === -1) {
      this.Retries = 255;
    }
  }

  // getDestinationDescription(): iDestinationListItem {
  //   return getDestinationDetails(
  //     this.routingResources[this.ResourceId],
  //     this.groupHandsets,
  //     this.endpointCCDTMF,
  //     this.endPointCCSIP
  //   );
  // }

  // TODO: replace this with the careGroupUtils functions
  getDestinationDescription(): iDestinationListItem {
    const dst = this.routingResources.find((f) => f.ID == this.ResourceId);
    // const dst = this.routingResources[this.ResourceId];
    let entryDescription = '--------';
    let typeName = '';
    if (!dst) console.log(this.routingResources, dst, this.ResourceId);
    if (dst) {
      const dstID = dst.DestinationId;

      switch (dst.DestinationType) {
        case teDESTINATION_TYPES.DESTINATION_TYPE_HANDSET:
          {
            const desc = this.groupHandsets.find(
              (e) => e.ID == dstID
            )?.Description;
            if (desc) {
              entryDescription = desc;
              typeName = 'Handset Group';
            }
          }
          break;

        case teDESTINATION_TYPES.DESTINATION_TYPE_CC_DTMF:
          {
            const desc = this.endpointCCDTMF.find(
              (e) => e.ID == dstID
            )?.Description;
            if (desc) {
              entryDescription = desc;
              typeName = 'Analog ARC';
            }
          }
          break;

        case teDESTINATION_TYPES.DESTINATION_TYPE_CC_IP_BS8521:
          {
            const desc = this.endPointCCSIP.find(
              (e) => e.ID == dstID
            )?.Description;
            if (desc) {
              entryDescription = desc;
              typeName = 'IP ARC';
            }
          }
          break;

        case teDESTINATION_TYPES.DESTINATION_TYPE_RESIDENT:
          break;
      }
    }
    return {
      ID: dst ? dst.ID.toString() : '-1',
      type: dst ? dst.DestinationType : 0,
      typeName: typeName,
      label: entryDescription,
      fullLabel: `(${typeName}) ${entryDescription}`,
    };
  }

  getDestinationType(): teDESTINATION_TYPES {
    return this.routingResources[this.ResourceId].DestinationType;
  }

  /*
   Total number of destination attemps = retries+1;
  */
  setNumberOfRetries(retries: number): void {
    if (retries <= 254) {
      this.Retries = retries;
    }
  }

  /**
   * Loop forever on this entry and don't move on
   */
  setRetryForEver(): void {
    this.Retries = 255;
  }

  clearDestination(): void {
    this.Retries = 0;
    this.ResourceId = this.CARE_SEQ_ENTRY_NOT_USED;
  }

  setDestination_HandsetGroup(dst: GroupHandset): boolean {
    return this.setDestination(
      teDESTINATION_TYPES.DESTINATION_TYPE_HANDSET,
      dst.ID
    );
  }

  setDestination_DTMF_ARC(dst: EndpointCCDTMF): boolean {
    return this.setDestination(
      teDESTINATION_TYPES.DESTINATION_TYPE_CC_DTMF,
      dst.ID
    );
  }

  setDestination_SIP_ARC(dst: EndpointCCSIP): boolean {
    return this.setDestination(
      teDESTINATION_TYPES.DESTINATION_TYPE_CC_DTMF,
      dst.ID
    );
  }

  setDestination(type: teDESTINATION_TYPES, ID: number): boolean {
    const status = false;
    const resource = this.routingResources.find(
      (e) => e.DestinationType == type && e.DestinationId == ID
    );

    if (resource) {
      this.ResourceId = resource.ID;
    }
    return status;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });

    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }
}
