import { minimumAccessLevel, UAG } from 'common/userUtils';

import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  isMobile,
  // isMobileOnly,
} from 'react-device-detect';

// determine whether we should be showing the mobile view
export const showMobile = (): boolean => {
  const show = isMobile && minimumAccessLevel(UAG.ELITE);
  return show;
};
