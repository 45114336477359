/** @module EquipmentTable */

import * as Site from 'components/floorplan/site';
import { fabric } from 'fabric';
/**
 * Handles the drawing of equipment tables on the plans
 */

const Equipgroup = new fabric.Group([], {
  hasControls: false,
  name: 'Equipment Table',
  hoverCursor: 'none',
  selectable: false,
});
const Itemgroup = new fabric.Group([], {
  hasControls: false,
  name: 'Items',
  hoverCursor: 'none',
  selectable: false,
});
const Tablegroup = new fabric.Group([], {
  hasControls: false,
  name: 'EquipTable',
  hoverCursor: 'pointer',
});

const columns = [4, 105, 320, 870];
const rows = [4, 55, 95];
const rowSpacing = 28;
const fontSize = [34, 28, 24];

/** Draw the initial equipment table*/
export const createEquipmentTable = (
  canvas: fabric.Canvas,
  plan: number
): void => {
  Equipgroup.remove(...Equipgroup.getObjects());
  Itemgroup.remove(...Itemgroup.getObjects());
  Tablegroup.removeWithUpdate(Equipgroup);
  Tablegroup.removeWithUpdate(Itemgroup);
  canvas.remove(Tablegroup);

  // The location of the equipment table
  const Equipx = Site.siteData.plans[plan].equipX;
  const Equipy = Site.siteData.plans[plan].equipY;

  //canvas.remove(TableGroup);
  const titletext = new fabric.Text(
    `Equipment Key For ${Site.siteData.plans[plan].planName}`,
    {
      left: Equipx + columns[0],
      top: Equipy + rows[0],
      fontFamily: 'Verdana',
      fontSize: fontSize[0],
      fill: 'purple',
    }
  );
  const headingtext1 = new fabric.Text('Key', {
    left: Equipx + columns[0],
    top: Equipy + rows[1],
    fontFamily: 'Verdana',
    fontSize: fontSize[1],
    fill: 'black',
  });
  const headingtext2 = new fabric.Text('Code', {
    left: Equipx + columns[1],
    top: Equipy + rows[1],
    fontFamily: 'Verdana',
    fontSize: fontSize[1],
    fill: 'black',
  });
  const headingtext3 = new fabric.Text('Description', {
    left: Equipx + columns[2],
    top: Equipy + rows[1],
    fontFamily: 'Verdana',
    fontSize: fontSize[1],
    fill: 'black',
  });
  const headingtext4 = new fabric.Text('Qty', {
    left: Equipx + columns[3],
    top: Equipy + rows[1],
    fontFamily: 'Verdana',
    fontSize: fontSize[1],
    fill: 'black',
  });
  const textGroup = new fabric.Group(
    [titletext, headingtext1, headingtext2, headingtext3, headingtext4],
    { name: 'headingGroup' }
  );
  Tablegroup.addWithUpdate(Itemgroup);
  Equipgroup.addWithUpdate(textGroup);
  Tablegroup.addWithUpdate(Equipgroup);

  canvas.add(Tablegroup);
  canvas.requestRenderAll();
};

/** Draw the equipment table*/
export const updateEquipmentTable = (
  canvas: fabric.Canvas,
  plan: number
): void => {
  const totals = Site.getEquipmentTotals(plan);
  Itemgroup.remove(...Itemgroup.getObjects());
  // The location of the equipment table
  const Equipx = Site.siteData.plans[plan].equipX;
  const Equipy = Site.siteData.plans[plan].equipY;

  // create normal rect
  const rect = new fabric.Rect({
    left: Equipx + columns[0] - 10,
    top: Equipy - 10,
    fill: 'white',
    width: columns[3] + 70,
    height: rows[2] + (totals.length + 1) * rowSpacing,
    borderColor: 'purple',
    stroke: 'purple',
    strokeWidth: 1,
    hasBorders: false,
  });

  Itemgroup.addWithUpdate(rect);
  rect.sendToBack();

  totals.forEach((t, index) => {
    const text1 = new fabric.Text(`${t.equip.designator}`, {
      left: Equipx + columns[0],
      top: Equipy + rows[2] + index * rowSpacing,
      fontFamily: 'Verdana',
      fontSize: fontSize[2],
      fill: 'black',
    });
    const text2 = new fabric.Text(`${t.equip.productCode}`, {
      left: Equipx + columns[1],
      top: Equipy + rows[2] + index * rowSpacing,
      fontFamily: 'Verdana',
      fontSize: fontSize[2],
      fill: 'black',
    });
    const text3 = new fabric.Text(`${t.equip.name}`, {
      left: Equipx + columns[2],
      top: Equipy + rows[2] + index * rowSpacing,
      fontFamily: 'Verdana',
      fontSize: fontSize[2],
      fill: 'black',
    });
    const text4 = new fabric.Text(`${t.total}`, {
      left: Equipx + columns[3],
      top: Equipy + rows[2] + index * rowSpacing,
      fontFamily: 'Verdana',
      fontSize: fontSize[2],
      fill: 'black',
    });
    const lineGroup = new fabric.Group([text1, text2, text3, text4], {
      name: `${t.equip.productCode}`,
    });

    Itemgroup.addWithUpdate(lineGroup);
  });
};
