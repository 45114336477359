import TablePaged, { dateFilter } from 'components/tables/TablePaged';

import React, { useEffect, useState } from 'react';

import Tile, { iTileEvent } from '../../tile';
import * as doorCalls from 'components/systemevents/doorEvents';
import { formatDateWithSeconds } from 'common/utils/dateUtils';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import { Column, Row, Cell } from 'react-table';
import _ from 'lodash';
import { Room } from 'components/installation/room';
import { Text } from 'grommet';
import RecentDoorCallsTable from './recentDoorCallsTable';

const RecentDoorCallsOverview = (props: any): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const [data, setData] = useState<doorCalls.iDoorCall[]>([]);

  const loadDataEvent = () => {
    // we take a deep copy so any changes are detected and re-render occurs
    setData(_.cloneDeep(doorCalls.getDoorCalls()));
    //console.log('DOORS', doorCalls.getDoorCalls());
  };

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.ALARMEVENTS,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
  ];

  return (
    <Tile
      title="Recent Door Calls"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      showLastEvent={false}
    >
      <RecentDoorCallsTable room={props.view?.room} data={data} />
    </Tile>
  );
};

export default RecentDoorCallsOverview;
