import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import * as dbAccess from './dbAccess';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as eventDispatcher from 'store/eventDispatcher';
import { createExtraReducers } from './commonReducers.js';
import { RootState } from 'store/store';
import { initialSliceState } from 'store/commonSlice';
import * as API from 'types/API';
import * as Amp from 'aws-amplify';
import _ from 'lodash';

const dbAccessInfo = {
  DAOname: 'Clients',
  allQuery: queries.listOmniviaClients,
  allSelector: 'listOmniviaClients',
  allSort: 'name',
  selectQuery: queries.getOmniviaClient,
  selectSelector: 'getOmniviaClient',
};

const adapter = createEntityAdapter({
  //selectId: (object) => object.id, // id is the default
  sortComparer: (a: API.OmniviaClient, b: API.OmniviaClient) =>
    a.name.localeCompare(b.name),
});

const initialState = adapter.getInitialState(initialSliceState);

// Thunk functions
export const fetch = createAsyncThunk('clients/fetch', async () => {
  const response = await dbAccess.loadData(dbAccessInfo, { limit: 2000 });
  return response;
});

export const select = createAsyncThunk(
  'clients/select',
  async (client: API.OmniviaClient) => {
    const response = await dbAccess.select(dbAccessInfo, { id: client.id });
    return response;
  }
);

export const update = createAsyncThunk(
  'clients/update',
  async (update: { id: string; changes: unknown }) => {
    const inputVars = _.cloneDeep(
      update.changes
    ) as API.UpdateOmniviaClientInput;
    _.set(inputVars, 'id', update.id);
    const variables = {
      input: inputVars,
    };

    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(mutations.updateOmniviaClient, variables)
    )) as { data: { updateOmniviaClient: API.OmniviaClient } };

    console.info('update:', response);
    return response.data.updateOmniviaClient;
  }
);

export const add = createAsyncThunk(
  'clients/add',
  async (add: { changes: unknown }) => {
    const variables = {
      input: add.changes,
    };

    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(mutations.createOmniviaClient, variables)
    )) as { data: { createOmniviaClient: API.OmniviaClient } };

    console.info('sites/add:', response);
    return response.data.createOmniviaClient;
  }
);

export const deleteRecord = createAsyncThunk(
  'clients/deleteRecord',
  async (deleteVars: { id: string }) => {
    const variables = {
      input: deleteVars,
    };
    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(mutations.deleteOmniviaClient, variables)
    )) as { data: { deleteOmniviaClient: API.OmniviaClient } };

    console.info('sites/delete:', response);
    return response.data.deleteOmniviaClient;
  }
);

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    // eslint-disable-next-line
    clearSelected(state, action) {
      state.selectedId = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(update.fulfilled, (state, action) => {
      const client = action.payload;
      adapter.setOne(state, action.payload);
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.CLIENT,
        eventDispatcher.systemEventStates.UPDATED,
        client,
        true
      );
    });

    builder.addCase(add.fulfilled, (state, action) => {
      const client = action.payload;
      adapter.addOne(state, action.payload);
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.CLIENT,
        eventDispatcher.systemEventStates.UPDATED,
        client,
        true
      );
    });

    builder.addCase(deleteRecord.fulfilled, (state, action) => {
      //const client = action.payload;
      adapter.removeOne(state, action.payload.id);
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.CLIENT,
        eventDispatcher.systemEventStates.UPDATED,
        undefined,
        true
      );
    });

    createExtraReducers(
      eventDispatcher.systemEventTopics.CLIENT,
      builder,
      fetch,
      select,
      adapter
    );
  },
});

export const { selectAll, selectById } = adapter.getSelectors(
  (state: RootState) => state.clients
);

export const { clearSelected } = slice.actions;
export default slice.reducer;

const getState = (state: RootState) => state;
export const getSelected = createSelector([getState], (state: RootState) => {
  return selectById(state, state.clients.selectedId);
});

export const getAll = createSelector([getState], (state: RootState) => {
  return selectAll(state);
});

export const getSlice = createSelector([getState], (state: RootState) => {
  return state.clients;
});
