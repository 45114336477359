export interface iSliceState {
  dataLoaded: boolean;
  loadStatus: string;
  selectStatus: string;
  selectedId: string;
}

export const initialSliceState: iSliceState = {
  dataLoaded: false,
  loadStatus: 'idle',
  selectStatus: 'idle',
  selectedId: '',
};
