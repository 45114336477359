import winston from 'winston';
import { Logtail } from '@logtail/node';
import { LogtailTransport } from '@logtail/winston';
import * as UserUtils from 'common/userUtils';
import store from 'store/store';
import * as sitesslice from 'store/sitesSlice';
import * as scuslice from 'store/scusSlice';
import { OmniviaSite, OmniviaSCU } from 'types/API';
import * as eventDispatcher from 'store/eventDispatcher';

const isDevelopment = process.env.REACT_APP_DEVELOPMENT === 'true';

// console.log(isDevelopment, process.env.REACT_APP_DEVELOPMENT);

// Create a Logtail client
const logtail = new Logtail('3rFgFxh6Hf9RttyCuQYFKRKr');

let username = '';
// let useremail = '';   // Rmeoved user email for privacy purposes
let userid = '';
let siteSelected: OmniviaSite | undefined = undefined;
let scuSelected: OmniviaSCU | undefined = undefined;
let accessLevel = '';

const updateUser = () => {
  userid = UserUtils.getCurrentCognitoUsername();
  // useremail = UserUtils.getCurrentUsersEmailAddress();
  username = UserUtils.getCurrentUsersName();
  accessLevel = UserUtils.getCurrentUserAccessLevel();
};

const updateSelectedSite = () => {
  siteSelected = sitesslice.getSelected(store.getState());
  scuSelected = scuslice.getSelected(store.getState());
};

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.USER,
  eventDispatcher.systemEventStates.AUTHORISED,
  () => updateUser()
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.USER,
  eventDispatcher.systemEventStates.NOTAUTHORISED,
  () => updateUser()
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.SITE,
  eventDispatcher.systemEventStates.SELECTED,
  () => updateSelectedSite()
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.SCU,
  eventDispatcher.systemEventStates.SELECTED,
  () => updateSelectedSite()
);

const cloudFormatter = winston.format((info) => {
  const { level, message } = info;
  info.level = `${level}`;
  info.message = message;
  info.service = 'Cloud';

  info.userid = userid;
  // info.useremail = useremail;
  info.username = username;
  info.accessLevel = accessLevel;

  if (siteSelected) {
    info.siteId = siteSelected.id;
    info.siteName = siteSelected.name;
  }

  if (scuSelected) {
    info.scuId = scuSelected.id;
    info.scuName = scuSelected.name;
  }
  return info;
})();

const consoleFormatter = winston.format((info) => {
  const { level, message } = info;
  info.level = `${level}`;
  info.message = message;

  return info;
})();

// const consoleFormatter = winston.format.printf(function (info) {
//   return `${
//     info.timestamp
//   }-${info.level}: ${JSON.stringify(info.message, null, 4)}\n`;
// });

// ... standard levels from NPM are, we override these with custom ones below
// { NPM default log levels
//   error: 0,
//   warn: 1,
//   info: 2,
//   http: 3,
//   verbose: 4,
//   debug: 5,
//   silly: 6
// }

const logger = winston.createLogger({
  // added dummy transport so we don't send anything to the log server
  // transports: [new transports.Console({ silent: true })],

  transports: [
    new winston.transports.Console({
      level: 'debug',
      silent: !isDevelopment, // silence the console logger is we are not in development
      format: winston.format.combine(
        winston.format.colorize(),
        winston.format.timestamp({
          format: 'HH:mm:ss',
        }),
        consoleFormatter
      ),
    }),

    new LogtailTransport(logtail, {
      level: 'info',
      format: winston.format.combine(
        winston.format.timestamp({
          format: 'YYYY-MM-DD HH:mm:ss',
        }),
        cloudFormatter,
        winston.format.prettyPrint()
      ),
    }),

    // new LokiTransport({
    //   host: GH,
    //   labels: { app: GA },
    //   level: 'info',
    //   silent: isDevelopment, // silence the loki logger whilst in development
    //   json: true,
    //   basicAuth: GU + ':' + GP,
    //   format: winston.format.combine(
    //     winston.format.timestamp({
    //       format: 'YYYY-MM-DD HH:mm:ss',
    //     }),
    //     lokiFormatter,
    //     winston.format.prettyPrint()
    //   ),
    //   replaceTimestamp: true,
    //   onConnectionError: (err) => console.error(err),
    // }),

    // new winston.transports.Console({}),
  ],
}) as winston.Logger;

export default logger;
