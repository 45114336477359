import { iExcelColumn, iExcelSchema } from 'common/excel/createExcel';
import { Manifest } from 'components/installation/manifest';
import { createExcel } from 'common/excel/createExcel';
import { headerRows } from 'common/excel/headerRows';
import { Worksheet } from 'exceljs';
import * as Site from 'components/floorplan/site';
import store from 'store/store';
import * as sitesslice from 'store/sitesSlice';
import * as UserUtils from 'common/userUtils';
import { cloneDeep } from 'lodash';

const manifest = new Manifest();

let extended = false;

let reportColumns: iExcelColumn[] = [];

const createColumns = () => {
  reportColumns = [
    {
      columnName: 'ID',
      columnWidth: 18,
      fieldName: 'ID',
      type: 'TEXT',
    },
    {
      columnName: 'Description',
      columnWidth: 28,
      fieldName: 'Description',
      type: 'TEXT',
    },
    {
      columnName: 'Event Code',
      columnWidth: 12,
      fieldName: 'ExternalId',
      type: 'TEXT',
    },
    {
      columnName: 'Status Code',
      columnWidth: 12,
      fieldName: 'StatusCode',
      type: 'TEXT',
    },
    {
      columnName: 'Status Code (Battery Low)',
      columnWidth: 12,
      fieldName: 'StatusCodeBatteryLow',
      type: 'TEXT',
    },
    {
      columnName: 'Priority',
      columnWidth: 12,
      fieldName: 'Priority',
      type: 'TEXT',
    },
  ];

  if (extended)
    reportColumns.push(
      {
        columnName: 'Technical Alarm',
        columnWidth: 12,
        fieldName: 'IsTechnical',
        type: 'TEXT',
      },
      {
        columnName: 'No Audio',
        columnWidth: 12,
        fieldName: 'NoneAudioAlarm',
        type: 'TEXT',
      },
      {
        columnName: 'Covert',
        columnWidth: 12,
        fieldName: 'Covert',
        type: 'TEXT',
      },
      {
        columnName: 'Disabled',
        columnWidth: 12,
        fieldName: 'AlarmDisabled',
        type: 'TEXT',
      },
      {
        columnName: 'EntryExit',
        columnWidth: 12,
        fieldName: 'EntryExit',
        type: 'TEXT',
      },
      {
        columnName: 'Intruder',
        columnWidth: 12,
        fieldName: 'Intruder',
        type: 'TEXT',
      },
      {
        columnName: 'Inactivity',
        columnWidth: 12,
        fieldName: 'Inactivity',
        type: 'TEXT',
      }
    );
};

export const alarmTypesHeaderRows = (sheet: Worksheet): void => {
  // call generic header first
  headerRows(sheet);
  if (extended) {
    const Row = sheet.addRow([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Default Settings When Assigning New Sensor',
    ]);
    Row.getCell(7).style.font = {
      size: 11,
      bold: true,
    };
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let data: any[] = [];

const assembleData = (): void => {
  const maxId = extended ? 500 : 100;
  const tmp = cloneDeep(manifest.alarmPriorities).filter(
    (f) => f.ExternalId < maxId
  );
  data = tmp;
};

// rename this to the specific report name
export const generateAlarmTypesReport = async (): Promise<void> => {
  extended = UserUtils.minimumAccessLevel(UserUtils.UAG.ELITE);
  createColumns();

  const alarmTypesReportDetails: iExcelSchema = {
    reportName: 'alarmTypesReport', // name of the report in the tables export button that the user clicks on
    reportDescription: 'Alarm Types, Priorties and Codes', // explanation of the report
    filteredData: false, // whether to use a tables filtered data or all data true=filtered
    fileName: 'Alarm Types and Priorities', // excel file name to create
    workSheetName: 'Alarm Types', // worksheet name
    title: 'Alarm Types, Priorities and Codes Report', // Title on worksheet
    columns: reportColumns, // column information
    headerRows: alarmTypesHeaderRows,
  };

  assembleData();
  await createExcel(alarmTypesReportDetails, data);
};
