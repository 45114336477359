import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import {
  Box,
  FormField,
  TextInput,
  Text,
  TextArea,
  Select,
  CheckBox,
  Tip,
  DateInput,
  CheckBoxGroup,
  Paragraph,
} from 'grommet';
import styled, { AnyStyledComponent } from 'styled-components';
import { get } from 'lodash';

const FormLabel = styled(Text as AnyStyledComponent)<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : '120px')};
  display: inline-block;
  text-align: Left;
`;

export interface iColumnLayout {
  labelWidth: string;
  columnPercentWidth: number;
}

export interface iField {
  label: string; // label for the form field
  id: string; // id - i.e. the data field
  tip?: string; // a tooltip to help the user
  // blank just fills the column space with nothing shown, plainstring just displays a string with no editing
  type:
    | 'blank'
    | 'plainstring'
    | 'text'
    | 'textArea'
    | 'checkbox'
    | 'checkboxGroup'
    | 'select'
    | 'date'
    | 'time';
  readOnly?: boolean;
  required?: boolean; // this field is required
  hide?: boolean; // whether to show on the form or not (can be used by the app to selectively toggle visibility)
  validate?: RegExp; // regex for validating a form field
  column?: number; // which column to place the field in, used on multiple column forms
  options?: iSelectKeyValue[]; // if select box then this the options list as a key value pair array
  formBoxWidth?: string; // the width of the input box
  button?: { icon: any; tip: string; onClick: (data: any[]) => void }; // optional button that can be shown alongside the form item with a callback when clicked
}

export interface iSelectKeyValue {
  key: unknown;
  value: string;
}

/**
 *
 * @param values convert a single array into a key value array
 * @returns
 */
export const convertSingleArrayToKeyValue = (
  values: string[]
): iSelectKeyValue[] => {
  return values.map((m) => {
    const i: iSelectKeyValue = { key: m, value: m };
    return i;
  });
};

export const AlignedFormField: React.FC<{
  id: string;
  type: string;
  tip?: string;
  readOnly?: boolean;
  formBoxWidth?: string;
  options?: iSelectKeyValue[];
}> = ({
  id,
  type = 'text',

  readOnly = false,
  formBoxWidth = 'medium',
  options,
}) => {
  // const disabled = type === "readonly" ? true : false;
  // const inputType = type === "readonly" ? "text" : type;

  const [searchOptions, setSearchOptions] = useState<
    iSelectKeyValue[] | undefined
  >(options);

  // check whether the options have dynamically changed, use case being another selection or input has altered the list
  useEffect(() => {
    setSearchOptions(options);
  }, [options]);

  if (type === 'select') {
    return (
      <Box width={formBoxWidth}>
        <Select
          name={id}
          type={type}
          size="medium"
          margin="none"
          disabled={readOnly}
          options={searchOptions ?? []}
          labelKey="value"
          valueKey={{ key: 'key', reduce: true }}
          onClose={() => setSearchOptions(options)}
          onSearch={(text) => {
            if (searchOptions === undefined) return;
            // The line below escapes regular expression special characters:
            // [ \ ^ $ . | ? * + ( )
            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

            // Create the regular expression with modified value which
            // handles escaping special characters. Without escaping special
            // characters, errors will appear in the console
            const exp = new RegExp(escapedText, 'i');
            setSearchOptions(
              searchOptions.filter((e) => {
                const value = get(e, 'value', undefined);
                if (value === undefined) return;
                return exp.test(value);
              })
            );
          }}
        />
      </Box>
    );
  } else if (type === 'checkbox') {
    return (
      <Box width={formBoxWidth}>
        <CheckBox name={id} type={type} disabled={readOnly} />
      </Box>
    );
  } else if (type === 'checkboxGroup') {
    return (
      <Box width={formBoxWidth}>
        <CheckBoxGroup
          name={id}
          gap="26px"
          options={options?.map((m) => m.value) ?? []}
          direction="row"
          disabled={readOnly}
        />
      </Box>
    );
  } else if (type === 'date') {
    return (
      <Box width={formBoxWidth}>
        <DateInput
          format="dd/mm/yyyy"
          size="14px"
          name={id}
          disabled={readOnly}
        />
      </Box>
    );
  } else if (type == 'textArea') {
    return (
      <Box width={formBoxWidth}>
        <TextArea name={id} size="medium" disabled={readOnly} rows={6} />
      </Box>
    );
  } else if (type !== 'blank' && type !== 'plainstring') {
    return (
      <Box width={formBoxWidth}>
        <TextInput
          name={id}
          size="medium"
          type={type}
          disabled={readOnly}
          style={{ padding: '2px' }}
        />
      </Box>
    );
  } else {
    return (
      <Box width={formBoxWidth}>
        <Text size="medium" weight="bold">
          {id}
        </Text>
      </Box>
    );
  }
};

const AlignedColumn = (displayfields: iField[], labelWidth?: string) => {
  return (
    <Box direction="column">
      {displayfields.map((item, index) => {
        return (
          <>
            {!item.hide && (
              <div key={`AFF${index}`}>
                {item.tip && (
                  <FormField
                    name={item.id}
                    margin={{ bottom: '2px' }}
                    validate={{
                      regexp: item.validate ?? /.*/,
                      message: 'incorrect format',
                      status: 'error',
                    }}
                    required={item.required}
                    direction="row"
                    label={
                      <Tip
                        dropProps={{
                          align: {
                            left: 'right',
                            top: 'bottom',
                          },
                        }}
                        content={<Paragraph size="small">{item.tip}</Paragraph>}
                        key={`TFF${item.id}`}
                      >
                        <FormLabel
                          width={labelWidth}
                          weight={
                            item.type == 'plainstring' ? 'bold' : 'normal'
                          }
                        >
                          {item.label}
                        </FormLabel>
                      </Tip>
                    }
                  >
                    <AlignedFormField
                      id={item.id}
                      type={item.type}
                      formBoxWidth={item.formBoxWidth}
                      options={item.options}
                      readOnly={item.readOnly}
                    />
                  </FormField>
                )}

                {!item.tip && (
                  <FormField
                    name={item.id}
                    direction="row"
                    margin={{ bottom: '2px' }}
                    validate={{
                      regexp: item.validate ?? /.*/,
                      message: 'incorrect format',
                      status: 'error',
                    }}
                    required={item.required}
                    label={
                      <FormLabel
                        margin="none"
                        width={labelWidth}
                        weight={item.type == 'plainstring' ? 'bold' : 'normal'}
                      >
                        {item.label}
                      </FormLabel>
                    }
                  >
                    <AlignedFormField
                      id={item.id}
                      type={item.type}
                      formBoxWidth={item.formBoxWidth}
                      options={item.options}
                      readOnly={item.readOnly}
                    />
                  </FormField>
                )}
              </div>
            )}
          </>
        );
      })}
    </Box>
  );
};

/**
 * AlignedFormFields
 * @param displayFields - the array of fields to display on the form
 * @param columnLayouts - specify a unique label width and column width for each column, if this is provdied then labelWidth and numberOfColumns are ignored
 * @param labelWidth - a single label width for all columns, only used if columnLayouts not provided
 * @param numberOfColumns - how many columns to display, only used if columnLayouts not provided, if neither provdied then one column is presumed
 * @returns
 */
export const AlignedFormFields: React.FC<{
  displayfields: iField[];
  columnLayouts?: iColumnLayout[];
  labelWidth?: string;
  numberOfColumns?: number;
  maxWidth?: string;
}> = ({
  displayfields,
  columnLayouts,
  labelWidth,
  numberOfColumns,
  maxWidth,
}) => {
  let cols = numberOfColumns ?? 1; // default to one column if not specified
  if (columnLayouts) cols = columnLayouts.length; // if we have column layouts then use that as the n umber of columns

  const EditFields = React.useMemo(() => {
    const content = [];
    for (let i = 0; i < cols; i++) {
      const colFields = displayfields.filter((f) => {
        if (numberOfColumns == undefined && columnLayouts == undefined)
          return true;
        return f.column == i + 1;
      });
      const width = columnLayouts
        ? `${columnLayouts[i].columnPercentWidth}%`
        : `${100 / cols}%`;
      const colLabelWidth = columnLayouts
        ? columnLayouts[i].labelWidth
        : labelWidth;
      content.push(
        <Box
          direction="column"
          pad={{ right: '8px', bottom: '0px', top: '0px' }}
          margin="none"
          width={width}
          key={`COL${i}`}
        >
          {AlignedColumn(colFields, colLabelWidth)}
        </Box>
      );
    }
    return content;
  }, [displayfields, labelWidth]);

  return (
    <Box
      direction="row"
      width={{ max: maxWidth ?? '100%' }}
      margin={{ top: '6px' }}
    >
      {EditFields}
    </Box>
  );
};
