import { teCLOUD_ERROR_CODES } from './IoTMessageDefines';

/*******teCLOUD_MSG_TY_DEVICES */

export enum teCLOUD_MSG_TY_DEVICES {
  E_CLOUD_MSG_DEVICES_SCU_PING_REQ = 0,
  E_CLOUD_MSG_DEVICES_SCU_PING_RESP,
  E_CLOUD_MSG_DEVICES_DEVICE_PING_REQ,
  E_CLOUD_MSG_DEVICES_DEVICE_PING_RESP,
  E_CLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_REQ,
  E_CLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_RESP,
  E_CLOUD_MSG_DEVICES_GET_LAST_SEEN_REQ,
  E_CLOUD_MSG_DEVICES_GET_LAST_SEEN_RESP,
  E_CLOUD_MSG_DEVICES_PAGE_REQ,
  E_CLOUD_MSG_DEVICES_PAGE_RESP,
  E_CLOUD_MSG_DEVICES_PAGE_DELIVERED,
  E_CLOUD_MSG_DEVICES_SET_PENDANT_PARAM_REQ,
  E_CLOUD_MSG_DEVICES_SET_PENDANT_PARAM_RESP,
  E_CLOUD_MSG_DEVICES_SET_PENDANT_PARAM_DELIVERED,
  E_CLOUD_MSG_DEVICES_DISCOVER_ALL_REQ,
  E_CLOUD_MSG_DEVICES_DISCOVER_DEVICE_REQ,
  E_CLOUD_MSG_DEVICES_GET_DISCOVER_STATUS_REQ,
  E_CLOUD_MSG_DEVICES_GET_DISCOVER_STATUS_RESP,
  E_CLOUD_MSG_DEVICES_COMISSION_REQ,
  E_CLOUD_MSG_DEVICES_COMISSION_RESP,
  E_CLOUD_MSG_DEVICES_BATTERY_LOG_NOT_USED,
  E_CLOUD_MSG_DEVICES_LOCATION_LOG_NOT_USED,
  E_CLOUD_MSG_DEVICES_LIVE_EVENT_LOG,
  E_CLOUD_MSG_DEVICES_OAD_QUERY_REQ,
  E_CLOUD_MSG_DEVICES_OAD_QUERY_RESP,
  E_CLOUD_MSG_DEVICES_OAD_STATUS_REQ,
  E_CLOUD_MSG_DEVICES_OAD_STATUS_RESP,
  E_CLOUD_MSG_DEVICES_OAD_CLEAR_REQ,
  E_CLOUD_MSG_DEVICES_OAD_CLEAR_RESP,
  E_CLOUD_MSG_DEVICES_OAD_PULL_UPDATE_REQ,
  E_CLOUD_MSG_DEVICES_OAD_PULL_UPDATE_RESP,
  E_CLOUD_MSG_DEVICES_OAD_DOWNLOAD_REQ,
  E_CLOUD_MSG_DEVICES_OAD_DOWNLOAD_RESP,
  E_CLOUD_MSG_DEVICES_OAD_ACTIVATE_REQ,
  E_CLOUD_MSG_DEVICES_OAD_ACTIVATE_RESP,
  E_CLOUD_MSG_DEVICES_ADD_REQ,
  E_CLOUD_MSG_DEVICES_ADD_RESP,
  E_CLOUD_MSG_DEVICES_DELETE_REQ,
  E_CLOUD_MSG_DEVICES_DELETE_RESP,
  E_CLOUD_MSG_DEVICES_ASSIGN_REQ,
  E_CLOUD_MSG_DEVICES_ASSIGN_RESP,
  E_CLOUD_MSG_DEVICES_MAX,
}

export enum tePROCESSOR {
  E_PROCESSOR_DATA = 0,
  E_PROCESSOR_AUDIO,
  E_PROCESSOR_APP,
}

export interface tsCLOUD_MSG_DEVICES_DEVICE_PING_REQ {
  mac: string;
  processor: tePROCESSOR;
}

export interface tsCLOUD_MSG_DEVICES_DEVICE_PING_RESP {
  mac: number;
  processor: tePROCESSOR;
  errorcode: teCLOUD_ERROR_CODES;
}

export interface tsCLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_REQ {
  mac: string;
  logonstatus: boolean;
}

export interface tsCLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_RESP {
  mac: number;
  errorcode: teCLOUD_ERROR_CODES;
  logonstatus: boolean;
}

export interface tsCLOUD_MSG_DEVICES_GET_LAST_SEEN_REQ {
  mac: string;
}

export interface tsLASTSEENDEVICEENTRY {
  mac: number;
  rssi: number;
}

export const MAX_LAST_SEEN_ENTRIES = 10;

export interface tsCLOUD_MSG_DEVICES_GET_LAST_SEEN_RESP {
  mac: number;
  lastseenentries: number;
  asLastSeen: tsLASTSEENDEVICEENTRY[];
  errorcode: number;
}

export interface tsCLOUD_MSG_DEVICES_PAGE_REQ {
  mac: string;
  wutmac: string;
  ledflashpattern: number;
  ledrepeats: number;
  vibrationpattern: number;
  vibrationrepeats: number;
  pagerepeats: number; // set to 4
  messageid: number;
}

export interface tsCLOUD_MSG_DEVICES_PAGE_RESP {
  mac: number;
  wutmac: number;
  errorcode: teCLOUD_ERROR_CODES;
}

export interface tsCLOUD_MSG_DEVICES_PAGE_DELIVERED {
  mac: number;
  wutmac: number;
  lastknownparent: number;
  networkuid: number;
  shortaddress: number;
  batteryvoltage: number;
  secondssincelastactivity: number;
  swversion: number;
  rxrssi: number;
  falsepagewakes: number;
  messageid: number;
}

export interface tsPENDANT_PARAMETER {
  paramvalue: number;
  paramnumber: number;
}

export interface tsCLOUD_MSG_DEVICES_SET_PENDANT_PARAM_REQ {
  parameters: tsPENDANT_PARAMETER[];
  mac: string;
  wutmac: string;
  ttl_ms: number; // how long it stays in the WUT buffer if not delivered
  pagerepeats: number; // set to 4
  numberofparameters: number; // max 4
  messageid: number;
}

export interface tsCLOUD_MSG_DEVICES_SET_PENDANT_PARAM_RESP {
  mac: number;
  wutmac: number;
  errorcode: teCLOUD_ERROR_CODES;
}

export interface tsCLOUD_MSG_DEVICES_SET_PENDANT_PARAM_DELIVERED {
  mac: number;
  wutmac: number;
  messageid: number;
}

//export interface tsCLOUD_MSG_DEVICES_DISCOVER_ALL_REQ {}

export interface tsCLOUD_MSG_DEVICES_DISCOVER_DEVICE_REQ {
  mac: string;
}

//export interface tsCLOUD_MSG_DEVICES_GET_DISCOVER_STATUS_REQ {}

export interface tsCLOUD_MSG_DEVICES_GET_DISCOVER_STATUS_RESP {
  errorcode: number;
}

export interface tsCLOUD_MSG_DEVICES_COMISSION_REQ {
  commissionlengthseconds: number;
  commissionrf: boolean;
  commissionip: boolean;
}

export interface tsCLOUD_MSG_DEVICES_COMISSION_RESP {
  errorcode: number;
}

export interface tsADDTIONRXLIST {
  parentMac: string;
  rssi_db: number;
}

export interface tsCLOUD_MSG_DEVICES_LIVE_EVENT_LOG {
  // device innformation
  mac: number;
  deviceType: number;
  deviceId: number;
  associatedAlarmId: number; // 0 if invalid

  // Battery Info
  batteryVoltageApplication: number;
  handset: null | {
    batteryCurrent: number;
    batteryTemperature: number;
    batteryVoltageNative: number;
    chargerProtectionStateOld: number;
    chargerProtectionStateNew: number;
    currentBatteryCapacity: number;

    cycleOnTime: number;
    cycleOffTime: number;
    isChargerEnabled: boolean;
    isCharging: boolean;
    isOvertemp: boolean;
    isScreenOn: boolean;
    maxChargeLvl: number;
  };
  // location info
  parentMac: number;
  rssi_db: number;
  addtionalReceiverList: tsADDTIONRXLIST[] | null;
}

export interface tsCLOUD_MSG_DEVICES_OAD_QUERY_REQ {
  mac: string; // set to 0 for all devices
}

export interface tsCLOUD_MSG_DEVICES_OAD_QUERY_RESP {
  errorcode: number;
}

export enum tePULLSTATE {
  E_OAD_PULL_STATE_IDLE = 0,
  E_OAD_PULL_STATE_DOWNLOADING,
  E_OAD_PULL_STATE_COMPLETE,
  E_OAD_PULL_STATE_DOWNLOAD_FAIL,
  E_OAD_PULL_STATE_CHECKSUM_FAIL,
  E_OAD_PULL_STATE_MAX,
}

export interface tsCLOUD_MSG_DEVICES_OAD_STATUS_RESP {
  deviceId: number;
  packetId: number;
  totalPackets: number;
  unitIndex: number;
  totalUnits: number;
  time: number; // In minutes
  state: number;
  targetType: number;
  imageId: number; // Index into the array of file descriptors (for console to get image name), 0-based
  imageCount: number; // Which image we're on (e.g. 1 of 2 with image IDs 2 and 5), 1-based
  totalImages: number; // Total number of images to be sent out
  pullState: tePULLSTATE; //Pull status
  upgradeVersion: string;
  downloadQueueSize: number;
}

export interface tsCLOUD_MSG_DEVICES_OAD_CLEAR_REQ {
  mac: string;
  processor: tePROCESSOR;
}

export interface tsCLOUD_MSG_DEVICES_OAD_CLEAR_RESP {
  errorcode: number;
}

export interface tsCLOUD_MSG_DEVICES_OAD_PULL_UPDATE_REQ {
  signed_url: string;
  version: string;
}

export interface tsCLOUD_MSG_DEVICES_OAD_PULL_UPDATE_RESP {
  errorcode: number;
}

export const OAD_ALL_TYPES = 0xff;
export const OAD_ALL_MODELS = 0xff;
export const OAD_ALL_DEVICES = 0;

export interface tsCLOUD_MSG_DEVICES_OAD_DOWNLOAD_REQ {
  start: boolean;
  deviceId: number;
  objectType: number;
  hwModel: number;
  interPacketInterval: number; // in milliseconds
}

export interface tsCLOUD_MSG_DEVICES_OAD_DOWNLOAD_RESP {
  errorcode: number;
}

export interface tsCLOUD_MSG_DEVICES_OAD_ACTIVATE_REQ {
  deviceId: number;
  activationTimeout: number; // in seconds
  allowRollBack: boolean;
}

export interface tsCLOUD_MSG_DEVICES_OAD_ACTIVATE_RESP {
  errorcode: number;
}

export interface tsCLOUD_MSG_DEVICES_ADD_REQ {
  mac: string;
  parentID: number; // device ID of a parent, only used for triggers
  unitId: number;
  type: number;
  hwmodel: number;
  gwInterface: boolean;
  name: string;
  location: number;
}

export interface tsCLOUD_MSG_DEVICES_ADD_RESP {
  errorcode: number;
  deviceId: number;
}

export interface tsCLOUD_MSG_DEVICES_DELETE_REQ {
  mac: string;
}

export interface tsCLOUD_MSG_DEVICES_DELETE_RESP {
  errorcode: number;
}

export interface tsCLOUD_MSG_DEVICES_ASSIGN_REQ {
  mac: string;
  unitId: number;
  name: string;
}

export interface tsCLOUD_MSG_DEVICES_ASSIGN_RESP {
  errorcode: number;
}
