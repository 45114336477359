import React, { useState, useEffect, WheelEvent } from 'react';
import useStateRef from 'react-usestateref';
import { Button, Box } from 'grommet';
import { useDrop } from 'react-dnd';

//import useFloorPlan from 'hooks/useFloorPlan';
import ProductToolbar from 'components/floorplan/productToolbar';
import { PlanModeEnum, Point } from 'types/plan-types';
import { Equipment, Location, INSTALLSTATUS } from 'types/product-types';
import { fabric } from 'fabric';

import { products } from 'data/product-definitions';
import * as CanvasSite from 'components/floorplan/canvasSite';
import { DNDtypes } from 'types/dndtypes';
import { Product } from 'components/floorplan/product';
import { Device } from 'components/installation/device';
import * as eventDispatcher from 'store/eventDispatcher';
import { iEventParams } from '../store/eventDispatcher';
import { iRoom } from 'types/manifest-types';
import styled from 'styled-components';
import { siteData } from 'components/floorplan/site';
import * as Site from 'components/floorplan/site';
import { HeadUpDisplay } from 'components/floorplan/HeadUpDisplay';
import { useWinstonLogger } from 'winston-react';
import { CablingDialogBox } from 'components/floorplan/cablingDialog';

// used for retaining the coords when a mouse down event occurs
let lastMouseDown: Point;
// true if we are currently panning the plan (alT+mouse)
let isDragging = false;

// the current canvas zoom
let canvasZoom = 1;

interface iDragItem {
  dragitem: unknown;
}

const VIEWPORT = styled.div``;

const DesignPlan = (): JSX.Element => {
  const logger = useWinstonLogger();
  /** The current editing mode of the plan*/
  const [planMode, setPlanMode] = useState<PlanModeEnum>(PlanModeEnum.VIEW);
  /** If in plan editing mode then the currently selected equipment type's product definition*/
  const [productDefinition, setProductDefinition] = useState<
    Equipment | undefined
  >();
  const [, /*canvasState*/ setCanvasState] = useState('');

  const [HUDequipIndex, setHUDequipIndex] = useState<Product | undefined>();

  /** current plan number being shown*/
  const [currentPlanNumber, setCurrentPlanNumber, currentPlanNumberRef] =
    useStateRef(-1);

  /** the current state of the HUD Headupdisplay box (i.e. details on the currently selected item ) */
  const [showHeadUpDisplay, setShowHeadUpDisplay] =
    React.useState<boolean>(false);
  /** the current state of the Cabling Dialog Box */
  const [showCableDialogBox, setShowCableDialogBox] =
    React.useState<boolean>(false);

  /** the site containging all plan data */
  //const [site, useSite] = useState<CanvasSite>(new CanvasSite());

  const [, /*{ isOver, canDrop }*/ drop] = useDrop(() => ({
    accept: [
      DNDtypes.ROOM,
      DNDtypes.DOOR,
      DNDtypes.LIFT,
      DNDtypes.SCU,
      DNDtypes.ACCESSPOINT,
    ],
    drop: (item: iDragItem, monitor) => {
      //logger.debug('dropped.');
      //logger.debug(item);

      //const sourceInitialPos = monitor.getInitialClientOffset();
      const dropPos = monitor.getClientOffset();
      const canvasdiv = document.getElementById('canvasdiv');
      const canvasOffset = canvasdiv?.getBoundingClientRect();

      //logger.debug(canvasZoom);
      //logger.debug(dropPos);
      //logger.debug(canvasOffset);
      const deviceItem: Device = item.dragitem as Device;
      const prod = Product.getProductfromManifestDeviceType(deviceItem);
      if (dropPos && canvasOffset) {
        const x = (dropPos.x - canvasOffset.x) / canvasZoom;
        const y = (dropPos.y - canvasOffset.y) / canvasZoom;
        const p: Point = { plan: currentPlanNumberRef.current, x: x, y: y };
        // if its a room unit we've dropped then send the room number for the plan designator
        if (monitor.getItemType() == DNDtypes.ROOM) {
          CanvasSite.addEquipment(
            p,
            prod,
            deviceItem,
            (item.dragitem as iRoom).RoomNumber
          );
        }
        // otherwise leave it to the siteplan to decide the desigantor
        else {
          CanvasSite.addEquipment(p, prod, deviceItem);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  //const isActive = isOver && canDrop;

  /** Set the plan editing or selecting mode */
  const updatePlanMode = (planmode: PlanModeEnum, designator?: string) => {
    console.log(planmode, designator);

    setPlanMode(planmode);
    if (designator) {
      if (designator != '') {
        const p: Equipment =
          products[products.findIndex((obj) => obj.designator == designator)];
        setProductDefinition(p);
      } else {
        setProductDefinition(undefined);
      }
    } else {
      setProductDefinition(undefined);
    }

    // hide the HUD on change
    CanvasSite.selectEquipment(undefined);
    setHUDequipIndex(undefined);
    setShowHeadUpDisplay(false);
  };

  /** Mouse has been pressed, relevant action dependent on editing mode */
  const mouseDown = (event: fabric.IEvent) => {
    console.log(event);

    if (CanvasSite.canvas) {
      lastMouseDown = CanvasSite.getEventPointer(event);
      lastMouseDown.plan = currentPlanNumberRef.current;

      //logger.debug(planMode);
      //logger.debug(lastMouseDown);

      // try and get the name of a selected object
      const name = event.target?.name;
      if (event.target) {
        event.target.lockMovementX = false;
        event.target.lockMovementY = false;
      }

      CanvasSite.selectEquipment(name);
      setHUDequipIndex(
        Site.siteData.equipment[CanvasSite.getCurrentEquipmentIndex()]
      );
      setShowHeadUpDisplay(name != undefined);

      //logger.debug(name);

      // Check for ALT key to signify panning
      // Disable ALT key dragging for now as it was buggy
      // //@ts-expect-error : TS issues between WheelEvent and IEvent, code works
      // const e: MouseEvent = event.e;
      // if (e.altKey === true) {
      //   isDragging = true;
      //   CanvasSite.canvas.selection = false; // prevent object selection
      // }
      // otherwise handle the mouse down
      // else {
      // How we react to the mouse down event depends on the current mode
      switch (planMode) {
        case PlanModeEnum.VIEW:
          if (event.target) {
            event.target.lockMovementX = true;
            event.target.lockMovementY = true;
          } else {
            CanvasSite.canvas.selection = false;
          }
          break;

        case PlanModeEnum.EDIT:
          if (!event.target) {
            CanvasSite.canvas.selection = false;
          }
          break;

        case PlanModeEnum.DESIGN:
          // Only place new item if we havn't clicked on a item that is already present.
          if (!event.target) {
            if (productDefinition) {
              CanvasSite.addEquipment(lastMouseDown, productDefinition);
            }
          }
          break;
      }
    }
  };

  /** Pan Canvas */
  const panCanvas = (deltaX: number, deltaY: number, render: boolean) => {
    if (CanvasSite.canvas) {
      const vpt = CanvasSite.canvas.viewportTransform as number[];
      vpt[4] += deltaX;
      vpt[5] += deltaY;
      if (render) CanvasSite.canvas.requestRenderAll();
    }
  };

  /** zoom the canvas */
  const zoomCanvas = (delta: number) => {
    if (CanvasSite.canvas) {
      let zoom = CanvasSite.canvas.getZoom();
      zoom *= 0.999 ** delta;

      if (zoom > 3) zoom = 3;
      if (zoom < 0.2) zoom = 0.2;

      CanvasSite.canvas.setZoom(zoom);
      canvasZoom = zoom;
    }
  };

  /** Mouse has been moved */
  const mouseMove = (event: fabric.IEvent) => {
    const minMoveSize = 1;
    const maxMoveSize = 50;

    if (CanvasSite.canvas) {
      const thisMouseDown = CanvasSite.getEventPointer(event);

      if (isDragging) {
        // The coordinates are based on the canvaszoom factor.
        // Normalise the x/y by zoom so the aamount we pan is also the same regardless of zoom level
        let deltaX = Math.floor(
          (thisMouseDown.x - lastMouseDown.x) * canvasZoom
        );
        let deltaY = Math.floor(
          (thisMouseDown.y - lastMouseDown.y) * canvasZoom
        );

        const absX = Math.abs(deltaX);
        const absY = Math.abs(deltaY);

        // To to jittering and big jumps put bounds on the delta
        if (absX < minMoveSize || absX > maxMoveSize) deltaX = 0;
        if (absY < minMoveSize || absY > maxMoveSize) deltaY = 0;

        lastMouseDown = CanvasSite.getEventPointer(event);
        if (deltaX != 0 || deltaY != 0) panCanvas(deltaX, deltaY, true);
      }
    }
  };

  /** Mouse has been released */
  const mouseUp = (event: fabric.IEvent) => {
    if (CanvasSite.canvas) {
      /*const thisMouseDown = */ CanvasSite.getEventPointer(event);
      isDragging = false;
      CanvasSite.canvas.selection = true;
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      CanvasSite.canvas.setViewportTransform(
        CanvasSite.canvas.viewportTransform as number[]
      );
    }
  };

  /** Mouse wheel Zoom action */
  const mouseWheel = (event: fabric.IEvent) => {
    // @ts-expect-error : TS issues between WheelEvent and IEvent, code works
    const e: WheelEvent = event.e;
    const delta = e.deltaY;

    if (CanvasSite.canvas) {
      zoomCanvas(delta);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  /** Mouse is hovering over an object */
  const mouseOver = (event: fabric.IEvent) => {
    if (CanvasSite.canvas && event) {
      // event.target?.set('borderColor', 'purple');
      // CanvasSite.canvas.renderAll();
    }
  };

  /** Mouse is no longer hovering over an object */
  // eslint-disable-next-line
  const mouseOut = (event: fabric.IEvent) => {
    if (CanvasSite.canvas) {
      // event.target?.set('borderColor', 'transparent');
      // CanvasSite.canvas.renderAll();
    }
  };

  /** Selection has been made */
  const handleSelection = (obj: unknown) => {
    console.log(obj);
  };

  /** Object has been modified */
  const handleObjectModified = (event: fabric.IEvent) => {
    console.log(event);
    if (CanvasSite.canvas) {
      const name = event.target?.name;
      if (name) {
        const new_p: Point = CanvasSite.getEventPointer(event);
        const x = new_p.x - lastMouseDown.x;
        const y = new_p.y - lastMouseDown.y;
        // Are we moving the equipment table?
        if (name == 'EquipTable') {
          siteData.plans[currentPlanNumberRef.current].equipX += x;
          siteData.plans[currentPlanNumberRef.current].equipY += y;
          Site.updateStore(true); // signal there's been a change that will need saving
        } // must be a piece of equipment
        else {
          CanvasSite.moveEquipment(x, y, name);
        }
      }
    }
  };

  /** Save a site plan */
  const storeSite = () => {
    CanvasSite.storeSite();
  };

  /** Save a site plan */
  const createPDF = () => {
    CanvasSite.createPDF();
  };

  /** Save a site plan */
  const exportPlanData = () => {
    CanvasSite.exportPlanData();
  };

  const switchPlan = (planNumber: number) => {
    if (planNumber < Site.siteData.plans.length) {
      CanvasSite.populatePlan(planNumber);
      setCurrentPlanNumber(planNumber);
      setHUDequipIndex(undefined);
      document.getElementById('canvasdiv')?.focus();
    }
  };

  /** Handle all key press events on the plan */
  function handleKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    // Treat key presses differently depending on if HUD Head Up display is open (OpenInfoBox)
    if (showHeadUpDisplay) {
      switch (e.code) {
        case 'Escape':
          setShowHeadUpDisplay(false);
      }
    }

    switch (e.code) {
      case 'Delete':
        if (planMode != PlanModeEnum.DESIGN) return;
        if (e.repeat == false) CanvasSite.removeEquipment();
        setShowHeadUpDisplay(false);
        setHUDequipIndex(undefined);
        break;
      case 'KeyV': // put into selection mode
        updatePlanMode(PlanModeEnum.VIEW, '');
        break;
      case 'KeyS': // CTRL+S = Save
        if (planMode == PlanModeEnum.VIEW) return;
        if (e.repeat == false) storeSite();
        break;
      case 'ArrowLeft': // Pan Left
      case 'Numpad4':
        panCanvas(20, 0, true);
        break;
      case 'ArrowRight': // Pan Right
      case 'Numpad6':
        panCanvas(-20, 0, true);
        break;
      case 'ArrowUp': // Pan Up
      case 'Numpad8':
        panCanvas(0, 20, true);
        break;
      case 'ArrowDown': // Pan down
      case 'Numpad2':
        panCanvas(0, -20, true);
        break;

      case 'PageUp': // ZoomIn
        zoomCanvas(-5);
        break;

      case 'PageDown': // ZoomIn
        zoomCanvas(5);
        break;
      case 'NumpadAdd': // Alter callout length
        if (planMode == PlanModeEnum.VIEW) return;
        CanvasSite.alterCalloutLength(5);
        break;
      case 'NumpadSubtract': // Alter callout length
        if (planMode == PlanModeEnum.VIEW) return;
        CanvasSite.alterCalloutLength(-5);
        break;
      case 'Digit0': // Zero or reset the canvas
      case 'Numpad0': // Zero or reset the canvas
        CanvasSite.resetCanvas();
        canvasZoom = 1;
        break;
      case 'Digit1':
        if (e.repeat == false) switchPlan(0);
        break;
      case 'Digit2':
        if (e.repeat == false) switchPlan(1);
        break;
      case 'Digit3':
        if (e.repeat == false) switchPlan(2);
        break;
      case 'Digit4':
        if (e.repeat == false) switchPlan(3);
        break;
      case 'Digit5':
        if (e.repeat == false) switchPlan(4);
        break;
      case 'KeyD': // Set install status of selected device to design
        if (planMode == PlanModeEnum.VIEW) return;
        CanvasSite.setinstallStatus(INSTALLSTATUS.DESIGN);
        break;
      case 'KeyF': // Set isntall status of selected device to fitted
        if (planMode == PlanModeEnum.VIEW) return;
        CanvasSite.setinstallStatus(INSTALLSTATUS.FITTED);
        break;
      case 'KeyC': // Set install status of selected device to commisioned
        if (planMode == PlanModeEnum.VIEW) return;
        CanvasSite.setinstallStatus(INSTALLSTATUS.COMMISSIONED);
        break;
      case 'KeyR': // Rotate clockwise by 90
        if (planMode == PlanModeEnum.VIEW) return;
        CanvasSite.rotateGroup(45);
        break;

      case 'KeyP': // Toggles if the selected equipment is public-common Area
        if (planMode != PlanModeEnum.DESIGN) return;
        CanvasSite.setCommon();
        break;
      case 'KeyN': // Go to next equipment
        CanvasSite.nextEquipment('forwards');
        setHUDequipIndex(
          Site.siteData.equipment[CanvasSite.getCurrentEquipmentIndex()]
        );
        break;
      case 'KeyB': // Go to previous equipment
        CanvasSite.nextEquipment('backwards');
        setHUDequipIndex(
          Site.siteData.equipment[CanvasSite.getCurrentEquipmentIndex()]
        );
        break;

      // Example of drawing directly onto context
      // case 'Digit1':
      //   if (canvas) {
      //     const ctx = canvas.getContext();
      //     ctx.save();
      //     ctx.beginPath();
      //     ctx.moveTo(222.3, 199.6);
      //     ctx.lineTo(0.5, 199.6);
      //     ctx.lineTo(0.5, 0.5);
      //     ctx.lineTo(222.3, 0.5);
      //     ctx.lineTo(222.3, 199.6);
      //     ctx.closePath();
      //     ctx.fillStyle = 'rgb(255, 254, 255)';
      //     ctx.fill();
      //     ctx.stroke();
      //     ctx.restore();
      //   }

      //   break;
    }
  }

  /** REspond to a select equipment event, usually emitted by a sidebar slection of a device etc */
  const zoomToDevice = (e: iEventParams) => {
    const macAddress = e.detail as number;
    if (Site.isOnDesignPlanMAC(macAddress) == undefined) {
      return;
    }
    /** find the location details */
    const loc: Location = CanvasSite.locateEquipment(macAddress);
    if (CanvasSite.canvas) {
      switchPlan(loc.plan);

      // first get the zoom, x, and y coordinates
      const objectLeft = loc.x;
      const objectTop = loc.y;
      let zoom = CanvasSite.canvas.getZoom();
      zoom = 2;
      canvasZoom = 2;
      // then calculate the offset based on canvas size
      const newLeft = -objectLeft * zoom + CanvasSite.canvas.getWidth() / 2;
      const newTop = -objectTop * zoom + CanvasSite.canvas.getHeight() / 2;
      // // update the canvas viewport
      CanvasSite.canvas.setViewportTransform([
        zoom,
        0,
        0,
        zoom,
        newLeft,
        newTop,
      ]);
    }
  };

  /** Load the site on creation of the canvas */
  useEffect(() => {
    console.log('canvas change');
  }, [CanvasSite.canvas]); // eslint-disable-line react-hooks/exhaustive-deps

  /** re-register the event handlers if planmode or product type change
   *  this ensures that we have current copies of the state variables
   */
  useEffect(() => {
    // remove all event listeners before adding again
    if (CanvasSite.canvas) {
      CanvasSite.canvas.off();
      CanvasSite.canvas.on('mouse:down', (obj) => mouseDown(obj));
      CanvasSite.canvas.on('object:modified', (obj) =>
        handleObjectModified(obj)
      );
      CanvasSite.canvas.on('mouse:move', (obj) => mouseMove(obj));
      CanvasSite.canvas.on('mouse:up', (obj) => mouseUp(obj));
      CanvasSite.canvas.on('mouse:wheel', (obj) => mouseWheel(obj));
      CanvasSite.canvas.on('mouse:over', (obj) => mouseOver(obj)); // not working
      CanvasSite.canvas.on('mouse:out', (obj) => mouseOut(obj)); // not working
      CanvasSite.canvas.on('selection:updated', (obj) => handleSelection(obj));
      CanvasSite.canvas.on('selection:created', (obj) => handleSelection(obj));
    }

    return () => {
      CanvasSite.canvas?.off('mouse:down', (obj) => mouseDown(obj));
      CanvasSite.canvas?.off('mouse:move', (obj) => mouseMove(obj));
      CanvasSite.canvas?.off('mouse:up', (obj) => mouseUp(obj));
      CanvasSite.canvas?.off('mouse:wheel', (obj) => mouseWheel(obj));
      CanvasSite.canvas?.off('mouse:over', (obj) => mouseOver(obj));
      CanvasSite.canvas?.off('mouse:out', (obj) => mouseOut(obj));
      CanvasSite.canvas?.off('selection:updated', (obj) =>
        handleSelection(obj)
      );
      CanvasSite.canvas?.off('selection:created', (obj) =>
        handleSelection(obj)
      );
      CanvasSite.canvas?.off('object:modified', (obj) =>
        handleObjectModified(obj)
      );
    };
  }, [planMode, productDefinition, CanvasSite.canvas]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    logger.info('DesignPlan.open');

    //const c: fabric.Canvas =
    CanvasSite.initCanvas();
    //c.preserveObjectStacking = true;
    setCanvasState(Date.now().toString());
    //site.canvas = c;
    CanvasSite.loadSite();
    updatePlanMode(PlanModeEnum.VIEW);
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.FLOORPLANS,
      eventDispatcher.systemEventStates.LOADED,
      () => {
        setCanvasState(Date.now().toString());
      }
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.DEVICE,
      eventDispatcher.systemEventStates.SELECTED,
      zoomToDevice
    );

    // UseEffect's cleanup function
    return () => {
      if (CanvasSite.canvas) {
        CanvasSite.canvas.dispose();
      }
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.FLOORPLANS,
        eventDispatcher.systemEventStates.LOADED,
        () => {
          setCanvasState(Date.now().toString());
        }
      );
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.DEVICE,
        eventDispatcher.systemEventStates.SELECTED,
        zoomToDevice
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        pad={{ left: '6px', top: '2px', bottom: '0px', right: '0px' }}
        margin={{ left: '0px', top: '0px', bottom: '0px', right: '0px' }}
        background="dark"
      >
        <ProductToolbar
          currentPlan={currentPlanNumber}
          planmode={planMode}
          updatePlanMode={updatePlanMode}
          productDefinition={productDefinition}
          savePlan={storeSite}
          exportPDF={createPDF}
          exportPlanData={exportPlanData}
          showCableDialogBox={setShowCableDialogBox}
        />
      </Box>

      <Box
        pad={{ left: '12px', top: '2px', bottom: '0px', right: '0px' }}
        margin={{ left: '0px', top: '0px', bottom: '0px', right: '0px' }}
        background="dark"
      >
        <Box
          pad={{ left: '2px', top: '0px', bottom: '0px', right: '0px' }}
          margin={{ left: '0px', top: '0px', bottom: '8px', right: '0px' }}
          align="start"
          direction="row"
        >
          {CanvasSite.getSiteData().plans.map((item, index) => (
            <Button
              color={index == currentPlanNumber ? 'gold' : 'brand'}
              size="medium"
              plain={false}
              key={index}
              margin={{ left: '4px', top: '0px', bottom: '0px', right: '0px' }}
              label={item.planName}
              onClick={() => {
                logger.info('DesignPlan.switchPlan', { plan: index });
                switchPlan(index);
              }}
            />
          ))}

          <Button
            size="medium"
            plain={false}
            label="Help"
            margin={{ left: '4px', top: '0px', bottom: '0px', right: '0px' }}
            onClick={() =>
              window.open('/help/designplan?topic=DesignShortcuts', '_blank')
            }
          />
        </Box>
        <HeadUpDisplay
          currentPlan={currentPlanNumber}
          visible={showHeadUpDisplay}
          equip={HUDequipIndex}
          planmode={planMode}
        >
          <></>
        </HeadUpDisplay>
        <div
          tabIndex={0}
          onKeyDown={(e) => {
            handleKeyPress(e);
          }}
          style={{ width: '100%', height: '100%' }}
        >
          <VIEWPORT ref={drop} id="canvasdiv">
            <canvas id="canvas" />
          </VIEWPORT>
        </div>
        <CablingDialogBox
          currentPlan={currentPlanNumber}
          visible={showCableDialogBox}
          setVisible={setShowCableDialogBox}
        />
      </Box>
    </>
  );
};

export default DesignPlan;
