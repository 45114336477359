import * as Amplify from 'aws-amplify';
import * as API from 'types/API';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getSignedUrl_get_or_put } from 'graphql/queries.ts';
import logger from 'common/logger';
import _ from 'lodash';

/**
 * @component useS3Bucket
 */

/** Get Signed URL */
export const getSignedURL = async (
  accesstype: 'getObject' | 'putObject',
  S3filename: string
  //accesstype,
  //S3filename
): Promise<string> => {
  // logger.info('S3.getSignedURL', {
  //   accesstype: accesstype,
  //   S3filename: S3filename,
  // });

  const resp = await Amplify.API.graphql(
    Amplify.graphqlOperation(getSignedUrl_get_or_put, {
      accessType: accesstype,
      filename: S3filename,
      expirePeriod: 60,
    })
  );

  const data = _.get(resp, 'data', undefined) as
    | API.GetSignedUrl_get_or_putQuery
    | undefined;

  const signedurl: string = data?.getSignedUrl_get_or_put
    ? data.getSignedUrl_get_or_put
    : '';

  logger.debug(signedurl);

  return signedurl;
};

/** getFile gets generic file from S3 */
const getFile = async (
  signedurl: string,
  type: XMLHttpRequestResponseType
): Promise<unknown> => {
  logger.debug('S3.getFile', {
    signedurl: signedurl,
    type: type,
  });

  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', signedurl, true);
    // we use the older method of fetching json as text and then parsing upon receipt, this
    // allows us to catch and debug any json errors
    // plus its more widely supported by browsers
    xhr.responseType = type == 'json' ? 'text' : type;
    // progress event listener used for debug only
    xhr.addEventListener('progress', () => {
      // console.log(event.loaded, event.total);
    });
    xhr.onload = function () {
      try {
        const status = xhr.status;
        if (status == 200) {
          const result =
            type == 'json' ? JSON.parse(xhr.response) : xhr.response;
          resolve(result);
        } else {
          reject(status);
        }
      } catch (err) {
        logger.error('S3.getFile.error', {
          signedurl: signedurl,
          type: type,
          error: err,
        });
      }
    };
    xhr.send();
  });
};

/** putFile sends generic file to S3 */
function putFile(
  signedurl: string,
  file: unknown,
  type: XMLHttpRequestResponseType
) {
  logger.debug('S3.putFile', {
    signedurl: signedurl,
    type: type,
  });

  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedurl, true);
    xhr.responseType = type;
    xhr.onload = function () {
      const status = xhr.status;
      if (status == 200) {
        resolve(xhr.response);
      } else {
        logger.error('S3.putFile.error', {
          signedurl: signedurl,
          type: type,
          error: status,
        });

        reject(status);
      }
    };
    xhr.send(file as Document);
  });
}

/** BLOB to image */
const blobToImage = (blob: Blob) => {
  return new Promise((resolve) => {
    resolve(URL.createObjectURL(blob));
  });
};

/** getJSON file from S3 */
const getJSON = async (url: string): Promise<unknown> => {
  // logger.info('S3.getJSON', {
  //   url: url,
  // });
  const signedurl = await getSignedURL('getObject', url);
  const document = await getFile(signedurl, 'json');
  return document;
};

/** putJSON file from S3 */
const putJSON = async (url: string, file: unknown): Promise<unknown> => {
  logger.info('S3.putJSON', {
    url: url,
  });
  const signedurl = await getSignedURL('putObject', url);
  const document = await putFile(signedurl, file, 'json');
  return document;
};

/** getImage file from S3 */
const getImage = async (url: string): Promise<unknown> => {
  logger.debug('S3.getImage', {
    url: url,
  });
  const signedurl = await getSignedURL('getObject', url);
  const document: Blob = <Blob>await getFile(signedurl, 'blob');
  const imgurl = await blobToImage(document);
  return imgurl;
};

export { getJSON, getImage, putJSON, getFile };
