import React, { useEffect, useState } from 'react';
import { Text, Menu, Box } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import styled from 'styled-components';
import { formatDateWithSeconds } from 'common/utils/dateUtils';
import { iEventParams } from '../../store/eventDispatcher';
import WaitDialogue, * as Wait from 'components/dialogues/waitDialogue';
import * as Icons from 'grommet-icons';
import * as UserUtils from 'common/userUtils';
import WidgetLoading from './desktopWidgets/widgetLoading';

export interface iTileEvent {
  /** The topic we are interested in */
  topic: eventDispatcher.systemEventTopics;
  /** The state we are interested in */
  state: eventDispatcher.systemEventStates;
  /** Our callabck function to be called */
  callback: (e: eventDispatcher.iEventParams) => void;
  /** If true then run the callback on initial startup */
  executeOnStartup: boolean;
}

export interface iMenuItemProps {
  label: string;
  icon: React.ReactElement;
  onClick: () => void;
  disabled: boolean;
  accessLevel?: UserUtils.UAG;
}

export interface iMenuProps {
  disabled: boolean;
  items: iMenuItemProps[];
}

export type TileProps = {
  /**Usual children node*/
  children: React.ReactElement;
  /**Title of the tile*/
  title: string;
  /**Array of any event listeners used by the tile*/
  eventListeners?: iTileEvent[];
  /** new event callback - to be used to get the child to re-render */
  setChildEvent: (event: string) => void;
  waitDiaglogueProps?: Wait.DialogueProps;
  menuProps?: iMenuProps;
  ContextButtons?: React.ReactElement;
  showLastEvent?: boolean;
  showSpinner?: boolean;
  spinnerText?: string;
};

/*
const TileDiv = styled.div`
  height: '98%';
  width: '100%';
  overflow-x: hidden;
  overflow-y: auto;
`;
*/

const Overlay = styled.div`
  position: absolute;
  top: 0px;
  height: 30px;
  margin-left: 10px;
  margin-top: 0px;
  display: 'flex';
`;

export const contextButtonStyle = {
  padding: 2,
  width: 46,
  height: 46,
  margin: 2,
  fontSize: 12,
};

const Tile = (props: TileProps): JSX.Element => {
  const [lastEvent, setLastEvent] = useState('');
  let showLastEvent = false;

  if (
    props.showLastEvent !== undefined &&
    UserUtils.minimumAccessLevel(UserUtils.UAG.ADMIN)
  ) {
    showLastEvent = props.showLastEvent;
  }

  const newEvent = (event: iEventParams) => {
    const ts = formatDateWithSeconds(event.timestamp);
    setLastEvent(ts.split(',')[1]);
    props.setChildEvent(ts);
  };

  useEffect(() => {
    // Register nan events
    props.eventListeners?.forEach((e) => {
      eventDispatcher.registerForEvent(e.topic, e.state, e.callback);
      if (e.executeOnStartup) {
        e.callback({
          subject: '',
          detail: '',
          timestamp: new Date().toISOString(),
        });
        const ts = formatDateWithSeconds(new Date());
        setLastEvent(ts.split(',')[1]);
        props.setChildEvent(ts);
      }
      // mirror the event into ourself so we can update the last event timesatmp on the tile
      eventDispatcher.registerForEvent(e.topic, e.state, newEvent);
    });

    return () => {
      // Remove registrations on exit
      props.eventListeners?.forEach((e) => {
        eventDispatcher.unRegisterForEvent(e.topic, e.state, e.callback);
        eventDispatcher.unRegisterForEvent(e.topic, e.state, newEvent);
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuItem = (item: iMenuItemProps) => {
    return {
      label: (
        <Box alignSelf="center">
          <Text size="medium">{item.label}</Text>
        </Box>
      ),
      icon: <Box pad="xsmall">{item.icon}</Box>,
      onClick: item.onClick,
      color: item.disabled ? 'grey-4' : '#bbbbbb',
      disabled: item.disabled,
    };
  };

  const menuItems = (items: iMenuItemProps[]) => {
    const filteredItems = items.filter((item) => {
      if (item.accessLevel && !UserUtils.minimumAccessLevel(item.accessLevel)) {
        return false;
      }
      return true;
    });
    return filteredItems.map((item) => menuItem(item));
  };

  // TODO FIX:
  //  when using the <TileDiv> instead of <div style={{ height: '98%', width: '100%' }}>
  //  pieChart.tsx fails to display.
  return (
    <div style={{ height: '98%', width: '100%' }}>
      <Box width="100%" background="brand" pad="1px">
        <Text size="large" color="white" weight="bold">
          {props.title}
        </Text>
      </Box>

      <WidgetLoading
        show={props.showSpinner ?? false}
        content={props.spinnerText}
      />

      {!props.showSpinner && (
        <Box
          direction="row"
          justify="start"
          gap="none"
          pad={{ right: 'medium', top: 'none', bottom: 'small' }}
          align="centre"
        >
          {props.menuProps && (
            <Menu
              label=""
              icon={<Icons.Menu />}
              disabled={props.menuProps.disabled}
              items={menuItems(props.menuProps.items)}
            />
          )}
          <Box margin="6px">{props.ContextButtons}</Box>
        </Box>
      )}

      {props.eventListeners &&
        props.eventListeners.length > 0 &&
        showLastEvent === true && (
          <Overlay>
            <Text size="small">Last event: {lastEvent}</Text>
          </Overlay>
        )}
      {props.waitDiaglogueProps && (
        <WaitDialogue {...props.waitDiaglogueProps} />
      )}

      {!props.showSpinner && props.children}
    </div>
  );
};

export default Tile;
