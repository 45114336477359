/** @module SystemFaults */
import store from 'store/store';
import * as API from 'types/API';
import * as eventDispatcher from 'store/eventDispatcher';
import * as faultsSlice from 'store/scuFaultsSlice';
import * as UserUtils from 'common/userUtils';

/**
 * All System Faults
 */

export let allFaults: API.OmniviaFaults[] = [];

const faultStateTextMapping = [
  { value: API.OmniviaFaultState.FAULT_ACTIVE, text: 'Active' },
  { value: API.OmniviaFaultState.FAULT_CLEARED, text: 'Cleared' },
  {
    value: API.OmniviaFaultState.FAULT_FORCE_CLEARED_USER,
    text: 'Force Cleared',
  },
];

export enum OmniviaFaultsTypes {
  ETHERNET_FAIL = 'ETHERNET_FAIL',
  DEVICE_POLL_FAIL = 'DEVICE_POLL_FAIL',
  PRIMARY_TELEPHONE_LINE_FAIL = 'PRIMARY_TELEPHONE_LINE_FAIL',
  SECONDARY_TELEPHONE_LINE_FAIL = 'SECONDARY_TELEPHONE_LINE_FAIL',
  MAINS_FAIL = 'MAINS_FAIL',
  PSU_BATTERY_LOW = 'PSU_BATTERY_LOW',
  DEVICE_BATTERY_LOW = 'DEVICE_BATTERY_LOW',
  AUDIO_ROUTE_FAIL = 'AUDIO_ROUTE_FAIL',
}

const faultTextMapping = [
  { value: API.OmniviaFaultsTypes.ETHERNET_FAIL, text: 'Ethernet link fail' },
  { value: API.OmniviaFaultsTypes.DEVICE_POLL_FAIL, text: 'Poll fail' },
  {
    value: API.OmniviaFaultsTypes.PRIMARY_TELEPHONE_LINE_FAIL,
    text: 'Primary telephone line fail',
  },
  {
    value: API.OmniviaFaultsTypes.SECONDARY_TELEPHONE_LINE_FAIL,
    text: 'Secondary telephone line fail',
  },
  { value: API.OmniviaFaultsTypes.MAINS_FAIL, text: 'Main power fail' },
  { value: API.OmniviaFaultsTypes.PSU_BATTERY_LOW, text: 'PSU battery low' },
  {
    value: API.OmniviaFaultsTypes.DEVICE_BATTERY_LOW,
    text: 'Device battery low',
  },
  { value: API.OmniviaFaultsTypes.AUDIO_ROUTE_FAIL, text: 'Audio route fail' },
];

/*
  This function is called from the DataManagerProvider whenever the redux faults slice is updated.
  There is a lambda function listenign to events, it then uses this to maintin the state of faults
  */
export const faultsLoad = (): void => {
  allFaults = faultsSlice.getAll(store.getState()) as API.OmniviaFaults[];

  // until we understand the Audio route fails, only show them to Admin
  if (UserUtils.getCurrentUserAccessLevel() != UserUtils.UAG.ADMIN) {
    allFaults = allFaults.filter(
      (e) => e.faultType != API.OmniviaFaultsTypes.AUDIO_ROUTE_FAIL
    );
  }
  allFaults = allFaults.sort(function (a, b) {
    return (
      new Date(a.dateRaised ? a.dateRaised : new Date()).getDate() -
      new Date(b.dateRaised ? b.dateRaised : new Date()).getDate()
    );
  });

  eventDispatcher.emitEvent(
    eventDispatcher.systemEventTopics.FAULTS,
    eventDispatcher.systemEventStates.PROCESSED,
    null,
    true
  );
};

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.FAULTS,
  eventDispatcher.systemEventStates.LOADED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    faultsLoad();
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.FAULTS,
  eventDispatcher.systemEventStates.UPDATED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    faultsLoad();
  }
);

export const getStateText = (state: API.OmniviaFaultState): string => {
  let text = 'unknown';

  const stateValue = faultStateTextMapping.find((e) => e.value === state);
  if (stateValue) {
    text = stateValue.text;
  }

  return text;
};

export const getFaultText = (fault: API.OmniviaFaultsTypes): string => {
  let text = 'unknown';

  const stateValue = faultTextMapping.find((e) => e.value === fault);
  if (stateValue) {
    text = stateValue.text;
  }

  return text;
};

export const clearfaultsfordevice = async (deviceId: number): Promise<void> => {
  for (const fault of allFaults) {
    if (
      parseInt(fault.deviceID) == deviceId &&
      fault.faultState === API.OmniviaFaultState.FAULT_ACTIVE
    ) {
      console.info('clear fault:', fault);
      const faultChanges = {
        id: fault.id,
        // Need to provide the deviceID as this is used as part of the unique key
        deviceID: fault.deviceID,
        // Need to provide the faultType as this is used as part of the unique key
        faultType: fault.faultType,
        dateCleared: new Date().toISOString(),
        faultState: API.OmniviaFaultState.FAULT_FORCE_CLEARED_USER,
        clearedBy: UserUtils.getCurrentUsersName(),
      };

      console.info('clear fault data:', faultChanges);
      await store.dispatch(faultsSlice.update(faultChanges));
      console.info('clear done');
    }
  }
};

/**
clearedBy: null
createdAt: "2022-01-05T19:39:36.812Z"
dateAlertLastGenerated: null
dateCleared: null
dateRaised: "2022-01-05T19:39:31.000Z"
deviceID: "6"
expirationUnixTime: null
faultSpecificText: "172.16.10.7"
faultState: "FAULT_ACTIVE"
faultType: "ETHERNET_FAIL"
friendlyString: "Ethernet fail for device ID:6 IP Address:172.16.10.7"
id: "9119a7e8-4dc9-451e-b462-2d3dc958c9bf"
updatedAt: "2022-01-05T19:39:36.812Z"
 */
