import React from 'react';
import { UAG } from 'common/userUtils';
import Page1 from '../mobileWidgets/page1';
import Page2 from '../mobileWidgets/page2';
import { WIDGETCAT, iWidgetEntry } from './widgetTypes';

export const mobileWidgetLibrary: Array<iWidgetEntry> = [
  {
    name: 'Page 1',
    description: 'Blank Screen',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <Page1 />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
  {
    name: 'Page 2',
    description: 'Blank Screen',
    category: WIDGETCAT.ALARMS,
    widget: [
      {
        minimumSCUSoftwareVersion: '0.0.0',
        widget: <Page2 />,
      },
    ],
    accessLevel: UAG.BASIC,
  },
];
