import React, { useMemo, useState } from 'react';
import useStateRef from 'react-usestateref';
import Tile, {
  iTileEvent,
  iMenuProps,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Button, Text } from 'grommet';

import { Manifest, teErrorCode } from 'components/installation/manifest';
import * as eventEnums from 'types/event-enums';
import * as Device from 'components/installation/device';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import TablePaged from 'components/tables/TablePaged';
import { Column, Row, Cell } from 'react-table';
import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
import * as AlarmEvents from 'components/systemevents/alarmEvents';
import SelectCell from 'components/tables/cellPresentationComponents/selectCell';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
import { useWinstonLogger } from 'winston-react';
import { ALARMS_TYPE_DESCRIPTIONS } from 'common/alarmTypesTextFiltered/alarmText';
import {
  alarm_types_value_text,
  getAlarmDescription,
} from 'common/alarmTypesTextFiltered/alarmTypeUtils';
import { ALARM_TYPES_ACCESSPOINT_SCU } from '../../../common/alarmTypesTextFiltered/alarmTypesSCU_HWIP';

const manifest = new Manifest();

interface iDebounce {
  minutes: number;
  seconds: number;
  milli: number;
}
const inputParams = [
  {
    alarmType: 'SCU_INPUT_1_ALARM_TYPE',
    inputPolarity: 'SCU_INPUT_1_SENSE',
    debounce: 'SCU_INPUT_1_DEBOUNCE',
  },
  {
    alarmType: 'SCU_INPUT_2_ALARM_TYPE',
    inputPolarity: 'SCU_INPUT_2_SENSE',
    debounce: 'SCU_INPUT_2_DEBOUNCE',
  },
  {
    alarmType: 'SCU_INPUT_3_ALARM_TYPE',
    inputPolarity: 'SCU_INPUT_3_SENSE',
    debounce: 'SCU_INPUT_3_DEBOUNCE',
  },
];

interface iTableData {
  ID: number;
  alarmType: eventEnums.ALARM_TYPES;
  inputPolarity: number;
  debounce_minutes: number;
  debounce_seconds: number;
  debounce_milliseconds: number;
}

const blankTableEntry: iTableData = {
  ID: 0,
  alarmType: eventEnums.ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT,
  inputPolarity: 0,
  debounce_minutes: 0,
  debounce_seconds: 0,
  debounce_milliseconds: 0,
};

let _allData: iTableData[] = [];
let _originalData: iTableData[] = [];

const SCUInputConfig = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useStateRef<number[]>([]);

  const convertDebounceToMs = (
    minutes: number,
    seconds: number,
    milliseconds: number
  ): number => {
    let totalMS = 0;
    totalMS += minutes * 60000;
    totalMS += seconds * 1000;
    totalMS += milliseconds;

    console.info('minutes:', minutes);
    console.info('seconds:', seconds);
    console.info('milliseconds:', milliseconds);

    console.info('total milliseconds:', totalMS);

    return totalMS;
  };

  const convertDebounceFromMs = (milliseconds: number): iDebounce => {
    let seconds = Math.floor(milliseconds / 1000);
    let mins = Math.floor(seconds / 60);

    milliseconds = milliseconds % 1000;
    seconds = seconds % 60;
    mins = mins % 60;

    return { minutes: mins, seconds: seconds, milli: milliseconds };
  };

  const getInputConfig = (input: number): iTableData => {
    const sp_alarmType = _.cloneDeep(
      manifest.getSystemParameter(inputParams[input].alarmType)
    );
    const sp_pol = _.cloneDeep(
      manifest.getSystemParameter(inputParams[input].inputPolarity)
    );
    const sp_debounce = _.cloneDeep(
      manifest.getSystemParameter(inputParams[input].debounce)
    );

    if (!sp_alarmType || !sp_pol || !sp_debounce) return blankTableEntry;

    // SCU set normally closed as 0 , normally open as 1
    const sense = parseInt(sp_pol.Value, 10) == 0 ? 1 : 0;

    const time: iDebounce = convertDebounceFromMs(
      parseInt(sp_debounce.Value, 10)
    );

    const data: iTableData = {
      ID: input,
      alarmType: parseInt(sp_alarmType.Value, 10),
      inputPolarity: sense,
      debounce_minutes: time.minutes,
      debounce_seconds: time.seconds,
      debounce_milliseconds: time.milli,
    };
    return data;
  };

  const loadData = () => {
    _allData = [getInputConfig(0), getInputConfig(1), getInputConfig(2)];
    _originalData = _.cloneDeep(_allData);
    setChanges([]);
    setRenderCount((old) => old + 1);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
  ];

  function getPolarityText(pol: Device.teHARDWIRED_INPUT_POLARITY): string {
    let text = '';

    const index = eventEnums.INPUT_POLARITIES.findIndex((object) => {
      return object.value === pol;
    });

    if (index != -1) {
      text = eventEnums.INPUT_POLARITIES[index].text;
    }

    return text;
  }

  /**
   * @param id    input number
   * @param property field to change
   * @param value new value
   */
  const setProperty = (id: number, property: string, value: unknown) => {
    const record = _allData.find((e) => e.ID == id);
    if (record) {
      const originalRecord = _originalData.find((e) => e.ID == id);

      const setIt = () => {
        if (!changesRef.current.includes(id)) {
          changesRef.current.push(id);
          setRenderCount((old) => old + 1);
        }
      };

      const value_number: number =
        value === '' ? 0 : parseInt(value as string, 10);
      _.set(record, property, value_number);
      setRenderCount((old) => old + 1);

      if (originalRecord) {
        if (_.get(originalRecord, property, 0) != value_number) {
          setIt();
        } else {
          _.pull(changesRef.current, id);
          setRenderCount((old) => old + 1);
        }
      }
    }
  };

  function getProperty(id: number, property: string): unknown {
    let input = _allData.find((e) => e.ID == id);
    if (input === undefined) {
      input = blankTableEntry;
    }
    const value = _.get(input, property, 0);
    return value;
  }

  const validateDebounce_minutes_seconds = (
    id: number,
    newValue: unknown
  ): unknown | undefined => {
    // max 1 hours
    if ((newValue as number) > 59) {
      return 59;
    }

    if ((newValue as number) < 0) {
      return 0;
    }

    return undefined;
  };

  const validateDebounce_milli = (
    id: number,
    newValue: unknown
  ): unknown | undefined => {
    // max 1 hours
    if ((newValue as number) > 999) {
      return 999;
    }

    if ((newValue as number) < 0) {
      return 0;
    }

    return undefined;
  };

  const alarmTypesList: eventEnums.iAlarmTypeDescription[] = useMemo(() => {
    return alarm_types_value_text(ALARM_TYPES_ACCESSPOINT_SCU);
    // return ALARMS_TYPE_DESCRIPTIONS;
  }, []);

  /**
   * The aim of all the cell components is that they do not render unnecessarily
   * All the cell components use React.memo to memorise the component.
   *
   * React.memo will only re-generate the compoent if a prop changes therefore we pass a reRenderValue prop
   * to the component to allow us to signal to React.memo that it needs to be re-rendered.
   *
   * The components use callback to set/get their data and not state varibles
   */
  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        disableFilters: true,
        id: 'ID',
        Header: 'Input',
        accessor: (row) => row.ID + 1,
        width: '60px',
      },
      {
        disableFilters: true,
        id: 'alarmType',
        Header: 'Alarm Type',
        accessor: (row) => row.alarmType,
        Cell: (props: Cell<iTableData>) => (
          <SelectCell
            id={props.row.original.ID}
            reRenderValue={props.row.original.alarmType}
            valueProperty={`alarmType`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={alarmTypesList}
            // options={ALARMS_TYPE_DESCRIPTIONS}
            alwaysDisplayDropDown={true}
            searchBox={true}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(row.original.ID, `alarmType`) as number;
          return getAlarmDescription(value);
        },
      },

      {
        disableFilters: true,
        id: 'alarmTypew',
        Header: 'Input Wiring',
        headerToolTip:
          'Set if the input is wired as\r\nnormally open or normally closed',
        accessor: (row: iTableData) => row.inputPolarity,

        Cell: (props: Cell<iTableData>) => (
          <SelectCell
            id={props.row.original.ID}
            reRenderValue={props.row.original.inputPolarity}
            valueProperty={`inputPolarity`}
            getProperty={getProperty}
            setProperty={setProperty}
            options={eventEnums.INPUT_POLARITIES}
          />
        ),
        getCellExportValue: (row: Row<iTableData>) => {
          const value = getProperty(row.original.ID, `inputPolarity`) as number;

          return getPolarityText(value);
        },
      },

      {
        disableFilters: true,
        id: 'debounce_minutes',
        Header: 'Activation Time (minutes)',
        accessor: 'debounce_minutes',
        Cell: (props: Cell<iTableData>) => (
          <InputCell
            id={props.row.original.ID}
            reRenderValue={props.row.original.debounce_minutes}
            valueProperty={`debounce_minutes`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="number"
            step="1"
            min={0}
            max={59}
            validator={validateDebounce_minutes_seconds}
            toolTip={
              <Text style={{ whiteSpace: 'pre-wrap' }}>
                {`The number of minutes the input must be continually asserted before an alarm is raised.
Minimum:0 Maximum:59.
Total activation time = minutes field + seconds field + milliseconds field.`}
              </Text>
            }
          />
        ),
      },

      {
        disableFilters: true,
        id: 'debounce_seconds',
        Header: 'Activation Time (seconds)',
        accessor: 'debounce_seconds',
        Cell: (props: Cell<iTableData>) => (
          <InputCell
            id={props.row.original.ID}
            reRenderValue={props.row.original.debounce_seconds}
            valueProperty={`debounce_seconds`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="number"
            step="1"
            min={0}
            max={59}
            validator={validateDebounce_minutes_seconds}
            toolTip={
              <Text style={{ whiteSpace: 'pre-wrap' }}>
                {`The number of seconds the input must be continually asserted before an alarm is raised.
 Minimum:0 Maximum:59.
 Total activation time = minutes field + seconds field + milliseconds field.`}
              </Text>
            }
          />
        ),
      },

      {
        disableFilters: true,
        id: 'debounce_milliseconds',
        Header: 'Activation Time (milliSeconds)',
        accessor: 'debounce_milliseconds',
        Cell: (props: Cell<iTableData>) => (
          <InputCell
            id={props.row.original.ID}
            reRenderValue={props.row.original.debounce_milliseconds}
            valueProperty={`debounce_milliseconds`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="number"
            step="1"
            min={0}
            max={999}
            validator={validateDebounce_milli}
            toolTip={
              <Text style={{ whiteSpace: 'pre-wrap' }}>
                {`The number of milliseconds the input must be continually asserted before an alarm is raised.
 Minimum:0 Maximum:999.
 Total activation time = minutes field + seconds field + milliseconds field.`}
              </Text>
            }
          />
        ),
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : [],
      pageSize: 25,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const save = () => {
    logger.info('SCUInputConfig.save', {
      changedIDs: changesRef.current,
    });
    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _allData.find((e) => e.ID === id);
      if (!stateRecord) continue;

      const sp_alarmType = manifest.getSystemParameter(
        inputParams[id].alarmType
      );
      const sp_pol = manifest.getSystemParameter(inputParams[id].inputPolarity);
      const sp_debounce = manifest.getSystemParameter(inputParams[id].debounce);

      if (!sp_alarmType || !sp_pol || !sp_debounce) continue;

      sp_alarmType.setFromNumber(stateRecord.alarmType);
      sp_pol.setFromNumber(stateRecord.inputPolarity == 0 ? 1 : 0);

      const totalMS = convertDebounceToMs(
        stateRecord.debounce_minutes,
        stateRecord.debounce_seconds,
        stateRecord.debounce_milliseconds
      );
      sp_debounce.setFromNumber(totalMS);
    }

    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setChanges([]);
    };

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('SCUInputConfig.saveFailed', {
            status: status,
            error: {},
          });
        }

        complete(str);
      })
      .catch((error) => {
        logger.info('SCUInputConfig.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        console.error('arc # save fail:', error);
        complete(str);
      });
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? true : false}
      tip="Save changes"
      disabled={changes.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('SCUInputConfig.discardChanges');
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="SCU Input Configuration"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={_allData ? _allData : []}
          showCheckboxes={false}
          showControls={false}
        />
      </>
    </Tile>
  );
};

export default SCUInputConfig;
