import React, { useState, useEffect } from 'react';
import { Box, Button, Layer, Text, Form, Tip } from 'grommet';
import {
  AlignedFormFields,
  convertSingleArrayToKeyValue,
  iField,
} from 'components/forms/formFields';
import SCUversion from 'components/wrappers/SCUversion';
import { AddCircle, Edit, SubtractCircle } from 'grommet-icons';
import { teSCHEDULES_OBJECT_TYPES } from 'types/manifest-enums';
import { ALLDAYSOFWEEK, Schedule } from 'components/installation/schedule';
import * as Utils from 'common/utils/dateUtils';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';
import logger from 'common/logger';
import { Manifest } from 'components/installation/manifest';

const m = new Manifest();

let _schedulesAddedOrDeleted = false;

export interface iScheduleCustomDataProps {
  data: string;
  setData: (d: string) => void;
}

export interface iScheduleValues {
  objectID: number | undefined;
  allday: boolean;
  from: string;
  to: string;
  daysOfWeek: string[];
  data1: string;
  data2: string;
}

const ScheduleEdit = (props: {
  objectType: teSCHEDULES_OBJECT_TYPES;
  objectID: number | undefined; // an Id to associate this schedule with, e.g. deviceID, database ID. if undefined then a text input is added with the label objectIDLabel.
  objectIDLabel?: string; // If objectId is undefined this is the label for the text box in creating a new object Id
  objectIDNewDefault?: number; // If objectId is undefined then this is the default ID to use when creating a new schedule
  scheduleName?: (props: Schedule) => string; // an optional custom name that can be shown in front of the schedule description
  schedules: Schedule[];
  save: () => void;
  disabled: boolean;
  hide?: boolean;
  title?: string;
  enabledAllWeekText?: string;
  addNewText?: string;
  data1?: (props: iScheduleCustomDataProps) => JSX.Element; // a custom component for managing the data 1 schedule field
  data2?: (props: iScheduleCustomDataProps) => JSX.Element; // a custom component for managing the data 2 schedule field
  showData2BeforeData1?: boolean;
}): JSX.Element => {
  const [editSchedule, setEditSchedule] = useState<Schedule | undefined>(
    undefined
  );

  const [values, setValues] = useState<iScheduleValues>({
    objectID: undefined,
    allday: false,
    from: '00:00',
    to: '00:00',
    daysOfWeek: Schedule.scheduleMaskToArray(255),
    data1: '',
    data2: '',
  });

  const scheduleFieldsR0: iField[] = [
    {
      label: `${props.objectIDLabel}`,
      id: 'objectID',
      type: 'text',
      column: 1,
      validate: /^[0-9]+$/,
      hide: props.objectID !== undefined,
      formBoxWidth: '50px',
    },
  ];

  const scheduleFieldsR1 = (allDay: boolean): iField[] => {
    return [
      { label: 'All Day', id: 'allday', type: 'checkbox', column: 1 },
      {
        label: 'Or Start',
        id: 'from',
        type: 'time',
        column: 2,
        readOnly: allDay,
      },
      {
        label: 'Stop',
        id: 'to',
        type: 'time',
        column: 3,
        readOnly: allDay,
      },
    ];
  };

  const scheduleFieldsR2: iField[] = [
    {
      label: '',
      id: 'daysOfWeek',
      type: 'checkboxGroup',
      options: convertSingleArrayToKeyValue([
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thur',
        'Fri',
        'Sat',
      ]),
      column: 1,
    },
  ];

  const amendSchedule = async () => {
    const start: Date = Utils.dateTodaySetTimeHHMM(values.from);
    const end: Date = Utils.dateTodaySetTimeHHMM(values.to);
    const mask = Schedule.ArrayToMask(values.daysOfWeek);

    if (editSchedule) {
      const e = m.getScheduleById(editSchedule.ID);
      if (e) {
        e.updateSchedule({
          ObjectType: props.objectType,
          ObjectID: values.objectID ?? 0,
          DaysOfWeekBitmap: mask,
          AllDay: values.allday ? 1 : 0,
          StartTime: Utils.dateGetSecondsFromMidnight(start),
          StopTime: Utils.dateGetSecondsFromMidnight(end),
          Data1: values.data1,
          Data2: values.data2,
        });

        const sc = props.schedules.find((f) => f.ID == e.ID);
        // console.log('amendSchedule', sc, e);
        sc?.updateSchedule(e);

        logger.info('scheduleEdit.amend', { schedule: e });

        props.save();
        setEditSchedule(undefined);
      }
    }
  };

  const addNewSchedule = async () => {
    _schedulesAddedOrDeleted = true;
    Schedule.createSchedule({
      ObjectType: props.objectType,
      ObjectID: props.objectID ?? props.objectIDNewDefault ?? 0,
      DaysOfWeekBitmap: ALLDAYSOFWEEK,
      AllDay: 1,
      Data1: '',
      Data2: '',
    }).then(() => {
      // Add the new schedule to our cloned copy
      // only add, do not affect other schedules oethrwise changes will get overwritten
      let t: Schedule[] = [];
      if (props.objectIDNewDefault) {
        // this type of schedule does not use both type and ID, it uses the ID field as a custom property. An example are door entry PINs which use the Id as the PIN
        t = m.getSchedulesByType(props.objectType);
      } else {
        if (props.objectID)
          t = m.getSchedulesByTypeAndId(props.objectType, props.objectID);
      }

      // loop through the schedules to find the new one
      t.forEach((i) => {
        const s = props.schedules.find((f) => f.ID == i.ID);
        // we didn't find this schedule so add it to out local copy!
        if (!s) props.schedules.push(i);
      });

      //console.log(props.schedules);
    });

    props.save();
  };

  const deleteSchedule = async (id: number) => {
    _schedulesAddedOrDeleted = true;
    await Schedule.deleteSchedule(id);

    // remove from the cloned copy
    const index = props.schedules.findIndex((f) => f.ID == id);
    if (index > -1) props.schedules.splice(index, 1);

    props.save();

    setEditSchedule(undefined);
  };

  // Set the selected schedule for editing
  const doEditSchedule = (s: Schedule) => {
    if (s) {
      const start = Utils.formatTimeOnlyHHMM(
        Utils.dateTodaySetSecondsFromMidnight(s.StartTime ?? 0)
      );
      const stop = Utils.formatTimeOnlyHHMM(
        Utils.dateTodaySetSecondsFromMidnight(s.StopTime ?? 0)
      );

      // console.log(s, start, stop);

      setValues({
        objectID: s.ObjectID,
        allday: s.AllDay ? true : false,
        from: start,
        to: stop,
        daysOfWeek: Schedule.scheduleMaskToArray(s.DaysOfWeekBitmap ?? 0),
        data1: s.Data1,
        data2: s.Data2,
      });

      setEditSchedule(s);
    }
  };

  const haveSchedules = props.schedules && props.schedules.length > 0;

  // console.log(props);

  const scheduleTitle = props.title
    ? props.title
    : props.schedules && props.schedules.length > 1
    ? 'Schedules'
    : 'Schedule';

  const Data1 = () => {
    if (props.data1)
      return (
        props.data1 && (
          <>
            {props.data1({
              data: values.data1,
              setData: (d) => setValues({ ...values, data1: d }),
            })}
          </>
        )
      );

    return <></>;
  };

  const Data2 = () => {
    if (props.data2)
      return (
        props.data2 && (
          <>
            {props.data2({
              data: values.data2,
              setData: (d) => setValues({ ...values, data2: d }),
            })}
          </>
        )
      );

    return <></>;
  };

  useEffect(() => {
    _schedulesAddedOrDeleted = false;

    return () => {
      if (_schedulesAddedOrDeleted == true) {
        console.info('Changes made so request manifest to be uploaded');
        IoTSCUManifest.Instance.requestManifestUpload(null);
      }
      _schedulesAddedOrDeleted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {editSchedule && (
        <Layer
          position="center"
          style={{ whiteSpace: 'pre-wrap' }}
          modal={true}
        >
          <Box margin="medium" pad="small" gap="small" width="600px">
            <Text size="large">Amend Schedule</Text>
            <Form
              value={values}
              onChange={(nextValue) => {
                setValues(nextValue);
                // console.log(values);
              }}
              // onReset={() => setValue({})}
              onSubmit={async () => {
                await amendSchedule();
              }}
            >
              {props.objectID === undefined && (
                <AlignedFormFields
                  displayfields={scheduleFieldsR0}
                  columnLayouts={[
                    { columnPercentWidth: 50, labelWidth: '120px' },
                  ]}
                />
              )}
              <AlignedFormFields
                displayfields={scheduleFieldsR1(values.allday)}
                columnLayouts={[
                  { columnPercentWidth: 40, labelWidth: '60px' },
                  { columnPercentWidth: 30, labelWidth: '50px' },
                  { columnPercentWidth: 30, labelWidth: '50px' },
                ]}
              />
              <Box margin="6px"></Box>
              <AlignedFormFields
                displayfields={scheduleFieldsR2}
                columnLayouts={[{ columnPercentWidth: 60, labelWidth: '0px' }]}
              />
              <Box margin="10px"></Box>
              {/* {props.data1 && (
                <>
                  {props.data1({
                    data: values.data1,
                    setData: (d) => setValues({ ...values, data1: d }),
                  })}
                </>
              )} */}
              {props.showData2BeforeData1 && <Data2 />}
              <Data1 />
              {!props.showData2BeforeData1 && <Data2 />}
              <Box margin="10px"></Box>
              <Box direction="row" gap="medium" justify="center">
                <Button
                  label="Amend"
                  size="medium"
                  plain={false}
                  margin="small"
                  type="submit"
                />
                <Button
                  label="Cancel"
                  size="medium"
                  plain={false}
                  margin="small"
                  onClick={() => {
                    setEditSchedule(undefined);
                  }}
                />
              </Box>
            </Form>
          </Box>
        </Layer>
      )}
      {!props.hide && (
        <Box direction="column" pad="2px">
          <SCUversion minVersion="5.10.0">
            <Text weight="bold">{scheduleTitle}</Text>

            {!haveSchedules && (
              <Text>{props.enabledAllWeekText ?? 'Enabled all week'}</Text>
            )}
            {haveSchedules && (
              <>
                {props.schedules.map((m, index) => (
                  <span key={index}>
                    <Text weight="bold" style={{ marginRight: '20px' }}>
                      {props.scheduleName && props.scheduleName(m)}
                    </Text>
                    <Text>
                      {props.objectID === undefined
                        ? `${props.objectIDLabel} ${m.ObjectID}  -`
                        : ''}
                      {Schedule.getScheduleText(m)}
                    </Text>
                    <Tip
                      content={<Text>Edit schedule</Text>}
                      dropProps={{ align: { right: 'left', top: 'bottom' } }}
                    >
                      <Edit
                        color={props.disabled ? 'grey' : 'green'}
                        size="16px"
                        cursor="pointer"
                        onClick={() => {
                          if (!props.disabled)
                            doEditSchedule(props.schedules[index]);
                        }}
                      />
                    </Tip>
                    <Tip
                      content={<Text>Delete this schedule</Text>}
                      dropProps={{ align: { right: 'left', top: 'bottom' } }}
                    >
                      <SubtractCircle
                        size="16px"
                        cursor="pointer"
                        color={props.disabled ? 'grey' : 'red'}
                        onClick={async () => {
                          if (!props.disabled) {
                            await deleteSchedule(m.ID);
                          }
                        }}
                        style={{ marginLeft: '6px' }}
                      />
                    </Tip>
                  </span>
                ))}
              </>
            )}
            <span>
              <Tip
                content={<Text>Add a custom schedule</Text>}
                dropProps={{ align: { right: 'left' } }}
              >
                <AddCircle
                  color={props.disabled ? 'grey' : 'green'}
                  size="18px"
                  cursor="pointer"
                  onClick={async () => {
                    if (!props.disabled) await addNewSchedule();
                  }}
                />
              </Tip>
              <Text style={{ marginLeft: '6px' }}>
                {props.addNewText ?? 'Add New Schedule'}
              </Text>
            </span>
          </SCUversion>
        </Box>
      )}
    </>
  );
};

export default ScheduleEdit;
