/** @module Installation */

import { store } from 'store/store';
import { iManifest, iSwVersion } from 'types/manifest-types';
import * as _ from 'lodash';
import { Manifest } from './manifest';
import * as manifestSlice from 'store/manifestSlice';
import { SCUSQLInjection } from 'common/IoT/IoTSCUSQLInjection';

const SQLInjection = SCUSQLInjection.Instance;

export enum teErrorCode {
  E_OK = 0,
  E_CONNECTION_FAIL,
  E_TIMEOUT,
  E_ERROR,
}

export enum teDB_BACKUPMETHOD {
  E_DB_BACKUPMETHOD_NONE = 0,
  E_DB_BACKUPMETHOD_DISK_AND_UPLOAD,
  E_DB_BACKUPMETHOD_DISK_ONLY,
}

export interface iManifestInfo {
  manifestObjectKeys: string[];
  tablename: string;
  dataChanged: boolean;
  tableID: number;
}

export function getJSON(): iManifest {
  // Used the manifest instance instead of direct call to the store so it is easier to mock when testing with Jest
  return Manifest.Instance.getJSON();
}

export function getManifestInstance(): Manifest {
  return Manifest.Instance;
}

function generateUpdateSQL(
  store: unknown,
  change: unknown,
  tableName: string,
  idKey: string
): { update: string; revert: string } {
  let update = `update ${tableName} set `;
  let revert = update;

  const id = _.get(store, `${idKey}`, undefined);
  const changeid = _.get(change, `${idKey}`, undefined);
  if (id != undefined && id === changeid) {
    const storeKeyValuePairs = Object.entries(store as Record<string, unknown>);

    for (const storeindex in storeKeyValuePairs) {
      const storeEntry_k = storeKeyValuePairs[storeindex][0];
      const storeEntry_v = storeKeyValuePairs[storeindex][1];

      const changeEntry_v = _.get(change, storeEntry_k, undefined);
      if (changeEntry_v !== undefined) {
        if (!_.isEqual(changeEntry_v, storeEntry_v)) {
          if (typeof changeEntry_v === 'string') {
            update += storeEntry_k + "='" + changeEntry_v + "',";
            revert += storeEntry_k + "='" + storeEntry_v + "',";
          } else {
            update += storeEntry_k + '=' + changeEntry_v + ',';
            revert += storeEntry_k + '=' + storeEntry_v + ',';
          }
        }
      }
    }
  }

  //replace last comma from query
  let n = update.lastIndexOf(',');
  update = update.slice(0, n) + update.slice(n).replace(',', '');
  n = revert.lastIndexOf(',');
  revert = revert.slice(0, n) + revert.slice(n).replace(',', '');

  update += ` where ${idKey}=${id};\n`;
  revert += ` where ${idKey}=${id};\n`;
  return { update, revert };
}

export function generateChangeSQLForTable(
  change: unknown[],
  tableName: string
): {
  update: string;
  revert: string;
} {
  let update = '';
  let revert = '';

  const idKey = manifestSlice.TABLE_IDS.get(tableName);
  if (idKey === undefined) {
    console.error('idKey not found for table:', tableName);
    return { update, revert };
  }

  const manifestTables = getJSON();

  for (const changeIndex in change) {
    const changeRow = change[changeIndex];

    const id = _.get(changeRow, `${idKey}`, undefined);
    if (id == undefined) {
      console.error('id field not found:', idKey);
      console.error('object:', changeRow);
      return { update, revert };
    }

    const table = _.get(manifestTables, tableName, undefined) as unknown;
    if (table == undefined) {
      console.error('cant find table:', tableName);
      return { update, revert };
    }
    if (!Array.isArray(table)) {
      console.error('table is not an array:', table);
      return { update, revert };
    }

    for (const index in table) {
      const row = table[index] as unknown;
      const rowid = _.get(row, `${idKey}`, undefined);
      if (rowid === id) {
        if (!_.isEqual(row, changeRow)) {
          const sql = generateUpdateSQL(row, changeRow, tableName, idKey);
          update += sql.update;
          revert += sql.revert;
          break;
        }
      }
    }
  }

  return { update, revert };
}

export async function executeSQLRevert(revert: string[]): Promise<boolean> {
  // |TODO: call iot runql on each revert entry
  console.error('Need to implement revert:', revert);
  return true;
}

export async function executeSQL(
  update: string,
  revert: string,
  backupType: teDB_BACKUPMETHOD,
  repopulateCaches = false
): Promise<teErrorCode> {
  let status = teErrorCode.E_TIMEOUT;
  const updateLines = update.split('\n');
  const revertLines = revert.split('\n');
  const toRevertLines: string[] = [];

  let forceupload = false;
  let backupToDisk = false;
  if (backupType == teDB_BACKUPMETHOD.E_DB_BACKUPMETHOD_DISK_AND_UPLOAD) {
    forceupload = true;
  } else if (backupType == teDB_BACKUPMETHOD.E_DB_BACKUPMETHOD_DISK_ONLY) {
    backupToDisk = true;
  }

  let connected = false;
  try {
    connected = await SQLInjection.checkConnection();
  } catch (error) {
    console.error('No sql connection:', error);
  }

  if (!connected) return teErrorCode.E_CONNECTION_FAIL;

  while (updateLines.length > 0) {
    const line = updateLines.shift();
    const ignoreRevert = revertLines.length == 0 ? false : true;

    const revert = ignoreRevert ? '' : revertLines.shift();
    const uploadManifest = false;

    if (!line || line == '' || (ignoreRevert === false && revert === ''))
      continue;

    //console.info('Run sql update:', line);
    //console.info('revert sql is:', revert);

    //SQLInjection.sendSQL will generate exception if no response is received.
    try {
      await SQLInjection.sendSQL(
        line,
        backupToDisk,
        false,
        uploadManifest,
        null
      );
      status = teErrorCode.E_OK;
    } catch (e) {
      console.error('SQL update error: ', e);
      status = teErrorCode.E_TIMEOUT;
      break;
    }

    if (!ignoreRevert && line && revert) {
      toRevertLines.push(revert);
    }
  }

  if (status != teErrorCode.E_OK && toRevertLines.length > 0) {
    await executeSQLRevert(toRevertLines);
  }

  // force a backup so the manifest is re-uploaded
  if (forceupload) {
    await SQLInjection.forceBackup(repopulateCaches);
  }

  return status;
}

/**
 * Creates a new object with just the properties specified in the
 * objectKeys array.
 *
 * @param obj object to get the keys from
 * @param objectKeys what keys to read from the object
 * @returns a copy of obj with only the properties specified in objectKeys
 */
export function getManifestObjectArray(
  obj: unknown,
  objectKeys: string[]
): unknown[] {
  const manifestArray: unknown[] = [];
  const newobj = _.pick(obj, objectKeys);
  manifestArray.push(newobj);
  return manifestArray;
}

export async function despatchToManifestStore(
  tableName: string,
  obj: unknown,
  keys?: string[]
): Promise<void> {
  await store.dispatch(
    manifestSlice.update({
      tableName: tableName,
      change: keys === undefined ? obj : getManifestObjectArray(obj, keys),
    })
  );
}

export async function despatchDeleteToManifestStore(
  tableName: string,
  obj: unknown,
  keys?: string[]
): Promise<void> {
  await store.dispatch(
    manifestSlice.deleteRow({
      tableName: tableName,
      change: keys === undefined ? obj : getManifestObjectArray(obj, keys),
    })
  );
}

export async function despatchInsertToManifestStore(
  tableName: string,
  obj: unknown,
  keys?: string[]
): Promise<void> {
  await store.dispatch(
    manifestSlice.insertRow({
      tableName: tableName,
      change: keys === undefined ? obj : getManifestObjectArray(obj, keys),
    })
  );
}

export function generateChangeSQL(
  manifestObject: unknown,
  manifest: iManifestInfo,
  sqlInput: { update: string; revert: string }
): {
  update: string;
  revert: string;
} {
  let update = sqlInput.update;
  let revert = sqlInput.revert;

  const changeobj = getManifestObjectArray(
    manifestObject,
    manifest.manifestObjectKeys
  );

  // The ID key is set to the ID in the device table.
  // When generating manifest changes the ID should be that of the device type table, i.e. accesspoint
  _.set(changeobj, '[0].ID', manifest.tableID);
  const sql = generateChangeSQLForTable(changeobj, manifest.tablename);
  if (sql.update != '') {
    update += sql.update;
    revert += sql.revert;
  }

  manifest.dataChanged = false;
  if (sql.update != '') {
    manifest.dataChanged = true;
  }

  return { update, revert };
}

export async function applyManifestChanges(
  manifestObject: unknown,
  manifestInfo: iManifestInfo
): Promise<void> {
  if (manifestInfo.dataChanged) {
    const changeobj = getManifestObjectArray(
      manifestObject,
      manifestInfo.manifestObjectKeys
    );

    // The ID key is set to the ID in the device table.
    // When generating manifest changes the ID should be that of the device type table, i.e. accesspoint
    _.set(changeobj, '[0].ID', manifestInfo.tableID);

    await despatchToManifestStore(manifestInfo.tablename, changeobj);
  }
  manifestInfo.dataChanged = false;
}

// convert manifest software version into string
export const versionToString = (s: iSwVersion): string => {
  return `${s.major}.${s.minor}.${s.build}`;
};

/**
 * Checks a resident group Id for validity
 * @param groupId
 * @returns true if groupId within expected range
 */
export const isValidGroupId = (groupId: number): boolean => {
  return groupId >= 1 && groupId <= 30;
};
