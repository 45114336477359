/** @module Installation */

import React from 'react';
import {
  iHandset,
  iHandSetChargeEvent,
  invalidID,
  iRoutingEvent,
} from 'types/manifest-types';
import { Device } from './device';
import { iDevice } from '../../types/manifest-types';
import * as events from 'components/systemevents/events';
import * as liveEvents from 'components/systemevents/liveEvents';
import { AUDIT_EVENTS } from '../../types/event-enums';
import { iAUDIT_EVENT_HS_LOG_ON } from '../../types/eventTypes';
import { HandsetChargeInfo } from 'types/API';
import * as manifestUtils from 'components/installation/manifestUtils';
import * as API from 'types/API';
import { teDEVICE_MODELS_HANDSET } from '../../types/manifest-enums';

//import * as Site from 'components/floorplan/site';

/*
 * Handset device class
 * @class
 * @hideconstructor
 * @implements iRoom
 */

export class Handset extends Device implements iHandset {
  ID = invalidID;
  TableID = invalidID;
  Description = '';
  DeviceId = invalidID;
  InUse = 0;
  LoggedOn = 0;
  MicVolume = 0; //not used
  FeatureMask = 0;
  BatteryEvents = [{ Info: [] as unknown as HandsetChargeInfo, Timestamp: '' }];
  lastEvent: API.OmniviaLiveEventsLog | undefined = undefined;
  lastKnownLocation = '';

  constructor(
    HValues: iHandset,
    deviceValues: iDevice,
    routingEvents: iRoutingEvent[],
    keys: string[],
    tableName: string
  ) {
    super(deviceValues, routingEvents);
    Object.assign(this, HValues);
    this.productManifest.manifestObjectKeys = keys;
    this.productManifest.tablename = tableName;
    this.productManifest.dataChanged = false;
    this.productManifest.tableID = this.ID;
    this.TableID = this.ID;
    this.ID = deviceValues.ID;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(
      this,
      this.productManifest,
      super.generateChangeSQL()
    );
    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    await super.applyManifestChanges();
    return manifestUtils.applyManifestChanges(this, this.productManifest);
  }

  toolTip(): JSX.Element {
    return (
      <div>
        <p>{this.Description}</p>
        <ul>
          <li>Status:{this.LoggedOn ? 'Logged On' : 'Logged Off'}</li>
          <li>Device ID: {this.DeviceId.toString()}</li>
          <li>MAC Address:{this.getDeviceMACaddr()}</li>
          <li></li>
        </ul>
      </div>
    );
  }

  /** get events for this handset */
  getHandsetEvents(): iAUDIT_EVENT_HS_LOG_ON[] {
    // Get all handset events
    // Use iAUDIT_EVENT_HS_LOG_ON as a generic type for handset events
    const hseventJSON: iAUDIT_EVENT_HS_LOG_ON[] = events.getEventsByType(
      [AUDIT_EVENTS.AUDIT_EVENT_HS_LOG_ON, AUDIT_EVENTS.AUDIT_EVENT_HS_LOG_OFF],
      true,
      true
    ) as iAUDIT_EVENT_HS_LOG_ON[];

    return hseventJSON.filter((e) => e.RaisedDeviceID == this.DeviceId);
  }

  /** Get the charge history for the handset */
  getHandsetChargeInfoHistory(): iHandSetChargeEvent[] {
    this.BatteryEvents = liveEvents.getHandsetChargeInfo(
      this.MacAddress.toString()
    );
    return this.BatteryEvents;
  }

  /** Get the current charge for the handset */
  getHandsetLastChargeInfo(): iHandSetChargeEvent | undefined {
    return this.BatteryEvents
      ? this.BatteryEvents[this.BatteryEvents.length - 1]
      : undefined;
  }
  isEvolutionPlus(): boolean {
    if (
      this.HwModel == teDEVICE_MODELS_HANDSET.DEVIE_HANDSET_MODEL_OMNIVIA_WIFI
    ) {
      return true;
    }

    return false;
  }

  /** return a data array of x and y points for a graph */
  getChargeData(): { x: string; y: number }[] {
    const data: { x: string; y: number }[] = [];
    this.BatteryEvents.forEach((b) => {
      data.push({ x: b.Timestamp, y: b.Info.currentBatteryCapacity });
    });
    return data;
  }

  async setLastEvent(
    _lastEvent: API.OmniviaLiveEventsLog | undefined
  ): Promise<void> {
    this.lastEvent = _lastEvent;
    this.lastKnownLocation = '';

    /*
     *  Site imports mainfest
     *  Manifest imports handsets
     *  Handsets imports site
     *  To fix this i used a dynamic import of site instead of
     *  import * as Site from 'components/floorplan/site';
     */
    const Site = await import('components/floorplan/site');
    if (this.lastEvent && this.lastEvent.parentMac) {
      this.lastKnownLocation = Site.getLocatabilityName(
        parseInt(this.lastEvent.parentMac)
      );
    }
  }

  /** get numeric charge level for a handset */
  static chargeLevel = (h: Handset): number => {
    const i = h.getHandsetLastChargeInfo();
    if (i) {
      let cap = (i as iHandSetChargeEvent).Info.currentBatteryCapacity;
      if (h.isEvolutionPlus()) {
        // EvolutionPLus handset only charges battery to 90% to maximise its life.
        // Normalise 0 to 90 to 0 to 100
        const min = 0;
        const max = 0.89;

        cap /= 100;

        if (cap < min) cap = min;
        if (cap > max) cap = max;

        cap = (cap - min) / (max - min);
        cap *= 100;
      }

      return Math.floor(cap);
    } else {
      return 0;
    }
  };

  /** get text charge level for a handset */
  static chargeLevelText = (h: Handset): string => {
    //return Handset.chargeLevel(h) > 90 ? '100%' : `${Handset.chargeLevel(h)}%`;
    return `${Handset.chargeLevel(h)}%`;
  };

  /** is a handset on charge? */
  static onCharge = (h: Handset): boolean => {
    const i = h.getHandsetLastChargeInfo();
    if (i) {
      return (i as iHandSetChargeEvent).Info.isCharging;
    } else return false;
  };

  /** is a handset screen on */
  static screenOn = (h: Handset): boolean => {
    const i = h.getHandsetLastChargeInfo();
    if (i) {
      return (i as iHandSetChargeEvent).Info.isScreenOn;
    } else return false;
  };
}
