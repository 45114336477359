import React, { FC, useState } from 'react';
import { Tip, Select, Box } from 'grommet';
import { CellProps } from './commonCellProps';

const SelectCell: FC<CellProps> = ({
  id,
  valueProperty,
  hasChanged,
  getProperty,
  setProperty,
  options,
  searchBox,
  toolTip,
  alwaysDisplayDropDown,
}) => {
  const value = getProperty(id, valueProperty);
  const [selectOptions, setSelectOptions] = useState(options);
  const [, setComponentRenderCount] = useState(0);
  const searchEnabled = searchBox ? searchBox : false;

  if (!options || !selectOptions) return <></>;

  const valueObject = options.find((o) => value == o.value);

  if (!valueObject && !alwaysDisplayDropDown) return <></>;

  let _toolTip: string | JSX.Element | undefined = undefined;

  if (typeof toolTip === 'function') {
    _toolTip = toolTip(id);
  } else if (toolTip) {
    _toolTip = toolTip;
  }

  const cell = (
    <Box background={hasChanged ? 'gold' : 'grey-0'}>
      <Select
        labelKey="text"
        valueKey="value"
        value={valueObject}
        size="small"
        options={selectOptions}
        onClose={() => {
          setSelectOptions(options);
        }}
        onChange={({ value: nextValue }) => {
          const validOption = options.find((o) => nextValue.value == o.value);

          if (validOption) {
            setProperty(id, valueProperty, nextValue.value);
            setComponentRenderCount((old) => old + 1);
          }
        }}
        onSearch={
          searchEnabled
            ? (text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  '\\$&'
                );

                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, 'i');
                const newOption = options.filter((o) => exp.test(o.text));

                setSelectOptions(newOption);
              }
            : undefined
        }
      />
    </Box>
  );

  //console.info('SelectCell: re-render');
  return (
    <>
      {_toolTip && <Tip content={_toolTip}>{cell}</Tip>}
      {_toolTip === undefined && <>{cell}</>}
    </>
  );
};
const memorised = React.memo(SelectCell);
export default memorised;
