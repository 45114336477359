import React from 'react';

import { PageHeader, Paragraph } from 'grommet';

const HelpDesignPlan = (): JSX.Element => {
  return (
    <>
      <PageHeader title="Control and Shortcuts for Design Plans" size="small" />

      <ul>
        <li>V - Puts the plan into view only mode</li>
        <li>Delete - Deletes the currently selected equipment from the plan</li>
        <li>S - Saves the current plan</li>
        <li>
          1,2,3,4,5 - Selects a plan. The same as clicking on a plan to load
        </li>
        <li>Page Up, Page Down - Zoom in and out</li>
        <li>Arrow Keys - Pans the plan left, right up and down</li>
        <li>0 - Resets the view zoom and pan to default</li>
        <li>D - Sets the selected equipment to designed</li>
        <li>F - Sets the selected equipment to fitted</li>
        <li>C - Sets the selected equipment to commisioned</li>
        <li>R - Rotates the selected equipment clockwise</li>
        <li>
          Keypad Plus, Keypad Minus - Change length of equipment call out line
        </li>
        <li>P - Toggles if the selected equipment is communal or not</li>
        <li>N - Selects the next equipment of the selected type on the plan</li>
      </ul>
      <Paragraph fill={true} textAlign="start">
        In order to move items select edit mode. To delete or add new equipment
        as part of a design select Design mode. In design mode Equipment can be
        added by selecting the equipment type and clicking for each piece of
        equipment you wish to add. To add existing equipment from an
        installation then drag it from the left hand menu onto the plan.
      </Paragraph>
      <Paragraph fill={true} textAlign="start">
        The equipment table will auto update whilst equipment is being added,
        the table can be re-positioned to a suitable place on the plan
      </Paragraph>
    </>
  );
};

export default HelpDesignPlan;
