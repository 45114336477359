import React from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent } from 'components/dashboard/tile';
import {
  Box,
  Button,
  CheckBox,
  MaskedInput,
  Select,
  Text,
  Layer,
  Form,
} from 'grommet';

import { teErrorCode } from 'components/installation/manifest';
import * as ManifestTypes from 'types/manifest-types';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import TablePaged from 'components/tables/TablePaged';
import { Column } from 'react-table';
import * as UserUtils from 'common/userUtils';
import { useWinstonLogger } from 'winston-react';
import { Edit, Clear } from 'grommet-icons';

import {
  getAllCareGroups,
  getCareSequence,
  getFullDestinationList,
  iCareGroupInfo,
  iDestinationListItem,
  saveToSCU,
  applyCareGroupToAlarmType,
  applyCareGroupToResidents,
} from 'components/installation/careGroupUtils';
import ScheduleEdit from 'components/dashboard/commonWidgets/schedules/scheduleEdit';
import { teSCHEDULES_OBJECT_TYPES } from 'types/manifest-enums';
import { manifest } from 'components/floorplan/site';
import { CareGroup } from 'components/installation/careGroup';
import { AlignedFormFields, iField } from 'components/forms/formFields';
import { generateAlarmCallSequencesReport } from '../../../reports/reportTypes/alarmCallSequencesReport';

import {
  ALARMS_TYPE_DESCRIPTIONS_SIMPLIFIED,
  iAlarmTypeDescription,
} from 'types/event-enums';

// NOTE: CareGroups have been renamed as Call Squences in the UI for clarity

let _allData: iCareGroupInfo[] = [];
let _careGroupsTableData: ManifestTypes.iCareGroup[] = [];
let _sequencesTableData: ManifestTypes.iCareSequence[] = [];
let _routingDestinations: iDestinationListItem[] = [];
const _alarmOptions = ALARMS_TYPE_DESCRIPTIONS_SIMPLIFIED.filter(
  (e) => e.value !== 0
).sort(function (a, b) {
  return a.text > b.text ? 1 : a.text < b.text ? -1 : 0;
});

const CareGroupsConfigTable = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [renderCount, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useState<number[]>([]);
  const [sequenceChanges, setSequenceChanges, sequenceChangesRef] =
    useState(false);
  const [selectedCareGroup, setSelectedCareGroup] = useState('-1');
  const [selectedCareSequence, setSelectedCareSequence] =
    useState<ManifestTypes.iCareSequence>();
  const [selectedResourceID, setSelectedResourceID] = useState('2');

  const [editCareGroupName, setEditCareGroupName] = useState<
    CareGroup | undefined
  >(undefined);

  // const [selectedRows, setSelectedRows] = useState<ManifestTypes.iCareGroup[]>(
  //   []
  // );

  const loadData = () => {
    // _allData = _.cloneDeep(manifest.handsets);
    _allData = getAllCareGroups();

    _careGroupsTableData = [];
    _allData.forEach((item) => {
      _careGroupsTableData.push({
        ID: item.careGroup.ID,
        Description: item.careGroup.Description,
        CareSequenceId: item.careGroup.CareSequenceId,
      });
    });

    _routingDestinations = getFullDestinationList();
    logger.debug('caregroupconfig.loaddata', _routingDestinations);

    // logger.debug( "caregroupconfig"_allData);
    setChanges([]);

    setRenderCount((old) => old + 1);
  };

  const selectCareSequenceItem = async (
    rowData: ManifestTypes.iCareSequence,
    selectionInfo: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (!selectionInfo.alreadySelected) {
      logger.debug('caregroupconfig.select', rowData);
      const s = _sequencesTableData.find((e) => e.ID == rowData.ID);

      if (s) {
        setSelectedCareSequence(s);
        setSelectedResourceID(s.ResourceId.toString());
        setRenderCount((old) => old + 1);
      }
    }
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        if (sequenceChangesRef.current || changesRef.current.length > 0) {
          console.info('Ignore manifest PROCESSED event as we have changes ');
        } else {
          loadData();
        }
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        if (sequenceChangesRef.current || changesRef.current.length > 0) {
          console.info('Ignore manifest UPDATED event as we have changes ');
        } else {
          loadData();
        }
      },
      executeOnStartup: true,
    },
  ];

  const columnsSequences = React.useMemo<Column<ManifestTypes.iCareSequence>[]>(
    () => [
      {
        Header: 'Step',
        accessor: 'ID',
        width: '60px',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <Text
            color={
              selectedCareSequence
                ? selectedCareSequence.ID == props.row.values.ID
                  ? 'orange'
                  : 'grey-1'
                : 'grey-1'
            }
          >
            {((props.row.values.ID - 1) % 10) + 1}
          </Text>
        ),
        disableFilters: true,
      },

      // {
      //   width: '200px',
      //   Header: 'Dest',
      //   accessor: 'ResourceId',
      //   /* eslint-disable react/prop-types */
      //   disableFilters: true,
      // },
      {
        width: '200px',
        Header: 'Destination Type',
        id: 'DestinationType',
        accessor: (row) => {
          if (row.ResourceId) {
            const seq = getCareSequence(row.ID);
            // logger.debug('caregroupconfig', { seq: seq, rowid: row.ID });
            if (seq) {
              const t = seq.getDestinationDescription();
              // logger.debug('caregroupconfig', t);
              return seq.getDestinationDescription().typeName;
            }
          }
          return '';
        },
        Cell: ({ ...props }) => (
          <Text
            color={
              selectedCareSequence
                ? selectedCareSequence.ID == props.row.values.ID
                  ? 'orange'
                  : 'grey-1'
                : 'grey-1'
            }
          >
            {props.row.values.DestinationType}
          </Text>
        ),
        disableFilters: true,
      },
      {
        width: '200px',
        Header: 'Destination Name',
        id: 'Destination',
        accessor: (row) =>
          row.ResourceId
            ? _allData[Number(selectedCareGroup)]?.sequences[
                row.Position
              ].getDestinationDescription().label
            : '',
        Cell: ({ ...props }) => (
          <Text
            color={
              selectedCareSequence
                ? selectedCareSequence.ID == props.row.values.ID
                  ? 'orange'
                  : 'grey-1'
                : 'grey-1'
            }
          >
            {props.row.values.Destination}
          </Text>
        ),

        disableFilters: true,
      },
      {
        width: '100px',
        Header: 'Attempts',
        accessor: 'Retries',
        id: 'Retries',

        Cell: ({ ...props }) => (
          <Text
            color={
              selectedCareSequence
                ? selectedCareSequence.ID == props.row.values.ID
                  ? 'orange'
                  : 'grey-1'
                : 'grey-1'
            }
          >
            {props.row.values.Retries == 255
              ? props.row.values.Destination == '--------'
                ? '--------'
                : 'Maximum'
              : props.row.values.Retries + 1}
          </Text>
        ),
        disableFilters: true,
      },
    ],
    [renderCount] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : [],
      pageSize: 11,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const save = () => {
    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setChanges([]);
      setSequenceChanges(false);
    };
    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });
    let str = 'Save failed';
    saveToSCU().then((status) => {
      if (status === teErrorCode.E_OK) {
        str = 'Save Completed Successfully';
      } else if (status === teErrorCode.E_CONNECTION_FAIL) {
        str = 'Connection failed';
        logger.info('handsetConfigTable.saveFailed', {
          status: status,
          error: {},
        });
      }

      complete(str);
    });
  };

  const exportExcel = () => {
    generateAlarmCallSequencesReport();
  };

  const ContextButtons = (
    <Box direction="row">
      <Button
        plain={false}
        size="small"
        label="Save"
        color={changes.length > 0 || sequenceChanges ? 'brand' : 'grey-4'}
        badge={changes.length > 0 || sequenceChanges ? true : false}
        tip="Save changes"
        disabled={changes.length > 0 || sequenceChanges ? false : true}
        onClick={() => {
          save();
        }}
      />
      <Button
        plain={false}
        size="small"
        label="Routing Report"
        tip="Export to Excel"
        margin={{ left: '8px' }}
        onClick={() => {
          exportExcel();
        }}
        disabled={!UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)}
      />
    </Box>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function changeCareGroup(value: any) {
    // logger.debug('caregroupconfig.changed', value);

    const callGroupInfo = _allData.find((e) => e.careGroup.ID == Number(value));

    if (callGroupInfo) {
      _sequencesTableData = [];
      callGroupInfo.sequences.forEach((item) => {
        _sequencesTableData.push({ ...item });
      });
      setRenderCount((old) => old + 1);
    }
    setSelectedCareSequence(undefined);
    setSelectedCareGroup(value);
    // logger.debug('caregroupconfig', { callGroupInfo, _sequencesTableData });
  }

  //logger.debug('caregroupconfig', selectedResourceID);

  //const cgDescription =
  //  _careGroupsTableData[Number(selectedCareGroup)]?.Description;

  const EditCareGroupName = () => {
    const [values, setValues] = useState<CareGroup | undefined>(
      editCareGroupName
    );

    if (editCareGroupName === undefined || values === undefined) {
      setEditCareGroupName(undefined);
      return <></>;
    }

    const careGroupEditFields: iField[] = [
      { label: 'Name', id: 'Description', type: 'text', column: 1 },
    ];

    const ammendData = async () => {
      const cgInfo = _allData.find(
        (e) => e.careGroup.ID === editCareGroupName.ID
      );
      if (cgInfo !== undefined) {
        // This will change the data in the actual caregroup instance
        cgInfo.careGroup.Description = values.Description;

        // Load data pulls all the manifest instance data as so will pull the change we have just made
        loadData();
        setSequenceChanges(true);
        //de-select caregroup so detrministic
        setSelectedCareSequence(undefined);
      }
    };

    return (
      <Box margin="medium" pad="small" gap="small" width="600px">
        <Form
          value={values}
          onChange={(nextValue) => {
            setValues(nextValue);
            // console.log(values);
          }}
          onSubmit={async () => {
            await ammendData();
            setEditCareGroupName(undefined);
          }}
        >
          <AlignedFormFields
            displayfields={careGroupEditFields}
            columnLayouts={[{ columnPercentWidth: 100, labelWidth: '60px' }]}
          />

          <Box margin="10px"></Box>
          <Box direction="row" gap="medium" justify="center">
            <Button
              label="Amend"
              size="medium"
              plain={false}
              margin="small"
              type="submit"
            />
            <Button
              label="Cancel"
              size="medium"
              plain={false}
              margin="small"
              onClick={() => {
                setEditCareGroupName(undefined);
              }}
            />
          </Box>
        </Form>
      </Box>
    );
  };

  const ApplyButtons = () => {
    const [alarmSelectOptions, setAlarmSelectOptions] = useState(_alarmOptions);
    const [alarmSelection, setAlarmSelection] = useState<
      iAlarmTypeDescription | undefined
    >(undefined);

    const currentCgInfo = _allData[Number(selectedCareGroup)];
    if (currentCgInfo === undefined) return <></>;
    let validEntry = false;
    for (const seq of currentCgInfo.sequences) {
      if (seq.ResourceId !== seq.CARE_SEQ_ENTRY_NOT_USED) {
        validEntry = true;
        break;
      }
    }

    const currentCg = currentCgInfo.careGroup;

    // Load data pulls all the manifest instance data as so will pull the change we have just made
    //loadData();
    //setSequenceChanges(true);
    //de-select caregroup so detrministic
    //setSelectedCareSequence(undefined);

    return (
      <>
        {validEntry && (
          <>
            <Text textAlign="start" weight="bold">
              {`Apply ${currentCg.Description} Call Sequence to:`}
            </Text>

            <Box direction="row" width="80vw">
              <Button
                margin={{ left: '0px', right: '35px', top: '10px' }}
                plain={false}
                size="small"
                label={'All Rooms'}
                onClick={() => {
                  setSequenceChanges(true);
                  applyCareGroupToResidents(currentCg.ID, manifest.rooms);
                  console.info('testSQL:', manifest.testGenerateSql());
                }}
              />

              <Button
                margin={{ left: '35px', right: '35px', top: '10px' }}
                plain={false}
                size="small"
                label={'All Residents'}
                onClick={async () => {
                  const residentList = manifest.rooms.filter(
                    (e) => e.isCommunal() == false
                  );
                  setSequenceChanges(true);
                  applyCareGroupToResidents(currentCg.ID, residentList);
                  console.info('testSQL:', manifest.testGenerateSql());
                }}
              />

              <Button
                margin={{ left: '35px', right: '35px', top: '10px' }}
                plain={false}
                size="small"
                label={'All Communal Units'}
                onClick={async () => {
                  const residentList = manifest.rooms.filter(
                    (e) => e.isCommunal() == true
                  );

                  setSequenceChanges(true);
                  applyCareGroupToResidents(currentCg.ID, residentList);
                  console.info('testSQL:', manifest.testGenerateSql());
                }}
              />
            </Box>
          </>
        )}

        <Box
          direction="row"
          width="80vw"
          margin={{ top: '10px' }}
          pad="6px"
          height="40px"
        >
          <Button
            margin={{ left: '-6px', right: '35px', top: '0px' }}
            plain={false}
            size="small"
            label={'Alarm Type'}
            disabled={alarmSelection === undefined}
            onClick={async () => {
              if (alarmSelection !== undefined) {
                const _setSeq = () => {
                  setSequenceChanges(true);
                  applyCareGroupToAlarmType(currentCg.ID, alarmSelection.value);
                  console.info('testSQL:', manifest.testGenerateSql());
                };

                if (!validEntry) {
                  setDialogueProps({
                    show: true,
                    dialogueText: `Are you sure you want to apply an empty call sequence to ${alarmSelection.text} alarms?`,
                    showSpinner: false,
                    showOkButton: true,
                    showCancelButton: true,
                    okButtonPress: () => {
                      _setSeq();
                      setDialogueProps(undefined);
                    },
                    cancelButtonPress: () => {
                      setDialogueProps(undefined);
                    },
                  });
                } else {
                  _setSeq();
                }
              }
            }}
          />

          <Select
            placeholder="Select Alarm Type..."
            labelKey="text"
            valueKey="value"
            options={alarmSelectOptions}
            value={alarmSelection}
            size="medium"
            disabled={false}
            onChange={({ option }) => {
              console.info('option:', option);
              setAlarmSelection(option);
            }}
            onSearch={(text) => {
              // The line below escapes regular expression special characters:
              // [ \ ^ $ . | ? * + ( )
              const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

              // Create the regular expression with modified value which
              // handles escaping special characters. Without escaping special
              // characters, errors will appear in the console
              const exp = new RegExp(escapedText, 'i');
              const newOption = _alarmOptions.filter((o) => exp.test(o.text));

              setAlarmSelectOptions(newOption);
            }}
          />
        </Box>
      </>
    );
  };

  console.log(selectedCareGroup);

  return (
    <Tile
      title="Call Sequences"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      // menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        {editCareGroupName && (
          <Layer
            position="center"
            background="dialogBackground"
            style={{ whiteSpace: 'pre-wrap' }}
            modal={true}
          >
            <EditCareGroupName />
          </Layer>
        )}
        <Box>
          <Box border="all" margin="15px" pad="10px" background="lightgreen">
            <Text weight="bold" color="grey-0" size="15px">
              A Call Sequence determines where an alarm will be sent to and how
              many attempts are made. This can be sent to any number of handset
              groups and Alarm Receiving Centres (ARCs). This can also be done
              on a timed basis, for example if handsets are used during the day,
              with ARC cover during the night.
            </Text>
          </Box>
          <Box width="50%" alignSelf="center" margin="small">
            <span>
              <Select
                placeholder="Select a Call Sequence..."
                labelKey="Description"
                valueKey={{ key: 'ID', reduce: true }}
                options={_careGroupsTableData}
                value={selectedCareGroup}
                size="medium"
                onChange={({ option }) => {
                  // console.info('option:', option);
                  changeCareGroup(option.ID);
                }}
              />
              <Edit
                style={{
                  marginLeft: '10px',
                }}
                color={
                  selectedCareGroup !== '-1' &&
                  selectedCareGroup != '0' &&
                  _allData !== undefined
                    ? 'green'
                    : 'grey'
                }
                size="16px"
                cursor="pointer"
                onClick={() => {
                  if (
                    selectedCareGroup !== '-1' &&
                    selectedCareGroup != '0' &&
                    _allData !== undefined
                  ) {
                    setEditCareGroupName(
                      _allData[Number(selectedCareGroup)].careGroup
                    );
                  }
                }}
              />
            </span>
          </Box>
          <Box direction="row">
            <Box>
              <TablePaged
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={columnsSequences}
                initialState={initialState}
                data={_sequencesTableData ? _sequencesTableData : []}
                showCheckboxes={false}
                showControls={false}
                onLastSelectedRow={selectCareSequenceItem}
              />

              {selectedCareGroup !== '-1' && _allData !== undefined && (
                <Box border="top">
                  <Box alignSelf="start" margin="8px" direction="column">
                    <ApplyButtons />
                  </Box>
                  {/* <AssociatedAlarms /> */}
                </Box>
              )}
            </Box>
            <Box border="left" height="300px" pad="8px" width="350px">
              <Text size="medium" margin="small" weight="bold">
                {`Step ${
                  selectedCareSequence
                    ? ((selectedCareSequence?.ID - 1) % 10) + 1
                    : ''
                }`}
              </Text>
              <span>
                <Select
                  placeholder="Select a destination..."
                  disabled={selectedCareSequence ? false : true}
                  labelKey="fullLabel"
                  valueKey={{ key: 'ID', reduce: true }}
                  options={_routingDestinations}
                  value={selectedResourceID}
                  size="small"
                  onChange={({ option }) => {
                    setSelectedResourceID(option.ID);
                    if (selectedCareSequence) {
                      console.info('option:', option);
                      const seq = getCareSequence(selectedCareSequence.ID);
                      if (seq) {
                        seq.ResourceId = Number(option.ID);
                        setSequenceChanges(true);
                        setRenderCount((old) => old + 1);
                      }
                    }
                  }}
                />
                <Clear
                  style={{
                    marginLeft: '10px',
                  }}
                  size="16px"
                  cursor="pointer"
                  onClick={() => {
                    if (selectedCareSequence !== undefined) {
                      console.info('clear');
                      const seq = getCareSequence(selectedCareSequence.ID);
                      if (seq) {
                        seq.clearDestination();
                        setSequenceChanges(true);
                        setSelectedCareSequence(undefined);
                        setSelectedResourceID('-2');
                        loadData();
                        setRenderCount((old) => old + 1);
                      }
                    }
                  }}
                />
              </span>
              <Box margin="small">
                <Text weight="bold">Retries</Text>
                <Box direction="row">
                  <CheckBox
                    disabled={selectedCareSequence ? false : true}
                    checked={selectedCareSequence?.Retries == 255}
                    label=""
                    pad="small"
                    onChange={(event) => {
                      if (selectedCareSequence) {
                        const seq = getCareSequence(selectedCareSequence.ID);
                        if (seq) {
                          seq.Retries = event.target.checked ? 255 : 1;
                          selectedCareSequence.Retries = seq.Retries;
                          setSequenceChanges(true);
                          setRenderCount((old) => old + 1);
                        }
                      }
                    }}
                  />
                  <Text margin={{ top: '8px' }}>Maximum</Text>
                </Box>
                <MaskedInput
                  disabled={
                    selectedCareSequence
                      ? selectedCareSequence.Retries == 255
                      : true
                  }
                  mask={[
                    {
                      length: 3,
                      regexp: /^[0-9]{1,3}$/,
                      placeholder: '000',
                    },
                  ]}
                  value={selectedCareSequence?.Retries}
                  onChange={(event) => {
                    if (selectedCareSequence) {
                      const seq = getCareSequence(selectedCareSequence.ID);
                      if (seq) {
                        seq.Retries = Number(event.target.value);
                        selectedCareSequence.Retries = seq.Retries;
                        setSequenceChanges(true);
                        setRenderCount((old) => old + 1);
                      }
                    }
                  }}
                />
                <ScheduleEdit
                  schedules={manifest.getSchedulesByTypeAndId(
                    teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_CARE_SEQUENCE_ENTRY,
                    selectedCareSequence?.ID ?? -1
                  )}
                  save={() => setSequenceChanges(true)}
                  objectID={selectedCareSequence?.ID ?? -1}
                  objectType={
                    teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_CARE_SEQUENCE_ENTRY
                  }
                  disabled={selectedCareSequence ? false : true}
                  hide={selectedCareSequence ? false : true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    </Tile>
  );
};

export default CareGroupsConfigTable;
