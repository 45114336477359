/** @module Installation */

import { iCameraConfig, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';

/*
 *
 * @class
 * @hideconstructor
 * @implements iCameraConfig
 */
export class CameraConfig implements iCameraConfig {
  /** iCameraConfig start */
  ID = invalidID;
  Url = '';
  /** iCameraConfig end */

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(values: iCameraConfig, keys: string[], tableName: string) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
  }

  // The VRUs camera urls are updated on a poll loop.
  // The VRU will only pull the camera URLs if the CAMERA_SYNC_ID id has changes so need
  // to increment it by one when we do the save
  setUrl(newUrl: string): void {
    const manifest = manifestUtils.getManifestInstance();
    console.info(`Setting id ${this.ID} to ${newUrl}`);

    if (this.Url != newUrl) {
      this.Url = newUrl;
      const sp_cameraSync = manifest.getSystemParameter('CAMERA_SYNC_ID');
      if (sp_cameraSync) {
        let oldSync = parseInt(sp_cameraSync.Value);
        oldSync++;
        sp_cameraSync.setFromNumber(oldSync);
      }
    }
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });
    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }
}
