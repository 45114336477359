import React, { forwardRef, useEffect } from 'react';
import { Box, TextInput } from 'grommet';

/* Need ForwardRef since this functional component
   is being passed into a custom theme for SearchInput
*/
const SearchInput = forwardRef(({ ...props }, textInputRef) => {
  useEffect(() => {
    const focusTimeout = setTimeout(() => {
      textInputRef.current.focus();
    }, 300);

    return () => {
      clearTimeout(focusTimeout);
    };
  }, [textInputRef]);

  return (
    <Box background="white" pad="6px" border={{ color: 'gold' }}>
      <TextInput size="medium" {...props} plain ref={textInputRef} />
    </Box>
  );
});

SearchInput.displayName = 'Search Input';

export default SearchInput;
