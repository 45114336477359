import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import * as dbAccess from './dbAccess';
import * as queries from '../graphql/queries';
import { createExtraReducers } from './commonReducers.js';
import {
  OmniviaLiveEventsLog,
  HandsetChargeInfo,
  ReceiverList,
} from '../types/API';

import { teCLOUD_MESSAGE_TYPES } from 'common/IoT/IoTMessageDefines';
import {
  teCLOUD_MSG_TY_DEVICES,
  tsCLOUD_MSG_DEVICES_LIVE_EVENT_LOG,
} from 'common/IoT/IoTMessageDefines_DEVICES';
import { SCUIoTMessaging } from 'common/IoT/SCUIoTMessaging';
import { Message } from 'common/IoT/IotMessageHandler';
import { RootState } from './store';
import { store } from 'store/store';
import { teDEVICE_TYPES } from 'types/manifest-enums';
import { systemEventTopics } from './eventDispatcher';
import { initialSliceState } from 'store/commonSlice';
import * as eventDispatcher from 'store/eventDispatcher';
import { basicuuid } from 'common/utils/miscUtils';
import { dateToIsoString_dateonly } from 'common/utils/dateUtils';
let subscribed = false;

const dbAccessInfo = {
  DAOname: 'liveEventsLog',
  allQuery: queries.liveEventsLogBySiteAndDate,
  allSelector: 'liveEventsLogBySiteAndDate',
  allSort: 'name',
  selectQuery: queries.getOmniviaLiveEventsLog,
  selectSelector: 'getOmniviaLiveEventLog',
};

/**
 * To enusre the lowest cost for live events they are handled differently to audit events
 * The audit event data flow from the SCU is:
 *       SCU -> IoT -> Lamdba -> App Sync -> Dynamo
 *
 * This data flow allows us to use app sync subscrptions to get real time events.
 *
 * Battery events data flow is:
 *       SCU -> IoT -> Dynamo
 *
 * To get real time events we subscribe to the IoT message sent from the SCU and then convert this
 * into the same data for that we would get from app sync.
 *
 * @param scuSerial SCU serial number
 */
function subscribeToNewEvents(scuSerial: string): void {
  if (subscribed) {
    return;
  }

  subscribed = true;

  console.info('live events log subscribe:', scuSerial);

  // battery logs are not inserted into the DB via app sync so normal graphql
  // susbscriptions can't be used.
  // Listen directly to the IoT event
  SCUIoTMessaging.Instance.registerMessageTypeCallback(
    teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES,
    (msg: Message) => {
      if (
        msg.type == teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES &&
        msg.opcode == teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_LIVE_EVENT_LOG
      ) {
        const liveEventMsg = msg.msg as tsCLOUD_MSG_DEVICES_LIVE_EVENT_LOG;

        let hsChargeInfo: HandsetChargeInfo | null = null;
        if (
          liveEventMsg.deviceType == teDEVICE_TYPES.DEVICE_TYPE_WARDEN_HANDSET
        ) {
          if (liveEventMsg.handset) {
            hsChargeInfo = {
              ...liveEventMsg.handset,
              __typename: 'HandsetChargeInfo',
            };
          }
        }

        let addtionalReceiverList: ReceiverList[] | null = null;

        if (liveEventMsg.addtionalReceiverList) {
          addtionalReceiverList = liveEventMsg.addtionalReceiverList.map(
            (obj) => ({ ...obj, __typename: 'ReceiverList' })
          );
        }

        const event: OmniviaLiveEventsLog = {
          __typename: 'OmniviaLiveEventsLog',

          // The ID is usually generated when inserting into the DB.
          // Since we are not getting the real-time data from the DB we need to generate one
          // When we fetch data from the DB all the data is overwritten so we can get away with
          // generating a fake UUID
          id: basicuuid(),
          mac: liveEventMsg.mac.toString(10),
          omniviaSCUSerial: msg.srcid,
          deviceType: liveEventMsg.deviceType,
          expirationUnixTime: 0,
          batteryVoltageApplication: liveEventMsg.batteryVoltageApplication,
          handset: hsChargeInfo,
          createdAt: msg.ts,
          updatedAt: msg.ts,
          deviceId: liveEventMsg.deviceId,
          associatedAlarmId: liveEventMsg.associatedAlarmId,
          parentMac: liveEventMsg.parentMac.toString(),
          rssi_db: liveEventMsg.rssi_db,
          addtionalReceiverList: addtionalReceiverList,
        };

        store.dispatch(
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          slice.actions.newEvent(event)
        );
      }
    },
    null
  );
}

const adapter = createEntityAdapter<OmniviaLiveEventsLog>({
  //selectId: (object) => object.id, // id is the default
  sortComparer: (a: OmniviaLiveEventsLog, b: OmniviaLiveEventsLog) => {
    if (a.updatedAt && b.updatedAt) {
      return a.updatedAt.localeCompare(b.updatedAt);
    }
    return a.id.localeCompare(b.id);
  },
});

const initialState = adapter.getInitialState(initialSliceState);

// Thunk functions
export const fetch = createAsyncThunk(
  'liveEventLog/fetch',
  async (fetchInfo: { SCUSerialNumber: string; daysToFetch: number }) => {
    const dateOffset = 24 * 60 * 60 * 1000 * fetchInfo.daysToFetch;
    const date = new Date();
    date.setTime(date.getTime() - dateOffset);
    const dateInPast: string = dateToIsoString_dateonly(date);

    console.info('Get live log for:', fetchInfo.SCUSerialNumber);

    const response = await dbAccess.loadData(
      dbAccessInfo,
      {
        omniviaSCUSerial: fetchInfo.SCUSerialNumber,
        createdAt: { ge: dateInPast },
        limit: 5000,
        allSort: function (a: OmniviaLiveEventsLog, b: OmniviaLiveEventsLog) {
          // oldest first
          if (a.createdAt && b.createdAt) {
            if (a.createdAt < b.createdAt) {
              return -1;
            }
            if (a.createdAt > b.createdAt) {
              return 1;
            }
          }
          return 0;
        },
      },
      true
    );

    //console.info('*** LIVE RESPONSE:', response);
    subscribeToNewEvents(fetchInfo.SCUSerialNumber);
    return response;
  }
);

export const select = createAsyncThunk(
  'liveEventLog/select',
  async (log: OmniviaLiveEventsLog) => {
    const response = await dbAccess.select(dbAccessInfo, { id: log.id });
    return response;
  }
);

const slice = createSlice({
  name: 'liveEventLog',
  initialState,
  reducers: {
    // eslint-disable-next-line
    clearSelected(state, action) {
      state.selectedId = '';
    },
    newEvent(state, action) {
      adapter.addOne(state, action.payload);
      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.LIVEEVENTS,
        eventDispatcher.systemEventStates.UPDATED,
        action.payload,
        true
      );
    },
  },
  extraReducers: (builder) => {
    return createExtraReducers(
      systemEventTopics.LIVEEVENTS,
      builder,
      fetch,
      select,
      adapter
    );
  },
});

export const { selectAll, selectById } = adapter.getSelectors<RootState>(
  (state: RootState) => state.liveeventslog
);
export const { clearSelected, newEvent } = slice.actions;

export default slice.reducer;

const getState = (state: RootState) => state;
export const getSelected = createSelector([getState], (state) => {
  return selectById(state, state.sites.selectedId);
});

export const getAll = createSelector([getState], (state) => {
  return selectAll(state);
});

export const getSlice = createSelector([getState], (state: RootState) => {
  return state.liveeventslog;
});
