import React, { useState } from 'react';
import useStateRef from 'react-usestateref';
import Tile, {
  iTileEvent,
  iMenuProps,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Button, Text } from 'grommet';

import { Manifest, teErrorCode } from 'components/installation/manifest';
import * as ManifestTypes from 'types/manifest-types';
import * as ManifestEnums from 'types/manifest-enums';
import { Device } from 'components/installation/device';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import TablePaged from 'components/tables/TablePaged';
import { Column } from 'react-table';
import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
//import SelectCell from 'components/tables/cellPresentationComponents/selectCell';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
import IDCell from 'components/tables/cellPresentationComponents/idCell';
import { useWinstonLogger } from 'winston-react';
import { Product } from 'components/floorplan/product';
import * as IoTAssign from 'common/IoT/IoTAssignDevice';
import * as Utils from 'common/utils/miscUtils';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';

const manifest = new Manifest();

interface iTableData extends Partial<ManifestTypes.iDevice> {
  UnitId: number;
  MacAddress: number;
  Name: string;
}

const blankTableEntry: iTableData = {
  ID: 0,
  Type: 0,
  HwModel: 0,
  MacAddress: 0,
  ClusterGW: 0,
  UnitId: 0,
  Name: 'Not Set',
};

let _allData: iTableData[] = [];

const UnassignedConfigTable = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useStateRef<number[]>([]);

  const loadData = () => {
    const unassignedDevices = _.cloneDeep(manifest.getUnassignedDevices());
    _allData = [];

    for (const index in unassignedDevices) {
      const device = unassignedDevices[index];
      console.info('Unassigned device:', device);

      const merged = _.merge(_.cloneDeep(blankTableEntry), device);
      const accessPoint = manifest.getAccessPointByDeviceMac(device.MacAddress);
      if (
        device.Type ===
          ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER &&
        accessPoint
      ) {
        merged.Name = accessPoint.Name;
        merged.UnitId = accessPoint.UnitId ? accessPoint.UnitId : 0;
      }

      _allData.push(merged);
    }

    console.info('Unassigned :', _allData);

    setChanges([]);
    setRenderCount((old) => old + 1);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        // only update if we are not editing
        if (changesRef.current.length == 0) {
          setDialogueProps(undefined);
          loadData();
        }
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        // only update if we are not editing
        if (changesRef.current.length == 0) {
          setDialogueProps(undefined);
          loadData();
        }
      },
      executeOnStartup: true,
    },
  ];

  /**
   * @param id    room unit device id
   * @param property item to change in the room unit object
   * @param value new value
   */
  const setProperty = (id: number, property: string, value: unknown) => {
    const device = _allData.find((e) => e.ID == id);
    if (device) {
      const manifestRecord = manifest.devices.find((e) => e.ID == id);

      const setIt = () => {
        if (!changesRef.current.includes(id)) {
          changesRef.current.push(id);
          setRenderCount((old) => old + 1);
        }
      };

      if (property === 'Name') {
        const MAX_NAME_LENGTH = 20;
        let name = value as string;
        if (name.length > MAX_NAME_LENGTH) {
          name = name.substring(0, MAX_NAME_LENGTH);
        }
        value = name;
      } else if (property === 'UnitId') {
        let unitid = value as number;
        if (unitid < 0) {
          unitid = 0;
        } else if (unitid > 9999) {
          unitid = 9999;
        }
        value = unitid;
      }

      _.set(device, property, value);
      setRenderCount((old) => old + 1);

      if (manifestRecord) {
        if (_.get(manifestRecord, property, 0) != value) {
          setIt();
        } else {
          _.pull(changesRef.current, id);
          setRenderCount((old) => old + 1);
        }
      } else {
        console.error('Manifest record not found for device:', device);
      }
    }
  };

  function getProperty(id: number, property: string): unknown {
    let device = _allData.find((e) => e.ID == id);
    if (device === undefined) {
      device = blankTableEntry;
    }
    const value = _.get(device, property, 0);
    return value;
  }

  const validateUnitId = (
    id: number,
    newValue: unknown
  ): number | undefined => {
    const unitId = newValue as number;

    if (unitId < 0 || unitId > 9999 || unitId == 0) return 0;

    // We allow multiple units to have 9000 as this defines the system
    if (unitId == 9000) return undefined;

    const deviceInfo = manifest.unitIdInUse(unitId);
    const currentTable = _allData.find((e) => {
      return e.UnitId == unitId && e.ID != id ? true : false;
    });

    if (deviceInfo === undefined && currentTable === undefined)
      return undefined;

    const ap = manifest.accessPoints.find((e) => e.ID == id);
    let revertUnitId = 0;
    if (ap) {
      revertUnitId = ap.UnitId;
    }

    let mac = 0;
    let deviceid = 0;
    if (deviceInfo !== undefined) {
      mac = deviceInfo.mac;
      deviceid = deviceInfo.deviceId;
    } else if (currentTable !== undefined) {
      mac = currentTable.MacAddress;
      deviceid = currentTable.ID ? currentTable.ID : 0;
    }
    let assignedToName = manifest.getDeviceNameByMac(mac);
    assignedToName += ` ID:${deviceid}`;

    logger.info('accessPointConfigTable.invalidUnitId', { unitId: unitId });
    setDialogueProps({
      show: true,
      dialogueText: `The unit ID ${unitId} is already in use.`,
      dialogueTextLine2: assignedToName,
      showSpinner: false,
      showOkButton: true,
      okButtonPress: () => setDialogueProps(undefined),
    });

    return revertUnitId;
  };

  /**
   * The aim of all the cell components is that they do not render unnecessarily
   * All the cell components use React.memo to memorise the component.
   *
   * React.memo will only re-generate the component if a prop changes therefore we pass a reRenderValue prop
   * to the component to allow us to signal to React.memo that it needs to be re-rendered.
   *
   * The components use callback to set/get their data and not state varibles
   */
  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        width: '60px',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <IDCell
            id={props.row.values.ID}
            changesRef={changesRef}
            reRenderValue={changesRef.current.includes(props.row.values.ID)}
          />
        ),
      },
      {
        Header: 'Type',
        accessor: (row) => {
          return Product.getProductBaseName(
            Product.getProductfromManifestDeviceType(row)
          );
        },
      },
      {
        Header: 'Identity',
        accessor: (row) => {
          if (row.Type == ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_SIP_DOOR) {
            return row.IPAddress;
          } else {
            return Device.MACaddrToString(row.MacAddress, 16);
          }
        },
        width: 30,
      },
      {
        Header: 'IP Address',
        accessor: (row) => {
          let bridge = false;
          if (row.ClusterGW) {
            bridge = true;
          } else if (
            row.Personality ===
            ManifestEnums.teDEVICE_PERSONALITY.DEVICE_PERSONALITY_GWI
          ) {
            bridge = true;
          }

          if (bridge) {
            return manifest.getDeviceIPAddress(row.MacAddress);
          }

          return '';
        },
      },
      {
        Header: 'Room/Unit Id',
        accessor: 'UnitId',
        width: '60px',
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.UnitId}
            valueProperty={`UnitId`}
            getProperty={getProperty}
            setProperty={setProperty}
            validator={validateUnitId}
            inputType="number"
            toolTip={
              <Text style={{ whiteSpace: 'pre-wrap' }}>
                {'For room units, the unit id is the room number'}
              </Text>
            }
          />
        ),
      },
      {
        Header: 'Name',
        accessor: 'Name',
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Name}
            valueProperty={`Name`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="text"
          />
        ),
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : [],
      pageSize: 25,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const save = async (): Promise<void> => {
    let manaifestSaveNeeded = false;
    logger.info('unassignedConfigTable.save', {
      changedIDs: changesRef.current,
    });

    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _allData.find((e) => e.ID === id);

      if (
        stateRecord === undefined ||
        stateRecord.Name === 'Not Set' ||
        stateRecord.Name === '' ||
        stateRecord.UnitId == 0
      ) {
        setDialogueProps({
          show: true,
          dialogueText:
            'Please ensure that the Room/Unit id and the name are set before saving',
          showSpinner: false,
          showOkButton: true,
          okButtonPress: () => {
            setDialogueProps(undefined);
          },
        });
        return;
      }
    }

    const complete = async (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        dialogueTextLine2: 'Please wait, resyncing...',
        showSpinner: true,
        showOkButton: false,
      });
      setChanges([]);

      // Delay added to ensure the change events have propergated
      // in all the SCU modules
      //
      // Without the delay I have seen the manifest request been ignored by
      // the SCU as it thought no DB changes has occured
      //
      await Utils.sleep(500);

      try {
        await IoTSCUManifest.Instance.requestManifestUpload(null);
      } catch (error) {
        console.error('Error waiting for manifest request, error:', error);
      }

      setDialogueProps(undefined);
      loadData();
    };

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _allData.find((e) => e.ID === id);
      const device = manifest.devices.find((e) => e.ID === id);
      if (!stateRecord || !device) {
        console.error('device not found during save');
        continue;
      }

      if (
        device.Type === ManifestEnums.teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER
      ) {
        // We only issue assign requests for rooms and interfaces
        // Accesspoint just need the table updating
        const accessPoint = manifest.getAccessPointByDeviceMac(
          device.MacAddress
        );
        if (accessPoint) {
          accessPoint.Name = stateRecord.Name;
          accessPoint.UnitId = stateRecord.UnitId;
          manaifestSaveNeeded = true;
        }
      } else {
        try {
          await IoTAssign.assignDevice(
            device.MacAddress,
            stateRecord.UnitId,
            stateRecord.Name,
            null
          );
        } catch (e) {
          setDialogueProps({
            show: true,
            dialogueText: 'No response from the SCU, it maybe offline',
            showSpinner: false,
            showOkButton: true,
            okButtonPress: () => {
              setDialogueProps(undefined);
            },
          });
          return;
        }
      }
    }

    if (manaifestSaveNeeded === false) {
      complete('Save Completed Successfully');
      return;
    }

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('unassignedConfigTable.saveFailed', {
            status: status,
            error: {},
          });
        }

        complete(str);
      })
      .catch((error) => {
        logger.info('unassignedConfigTable.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        console.error('arc # save fail:', error);
        complete(str);
      });
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? changes.length : false}
      tip="Save changes"
      disabled={changes.length > 0 ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('unassignedConfigTable.discardChanges');
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="Unassigned Device Configuration"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={_allData ? _allData : []}
          showCheckboxes={false}
          reportDescription={{
            header: '',
            filename: 'UnassignedDeviceConfig',
          }}
        />
      </>
    </Tile>
  );
};

export default UnassignedConfigTable;
