import { HandsetChargeInfo } from './API';
import {
  SYSTEM_PARAMETER_ACCESS_TYPES,
  SYSTEM_PARAMETER_DATA_TYPES,
  teDESTINATION_TYPES,
} from './manifest-enums';
/** @module types */

export interface iSwVersion {
  major: number;
  minor: number;
  build: number;
}

export const invalidID = -1;

export interface iSystemParameter {
  /** Database ID */
  ID: number;
  /** Parameter Name */
  Name: string;
  /** Value of parameter */
  Value: string;
  /** Default value */
  DefaultValue: string;
  /** Paramere description */
  Description: string;
  /** Data type
   * DATA_TYPE_INT8 = 0,
   * DATA_TYPE_INT16,
   * DATA_TYPE_INT32,
   * DATA_TYPE_INT64,
   * DATA_TYPE_DATE,
   * DATA_TYPE_TEXT,
   * DATA_TYPE_UINT8,
   * DATA_TYPE_UINT16,
   * DATA_TYPE_NULL,
   */
  DataType: SYSTEM_PARAMETER_DATA_TYPES;
  /** Access Level, 0=root 1=user(installer) */
  AccessLevel: SYSTEM_PARAMETER_ACCESS_TYPES;
  ShortName: string;
}

export interface iHandSetChargeEvent {
  Info: HandsetChargeInfo;
  Timestamp: string;
}

export interface iHandset {
  /** Database table number */
  ID: number;
  /** Name of this handset */
  Description: string;
  /** system device ID */
  DeviceId: number;
  /** currently in use (in a call) */
  InUse: number;
  /** currentl logged on */
  LoggedOn: number;
  /** not used */
  MicVolume: number;
  /** Which features this handset supports
   * Bit 0 - Supports Ring Volume feature of adjustable ring tone
   * Bit 1 - Supports WIFI connection for WIFI calls
   */
  FeatureMask: number;
  /** The recent battery events */
  BatteryEvents: iHandSetChargeEvent[];
}

export interface iRoom {
  ID: number;
  /** Friendly name */
  Name: string;
  /** Which care group to use */
  DefaultCareGroupId: number;
  /** Which technical group to use */
  DefaultTechnicalCareGroupId: number;
  /** maps to the device ID in the device table */
  HomeDeviceId: number;
  /** Room number */
  RoomNumber: number;
  /** Audio volume of room unit. sets to 0-4 to denote levels  to 5
   * Level corresponds to an attentuation defined in the system parameters 36 to 40
   */
  Volume: 0 | 1 | 2 | 3 | 4;
  /** Tone volume of the room unit. sets to 0-4 to denote levels  to 5
   * Level corresponds to an attentuation defined in the system parameters 36 to 40
   */
  ToneVolume: 0 | 1 | 2 | 3 | 4;
  /** Mic volume - NOT USED on VRUs (AGC is used). Used on st ediion room units*/
  MicVolume: 0 | 1 | 2 | 3 | 4;
  /** Inactivity enabled?*/
  Inactivity: number;
  /** Intruder enabled? */
  Intruder: number;
  /** not used */
  Empty: number;
  /** OK feature enabled> */
  EnableOK: number;
  /** Last OK event, tim in ms since 970 etc (EPOCH) */
  LastOK: number;
  FlagOK: number;
  /** Home/Away state */
  AwayState: number;
  /** Linked room number (if the room contains more than one room unit)
   *  0 = no link
   *   to 9999 = link to another room number. Both rooms will contain a link to each other
   *  10000 = link to managers handset for conierge calls
   */
  LinkedRoomNumber: number;
  /** WIFI mode
   *  0 = connect to WIFI on demand if no LAN is present
   *  1 = WIFI is always on
   *  2 = Act as a WIFI access point
   */
  WifiMode: 0 | 1 | 2;

  /** Added in R5.10 */
  MetaData: string;
  LastSeen: number;
  LastSpokenTo: number;
  OKTimeLeftBeforeReminder: number;
  OKReminderPeriod: number;

  /** Added in R5.11 */
  RaiseResidentOKAlarm: number;

  /** Added in R5.12 */
  TestModeEnabledEPOCH: number;
  IntruderStateChangeEPOCH: number;
  IntruderState: number;
  EntryExitTimeout: number;
  DivertDoorToManager: number;
  HomeAwaySetIntruder: number;
  AwayDivertDoorToManager: number;
}

export interface iAccessPoint {
  ID: number;
  /** Friendly name */
  Name: string;
  /** maps to the devive ID in the device table */
  DeviceId: number;
  /** Unit id is used for BSI , akin to room number */
  UnitId?: number;
}

/** Door Panels and other interfaces (MAIS)
 * This is the DOOR_PANEL table which is confusingly named as its
 * actually consists of:-
 * Door Panels (Connected to MAIs)
 * 2N IP panels
 * VPC MAI interfaces
 * @interface
 */
export interface iDoorsAndOtherInteraces {
  ID: number;
  Name: string;
  DeviceId: number;
  UnitId: number;
  Volume: number;
  MicVolume: number;
  CareGroupId: number;
  TechnicalCareGroupId: number;
  RelayModes: number;
  CameraId: number;
}

/** iDevice
 * @interface
 */
export interface iDevice {
  /** Database ID */
  ID: number;
  /** MAC Address , 64bit number */
  MacAddress: number;
  /** product type */
  Type: number;
  /** Location code passed to BSI */
  Location: number;
  /** Hardware Model, i.e. which version */
  HwModel: number;
  /** How many polls have been missed */
  PollsMissed: number;
  /** id of the devices data parent */
  ParentID: number;
  /** RSSI signal strength */
  RSSI: number;
  /** network depth */
  Depth: number;
  /** Number fo network rejoins */
  NetworkJoins: number;
  /** not sure if used */
  RaisePollFails: number;
  /** not used */
  PrefParentID: number;
  /** the MAI mode of use pullcord, door interface etc
   * 0 - not used
   *  - Lift interface
   * 2 - Door interface
   * 3 -  Button call point
   * 4 - Gateway interafce
   * 5 - Pullcord interface */
  Personality: number;
  /** IP Address if relevant */
  IPAddress: string;
  /** If used as a clsuter gateway (bridge to wireless network) */
  ClusterGW: number;

  /** Only in R5.03 software */
  /** Set to 1 to put the devces 869MHZ receiver into tunstall mode. */
  TunstallReceiver?: number;

  DateAdded: string; // DateAdded added in R5.19
  AddedBy: string; // AddedBy added in R5.19
}

export const iDeviceDefaults: iDevice = {
  ID: invalidID,
  MacAddress: 0,
  Location: 0,
  Type: 0,
  HwModel: 0,
  PollsMissed: 0,
  ParentID: 0,
  RSSI: 0,
  Depth: 0,
  NetworkJoins: 0,
  RaisePollFails: 0,
  PrefParentID: 0,
  Personality: 0,
  IPAddress: '',
  ClusterGW: 0,
  DateAdded: '',
  AddedBy: '',
};

/** iCareGroup
 * @interface
 */
export interface iCareGroup {
  ID: number;
  Description: string;
  CareSequenceId: number;
}

/** iCareSequence
 * @interface
 */
export interface iCareSequence {
  ID: number;
  CareSequenceId: number;
  Position: number;
  Retries: number;
  ResourceId: number;
}

/** iRoutingResource
 * @interface
 */
export interface iRoutingResource {
  ID: number;
  DestinationType: teDESTINATION_TYPES;
  DestinationId: number;
  Available: number;
}

/** iRoutingEvent
 * @interface
 */
export interface iRoutingEvent {
  ID: number;
  AlarmSourceDeviceId: number;
  AlarmTypeId: number;
  AltAlarmTypeId: number;
  ResidentId: number;
  CareSequenceId: number;
  Flags: number;
  // IsTechnical is not used, alarms are hard coded if they are technical alarms
  IsTechnical: number;
  SpeechCfg: number;
  GuardPeriod: number;
  PreAlarmDelay: number;

  /** Added in R5.10 */
  Covert?: number;

  /** Added in R5.12 */
  AlarmDisabled?: number;
}

/** iEndpointCCDTMF
 * @interface
 */
export interface iEndpointCCDTMF {
  ID: number;
  Description: string;
  Number: string;
  Line: number;
  InUse: number;
  LoggedOn: number;
}

export interface iSYSTEM {
  created: string;
  'sw version': string;
}

export interface iOAD {
  DeviceID: number;
  DeviceSW: number;
  CoProSW: number;
  AppProSW: number;
  DevicePending: number;
  CoProPending: number;
  AppProPending: number;
}

export interface iCameraConfig {
  ID: number;
  Url: string;
}

export type iARCDetailType =
  | 'Call Point'
  | 'Communal'
  | 'Door Panel'
  | 'IP Door Panel'
  | 'Lift'
  | 'Assist Pullcord'
  | 'Room'
  | 'System';

export interface iARCDetail {
  equipID: string;
  description: string;
  audioEnabled: string;
  type: iARCDetailType;
}

export interface iGroupHandset {
  ID: number;
  Description: string;
  Mask: number;
  AvailableMask: number;
  LoggedOnMask: number;
}

export interface iEndpoint_CC_SIP {
  ID: number;
  Description: string;
  ProtcolType: number;
  RegistryURI: string | null;
  Port: number;
  AuthScheme: string;
  AuthUserName: string;
  AuthPassword: string;
  Realm: string;
  ConnectionFlags: number;
  SIPIdURI: string | null;
  UnitId: string | null;
  Address1URI: string | null;
  Address2URI: string | null;
  Address3URI: string | null;
  AllowConcurrentAlarms: number;
  ConfigurationType: number;
  DelayForAlarmMessageSend: number;
  HandshakeMode: number;
  HeartbeatLocalUnitIdent: string;
  ImmediateAlarmMessage: number;
  InDialogMessages: number;
  NonAudioAsAudio: number; // convert any non audio calls to being audio
}

export interface iALARM_TYPES {
  ID: number;
  ExternalId: number;
  IsTechnical: number;
  Description: string;
  Priority: number;
  StatusCode: number;
  StatusCodeBatteryLow: number;
  NoneAudioAlarm: number;
  Covert: number;
  AlarmDisabled: number;
  EntryExit: number;
  Intruder: number;
  Inactivity: number;
}

export interface iSCHEDULE {
  ID: number;
  ObjectID: number;
  ObjectType: number;
  DaysOfWeekBitmap: number; // Bit 0=Sunday, Bit 1=Monday, ... , Bit 6 = Saturday
  StartTime: number; // seconds from midnight
  StopTime: number; // seconds from midnight
  AllDay: number;

  // Added in R5.11 but changed in R5.12 to string
  Data1?: string; // use dependant on the object type
  Data2?: string; // use dependant on the object type
}

export interface iResidentGroup {
  groupID: number; // 1 to 30
  name: string;
  description: string;
}

export const iResidentGroupDefaults: iResidentGroup[] = [
  { groupID: 1, name: 'Group1', description: '' },
  { groupID: 2, name: 'Group2', description: '' },
  { groupID: 3, name: 'Group3', description: '' },
  { groupID: 4, name: 'Group4', description: '' },
  { groupID: 5, name: 'Group5', description: '' },
  { groupID: 6, name: 'Group6', description: '' },
  { groupID: 7, name: 'Group7', description: '' },
  { groupID: 8, name: 'Group8', description: '' },
  { groupID: 9, name: 'Group9', description: '' },
  { groupID: 10, name: 'Group10', description: '' },
  { groupID: 11, name: 'Group11', description: '' },
  { groupID: 12, name: 'Group12', description: '' },
  { groupID: 13, name: 'Group13', description: '' },
  { groupID: 14, name: 'Group14', description: '' },
  { groupID: 15, name: 'Group15', description: '' },
  { groupID: 16, name: 'Group16', description: '' },
  { groupID: 17, name: 'Group17', description: '' },
  { groupID: 18, name: 'Group18', description: '' },
  { groupID: 19, name: 'Group19', description: '' },
  { groupID: 20, name: 'Group20', description: '' },
  { groupID: 21, name: 'Group21', description: '' },
  { groupID: 22, name: 'Group22', description: '' },
  { groupID: 23, name: 'Group23', description: '' },
  { groupID: 24, name: 'Group24', description: '' },
  { groupID: 25, name: 'Group25', description: '' },
  { groupID: 26, name: 'Group26', description: '' },
  { groupID: 27, name: 'Group27', description: '' },
  { groupID: 28, name: 'Group28', description: '' },
  { groupID: 29, name: 'Group29', description: '' },
  { groupID: 30, name: 'Group30', description: '' },
];

export interface iCLOUD_META_DATA {
  ID: number;
  Type: string;
  Data: string;
}

/**
 * Manifest
 * @interface
 */
export interface iManifest {
  /** System info; software verion */
  SYSTEM: iSYSTEM;
  /** Network interface */
  NETWORK: [];
  /** Systm DB version */
  SYSTEM_DB_VERSION: [];
  /** All system patameters */
  SYSTEM_PARAMETERS: iSystemParameter[];
  /** List of all devices on the system */
  DEVICE_LIST: iDevice[];
  /** Audio neighbours for devices*/
  DEVICE_NEIGHBOUR: [];
  /** Handset Groups */
  GROUP_HANDSET: iGroupHandset[];
  /** Handsets */
  ENDPOINT_HANDSET: iHandset[];
  /** Telephone lines */
  ENDPOINT_POTS: [];
  /**  */
  ENDPOINT_CC_DTMF: iEndpointCCDTMF[];
  /** Care groups */
  CARE_GROUP: iCareGroup[];
  /** Care sequences - order in which alarm calls are made and to where*/
  CARE_SEQUENCE: iCareSequence[];
  /** Care sequence info - Not used */
  CARE_SEQUENCE_INFO: [];
  ENUM_TYPES_DEVICE: [];
  ENUM_TYPES_DESTINATION: [];
  ROUTING_RESOURCE: iRoutingResource[];
  ROUTING_EVENT: iRoutingEvent[];
  /** All residents and their associated device IDs */
  RESIDENT: iRoom[];
  ALARM_TYPES: iALARM_TYPES[];
  DOOR_PANEL: iDoorsAndOtherInteraces[];
  /** The wireless access points */
  ROUTER: iAccessPoint[];
  /** Commands the installer can use in the GWI - to be deprecated at some stage */
  INSTALLER_SHELL: [];
  /** Any IP cameras */
  CAMERA_CONFIG: iCameraConfig[];
  ENDPOINT_CC_SIP: iEndpoint_CC_SIP[];
  /** Any timed events/schedules */
  SCHEDULES: iSCHEDULE[];
  /** Added CLOUD_META_DATA in R5.07 */
  CLOUD_META_DATA: iCLOUD_META_DATA[];
}
