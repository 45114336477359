import React, { useState } from 'react';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../../tile';
import { Form, FormField, TextInput, Button, Box } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest, teErrorCode } from '../../../installation/manifest';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import * as Icons from 'grommet-icons';
import { useWinstonLogger } from 'winston-react';

interface iConfig {
  minRngVolume: number;
}
let unchangedConfig: iConfig | undefined = undefined;

const HandsetSettings = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [config, setConfig] = useState<iConfig>({
    minRngVolume: 15,
  });

  const [, SetChildEvent] = useState('');
  const [dataloaded, setDataloaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueText, setDialogueText] = useState('');
  const manifest = new Manifest();

  const min = 2;
  const max = 15;

  function updateInfo() {
    if (manifest.systemParameters.length > 0) {
      const info: iConfig = {
        minRngVolume: 15,
      };

      const minRngVolumeSP = manifest.getSystemParameter(
        'HANDSET_MINIMUM_RING_VOLUME'
      );

      if (!minRngVolumeSP) return;
      info.minRngVolume = parseInt(minRngVolumeSP.Value, 10);

      unchangedConfig = _.cloneDeep(info);

      setConfig(info);
      setDataloaded(true);
    }
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: updateInfo,
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: updateInfo,
      executeOnStartup: false,
    },
  ];

  function save(): void {
    console.info('***************Saving');

    logger.info('handsetSettings.save');

    if (config.minRngVolume < min || config.minRngVolume > max) {
      return;
    }

    const minRngVolumeSP = manifest.getSystemParameter(
      'HANDSET_MINIMUM_RING_VOLUME'
    );

    if (minRngVolumeSP) {
      minRngVolumeSP.setFromNumber(config.minRngVolume);
      setDialogueText('Please wait.....');
      setSaving(true);
      setShowDialogue(true);

      const complete = (str: string) => {
        setDialogueText(str);
        setSaving(false);
      };

      let str = 'Save failed';
      manifest
        .save()
        .then((status) => {
          if (status === teErrorCode.E_OK) {
            str = 'Save Completed Successfully';
          } else if (status === teErrorCode.E_CONNECTION_FAIL) {
            str = 'Connection failed';
          }
          complete(str);
        })
        .catch((error) => {
          console.error('save fail:', error);
          complete(str);
        });
    }
  }

  const configChanged = () => {
    if (config.minRngVolume < min || config.minRngVolume > max) {
      return false;
    }

    return !_.isEqual(unchangedConfig, config);
  };

  const waitProps: Wait.DialogueProps = {
    show: showDialogue,
    dialogueText: dialogueText,
    showSpinner: saving,
    showOkButton: !saving,
    okButtonPress: () => {
      setShowDialogue(false);
    },
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={configChanged() ? 'brand' : 'grey-4'}
      badge={configChanged()}
      tip="Save changes"
      disabled={configChanged() ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Discard Changes',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('handsetSettings.discardChanges');
            updateInfo();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="Global Handset Settings"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={waitProps}
      ContextButtons={ContextButtons}
      menuProps={menuItems}
    >
      <>
        <p />
        {dataloaded && (
          <div>
            <p>Sets the minimum ringing volume for all care worker handsets.</p>
            <p>The volume range is 2 to 15, 15 been the loudest.</p>
            <Form
              value={config}
              onChange={(nextValue) => {
                setConfig(nextValue);
              }}
            >
              <Box direction="row" pad="xsmall" justify="center">
                <FormField
                  name="minRngVolume"
                  label="Minimum ringing volume"
                  direction="row"
                >
                  <Box width="110px">
                    <TextInput
                      name="minRngVolume"
                      type="number"
                      // Set this style to make the time icon white
                      style={{ colorScheme: 'dark' }}
                      min={min}
                      max={max}
                    />
                  </Box>
                </FormField>
              </Box>
            </Form>
          </div>
        )}
      </>
    </Tile>
  );
};

export default HandsetSettings;
