import React from 'react';

import * as UserUtils from 'common/userUtils';

const UserLevel = (props: {
  children: React.ReactNode;
  minUserLevel: UserUtils.UAG;
}): JSX.Element => {
  const allowed = UserUtils.minimumAccessLevel(props.minUserLevel);

  return <>{allowed && props.children}</>;
};

export default UserLevel;
