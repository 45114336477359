/** @module types */

import { Plan } from 'components/floorplan/plan';
import { Product } from 'components/floorplan/product';

/**
 * Plan
 * @interface
 */
export interface iPlan {
  /** friendly name of the plan */
  planName: string;
  /** Any brief notes for the plan */
  planNotes: string;
  /** The filename of the floor plan */
  planImageFileName: string;
  /** Plan image local URL */
  planImageURL: string;
  /** X offset */
  Xoffset: number;
  /** Y offset */
  Yoffset: number;
  /** Scale */
  scale: number;
  /** Equipment Table position X */
  equipX: number;
  /** Equipment Table position Y */
  equipY: number;
}

/**
 * Site
 * @interface
 */
export interface iSite {
  /** friendly name of the site */
  name: string;
  /** The URL of the floor plan */
  plans: Plan[];
  /** The equipment on the plan */
  equipment: Product[];
  /** has the site plan designed been modified since last save */
  modified?: number;
  /** last save */
  lastSaved?: string;
  /** saved by */
  savedByUser?: string;
  /** Quote Ref */
  quoteRef?: string;
  /** Revision */
  revision?: number;
}

/**
 * PlanMode
 * The mode that the plan editor is in.
 */
export enum PlanModeEnum {
  VIEW = 'VIEW', //View allows viewing only
  EDIT = 'EDIT', //Edit allows movement of units on plan
  DESIGN = 'DESIGN', //Design allows adding of new items and deletes etc
}

/**
 * Point
 * @interface
 */
export interface Point {
  /** the plan number this points belongs */
  plan: number;
  /** The x position */
  x: number;
  /** The y position */
  y: number;
}
