import React, { ReactElement, useState } from 'react';
import { Box, Button, Select, Text } from 'grommet';
import { iRoomForm } from './residentFormFields';
import {
  formatDateFromEpoch,
  formatDateWithSeconds,
  formatTimeOnlyHHMM,
  getDateFromEpoch,
} from 'common/utils/dateUtils';
import * as IoTTest from 'common/IoT/IoTTest';
import { useInterval } from 'hooks/useInterval';

const ResidentService = (props: {
  room: iRoomForm | undefined;
}): ReactElement => {
  const [, SetChildEvent] = useState('');
  const [time, setTime] = useState('20 minutes');
  const last =
    props.room && props.room.TestModeEnabledEPOCH != 0
      ? formatDateFromEpoch(props.room.TestModeEnabledEPOCH)
      : 'None';

  const epochNow = new Date(Date.now()).getTime() / 1000;

  const currently =
    props.room && props.room.TestModeEnabledEPOCH != 0
      ? props.room.TestModeEnabledEPOCH > epochNow
      : false;

  const timeOnly =
    props.room && props.room.TestModeEnabledEPOCH != 0
      ? formatTimeOnlyHHMM(getDateFromEpoch(props.room.TestModeEnabledEPOCH))
      : 'None';

  const id = props.room ? props.room.ID : -1;

  const text = currently
    ? `Currently in Service Test Mode until: ${timeOnly}`
    : `Last in Service Test Mode: ${last}`;

  // Update the status on a routine basis
  useInterval(() => {
    const ts = formatDateWithSeconds(new Date());
    SetChildEvent(ts);
  }, 1000);

  return (
    <>
      {id !== -1 && (
        <Box margin={{ top: '10px' }}>
          <Text weight="bold">{text}</Text>
          <Box direction="row" border="all" pad="10px" margin={{ top: '10px' }}>
            <Button
              margin={{ left: '35px', right: '35px', top: '10px' }}
              plain={false}
              size="small"
              label={currently ? 'Disable Test Mode' : 'Enable Test Mode'}
              onClick={async () => {
                const t = Number(time.split(' ')[0]) * 60;
                console.log(t);
                if (currently) {
                  await IoTTest.residentTestMode(id, false, 0);
                } else {
                  await IoTTest.residentTestMode(id, true, t);
                }
              }}
            />
            <Select
              size="medium"
              width="80px"
              plain={true}
              disabled={currently}
              options={[
                '5 minutes',
                '10 minutes',
                '15 minutes',
                '20 minutes',
                '30 minutes',
                '40 minutes',
                '50 minutes',
                '60 minutes',
              ]}
              value={time}
              onChange={({ value: nextValue }) => {
                setTime(nextValue);
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ResidentService;
