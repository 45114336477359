/** @module Installation */

import { iCareGroup, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';

/*
 * A Care Group aka Call Sequence
 * @class
 * @hideconstructor
 * @implements iCareGroup
 */

export class CareGroup implements iCareGroup {
  /** iCareGroup start */
  ID = invalidID;
  Description = '';
  CareSequenceId = invalidID;
  /** iCareGroup end */

  // Got software version incase we need to do processsing differently for
  // different software revsions in the future
  softwareVersion = 0;

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(
    values: iCareGroup,
    keys: string[],
    tableName: string,
    softwareVersion: number
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
  }

  setDescription(desc: string): void {
    this.Description = desc;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });

    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }
}
