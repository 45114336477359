import { iSelectKeyValue } from 'components/forms/formFields';

export enum SYSTEM_PARAMETER_DATA_TYPES {
  DATA_TYPE_INT8 = 0,
  DATA_TYPE_INT16,
  DATA_TYPE_INT32,
  DATA_TYPE_INT64,
  DATA_TYPE_DATE,
  DATA_TYPE_TEXT,
  DATA_TYPE_UINT8,
  DATA_TYPE_UINT16,
  DATA_TYPE_NULL,
  MAX_DATA_TYPES,
}

export enum SYSTEM_PARAMETER_ACCESS_TYPES {
  ACCESS_ROOT = 0,
  ACCESS_USER,
}

export enum teDEVICE_TYPES {
  DEVICE_TYPE_JENNET_COORDINATOR,
  DEVICE_TYPE_JENNET_ROUTER,
  DEVICE_TYPE_AUDIO_COPROCESSOR,
  DEVICE_TYPE_WARDEN_HANDSET,
  DEVICE_TYPE_PENDANT,
  DEVICE_TYPE_ROOM_UNIT,
  DEVICE_TYPE_GATEWAY,
  DEVICE_TYPE_AUDIO_ADAPTOR, // Audio co-processor attached to the gateway
  DEVICE_TYPE_APEX_DOOR_PANEL_AND_INTERFACES, // Apex Door Panel Controller
  DEVICE_TYPE_PULLCORD,
  DEVICE_TYPE_SMOKE,
  DEVICE_TYPE_DOOR_ENTRY,
  DEVICE_TYPE_WIM,
  DEVICE_TYPE_FALL,
  DEVICE_TYPE_MFAP_APPLICATION_PRO,
  DEVICE_TYPE_MFAP_AUDIO_COPROCESSOR,
  DEVICE_TYPE_VRU_APPLICATION_PRO,
  DEVICE_TYPE_PANIC,
  DEVICE_TYPE_SIP_DOOR,
  DEVICE_TYPE_FREE_19,
  DEVICE_TYPE_FREE_20,
  DEVICE_TYPE_FREE_21,
  DEVICE_TYPE_FREE_22,
  DEVICE_TYPE_FREE_23,
  DEVICE_TYPE_FREE_24,
  DEVICE_TYPE_FREE_25,
  DEVICE_TYPE_FREE_26,
  DEVICE_TYPE_FREE_27,
  DEVICE_TYPE_FREE_28,
  DEVICE_TYPE_FREE_29,
  DEVICE_TYPE_FREE_30,
  DEVICE_TYPE_FREE_31,
  DEVICE_TYPE_TUNSTALL_START = 32,
  DEVICE_TYPE_TUNSTALL_PERSONAL_TRIGGER = DEVICE_TYPE_TUNSTALL_START,
  DEVICE_TYPE_TUNSTALL_PERSONAL_AUTO_LB,
  DEVICE_TYPE_TUNSTALL_PERSONAL_AUTO_PRESENCE,
  DEVICE_TYPE_TUNSTALL_TEMP_EXTREME,
  DEVICE_TYPE_TUNSTALL_FLOOD,
  DEVICE_TYPE_TUNSTALL_CARBON_MONOX,
  DEVICE_TYPE_TUNSTALL_SMOKE,
  DEVICE_TYPE_TUNSTALL_DOOR_CONTACT,
  DEVICE_TYPE_TUNSTALL_PULLCORD,
  DEVICE_TYPE_TUNSTALL_ENURESIS,
  DEVICE_TYPE_TUNSTALL_BED_CHAIR,
  DEVICE_TYPE_TUNSTALL_MEDICATION_DISPENSER,
  DEVICE_TYPE_TUNSTALL_EPILEPSY,
  DEVICE_TYPE_TUNSTALL_WIRELESS_CALL_TERMINAL,
  DEVICE_TYPE_TUNSTALL_FALL,
  DEVICE_TYPE_TUNSTALL_PIR,
  DEVICE_TYPE_TUNSTALL_CARER,
  DEVICE_TYPE_TUNSTALL_PROPERTY_EXIT,
  DEVICE_TYPE_TUNSTALL_INTRUDER_ARMDISARM,
  DEVICE_TYPE_TUNSTALL_PANIC_BOGUSCALLER,
  DEVICE_TYPE_TUNSTALL_NATURAL_GAS,
  DEVICE_TYPE_TUNSTALL_END = DEVICE_TYPE_TUNSTALL_NATURAL_GAS,
  DEVICE_TYPE_MAX,
}

export enum teDEVICE_MODELS {
  DEVICE_MODEL_V1 = 0,
  DEVICE_MODEL_V2,
  DEVICE_MODEL_V3,
  DEVICE_MODEL_V4,
  DEVICE_MODEL_MAX,
}

export enum teDEVICE_MODELS_WIM {
  DEVICE_WIM_MODEL_2_4GHZ = 0,
  DEVICE_WIM_MODEL_UNIVERSAL_869 = 1,
  DEVICE_WIM_MODEL_UNIVERSAL_EPILEPSY,
  DEVICE_WIM_MODEL_MAX,
}

export enum teDEVICE_MODELS_ROOM_UNIT {
  DEVICE_MODEL_ENTROTEC = 0,
  DEVICE_MODEL_AUDIO_ONLY,
  DEVICE_MODEL_VRU,
  DEVICE_MODEL_ROOM_MAX,
}

export enum teDEVICE_MODELS_ROUTER {
  DEVICE_MODEL_PRE_OMIVIA = 0,
  DEVICE_MODEL_MAP,
  DEVICE_MODEL_MLS,
  DEVICE_MODEL_ROUTER_MAX,
}

export enum teDEVICE_MODELS_DOOR_AND_DEVICE_INTERFACES {
  DEVICE_MODEL_PRE_OMIVIA_LIFT_INTERFACE = teDEVICE_MODELS.DEVICE_MODEL_V1,
  DEVICE_MODEL_PRE_OMIVIA_DOOR_INTERFACE = teDEVICE_MODELS.DEVICE_MODEL_V2,
  DEVICE_MODEL_MAI = teDEVICE_MODELS.DEVICE_MODEL_V3, // Use the personality to determine the type (teDEVICE_PERSONALITY)
  DEVICE_MODEL_DOORINTERFACE_MAX,
}

export enum teDEVICE_MODELS_HANDSET {
  DEVIE_HANDSET_MODEL_OBSOLETE = teDEVICE_MODELS.DEVICE_MODEL_V1,
  DEVIE_HANDSET_MODEL_EVOLUTION = teDEVICE_MODELS.DEVICE_MODEL_V2,
  DEVIE_HANDSET_MODEL_OMNIVIA_WIFI = teDEVICE_MODELS.DEVICE_MODEL_V3,
  DEVIE_HANDSET_MODEL_OMNIVIA_802_15_5 = teDEVICE_MODELS.DEVICE_MODEL_V4,
}

export enum teDEVICE_PERSONALITY {
  DEVICE_PERSONALITY_NOT_USED = 0,
  DEVICE_PERSONALITY_MLI, // Lift Interface
  DEVICE_PERSONALITY_MDI, // Door Interface
  DEVICE_PERSONALITY_MCI, // One button call point interface
  DEVICE_PERSONALITY_GWI, // Gateway Interface
  DEVICE_PERSONALITY_MPI, // Pull Cord Interface
  DEVICE_PERSONALITY_MAX,
}

export enum teDESTINATION_TYPES {
  DESTINATION_TYPE_HANDSET = 0, //!< One or more wireless warden handset units
  DESTINATION_TYPE_VOIP, //!< One or more VoIP telephone(s) dialled through Asterisk
  DESTINATION_TYPE_POTS, //!< One POTS telephone number
  DESTINATION_TYPE_CC_IP, //!< One control centre via a IP
  DESTINATION_TYPE_CC_DTMF, //!< One control centre via a POTS telephone line
  DESTINATION_TYPE_EXTERNAL, //!< One external scheme (phase 2 only)
  DESTINATION_TYPE_RESIDENT, //!< One room unit (endpoint for Door Panel calls)
  DESTINATION_TYPE_HS_TO_HS, //!< Also encapsulates source information, but never mind
  DESTINATION_TYPE_HS_TO_POTS, //!< Also encapsulates source information, but never mind
  DESTINATION_TYPE_POTS_TO_HS, //!< Also encapsulates source information, but never mind
  DESTINATION_TYPE_CC_IP_BS8521, //!< One control centre via a BS8521-2 IP signalling
  DESTINATION_TYPE_MAX,
}

export enum teFeatureBits {
  HANDSET_FEATURE_BIT_V2_ALARM_NOTIFY = 0,
  HANDSET_FEATURE_BIT_V2_AUDIO_OPEN,
  HANDSET_FEATURE_BITS,
}

/**
 * Relay mode to set room terminal into when a call is placed
 * Types 1,2 available for social calls, alarm calls and door panel calls
 * Types 3,4 available only for alarm and door panel calls
 */
export enum teRELAY_MODE {
  RELAY_MODE_IGNORE = 0, //!< Placeholder to push NO_CHANGE to value 1
  RELAY_MODE_NO_CHANGE, //!< Don't do any relay actions on call events
  RELAY_MODE_DURING_CALL, //!< From call connected to call cleared
  RELAY_MODE_DURING_ANSWER_WAIT, //!< From alarm raised / door panel ringing to call answered / timed-out
  RELAY_MODE_DURING_ALARM, //!< From alarm raised to call cleared
}

export enum teRU_NOTIFY_FLAG_BITS { //NB if changing (see also teRESIDENT_HARDWIRED_FLAG_BITS in message type list)
  RU_NOTIFY_FLAG_BIT_INACTIVITY = 0, //!< The alarm indicates inactivity
  RU_NOTIFY_FLAG_BIT_INTRUDER = 1, //!< The alarm indicates intruder
  RU_NOTIFY_FLAG_BIT_ENTRY = 2, //!< The alarm indicates entry (intruder should also be set)
  RU_NOTIFY_FLAG_BIT_POLARITY = 3, //!< Polarity of the alarm input trigger (just storage, used elsewhere)
  RU_NOTIFY_FLAG_BIT_MAX = 4,
}

export const PERSONALITY_OPTIONS: { value: number; text: string }[] = [
  {
    text: 'Not set',
    value: teDEVICE_PERSONALITY.DEVICE_PERSONALITY_NOT_USED,
  }, // Lift Interface
  {
    text: 'Lift interface',
    value: teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MLI,
  }, // Lift Interface
  {
    text: 'Door interface',
    value: teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MDI,
  }, // Door Interface
  {
    text: 'One button call point interface',
    value: teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MCI,
  }, // One button call point interface
  {
    text: 'Pullcord interface',
    value: teDEVICE_PERSONALITY.DEVICE_PERSONALITY_MPI,
  }, // Pull Cord Interface
  {
    text: 'Node 0',
    value: teDEVICE_PERSONALITY.DEVICE_PERSONALITY_GWI,
  }, // Pull Cord Interface
];

export enum teSPEECH_CONFIG {
  SPEECH_CFG_NO_OVERIDES = 0x00,
  SPEECH_CFG_NON_SPEECH = 0x01,
  SPEECH_CFG_NON_SPEECH_NO_ALERT = 0x02,
  SPEECH_CFG_NON_SPEECH_MAX,
}

export enum teSCHEDULES_OBJECT_TYPES {
  SCHEDULES_OBJECT_TYPE_ROUTING_EVENT = 0,
  SCHEDULES_OBJECT_TYPE_CARE_SEQUENCE_ENTRY,
  SCHEDULES_OBJECT_TYPE_RESIDENT_OK,
  SCHEDULES_OBJECT_TYPE_TRADES_ACCESS,
  SCHEDULES_OBJECT_TYPE_INACTIVITY,
  SCHEDULES_OBJECT_TYPE_MAX,
}

export enum teBSI_LOCATIONS {
  LOCATION_NO_LOCATION_INFORMATION = 0,
  LOCATION_LOCAL_UNIT = 1,
  LOCATION_HALLWAY_DOWNSTAIRS = 2,
  LOCATION_HALLWAY_UPSTAIRS = 3,
  LOCATION_STAIRS_MAIN = 4,
  LOCATION_STAIRS_OTHER = 5,
  LOCATION_LANDING = 6,
  LOCATION_BEDROOM_1_MASTER = 7,
  LOCATION_BEDROOM_2 = 8,
  LOCATION_BEDROOM_3_OTHER = 9,
  LOCATION_BEDROOM_4_GUEST = 10,
  LOCATION_LIVING_ROOM_MAIN = 11,
  LOCATION_LIVING_ROOM_SECOND = 12,
  LOCATION_LIVING_AREA_OTHER = 13,
  LOCATION_DINING_ROOM_MAIN = 14,
  LOCATION_DINING_ROOM_SECOND = 15,
  LOCATION_DINING_AREA_OTHER = 16,
  LOCATION_BATHROOM_MAIN = 17,
  LOCATION_BATHROOM_SECOND = 18,
  LOCATION_WC_TOILET_UPSTAIRS = 19,
  LOCATION_WC_TOILET_DOWNSTAIRS = 20,
  LOCATION_WC_TOILET_OTHER = 21,
  LOCATION_KITCHEN_MAIN = 22,
  LOCATION_KITCHEN_SECOND = 23,
  LOCATION_KITCHEN_AREA_OTHER = 24,
  LOCATION_UTILITY_ROOM_MAIN = 25,
  LOCATION_UTILITY_ROOM_OTHER = 26,
  LOCATION_ENTRANCE_LOBBY = 27,
  LOCATION_FRONT_DOOR_MAIN = 28,
  LOCATION_FRONT_DOOR_OTHER = 29,
  LOCATION_BACK_DOOR_MAIN = 30,
  LOCATION_BACK_DOOR_OTHER = 31,
  LOCATION_GARAGE_MAIN = 32,
  LOCATION_GARAGE_OTHER = 33,
  LOCATION_WORKSHOP = 34,
  LOCATION_LAUNDRY_MAIN = 35,
  LOCATION_LAUNDRY_OTHER = 36,
  LOCATION_OFFICE_MAIN = 37,
  LOCATION_STUDY_OFFICE_OTHER = 38,
  LOCATION_GAMES_ROOM = 39,
  LOCATION_COMMON_ROOM_MAIN = 40,
  LOCATION_COMMON_ROOM_OTHER = 41,
  LOCATION_LIFT_MAIN = 42,
  LOCATION_LIFT_SECOND = 43,
  LOCATION_LIFT_OTHER = 44,
  LOCATION_FRONT_GATE = 45,
  LOCATION_REAR_GATE = 46,
  LOCATION_OUTBUILDING_MAIN = 47,
  LOCATION_SHED = 48,
  LOCATION_OUTBUILDING_SHED_OTHER = 49,
  LOCATION_GARDEN_FRONT = 50,
  LOCATION_GARDEN_REAR = 51,
  LOCATION_GARDEN_OTHER = 52,
  LOCATION_BASEMENT_CELLAR = 53,
  LOCATION_GROUND_FLOOR = 54,
  LOCATION_BIN_STORE = 55,
  LOCATION_BOILER_ROOM = 56,
  LOCATION_ATTIC = 57,
  LOCATION_RESERVED_BSI_START = 58,
  LOCATION_RESERVED_BSI_END = 79,
  LOCATION_PROPRIETARY_START = 80,
  LOCATION_PROPRIETARY_END = 99,
}

export const asLocationCodeStrings: string[] = [
  'No location information',
  'Local unit',
  'Hallway (downstairs)',
  'Hallway (upstairs)',
  'Stairs (main)',
  'Stairs (other)',
  'Landing',
  'Bedroom 1 (master)',
  'Bedroom 2',
  'Bedroom 3 (other)',
  'Bedroom 4 (guest)',
  'Living room (main)',
  'Living room (second)',
  'Living area (other)',
  'Dining room (main)',
  'Dining room (second)',
  'Dining area (other)',
  'Bathroom (main)',
  'Bathroom (second)',
  'WC/toilet (upstairs)',
  'WC/toilet (downstairs)',
  'WC/toilet (other)',
  'Kitchen (main)',
  'Kitchen (second)',
  'Kitchen area (other)',
  'Utility room (main)',
  'Utility room (other)',
  'Entrance/lobby',
  'Front door (main)',
  'Front door (other)',
  'Back door (main)',
  'Back door (other)',
  'Garage (main)',
  'Garage (other)',
  'Workshop',
  'Laundry (main)',
  'Laundry (other)',
  'Office (main)',
  'Study/office (other)',
  'Games room',
  'Common room (main)',
  'Common room (other)',
  'Lift (main)',
  'Lift (second)',
  'Lift (other)',
  'Front gate',
  'Rear gate',
  'Outbuilding (main)',
  'Shed',
  'Outbuilding/shed (other)',
  'Garden (front)',
  'Garden (rear)',
  'Garden (other)',
  'Basement/cellar',
  'Ground floor',
  'Bin store',
  'Boiler room',
  'Attic',
];

export const locations_keyvalues = (): iSelectKeyValue[] => {
  const array: iSelectKeyValue[] = asLocationCodeStrings.map((a, index) => {
    return { key: index.toString(), value: a };
  });
  return array;
};
