/** @module Installation */

import { iEndpointCCDTMF, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';

/*
 * ARC POTS telephone numbers
 * @class
 * @hideconstructor
 * @implements iEndpointCCDTMF
 */

export class EndpointCCDTMF implements iEndpointCCDTMF {
  /** iEndpointCCDTMF start */
  ID = invalidID;
  Description = '';
  Number = '';
  Line = 1;
  InUse = 0;
  LoggedOn = 0;
  /** iEndpointCCDTMF end */

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  softwareVersion = 0;

  constructor(
    values: iEndpointCCDTMF,
    keys: string[],
    tableName: string,
    softwareVersion: number
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
    //this.Number = this.Number ? this.Number : '';
    //if (this.Number == 'null') this.Number = '';
  }

  static getTelephoneNumber(number: string | null): string {
    if (number) {
      if (number.charAt(number.length - 1) === 'w') {
        number = number.slice(0, -1);
      }
    } else {
      number = '';
    }
    return number;
  }

  setTelephoneNumber(newNumber: string): void {
    if (newNumber.length > 0) {
      if (newNumber.charAt(newNumber.length - 1) !== 'w') {
        newNumber += 'w';
      }
    }
    this.Number = newNumber;

    console.info('setTelephoneNumber line:', this.Line);

    // validate the telephone line
    if (this.Line === 0 || this.Line === null) {
      this.Line = 1;
    }
  }

  setDescription(desc: string): void {
    this.Description = desc;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });

    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }
}
