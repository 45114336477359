// You can pass this object to the `theme` property
export const nivoChartTheme = {
  'background': '#111111',
  'textColor': '#dddddd',
  'fontSize': 11,
  'axis': {
    'domain': {
      'line': {
        'stroke': '#777777',
        'strokeWidth': 1,
      },
    },
    'legend': {
      'text': {
        'fontSize': 12,
        'fill': '#dddddd',
      },
    },
    'ticks': {
      'line': {
        'stroke': '#777777',
        'strokeWidth': 1,
      },
      'text': {
        'fontSize': 11,
        'fill': '#dddddd',
      },
    },
  },
  'grid': {
    'line': {
      'stroke': '#dddddd',
      'strokeWidth': 1,
    },
  },
  'legends': {
    'title': {
      'text': {
        'fontSize': 11,
        'fill': '#dddddd',
      },
    },
    'text': {
      'fontSize': 11,
      'fill': '#dddddd',
    },
    'ticks': {
      'line': {},
      'text': {
        'fontSize': 10,
        'fill': '#dddddd',
      },
    },
  },
  'annotations': {
    'text': {
      'fontSize': 13,
      'fill': '#dddddd',
      'outlineWidth': 2,
      'outlineColor': '#ffffff',
      'outlineOpacity': 1,
    },
    'link': {
      'stroke': '#000000',
      'strokeWidth': 1,
      'outlineWidth': 2,
      'outlineColor': '#ffffff',
      'outlineOpacity': 1,
    },
    'outline': {
      'stroke': '#000000',
      'strokeWidth': 2,
      'outlineWidth': 2,
      'outlineColor': '#ffffff',
      'outlineOpacity': 1,
    },
    'symbol': {
      'fill': '#000000',
      'outlineWidth': 2,
      'outlineColor': '#ffffff',
      'outlineOpacity': 1,
    },
  },
  'tooltip': {
    'container': {
      'background': '#ffffff',
      'color': '#dddddd',
      'fontSize': 12,
    },
    'basic': {},
    'chip': {},
    'table': {},
    'tableCell': {},
    'tableCellValue': {},
  },
};
