import * as UserUtils from 'common/userUtils';

export interface iSystemParameterDefinition {
  ID: number;
  Name: string;
  Description: string;
  DefaultValue: string;
  minValue?: number;
  maxValue?: number;
  allowedValues?: string[];
  DataType: number;
  AccessLevel: UserUtils.UAG;
  Categroy: string;
  ShortName: string;
}

export enum eDataTypes {
  DATA_TYPE_INT8 = 0,
  DATA_TYPE_INT16,
  DATA_TYPE_INT32,
  DATA_TYPE_INT64,
  DATA_TYPE_DATE,
  DATA_TYPE_TEXT,
  DATA_TYPE_UINT8,
  DATA_TYPE_UINT16,
  DATA_TYPE_NULL,
}

export const dataTypeIsANumber = (dt: eDataTypes): boolean => {
  if (dt >= eDataTypes.DATA_TYPE_INT8 && dt <= eDataTypes.DATA_TYPE_INT64)
    return true;
  if (dt >= eDataTypes.DATA_TYPE_UINT8 && dt <= eDataTypes.DATA_TYPE_UINT16)
    return true;
  return false;
};

// TODO:  Still need to define Min and MAx for admin on parameters
const jsonString = `[
   {
     "ID": 0,
     "Name": "JIF_SERIAL_DEVICE",
     "DefaultValue": "/dev/ttyUSB",
     "Description": "Base name for Jennic Interface ports",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "JIFF Serial Device",
     "Category": "Serial"
   },
   {
     "ID": 1,
     "Name": "JIF_SERIAL_BAUD",
     "DefaultValue": "7",
     "Description": "Baud rate for Jennic Interface ports",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "JIFF Serial Baud",
     "Category": "Serial"
   },
   {
     "ID": 2,
     "Name": "MQTT_HOST",
     "DefaultValue": "localhost",
     "Description": "Default host name of MQTT broker",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "MQTT Host",
     "Category": "MQTT"
   },
   {
     "ID": 3,
     "Name": "MQTT_PORT",
     "DefaultValue": "1883",
     "Description": "Default port to connect to MQTT broker on",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "MQTT Port",
     "Category": "MQTT"
   },
   {
     "ID": 4,
     "Name": "MQTT_TIMEOUT",
     "DefaultValue": "60",
     "Description": "Timeout between keep-alive messages sent to broker",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "MQTT Timeout",
     "Category": "MQTT"
   },
   {
     "ID": 5,
     "Name": "MQTT_QOS",
     "DefaultValue": "2",
     "Description": "Default MQTT quality of service to use",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "MQTT Qos",
     "Category": "MQTT"
   },
   {
     "ID": 6,
     "Name": "AC_WAIT_RESPONSE",
     "DefaultValue": "1000",
     "Description": "Time to wait for an ACK to an Audio Controller message",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Audio Timeout",
     "Category": "Audio"
   },
   {
     "ID": 7,
     "Name": "AC_RETRY_ATTEMPTS",
     "DefaultValue": "10",
     "Description": "Number of retries for an Audio Controller message",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Audio Retries",
     "Category": "Audio"
   },
   {
     "ID": 8,
     "Name": "DISCO_TX_TIMEOUT",
     "DefaultValue": "4000",
     "Description": "Time to wait before re-sending a Tx command (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Disco Tx Timeout",
     "Category": "Audio"
   },
   {
     "ID": 9,
     "Name": "DISCO_RX_TIMEOUT",
     "DefaultValue": "4000",
     "Description": "Time to wait before re-sending an Rx command (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Disco Rx Timeout",
     "Category": "Audio"
   },
   {
     "ID": 10,
     "Name": "DISCO_MAX_RETRIES",
     "DefaultValue": "3",
     "Description": "Maximum retries to get a message through",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Max Neigh Retries",
     "Category": "Audio"
   },
   {
     "ID": 11,
     "Name": "DISCO_HOUR",
     "DefaultValue": "1",
     "Description": "Hour for discovery to take place every day (0-23)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Discovery Hour",
     "Category": "Audio"
   },
   {
     "ID": 12,
     "Name": "DISCO_MIN",
     "DefaultValue": "0",
     "Description": "Minute for discovery to take place (0-59)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Discovery Min",
     "Category": "Audio"
   },
   {
     "ID": 13,
     "Name": "FXO_FAILS_BEFORE_ALARM",
     "DefaultValue": "20",
     "Description": "FXO fail count before alarm.  Tested every 5 seconds. A value of 0 disables any monitoring",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "FXO Fail count before alarm",
     "Category": "System"
   },
   {
     "ID": 14,
     "Name": "AUDIT_FILENAME",
     "DefaultValue": "Audit.log",
     "Description": "Audit log filename",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Audit Filename",
     "Category": "Audit"
   },
   {
     "ID": 15,
     "Name": "INTER_POLL_POLL_TIME",
     "DefaultValue": "20",
     "Description": "Time between device polling loops (min)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Poll Period",
     "Category": "System Poll"
   },
   {
     "ID": 16,
     "Name": "INTER_DEVICE_POLL_TIME",
     "DefaultValue": "5000",
     "Description": "Time between polling each device when polling (mS)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inter Device Period",
     "Category": "System Poll"
   },
   {
     "ID": 17,
     "Name": "MAX_MISSED_POLLS",
     "DefaultValue": "12",
     "Description": "Maximum polls a device can miss before an alarm is raised",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Max Missed Polls",
     "Category": "System Poll"
   },
   {
     "ID": 18,
     "Name": "MAX_MISSED_BATT_POLLS",
     "DefaultValue": "12",
     "Description": "Maximum status messages from a battery device that can be missed before an alarm is raised",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Max Missed Battery",
     "Category": "System Poll"
   },
   {
     "ID": 19,
     "Name": "INTRUDER_EXIT_TIMEOUT",
     "DefaultValue": "30",
     "Description": "Time exit tones will play on a room unit before intruder monitoring is active (S)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Exit Timeout",
     "Category": "Intruder"
   },
   {
     "ID": 20,
     "Name": "INTRUDER_ENTRY_TIMEOUT",
     "DefaultValue": "30",
     "Description": "Time entry tones will play on a room unit before an intruder alarm is raised (S)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Entry Timeout",
     "Category": "Intruder"
   },
   {
     "ID": 21,
     "Name": "INACTIVITY_TIMEOUT",
     "DefaultValue": "600",
     "Description": "Time for which a resident may be inactive before an alarm will be raised (S)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Timeout",
     "Category": "Inactivity"
   },
   {
     "ID": 22,
     "Name": "INACTIVITY_ON_HOUR",
     "DefaultValue": "10",
     "Description": "Hour of day when inactivity monitoring will begin",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. On Hour",
     "Category": "Inactivity"
   },
   {
     "ID": 23,
     "Name": "INACTIVITY_ON_MINUTE",
     "DefaultValue": "0",
     "Description": "Minute of hour when inactivity monitoring will begin",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. On Min",
     "Category": "Inactivity"
   },
   {
     "ID": 24,
     "Name": "INACTIVITY_OFF_HOUR",
     "DefaultValue": "20",
     "Description": "Hour of day when inactivity monitoring will cease",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Off Hour",
     "Category": "Inactivity"
   },
   {
     "ID": 25,
     "Name": "INACTIVITY_OFF_MINUTE",
     "DefaultValue": "0",
     "Description": "Minute of hour when inactivity monitoring will cease",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Off Min",
     "Category": "Inactivity"
   },
   {
     "ID": 26,
     "Name": "INACTIVITY_MONDAY",
     "DefaultValue": "0",
     "Description": "Will inactivity be monitored on Monday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Monday",
     "Category": "Inactivity"
   },
   {
     "ID": 27,
     "Name": "INACTIVITY_TUESDAY",
     "DefaultValue": "0",
     "Description": "Will inactivity be monitored on Tuesday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Tuesday",
     "Category": "Inactivity"
   },
   {
     "ID": 28,
     "Name": "INACTIVITY_WEDNESDAY",
     "DefaultValue": "0",
     "Description": "Will inactivity be monitored on Wednesday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Wednesday",
     "Category": "Inactivity"
   },
   {
     "ID": 29,
     "Name": "INACTIVITY_THURSDAY",
     "DefaultValue": "0",
     "Description": "Will inactivity be monitored on Thursday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Thursday",
     "Category": "Inactivity"
   },
   {
     "ID": 30,
     "Name": "INACTIVITY_FRIDAY",
     "DefaultValue": "0",
     "Description": "Will inactivity be monitored on Friday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Friday",
     "Category": "Inactivity"
   },
   {
     "ID": 31,
     "Name": "INACTIVITY_SATURDAY",
     "DefaultValue": "0",
     "Description": "Will inactivity be monitored on Saturday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Saturday",
     "Category": "Inactivity"
   },
   {
     "ID": 32,
     "Name": "INACTIVITY_SUNDAY",
     "DefaultValue": "0",
     "Description": "Will inactivity be monitored on Sunday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Inact. Sunday",
     "Category": "Inactivity"
   },
   {
     "ID": 33,
     "Name": "DB_BACKUP_HOUR",
     "DefaultValue": "3",
     "Description": "Hour of day to do DB backup to SD card and persistent storage",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "DB Backup Hour",
     "Category": "System"
   },
   {
     "ID": 34,
     "Name": "DB_BACKUP_MINUTE",
     "DefaultValue": "0",
     "Description": "Minute of day to do DB Backup to SD card and persistent storage",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "DB Backup Minute",
     "Category": "System"
   },
   {
     "ID": 35,
     "Name": "PRIVACY_TIMEOUT",
     "DefaultValue": "0",
     "Description": "Time privacy will remain on for (0 = manual on/off) (S)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Privacy Timeout",
     "Category": "Privacy"
   },
   {
     "ID": 36,
     "Name": "VOLUME_LEVEL_1",
     "DefaultValue": "-24",
     "Description": "The volume used when a device is set to volume level 1",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume 1",
     "Category": "Volume"
   },
   {
     "ID": 37,
     "Name": "VOLUME_LEVEL_2",
     "DefaultValue": "-18",
     "Description": "The volume used when a device is set to volume level 2",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume 2",
     "Category": "Volume"
   },
   {
     "ID": 38,
     "Name": "VOLUME_LEVEL_3",
     "DefaultValue": "-12",
     "Description": "The volume used when a device is set to volume level 3",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume 3",
     "Category": "Volume"
   },
   {
     "ID": 39,
     "Name": "VOLUME_LEVEL_4",
     "DefaultValue": "-6",
     "Description": "The volume used when a device is set to volume level 4",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume 4",
     "Category": "Volume"
   },
   {
     "ID": 40,
     "Name": "VOLUME_LEVEL_5",
     "DefaultValue": "0",
     "Description": "The volume used when a device is set to volume level 5",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume 5",
     "Category": "Volume"
   },
   {
     "ID": 41,
     "Name": "PCA_INVITATION_TIMEOUT",
     "DefaultValue": "60000",
     "Description": "Invitation wait timeout (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "PCA Invite Time",
     "Category": "Protocols"
   },
   {
     "ID": 42,
     "Name": "PCA_JUNK_TONE_FREQUENCY",
     "DefaultValue": "1850",
     "Description": "Junk Tone frequency",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Junk Tone Freq",
     "Category": "Protocols"
   },
   {
     "ID": 43,
     "Name": "PCA_JUNK_TONE_ON",
     "DefaultValue": "250",
     "Description": "Junk tone on time (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Junk Tone On",
     "Category": "Protocols"
   },
   {
     "ID": 44,
     "Name": "PCA_JUNK_TONE_OFF",
     "DefaultValue": "1750",
     "Description": "Junk tone off time (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Junk Tone Off",
     "Category": "Protocols"
   },
   {
     "ID": 45,
     "Name": "PCA_BSI_ENABLED",
     "DefaultValue": "1",
     "Description": "BSI protocol enabled (0 = No, 1 = Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "BSI Enabled",
     "Category": "Protocols"
   },
   {
     "ID": 46,
     "Name": "BSI_SCHEME_ID",
     "DefaultValue": "9999",
     "Description": "Scheme ID for BSI communications",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "BSI Scheme ID",
     "Category": "Protocols BSI",
	 "minValue":0,
	 "maxValue":9999
   },
   {
     "ID": 47,
     "Name": "BSI_WAIT_BEFORE_SEND",
     "DefaultValue": "80",
     "Description": "BSI delay before sending responses (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "BSI Send Wait",
     "Category": "Protocols BSI"
   },
   {
     "ID": 48,
     "Name": "BSI_RESPONSE_WAIT",
     "DefaultValue": "1200",
     "Description": "BSI ACK response wait timeout (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "BSI ACK Wait",
     "Category": "Protocols BSI"
   },
   {
     "ID": 49,
     "Name": "BSI_KEEP_ONLINE_TIMEOUT",
     "DefaultValue": "300000",
     "Description": "BSI Keep online timeout (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "BSI Keep Online",
     "Category": "Protocols BSI"
   },
   {
     "ID": 50,
     "Name": "LINE_1_PREFIX",
     "DefaultValue": "101",
     "Description": "Prefix to tell Asterisk to dial this number on line 1",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Line 1 Prefix",
     "Category": "Asterisk"
   },
   {
     "ID": 51,
     "Name": "LINE_2_PREFIX",
     "DefaultValue": "201",
     "Description": "Prefix to tell Asterisk to dial this number on line 2",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Line 2 Prefix",
     "Category": "Asterisk"
   },
   {
     "ID": 52,
     "Name": "ASTERISK_CONTEXT",
     "DefaultValue": "from-internal",
     "Description": "Context to place Asterisk calls out of",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Asterisk Context",
     "Category": "Asterisk"
   },
   {
     "ID": 53,
     "Name": "NETWORK_UID",
     "DefaultValue": "65535",
     "Description": "UID to identify this network",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Network UID",
     "Category": "System"
   },
   {
     "ID": 54,
     "Name": "TRADESMAN_PIN",
     "DefaultValue": "5654",
     "Description": "Tradesman PIN code (2-6 numeric characters)",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Tradesman PIN",
     "Category": "Door Panel"
   },
   {
     "ID": 55,
     "Name": "TRADESMAN_ON_HOUR",
     "DefaultValue": "9",
     "Description": "Hour of day when tradesman entry is allowed",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman On Hour",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 23
   },
   {
     "ID": 56,
     "Name": "TRADESMAN_ON_MINUTE",
     "DefaultValue": "0",
     "Description": "Minute of hour when tradesman entry is allowed",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman On Min",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 59
   },
   {
     "ID": 57,
     "Name": "TRADESMAN_OFF_HOUR",
     "DefaultValue": "17",
     "Description": "Hour of day when tradesman entry will cease",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Off Hour",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 23
   },
   {
     "ID": 58,
     "Name": "TRADESMAN_OFF_MINUTE",
     "DefaultValue": "0",
     "Description": "Minute of hour when tradesman entry will cease",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Off Min",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 59
   },
   {
     "ID": 59,
     "Name": "TRADESMAN_SUNDAY",
     "DefaultValue": "0",
     "Description": "Is tradesman entry allowed on Sunday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Sunday",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 60,
     "Name": "TRADESMAN_MONDAY",
     "DefaultValue": "1",
     "Description": "Is tradesman entry allowed on Monday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Monday",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 61,
     "Name": "TRADESMAN_TUESDAY",
     "DefaultValue": "1",
     "Description": "Is tradesman entry allowed on Tuesday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Tuesday",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 62,
     "Name": "TRADESMAN_WEDNESDAY",
     "DefaultValue": "1",
     "Description": "Is tradesman entry allowed on Wednesday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Wednesday",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 63,
     "Name": "TRADESMAN_THURSDAY",
     "DefaultValue": "1",
     "Description": "Is tradesman entry allowed on Thursday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Thursday",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 64,
     "Name": "TRADESMAN_FRIDAY",
     "DefaultValue": "1",
     "Description": "Is tradesman entry allowed on Friday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Friday",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 65,
     "Name": "TRADESMAN_SATURDAY",
     "DefaultValue": "0",
     "Description": "Is tradesman entry allowed on Saturday (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Tradesman Saturday",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 66,
     "Name": "DOOR_PANEL_RING_TIMEOUT",
     "DefaultValue": "180",
     "Description": "Timeout in seconds for ringing a resident or handset unit from a Door Panel",
     "DataType": 2,
     "AccessLevel": "manager",
     "ShortName": "DP Ring Timeout",
     "Category": "Door Panel",
     "minValue": 30,
     "maxValue": 300
   },
   {
     "ID": 67,
     "Name": "VOX_DP_PBX_THRESH",
     "DefaultValue": "40",
     "Description": "DP to PBX VOX noise threshold",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "DP-PBX Thresh",
     "Category": "VOX"
   },
   {
     "ID": 68,
     "Name": "VOX_DP_HU_THRESH",
     "DefaultValue": "40",
     "Description": "DP to Handset VOX noise threshold",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "DP-HU Thresh",
     "Category": "VOX"
   },
   {
     "ID": 69,
     "Name": "NOTIFY_RESEND_TIMEOUT",
     "DefaultValue": "5",
     "Description": "Time between resends of HU notify message (s)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "HU notify resend",
     "Category": "Alarm Handler"
   },
   {
     "ID": 70,
     "Name": "ATTEMPT_TIMEOUT",
     "DefaultValue": "180",
     "Description": "Time to wait for handset alarm selection before trying next handler (s)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "HS selection wait timeout",
     "Category": "Alarm Handler",
	 "minValue":30,
	 "maxValue":360
   },
   {
     "ID": 71,
     "Name": "AUDIO_RESPONSE_TIMEOUT",
     "DefaultValue": "5",
     "Description": "Time to wait for Audio link response message (s)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Audio response wait",
     "Category": "Alarm Handler"
   },
   {
     "ID": 72,
     "Name": "AUDIO_OK_TIMEOUT",
     "DefaultValue": "120",
     "Description": "Time without audio OK message before quitting call (s)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Audio ok wait",
     "Category": "Alarm Handler"
   },
   {
     "ID": 73,
     "Name": "CC_RX_VOLUME",
     "DefaultValue": "0",
     "Description": "Default value to use for Asterisk Rx volume to CC",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "CC Rx Volume",
     "Category": "Asterisk"
   },
   {
     "ID": 74,
     "Name": "CC_TX_VOLUME",
     "DefaultValue": "0",
     "Description": "Default value to use for Asterisk Tx volume to CC",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "CC Tx Volume",
     "Category": "Asterisk"
   },
   {
     "ID": 75,
     "Name": "RSSI_THRESHOLD",
     "DefaultValue": "40",
     "Description": "Minimum acceptable RSSI for audio routing",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "RSSI Threshold",
     "Category": "Audio"
   },
   {
     "ID": 76,
     "Name": "RU_PTT_FOR_HANDSET",
     "DefaultValue": "0",
     "Description": "Open audio from room unit to handset in PTT (1) or VOX (0)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "RU Handset PTT",
     "Category": "Audio"
   },
   {
     "ID": 77,
     "Name": "RU_PTT_FOR_PBX",
     "DefaultValue": "0",
     "Description": "Open audio from room unit to control centre in PTT (1) or VOX (0)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "RU PBX PTT",
     "Category": "Audio",
	 "minValue":0,
	 "maxValue":1
   },
   {
     "ID": 78,
     "Name": "RX_ALL_SLOTS",
     "DefaultValue": "1",
     "Description": "Set to 1 to allow packet receipt on any timeslot",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Rx all slots",
     "Category": "Audio"
   },
   {
     "ID": 79,
     "Name": "DP_PTT_FOR_HANDSET",
     "DefaultValue": "0",
     "Description": "Open audio from door panel to handset in PTT (1) or VOX (0)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "DP Handset PTT",
     "Category": "Audio",
	 "minValue":0,
	 "maxValue":1
   },
   {
     "ID": 80,
     "Name": "DP_PTT_FOR_PBX",
     "DefaultValue": "0",
     "Description": "Open audio from door panel to control centre in PTT (1) or VOX (0)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "DP PBX PTT",
     "Category": "Audio",
	 "minValue":0,
	 "maxValue":1
   },
   {
     "ID": 81,
     "Name": "BSI_DOOR_UNLOCK_CODE",
     "DefaultValue": "4",
     "Description": "Code upon which to issue a door unlock command",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "BSI Door Unlock",
     "Category": "Protocols BSI",
	 "minValue":0,
	 "maxValue":99
   },
   {
     "ID": 82,
     "Name": "BSI_WAIT_FOR_CLEAR_ACK",
     "DefaultValue": "1",
     "Description": "Wait for an ACK to the clear speech response",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "BSI Clear ACK",
     "Category": "Protocols BSI",
	 "minValue":0,
	 "maxValue":1
   },
   {
     "ID": 83,
     "Name": "VOX_RU_PBX_THRESH",
     "DefaultValue": "30",
     "Description": "RU to PBX VOX noise threshold",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "RU-PBX VOX Thresh",
     "Category": "VOX",
	 "minValue":0,
	 "maxValue":255
   },
   {
     "ID": 84,
     "Name": "VOX_RU_HU_THRESH",
     "DefaultValue": "30",
     "Description": "RU to Handset VOX noise threshold",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "RU-HU VOX Thresh",
     "Category": "VOX",
	 "minValue":0,
	 "maxValue":255
   },
   {
     "ID": 85,
     "Name": "VOX_RU_DP_THRESH",
     "DefaultValue": "22",
     "Description": "RU to DP VOX noise threshold",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "RU-DP VOX Thresh",
     "Category": "VOX",
	 "minValue":0,
	 "maxValue":255
   },
   {
     "ID": 86,
     "Name": "VOX_RU_T_LISTEN",
     "DefaultValue": "50",
     "Description": "Minimum time before going to listening mode for RU",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VOX RU tListen",
     "Category": "VOX",
	 "minValue":0,
	 "maxValue":2000
   },
   {
     "ID": 87,
     "Name": "VOX_RU_T_TALK",
     "DefaultValue": "500",
     "Description": "Minimum time before reverting to talking mode for RU",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VOX RU tTalk",
     "Category": "VOX",
	 "minValue":0,
	 "maxValue":2000
   },
   {
     "ID": 88,
     "Name": "VOX_DP_T_LISTEN",
     "DefaultValue": "50",
     "Description": "Minimum time before going to listening mode for DP",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VOX DP tListen",
     "Category": "VOX",
	 "minValue":0,
	 "maxValue":2000
   },
   {
     "ID": 89,
     "Name": "VOX_DP_T_TALK",
     "DefaultValue": "500",
     "Description": "Minimum time before reverting to talking mode for DP",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VOX DP tTalk",
     "Category": "VOX",
	 "minValue":0,
	 "maxValue":2000
   },
   {
     "ID": 90,
     "Name": "ANSWER_LINE_1",
     "DefaultValue": "1",
     "Description": "Answer incoming calls on line 1\\n0:No answer, 1:Protocol invitation, 2:Call handsets, 3:Protocol LineTest",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Answer L1",
     "Category": "Protocols",
	 "minValue":0,
	 "maxValue":3
   },
   {
     "ID": 91,
     "Name": "ANSWER_LINE_2",
     "DefaultValue": "1",
     "Description": "Answer incoming calls on line 2\\n0:No answer, 1:Protocol invitation, 2:Call handsets, 3:Protocol LineTest",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Answer L2",
     "Category": "Protocols",
	 "minValue":0,
	 "maxValue":3
   },
   {
     "ID": 92,
     "Name": "MAINS_FAIL_INPUT_SENSE",
     "DefaultValue": "1",
     "Description": "Mains fail alarm on input closed (0) or open (1)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Mains Fail Sense",
     "Category": "System",
	 "minValue":0,
	 "maxValue":1
   },
   {
     "ID": 93,
     "Name": "BATTERY_LOW_INPUT_SENSE",
     "DefaultValue": "0",
     "Description": "Battery low alarm on input closed (0) or open (1)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Battery Low Sense",
     "Category": "System",
	 "minValue":0,
	 "maxValue":1
   },
   {
     "ID": 94,
     "Name": "GATEWAY_UNIT_ID",
     "DefaultValue": "9000",
     "Description": "Unit id to use for gateway handled alarms to control centres",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Gateway Unit Id",
     "Category": "Alarm Handler",
	 "minValue":0,
	 "maxValue":9999
   },
   {
     "ID": 95,
     "Name": "KEY_SAFE_RELAY",
     "DefaultValue": "1",
     "Description": "Room unit relay to use to open a key safe (1 or 2)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Key Safe Relay",
     "Category": "Key Safe",
	 "minValue":1,
	 "maxValue":2
   },
   {
     "ID": 96,
     "Name": "KEY_SAFE_TIMEOUT",
     "DefaultValue": "30000",
     "Description": "Time to activate the key safe relay on a room unit (ms)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Key Safe Time",
     "Category": "Key Safe",
	 "minValue":1000,
	 "maxValue":120000
   },
   {
     "ID": 97,
     "Name": "RF_CHANNEL",
     "DefaultValue": "15",
     "Description": "RF channel used for the signalling network, 11 to 26 (set to 0 to have the system choose again and set here)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Rf Channel",
     "Category": "System",
	 "minValue":0,
	 "maxValue":26
   },
   {
     "ID": 98,
     "Name": "DISCO_AUTO",
     "DefaultValue": "0",
     "Description": "Use automatic audio discovery (1) or only trigger from handset (0)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Discovery Auto",
     "Category": "Audio",
	 "minValue":0,
	 "maxValue":1
   },
   {
     "ID": 99,
     "Name": "BSI_SAFE_UNLOCK_CODE",
     "DefaultValue": "4",
     "Description": "Code upon which to issue a key safe unlock command",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "BSI Safe Unlock",
     "Category": "Protocols BSI",
	 "minValue":0,
	 "maxValue":99
   },
   {
     "ID": 100,
     "Name": "ALARM_QUEUE_ID",
     "DefaultValue": "0",
     "Description": "Persistent alarm queue id",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Alarm Queue Id",
     "Category": "Alarm Handler",
	 "minValue":0,
	 "maxValue":65535
   },
   {
     "ID": 101,
     "Name": "OAD_SESSION_ID",
     "DefaultValue": "0",
     "Description": "Session id used by OAD software",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "OAD Session Id",
     "Category": "OAD",
	 "minValue":0,
	 "maxValue":15
   },
   {
     "ID": 102,
     "Name": "OAD_SEND_TIMEOUT",
     "DefaultValue": "500",
     "Description": "Timeout between OAD broadcasts (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "OAD Send Time",
     "Category": "OAD",
	 "minValue":100,
	 "maxValue":5000
   },
   {
     "ID": 103,
     "Name": "OAD_ACTIVATE_TIMEOUT",
     "DefaultValue": "300",
     "Description": "Timeout period until activation occurs (s)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "OAD Activate Time",
     "Category": "OAD",
	 "minValue":60,
	 "maxValue":65535
   },
   {
     "ID": 104,
     "Name": "RELAY_ALARM",
     "DefaultValue": "11",
     "Description": "Relay modes during alarm call. Encoded as <relay1, 1-4><relay2 1-4>. 1 = No change 2 = during call , 3= answer wait , 4 =during alarm",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Relay Alarm",
     "Category": "Relays",
	 "minValue":11,
	 "maxValue":44
   },
   {
     "ID": 105,
     "Name": "RELAY_INTERNAL",
     "DefaultValue": "11",
     "Description": "Relay modes during internal social call. Encoded as <relay1, 1-4><relay2 1-4>. 1 = No change 2 = during call , 3= answer wait , 4 =during alarm",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Relay Internal",
     "Category": "Relays",
	 "minValue":11,
	 "maxValue":44
   },
   {
     "ID": 106,
     "Name": "RELAY_EXTERNAL",
     "DefaultValue": "11",
     "Description": "Relay modes during external social call. Encoded as <relay1, 1-4><relay2 1-4>. 1 = No change 2 = during call , 3= answer wait , 4 =during alarm",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Relay External",
     "Category": "Relays",
	 "minValue":11,
	 "maxValue":44
   },
   {
     "ID": 107,
     "Name": "SET_POLL_FAIL_ON_RAISE",
     "DefaultValue": "1",
     "Description": "Set poll fail on raise (1) or only on clear (0)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Poll Fail on Raise",
     "Category": "System Poll",
	 "minValue":0,
	 "maxValue":1

   },
   {
     "ID": 108,
     "Name": "RX_TX_SPACING",
     "DefaultValue": "2",
     "Description": "Spacing between Rx and Tx audio packet slots",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Rx-Tx Spacing",
     "Category": "Audio"
   },
   {
     "ID": 109,
     "Name": "DISCO_START_DELAY",
     "DefaultValue": "10",
     "Description": "Delay before node should start its transmissions (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Disco Start Delay",
     "Category": "Audio"
   },
   {
     "ID": 110,
     "Name": "DISCO_CHANNEL",
     "DefaultValue": "26",
     "Description": "Channel to run discovery procedure on",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Disco Channel",
     "Category": "Audio"
   },
   {
     "ID": 111,
     "Name": "DISCO_NO_TO_TX",
     "DefaultValue": "255",
     "Description": "Number of transmissions per node (max 255)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Disco nTx",
     "Category": "Audio"
   },
   {
     "ID": 112,
     "Name": "DISCO_SESSION_ID",
     "DefaultValue": "0",
     "Description": "Session ID used to identify this particular discovery",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Disco Session",
     "Category": "Audio"
   },
   {
     "ID": 113,
     "Name": "DISCO_PERIODIC",
     "DefaultValue": "0",
     "Description": "Timeout for periodic discovery process (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Disco Periodic",
     "Category": "Audio"
   },
   {
     "ID": 114,
     "Name": "ROAM_MIN_RSSI_PERIOD",
     "DefaultValue": "2000",
     "Description": "Timeout for Rssi below threshold before roaming (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Roam Rssi Time",
     "Category": "Roaming"
   },
   {
     "ID": 115,
     "Name": "ROAM_MIN_RSSI",
     "DefaultValue": "60",
     "Description": "Rssi Threshold for roaming (0-255)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Roam Rssi",
     "Category": "Roaming"
   },
   {
     "ID": 116,
     "Name": "ROAM_MIN_REJOIN_TIME",
     "DefaultValue": "3000",
     "Description": "Minimum time between roaming rejoins (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Roam Rejoin",
     "Category": "Roaming"
   },
   {
     "ID": 117,
     "Name": "ROAM_MAX_PACKET_LOSS_TIME",
     "DefaultValue": "2000",
     "Description": "Maximum time for packet loss before roaming (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Roam Packet",
     "Category": "Roaming"
   },
   {
     "ID": 118,
     "Name": "ROAM_MAX_AUDIO_SEARCH_TIME",
     "DefaultValue": "40000",
     "Description": "Maximum time to be roaming before failing call (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Roam Search",
     "Category": "Roaming"
   },
   {
     "ID": 119,
     "Name": "ROAM_MAX_PARENT_SEARCH_TIME",
     "DefaultValue": "10000",
     "Description": "Maximum time to search for a parent before failing call (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Roam Parent",
     "Category": "Roaming"
   },
   {
     "ID": 120,
     "Name": "ROAM_FAIL_TONE_TIME",
     "DefaultValue": "1500",
     "Description": "Time to play failure tone after call disconnect (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Fail Tone Time",
     "Category": "Roaming"
   },
   {
     "ID": 121,
     "Name": "ROAM_FAIL_TONE_HZ",
     "DefaultValue": "880",
     "Description": "Frequency of failure tone after call disconnect (Hz)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Fail Tone Hz",
     "Category": "Roaming"
   },
   {
     "ID": 122,
     "Name": "ROAM_FAIL_TONE_VOLUME",
     "DefaultValue": "-30",
     "Description": "Volume (attenuation) for failure tone after call disconnect",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Fail Tone Vol",
     "Category": "Roaming"
   },
   {
     "ID": 123,
     "Name": "HANDSET_CALL_TIMEOUT",
     "DefaultValue": "1000",
     "Description": "Time between retries for handset call set-up",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Handset Timeout",
     "Category": "Audio"
   },
   {
     "ID": 124,
     "Name": "HANDSET_CALL_RETRIES",
     "DefaultValue": "10",
     "Description": "Number of retries during call set-up before handset times out",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Handset Retries",
     "Category": "Audio"
   },
   {
     "ID": 125,
     "Name": "DROP_LOWER_PRIORITY",
     "DefaultValue": "2",
     "Description": "Enable call pre-emption (0 - none, 1 - drop at raise and prohibit, 2 - drop if no route)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Drop Low Priority",
     "Category": "Alarm"
   },
   {
     "ID": 126,
     "Name": "ALWAYS_LOGGED_ON",
     "DefaultValue": "1",
     "Description": "Determines if handsets are always logged on (0=No, 1=Yes)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Always Logged On",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 127,
     "Name": "CHANNEL_STRATEGY",
     "DefaultValue": "2",
     "Description": "Audio channel strategy (0 - 1 channel, 1 - 2 channels adjacent, 2 - 2 channels independent)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Channel Strategy",
     "Category": "Audio"
   },
   {
     "ID": 128,
     "Name": "BAD_CALL_THRESHOLD",
     "DefaultValue": "20",
     "Description": "Percentage packet loss threshold to log bad calls (integer value)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Bad Call Threshold",
     "Category": "Audio"
   },
   {
     "ID": 129,
     "Name": "ALLOW_SAME_PARENT",
     "DefaultValue": "1",
     "Description": "Allow handsets to join to the same parent (0 = no, 1 = yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Allow Same Parent",
     "Category": "System"
   },
   {
     "ID": 130,
     "Name": "SITE_ID",
     "DefaultValue": "Unknown",
     "Description": "Site identifier",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Site Id",
     "Category": "System"
   },
   {
     "ID": 131,
     "Name": "UPLOAD_NET_LOGS",
     "DefaultValue": "0",
     "Description": "Upload network logs (0 = no, 1 = yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Upload Net Logs",
     "Category": "System"
   },
   {
     "ID": 132,
     "Name": "UPPER_RSSI_THRESHOLD",
     "DefaultValue": "70",
     "Description": "Higher RSSI limit (<= RSSI_THRESHOLD to ignore)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Upper RSSI",
     "Category": "Audio"
   },
   {
     "ID": 133,
     "Name": "TEST_CALL_ID",
     "DefaultValue": "0",
     "Description": "Persistent test call id",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Test Call ID",
     "Category": "Audio"
   },
   {
     "ID": 134,
     "Name": "AUDIO_CHANNEL",
     "DefaultValue": "26",
     "Description": "Initial audio channel to use",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Audio Channel",
     "Category": "Audio"
   },
   {
     "ID": 135,
     "Name": "AUDIO_SEPARATION",
     "DefaultValue": "3",
     "Description": "Separation to use between audio channels",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Audio Separation",
     "Category": "Audio"
   },
   {
     "ID": 136,
     "Name": "PKT_LOSS_THRESHOLD",
     "DefaultValue": "5",
     "Description": "Maximum allowed packet loss before rejecting a discovery entry (in per cent)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Pkt Loss Threshold",
     "Category": "Audio"
   },
   {
     "ID": 137,
     "Name": "OAD_MAX_TRIES",
     "DefaultValue": "5",
     "Description": "Maximum number of tries for an OAD query to a device",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "OAD Max Tries",
     "Category": "OAD"
   },
   {
     "ID": 138,
     "Name": "OAD_QUERY_TIMEOUT",
     "DefaultValue": "2500",
     "Description": "Timeout between retries for an OAD query to a device (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "OAD Query Time",
     "Category": "OAD"
   },
   {
     "ID": 139,
     "Name": "SELF_CHECK_THRESHOLD",
     "DefaultValue": "100",
     "Description": "Number of send fails allowed before auto-reset of GW interface",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Self Check Threshold",
     "Category": "System"
   },
   {
     "ID": 140,
     "Name": "SELF_CHECK_MODE",
     "DefaultValue": "1",
     "Description": "Reset count to 0 or decrement count on successful message send (0 - decrement, 1 - reset)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Self Check Mode",
     "Category": "System"
   },
   {
     "ID": 141,
     "Name": "POLL_ACK_RETRIES",
     "DefaultValue": "5",
     "Description": "Number of end-to-end ACK retries for poll messaging",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Poll Ack Retries",
     "Category": "System Poll"
   },
   {
     "ID": 142,
     "Name": "DOOR_CALL_ACK_RETRIES",
     "DefaultValue": "10",
     "Description": "Number of end-to-end ACK retries for door call messaging",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Door Ack Retries",
     "Category": "Door Panel"
   },
   {
     "ID": 143,
     "Name": "CONTACT_ALARM_TIMEOUT",
     "DefaultValue": "150000",
     "Description": "Timeout before triggering door contact left open alarm output (ms)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Contact Alarm Time",
     "Category": "Door Panel",
     "minValue": 30000,
     "maxValue": 300000
   },
   {
     "ID": 144,
     "Name": "RU_PTT_FOR_DP",
     "DefaultValue": "0",
     "Description": "Open audio from room unit to door panel in PTT (1) or VOX (0)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "RU DP PTT",
     "Category": "Audio"
   },
   {
     "ID": 145,
     "Name": "OUTGOING_LINE",
     "DefaultValue": "101",
     "Description": "Routing prefix for HS outgoing calls. Pots Lines=101,102 Ext SIP=201",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Outgoing Line prefix",
     "Category": "Audio"
   },
   {
     "ID": 146,
     "Name": "ENHANCED_CALLS",
     "DefaultValue": "1",
     "Description": "Allow handset outgoing Calls (0 - No, 1 - Yes)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Allow handset outgoing Calls",
     "Category": "System"
   },
   {
     "ID": 147,
     "Name": "HANDSET_SYNC_ID",
     "DefaultValue": "0",
     "Description": "Sync ID for handset table",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Handset Sync Id",
     "Category": "System"
   },
   {
     "ID": 148,
     "Name": "RESIDENT_SYNC_ID",
     "DefaultValue": "0",
     "Description": "Sync ID for resident table",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Resident Sync Id",
     "Category": "System"
   },
   {
     "ID": 149,
     "Name": "HS_CAN_DELETE",
     "DefaultValue": "0",
     "Description": "Allow handsets to remove devices (0 - no, 1 - yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "HS Can Delete",
     "Category": "System"
   },
   {
     "ID": 150,
     "Name": "HW1_DEFAULT_FLAGS",
     "DefaultValue": "0",
     "Description": "Default flags for new hard-wired input 1 events",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "HW1 Default Flags",
     "Category": "HW Inputs"
   },
   {
     "ID": 151,
     "Name": "HW1_DEFAULT_TYPE",
     "DefaultValue": "0",
     "Description": "Default alarm type for new hard-wired input 1 events",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "HW1 Default Type",
     "Category": "HW Inputs"
   },
   {
     "ID": 152,
     "Name": "HW2_DEFAULT_FLAGS",
     "DefaultValue": "0",
     "Description": "Default flags for new hard-wired input 2 events",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "HW2 Default Flags",
     "Category": "HW Inputs"
   },
   {
     "ID": 153,
     "Name": "HW2_DEFAULT_TYPE",
     "DefaultValue": "0",
     "Description": "Default alarm type for new hard-wired input 2 events",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "HW2 Default Type",
     "Category": "HW Inputs"
   },
   {
     "ID": 154,
     "Name": "HW3_DEFAULT_FLAGS",
     "DefaultValue": "0",
     "Description": "Default flags for new hard-wired input 3 events",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "HW3 Default Flags",
     "Category": "HW Inputs"
   },
   {
     "ID": 155,
     "Name": "HW3_DEFAULT_TYPE",
     "DefaultValue": "0",
     "Description": "Default alarm type for new hard-wired input 3 events",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "HW3 Default Type",
     "Category": "HW Inputs"
   },
   {
     "ID": 156,
     "Name": "LOW_BATTERY",
     "DefaultValue": "2800",
     "Description": "Value in mV to mark a device as having low battery",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Low Battery",
     "Category": "System Poll"
   },
   {
     "ID": 157,
     "Name": "DOOR_PANEL_A_B",
     "DefaultValue": "1",
     "Description": "Interpret 1000 and 2000 range room numbers as xxA and xxB respectively (0 - No, 1 - Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Door Panel A/B",
     "Category": "Door Panel"
   },
   {
     "ID": 158,
     "Name": "ENABLE_NETWORK_SHAPING",
     "DefaultValue": "0",
     "Description": "Allow automatic network shaping (0 - No, 1 - Yes)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Network Shaping",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
	 
   },
   {
     "ID": 159,
     "Name": "RESIDENT_OK_START_HOUR",
     "DefaultValue": "9",
     "Description": "Resident OK checking Reset Hour",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Resident OK Start Hour",
     "Category": "System"
   },
   {
     "ID": 160,
     "Name": "RESIDENT_OK_START_MINUTE",
     "DefaultValue": "0",
     "Description": "Resident OK checking Reset Minute",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Resident OK Start Minute",
     "Category": "System"
   },
   {
     "ID": 161,
     "Name": "RESIDENT_OK_STOP_HOUR",
     "DefaultValue": "17",
     "Description": "Resident OK checking Set Hour",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Resident OK Stop Hour",
     "Category": "System"
   },
   {
     "ID": 162,
     "Name": "RESIDENT_OK_STOP_MINUTE",
     "DefaultValue": "0",
     "Description": "Resident OK checking Set Minute",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Resident OK Stop Minute",
     "Category": "System"
   },
   {
     "ID": 163,
     "Name": "QUICK_DISCOVERY_POLLS",
     "DefaultValue": "2",
     "Description": "Number of poll loops to request a quick discovery on",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Quick Discovery Polls",
     "Category": "System"
   },
   {
     "ID": 164,
     "Name": "BSI_INVITE_DURATION",
     "DefaultValue": "1000",
     "Description": "Minimum BSI invitation duration to enforce (ms, 0 to disable)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "BSI Invite Duration",
     "Category": "Protocols BSI"
   },
   {
     "ID": 165,
     "Name": "BSI_MAX_KEEP_ONLINE",
     "DefaultValue": "30",
     "Description": "Maximum time to stay online without any other interaction (mins, 0 to disable)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "BSI Max Keep Online",
     "Category": "Protocols BSI"
   },
   {
     "ID": 166,
     "Name": "STANDARD_OAD_QUERY_TIME",
     "DefaultValue": "250",
     "Description": "Standard OAD query time (ms)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Standard Query Time",
     "Category": "OAD",
     "minValue": 50,
     "maxValue": 2000
   },
   {
     "ID": 167,
     "Name": "ONE_BUTTON_TIMEOUT",
     "DefaultValue": "10",
     "Description": "Time to energise door open relays for (s)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "One-Button Timeout",
     "Category": "OneButton",
     "minValue": 1,
     "maxValue": 60
   },
   {
     "ID": 168,
     "Name": "ONE_BUTTON_RELAYS",
     "DefaultValue": "1",
     "Description": "Bitmap of relays to energise, 1 = relay 1, 2 = relay 2, 3 = both relays",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "One-Button Relays",
     "Category": "OneButton",
     "minValue": 0,
     "maxValue": 3
   },
   {
     "ID": 169,
     "Name": "ONE_BUTTON_VOLUME",
     "DefaultValue": "1",
     "Description": "Volume for tones (0-4 with 4 being the loudest)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "One-Button Volume",
     "Category": "OneButton",
     "minValue": 0,
     "maxValue": 4
   },
   {
     "ID": 170,
     "Name": "ONE_BUTTON_OPEN_TONES",
     "DefaultValue": "1",
     "Description": "Whether to play door open tones or not (0 = no, 1 = yes)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "One-Button Open Tones",
     "Category": "OneButton",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 171,
     "Name": "WANDERING_PATIENT_MODE",
     "DefaultValue": "0",
     "Description": "Filter mode for wandering patient alarms(0 = none, always raise alarm, 1 = Only raise when resident is away)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Wandering Patient Mode",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 172,
     "Name": "FALL_CANCEL_WAIT_TIME",
     "DefaultValue": "30",
     "Description": "Number seconds to wait before raising a fall alarm to allow user to cancel",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Fall Alarm Delay",
     "Category": "System",
     "minValue": 5,
     "maxValue": 120
   },
   {
     "ID": 173,
     "Name": "MAX_ALARM_CANCEL_AGE",
     "DefaultValue": "30",
     "Description": "Age in seconds of an alarm that is allowed to be cancelled by user pressing cancel button on their pendant",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Max alarm cancel age",
     "Category": "System"
   },
   {
     "ID": 174,
     "Name": "MAX_OAD_FILLINS_RF_BINARY",
     "DefaultValue": "10",
     "Description": "Number of fill in packet attempts for RF processors",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "OAD Fill In RF",
     "Category": "OAD"
   },
   {
     "ID": 175,
     "Name": "MAX_OAD_FILLINS_ESP32_BINARY",
     "DefaultValue": "100",
     "Description": "Number of fill in packet attempts for ESP32 processor",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "OAD Fill In ESP32",
     "Category": "OAD"
   },
   {
     "ID": 176,
     "Name": "OAD_CHECK_WAIT_TIMEOUT",
     "DefaultValue": "16000",
     "Description": "Delay to wait for OAD check response",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "OAD check wait",
     "Category": "OAD"
   },
   {
     "ID": 177,
     "Name": "WIFI_SITE_KEY",
     "DefaultValue": "xzpQ7j13Qq",
     "Description": "Wifi site key",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Wifi site key",
     "Category": "System"
   },
   {
     "ID": 178,
     "Name": "WIFI_SITE_CHANNEL",
     "DefaultValue": "7",
     "Description": "Wifi site channel",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Wifi site channel",
     "Category": "System"
   },
   {
     "ID": 179,
     "Name": "WIFI_SITE_VISIBILITY",
     "DefaultValue": "1",
     "Description": "Wifi SSID Visibility",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Wifi SSID Visibility",
     "Category": "System"
   },
   {
     "ID": 180,
     "Name": "OAD_IP_DOWNLOAD_ADDRESS",
     "DefaultValue": "172.16.0.5",
     "Description": "IP Address for IP OAD",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "OAD IP",
     "Category": "OAD"
   },
   {
     "ID": 181,
     "Name": "OAD_IP_DOWNLOAD_URL",
     "DefaultValue": "/upgrade/",
     "Description": "URL to append to HTTP OAD request",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "OAD URL",
     "Category": "OAD"
   },
   {
     "ID": 182,
     "Name": "IP_ADDRESS_RANGE",
     "DefaultValue": "172.16.0.0",
     "Description": "IP address range",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "IP RANGE",
     "Category": "System"
   },
   {
     "ID": 183,
     "Name": "IP_NETMASK",
     "DefaultValue": "255.255.0.0",
     "Description": "IP netmask",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "IP MASK",
     "Category": "System"
   },
   {
     "ID": 184,
     "Name": "IP_GATEWAY",
     "DefaultValue": "172.16.0.1",
     "Description": "IP Gateway",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "IP GATEWAY",
     "Category": "System"
   },
   {
     "ID": 185,
     "Name": "IP_DNS",
     "DefaultValue": "8.8.8.8",
     "Description": "IP DNS",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "IP DNS",
     "Category": "System"
   },
   {
     "ID": 186,
     "Name": "CAMERA_SYNC_ID",
     "DefaultValue": "0",
     "Description": "Sync ID for camera table",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Camera Sync Id",
     "Category": "System"
   },
   {
     "ID": 187,
     "Name": "VRU_VOLUME_LEVEL_1",
     "DefaultValue": "-40",
     "Description": "VRU volume used when set to volume level 1",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume1",
     "Category": "Volume"
   },
   {
     "ID": 188,
     "Name": "VRU_VOLUME_LEVEL_2",
     "DefaultValue": "-35",
     "Description": "VRU volume used when set to volume level 2",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume2",
     "Category": "Volume"
   },
   {
     "ID": 189,
     "Name": "VRU_VOLUME_LEVEL_3",
     "DefaultValue": "-30",
     "Description": "VRU volume used when set to volume level 3",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume3",
     "Category": "Volume"
   },
   {
     "ID": 190,
     "Name": "VRU_VOLUME_LEVEL_4",
     "DefaultValue": "-25",
     "Description": "VRU volume used when set to volume level 4",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume4",
     "Category": "Volume"
   },
   {
     "ID": 191,
     "Name": "VRU_VOLUME_LEVEL_5",
     "DefaultValue": "-18",
     "Description": "VRU volume used when set to volume level 5",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Volume5",
     "Category": "Volume"
   },
   {
     "ID": 192,
     "Name": "MAX_PREVIOUS_ALARMS_TO_CANCEL",
     "DefaultValue": "2",
     "Description": "Number of active alarms to be cancelled on trigger cancel press",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Max previous Alarms",
     "Category": "System",
     "minValue": 0,
     "maxValue": 4
   },
   {
     "ID": 193,
     "Name": "DOOR_CALLS_ONLY_SYSTEM",
     "DefaultValue": "0",
     "Description": "Allow only door calls, set to 305436790 to enable door only",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Door Calls Only",
     "Category": "System",
     "minValue": 0,
     "maxValue": 305436790
   },
   {
     "ID": 194,
     "Name": "ENABLE_AUDIT_V2",
     "DefaultValue": "1",
     "Description": "Enable V2 audit events",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "V2 Audit",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 195,
     "Name": "V2_AUDIT_MAX_EVENTS_PER_DB",
     "DefaultValue": "5000",
     "Description": "Max events in the DB before archiving",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "V2 Audit max events",
     "Category": "System",
     "minValue": 1000,
     "maxValue": 10000
   },
   {
     "ID": 196,
     "Name": "ETHERNET_GW_INTERFACES_ENABLED",
     "DefaultValue": "1",
     "Description": "Ethernet based GWI",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "EthernetGWI",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 197,
     "Name": "ETHERNET_CLUSTER_GWI_ENABLED",
     "DefaultValue": "1",
     "Description": "Ethernet Cluster GWI",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "EthernetClusterGWI",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 198,
     "Name": "ETHERNET_GWI_IP_ADDRESS",
     "DefaultValue": "172.16.0.5",
     "Description": "IP address for GWI traffic",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Ethernet GWI IP Address",
     "Category": "System",
     "minValue": 7,
     "maxValue": 15
   },
   {
     "ID": 199,
     "Name": "ETHERNET_GWI_NETMASK",
     "DefaultValue": "255.255.0.0",
     "Description": "IP netmask for GWI traffic",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Ethernet GWI Netmask",
     "Category": "System",
     "minValue": 7,
     "maxValue": 15
   },
   {
     "ID": 200,
     "Name": "LOG_TRIGGER_RX_RSSI",
     "DefaultValue": "0",
     "Description": "Allow RSSI logging for triggers",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "RSSI Logging",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 201,
     "Name": "ANSWER_LINE_SIP",
     "DefaultValue": "0",
     "Description": "Answer incoming SIP calls\\n0:No answer, 1:Protocol invitation, 2:Call handsets, 3:Protocol LineTest",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Answer SIP",
     "Category": "Protocols",
     "minValue": 0,
     "maxValue": 3
   },
   {
     "ID": 202,
     "Name": "SIP_DOOR_API_USER",
     "DefaultValue": "WH_GW",
     "Description": "SIP Door panel API username",
     "DataType": 5,
     "AccessLevel": "technical",
     "ShortName": "SIP API Username",
     "Category": "Door Panel",
     "minValue": 2,
     "maxValue": 25 
   },
   {
     "ID": 203,
     "Name": "SIP_DOOR_API_PASS",
     "DefaultValue": "XTjYKv3df6",
     "Description": "SIP Door panel API password",
     "DataType": 5,
     "AccessLevel": "technical",
     "ShortName": "SIP API Password",
     "Category": "Door Panel",
     "minValue": 8,
     "maxValue": 25
   },
   {
     "ID": 204,
     "Name": "SIP_DOOR_OPEN_SWITCH",
     "DefaultValue": "1",
     "Description": "SIP Door open switch number",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SIP Door open switch",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 2 
   },
   {
     "ID": 205,
     "Name": "SIP_DOOR_OPEN_TIME",
     "DefaultValue": "0",
     "Description": "SIP Door open time in seconds. Set to 0 for relay toggle to be handled by the SIP panel",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SIP Door open time",
     "Category": "Door Panel",
     "minValue": 0,
     "maxValue": 60 
   },
   {
     "ID": 206,
     "Name": "VRU_WIFI_SCAN",
     "DefaultValue": "0",
     "Description": "Allow VRU periodic WiFi scanning, 0:No >0 Yes",
     "DataType": 2,
	 "AccessLevel": "admin",
     "ShortName": "VRU WiFi Scan",
     "Category": "System"
   },
   {
     "ID": 207,
     "Name": "ENABLE_COMPANION_APP",
     "DefaultValue": "0",
     "Description": "Enable companion app functionality",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Enable Companion App",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 208,
     "Name": "ALLOW_COMPANION_APP_ROAMING",
     "DefaultValue": "0",
     "Description": "Enable companion app roaming",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Enable Companion App Roaming",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 209,
     "Name": "SIP_BSI_DTMF_ON_TIME",
     "DefaultValue": "20",
     "Description": "SIP BSI DTMF on time (Omnivia Only)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "SIP BSI DTMF on time",
     "Category": "System"
   },
   {
     "ID": 210,
     "Name": "SIP_BSI_DTMF_OFF_TIME",
     "DefaultValue": "80",
     "Description": "SIP_BSI_DTMF_OFF_TIME BSI DTMF off time (Omnivia Only)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "SIP_BSI_DTMF_OFF_TIME BSI DTMF off time",
     "Category": "System"
   },
   {
     "ID": 211,
     "Name": "FXO_BSI_DTMF_ON_TIME",
     "DefaultValue": "80",
     "Description": "FXO BSI DTMF on time (Omnivia Only)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "FXO BSI DTMF on time",
     "Category": "System"
   },
   {
     "ID": 212,
     "Name": "FXO_BSI_DTMF_OFF_TIME",
     "DefaultValue": "80",
     "Description": "FXO BSI DTMF off time (Omnivia Only)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "FXO BSI DTMF off time",
     "Category": "System"
   },
   {
     "ID": 213,
     "Name": "SYSTEM_CNTRL_TYPE",
     "DefaultValue": "2",
     "Description": "System Controller Type. 0 = IP08 , 1 = PROD-3200 (Upgrade), 2 = PROD-3208 (Rack)",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "System Type",
     "Category": "System"
   },
   {
     "ID": 214,
     "Name": "VRU_SIP_DOOR_VOLUME_LEVEL_1",
     "DefaultValue": "-30",
     "Description": "VRU volume used when set to volume level 1 on SIP door call",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VRUSIPVolume1",
     "Category": "Volume"
   },
   {
     "ID": 215,
     "Name": "VRU_SIP_DOOR_VOLUME_LEVEL_2",
     "DefaultValue": "-24",
     "Description": "VRU volume used when set to volume level 2 on SIP door call",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VRUSIPVolume2",
     "Category": "Volume"
   },
   {
     "ID": 216,
     "Name": "VRU_SIP_DOOR_VOLUME_LEVEL_3",
     "DefaultValue": "-22",
     "Description": "VRU volume used when set to volume level 3 on SIP door call",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VRUSIPVolume3",
     "Category": "Volume"
   },
   {
     "ID": 217,
     "Name": "VRU_SIP_DOOR_VOLUME_LEVEL_4",
     "DefaultValue": "-18",
     "Description": "VRU volume used when set to volume level 4 on SIP door call",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VRUSIPVolume4",
     "Category": "Volume"
   },
   {
     "ID": 218,
     "Name": "VRU_SIP_DOOR_VOLUME_LEVEL_5",
     "DefaultValue": "-15",
     "Description": "VRU volume used when set to volume level 5 on SIP door call",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "VRUSIPVolume5",
     "Category": "Volume"
   },
   {
     "ID": 219,
     "Name": "CLUSTER_RSSI_THRESHOLD",
     "DefaultValue": "55",
     "Description": "Minimum acceptable RSSI for cluster audio routing",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Cluster RSSI",
     "Category": "Audio",
     "minValue": 1,
     "maxValue": 150 
   },
   {
     "ID": 220,
     "Name": "SIP_VERIFY_TLS_CERTS",
     "DefaultValue": "0",
     "Description": "Verify control centre TLS certificate. 0-Ignore 1-Verify",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Verify TLS",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1 
   },
   {
     "ID": 221,
     "Name": "BS8521_2_SETUP_TIMEOUT",
     "DefaultValue": "120",
     "Description": "SIP Setup timeout(seconds)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "BS8521_2 SIP Timeout",
     "Category": "BS8521_2",
     "minValue": 60,
     "maxValue": 3600 
   },
   {
     "ID": 222,
     "Name": "BS8521_2_ANSWER_TIMEOUT",
     "DefaultValue": "240",
     "Description": "Max time to wait for ARC to answer the SIP call(seconds)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "BS8521_2 answer timeout",
     "Category": "BS8521_2",
     "minValue": 60,
     "maxValue": 3600 
   },
   {
     "ID": 223,
     "Name": "BS8521_2_CC_FREE_TIMEOUT",
     "DefaultValue": "300",
     "Description": "Max time to wait for a ARC to become free(seconds)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "BS8521_2 CC Free timeout",
     "Category": "BS8521_2",
     "minValue": 60,
     "maxValue": 600 
   },
   {
     "ID": 224,
     "Name": "BS8521_2_SIP_ANS_ACC_CALL",
     "DefaultValue": "0",
     "Description": "Open audio call on SIP answer. 0 - don't open 1 = open",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "BS8521_2 SIP Answer selects call",
     "Category": "BS8521_2",
     "minValue": 0,
     "maxValue": 1 
   },
   {
     "ID": 225,
     "Name": "BS8521_2_SIP_REG_TIMEOUT",
     "DefaultValue": "10000",
     "Description": "Timeout(mS) for SIP registration",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Timeout(mS) for SIP registration",
     "Category": "BS8521_2",
     "minValue": 5000,
     "maxValue": 120000 
   },
   {
     "ID": 226,
     "Name": "BS8521_2_SIP_RING_TIMEOUT",
     "DefaultValue": "10000",
     "Description": "Timeout(mS) for SIP ringing event",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Timeout(mS) for SIP ringing event",
     "Category": "BS8521_2",
     "minValue": 5000,
     "maxValue": 60000 
   },
   {
     "ID": 227,
     "Name": "BS8521_2_SIP_MSG_ACK_TIMEOUT",
     "DefaultValue": "5000",
     "Description": "Timeout(mS) for SIP messages",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Timeout(mS) for SIP messages",
     "Category": "BS8521_2",
     "minValue": 1000,
     "maxValue": 30000 
   },
   {
     "ID": 228,
     "Name": "BS8521_2_SIP_ANSWER_TIMEOUT",
     "DefaultValue": "300",
     "Description": "Timeout(s) for SIP answer",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Timeout(s) for SIP answer",
     "Category": "BS8521_2",
     "minValue": 60,
     "maxValue": 600 
   },
   {
     "ID": 229,
     "Name": "BS8521_2_SIP_ONLINE_TIMEOUT",
     "DefaultValue": "300",
     "Description": "Maximum time that a SIP audio connection can be open for in seconds",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Timeout(s) for SIP online",
     "Category": "BS8521_2",
     "minValue": 120,
     "maxValue": 7200 
   },
   {
     "ID": 230,
     "Name": "BS8521_2_SIP_HEARTBEAT_TIME",
     "DefaultValue": "300",
     "Description": "BS8521-2 Heartbeat period (s)",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "BS8521-2 Heartbeat period(s)",
     "Category": "BS8521_2",
     "minValue": 120,
     "maxValue": 86400 
   },
   {
     "ID": 231,
     "Name": "BS8521_2_VERSION",
     "DefaultValue": "1",
     "Description": "BS8521-2 Version string",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "BS8521-2 Version string",
     "Category": "BS8521_2"
   },
   {
     "ID": 232,
     "Name": "CLOUD_END_POINT",
     "DefaultValue": "a12vmg0bcnm4fa-ats.iot.eu-west-1.amazonaws.com:8883",
     "Description": "Cloud Endpoint",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Cloud Endpoint",
     "Category": "Cloud"
   },
   {
     "ID": 233,
     "Name": "CLOUD_SITE_NAME",
     "DefaultValue": "WH_SCU_Invalid",
     "Description": "Cloud SiteName",
     "DataType": 5,
     "AccessLevel": "admin",
     "ShortName": "Cloud Sitename",
     "Category": "Cloud"
   },
   {
     "ID": 234,
     "Name": "CLOUD_HEARTBEAT_PERIOD",
     "DefaultValue": "30",
     "Description": "Minutes between heart beat messages. Set to 0 to disable",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Cloud Heartbeat",
     "Category": "Cloud"
   },
   {
     "ID": 235,
     "Name": "CLOUD_V2_ENABLED",
     "DefaultValue": "1",
     "Description": "Enable v2 messaging",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Enable v2 messaging",
     "Category": "Cloud"
   },
   {
     "ID": 236,
     "Name": "LOW_BATTERY_REPEAT",
     "DefaultValue": "12",
     "Description": "Low battery alarm repeat interval in hours",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Low Battery Repeat",
     "Category": "System",
     "minValue": 6,
     "maxValue": 672
   },
   {
     "ID": 237,
     "Name": "DOOR_PANEL_EXT_DOOR_AS_VIDEO",
     "DefaultValue": "0",
     "Description": "Use the door panel external door release relay for video feed relay.",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Door external door release for video",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 238,
     "Name": "IGNORE_BSI_PTT",
     "DefaultValue": "0",
     "Description": "Ignore push to talk commands from a BSI ARC. 0 = Action 1 = ignore",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Ignore BSI PTT",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 239,
     "Name": "SIP_DISABLE_SRTP",
     "DefaultValue": "1",
     "Description": "Disable SIP Media Encryption. 0 = Enabled 1 = Disabled",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SIP RTP Media Encryption ",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
	 
   },
   {
     "ID": 240,
     "Name": "HANDSET_MINIMUM_RING_VOLUME",
     "DefaultValue": "15",
     "Description": "Minimum handset alarm ringing volume",
     "DataType": 2,
     "AccessLevel": "manager",
     "ShortName": "Handset Ring Volume",
     "Category": "System",
     "minValue": 2,
     "maxValue": 15
   },
   {
     "ID": 241,
     "Name": "CANCEL_AT_SOURCE_MODE",
     "DefaultValue": "1",
     "Description": "Cancel at source mode. 0:disabled 1:Enabled 2:Enabled with PIN",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Cancel at source mode",
     "Category": "System",
     "minValue": 0,
     "maxValue": 2
   },
   {
     "ID": 242,
     "Name": "CANCEL_AT_SOURCE_PIN",
     "DefaultValue": "0",
     "Description": "4 digit Pin code to cancel alarms at source",
     "DataType": 5,
     "AccessLevel": "technical",
     "ShortName": "Cancel at source PIN",
     "Category": "System",
     "minValue": 4,
     "maxValue": 4
   },
   {
     "ID": 243,
     "Name": "DB_MANIFEST_UPLOAD_QUIET_TIME",
     "DefaultValue": "900",
     "Description": "Seconds of no DB activity for DB manifest upload",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "DB Manifest quiet tiume",
     "Category": "System"
   },
   {
     "ID": 244,
     "Name": "CLOUD_EVENT_UPLOAD_ENABLED",
     "DefaultValue": "1",
     "Description": "Set to 1 to enable cloud event upload",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Cloud event upload",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 245,
     "Name": "CLOUD_DEBUG_UPLOAD_ENABLED",
     "DefaultValue": "1",
     "Description": "Set to 1 to enable cloud debug upload",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Cloud debug upload",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 246,
     "Name": "AWS_DEBUG_ENVIRONMENT",
     "DefaultValue": "0",
     "Description": "AWS Debug Environment",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "AWS Debug Environment",
     "Category": "System",
     "minValue": 0,
     "maxValue": 2
   },
   {
     "ID": 247,
     "Name": "AWS_SQL_CHANGE_UPLOAD",
     "DefaultValue": "1",
     "Description": "Upload SQL changes to AWS",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Upload SQL changes to AWS",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 248,
     "Name": "WIRELESS_DEVICE_SHADOW_CHANGE_COUNT",
     "DefaultValue": "0",
     "Description": "Incremented on a WIRELESS_DEVICE_SHADOW_CHANGE_COUNT Change",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Device shadow change counter",
     "Category": "System"
   },
   {
     "ID": 249,
     "Name": "LOW_BATTERY_RECOVERY_VOLTAGE",
     "DefaultValue": "3000",
     "Description": "Value in mV to mark a device low battery has been replaced",
     "DataType": 2,
     "AccessLevel": "admin",
     "ShortName": "Low Battery Recovery Voltage",
     "Category": "System Poll",
     "minValue": 2000,
     "maxValue": 3100
   },
   {
     "ID": 250,
     "Name": "SCU_INPUT_1_ALARM_TYPE",
     "DefaultValue": "51",
     "Description": "SCU Input 1 Alarm Type",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 1 Alarm Type",
     "Category": "System",
     "minValue": 0,
     "maxValue": 99
   },
   {
     "ID": 251,
     "Name": "SCU_INPUT_1_SENSE",
     "DefaultValue": "0",
     "Description": "SCU Input 1 Sense, 0 = Normally closed 1 = Normally Open",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 1 Sense",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 252,
     "Name": "SCU_INPUT_1_DEBOUNCE",
     "DefaultValue": "300000",
     "Description": "SCU Input 1 Debounce Time in mS",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 1 Debounce Time in mS",
     "Category": "System",
     "minValue": 0,
     "maxValue": 600000
   },
   {
     "ID": 253,
     "Name": "SCU_INPUT_2_ALARM_TYPE",
     "DefaultValue": "51",
     "Description": "SCU Input 2 Alarm Type",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 2 Alarm Type",
     "Category": "System",
     "minValue": 0,
     "maxValue": 99
   },
   {
     "ID": 254,
     "Name": "SCU_INPUT_2_SENSE",
     "DefaultValue": "0",
     "Description": "SCU Input 2 Sense, 0 = Normally closed 1 = Normally Open",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 2 Sense",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 255,
     "Name": "SCU_INPUT_2_DEBOUNCE",
     "DefaultValue": "300000",
     "Description": "SCU Input 2 Debounce Time in mS",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 2 Debounce Time in mS",
     "Category": "System",
     "minValue": 0,
     "maxValue": 600000
   },
   {
     "ID": 256,
     "Name": "SCU_INPUT_3_ALARM_TYPE",
     "DefaultValue": "0",
     "Description": "SCU Input 3 Alarm Type",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 3 Alarm Type",
     "Category": "System",
     "minValue": 0,
     "maxValue": 99
   },
   {
     "ID": 257,
     "Name": "SCU_INPUT_3_SENSE",
     "DefaultValue": "0",
     "Description": "SCU Input 3 Sense, 0 = Normally closed 1 = Normally Open",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 3 Sense",
     "Category": "System",
     "minValue": 0,
     "maxValue": 1
   },
   {
     "ID": 258,
     "Name": "SCU_INPUT_3_DEBOUNCE",
     "DefaultValue": "100",
     "Description": "SCU Input 3 Debounce Time in mS",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "SCU Input 3 Debounce Time in mS",
     "Category": "System",
     "minValue": 0,
     "maxValue": 600000
   },
   {
     "ID": 259,
     "Name": "LIVE_ALARM_SEND_PERIOD",
     "DefaultValue": "120",
     "Description": "Period in seconds between live alarm events to the cloud. Set to 0 to turn off",
     "DataType": 2,
     "AccessLevel": "technical",
     "ShortName": "Live alarm send period (Seconds). Set to 0 to turn off",
     "Category": "Alarm Handler",
     "minValue": 0,
     "maxValue": 7200
   },
   {
     "ID": 260,
     "Name": "MAX_ALARM_EVENTS_PER_ALARM",
     "DefaultValue": "1000",
     "Description": "Max alarm events per alarm sent to the cloud.",
     "DataType": 2,
     "AccessLevel": "elite",
     "ShortName": "Max Alarm Events",
     "Category": "Alarm Handler",
     "minValue": 50,
     "maxValue": 5000
   },
   {
      "ID": 261,
      "Name": "IP_PING_ENABLE",
      "DefaultValue": "1",
      "Description": "Enable the fast IP ping module.",
      "DataType": 2,
      "AccessLevel": "technical",
      "ShortName": "IP Ping enable",
      "Category": "System",
      "minValue": 0,
      "maxValue": 1
    },
    {
       "ID": 262,
       "Name": "IP_PING_STARTUP_DELAY_MS",
       "DefaultValue": "120000",
       "Description": "Time after boot before starting the IP Polls (mS).",
       "DataType": 2,
       "AccessLevel": "technical",
       "ShortName": "IP Ping Start-up Delay (mS)",
       "Category": "System",
       "minValue": 5000,
       "maxValue": 720000
     },
     {
        "ID": 263,
        "Name": "IP_PING_POLL_PERIOD",
        "DefaultValue": "50000",
        "Description": "Time between IP ping poll rounds (mS).",
        "DataType": 2,
        "AccessLevel": "technical",
        "ShortName": "IP Ping Poll Period (mS)",
        "Category": "System",
        "minValue": 30000,
        "maxValue": 1200000
      },
      {
         "ID": 264,
         "Name": "IP_PING_INTER_POLL_TIME_MS",
         "DefaultValue": "100",
         "Description": "Time between sending batches of ping messages (mS).",
         "DataType": 2,
         "AccessLevel": "elite",
         "ShortName": "IP Ping Inter-Poll Period (mS)",
         "Category": "System",
         "minValue": 100,
         "maxValue": 10000
       },
       {
         "ID": 265,
         "Name": "IP_PING_RESPONSE_WAIT_MS",
         "DefaultValue": "50",
         "Description": "Time to wait for a ping response (mS).",
         "DataType": 2,
         "AccessLevel": "technical",
         "ShortName": "IP Ping Response Wait (mS)",
         "Category": "System",
         "minValue": 20,
         "maxValue": 2000
       },
       {
         "ID": 266,
         "Name": "IP_PING_MAX_QUICK_TRIES",
         "DefaultValue": "3",
         "Description": "Number of attempts to send a ping to a unit before deeming as no response.",
         "DataType": 2,
         "AccessLevel": "technical",
         "ShortName": "IP Ping Quick Tries",
         "Category": "System",
         "minValue": 2,
         "maxValue": 10
       },
       {
         "ID": 267,
         "Name": "IP_PING_POLL_ROUNDS_BEFORE_ALARM_CHECK",
         "DefaultValue": "1",
         "Description": "Number of poll rounds before checking for no responses.",
         "DataType": 2,
         "AccessLevel": "admin",
         "ShortName": "IP Ping Quick Tries",
         "Category": "System",
         "minValue": 2,
         "maxValue": 10
       },
       {
         "ID": 268,
         "Name": "IP_PING_MAX_MISSES",
         "DefaultValue": "2",
         "Description": "Number of poll periods in which a ping response is not seen before raising the alarm.",
         "DataType": 2,
         "AccessLevel": "technical",
         "ShortName": "IP Ping Max Misses",
         "Category": "System",
         "minValue": 2,
         "maxValue": 100
       },
       {
         "ID": 269,
         "Name": "IP_PING_MAX_MESSAGES_PER_BATCH",
         "DefaultValue": "10",
         "Description": "Number of ping messages to send at once.",
         "DataType": 2,
         "AccessLevel": "admin",
         "ShortName": "IP Ping Max Messages",
         "Category": "System",
         "minValue": 1,
         "maxValue": 100
       },
       {
         "ID": 270,
         "Name": "TUNSTALL_SYSTEM",
         "DefaultValue": "1",
         "Description": "Enable tunstall features.",
         "DataType": 2,
         "AccessLevel": "admin",
         "ShortName": "Enable tunstall features",
         "Category": "System",
         "minValue": 0,
         "maxValue": 1
       },
       {
         "ID": 271,
         "Name": "TEST_MODE_MAX_ON_TIME",
         "DefaultValue": "1200",
         "Description": "Number of seconds test mode is allowed to be active.",
         "DataType": 2,
         "AccessLevel": "admin",
         "ShortName": "Test mode maximum on time (seconds)",
         "Category": "System",
         "minValue": 5,
         "maxValue": 3600
       },
       {
         "ID": 272,
         "Name": "TEST_MODE_ALERT_DURATION",
         "DefaultValue": "10",
         "Description": "Number of seconds an alaarm will be presented in test mode before been auto cleared",
         "DataType": 2,
         "AccessLevel": "technical",
         "ShortName": "Test mode alert druation (seconds)",
         "Category": "System",
         "minValue": 1,
         "maxValue": 120
       },
       {
         "ID": 273,
         "Name": "BROADCAST_SPEECH_MULTICAST_ADDRESS",
         "DefaultValue": "224.0.0.160",
         "Description": "Broadcast speech multicast IP address",
         "DataType": 5,
         "AccessLevel": "admin",
         "ShortName": "Broadcast speech multicast IP address",
         "Category": "System"
       },
       {
         "ID": 274,
         "Name": "BROADCAST_SPEECH_MULTICAST_PORT",
         "DefaultValue": "40010",
         "Description": "Broadcast speech multicast port",
         "DataType": 2,
         "AccessLevel": "admin",
         "ShortName": "Broadcast speech multicast port",
         "Category": "System"
       },
       {
         "ID": 275,
         "Name": "BS8521_2_MAX_MSG_RETRIES",
         "DefaultValue": "12",
         "Description": "Maximum alarm message retries before trying the next handler",
         "DataType": 2,
         "AccessLevel": "technical",
         "ShortName": "BS8521-2 Maximum alarm message retries",
         "Category": "BS8521_2"
       },
       {
         "ID": 276,
         "Name": "ALLOW_LOW_BATTERY_ON_RAISE",
         "DefaultValue": "0",
         "Description": "Ignore low battery repeat interval",
         "DataType": 2,
         "AccessLevel": "technical",
         "ShortName": "Low battery on raise",
         "Category": "System"
       }
  ]`;

export const systemParameterDefinitions: iSystemParameterDefinition[] =
  JSON.parse(jsonString);
