import TablePaged from 'components/tables/TablePaged';

import React, { useEffect, useState } from 'react';
//import useStateRef from 'react-usestateref';
import { generateAlarmTypesReport } from '../../../reports/reportTypes/alarmTypesReport';
import Tile, { iTileEvent } from '../../tile';

import { Box, Button } from 'grommet';
import { Column, Cell } from 'react-table';
import * as Wait from 'components/dialogues/waitDialogue';
import { useWinstonLogger } from 'winston-react';
import { Text } from 'grommet';
import { Manifest, teErrorCode } from 'components/installation/manifest';
import { AlarmPriority } from 'components/installation/alarmPriority';
import SelectCell from 'components/tables/cellPresentationComponents/selectCell';
import _, { cloneDeep } from 'lodash';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
import useStateRef from 'react-usestateref';
import { ImportExcel } from 'common/excel/importExcel';
import { iALARM_TYPES } from 'types/manifest-types';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import { isWithinRange } from '../../../../common/utils/numberUtils';
import * as UserUtils from 'common/userUtils';
import { minimumAccessLevel } from '../../../../common/userUtils';

const m = new Manifest();

const priorityOptions = [
  { value: 0, text: '0' },
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' },
  { value: 6, text: '6' },
  { value: 7, text: '7' },
  { value: 8, text: '8' },
  { value: 9, text: '9' },
];

let _data: iALARM_TYPES[] = [];

const AlarmTypes = (props: any): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');
  const [changes, setChanges, changesRef] = useStateRef(false);
  const [, setRenderCount] = useState(0);
  const [importDisplay, setImportDisplay] = useState(false);
  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  /* eslint-disable react/prop-types */

  const getManifestData = () => {
    let maxExternalId = 100;
    if (minimumAccessLevel(UserUtils.UAG.ADMIN)) {
      maxExternalId = 1000;
    }
    const tmp = cloneDeep(m.alarmPriorities).filter(
      (f) => f.ExternalId < maxExternalId
    );

    _data = tmp;
  };

  const loadDataEvent = () => {
    if (!changes) getManifestData();
  };

  function getProperty(id: number, property: string): unknown {
    const d = _data.find((e) => e.ID == id);
    if (d === undefined) {
      return 0;
    }
    const value = _.get(d, property, 0);
    return value;
  }

  function hasChanged(id: number, property: string): boolean {
    const localRecord = _data.find((e) => e.ID == id);
    const manifestRecord = m.alarmPriorities.find((e) => e.ID == id);
    if (localRecord !== undefined && manifestRecord !== undefined) {
      const a = _.get(localRecord, property, 0);
      const b = _.get(manifestRecord, property, 0);
      const changed = a != undefined && b != undefined && a != b;
      // console.log(
      //   'Alarm Type Change',
      //   'Changed: ',
      //   changed,
      //   _.get(localRecord, property, 0),
      //   _.get(manifestRecord, property, 0)
      // );
      return changed;
    }
    return false;
  }

  const setProperty = (id: number, property: string, value: unknown) => {
    const localRecord = _data.find((e) => e.ID == id);
    const manifestRecord = m.alarmPriorities.find((e) => e.ID == id);
    if (localRecord !== undefined && manifestRecord !== undefined) {
      _.set(localRecord, property, value);
      // _.set(manifestRecord, property, value);
      setChanges(true);
      setRenderCount((old) => old + 1);
    } else {
      console.log('Alarm type not found');
    }
  };

  const columns = React.useMemo<Column<AlarmPriority>[]>(() => {
    return [
      {
        Header: 'ID',
        accessor: 'ID',
        width: 10,
      },
      {
        Header: 'Description',
        id: 'Description',
        accessor: 'Description',
        // maxWidth: 60,
        // minWidth: 60,
        // width: 60,
        maxWidth: 250,
        width: 250,
        Cell: (data: Cell<AlarmPriority>) => {
          const text = data.row.original.Description;
          return (
            <Box pad={{ left: '10px' }} width="250px">
              <Text truncate={true} textAlign="start" weight="bold">
                {text}
              </Text>
            </Box>
          );
        },
      },

      {
        Header: 'Alarm Event Code',
        id: 'ExternalId',
        accessor: 'ExternalId',
        maxWidth: 60,
        minWidth: 60,
        width: 60,
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.ExternalId}
            hasChanged={hasChanged(props.row.values.ID, 'ExternalId')}
            valueProperty={`ExternalId`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="number"
            min={0}
            max={99}
          />
        ),
        // disableFilters: true,
      },

      {
        Header: 'Status Code',
        accessor: 'StatusCode',
        maxWidth: 40,
        minWidth: 40,
        width: 40,
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.StatusCode}
            valueProperty={`StatusCode`}
            hasChanged={hasChanged(props.row.values.ID, 'StatusCode')}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="number"
            min={0}
            max={99}
          />
        ),
        // disableFilters: true,
      },
      {
        Header: 'Status Code (Battery Low)',
        accessor: 'StatusCodeBatteryLow',
        maxWidth: 40,
        minWidth: 40,
        width: 40,
        Cell: ({ ...props }) => {
          if (props.row.original.StatusCodeBatteryLow < 100)
            return (
              <InputCell
                id={props.row.values.ID}
                reRenderValue={props.row.original.StatusCodeBatteryLow}
                valueProperty={`StatusCodeBatteryLow`}
                hasChanged={hasChanged(
                  props.row.values.ID,
                  'StatusCodeBatteryLow'
                )}
                getProperty={getProperty}
                setProperty={setProperty}
                inputType="number"
                min={0}
                max={99}
              />
            );

          return <></>;
        },
        // disableFilters: true,
      },
      {
        id: 'Priority',
        Header: 'Priority',
        accessor: 'Priority',
        width: 40,

        Cell: ({ ...props }) => (
          <SelectCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Priority}
            hasChanged={hasChanged(props.row.values.ID, 'Priority')}
            valueProperty={`Priority`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="number"
            options={priorityOptions}
          />
        ),
        // disableFilters: true,
      },
      // {
      //   id: 'IsTechnical',
      //   Header: 'Technical Alarm',
      //   accessor: 'IsTechnical',
      //   width: 40,

      //   Cell: ({ ...props }) => (
      //     <CheckBoxCell
      //       id={props.row.values.ID}
      //       reRenderValue={props.row.original.IsTechnical}
      //       hasChanged={hasChanged(props.row.values.ID, 'IsTechnical')}
      //       valueProperty={`IsTechnical`}
      //       getProperty={getProperty}
      //       setProperty={setProperty}
      //     />
      //   ),
      //   // disableFilters: true,
      // },
    ];
  }, []);

  const save = () => {
    const complete = (str: string) => {
      setChanges(false);
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
    };

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    // save chanegs to manifest
    _data.forEach((d) => {
      const manifestRecord = m.alarmPriorities.find((e) => e.ID == d.ID);
      if (manifestRecord) {
        manifestRecord.ExternalId = d.ExternalId;
        manifestRecord.StatusCode = d.StatusCode;
        manifestRecord.StatusCodeBatteryLow = d.StatusCodeBatteryLow;
        manifestRecord.Priority = d.Priority;
      }
    });

    let str = 'Save failed';
    m.save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('alarmTypesTable.saveFailed', {
            status: status,
            error: {},
          });
        }

        complete(str);
      })
      .catch((error) => {
        logger.info('alatmTypesTable.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        console.error('arc # save fail:', error);
        complete(str);
      });
  };

  const exportExcel = () => {
    generateAlarmTypesReport();
  };

  const ContextButtons = (
    <Box direction="row">
      <Button
        plain={false}
        size="small"
        color={changesRef.current == true ? 'brand' : 'grey-4'}
        badge={changesRef.current == true}
        disabled={changesRef.current == false}
        label="Save"
        tip="Save changes"
        onClick={() => {
          save();
        }}
      />
      <Button
        plain={false}
        size="small"
        label="Export"
        tip="Export to Excel"
        margin={{ left: '8px' }}
        onClick={() => {
          exportExcel();
        }}
        disabled={changesRef.current == true}
      />
      <Button
        plain={false}
        size="small"
        label="Import"
        tip="Import from Excel. Please use the export file as a template."
        margin={{ left: '8px' }}
        onClick={() => {
          setImportDisplay(true);
        }}
        disabled={false}
      />
      <Button
        plain={false}
        size="small"
        margin={{ left: '8px' }}
        color={changesRef.current == true ? 'brand' : 'grey-4'}
        disabled={changesRef.current == false}
        label="Undo Changes"
        tip="Remove any changes (these are highlighted in yellow)"
        onClick={() => {
          getManifestData();
          setChanges(false);
          setRenderCount((old) => old + 1);
        }}
      />
    </Box>
  );

  const doImport = (data: string[][]) => {
    data.forEach((d: string[]) => {
      console.log('import line', d);
      const localRecord = _data.find((e) => e.ID == Number(d[0]));
      const manifestRecord = m.alarmPriorities.find(
        (e) => e.ID == Number(d[0])
      );
      if (localRecord !== undefined && manifestRecord !== undefined) {
        // d[1] is the description - ignore this - might be useful later for importing if we want to change alarm text?
        // need to do checks on cells for correct types
        if (isWithinRange(d[2], 0, 99)) _.set(localRecord, 'ExternalId', d[2]);
        if (isWithinRange(d[3], 0, 99)) _.set(localRecord, 'StatusCode', d[3]);
        if (isWithinRange(d[4], 0, 99))
          _.set(localRecord, 'StatusCodeBatteryLow', d[4]);
        if (isWithinRange(d[5], 0, 9)) _.set(localRecord, 'Priority', d[5]);
        // _.set(localRecord, 'IsTechnical', d[6]);
      } else {
        console.log('Alarm type not found');
      }
    });
    setChanges(true);
    setRenderCount((old) => old + 1);
  };

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: ['ID'],
      pageSize: 20,
      sortBy: [],
    }),
    []
  );

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
  ];

  // console.log(_data);

  return (
    <>
      <Tile
        title="Alarm Types"
        eventListeners={events}
        setChildEvent={SetChildEvent}
        ContextButtons={ContextButtons}
        waitDiaglogueProps={dialogueProps}
      >
        <>
          <ImportExcel
            title={'Import Alarm Types, Codes and Priorities'}
            numberOfColumns={6}
            expectedHeaders={[
              'ID',
              'Description',
              'Event Code',
              'Status Code',
              'Status Code (Battery Low)',
              'Priority',
              // 'Technical Alarm',
            ]}
            doImport={async (d: string[][]) => {
              doImport(d);
            }}
            display={importDisplay}
            setDisplay={setImportDisplay}
            allowAppend={false}
          />

          {_data && _data.length > 0 && (
            <TablePaged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={columns}
              initialState={initialState}
              data={_data}
              showCheckboxes={false}
              reportDescription={{
                header: '',
                filename: 'AlarmTypes',
              }}
              showControlsExports={false}
            />
          )}
        </>
      </Tile>
    </>
  );
};

export default AlarmTypes;
