import { Worksheet } from 'exceljs';
import store from 'store/store';
import * as sitesslice from 'store/sitesSlice';
import { getCurrentUsersName } from '../userUtils';
import { formatDate } from '../utils/dateUtils';

export const headerRows = (sheet: Worksheet): void => {
  const selectedSite = sitesslice.getSelected(store.getState());

  let Row = sheet.addRow(['Site:', selectedSite?.name]);
  Row.getCell(1).style.font = {
    size: 11,
    bold: true,
  };

  Row = sheet.addRow(['Created By:', getCurrentUsersName()]);
  Row.getCell(1).style.font = {
    size: 11,
    bold: true,
  };

  Row = sheet.addRow(['Date of Report:', formatDate(new Date())]);
  Row.getCell(1).style.font = {
    size: 11,
    bold: true,
  };
};
