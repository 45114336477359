import React, { FC, MutableRefObject, useEffect, useState } from 'react';
import { Tip, Text, Box } from 'grommet';
//import * as Icons from 'grommet-icons';

export interface IDCellProps {
  id: number;
  changesRef?: MutableRefObject<number[]>;
  toolTip?:
    | undefined
    | string
    | JSX.Element
    | ((id: unknown) => JSX.Element | undefined);
  reRenderValue?: unknown;
}

const IDCell: FC<IDCellProps> = ({ id, changesRef, toolTip }) => {
  const value = id;
  const [, setComponentRenderCount] = useState(0);
  const changed = changesRef ? changesRef.current.includes(id) : false;

  useEffect(() => {
    setComponentRenderCount((old) => old + 1);
  }, [changesRef]);

  let _toolTip: string | JSX.Element | undefined = undefined;

  if (typeof toolTip === 'function') {
    _toolTip = toolTip(id);
  } else if (toolTip) {
    _toolTip = toolTip;
  }

  let colour = undefined;
  if (changed) {
    colour = 'red';
  }

  //{changed && <Icons.Alert color="yellow" size="15px" />}
  const cell = (
    <Box
      pad="none"
      direction="row"
      justify="center"
      align="center"
      style={{ color: colour }}
    >
      <Text>{value}</Text>
    </Box>
  );

  return (
    <>
      {_toolTip && <Tip content={_toolTip}>{cell}</Tip>}
      {_toolTip === undefined && <>{cell}</>}
    </>
  );
};

export default React.memo(IDCell);
