import React from 'react';
import useState from 'react-usestateref';
import Tile, {
  iTileEvent,
  iMenuProps,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Box, Button, Select, Form, Layer } from 'grommet';

import { Manifest, teErrorCode } from 'components/installation/manifest';
import * as ManifestTypes from 'types/manifest-types';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import TablePaged from 'components/tables/TablePaged';
import { Column } from 'react-table';
import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
import InputCell from 'components/tables/cellPresentationComponents/inputCell';
//import CheckBoxCell from 'components/tables/cellPresentationComponents/checkBoxCell';
import IDCell from 'components/tables/cellPresentationComponents/idCell';
import { useWinstonLogger } from 'winston-react';
import * as IoTHandsets from 'common/IoT/IoTHandset';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';
import { getAllHandsetGroups } from 'components/installation/careGroupUtils';
import { Edit } from 'grommet-icons';
import { AlignedFormFields, iField } from 'components/forms/formFields';
import { GroupHandset } from 'components/installation/groupHandset';
import HandsetQRCode from './handsetQRCode';
import HandsetAssignDialogue from './handsetAssignDialogue';
import * as IotDeviceMgmt from 'common/IoT/Helpers/DeviceManagement';
import { teDEVICE_MODELS } from 'types/manifest-enums';

const manifest = new Manifest();

interface iTableData
  extends Partial<ManifestTypes.iHandset>,
    Partial<ManifestTypes.iDevice> {
  TableID: number;
}

const blankTableEntry: iTableData = {
  ID: 0,
  TableID: 0,
  Description: 'blank',
  DeviceId: 0,
  Type: 0,
  HwModel: 0,
  InUse: 0,
  LoggedOn: 0,
  FeatureMask: 0,
};

let _allData: iTableData[] = [];
let _handsetGroups: ManifestTypes.iGroupHandset[] = [];
let _handsetsInGroupIDs: (number | undefined)[] = [];

const HandsetConfigTable = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [renderCount, setRenderCount] = useState(0);
  const [changes, setChanges, changesRef] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<iTableData[]>([]);
  const [selectedHandsetGroup, setSelectedHandsetGroup] = useState('-1');
  const [groupChanges, setGroupChanges] = useState(false);
  const [editGroupName, setEditGroupName] = useState<number | undefined>(
    undefined
  );

  const [showAddDialogue, setShowAddDialogue] = useState(false);
  const [showQRCode, setshowQRCode] = useState(false);
  const [QRCodeInfo, setQRCodeInfo] = useState({ id: 0, mac: '' });

  const loadData = () => {
    _allData = _.cloneDeep(manifest.handsets);

    _handsetGroups = getAllHandsetGroups();
    // console.log(_handsetGroups);

    setChanges([]);
    setGroupChanges(false);

    setRenderCount((old) => old + 1);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
  ];

  /**
   * @param id    handset device id
   * @param property item to change in the room unit object
   * @param value new value
   */
  const setProperty = (id: number, property: string, value: unknown) => {
    const device = _allData.find((e) => e.ID == id);
    if (device) {
      const manifestRecord = manifest.handsets.find((e) => e.ID == id);

      const setIt = () => {
        if (!changesRef.current.includes(id)) {
          changesRef.current.push(id);
          setRenderCount((old) => old + 1);
        }
      };

      _.set(device, property, value);

      if (manifestRecord) {
        if (_.get(manifestRecord, property, 0) != value) {
          setIt();
        } else {
          _.pull(changesRef.current, id);
          setRenderCount((old) => old + 1);
        }
      } else {
        console.error('Manifest record not found for device:', device);
      }
    }
  };

  function getProperty(id: number, property: string): unknown {
    let device = _allData.find((e) => e.ID == id);
    if (device === undefined) {
      device = blankTableEntry;
    }
    const value = _.get(device, property, 0);
    return value;
  }

  // add or remove a handset from a group
  function toggleHandsetInGroup(tableID: number, loggedOn: boolean) {
    const hg = manifest.groupHandsets.find(
      (e) => e.ID == Number(selectedHandsetGroup)
    );

    // console.log(hg, tableID);
    if (hg) {
      if (_handsetsInGroupIDs.includes(tableID)) {
        hg.removeHandsetFromGroup(tableID);
      } else {
        hg.addHandsettoGroup(tableID, loggedOn);
      }
      _handsetsInGroupIDs = hg.getHandsetsInGroup();
      setGroupChanges(true);
      setRenderCount((old) => old + 1);
    }
  }

  /**
   * The aim of all the cell components is that they do not render unnecessarily
   * All the cell components use React.memo to memorise the component.
   *
   * React.memo will only re-generate the component if a prop changes therefore we pass a reRenderValue prop
   * to the component to allow us to signal to React.memo that it needs to be re-rendered.
   *
   * The components use callback to set/get their data and not state varibles
   */
  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        width: '60px',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <IDCell
            id={props.row.values.ID}
            changesRef={changesRef}
            reRenderValue={changesRef.current.includes(props.row.values.ID)}
          />
        ),
        disableFilters: true,
      },
      {
        width: '300px',
        Header: 'Name',
        accessor: 'Description',
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => (
          <InputCell
            id={props.row.values.ID}
            reRenderValue={props.row.original.Description}
            valueProperty={`Description`}
            getProperty={getProperty}
            setProperty={setProperty}
            inputType="text"
          />
        ),
        disableFilters: true,
      },
      {
        id: 'TableID',
        Header: 'In Group',
        accessor: 'TableID',

        Cell: ({ ...props }) => {
          return (
            <Button
              label={
                _handsetsInGroupIDs.includes(props.row.values.TableID)
                  ? 'Yes'
                  : 'No'
              }
              onClick={() => {
                toggleHandsetInGroup(
                  props.row.values.TableID,
                  props.row.values.LoggedOn
                );
              }}
            />
          );
        },

        disableFilters: true,
      },
      {
        id: 'LoggedOn',
        Header: 'LoggedOn',
        accessor: (row) => {
          return row.LoggedOn ? 'Yes' : 'No';
        },

        disableFilters: true,
      },
    ],
    [renderCount, selectedHandsetGroup] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : [],
      pageSize: 25,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const save = () => {
    logger.info('handsetConfigTable.save', {
      changedIDs: changesRef.current,
    });
    for (const index in changesRef.current) {
      const id = changesRef.current[index];
      const stateRecord = _allData.find((e) => e.ID === id);
      let device = manifest.handsets.find((e) => e.ID === id);
      if (!stateRecord || !device) {
        console.error('handset not found during save');
        continue;
      }
      device = _.merge(device, stateRecord);
    }

    const complete = (str: string) => {
      setDialogueProps({
        show: true,
        dialogueText: str,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
      setChanges([]);
      setGroupChanges(false);
    };

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait',
      showSpinner: true,
      showOkButton: false,
    });

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
          logger.info('handsetConfigTable.saveFailed', {
            status: status,
            error: {},
          });
        }

        complete(str);
      })
      .catch((error) => {
        logger.info('handsetConfigTable.saveFailed', {
          status: teErrorCode.E_ERROR,
          error: error,
        });
        console.error('arc # save fail:', error);
        complete(str);
      });
  };

  const setHandsetStatus = async (
    handset: iTableData,
    logon: boolean
  ): Promise<void> => {
    let resp = undefined;

    if (handset.MacAddress === undefined) return;
    const device = _allData.find((e) => e.MacAddress == handset.MacAddress);
    if (device === undefined) return;

    try {
      const response = await IoTHandsets.setHandsetLogonStatus(
        handset.MacAddress,
        logon,
        null
      );

      resp = response.msgHeader
        ?.msg as IoTHandsets.tsCLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_RESP;

      console.info('Status Resposne:', resp);

      const manifestHandset = manifest.handsets.find((e) => {
        return e.ID == handset.ID;
      });

      if (manifestHandset) {
        manifestHandset.LoggedOn = resp.logonstatus ? 1 : 0;
        device.LoggedOn = resp.logonstatus ? 1 : 0;
      }
    } catch (e) {
      console.info('No response from the SCU');
    }
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={changes.length > 0 || groupChanges ? 'brand' : 'grey-4'}
      badge={changes.length > 0 ? changes.length : false}
      tip="Save changes"
      disabled={changes.length > 0 || groupChanges ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Log off selected handsets',
          icon: <Icons.Logout size="medium" />,
          onClick: () => {
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to log off the selected handsets?`,
              dialogueTextLine2: `The handsets will no longer receiver alarm calls.`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress: async () => {
                setDialogueProps({
                  show: true,
                  dialogueText: 'Please Wait...',
                  showSpinner: true,
                  showOkButton: false,
                });
                for (const index in selectedRows) {
                  await setHandsetStatus(selectedRows[index], false);
                }
                setDialogueProps(undefined);
                setRenderCount((old) => old + 1);
                await IoTSCUManifest.Instance.requestManifestUpload(null);
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
          },
          disabled: selectedRows.length == 0,
        },
        {
          label: 'Log on selected handsets',
          icon: <Icons.Login size="medium" />,
          onClick: () => {
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to log on the selected handsets?`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress: async () => {
                setDialogueProps({
                  show: true,
                  dialogueText: 'Please Wait...',
                  showSpinner: true,
                  showOkButton: false,
                });
                for (const index in selectedRows) {
                  await setHandsetStatus(selectedRows[index], true);
                }
                setDialogueProps(undefined);
                setRenderCount((old) => old + 1);
                await IoTSCUManifest.Instance.requestManifestUpload(null);
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
          },
          disabled: selectedRows.length == 0,
        },

        {
          label: 'Assign Handset',
          icon: <Icons.Login size="medium" />,
          onClick: () => {
            setShowAddDialogue(true);
          },
          disabled: false,
        },
        {
          label: 'Display commissioning QR Code',
          icon: <Icons.Qr size="medium" />,
          onClick: () => {
            console.info('selectedRows[0]:', JSON.stringify(selectedRows[0]));

            if (
              selectedRows[0].ID !== undefined &&
              selectedRows[0].MacAddress !== undefined
            ) {
              setQRCodeInfo({
                id: selectedRows[0].TableID,
                mac: '0x' + selectedRows[0].MacAddress.toString(16),
              });
              setshowQRCode(true);
            }
          },
          disabled:
            selectedRows.length != 1 ||
            selectedRows[0].HwModel != teDEVICE_MODELS.DEVICE_MODEL_V3,
        },

        {
          label: 'Delete Handset',
          icon: <Icons.Trash size="medium" />,
          onClick: () => {
            console.info('selectedRows[0]:', JSON.stringify(selectedRows[0]));
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to delete this handset?`,
              dialogueTextLine2: `Name: ${selectedRows[0].Description}`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress() {
                if (selectedRows[0].MacAddress) {
                  logger.info('handsetConfigTable.deleteDevice', {
                    device: selectedRows[0],
                  });
                  IotDeviceMgmt.deleteDevice(
                    selectedRows[0].MacAddress,
                    setDialogueProps
                  );
                }
              },
              cancelButtonPress() {
                setDialogueProps(undefined);
              },
            });
          },
          disabled: selectedRows.length != 1,
        },

        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('handsetConfigTable.discardChanges');
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [selectedRows] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function changeHandsetGroup(value: any) {
    console.log('Changed Handset Group', value);
    const hg = manifest.groupHandsets.find((e) => e.ID == Number(value));
    console.log(hg);
    if (hg) _handsetsInGroupIDs = hg.getHandsetsInGroup();
    // console.log(_handsetsInGroupIDs);
    setSelectedHandsetGroup(value);
  }

  const EditGroupName = () => {
    const [values, setValues] = useState<GroupHandset | undefined>(
      manifest.groupHandsets.find((e) => e.ID == editGroupName)
    );

    if (editGroupName === undefined || values === undefined) {
      setEditGroupName(undefined);
      return <></>;
    }

    const hg = manifest.groupHandsets.find((e) => e.ID == values.ID);
    if (hg === undefined) {
      setEditGroupName(undefined);
      return <></>;
    }

    const groupEditFields: iField[] = [
      { label: 'Name', id: 'Description', type: 'text', column: 1 },
    ];

    const ammendData = async () => {
      if (hg !== undefined) {
        // This will change the data in the actual caregroup instance
        hg.Description = values.Description;

        // Load data pulls all the manifest instance data as so will pull the change we have just made
        loadData();
        setGroupChanges(true);
        setEditGroupName(undefined);
        setRenderCount((old) => old + 1);
      }
    };

    return (
      <Box margin="medium" pad="small" gap="small" width="600px">
        <Form
          value={values}
          onChange={(nextValue) => {
            setValues(nextValue);
            console.log(values);
          }}
          onSubmit={async () => {
            await ammendData();
            setEditGroupName(undefined);
          }}
        >
          <AlignedFormFields
            displayfields={groupEditFields}
            columnLayouts={[{ columnPercentWidth: 100, labelWidth: '60px' }]}
          />

          <Box margin="10px"></Box>
          <Box direction="row" gap="medium" justify="center">
            <Button
              label="Amend"
              size="medium"
              plain={false}
              margin="small"
              type="submit"
            />
            <Button
              label="Cancel"
              size="medium"
              plain={false}
              margin="small"
              onClick={() => {
                setEditGroupName(undefined);
              }}
            />
          </Box>
        </Form>
      </Box>
    );
  };

  return (
    <Tile
      title="Handsets and Handset Groups"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <HandsetQRCode
          show={showQRCode}
          setShow={setshowQRCode}
          handsetId={QRCodeInfo.id}
          mac={QRCodeInfo.mac}
        />

        <HandsetAssignDialogue
          show={showAddDialogue}
          setShow={setShowAddDialogue}
          setDialogueProps={setDialogueProps}
        />

        {editGroupName && (
          <Layer
            position="center"
            background="dialogBackground"
            style={{ whiteSpace: 'pre-wrap' }}
            modal={true}
          >
            <EditGroupName />
          </Layer>
        )}

        <Box>
          <Box width="50%" alignSelf="center" margin="small">
            <span>
              <Select
                placeholder="Select a handset group..."
                labelKey="Description"
                valueKey={{ key: 'ID', reduce: true }}
                options={_handsetGroups}
                value={selectedHandsetGroup}
                size="medium"
                onChange={({ option }) => {
                  console.info('option:', option);
                  changeHandsetGroup(option.ID);
                }}
              />

              <Edit
                style={{
                  marginLeft: '10px',
                }}
                size="16px"
                cursor="pointer"
                onClick={() => {
                  if (selectedHandsetGroup !== '-1' && _allData !== undefined) {
                    setEditGroupName(Number(selectedHandsetGroup));
                  }
                }}
              />
            </span>
          </Box>
          {selectedHandsetGroup != '-1' && (
            <TablePaged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={columns}
              initialState={initialState}
              data={_allData ? _allData : []}
              reportDescription={{
                header: '',
                filename: 'HandsetConfig',
              }}
              showCheckboxes={true}
              onSelectRow={(rows: iTableData[]) => {
                setSelectedRows(rows);
              }}
            />
          )}
        </Box>
      </>
    </Tile>
  );
};

export default HandsetConfigTable;
