/** @module Installation */

import { iSCHEDULE, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';
import * as Utils from 'common/utils/dateUtils';
import * as _ from 'lodash';
import * as eventDispatcher from 'store/eventDispatcher';
// import { SCUSQLInjection } from 'common/IoT/IoTSCUSQLInjection';
// const SQLInjection = SCUSQLInjection.Instance;

/*
 * A Schedule
 * @class
 * @hideconstructor
 * @implements iEndpoint_CC_SIP
 */

export const SUNDAY = 0b0000001;
export const MONDAY = 0b0000010;
export const TUESDAY = 0b0000100;
export const WEDNESDAY = 0b0001000;
export const THURSDAY = 0b0010000;
export const FRIDAY = 0b0100000;
export const SATURDAY = 0b1000000;
export const ALLDAYSOFWEEK = 0b1111111;

export const iSCHEDULE_defaults: iSCHEDULE = {
  ID: 0,
  ObjectID: 0,
  ObjectType: 0,
  DaysOfWeekBitmap: 0,
  StartTime: 0,
  StopTime: 0,
  AllDay: 0,
};

export class Schedule implements iSCHEDULE {
  /** iSCHEDULE start */
  ID = invalidID;
  ObjectID = 0;
  ObjectType = 0;
  DaysOfWeekBitmap = 0;
  StartTime = 0;
  StopTime = 0;
  AllDay = 0;

  /** Added in R5.11 but changed in R5.12 to string*/
  Data1 = '';
  Data2 = '';
  /** iSCHEDULE end */

  // Got software version incase we need to do processsing differently for
  // different software revsions in the future
  softwareVersion = 0;

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(
    values: iSCHEDULE,
    keys: string[],
    tableName: string,
    softwareVersion: number
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
  }

  /**
   * @param objectType
   * @param objectId  ID is specific to the objectType
   * @param dayBitmask Bit 0=Sunday, Bit 1=Monday, ... , Bit 6 = Saturday
   * @param timeRange Only the hours,minutes and seconds are used from the DATE objects.  If undefined then it will be an all day event
   */
  public updateSchedule(schedule: Partial<iSCHEDULE>): void {
    const obj = Schedule.createObject({ ID: this.ID, ...schedule });

    Object.assign(this, obj);
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });

    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }

  /**
   *  This will create a new schedule in the local manifest and also save straight away to the SCU.
   *  A systemEventTopics.MANIFEST , systemEventStates.UPDATED event will be raised once successfull.
   *
   *  SCHEDULES_OBJECT_TYPE_ROUTING_EVENT :  The objectId == ROUTING_EVENTS.ID
   *  SCHEDULES_OBJECT_TYPE_CARE_SEQUENCE_ENTRY: The objectId == CARE_SEQUENCE.ID
   *  SCHEDULES_OBJECT_TYPE_RESIDENT_OK: The objectId == RESIDENT.HomeDeviceId
   */
  static async createSchedule(schedule: Partial<iSCHEDULE>): Promise<void> {
    const nextId = manifestUtils
      .getManifestInstance()
      .getNextTableId_Schedule();

    const obj = Schedule.createObject({ ID: nextId, ...schedule });

    console.info(`createSchedule obj:${JSON.stringify(obj)}`);

    const insertSql =
      `INSERT INTO SCHEDULES (ID,ObjectID,ObjectType,DaysOfWeekBitmap,StartTime,StopTime,AllDay,Data1,Data2) ` +
      `VALUES(${obj.ID},${obj.ObjectID},${obj.ObjectType},${obj.DaysOfWeekBitmap},${obj.StartTime},${obj.StopTime},${obj.AllDay},'','')\n`;

    const status = await manifestUtils.executeSQL(
      insertSql,
      '',
      manifestUtils.teDB_BACKUPMETHOD.E_DB_BACKUPMETHOD_DISK_ONLY
    );
    if (status !== manifestUtils.teErrorCode.E_OK) {
      console.error('Failed to run sql:', insertSql);
      return;
    }

    // OPtimitically add to the manifest
    await manifestUtils.despatchInsertToManifestStore(
      'SCHEDULES',
      obj,
      _.keys(obj)
    );

    const row = new Schedule(
      obj,
      _.keys(obj),
      'SCHEDULES',
      manifestUtils.getManifestInstance().getSCUSoftwareVersion()
    );
    manifestUtils.getManifestInstance().schedules.push(row);

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.UPDATED,
      null,
      true
    );
  }

  /**
   *  This will delete the specified schedule in the local manifest and also save straight away to the SCU.
   *  A systemEventTopics.MANIFEST , systemEventStates.UPDATED event will be raised once successfull.
   */
  static async deleteSchedule(
    scheduleTableId: number
  ): Promise<manifestUtils.teErrorCode> {
    const manifestScheduleList = manifestUtils.getManifestInstance().schedules;
    const schedule = manifestScheduleList.find((e) => e.ID === scheduleTableId);
    if (schedule === undefined) return manifestUtils.teErrorCode.E_ERROR;

    const deleteSQL = `DELETE from SCHEDULES where ID=${scheduleTableId}`;

    const status = await manifestUtils.executeSQL(
      deleteSQL,
      '',
      manifestUtils.teDB_BACKUPMETHOD.E_DB_BACKUPMETHOD_DISK_ONLY
    );
    if (status !== manifestUtils.teErrorCode.E_OK) {
      console.error('Failed to run sql');
      return status;
    }

    await manifestUtils.despatchDeleteToManifestStore(
      schedule.manifestInfo.tablename,
      schedule,
      schedule.manifestInfo.manifestObjectKeys
    );

    _.remove(manifestScheduleList, function (e) {
      return e.ID === scheduleTableId;
    });

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.UPDATED,
      null,
      true
    );

    return manifestUtils.teErrorCode.E_OK;
  }

  static scheduleMaskToString(mask: number): string {
    const r =
      (mask & SUNDAY ? 'Su ' : '') +
      (mask & MONDAY ? 'Mo ' : '') +
      (mask & TUESDAY ? 'Tu ' : '') +
      (mask & WEDNESDAY ? 'We ' : '') +
      (mask & THURSDAY ? 'Th ' : '') +
      (mask & FRIDAY ? 'Fr ' : '') +
      (mask & SATURDAY ? 'Sa ' : '');
    return r;
  }

  static scheduleMaskToArray(mask: number): string[] {
    const r = [];

    if (mask & SUNDAY) r.push('Sun');
    if (mask & MONDAY) r.push('Mon');
    if (mask & TUESDAY) r.push('Tue');
    if (mask & WEDNESDAY) r.push('Wed');
    if (mask & THURSDAY) r.push('Thur');
    if (mask & FRIDAY) r.push('Fri');
    if (mask & SATURDAY) r.push('Sat');
    return r;
  }

  static ArrayToMask(arr: string[]): number {
    let r = 0;
    if (arr.includes('Sun')) r = r | SUNDAY;
    if (arr.includes('Mon')) r = r | MONDAY;
    if (arr.includes('Tue')) r = r | TUESDAY;
    if (arr.includes('Wed')) r = r | WEDNESDAY;
    if (arr.includes('Thur')) r = r | THURSDAY;
    if (arr.includes('Fri')) r = r | FRIDAY;
    if (arr.includes('Sat')) r = r | SATURDAY;

    return r;
  }

  static getScheduleText(schedule: iSCHEDULE): string {
    let txt = '';

    if (schedule.AllDay) txt = txt + 'All Day ';
    else {
      if (schedule.StartTime >= 0 && schedule.StopTime >= 0) {
        txt =
          txt +
          'Start ' +
          Utils.formatTimeOnlyHHMM(
            Utils.dateTodaySetSecondsFromMidnight(schedule.StartTime)
          ) +
          ' Stop ' +
          Utils.formatTimeOnlyHHMM(
            Utils.dateTodaySetSecondsFromMidnight(schedule.StopTime)
          );
      }
    }

    txt = txt + ' ' + this.scheduleMaskToString(schedule.DaysOfWeekBitmap);

    return txt;
  }

  static getScheduleTextTimeOnly(schedule: iSCHEDULE): string {
    let txt = '';

    if (schedule.StartTime && schedule.StopTime) {
      txt =
        txt +
        Utils.formatTimeOnlyHHMM(
          Utils.dateTodaySetSecondsFromMidnight(schedule.StartTime)
        ) +
        ' to ' +
        Utils.formatTimeOnlyHHMM(
          Utils.dateTodaySetSecondsFromMidnight(schedule.StopTime)
        );
    }

    return txt;
  }

  istoday(): boolean {
    // Get today's date
    const today = new Date().getDay(); // 0 = sunday, 1 = monday
    return (this.DaysOfWeekBitmap & (1 << today)) > 0;
  }

  private static createObject(schedule: Partial<iSCHEDULE>): iSCHEDULE {
    let _allDay = schedule.AllDay && schedule.AllDay > 0;
    if (schedule.StartTime === undefined || schedule.StopTime === undefined) {
      _allDay = true;
    }

    const obj: iSCHEDULE = {
      ...iSCHEDULE_defaults,
      ...schedule,
      AllDay: _allDay ? 1 : 0,
    };

    return obj;
  }
}
