import React, { useState } from 'react';
import TablePaged from 'components/tables/TablePaged';
import { Box, Grid, Button, Select, Layer, Form, Text } from 'grommet';
import * as UsersUtils from 'common/userUtils';
import * as SiteSlice from 'store/sitesSlice';
import * as Redux from 'react-redux';
import * as ReduxStore from 'store/store';
import * as SiteAuthUtils from 'common/siteAuthUtils';
import Tile, { iTileEvent, iMenuProps } from '../tile';
import * as Wait from 'components/dialogues/waitDialogue';
import { useWinstonLogger } from 'winston-react';
import * as Icons from 'grommet-icons';
import * as FormFields from 'components/forms/formFieldsOld';
import _ from 'lodash';
import { SendNewUserEmail } from '../../email/email';
import { BRAND_WIRELESSHEALTH, BRAND_TUNSTALL } from '../../themes/brands';

const newUserFields: FormFields.iField[] = [
  { label: 'Full Name', id: 'fullname', type: 'text' },
  { label: 'Email', id: 'email', type: 'text' },
  { label: 'Password', id: 'password', type: 'text' },
];

export interface iNewUserInput {
  fullname: string;
  email: string;
  password: string;
}

interface UserSite {
  siteName: string;
  siteID: string;
}

interface UserData extends UsersUtils.iUser {
  sites: UserSite[];
  accessLevel: UsersUtils.UAG;
}

type SelectOptions = { label: string; value: string };

type SelectOptionsReportInterval = {
  label: string;
  value: UsersUtils.eReportSchedule;
};

const userReportIntervalOptions: SelectOptionsReportInterval[] = [
  {
    value: UsersUtils.eReportSchedule.E_REPORT_INTERVAL_NONE,
    label: UsersUtils.getReportIntervalString(
      UsersUtils.eReportSchedule.E_REPORT_INTERVAL_NONE
    ),
  },
  {
    value: UsersUtils.eReportSchedule.E_REPORT_INTERVAL_DAILY,
    label: UsersUtils.getReportIntervalString(
      UsersUtils.eReportSchedule.E_REPORT_INTERVAL_DAILY
    ),
  },
  {
    value: UsersUtils.eReportSchedule.E_REPORT_INTERVAL_WEEKLY,
    label: UsersUtils.getReportIntervalString(
      UsersUtils.eReportSchedule.E_REPORT_INTERVAL_WEEKLY
    ),
  },
];

const events: iTileEvent[] = [];

const UserAdmin = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const logger = useWinstonLogger();
  const [userList, setUserList] = useState<UserData[]>([]);
  const [currentUser, setCurrentUser] = useState<UserData | undefined>();
  const allSites = Redux.useSelector(SiteSlice.getAll);

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();

  const [newUser, setNewUser] = useState<iNewUserInput | undefined>();

  const [siteSearch, setSiteSearch] = useState(allSites);
  const [showNewUserInput, setShowNewUserInput] = useState(false);

  const userColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'Access',
        accessor: 'accessLevel',
      },
      {
        Header: 'Sites',
        accessor: (row: UserData) => {
          let sitesString = '';
          if (
            row.accessLevel == UsersUtils.UAG.ADMIN ||
            row.accessLevel == UsersUtils.UAG.ELITE
          ) {
            sitesString = 'All Sites';
          } else {
            for (const index in row.sites) {
              if (sitesString.length > 0) sitesString += ', ';
              sitesString += row.sites[index].siteName;
            }
          }
          return sitesString;
        },
      },
    ],
    []
  );

  const password_generator = (len: number) => {
    const length = len ? len : 10;
    const string = 'abcdefghijklmnopqrstuvwxyz'; //to upper
    const numeric = '0123456789';
    const punctuation = '!@#$%^&*()_+~}{[]:;?><,./=';
    let password = '';
    let character = '';

    while (password.length < length) {
      const entity1 = Math.ceil(string.length * Math.random() * Math.random());
      const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      const entity3 = Math.ceil(
        punctuation.length * Math.random() * Math.random()
      );
      let hold = string.charAt(entity1);
      hold = password.length % 2 == 0 ? hold.toUpperCase() : hold;
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password
      .split('')
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join('');
    return password.substr(0, len);
  };

  const selectUser = async (
    rowData: UserData,
    selectionInfo?: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (selectionInfo === undefined || !selectionInfo.alreadySelected) {
      console.info('User Selected:', rowData);
      logger.info('userAdmin.selectUser', { user: rowData });
      // Perform a deepclone to ensure we don't copy object refs which will lead to
      // modifying the orginal data
      setCurrentUser(_.cloneDeep(rowData));
    }
  };

  const loadUsers = (): void => {
    setDialogueProps({
      show: true,
      dialogueText: `Please Wait...`,
      showSpinner: true,
      showOkButton: false,
    });

    UsersUtils.listUsers().then(async (userList) => {
      const users: UserData[] = [];
      let newUserRow: UserData | undefined = undefined;
      for (const index in userList) {
        const groups = await UsersUtils.listGroupsForUser(
          userList[index].username
        );
        const siteIDS = UsersUtils.getSitesFromGroups(groups);
        const userSites: UserSite[] = [];
        for (const index in siteIDS) {
          const id = siteIDS[index];
          const site = SiteSlice.selectById(ReduxStore.store.getState(), id);
          if (site) {
            const userSite: UserSite = { siteID: site.id, siteName: site.name };
            userSites.push(userSite);
          }
        }

        const UserData: UserData = {
          ...userList[index],
          sites: userSites,
          accessLevel: UsersUtils.getAccessLevelFromGroups(groups),
        };
        const usserDataClone = _.cloneDeep(UserData);
        users.push(usserDataClone);
        if (newUser !== undefined && userList[index].email === newUser.email) {
          newUserRow = usserDataClone;
        }
      }
      setUserList(users);
      console.info('sites:', allSites);

      if (newUser !== undefined && newUserRow !== undefined) {
        await selectUser(newUserRow);

        SendNewUserEmail(newUser);

        navigator.clipboard.writeText(newUser.password);

        setDialogueProps({
          show: true,
          dialogueText: `${newUser.fullname} has been created with the pasword: ${newUser.password} which has been copied to the clipboard`,
          dialogueTextLine2: `Now set the User level and the Authorised sites, then click save.`,
          showSpinner: false,
          showOkButton: true,
          okButtonPress: () => {
            setDialogueProps(undefined);
          },
        });

        setShowNewUserInput(false);
        setNewUser(undefined);
      } else {
        setDialogueProps(undefined);
      }
    });
  };

  function cancelUser(): void {
    setCurrentUser(undefined);
  }

  async function createUser(): Promise<void> {
    if (newUser === undefined) return;
    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait...',
      showSpinner: true,
      showOkButton: false,
    });

    const resp = await UsersUtils.createUser(
      newUser.fullname,
      newUser.email,
      newUser.password
    );

    console.info('UsersUtils.createUser resp:', resp);

    if (resp.success == true) {
      loadUsers();
    } else {
      setDialogueProps({
        show: true,
        dialogueText: `The user couldn't be created, error:`,
        dialogueTextLine2: resp.error,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
    }
  }

  async function saveUser(): Promise<void> {
    if (!currentUser) return;

    logger.info('userAdmin.save');

    if (!UsersUtils.minimumAccessLevel(UsersUtils.UAG.ADMIN)) {
      if (
        currentUser.accessLevel === UsersUtils.UAG.ADMIN ||
        currentUser.accessLevel === UsersUtils.UAG.ELITE
      ) {
        setDialogueProps({
          show: true,
          dialogueText:
            "You don't have permission to modify ADMIN or ELITE accounts",
          showSpinner: false,
          showOkButton: true,
          okButtonPress() {
            setDialogueProps(undefined);
          },
        });
        return;
      }
    }

    let userGroupsChanged = false;
    let accessLevelChanged = false;
    let userConfigChanged = false;

    setDialogueProps({
      show: true,
      dialogueText: 'Please Wait...',
      showSpinner: true,
      showOkButton: false,
    });

    // get the users data as stored in AWS
    const originalUserData = userList.find(
      (element) => element.username === currentUser.username
    );

    if (!originalUserData) {
      console.error("Couldn't find user in the original data:", currentUser);
      return;
    }

    // Look for sites that the user has been removed from by comparing the modified object
    // to that in AWS
    for (const index in originalUserData.sites) {
      const foundSite = currentUser.sites.find(
        (element) => element.siteID === originalUserData.sites[index].siteID
      );

      if (!foundSite) {
        console.info(
          'Delete user ' +
            currentUser.name +
            ' from site ' +
            originalUserData.sites[index].siteName
        );
        await SiteAuthUtils.removeUserAccessForSite(
          currentUser.username,
          originalUserData.sites[index].siteID
        );
        userGroupsChanged = true;
      }
    }

    // Look for sites that the user has been added to
    for (const index in currentUser.sites) {
      const foundSite = originalUserData.sites.find(
        (element) => element.siteID === currentUser.sites[index].siteID
      );

      if (!foundSite) {
        console.info(
          'Adding user ' +
            currentUser.name +
            ' to site ' +
            currentUser.sites[index].siteName
        );
        await SiteAuthUtils.addUserAccessForSite(
          currentUser.username,
          currentUser.sites[index].siteID
        );
        userGroupsChanged = true;
      }
    }

    if (originalUserData.accessLevel != currentUser.accessLevel) {
      console.info('User access level change to:', currentUser.accessLevel);
      await UsersUtils.setUserAccessRights(
        currentUser.username,
        currentUser.accessLevel
      );

      accessLevelChanged = true;
    }

    if (userGroupsChanged || accessLevelChanged) {
      await SiteAuthUtils.setupUserIotAuthorisation(currentUser.username);
    }

    if (
      originalUserData.userConfig.reportInterval !=
        currentUser.userConfig.reportInterval ||
      originalUserData.userConfig.brand != currentUser.userConfig.brand
    ) {
      await UsersUtils.updateUserConfig(
        currentUser.username,
        currentUser.userConfig
      );
      userConfigChanged = true;
    }

    if (userGroupsChanged || accessLevelChanged || userConfigChanged) {
      loadUsers();
    } else {
      console.info('Save completed');
      setDialogueProps(undefined);
    }

    setNewUser(undefined);

    //reload window if the brand has changed
    if (
      originalUserData.userConfig.brand != currentUser.userConfig.brand &&
      currentUser.username == UsersUtils.getCurrentCognitoUsername()
    )
      window.location.reload();
  }

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: [''],
      pageSize: 5,
    }),

    []
  );

  function siteSelectionChange(value: SelectOptions[]): void {
    if (currentUser) {
      const userSites: UserSite[] = value.map((site) => {
        if (site) {
          return { siteName: site.label, siteID: site.value };
        } else {
          return { siteName: '', siteID: '' };
        }
      });
      const newcurrentUser: UserData = _.cloneDeep(currentUser);
      newcurrentUser.sites = userSites;
      setCurrentUser(newcurrentUser);
    }
  }

  function getUsersSiteList(): string {
    const maxSitesListed = 4;
    let siteList = '';
    let sitesListed = 0;
    if (currentUser) {
      currentUser.sites.map((site) => {
        if (sitesListed < maxSitesListed) {
          siteList += site.siteName + ',';
        }
        sitesListed++;
      });
    }

    if (sitesListed > maxSitesListed) {
      siteList += ` + ${sitesListed - maxSitesListed} others`;
    }

    if (siteList.endsWith(',')) {
      return siteList.slice(0, -1);
    }

    return siteList;
  }

  React.useEffect(() => {
    loadUsers();
    // UseEffect's cleanup function
    return () => {
      setUserList([]);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Create New User',
          icon: <Icons.Add size="medium" />,
          onClick: async () => {
            setCurrentUser(undefined);
            setShowNewUserInput(true);
          },
          disabled: false,
        },
        {
          label: 'Delete Selected User',
          icon: <Icons.Trash size="medium" />,
          onClick: async () => {
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to delete ${currentUser?.name}`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress: async () => {
                if (!currentUser) return;
                setDialogueProps({
                  show: true,
                  dialogueText: `Please Wait...`,
                  showSpinner: true,
                  showOkButton: false,
                });
                await UsersUtils.deleteUser(currentUser.username);
                setCurrentUser(undefined);
                loadUsers();
              },
              cancelButtonPress: () => {
                setDialogueProps(undefined);
              },
            });
          },
          disabled: currentUser && currentUser.username !== '' ? false : true,
        },
        {
          label: 'Set Temporary Password',
          icon: <Icons.Login size="medium" />,
          onClick: async () => {
            if (currentUser === undefined) return;
            const password = password_generator(10);
            navigator.clipboard.writeText(password);
            setDialogueProps({
              show: true,
              dialogueText: `Set user temporary password to ${password}`,
              dialogueTextLine2: `The password has been copied to the clipboard`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress: async () => {
                setDialogueProps({
                  show: true,
                  dialogueText: `Please Wait...`,
                  showSpinner: true,
                  showOkButton: false,
                });
                const response = await UsersUtils.setUserPassword(
                  currentUser.username,
                  password
                );

                console.info('Password response:', response);

                if (response.success == false) {
                  setDialogueProps({
                    show: true,
                    dialogueText: `Failed to set the password`,
                    dialogueTextLine2: response.error,
                    showSpinner: false,
                    showOkButton: true,
                    okButtonPress() {
                      setDialogueProps(undefined);
                    },
                  });
                } else {
                  await SendNewUserEmail({
                    fullname: currentUser.name,
                    email: currentUser.email,
                    password: password,
                  });
                  setDialogueProps({
                    show: true,
                    dialogueText: `The password has been set successfully`,
                    dialogueTextLine2: `Password: ${password}`,
                    showSpinner: false,
                    showOkButton: true,
                    okButtonPress() {
                      setDialogueProps(undefined);
                    },
                  });
                }
              },
              cancelButtonPress: () => {
                setDialogueProps(undefined);
              },
            });
          },
          disabled: currentUser && currentUser.username !== '' ? false : true,
        },
      ],
    }),
    [currentUser] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="User Admin"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
    >
      <div>
        {showNewUserInput && (
          <Layer
            style={{ whiteSpace: 'pre-wrap' }}
            modal={true}
            background="dialogBackground"
          >
            <Box align="center" margin="medium">
              <Box margin={{ bottom: 'medium' }}>
                <Text>Please enter the new user details</Text>
              </Box>

              <Form<iNewUserInput>
                value={
                  newUser
                    ? newUser
                    : {
                        fullname: '',
                        email: '',
                        password: password_generator(10),
                      }
                }
                onChange={(nextValue) => {
                  setNewUser(nextValue);
                }}
              >
                <Box direction="column">
                  <FormFields.AlignedFormFields
                    displayfields={newUserFields}
                    labelWidth={'80px'}
                  />
                </Box>

                <Box gridArea="buttons" direction="row" justify="center">
                  <Button
                    onClick={() => createUser()}
                    label="Save"
                    size="medium"
                    plain={false}
                    margin="small"
                  />
                  <Button
                    onClick={() => setShowNewUserInput(false)}
                    label="Cancel"
                    size="medium"
                    plain={false}
                    margin="small"
                  />
                </Box>
              </Form>
            </Box>
          </Layer>
        )}

        <Grid
          rows={['flex', 'flex', 'flex', 'fixed', 'fixed']}
          columns={['flex', 'flex', 'flex', 'flex']}
          gap="small"
          areas={[
            { name: 'table', start: [0, 0], end: [3, 0] },
            { name: 'Sites', start: [0, 1], end: [3, 1] },
            { name: 'Level', start: [0, 2], end: [0, 2] },
            { name: 'Brand', start: [1, 2], end: [1, 2] },
            { name: 'Log', start: [2, 2], end: [2, 2] },
            { name: 'buttons', start: [1, 4], end: [2, 4] },
          ]}
        >
          <Box gridArea="table">
            <TablePaged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={userColumns}
              initialState={initialState}
              data={userList}
              showCheckboxes={false}
              onLastSelectedRow={selectUser}
              currentSelectedID={currentUser && currentUser.username}
              selectorKeyName="username"
              // allowRowDrag={true}
              reportDescription={{
                header: '',
                filename: 'Users',
              }}
            />
          </Box>
          <Box gridArea="Sites">
            {currentUser && (
              <>
                <p>Authorised Sites</p>
                <Select
                  multiple={true}
                  labelKey="label"
                  valueKey="value"
                  options={siteSearch.map((site) => {
                    return { label: site.name, value: site.id };
                  })}
                  value={currentUser.sites.map((site) => {
                    return { label: site.siteName, value: site.siteID };
                  })}
                  onChange={({ value }) => siteSelectionChange(value)}
                  messages={{ multiple: getUsersSiteList() }}
                  onClose={() => setSiteSearch(allSites)}
                  onSearch={(text) => {
                    // The line below escapes regular expression special characters:
                    // [ \ ^ $ . | ? * + ( )
                    const escapedText = text.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      '\\$&'
                    );

                    // Create the regular expression with modified value which
                    // handles escaping special characters. Without escaping special
                    // characters, errors will appear in the console
                    const exp = new RegExp(escapedText, 'i');
                    const newList = allSites.filter((o) => exp.test(o.name));
                    console.info('Newlist:', newList);
                    setSiteSearch(newList);
                  }}
                />
              </>
            )}
          </Box>
          <Box gridArea="Level">
            {currentUser && (
              <>
                <p>User Level</p>

                <Select
                  labelKey="label"
                  valueKey="value"
                  options={UsersUtils.userTypeOptions}
                  value={{
                    value: currentUser.accessLevel,
                    label: currentUser.accessLevel,
                  }}
                  onChange={({ option }) => {
                    const newcurrentUser: UserData = _.cloneDeep(currentUser);
                    newcurrentUser.accessLevel = option.value;
                    setCurrentUser(newcurrentUser);
                  }}
                />
              </>
            )}
          </Box>
          <Box gridArea="Brand">
            {currentUser && (
              <>
                <p>Company Brand</p>

                <Select
                  labelKey="label"
                  valueKey="value"
                  disabled={
                    !UsersUtils.minimumAccessLevel(UsersUtils.UAG.ADMIN)
                  }
                  options={[
                    { label: BRAND_TUNSTALL, value: BRAND_TUNSTALL },
                    {
                      label: BRAND_WIRELESSHEALTH,
                      value: BRAND_WIRELESSHEALTH,
                    },
                  ]}
                  value={{
                    value: currentUser.userConfig.brand,
                    label: currentUser.userConfig.brand,
                  }}
                  onChange={({ option }) => {
                    const newcurrentUser: UserData = _.cloneDeep(currentUser);
                    newcurrentUser.userConfig.brand = option.value;
                    setCurrentUser(newcurrentUser);
                  }}
                />
              </>
            )}
          </Box>
          <Box gridArea="Log">
            {currentUser && (
              <>
                <p>Audit Log Interval</p>
                <Select
                  labelKey="label"
                  valueKey="value"
                  options={userReportIntervalOptions}
                  onChange={({ option }) => {
                    const newcurrentUser: UserData = _.cloneDeep(currentUser);
                    newcurrentUser.userConfig.reportInterval = option.value;
                    setCurrentUser(newcurrentUser);
                  }}
                  value={{
                    label: UsersUtils.getReportIntervalString(
                      currentUser.userConfig.reportInterval
                    ),
                    value: currentUser.userConfig.reportInterval,
                  }}
                />
              </>
            )}
          </Box>
          <Box gridArea="buttons" direction="row" justify="center">
            <Button
              onClick={saveUser}
              label="Save"
              disabled={!currentUser}
              size="medium"
              plain={false}
              margin="small"
            />
            <Button
              onClick={cancelUser}
              label="Discard Changes"
              disabled={!currentUser}
              size="medium"
              plain={false}
              margin="small"
            />
          </Box>
        </Grid>
      </div>
    </Tile>
  );
};

export default UserAdmin;
