import React, { forwardRef, LegacyRef } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useDrag } from 'react-dnd';
import { DNDtypes } from 'types/dndtypes';

export type Props = React.LiHTMLAttributes<HTMLLIElement> & {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  active?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  firstchild?: boolean;
  popperarrow?: boolean;
  clickCB: () => void;
  tooltip?: React.ReactElement;
  dragitem?: unknown;
  dragtype?: string;
};

const MenuItem: React.ForwardRefRenderFunction<unknown, Props> = (
  {
    children,
    className,
    icon,
    active,
    prefix,
    suffix,
    //firstchild,
    //popperarrow,
    clickCB,
    tooltip,
    dragitem,
    dragtype,
    ...rest
  },
  ref
) => {
  const [, /*collected*/ drag /*dragPreview*/] = useDrag(() => ({
    type: dragtype ?? DNDtypes.NONE,
    item: { dragitem },
  }));

  const menuItemRef: LegacyRef<HTMLLIElement> =
    (ref as LegacyRef<HTMLLIElement>) || React.createRef<HTMLLIElement>();

  // create a random ID to use for the tooltip
  const id = `ID${
    Math.floor(Math.random() * 1000) * Math.floor(Math.random() * 1000)
  }`;

  return (
    <li
      ref={menuItemRef}
      className={classNames('pro-menu-item', className, { active })}
      {...rest}
    >
      <div
        ref={drag}
        className="pro-inner-item"
        tabIndex={0}
        role="button"
        data-tip
        data-for={id}
        onClick={() => clickCB()}
      >
        {icon ? (
          <span className="pro-icon-wrapper">
            <span className="pro-icon">{icon}</span>
          </span>
        ) : null}

        {prefix ? <span className="prefix-wrapper">{prefix}</span> : null}
        <span className="pro-item-content">{children}</span>
        {suffix ? <span className="suffix-wrapper">{suffix}</span> : null}
      </div>
      {tooltip && (
        <ReactTooltip id={id} place="right">
          {tooltip}
        </ReactTooltip>
      )}
    </li>
  );
};

export default forwardRef<unknown, Props>(MenuItem);
