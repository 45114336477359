import TablePaged, { dateFilter } from 'components/tables/TablePaged';

import React, { useEffect, useMemo } from 'react';
import useState from 'react-usestateref';
import * as alarmEvents from 'components/systemevents/alarmEvents';
import { formatDateWithSeconds } from 'common/utils/dateUtils';
import { Column, Row, Cell } from 'react-table';
import { Text, Tip } from 'grommet';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RecentAlarmsTable = (props: any): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const columns = React.useMemo<Column<alarmEvents.iAlarmSummary>[]>(
    () => [
      {
        Header: 'Date/Time',
        id: 'raisedAt',
        width: 120,
        //accessor: (row: alarmEvents.iAlarmSummary) => formatDateWithSeconds(row.raisedAt),

        accessor: (row) => new Date(row.raisedAt),
        sortType: 'datetime',
        filter: dateFilter,
        disableFilters: props.disableFilters,

        // Using the cell access so that the sortType: 'datetime' works
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const date = data.value;
          if (date) return date ? formatDateWithSeconds(date) : 'Unknown';
        },

        getCellExportValue: (row: Row<alarmEvents.iAlarmSummary>) => {
          return formatDateWithSeconds(row.values.raisedAt);
        },
      },
      {
        Header: 'Room',
        accessor: 'roomNumber',
        filter: 'equals',
        width: '0px',
        disableFilters: props.disableFilters,
      },
      {
        Header: 'Type',
        accessor: 'alarmType',
        maxWidth: 100,
        minWidth: 100,
        // Used a <Text> element so keep long strings on a single line
        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => (
          <Text truncate={true}>{data.row.original.alarmType}</Text>
        ),
        disableFilters: props.disableFilters,
      },
      // {
      //   Header: 'Care Group',
      //   accessor: 'careGroupDesc',
      //   maxWidth: 100,
      //   minWidth: 100,
      //   width: 100,
      //   Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
      //     const text = data.row.original.careGroupDesc;
      //     return <Text truncate={true}>{text}</Text>;
      //   },
      //   disableFilters: props.disableFilters,
      // },
      {
        Header: 'Answered By',
        accessor: (row) => {
          return row.CancelReason ? row.CancelReason : row.answeredBy;
        },
        disableFilters: props.disableFilters,

        Cell: (data: Cell<alarmEvents.iAlarmSummary>) => {
          const text = data.row.original.CancelReason
            ? data.row.original.CancelReason
            : data.row.original.answeredBy;

          let truncatedText = text;
          if (text.length > 30) {
            truncatedText = text.substring(0, 27);
            truncatedText += '...';
            return (
              <Tip content={<Text size="medium">{text}</Text>}>
                <Text truncate={true}>{truncatedText}</Text>
              </Tip>
            );
          }
          return <Text truncate={true}>{text}</Text>;
        },
      },
    ],
    [props]
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: [],
      pageSize: 10,
      sortBy: [
        {
          id: 'raisedAt',
          desc: true,
        },
      ],
    }),
    []
  );

  const data = useMemo(() => {
    // console.log('Alarms Overview', props);
    if (props.room) {
      return alarmEvents.alarmSummaries.filter(
        (f) => f.roomNumber == Number(props.room)
      );
    } else return alarmEvents.alarmSummaries;
  }, [props]);

  return (
    <TablePaged
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      initialState={initialState}
      data={data}
      showCheckboxes={false}
      reportDescription={{
        header: '',
        filename: 'RecentAlarms',
      }}
    />
  );
};

export default RecentAlarmsTable;
