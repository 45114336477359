/** @module Installation */

import { iALARM_TYPES, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';

/*
 * Alarm Types and Priority Table
 * @class
 * @hideconstructor
 * @implements iALARM_TYPES
 */

export class AlarmPriority implements iALARM_TYPES {
  /** iALARM_TYPES start */
  ID = invalidID;
  Description = '';
  ExternalId = 0;
  IsTechnical = 0;
  Priority = 0;
  StatusCode = 0;
  StatusCodeBatteryLow = 0;
  NoneAudioAlarm = 0;
  Covert = 0;
  AlarmDisabled = 0;
  EntryExit = 0;
  Intruder = 0;
  Inactivity = 0;
  /** iALARM_TYPES end */

  // Got software version incase we need to do processsing differently for
  // different software revsions in the future
  softwareVersion = 0;

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(
    values: iALARM_TYPES,
    keys: string[],
    tableName: string,
    softwareVersion: number
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
  }

  /* High number = higher priority . MAX = 9*/
  setPriority(priority: number): void {
    if (priority > 9) priority = 9;
    this.Priority = priority;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });

    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }
}
