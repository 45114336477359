import { iSelectKeyValue } from 'components/forms/formFields';

export enum AUDIT_EVENTS {
  // AUTOGEN START
  AUDIT_EVENT_ALARM_RAISE = 0,
  AUDIT_EVENT_ALARM_RERAISE,
  AUDIT_EVENT_ALARM_FAIL,
  AUDIT_EVENT_ALARM_SELECT,
  AUDIT_EVENT_ALARM_DEST_RAISE,
  AUDIT_EVENT_ALARM_DEST_FAIL,
  AUDIT_EVENT_ALARM_CLEAR,
  AUDIT_EVENT_ALARM_CONSUME,
  AUDIT_EVENT_SOCIAL_CALL_RAISE,
  AUDIT_EVENT_SOCIAL_CALL_SELECT,
  AUDIT_EVENT_SOCIAL_CALL_CLEAR,
  AUDIT_EVENT_DOOR_CALL_RAISE,
  AUDIT_EVENT_DOOR_CALL_SELECT,
  AUDIT_EVENT_DOOR_CALL_RAISE_RU,
  AUDIT_EVENT_DOOR_CALL_RU_SELECT,
  AUDIT_EVENT_DOOR_CALL_CLEAR_OPEN,
  AUDIT_EVENT_DOOR_CALL_CLEAR,
  AUDIT_EVENT_DOOR_CALL_FAIL,
  AUDIT_EVENT_DOOR_EXIT,
  AUDIT_EVENT_DOOR_FIRE_SWITCH,
  AUDIT_EVENT_DOOR_CANCEL_FIRE,
  AUDIT_EVENT_DOOR_TRADES_ALLOW,
  AUDIT_EVENT_DOOR_TRADES_PIN,
  AUDIT_EVENT_DOOR_TRADES_DENY,
  AUDIT_EVENT_DOOR_CALL_NO_CONCIERGE,
  AUDIT_EVENT_DOOR_CALL_NO_RESIDENT,
  AUDIT_EVENT_MAINS_FAIL,
  AUDIT_EVENT_MAINS_OK,
  AUDIT_EVENT_BATTERY_LOW,
  AUDIT_EVENT_BATTERY_OK,
  AUDIT_EVENT_FORCED_CLEAR,
  AUDIT_EVENT_SYSTEM_STARTED,
  AUDIT_EVENT_HS_LOG_ON,
  AUDIT_EVENT_HS_LOG_OFF,
  AUDIT_EVENT_ALL_HS_LOG_OFF,
  AUDIT_EVENT_CALL_BANNED,
  AUDIT_EVENT_CALL_KICKED,
  AUDIT_EVENT_ALARM_FAIL_UNKNOWN,
  AUDIT_EVENT_ALL_HS_LOG_ON,
  AUDIT_EVENT_HS_NOTIFIED,
  AUDIT_EVENT_RAISE_TIMEOUT,
  AUDIT_EVENT_DOOR_CALL_RAISE_EXT,
  AUDIT_EVENT_DOOR_EXIT_EXT,
  AUDIT_EVENT_SOCIAL_CALL_RAISE_HU,
  AUDIT_EVENT_SOCIAL_CALL_RAISE_POTS,
  AUDIT_EVENT_INCOMING_CALL_RAISE,
  AUDIT_EVENT_DOOR_CANCEL,
  AUDIT_EVENT_DOOR_CALL_KICKED,
  AUDIT_EVENT_DOOR_CALL_CC_FAIL,
  AUDIT_EVENT_DOOR_CALL_ANSWER_TIMEOUT,
  AUDIT_EVENT_DOOR_CALL_SPEECH_TIMEOUT,
  AUDIT_EVENT_AST_STALE_AUDIO,
  AUDIT_EVENT_AST_NO_ANSWER,
  AUDIT_EVENT_AST_PROTOCOL_FAIL,
  AUDIT_EVENT_ALARM_FORCE_CLEAR,
  AUDIT_EVENT_USER_CLEAR,
  AUDIT_EVENT_AGGREGATION_ALARM,
  AUDIT_EVENT_AGGREGATION_DOORCALL,
  AUDIT_EVENT_AGGREGATION_SOCIAL,
  AUDIT_EVENT_SYSTEM_TEST,
  AUDIT_EVENT_ANALYSE_AUDIO,
  AUDIT_EVENT_DEVICE_BATTERY_LOW,
  AUDIT_EVENT_DEVICE_ETHERNET_FAIL,
  AUDIT_EVENT_DEVICE_POOR_AUDIO,
  AUDIT_EVENT_SIP_REGISTER_FAIL,
  AUDIT_EVENT_SIP_DIAL_FAIL,
  AUDIT_EVENT_SIP_MSG_FAIL,
  AUDIT_EVENT_SIP_ANSWER_FAIL,
  AUDIT_EVENT_SIP_ONLINE_TIMEOUT,
  AUDIT_EVENT_SIP_NO_CALL,
  AUDIT_EVENT_DEVICE_POLL_FAIL,
  AUDIT_EVENT_HWIN_EVENT,
  AUDIT_EVENT_AUDIO_ROUTE_FAIL,
  AUDIT_EVENT_COMPANION_DOOR_CALL_SELECT,
  AUDIT_EVENT_RDC_DOOR_CALL_SELECT,
  AUDIT_EVENT_COMPANION_DOOR_CALL_CLEAR,
  AUDIT_EVENT_RDC_DOOR_CALL_CLEAR,
  AUDIT_EVENT_SCU_HWIN_EVENT,
  AUDIT_EVENT_SYSTEM_FORCED_CLEAR,
  AUDIT_EVENT_VRU_FORCED_CLEAR,
  AUDIT_EVENT_ALARM_FORCE_CLEAR_IN_GUARD_PERIOD,
  AUDIT_EVENT_HOME_AWAY_STATUS_CHANGE,
  AUDIT_EVENT_ACTIVITY_EVENT,
  AUDIT_EVENT_SYSTEM_CHECK_EVENT,
  AUDIT_EVENT_REMOTE_CALL_RAISE,
  AUDIT_EVENT_REMOTE_CALL_CLEAR,
  AUDIT_EVENT_RESIDENT_LAST_SEEN_UPDATED,
  AUDIT_EVENT_RESIDENT_LAST_TALKED_TO_UPDATED,
  AUDIT_EVENT_ALARM_FORCE_CLEAR_AUTO_TEST,
  AUDIT_EVENT_ALARM_FORCE_CLEAR_SERVICE_TEST,
  AUDIT_EVENT_INTRUDER_STATUS_CHANGE,
  AUDIT_EVENT_ACTIVITY_DETECTED,
  AUDIT_EVENT_DOOR_CALL_DIVERTED,
  AUDIT_EVENT_INTRUDER_ALARM_ACTIVATED,
  AUDIT_EVENT_SIP_REGISTRATION_STATUS,
  AUDIT_EVENT_GENERIC_LOG,

  // AUTOGEN STOP
  AUDIT_EVENT_MAX,
  AUDIT_EVENT_CLOUD_EVENT_START = 0x1000,
}

export enum DB_EVENT_STATUS_CODES {
  DB_EVENT_STATUS_CODE_OK_NO_REASON = 0,
  DB_EVENT_STATUS_CODE_DOOR_OPENED = 1,
  DB_EVENT_STATUS_CODE_DOOR_REJECTED = 2,
  DB_EVENT_STATUS_CODE_DOOR_FAILED_NO_RESIDENT = 3,
  DB_EVENT_STATUS_CODE_DOOR_FAILED_TIMEOUT = 4,
  DB_EVENT_STATUS_CODE_DOOR_FAILED = 5,
  DB_EVENT_STATUS_CODE_ALARM_CLEARED = 6,
  DB_EVENT_STATUS_CODE_ALARM_CLEARED_ENGINNER = 7,
  DB_EVENT_STATUS_CODE_ALARM_CLEARED_BY_USER = 8,
  DB_EVENT_STATUS_CODE_ALARM_FAILED_RERAISE = 9,
  DB_EVENT_STATUS_CODE_ALARM_FAILED_PROTOCOL = 10,
  DB_EVENT_STATUS_CODE_ALARM_FAILED_SELECT_NO_ROUTE = 11,
  DB_EVENT_STATUS_CODE_ALARM_FAILED_SELECT_DEVICE_ALREADY_IN_CALL = 12,
  DB_EVENT_STATUS_CODE_ALARM_FAILED = 13,
  DB_EVENT_STATUS_CODE_CALL_REJECT_PRIVACY = 14,
  DB_EVENT_STATUS_CODE_TRADES_OK_DOOR_OPEN = 15,
  DB_EVENT_STATUS_CODE_TRADES_DENY_INVALID_PIN = 16,
  DB_EVENT_STATUS_CODE_TRADES_DENY_TIME = 17,
  DB_EVENT_STATUS_CODE_TEST_PASS = 18,
  DB_EVENT_STATUS_CODE_TEST_FAIL = 19,
  DB_EVENT_STATUS_CODE_RECORD_IN_PROGRESS = 20,
  DB_EVENT_STATUS_CODE_ALARM_CLEARED_BY_HANDSET = 21,
  DB_EVENT_STATUS_CODE_DOOR_FAILED_NO_MANAGER = 22,
  DB_EVENT_STATUS_CODE_DOOR_FAILED_SPEECH_SETUP = 23,
  DB_EVENT_STATUS_CODE_DOOR_CALL_KICKED = 24,
  DB_EVENT_STATUS_CODE_DOOR_CANCELLED = 25,
  DB_EVENT_STATUS_FAILED_STAIL_AUDIO = 26,
  DB_EVENT_STATUS_FAILED_NO_ANSWER = 27,
  DB_EVENT_STATUS_FAILED_PROTOCOL_FAIL = 28,
  DB_EVENT_STATUS_CODE_INPUT_CLOSED = 29,
  DB_EVENT_STATUS_CODE_INPUT_OPEN = 30,
  DB_EVENT_STATUS_CODE_ALARM_CLEARED_BY_SYSTEM = 31,
  DB_EVENT_STATUS_CODE_ALARM_CLEARED_IN_GUARD_PERIOD = 32,
  DB_EVENT_STATUS_RESIDENT_HOME = 33,
  DB_EVENT_STATUS_RESIDENT_AWAY = 34,
  DB_EVENT_STATUS_RESIDENT_TPM_OK = 35,
  DB_EVENT_STATUS_RESIDENT_TPM_FAIL = 36,
  DB_EVENT_STATUS_CODE_ALARM_CLEARED_AUTO_TEST = 37,
  DB_EVENT_STATUS_CODE_ALARM_CLEARED_SERVICE_TEST = 38,
  DB_EVENT_STATUS_INTRUDER_ARMED = 39,
  DB_EVENT_STATUS_INTRUDER_DISARMED = 40,
  DB_EVENT_STATUS_CODE_MAX,
}

export enum ALARM_TYPES {
  ALARM_TYPE_NO_ALARM_EVENT = 0,
  ALARM_TYPE_FIXED_TRIGGER_1 = 10, // "Red Button"
  ALARM_TYPE_FIXED_TRIGGER_2, // Pullcord
  ALARM_TYPE_PRESSURE_MAT,
  ALARM_TYPE_DOOR_CONTACT,
  ALARM_TYPE_PASSIVE_INFRA_RED_PIR_DETECTOR,
  ALARM_TYPE_BOUNDARY_BREACH,
  ALARM_TYPE_SMOKE,
  ALARM_TYPE_FIRE,
  ALARM_TYPE_BOGUS_CALLER_TRIGGER,
  ALARM_TYPE_PERSONAL_TRIGGER_1, // Pendant
  ALARM_TYPE_PERSONAL_TRIGGER_2,
  ALARM_TYPE_FALL_TRIGGER_1,
  ALARM_TYPE_FALL_TRIGGER_2,
  ALARM_TYPE_PERSONAL_ATTACK_1,
  ALARM_TYPE_PERSONAL_ATTACK_2,
  ALARM_TYPE_PANIC_BUTTON,
  ALARM_TYPE_CARBON_MONOXIDE_GAS,
  ALARM_TYPE_NATURAL_GAS,
  ALARM_TYPE_INTRUDER,
  ALARM_TYPE_AUTOMATIC_PERSON_DOWN,
  ALARM_TYPE_PROPERTY_EXIT_1,
  ALARM_TYPE_PROPERTY_EXIT_2,
  ALARM_TYPE_HIGH_TEMPERATURE,
  ALARM_TYPE_LOW_TEMPERATURE,
  ALARM_TYPE_TEMPERATURE_RATE_OF_RISE,
  ALARM_TYPE_MEDICATION_DISPENSER,
  ALARM_TYPE_ENURESIS,
  ALARM_TYPE_BED_OCCUPANCY,
  ALARM_TYPE_CHAIR_OCCUPANCY,
  ALARM_TYPE_PROPERTY_OCCUPANCY_1,
  ALARM_TYPE_PROPERTY_OCCUPANCY_2,
  ALARM_TYPE_BATHROOM_OCCUPANCY,
  ALARM_TYPE_SEIZURE,
  ALARM_TYPE_INACTIVITY,
  ALARM_TYPE_ENVIRONMENTAL_MONITOR,
  ALARM_TYPE_BED_MONITOR,
  ALARM_TYPE_CHAIR_MONITOR,
  ALARM_TYPE_FLOOD,
  ALARM_TYPE_BATH_LEVEL,
  ALARM_TYPE_LIGHTING_CIRCUIT,
  ALARM_TYPE_HEATING,
  ALARM_TYPE_MAINS_POWER,
  ALARM_TYPE_SYSTEM_POWER_SUPPLY,
  ALARM_TYPE_BATTERY,
  ALARM_TYPE_DUTY_SWITCH,
  ALARM_TYPE_DOOR_OPEN,
  ALARM_TYPE_FIRE_DOOR_OPEN,
  ALARM_TYPE_SYSTEM_STATUS,
  ALARM_TYPE_AUTOMATIC_PERIODIC_TEST_CALL,
  ALARM_TYPE_TELEPHONE_LINE_1,
  ALARM_TYPE_TELEPHONE_LINE_2,
  ALARM_TYPE_RADIO_RECEIVER,
  ALARM_TYPE_IP_COMMUNICATION_LINK,
  ALARM_TYPE_SERIAL_DATA_LINK,
  ALARM_TYPE_SYSTEM_UNDER_TEST,
  ALARM_TYPE_SERVICE_PROVIDER_IDS = 90,
  ALARM_TYPE_SERVICE_CALL = 91,
  ALARM_TYPE_ACKNOWLEDGEMENT_MESSAGE = 97,
  ALARM_TYPE_PRESENCE_MARKING = 98,
  ALARM_TYPE_SERVICE_COMPLETED = 99,
  ALARM_TYPE_RESIDENT_UNIT_POLL_FAILURE = 200, //Proprietary events start
  ALARM_TYPE_ROUTER_POLL_FAILURE,
  ALARM_TYPE_TRIGGER_POLL_FAILURE,
  ALARM_TYPE_SOCIAL_CALL,
  ALARM_TYPE_TAG_STATUS_REPORT,
  ALARM_TYPE_TAG_ACK,
  ALARM_TYPE_WIRED_INPUT_1,
  ALARM_TYPE_WIRED_INPUT_2,
  ALARM_TYPE_DOOR_CALL,
  ALARM_TYPE_DOOR_POLL_FAILURE,
  ALARM_TYPE_AUDIO_ADAPTOR_POLL_FAILURE,
  ALARM_TYPE_RESIDENT_OK,
  ALARM_TYPE_INCOMING_POTS_CALL,
  ALARM_TYPE_WIRED_INPUT_3,
  ALARM_TYPE_TAG_TALK_ACTIVE,
  ALARM_TYPE_TAG_TALK_INACTIVE,
  ALARM_TYPE_TAG_OPEN,
  ALARM_TYPE_TAG_END,
  ALARM_TYPE_RESIDENT_HOME,
  ALARM_TYPE_RESIDENT_AWAY,
  ALARM_TYPE_WANDERING,
  ALARM_TYPE_PAGE_ACKNOWLEDGE,
  ALARM_TYPE_CANCEL_REQUEST,
  ALARM_TYPE_MESSAGE_REQUEST,
  ALARM_TYPE_WIRED_INPUT_1_OPEN_EVENT,
  ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT,
  ALARM_TYPE_WIRED_INPUT_2_OPEN_EVENT,
  ALARM_TYPE_WIRED_INPUT_2_CLOSE_EVENT,
  ALARM_TYPE_WIRED_INPUT_3_OPEN_EVENT,
  ALARM_TYPE_WIRED_INPUT_3_CLOSE_EVENT,
  ALARM_TYPE_DEVICE_RESET_EVENT,
  ALARM_TYPE_SCU_WIRED_INPUT_1_OPEN_EVENT,
  ALARM_TYPE_SCU_WIRED_INPUT_1_CLOSE_EVENT,
  ALARM_TYPE_SCU_WIRED_INPUT_2_OPEN_EVENT,
  ALARM_TYPE_SCU_WIRED_INPUT_2_CLOSE_EVENT,
  ALARM_TYPE_SCU_WIRED_INPUT_3_OPEN_EVENT,
  ALARM_TYPE_SCU_WIRED_INPUT_3_CLOSE_EVENT,
  ALARM_TYPE_TUNSTALL_TRIGGER_EVENT,
  ALARM_TYPE_DETECTOR_FAULT,
  ALARM_TYPE_DETECTOR_EOL,
  ALARM_TYPE_ASSISTANCE_NEEDED,
  ALARM_TYPE_BUTTON_FAULT,
  ALARM_TYPE_DOOR_CLOSED,
  ALARM_TYPE_MEDICATION_DOSE_MISSED,
  ALARM_TYPE_NURSE_CALL_FAULT,
  ALARM_TYPE_NURSE_CALL,
  ALARM_TYPE_TAMPER,
  ALARM_TYPE_MAINS_POWER_RESTORED,
  ALARM_TYPE_BEDCHAIR_NOT_IN,
  ALARM_TYPE_BEDCHAIR_NOT_UP,
  ALARM_TYPE_BEDCHAIR_ABSENCE,
  ALARM_TYPE_BEDCHAIR_OTHER,
  ALARM_TYPE_FALL_NOT_WORN,
  ALARM_TYPE_FALL_CANCELLED,
  ALARM_TYPE_CARER_ALARM,
  ALARM_TYPE_RESIDENT_NOT_OK,
  ALARM_TYPE_INTRUDER_ARM_DISARM_TOGGLE,
  ALARM_TYPE_INTRUDER_ARM,
  ALARM_TYPE_INTRUDER_DISARM,
  ALARM_TYPE_EMERGENCY,
  ALARM_TYPE_NOT_WORN,
  ALARM_TYPE_DOOR_LEFT_OPEN,
  ALARM_TYPE_TELEPHONE_LINE_RESTORED,
  ALARM_TYPE_PERSONAL_TRIGGER_BATTERY_LOW,
  ALARM_TYPE_TEMP_EXT_BATTERY_LOW,
  ALARM_TYPE_FLOOD_BATTERY_LOW,
  ALARM_TYPE_CO_BATTERY_LOW,
  ALARM_TYPE_SMOKE_BATTERY_LOW,
  ALARM_TYPE_DOOR_BATTERY_LOW,
  ALARM_TYPE_PULLCORD_BATTERY_LOW,
  ALARM_TYPE_ENURESIS_BATTERY_LOW,
  ALARM_TYPE_BEDCHAIR_BATTERY_LOW,
  ALARM_TYPE_FALL_BATTERY_LOW,
  ALARM_TYPE_PIR_BATTERY_LOW,
  ALARM_TYPE_CARER_BATTERY_LOW,
  ALARM_TYPE_NATURAL_GAS_BATTERY_LOW,
  ALARM_TYPE_PES_BATTERY_LOW,
  ALARM_TYPE_ARMDISARM_BATTERY_LOW,
  ALARM_TYPE_BOGUS_CALL_BATTERY_LOW,
  ALARM_TYPE_MEDICATION_BATTERY_LOW,
  ALARM_TYPE_EPILEPSY_BATTERY_LOW,
  ALARM_TYPE_WNC_BATTERY_LOW,
  ALARM_TYPE_WNC_CANCEL_REQ,
  ALARM_TYPE_CO_DEVICE_FAULT,
  ALARM_TYPE_MEDICATION_FAULT,
  ALARM_TYPE_PULLCORD_POLL_FAILURE,
  ALARM_TYPE_SMOKE_POLL_FAILURE,
  ALARM_TYPE_WIM_POLL_FAILURE,
  ALARM_TYPE_WIM_BATTERY_LOW,
  ALARM_TYPE_FALL_POLL_FAILURE,
  ALARM_TYPE_PANIC_BATTERY_LOW,
  ALARM_TYPE_PANIC_POLL_FAILURE,
  ALARM_TYPE_FALL_MANUAL_PRESS,
}

export interface iAlarmTypeDescription {
  value: ALARM_TYPES;
  text: string;
}

// export const ALARMS_TYPE_DESCRIPTIONS: iAlarmTypeDescription[] = [
//   { value: ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT, text: 'Disabled' },
//   { value: ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1, text: 'Red Button' },
//   { value: ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_2, text: 'Pullcord' },
//   { value: ALARM_TYPES.ALARM_TYPE_PRESSURE_MAT, text: 'Pressure mat' },
//   { value: ALARM_TYPES.ALARM_TYPE_DOOR_CONTACT, text: 'Door contact' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PASSIVE_INFRA_RED_PIR_DETECTOR,
//     text: 'PIR (Standard)',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_BOUNDARY_BREACH, text: 'Boundary breach' },
//   { value: ALARM_TYPES.ALARM_TYPE_SMOKE, text: 'Smoke' },
//   { value: ALARM_TYPES.ALARM_TYPE_FIRE, text: 'Fire' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_BOGUS_CALLER_TRIGGER,
//     text: 'Bogus caller trigger',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_1, text: 'Pendant' }, // Pendant
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_2,
//     text: 'Personal trigger 2',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_1, text: 'Fall' },
//   { value: ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_2, text: 'Fall 2' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PERSONAL_ATTACK_1,
//     text: 'Personal attack 1',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PERSONAL_ATTACK_2,
//     text: 'Personal attack 2',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_PANIC_BUTTON, text: 'Panic button' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_CARBON_MONOXIDE_GAS,
//     text: 'Carbon monoxide gas',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_NATURAL_GAS, text: 'Natural gas' },
//   { value: ALARM_TYPES.ALARM_TYPE_INTRUDER, text: 'Intruder' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_AUTOMATIC_PERSON_DOWN,
//     text: 'Automatic person down',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_1, text: 'PIR (Entry/Exit)' },
//   { value: ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_2, text: 'Property exit 2' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_HIGH_TEMPERATURE,
//     text: 'High temperature',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_LOW_TEMPERATURE, text: 'Low temperature' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TEMPERATURE_RATE_OF_RISE,
//     text: 'Temperature rate of rise',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_MEDICATION_DISPENSER,
//     text: 'Medication Dispensed',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_ENURESIS, text: 'Enuresis' },
//   { value: ALARM_TYPES.ALARM_TYPE_BED_OCCUPANCY, text: 'Not in Bed/Chair' },
//   { value: ALARM_TYPES.ALARM_TYPE_CHAIR_OCCUPANCY, text: 'Bed/Chair Absence' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PROPERTY_OCCUPANCY_1,
//     text: 'Property occupancy 1',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PROPERTY_OCCUPANCY_2,
//     text: 'Property occupancy 2',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_BATHROOM_OCCUPANCY,
//     text: 'Bathroom occupancy',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_SEIZURE, text: 'Seizure' },
//   { value: ALARM_TYPES.ALARM_TYPE_INACTIVITY, text: 'Inactivity' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_ENVIRONMENTAL_MONITOR,
//     text: 'Environmental monitor',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_BED_MONITOR, text: 'Not up Bed/Chair' },
//   { value: ALARM_TYPES.ALARM_TYPE_CHAIR_MONITOR, text: 'Bed/Chair Other' },
//   { value: ALARM_TYPES.ALARM_TYPE_FLOOD, text: 'Flood' },
//   { value: ALARM_TYPES.ALARM_TYPE_BATH_LEVEL, text: 'Bath level' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_LIGHTING_CIRCUIT,
//     text: 'Lighting circuit',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_HEATING, text: 'Heating' },
//   { value: ALARM_TYPES.ALARM_TYPE_MAINS_POWER, text: 'Mains fail' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SYSTEM_POWER_SUPPLY,
//     text: 'System power supply',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_BATTERY, text: 'Battery low' },
//   { value: ALARM_TYPES.ALARM_TYPE_DUTY_SWITCH, text: 'Duty switch' },
//   { value: ALARM_TYPES.ALARM_TYPE_DOOR_OPEN, text: 'Door open' },
//   { value: ALARM_TYPES.ALARM_TYPE_FIRE_DOOR_OPEN, text: 'Fire door open' },
//   { value: ALARM_TYPES.ALARM_TYPE_SYSTEM_STATUS, text: 'System status' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_AUTOMATIC_PERIODIC_TEST_CALL,
//     text: 'Automatic periodic test call',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_1,
//     text: 'Telephone line fail',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_2,
//     text: 'Telephone line 2 fail',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_RADIO_RECEIVER, text: 'Radio receiver' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_IP_COMMUNICATION_LINK,
//     text: 'IP communication link',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SERIAL_DATA_LINK,
//     text: 'Serial data link',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SYSTEM_UNDER_TEST,
//     text: 'System under test',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SERVICE_PROVIDER_IDS,
//     text: 'Service provider IDs',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_SERVICE_CALL, text: 'Service call' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_ACKNOWLEDGEMENT_MESSAGE,
//     text: 'Acknowledgement message',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PRESENCE_MARKING,
//     text: 'Presence marking',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SERVICE_COMPLETED,
//     text: 'Service completed',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_RESIDENT_UNIT_POLL_FAILURE,
//     text: 'Room Poll Fail',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_ROUTER_POLL_FAILURE,
//     text: 'Router Fail',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TRIGGER_POLL_FAILURE,
//     text: 'Trigger Fail',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_SOCIAL_CALL, text: 'Social Call' },
//   { value: ALARM_TYPES.ALARM_TYPE_TAG_STATUS_REPORT, text: 'Tag Status' },
//   { value: ALARM_TYPES.ALARM_TYPE_TAG_ACK, text: 'Tag ACK' },
//   { value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1, text: 'Input 1' },
//   { value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2, text: 'Input 2' },
//   { value: ALARM_TYPES.ALARM_TYPE_DOOR_CALL, text: 'Door Panel Call' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_DOOR_POLL_FAILURE,
//     text: 'Door Panel Poll Fail',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_AUDIO_ADAPTOR_POLL_FAILURE,
//     text: 'Audio Adaptor Poll Fail',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_RESIDENT_OK, text: 'Resident ok' },
//   { value: ALARM_TYPES.ALARM_TYPE_INCOMING_POTS_CALL, text: 'Incoming call' },
//   { value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3, text: 'Input 3' },
//   { value: ALARM_TYPES.ALARM_TYPE_TAG_TALK_ACTIVE, text: 'Tag Talk Active' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TAG_TALK_INACTIVE,
//     text: 'Tag Talk Inactive',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_TAG_OPEN, text: 'Tag Open' },
//   { value: ALARM_TYPES.ALARM_TYPE_TAG_END, text: 'Tag End' },
//   { value: ALARM_TYPES.ALARM_TYPE_RESIDENT_HOME, text: 'Resident Home' },
//   { value: ALARM_TYPES.ALARM_TYPE_RESIDENT_AWAY, text: 'Resident Away' },
//   { value: ALARM_TYPES.ALARM_TYPE_WANDERING, text: 'Resident Wandering' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PAGE_ACKNOWLEDGE,
//     text: 'Page Acknowledgment',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_CANCEL_REQUEST,
//     text: 'Cancel',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_MESSAGE_REQUEST, text: 'Message Request' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_OPEN_EVENT,
//     text: 'Input 1 open event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT,
//     text: 'Input 1 close event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_OPEN_EVENT,
//     text: 'Input 2 open event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_CLOSE_EVENT,
//     text: 'Input 2 close event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_OPEN_EVENT,
//     text: 'Input 3 open event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_CLOSE_EVENT,
//     text: 'Input 3 close event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_DEVICE_RESET_EVENT,
//     text: 'Device reset event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_OPEN_EVENT,
//     text: 'SCU Input 1 open event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_CLOSE_EVENT,
//     text: 'SCU Input 1 close event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_OPEN_EVENT,
//     text: 'SCU Input 2 open event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_CLOSE_EVENT,
//     text: 'SCU Input 2 close event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_OPEN_EVENT,
//     text: 'SCU Input 3 open event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_CLOSE_EVENT,
//     text: 'SCU Input 3 close event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TUNSTALL_TRIGGER_EVENT,
//     text: 'Tunstall trigger event',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_DETECTOR_FAULT,
//     text: 'Detectior Fault',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_DETECTOR_EOL,
//     text: 'Detectior End Of Life',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_ASSISTANCE_NEEDED,
//     text: 'Assist',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_BUTTON_FAULT,
//     text: 'Button fault',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_DOOR_CLOSED,
//     text: 'Door Closed',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_MEDICATION_DOSE_MISSED,
//     text: 'Medication Missed',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_NURSE_CALL_FAULT,
//     text: 'Pear push fault',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_NURSE_CALL,
//     text: 'Call',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TAMPER,
//     text: 'Tamper',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_MAINS_POWER_RESTORED,
//     text: 'Mains Power Restored',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_NOT_IN,
//     text: 'Not in Bed or Chair',
//   },

//   {
//     value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_NOT_UP,
//     text: 'Not up from bed or chair',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_ABSENCE,
//     text: 'Absence from bed or chair',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_OTHER,
//     text: 'Bed chair other',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_FALL_NOT_WORN,
//     text: 'Fall not worn',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_FALL_CANCELLED,
//     text: 'Fall Canceled',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_CARER_ALARM,
//     text: 'Carer',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_RESIDENT_NOT_OK,
//     text: 'Resident not OK',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM_DISARM_TOGGLE,
//     text: 'Intruder Arm/Disarm Switch',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM,
//     text: 'Intruder Arm Only',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_INTRUDER_DISARM,
//     text: 'Intruder Disarm Only',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_EMERGENCY,
//     text: 'Emergency',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_NOT_WORN,
//     text: 'Not Worn',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_DOOR_LEFT_OPEN,
//     text: 'Door Left Open',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_RESTORED,
//     text: 'Telephone Line Restored',
//   },

//   {
//     value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_BATTERY_LOW,
//     text: 'Pendant battery low',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_TEMP_EXT_BATTERY_LOW,
//     text: 'Temperature battery low',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_FLOOD_BATTERY_LOW,
//     text: 'Flood battery low',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_CO_BATTERY_LOW, text: 'CO battery low' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_SMOKE_BATTERY_LOW,
//     text: 'Smoke battery low',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_DOOR_BATTERY_LOW, text: 'Door battery low' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PULLCORD_BATTERY_LOW,
//     text: 'Pullcord battery low',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_ENURESIS_BATTERY_LOW,
//     text: 'Enuresis battery low',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_BATTERY_LOW,
//     text: 'Bed/Chair battery low',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_FALL_BATTERY_LOW, text: 'Fall battery low' },
//   { value: ALARM_TYPES.ALARM_TYPE_PIR_BATTERY_LOW, text: 'PIR battery low' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_CARER_BATTERY_LOW,
//     text: 'Carer battery low',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_NATURAL_GAS_BATTERY_LOW,
//     text: 'Natural gas battery low',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_PES_BATTERY_LOW, text: 'PES battery low' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_ARMDISARM_BATTERY_LOW,
//     text: 'Arm/Disarm battery low',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_BOGUS_CALL_BATTERY_LOW,
//     text: 'Bogus battery low',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_MEDICATION_BATTERY_LOW,
//     text: 'Medication battery low',
//   },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_EPILEPSY_BATTERY_LOW,
//     text: 'Epilepsy battery low',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_WNC_BATTERY_LOW, text: 'WNC battery low' },
//   { value: ALARM_TYPES.ALARM_TYPE_WNC_CANCEL_REQ, text: 'WNC cancel' },
//   { value: ALARM_TYPES.ALARM_TYPE_CO_DEVICE_FAULT, text: 'CO device fault' },
//   { value: ALARM_TYPES.ALARM_TYPE_MEDICATION_FAULT, text: 'Medication fault' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PULLCORD_POLL_FAILURE,
//     text: 'Pullcord poll fail',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_SMOKE_POLL_FAILURE, text: 'Smoke poll fail' },
//   { value: ALARM_TYPES.ALARM_TYPE_WIM_POLL_FAILURE, text: 'WIM poll fail' },
//   { value: ALARM_TYPES.ALARM_TYPE_WIM_BATTERY_LOW, text: 'WIM battery low' },
//   { value: ALARM_TYPES.ALARM_TYPE_FALL_POLL_FAILURE, text: 'Fall poll fail' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_PANIC_BATTERY_LOW,
//     text: 'Panic battery low',
//   },
//   { value: ALARM_TYPES.ALARM_TYPE_PANIC_POLL_FAILURE, text: 'Panic poll fail' },
//   {
//     value: ALARM_TYPES.ALARM_TYPE_FALL_MANUAL_PRESS,
//     text: 'Manual Press-Fall Detector',
//   },
// ];

export const ALARMS_TYPE_DESCRIPTIONS_SIMPLIFIED: iAlarmTypeDescription[] = [
  { value: ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1, text: 'Red Button' },
  { value: ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_2, text: 'Pullcord' },
  { value: ALARM_TYPES.ALARM_TYPE_PRESSURE_MAT, text: 'Pressure mat' },
  { value: ALARM_TYPES.ALARM_TYPE_DOOR_CONTACT, text: 'Door contact' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PASSIVE_INFRA_RED_PIR_DETECTOR,
    text: 'PIR (Standard)',
  },
  { value: ALARM_TYPES.ALARM_TYPE_BOUNDARY_BREACH, text: 'Boundary breach' },
  { value: ALARM_TYPES.ALARM_TYPE_SMOKE, text: 'Smoke' },
  { value: ALARM_TYPES.ALARM_TYPE_FIRE, text: 'Fire' },
  {
    value: ALARM_TYPES.ALARM_TYPE_BOGUS_CALLER_TRIGGER,
    text: 'Bogus caller trigger',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_1, text: 'Pendant' }, // Pendant
  {
    value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_2,
    text: 'Personal trigger 2',
  },
  { value: ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_1, text: 'Fall' },
  { value: ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_2, text: 'Fall 2' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PERSONAL_ATTACK_1,
    text: 'Personal attack 1',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_PERSONAL_ATTACK_2,
    text: 'Personal attack 2',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PANIC_BUTTON, text: 'Panic button' },
  {
    value: ALARM_TYPES.ALARM_TYPE_CARBON_MONOXIDE_GAS,
    text: 'Carbon monoxide gas',
  },
  { value: ALARM_TYPES.ALARM_TYPE_NATURAL_GAS, text: 'Natural gas' },
  { value: ALARM_TYPES.ALARM_TYPE_INTRUDER, text: 'Intruder' },
  {
    value: ALARM_TYPES.ALARM_TYPE_AUTOMATIC_PERSON_DOWN,
    text: 'Automatic person down',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_1, text: 'PIR (Entry/Exit)' },
  { value: ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_2, text: 'Property exit 2' },
  {
    value: ALARM_TYPES.ALARM_TYPE_HIGH_TEMPERATURE,
    text: 'High temperature',
  },
  { value: ALARM_TYPES.ALARM_TYPE_LOW_TEMPERATURE, text: 'Low temperature' },
  {
    value: ALARM_TYPES.ALARM_TYPE_TEMPERATURE_RATE_OF_RISE,
    text: 'Temperature rate of rise',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_MEDICATION_DISPENSER,
    text: 'Medication Dispensed',
  },
  { value: ALARM_TYPES.ALARM_TYPE_ENURESIS, text: 'Enuresis' },
  { value: ALARM_TYPES.ALARM_TYPE_BED_OCCUPANCY, text: 'Not in Bed/Chair' },
  { value: ALARM_TYPES.ALARM_TYPE_CHAIR_OCCUPANCY, text: 'Bed/Chair Absence' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PROPERTY_OCCUPANCY_1,
    text: 'Property occupancy 1',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_PROPERTY_OCCUPANCY_2,
    text: 'Property occupancy 2',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BATHROOM_OCCUPANCY,
    text: 'Bathroom occupancy',
  },
  { value: ALARM_TYPES.ALARM_TYPE_SEIZURE, text: 'Seizure' },
  { value: ALARM_TYPES.ALARM_TYPE_INACTIVITY, text: 'Inactivity' },
  {
    value: ALARM_TYPES.ALARM_TYPE_ENVIRONMENTAL_MONITOR,
    text: 'Environmental monitor',
  },
  { value: ALARM_TYPES.ALARM_TYPE_BED_MONITOR, text: 'Not up Bed/Chair' },
  { value: ALARM_TYPES.ALARM_TYPE_CHAIR_MONITOR, text: 'Bed/Chair Other' },
  { value: ALARM_TYPES.ALARM_TYPE_FLOOD, text: 'Flood' },
  { value: ALARM_TYPES.ALARM_TYPE_BATH_LEVEL, text: 'Bath level' },
  {
    value: ALARM_TYPES.ALARM_TYPE_LIGHTING_CIRCUIT,
    text: 'Lighting circuit',
  },
  { value: ALARM_TYPES.ALARM_TYPE_HEATING, text: 'Heating' },
  { value: ALARM_TYPES.ALARM_TYPE_MAINS_POWER, text: 'Mains fail' },
  {
    value: ALARM_TYPES.ALARM_TYPE_SYSTEM_POWER_SUPPLY,
    text: 'System power supply',
  },
  { value: ALARM_TYPES.ALARM_TYPE_BATTERY, text: 'Battery low' },
  { value: ALARM_TYPES.ALARM_TYPE_DOOR_OPEN, text: 'Door open' },
  { value: ALARM_TYPES.ALARM_TYPE_FIRE_DOOR_OPEN, text: 'Fire door open' },
  {
    value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_1,
    text: 'Telephone line fail',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_2,
    text: 'Telephone line 2 fail',
  },
  { value: ALARM_TYPES.ALARM_TYPE_RADIO_RECEIVER, text: 'Radio receiver' },
  {
    value: ALARM_TYPES.ALARM_TYPE_IP_COMMUNICATION_LINK,
    text: 'IP communication link',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SERVICE_PROVIDER_IDS,
    text: 'Service provider IDs',
  },
  { value: ALARM_TYPES.ALARM_TYPE_SERVICE_CALL, text: 'Service call' },
  {
    value: ALARM_TYPES.ALARM_TYPE_ACKNOWLEDGEMENT_MESSAGE,
    text: 'Acknowledgement message',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_PRESENCE_MARKING,
    text: 'Presence marking',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_SERVICE_COMPLETED,
    text: 'Service completed',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_RESIDENT_UNIT_POLL_FAILURE,
    text: 'Room Poll Fail',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_ROUTER_POLL_FAILURE,
    text: 'Router Fail',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TRIGGER_POLL_FAILURE,
    text: 'Trigger Fail',
  },
  { value: ALARM_TYPES.ALARM_TYPE_DOOR_CALL, text: 'Door Panel Call' },
  {
    value: ALARM_TYPES.ALARM_TYPE_DOOR_POLL_FAILURE,
    text: 'Door Panel Poll Fail',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_AUDIO_ADAPTOR_POLL_FAILURE,
    text: 'Audio Adaptor Poll Fail',
  },
  { value: ALARM_TYPES.ALARM_TYPE_WANDERING, text: 'Resident Wandering' },
  {
    value: ALARM_TYPES.ALARM_TYPE_DETECTOR_FAULT,
    text: 'Detectior Fault',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_DETECTOR_EOL,
    text: 'Detectior End Of Life',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_ASSISTANCE_NEEDED,
    text: 'Assist',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BUTTON_FAULT,
    text: 'Button fault',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_DOOR_CLOSED,
    text: 'Door Closed',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_MEDICATION_DOSE_MISSED,
    text: 'Medication Missed',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_NURSE_CALL_FAULT,
    text: 'Pear push fault',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_NURSE_CALL,
    text: 'Call',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TAMPER,
    text: 'Tamper',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_MAINS_POWER_RESTORED,
    text: 'Mains Power Restored',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_NOT_IN,
    text: 'Not in Bed or Chair',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_NOT_UP,
    text: 'Not up from bed or chair',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_ABSENCE,
    text: 'Absence from bed or chair',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_OTHER,
    text: 'Bed chair other',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_FALL_NOT_WORN,
    text: 'Fall not worn',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_FALL_CANCELLED,
    text: 'Fall Canceled',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_CARER_ALARM,
    text: 'Carer',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_INTRUDER_ARM_DISARM_TOGGLE,
    text: 'Intruder Arm/Disarm Switch',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_EMERGENCY,
    text: 'Emergency',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_NOT_WORN,
    text: 'Not Worn',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_DOOR_LEFT_OPEN,
    text: 'Door Left Open',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_RESTORED,
    text: 'Telephone Line Restored',
  },

  {
    value: ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_BATTERY_LOW,
    text: 'Pendant battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_TEMP_EXT_BATTERY_LOW,
    text: 'Temperature battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_FLOOD_BATTERY_LOW,
    text: 'Flood battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_CO_BATTERY_LOW, text: 'CO battery low' },
  {
    value: ALARM_TYPES.ALARM_TYPE_SMOKE_BATTERY_LOW,
    text: 'Smoke battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_DOOR_BATTERY_LOW, text: 'Door battery low' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PULLCORD_BATTERY_LOW,
    text: 'Pullcord battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_ENURESIS_BATTERY_LOW,
    text: 'Enuresis battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BEDCHAIR_BATTERY_LOW,
    text: 'Bed/Chair battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_FALL_BATTERY_LOW, text: 'Fall battery low' },
  { value: ALARM_TYPES.ALARM_TYPE_PIR_BATTERY_LOW, text: 'PIR battery low' },
  {
    value: ALARM_TYPES.ALARM_TYPE_CARER_BATTERY_LOW,
    text: 'Carer battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_NATURAL_GAS_BATTERY_LOW,
    text: 'Natural gas battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PES_BATTERY_LOW, text: 'PES battery low' },
  {
    value: ALARM_TYPES.ALARM_TYPE_ARMDISARM_BATTERY_LOW,
    text: 'Arm/Disarm battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_BOGUS_CALL_BATTERY_LOW,
    text: 'Bogus battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_MEDICATION_BATTERY_LOW,
    text: 'Medication battery low',
  },
  {
    value: ALARM_TYPES.ALARM_TYPE_EPILEPSY_BATTERY_LOW,
    text: 'Epilepsy battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_WNC_BATTERY_LOW, text: 'WNC battery low' },
  { value: ALARM_TYPES.ALARM_TYPE_WNC_CANCEL_REQ, text: 'WNC cancel' },
  { value: ALARM_TYPES.ALARM_TYPE_CO_DEVICE_FAULT, text: 'CO device fault' },
  { value: ALARM_TYPES.ALARM_TYPE_MEDICATION_FAULT, text: 'Medication fault' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PULLCORD_POLL_FAILURE,
    text: 'Pullcord poll fail',
  },
  { value: ALARM_TYPES.ALARM_TYPE_SMOKE_POLL_FAILURE, text: 'Smoke poll fail' },
  { value: ALARM_TYPES.ALARM_TYPE_WIM_POLL_FAILURE, text: 'WIM poll fail' },
  { value: ALARM_TYPES.ALARM_TYPE_WIM_BATTERY_LOW, text: 'WIM battery low' },
  { value: ALARM_TYPES.ALARM_TYPE_FALL_POLL_FAILURE, text: 'Fall poll fail' },
  {
    value: ALARM_TYPES.ALARM_TYPE_PANIC_BATTERY_LOW,
    text: 'Panic battery low',
  },
  { value: ALARM_TYPES.ALARM_TYPE_PANIC_POLL_FAILURE, text: 'Panic poll fail' },
  {
    value: ALARM_TYPES.ALARM_TYPE_FALL_MANUAL_PRESS,
    text: 'Manual Press-Fall Detector',
  },
];

export const alarmTypes_SIMPLIFIED_keyvalues = (): iSelectKeyValue[] => {
  const array: iSelectKeyValue[] = ALARMS_TYPE_DESCRIPTIONS_SIMPLIFIED.map(
    (a) => {
      return { key: a.value.toString(), value: a.text };
    }
  );
  array.sort((a, b) => a.value.localeCompare(b.value));

  return array;
};

export enum teINPUT_POLARITIES {
  NORMALL_OPEN = 0,
  NORMALL_CLOSED,
}

export const INPUT_POLARITIES: { value: number; text: string }[] = [
  { value: 0, text: 'N/O' },
  { value: 1, text: 'N/C' },
];

export enum teGENERIC_LOG_TYPE {
  GENERIC_LOG_TYPE_NFC_READ = 0,
}
