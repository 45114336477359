import React from 'react';
import { Text, Button } from 'grommet';
import styled from 'styled-components';

const IconButton = styled(Button)`
  padding: 0;
`;

export interface iAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  iconSize?: number;
  colour?: string;
  leftMargin?: string;
  tip: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (rowData: any[]) => void;
  disabled?: boolean;
}

/** RowAction
 * defines a clickable component for a row that opens a link for example.
 * usually used for icons that perform an action
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Action = (props: { data: any[]; action: iAction }): JSX.Element => {
  return (
    <IconButton
      icon={
        <props.action.icon
          color={props.action.colour ?? 'brand'}
          size={`${props.action.iconSize ?? 24}`}
        />
      }
      margin={{ left: props.action.leftMargin ?? '0px' }}
      tip={{ content: <Text>{props.action.tip}</Text> }}
      onClick={() => props.action.onClick(props.data)}
      disabled={props.action.disabled ?? false}
    />
  );
};

export default Action;
