import { UAG } from 'common/userUtils';
//import { iSwVersion } from 'types/manifest-types';

/** Type to support the widget library */

export enum WIDGETCAT {
  ALARMS,
  RESIDENTS,
  DOORS,
  GENERAL,
}

export interface iWidget {
  minimumSCUSoftwareVersion: string;
  widget: React.ReactNode;
}

export interface iWidgetEntry {
  name: string;
  description: string;
  category: WIDGETCAT;
  widget: iWidget[];
  accessLevel: UAG;
}

export interface iLayoutView {
  name: string;
  accessLevel: UAG;
  // the layout data of widegets, positions and sizes
  // note that in a mobile layout only the widget name and x position is used, where x = the page number within the mobile swipe pages
  layouts: ReactGridLayout.Layout[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any[]; // any props for each layout
}
