import EventEmitter from 'events';

/** the event types supported */
export enum systemEventTopics {
  SITE = 'Site',
  SCU = 'SCU',
  MANIFEST = 'Manifest',
  FLOORPLANS = 'FloorPlans',
  BATTERY = 'BatteryEvents',
  FAULTS = 'Faults',
  /** AUDIT EVENTS FROM THE SITE */
  SITEEVENTS = 'SiteEvents',
  ALARMEVENTS = 'AlarmEvents',
  CLIENT = 'Client',
  USER = 'User',
  DEVICE = 'Device',
  LIVEEVENTS = 'LiveEvents',
  DOOREVENTS = 'DoorEvents',
  IOT = 'IOT',
  VIEW = 'View',
  DASHBOARD = 'Dashboard',
  WIRELESS_DEVICE = 'WirelessDevice',
  WIRELESS_DEVICE_LOCATION = 'WirelessDeviceLocation',
  ROOM = 'Room',
  RESIDENTGROUP = 'ResidentGroup',
}

export enum systemEventStates {
  NONE = '',
  LOADING = 'Loading',
  LOADED = 'Loaded',
  PROCESSED = 'Processed',
  LOADERROR = 'LoadError',
  SAVING = 'Saving',
  SAVED = 'Saved',
  SAVEERROR = 'SaveError',
  SELECTED = 'Selected',
  AUTHORISED = 'Authorised',
  NOTAUTHORISED = 'NotAuthorised',
  UPDATED = 'Updated',
  INITIAL_LOAD_COMPLETE = 'InitialLoadComplete',
  COMPLETE = 'Complete',
  EDIT = 'Edit',
  REFRESHNEEDED = 'RefreshNeeded',
  OFFLINE = 'Offline',
  ONLINE = 'OnLine',
  DOWNLOADCOMPLETE = 'Downloadcomplete',
  NAVIGATE = 'Navigate',
}

export interface iEventParams {
  // Event subject string
  subject: string;
  // Any associated object containing optional event information
  detail: unknown;
  // Timestamp of the event in ISO date format
  timestamp: string;
}

/** The string used to indentify the event subject */
export const subjectString = (
  topic: systemEventTopics,
  state: systemEventStates
): string => {
  return `${topic}${state}`;
};

// Handle registration of listeners for loading events
const eventTarget = new EventEmitter();

/** Register to listen to new events.
 * @param topic - the topic of interest
 * @param state - the state in that topic, or NONE if not applicable
 * @param callback - the function to call when the event occurs
 * @param once - set true if you only want one callback on the next occurence, false if you want a callback for each event that occurs
 */
export const registerForEvent = (
  topic: systemEventTopics,
  state: systemEventStates,
  callback: (e: iEventParams) => void,
  once?: boolean
): void => {
  if (once) {
    eventTarget.once(subjectString(topic, state), callback);
  } else {
    eventTarget.on(subjectString(topic, state), callback);
  }
};

/** Remove registration of a listener - use the same parameters below that were used when orignally registering the event
 * @param topic - the topic of interest
 * @param state - the state in that topic, or NONE if not applicable
 * @param callback - the function to call when the event occurs
 * */
export const unRegisterForEvent = (
  topic: systemEventTopics,
  state: systemEventStates,
  callback: (e: iEventParams) => void
): void => {
  eventTarget.off(subjectString(topic, state), callback);
};

/** Dispatch new events to registered listeners
 * @param topic - the topic of interest
 * @param state - the state in that topic, or NONE if not applicable
 * @param detail - optional object with additional info for that event, i.e. event specific information
 * @param afterProcessTick - optional boolean. If true then the event is after the next process tick
 * @
 * */
export const emitEvent = (
  topic: systemEventTopics,
  state: systemEventStates,
  detail?: unknown,
  afterProcessTick?: boolean
): void => {
  if (detail == undefined) detail = '';
  const subject = `${topic}${state}`;
  // console.debug(subject);
  const timestamp = new Date().toISOString();
  const params: iEventParams = {
    subject: subject,
    detail: detail,
    timestamp: timestamp,
  };

  //process.nextTick(() => eventTarget.emit(e, detail));
  if (afterProcessTick) {
    // event emitting after current call stack being executed
    // this is required for events from redux otherwise we cannot access the store
    process.nextTick(() => eventTarget.emit(subject, params));
  } else {
    eventTarget.emit(subject, params);
  }
};
