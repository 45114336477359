/** @module GetDataItems */

import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty } from 'common/utils/miscUtils';

/**
 * @desc recursively fetch all items in a list query using nextToken
 * @param {Object} client The apollo client to use
 * @param {Object} query The query object from cda-graphql in use.
 * @param {Object} variables The variables to pass to query.
 * @param {boolean} recurse set true to do a recursive fetch
 * @param {boolean} log send results to the console log
 * @param {String} selector required string to select items form the data i.e. data[selector]
 * @param {String} selector2 optional string to select items form the data .i.e. data.selector.selector2
 * @param {String} sort field name if sorting required by a field
 * @param {Function} callback Optional callback function to be fired with every batch of items from query iteration.
 * @param {Function} results optional setter callback function to pass the results back at the end of the query
 * @param {Function} results2 optional setter callback function to pass the results back at the end of the query
 * @returns {Array} Array of all items received from queries.
 */
async function GetDataItems({
  /*client,*/
  query,
  variables,
  recurse = false,
  log = false,
  selector,
  selector2,
  sort = false,
  callback = undefined,
  results,
  results2 = undefined,
}) {
  let timestart = performance.now();
  let items = [];
  let res;
  let limitReached = false;
  do {
    const { data } = await API.graphql(graphqlOperation(query, variables));

    res =
      data && selector2
        ? data[selector]
          ? data[selector][selector2]
          : null
        : data[selector];

    if (res.items) {
      items.push(...res.items);
    }

    if (callback) callback(res);

    if (!isEmpty(res) && !isEmpty(res.nextToken)) {
      variables['nextToken'] = res.nextToken;
    }

    if (!isEmpty(variables.limit) && items.length >= variables.limit) {
      limitReached = true;
      console.info(
        `Query limit reached: ${variables.limit} Items pulled ${items.length}`
      );
    }
  } while (
    recurse &&
    !isEmpty(res) &&
    !isEmpty(res.nextToken) &&
    !limitReached
  );

  if (sort) {
    // Sort alphabetically on name
    items.sort(function (a, b) {
      if (a[sort] < b[sort]) {
        return -1;
      }
      if (a[sort] > b[sort]) {
        return 1;
      }
      return 0;
    });
  }

  let response;
  if (res && !res.items && !recurse) {
    response = res;
  } else {
    response = items;
  }

  if (log) {
    let p = `${Math.round(performance.now() - timestart)} ms to load query`;
    console.log(response);
    console.log(p);
  }

  if (results) results(response);
  if (results2) results2(response);

  return response;
}

/**
 * @desc perform async mutation
 * @param {Object} client The apollo client to use
 * @param {Object} mutation The query object from cda-graphql in use.
 * @param {Object} variables The variables to pass to query.
 */
async function DoMutation({ /*client,*/ mutation, variables }) {
  const { data } = await API.graphql(graphqlOperation(mutation, variables));

  return data;
}

export { GetDataItems, DoMutation };
