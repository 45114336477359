/** @module TitleBlock */

import { formatDate } from 'common/utils/dateUtils';
import * as Site from 'components/floorplan/site';
import { fabric } from 'fabric';

/**
 * Handles the drawing of the title block on the plans
 */

// The location of the Title Block
const Titlex = 820;
const Titley = 2090;
const Titlegroup = new fabric.Group([], {
  hasControls: false,
  name: 'TitleBlock',
  hoverCursor: 'none',
});

const columns = [4, 80, 250, 500];
const rows = [4, 40, 80];
//const rowSpacing = 18;
const fontSize = [26, 24, 20];

/** Draw the title block information*/
export const createTitleBlock = (canvas: fabric.Canvas, plan: number): void => {
  Titlegroup.remove(...Titlegroup.getObjects());
  canvas.remove(Titlegroup);

  const sitetext = new fabric.Text(
    `${Site.siteData.name} - ${Site.siteData.plans[plan].planName}`,
    {
      left: Titlex + columns[0],
      top: Titley + rows[0],
      fontFamily: 'Verdana',
      fontSize: fontSize[0],
      fill: 'black',
    }
  );
  const rt =
    Site.siteData.quoteRef && Site.siteData.revision
      ? `Reference: ${Site.siteData.quoteRef} R${
          Site.siteData.revision
        }    ${formatDate(Site.siteData.lastSaved)}`
      : '';
  const revisiontext = new fabric.Text(rt, {
    left: Titlex + columns[0],
    top: Titley + rows[1],
    fontFamily: 'Verdana',
    fontSize: fontSize[1],
    fill: 'black',
  });
  const pagetext = new fabric.Text(
    `Page ${plan + 1} of ${Site.siteData.plans.length}`,
    {
      left: Titlex + columns[0],
      top: Titley + rows[2],
      fontFamily: 'Verdana',
      fontSize: fontSize[1],
      fill: 'black',
    }
  );

  const textGroup = new fabric.Group([sitetext, revisiontext, pagetext], {
    name: 'titletextgrp',
  });

  Titlegroup.addWithUpdate(textGroup);
  canvas?.add(Titlegroup);
};
