//import * as CanvasSite from 'components/floorplan/canvasSite';
//import * as events from 'components/systemevents/events';
import React, { useEffect, useState } from 'react';
import 'types/event-enums';
import Tile from '../tile';
import { fabric } from 'fabric';

const PlanSpy = (): JSX.Element => {
  const [spy /*SetSpy*/] = useState();
  const [, SetChildEvent] = useState('');
  const [canvas /*setCanvas*/] = useState<fabric.Canvas>();
  /** the site containging all plan data */
  // const [site, useSite] = useState<CanvasSite>(new CanvasSite());

  useEffect(() => {
    console.log('');
  }, [spy]);

  useEffect(() => {
    // const c: fabric.Canvas = initCanvas();
    // setCanvas(c);
    // site.canvas = c;
    // site.loadSite().then(() => {
    //   if (site.plans.length > 0) site.populatePlan(0);
    // });
    // UseEffect's cleanup function
    return () => {
      if (canvas) {
        canvas.dispose();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile title="Plan Spy" setChildEvent={SetChildEvent}>
      {/* {site && site.plans && site.plans.length > 0 && (
        <p>{site.plans[0].planName}</p>
      )} */}
      <canvas id="canvasspy" />
    </Tile>
  );
};

export default PlanSpy;
