import {
  convertSingleArrayToKeyValue,
  iField,
  iSelectKeyValue,
} from 'components/forms/formFields';
import * as UserUtils from 'common/userUtils';
import { Manifest } from 'components/installation/manifest';
import { iEndpoint_CC_SIP } from '../../../../types/manifest-types';
import { containsOnlyDigits } from '../../../../common/utils/numberUtils';
import { set } from 'lodash';

// ID = invalidID;
// Description = '';
// ProtcolType = 0;
// RegistryURI = '';
// Port = 5060;
// AuthScheme = 'digest';
// AuthUserName = '';
// AuthPassword = '';
// Realm = '*';
// ConnectionFlags = 0;
// SIPIdURI = '';
// UnitId = '00000000';
// Address1URI = null;
// Address2URI = null;
// Address3URI = null;
// AllowConcurrentAlarms = 0; // not currently supported, each alarm can be raised with its own call session
// ConfigurationType = 1; // BS8521 Configuration System Type
// DelayForAlarmMessageSend = 0; // optional delay prior to sending alarm message (not necessary, just contigency )
// HandshakeMode = 0; // 0 = as per BS8521, 1 = as per Tunstall workaround (send alarm message after call established)
// HeartbeatLocalUnitIdent = ''; // UMO ARCs require an additional local unit ID sending with the heartbeat message.
// ImmediateAlarmMessage = 0; // send all alarm messages as the alarm message occurs (i.e. not just when requested by the ARC)
// InDialogMessages = 0; // Send Audio Alarm Message with InDialog Call ID field.

export interface iARCDigitalForm {
  ID: number;
  Description: string;
  UnitId: string;
  AlarmMessageURI: string;
  HeartbeatURI: string;
  AlarmMessagePort: string;
  HeartbeatPort: string;
  RegistryURI: string;
  RegistryPort: string;
  SIPIdURI: string;
  AuthUserName: string;
  AuthPassword: string;
  ConfigurationType: number;
  HandshakeMode: number;
  HeartbeatLocalUnitIdent: string;
  ImmediateAlarmMessage: boolean;
  InDialogMessages: boolean;
  DelayForAlarmMessageSend: string;
  TLSEnabled: boolean;
  AudioCallsOnly: boolean;
}

const setTLS = (uri: string, enableTLS: boolean): string => {
  let a: string[] = [];
  let aURI = uri;
  if (uri != '') {
    a = uri.split(':'); // should be protocol : address : port
    if (a.length > 1) {
      aURI = (enableTLS ? 'sips' : 'sip') + ':' + a[1];
    }
  }

  return aURI;
};

export const loadArcDigital = (data: iEndpoint_CC_SIP): iARCDigitalForm => {
  const tlsOn: boolean = data.RegistryURI
    ? data.RegistryURI.toLowerCase().includes('sips')
    : false;

  let a1: string[] = [];
  let a1Port = -1;
  let a1Add = '';
  if (data.Address1URI) {
    a1 = data.Address1URI.split(':'); // should be protocol : address : port
    if (a1.length > 1) {
      a1Add = a1[0] + ':' + a1[1];
    }
    if (a1.length > 2) {
      // means we have a possible port specified
      if (containsOnlyDigits(a1[2])) a1Port = Number(a1[2]);
    }
  }

  let a2: string[] = [];
  let a2Port = -1;
  let a2Add = '';
  if (data.Address2URI) {
    a2 = data.Address2URI.split(':'); // should be protocol : address : port
    if (a2.length > 1) {
      a2Add = a2[0] + ':' + a2[1];
    }
    if (a2.length > 2) {
      // means we have a possible port specified
      if (containsOnlyDigits(a2[2])) a2Port = Number(a2[2]);
    }
  }

  const form: iARCDigitalForm = {
    ID: 0,
    Description: data.Description ?? '',
    UnitId: data.UnitId ? data.UnitId.substring(0, 8) : '00000000',
    AlarmMessageURI: a1Add,
    HeartbeatURI: a2Add,
    AlarmMessagePort: a1Port != -1 ? a1Port.toString() : '',
    HeartbeatPort: a2Port != -1 ? a2Port.toString() : '',
    RegistryURI: data.RegistryURI ?? '',
    RegistryPort: data.Port ? data.Port.toString() : '',
    SIPIdURI: data.SIPIdURI ?? '',
    AuthUserName: data.AuthUserName ?? '',
    AuthPassword: data.AuthPassword ?? '',
    ConfigurationType: data.ConfigurationType,
    HandshakeMode: data.HandshakeMode,
    HeartbeatLocalUnitIdent: '',
    AudioCallsOnly: data.NonAudioAsAudio == 1,
    ImmediateAlarmMessage: data.ImmediateAlarmMessage == 1,
    InDialogMessages: data.InDialogMessages == 1,
    DelayForAlarmMessageSend: data.DelayForAlarmMessageSend.toString(),
    TLSEnabled: tlsOn,
  };

  return form;
};

export const saveArcDigital = (
  data: iARCDigitalForm | undefined,
  mData: iEndpoint_CC_SIP
): void => {
  if (data == undefined) return;
  const add1 =
    data.AlarmMessagePort != ''
      ? data.AlarmMessageURI + ':' + data.AlarmMessagePort
      : data.AlarmMessageURI;

  const add2 =
    data.HeartbeatPort != ''
      ? data.HeartbeatURI + ':' + data.HeartbeatPort
      : data.HeartbeatURI;

  mData.Description = data.Description;
  mData.RegistryURI = data.RegistryURI;
  mData.Port = containsOnlyDigits(data.RegistryPort)
    ? Number(data.RegistryPort)
    : 0;
  mData.Address1URI = add1;
  mData.Address2URI = add2;
  mData.SIPIdURI = data.SIPIdURI;
  mData.UnitId = data.UnitId.substring(0, 8);
  mData.AuthUserName = data.AuthUserName;
  mData.AuthPassword = data.AuthPassword;
  mData.HandshakeMode = data.HandshakeMode;
  mData.ConfigurationType = data.ConfigurationType;
  mData.ImmediateAlarmMessage = data.ImmediateAlarmMessage ? 1 : 0;
  mData.InDialogMessages = data.InDialogMessages ? 1 : 0;
  mData.NonAudioAsAudio = data.AudioCallsOnly ? 1 : 0;
  mData.DelayForAlarmMessageSend = containsOnlyDigits(
    data.DelayForAlarmMessageSend
  )
    ? Number(data.DelayForAlarmMessageSend)
    : 0;
};

export const arcDigitalForm = (
  arc: iARCDigitalForm | undefined,
  m: Manifest
): iField[] => {
  if (arc) arc.AlarmMessageURI = setTLS(arc.AlarmMessageURI, arc.TLSEnabled);
  if (arc) arc.HeartbeatURI = setTLS(arc.HeartbeatURI, arc.TLSEnabled);
  if (arc) arc.RegistryURI = setTLS(arc.RegistryURI, arc.TLSEnabled);

  return [
    {
      label: 'Description',
      id: 'Description',
      formBoxWidth: '460px',
      type: 'text',
      column: 1,
    },
    {
      label: 'Registration URI',
      id: 'RegistryURI',
      type: 'text',
      formBoxWidth: '460px',
      column: 1,
    },

    {
      label: 'Alarm Messaging URI',
      id: 'AlarmMessageURI',
      type: 'text',
      formBoxWidth: '460px',
      column: 1,
    },
    {
      label: 'HeartBeat Messages URI',
      id: 'HeartbeatURI',
      type: 'text',
      formBoxWidth: '460px',
      column: 1,
    },
    {
      label: 'Scheme ID URI',
      id: 'SIPIdURI',
      type: 'text',
      formBoxWidth: '460px',
      column: 1,
    },
    {
      label: '',
      id: '',
      type: 'blank',
      column: 1,
      readOnly: false,
    },
    {
      label: '',
      id: '',
      type: 'blank',
      column: 1,
      readOnly: false,
    },
    {
      label: '',
      id: '',
      type: 'blank',
      column: 1,
      readOnly: false,
    },
    {
      label: 'ADVANCED',
      id: '',
      type: 'plainstring',
      column: 1,
      readOnly: false,
    },
    {
      label: 'Protocol Variant',
      id: 'HandshakeMode',
      formBoxWidth: '260px',
      type: 'select',
      options: [
        { key: 0, value: 'Standard BS8521-2' },
        { key: 1, value: 'Tunstall BS8521-2' },
      ],
      column: 1,
      readOnly: false,
    },
    {
      label: 'Configuration System Type',
      id: 'ConfigurationType',
      type: 'select',
      formBoxWidth: '460px',
      options: [
        { key: 0, value: '0 - Local Unit and Controller' },
        { key: 1, value: '1 - Grouped Equipment with Supervisor Off Duty' },
        { key: 2, value: '2 - Grouped Equipment with Supervisor On Duty' },
        {
          key: 3,
          value:
            '3 - Grouped equipment with supervisor on duty acting as an ARC',
        },
        {
          key: 5,
          value:
            '5 - Grouped equipment with supervisor off duty – local unit directly addressable',
        },
        {
          key: 6,
          value:
            '6 - Grouped equipment with supervisor on duty – local unit directly addressable',
        },
        {
          key: 7,
          value:
            '7 - Grouped equipment with supervisor on duty acting as an ARC – local unit directly addressable',
        },
      ],
      column: 1,
      readOnly: false,
    },
    {
      label: 'Audio Calls Only',
      id: 'AudioCallsOnly',
      type: 'checkbox',
      column: 1,
      readOnly: false,
      tip: 'All calls will be presented with audio (media stream). Technical calls will be converted to audio and present a spoken message to the ARC operator.',
    },
    {
      label: 'Immediate Alarm Messages',
      id: 'ImmediateAlarmMessage',
      type: 'checkbox',
      column: 1,
      readOnly: false,
      tip: 'Alarm messages will be sent as soon as an alarm occurs rather then wait for the ARC to send a query for any more alarms.',
    },
    {
      label: 'In Dialog Messaging',
      id: 'InDialogMessages',
      type: 'checkbox',
      column: 1,
      readOnly: false,
      tip: 'Audio call messaging is sent with call ID, local and remote tags. Essential for Tunstall ARCs.',
    },
    {
      label: 'Delay Alarm Message(ms)',
      id: 'DelayForAlarmMessageSend',
      type: 'text',
      validate: /^[0-9]+$/,
      formBoxWidth: '60px',
      column: 1,
      readOnly: false,
      tip: 'Can be used to create a small delay before the schgeme sends the alarm message when requested to do so.',
    },

    {
      label: 'Scheme ID',
      id: 'UnitId',
      formBoxWidth: '80px',
      type: 'text',
      validate: /^[0-9]+$/,
      column: 2,
    },
    {
      label: 'Registration Port',
      id: 'RegistryPort',
      formBoxWidth: '60px',
      type: 'text',
      validate: /^[0-9]+$/,
      column: 2,
    },
    {
      label: 'Alarm Message Port',
      id: 'AlarmMessagePort',
      formBoxWidth: '60px',
      type: 'text',
      validate: /^[0-9]+$/,
      column: 2,
    },
    {
      label: 'Heartbeat Port',
      id: 'HeartbeatPort',
      formBoxWidth: '60px',
      type: 'text',
      validate: /^[0-9]+$/,
      column: 2,
    },
    {
      label: 'Auth username',
      id: 'AuthUserName',
      formBoxWidth: '160px',
      type: 'text',
      column: 2,
    },
    {
      label: 'Auth password',
      id: 'AuthPassword',
      formBoxWidth: '160px',
      type: 'text',
      column: 2,
    },
    {
      label: 'TLS Encryption',
      id: 'TLSEnabled',
      type: 'checkbox',
      column: 2,
      readOnly: false,
    },
  ];
};
