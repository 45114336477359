import * as IotEvents from './IoTMessageDefines_EVENTS';
import * as IoTMessageDefines from './IoTMessageDefines';
import { Message } from './IotMessageHandler';
import { SCUIoTMessaging } from './SCUIoTMessaging';
import * as SQL from 'node-sql-parser';
import * as _ from 'lodash';
import * as ManifestUtils from 'components/installation/manifestUtils';
import * as EventDispatcher from 'store/eventDispatcher';

const SCUMessaging = SCUIoTMessaging.Instance;
const sqlParser = new SQL.Parser();
let parseEvents = false;

const tablesToIgnore = ['OAD', 'LOCATABILITY', 'TRIGGER_LEVEL'];

const handleConfigChangeMessage = async (
  msg: IotEvents.tsE_CLOUD_MSG_EVENTS_CONFIG_CHANGE
) => {
  if (!parseEvents) return;
  let changed = false;
  for (const index in msg.sqlData) {
    const sqlLine = msg.sqlData[index];
    const ast = sqlParser.astify(sqlLine) as SQL.AST;
    //console.info('ast:', ast);

    if (ast.type === 'update') {
      const update: SQL.Update = ast as SQL.Update;
      const tableName = (update.table as SQL.From[])[0].table;

      if (tablesToIgnore.includes(tableName)) return;

      const changeObj = {};
      for (const colIndex in update.set) {
        const col = update.set[colIndex];
        //console.info(`${tableName} field ${col.column} = ${col.value.value}`);
        _.set(changeObj, col.column, col.value.value);
      }

      //console.info('Change obj:', changeObj);
      await ManifestUtils.despatchToManifestStore(tableName, [changeObj]);
      changed = true;
    }

    /*
{
    "type": "update",
    "table": [
        {
            "db": null,
            "table": "SYSTEM_PARAMETERS",
            "as": null
        }
    ],
    "set": [
        {
            "column": "ID",
            "value": {
                "type": "number",
                "value": 243
            },
            "table": null
        },
        {
            "column": "Value",
            "value": {
                "type": "single_quote_string",
                "value": "900"
            },
            "table": null
        }
    ],
    "where": {
        "type": "binary_expr",
        "operator": "=",
        "left": {
            "type": "column_ref",
            "table": null,
            "column": "ID"
        },
        "right": {
            "type": "number",
            "value": 243
        }
    },
    "orderby": null,
    "limit": null
}
*/
  }

  if (changed) {
    EventDispatcher.emitEvent(
      EventDispatcher.systemEventTopics.MANIFEST,
      EventDispatcher.systemEventStates.LOADED,
      null,
      true
    );
  }
};

export const register = (): void => {
  SCUMessaging.registerMessageTypeCallback(
    IoTMessageDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_EVENTS,
    async (msg: Message) => {
      if (
        msg.type ==
          IoTMessageDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_EVENTS &&
        msg.opcode ==
          IotEvents.teCLOUD_MSG_TY_EVENTS.E_CLOUD_MSG_EVENTS_CONFIG_CHANGE
      ) {
        /*
      const detail: IOTResponse.iIotResponseEventDetail = {
        transId: msg.transid,
        topic: IOTResponse.IoTTopics.SCU_CONFIGURATION_CHANGE,
        userData: null,
        msgHeader: msg,
      };*/
        if (msg.msg) {
          await handleConfigChangeMessage(
            msg.msg as IotEvents.tsE_CLOUD_MSG_EVENTS_CONFIG_CHANGE
          );
        }
      }
    },
    null
  );
  parseEvents = true;
};

export const unregister = (): void => {
  parseEvents = false;
};
