import {
  teCLOUD_MSG_TY_DEVICES,
  tsCLOUD_MSG_DEVICES_DISCOVER_DEVICE_REQ,
  tsCLOUD_MSG_DEVICES_COMISSION_REQ,
} from './IoTMessageDefines_DEVICES';

import { teCLOUD_MESSAGE_TYPES } from './IoTMessageDefines';

import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
import { Device } from 'components/installation/device';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

// Re-export the tePROCESSOR from IoTMessageDefines
export { teCLOUD_ERROR_CODES, getErrorText } from './IoTMessageDefines';
export { teDEVICE_TYPES, teDEVICE_MODELS } from 'types/manifest-enums';
export type { tsCLOUD_MSG_DEVICES_COMISSION_RESP } from './IoTMessageDefines_DEVICES';

export async function discoverAll(
  userData: unknown
): Promise<iIotResponseEventDetail> {
  console.info('discoverAll');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode = teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_DISCOVER_ALL_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_GET_DISCOVER_STATUS_RESP;

  const msg = {};
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'discoverAll',
    userData
  );
}

export async function discoverDevice(
  mac: number,
  userData: unknown
): Promise<iIotResponseEventDetail> {
  console.info('discoverDevice');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode = teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_DISCOVER_DEVICE_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_GET_DISCOVER_STATUS_RESP;

  const msg: tsCLOUD_MSG_DEVICES_DISCOVER_DEVICE_REQ = {
    mac: Device.MACaddrToString(mac, 10),
  };

  const transId = MessageHandler.getNextGlobalTransactionId();

  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'discoverDevice',
    userData
  );
}

export async function getDiscoverState(
  userData: unknown
): Promise<iIotResponseEventDetail> {
  console.info('getDiscoverState');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_GET_DISCOVER_STATUS_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_GET_DISCOVER_STATUS_RESP;

  const msg = {};
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'getDiscoverState',
    userData
  );
}

export async function commissionReq(
  ip: boolean,
  rf: boolean,
  timeout: number,
  userData: unknown
): Promise<iIotResponseEventDetail> {
  console.info('commissionReq');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode = teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_COMISSION_REQ;
  const rplyopcode = teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_COMISSION_RESP;

  const msg: tsCLOUD_MSG_DEVICES_COMISSION_REQ = {
    commissionlengthseconds: timeout,
    commissionrf: rf,
    commissionip: ip,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'commissionReq',
    userData
  );
}
