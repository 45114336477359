/** @module types */

import { iAlarmTypeDescription } from './event-enums';
import { teDEVICE_TYPES, teDEVICE_MODELS } from './manifest-enums';
import { Point } from './plan-types';

export enum INSTALLSTATUS {
  DESIGN = 'Design Only',
  ORDERED = 'Ordered',
  SUPPLIED = 'Supplied',
  FITTED = 'Fitted',
  COMMISSIONED = 'Commissioned',
}

export enum OUTPUTMETHOD {
  RELAYNO = 'N/O Relay',
  RELAYNC = 'N/C Relay',
  TWELVEVOLTPERMANENT = '12V Permanent',
  TWELVEVOLTPULSED = '12V Pulsed',
}

export interface InputType {
  /** The name of the input, i.e. input1 , input2,  */
  inputname: string;
  /** set to true if this input has to be connected, i.e. cannot be left unconnected in an installation  */
  mandatory: boolean;
  /** Wiring of the input */
  wiring: 'N/O' | 'N/C';
  /** the configured alarm type for this input, if relevant */
  alarmType: iAlarmTypeDescription;
  /** minimum input duration in ms */
  minDuration: number;
}

export interface OutputType {
  /** The name of the output, i.e. output1 , output2,  */
  outputname: string;
  /** set to true if this output has to be connected, i.e. cannot be left unconnected in an installation  */
  mandatory: boolean;
  /** output method */
  method: OUTPUTMETHOD;
}

export interface Cat5Cable {
  /** connected to unique ID */
  connectedTo: string;
  /** port */
  port: number;
}

export interface MACable {
  /** connected to unique ID of an MAI*/
  connectedTo: string;
}

/** Default values for location interface, for ease of use in creating objects */
export const defaultLocationValues: Location = {
  plan: -1,
  x: 0,
  y: 0,
  id: 0,
  uniqueID: '',
  angle: 0,
  calloutLength: 10,
  commonArea: false,
  locationName: '',
  locatabilityName: '',
  cat5Connection: { connectedTo: '', port: 0 },
  MAIconnection: { connectedTo: '' },
  MAPconnection: { connectedTo: '' },
  designNotes: '',
  serviceNotes: '',
  installStatus: INSTALLSTATUS.DESIGN,
};

/**
 * Location
 * @interface
 * @extends Point
 */
export interface Location extends Point {
  /** the sequential ID in the plan of that device type from 1 to xxxx */
  id: number;
  /** the unique ID on the plan which is the designator + id */
  uniqueID: string;
  /** rotation angle if rotated */
  angle: number;
  /** callout length, extends the call out box to aid in making the plan tidy */
  calloutLength: number;
  /** commonArea is set true if the unit resides in a common area, used for room units etc*/
  commonArea: boolean | undefined;
  /** Room name of where it is installed */
  locationName?: string;
  /** Descriptive name to be used when reporting locatibility (e.g. a staff room or communnal area for handsets for example) */
  locatabilityName: string;
  /** Install status */
  installStatus: INSTALLSTATUS;
  /** UniqueID of the equipment that the incoming CAT5 cable goes to i.e. POE1 or MLS12  */
  cat5Connection?: Cat5Cable;
  MAIconnection?: MACable;
  MAPconnection?: MACable;
  /** Any design notes for this item */
  designNotes?: string;
  /** Any Service notes for this item */
  serviceNotes?: string;
}

/**
 * System
 * @readonly
 * @enum
 * @property NONE
 * @property CARELOCATE
 * @property OMNIVIA
 * @property ALL
 */
export enum System {
  NONE,
  CARELOCATE,
  OMNIVIA,
  ALL,
}

/**
 * ProductCategory
 * @readonly
 * @enum
 * @property NONE
 * @property ROOM
 * @property SYSTEM
 * @property WIRELESSACCESS
 * @property PERIPHERAL
 * @property DOOR ENTRY
 * @property LIFT
 * @property POWER
 */
export enum ProductCategory {
  NONE,
  ROOM,
  SYSTEM,
  WIRELESSACCESS,
  PERIPHERAL,
  DOORENTRY,
  LIFT,
  POWER,
  /* equipment items that are not part of WH installed products e.g. outputs from other 3rd pary equipment */
  /* used for wiring plans etc */
  THIRDPARTYOUTPUT,
  PERIPHERAL_TUNSTALL,
}

export const ProductCategorySelect = [
  { key: ProductCategory.NONE, text: 'None' },
  { key: ProductCategory.ROOM, text: 'Room' },
  { key: ProductCategory.SYSTEM, text: 'System' },
  { key: ProductCategory.WIRELESSACCESS, text: 'Access Points' },
  { key: ProductCategory.PERIPHERAL, text: 'Peripherals' },
  { key: ProductCategory.DOORENTRY, text: 'Door Entry' },
  { key: ProductCategory.LIFT, text: 'Lift' },
  { key: ProductCategory.POWER, text: 'Power' },
  { key: ProductCategory.THIRDPARTYOUTPUT, text: 'Miscellaneous' },
];

/**
 * includeOtherProduct e.g. a backbox
 * @interface */
export interface includeOtherProduct {
  /** the other related product code */
  productCode: string;
  /** the qty to incluce */
  qty: number;
}

/** Default values for equipment interface, for ease of use in creating objects */
export const defaultEquipmentValues: Equipment = {
  productCode: '',
  name: '',
  system: System.NONE,
  category: ProductCategory.NONE,
  designator: '',
  voltage: 0,
  powerConsumptionmA: 0,
  firstFix: false,
  designItem: true,
  deviceType: teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT,
  deviceModel: teDEVICE_MODELS.DEVICE_MODEL_V1,
  requiredOthers: undefined,
  cat5Required: false,
  MAIRequiredNumber: 0,
  MAPRequired: false,
  assignable: false,
  inputs: [],
  outputs: [],
};

/** tODO: refactror to remove baseNAme and baseDesignator, simplify name and designator fields to remove colour and add a colour field */

/**
 * Equipment
 * @interface
 */
export interface Equipment {
  /** The Wireless Health Product Code e.g. PROD-3143 */
  productCode: string;
  /** Descriptive name of the product e.g. Video Room Unit */
  name: string;
  /** Descriptive name of the product without any colour varient e.g. Multi-Access Point */
  baseName?: string;
  /** Longer description of the product */
  description?: string;
  /** Assigned Unit or Room number for assignable equipment */
  unitNumber?: number | undefined;
  /** The installed identifier, i.e. MAC address or serial number in the case of an SCU  */
  physicalID?: number | undefined;
  /** Assignable, i.e. is assigned by the sCU along with a unit/room number */
  assignable?: boolean;
  /** The system that this product is suitable fori.e. CARELOCATE, OMNIVIA */
  system: System;
  /** The symbol designator e.g. VRU */
  designator: string;
  /** The symbol designator without any colour variente e.g. MAP */
  baseDesignator?: string;
  /** the product category i.e. ROOM, SYSTEM...*/
  category: ProductCategory;

  /** Firstfix is set true if this is a 1st fix item, e.g. backbox*/
  firstFix: boolean;
  //** designItem is set true if this is a product to placed on a plan*/
  designItem: boolean;

  /** cross reference to the WH embedded device types */
  deviceType?: teDEVICE_TYPES;
  /** cross reference to the WH embedded device models e..g V0, V1, V2...*/
  deviceModel?: teDEVICE_MODELS;

  /** The working voltage of the the product */
  voltage: number;
  /** The product's current rating */
  powerConsumptionmA: number;
  /** does this product need a cat5 connection?   */
  cat5Required?: boolean;
  /** available CAT5 ports for product to connect to me */
  cat5Ports?: number;
  /** does this product need a MAI connection and how many CAT5 cables?  e.g. lift, visual pullcord */
  MAIRequiredNumber?: number;
  /** does this product need a MAP connection e.g. mains fail or 3rd party outputs */
  MAPRequired?: boolean;
  /** Product inputs */
  inputs: InputType[];
  /** Product outputs */
  outputs: OutputType[];
  /** */
  /** if other products need to be added in order to sue this product (i.e. backbox) */
  requiredOthers: includeOtherProduct[] | undefined;
  /** Is this still a manufacrtured item */
  obsolete?: boolean;
  /** The replacement part's product code */
  replaceProductCode?: string;
}
