import Tile, { iTileEvent } from 'components/dashboard/tile';
import React, { useEffect, useState } from 'react';
import * as eventDispatcher from 'store/eventDispatcher';
import { BaseTable } from 'components/tables/baseTable';
import { createColumnHelper } from '@tanstack/react-table';
import { manifest } from 'components/floorplan/site';
import {
  formatDateWithSeconds,
  formatTimeOnlyHHMM,
  getDateFromEpoch,
} from 'common/utils/dateUtils';
import { useInterval } from 'hooks/useInterval';

export interface iData {
  roomNumber: string;
  room: string;
  timeOver: string;
}

const columnHelper = createColumnHelper<iData>();

const columns = [
  columnHelper.accessor('roomNumber', {
    header: 'Room Number',
    id: 'roomNumber',
    cell: (info) => info.getValue(),
    size: 150,
  }),
  columnHelper.accessor('room', {
    header: 'Room',
    enableColumnFilter: false,
    size: 150,
  }),
  columnHelper.accessor('timeOver', {
    header: 'Time Ending',
    enableColumnFilter: false,
    size: 100,
  }),
];

function RoomTable() {
  const [data, setData] = useState<iData[]>([]);
  const [lastEvent, setLastEvent] = useState('');

  useInterval(() => {
    const ts = formatDateWithSeconds(new Date());
    setLastEvent(ts);
  }, 1000);

  useEffect(() => {
    const epochNow = new Date(Date.now()).getTime() / 1000;
    const rooms: {
      roomNumber: string;
      room: string;
      timeOver: string;
    }[] = [];
    manifest.rooms.forEach((element) => {
      if (element.TestModeEnabledEPOCH > epochNow) {
        rooms.push({
          roomNumber: element.RoomNumber.toString(),
          room: element.Name,
          timeOver: formatTimeOnlyHHMM(
            getDateFromEpoch(element.TestModeEnabledEPOCH)
          ),
        });
      }
    });
    setData(rooms);
  }, [lastEvent]);

  return (
    <>
      <BaseTable
        columns={columns}
        data={data}
        rowAction={[]}
        eventTopic="EXAMPLETABLE"
      />
    </>
  );
}

const RoomsInTestMode = (): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        // updateInfo();
      },
      executeOnStartup: true,
    },
  ];

  return (
    <Tile
      title="Rooms In Test Mode"
      eventListeners={events}
      setChildEvent={SetChildEvent}
    >
      <>
        <RoomTable />
      </>
    </Tile>
  );
};
export default RoomsInTestMode;
