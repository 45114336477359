import React, { useState } from 'react';
import { BoxProps } from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';
import { ProSidebar, Menu, MenuItem } from 'components/sidebar/ProSidebar';
//import 'react-pro-sidebar/dist/css/styles.css';

export interface ISidebarProps {
  /** Title string or any react node */
  title: React.ReactNode;
  /** The width of the side bar, ot currently implemented */
  width?: string;
  /** Whether the sidebar can be collapsed */
  collapsible?: boolean;
  /** children content elements to be displayed in Sidebar */
  children: React.ReactNode;
}

/** Collapsible side bar component
 */
const PracticalSidebar = ({
  title,
  //width,
  children,
  collapsible,
}: // ...rest
ISidebarProps & BoxProps): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false);
  /*
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);*/

  const handleCollapsedChange = () => {
    if (collapsible) {
      setCollapsed(!collapsed);
    }
  };

  /*
  const handleImageChange = (checked: boolean) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value: boolean) => {
    setToggled(value);
  };
  */

  return (
    <ProSidebar collapsed={collapsed}>
      <Menu iconShape="square">
        <MenuItem icon={<MenuIcon />} clickCB={handleCollapsedChange}>
          {title}
        </MenuItem>
        {children}
      </Menu>
    </ProSidebar>
  );
};

PracticalSidebar.defaultProps = {
  title: 'Sidebar Menu',
  width: 'medium',
  collapsible: true,
};

export default PracticalSidebar;
