/** @module Installation */

import { store } from 'store/store';
//import * as manifestslice from 'store/manifestSlice';
import {
  iDevice,
  iManifest,
  iRoom,
  iAccessPoint,
  iRoutingEvent,
  iSwVersion,
  iDoorsAndOtherInteraces,
  iHandset,
} from 'types/manifest-types';
import * as ip from 'common/ip';
import {
  iDeviceDefaults /*iSCHEDULE*/,
  iResidentGroup,
} from '../../types/manifest-types';
import { Room } from './room';
import { AccessPoint } from './accesspoint';
import { Handset } from './handset';
import { Trigger, isWirelessDevice } from './trigger';
import { teDEVICE_TYPES, teSCHEDULES_OBJECT_TYPES } from 'types/manifest-enums';
import * as eventDispatcher from 'store/eventDispatcher';
import { DoorAndDeviceInterface } from './doorAndDeviceInterface';
import { getLastLiveEvent } from 'components/systemevents/liveEvents';
import * as _ from 'lodash';
import * as manifestUtils from 'components/installation/manifestUtils';
import { SystemParameter } from './systemParameter';
import { EndpointCCDTMF } from './endpoint_cc_dtmf';
import { SCUSQLInjection } from 'common/IoT/IoTSCUSQLInjection';
import { CameraConfig } from './cameraConfig';
import { GroupHandset } from './groupHandset';
import { EndpointCCSIP } from './endpoint_cc_sip';
import { CareGroup } from './careGroup';
import { CareSequence } from './careSequence';
import { RoutingResource } from './routingResource';
import { AlarmPriority } from './alarmPriority';
import { Schedule } from './schedule';
import { getCareGroupSequenceId } from './careGroupUtils';
import semver from 'semver';
import * as IoTDeviceAddDelete from 'common/IoT/IoTAddDelete';
import * as IoTMsgDefines_DEVICES from 'common/IoT/IoTMessageDefines_DEVICES';
import * as IoTMsgDefines from 'common/IoT/IoTMessageDefines';
import { CloudMetaData } from './cloudMetadata';
//import logger from 'common/logger';
import { iResidentGroupDefaults } from '../../types/manifest-types';
import { GatewayInterface, isGatewayInterface } from './gatewayInterface';

export { teErrorCode } from 'components/installation/manifestUtils';
interface iTableIDs {
  SCHEDULES: number;
  CLOUD_META_DATA: number;
}

/**
 * Handles all site equipment data
 * Manifest is a singleton
 * @class
 * @hideconstructor
 */
export class Manifest {
  private static _Instance: Manifest;

  swVersion: iSwVersion = { major: 0, minor: 0, build: 0 };
  devices: iDevice[] = [];
  rooms: Room[] = [];
  accessPoints: AccessPoint[] = [];
  handsets: Handset[] = [];
  triggers: Trigger[] = [];
  deviceInterfaces: DoorAndDeviceInterface[] = [];
  systemParameters: SystemParameter[] = [];
  endpointCCDTMF: EndpointCCDTMF[] = [];
  manifestUpdated: Date = new Date();
  manifestLoaded = false;
  cameraConfigs: CameraConfig[] = [];
  groupHandsets: GroupHandset[] = [];
  endPointCCSIP: EndpointCCSIP[] = [];
  careGroups: CareGroup[] = [];
  careSequences: CareSequence[] = [];
  routingResources: RoutingResource[] = [];
  alarmPriorities: AlarmPriority[] = [];
  schedules: Schedule[] = [];
  nextTableIds: iTableIDs = { SCHEDULES: 1, CLOUD_META_DATA: 1 };
  cloudMetaData: CloudMetaData[] = [];
  gatewayInterfaces: GatewayInterface[] = [];

  public static get Instance(): Manifest {
    if (Manifest._Instance) {
      return Manifest._Instance;
    } else {
      return new Manifest();
    }
  }

  constructor() {
    if (Manifest._Instance) {
      return Manifest._Instance;
    }
    Manifest._Instance = this;
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.LOADED,
      () => {
        // eslint-disable-next-line  @typescript-eslint/no-use-before-define
        this.loadManifest();

        eventDispatcher.emitEvent(
          eventDispatcher.systemEventTopics.MANIFEST,
          eventDispatcher.systemEventStates.PROCESSED,
          null,
          true
        );
      }
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.LIVEEVENTS,
      eventDispatcher.systemEventStates.LOADED,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      (e) => {
        this.getLiveEvents();
      }
    );

    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.LIVEEVENTS,
      eventDispatcher.systemEventStates.UPDATED,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      (e) => {
        // e will have the new record
        this.getLiveEvents();
      }
    );
  }

  clear(): void {
    this.manifestLoaded = false;
    this.swVersion = { major: 0, minor: 0, build: 0 };
    this.devices = [];
    this.rooms = [];
    this.handsets = [];
    this.accessPoints = [];
    this.triggers = [];
    this.systemParameters = [];
    this.endpointCCDTMF = [];
    this.deviceInterfaces = [];
    this.manifestUpdated = new Date();
    this.cameraConfigs = [];
    this.groupHandsets = [];
    this.endPointCCSIP = [];
    this.careGroups = [];
    this.careSequences = [];
    this.alarmPriorities = [];
    this.routingResources = [];
    this.schedules = [];
    this.nextTableIds = { SCHEDULES: 1, CLOUD_META_DATA: 1 };
    this.cloudMetaData = [];
    this.gatewayInterfaces = [];
  }

  loadManifest(): {
    rooms: number;
    accessPoints: number;
    handsets: number;
    triggers: number;
  } {
    this.clear();
    const data = this.getJSON();
    //logger.debug(data);
    if (data) {
      this.manifestLoaded = true;
      this.manifestUpdated = new Date(data.SYSTEM.created);
      this.devices = data.DEVICE_LIST;

      // ensure care group is loaded first as other
      // classes will useit
      data.CARE_GROUP.forEach((e) => {
        const row = new CareGroup(
          e,
          _.keys(e),
          'CARE_GROUP',
          this.getSCUSoftwareVersion()
        );
        this.careGroups.push(row);
      });

      data.ALARM_TYPES.forEach((e) => {
        const row = new AlarmPriority(
          e,
          _.keys(e),
          'ALARM_TYPES',
          this.getSCUSoftwareVersion()
        );
        this.alarmPriorities.push(row);
      });

      if (data.CLOUD_META_DATA !== undefined) {
        let maxId = 0;
        data.CLOUD_META_DATA.forEach((e) => {
          const row = new CloudMetaData(
            e,
            _.keys(e),
            'CLOUD_META_DATA',
            this.getSCUSoftwareVersion()
          );
          this.cloudMetaData.push(row);
          if (row.ID > maxId) {
            maxId = row.ID;
          }
        });
        this.nextTableIds.CLOUD_META_DATA = maxId + 1;
      }

      data.RESIDENT.forEach((d) => {
        const dev = this.getDevice(d.HomeDeviceId);
        const routingEvents = this.getRoutingEvents(d.HomeDeviceId);
        const peripheralRoutingEvents = this.getResidentPeripheralRoutingEvents(
          d.HomeDeviceId,
          d.ID
        );
        const r = new Room(
          d,
          dev,
          routingEvents,
          peripheralRoutingEvents,
          _.keys(d),
          'RESIDENT'
        );
        this.rooms.push(r);
      });
      data.ROUTER.forEach((d) => {
        const dev = this.getDevice(d.DeviceId);
        const routingEvents = this.getRoutingEvents(d.DeviceId);
        const a = new AccessPoint(d, dev, routingEvents, _.keys(d), 'ROUTER');
        this.accessPoints.push(a);
      });
      data.DOOR_PANEL.forEach((d) => {
        const dev = this.getDevice(d.DeviceId);
        const routingEvents = this.getRoutingEvents(d.DeviceId);
        const a = new DoorAndDeviceInterface(
          d,
          dev,
          routingEvents,
          _.keys(d),
          'DOOR_PANEL'
        );
        this.deviceInterfaces.push(a);
      });

      data.ENDPOINT_HANDSET.forEach((d) => {
        if (d.DeviceId != 0) {
          const dev = this.getDevice(d.DeviceId);
          const routingEvents = this.getRoutingEvents(d.DeviceId);
          const h = new Handset(
            d,
            dev,
            routingEvents,
            _.keys(d),
            'ENDPOINT_HANDSET'
          );
          this.handsets.push(h);
        }
      });
      // logger.debug(this.handsets);
      data.DEVICE_LIST.forEach((d) => {
        if (d.ID != 0 && isWirelessDevice(d.Type)) {
          const dev = this.getDevice(d.ID);
          const routingEvents = this.getRoutingEvents(d.ID);
          // Pass the parent's (room) object when constructing the trigger so we can carry across any useful fields
          const room: iRoom | undefined = this.getRoomByDeviceID(dev.ParentID);
          const t = new Trigger(dev, room, routingEvents);
          this.triggers.push(t);
        } else if (isGatewayInterface(d.Type)) {
          const dev = this.getDevice(d.ID);
          const routingEvents = this.getRoutingEvents(d.ID);
          const GWI = new GatewayInterface(dev, routingEvents);
          this.gatewayInterfaces.push(GWI);
        }
      });
      data.SYSTEM_PARAMETERS.forEach((d) => {
        const sp = new SystemParameter(d, _.keys(d), 'SYSTEM_PARAMETERS');
        this.systemParameters.push(sp);
      });

      data.CAMERA_CONFIG.forEach((d) => {
        const sp = new CameraConfig(d, _.keys(d), 'CAMERA_CONFIG');
        this.cameraConfigs.push(sp);
      });

      const versionsString: string = data.SYSTEM['sw version'];

      if (versionsString) {
        const versionsParts = versionsString.split('.');

        if (versionsParts[0][0] === 'R' && versionsParts.length === 3) {
          console.info('*********** Version string:', versionsString);
          const major = parseInt(versionsParts[0].substring(1), 10);
          const minor = parseInt(versionsParts[1], 10);
          const build = parseInt(versionsParts[2], 10);
          this.swVersion = { major: major, minor: minor, build: build };
          console.info('**** Version obj:', this.swVersion);
          console.info('**** Version absolute:', this.getSCUSoftwareVersion());
        }
      }

      data.ENDPOINT_CC_DTMF.forEach((d) => {
        const row = new EndpointCCDTMF(
          d,
          _.keys(d),
          'ENDPOINT_CC_DTMF',
          this.getSCUSoftwareVersion()
        );
        this.endpointCCDTMF.push(row);
      });

      data.GROUP_HANDSET.forEach((d) => {
        const row = new GroupHandset(
          d,
          _.keys(d),
          'GROUP_HANDSET',
          this.getSCUSoftwareVersion()
        );
        this.groupHandsets.push(row);
      });

      data.ENDPOINT_CC_SIP.forEach((d) => {
        const row = new EndpointCCSIP(
          d,
          _.keys(d),
          'ENDPOINT_CC_SIP',
          this.getSCUSoftwareVersion()
        );
        this.endPointCCSIP.push(row);
      });

      data.ROUTING_RESOURCE.forEach((d) => {
        const row = new RoutingResource(
          d,
          _.keys(d),
          'ROUTING_RESOURCE',
          this.getSCUSoftwareVersion()
        );
        this.routingResources.push(row);
      });

      data.CARE_SEQUENCE.forEach((d) => {
        // console.log(d);
        const row = new CareSequence(
          d,
          _.keys(d),
          'CARE_SEQUENCE',
          this.getSCUSoftwareVersion(),
          this.routingResources,
          this.groupHandsets,
          this.endpointCCDTMF,
          this.endPointCCSIP
        );
        this.careSequences.push(row);
      });

      data.SCHEDULES.forEach((e) => {
        let maxId = 0;
        const row = new Schedule(
          e,
          _.keys(e),
          'SCHEDULES',
          this.getSCUSoftwareVersion()
        );
        this.schedules.push(row);
        if (row.ID > maxId) {
          maxId = row.ID;
        }
        this.nextTableIds.SCHEDULES = maxId + 1;
      });

      console.log(`next sequence id: ${this.nextTableIds.SCHEDULES}`);
      //console.log(this.schedules);
    } else {
      console.log('**NO MANIFEST DATA');
    }

    this.getLiveEvents();

    return {
      rooms: this.rooms.length,
      accessPoints: this.accessPoints.length,
      handsets: this.handsets.length,
      triggers: this.triggers.length,
    };
  }

  async getLiveEvents(): Promise<void> {
    this.triggers.forEach((t) => {
      const e = getLastLiveEvent(t.MacAddress.toString());
      t.lastEvent = e;
    });

    for (const index in this.handsets) {
      const hs = this.handsets[index];
      const e = getLastLiveEvent(hs.MacAddress.toString());
      await hs.setLastEvent(e);
    }

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.LIVEEVENTS,
      eventDispatcher.systemEventStates.PROCESSED,
      null,
      true
    );
  }

  getJSON = (): iManifest => {
    return <iManifest>store.getState().manifest.manifest;
  };

  /** find a device by its device ID
   * if none found then returns a default empty device with an invalid device ID
   * @param deviceID: number
   */
  getDevice = (deviceID: number): iDevice => {
    let d: iDevice = iDeviceDefaults;
    const r = this.devices.find((e) => e.ID == deviceID);
    if (r) {
      d = r;
    }

    return d;
  };

  /** find a device by its device MAC Address
   * if none found then returns a default of 0
   * @param mac: number
   */
  getDeviceByMac = (mac: number): iDevice => {
    let d: iDevice = iDeviceDefaults;
    const r = this.devices.find((e) => e.MacAddress == mac);
    if (r) d = r;

    return d;
  };

  /** find a room by its device ID
   * if none found then returns undefined
   * @param deviceID: number
   */
  getRoomByDeviceID = (deviceID: number): iRoom | undefined => {
    if (this.rooms) {
      const r: iRoom | undefined = this.rooms.find(
        (e) => e.HomeDeviceId == deviceID
      );
      return r;
    }
    return undefined;
  };

  /** find a room by its MAC address
   * if none found then returns undefined
   * @param mac: number
   */
  getRoomByDeviceMac = (mac: number): iRoom | undefined => {
    if (this.rooms) {
      const r: iRoom | undefined = this.rooms.find((e) => e.MacAddress == mac);
      return r;
    }
    return undefined;
  };

  /** find a room by its room number
   * if none found then returns undefined
   * @param mac: number
   */
  getRoomByRoomNumber = (roomNumber: number): Room | undefined => {
    if (this.rooms) {
      const r: Room | undefined = this.rooms.find(
        (e) => e.RoomNumber == roomNumber
      );
      return r;
    }
    return undefined;
  };

  /** find a room by its resident database id
   * if none found then returns undefined
   * @param mac: number
   */
  getRoomByResidentId = (residentId: number): Room | undefined => {
    if (this.rooms) {
      const r: Room | undefined = this.rooms.find(
        (e) => e.productManifest.tableID == residentId
      );
      return r;
    }
    return undefined;
  };

  /** find a wireless access point by its MAC address
   * if none found then returns undefined
   * @param mac: number
   */
  getAccessPointByDeviceMac = (mac: number): iAccessPoint | undefined => {
    if (this.accessPoints) {
      const r: iAccessPoint | undefined = this.accessPoints.find(
        (e) => e.MacAddress == mac
      );
      return r;
    }
    return undefined;
  };

  /** find a interface by its MAC address
   * if none found then returns undefined
   * @param mac: number
   */
  getInterfaceByDeviceMac = (
    mac: number
  ): iDoorsAndOtherInteraces | undefined => {
    if (this.deviceInterfaces) {
      const i: iDoorsAndOtherInteraces | undefined = this.deviceInterfaces.find(
        (e) => e.MacAddress == mac
      );
      return i;
    }
    return undefined;
  };

  /** find a handset by its MAC address
   * if none found then returns undefined
   * @param mac: number
   */
  getHandsetByDeviceMac = (mac: number): iHandset | undefined => {
    if (this.deviceInterfaces) {
      const i: iHandset | undefined = this.handsets.find(
        (e) => e.MacAddress == mac
      );
      return i;
    }
    return undefined;
  };

  /** find a handset by its MAC address
   * if none found then returns undefined
   * @param mac: number
   */
  getGWIDeviceMac = (mac: number): GatewayInterface | undefined => {
    if (this.gatewayInterfaces) {
      const i: GatewayInterface | undefined = this.gatewayInterfaces.find(
        (e) => e.MacAddress == mac
      );
      return i;
    }
    return undefined;
  };

  /** find all routing events for a device
   * if none found then returns a empty list
   * @param deviceID: number
   */
  getRoutingEvents = (deviceID?: number): iRoutingEvent[] => {
    const routingevents = this.getJSON().ROUTING_EVENT;

    if (deviceID === undefined) {
      return routingevents;
    }

    let filtered: iRoutingEvent[] = [];
    if (routingevents) {
      filtered = routingevents.filter(
        (item) => item.AlarmSourceDeviceId == deviceID
      );
    }
    return filtered;
  };

  /** find all routing events for a care group
   * if none found then returns a empty list
   * @param deviceID: number
   */
  getRoutingEventsForCareGroup = (careGroupID: number): iRoutingEvent[] => {
    const seqID = getCareGroupSequenceId(careGroupID);
    const routingevents = this.getJSON().ROUTING_EVENT;

    let filtered: iRoutingEvent[] = [];
    if (routingevents) {
      filtered = routingevents.filter((item) => item.CareSequenceId == seqID);
    }
    return filtered;
  };

  /** find all peripheral routing events for a resident
   * This will not return any events for the specific room device, only radio peripherals
   * @param deviceID: number
   */
  getResidentPeripheralRoutingEvents = (
    deviceID: number,
    residentId: number
  ): iRoutingEvent[] => {
    const routingevents = this.getJSON().ROUTING_EVENT;
    let filtered: iRoutingEvent[] = [];
    if (routingevents) {
      filtered = routingevents.filter(
        (item) =>
          item.AlarmSourceDeviceId != deviceID && item.ResidentId == residentId
      );
    }
    return filtered;
  };

  /*
   Omnivia devices are usually assigned IP addresses automatically by the SCU.
   If we require to assign a static IP address to a device, for exmaple 2N door panels, then the 
   IPAddress field in the DEVICE_LIST table is set to something other than 255.255.255.255.
   
   This function examines the IPAddress field and returns either the static address or the SCU dynamic address

   example use: 
   const ipaddress =  Manifest.getDeviceIPAddress(0x158d0000a9b27d);
   if(typeof ipaddress === "undefined" )
   {
      // device not found
   }
   */
  public getDeviceIPAddress(mac: number): string | undefined {
    const manifest: iManifest = this.getJSON();

    if (manifest.DEVICE_LIST && manifest.SYSTEM_PARAMETERS) {
      const device = manifest.DEVICE_LIST.find(
        ({ MacAddress }) => MacAddress === mac
      );

      const IPAddressRangeSysParam = manifest.SYSTEM_PARAMETERS.find(
        ({ Name }) => Name === 'IP_ADDRESS_RANGE'
      );

      if (device && IPAddressRangeSysParam) {
        if (device.IPAddress != '255.255.255.255') {
          return device.IPAddress;
        }
        // default ip address range is 172.16.0.0
        const deviceIPBuffer = ip.toBuffer(IPAddressRangeSysParam.Value, 0);

        // Setup the IP address,  IP range | b | a i.e. 172.16.a.b
        const a = 10 + Math.trunc(device.ID / 250);
        const b = (device.ID % 250) + 1;

        deviceIPBuffer[2] = a;
        deviceIPBuffer[3] = b;

        return ip.toString(deviceIPBuffer);
      }
    }

    console.info('Cant find:', mac);
    return undefined;
  }

  /** get a filtered list of triggers by a particular type*/
  public getTriggersByType(type: teDEVICE_TYPES): Trigger[] {
    return this.triggers.filter((t) => t.Type == type);
  }

  generateChangeSQL(): { update: string; revert: string } {
    let update = '';
    let revert = '';

    this.rooms.forEach((device) => {
      const sql = device.generateChangeSQL();

      update += sql.update;
      revert += sql.revert;
    });

    this.handsets.forEach((device) => {
      const sql = device.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.accessPoints.forEach((device) => {
      const sql = device.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.triggers.forEach((device) => {
      const sql = device.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.systemParameters.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.endpointCCDTMF.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.deviceInterfaces.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.cameraConfigs.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.groupHandsets.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.endPointCCSIP.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.careGroups.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.careSequences.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.alarmPriorities.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.schedules.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.cloudMetaData.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    this.gatewayInterfaces.forEach((e) => {
      const sql = e.generateChangeSQL();
      update += sql.update;
      revert += sql.revert;
    });

    return { update, revert };
  }

  async applyChanges(): Promise<void> {
    console.info('Apply Changes');

    const promises: Promise<unknown>[] = [];
    this.rooms.forEach((device) => {
      promises.push(device.applyManifestChanges());
    });

    this.handsets.forEach((device) => {
      promises.push(device.applyManifestChanges());
    });

    this.accessPoints.forEach((device) => {
      promises.push(device.applyManifestChanges());
    });

    this.triggers.forEach((device) => {
      promises.push(device.applyManifestChanges());
    });

    this.systemParameters.forEach((e) => {
      promises.push(e.applyManifestChanges());
    });

    this.endpointCCDTMF.forEach((e) => {
      promises.push(e.applyManifestChanges());
    });

    this.deviceInterfaces.forEach((e) => {
      promises.push(e.applyManifestChanges());
    });

    this.cameraConfigs.forEach((e) => {
      promises.push(e.applyManifestChanges());
    });

    this.groupHandsets.forEach((e) => {
      promises.push(e.applyManifestChanges());
    });

    this.endPointCCSIP.forEach((e) => {
      promises.push(e.applyManifestChanges());
    });

    this.careGroups.forEach((e) => {
      promises.push(e.applyManifestChanges());
    });

    this.cloudMetaData.forEach((e) => {
      promises.push(e.applyManifestChanges());
    });

    // wait for all items to be completed
    await Promise.allSettled(promises);

    // Repopulate the device list array as this could have been updated
    const data = this.getJSON();
    this.devices = data.DEVICE_LIST;

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.UPDATED,
      null,
      true
    );
  }

  /*
   * Tell each device to push any changes to their local copies of the manifest data
   */
  async save(repopulateCaches = false): Promise<manifestUtils.teErrorCode> {
    let status = manifestUtils.teErrorCode.E_TIMEOUT;
    const sql = this.generateChangeSQL();

    if (sql.update !== '') {
      // execute sql changes
      status = await manifestUtils.executeSQL(
        sql.update,
        sql.revert,
        manifestUtils.teDB_BACKUPMETHOD.E_DB_BACKUPMETHOD_DISK_AND_UPLOAD,
        repopulateCaches
      );
      if (status === manifestUtils.teErrorCode.E_OK) {
        // if execute succeeds merge changes into the store
        this.applyChanges();
      } else {
        console.error('Failed to run sql');
      }
    } else {
      console.info('no sql changed');
      status = manifestUtils.teErrorCode.E_OK;
    }

    return status;
  }

  /*
   * Tell each device to push any changes to their local copies of the manifest data
   */
  testGenerateSql(): string {
    return this.generateChangeSQL().update;
  }

  getSystemParameter(name: string): SystemParameter | undefined {
    const sp: SystemParameter | undefined = this.systemParameters.find(
      (element) => element.Name == name
    );

    return sp;
  }

  getSCUSoftwareVersion(): number {
    return (
      this.swVersion.major * 10000 +
      this.swVersion.minor * 100 +
      this.swVersion.build
    );
  }

  // Check whether the SCU is between two defined versions
  // e.g. 0.0.0 and 5.10.0   i.e. valid for old versions equal to or below 5.10.0
  // e.g. 5.10.0 and 999.999.999 i.e. valid for versions 5.10.0 and above
  isInSoftwareVersionRange(range: { min?: string; max?: string }): boolean {
    if (!this.manifestLoaded) return false;
    const ver = manifestUtils.versionToString(this.swVersion);
    const meetsMinVersion = semver.gte(ver, range.min ?? '0.0.0');
    const meetsMaxVersion = semver.lte(ver, range.max ?? '999.999.999');
    return meetsMinVersion && meetsMaxVersion;
  }

  async deleteTrigger(deviceID: number): Promise<manifestUtils.teErrorCode> {
    const trigger = this.triggers.find((e) => e.ID === deviceID);
    if (!trigger) return manifestUtils.teErrorCode.E_ERROR;

    try {
      const iotRes = await IoTDeviceAddDelete.deleteDevice(
        trigger.MacAddress,
        null
      );

      const resp = iotRes.msgHeader
        ?.msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_DELETE_RESP;
      if (
        resp.errorcode !== IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
      ) {
        return manifestUtils.teErrorCode.E_CONNECTION_FAIL;
      }
    } catch (error) {
      return manifestUtils.teErrorCode.E_CONNECTION_FAIL;
    }

    await SCUSQLInjection.Instance.forceBackup(false);

    await manifestUtils.despatchDeleteToManifestStore(
      trigger.deviceManifest.tablename,
      trigger,
      trigger.deviceManifest.manifestObjectKeys
    );

    _.remove(this.triggers, function (e) {
      return trigger.ID === e.ID;
    });

    // Repopulate the device list array as this will have been updated
    const data = this.getJSON();
    this.devices = data.DEVICE_LIST;

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.UPDATED,
      null,
      true
    );

    return manifestUtils.teErrorCode.E_OK;
  }

  getDoors(): DoorAndDeviceInterface[] {
    return this.deviceInterfaces.filter((e) => e.isDoor());
  }

  getLifts(): DoorAndDeviceInterface[] {
    return this.deviceInterfaces.filter((e) => e.isLift());
  }

  getStandalonePullcords(): DoorAndDeviceInterface[] {
    return this.deviceInterfaces.filter((e) => e.isPullcord());
  }

  getCallPoints(): DoorAndDeviceInterface[] {
    return this.deviceInterfaces.filter((e) => e.isCallPoint());
  }

  /**
   * Get the associated room number for a device id.
   * @param deviceIdStringOrNumber
   * @returns either N/A if a room number is nto applicable of the room number
   */
  getAssoaciatedRoomNumberForDeviceID(
    deviceIdStringOrNumber: number | string
  ): number | undefined {
    let roomNumber: number | undefined = undefined;
    const deviceId =
      typeof deviceIdStringOrNumber === 'string'
        ? parseInt(deviceIdStringOrNumber, 10)
        : deviceIdStringOrNumber;

    const room = this.rooms.find((e) => e.ID === deviceId);
    if (room) {
      roomNumber = room.RoomNumber;
    } else {
      const trigger = this.triggers.find((e) => e.ID === deviceId);
      if (trigger) {
        const triggerRoom = this.rooms.find(
          (room) => room.HomeDeviceId == trigger.ParentID
        )?.RoomNumber;
        if (triggerRoom) {
          roomNumber = triggerRoom;
        }
      }
    }
    return roomNumber;
  }

  getDeviceNameByMac(mac: number): string {
    const room = this.getRoomByDeviceMac(mac);
    if (room) return room.Name;
    const ap = this.getAccessPointByDeviceMac(mac);
    if (ap) return ap.Name;
    const intrf = this.getInterfaceByDeviceMac(mac);
    if (intrf) return intrf.Name;
    const handset = this.getHandsetByDeviceMac(mac);
    if (handset) return handset.Description;

    return '';
  }

  getUnitNumberByMac(mac: number): number {
    const room = this.getRoomByDeviceMac(mac);
    if (room) return room.RoomNumber;
    const ap = this.getAccessPointByDeviceMac(mac);
    if (ap) return ap.UnitId ? ap.UnitId : 9000;
    const intrf = this.getInterfaceByDeviceMac(mac);
    if (intrf) return intrf.UnitId;
    return 0;
  }

  getDeviceDescriptionByMac(mac: number): string {
    const room = this.getRoomByDeviceMac(mac) as Room;
    if (room) return room.getDeviceDescription();
    const ap = this.getAccessPointByDeviceMac(mac) as AccessPoint;
    if (ap) return ap.getDeviceDescription();
    const intrf = this.getInterfaceByDeviceMac(mac) as DoorAndDeviceInterface;
    if (intrf) return intrf.getDeviceDescription();
    const handset = this.getHandsetByDeviceMac(mac) as Handset;
    if (handset) return handset.getDeviceDescription();

    return '';
  }

  /**
   * Returns the mac address and device id of the unit that has been assigned the unitId
   * @param unitId  unit id to check
   * @returns undefined if not found, else object with mac address and device id
   */
  unitIdInUse(unitId: number): { mac: number; deviceId: number } | undefined {
    const rm = this.rooms.find((e) => e.RoomNumber == unitId);
    if (rm) {
      return { mac: rm.MacAddress, deviceId: rm.ID };
    }

    const intf = this.deviceInterfaces.find((e) => e.UnitId == unitId);
    if (intf) {
      return { mac: intf.MacAddress, deviceId: intf.ID };
    }

    const ap = this.accessPoints.find((e) => e.UnitId == unitId);
    if (ap) {
      return { mac: ap.MacAddress, deviceId: ap.ID };
    }
    return undefined;
  }

  getUnassignedDevices(): iDevice[] {
    const unassigned: iDevice[] = [];
    for (const index in this.devices) {
      const device = this.devices[index];
      if (device.Type === teDEVICE_TYPES.DEVICE_TYPE_ROOM_UNIT) {
        if (this.getRoomByDeviceMac(device.MacAddress) === undefined) {
          unassigned.push(device);
        }
      } else if (
        device.Type ===
        teDEVICE_TYPES.DEVICE_TYPE_APEX_DOOR_PANEL_AND_INTERFACES
      ) {
        if (this.getInterfaceByDeviceMac(device.MacAddress) === undefined) {
          unassigned.push(device);
        }
      } else if (device.Type === teDEVICE_TYPES.DEVICE_TYPE_JENNET_ROUTER) {
        const accessPoint = this.getAccessPointByDeviceMac(device.MacAddress);
        if (accessPoint) {
          // Accesspoints have their table entry created at comission time
          // The unitId will default to 9000 and name == '' | null
          // It is valid to leave the unit id as 9000 so only list of the name is empty/null
          if (accessPoint.Name === null || accessPoint.Name === '') {
            // GWI never need assigning
            if (accessPoint.ID != 1) {
              unassigned.push(device);
            }
          }
        }
      }
    }
    return unassigned;
  }

  getSchedulesByTypeAndId(
    objectType: teSCHEDULES_OBJECT_TYPES,
    objectID: number
  ): Schedule[] {
    return this.schedules.filter(
      (s) => s.ObjectType == objectType && s.ObjectID == objectID
    );
  }

  getSchedulesByType(objectType: teSCHEDULES_OBJECT_TYPES): Schedule[] {
    return this.schedules.filter((s) => s.ObjectType == objectType);
  }

  getScheduleById(id: number): Schedule | undefined {
    return this.schedules.find((s) => s.ID == id);
  }

  getNextTableId_Schedule(): number {
    const next = this.nextTableIds.SCHEDULES;
    this.nextTableIds.SCHEDULES++;
    return next;
  }

  getNextTableId_CLOUD_META_DATA(): number {
    const next = this.nextTableIds.CLOUD_META_DATA;
    this.nextTableIds.CLOUD_META_DATA++;
    return next;
  }

  getAlarmPriority(alarmType: number): number | undefined {
    return this.alarmPriorities.find((f) => f.ID == alarmType)?.Priority;
  }

  /**
   * getResidentGroups
   * @returns a list of groups
   */
  async getResidentGroups(): Promise<iResidentGroup[] | undefined> {
    const type = 'residentgroups';
    let md = this.cloudMetaData.find((f) => f.Type == type);
    let g: iResidentGroup[] | undefined = undefined;

    // if we don't already have a group entry in the manifest then add a default one
    if (!md) {
      console.log('gn - create entry', this.cloudMetaData);
      await CloudMetaData.createEntry(
        type,
        JSON.stringify(iResidentGroupDefaults)
      );
      md = this.cloudMetaData.find((f) => f.Type == type);
    }

    if (md) {
      g = JSON.parse(md.Data) as iResidentGroup[];
    }

    return g;
  }

  async setGroupName(groupId: number, groupName: string): Promise<void> {
    const rg = await this.getResidentGroups();
    const type = 'residentgroups';
    const md = this.cloudMetaData.find((f) => f.Type == type);
    if (rg) {
      const group = rg[groupId - 1];
      group.name = groupName;
      if (md) md.Data = JSON.stringify(rg);
      //console.log('RG: - ' + group.name, this.cloudMetaData);
    }
    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.RESIDENTGROUP,
      eventDispatcher.systemEventStates.UPDATED,
      { rg },
      true
    );
  }

  /**
   * getRoomsInAGroup
   * @param groupId
   * @returns an array of rooms belinging to that group
   */
  getRoomsInAGroup(groupId: number): Room[] {
    const rm: Room[] = [];
    if (manifestUtils.isValidGroupId(groupId)) {
      this.rooms.forEach((r) => {
        if (r.isInGroup(groupId)) rm.push(r);
      });
    }

    return rm;
  }

  /**
   * groupHasRooms
   * @param groupId
   * @returns true if there is at least one room in the group
   */
  groupHasRooms(groupId: number): boolean {
    let ret = false;
    if (manifestUtils.isValidGroupId(groupId)) {
      for (let i = 0; i < this.rooms.length; i++) {
        if (this.rooms[i].isInGroup(groupId)) {
          ret = true;
          break;
        }
      }
    }

    return ret;
  }
}
