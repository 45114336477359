import { CableSpec } from '../types/cable-types';

export enum CABLENAMES {
  SHIELDEDCAT5 = 0,
  BASICCAT5,
}

export const cablesSpecs: Array<CableSpec> = [
  {
    spec: 'CAT5e F/UTP or CAT6A F/UTP Shielded',
    examples: [],
    planColour: 'purple',
    planDashArray: [8, 8],
  },
  {
    spec: 'CAT5e U/UTP or CAT6 U/UTP',
    examples: [],
    planColour: 'green',
    planDashArray: [4, 4],
  },
];
