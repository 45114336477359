import { iReportCard } from './types';
import * as UserUtils from 'common/userUtils';
import { generateARCUnitDetailsReport } from 'components/reports/reportTypes/arcUnitDetails';

export const arcReports: iReportCard[] = [
  {
    title: 'Equipment Details',
    color: 'amethyst',
    description:
      'Generates a report of all equipment details and IDs for use by an Alarm Monitoring Centre (ARC)',
    accessLevel: UserUtils.UAG.MANAGER,
    run: async (): Promise<void> => {
      await generateARCUnitDetailsReport();
    },
  },
];
