/** @module Installation */

import { iEndpoint_CC_SIP, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';

/*
 * A SIP control centre
 * @class
 * @hideconstructor
 * @implements iEndpoint_CC_SIP
 */

export class EndpointCCSIP implements iEndpoint_CC_SIP {
  /** iEndpoint_CC_SIP start */
  ID = invalidID;
  Description = '';
  ProtcolType = 0;
  RegistryURI = '';
  Port = 5060;
  AuthScheme = 'digest';
  AuthUserName = '';
  AuthPassword = '';
  Realm = '*';
  ConnectionFlags = 0;
  SIPIdURI = '';
  UnitId = '00000000';
  Address1URI = null;
  Address2URI = null;
  Address3URI = null;
  AllowConcurrentAlarms = 0; // not currently supported, each alarm can be raised with its own call session
  ConfigurationType = 1; // BS8521 Configuration System Type
  DelayForAlarmMessageSend = 0; // optional delay prior to sending alarm message (not necessary, just contigency )
  HandshakeMode = 0; // 0 = as per BS8521, 1 = as per Tunstall workaround (send alarm message after call established)
  HeartbeatLocalUnitIdent = ''; // UMO ARCs require an additional local unit ID sending with the heartbeat message.
  ImmediateAlarmMessage = 0; // send all alarm messages as the alarm message occurs (i.e. not just when requested by the ARC)
  InDialogMessages = 0; // Send Audio Alarm Message with InDialog Call ID field.
  NonAudioAsAudio = 0; // Added R5.20b0
  /** iEndpoint_CC_SIP end */

  // Got software version incase we need to do processsing differently for
  // different software revsions in the future
  softwareVersion = 0;

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(
    values: iEndpoint_CC_SIP,
    keys: string[],
    tableName: string,
    softwareVersion: number
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
  }

  setDescription(desc: string): void {
    this.Description = desc;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });

    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }
}
