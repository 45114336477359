import React, { useState } from 'react';
import 'types/event-enums';
import Tile, { iTileEvent } from '../../tile';
import { Manifest } from 'components/installation/manifest';
import { Box } from 'grommet';
import { ResponsiveLine } from '@nivo/line';
import { nivoChartTheme } from 'components/themes/nivoChartTheme';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';

const manifest = new Manifest();

/*
const HandsetImage = styled.img`
  padding: 4px;
`;
*/

let chartData = [
  {
    id: '',
    color: 'hsl(335, 70%, 50%)',
    data: [{ x: '2022-06-07T11:53:31.940Z', y: 0 }],
  },
];

// const chartData = [
//   {
//     'id': 'Handset 3',
//     'data': [
//       {
//         'x': '2022-06-07T11:53:31.940Z',
//         'y': 83,
//       },
//       {
//         'x': '2022-06-07T11:57:11.939Z',
//         'y': 83,
//       },
//       {
//         'x': '2022-06-07T11:59:27.332Z',
//         'y': 85,
//       },
//       {
//         'x': '2022-06-07T11:59:56.221Z',
//         'y': 85,
//       },
//       {
//         'x': '2022-06-07T12:02:30.378Z',
//         'y': 85,
//       },
//       {
//         'x': '2022-06-07T12:05:25.744Z',
//         'y': 86,
//       },
//       {
//         'x': '2022-06-07T12:05:46.637Z',
//         'y': 86,
//       },
//       {
//         'x': '2022-06-07T12:10:50.423Z',
//         'y': 87,
//       },
//       {
//         'x': '2022-06-07T12:11:01.385Z',
//         'y': 87,
//       },
//       {
//         'x': '2022-06-07T12:12:31.456Z',
//         'y': 87,
//       },
//     ],
//   },
// ];

const HandsetChargeGraph = (): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const loadDataEvent = () => {
    chartData = [];

    manifest.handsets.forEach((h) => {
      h.getHandsetChargeInfoHistory();
      const cdata = h.getChargeData();
      let lastTimestamp = 0;

      const filteredData: { x: string; y: number }[] = [];
      cdata.forEach((b) => {
        const ts = new Date(b.x);
        if (ts.getTime() - lastTimestamp > 1000 * 60 * 30) {
          filteredData.push(b);
          lastTimestamp = ts.getTime();
        }
      });

      chartData.push({
        id: h.Description,
        color: 'hsl(89, 70%, 50%)',
        data: filteredData,
      });
    });
  };

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.LIVEEVENTS,
      state: systemEventStates.UPDATED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
  ];

  return (
    <Tile
      title="Charging History"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      showLastEvent={true}
    >
      <>
        <Box pad="small" style={{ height: '95%', width: '100%' }}>
          {/* <div style={{ height: 300, width: 550 }}> */}
          <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 110, bottom: 50, left: 50 }}
            xScale={{ format: '%Y-%m-%dT%H:%M:%S.%LZ', type: 'time' }}
            xFormat="time:%Y-%m-%T%H:%M:%S.%LZ"
            yScale={{
              type: 'linear',
              stacked: false,
              min: 0.0,
              max: 100.0,
            }}
            curve="cardinal"
            axisTop={null}
            axisRight={null}
            // axisRight={{
            //   tickValues: [0.0, 0.2, 0.4, 0.6, 0.8, 1.0],
            //   tickSize: 5,
            //   tickPadding: 5,
            //   tickRotation: 0,
            //   format: '0.2',
            //   legend: '',
            //   legendOffset: 0,
            // }}
            axisBottom={{
              tickValues: 'every 2 hour',
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 90,
              format: '%H:%M',
              legend: 'Time',
              legendOffset: 44,
              legendPosition: 'middle',
            }}
            axisLeft={{
              // tickValues: [0.0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              // tickSize: 5,
              // tickPadding: 5,
              // tickRotation: 0,
              // format: '.2',
              legend: '% Charge',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            enableGridX={false}
            theme={nivoChartTheme}
            colors={{ scheme: 'accent' }}
            lineWidth={1}
            pointSize={2}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={1}
            pointBorderColor={{ from: 'serieColor' }}
            enablePointLabel={false}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            // gridXValues={[0, 20, 40, 60, 80, 100, 120]}
            // gridYValues={[80, 82, 84, 86, 88, 90, 92, 94, 96, 98, 100]}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 12,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
          {/* </div> */}
        </Box>
      </>
    </Tile>
  );
};

export default HandsetChargeGraph;
