/** @module Installation */

import { iSystemParameter, invalidID } from 'types/manifest-types';
import {
  SYSTEM_PARAMETER_DATA_TYPES,
  SYSTEM_PARAMETER_ACCESS_TYPES,
} from 'types/manifest-enums';
import * as manifestUtils from 'components/installation/manifestUtils';

/*
 * Handset device class
 * @class
 * @hideconstructor
 * @implements iSystemParameter
 */

export class SystemParameter implements iSystemParameter {
  /** iSystemParameter start */
  ID = invalidID;
  Name = '';
  Value = '';
  DefaultValue = '';
  Description = '';
  DataType = SYSTEM_PARAMETER_DATA_TYPES.DATA_TYPE_TEXT;
  AccessLevel = SYSTEM_PARAMETER_ACCESS_TYPES.ACCESS_USER;
  ShortName = '';
  /** iSystemParameter end */

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(values: iSystemParameter, keys: string[], tableName: string) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });
    return sql;
  }

  setFromSring(value: string): void {
    if (this.DataType == SYSTEM_PARAMETER_DATA_TYPES.DATA_TYPE_TEXT) {
      this.Value = value;
    } else {
      this.Value = parseInt(value).toString();
    }
  }

  setFromNumber(value: number): void {
    this.Value = value.toString();
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }
}
