import { ALARM_TYPES } from 'types/event-enums';
export { ALARM_TYPES } from 'types/event-enums';

const techAlarms = new Map<number, boolean>([
  [ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1, false],
  [ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_2, false],
  [ALARM_TYPES.ALARM_TYPE_PRESSURE_MAT, false],
  [ALARM_TYPES.ALARM_TYPE_DOOR_CONTACT, false],
  [ALARM_TYPES.ALARM_TYPE_PASSIVE_INFRA_RED_PIR_DETECTOR, false],
  [ALARM_TYPES.ALARM_TYPE_BOUNDARY_BREACH, false],
  [ALARM_TYPES.ALARM_TYPE_SMOKE, false],
  [ALARM_TYPES.ALARM_TYPE_FIRE, false],
  [ALARM_TYPES.ALARM_TYPE_BOGUS_CALLER_TRIGGER, false],
  [ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_1, false],
  [ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_2, false],
  [ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_1, false],
  [ALARM_TYPES.ALARM_TYPE_FALL_TRIGGER_2, false],
  [ALARM_TYPES.ALARM_TYPE_PERSONAL_ATTACK_1, false],
  [ALARM_TYPES.ALARM_TYPE_PERSONAL_ATTACK_2, false],
  [ALARM_TYPES.ALARM_TYPE_PANIC_BUTTON, false],
  [ALARM_TYPES.ALARM_TYPE_CARBON_MONOXIDE_GAS, false],
  [ALARM_TYPES.ALARM_TYPE_NATURAL_GAS, false],
  [ALARM_TYPES.ALARM_TYPE_INTRUDER, false],
  [ALARM_TYPES.ALARM_TYPE_AUTOMATIC_PERSON_DOWN, false],
  [ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_1, false],
  [ALARM_TYPES.ALARM_TYPE_PROPERTY_EXIT_2, false],
  [ALARM_TYPES.ALARM_TYPE_HIGH_TEMPERATURE, false],
  [ALARM_TYPES.ALARM_TYPE_LOW_TEMPERATURE, false],
  [ALARM_TYPES.ALARM_TYPE_TEMPERATURE_RATE_OF_RISE, false],
  [ALARM_TYPES.ALARM_TYPE_MEDICATION_DISPENSER, false],
  [ALARM_TYPES.ALARM_TYPE_ENURESIS, false],
  [ALARM_TYPES.ALARM_TYPE_BED_OCCUPANCY, false],
  [ALARM_TYPES.ALARM_TYPE_CHAIR_OCCUPANCY, false],
  [ALARM_TYPES.ALARM_TYPE_PROPERTY_OCCUPANCY_1, false],
  [ALARM_TYPES.ALARM_TYPE_PROPERTY_OCCUPANCY_2, false],
  [ALARM_TYPES.ALARM_TYPE_BATHROOM_OCCUPANCY, false],
  [ALARM_TYPES.ALARM_TYPE_SEIZURE, false],
  [ALARM_TYPES.ALARM_TYPE_INACTIVITY, false],
  [ALARM_TYPES.ALARM_TYPE_ENVIRONMENTAL_MONITOR, false],
  [ALARM_TYPES.ALARM_TYPE_BED_MONITOR, false],
  [ALARM_TYPES.ALARM_TYPE_CHAIR_MONITOR, false],
  [ALARM_TYPES.ALARM_TYPE_FLOOD, false],
  [ALARM_TYPES.ALARM_TYPE_BATH_LEVEL, false],
  [ALARM_TYPES.ALARM_TYPE_LIGHTING_CIRCUIT, false],
  [ALARM_TYPES.ALARM_TYPE_HEATING, false],
  [ALARM_TYPES.ALARM_TYPE_MAINS_POWER, true],
  [ALARM_TYPES.ALARM_TYPE_SYSTEM_POWER_SUPPLY, false],
  [ALARM_TYPES.ALARM_TYPE_BATTERY, true],
  [ALARM_TYPES.ALARM_TYPE_DUTY_SWITCH, false],
  [ALARM_TYPES.ALARM_TYPE_DOOR_OPEN, false],
  [ALARM_TYPES.ALARM_TYPE_FIRE_DOOR_OPEN, false],
  [ALARM_TYPES.ALARM_TYPE_SYSTEM_STATUS, true],
  [ALARM_TYPES.ALARM_TYPE_AUTOMATIC_PERIODIC_TEST_CALL, true],
  [ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_1, true],
  [ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_2, true],
  [ALARM_TYPES.ALARM_TYPE_RADIO_RECEIVER, true],
  [ALARM_TYPES.ALARM_TYPE_IP_COMMUNICATION_LINK, true],
  [ALARM_TYPES.ALARM_TYPE_SERIAL_DATA_LINK, true],
  [ALARM_TYPES.ALARM_TYPE_SYSTEM_UNDER_TEST, true],
  [ALARM_TYPES.ALARM_TYPE_SERVICE_PROVIDER_IDS, true],
  [ALARM_TYPES.ALARM_TYPE_SERVICE_CALL, true],
  [ALARM_TYPES.ALARM_TYPE_ACKNOWLEDGEMENT_MESSAGE, true],
  [ALARM_TYPES.ALARM_TYPE_PRESENCE_MARKING, true],
  [ALARM_TYPES.ALARM_TYPE_SERVICE_COMPLETED, true],
  [ALARM_TYPES.ALARM_TYPE_RESIDENT_UNIT_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_ROUTER_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_TRIGGER_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_SOCIAL_CALL, false],
  [ALARM_TYPES.ALARM_TYPE_TAG_STATUS_REPORT, true],
  [ALARM_TYPES.ALARM_TYPE_TAG_ACK, true],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1, false],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2, false],
  [ALARM_TYPES.ALARM_TYPE_DOOR_CALL, false],
  [ALARM_TYPES.ALARM_TYPE_DOOR_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_AUDIO_ADAPTOR_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_RESIDENT_OK, false],
  [ALARM_TYPES.ALARM_TYPE_INCOMING_POTS_CALL, false],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3, false],
  [ALARM_TYPES.ALARM_TYPE_TAG_TALK_ACTIVE, true],
  [ALARM_TYPES.ALARM_TYPE_TAG_TALK_INACTIVE, true],
  [ALARM_TYPES.ALARM_TYPE_TAG_OPEN, true],
  [ALARM_TYPES.ALARM_TYPE_TAG_END, true],
  [ALARM_TYPES.ALARM_TYPE_RESIDENT_HOME, false],
  [ALARM_TYPES.ALARM_TYPE_RESIDENT_AWAY, false],
  [ALARM_TYPES.ALARM_TYPE_WANDERING, false],
  [ALARM_TYPES.ALARM_TYPE_PAGE_ACKNOWLEDGE, true],
  [ALARM_TYPES.ALARM_TYPE_CANCEL_REQUEST, true],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_OPEN_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_1_CLOSE_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_OPEN_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_2_CLOSE_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_OPEN_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_WIRED_INPUT_3_CLOSE_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_DEVICE_RESET_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_OPEN_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_1_CLOSE_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_OPEN_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_2_CLOSE_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_OPEN_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_SCU_WIRED_INPUT_3_CLOSE_EVENT, true],
  [ALARM_TYPES.ALARM_TYPE_TUNSTALL_TRIGGER_EVENT, false],
  [ALARM_TYPES.ALARM_TYPE_DETECTOR_FAULT, true],
  [ALARM_TYPES.ALARM_TYPE_DETECTOR_EOL, true],
  [ALARM_TYPES.ALARM_TYPE_ASSISTANCE_NEEDED, false],
  [ALARM_TYPES.ALARM_TYPE_BUTTON_FAULT, true],
  [ALARM_TYPES.ALARM_TYPE_DOOR_CLOSED, false],
  [ALARM_TYPES.ALARM_TYPE_MEDICATION_DOSE_MISSED, false],
  [ALARM_TYPES.ALARM_TYPE_NURSE_CALL_FAULT, true],
  [ALARM_TYPES.ALARM_TYPE_NURSE_CALL, false],
  [ALARM_TYPES.ALARM_TYPE_TAMPER, true],
  [ALARM_TYPES.ALARM_TYPE_MAINS_POWER_RESTORED, true],
  [ALARM_TYPES.ALARM_TYPE_TELEPHONE_LINE_RESTORED, true],
  [ALARM_TYPES.ALARM_TYPE_PERSONAL_TRIGGER_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_TEMP_EXT_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_FLOOD_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_CO_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_SMOKE_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_DOOR_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_PULLCORD_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_ENURESIS_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_BEDCHAIR_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_FALL_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_PIR_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_CARER_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_NATURAL_GAS_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_PES_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_ARMDISARM_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_BOGUS_CALL_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_MEDICATION_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_EPILEPSY_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_WNC_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_WNC_CANCEL_REQ, false],
  [ALARM_TYPES.ALARM_TYPE_CO_DEVICE_FAULT, true],
  [ALARM_TYPES.ALARM_TYPE_MEDICATION_FAULT, true],
  [ALARM_TYPES.ALARM_TYPE_PULLCORD_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_SMOKE_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_WIM_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_WIM_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_FALL_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_PANIC_BATTERY_LOW, true],
  [ALARM_TYPES.ALARM_TYPE_PANIC_POLL_FAILURE, true],
  [ALARM_TYPES.ALARM_TYPE_FALL_MANUAL_PRESS, false],
]);

export const DEFAULT_TECH_ALARM_CARE_SEQUANCEID = 0;
export const DEFAULT_ALARM_CARE_SEQUANCEID = 1;

export const alarmIsTechnical = (type: ALARM_TYPES): boolean => {
  const tech = techAlarms.get(type);
  if (techAlarms.has(type) && tech !== undefined) {
    return tech;
  }
  return false;
};
