import React, { useState, useEffect } from 'react';
import useStateRef from 'react-usestateref';
import Tile, { iTileEvent } from '../../tile';
import { Button } from 'grommet';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import * as IoTTest from 'common/IoT/IoTTest';
import { ALARM_TYPES } from 'types/event-enums';
import * as IoTRemoteDial from 'common/IoT/IoTTRemoteDialing';
import { getCurrentUsersName } from 'common/userUtils';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import * as IoTAlarms from 'common/IoT/IoTAlarms';
import { Schedule } from 'components/installation/schedule';
import { teSCHEDULES_OBJECT_TYPES } from 'types/manifest-enums';
import TablePaged from 'components/tables/TablePaged';
import { Column } from 'react-table';
import * as eventDispatcher from 'store/eventDispatcher';
import { iSCHEDULE } from 'types/manifest-types';
import { Manifest } from 'components/installation/manifest';
import * as Utils from 'common/utils/dateUtils';

export const nanoid = customAlphabet(alphanumeric, 24);

declare type iTableData = iSCHEDULE;
let _allData: iTableData[] = [];
const manifest = new Manifest();

const AlarmTest = (): JSX.Element => {
  const [, setRenderCount] = useState(0);
  const [, SetChildEvent] = useState('');
  const [selected, setSelected] = useState<iTableData | undefined>();
  const [dialogueProps, setDialogueProps] = useStateRef<
    Wait.DialogueProps | undefined
  >();

  const [callUID, SetCallUID] = useState('');

  const loadData = () => {
    _allData = _.cloneDeep(manifest.schedules);
    setRenderCount((old) => old + 1);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
  ];

  async function raiseAlarm(deviceId: number, alarmType: ALARM_TYPES) {
    setDialogueProps({
      show: true,
      dialogueText: `Please wait....`,
      showSpinner: true,
      showOkButton: false,
    });

    const errorCode: IoTTest.teCLOUD_ERROR_CODES = await IoTTest.raiseAlarm(
      deviceId,
      alarmType
    );

    console.info(`raiseAlarm response errorcode:${errorCode}`);

    setDialogueProps(undefined);
  }

  async function remoteDial(telephoneNumber: string, username: string) {
    if (callUID == '') {
      const callUID = username + '_' + nanoid();

      setDialogueProps({
        show: true,
        dialogueText: `Please wait....`,
        showSpinner: true,
        showOkButton: false,
      });

      const errorCode: IoTRemoteDial.teCLOUD_ERROR_CODES =
        await IoTRemoteDial.requestCallBack(
          callUID,
          username,
          telephoneNumber,
          IoTRemoteDial.TELEPHONE_LINES.TELEPHONE_LINE_SIP_1
        );

      console.info(`remoteDial response errorcode:${errorCode}`);
      if (errorCode == IoTRemoteDial.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE) {
        SetCallUID(callUID);

        // ENSURE YOU NOW SET A TIMER THAT SENDS A HEARTBEAT EVERY 30SECONDS: IoTRemoteDial.sendHeartbeat(callUID)
        // If a heartbeat isn't received the SCU will habgup the telephone line
      }

      setDialogueProps(undefined);
    } else {
      setDialogueProps({
        show: true,
        dialogueText: `Please clear exisiting call`,
        showSpinner: true,
        showOkButton: true,
        okButtonPress: () => {
          setDialogueProps(undefined);
        },
      });
    }
  }

  async function connectResident(
    unitId: number, // room number
    vrutext: string,
    username: string
  ) {
    setDialogueProps({
      show: true,
      dialogueText: `Please wait....`,
      showSpinner: true,
      showOkButton: false,
    });

    const errorCode: IoTRemoteDial.teCLOUD_ERROR_CODES =
      await IoTRemoteDial.connectResident(unitId, vrutext, username, callUID);

    console.info(`connectResident response errorcode:${errorCode}`);

    setDialogueProps(undefined);
  }

  async function disconnectResident(username: string) {
    setDialogueProps({
      show: true,
      dialogueText: `Please wait....`,
      showSpinner: true,
      showOkButton: false,
    });

    const errorCode: IoTRemoteDial.teCLOUD_ERROR_CODES =
      await IoTRemoteDial.disconnectResident(username, callUID);

    console.info(`disconnectResident response errorcode:${errorCode}`);

    setDialogueProps(undefined);
  }

  async function disconnectRemoteCall(username: string) {
    if (callUID != '') {
      console.info(
        `***disconnectRemoteCall username:${username}  callid:${callUID}`
      );

      await IoTRemoteDial.disconnectRemoteCall(username, callUID);
      SetCallUID('');
    }
  }

  const dialEvent = (
    msg: IoTRemoteDial.tsE_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT
  ) => {
    console.info('Dial event: ', JSON.stringify(msg));
    console.info(
      `Dial event ${
        IoTRemoteDial.teCLOUD_REMOTECALL_EVENTS_STATUS_CODES[msg.eventcode]
      }`
    );
  };

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: [],
      pageSize: 25,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        id: 'ID',
        Header: 'ID',
        accessor: 'ID',
        width: '10px',
      },
      {
        id: 'ObjectID',
        Header: 'ObjectID',
        accessor: 'ObjectID',
        width: '10px',
      },
      {
        id: 'ObjectType',
        Header: 'ObjectType',
        accessor: (row) => {
          return (
            teSCHEDULES_OBJECT_TYPES[row.ObjectType] + ` (${row.ObjectType})`
          );
        },
        width: '10px',
      },
      {
        id: 'DaysOfWeekBitmap',
        Header: 'DaysOfWeekBitmap',
        accessor: (row) => {
          return row.DaysOfWeekBitmap.toString(2);
        },
        width: '10px',
      },
      {
        id: 'StartTime',
        Header: 'StartTime',
        accessor: 'StartTime',
        width: '10px',
      },
      {
        id: 'StopTime',
        Header: 'StopTime',
        accessor: 'StopTime',
        width: '10px',
      },
      {
        id: 'AllDay',
        Header: 'AllDay',
        accessor: 'AllDay',
        width: '10px',
      },
    ],
    [_allData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const regIndex = IoTRemoteDial.registerForDialStatusEvents(dialEvent);
    return () => {
      IoTRemoteDial.unregisterDialStatusEventCallabck(regIndex);
    };
  }, []);

  const select = async (
    rowData: iTableData,
    selectionInfo: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (!selectionInfo.alreadySelected) {
      const record = _allData.find((e) => e.ID === rowData.ID);
      setSelected(record);
    } else {
      setSelected(undefined);
    }
  };

  return (
    <Tile
      eventListeners={events}
      title="Alarm Test"
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
    >
      <>
        <Button
          plain={false}
          size="small"
          label="Raise Alarm"
          onClick={() => {
            raiseAlarm(16, ALARM_TYPES.ALARM_TYPE_FIXED_TRIGGER_1);
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Get alarm Q"
          onClick={async () => {
            const scuRes = await IoTAlarms.getAlarmQueue();
            console.info('getAlarmQueue:', scuRes);
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Send Heartbeat"
          onClick={async () => {
            await IoTRemoteDial.sendHeartbeat(callUID);
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Call me"
          onClick={() => {
            remoteDial('2025', getCurrentUsersName());
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Drop telephone line"
          onClick={() => {
            disconnectRemoteCall(getCurrentUsersName());
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Connect resident 4"
          onClick={() => {
            connectResident(
              4, // room number
              'Calling from the cloud',
              getCurrentUsersName()
            );
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Connect resident 300"
          onClick={() => {
            connectResident(
              300, // room number
              'Calling from the cloud',
              getCurrentUsersName()
            );
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Connect resident 10"
          onClick={() => {
            connectResident(
              10, // room number
              'Calling from the cloud',
              getCurrentUsersName()
            );
          }}
        />

        <Button
          plain={false}
          size="small"
          label="Disconnect resident"
          onClick={() => {
            disconnectResident(getCurrentUsersName());
          }}
        />

        {/* <Button
          plain={false}
          size="small"
          label="Create Schedule"
          onClick={() => {
            const start: Date = Utils.dateTodaySetTimeHHMM('14:55');
            const end: Date = Utils.dateTodaySetTimeHHMM('23:59');

            Schedule.createSchedule(
              teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_ROUTING_EVENT,
              13,
              0b1111111,
              false,
              { startTime: start, stopTime: end }
            );
          }}
        /> */}

        <Button
          plain={false}
          size="small"
          label="Delete selected Schedule"
          onClick={() => {
            if (selected !== undefined) {
              Schedule.deleteSchedule(selected.ID);
            }
          }}
        />

        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={_allData ? _allData : []}
          showCheckboxes={false}
          showControls={true}
          selectorKeyName="ID"
          onLastSelectedRow={select}
          currentSelectedID={selected?.ID}
        />
      </>
    </Tile>
  );
};

export default AlarmTest;
