import {
  tePROCESSOR,
  teCLOUD_MSG_TY_DEVICES,
  tsCLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_REQ,
} from './IoTMessageDefines_DEVICES';

import {
  teCLOUD_ERROR_CODES,
  teCLOUD_MESSAGE_TYPES,
} from './IoTMessageDefines';

import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
//import logger from 'common/logger';
import { Device } from 'components/installation/device';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

// Re-export the tePROCESSOR from IoTMessageDefines
export { tePROCESSOR as tePROCESSOR };
export { teCLOUD_ERROR_CODES as teCLOUD_ERROR_CODES };
export type { tsCLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_RESP } from './IoTMessageDefines_DEVICES';

export async function setHandsetLogonStatus(
  mac: number,
  loggedOn: boolean,
  userData: unknown
): Promise<iIotResponseEventDetail> {
  console.info('setHandsetLogonStatus:', mac);

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_RESP;

  const msg: tsCLOUD_MSG_DEVICES_SET_HANDSET_LOGON_STATUS_REQ = {
    mac: Device.MACaddrToString(mac, 10),
    logonstatus: loggedOn,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'setHandsetLogonStatus',
    userData
  );
}
