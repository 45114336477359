import { iExcelColumn, iExcelSchema } from 'common/excel/createExcel';
import { Manifest } from 'components/installation/manifest';
import { createExcel } from 'common/excel/createExcel';
import { formatTimeOnlyHHMM, getDateFromEpoch } from 'common/utils/dateUtils';
import { headerRows } from 'common/excel/headerRows';

const manifest = new Manifest();

const reportColumns: iExcelColumn[] = [
  {
    columnName: 'Room Number',
    columnWidth: 30,
    fieldName: 'roomNumber',
    type: 'TEXT',
  },
  { columnName: 'Room Name', columnWidth: 50, fieldName: 'name', type: 'TEXT' },
  {
    columnName: 'Last in Test Mode',
    columnWidth: 30,
    fieldName: 'lastTestDate',
    type: 'DATE',
  },
  {
    columnName: 'Time of Last Test Mode',
    columnWidth: 30,
    fieldName: 'lastTestTime',
    type: 'TEXT',
  },
];

export const exampleReportDetails: iExcelSchema = {
  reportName: 'serviceTestReport', // name of the report in the tables export button that the user clicks on
  reportDescription: 'A report of the service test for all rooms', // explanantion of the report
  filteredData: false, // whether to use a tables filtered data or all data true=filtered
  fileName: 'service test report', // excel file name to create
  workSheetName: 'Service Test', // worksheet name
  title: 'Service Test Report', // Title on worksheet
  columns: reportColumns, // column information
  headerRows: headerRows,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let data: any[] = [];

const assembleData = (): void => {
  data = [];
  manifest.rooms.forEach((element) => {
    data.push({
      roomNumber: element.RoomNumber,
      name: element.Name,
      lastTestDate: getDateFromEpoch(element.TestModeEnabledEPOCH),
      lastTestTime: formatTimeOnlyHHMM(
        getDateFromEpoch(element.TestModeEnabledEPOCH)
      ),
    });
  });
};

// rename this to the specific report name
export const generateTestReport = async (): Promise<void> => {
  assembleData();
  await createExcel(exampleReportDetails, data);
};
