import React, { useState, useEffect, useRef } from 'react';
// import useStateRef from 'react-usestateref';
import { Box, TextInput, Text, Grid, Select, Tip } from 'grommet';
import * as CanvasSite from 'components/floorplan/canvasSite';
import * as Site from 'components/floorplan/site';
import { Product } from 'components/floorplan/product';
import styled from 'styled-components';
import { useWinstonLogger } from 'winston-react';
import { Manifest } from 'components/installation/manifest';
import { Device } from 'components/installation/device';
import { MACLIST } from 'components/floorplan/site';
import { INSTALLSTATUS } from '../../types/product-types';
import { PlanModeEnum } from '../../types/plan-types';

export type HUDProps = {
  /**Usual children node*/
  children: React.ReactElement;
  /**Whether the head up display should be visible*/
  visible: boolean;
  /** Current Plan index */
  currentPlan: number;
  /**the index of the current selected equipment*/
  equip: Product | undefined;
  /**plan mode*/
  planmode: PlanModeEnum;
  /** new event callback - to be used to get the child to re-render */
  //setChildEvent: (event: string) => void;
};

//  display: ${(props) => (props.visible ? 'block' : 'none')};

const HUDDIV = styled.div.attrs((props: { visible: boolean }) => props)`
  width: 100%;
  height: 140px;
  display: 'block';
  z-index: 2;
  opacity: 0.9;
  background-color: #222222;
  position: relative;
  top: 0px;
  left: 0px;
  padding: 0px;
`;

const WRAPPER = styled.div`
  width: 100%;
  height: 140px;
  position: relative;
`;

const HUDInput = styled(TextInput)`
  padding: 0px;
  color: #eeeeee;
`;

const HUDSelect = styled(Select)`
  padding: 0px;
  color: #eeeeee;
`;

let ports: Product[];
let MACaddrs: MACLIST[];
/**  stores either MAIs or MAP depending on what the product needs to connect to*/
let MAIMAPs: Product[];

const manifest = new Manifest();

export const HeadUpDisplay = (props: HUDProps): JSX.Element => {
  const logger = useWinstonLogger();
  const [, setLastEvent] = useState(new Date());
  const [CAT5options, setCAT5Options] = React.useState(ports);
  const [MAIMAPoptions, setMAIMAPOptions] = React.useState(MAIMAPs);
  const [MACoptions, setMACOptions] = React.useState(MACaddrs);
  const [currentEquip, setCurrentEquip] = useState(props.equip);

  // debounce timer for input boxes
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  /** Updates the changed information in the Equipment
   *  changes: snippet of chanegs to make
   *  debouce: time in ms to debouce (useful for text inputs where the user is still typing)
   *  validatefn : a validation function to call
   *  validateparam: a parameter to pass to the validation function
   *  revertChanges: what to revert to if the validation fails
   */
  const updateUIChange = (
    changes: Partial<Product>,
    debounce: number,
    validatefn?: (v?: string, uniqueID?: string | undefined) => boolean,
    validateparam?: string,
    revertChanges?: Partial<Product>
  ) => {
    // if the debounce timer is running then stop and clear it
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Make the Changes to product
    props.equip?.modifyProduct(changes);

    timeoutRef.current = setTimeout(() => {
      let proceed = true;
      if (validatefn != undefined)
        proceed = validatefn(validateparam, props.equip?.uniqueID);
      if (proceed == false && revertChanges) {
        // revert the previous chanegs
        props.equip?.modifyProduct(revertChanges);
      }
      logger.info('HeadUpDisplay.changedunitdetails', { value: changes });
      Site.updateStore(true); // ensure we update the store and cause the save button to highlight

      // cause the UI to update
      setLastEvent(new Date());
    }, debounce);

    // cause the UI to update
    setLastEvent(new Date());

    // Get the slected unit to refresh ont he canvas
    if (props.equip) CanvasSite.refreshProductUI(props.equip);
  };

  const unitNumberValidation = (
    v?: string,
    uniqueID?: string | undefined
  ): boolean => {
    let success = true;
    /** Check it isn't already in use by the manifest */
    if (manifest.unitIdInUse(Number(v)) !== undefined) success = false;
    /** Check it isn't already in use in the plandata (i.e. configured in plan but not yet copied to mainfest)  */
    if (Site.isOnDesignPlanUnitNumber(Number(v), uniqueID) !== undefined)
      success = false;
    return success;
  };

  const details = () => {
    if (CanvasSite.getCurrentEquipmentIndex() >= 0 && props.equip) {
      return (
        <Grid
          columns={[
            '250px',
            'small',
            'small',
            'small',
            'small',
            'small',
            'small',
          ]}
          rows={['auto', 'auto']}
          gap="none"
          margin="xxsmall"
          areas={[
            { name: 'designator', start: [0, 0], end: [0, 0] },
            { name: 'prodcode', start: [0, 1], end: [0, 1] },
            { name: 'unitnumber', start: [1, 0], end: [1, 0] },
            { name: 'locationname', start: [1, 1], end: [1, 1] },
            { name: 'mac', start: [2, 0], end: [2, 0] },
            { name: 'cat5', start: [3, 0], end: [3, 0] },
            { name: 'designnotes', start: [4, 0], end: [6, 0] },
            { name: 'servicenotes', start: [4, 1], end: [6, 1] },
            // { name: 'commonArea', start: [5, 0], end: [6, 0] },
            { name: 'locatability', start: [2, 1], end: [2, 1] },

            { name: 'empty2', start: [3, 1], end: [3, 1] },
          ]}
        >
          <Box pad="xxsmall" gridArea="designator">
            <Text size="medium" textAlign="start" color="gold">
              Equipment ID : {props.equip.uniqueID}
            </Text>
            <Text size="medium" textAlign="start" color="gold">
              {props.equip.physicalID > 0
                ? `Device ID : ${
                    manifest.getDeviceByMac(props.equip.physicalID).ID
                  }`
                : ' '}
            </Text>
            <Text size="medium" textAlign="start" color="gold">
              {props.equip.name}
            </Text>
            {/* <Text size="medium" textAlign="start">
              ({props.equip.installStatus})
            </Text> */}
          </Box>
          <Box pad="xxsmall" gridArea="prodcode">
            <Text size="medium" textAlign="start" color="gold">
              Product Code : {props.equip.productCode}
            </Text>
          </Box>

          {/* <Box pad="xxsmall" gridArea="commonArea">
            <Text size="medium" textAlign="start">
              Communal : {props.equip.commonArea.toString()}
            </Text>
          </Box> */}

          <Box pad="xxsmall" gridArea="unitnumber">
            <Tip
              dropProps={{ align: { bottom: 'top' } }}
              content={
                <Text>
                  This number is used to identify the alarm source with Alarm
                  Receiving Centres
                </Text>
              }
            >
              <Text
                size="medium"
                textAlign="start"
                margin={{
                  left: '0px',
                  top: '0px',
                  bottom: '2px',
                  right: '6px',
                }}
                color="gold"
              >
                Room/Unit Number
              </Text>
            </Tip>
            <HUDInput
              size="medium"
              textAlign="start"
              placeholder="number..."
              type="number"
              value={props.equip.unitNumber}
              onChange={(event) => {
                updateUIChange(
                  {
                    unitNumber: Number(event.target.value),
                  },
                  500,
                  unitNumberValidation,
                  event.target.value,
                  {
                    unitNumber: props.equip?.unitNumber,
                  }
                );
              }}
            />
          </Box>
          <Box pad="xxsmall" gridArea="locationname">
            <Text
              size="medium"
              textAlign="start"
              margin={{ left: '0px', top: '0px', bottom: '2px', right: '6px' }}
              color="gold"
            >
              Location/Room Name
            </Text>
            <HUDInput
              size="medium"
              textAlign="start"
              placeholder="name..."
              value={props.equip.locationName}
              onChange={(event) => {
                updateUIChange(
                  {
                    locationName: event.target.value,
                  },
                  500
                );
              }}
            />
          </Box>
          {/* Only show for units that are assignable and will have an RF MAC */}
          {props.equip?.assignable && (
            <Box pad="xxsmall" gridArea="mac">
              <Text
                size="medium"
                textAlign="start"
                margin={{
                  left: '0px',
                  top: '0px',
                  bottom: '2px',
                  right: '6px',
                }}
                color="gold"
              >
                MAC Address
              </Text>

              <HUDSelect
                alignSelf="end"
                placeholder=""
                labelKey="display"
                valueKey={{ key: 'macHEX', reduce: true }}
                options={MACoptions}
                value={Device.MACaddrToString(props.equip.physicalID, 16)}
                size="small"
                onChange={({ value: nextValue }) => {
                  logger.info('HUD.changeMACAddress', {
                    selected: nextValue,
                  });
                  const mac = Device.MACaddrStringToNumber(nextValue);

                  const p = MACaddrs.find((f) => f.macnumber == mac);

                  updateUIChange(
                    {
                      physicalID: mac,
                      locationName: p ? p.unitName : '',
                      unitNumber: p ? p.unitNumber : 0,
                      installStatus: INSTALLSTATUS.COMMISSIONED,
                    },
                    0
                  );
                }}
                onClose={() => setMACOptions(MACaddrs)}
                onSearch={(text: string) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    '\\$&'
                  );

                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, 'i');
                  MACaddrs &&
                    setMACOptions(MACaddrs.filter((o) => exp.test(o.display)));
                }}
              />
            </Box>
          )}

          {/* Only show the CAT5 connction box for products requiring a CAT5 connection */}
          {props.equip?.cat5Required && (
            <Box pad="xxsmall" gridArea="cat5">
              <Text
                size="medium"
                textAlign="start"
                margin={{
                  left: '0px',
                  top: '0px',
                  bottom: '2px',
                  right: '6px',
                }}
                color="gold"
              >
                CAT5 Connection
              </Text>

              <HUDSelect
                alignSelf="end"
                placeholder=""
                labelKey="uniqueID"
                valueKey={{ key: 'uniqueID', reduce: true }}
                options={CAT5options}
                value={props.equip.cat5Connection.connectedTo}
                size="small"
                onChange={({ value: nextValue }) => {
                  logger.info('HUD.changeCAT5Connection', {
                    selected: nextValue,
                  });

                  updateUIChange(
                    {
                      cat5Connection: {
                        connectedTo: nextValue,
                        port: 0,
                      },
                    },
                    0
                  );
                }}
                onClose={() => setCAT5Options(ports)}
                onSearch={(text: string) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    '\\$&'
                  );

                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, 'i');
                  ports &&
                    setCAT5Options(ports.filter((o) => exp.test(o.name)));
                }}
              />
            </Box>
          )}
          {/* Only show the MAI connection box for products requiring an MAI connection or MAP connection */}
          {(props.equip?.MAIRequiredNumber > 0 || props.equip?.MAPRequired) && (
            <Box pad="xxsmall" gridArea="cat5">
              <Text
                size="medium"
                textAlign="start"
                margin={{
                  left: '0px',
                  top: '0px',
                  bottom: '2px',
                  right: '6px',
                }}
                color="gold"
              >
                {props.equip?.MAPRequired && 'Associated MAP'}
                {props.equip?.MAIRequiredNumber > 0 && 'Associated MAI'}
              </Text>

              <HUDSelect
                alignSelf="end"
                placeholder=""
                labelKey="uniqueID"
                valueKey={{ key: 'uniqueID', reduce: true }}
                options={MAIMAPoptions}
                value={
                  props.equip?.MAPRequired
                    ? props.equip.MAPConnection.connectedTo
                    : props.equip.MAIConnection.connectedTo
                }
                size="small"
                onChange={({ value: nextValue }) => {
                  logger.info('HUD.changeMAIorMAPConnection', {
                    selected: nextValue,
                  });
                  props.equip?.MAPRequired
                    ? updateUIChange(
                        {
                          MAPConnection: {
                            connectedTo: nextValue,
                          },
                        },
                        0
                      )
                    : updateUIChange(
                        {
                          MAIConnection: {
                            connectedTo: nextValue,
                          },
                        },
                        0
                      );
                }}
                onClose={() => setMAIMAPOptions(MAIMAPs)}
                onSearch={(text: string) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    '\\$&'
                  );

                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, 'i');
                  MAIMAPs &&
                    setMAIMAPOptions(MAIMAPs.filter((o) => exp.test(o.name)));
                }}
              />
            </Box>
          )}

          <Box pad="xxsmall" gridArea="designnotes">
            <Tip
              dropProps={{ align: { bottom: 'top' } }}
              content={
                <Text>
                  Any design notes will be added on the plan next to that unit
                </Text>
              }
            >
              <Text
                size="medium"
                textAlign="start"
                margin={{
                  left: '0px',
                  top: '0px',
                  bottom: '2px',
                  right: '6px',
                }}
                color="gold"
              >
                Design Notes
              </Text>
            </Tip>
            <HUDInput
              size="medium"
              textAlign="start"
              placeholder="design notes..."
              value={props.equip.designNotes}
              onChange={(event) => {
                updateUIChange(
                  {
                    designNotes: event.target.value,
                  },
                  500
                );
              }}
            />
          </Box>
          <Box pad="xxsmall" gridArea="servicenotes">
            <Text
              size="medium"
              textAlign="start"
              margin={{ left: '0px', top: '0px', bottom: '2px', right: '6px' }}
              color="gold"
            >
              Service Notes
            </Text>
            <HUDInput
              size="medium"
              textAlign="start"
              placeholder="service notes..."
              value={props.equip.serviceNotes}
              onChange={(event) => {
                updateUIChange(
                  {
                    serviceNotes: event.target.value,
                  },
                  500
                );
              }}
            />
          </Box>
          <Box pad="xxsmall" gridArea="locatability">
            <Tip
              dropProps={{ align: { bottom: 'top' } }}
              content={
                <Text>
                  Used as a friendly name for locating handsets for example
                </Text>
              }
            >
              <Text
                size="medium"
                textAlign="start"
                margin={{
                  left: '0px',
                  top: '0px',
                  bottom: '2px',
                  right: '6px',
                }}
                color="gold"
              >
                Locatability Name
              </Text>
            </Tip>
            <HUDInput
              size="medium"
              textAlign="start"
              placeholder="locatability..."
              value={props.equip.locatabilityName}
              onChange={(event) => {
                updateUIChange(
                  {
                    locatabilityName: event.target.value,
                  },
                  500
                );
              }}
            />
          </Box>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    // Setup the port options for the CAT5 ports select box
    // Add any currently connected port on first so that it displays in the dropdown etc..
    ports = [];
    if (props.equip) {
      const p = Site.getProductFromUniqueID(
        props.equip.cat5Connection.connectedTo
      );

      ports.push(...Site.getAvailableCat5Connections(props.currentPlan, false));

      // if our current connection is not in the list then add it
      if (p && ports.find((f) => f.uniqueID == p.uniqueID) == undefined)
        ports.push(p);
    }
    setCAT5Options(ports);

    // Setup the MAI options for the MAI select box (shown for devices such VPC, Lift, DDP etc)
    // Add any currently connected port on first so that it displays in the dropdown etc..
    MAIMAPs = [];
    if (props.equip) {
      const p = props.equip.MAPRequired
        ? Site.getProductFromUniqueID(props.equip.MAPConnection.connectedTo)
        : Site.getProductFromUniqueID(props.equip.MAIConnection.connectedTo);

      props.equip.MAPRequired
        ? MAIMAPs.push(...Site.getAvailableMAPConnections(props.currentPlan))
        : MAIMAPs.push(...Site.getAvailableMAIConnections(props.currentPlan));

      // if our current connection is not in the list then add it
      if (p && MAIMAPs.find((f) => f.uniqueID == p.uniqueID) == undefined)
        MAIMAPs.push(p);
    }
    setMAIMAPOptions(MAIMAPs);

    // Setup the avaiable MACAddress options
    // Add any current Physical ID for the selected equipment
    MACaddrs = [];
    if (props.equip) {
      MACaddrs.push({
        macnumber: -1,
        macHEX: Device.MACaddrToString(-1, 16),
        display: 'Not Set',
        unitName: '',
        unitNumber: 0,
      });

      if (props.equip.physicalID && props.equip.physicalID !== -1) {
        MACaddrs.push({
          macnumber: props.equip.physicalID,
          macHEX: Device.MACaddrToString(props.equip.physicalID, 16),
          display: Device.MACaddrToString(props.equip.physicalID, 16),
          unitName: props.equip.locationName,
          unitNumber: props.equip.unitNumber,
        });
      }
      MACaddrs.push(...Site.getAvailableMACs(props.equip));
    }
    setMACOptions(MACaddrs);

    if (props.equip) {
      if (props.equip.uniqueID != currentEquip?.uniqueID)
        setCurrentEquip(props.equip);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible, props.equip]);

  return (
    <WRAPPER>
      {props.children}
      <HUDDIV visible={props.visible}>{props.equip && details()}</HUDDIV>
    </WRAPPER>
  );
};
