/** @module Installation */

import { iCLOUD_META_DATA, invalidID } from 'types/manifest-types';
import * as manifestUtils from 'components/installation/manifestUtils';
import * as _ from 'lodash';
import * as eventDispatcher from 'store/eventDispatcher';

/*
 * Cloud Meta Data
 * @class
 * @hideconstructor
 * @implements iCareGroup
 */

export class CloudMetaData implements iCLOUD_META_DATA {
  /** iCLOUD_META_DATA start */
  ID = invalidID;
  Type = '';
  Data = '';
  /** iCLOUD_META_DATA end */

  // Got software version incase we need to do processsing differently for
  // different software revsions in the future
  softwareVersion = 0;

  manifestInfo: manifestUtils.iManifestInfo = {
    manifestObjectKeys: [],
    tablename: '',
    dataChanged: false,
    tableID: invalidID,
  };

  constructor(
    values: iCLOUD_META_DATA,
    keys: string[],
    tableName: string,
    softwareVersion: number
  ) {
    Object.assign(this, values);
    this.manifestInfo.manifestObjectKeys = keys;
    this.manifestInfo.tablename = tableName;
    this.manifestInfo.dataChanged = false;
    this.manifestInfo.tableID = this.ID;
    this.softwareVersion = softwareVersion;
  }

  generateChangeSQL(): { update: string; revert: string } {
    const sql = manifestUtils.generateChangeSQL(this, this.manifestInfo, {
      update: '',
      revert: '',
    });

    return sql;
  }

  async applyManifestChanges(): Promise<void> {
    return manifestUtils.applyManifestChanges(this, this.manifestInfo);
  }

  static async createEntry(type: string, data: string): Promise<void> {
    const manifest = manifestUtils.getManifestInstance();
    const nextId = manifest.getNextTableId_CLOUD_META_DATA();

    const inVersionRange = manifest.isInSoftwareVersionRange({
      min: '5.10.0',
      max: '999.999.999',
    });

    const obj: iCLOUD_META_DATA = {
      ID: nextId,
      Type: type,
      Data: data,
    };

    //console.info(`createEntry obj:${JSON.stringify(obj)}`);
    if (inVersionRange) {
      const insertSql =
        `INSERT INTO CLOUD_META_DATA (ID,Type,Data) ` +
        `VALUES(${obj.ID},'${obj.Type}','${obj.Data}')\n`;

      const status = await manifestUtils.executeSQL(
        insertSql,
        '',
        manifestUtils.teDB_BACKUPMETHOD.E_DB_BACKUPMETHOD_DISK_AND_UPLOAD
      );
      if (status !== manifestUtils.teErrorCode.E_OK) {
        console.error('Failed to run sql:', insertSql);
        return;
      }

      // Automatically add to the manifest
      await manifestUtils.despatchInsertToManifestStore(
        'CLOUD_META_DATA',
        obj,
        _.keys(obj)
      );

      const row = new CloudMetaData(
        obj,
        _.keys(obj),
        'CLOUD_META_DATA',
        manifest.getSCUSoftwareVersion()
      );
      manifest.cloudMetaData.push(row);

      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.UPDATED,
        null,
        true
      );
    }
  }

  static async deleteEntry(ID: number): Promise<manifestUtils.teErrorCode> {
    const manifestList = manifestUtils.getManifestInstance().cloudMetaData;
    const entry = manifestList.find((e) => e.ID === ID);
    if (entry === undefined) return manifestUtils.teErrorCode.E_ERROR;

    const deleteSQL = `DELETE from CLOUD_META_DATA where ID=${ID}`;

    const status = await manifestUtils.executeSQL(
      deleteSQL,
      '',
      manifestUtils.teDB_BACKUPMETHOD.E_DB_BACKUPMETHOD_DISK_ONLY
    );
    if (status !== manifestUtils.teErrorCode.E_OK) {
      console.error('Failed to run sql');
      return status;
    }

    await manifestUtils.despatchDeleteToManifestStore(
      entry.manifestInfo.tablename,
      entry,
      entry.manifestInfo.manifestObjectKeys
    );

    _.remove(manifestList, function (e) {
      return e.ID === ID;
    });

    eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.UPDATED,
      null,
      true
    );

    return manifestUtils.teErrorCode.E_OK;
  }
}
