import React from 'react';
import useState from 'react-usestateref';
import {
  Box,
  FormField,
  TextInput,
  Text,
  Select,
  CheckBox,
  Tip,
} from 'grommet';
import styled from 'styled-components';
import * as _ from 'lodash';

const FormLabel = styled(Text)<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : '120px')};
  display: inline-block;
  text-align: right;
`;

const LabelBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: top;
  height: 55%;
  width: 100%;
`;

export interface iField {
  label: string;
  id: string;
  type: string;
  options?: unknown[];
  formBoxWidth?: string;
  visible?: boolean;
  tooltip?: string | JSX.Element | undefined;
}

const Tooltip: React.FC<{
  content: string | JSX.Element | undefined;
  children: JSX.Element;
}> = ({ content, children }) => {
  if (content !== undefined) {
    return (
      <Tip
        dropProps={{
          margin: { left: 'large' },
        }}
        content={content}
      >
        {children}
      </Tip>
    );
  }
  return children;
};

export const AlignedFormField: React.FC<{
  id: string;
  label: string;
  type?: string;
  formBoxWidth?: string;
  options?: unknown[];
  labelWidth?: string;
  visible?: boolean;
  tooltip: string | JSX.Element | undefined;
}> = ({
  id,
  label,
  type = 'text',
  formBoxWidth = 'medium',
  options,
  labelWidth,
  visible,
  tooltip,
}) => {
  const disabled = type === 'readonly' ? true : false;
  const inputType = type === 'readonly' ? 'text' : type;
  const [searchOptions, setSearchOptions] = useState(options);
  if (visible === undefined) visible = true;

  if (type === 'select') {
    return (
      <>
        {visible === true && (
          <Tooltip content={tooltip}>
            <FormField
              name={id}
              direction="row"
              label={
                <LabelBox>
                  <FormLabel width={labelWidth}>{label}</FormLabel>
                </LabelBox>
              }
            >
              <Box width={formBoxWidth}>
                <Select
                  //id={id + '-id'}
                  name={id}
                  type={inputType}
                  size="medium"
                  margin="none"
                  disabled={disabled}
                  options={searchOptions as []}
                  labelKey="name"
                  valueKey={{ key: 'id', reduce: true }}
                  onClose={() => setSearchOptions(options)}
                  onSearch={(text) => {
                    if (searchOptions === undefined) return;
                    // The line below escapes regular expression special characters:
                    // [ \ ^ $ . | ? * + ( )
                    const escapedText = text.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      '\\$&'
                    );

                    // Create the regular expression with modified value which
                    // handles escaping special characters. Without escaping special
                    // characters, errors will appear in the console
                    const exp = new RegExp(escapedText, 'i');

                    if (escapedText.length == 0) {
                      setSearchOptions(options);
                    } else {
                      const newList = searchOptions.filter((e) => {
                        const value = _.get(e, 'name', undefined);
                        if (value === undefined) return;
                        return exp.test(value);
                      });
                      setSearchOptions(newList);
                    }
                  }}
                />
              </Box>
            </FormField>
          </Tooltip>
        )}
      </>
    );
  } else if (type === 'checkbox') {
    return (
      <Tooltip content={tooltip}>
        <FormField
          //id={id + '-id'}
          name={id}
          direction="row"
          label={
            <LabelBox>
              <FormLabel width={labelWidth}>{label}</FormLabel>
            </LabelBox>
          }
        >
          <CheckBox
            //id={id + '-id'}
            name={id}
            type={inputType}
            disabled={disabled}
          />
        </FormField>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip content={tooltip}>
        <FormField
          //id={id + '-id'}
          name={id}
          direction="row"
          label={
            <LabelBox>
              <FormLabel width={labelWidth}>{label}</FormLabel>
            </LabelBox>
          }
        >
          <Box width={formBoxWidth}>
            <TextInput
              //id={id + '-id'}
              name={id}
              size="medium"
              type={inputType}
              disabled={disabled}
            />
          </Box>
        </FormField>
      </Tooltip>
    );
  }
};

export const AlignedFormFields: React.FC<{
  displayfields: iField[];
  labelWidth?: string;
}> = ({ displayfields, labelWidth }) => {
  const EditFields = React.useMemo(() => {
    return displayfields.map((item) => {
      return (
        <AlignedFormField
          key={item.id}
          label={item.label}
          id={item.id}
          type={item.type}
          formBoxWidth={item.formBoxWidth}
          options={item.options}
          labelWidth={labelWidth}
          visible={item.visible}
          tooltip={item.tooltip}
        />
      );
    });
  }, [displayfields, labelWidth]);

  return <>{EditFields}</>;
};
