/**
 * Handles all site events
 *
 * @module SystemEvents
 */

import store from 'store/store';
import { OmniviaSCUEvent } from 'types/API';
import * as eventsslice from 'store/scuEventsSlice';
import { AUDIT_EVENTS } from 'types/event-enums';
import * as eventDispatcher from 'store/eventDispatcher';
import * as scuSlice from 'store/scusSlice';
import {
  systemEventTopics,
  systemEventStates,
} from '../../store/eventDispatcher';
import i18next from 'i18n';
import * as _ from 'lodash';
import * as alarmEventsModule from './alarmEvents';
import logger from 'common/logger';

/*
import {
  iAUDIT_EVENT_HS_LOG_ON,
  iAUDIT_EVENT_HS_NOTIFIED,
} from '../../types/eventTypes';
*/

/**
 * Aggregated Alarm Events interface
 * @interface */
export interface iEventAgregation {
  id: number;
  /** event data */
  events: OmniviaSCUEvent[];
}

/**
 * Data Fetch mode - simple data and full rich data
 */
export enum teDATA_FETCH_MODE {
  E_FETCH_MODE_SIMPLE = 0,
  E_FETCH_MODE_FULL,
}

export let allEvents: OmniviaSCUEvent[] = [];
export let alarmEvents: iEventAgregation[] = [];
export let nonAlarmEvents: OmniviaSCUEvent[] = [];
let oldestEventDate = new Date();

//let currentSelectedSCUId = '';
let fetchMode: teDATA_FETCH_MODE = teDATA_FETCH_MODE.E_FETCH_MODE_SIMPLE;
export let fetchDataIssued = false;

export const setfetchMode = (value: teDATA_FETCH_MODE): void => {
  fetchDataIssued = false;
  fetchMode = value;
};

export const getfetchMode = (): teDATA_FETCH_MODE => {
  return fetchMode;
};

export const getDuration = (start: string, end: string): number => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  let duration_ms = 0;
  if (endDate.getTime() > startDate.getTime()) {
    duration_ms = endDate.getTime() - startDate.getTime();
  }
  return duration_ms / 1000;
};

export const getAllEventsForAggregationId = (
  id: number,
  eventsList: OmniviaSCUEvent[]
): { aggregation: iEventAgregation; eventsRemaining: OmniviaSCUEvent[] } => {
  const _aggregation: iEventAgregation = { id: id, events: [] };

  let i = eventsList.length;

  // Traverse through the array backwards so when we delete elements we do not
  // mess up the index.
  while (i--) {
    if (eventsList[i].aggregationId === id) {
      _aggregation.events.push(eventsList[i]);
      eventsList.splice(i, 1);
    }
  }

  return { aggregation: _aggregation, eventsRemaining: eventsList };
};

export const translateEvent = (
  e: OmniviaSCUEvent,
  alarmSummary: alarmEventsModule.iAlarmSummary | undefined
): void => {
  // do translations of the friendly event string if one exists
  if (e.eventJSON) {
    const eJSON = JSON.parse(e.eventJSON);

    const key = `auditevent_${e.eventId}`;
    const trans = i18next.t(key, {
      ns: 'auditTrail',
      eventJSON: eJSON,
      alarmSummary: alarmSummary,
    });

    if (trans != key) {
      // set the top level event text
      e.eventString = trans;

      // Set the JSON object freidnly string
      _.set(eJSON, 'FriendlyString', trans);
      _.set(e, 'eventJSON', JSON.stringify(eJSON));
    }
  }
};

const translateEvents = (
  events: OmniviaSCUEvent[],
  alarmSummary: alarmEventsModule.iAlarmSummary | undefined
) => {
  // do translations of the friendly event string if one exists
  events.forEach((e) => {
    // do translations of the friendly event string if one exists
    translateEvent(e, alarmSummary);
  });
};

/**
 *
 * This function is called from the DataManagerProvider whenever the redux events slice is updated.
 *
 * Traverses all the events and pulls together all the events that are related for the duration of the alarm.
 * nonAlarm events that do not have any related events, for exmaple poll fail, will be put into the nonAlarmEvents array
 *
 * When you call loadDetailedEventsForAlarm and loadEventsForType this function will be call again with
 *a list that includes the addtional events
 * @param events: the JSON event data to load
 * @param selectedSCUId: the currently selected SCUID  (serial number)
 * @return: the number of events loaded for both nonAlarm and alarm
 */
export const load = (
  events: OmniviaSCUEvent[],
  selectedSCUId: string
): { nonAlarmTotal: number; alarmTotal: number } => {
  logger.debug('Events load');
  // logger.debug(events);
  logger.debug(selectedSCUId);
  allEvents = events;
  alarmEvents = [];
  nonAlarmEvents = [];
  //currentSelectedSCUId = selectedSCUId;
  let eventsRemainCopy: OmniviaSCUEvent[] = JSON.parse(
    JSON.stringify(allEvents)
  );

  logger.debug('Total events:', eventsRemainCopy.length);
  oldestEventDate = new Date();

  do {
    if (
      eventsRemainCopy.length > 0 &&
      eventsRemainCopy[0].aggregationId &&
      eventsRemainCopy[0].aggregationId > 0
    ) {
      if (eventsRemainCopy[0].raisedAt) {
        const newDate = new Date(eventsRemainCopy[0].raisedAt);
        if (newDate < oldestEventDate) {
          oldestEventDate = newDate;
        }
      }
      const retObj = getAllEventsForAggregationId(
        eventsRemainCopy[0].aggregationId,
        eventsRemainCopy
      );

      alarmEvents.push(retObj.aggregation);

      eventsRemainCopy = retObj.eventsRemaining;
    } else if (eventsRemainCopy.length > 0) {
      nonAlarmEvents.push(eventsRemainCopy[0]);
      eventsRemainCopy.shift();
    }
  } while (eventsRemainCopy.length > 0);

  //console.info('alarmEvents:', alarmEvents);
  //console.info('nonAlarmEvents:', nonAlarmEvents);

  // Specific call to getAlarms instead of hooking off the processed events for 2 reasons:
  // 1. We ensure that the alarm summeries are created before any module tries to use them
  // 2. Allow us to pass the alarm summary to the Audit translations
  //
  // Also ensure getAlarms alarm is called before the translations as the alarm Events Module
  // pulls information from the text strings.
  //
  // TODO: This needs resolving and the eventJSON should contain all the info
  alarmEventsModule.getAlarms();

  // moved the translate to after deciphering the alarms so that we
  // can pass the alarm sumary to the audit events
  translateEvents(nonAlarmEvents, undefined);
  for (const index in alarmEvents) {
    const alarmSummary = alarmEventsModule.alarmSummaries.find(
      (summary) => summary.alarmId === alarmEvents[index].id
    );

    translateEvents(alarmEvents[index].events, alarmSummary);
  }

  const totals = {
    nonAlarmTotal: nonAlarmEvents.length,
    alarmTotal: alarmEvents.length,
  };
  logger.debug('Events load complete');

  return totals;
};

/** Fetch - invokes a data store S3 fetch on event data
 *  this wil getch either simple of full data based on the current fetchMode setting
 * @param daysToFetch : number - optional number of days to fetch data for, defaults to 30
 * @param mode - optional parameter to specify fetchMode of simple or full
 */
/*
export const fetch = (daysToFetch?: number, mode?: teDATA_FETCH_MODE): void => {
  if (!daysToFetch) {
    daysToFetch = 30;
  }

  if (mode) {
    setfetchMode(mode);
  }

  if (fetchDataIssued == true) {
    return;
  }

  fetchDataIssued = true;

  if (fetchMode === teDATA_FETCH_MODE.E_FETCH_MODE_SIMPLE) {
    store.dispatch(
      eventsslice.fetch({
        SCUId: currentSelectedSCUId,
        daysToFetch: daysToFetch,
      })
    );
  } else {
    store.dispatch(
      eventsslice.fetchAll({
        SCUId: currentSelectedSCUId,
        daysToFetch: daysToFetch,
      })
    );
  }
};
*/

/**
 * @description Get a list of events of particular types
 * @param filterevents AUDIT_EVENTS[] filterevents - an array of audit events we are interested in. Can be just one event or multiple.
 * @param nonAlarm boolean which states whether we are interested in nonalarm events only (speeds up the search)
 * @param asEventJSON optional boolean, set to true if we wish to flatten and return the EventJSON string field only.
 * @example getEventsByType([AUDIT_EVENTS.AUDIT_EVENT_ALARM_RAISE])
 * @example getEventsByType([AUDIT_EVENTS.AUDIT_EVENT_ALARM_RAISE, AUDIT_EVENTS.AUDIT_EVENT_ALARM_SELECT])
 *
 */
export const getEventsByType = (
  filterevents: AUDIT_EVENTS[],
  nonAlarm?: boolean,
  asEventJSON?: boolean
): OmniviaSCUEvent[] | [] => {
  const ret = nonAlarm
    ? nonAlarmEvents.filter((e) => filterevents.includes(e.eventId))
    : allEvents.filter((e) => filterevents.includes(e.eventId));

  if (asEventJSON) {
    const js = ret.map((e) => {
      let r = [];
      if (e.eventJSON) r = JSON.parse(e.eventJSON);
      return r;
    });
    return js;
  }

  return ret;
};

export const getOldestEntry = (): Date => {
  return oldestEventDate;
};

function getAllDataFromTheStore() {
  const allEvents = eventsslice.getAll(store.getState()) as OmniviaSCUEvent[];
  const scuSelected = scuSlice.getSelected(store.getState());
  if (allEvents && scuSelected) {
    load(allEvents, scuSelected.id);
    eventDispatcher.emitEvent(
      systemEventTopics.SITEEVENTS,
      systemEventStates.PROCESSED,
      null,
      true
    );
  }
}

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.SITEEVENTS,
  eventDispatcher.systemEventStates.LOADED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    getAllDataFromTheStore();
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.SITEEVENTS,
  eventDispatcher.systemEventStates.UPDATED,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  (e) => {
    // e will have the new record
    getAllDataFromTheStore();
  }
);
