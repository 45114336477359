/** TODO
Audio Neighbours tile
=====================

Ability to show all all neighbours for a node audio routing and enable disable links:
(EH - was thinking we could show this on the floor plan, have a new view showing multi-floors and neighbours of a device)

To list a nodes neighbours and RSSIs:
select * from DEVICE_NEIGHBOUR where NodeDeviceId in (44) OR NeighbourDeviceId IN (44)

To disable a node from all routing:
update DEVICE_NEIGHBOUR set Ignore=1 where NodeDeviceId in (44) OR NeighbourDeviceId IN (44)


Matching the manifest and plan data
- Matching - split into text and number,  strong fuzzy text, exact on number.
- Unassigned units:
-   Hud :  when select a unit give drop down of all MAC for that unit type   - ensure change change MAC if mistake made
        :  Hover of unit give info,  create a div - layered:  show of hide the div depending on if selected.  Set the div position so it is over the compoent.

 */

import './App.scss';
import 'reflect-metadata'; // needed by class-transformer (JSON-> Class and vice versa)

import LoggedinLayout from 'components/themes/loggedinLayout';
import './App.css';
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import React, { useEffect, useState } from 'react';
import spinner from './assets/img/tail-spin.svg';
import { Auth } from 'aws-amplify';
import { Anchor, Button, Box, Text } from 'grommet';

// import aws_config from './aws-exports-production'; // production backend
import aws_config from './aws-exports'; // development backend
import Amplify from 'aws-amplify';
import { Switch, Route } from 'react-router-dom';
import Views from './views/Views';

import { useSelector, useDispatch } from 'react-redux';
import { setUser, setUserCognitoId } from './store/appUserSlice';
import * as UserUtils from 'common/userUtils';
import * as SiteAuthUtils from 'common/siteAuthUtils';
import { useWinstonLogger } from 'winston-react';
import styled from 'styled-components';
import LoginHelp from 'assets/img/loginHelp.png';
import _ from 'lodash';
import whlogo from 'assets/img/wirelessHealthLogo.svg';
import tunstalllogo from 'assets/img/logo-tunstall.svg';

/**
 * Important that the dataSequencer module is loaded here so that it is created first
 * so that it registers for events before any other modules emit events
 */
import * as dataSequencer from 'store/dataSequencer';
import HelpPages from 'views/helpPages';
import { BRAND_WIRELESSHEALTH, BRAND_TUNSTALL } from 'components/themes/brands';

Amplify.configure(aws_config);
let authSignedIn = false;
dataSequencer.init();

/**
 * SN:  I have seen instance the the stored AUth data is corrupted
 * and the only way to correct it is to log out.
 * I don't know why this happens but added a work arround.
 */
window.addEventListener('unhandledrejection', async (event) => {
  //console.error('Global unhandledrejection:', event);
  if (event.reason.stack.includes('AuthClass.')) {
    await UserUtils.logout();
  }
});

const CenteredDiv = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoginInHelp = styled.div`
  position: fixed;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Header = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const App = () => {
  const SCREEN_STATE = {
    LOADING: 'loading',
    UNAUTHORISED: 'unauth',
    LOADED: 'loaded',
  };

  const logger = useWinstonLogger();
  const [screenState, setScreenState] = useState(SCREEN_STATE.LOADING);
  let domainBrand = BRAND_WIRELESSHEALTH;

  const dispatch = useDispatch();
  const store_appUser = useSelector((state) => state.appUser);

  if (
    window &&
    (window.location.href.includes('staging3') ||
      window.location.href.includes('tunstall'))
  )
    domainBrand = BRAND_TUNSTALL;

  const signOut = async (e) => {
    logger.info('Sign out');
    e.preventDefault();
    await UserUtils.logout();
  };

  const SignUpText = () => {
    if (domainBrand == BRAND_WIRELESSHEALTH)
      return (
        <center>
          <p>You are currently not authorised to use this site</p>
          <p>Please contact our customer support for help:</p>
          <p>insights@wirelesshealth.co.uk</p>

          <Button onClick={signOut}>Sign out</Button>
        </center>
      );

    if (domainBrand == BRAND_TUNSTALL)
      return (
        <center>
          <p>You are currently not authorised to use this site</p>
          <p>Please contact our customer support for help:</p>
          <p>e: xxxxx@tunstall.co.uk t: 01977 xxxxxxx</p>

          <Button onClick={signOut}>Sign out</Button>
        </center>
      );
  };

  const LoginHelpText = () => {
    if (domainBrand == BRAND_WIRELESSHEALTH) return <img src={LoginHelp} />;

    if (domainBrand == BRAND_TUNSTALL)
      return (
        <center>
          <p>
            For new users please contact our customer support to request a
            login:
          </p>
          <p>e: xxxxx@tunstall.co.uk t: 01977 xxxxxxx</p>
        </center>
      );
  };

  const HeaderText = () => {
    if (domainBrand == BRAND_WIRELESSHEALTH)
      return (
        <Box direction="row">
          <Anchor to="http://practicalcontrol.co.uk">
            <img
              src={whlogo}
              style={{
                width: '145px',
                height: '60px',
                marginRight: '8px',
              }}
            />
          </Anchor>
          <Box margin={{ top: '-8px' }}>
            <Text size="60px" color="aqua">
              Insights
            </Text>
          </Box>
        </Box>
      );

    if (domainBrand == BRAND_TUNSTALL)
      return (
        <Box direction="row">
          <Anchor to="http://tunstall.co.uk">
            <img
              src={tunstalllogo}
              style={{ width: '160px', height: '70px', margin: 'small' }}
            />
          </Anchor>
          <Box margin={{ top: '12px', left: '10px' }}>
            {/* <Text size="40px" color="white">
              Insights
            </Text> */}
          </Box>
        </Box>
      );
  };

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      logger.debug(`nextAuthState: ${nextAuthState}`);

      if (
        !authSignedIn &&
        authData &&
        authData.signInUserSession &&
        authData.signInUserSession.accessToken
      ) {
        authSignedIn = true;
        let _user = {
          userid: authData.username,
          attributes: authData.attributes,
        };

        let groups =
          authData.signInUserSession.accessToken.payload['cognito:groups'];

        // Store the users cognitoIdentityId in a custome attribute.
        // This is needed when we authorise the user for IoT events

        try {
          Auth.currentCredentials().then(async (info) => {
            const cognitoIdentityId = info.identityId;
            const oldCognitoIdentityId = _.get(
              authData.attributes,
              'custom:cognitoIdentityId',
              ''
            );

            logger.info('app.login.currentCredentials.cognitoIdentityId', {
              current: cognitoIdentityId,
              previous: oldCognitoIdentityId,
            });
            //console.info('cognitoIdentityId:', cognitoIdentityId);
            //console.info('previous cognitoIdentityId:', oldCognitoIdentityId);

            if (oldCognitoIdentityId !== cognitoIdentityId) {
              //console.info(
              //  `Update cogId from ${oldCognitoIdentityId} to ${cognitoIdentityId}`
              //);

              logger.info(
                'app.login.currentCredentials.updateCognitoIdentityId',
                {
                  current: cognitoIdentityId,
                }
              );

              await Auth.updateUserAttributes(authData, {
                'custom:cognitoIdentityId': cognitoIdentityId,
              });
              window.location.reload();
            } else {
              await SiteAuthUtils.checkCurrentUserIotConfigAuth(
                authData,
                authData.username,
                authData.attributes
              );
            }

            await dispatch(setUser({ user: _user, groups: groups }));
            await dispatch(setUserCognitoId(cognitoIdentityId));
            await UserUtils.getCurrentUserConfigAttributes();

            if (!UserUtils.minimumAccessLevel(UserUtils.UAG.ELITE)) {
              const DEBUG = false;
              if (!DEBUG) {
                if (!window.console) window.console = {};
                const methods = ['log', 'debug', 'warn', 'info'];
                for (let i = 0; i < methods.length; i++) {
                  console[methods[i]] = function () {};
                }
              }
            }
          });
        } catch (e) {
          console.error('**Failed to setup cognitoIdentityId. errorr', e);
        }
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      store_appUser.dataLoaded === true &&
      store_appUser.authorisedState === true &&
      screenState === SCREEN_STATE.LOADING
    ) {
      logger.info('app.login.user');
      console.info('dataloaded and authorised, good to go');
      setScreenState(SCREEN_STATE.LOADED);
    } else if (
      store_appUser.user.userid !== '' &&
      store_appUser.authorisedState === false &&
      screenState === SCREEN_STATE.LOADING
    ) {
      console.info('user not authorised');
      setScreenState(SCREEN_STATE.UNAUTHORISED);
    }
  }, [screenState, store_appUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box background="#31363e">
      <AmplifyAuthContainer>
        {screenState == SCREEN_STATE.LOADING && (
          <LoginInHelp>
            <LoginHelpText />
          </LoginInHelp>
        )}
        {screenState != SCREEN_STATE.LOADED && (
          <Header>
            <HeaderText />
          </Header>
        )}

        <AmplifyAuthenticator usernameAlias="email">
          <AmplifySignIn
            headerText="Sign into your Insights account"
            slot="sign-in"
            hideSignUp
            formFields={[
              {
                type: 'username',
                label: 'Username',
                placeholder: 'Email Address',
                required: false,
              },
              {
                type: 'password',
                label: 'Password',
                placeholder: 'Password',
                required: true,
              },
            ]}
          ></AmplifySignIn>

          {screenState == SCREEN_STATE.LOADED && (
            <LoggedinLayout>
              <div className="App">
                <Switch>
                  <Route path="/views" component={Views} />
                  <Route path="/" exact component={Views} />
                  <Route path="/help" component={HelpPages} />
                </Switch>
              </div>
            </LoggedinLayout>
          )}
          {screenState == SCREEN_STATE.UNAUTHORISED && (
            <CenteredDiv>
              <SignUpText />
            </CenteredDiv>
          )}
          {screenState == SCREEN_STATE.LOADING && (
            <>
              <CenteredDiv>
                <center>
                  <p>
                    <img src={spinner} className="widget" />
                  </p>
                  <p>Loading...</p>
                  <Button onClick={signOut}>Sign out</Button>
                </center>
              </CenteredDiv>
            </>
          )}
        </AmplifyAuthenticator>
      </AmplifyAuthContainer>
    </Box>
  );
};

export default App;
