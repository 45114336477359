import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import * as eventDispatcher from 'store/eventDispatcher';
import * as userUtils from 'common/userUtils';
import * as LocalStore from 'common/localStorage';
import { store } from 'store/store';
import * as _ from 'lodash';
import appHistory from 'common/appHistory';
import logger from 'common/logger';
import { iDashBoardEvent } from 'views/Dashboard';

const getLocalStorageItem = (item: string): string | null => {
  if (!window || !window.localStorage) return null;
  return window.localStorage.getItem(item);
};

interface iPersistentUserData {
  selectedAlarmId: number | undefined;
  selectedSiteId: string | undefined;
  selectedView: string | undefined;
  selectedDashboard: iDashBoardEvent | undefined;
  selectedDeviceMAC: number | undefined;
}

interface iUserState {
  dataLoaded: boolean;
  user: {
    userid: string;
    attributes: {
      name: string;
      email: string;
      email_verified: boolean;
    };
  };
  groups: string[];
  authorisedState: boolean;
  isAdmin: boolean;
  cognitoIdentityId: string;
  persistentUserData: iPersistentUserData;
  userConfig: userUtils.iUserConfig;
}

const persistentUserDataInitial: iPersistentUserData = {
  selectedAlarmId: undefined,
  selectedSiteId: undefined,
  selectedView: undefined,
  selectedDashboard: undefined,
  selectedDeviceMAC: undefined,
};

const persistentUserDataString: string | null = getLocalStorageItem(
  LocalStore.teLocalStorageItems.E_LS_KEY_USER_SELECTIONS
);

const persistentUserData: iPersistentUserData =
  persistentUserDataString !== '' && persistentUserDataString !== null
    ? JSON.parse(persistentUserDataString)
    : persistentUserDataInitial;

//console.info('Persistent data:', persistentUserData);

const initialState: iUserState = {
  dataLoaded: false,
  user: {
    userid: '',
    attributes: {
      name: '',
      email: '',
      email_verified: false,
    },
  },
  groups: [],
  authorisedState: false,
  isAdmin: false,
  cognitoIdentityId: '',
  persistentUserData: persistentUserData,
  userConfig: userUtils.userConfigDefault,
};

const savePeristentData = (data: iPersistentUserData) => {
  window.localStorage.setItem(
    LocalStore.teLocalStorageItems.E_LS_KEY_USER_SELECTIONS,
    JSON.stringify(data)
  );
};

const slice = createSlice({
  name: 'appUser',
  initialState,
  reducers: {
    appDataLoaded(state, action) {
      state.dataLoaded = action.payload;
    },

    setUserAuthorised(state, action) {
      state.authorisedState = action.payload;
    },

    setUserCognitoId(state, action) {
      state.cognitoIdentityId = action.payload;
    },

    setCognitoUserConfiguration(state, action) {
      state.userConfig = action.payload;
    },

    setUser(state, action) {
      state.isAdmin = false;
      state.authorisedState = false;

      if (action.payload.groups && action.payload.groups.length > 0) {
        state.groups = action.payload.groups;
        const userLevel = userUtils.getAccessLevelFromGroups(
          action.payload.groups
        );

        if (userLevel != userUtils.UAG.NONE) {
          console.info('user authorised');
          state.authorisedState = true;
        }

        if (userLevel == userUtils.UAG.ADMIN) {
          state.isAdmin = true;
        }
      }

      state.user = action.payload.user;

      if (state.authorisedState) {
        eventDispatcher.emitEvent(
          eventDispatcher.systemEventTopics.USER,
          eventDispatcher.systemEventStates.AUTHORISED,
          null,
          true
        );
      } else {
        eventDispatcher.emitEvent(
          eventDispatcher.systemEventTopics.USER,
          eventDispatcher.systemEventStates.NOTAUTHORISED,
          null,
          true
        );
      }
    },

    setPersistentData(state, action) {
      state.persistentUserData = action.payload as iPersistentUserData;
      savePeristentData(state.persistentUserData);
    },
  },
});

export const {
  appDataLoaded,
  setUser,
  setUserCognitoId,
  setPersistentData,
  setCognitoUserConfiguration,
} = slice.actions;

export default slice.reducer;

const getState = (state: RootState) => state;

export const getSlice = createSelector([getState], (state: RootState) => {
  return state.appUser;
});

export const getUsersName = createSelector(
  [getState],
  (state: RootState): string => {
    return state.appUser.user.attributes.name;
  }
);

export const getUsersEmailAddress = createSelector(
  [getState],
  (state: RootState): string => {
    return state.appUser.user.attributes.email;
  }
);

export const userHasVerifiedTheirEmailAddress = createSelector(
  [getState],
  (state: RootState): boolean => {
    return state.appUser.user.attributes.email_verified;
  }
);

export const isUserAdmin = createSelector(
  [getState],
  (state: RootState): boolean => {
    return state.appUser.isAdmin;
  }
);

export const getUserGroups = createSelector(
  [getState],
  (state: RootState): string[] => {
    return state.appUser.groups;
  }
);

export const getUserId = createSelector(
  [getState],
  (state: RootState): string => {
    return state.appUser.user.userid;
  }
);

export const getPersistantData = createSelector(
  [getState],
  (state: RootState): iPersistentUserData => {
    return state.appUser.persistentUserData;
  }
);

export const getCognitoUserConfig = createSelector(
  [getState],
  (state: RootState): userUtils.iUserConfig => {
    return state.appUser.userConfig;
  }
);

const updatePersistantField = async (
  field: string,
  value: unknown
): Promise<void> => {
  console.info('Change field type:', typeof value, value);

  const pData = getPersistantData(store.getState());
  const oldValue = _.get(pData, field, undefined);
  if (oldValue == value || (oldValue === undefined && value == '')) return;

  const persistentUserData: iPersistentUserData = _.cloneDeep(pData);

  _.set(persistentUserData, field, value);

  if (typeof persistentUserData.selectedAlarmId === 'string') {
    persistentUserData.selectedAlarmId = undefined;
  }

  if (typeof persistentUserData.selectedDeviceMAC === 'string') {
    persistentUserData.selectedDeviceMAC = undefined;
  }

  await store.dispatch(slice.actions.setPersistentData(persistentUserData));

  console.info(
    `Set Persistent field ${field} = ${value} was:${_.get(
      pData,
      field,
      undefined
    )}`
  );
  console.info('Persistent data updated to :', persistentUserData);
  console.info('Persistent data updated was :', pData);
};

export const changeView = async (view: string) => {
  await updatePersistantField('selectedView', view);
  appHistory.push(view);
  //logger.info('view.change', { view: view });
};

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.ALARMEVENTS,
  eventDispatcher.systemEventStates.SELECTED,
  async (e) => {
    const value = e.detail as number | undefined;
    await updatePersistantField('selectedAlarmId', value);
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.DEVICE,
  eventDispatcher.systemEventStates.SELECTED,
  async (e) => {
    const value = e.detail as number | undefined;
    await updatePersistantField('selectedDeviceMAC', value);
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.VIEW,
  eventDispatcher.systemEventStates.SELECTED,
  async (e) => {
    const value = e.detail as string | undefined;
    if (value) {
      await changeView(value);
    } else {
      await updatePersistantField('selectedView', value);
    }
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.DASHBOARD,
  eventDispatcher.systemEventStates.SELECTED,
  async (e) => {
    const value = e.detail as iDashBoardEvent | undefined;

    console.info('dash change:', value);
    await updatePersistantField('selectedDashboard', value);

    const location = appHistory.location;
    console.info('History:', location);
    const newLocation = '/views/dashboard/';

    logger.info('dashboard.change', { dashboard: value });

    await changeView(newLocation);
    await eventDispatcher.emitEvent(
      eventDispatcher.systemEventTopics.DASHBOARD,
      eventDispatcher.systemEventStates.REFRESHNEEDED,
      value,
      true
    );
  }
);

eventDispatcher.registerForEvent(
  eventDispatcher.systemEventTopics.SITE,
  eventDispatcher.systemEventStates.SELECTED,
  async (e) => {
    const value = e.detail as string | undefined;
    await updatePersistantField('selectedSiteId', value);
  }
);
