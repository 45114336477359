import * as MsgDefines_DEVICES from './IoTMessageDefines_DEVICES';
import * as MsgDefines from './IoTMessageDefines';
import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
//import logger from 'common/logger';
import { Device } from 'components/installation/device';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

export { teCLOUD_ERROR_CODES } from './IoTMessageDefines';
export { teDEVICE_TYPES, teDEVICE_MODELS } from 'types/manifest-enums';
export type { tsCLOUD_MSG_DEVICES_ASSIGN_RESP } from './IoTMessageDefines_DEVICES';

/**
 * Assigns a interfaces (Doors/MAIs) or rooms a name and room number
 * On receipt of the request the SCU will create the necassary SQLite tables for rooms and interfaces/door
 * @param mac
 * @param unitId
 * @param name
 * @param userData
 * @returns
 */
export async function assignDevice(
  mac: number,
  unitId: number,
  name: string,
  userData: unknown = null
): Promise<iIotResponseEventDetail> {
  const type = MsgDefines.teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_DEVICES;
  const opcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_ASSIGN_REQ;
  const rplyopcode =
    MsgDefines_DEVICES.teCLOUD_MSG_TY_DEVICES.E_CLOUD_MSG_DEVICES_ASSIGN_RESP;

  const msg: MsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_ASSIGN_REQ = {
    mac: Device.MACaddrToString(mac, 10),
    unitId: unitId,
    name: name,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    3000,
    'assignDevice',
    userData
  );
}
