/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateOmniviaClientInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  addr1?: string | null,
  addr2?: string | null,
  addr3?: string | null,
  city?: string | null,
  county?: string | null,
  country?: string | null,
  postcode?: string | null,
  contactEmail?: string | null,
  contactTelephone?: string | null,
  timeZone?: string | null,
  brand?: string | null,
  tags?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
};

export type ModelOmniviaClientConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  addr1?: ModelStringInput | null,
  addr2?: ModelStringInput | null,
  addr3?: ModelStringInput | null,
  city?: ModelStringInput | null,
  county?: ModelStringInput | null,
  country?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactTelephone?: ModelStringInput | null,
  timeZone?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  _deleted?: ModelBooleanInput | null,
  and?: Array< ModelOmniviaClientConditionInput | null > | null,
  or?: Array< ModelOmniviaClientConditionInput | null > | null,
  not?: ModelOmniviaClientConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type OmniviaClient = {
  __typename: "OmniviaClient",
  id: string,
  name: string,
  description?: string | null,
  addr1?: string | null,
  addr2?: string | null,
  addr3?: string | null,
  city?: string | null,
  county?: string | null,
  country?: string | null,
  postcode?: string | null,
  contactEmail?: string | null,
  contactTelephone?: string | null,
  timeZone?: string | null,
  brand?: string | null,
  tags?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
  linkedSites?: ModelOmniviaSiteConnection | null,
};

export type ModelOmniviaSiteConnection = {
  __typename: "ModelOmniviaSiteConnection",
  items:  Array<OmniviaSite | null >,
  nextToken?: string | null,
};

export type OmniviaSite = {
  __typename: "OmniviaSite",
  id: string,
  name: string,
  description?: string | null,
  addr1?: string | null,
  addr2?: string | null,
  addr3?: string | null,
  city?: string | null,
  county?: string | null,
  country?: string | null,
  postcode?: string | null,
  contactEmail?: string | null,
  contactTelephone?: string | null,
  timeZone: string,
  maintenanceEmail?: string | null,
  pollFailEmail?: string | null,
  stuckAlarmMinAge?: number | null,
  stuckAlarmMaxAge?: number | null,
  stuckAlarmMinTimeBetweenSMS?: number | null,
  stuckAlarmLastAlert?: string | null,
  siteAlertsEnabled?: boolean | null,
  brand?: string | null,
  tags?: string | null,
  floorPlanFolder?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
  groupsCanAccess?: Array< string > | null,
  linkedClient?: OmniviaClient | null,
  linkedSCUs?: ModelOmniviaSCUConnection | null,
};

export type ModelOmniviaSCUConnection = {
  __typename: "ModelOmniviaSCUConnection",
  items:  Array<OmniviaSCU | null >,
  nextToken?: string | null,
};

export type OmniviaSCU = {
  __typename: "OmniviaSCU",
  id: string,
  name: string,
  description?: string | null,
  serialNumber: string,
  localEthernetIP?: string | null,
  localWiFiIP?: string | null,
  ethernetMAC?: string | null,
  wifiMAC?: string | null,
  softwareVersion?: string | null,
  mainOutgoingPOTSNumber: string,
  backupOutgoingPOTSNumber: string,
  remoteAccessPort?: number | null,
  productNumber?: string | null,
  lastSeen?: string | null,
  unitTime?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
  groupsCanAccess?: Array< string | null > | null,
  linkedSite?: OmniviaSite | null,
  linkedEvents?: ModelOmniviaSCUEventConnection | null,
  linkedFaults?: ModelOmniviaFaultsConnection | null,
};

export type ModelOmniviaSCUEventConnection = {
  __typename: "ModelOmniviaSCUEventConnection",
  items:  Array<OmniviaSCUEvent | null >,
  nextToken?: string | null,
};

export type OmniviaSCUEvent = {
  __typename: "OmniviaSCUEvent",
  id: string,
  alertGeneratedState: number,
  omniviaSCUEventLinkedSCUId: string,
  eventId: number,
  transactionId: number,
  eventIdText?: string | null,
  eventString?: string | null,
  raisedAt: string,
  eventJSON?: string | null,
  aggregationId?: number | null,
  unitTime?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
  omniviaSCUEventLinkedSCUIdEventId?: string | null,
  linkedSCU?: OmniviaSCU | null,
};

export type ModelOmniviaFaultsConnection = {
  __typename: "ModelOmniviaFaultsConnection",
  items:  Array<OmniviaFaults | null >,
  nextToken?: string | null,
};

export type OmniviaFaults = {
  __typename: "OmniviaFaults",
  id: string,
  omniviaFaultsLinkedSCUId?: string | null,
  faultState: OmniviaFaultState,
  faultType: OmniviaFaultsTypes,
  dateRaised: string,
  dateCleared?: string | null,
  dateAlertLastGenerated?: string | null,
  deviceID: string,
  faultSpecificText?: string | null,
  friendlyString?: string | null,
  clearedBy?: string | null,
  associatedRoomNumber?: number | null,
  identity?: string | null,
  faultUnitClassification?: FaultUnitClassification | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
  linkedSCU?: OmniviaSCU | null,
};

export enum OmniviaFaultState {
  FAULT_ACTIVE = "FAULT_ACTIVE",
  FAULT_CLEARED = "FAULT_CLEARED",
  FAULT_FORCE_CLEARED_USER = "FAULT_FORCE_CLEARED_USER",
}


export enum OmniviaFaultsTypes {
  ETHERNET_FAIL = "ETHERNET_FAIL",
  DEVICE_POLL_FAIL = "DEVICE_POLL_FAIL",
  PRIMARY_TELEPHONE_LINE_FAIL = "PRIMARY_TELEPHONE_LINE_FAIL",
  SECONDARY_TELEPHONE_LINE_FAIL = "SECONDARY_TELEPHONE_LINE_FAIL",
  MAINS_FAIL = "MAINS_FAIL",
  PSU_BATTERY_LOW = "PSU_BATTERY_LOW",
  DEVICE_BATTERY_LOW = "DEVICE_BATTERY_LOW",
  AUDIO_ROUTE_FAIL = "AUDIO_ROUTE_FAIL",
}


export enum FaultUnitClassification {
  trigger = "trigger",
  accesspoint = "accesspoint",
  roomunit = "roomunit",
}


export type UpdateOmniviaClientInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  addr1?: string | null,
  addr2?: string | null,
  addr3?: string | null,
  city?: string | null,
  county?: string | null,
  country?: string | null,
  postcode?: string | null,
  contactEmail?: string | null,
  contactTelephone?: string | null,
  timeZone?: string | null,
  brand?: string | null,
  tags?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
};

export type DeleteOmniviaClientInput = {
  id: string,
};

export type CreateOmniviaSiteInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  addr1?: string | null,
  addr2?: string | null,
  addr3?: string | null,
  city?: string | null,
  county?: string | null,
  country?: string | null,
  postcode?: string | null,
  contactEmail?: string | null,
  contactTelephone?: string | null,
  timeZone: string,
  maintenanceEmail?: string | null,
  pollFailEmail?: string | null,
  stuckAlarmMinAge?: number | null,
  stuckAlarmMaxAge?: number | null,
  stuckAlarmMinTimeBetweenSMS?: number | null,
  stuckAlarmLastAlert?: string | null,
  siteAlertsEnabled?: boolean | null,
  brand?: string | null,
  tags?: string | null,
  floorPlanFolder?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
  groupsCanAccess?: Array< string > | null,
  omniviaSiteLinkedClientId?: string | null,
};

export type ModelOmniviaSiteConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  addr1?: ModelStringInput | null,
  addr2?: ModelStringInput | null,
  addr3?: ModelStringInput | null,
  city?: ModelStringInput | null,
  county?: ModelStringInput | null,
  country?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactTelephone?: ModelStringInput | null,
  timeZone?: ModelStringInput | null,
  maintenanceEmail?: ModelStringInput | null,
  pollFailEmail?: ModelStringInput | null,
  stuckAlarmMinAge?: ModelIntInput | null,
  stuckAlarmMaxAge?: ModelIntInput | null,
  stuckAlarmMinTimeBetweenSMS?: ModelIntInput | null,
  stuckAlarmLastAlert?: ModelStringInput | null,
  siteAlertsEnabled?: ModelBooleanInput | null,
  brand?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  floorPlanFolder?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  _deleted?: ModelBooleanInput | null,
  and?: Array< ModelOmniviaSiteConditionInput | null > | null,
  or?: Array< ModelOmniviaSiteConditionInput | null > | null,
  not?: ModelOmniviaSiteConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateOmniviaSiteInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  addr1?: string | null,
  addr2?: string | null,
  addr3?: string | null,
  city?: string | null,
  county?: string | null,
  country?: string | null,
  postcode?: string | null,
  contactEmail?: string | null,
  contactTelephone?: string | null,
  timeZone?: string | null,
  maintenanceEmail?: string | null,
  pollFailEmail?: string | null,
  stuckAlarmMinAge?: number | null,
  stuckAlarmMaxAge?: number | null,
  stuckAlarmMinTimeBetweenSMS?: number | null,
  stuckAlarmLastAlert?: string | null,
  siteAlertsEnabled?: boolean | null,
  brand?: string | null,
  tags?: string | null,
  floorPlanFolder?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
  groupsCanAccess?: Array< string > | null,
  omniviaSiteLinkedClientId?: string | null,
};

export type DeleteOmniviaSiteInput = {
  id: string,
};

export type CreateOmniviaSCUInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  serialNumber: string,
  localEthernetIP?: string | null,
  localWiFiIP?: string | null,
  ethernetMAC?: string | null,
  wifiMAC?: string | null,
  softwareVersion?: string | null,
  mainOutgoingPOTSNumber: string,
  backupOutgoingPOTSNumber: string,
  remoteAccessPort?: number | null,
  productNumber?: string | null,
  lastSeen?: string | null,
  unitTime?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
  groupsCanAccess?: Array< string | null > | null,
  omniviaSCULinkedSiteId?: string | null,
};

export type ModelOmniviaSCUConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  serialNumber?: ModelStringInput | null,
  localEthernetIP?: ModelStringInput | null,
  localWiFiIP?: ModelStringInput | null,
  ethernetMAC?: ModelStringInput | null,
  wifiMAC?: ModelStringInput | null,
  softwareVersion?: ModelStringInput | null,
  mainOutgoingPOTSNumber?: ModelStringInput | null,
  backupOutgoingPOTSNumber?: ModelStringInput | null,
  remoteAccessPort?: ModelIntInput | null,
  productNumber?: ModelStringInput | null,
  lastSeen?: ModelStringInput | null,
  unitTime?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  _deleted?: ModelBooleanInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelOmniviaSCUConditionInput | null > | null,
  or?: Array< ModelOmniviaSCUConditionInput | null > | null,
  not?: ModelOmniviaSCUConditionInput | null,
};

export type UpdateOmniviaSCUInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  serialNumber?: string | null,
  localEthernetIP?: string | null,
  localWiFiIP?: string | null,
  ethernetMAC?: string | null,
  wifiMAC?: string | null,
  softwareVersion?: string | null,
  mainOutgoingPOTSNumber?: string | null,
  backupOutgoingPOTSNumber?: string | null,
  remoteAccessPort?: number | null,
  productNumber?: string | null,
  lastSeen?: string | null,
  unitTime?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _deleted?: boolean | null,
  groupsCanAccess?: Array< string | null > | null,
  omniviaSCULinkedSiteId?: string | null,
};

export type DeleteOmniviaSCUInput = {
  id: string,
};

export type CreateOmniviaSCUEventInput = {
  id?: string | null,
  alertGeneratedState: number,
  omniviaSCUEventLinkedSCUId: string,
  eventId: number,
  transactionId: number,
  eventIdText?: string | null,
  eventString?: string | null,
  raisedAt: string,
  eventJSON?: string | null,
  aggregationId?: number | null,
  unitTime?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
  omniviaSCUEventLinkedSCUIdEventId?: string | null,
};

export type ModelOmniviaSCUEventConditionInput = {
  alertGeneratedState?: ModelIntInput | null,
  eventIdText?: ModelStringInput | null,
  eventString?: ModelStringInput | null,
  eventJSON?: ModelStringInput | null,
  aggregationId?: ModelIntInput | null,
  unitTime?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expirationUnixTime?: ModelIntInput | null,
  omniviaSCUEventLinkedSCUIdEventId?: ModelStringInput | null,
  and?: Array< ModelOmniviaSCUEventConditionInput | null > | null,
  or?: Array< ModelOmniviaSCUEventConditionInput | null > | null,
  not?: ModelOmniviaSCUEventConditionInput | null,
};

export type UpdateOmniviaSCUEventInput = {
  id?: string | null,
  alertGeneratedState?: number | null,
  omniviaSCUEventLinkedSCUId: string,
  eventId: number,
  transactionId: number,
  eventIdText?: string | null,
  eventString?: string | null,
  raisedAt: string,
  eventJSON?: string | null,
  aggregationId?: number | null,
  unitTime?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
  omniviaSCUEventLinkedSCUIdEventId?: string | null,
};

export type DeleteOmniviaSCUEventInput = {
  omniviaSCUEventLinkedSCUId: string,
  raisedAt: string,
  transactionId: number,
  eventId: number,
};

export type CreateOmniviaFaultsInput = {
  id?: string | null,
  omniviaFaultsLinkedSCUId?: string | null,
  faultState: OmniviaFaultState,
  faultType: OmniviaFaultsTypes,
  dateRaised: string,
  dateCleared?: string | null,
  dateAlertLastGenerated?: string | null,
  deviceID: string,
  faultSpecificText?: string | null,
  friendlyString?: string | null,
  clearedBy?: string | null,
  associatedRoomNumber?: number | null,
  identity?: string | null,
  faultUnitClassification?: FaultUnitClassification | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
};

export type ModelOmniviaFaultsConditionInput = {
  omniviaFaultsLinkedSCUId?: ModelIDInput | null,
  faultState?: ModelOmniviaFaultStateInput | null,
  faultType?: ModelOmniviaFaultsTypesInput | null,
  dateRaised?: ModelStringInput | null,
  dateCleared?: ModelStringInput | null,
  dateAlertLastGenerated?: ModelStringInput | null,
  deviceID?: ModelStringInput | null,
  faultSpecificText?: ModelStringInput | null,
  friendlyString?: ModelStringInput | null,
  clearedBy?: ModelStringInput | null,
  associatedRoomNumber?: ModelIntInput | null,
  identity?: ModelStringInput | null,
  faultUnitClassification?: ModelFaultUnitClassificationInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expirationUnixTime?: ModelIntInput | null,
  and?: Array< ModelOmniviaFaultsConditionInput | null > | null,
  or?: Array< ModelOmniviaFaultsConditionInput | null > | null,
  not?: ModelOmniviaFaultsConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelOmniviaFaultStateInput = {
  eq?: OmniviaFaultState | null,
  ne?: OmniviaFaultState | null,
};

export type ModelOmniviaFaultsTypesInput = {
  eq?: OmniviaFaultsTypes | null,
  ne?: OmniviaFaultsTypes | null,
};

export type ModelFaultUnitClassificationInput = {
  eq?: FaultUnitClassification | null,
  ne?: FaultUnitClassification | null,
};

export type UpdateOmniviaFaultsInput = {
  id: string,
  omniviaFaultsLinkedSCUId?: string | null,
  faultState?: OmniviaFaultState | null,
  faultType?: OmniviaFaultsTypes | null,
  dateRaised?: string | null,
  dateCleared?: string | null,
  dateAlertLastGenerated?: string | null,
  deviceID?: string | null,
  faultSpecificText?: string | null,
  friendlyString?: string | null,
  clearedBy?: string | null,
  associatedRoomNumber?: number | null,
  identity?: string | null,
  faultUnitClassification?: FaultUnitClassification | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
};

export type DeleteOmniviaFaultsInput = {
  id: string,
};

export type CreateOmniviaLiveEventsLogInput = {
  id?: string | null,
  mac: string,
  omniviaSCUSerial: string,
  deviceType: number,
  expirationUnixTime: number,
  deviceId: number,
  associatedAlarmId?: number | null,
  batteryVoltageApplication?: number | null,
  handset?: HandsetChargeInfoInput | null,
  parentMac?: string | null,
  rssi_db?: number | null,
  addtionalReceiverList?: Array< ReceiverListInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type HandsetChargeInfoInput = {
  batteryVoltageNative: number,
  batteryCurrent: number,
  isCharging: boolean,
  isScreenOn: boolean,
  chargerProtectionStateOld: number,
  chargerProtectionStateNew: number,
  currentBatteryCapacity: number,
  batteryTemperature: number,
  maxChargeLvl: number,
  isChargerEnabled: boolean,
  isOvertemp: boolean,
  cycleOnTime: number,
  cycleOffTime: number,
};

export type ReceiverListInput = {
  parentMac: string,
  rssi_db: number,
};

export type ModelOmniviaLiveEventsLogConditionInput = {
  omniviaSCUSerial?: ModelStringInput | null,
  deviceType?: ModelIntInput | null,
  expirationUnixTime?: ModelIntInput | null,
  deviceId?: ModelIntInput | null,
  associatedAlarmId?: ModelIntInput | null,
  batteryVoltageApplication?: ModelIntInput | null,
  parentMac?: ModelStringInput | null,
  rssi_db?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOmniviaLiveEventsLogConditionInput | null > | null,
  or?: Array< ModelOmniviaLiveEventsLogConditionInput | null > | null,
  not?: ModelOmniviaLiveEventsLogConditionInput | null,
};

export type OmniviaLiveEventsLog = {
  __typename: "OmniviaLiveEventsLog",
  id: string,
  mac: string,
  omniviaSCUSerial: string,
  deviceType: number,
  expirationUnixTime: number,
  deviceId: number,
  associatedAlarmId?: number | null,
  batteryVoltageApplication?: number | null,
  handset?: HandsetChargeInfo | null,
  parentMac?: string | null,
  rssi_db?: number | null,
  addtionalReceiverList?:  Array<ReceiverList | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type HandsetChargeInfo = {
  __typename: "HandsetChargeInfo",
  batteryVoltageNative: number,
  batteryCurrent: number,
  isCharging: boolean,
  isScreenOn: boolean,
  chargerProtectionStateOld: number,
  chargerProtectionStateNew: number,
  currentBatteryCapacity: number,
  batteryTemperature: number,
  maxChargeLvl: number,
  isChargerEnabled: boolean,
  isOvertemp: boolean,
  cycleOnTime: number,
  cycleOffTime: number,
};

export type ReceiverList = {
  __typename: "ReceiverList",
  parentMac: string,
  rssi_db: number,
};

export type UpdateOmniviaLiveEventsLogInput = {
  id: string,
  mac: string,
  omniviaSCUSerial?: string | null,
  deviceType?: number | null,
  expirationUnixTime?: number | null,
  deviceId?: number | null,
  associatedAlarmId?: number | null,
  batteryVoltageApplication?: number | null,
  handset?: HandsetChargeInfoInput | null,
  parentMac?: string | null,
  rssi_db?: number | null,
  addtionalReceiverList?: Array< ReceiverListInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOmniviaLiveEventsLogInput = {
  id: string,
  mac: string,
};

export type CreateOmniviaBatteryLogInput = {
  id?: string | null,
  mac: string,
  omniviaSCUSerial: string,
  deviceType: number,
  expirationUnixTime: number,
  batteryVoltageApplication?: number | null,
  handset?: HandsetChargeInfoInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOmniviaBatteryLogConditionInput = {
  omniviaSCUSerial?: ModelStringInput | null,
  deviceType?: ModelIntInput | null,
  expirationUnixTime?: ModelIntInput | null,
  batteryVoltageApplication?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOmniviaBatteryLogConditionInput | null > | null,
  or?: Array< ModelOmniviaBatteryLogConditionInput | null > | null,
  not?: ModelOmniviaBatteryLogConditionInput | null,
};

export type OmniviaBatteryLog = {
  __typename: "OmniviaBatteryLog",
  id: string,
  mac: string,
  omniviaSCUSerial: string,
  deviceType: number,
  expirationUnixTime: number,
  batteryVoltageApplication?: number | null,
  handset?: HandsetChargeInfo | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateOmniviaBatteryLogInput = {
  id: string,
  mac: string,
  omniviaSCUSerial?: string | null,
  deviceType?: number | null,
  expirationUnixTime?: number | null,
  batteryVoltageApplication?: number | null,
  handset?: HandsetChargeInfoInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOmniviaBatteryLogInput = {
  id: string,
  mac: string,
};

export type CreateOmniviaLocationHistoryInput = {
  id?: string | null,
  mac: string,
  omniviaSCUSerial: string,
  deviceType: number,
  expirationUnixTime: number,
  parentMac: string,
  rssi_db?: number | null,
  addtionalReceiverList?: Array< ReceiverListInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOmniviaLocationHistoryConditionInput = {
  omniviaSCUSerial?: ModelStringInput | null,
  deviceType?: ModelIntInput | null,
  expirationUnixTime?: ModelIntInput | null,
  parentMac?: ModelStringInput | null,
  rssi_db?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOmniviaLocationHistoryConditionInput | null > | null,
  or?: Array< ModelOmniviaLocationHistoryConditionInput | null > | null,
  not?: ModelOmniviaLocationHistoryConditionInput | null,
};

export type OmniviaLocationHistory = {
  __typename: "OmniviaLocationHistory",
  id: string,
  mac: string,
  omniviaSCUSerial: string,
  deviceType: number,
  expirationUnixTime: number,
  parentMac: string,
  rssi_db?: number | null,
  addtionalReceiverList?:  Array<ReceiverList | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateOmniviaLocationHistoryInput = {
  id: string,
  mac: string,
  omniviaSCUSerial?: string | null,
  deviceType?: number | null,
  expirationUnixTime?: number | null,
  parentMac?: string | null,
  rssi_db?: number | null,
  addtionalReceiverList?: Array< ReceiverListInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOmniviaLocationHistoryInput = {
  id: string,
  mac: string,
};

export enum S3URLType {
  getObject = "getObject",
  putObject = "putObject",
}


export type Tokens = {
  __typename: "Tokens",
  maker: string,
  biblio: string,
};

export type ModelOmniviaClientFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  addr1?: ModelStringInput | null,
  addr2?: ModelStringInput | null,
  addr3?: ModelStringInput | null,
  city?: ModelStringInput | null,
  county?: ModelStringInput | null,
  country?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactTelephone?: ModelStringInput | null,
  timeZone?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  _deleted?: ModelBooleanInput | null,
  and?: Array< ModelOmniviaClientFilterInput | null > | null,
  or?: Array< ModelOmniviaClientFilterInput | null > | null,
  not?: ModelOmniviaClientFilterInput | null,
};

export type ModelOmniviaClientConnection = {
  __typename: "ModelOmniviaClientConnection",
  items:  Array<OmniviaClient | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelOmniviaSiteFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  addr1?: ModelStringInput | null,
  addr2?: ModelStringInput | null,
  addr3?: ModelStringInput | null,
  city?: ModelStringInput | null,
  county?: ModelStringInput | null,
  country?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactTelephone?: ModelStringInput | null,
  timeZone?: ModelStringInput | null,
  maintenanceEmail?: ModelStringInput | null,
  pollFailEmail?: ModelStringInput | null,
  stuckAlarmMinAge?: ModelIntInput | null,
  stuckAlarmMaxAge?: ModelIntInput | null,
  stuckAlarmMinTimeBetweenSMS?: ModelIntInput | null,
  stuckAlarmLastAlert?: ModelStringInput | null,
  siteAlertsEnabled?: ModelBooleanInput | null,
  brand?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  floorPlanFolder?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  _deleted?: ModelBooleanInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelOmniviaSiteFilterInput | null > | null,
  or?: Array< ModelOmniviaSiteFilterInput | null > | null,
  not?: ModelOmniviaSiteFilterInput | null,
};

export type ModelOmniviaSCUFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  serialNumber?: ModelStringInput | null,
  localEthernetIP?: ModelStringInput | null,
  localWiFiIP?: ModelStringInput | null,
  ethernetMAC?: ModelStringInput | null,
  wifiMAC?: ModelStringInput | null,
  softwareVersion?: ModelStringInput | null,
  mainOutgoingPOTSNumber?: ModelStringInput | null,
  backupOutgoingPOTSNumber?: ModelStringInput | null,
  remoteAccessPort?: ModelIntInput | null,
  productNumber?: ModelStringInput | null,
  lastSeen?: ModelStringInput | null,
  unitTime?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  _deleted?: ModelBooleanInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelOmniviaSCUFilterInput | null > | null,
  or?: Array< ModelOmniviaSCUFilterInput | null > | null,
  not?: ModelOmniviaSCUFilterInput | null,
};

export type ModelOmniviaSCUEventPrimaryCompositeKeyConditionInput = {
  eq?: ModelOmniviaSCUEventPrimaryCompositeKeyInput | null,
  le?: ModelOmniviaSCUEventPrimaryCompositeKeyInput | null,
  lt?: ModelOmniviaSCUEventPrimaryCompositeKeyInput | null,
  ge?: ModelOmniviaSCUEventPrimaryCompositeKeyInput | null,
  gt?: ModelOmniviaSCUEventPrimaryCompositeKeyInput | null,
  between?: Array< ModelOmniviaSCUEventPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelOmniviaSCUEventPrimaryCompositeKeyInput | null,
};

export type ModelOmniviaSCUEventPrimaryCompositeKeyInput = {
  raisedAt?: string | null,
  transactionId?: number | null,
  eventId?: number | null,
};

export type ModelOmniviaSCUEventFilterInput = {
  id?: ModelIDInput | null,
  alertGeneratedState?: ModelIntInput | null,
  omniviaSCUEventLinkedSCUId?: ModelIDInput | null,
  eventId?: ModelIntInput | null,
  transactionId?: ModelIntInput | null,
  eventIdText?: ModelStringInput | null,
  eventString?: ModelStringInput | null,
  raisedAt?: ModelStringInput | null,
  eventJSON?: ModelStringInput | null,
  aggregationId?: ModelIntInput | null,
  unitTime?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expirationUnixTime?: ModelIntInput | null,
  omniviaSCUEventLinkedSCUIdEventId?: ModelStringInput | null,
  and?: Array< ModelOmniviaSCUEventFilterInput | null > | null,
  or?: Array< ModelOmniviaSCUEventFilterInput | null > | null,
  not?: ModelOmniviaSCUEventFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelOmniviaFaultsFilterInput = {
  id?: ModelIDInput | null,
  omniviaFaultsLinkedSCUId?: ModelIDInput | null,
  faultState?: ModelOmniviaFaultStateInput | null,
  faultType?: ModelOmniviaFaultsTypesInput | null,
  dateRaised?: ModelStringInput | null,
  dateCleared?: ModelStringInput | null,
  dateAlertLastGenerated?: ModelStringInput | null,
  deviceID?: ModelStringInput | null,
  faultSpecificText?: ModelStringInput | null,
  friendlyString?: ModelStringInput | null,
  clearedBy?: ModelStringInput | null,
  associatedRoomNumber?: ModelIntInput | null,
  identity?: ModelStringInput | null,
  faultUnitClassification?: ModelFaultUnitClassificationInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expirationUnixTime?: ModelIntInput | null,
  and?: Array< ModelOmniviaFaultsFilterInput | null > | null,
  or?: Array< ModelOmniviaFaultsFilterInput | null > | null,
  not?: ModelOmniviaFaultsFilterInput | null,
};

export type ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyConditionInput = {
  eq?: ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyInput | null,
  le?: ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyInput | null,
  lt?: ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyInput | null,
  ge?: ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyInput | null,
  gt?: ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyInput | null,
  between?: Array< ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyInput | null > | null,
  beginsWith?: ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyInput | null,
};

export type ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyInput = {
  faultType?: OmniviaFaultsTypes | null,
  faultState?: OmniviaFaultState | null,
  deviceID?: string | null,
};

export type ModelOmniviaLiveEventsLogFilterInput = {
  id?: ModelIDInput | null,
  mac?: ModelStringInput | null,
  omniviaSCUSerial?: ModelStringInput | null,
  deviceType?: ModelIntInput | null,
  expirationUnixTime?: ModelIntInput | null,
  deviceId?: ModelIntInput | null,
  associatedAlarmId?: ModelIntInput | null,
  batteryVoltageApplication?: ModelIntInput | null,
  parentMac?: ModelStringInput | null,
  rssi_db?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOmniviaLiveEventsLogFilterInput | null > | null,
  or?: Array< ModelOmniviaLiveEventsLogFilterInput | null > | null,
  not?: ModelOmniviaLiveEventsLogFilterInput | null,
};

export type ModelOmniviaLiveEventsLogConnection = {
  __typename: "ModelOmniviaLiveEventsLogConnection",
  items:  Array<OmniviaLiveEventsLog | null >,
  nextToken?: string | null,
};

export type ModelOmniviaBatteryLogFilterInput = {
  id?: ModelIDInput | null,
  mac?: ModelStringInput | null,
  omniviaSCUSerial?: ModelStringInput | null,
  deviceType?: ModelIntInput | null,
  expirationUnixTime?: ModelIntInput | null,
  batteryVoltageApplication?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOmniviaBatteryLogFilterInput | null > | null,
  or?: Array< ModelOmniviaBatteryLogFilterInput | null > | null,
  not?: ModelOmniviaBatteryLogFilterInput | null,
};

export type ModelOmniviaBatteryLogConnection = {
  __typename: "ModelOmniviaBatteryLogConnection",
  items:  Array<OmniviaBatteryLog | null >,
  nextToken?: string | null,
};

export type ModelOmniviaLocationHistoryFilterInput = {
  id?: ModelIDInput | null,
  mac?: ModelStringInput | null,
  omniviaSCUSerial?: ModelStringInput | null,
  deviceType?: ModelIntInput | null,
  expirationUnixTime?: ModelIntInput | null,
  parentMac?: ModelStringInput | null,
  rssi_db?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOmniviaLocationHistoryFilterInput | null > | null,
  or?: Array< ModelOmniviaLocationHistoryFilterInput | null > | null,
  not?: ModelOmniviaLocationHistoryFilterInput | null,
};

export type ModelOmniviaLocationHistoryConnection = {
  __typename: "ModelOmniviaLocationHistoryConnection",
  items:  Array<OmniviaLocationHistory | null >,
  nextToken?: string | null,
};

export type UpdateGroupMutationVariables = {
  groupName: string,
  description: string,
};

export type UpdateGroupMutation = {
  updateGroup?: string | null,
};

export type DeleteGroupMutationVariables = {
  groupName: string,
};

export type DeleteGroupMutation = {
  deleteGroup?: string | null,
};

export type CreateGroupMutationVariables = {
  groupName: string,
  description: string,
};

export type CreateGroupMutation = {
  createGroup?: string | null,
};

export type AuthoriseUserIotPoliciesMutationVariables = {
  username: string,
};

export type AuthoriseUserIotPoliciesMutation = {
  authoriseUserIotPolicies?: string | null,
};

export type UpdateUserAttributeMutationVariables = {
  username: string,
  key: string,
  value: string,
};

export type UpdateUserAttributeMutation = {
  updateUserAttribute?: string | null,
};

export type CreateUserMutationVariables = {
  fullname: string,
  email: string,
  password: string,
};

export type CreateUserMutation = {
  createUser: string,
};

export type DeleteUserMutationVariables = {
  username: string,
};

export type DeleteUserMutation = {
  deleteUser: string,
};

export type SetUserPasswdMutationVariables = {
  username: string,
  password: string,
  permanent: boolean,
};

export type SetUserPasswdMutation = {
  setUserPasswd: string,
};

export type SendEmailMutationVariables = {
  to?: string | null,
  bcc?: string | null,
  subject: string,
  text?: string | null,
  html?: string | null,
};

export type SendEmailMutation = {
  sendEmail: string,
};

export type CreateOmniviaClientMutationVariables = {
  input: CreateOmniviaClientInput,
  condition?: ModelOmniviaClientConditionInput | null,
};

export type CreateOmniviaClientMutation = {
  createOmniviaClient?:  {
    __typename: "OmniviaClient",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone?: string | null,
    brand?: string | null,
    tags?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    linkedSites?:  {
      __typename: "ModelOmniviaSiteConnection",
      items:  Array< {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOmniviaClientMutationVariables = {
  input: UpdateOmniviaClientInput,
  condition?: ModelOmniviaClientConditionInput | null,
};

export type UpdateOmniviaClientMutation = {
  updateOmniviaClient?:  {
    __typename: "OmniviaClient",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone?: string | null,
    brand?: string | null,
    tags?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    linkedSites?:  {
      __typename: "ModelOmniviaSiteConnection",
      items:  Array< {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOmniviaClientMutationVariables = {
  input: DeleteOmniviaClientInput,
  condition?: ModelOmniviaClientConditionInput | null,
};

export type DeleteOmniviaClientMutation = {
  deleteOmniviaClient?:  {
    __typename: "OmniviaClient",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone?: string | null,
    brand?: string | null,
    tags?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    linkedSites?:  {
      __typename: "ModelOmniviaSiteConnection",
      items:  Array< {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOmniviaSiteMutationVariables = {
  input: CreateOmniviaSiteInput,
  condition?: ModelOmniviaSiteConditionInput | null,
};

export type CreateOmniviaSiteMutation = {
  createOmniviaSite?:  {
    __typename: "OmniviaSite",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone: string,
    maintenanceEmail?: string | null,
    pollFailEmail?: string | null,
    stuckAlarmMinAge?: number | null,
    stuckAlarmMaxAge?: number | null,
    stuckAlarmMinTimeBetweenSMS?: number | null,
    stuckAlarmLastAlert?: string | null,
    siteAlertsEnabled?: boolean | null,
    brand?: string | null,
    tags?: string | null,
    floorPlanFolder?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string > | null,
    linkedClient?:  {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedSCUs?:  {
      __typename: "ModelOmniviaSCUConnection",
      items:  Array< {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOmniviaSiteMutationVariables = {
  input: UpdateOmniviaSiteInput,
  condition?: ModelOmniviaSiteConditionInput | null,
};

export type UpdateOmniviaSiteMutation = {
  updateOmniviaSite?:  {
    __typename: "OmniviaSite",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone: string,
    maintenanceEmail?: string | null,
    pollFailEmail?: string | null,
    stuckAlarmMinAge?: number | null,
    stuckAlarmMaxAge?: number | null,
    stuckAlarmMinTimeBetweenSMS?: number | null,
    stuckAlarmLastAlert?: string | null,
    siteAlertsEnabled?: boolean | null,
    brand?: string | null,
    tags?: string | null,
    floorPlanFolder?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string > | null,
    linkedClient?:  {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedSCUs?:  {
      __typename: "ModelOmniviaSCUConnection",
      items:  Array< {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOmniviaSiteMutationVariables = {
  input: DeleteOmniviaSiteInput,
  condition?: ModelOmniviaSiteConditionInput | null,
};

export type DeleteOmniviaSiteMutation = {
  deleteOmniviaSite?:  {
    __typename: "OmniviaSite",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone: string,
    maintenanceEmail?: string | null,
    pollFailEmail?: string | null,
    stuckAlarmMinAge?: number | null,
    stuckAlarmMaxAge?: number | null,
    stuckAlarmMinTimeBetweenSMS?: number | null,
    stuckAlarmLastAlert?: string | null,
    siteAlertsEnabled?: boolean | null,
    brand?: string | null,
    tags?: string | null,
    floorPlanFolder?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string > | null,
    linkedClient?:  {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedSCUs?:  {
      __typename: "ModelOmniviaSCUConnection",
      items:  Array< {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOmniviaSCUMutationVariables = {
  input: CreateOmniviaSCUInput,
  condition?: ModelOmniviaSCUConditionInput | null,
};

export type CreateOmniviaSCUMutation = {
  createOmniviaSCU?:  {
    __typename: "OmniviaSCU",
    id: string,
    name: string,
    description?: string | null,
    serialNumber: string,
    localEthernetIP?: string | null,
    localWiFiIP?: string | null,
    ethernetMAC?: string | null,
    wifiMAC?: string | null,
    softwareVersion?: string | null,
    mainOutgoingPOTSNumber: string,
    backupOutgoingPOTSNumber: string,
    remoteAccessPort?: number | null,
    productNumber?: string | null,
    lastSeen?: string | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string | null > | null,
    linkedSite?:  {
      __typename: "OmniviaSite",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone: string,
      maintenanceEmail?: string | null,
      pollFailEmail?: string | null,
      stuckAlarmMinAge?: number | null,
      stuckAlarmMaxAge?: number | null,
      stuckAlarmMinTimeBetweenSMS?: number | null,
      stuckAlarmLastAlert?: string | null,
      siteAlertsEnabled?: boolean | null,
      brand?: string | null,
      tags?: string | null,
      floorPlanFolder?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string > | null,
      linkedClient?:  {
        __typename: "OmniviaClient",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone?: string | null,
        brand?: string | null,
        tags?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        linkedSites?:  {
          __typename: "ModelOmniviaSiteConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedSCUs?:  {
        __typename: "ModelOmniviaSCUConnection",
        items:  Array< {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedEvents?:  {
      __typename: "ModelOmniviaSCUEventConnection",
      items:  Array< {
        __typename: "OmniviaSCUEvent",
        id: string,
        alertGeneratedState: number,
        omniviaSCUEventLinkedSCUId: string,
        eventId: number,
        transactionId: number,
        eventIdText?: string | null,
        eventString?: string | null,
        raisedAt: string,
        eventJSON?: string | null,
        aggregationId?: number | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        omniviaSCUEventLinkedSCUIdEventId?: string | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedFaults?:  {
      __typename: "ModelOmniviaFaultsConnection",
      items:  Array< {
        __typename: "OmniviaFaults",
        id: string,
        omniviaFaultsLinkedSCUId?: string | null,
        faultState: OmniviaFaultState,
        faultType: OmniviaFaultsTypes,
        dateRaised: string,
        dateCleared?: string | null,
        dateAlertLastGenerated?: string | null,
        deviceID: string,
        faultSpecificText?: string | null,
        friendlyString?: string | null,
        clearedBy?: string | null,
        associatedRoomNumber?: number | null,
        identity?: string | null,
        faultUnitClassification?: FaultUnitClassification | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOmniviaSCUMutationVariables = {
  input: UpdateOmniviaSCUInput,
  condition?: ModelOmniviaSCUConditionInput | null,
};

export type UpdateOmniviaSCUMutation = {
  updateOmniviaSCU?:  {
    __typename: "OmniviaSCU",
    id: string,
    name: string,
    description?: string | null,
    serialNumber: string,
    localEthernetIP?: string | null,
    localWiFiIP?: string | null,
    ethernetMAC?: string | null,
    wifiMAC?: string | null,
    softwareVersion?: string | null,
    mainOutgoingPOTSNumber: string,
    backupOutgoingPOTSNumber: string,
    remoteAccessPort?: number | null,
    productNumber?: string | null,
    lastSeen?: string | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string | null > | null,
    linkedSite?:  {
      __typename: "OmniviaSite",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone: string,
      maintenanceEmail?: string | null,
      pollFailEmail?: string | null,
      stuckAlarmMinAge?: number | null,
      stuckAlarmMaxAge?: number | null,
      stuckAlarmMinTimeBetweenSMS?: number | null,
      stuckAlarmLastAlert?: string | null,
      siteAlertsEnabled?: boolean | null,
      brand?: string | null,
      tags?: string | null,
      floorPlanFolder?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string > | null,
      linkedClient?:  {
        __typename: "OmniviaClient",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone?: string | null,
        brand?: string | null,
        tags?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        linkedSites?:  {
          __typename: "ModelOmniviaSiteConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedSCUs?:  {
        __typename: "ModelOmniviaSCUConnection",
        items:  Array< {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedEvents?:  {
      __typename: "ModelOmniviaSCUEventConnection",
      items:  Array< {
        __typename: "OmniviaSCUEvent",
        id: string,
        alertGeneratedState: number,
        omniviaSCUEventLinkedSCUId: string,
        eventId: number,
        transactionId: number,
        eventIdText?: string | null,
        eventString?: string | null,
        raisedAt: string,
        eventJSON?: string | null,
        aggregationId?: number | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        omniviaSCUEventLinkedSCUIdEventId?: string | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedFaults?:  {
      __typename: "ModelOmniviaFaultsConnection",
      items:  Array< {
        __typename: "OmniviaFaults",
        id: string,
        omniviaFaultsLinkedSCUId?: string | null,
        faultState: OmniviaFaultState,
        faultType: OmniviaFaultsTypes,
        dateRaised: string,
        dateCleared?: string | null,
        dateAlertLastGenerated?: string | null,
        deviceID: string,
        faultSpecificText?: string | null,
        friendlyString?: string | null,
        clearedBy?: string | null,
        associatedRoomNumber?: number | null,
        identity?: string | null,
        faultUnitClassification?: FaultUnitClassification | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOmniviaSCUMutationVariables = {
  input: DeleteOmniviaSCUInput,
  condition?: ModelOmniviaSCUConditionInput | null,
};

export type DeleteOmniviaSCUMutation = {
  deleteOmniviaSCU?:  {
    __typename: "OmniviaSCU",
    id: string,
    name: string,
    description?: string | null,
    serialNumber: string,
    localEthernetIP?: string | null,
    localWiFiIP?: string | null,
    ethernetMAC?: string | null,
    wifiMAC?: string | null,
    softwareVersion?: string | null,
    mainOutgoingPOTSNumber: string,
    backupOutgoingPOTSNumber: string,
    remoteAccessPort?: number | null,
    productNumber?: string | null,
    lastSeen?: string | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string | null > | null,
    linkedSite?:  {
      __typename: "OmniviaSite",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone: string,
      maintenanceEmail?: string | null,
      pollFailEmail?: string | null,
      stuckAlarmMinAge?: number | null,
      stuckAlarmMaxAge?: number | null,
      stuckAlarmMinTimeBetweenSMS?: number | null,
      stuckAlarmLastAlert?: string | null,
      siteAlertsEnabled?: boolean | null,
      brand?: string | null,
      tags?: string | null,
      floorPlanFolder?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string > | null,
      linkedClient?:  {
        __typename: "OmniviaClient",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone?: string | null,
        brand?: string | null,
        tags?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        linkedSites?:  {
          __typename: "ModelOmniviaSiteConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedSCUs?:  {
        __typename: "ModelOmniviaSCUConnection",
        items:  Array< {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedEvents?:  {
      __typename: "ModelOmniviaSCUEventConnection",
      items:  Array< {
        __typename: "OmniviaSCUEvent",
        id: string,
        alertGeneratedState: number,
        omniviaSCUEventLinkedSCUId: string,
        eventId: number,
        transactionId: number,
        eventIdText?: string | null,
        eventString?: string | null,
        raisedAt: string,
        eventJSON?: string | null,
        aggregationId?: number | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        omniviaSCUEventLinkedSCUIdEventId?: string | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedFaults?:  {
      __typename: "ModelOmniviaFaultsConnection",
      items:  Array< {
        __typename: "OmniviaFaults",
        id: string,
        omniviaFaultsLinkedSCUId?: string | null,
        faultState: OmniviaFaultState,
        faultType: OmniviaFaultsTypes,
        dateRaised: string,
        dateCleared?: string | null,
        dateAlertLastGenerated?: string | null,
        deviceID: string,
        faultSpecificText?: string | null,
        friendlyString?: string | null,
        clearedBy?: string | null,
        associatedRoomNumber?: number | null,
        identity?: string | null,
        faultUnitClassification?: FaultUnitClassification | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOmniviaSCUEventMutationVariables = {
  input: CreateOmniviaSCUEventInput,
  condition?: ModelOmniviaSCUEventConditionInput | null,
};

export type CreateOmniviaSCUEventMutation = {
  createOmniviaSCUEvent?:  {
    __typename: "OmniviaSCUEvent",
    id: string,
    alertGeneratedState: number,
    omniviaSCUEventLinkedSCUId: string,
    eventId: number,
    transactionId: number,
    eventIdText?: string | null,
    eventString?: string | null,
    raisedAt: string,
    eventJSON?: string | null,
    aggregationId?: number | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    omniviaSCUEventLinkedSCUIdEventId?: string | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOmniviaSCUEventMutationVariables = {
  input: UpdateOmniviaSCUEventInput,
  condition?: ModelOmniviaSCUEventConditionInput | null,
};

export type UpdateOmniviaSCUEventMutation = {
  updateOmniviaSCUEvent?:  {
    __typename: "OmniviaSCUEvent",
    id: string,
    alertGeneratedState: number,
    omniviaSCUEventLinkedSCUId: string,
    eventId: number,
    transactionId: number,
    eventIdText?: string | null,
    eventString?: string | null,
    raisedAt: string,
    eventJSON?: string | null,
    aggregationId?: number | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    omniviaSCUEventLinkedSCUIdEventId?: string | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOmniviaSCUEventMutationVariables = {
  input: DeleteOmniviaSCUEventInput,
  condition?: ModelOmniviaSCUEventConditionInput | null,
};

export type DeleteOmniviaSCUEventMutation = {
  deleteOmniviaSCUEvent?:  {
    __typename: "OmniviaSCUEvent",
    id: string,
    alertGeneratedState: number,
    omniviaSCUEventLinkedSCUId: string,
    eventId: number,
    transactionId: number,
    eventIdText?: string | null,
    eventString?: string | null,
    raisedAt: string,
    eventJSON?: string | null,
    aggregationId?: number | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    omniviaSCUEventLinkedSCUIdEventId?: string | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOmniviaFaultsMutationVariables = {
  input: CreateOmniviaFaultsInput,
  condition?: ModelOmniviaFaultsConditionInput | null,
};

export type CreateOmniviaFaultsMutation = {
  createOmniviaFaults?:  {
    __typename: "OmniviaFaults",
    id: string,
    omniviaFaultsLinkedSCUId?: string | null,
    faultState: OmniviaFaultState,
    faultType: OmniviaFaultsTypes,
    dateRaised: string,
    dateCleared?: string | null,
    dateAlertLastGenerated?: string | null,
    deviceID: string,
    faultSpecificText?: string | null,
    friendlyString?: string | null,
    clearedBy?: string | null,
    associatedRoomNumber?: number | null,
    identity?: string | null,
    faultUnitClassification?: FaultUnitClassification | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOmniviaFaultsMutationVariables = {
  input: UpdateOmniviaFaultsInput,
  condition?: ModelOmniviaFaultsConditionInput | null,
};

export type UpdateOmniviaFaultsMutation = {
  updateOmniviaFaults?:  {
    __typename: "OmniviaFaults",
    id: string,
    omniviaFaultsLinkedSCUId?: string | null,
    faultState: OmniviaFaultState,
    faultType: OmniviaFaultsTypes,
    dateRaised: string,
    dateCleared?: string | null,
    dateAlertLastGenerated?: string | null,
    deviceID: string,
    faultSpecificText?: string | null,
    friendlyString?: string | null,
    clearedBy?: string | null,
    associatedRoomNumber?: number | null,
    identity?: string | null,
    faultUnitClassification?: FaultUnitClassification | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOmniviaFaultsMutationVariables = {
  input: DeleteOmniviaFaultsInput,
  condition?: ModelOmniviaFaultsConditionInput | null,
};

export type DeleteOmniviaFaultsMutation = {
  deleteOmniviaFaults?:  {
    __typename: "OmniviaFaults",
    id: string,
    omniviaFaultsLinkedSCUId?: string | null,
    faultState: OmniviaFaultState,
    faultType: OmniviaFaultsTypes,
    dateRaised: string,
    dateCleared?: string | null,
    dateAlertLastGenerated?: string | null,
    deviceID: string,
    faultSpecificText?: string | null,
    friendlyString?: string | null,
    clearedBy?: string | null,
    associatedRoomNumber?: number | null,
    identity?: string | null,
    faultUnitClassification?: FaultUnitClassification | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOmniviaLiveEventsLogMutationVariables = {
  input: CreateOmniviaLiveEventsLogInput,
  condition?: ModelOmniviaLiveEventsLogConditionInput | null,
};

export type CreateOmniviaLiveEventsLogMutation = {
  createOmniviaLiveEventsLog?:  {
    __typename: "OmniviaLiveEventsLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    deviceId: number,
    associatedAlarmId?: number | null,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    parentMac?: string | null,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateOmniviaLiveEventsLogMutationVariables = {
  input: UpdateOmniviaLiveEventsLogInput,
  condition?: ModelOmniviaLiveEventsLogConditionInput | null,
};

export type UpdateOmniviaLiveEventsLogMutation = {
  updateOmniviaLiveEventsLog?:  {
    __typename: "OmniviaLiveEventsLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    deviceId: number,
    associatedAlarmId?: number | null,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    parentMac?: string | null,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteOmniviaLiveEventsLogMutationVariables = {
  input: DeleteOmniviaLiveEventsLogInput,
  condition?: ModelOmniviaLiveEventsLogConditionInput | null,
};

export type DeleteOmniviaLiveEventsLogMutation = {
  deleteOmniviaLiveEventsLog?:  {
    __typename: "OmniviaLiveEventsLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    deviceId: number,
    associatedAlarmId?: number | null,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    parentMac?: string | null,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateOmniviaBatteryLogMutationVariables = {
  input: CreateOmniviaBatteryLogInput,
  condition?: ModelOmniviaBatteryLogConditionInput | null,
};

export type CreateOmniviaBatteryLogMutation = {
  createOmniviaBatteryLog?:  {
    __typename: "OmniviaBatteryLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateOmniviaBatteryLogMutationVariables = {
  input: UpdateOmniviaBatteryLogInput,
  condition?: ModelOmniviaBatteryLogConditionInput | null,
};

export type UpdateOmniviaBatteryLogMutation = {
  updateOmniviaBatteryLog?:  {
    __typename: "OmniviaBatteryLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteOmniviaBatteryLogMutationVariables = {
  input: DeleteOmniviaBatteryLogInput,
  condition?: ModelOmniviaBatteryLogConditionInput | null,
};

export type DeleteOmniviaBatteryLogMutation = {
  deleteOmniviaBatteryLog?:  {
    __typename: "OmniviaBatteryLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateOmniviaLocationHistoryMutationVariables = {
  input: CreateOmniviaLocationHistoryInput,
  condition?: ModelOmniviaLocationHistoryConditionInput | null,
};

export type CreateOmniviaLocationHistoryMutation = {
  createOmniviaLocationHistory?:  {
    __typename: "OmniviaLocationHistory",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    parentMac: string,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateOmniviaLocationHistoryMutationVariables = {
  input: UpdateOmniviaLocationHistoryInput,
  condition?: ModelOmniviaLocationHistoryConditionInput | null,
};

export type UpdateOmniviaLocationHistoryMutation = {
  updateOmniviaLocationHistory?:  {
    __typename: "OmniviaLocationHistory",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    parentMac: string,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteOmniviaLocationHistoryMutationVariables = {
  input: DeleteOmniviaLocationHistoryInput,
  condition?: ModelOmniviaLocationHistoryConditionInput | null,
};

export type DeleteOmniviaLocationHistoryMutation = {
  deleteOmniviaLocationHistory?:  {
    __typename: "OmniviaLocationHistory",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    parentMac: string,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type GetSignedUrl_get_or_putQueryVariables = {
  accessType: S3URLType,
  filename: string,
  expirePeriod: number,
};

export type GetSignedUrl_get_or_putQuery = {
  getSignedUrl_get_or_put?: string | null,
};

export type GenerateCallReportQueryVariables = {
  scuId: string,
  siteName: string,
  reportDurationDays: number,
  email: string,
};

export type GenerateCallReportQuery = {
  generateCallReport: string,
};

export type GenerateAccessTokensQueryVariables = {
  notUsed?: string | null,
};

export type GenerateAccessTokensQuery = {
  generateAccessTokens:  {
    __typename: "Tokens",
    maker: string,
    biblio: string,
  },
};

export type GetOmniviaClientQueryVariables = {
  id: string,
};

export type GetOmniviaClientQuery = {
  getOmniviaClient?:  {
    __typename: "OmniviaClient",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone?: string | null,
    brand?: string | null,
    tags?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    linkedSites?:  {
      __typename: "ModelOmniviaSiteConnection",
      items:  Array< {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOmniviaClientsQueryVariables = {
  filter?: ModelOmniviaClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOmniviaClientsQuery = {
  listOmniviaClients?:  {
    __typename: "ModelOmniviaClientConnection",
    items:  Array< {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClientByBrandQueryVariables = {
  brand?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClientByBrandQuery = {
  clientByBrand?:  {
    __typename: "ModelOmniviaClientConnection",
    items:  Array< {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOmniviaSiteQueryVariables = {
  id: string,
};

export type GetOmniviaSiteQuery = {
  getOmniviaSite?:  {
    __typename: "OmniviaSite",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone: string,
    maintenanceEmail?: string | null,
    pollFailEmail?: string | null,
    stuckAlarmMinAge?: number | null,
    stuckAlarmMaxAge?: number | null,
    stuckAlarmMinTimeBetweenSMS?: number | null,
    stuckAlarmLastAlert?: string | null,
    siteAlertsEnabled?: boolean | null,
    brand?: string | null,
    tags?: string | null,
    floorPlanFolder?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string > | null,
    linkedClient?:  {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedSCUs?:  {
      __typename: "ModelOmniviaSCUConnection",
      items:  Array< {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOmniviaSitesQueryVariables = {
  id?: string | null,
  filter?: ModelOmniviaSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOmniviaSitesQuery = {
  listOmniviaSites?:  {
    __typename: "ModelOmniviaSiteConnection",
    items:  Array< {
      __typename: "OmniviaSite",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone: string,
      maintenanceEmail?: string | null,
      pollFailEmail?: string | null,
      stuckAlarmMinAge?: number | null,
      stuckAlarmMaxAge?: number | null,
      stuckAlarmMinTimeBetweenSMS?: number | null,
      stuckAlarmLastAlert?: string | null,
      siteAlertsEnabled?: boolean | null,
      brand?: string | null,
      tags?: string | null,
      floorPlanFolder?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string > | null,
      linkedClient?:  {
        __typename: "OmniviaClient",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone?: string | null,
        brand?: string | null,
        tags?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        linkedSites?:  {
          __typename: "ModelOmniviaSiteConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedSCUs?:  {
        __typename: "ModelOmniviaSCUConnection",
        items:  Array< {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SiteByNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SiteByNameQuery = {
  siteByName?:  {
    __typename: "ModelOmniviaSiteConnection",
    items:  Array< {
      __typename: "OmniviaSite",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone: string,
      maintenanceEmail?: string | null,
      pollFailEmail?: string | null,
      stuckAlarmMinAge?: number | null,
      stuckAlarmMaxAge?: number | null,
      stuckAlarmMinTimeBetweenSMS?: number | null,
      stuckAlarmLastAlert?: string | null,
      siteAlertsEnabled?: boolean | null,
      brand?: string | null,
      tags?: string | null,
      floorPlanFolder?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string > | null,
      linkedClient?:  {
        __typename: "OmniviaClient",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone?: string | null,
        brand?: string | null,
        tags?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        linkedSites?:  {
          __typename: "ModelOmniviaSiteConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedSCUs?:  {
        __typename: "ModelOmniviaSCUConnection",
        items:  Array< {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SiteByBrandQueryVariables = {
  brand?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SiteByBrandQuery = {
  siteByBrand?:  {
    __typename: "ModelOmniviaSiteConnection",
    items:  Array< {
      __typename: "OmniviaSite",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone: string,
      maintenanceEmail?: string | null,
      pollFailEmail?: string | null,
      stuckAlarmMinAge?: number | null,
      stuckAlarmMaxAge?: number | null,
      stuckAlarmMinTimeBetweenSMS?: number | null,
      stuckAlarmLastAlert?: string | null,
      siteAlertsEnabled?: boolean | null,
      brand?: string | null,
      tags?: string | null,
      floorPlanFolder?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string > | null,
      linkedClient?:  {
        __typename: "OmniviaClient",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone?: string | null,
        brand?: string | null,
        tags?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        linkedSites?:  {
          __typename: "ModelOmniviaSiteConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedSCUs?:  {
        __typename: "ModelOmniviaSCUConnection",
        items:  Array< {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOmniviaSCUQueryVariables = {
  id: string,
};

export type GetOmniviaSCUQuery = {
  getOmniviaSCU?:  {
    __typename: "OmniviaSCU",
    id: string,
    name: string,
    description?: string | null,
    serialNumber: string,
    localEthernetIP?: string | null,
    localWiFiIP?: string | null,
    ethernetMAC?: string | null,
    wifiMAC?: string | null,
    softwareVersion?: string | null,
    mainOutgoingPOTSNumber: string,
    backupOutgoingPOTSNumber: string,
    remoteAccessPort?: number | null,
    productNumber?: string | null,
    lastSeen?: string | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string | null > | null,
    linkedSite?:  {
      __typename: "OmniviaSite",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone: string,
      maintenanceEmail?: string | null,
      pollFailEmail?: string | null,
      stuckAlarmMinAge?: number | null,
      stuckAlarmMaxAge?: number | null,
      stuckAlarmMinTimeBetweenSMS?: number | null,
      stuckAlarmLastAlert?: string | null,
      siteAlertsEnabled?: boolean | null,
      brand?: string | null,
      tags?: string | null,
      floorPlanFolder?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string > | null,
      linkedClient?:  {
        __typename: "OmniviaClient",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone?: string | null,
        brand?: string | null,
        tags?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        linkedSites?:  {
          __typename: "ModelOmniviaSiteConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedSCUs?:  {
        __typename: "ModelOmniviaSCUConnection",
        items:  Array< {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedEvents?:  {
      __typename: "ModelOmniviaSCUEventConnection",
      items:  Array< {
        __typename: "OmniviaSCUEvent",
        id: string,
        alertGeneratedState: number,
        omniviaSCUEventLinkedSCUId: string,
        eventId: number,
        transactionId: number,
        eventIdText?: string | null,
        eventString?: string | null,
        raisedAt: string,
        eventJSON?: string | null,
        aggregationId?: number | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        omniviaSCUEventLinkedSCUIdEventId?: string | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedFaults?:  {
      __typename: "ModelOmniviaFaultsConnection",
      items:  Array< {
        __typename: "OmniviaFaults",
        id: string,
        omniviaFaultsLinkedSCUId?: string | null,
        faultState: OmniviaFaultState,
        faultType: OmniviaFaultsTypes,
        dateRaised: string,
        dateCleared?: string | null,
        dateAlertLastGenerated?: string | null,
        deviceID: string,
        faultSpecificText?: string | null,
        friendlyString?: string | null,
        clearedBy?: string | null,
        associatedRoomNumber?: number | null,
        identity?: string | null,
        faultUnitClassification?: FaultUnitClassification | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOmniviaSCUSQueryVariables = {
  filter?: ModelOmniviaSCUFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOmniviaSCUSQuery = {
  listOmniviaSCUS?:  {
    __typename: "ModelOmniviaSCUConnection",
    items:  Array< {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ScuByIDQueryVariables = {
  id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSCUFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ScuByIDQuery = {
  scuByID?:  {
    __typename: "ModelOmniviaSCUConnection",
    items:  Array< {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ScuBySerialNumberQueryVariables = {
  serialNumber?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSCUFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ScuBySerialNumberQuery = {
  scuBySerialNumber?:  {
    __typename: "ModelOmniviaSCUConnection",
    items:  Array< {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ScuByMainTelephoneQueryVariables = {
  mainOutgoingPOTSNumber?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSCUFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ScuByMainTelephoneQuery = {
  scuByMainTelephone?:  {
    __typename: "ModelOmniviaSCUConnection",
    items:  Array< {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ScuByBackupTelephoneQueryVariables = {
  backupOutgoingPOTSNumber?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSCUFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ScuByBackupTelephoneQuery = {
  scuByBackupTelephone?:  {
    __typename: "ModelOmniviaSCUConnection",
    items:  Array< {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOmniviaSCUEventQueryVariables = {
  omniviaSCUEventLinkedSCUId: string,
  raisedAt: string,
  transactionId: number,
  eventId: number,
};

export type GetOmniviaSCUEventQuery = {
  getOmniviaSCUEvent?:  {
    __typename: "OmniviaSCUEvent",
    id: string,
    alertGeneratedState: number,
    omniviaSCUEventLinkedSCUId: string,
    eventId: number,
    transactionId: number,
    eventIdText?: string | null,
    eventString?: string | null,
    raisedAt: string,
    eventJSON?: string | null,
    aggregationId?: number | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    omniviaSCUEventLinkedSCUIdEventId?: string | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListOmniviaSCUEventsQueryVariables = {
  omniviaSCUEventLinkedSCUId?: string | null,
  raisedAtTransactionIdEventId?: ModelOmniviaSCUEventPrimaryCompositeKeyConditionInput | null,
  filter?: ModelOmniviaSCUEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOmniviaSCUEventsQuery = {
  listOmniviaSCUEvents?:  {
    __typename: "ModelOmniviaSCUEventConnection",
    items:  Array< {
      __typename: "OmniviaSCUEvent",
      id: string,
      alertGeneratedState: number,
      omniviaSCUEventLinkedSCUId: string,
      eventId: number,
      transactionId: number,
      eventIdText?: string | null,
      eventString?: string | null,
      raisedAt: string,
      eventJSON?: string | null,
      aggregationId?: number | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      omniviaSCUEventLinkedSCUIdEventId?: string | null,
      linkedSCU?:  {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventBySCUandAggregationQueryVariables = {
  omniviaSCUEventLinkedSCUId?: string | null,
  aggregationId?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSCUEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventBySCUandAggregationQuery = {
  eventBySCUandAggregation?:  {
    __typename: "ModelOmniviaSCUEventConnection",
    items:  Array< {
      __typename: "OmniviaSCUEvent",
      id: string,
      alertGeneratedState: number,
      omniviaSCUEventLinkedSCUId: string,
      eventId: number,
      transactionId: number,
      eventIdText?: string | null,
      eventString?: string | null,
      raisedAt: string,
      eventJSON?: string | null,
      aggregationId?: number | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      omniviaSCUEventLinkedSCUIdEventId?: string | null,
      linkedSCU?:  {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventBySCUandDateandEventQueryVariables = {
  omniviaSCUEventLinkedSCUIdEventId?: string | null,
  raisedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSCUEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventBySCUandDateandEventQuery = {
  eventBySCUandDateandEvent?:  {
    __typename: "ModelOmniviaSCUEventConnection",
    items:  Array< {
      __typename: "OmniviaSCUEvent",
      id: string,
      alertGeneratedState: number,
      omniviaSCUEventLinkedSCUId: string,
      eventId: number,
      transactionId: number,
      eventIdText?: string | null,
      eventString?: string | null,
      raisedAt: string,
      eventJSON?: string | null,
      aggregationId?: number | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      omniviaSCUEventLinkedSCUIdEventId?: string | null,
      linkedSCU?:  {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventBySCUandDateQueryVariables = {
  omniviaSCUEventLinkedSCUId?: string | null,
  raisedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaSCUEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventBySCUandDateQuery = {
  eventBySCUandDate?:  {
    __typename: "ModelOmniviaSCUEventConnection",
    items:  Array< {
      __typename: "OmniviaSCUEvent",
      id: string,
      alertGeneratedState: number,
      omniviaSCUEventLinkedSCUId: string,
      eventId: number,
      transactionId: number,
      eventIdText?: string | null,
      eventString?: string | null,
      raisedAt: string,
      eventJSON?: string | null,
      aggregationId?: number | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      omniviaSCUEventLinkedSCUIdEventId?: string | null,
      linkedSCU?:  {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOmniviaFaultsQueryVariables = {
  id: string,
};

export type GetOmniviaFaultsQuery = {
  getOmniviaFaults?:  {
    __typename: "OmniviaFaults",
    id: string,
    omniviaFaultsLinkedSCUId?: string | null,
    faultState: OmniviaFaultState,
    faultType: OmniviaFaultsTypes,
    dateRaised: string,
    dateCleared?: string | null,
    dateAlertLastGenerated?: string | null,
    deviceID: string,
    faultSpecificText?: string | null,
    friendlyString?: string | null,
    clearedBy?: string | null,
    associatedRoomNumber?: number | null,
    identity?: string | null,
    faultUnitClassification?: FaultUnitClassification | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListOmniviaFaultsQueryVariables = {
  id?: string | null,
  filter?: ModelOmniviaFaultsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOmniviaFaultsQuery = {
  listOmniviaFaults?:  {
    __typename: "ModelOmniviaFaultsConnection",
    items:  Array< {
      __typename: "OmniviaFaults",
      id: string,
      omniviaFaultsLinkedSCUId?: string | null,
      faultState: OmniviaFaultState,
      faultType: OmniviaFaultsTypes,
      dateRaised: string,
      dateCleared?: string | null,
      dateAlertLastGenerated?: string | null,
      deviceID: string,
      faultSpecificText?: string | null,
      friendlyString?: string | null,
      clearedBy?: string | null,
      associatedRoomNumber?: number | null,
      identity?: string | null,
      faultUnitClassification?: FaultUnitClassification | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      linkedSCU?:  {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FaultsByDateRaisedQueryVariables = {
  omniviaFaultsLinkedSCUId?: string | null,
  dateRaised?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaFaultsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FaultsByDateRaisedQuery = {
  faultsByDateRaised?:  {
    __typename: "ModelOmniviaFaultsConnection",
    items:  Array< {
      __typename: "OmniviaFaults",
      id: string,
      omniviaFaultsLinkedSCUId?: string | null,
      faultState: OmniviaFaultState,
      faultType: OmniviaFaultsTypes,
      dateRaised: string,
      dateCleared?: string | null,
      dateAlertLastGenerated?: string | null,
      deviceID: string,
      faultSpecificText?: string | null,
      friendlyString?: string | null,
      clearedBy?: string | null,
      associatedRoomNumber?: number | null,
      identity?: string | null,
      faultUnitClassification?: FaultUnitClassification | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      linkedSCU?:  {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FaultsByTypeandStateandDeviceIdQueryVariables = {
  omniviaFaultsLinkedSCUId?: string | null,
  faultTypeFaultStateDeviceID?: ModelOmniviaFaultsFaultsByTypeandStateandDeviceIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaFaultsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FaultsByTypeandStateandDeviceIdQuery = {
  faultsByTypeandStateandDeviceId?:  {
    __typename: "ModelOmniviaFaultsConnection",
    items:  Array< {
      __typename: "OmniviaFaults",
      id: string,
      omniviaFaultsLinkedSCUId?: string | null,
      faultState: OmniviaFaultState,
      faultType: OmniviaFaultsTypes,
      dateRaised: string,
      dateCleared?: string | null,
      dateAlertLastGenerated?: string | null,
      deviceID: string,
      faultSpecificText?: string | null,
      friendlyString?: string | null,
      clearedBy?: string | null,
      associatedRoomNumber?: number | null,
      identity?: string | null,
      faultUnitClassification?: FaultUnitClassification | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      linkedSCU?:  {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FaultsByStateQueryVariables = {
  omniviaFaultsLinkedSCUId?: string | null,
  faultState?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaFaultsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FaultsByStateQuery = {
  faultsByState?:  {
    __typename: "ModelOmniviaFaultsConnection",
    items:  Array< {
      __typename: "OmniviaFaults",
      id: string,
      omniviaFaultsLinkedSCUId?: string | null,
      faultState: OmniviaFaultState,
      faultType: OmniviaFaultsTypes,
      dateRaised: string,
      dateCleared?: string | null,
      dateAlertLastGenerated?: string | null,
      deviceID: string,
      faultSpecificText?: string | null,
      friendlyString?: string | null,
      clearedBy?: string | null,
      associatedRoomNumber?: number | null,
      identity?: string | null,
      faultUnitClassification?: FaultUnitClassification | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      linkedSCU?:  {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOmniviaLiveEventsLogQueryVariables = {
  id: string,
  mac: string,
};

export type GetOmniviaLiveEventsLogQuery = {
  getOmniviaLiveEventsLog?:  {
    __typename: "OmniviaLiveEventsLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    deviceId: number,
    associatedAlarmId?: number | null,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    parentMac?: string | null,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListOmniviaLiveEventsLogsQueryVariables = {
  id?: string | null,
  mac?: ModelStringKeyConditionInput | null,
  filter?: ModelOmniviaLiveEventsLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOmniviaLiveEventsLogsQuery = {
  listOmniviaLiveEventsLogs?:  {
    __typename: "ModelOmniviaLiveEventsLogConnection",
    items:  Array< {
      __typename: "OmniviaLiveEventsLog",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      deviceId: number,
      associatedAlarmId?: number | null,
      batteryVoltageApplication?: number | null,
      handset?:  {
        __typename: "HandsetChargeInfo",
        batteryVoltageNative: number,
        batteryCurrent: number,
        isCharging: boolean,
        isScreenOn: boolean,
        chargerProtectionStateOld: number,
        chargerProtectionStateNew: number,
        currentBatteryCapacity: number,
        batteryTemperature: number,
        maxChargeLvl: number,
        isChargerEnabled: boolean,
        isOvertemp: boolean,
        cycleOnTime: number,
        cycleOffTime: number,
      } | null,
      parentMac?: string | null,
      rssi_db?: number | null,
      addtionalReceiverList?:  Array< {
        __typename: "ReceiverList",
        parentMac: string,
        rssi_db: number,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LiveEventsLogByDateQueryVariables = {
  mac?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaLiveEventsLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LiveEventsLogByDateQuery = {
  liveEventsLogByDate?:  {
    __typename: "ModelOmniviaLiveEventsLogConnection",
    items:  Array< {
      __typename: "OmniviaLiveEventsLog",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      deviceId: number,
      associatedAlarmId?: number | null,
      batteryVoltageApplication?: number | null,
      handset?:  {
        __typename: "HandsetChargeInfo",
        batteryVoltageNative: number,
        batteryCurrent: number,
        isCharging: boolean,
        isScreenOn: boolean,
        chargerProtectionStateOld: number,
        chargerProtectionStateNew: number,
        currentBatteryCapacity: number,
        batteryTemperature: number,
        maxChargeLvl: number,
        isChargerEnabled: boolean,
        isOvertemp: boolean,
        cycleOnTime: number,
        cycleOffTime: number,
      } | null,
      parentMac?: string | null,
      rssi_db?: number | null,
      addtionalReceiverList?:  Array< {
        __typename: "ReceiverList",
        parentMac: string,
        rssi_db: number,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LiveEventsLogBySiteAndDateQueryVariables = {
  omniviaSCUSerial?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaLiveEventsLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LiveEventsLogBySiteAndDateQuery = {
  liveEventsLogBySiteAndDate?:  {
    __typename: "ModelOmniviaLiveEventsLogConnection",
    items:  Array< {
      __typename: "OmniviaLiveEventsLog",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      deviceId: number,
      associatedAlarmId?: number | null,
      batteryVoltageApplication?: number | null,
      handset?:  {
        __typename: "HandsetChargeInfo",
        batteryVoltageNative: number,
        batteryCurrent: number,
        isCharging: boolean,
        isScreenOn: boolean,
        chargerProtectionStateOld: number,
        chargerProtectionStateNew: number,
        currentBatteryCapacity: number,
        batteryTemperature: number,
        maxChargeLvl: number,
        isChargerEnabled: boolean,
        isOvertemp: boolean,
        cycleOnTime: number,
        cycleOffTime: number,
      } | null,
      parentMac?: string | null,
      rssi_db?: number | null,
      addtionalReceiverList?:  Array< {
        __typename: "ReceiverList",
        parentMac: string,
        rssi_db: number,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOmniviaBatteryLogQueryVariables = {
  id: string,
  mac: string,
};

export type GetOmniviaBatteryLogQuery = {
  getOmniviaBatteryLog?:  {
    __typename: "OmniviaBatteryLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListOmniviaBatteryLogsQueryVariables = {
  id?: string | null,
  mac?: ModelStringKeyConditionInput | null,
  filter?: ModelOmniviaBatteryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOmniviaBatteryLogsQuery = {
  listOmniviaBatteryLogs?:  {
    __typename: "ModelOmniviaBatteryLogConnection",
    items:  Array< {
      __typename: "OmniviaBatteryLog",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      batteryVoltageApplication?: number | null,
      handset?:  {
        __typename: "HandsetChargeInfo",
        batteryVoltageNative: number,
        batteryCurrent: number,
        isCharging: boolean,
        isScreenOn: boolean,
        chargerProtectionStateOld: number,
        chargerProtectionStateNew: number,
        currentBatteryCapacity: number,
        batteryTemperature: number,
        maxChargeLvl: number,
        isChargerEnabled: boolean,
        isOvertemp: boolean,
        cycleOnTime: number,
        cycleOffTime: number,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BatteryLogByDateQueryVariables = {
  mac?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaBatteryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BatteryLogByDateQuery = {
  batteryLogByDate?:  {
    __typename: "ModelOmniviaBatteryLogConnection",
    items:  Array< {
      __typename: "OmniviaBatteryLog",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      batteryVoltageApplication?: number | null,
      handset?:  {
        __typename: "HandsetChargeInfo",
        batteryVoltageNative: number,
        batteryCurrent: number,
        isCharging: boolean,
        isScreenOn: boolean,
        chargerProtectionStateOld: number,
        chargerProtectionStateNew: number,
        currentBatteryCapacity: number,
        batteryTemperature: number,
        maxChargeLvl: number,
        isChargerEnabled: boolean,
        isOvertemp: boolean,
        cycleOnTime: number,
        cycleOffTime: number,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BatteryLogBySiteAndDateQueryVariables = {
  omniviaSCUSerial?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaBatteryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BatteryLogBySiteAndDateQuery = {
  batteryLogBySiteAndDate?:  {
    __typename: "ModelOmniviaBatteryLogConnection",
    items:  Array< {
      __typename: "OmniviaBatteryLog",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      batteryVoltageApplication?: number | null,
      handset?:  {
        __typename: "HandsetChargeInfo",
        batteryVoltageNative: number,
        batteryCurrent: number,
        isCharging: boolean,
        isScreenOn: boolean,
        chargerProtectionStateOld: number,
        chargerProtectionStateNew: number,
        currentBatteryCapacity: number,
        batteryTemperature: number,
        maxChargeLvl: number,
        isChargerEnabled: boolean,
        isOvertemp: boolean,
        cycleOnTime: number,
        cycleOffTime: number,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOmniviaLocationHistoryQueryVariables = {
  id: string,
  mac: string,
};

export type GetOmniviaLocationHistoryQuery = {
  getOmniviaLocationHistory?:  {
    __typename: "OmniviaLocationHistory",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    parentMac: string,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListOmniviaLocationHistoriesQueryVariables = {
  id?: string | null,
  mac?: ModelStringKeyConditionInput | null,
  filter?: ModelOmniviaLocationHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOmniviaLocationHistoriesQuery = {
  listOmniviaLocationHistories?:  {
    __typename: "ModelOmniviaLocationHistoryConnection",
    items:  Array< {
      __typename: "OmniviaLocationHistory",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      parentMac: string,
      rssi_db?: number | null,
      addtionalReceiverList?:  Array< {
        __typename: "ReceiverList",
        parentMac: string,
        rssi_db: number,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HistoryLogByDateQueryVariables = {
  mac?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaLocationHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HistoryLogByDateQuery = {
  historyLogByDate?:  {
    __typename: "ModelOmniviaLocationHistoryConnection",
    items:  Array< {
      __typename: "OmniviaLocationHistory",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      parentMac: string,
      rssi_db?: number | null,
      addtionalReceiverList?:  Array< {
        __typename: "ReceiverList",
        parentMac: string,
        rssi_db: number,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HistoryLogBySiteAndDateQueryVariables = {
  omniviaSCUSerial?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniviaLocationHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HistoryLogBySiteAndDateQuery = {
  historyLogBySiteAndDate?:  {
    __typename: "ModelOmniviaLocationHistoryConnection",
    items:  Array< {
      __typename: "OmniviaLocationHistory",
      id: string,
      mac: string,
      omniviaSCUSerial: string,
      deviceType: number,
      expirationUnixTime: number,
      parentMac: string,
      rssi_db?: number | null,
      addtionalReceiverList?:  Array< {
        __typename: "ReceiverList",
        parentMac: string,
        rssi_db: number,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOmniviaBatteryLogSubscriptionVariables = {
  omniviaSCUSerial?: string | null,
};

export type OnCreateOmniviaBatteryLogSubscription = {
  onCreateOmniviaBatteryLog?:  {
    __typename: "OmniviaBatteryLog",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    batteryVoltageApplication?: number | null,
    handset?:  {
      __typename: "HandsetChargeInfo",
      batteryVoltageNative: number,
      batteryCurrent: number,
      isCharging: boolean,
      isScreenOn: boolean,
      chargerProtectionStateOld: number,
      chargerProtectionStateNew: number,
      currentBatteryCapacity: number,
      batteryTemperature: number,
      maxChargeLvl: number,
      isChargerEnabled: boolean,
      isOvertemp: boolean,
      cycleOnTime: number,
      cycleOffTime: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateOmniviaLocationHistorySubscriptionVariables = {
  omniviaSCUSerial?: string | null,
};

export type OnCreateOmniviaLocationHistorySubscription = {
  onCreateOmniviaLocationHistory?:  {
    __typename: "OmniviaLocationHistory",
    id: string,
    mac: string,
    omniviaSCUSerial: string,
    deviceType: number,
    expirationUnixTime: number,
    parentMac: string,
    rssi_db?: number | null,
    addtionalReceiverList?:  Array< {
      __typename: "ReceiverList",
      parentMac: string,
      rssi_db: number,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateOmniviaSCUEventSubscriptionVariables = {
  omniviaSCUEventLinkedSCUId?: string | null,
};

export type OnCreateOmniviaSCUEventSubscription = {
  onCreateOmniviaSCUEvent?:  {
    __typename: "OmniviaSCUEvent",
    id: string,
    alertGeneratedState: number,
    omniviaSCUEventLinkedSCUId: string,
    eventId: number,
    transactionId: number,
    eventIdText?: string | null,
    eventString?: string | null,
    raisedAt: string,
    eventJSON?: string | null,
    aggregationId?: number | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    omniviaSCUEventLinkedSCUIdEventId?: string | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOmniviaSCUSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateOmniviaSCUSubscription = {
  onUpdateOmniviaSCU?:  {
    __typename: "OmniviaSCU",
    id: string,
    name: string,
    description?: string | null,
    serialNumber: string,
    localEthernetIP?: string | null,
    localWiFiIP?: string | null,
    ethernetMAC?: string | null,
    wifiMAC?: string | null,
    softwareVersion?: string | null,
    mainOutgoingPOTSNumber: string,
    backupOutgoingPOTSNumber: string,
    remoteAccessPort?: number | null,
    productNumber?: string | null,
    lastSeen?: string | null,
    unitTime?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string | null > | null,
    linkedSite?:  {
      __typename: "OmniviaSite",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone: string,
      maintenanceEmail?: string | null,
      pollFailEmail?: string | null,
      stuckAlarmMinAge?: number | null,
      stuckAlarmMaxAge?: number | null,
      stuckAlarmMinTimeBetweenSMS?: number | null,
      stuckAlarmLastAlert?: string | null,
      siteAlertsEnabled?: boolean | null,
      brand?: string | null,
      tags?: string | null,
      floorPlanFolder?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string > | null,
      linkedClient?:  {
        __typename: "OmniviaClient",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone?: string | null,
        brand?: string | null,
        tags?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        linkedSites?:  {
          __typename: "ModelOmniviaSiteConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedSCUs?:  {
        __typename: "ModelOmniviaSCUConnection",
        items:  Array< {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedEvents?:  {
      __typename: "ModelOmniviaSCUEventConnection",
      items:  Array< {
        __typename: "OmniviaSCUEvent",
        id: string,
        alertGeneratedState: number,
        omniviaSCUEventLinkedSCUId: string,
        eventId: number,
        transactionId: number,
        eventIdText?: string | null,
        eventString?: string | null,
        raisedAt: string,
        eventJSON?: string | null,
        aggregationId?: number | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        omniviaSCUEventLinkedSCUIdEventId?: string | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedFaults?:  {
      __typename: "ModelOmniviaFaultsConnection",
      items:  Array< {
        __typename: "OmniviaFaults",
        id: string,
        omniviaFaultsLinkedSCUId?: string | null,
        faultState: OmniviaFaultState,
        faultType: OmniviaFaultsTypes,
        dateRaised: string,
        dateCleared?: string | null,
        dateAlertLastGenerated?: string | null,
        deviceID: string,
        faultSpecificText?: string | null,
        friendlyString?: string | null,
        clearedBy?: string | null,
        associatedRoomNumber?: number | null,
        identity?: string | null,
        faultUnitClassification?: FaultUnitClassification | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        expirationUnixTime?: number | null,
        linkedSCU?:  {
          __typename: "OmniviaSCU",
          id: string,
          name: string,
          description?: string | null,
          serialNumber: string,
          localEthernetIP?: string | null,
          localWiFiIP?: string | null,
          ethernetMAC?: string | null,
          wifiMAC?: string | null,
          softwareVersion?: string | null,
          mainOutgoingPOTSNumber: string,
          backupOutgoingPOTSNumber: string,
          remoteAccessPort?: number | null,
          productNumber?: string | null,
          lastSeen?: string | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateOmniviaClientSubscription = {
  onCreateOmniviaClient?:  {
    __typename: "OmniviaClient",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone?: string | null,
    brand?: string | null,
    tags?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    linkedSites?:  {
      __typename: "ModelOmniviaSiteConnection",
      items:  Array< {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOmniviaClientSubscription = {
  onUpdateOmniviaClient?:  {
    __typename: "OmniviaClient",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone?: string | null,
    brand?: string | null,
    tags?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    linkedSites?:  {
      __typename: "ModelOmniviaSiteConnection",
      items:  Array< {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteOmniviaClientSubscription = {
  onDeleteOmniviaClient?:  {
    __typename: "OmniviaClient",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone?: string | null,
    brand?: string | null,
    tags?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    linkedSites?:  {
      __typename: "ModelOmniviaSiteConnection",
      items:  Array< {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateOmniviaSiteSubscription = {
  onCreateOmniviaSite?:  {
    __typename: "OmniviaSite",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone: string,
    maintenanceEmail?: string | null,
    pollFailEmail?: string | null,
    stuckAlarmMinAge?: number | null,
    stuckAlarmMaxAge?: number | null,
    stuckAlarmMinTimeBetweenSMS?: number | null,
    stuckAlarmLastAlert?: string | null,
    siteAlertsEnabled?: boolean | null,
    brand?: string | null,
    tags?: string | null,
    floorPlanFolder?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string > | null,
    linkedClient?:  {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedSCUs?:  {
      __typename: "ModelOmniviaSCUConnection",
      items:  Array< {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOmniviaSiteSubscription = {
  onUpdateOmniviaSite?:  {
    __typename: "OmniviaSite",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone: string,
    maintenanceEmail?: string | null,
    pollFailEmail?: string | null,
    stuckAlarmMinAge?: number | null,
    stuckAlarmMaxAge?: number | null,
    stuckAlarmMinTimeBetweenSMS?: number | null,
    stuckAlarmLastAlert?: string | null,
    siteAlertsEnabled?: boolean | null,
    brand?: string | null,
    tags?: string | null,
    floorPlanFolder?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string > | null,
    linkedClient?:  {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedSCUs?:  {
      __typename: "ModelOmniviaSCUConnection",
      items:  Array< {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteOmniviaSiteSubscription = {
  onDeleteOmniviaSite?:  {
    __typename: "OmniviaSite",
    id: string,
    name: string,
    description?: string | null,
    addr1?: string | null,
    addr2?: string | null,
    addr3?: string | null,
    city?: string | null,
    county?: string | null,
    country?: string | null,
    postcode?: string | null,
    contactEmail?: string | null,
    contactTelephone?: string | null,
    timeZone: string,
    maintenanceEmail?: string | null,
    pollFailEmail?: string | null,
    stuckAlarmMinAge?: number | null,
    stuckAlarmMaxAge?: number | null,
    stuckAlarmMinTimeBetweenSMS?: number | null,
    stuckAlarmLastAlert?: string | null,
    siteAlertsEnabled?: boolean | null,
    brand?: string | null,
    tags?: string | null,
    floorPlanFolder?: string | null,
    modifiedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _deleted?: boolean | null,
    groupsCanAccess?: Array< string > | null,
    linkedClient?:  {
      __typename: "OmniviaClient",
      id: string,
      name: string,
      description?: string | null,
      addr1?: string | null,
      addr2?: string | null,
      addr3?: string | null,
      city?: string | null,
      county?: string | null,
      country?: string | null,
      postcode?: string | null,
      contactEmail?: string | null,
      contactTelephone?: string | null,
      timeZone?: string | null,
      brand?: string | null,
      tags?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      linkedSites?:  {
        __typename: "ModelOmniviaSiteConnection",
        items:  Array< {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    linkedSCUs?:  {
      __typename: "ModelOmniviaSCUConnection",
      items:  Array< {
        __typename: "OmniviaSCU",
        id: string,
        name: string,
        description?: string | null,
        serialNumber: string,
        localEthernetIP?: string | null,
        localWiFiIP?: string | null,
        ethernetMAC?: string | null,
        wifiMAC?: string | null,
        softwareVersion?: string | null,
        mainOutgoingPOTSNumber: string,
        backupOutgoingPOTSNumber: string,
        remoteAccessPort?: number | null,
        productNumber?: string | null,
        lastSeen?: string | null,
        unitTime?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string | null > | null,
        linkedSite?:  {
          __typename: "OmniviaSite",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone: string,
          maintenanceEmail?: string | null,
          pollFailEmail?: string | null,
          stuckAlarmMinAge?: number | null,
          stuckAlarmMaxAge?: number | null,
          stuckAlarmMinTimeBetweenSMS?: number | null,
          stuckAlarmLastAlert?: string | null,
          siteAlertsEnabled?: boolean | null,
          brand?: string | null,
          tags?: string | null,
          floorPlanFolder?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
          groupsCanAccess?: Array< string > | null,
        } | null,
        linkedEvents?:  {
          __typename: "ModelOmniviaSCUEventConnection",
          nextToken?: string | null,
        } | null,
        linkedFaults?:  {
          __typename: "ModelOmniviaFaultsConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateOmniviaFaultsSubscription = {
  onCreateOmniviaFaults?:  {
    __typename: "OmniviaFaults",
    id: string,
    omniviaFaultsLinkedSCUId?: string | null,
    faultState: OmniviaFaultState,
    faultType: OmniviaFaultsTypes,
    dateRaised: string,
    dateCleared?: string | null,
    dateAlertLastGenerated?: string | null,
    deviceID: string,
    faultSpecificText?: string | null,
    friendlyString?: string | null,
    clearedBy?: string | null,
    associatedRoomNumber?: number | null,
    identity?: string | null,
    faultUnitClassification?: FaultUnitClassification | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOmniviaFaultsSubscription = {
  onUpdateOmniviaFaults?:  {
    __typename: "OmniviaFaults",
    id: string,
    omniviaFaultsLinkedSCUId?: string | null,
    faultState: OmniviaFaultState,
    faultType: OmniviaFaultsTypes,
    dateRaised: string,
    dateCleared?: string | null,
    dateAlertLastGenerated?: string | null,
    deviceID: string,
    faultSpecificText?: string | null,
    friendlyString?: string | null,
    clearedBy?: string | null,
    associatedRoomNumber?: number | null,
    identity?: string | null,
    faultUnitClassification?: FaultUnitClassification | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOmniviaFaultsSubscription = {
  onDeleteOmniviaFaults?:  {
    __typename: "OmniviaFaults",
    id: string,
    omniviaFaultsLinkedSCUId?: string | null,
    faultState: OmniviaFaultState,
    faultType: OmniviaFaultsTypes,
    dateRaised: string,
    dateCleared?: string | null,
    dateAlertLastGenerated?: string | null,
    deviceID: string,
    faultSpecificText?: string | null,
    friendlyString?: string | null,
    clearedBy?: string | null,
    associatedRoomNumber?: number | null,
    identity?: string | null,
    faultUnitClassification?: FaultUnitClassification | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    linkedSCU?:  {
      __typename: "OmniviaSCU",
      id: string,
      name: string,
      description?: string | null,
      serialNumber: string,
      localEthernetIP?: string | null,
      localWiFiIP?: string | null,
      ethernetMAC?: string | null,
      wifiMAC?: string | null,
      softwareVersion?: string | null,
      mainOutgoingPOTSNumber: string,
      backupOutgoingPOTSNumber: string,
      remoteAccessPort?: number | null,
      productNumber?: string | null,
      lastSeen?: string | null,
      unitTime?: string | null,
      modifiedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _deleted?: boolean | null,
      groupsCanAccess?: Array< string | null > | null,
      linkedSite?:  {
        __typename: "OmniviaSite",
        id: string,
        name: string,
        description?: string | null,
        addr1?: string | null,
        addr2?: string | null,
        addr3?: string | null,
        city?: string | null,
        county?: string | null,
        country?: string | null,
        postcode?: string | null,
        contactEmail?: string | null,
        contactTelephone?: string | null,
        timeZone: string,
        maintenanceEmail?: string | null,
        pollFailEmail?: string | null,
        stuckAlarmMinAge?: number | null,
        stuckAlarmMaxAge?: number | null,
        stuckAlarmMinTimeBetweenSMS?: number | null,
        stuckAlarmLastAlert?: string | null,
        siteAlertsEnabled?: boolean | null,
        brand?: string | null,
        tags?: string | null,
        floorPlanFolder?: string | null,
        modifiedBy?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        _deleted?: boolean | null,
        groupsCanAccess?: Array< string > | null,
        linkedClient?:  {
          __typename: "OmniviaClient",
          id: string,
          name: string,
          description?: string | null,
          addr1?: string | null,
          addr2?: string | null,
          addr3?: string | null,
          city?: string | null,
          county?: string | null,
          country?: string | null,
          postcode?: string | null,
          contactEmail?: string | null,
          contactTelephone?: string | null,
          timeZone?: string | null,
          brand?: string | null,
          tags?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          _deleted?: boolean | null,
        } | null,
        linkedSCUs?:  {
          __typename: "ModelOmniviaSCUConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      linkedEvents?:  {
        __typename: "ModelOmniviaSCUEventConnection",
        items:  Array< {
          __typename: "OmniviaSCUEvent",
          id: string,
          alertGeneratedState: number,
          omniviaSCUEventLinkedSCUId: string,
          eventId: number,
          transactionId: number,
          eventIdText?: string | null,
          eventString?: string | null,
          raisedAt: string,
          eventJSON?: string | null,
          aggregationId?: number | null,
          unitTime?: string | null,
          modifiedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
          omniviaSCUEventLinkedSCUIdEventId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      linkedFaults?:  {
        __typename: "ModelOmniviaFaultsConnection",
        items:  Array< {
          __typename: "OmniviaFaults",
          id: string,
          omniviaFaultsLinkedSCUId?: string | null,
          faultState: OmniviaFaultState,
          faultType: OmniviaFaultsTypes,
          dateRaised: string,
          dateCleared?: string | null,
          dateAlertLastGenerated?: string | null,
          deviceID: string,
          faultSpecificText?: string | null,
          friendlyString?: string | null,
          clearedBy?: string | null,
          associatedRoomNumber?: number | null,
          identity?: string | null,
          faultUnitClassification?: FaultUnitClassification | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          expirationUnixTime?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};
