import TablePaged, { dateFilter } from 'components/tables/TablePaged';

import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent } from '../../tile';
import { Text, Tip, Paragraph } from 'grommet';

import { Column, Row } from 'react-table';
import * as Wait from 'components/dialogues/waitDialogue';
import * as AlarmEvents from 'components/systemevents/alarmEvents';
import * as eventDispatcher from 'store/eventDispatcher';
import * as utils from 'common/utils/dateUtils';
import store from 'store/store';
import * as siteSlice from 'store/sitesSlice';
import { useWinstonLogger } from 'winston-react';
import * as EventEnums from 'types/event-enums';
import * as ReduxStore from 'store/store';
import * as UsersSlice from 'store/appUserSlice';
import { useTranslation } from 'react-i18next';
import * as UserUtils from 'common/userUtils';
import { Manifest } from 'components/installation/manifest';

const manifest = new Manifest();

const AlarmAuditTrail = (): JSX.Element => {
  const logger = useWinstonLogger();
  const { t } = useTranslation('auditTrail');
  const [, SetChildEvent] = useState('');
  const [dialogueProps] = useState<Wait.DialogueProps | undefined>();

  const [alarmId, setAlarmId] = useState<number | undefined>();
  const [allData, setAllData] = useState<AlarmEvents.iAlarmEventSummary[]>([]);
  const [reportDescription, setReportDescription] = useState({
    header: '',
    filename: 'allData',
  });
  const [description, setDescription] = useState('');

  const loadData = (alarmId: number | undefined) => {
    let doorCall = false;
    if (alarmId) {
      const alarmSummary = AlarmEvents.alarmSummaries.find(
        (e) => e.alarmId === alarmId
      );
      logger.info('alarmAudit', { alarmID: alarmId });
      const alarmEvents = AlarmEvents.getAlarmAuditTrail(alarmId);

      const doorEvents = alarmEvents.filter(
        (e) =>
          e.eventId ===
            EventEnums.AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RAISE_RU ||
          e.eventId === EventEnums.AUDIT_EVENTS.AUDIT_EVENT_DOOR_CALL_RAISE
      );
      if (doorEvents.length > 0) doorCall = true;

      setAlarmId(alarmId);

      setAllData(alarmEvents);
      const siteSelected = siteSlice.getSelected(store.getState());

      let header = '';
      if (!doorCall) {
        header = `Audit Trail for the ${
          alarmSummary?.alarmType
        } alarm in Room ${alarmSummary?.roomNumber} on ${utils.formatDate(
          alarmSummary?.raisedAt
        )}`;

        if (alarmSummary && alarmSummary.raisedDeviceId) {
          const trigger = manifest.triggers.find(
            (e) => e.ID == alarmSummary.raisedDeviceId
          );
          if (trigger) {
            header += `\r\nPeripheral Type: ${
              trigger.Description
            }, Identity: ${trigger.getIdentity()}`;
          }
        }

        setReportDescription({
          header: header,
          filename: `${siteSelected?.name} Room ${alarmSummary?.roomNumber} ${alarmSummary?.alarmType} alarm audit trail`,
        });
      } else {
        header = `Door call audit trail`;
        setReportDescription({
          header: header,
          filename: `${siteSelected?.name} Door call audit trail`,
        });
      }

      setDescription(header);
    } else {
      setAlarmId(undefined);
      setAllData([]);
    }
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.ALARMEVENTS,
      state: eventDispatcher.systemEventStates.SELECTED,
      callback: (event: eventDispatcher.iEventParams) =>
        loadData(event.detail as number),
      executeOnStartup: false,
    },
    {
      topic: eventDispatcher.systemEventTopics.DOOREVENTS,
      state: eventDispatcher.systemEventStates.SELECTED,
      callback: (event: eventDispatcher.iEventParams) =>
        loadData(event.detail as number),
      executeOnStartup: false,
    },
  ];

  useEffect(() => {
    const persistantData = UsersSlice.getPersistantData(
      ReduxStore.store.getState()
    );
    loadData(persistantData.selectedAlarmId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.ADMIN)
        ? ['transactionId', 'eventData']
        : ['transactionId', 'eventData', 'eventId'],
      pageSize: 30,
      sortBy: [
        {
          id: 'transactionId',
          desc: false,
        },
      ],
    }),
    []
  );

  /*
  eventReceivedAtServer: string;
  eventRaisedAtSCU: string;
  eventId: number;
  eventText: string;
  transactionId: number;
  eventData: unknown | undefined; 
  uiDescription: string;
*/

  const columns = React.useMemo<Column<AlarmEvents.iAlarmEventSummary>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'transactionId',
      },
      {
        Header: '',
        accessor: 'eventData',
        getCellExportValue: () => {
          return '';
        },
      },
      {
        width: '16%',
        id: 'eventtime',
        Header: 'Event Time',
        sortType: 'datetime',
        filter: dateFilter,
        accessor: (row) => new Date(row.eventRaisedAtSCU),

        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) => utils.formatDateWithSeconds(props.cell.value),
        sortDescFirst: true,
        getCellExportValue: (row: Row<AlarmEvents.iAlarmEventSummary>) => {
          return utils.formatDateWithSeconds(row.original.eventRaisedAtSCU);
        },
      },
      {
        Header: 'EventID',
        accessor: 'eventId',
        width: '10%',
        Cell: ({ ...props }) => {
          const json = JSON.stringify(props.row.values.eventData, null, '\n');

          return (
            <>
              <Tip
                dropProps={{ margin: 'large' }}
                content={<Paragraph>{json}</Paragraph>}
              >
                {props.row.values.eventId}
              </Tip>
            </>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'uiDescription',
      },
    ],
    []
  );

  return (
    <Tile
      title={t('Audit Trail')}
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
    >
      <>
        {!alarmId && (
          <p>
            No call has been selected. Please select a call to view from the
            recent alarms or door calls tile
          </p>
        )}
        {alarmId && (
          <Text style={{ whiteSpace: 'pre-wrap' }}>{description}</Text>
        )}

        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={allData}
          showCheckboxes={false}
          reportDescription={reportDescription}
        />
      </>
    </Tile>
  );
};

export default AlarmAuditTrail;
