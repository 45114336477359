import * as Amplify from 'aws-amplify';
import { generateCallReport } from 'graphql/queries';
import store from 'store/store';
import * as scuslice from 'store/scusSlice';
import * as siteslice from 'store/sitesSlice';
import _ from 'lodash';
import * as S3Buckets from 'common/S3Bucket';
import * as FileSaver from 'file-saver';
import * as API from 'types/API';

export async function generateCallReportForCurrentSite(
  reportDurationDays: number
): Promise<Blob | undefined> {
  const selectSCU = scuslice.getSelected(store.getState());
  const selectSite = siteslice.getSelected(store.getState());
  if (selectSCU && selectSite) {
    try {
      const resp = await Amplify.API.graphql(
        Amplify.graphqlOperation(generateCallReport, {
          scuId: selectSCU.id,
          siteName: selectSite.name,
          reportDurationDays: reportDurationDays,
          email: '',
        })
      );

      const data = _.get(resp, 'data', undefined) as
        | API.GenerateCallReportQuery
        | undefined;

      console.info('report response: ', data);

      const signedurl: string = data ? data.generateCallReport : '';
      if (signedurl != '') {
        try {
          const document: Blob = <Blob>(
            await S3Buckets.getFile(signedurl, 'blob')
          );
          console.info('Got report file');

          if (document) {
            await FileSaver.saveAs(
              document,
              `CallReport_${selectSite.name}.xlsx` // set name of file
            );
          }

          return document;
        } catch (error) {
          console.error(
            'generateCallReport failed to get report, error:',
            error
          );
        }
      }
    } catch (error) {
      console.error('generateCallReport failed, error:', error);
    }
  }

  return undefined;
}
