import React, { useState, useEffect } from 'react';
import { Box, Button, Layer, Paragraph, Form } from 'grommet';
import * as IotDeviceMgmt from 'common/IoT/Helpers/DeviceManagement';
import * as Wait from 'components/dialogues/waitDialogue';
import { Dispatch } from 'react';
import { products } from 'data/product-definitions';
import { Equipment } from 'types/product-types';
import * as FormFields from 'components/forms/formFields';
import { Manifest } from 'components/installation/manifest';
import { iRoom } from 'types/manifest-types';
import { ALARM_TYPES } from 'types/event-enums';
import * as eventEnums from 'types/event-enums';
import {
  getCurrentUserConfigAttributes,
  getCurrentUsersName,
} from 'common/userUtils';
import { teBSI_LOCATIONS } from 'types/manifest-enums';
//import useStateRef from 'react-usestateref';
import { BRAND_WIRELESSHEALTH, BRAND_TUNSTALL } from '../themes/brands';
import { sleep } from 'common/utils/miscUtils';
import * as eventDispatcher from 'store/eventDispatcher';
import { ALARMS_TYPE_DESCRIPTIONS } from 'common/alarmTypesTextFiltered/alarmText';

interface iAddPeripheralDialogue {
  defaultRoom?: iRoom | undefined;
  show: boolean;
  setShow: Dispatch<boolean>;
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>;
}

let m_ProductsToList: string[] = [];
let recentlyAddedID = -1;

const m_869Devices = [
  'PROD-3158', // Fall
  'PROD-3204', // Universal

  'T2',
  'T3',
  'T4',
  'T5',
  'T6',
  'T7',
  'T8',
  'T9',
  'T10',
  'T11',
  'T12',
  'T13',
  'T14',
  'T15',
  'T16',
  'T17',
  'T18',
  'T19',
  'T20',
  'T21',
  'T22,',
];

const m_AlarmTypes = ALARMS_TYPE_DESCRIPTIONS.map((e) => {
  return { value: e.text, key: e.value };
});

interface iPeripheralAddFields {
  product: Partial<Equipment>;
  room: Partial<iRoom>;
  mac: string;
  inputs: number;
  alarmTypeIn1: ALARM_TYPES;
  alarmTypeIn2: ALARM_TYPES;
}

const blank: iPeripheralAddFields = {
  product: { name: '' },
  room: { Name: '' },
  mac: '',
  inputs: 0,
  alarmTypeIn1: ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT,
  alarmTypeIn2: ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT,
};

const manifest = new Manifest();

const AddPeripheralDialogue = (props: iAddPeripheralDialogue): JSX.Element => {
  //const [selected, setSelected] = useState<iPeripheralAddFields | undefined>();
  const [peripheralInfo, setPeripheralInfo] =
    useState<iPeripheralAddFields>(blank);
  const [brand, setBrand] = useState(BRAND_WIRELESSHEALTH);

  if (brand == BRAND_WIRELESSHEALTH) {
    m_ProductsToList = [
      'PROD-3158', // Fall
      'PROD-3159', // CareLocate Pendant
      'PROD-3078', // Remote Door Control
      'PROD-3165', // Wireless Door Sensor
      'PROD-3204', // Universal
    ];
  } else if (brand == 'Tunstall') {
    m_ProductsToList = [
      'T2',
      'T3',
      'T4',
      'T5',
      'T6',
      'T7',
      'T8',
      'T9',
      'T10',
      'T11',
      'T12',
      'T13',
      'T14',
      'T15',
      'T16',
      'T17',
      'T18',
      'T19',
      'T20',
      'T21',
    ];
  }

  // Using map so we can control the order of the options.
  // The product option list will be in the order of the items defined in m_ProductsToList
  const m_Products = m_ProductsToList.map((p) => {
    const product = products.find((e) => e.productCode == p);
    if (product)
      return { value: product.productCode + ' ' + product.name, key: product };
    else return { value: 'not found', key: undefined };
  });

  // console.log(m_ProductsToList, m_Products);

  const m_Rooms = manifest.rooms
    .map((e) => {
      return { value: `Rm: ${e.RoomNumber} ${e.Name}`, key: e };
    })
    .sort(function (a, b) {
      return a.key.RoomNumber - b.key.RoomNumber;
    });

  const _fields: FormFields.iField[] = [
    {
      label: 'Type',
      id: 'product',
      type: 'select',
      options: m_Products,
    },
    { label: 'Identifier', id: 'mac', type: 'text', formBoxWidth: 'small' },
    {
      label: 'Room',
      id: 'room',
      type: 'select',
      options: m_Rooms,
      readOnly: props.defaultRoom != undefined,
    },
    {
      label: 'Input 1',
      id: 'alarmTypeIn1',
      type: 'select',
      options: m_AlarmTypes,
      hide: true,
    },
    {
      label: 'Input 2',
      id: 'alarmTypeIn2',
      type: 'select',
      options: m_AlarmTypes,
      hide: true,
    },
  ];

  const [fields, setFields] = useState<FormFields.iField[]>(_fields);

  const addClicked = async () => {
    let errorMessage = '';

    let mac = NaN;
    if (brand == BRAND_WIRELESSHEALTH) mac = parseInt(peripheralInfo.mac, 16);
    if (brand == BRAND_TUNSTALL) mac = parseInt(peripheralInfo.mac);

    if (
      !peripheralInfo.product ||
      !peripheralInfo.product.productCode ||
      !m_ProductsToList.includes(peripheralInfo.product.productCode) ||
      peripheralInfo.product.deviceModel === undefined ||
      peripheralInfo.product.deviceType === undefined
    ) {
      errorMessage = 'Please select a peripheral type';
    } else if (
      peripheralInfo.mac.includes('x') ||
      (peripheralInfo.mac.length != 16 && brand == BRAND_WIRELESSHEALTH) ||
      ((peripheralInfo.mac.length > 6 || peripheralInfo.mac.length < 3) &&
        brand == 'Tunstall') ||
      isNaN(mac)
    ) {
      errorMessage = 'Please enter a valid Identifier';
    } else if (
      peripheralInfo.room === undefined ||
      peripheralInfo.room.RoomNumber == undefined
    ) {
      errorMessage = 'Please select a Room';
    } else if (peripheralInfo.room !== undefined) {
      if (!manifest.unitIdInUse(peripheralInfo.room.RoomNumber)) {
        errorMessage = `Room ${peripheralInfo.room} not found`;
      } else {
        let JennicDevice = true;
        if (m_869Devices.includes(peripheralInfo.product.productCode)) {
          JennicDevice = false;
        }

        let JennicMac = false;
        if (peripheralInfo.mac.substring(0, 6) == '00158d') {
          JennicMac = true;
        }

        if (JennicDevice && !JennicMac) {
          errorMessage =
            'The incorrect peripheral type has been set.  The type for devices with an identifier starting with 00124b can only be set as PROD-3158 or PROD-3204.';
        }

        if (!JennicDevice && JennicMac) {
          errorMessage =
            'The incorrect peripheral type has been set.  The type for devices with an identifier starting with 00158d can only be set as PROD-3159, PROD-3078 or PROD-3165.';
        }
      }
    }

    if (errorMessage !== '') {
      props.setDialogueProps({
        show: true,
        dialogueText: errorMessage,
        //dialogueTextLine2: str2,
        showSpinner: false,
        showOkButton: true,
        okButtonPress: () => {
          props.setDialogueProps(undefined);
        },
        showCancelButton: false,
      });
    } else {
      if (
        peripheralInfo.room.HomeDeviceId !== undefined &&
        peripheralInfo.room.RoomNumber !== undefined &&
        peripheralInfo.product.deviceModel !== undefined &&
        peripheralInfo.product.deviceType !== undefined
      ) {
        let ok;
        recentlyAddedID = -1;

        if (peripheralInfo.inputs > 0) {
          const res = await IotDeviceMgmt.addUniversalSensor(
            mac,
            peripheralInfo.room.HomeDeviceId,
            peripheralInfo.product.deviceType,
            peripheralInfo.product.deviceModel,
            peripheralInfo.alarmTypeIn1,
            peripheralInfo.alarmTypeIn2,
            teBSI_LOCATIONS.LOCATION_NO_LOCATION_INFORMATION,
            props.setDialogueProps
          );
          ok = res.ok;
          recentlyAddedID = res.deviceId;
        } else {
          const res = await IotDeviceMgmt.addBatteryDevice(
            mac,
            peripheralInfo.room.HomeDeviceId,
            peripheralInfo.product.deviceType,
            peripheralInfo.product.deviceModel,
            teBSI_LOCATIONS.LOCATION_NO_LOCATION_INFORMATION,
            props.setDialogueProps
          );
          ok = res.ok;
          recentlyAddedID = res.deviceId;
        }

        props.setDialogueProps(undefined);
        if (ok) props.setShow(false);
      }
    }
  };

  // listen for manifest processed events for any changes we might want to make to a newly assigned peripheral
  const manifestProcessed = async () => {
    console.log(recentlyAddedID, getCurrentUsersName());
    if (recentlyAddedID != -1) {
      // set the user that added the device
      const trig = manifest.triggers.find((t) => t.ID == recentlyAddedID);
      console.log(trig);
      if (trig) {
        trig.AddedBy = getCurrentUsersName();
        await manifest.save();
        recentlyAddedID = -1;
      }
    }
  };

  // Clear the previous settings
  useEffect(() => {
    if (props.show) {
      // if we've been passed a default room to assign the device to then populate this
      const pi: iPeripheralAddFields = props.defaultRoom
        ? { ...blank, room: props.defaultRoom }
        : blank;
      setPeripheralInfo(pi);

      _fields[3].hide = true;
      _fields[4].hide = true;
      manifest.rooms;

      getCurrentUserConfigAttributes().then((cfg) => {
        if (cfg.brand != '') {
          setBrand(cfg.brand);
        } else {
          setBrand(BRAND_WIRELESSHEALTH);
        }
      });
      setFields(_fields);
    }
  }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCurrentUserConfigAttributes().then((cfg) => {
      if (cfg.brand != '') {
        setBrand(cfg.brand);
      } else {
        setBrand(BRAND_WIRELESSHEALTH);
      }
    });

    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.PROCESSED,
      manifestProcessed
    );

    return () => {
      // Remove registrations on exit
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.PROCESSED,
        manifestProcessed
      );

      recentlyAddedID = -1;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.show && (
        <Layer
          className={'waitdialog'}
          background="dialogBackground"
          style={{ whiteSpace: 'pre-wrap' }}
          modal={true}
        >
          <Box pad="small" gap="small" align="center">
            <Paragraph textAlign="center">
              To assign a new device, please enter the details below and press
              assign.
            </Paragraph>

            <Form<iPeripheralAddFields>
              value={peripheralInfo}
              onChange={(nextValue) => {
                let mac = nextValue.mac;
                if (
                  nextValue.product.productCode &&
                  nextValue.product.productCode !=
                    peripheralInfo.product.productCode
                ) {
                  if (brand == BRAND_WIRELESSHEALTH) {
                    if (m_869Devices.includes(nextValue.product.productCode)) {
                      mac = '00124b001bxxxxxx';
                    } else {
                      mac = '00158d0000xxxxxx';
                    }
                  } else if (brand == 'Tunstall') {
                    mac = '';
                  }

                  nextValue.inputs = 0;
                  nextValue.alarmTypeIn1 =
                    ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT;
                  nextValue.alarmTypeIn2 =
                    ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT;
                  _fields[3].hide = true;
                  _fields[4].hide = true;

                  if (nextValue.product.productCode === 'PROD-3204') {
                    // universal with 2 inputs
                    nextValue.inputs = 2;
                    nextValue.alarmTypeIn1 = ALARM_TYPES.ALARM_TYPE_SEIZURE;
                    nextValue.alarmTypeIn2 = ALARM_TYPES.ALARM_TYPE_BATTERY;
                    _fields[3].hide = false;
                    _fields[4].hide = false;
                  } else if (nextValue.product.productCode === 'PROD-3165') {
                    // wireless door sensor with 1 input
                    nextValue.inputs = 1;
                    nextValue.alarmTypeIn1 = ALARM_TYPES.ALARM_TYPE_WANDERING;
                    _fields[3].hide = false;
                  }
                  setFields(_fields);
                }

                nextValue.mac = mac;
                setPeripheralInfo(nextValue);
              }}
              onSubmit={async () => {
                console.info('Selected: ', peripheralInfo);
                props.setDialogueProps({
                  show: true,
                  dialogueText: `Are you sure you want to assign a ${peripheralInfo.product.name} to room ${peripheralInfo.room.RoomNumber}?`,
                  showSpinner: false,
                  showOkButton: true,
                  okButtonPress: () => {
                    addClicked();
                  },
                  showCancelButton: true,
                  cancelButtonPress: () => {
                    props.setDialogueProps(undefined);
                  },
                });
              }}
            >
              <Box direction="column">
                <FormFields.AlignedFormFields
                  displayfields={fields}
                  labelWidth="55px"
                />
              </Box>

              <Box direction="row" gap="medium" justify="center">
                <Button
                  label="Assign"
                  size="medium"
                  plain={false}
                  margin="small"
                  type="submit"
                />
                <Button
                  label="Cancel"
                  size="medium"
                  plain={false}
                  margin="small"
                  onClick={() => {
                    console.info('Cancel');
                    props.setShow(false);
                    props.setDialogueProps(undefined);
                  }}
                />
              </Box>
            </Form>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default AddPeripheralDialogue;
