import { iExcelColumn, iExcelSchema } from 'common/excel/createExcel';
import { Manifest } from 'components/installation/manifest';
import { createExcel } from 'common/excel/createExcel';
import { headerRows } from 'common/excel/headerRows';
import { Worksheet } from 'exceljs';
import * as UserUtils from 'common/userUtils';
import { cloneDeep } from 'lodash';
import { iDevice, iRoutingEvent } from '../../../types/manifest-types';
import * as eventEnums from 'types/event-enums';
import * as careGroupUtils from 'components/installation/careGroupUtils';
import { ALARM_TYPES } from '../../../types/event-enums';
import { alarmIsTechnical } from '../../../common/alarmUtils';
import { Device } from 'components/installation/device';
import { triggerAsyncId } from 'async_hooks';
import { getAlarmDescription } from 'common/alarmTypesTextFiltered/alarmTypeUtils';

const manifest = new Manifest();

let extended = false;

let reportColumns: iExcelColumn[] = [];

interface iAlarmCS extends iRoutingEvent {
  alarm: string;
  unitId: number;
  name: string;
  callSequence: string;
  macAddress: string;
}

const createColumns = () => {
  reportColumns = [
    {
      columnName: 'ID',
      columnWidth: 16,
      fieldName: 'ID',
      type: 'TEXT',
    },
    {
      columnName: 'Unit',
      columnWidth: 10,
      fieldName: 'unitId',
      type: 'TEXT',
    },
    {
      columnName: 'Identity',
      columnWidth: 12,
      fieldName: 'macAddress',
      type: 'TEXT',
    },
    {
      columnName: 'Name',
      columnWidth: 20,
      fieldName: 'name',
      type: 'TEXT',
    },

    {
      columnName: 'Alarm',
      columnWidth: 40,
      fieldName: 'alarm',
      type: 'TEXT',
    },
    {
      columnName: 'Call Sequence',
      columnWidth: 20,
      fieldName: 'callSequence',
      type: 'TEXT',
    },
  ];

  if (extended)
    reportColumns.push(
      {
        columnName: 'Technical Alarm',
        columnWidth: 12,
        fieldName: 'IsTechnical',
        type: 'TEXT',
      },
      {
        columnName: 'Guard Period',
        columnWidth: 12,
        fieldName: 'GuardPeriod',
        type: 'TEXT',
      },
      {
        columnName: 'Pre-alarm Delay',
        columnWidth: 12,
        fieldName: 'PreAlarmDelay',
        type: 'TEXT',
      },
      {
        columnName: 'Covert',
        columnWidth: 12,
        fieldName: 'Covert',
        type: 'TEXT',
      },
      {
        columnName: 'Disabled',
        columnWidth: 12,
        fieldName: 'AlarmDisabled',
        type: 'TEXT',
      }
    );
};

export const alarmCSHeaderRows = (sheet: Worksheet): void => {
  // call generic header first
  headerRows(sheet);
  if (extended) {
    // const Row = sheet.addRow([
    //   '',
    //   '',
    //   '',
    //   '',
    //   '',
    //   '',
    //   '',
    //   'Default Settings When Assigning New Sensor',
    // ]);
    // Row.getCell(7).style.font = {
    //   size: 11,
    //   bold: true,
    // };
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let data: iAlarmCS[] = [];

const getName = (deviceId: number) => {
  const room = manifest.getRoomByDeviceID(deviceId);
  if (room) {
    return room.Name;
  }

  const ap = manifest.accessPoints.find((e) => e.ID === deviceId);
  if (ap) {
    return ap.Name;
  }

  const intf = manifest.deviceInterfaces.find((e) => e.ID === deviceId);
  if (intf) {
    return intf.Name;
  }

  const dev = manifest.triggers.find((e) => e.ID === deviceId);
  if (dev) {
    return dev.parentRoom?.Name ?? '';
  }

  return 'unknown';
};

const getUnitId = (deviceId: number): number => {
  const room = manifest.getRoomByDeviceID(deviceId);
  if (room) {
    return room.RoomNumber;
  }

  const ap = manifest.accessPoints.find((e) => e.ID === deviceId);
  if (ap) {
    return ap.UnitId;
  }

  const intf = manifest.deviceInterfaces.find((e) => e.ID === deviceId);
  if (intf) {
    return intf.UnitId;
  }

  const dev = manifest.triggers.find((e) => e.ID === deviceId);
  if (dev) {
    return dev.parentRoom?.RoomNumber ?? 0;
  }

  return 0;
};

const assembleData = (): void => {
  const tmp = cloneDeep(manifest.getRoutingEvents());
  data = [];
  tmp.forEach((t) => {
    const cg = careGroupUtils.getCareGroupIDFromSequenceId(t.CareSequenceId);
    const src = getAlarmDescription(t.AlarmTypeId);
    const alt = getAlarmDescription(t.AltAlarmTypeId);
    const alarm = src == alt ? alt : src + ' : ' + alt;

    let identity = '';
    const trig = manifest.triggers.find((f) => f.ID == t.AlarmSourceDeviceId);
    const dev = manifest.getDevice(t.AlarmSourceDeviceId);

    if (trig) {
      identity = trig.getIdentity();
    } else if (dev) {
      console.log('REP', dev);
      const dtmp = Device.MACaddrToString(dev.MacAddress, 16);
      identity = dtmp.substring(dtmp.length - 6);
    }

    if (t.AltAlarmTypeId != ALARM_TYPES.ALARM_TYPE_NO_ALARM_EVENT) {
      data.push({
        ...t,
        alarm: alarm,
        unitId: getUnitId(t.AlarmSourceDeviceId),
        name: getName(t.AlarmSourceDeviceId),
        callSequence: cg ? cg.Description : '',
        macAddress: identity,
      });
    }
  });

  data.sort(function (a, b) {
    return a.unitId - b.unitId;
  });
};

// rename this to the specific report name
export const generateAlarmCallSequencesReport = async (): Promise<void> => {
  extended = UserUtils.minimumAccessLevel(UserUtils.UAG.ELITE);
  createColumns();

  const alarmCSReportDetails: iExcelSchema = {
    reportName: 'alarmCallSequencesReport', // name of the report in the tables export button that the user clicks on
    reportDescription: 'Alarm Call Sequence Usage', // explanation of the report
    filteredData: false, // whether to use a tables filtered data or all data true=filtered
    fileName: 'Alarm Call Sequence Usage', // excel file name to create
    workSheetName: 'Call Sequences', // worksheet name
    title: 'Alarm Call Sequence Usage', // Title on worksheet
    columns: reportColumns, // column information
    headerRows: alarmCSHeaderRows,
  };

  assembleData();
  await createExcel(alarmCSReportDetails, data);
};
