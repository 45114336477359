import TablePaged from 'components/tables/TablePaged';
import React, { useState } from 'react';
import Tile, { iTileEvent } from '../tile';
import * as utils from 'common/utils/dateUtils';
import { Text } from 'grommet';
import { Room } from 'components/installation/room';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest } from '../../installation/manifest';

/*
import * as Icons from 'grommet-icons';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';
import store from 'store/store';
import * as manifestSlice from 'store/manifestSlice';
*/

const ResidentNotOKOverview = (): JSX.Element => {
  interface iMonitoringInfo {
    numberEnabled: number;
    numberDisabled: number;
    to: string;
    from: string;
  }

  const [, setMonitoringInfo] = useState<iMonitoringInfo>({
    numberEnabled: 0,
    numberDisabled: 0,
    to: '',
    from: '',
  });

  const [, SetChildEvent] = useState('');
  const [dataloaded, setDataloaded] = useState<number>(0);
  const manifest = new Manifest();

  function updateInfo() {
    if (
      manifest.rooms &&
      manifest.systemParameters &&
      manifest.rooms.length > 0
    ) {
      const info: iMonitoringInfo = {
        numberEnabled: 0,
        numberDisabled: 0,
        to: '',
        from: '',
      };
      for (const index in manifest.rooms) {
        const room = manifest.rooms[index];
        if (room.EnableOK) {
          info.numberEnabled++;
        } else {
          info.numberDisabled++;
        }
      }

      const sp_startHour = manifest.getSystemParameter(
        'RESIDENT_OK_START_HOUR'
      );
      const sp_startMinute = manifest.getSystemParameter(
        'RESIDENT_OK_START_MINUTE'
      );
      const sp_stopHour = manifest.getSystemParameter('RESIDENT_OK_STOP_HOUR');
      const sp_stopMinute = manifest.getSystemParameter(
        'RESIDENT_OK_STOP_MINUTE'
      );

      if (sp_startHour && sp_startMinute && sp_stopHour && sp_stopMinute) {
        info.from =
          sp_startHour.Value.padStart(2, '0') +
          ':' +
          sp_startMinute.Value.padStart(2, '0');
        info.to =
          sp_stopHour.Value.padStart(2, '0') +
          ':' +
          sp_stopMinute.Value.padStart(2, '0');
      }

      setMonitoringInfo(info);
      setDataloaded(dataloaded + 1);
    }
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        updateInfo();
      },
      executeOnStartup: true,
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        id: 'RoomNumber',
        Header: 'Room',
        accessor: 'RoomNumber',
      },
      {
        Header: 'Name',
        accessor: 'Name',
      },
      {
        Header: 'Last Ok Press',
        accessor: (row: Room) => {
          //row.LastOK ? new Date(row.LastOK) : undefined,
          if (!row.EnableOK) {
            return '';
          } else if (row.LastOK > 0) {
            const date = new Date();
            date.setTime(row.LastOK * 1000);
            return utils.formatDate(date.toString());
          } else {
            return 'never';
          }
        },
      },
    ],
    []
  );

  const initialState = React.useMemo(
    () => ({
      pageSize: 10,
      sortBy: [
        {
          id: 'RoomNumber',
          desc: false,
        },
      ],
      filters: [
        {
          id: 'OK',
          value: 'No',
        },
      ],
    }),
    []
  );

  return (
    <Tile
      title="Residents who haven't pressed OK"
      eventListeners={events}
      setChildEvent={SetChildEvent}
    >
      <>
        {dataloaded > 0 && (
          <div>
            <Text>
              Last updated{' '}
              {utils.formatDateWithSeconds(manifest.manifestUpdated)}
            </Text>

            <TablePaged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={columns}
              initialState={initialState}
              data={manifest.rooms.filter(
                (room) => room.EnableOK && !room.FlagOK
              )}
              showCheckboxes={false}
              reportDescription={{
                header: '',
                filename: 'ResidentsNotOk',
              }}
            />
          </div>
        )}
      </>
    </Tile>
  );
};

export default ResidentNotOKOverview;
