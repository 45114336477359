import { Manifest } from 'components/installation/manifest';

export const globalOkStart = (manifest: Manifest): string => {
  const sp_startHour = manifest.getSystemParameter('RESIDENT_OK_START_HOUR');
  const sp_startMinute = manifest.getSystemParameter(
    'RESIDENT_OK_START_MINUTE'
  );

  if (sp_startHour && sp_startMinute)
    return (
      sp_startHour.Value.padStart(2, '0') +
      ':' +
      sp_startMinute.Value.padStart(2, '0')
    );

  return '';
};

export const globalOkStop = (manifest: Manifest): string => {
  const sp_stopHour = manifest.getSystemParameter('RESIDENT_OK_STOP_HOUR');
  const sp_stopMinute = manifest.getSystemParameter('RESIDENT_OK_STOP_MINUTE');

  if (sp_stopHour && sp_stopMinute) {
    return (
      sp_stopHour.Value.padStart(2, '0') +
      ':' +
      sp_stopMinute.Value.padStart(2, '0')
    );
  }

  return '';
};

export const globalOkPeriod = (manifest: Manifest): string => {
  return globalOkStart(manifest) + ' to ' + globalOkStop(manifest);
};

export const globalInactivityStart = (manifest: Manifest): string => {
  const sp_startHour = manifest.getSystemParameter('INACTIVITY_ON_HOUR');
  const sp_startMinute = manifest.getSystemParameter('INACTIVITY_ON_MINUTE');

  if (sp_startHour && sp_startMinute)
    return (
      sp_startHour.Value.padStart(2, '0') +
      ':' +
      sp_startMinute.Value.padStart(2, '0')
    );

  return '';
};

export const globalInactivityStop = (manifest: Manifest): string => {
  const sp_stopHour = manifest.getSystemParameter('INACTIVITY_OFF_HOUR');
  const sp_stopMinute = manifest.getSystemParameter('INACTIVITY_OFF_MINUTE');

  if (sp_stopHour && sp_stopMinute) {
    return (
      sp_stopHour.Value.padStart(2, '0') +
      ':' +
      sp_stopMinute.Value.padStart(2, '0')
    );
  }

  return '';
};

export const globalInactivityPeriod = (manifest: Manifest): string => {
  return (
    globalInactivityStart(manifest) + ' to ' + globalInactivityStop(manifest)
  );
};
