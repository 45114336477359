/**
 * @module SiteLists
 */

import store from 'store/store';
import { OmniviaSite } from 'types/API';
import * as siteSlice from 'store/sitesSlice';

export let allSites: OmniviaSite[] = [];
export let selectedSite: OmniviaSite | null = null;

/**
 * Call to select a site.  On this call the followng will automatically happen from within the DataManagerProvider:
 *    1. Floor plan loaded
 *    2. SCUs loaded and the 1st in the list loaded
 *    3. SCU manifest loaded
 *    4. SCU faults loaded
 *    5. SCU events loaded
 *    6. Battery log loaded
 *    7. Battery location events loaded
 *
 * @param siteId : id in the form b09644cd-cf82-4e0f-957f-81dde7be3c93
 */
export const select = (siteId: string): void => {
  store.dispatch(siteSlice.select({ id: siteId }));
};

/**
 *
 * This function is called from the DataManagerProvider when the
 * site lists is loaded.  This happens on start-up or anytime the
 * fetch function is called.
 *
 * @param locationEvents: list of location events
 * @param selectedSCUId: the currently selected SCUID  (serial number)
 * @return: the number of events loaded for both nonAlarm and alarm
 */
export const load = (siteList: OmniviaSite[]): number => {
  //console.info('Sites loaded:', siteList);
  allSites = siteList;

  return allSites.length;
};

export const fetch = (): void => {
  store.dispatch(siteSlice.fetch());
};

/**
 * Called from the DataManagerProvider when a site has been selected
 * and the associated SCU data has been loaded from the database
 * @param site Site object or undefined/null if
 * @returns id of the selection or null if non selected
 */
export const selectionHasChanged = (
  site: OmniviaSite | undefined
): string | null => {
  console.info('Sites selection changed:', site);
  if (site) {
    selectedSite = site;
    return selectedSite.id;
  } else {
    selectedSite = null;
    return null;
  }
};

export const siteHasSCUAssigned = (): boolean => {
  return siteSlice.siteHasSCUAssigned();
};
