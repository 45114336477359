import { tePROCESSOR } from './IoTMessageDefines_DEVICES';
import {
  teCLOUD_MSG_TY_SYSTEM,
  tsCLOUD_MSG_SYSTEM_RESET_REQ,
} from './IoTMessageDefines_SYSTEM';
import {
  teCLOUD_ERROR_CODES,
  teCLOUD_MESSAGE_TYPES,
} from './IoTMessageDefines';

import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';
//import logger from 'common/logger';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

export { teCLOUD_ERROR_CODES as teCLOUD_ERROR_CODES };

export async function scuRestartTunnel(
  userData: unknown
): Promise<iIotResponseEventDetail> {
  console.info('scuRestartTunnel');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM;
  const opcode = teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_RESTART_TUNNEL_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_RESTART_TUNNEL_RESP;
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    {},
    defaultTimeout,
    'scuRestartTunnel',
    userData
  );
}
