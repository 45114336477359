/** @module FloorPlans */

import { iPlan } from 'types/plan-types';

/**
 * Creates a plan containg the plan image url and the plan scale, offsets etc
 * @class
 * @implements {iPlan}
 * @hideconstructor
 */
export class Plan implements iPlan {
  /** friendly name of the plan */
  planName = '';
  planNotes = '';
  planImageFileName = '';
  planImageURL = '';
  Xoffset = 0;
  Yoffset = 0;
  scale = 0.4;
  equipX = 1300;
  equipY = 100;
  //constructor() {}

  /** store plan */
}
