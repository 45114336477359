import {
  convertSingleArrayToKeyValue,
  iField,
  iSelectKeyValue,
} from 'components/forms/formFields';
import * as UserUtils from 'common/userUtils';
import { Manifest } from 'components/installation/manifest';
import { getRoomListForLinks } from '../../../installation/manifestRoomUtils';

// ID = invalidID;
// Name = '';
// DefaultCareGroupId = 0;
// DefaultTechnicalCareGroupId = 0;
// HomeDeviceId = -1;
// RoomNumber = -1;
// Volume: 0 | 1 | 2 | 3 | 4 = 0;
// ToneVolume: 0 | 1 | 2 | 3 | 4 = 0;
// MicVolume: 0 | 1 | 2 | 3 | 4 = 0;
// Inactivity = 0;
// Intruder = 0;
// Empty = 0;
// EnableOK = 0;
// LastOK = 0;
// FlagOK = 0;
// AwayState = 0;
// LinkedRoomNumber = 0;
// WifiMode: 0 | 1 | 2 = 0;
// MetaData: JSON | null = null;
// LastSpokenTo = 0;
// LastSeen = 0;
// OKTimeLeftBeforeReminder = 3600;
// OKReminderPeriod = 180;
// RaiseResidentOKAlarm = 0;
/** Added in R5.12 */
//  TestModeEnabledEPOCH = 0;
//  IntruderStateChangeEPOCH = 0;
//  IntruderState = 0;
//  EntryExitTimeout = 30;
//  DivertDoorToManager = 0;
//  HomeAwaySetIntruder = 1;
//  AwayDivertDoorToManager = 0;
/* iRoom end */

export interface iRoomForm {
  ID: number;
  Name: string;
  DeviceID: number;
  CareGroup: string;
  RoomNumber: number;
  AwayState: boolean;
  AwayDescription: string;
  EnableOK: boolean;
  Inactivity: boolean;
  Intruder: boolean;
  Empty: boolean;
  Communal: boolean;
  PollingActive: boolean;
  tags: string;
  lastOKPress: string;
  speechVolume: string;
  toneVolume: string;
  LinkedRoomNumber: number;
  okAudibleReminders: boolean;
  okReminderFrequency: string;
  okReminderBeforeEnd: string;
  RaiseResidentOKAlarm: boolean;
  TestModeEnabledEPOCH: number;
  IntruderEntryExitTimeout: number;
  DivertDoorToManager: boolean;
  HomeAwaySetIntruder: boolean;
  AwayDivertDoorToManager: boolean;
}

export const roomForm = (
  careGroups: iSelectKeyValue[],
  room: iRoomForm | undefined,
  m: Manifest
): iField[] => {
  const SCU5110 = m.isInSoftwareVersionRange({ min: '5.11.0' });
  const SCU5120 = m.isInSoftwareVersionRange({ min: '5.12.0' });

  const rooms = getRoomListForLinks(m, room?.RoomNumber);

  return [
    { label: 'Name', id: 'Name', type: 'text', column: 1 },
    {
      label: 'Status',
      id: 'AwayDescription',
      type: 'text',
      column: 1,
      readOnly: true,
    },

    // {
    //   label: '',
    //   id: '',
    //   type: 'blank',
    //   column: 1,
    // },
    {
      label: 'Call Sequence',
      id: 'CareGroup',
      type: 'select',
      options: careGroups,
      column: 1,
      readOnly: false,
      tip: 'A Call Sequence is where alarms will be sent to. Changing the Call Sequence will change the Call Sequence for the room unit and apply it to all of its inputs and sensors',
    },

    {
      label: 'Speech Volume',
      id: 'speechVolume',
      type: 'select',
      options: convertSingleArrayToKeyValue(['1', '2', '3', '4', '5']),
      column: 1,
    },
    {
      label: 'Tone Volume',
      id: 'toneVolume',
      type: 'select',
      options: convertSingleArrayToKeyValue(['1', '2', '3', '4', '5']),
      column: 1,
    },
    {
      label: 'Linked Room Unit',
      id: 'LinkedRoomNumber',
      type: 'select',
      options: rooms,
      column: 1,
      tip: 'Linking is used when a room contains two room units. Both room units will ring when a visitor calls the room. It can also be used to link a room unit to the managers handset so that visitor door calls to the managers handset also present on the room unit. Only one room unit can be linked to the managers handset.',
      readOnly: false,
    },

    {
      label: 'Intruder Monitoring',
      id: 'Intruder',
      type: 'checkbox',
      column: 2,
      readOnly: !SCU5120,
    },
    {
      label: 'Allow Home/Away Arming',
      id: 'HomeAwaySetIntruder',
      type: 'checkbox',
      column: 2,
      tip: 'If enabled then pressing AWAY will arm the Intruder system and pressing HOME will disarm the intruder system.',
      readOnly: !SCU5120 || !room?.Intruder,
    },
    {
      label: 'Intruder Entry/Exit Timeout',
      id: 'IntruderEntryExitTimeout',
      type: 'text',
      validate: /^[0-9]+$/,
      formBoxWidth: '60px',
      tip: 'How long in seconds that the resident has to exit and enter the property when the intruder system is armed.',
      column: 2,
      readOnly: !SCU5120 || !room?.Intruder,
    },
    {
      label: 'Inactivity Monitoring',
      id: 'Inactivity',
      type: 'checkbox',
      column: 2,
      readOnly: !SCU5110,
    },

    {
      label: 'Empty Property',
      id: 'Empty',
      type: 'checkbox',
      column: 2,
      readOnly: !SCU5110,
    },
    {
      label: 'Communal Room',
      id: 'Communal',
      type: 'checkbox',
      column: 2,
      readOnly: !SCU5110,
    },
    {
      label: 'Polling Active',
      id: 'PollingActive',
      type: 'checkbox',
      column: 2,
      tip: 'Enables/Disables Polling of the Room Unit',
      readOnly: !UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL),
    },

    {
      label: 'Enable Ok Monitoring',
      id: 'EnableOK',
      type: 'checkbox',
      column: 3,
      tip: 'Enables/Disables Ok Monitoring for this resident',
    },
    {
      label: 'Raise Alarm if not OK',
      id: 'RaiseResidentOKAlarm',
      type: 'checkbox',
      column: 3,
      tip: 'Raises an alarm at the end of the OK period if the OK button was not pressed.',
      readOnly: !SCU5110,
    },
    {
      label: 'Enable OK Audible Reminders',
      id: 'okAudibleReminders',
      type: 'checkbox',
      column: 3,
      tip: 'A routine reminder message will play when the resident needs to press ok',
      readOnly: !room?.EnableOK,
      hide: !SCU5110,
    },
    {
      label: 'Reminder Frequency (minutes)',
      id: 'okReminderFrequency',
      validate: /^[0-9]+$/,
      type: 'text',
      formBoxWidth: '60px',
      column: 3,
      tip: 'How often the reminder message will play',
      readOnly: !room?.EnableOK || !room?.okAudibleReminders,
      hide: !SCU5110,
    },
    {
      label: 'Reminder Play From End (minutes)',
      id: 'okReminderBeforeEnd',
      validate: /^[0-9]+$/,
      type: 'text',
      formBoxWidth: '60px',
      column: 3,
      tip: 'How many minutes before the end of monitoring that the reminders will start. This is useful if the period starts early in the morning and you do not wish to disturb the resident until towards the end of the monitoring',
      readOnly: !room?.EnableOK || !room?.okAudibleReminders,
      hide: !SCU5110,
    },
    {
      label: 'Divert Door Calls',
      id: 'DivertDoorToManager',
      type: 'checkbox',
      column: 3,
      readOnly: !SCU5120,
      tip: 'If enabled then any door calls to resident will be diverted to a handset or Alarm Receiving Centre',
    },
    {
      label: 'Divert Door Calls when Away',
      id: 'AwayDivertDoorToManager',
      type: 'checkbox',
      column: 3,
      readOnly: !SCU5120 || room?.DivertDoorToManager,
      tip: 'If enabled then any door calls whilst the resident is AWAY will be diverted to a handset or Alarm Receiving Centre',
    },
  ];
};
