import React, { useState, useEffect } from 'react';
import { Box, Button, Layer, Paragraph, Form } from 'grommet';
import * as IotDeviceMgmt from 'common/IoT/Helpers/DeviceManagement';
import * as Wait from 'components/dialogues/waitDialogue';
import { Dispatch } from 'react';
import * as FormFields from 'components/forms/formFields';
import { Manifest } from 'components/installation/manifest';

//import useStateRef from 'react-usestateref';

interface iHandsetAssignDialogue {
  show: boolean;
  setShow: Dispatch<boolean>;
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>;
}

interface iHandsetAddFields {
  product: string;
  mac: string;
  name: string;
}

const blank: iHandsetAddFields = {
  product: 'PROD-3222',
  mac: '',
  name: '',
};

const m_ProductsToList: FormFields.iSelectKeyValue[] = [
  { value: 'EVOLUTION+', key: 'PROD-3222' }, // Omnivia WiFI Handset
];

const manifest = new Manifest();

const HandsetAssignDialogue = (props: iHandsetAssignDialogue): JSX.Element => {
  const [peripheralInfo, setPeripheralInfo] =
    useState<iHandsetAddFields>(blank);

  const _fields: FormFields.iField[] = [
    {
      label: 'Type',
      id: 'product',
      type: 'select',
      options: m_ProductsToList,
    },
    { label: 'Identifier', id: 'mac', type: 'text', formBoxWidth: 'small' },
    { label: 'Name', id: 'name', type: 'text' },
  ];

  const [fields, setFields] = useState<FormFields.iField[]>(_fields);

  const addClicked = async () => {
    console.info('device to assign:', peripheralInfo);
    let mac = peripheralInfo.mac;

    if (peripheralInfo.mac.startsWith('0x') == false) {
      mac = '0x' + peripheralInfo.mac;
    }

    const macNumber = parseInt(mac);
    console.info(`Adding max:${macNumber}`);

    const res = await IotDeviceMgmt.addEvolutionPlusHandset(
      macNumber,
      peripheralInfo.name,
      props.setDialogueProps
    );

    if (res.ok) {
      props.setDialogueProps(undefined);
      props.setShow(false);
    }
  };

  // Clear the previous settings
  useEffect(() => {
    if (props.show) {
      setPeripheralInfo(blank);
      setFields(_fields);

      if (manifest.handsets.length > 32) {
        props.setDialogueProps({
          show: true,
          dialogueText: `The maximum number of handsets has been reached? Please delete a handset first.`,
          showSpinner: false,
          showOkButton: false,
          showCancelButton: true,
          cancelButtonPress: () => {
            props.setDialogueProps(undefined);
            props.setShow(false);
          },
        });
      }
    }
  }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.show && (
        <Layer
          className={'waitdialog'}
          background="dialogBackground"
          style={{ whiteSpace: 'pre-wrap' }}
          modal={true}
        >
          <Box pad="small" gap="small" align="center">
            <Paragraph textAlign="center">
              To assign a new handset, please enter the details below and press
              assign. The handset Identifier can be located in the
              handset&apos;s About menu.
            </Paragraph>

            <Form<iHandsetAddFields>
              value={peripheralInfo}
              onChange={(nextValue) => {
                setPeripheralInfo(nextValue);
              }}
              onSubmit={async () => {
                console.info('Selected: ', peripheralInfo);
                props.setDialogueProps({
                  show: true,
                  dialogueText: `Are you sure you want to assign a ${peripheralInfo.mac}?`,
                  showSpinner: false,
                  showOkButton: true,
                  okButtonPress: () => {
                    addClicked();
                  },
                  showCancelButton: true,
                  cancelButtonPress: () => {
                    props.setDialogueProps(undefined);
                  },
                });
              }}
            >
              <Box direction="column">
                <FormFields.AlignedFormFields
                  displayfields={fields}
                  labelWidth="55px"
                />
              </Box>

              <Box direction="row" gap="medium" justify="center">
                <Button
                  label="Assign"
                  size="medium"
                  plain={false}
                  margin="small"
                  type="submit"
                />
                <Button
                  label="Cancel"
                  size="medium"
                  plain={false}
                  margin="small"
                  onClick={() => {
                    console.info('Cancel');
                    props.setShow(false);
                    props.setDialogueProps(undefined);
                  }}
                />
              </Box>
            </Form>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default HandsetAssignDialogue;
