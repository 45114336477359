import React, { useState } from 'react';
import useStateRef from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps } from 'components/dashboard/tile';
import { Button, Box } from 'grommet';

import { Manifest } from 'components/installation/manifest';
import * as eventDispatcher from 'store/eventDispatcher';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import TablePaged from 'components/tables/TablePaged';
import { Column } from 'react-table';
import * as UserUtils from 'common/userUtils';
import * as Icons from 'grommet-icons';
import { iRoutingEvent } from 'types/manifest-types';
import * as eventEnums from 'types/event-enums';
import * as careGroupUtils from 'components/installation/careGroupUtils';
import { getAlarmDescription } from 'common/alarmTypesTextFiltered/alarmTypeUtils';

const manifest = new Manifest();

declare type iTableData = iRoutingEvent;

let _allData: iTableData[] = [];

const RoutingEventsTable = (): JSX.Element => {
  //const logger = useWinstonLogger();
  const [, SetChildEvent] = useState('');
  const [selected, setSelected] = useState<iTableData | undefined>();

  const [dialogueProps] = useState<Wait.DialogueProps | undefined>();
  const [, setRenderCount] = useState(0);

  const loadData = () => {
    _allData = _.cloneDeep(manifest.getRoutingEvents());
    setRenderCount((old) => old + 1);
  };

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: () => {
        loadData();
      },
      executeOnStartup: true,
    },
  ];

  const getName = (deviceId: number) => {
    const room = manifest.getRoomByDeviceID(deviceId);
    if (room) {
      return room.Name;
    }

    const ap = manifest.accessPoints.find((e) => e.ID === deviceId);
    if (ap) {
      return ap.Name;
    }

    const intf = manifest.deviceInterfaces.find((e) => e.ID === deviceId);
    if (intf) {
      return intf.Name;
    }

    const dev = manifest.triggers.find((e) => e.ID === deviceId);
    if (dev) {
      return dev.getDeviceDescription();
    }

    return 'unknown';
  };

  const getUnitId = (deviceId: number) => {
    const room = manifest.getRoomByDeviceID(deviceId);
    if (room) {
      return room.RoomNumber;
    }

    const ap = manifest.accessPoints.find((e) => e.ID === deviceId);
    if (ap) {
      return ap.UnitId;
    }

    const intf = manifest.deviceInterfaces.find((e) => e.ID === deviceId);
    if (intf) {
      return intf.UnitId;
    }

    return '-';
  };

  /**
   * The aim of all the cell components is that they do not render unnecessarily
   * All the cell components use React.memo to memorise the component.
   *
   * React.memo will only re-generate the compoent if a prop changes therefore we pass a reRenderValue prop
   * to the component to allow us to signal to React.memo that it needs to be re-rendered.
   *
   * The components use callback to set/get their data and not state varibles
   */
  const columns = React.useMemo<Column<iTableData>[]>(
    () => [
      {
        id: 'ID',
        Header: 'ID',
        accessor: (row) => row.ID,
        width: '10px',
      },
      {
        id: 'AlarmTypeId',
        Header: 'AlarmTypeId',
        accessor: (row) =>
          `${getAlarmDescription(row.AlarmTypeId)} (${row.AlarmTypeId})`,
      },
      {
        id: 'AltAlarmTypeId',
        Header: 'AltAlarmTypeId',
        accessor: (row) =>
          `${getAlarmDescription(row.AltAlarmTypeId)} (${row.AltAlarmTypeId})`,
      },
      {
        id: 'AlarmSourceDeviceId',
        Header: 'AlarmSourceDeviceId',
        accessor: 'AlarmSourceDeviceId',
      },
      {
        id: 'Unit #',
        Header: 'Unit #',
        accessor: (row) => {
          return getUnitId(row.AlarmSourceDeviceId);
        },
      },
      {
        id: 'Device',
        Header: 'Device',
        accessor: (row) => {
          return getName(row.AlarmSourceDeviceId);
        },
      },
      {
        id: 'CareGroup',
        Header: 'CareGroup',
        accessor: (row) => {
          const cg = careGroupUtils.getCareGroupIDFromSequenceId(
            row.CareSequenceId
          );
          return `${cg?.Description} (CG:${cg?.ID}  CS:${row.CareSequenceId})`;
        },
      },
      {
        id: 'SpeechCfg',
        Header: 'SpeechCfg',
        accessor: 'SpeechCfg',
      },
      {
        id: 'IsTechnical',
        Header: 'IsTechnical',
        accessor: 'IsTechnical',
      },

      {
        id: 'GuardPeriod',
        Header: 'GuardPeriod',
        accessor: 'GuardPeriod',
      },
      {
        id: 'PreAlarmDelay',
        Header: 'PreAlarmDelay',
        accessor: 'PreAlarmDelay',
      },
      {
        id: 'Covert',
        Header: 'Covert',
        accessor: 'Covert',
      },
      {
        id: 'AlarmDisabled',
        Header: 'AlarmDisabled',
        accessor: 'AlarmDisabled',
      },
    ],
    [_allData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL)
        ? []
        : [],
      pageSize: 14,
      sortBy: [
        {
          id: 'ID',
          desc: false,
        },
      ],
    }),
    []
  );

  const ContextButtons = <Box direction="row" gap="large"></Box>;

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Refresh',
          icon: <Icons.Refresh size="medium" />,
          onClick: () => {
            loadData();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const select = async (
    rowData: iTableData,
    selectionInfo: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (!selectionInfo.alreadySelected) {
      const record = _allData.find((e) => e.ID === rowData.ID);
      setSelected(record);
    } else {
      setSelected(undefined);
    }
  };

  return (
    <Tile
      title="Routing Events"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={_allData ? _allData : []}
          showCheckboxes={false}
          showControls={true}
          selectorKeyName="ID"
          onLastSelectedRow={select}
          currentSelectedID={selected?.ID}
        />
      </>
    </Tile>
  );
};

export default RoutingEventsTable;
