import React, { useState, useEffect } from 'react';
import { Box, Button, Layer, Text, Form, Tabs, Tab } from 'grommet';
import {
  AlignedFormFields,
  convertSingleArrayToKeyValue,
  iField,
  iSelectKeyValue,
} from 'components/forms/formFields';

import { iRoutingEvent } from 'types/manifest-types';
import * as eventEnums from 'types/event-enums';
import { Manifest } from 'components/installation/manifest';
import {
  getAllCareGroups,
  getCareGroupIDFromSequenceId,
  getCareGroupSequenceId,
} from 'components/installation/careGroupUtils';
import {
  locations_keyvalues,
  teRU_NOTIFY_FLAG_BITS,
  teSCHEDULES_OBJECT_TYPES,
} from 'types/manifest-enums';
import ScheduleEdit from './schedules/scheduleEdit';
import { Device } from 'components/installation/device';
import { iREDialogShowProps } from '../desktopWidgets/resident/resident';
import * as UserUtils from 'common/userUtils';
import { Schedule } from 'components/installation/schedule';
import { getAlarmDescription } from 'common/alarmTypesTextFiltered/alarmTypeUtils';

const m = new Manifest();

interface iREForm {
  ID: number;
  alarmTypeId: string;
  alarmTypeDescription: string;
  altAlarmTypeId: string;
  altAlarmTypeDescription: string;
  Covert: boolean;
  GuardPeriod: number;
  PreAlarmDelay: number;
  CareGroupId: string;
  Inactivity: boolean;
  Intruder: boolean;
  IntruderEntry: boolean;
  Polarity: string;
  priority: number;
  AlarmDisabled: boolean;
}

interface iREFormMeta {
  deviceLocation: string;
}

export interface iREDialogProps {
  productCode: string; // used to cover any subtle inpout configuration differences between products
  device: Device | undefined;
  events: iRoutingEvent[]; // allow multiple as for example a MAP needs two events in the routing table for an input
  message: string; // displayed at the top of the dialog
  wirelessSensor: boolean; // whether we are editing a wireless sensor or not, determines which form fields we show
  alarmTypes?: iSelectKeyValue[]; // a specific list of alarm types to display, if not supplied then the simplified list is used
  save: (wirelessSensor: boolean, events: iRoutingEvent[]) => void;
  changes?: (s: boolean) => void; // called if changes occur, for example to schedules
  show: boolean;
  activeTab?: number;
  setShow: (props: iREDialogShowProps) => void;
  disabled: boolean;
}

const RoutingEventEdit = (props: iREDialogProps) => {
  const [values, setValues] = useState<{
    events: iREForm[];
    data: iREFormMeta;
  }>();
  const [careGroups, setCareGroups] = useState<iSelectKeyValue[]>([]);
  const [index, setIndex] = React.useState(0);
  const [schedules, setSchedules] = useState<Schedule[]>();

  const onActive = (nextIndex: number) => {
    setIndex(nextIndex);
    if (values) {
      setSchedules(
        m.getSchedulesByTypeAndId(
          teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_ROUTING_EVENT,
          values.events[nextIndex].ID
        )
      );
    }
  };

  const formFields = (i: number): iField[] => {
    let intruder = false;
    if (values) {
      intruder = values.events[i].Intruder;
    }
    const SCU5120 = m.isInSoftwareVersionRange({ min: '5.12.0' });
    return [
      {
        label: 'Alarm Type',
        id: `events[${i}].altAlarmTypeId`,
        type: 'select',
        formBoxWidth: '250px',
        options: props.alarmTypes
          ? props.alarmTypes
          : eventEnums.alarmTypes_SIMPLIFIED_keyvalues(),
        column: 1,
        hide: props.wirelessSensor,
      },
      {
        label: 'Call Sequence',
        id: `events[${i}].CareGroupId`,
        type: 'select',
        options: careGroups,
        column: 1,
        readOnly: false,
        tip: 'The call sequence determines where the alarms are sent to.',
      },
      {
        label: 'Polarity',
        id: `events[${i}].Polarity`,
        type: 'select',
        options: convertSingleArrayToKeyValue([
          'Normally Open',
          'Normally Closed',
        ]),
        column: 1,
        formBoxWidth: '180px',
        hide: props.wirelessSensor,
      },
      {
        label: 'Guard Period Time(s)',
        id: `events[${i}].GuardPeriod`,
        type: 'text',
        formBoxWidth: '60px',
        validate: /^[0-9]+$/,
        column: 1,
        readOnly: intruder,
        tip: 'Guard Period: The input must be active for the minimum guard period before an alarm is raised.',
      },
      {
        label: 'Pre-Alarm Delay(s)',
        id: `events[${i}].PreAlarmDelay`,
        type: 'text',
        validate: /^[0-9]+$/,
        formBoxWidth: '60px',
        column: 1,
        readOnly: intruder,
        tip: 'Pre Alarm Delay: Once the alarm is raised, the pre-alarm delay allows time for the user to cancel. After the pre-alarm delay period has expired the alarm then proceeds as per the care group.',
      },
      {
        label: 'Used for Inactivity',
        id: `events[${i}].Inactivity`,
        type: 'checkbox',
        column: 1,
        readOnly: false,
      },
      {
        label: 'Used for Intruder',
        id: `events[${i}].Intruder`,
        type: 'checkbox',
        column: 1,
        readOnly: false,
      },
      {
        label: 'Entry Sensor',
        id: `events[${i}].IntruderEntry`,
        type: 'checkbox',
        column: 1,
        readOnly: !intruder,
      },
      {
        label: 'Covert',
        id: `events[${i}].Covert`,
        type: 'checkbox',
        column: 1,
        readOnly: false,
        tip: 'If set to covert then there will be no audible or visible indication on the room unit.',
      },
      {
        label: 'Alarm Disabled',
        id: `events[${i}].AlarmDisabled`,
        type: 'checkbox',
        column: 1,
        readOnly:
          !SCU5120 || !UserUtils.minimumAccessLevel(UserUtils.UAG.TECHNICAL),
        tip: 'If set no alarm will be raised. The event will be still be used for intruder and inactivity monitoring. ',
      },
    ];
  };

  const locationFormFields = (): iField[] => {
    return [
      {
        label: 'Device Location',
        id: `data.deviceLocation`,
        type: 'select',
        options: locations_keyvalues(),
        column: 1,
        hide: !props.wirelessSensor,
      },
    ];
  };

  const amendRoutingEvents = async () => {
    // copy form info across to routing event object
    if (values && values.events) {
      const eventsToModify: iRoutingEvent[] = [];

      props.events.forEach((event) => {
        const e: iRoutingEvent = { ...event };
        const v = values.events.find((value) => value.ID === e.ID);

        if (v) {
          e.Covert = v.Covert ? 1 : 0;
          e.GuardPeriod = v.GuardPeriod;
          e.PreAlarmDelay = v.PreAlarmDelay;
          e.AlarmTypeId = Number(v.alarmTypeId);
          e.AltAlarmTypeId = Number(v.altAlarmTypeId);
          e.AlarmDisabled = v.AlarmDisabled ? 1 : 0;

          const flags =
            (v.Inactivity
              ? 1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_INACTIVITY
              : 0) +
            (v.Intruder
              ? 1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_INTRUDER
              : 0) +
            (v.IntruderEntry
              ? 1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_ENTRY
              : 0) +
            (v.Polarity == 'Normally Closed'
              ? 1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_POLARITY
              : 0);

          e.Flags = flags;
          e.CareSequenceId = getCareGroupSequenceId(Number(v.CareGroupId));

          eventsToModify.push(e);
        }
      });

      if (props.wirelessSensor && props.device) {
        props.device.Location = Number(values.data.deviceLocation);
      }
      props.save(props.wirelessSensor, eventsToModify);
    }

    props.setShow({ show: false, wirelessSensor: false, activeTab: 0 });
  };

  useEffect(() => {
    // console.log(props);
    const events: iREForm[] = [];
    if (props.events) {
      props.events.forEach((e) => {
        const r: iREForm = {
          ID: -1,
          alarmTypeId: '0',
          alarmTypeDescription: '',
          altAlarmTypeId: '0',
          altAlarmTypeDescription: '',
          Covert: false,
          GuardPeriod: 0,
          PreAlarmDelay: 0,
          CareGroupId: '-1',
          Inactivity: false,
          Intruder: false,
          IntruderEntry: false,
          Polarity: '',
          priority: 0,
          AlarmDisabled: false,
        };

        r.ID = e.ID;
        r.alarmTypeId = e.AlarmTypeId.toString();
        r.alarmTypeDescription = getAlarmDescription(e.AlarmTypeId);
        r.altAlarmTypeId = e.AltAlarmTypeId.toString();
        r.altAlarmTypeDescription = getAlarmDescription(e.AltAlarmTypeId);
        if (e.Covert) r.Covert = e.Covert === 1;
        if (e.AlarmDisabled) {
          r.AlarmDisabled = e.AlarmDisabled === 1;
        }

        r.GuardPeriod = e.GuardPeriod;
        r.PreAlarmDelay = e.PreAlarmDelay;
        r.CareGroupId =
          getCareGroupIDFromSequenceId(e.CareSequenceId)?.ID.toString() ?? '';

        r.Inactivity =
          (e.Flags &
            (1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_INACTIVITY)) >
          0;
        r.Intruder =
          (e.Flags & (1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_INTRUDER)) >
          0;
        r.IntruderEntry =
          (e.Flags & (1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_ENTRY)) > 0;
        r.Polarity =
          (e.Flags & (1 << teRU_NOTIFY_FLAG_BITS.RU_NOTIFY_FLAG_BIT_POLARITY)) >
          0
            ? 'Normally Closed'
            : 'Normally Open';
        r.priority = m.getAlarmPriority(e.AltAlarmTypeId) ?? 0;
        events.push(r);
      });

      // order the routing evenst by priority if a wireless sensor
      if (props.wirelessSensor) {
        events.sort((a, b) => b.priority - a.priority);
      }

      // console.log(
      //   'useEffect',
      //   events,
      //   props.events,
      //   props.device?.Location.toString() || '0'
      // );
      setValues({
        events: events,
        data: { deviceLocation: props.device?.Location.toString() || '0' },
      });

      let activeTab = index;

      if (props.activeTab != undefined && props.activeTab < events.length) {
        activeTab = props.activeTab;
      }

      if (events && events[activeTab])
        setSchedules(
          m.getSchedulesByTypeAndId(
            teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_ROUTING_EVENT,
            events[activeTab].ID
          )
        );

      setIndex(activeTab);
    }

    const cg = getAllCareGroups().map((c) => {
      return {
        key: c.careGroup.ID.toString(),
        value: c.careGroup.Description,
      };
    });
    // logger.debug('resident.load.room', { caregroups: cg });
    setCareGroups(cg);
  }, [props]);

  // console.log(values);

  return (
    <>
      {props.show && (
        <Layer position="right" style={{ whiteSpace: 'pre-wrap' }} modal={true}>
          <Box margin="medium" pad="small" gap="small" width="800px">
            <Text size="large">Configuration</Text>
            <Form
              value={values}
              onChange={(nextValue) => {
                setValues(nextValue);
                // console.log(values);
              }}
              // onReset={() => setValue({})}
              onSubmit={async () => {
                await amendRoutingEvents();
              }}
            >
              <Box border="bottom" pad={{ bottom: '8px' }}>
                <AlignedFormFields
                  displayfields={locationFormFields()}
                  columnLayouts={[
                    { columnPercentWidth: 50, labelWidth: '100px' },
                  ]}
                />
              </Box>
              <Text>{props.message}</Text>

              <Tabs activeIndex={index} onActive={onActive}>
                {values?.events.map((e, index) => (
                  <Tab key={index} title={e.alarmTypeDescription}>
                    <Box margin={{ bottom: '20px' }} direction="row">
                      <AlignedFormFields
                        displayfields={formFields(index)}
                        columnLayouts={[
                          { columnPercentWidth: 100, labelWidth: '140px' },
                        ]}
                      />
                      <Box width="500px">
                        <ScheduleEdit
                          schedules={schedules ?? []}
                          save={() => {
                            if (props.changes) props.changes(true);
                          }}
                          objectID={e.ID}
                          objectType={
                            teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_ROUTING_EVENT
                          }
                          disabled={false}
                        />
                      </Box>
                    </Box>
                  </Tab>
                ))}
              </Tabs>

              <Box direction="row" gap="medium" justify="center">
                <Button
                  label="Amend"
                  size="medium"
                  plain={false}
                  margin="small"
                  type="submit"
                />

                <Button
                  label="Cancel"
                  size="medium"
                  plain={false}
                  margin="small"
                  onClick={() => {
                    props.setShow({
                      show: false,
                      wirelessSensor: false,
                      activeTab: 0,
                    });
                  }}
                />
              </Box>
            </Form>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default RoutingEventEdit;
