import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import appUserReducer from './appUserSlice';
import clientsReducer from './clientsSlice';
import sitesReducer from './sitesSlice';
import scusReducer from './scusSlice';
import scuFaultsReducer from './scuFaultsSlice';
import manifestReducer from './manifestSlice';
import scuEventsReducer from './scuEventsSlice';
import sitePlanReducer from './sitePlanSlice';
import liveEventsLogReducer from './liveEventsLogSlice';

// read https://redux.js.org/tutorials/fundamentals/part-8-modern-redux as using redux-toolkit

const devTools = process.env.NODE_ENV === 'development' ? true : false;

export const store = configureStore({
  devTools: devTools,
  reducer: {
    appUser: appUserReducer,
    clients: clientsReducer,
    sites: sitesReducer,
    scus: scusReducer,
    scufaults: scuFaultsReducer,
    manifest: manifestReducer,
    scuevents: scuEventsReducer,
    siteplan: sitePlanReducer,
    liveeventslog: liveEventsLogReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //serializableCheck: false, // stops console error messages for appUserSlice data stored in redux
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
