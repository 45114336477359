import * as Site from 'components/floorplan/site';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';

//TODO: move data creation to cabling.ts when complete

/** Function to create an spreadsheet detailing the cable schedule */
const cablingXLSX = async (): Promise<void> => {
  const wb = new ExcelJS.Workbook();
  const sheets: ExcelJS.Worksheet[] = [];
  const fillColour = 'd58feb';

  Site.siteData.plans.forEach((p, index) => {
    sheets.push(wb.addWorksheet(p.planName));

    let Row = sheets[index].addRow([`Cable Schedule for ${p.planName}`]);
    Row.getCell(1).border = {
      top: { style: 'thick' },
      left: { style: 'thick' },
      bottom: { style: 'thick' },
      right: { style: 'thick' },
    };

    Row.getCell(1).style.font = {
      // set floor name to bold
      size: 12,
      bold: true,
    };

    Row = sheets[index].addRow([
      'ID',
      'Cable Spec',
      'From Plan',
      'From Equipment',
      'Tag',
      'To Plan',
      'To Equipment',
      'Tag',
      'Type',
      'Voltage',
      'Length(m)',
    ]);

    // set the width of columns
    sheets[index].getColumn(1).width = 10;
    sheets[index].getColumn(2).width = 34;
    sheets[index].getColumn(3).width = 24;
    sheets[index].getColumn(4).width = 40;
    sheets[index].getColumn(5).width = 14;
    sheets[index].getColumn(6).width = 24;
    sheets[index].getColumn(7).width = 40;
    sheets[index].getColumn(8).width = 14;
    sheets[index].getColumn(9).width = 10;
    sheets[index].getColumn(10).width = 12;
    sheets[index].getColumn(11).width = 14;

    for (let i = 1; i < 12; i++) {
      Row.getCell(i).style.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: fillColour },
      };
    }

    // Add SCU POE to other plan cabinet connections
    let id = 0;
    // decide what plan the SCU is on...
    const SCU = Site.siteData.equipment.find((f) => f.designator == 'SCU');
    const SCUPOE = Site.siteData.equipment.find(
      (f) => f.designator == 'POE' && f.plan == SCU?.plan
    );

    Site.siteData.equipment
      .filter((f) => f.designator == 'POE')
      .forEach((e) => {
        // add rows to connect all other POE cabinets to the SCU floor/plan
        if (index == SCU?.plan && e.plan != index) {
          const connectedTo = SCUPOE?.uniqueID;
          const connectedToProd = SCUPOE;
          const ToLocationName = connectedToProd?.locationName
            ? ' (' + connectedToProd?.locationName + ')'
            : '';
          const FromLocationName = e.locationName
            ? ' (' + connectedToProd?.locationName + ')'
            : '';

          id++;
          const row = [
            `S${index}${id}`,
            'CAT5e F/UTP or CAT6A F/UTP Shielded',
            p.planName + ToLocationName,
            `${connectedTo} ${connectedToProd?.name}`,
            `S${index}${id}-${e.uniqueID}`,
            Site.siteData.plans[e.plan].planName + FromLocationName,
            `${e.uniqueID} ${e.name}`,
            `S${index}${id}-${connectedTo}`,
            'Data',
            'None',
            'Unknown',
          ];
          sheets[index].addRow(row);
        }
      });

    // Add MAP Connections for general inputs .. if any
    id = 0;
    Site.getEquipmentOnThisPlanOnly(index).forEach((e) => {
      // add rows for all equipment with a MAP input connection
      if (e.MAPConnection && e.MAPRequired) {
        const connectedTo = e.MAPConnection.connectedTo;
        const isConnected = connectedTo;
        const connectedToProd = Site.getProductFromUniqueID(connectedTo);
        const ToLocationName = connectedToProd?.locationName
          ? ' (' + connectedToProd?.locationName + ')'
          : '';
        const FromLocationName = e.locationName
          ? ' (' + e?.locationName + ')'
          : '';

        id++;
        const row = [
          `MP${index}${id}`,
          'CAT5e/CAT6 generic cable or flex',
          Site.siteData.plans[e.plan].planName + FromLocationName,
          `${e.uniqueID} ${e.locationName} ${e.designNotes}`,
          isConnected ? `MP${index}${id}-${connectedTo}` : '**ERROR**',
          Site.siteData.plans[connectedToProd ? connectedToProd.plan : 0]
            .planName + ToLocationName,
          isConnected
            ? `${connectedTo} ${connectedToProd?.name}`
            : '**ERROR - UNCONNECTED**',
          `MP${index}${id}-${e.uniqueID}`,
          'Data',
          '12V',
          'Unknown',
        ];
        sheets[index].addRow(row);
      }
    });

    // Add MAI Connections for Lifts, VPC, Door etc
    id = 0;
    Site.getEquipmentOnThisPlanOnly(index).forEach((e) => {
      // add rows for all equipment with a CAT5 connection
      if (e.MAIConnection && e.MAIRequiredNumber > 0) {
        const connectedTo = e.MAIConnection.connectedTo;
        const isConnected = connectedTo;
        const connectedToProd = Site.getProductFromUniqueID(connectedTo);
        const ToLocationName = connectedToProd?.locationName
          ? ' (' + connectedToProd?.locationName + ')'
          : '';
        const FromLocationName = e.locationName
          ? ' (' + e?.locationName + ')'
          : '';
        // some interfaces require more than one CAT5 cable
        for (let i = 0; i < e.MAIRequiredNumber; i++) {
          id++;
          const row = [
            `MA${index}${id}`,
            'CAT5e F/UTP or CAT6A F/UTP Shielded',
            Site.siteData.plans[e.plan].planName + FromLocationName,
            `${e.uniqueID} ${e.name}`,
            isConnected ? `MA${index}${id}-${connectedTo}` : '**ERROR**',
            Site.siteData.plans[connectedToProd ? connectedToProd.plan : 0]
              .planName + ToLocationName,
            isConnected
              ? `${connectedTo} ${connectedToProd?.name}`
              : '**ERROR - UNCONNECTED**',
            `MA${index}${id}-${e.uniqueID}`,
            'Data',
            '12V',
            'Unknown',
          ];
          sheets[index].addRow(row);
        }
      }
    });

    // Add CAT5 Connections for POE and MLS
    id = 0;
    Site.getEquipmentOnThisPlanOnly(index).forEach((e) => {
      // add rows for all equipment with a CAT5 connection
      if (e.cat5Connection && e.cat5Required) {
        const connectedTo = e.cat5Connection.connectedTo;
        const isConnected = connectedTo;
        const connectedToProd = Site.getProductFromUniqueID(connectedTo);
        const ToLocationName = connectedToProd?.locationName
          ? ' (' + connectedToProd?.locationName + ')'
          : '';
        const FromLocationName = e.locationName
          ? ' (' + e?.locationName + ')'
          : '';
        id++;
        const row = [
          `P${index}${id}`,
          'CAT5e F/UTP or CAT6A F/UTP Shielded',
          Site.siteData.plans[e.plan].planName + FromLocationName,

          `${e.uniqueID} ${e.name}`,
          isConnected ? `P${index}${id}-${connectedTo}` : '**ERROR**',
          Site.siteData.plans[connectedToProd ? connectedToProd.plan : 0]
            .planName + ToLocationName,
          isConnected
            ? `${connectedTo} ${connectedToProd?.name}`
            : '**ERROR - UNCONNECTED**',
          `P${index}${id}-${e.uniqueID}`,
          'POE',
          '48V',
          'Unknown',
        ];
        sheets[index].addRow(row);
      }
    });

    // set each cell of each row to have a thin border
    sheets[index].eachRow((row) => {
      row.eachCell((cell: ExcelJS.Cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
  });

  wb.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: '' });
    FileSaver.saveAs(
      blob,
      `${Site.siteData.quoteRef} ${Site.siteData.name} - Wireless Health Cabling Schedule R${Site.siteData.revision}.xlsx` // set name of file
    );
  });
};

export default cablingXLSX;
