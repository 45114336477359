import TablePaged, { dateFilter } from 'components/tables/TablePaged';

import React, { useMemo, useState } from 'react';

import Tile, { iTileEvent } from '../tile';
import * as doorCalls from 'components/systemevents/doorEvents';
import {
  dateToIsoString_dateonly,
  formatDateWithSeconds,
  formatTimeOnly,
  dateWithinDateRange,
} from 'common/utils/dateUtils';
import {
  systemEventStates,
  systemEventTopics,
  emitEvent,
} from 'store/eventDispatcher';
import { Column, Row, Cell } from 'react-table';
import _ from 'lodash';
import DateRange from 'components/configuration/eventsLoadDataRange';
import * as Wait from 'components/dialogues/waitDialogue';
import { Text } from 'grommet';

const DoorCalls = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const [selectedRow, setSelectedRow] = useState<
    doorCalls.iDoorCall | undefined
  >();
  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const [data, setData] = useState<doorCalls.iDoorCall[]>([]);

  // EventType: number;
  //   RaisedDeviceID: number;
  //   AlarmID: number;
  //   RaisedTime: string;
  //   OwnerDeviceName: string;
  //   FriendlyString: string;

  const columns = React.useMemo<Column<doorCalls.iDoorCall>[]>(
    () => [
      {
        Header: 'Date/Time',
        id: 'RaisedTime',
        //accessor: (row: alarmEvents.iAlarmSummary) => formatDateWithSeconds(row.raisedAt),

        accessor: (row) => new Date(row.RaisedTime),
        sortType: 'datetime',
        filter: dateFilter,

        // Using the cell access so that the sortType: 'datetime' works
        Cell: (data: Cell<doorCalls.iDoorCall>) => {
          const date = data.value;
          if (date) return date ? formatDateWithSeconds(date) : 'Unknown';
        },

        getCellExportValue: (row: Row<doorCalls.iDoorCall>) => {
          return formatDateWithSeconds(row.original.RaisedTime);
        },
      },
      {
        Header: 'Alarm ID',
        accessor: 'AlarmID',
        width: 65,
      },
      {
        Header: 'Door Panel',
        accessor: 'OwnerDeviceName',
        Cell: (data: Cell<doorCalls.iDoorCall>) => (
          <Text truncate={true}>{data.row.original.OwnerDeviceName}</Text>
        ),
      },
      {
        Header: 'Call To',
        accessor: (row) => (row.CallData ? row.CallData.Name : ''),
        width: 100,
        Cell: (data: Cell<doorCalls.iDoorCall>) => (
          <Text truncate={true}>
            {data.row.original.CallData ? data.row.original.CallData.Name : ''}
          </Text>
        ),
      },
      {
        Header: 'Answered',
        accessor: (row) =>
          row.CallData && row.CallData.SelectTime
            ? formatTimeOnly(row.CallData.SelectTime)
            : 'No answer',
        width: 80,

        getCellExportValue: (row: Row<doorCalls.iDoorCall>) => {
          return row.original.CallData && row.original.CallData.SelectTime
            ? formatTimeOnly(row.original.CallData.SelectTime)
            : '';
        },
      },
      {
        Header: 'Answered by',
        accessor: (row) => (row.AnsweredBy ? row.AnsweredBy : ''),
        getCellExportValue: (row: Row<doorCalls.iDoorCall>) => {
          return row.original.CallData && row.original.CallData.SelectTime
            ? formatTimeOnly(row.original.CallData.SelectTime)
            : '';
        },
      },
      {
        Header: 'Door Opened',
        accessor: (row) => (row.CallData?.OpenedDoor == true ? 'yes' : 'no'),
        width: 100,
        getCellExportValue: (row: Row<doorCalls.iDoorCall>) => {
          console.info('Row:', row);
          return row.original.CallData &&
            row.original.CallData.OpenedDoor === true
            ? 'Yes'
            : 'No';
        },
      },
      {
        Header: 'Ended',
        accessor: (row) =>
          row.CallData ? formatTimeOnly(row.CallData.ClearTime) : '',
        width: 80,

        getCellExportValue: (row: Row<doorCalls.iDoorCall>) => {
          return row.original.CallData && row.original.CallData.ClearTime
            ? formatTimeOnly(row.original.CallData.ClearTime)
            : '';
        },
      },
    ],
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const loadDataEvent = () => {
    // we take a deep copy so any changes are detected and re-render occurs
    setData(_.cloneDeep(doorCalls.getDoorCalls()));
  };

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: [],
      pageSize: 10,
      sortBy: [
        {
          id: 'RaisedTime',
          desc: true,
        },
      ],
    }),
    []
  );

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.ALARMEVENTS,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: true,
    },
  ];

  const select = async (
    rowData: doorCalls.iDoorCall,
    selectionInfo: {
      rowid: number;
      columnNameClicked: string;
      alreadySelected: boolean;
    }
  ): Promise<void> => {
    if (!selectionInfo.alreadySelected) {
      setSelectedRow(rowData);
      emitEvent(
        systemEventTopics.DOOREVENTS,
        systemEventStates.SELECTED,
        rowData.AlarmID,
        true
      );
    }
  };

  const datedCalls = useMemo(() => {
    return data.filter((a) =>
      dateWithinDateRange(
        dateToIsoString_dateonly(start),
        dateToIsoString_dateonly(end),
        dateToIsoString_dateonly(new Date(a.RaisedTime))
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  return (
    <Tile
      title="Recent Door Calls"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      showLastEvent={true}
      waitDiaglogueProps={dialogueProps}
    >
      {/* <p>dummy</p> */}
      <>
        <DateRange
          setDialogueProps={setDialogueProps}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
        />
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={datedCalls}
          showCheckboxes={false}
          onLastSelectedRow={select}
          currentSelectedID={selectedRow?.AlarmID}
          selectorKeyName="AlarmID"
          reportDescription={{
            header: '',
            filename: 'RecentDoorCalls',
          }}
        />
      </>
    </Tile>
  );
};

export default DoorCalls;
