import React, { useState } from 'react';
import Tile, {
  iTileEvent,
  iMenuProps,
  contextButtonStyle,
} from 'components/dashboard/tile';
import { Form, FormField, TextInput, Button, Box, Text } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest, teErrorCode } from '../../../installation/manifest';
import * as Wait from 'components/dialogues/waitDialogue';
import _ from 'lodash';
import * as Icons from 'grommet-icons';
import { useWinstonLogger } from 'winston-react';
import {
  globalInactivityStop,
  globalInactivityStart,
  globalOkStart,
  globalOkStop,
} from './residentUtils';

interface iMonitoringInfo {
  toOk: string;
  fromOk: string;
  toInactivity: string;
  fromInactivity: string;
}
let unchangedConfig: iMonitoringInfo | undefined = undefined;

const GlobalMonitoringSettings = (): JSX.Element => {
  const logger = useWinstonLogger();
  const [monitoringInfo, setMonitoringInfo] = useState<iMonitoringInfo>({
    toOk: '',
    fromOk: '',
    toInactivity: '',
    fromInactivity: '',
  });

  const [, SetChildEvent] = useState('');
  const [dataloaded, setDataloaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueText, setDialogueText] = useState('');
  const manifest = new Manifest();

  function updateInfo() {
    if (manifest.systemParameters.length > 0) {
      const info: iMonitoringInfo = {
        toOk: '',
        fromOk: '',
        toInactivity: '',
        fromInactivity: '',
      };

      info.fromOk = globalOkStart(manifest);
      info.toOk = globalOkStop(manifest);
      info.fromInactivity = globalInactivityStart(manifest);
      info.toInactivity = globalInactivityStop(manifest);

      unchangedConfig = _.cloneDeep(info);
      setMonitoringInfo(info);
      setDataloaded(true);
    }
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: updateInfo,
      executeOnStartup: true,
    },
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: updateInfo,
      executeOnStartup: false,
    },
  ];

  function save(): void {
    logger.info('residentOKSettings.save');

    const fromOk = monitoringInfo.fromOk.split(':');
    const toOk = monitoringInfo.toOk.split(':');

    const sp_startHour = manifest.getSystemParameter('RESIDENT_OK_START_HOUR');
    const sp_startMinute = manifest.getSystemParameter(
      'RESIDENT_OK_START_MINUTE'
    );
    const sp_stopHour = manifest.getSystemParameter('RESIDENT_OK_STOP_HOUR');
    const sp_stopMinute = manifest.getSystemParameter(
      'RESIDENT_OK_STOP_MINUTE'
    );

    if (sp_startHour && sp_startMinute && sp_stopHour && sp_stopMinute) {
      sp_startHour.setFromSring(fromOk[0]);
      sp_startMinute.setFromSring(fromOk[1]);
      sp_stopHour.setFromSring(toOk[0]);
      sp_stopMinute.setFromSring(toOk[1]);
    }

    const fromInact = monitoringInfo.fromInactivity.split(':');
    const toInact = monitoringInfo.toInactivity.split(':');

    const i_startHour = manifest.getSystemParameter('INACTIVITY_ON_HOUR');
    const i_startMinute = manifest.getSystemParameter('INACTIVITY_ON_MINUTE');
    const i_stopHour = manifest.getSystemParameter('INACTIVITY_OFF_HOUR');
    const i_stopMinute = manifest.getSystemParameter('INACTIVITY_OFF_MINUTE');

    if (i_startHour && i_startMinute && i_stopHour && i_stopMinute) {
      i_startHour.setFromSring(fromInact[0]);
      i_startMinute.setFromSring(fromInact[1]);
      i_stopHour.setFromSring(toInact[0]);
      i_stopMinute.setFromSring(toInact[1]);
    }

    setDialogueText('Please wait.....');
    setSaving(true);
    setShowDialogue(true);

    const complete = (str: string) => {
      setDialogueText(str);
      setSaving(false);
    };

    let str = 'Save failed';
    manifest
      .save()
      .then((status) => {
        if (status === teErrorCode.E_OK) {
          str = 'Save Completed Successfully';
        } else if (status === teErrorCode.E_CONNECTION_FAIL) {
          str = 'Connection failed';
        }
        complete(str);
      })
      .catch((error) => {
        console.error('resident save fail:', error);
        complete(str);
      });
  }

  const configChanged = () => {
    return !_.isEqual(unchangedConfig, monitoringInfo);
  };

  const waitProps: Wait.DialogueProps = {
    show: showDialogue,
    dialogueText: dialogueText,
    showSpinner: saving,
    showOkButton: !saving,
    okButtonPress: () => {
      setShowDialogue(false);
    },
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={configChanged() ? 'brand' : 'grey-4'}
      badge={configChanged()}
      tip="Save changes"
      disabled={configChanged() ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Discard Changes',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            logger.info('residentOKSettings.discardChanges');
            updateInfo();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="Global Monitoring Settings"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={waitProps}
      ContextButtons={ContextButtons}
      menuProps={menuItems}
    >
      <>
        <p />
        {dataloaded && (
          <div>
            <Form
              value={monitoringInfo}
              onChange={(nextValue) => {
                setMonitoringInfo(nextValue);
              }}
            >
              <Box direction="row" pad="xsmall" justify="start">
                <Box width="220px" align="end">
                  <Text>{`Global I'm OK Monitoring Period`}</Text>
                </Box>
                <FormField name="from" label="from" direction="row">
                  <Box width="110px">
                    <TextInput
                      name="fromOk"
                      type="time"
                      // Set this style to make the time icon white
                    />
                  </Box>
                </FormField>
                <FormField name="to" label="to" direction="row">
                  <Box width="110px">
                    <TextInput
                      name="toOk"
                      type="time"
                      // Set this style to make the time icon white
                    />
                  </Box>
                </FormField>
              </Box>
              <Box direction="row" pad="xsmall" justify="center">
                <Box width="220px" align="end">
                  <Text>{`Global Inactivity Monitoring Period`}</Text>
                </Box>
                <FormField name="from" label="from" direction="row">
                  <Box width="110px">
                    <TextInput
                      name="fromInactivity"
                      type="time"
                      // Set this style to make the time icon white
                    />
                  </Box>
                </FormField>
                <FormField name="to" label="to" direction="row">
                  <Box width="110px">
                    <TextInput
                      name="toInactivity"
                      type="time"
                      // Set this style to make the time icon white
                    />
                  </Box>
                </FormField>
              </Box>
            </Form>
          </div>
        )}
      </>
    </Tile>
  );
};

export default GlobalMonitoringSettings;
