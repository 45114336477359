import React, { useState, useEffect } from 'react';
import { Box, Button, Layer, Paragraph, Form } from 'grommet';
import { Dispatch } from 'react';
import * as FormFields from 'components/forms/formFieldsOld';

interface iGetMACAddressDialogue {
  mac: string;
  show: boolean;
  setShow: Dispatch<boolean>;
  setMac: Dispatch<string>;
}

interface iFields {
  mac: string;
}

const blank: iFields = {
  mac: '',
};

const GetMACAddressDialogue = (props: iGetMACAddressDialogue): JSX.Element => {
  //const [selected, setSelected] = useState<iPeripheralAddFields | undefined>();
  const [info, setInfo] = useState<iFields>(blank);

  const _fields: FormFields.iField[] = [
    { label: 'Identifier', id: 'mac', type: 'text', formBoxWidth: 'small' },
  ];

  const [fields, setFields] = useState<FormFields.iField[]>(_fields);

  // Clear the previous settings
  useEffect(() => {
    console.info('props.show:', props.show);
    if (props.show) {
      setInfo({ mac: props.mac });
      setFields(_fields);
    }
  }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.show && (
        <Layer
          background="dialogBackground"
          className={'waitdialog'}
          style={{ whiteSpace: 'pre-wrap' }}
          modal={true}
        >
          <Box pad="small" gap="small" align="center">
            <Paragraph textAlign="center">
              Please enter the device identity in the format, 00124b001bxxxxxx
              or 00158d0000xxxxxx
            </Paragraph>

            <Form<iFields>
              value={info}
              onChange={(nextValue) => {
                setInfo(nextValue);
              }}
              onSubmit={async () => {
                console.info('Selected: ', info);
                props.setMac(info.mac);
                props.setShow(false);
              }}
            >
              <Box direction="column">
                <FormFields.AlignedFormFields
                  displayfields={fields}
                  labelWidth="55px"
                />
              </Box>

              <Box direction="row" gap="medium" justify="center">
                <Button
                  label="Ok"
                  size="medium"
                  plain={false}
                  margin="small"
                  type="submit"
                />
              </Box>
            </Form>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default GetMACAddressDialogue;
