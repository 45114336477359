import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps, contextButtonStyle } from '../tile';
import { Button, Box, Form, Select } from 'grommet';
import * as eventDispatcher from 'store/eventDispatcher';
import _ from 'lodash';
import * as Wait from 'components/dialogues/waitDialogue';
import * as Icons from 'grommet-icons';
import * as API from 'types/API';
import * as sitesslice from 'store/sitesSlice';
import * as clientslice from 'store/clientsSlice';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as Utils from 'common/utils/dateUtils';
import * as FormFields from 'components/forms/formFieldsOld';
import * as SiteAuth from 'common/siteAuthUtils';
import { getDifferences } from 'common/utils/miscUtils';

const blank: API.UpdateOmniviaSiteInput = {
  id: '',
  name: '',
  description: '',
  addr1: '',
  addr2: '',
  addr3: '',
  city: '',
  county: '',
  country: '',
  postcode: '',
  contactEmail: '',
  contactTelephone: '',
  timeZone: 'Europe/London',
  maintenanceEmail: '',
  pollFailEmail: '',
  floorPlanFolder: '',
  omniviaSiteLinkedClientId: '',
};

const SiteEdit = (): JSX.Element => {
  const [, SetChildEvent] = useState('');

  const [dialogueProps, setDialogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [changes, setChanges] = useState<unknown>({});
  const allSites = useSelector(sitesslice.getAll);
  const allClients = useSelector(clientslice.getAll);

  const [selectedSite, setSelectedSite, selectedSiteRef] =
    useState<API.UpdateOmniviaSiteInput>(blank);
  const [siteOptions, setSiteOptions] = useState(allSites);

  const fields: FormFields.iField[] = [
    { label: 'ID', id: 'id', type: 'readonly' },
    { label: 'Name', id: 'name', type: 'text' },
    {
      label: 'Client',
      id: 'omniviaSiteLinkedClientId',
      type: 'select',
      options: allClients,
    },
    {
      label: 'Description',
      id: 'description',
      type: 'text',
      formBoxWidth: 'large',
    },
    { label: 'Address 1', id: 'addr1', type: 'text' },
    { label: 'Address 2', id: 'addr2', type: 'text' },
    { label: 'Address 3', id: 'addr3', type: 'text' },
    { label: 'City', id: 'city', type: 'text' },
    { label: 'County', id: 'county', type: 'text' },
    { label: 'Country', id: 'country', type: 'text' },
    { label: 'Postcode', id: 'postcode', type: 'text', formBoxWidth: 'small' },
    { label: 'Email', id: 'contactEmail', type: 'text' },
    //{ label: 'Maintenance Email', id: 'maintenanceEmail', type: 'text' },
    { label: 'Poll Fail Emails', id: 'pollFailEmail', type: 'text' },
    { label: 'Telephone', id: 'contactTelephone', type: 'text' },
    { label: 'Floor plan folder', id: 'floorPlanFolder', type: 'text' },
  ];

  function loadData(site: API.OmniviaSite | undefined) {
    setChanges({});

    /*
     * FormFields generates errors if any fields are null.
     * Remove null fields fromn the object so they can be replaced with a blank string
     */
    let sitecopy = _.omitBy(site, _.isNull) as API.UpdateOmniviaSiteInput;

    // Remove properties from API.OmniviaSite that are not in API.UpdateOmniviaSiteInput so we
    // can use the object as input to the graphql mutation
    sitecopy = _.omit(sitecopy, [
      '__typename',
      'linkedClient',
    ]) as API.UpdateOmniviaSiteInput;

    sitecopy.omniviaSiteLinkedClientId = site?.linkedClient?.id;
    const newRecord = _.cloneDeep(blank) as API.UpdateOmniviaSiteInput;

    setSelectedSite(_.merge(newRecord, sitecopy));
    setDialogueProps(undefined);

    console.info('site:', sitecopy);
  }

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.SITE,
      state: eventDispatcher.systemEventStates.EDIT,
      callback: (event) => {
        loadData(event.detail as API.OmniviaSite);
      },
      executeOnStartup: false,
    },
    {
      topic: eventDispatcher.systemEventTopics.SITE,
      state: eventDispatcher.systemEventStates.UPDATED,
      callback: (event) => {
        loadData(event.detail as API.OmniviaSite);
      },
      executeOnStartup: false,
    },
  ];

  async function save() {
    let diff = getDifferences(
      allSites.find((e) => e.id == selectedSite.id),
      selectedSiteRef.current
    );

    if (diff.omniviaSiteLinkedClientId === '')
      diff = _.omit(diff, ['omniviaSiteLinkedClientId']);
    await store.dispatch(
      sitesslice.update({
        id: selectedSite.id,
        changes: diff,
      })
    );

    setTimeout(
      async () => await SiteAuth.updateSiteAuthorisationGroup(selectedSite.id),
      10
    );
  }

  const ContextButtons = (
    <Box direction="row" gap="large">
      <Select
        alignSelf="end"
        placeholder="Select a site..."
        labelKey="name"
        valueKey={{ key: 'id', reduce: true }}
        options={siteOptions}
        value={selectedSite?.id ? selectedSite.id : 'Select a site...'}
        size="medium"
        onChange={({ value: nextValue }) => {
          const site = allSites.find((e) => e.id === nextValue);
          eventDispatcher.emitEvent(
            eventDispatcher.systemEventTopics.SITE,
            eventDispatcher.systemEventStates.EDIT,
            site,
            true
          );
        }}
        onClose={() => setSiteOptions(allSites)}
        onSearch={(text) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp(escapedText, 'i');
          setSiteOptions(siteOptions.filter((o) => exp.test(o.name)));
        }}
      />

      <Button
        plain={false}
        size="small"
        label="Save"
        color={!_.isEmpty(changes) ? 'brand' : 'grey-4'}
        badge={!_.isEmpty(changes) ? true : false}
        tip="Save changes"
        disabled={_.isEmpty(changes)}
        style={contextButtonStyle}
        onClick={() => {
          save();
        }}
      />
    </Box>
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'DiscardChanges',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            loadData(allSites.find((e) => e.id == selectedSiteRef.current.id));
          },
          disabled: _.isEmpty(changes) ? true : false,
        },
        {
          label: 'Create New',
          icon: <Icons.Add size="medium" />,
          onClick: async () => {
            setDialogueProps({
              show: true,
              dialogueText: 'Please Wait...',
              showSpinner: true,
              showOkButton: false,
            });

            const newSite = _.cloneDeep(blank);
            newSite.name = 'NewSite_' + Utils.formatDate(new Date());
            setSelectedSite(newSite);
            await store.dispatch(
              sitesslice.add({
                changes: _.omit(newSite, ['id', 'omniviaSiteLinkedClientId']),
              })
            );

            //setDialogueProps will get cleared when we get a SITE#UPDATE event
          },
          disabled: false,
        },
        {
          label: 'Delete Site',
          icon: <Icons.Trash size="medium" />,
          onClick: async () => {
            setDialogueProps({
              show: true,
              dialogueText: `Are you sure you want to delete ${selectedSite.name}?`,
              showSpinner: false,
              showOkButton: true,
              showCancelButton: true,
              okButtonPress: async () => {
                await store.dispatch(
                  sitesslice.deleteRecord({ id: selectedSite.id })
                );
                setDialogueProps({
                  show: true,
                  dialogueText: `Please wit`,
                  showSpinner: false,
                  showOkButton: false,
                });
              },
              cancelButtonPress: () => {
                setDialogueProps(undefined);
              },
            });
          },
          disabled: selectedSite && selectedSite.id !== '' ? false : true,
        },
      ],
    }),
    [selectedSite, changes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setSiteOptions(allSites);
  }, [allSites]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title="Omnivia Site"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      waitDiaglogueProps={dialogueProps}
      menuProps={menuItems}
      ContextButtons={ContextButtons}
    >
      <>
        {selectedSite !== undefined && selectedSite.id !== '' && (
          <Form<API.UpdateOmniviaSiteInput>
            value={selectedSite}
            onReset={() => setSelectedSite(blank)}
            onChange={(nextValue) => {
              // console.log('Change', nextValue, touched);
              setSelectedSite(nextValue);
              setChanges(
                getDifferences(
                  allSites.find((e) => e.id == selectedSite.id),
                  selectedSiteRef.current
                )
              );
            }}
            onSubmit={(event) =>
              console.log('onSubmit', event.value, event.touched)
            }
          >
            <Box direction="column">
              <FormFields.AlignedFormFields displayfields={fields} />
            </Box>
          </Form>
        )}
      </>
    </Tile>
  );
};

export default SiteEdit;
