import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import {
  Anchor,
  Header as GrommetHeader,
  Heading,
  Select,
  Box,
  Spinner,
  Menu,
  Layer,
  Button,
  Text,
} from 'grommet';
import * as UserUtils from 'common/userUtils';

import whlogo from 'assets/img/wirelessHealthLogo.svg';
import tunstalllogo from 'assets/img/logo-tunstall.svg';
import { allSites, select as selectSite } from 'components/sites/siteList';
import * as LocalStore from 'common/localStorage';
import * as Icons from 'grommet-icons';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';
import WaitDialogue from 'components/dialogues/waitDialogue';
import { useWinstonLogger } from 'winston-react';
import styled from 'styled-components';
import * as EventDispatcher from 'store/eventDispatcher';
import * as ReduxStore from 'store/store';
import * as UsersSlice from 'store/appUserSlice';
import {
  getLastLiveEventTime,
  getSiteAlive,
} from 'components/systemevents/liveEvents';
import { getCurrentPageName, refreshHistory } from 'views/Dashboard';
import { BRAND_WIRELESSHEALTH } from './brands';
// import history from 'common/appHistory';

// let backButtonColor = 'grey';
// let forwardButtonColor = 'grey';

// export const setBackColor = (color) => {
//   backButtonColor = color;
// };

// export const setForwardColor = (color) => {
//   forwardButtonColor = color;
// };

const SiteSelect = styled(Select)`
  min-width: 400px;
`;

let storedOverrideUserLevel = localStorage.getItem(
  LocalStore.teLocalStorageItems.E_LS_KEY_OVERRIDE_USER_LEVEL
);

const Header = (props) => {
  const logger = useWinstonLogger();
  const siteOptions = allSites;

  const persistantData = UsersSlice.getPersistantData(
    ReduxStore.store.getState()
  );
  let storedSite = persistantData.selectedSiteId;

  if (!storedSite) storedSite = '';

  // console.log('brand: ', props.brand);

  const [, setIsAlive] = React.useState(false);
  const [, setLastSeen] = React.useState('');
  const [value, setValue] = React.useState(storedSite);
  const [options, setOptions] = React.useState(siteOptions);
  const [refreshing, setRefreshing] = React.useState(false);
  const [dialogueProps, setDialogueProps] = React.useState();
  const [showOverrideUserLevelDialogue, setShowOverrideUserLevelDialogue] =
    React.useState(false);

  const [overrideUserLevel, setOverrideUserLevel, overrideUserLevelRef] =
    useState(storedOverrideUserLevel);

  const [IoTOffline, setIoTOffline] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const [lastEvent, setLastEvent] = useState('');

  const signOut = async (e) => {
    e.preventDefault();
    await UserUtils.logout();
  };

  const reloadSite = async () => {
    setRefreshing(true);
    try {
      await IoTSCUManifest.Instance.requestManifestUpload(null);
    } catch (error) {
      console.error('Error waiting for manifest request, error:', error);
    }
    setRefreshing(false);
  };

  const getMenuItems = () => {
    const menuItems = [
      {
        label: (
          <Box alignSelf="center">
            <Text size="medium">Refresh</Text>
          </Box>
        ),
        disabled: refreshing,
        icon: (
          <Box pad="xsmall">
            {!refreshing && <Icons.Refresh size="15px" />}
            {refreshing && <Spinner alignSelf="center" size="1px" />}
          </Box>
        ),
        onClick: async () => {
          logger.info('user.siteReload');
          setDialogueProps({
            show: true,
            dialogueText: `Reloading the site data, please wait...`,
            showSpinner: true,
            showOkButton: false,
          });
          await reloadSite();
          setDialogueProps(undefined);
          logger.info('user.siteReloadComplete');
        },
        color: '#ccc',
      },
      {
        label: (
          <Box alignSelf="center">
            <Text size="medium">Logout</Text>
          </Box>
        ),
        icon: (
          <Box pad="xsmall">
            <Icons.Logout size="15px" />
          </Box>
        ),
        onClick: async (e) => {
          await logger.info('user.logout');
          await signOut(e);
        },
        color: '#ccc',
      },
    ];

    const groups = UserUtils.getCurrentUsersGroups();
    const al = UserUtils.getAccessLevelFromGroups(groups);

    if (al === UserUtils.UAG.ADMIN || al === UserUtils.UAG.ELITE) {
      menuItems.push({
        label: (
          <Box alignSelf="center">
            <Text size="medium">Override user level</Text>
          </Box>
        ),
        icon: (
          <Box pad="xsmall">
            <Icons.UserSettings size="15px" />
          </Box>
        ),
        onClick: async () => {
          setShowOverrideUserLevelDialogue(true);
        },
        color: '#ccc',
      });
    }

    return menuItems;
  };

  const saveOverrideUser = async () => {
    if (overrideUserLevelRef) {
      console.info(
        'overrideUserLevelRef.current=',
        overrideUserLevelRef.current
      );

      if (overrideUserLevelRef.current === undefined) {
        await localStorage.removeItem(
          LocalStore.teLocalStorageItems.E_LS_KEY_OVERRIDE_USER_LEVEL
        );
      } else {
        await localStorage.setItem(
          LocalStore.teLocalStorageItems.E_LS_KEY_OVERRIDE_USER_LEVEL,
          overrideUserLevelRef.current
        );
      }
      setTimeout(() => window.location.reload(), 100);
    }
  };

  useEffect(() => {
    if (value != '') {
      console.log(value);
      setIsAlive(false);
      setLastSeen('Site Not Connected');
      selectSite(value);
    }
  }, [value]);

  useEffect(() => {
    const groups = UserUtils.getCurrentUsersGroups();
    const al = UserUtils.getAccessLevelFromGroups(groups);

    if (
      (al !== UserUtils.UAG.ADMIN) & (al !== UserUtils.UAG.ELITE) &&
      storedOverrideUserLevel != undefined
    ) {
      localStorage.removeItem(
        LocalStore.teLocalStorageItems.E_LS_KEY_OVERRIDE_USER_LEVEL
      );
      setTimeout(() => window.location.reload(), 100);
    }

    EventDispatcher.registerForEvent(
      EventDispatcher.systemEventTopics.IOT,
      EventDispatcher.systemEventStates.OFFLINE,
      () => {
        setIoTOffline(true);
      }
    );

    EventDispatcher.registerForEvent(
      EventDispatcher.systemEventTopics.IOT,
      EventDispatcher.systemEventStates.ONLINE,
      () => {
        setIoTOffline(false);
      }
    );

    EventDispatcher.registerForEvent(
      EventDispatcher.systemEventTopics.LIVEEVENTS,
      EventDispatcher.systemEventStates.LOADED,
      () => {
        setIsAlive(getSiteAlive());
      }
    );

    EventDispatcher.registerForEvent(
      EventDispatcher.systemEventTopics.SITE,
      EventDispatcher.systemEventStates.LOADED,
      () => {
        setLastSeen(getLastLiveEventTime());
      }
    );

    return () => {
      // Remove registrations on exit
      EventDispatcher.unRegisterForEvent(
        EventDispatcher.systemEventTopics.IOT,
        EventDispatcher.systemEventStates.OFFLINE,
        () => {
          setIoTOffline(true);
        }
      );

      EventDispatcher.unRegisterForEvent(
        EventDispatcher.systemEventTopics.IOT,
        EventDispatcher.systemEventStates.ONLINE,
        () => {
          setIoTOffline(false);
        }
      );

      EventDispatcher.unRegisterForEvent(
        EventDispatcher.systemEventTopics.LIVEEVENTS,
        EventDispatcher.systemEventStates.LOADED,
        () => {
          setIsAlive(getSiteAlive());
        }
      );

      EventDispatcher.unRegisterForEvent(
        EventDispatcher.systemEventTopics.SITE,
        EventDispatcher.systemEventStates.LOADED,
        () => {
          setLastSeen(getLastLiveEventTime());
        }
      );
    };
  }, []);

  return (
    <GrommetHeader
      background={props.brand == BRAND_WIRELESSHEALTH ? 'grey-9' : 'grey-9'}
      justify="start"
      pad={{ left: 'medium', top: 'none', bottom: 'none' }}
    >
      {props.brand == BRAND_WIRELESSHEALTH && (
        <Heading margin="none" color="#dddddd">
          <Box direction="row">
            <Anchor to="http://practicalcontrol.co.uk">
              <img
                src={whlogo}
                style={{
                  width: '90px',
                  height: '40px',
                  marginRight: '8px',
                }}
              />
            </Anchor>
            <Box margin={{ top: '-6px' }}>
              <Text size="xxlarge" color="aqua">
                Insights
              </Text>
            </Box>
          </Box>
        </Heading>
      )}

      {props.brand == 'Tunstall' && (
        <Heading margin="none" color="brand">
          <Box direction="row">
            <Anchor to="http://tunstall.co.uk">
              <img
                src={tunstalllogo}
                style={{ width: '160px', height: '70px', margin: 'small' }}
              />
            </Anchor>
            <Box margin={{ top: '14px', left: '10px' }}>
              {/* <Text size="xxlarge" color="white">
                Insights
              </Text> */}
            </Box>
          </Box>
        </Heading>
      )}

      {storedOverrideUserLevel && (
        <p style={{ color: 'red' }}>
          USER LEVEL OVERRIDE SET TO {storedOverrideUserLevel.toUpperCase()}
        </p>
      )}

      {dialogueProps && <WaitDialogue {...dialogueProps} />}
      {showOverrideUserLevelDialogue && (
        <Layer
          className={'waitdialog'}
          background="dialogBackground"
          style={{ whiteSpace: 'pre-wrap' }}
          modal={true}
        >
          <Box pad="small" gap="small" align="center">
            <p>The page will re-load once changed</p>
            <Select
              placeholder="Select a access Level..."
              labelKey="label"
              valueKey="value"
              options={UserUtils.userTypeOptions}
              value={{
                value: overrideUserLevel,
                label: overrideUserLevel,
              }}
              size="medium"
              onChange={({ option }) => {
                console.info('option:', option);
                setOverrideUserLevel(option.value);
              }}
            />

            <Box direction="row">
              <Button
                label="Set"
                size="medium"
                plain={false}
                margin="small"
                onClick={() => {
                  setShowOverrideUserLevelDialogue(false);
                  saveOverrideUser();
                }}
              />
              <Button
                label="Clear"
                size="medium"
                plain={false}
                margin="small"
                onClick={() => {
                  setShowOverrideUserLevelDialogue(false);
                  setOverrideUserLevel();
                  saveOverrideUser();
                }}
              />
              <Button
                label="Cancel"
                size="medium"
                plain={false}
                margin="small"
                onClick={() => {
                  setShowOverrideUserLevelDialogue(false);
                }}
              />
            </Box>
          </Box>
        </Layer>
      )}

      <Box flex="grow" pad={{ right: 'small' }}>
        <Box alignSelf="end" direction="row">
          <SiteSelect
            style={
              IoTOffline === true ? { color: 'red' } : { color: '#dddddd' }
            }
            alignSelf="end"
            placeholder="Select a site..."
            labelKey="name"
            valueKey={{ key: 'id', reduce: true }}
            options={options}
            value={value}
            size="medium"
            onChange={({ value: nextValue }) => {
              logger.info('user.changeSite', { selected: nextValue });
              setValue(nextValue);
              options.forEach((element) => {
                if (element.id == nextValue) {
                  refreshHistory(element.name);
                }
              });

              EventDispatcher.emitEvent(
                EventDispatcher.systemEventTopics.DASHBOARD,
                EventDispatcher.systemEventStates.SELECTED,
                { name: getCurrentPageName() },
                true
              );
            }}
            onClose={() => setOptions(siteOptions)}
            onSearch={(text) => {
              // The line below escapes regular expression special characters:
              // [ \ ^ $ . | ? * + ( )
              const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

              // Create the regular expression with modified value which
              // handles escaping special characters. Without escaping special
              // characters, errors will appear in the console
              const exp = new RegExp(escapedText, 'i');
              setOptions(siteOptions.filter((o) => exp.test(o.name)));
            }}
          />
          {/* {!isAlive && (
            <Box margin={{ left: '6px' }} background="red" pad="2px">
              <Tip content={<Text>{lastSeen}</Text>}>
                <Icons.Alert color="white" />
              </Tip>
            </Box>
          )} */}
        </Box>
      </Box>
      {/* <Icons.LinkPrevious
        color={backButtonColor}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          goToLastPage();
          setLastEvent(new Date());
        }}
      />
      <Icons.LinkNext
        color={forwardButtonColor}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          goToNextPage();
          setLastEvent(new Date());
        }}
      /> */}
      <Menu
        margin={{ right: '90px', bottom: '10px', top: '10px' }}
        label=""
        icon={<Icons.Menu />}
        size="small"
        items={getMenuItems()}
      />
    </GrommetHeader>
  );
};

export default Header;
