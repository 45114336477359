// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import React, { useState, useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie';
import * as alarmEvents from 'components/systemevents/alarmEvents';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import Tile, { iTileEvent } from '../tile';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

interface iAlarmTypeDescription {
  id: string;
  label: string;
  value: number;
  color: string;
}
let data: iAlarmTypeDescription[] = [];
let colourCycle = 0;

const PieChart = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  // alarms.fetch(30);

  /*
  const typeColours = [
    { id: 'Pendant', colour: 'hsl(124, 70%, 50%)' },
    { id: 'Fall Trigger', colour: 'hsl(286, 70%, 50%)' },
    { id: 'Seizure', colour: 'hsl(13, 70%, 50%)' },
    { id: 'Mains Fail', colour: 'hsl(200, 70%, 50%)' },
    { id: 'Smoke', colour: 'hsl(360, 70%, 50%)' },
    { id: 'Pullcord', colour: 'hsl(180, 70%, 50%)' },
    { id: 'Battery Low', colour: 'hsl(110, 70%, 50%)' },
    { id: 'Red Button', colour: 'hsl(317, 70%, 70%)' },
    { id: 'Fire', colour: 'hsl(220, 70%, 50%)' },
  ];
  */

  const colours = [
    'hsl(124, 70%, 50%)',
    'hsl(286, 70%, 50%)',
    'hsl(13, 70%, 50%)',
    'hsl(200, 70%, 50%)',
    'hsl(360, 70%, 50%)',
  ];

  const getColour = (): string => {
    // TODO : colours seem to be picked at random and ifgnore what we set here
    /*
    const colour = typeColours.find((e) => e.id === type);
    if (colour) return colour.colour;
    */

    return colours[colourCycle++ % colours.length];
  };

  const loadChartData = () => {
    const alarmdata = alarmEvents.getTotalAlarmsByType();
    alarmdata.sort((a, b) => {
      if (a.description < b.description) return -1;
      if (a.description > b.description) return 1;
      return 0;
    });

    data = [];
    alarmdata.forEach((a) => {
      data.push({
        id: a.description,
        label: a.description,
        value: a.total,
        color: getColour(),
      });
    });
  };

  const newDataEvent = () => {
    setShowSpinner(false);
    loadChartData();
  };

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.SITEEVENTS,
      state: systemEventStates.PROCESSED,
      callback: newDataEvent,
      executeOnStartup: true,
    },
  ];

  useEffect(() => {
    if (data.length === 0) {
      setShowSpinner(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title="Alarms By Type"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      showSpinner={showSpinner}
    >
      <>
        {!showSpinner && (
          <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]],
            }}
            arcLinkLabelsSkipAngle={6}
            arcLinkLabelsTextColor="#111111"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLinkLabelsDiagonalLength={20}
            arcLinkLabelsStraightLength={20}
            arcLinkLabelsTextOffset={6}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: '#000',
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
                padding: 1,
              },
            ]}
            fill={[
              {
                match: {
                  id: 'Pendant',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'Fall Trigger',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'Seizure',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'Mains Fail',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'Smoke',
                },
                id: 'lines',
              },
              {
                match: {
                  id: 'Pullcord',
                },
                id: 'lines',
              },
              {
                match: {
                  id: 'Battery Low',
                },
                id: 'lines',
              },
              {
                match: {
                  id: 'Red Button',
                },
                id: 'lines',
              },
              {
                match: {
                  id: 'Fire',
                },
                id: 'lines',
              },
            ]}
            /*
          legends={[
            {
              anchor: 'left',
              direction: 'column',
              justify: false,
              translateX: -60,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
          */
          />
        )}
      </>
    </Tile>
  );
};

export default PieChart;
