import React, { ReactElement, useState, useEffect } from 'react';
import useStateRef from 'react-usestateref';
import 'scss/reactresizeablestyles.css';
import 'scss/reactgridstyles.css';
import ReactGridLayout from 'react-grid-layout';
import { iLayoutView, iWidgetEntry } from './layouts/widgetTypes';
import { desktopWidgetLibrary } from './layouts/desktopWidgetLibrary';
import { mobileWidgetLibrary } from './layouts/mobileWidgetLibrary';
import { desktopDashboards } from './layouts/desktopDashboards';
import { mobileDashboards } from './layouts/mobileDashboards';
import { Box } from 'grommet';
import { minimumAccessLevel } from '../../common/userUtils';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { showMobile } from 'common/deviceDetect';
import { commonWidgetLibrary } from './layouts/commonWidgetLibrary';
import { iDashBoardEvent } from 'views/Dashboard';
import { Manifest } from 'components/installation/manifest';
import * as eventDispatcher from 'store/eventDispatcher';

import semver from 'semver';
import { versionToString } from 'components/installation/manifestUtils';

type LayoutManagerProps = {
  view: iDashBoardEvent;
  props?: unknown;
};

/** return a filtered view based on user access level */
// export function viewLibraryByUserAccessLevel(): Array<iLayoutView> {
//   return desktopDashboards.filter((a) => minimumAccessLevel(a.accessLevel));
// }

export const LayoutManager = ({
  view,
  ...props
}: LayoutManagerProps): JSX.Element => {
  let layouts: ReactGridLayout.Layout[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let widgetProps: (Partial<any> & React.Attributes) | undefined = undefined;
  const [ident, setIdent] = useState(1);
  const [, /*currentPage*/ setCurrentPage, currentPageRef] = useStateRef(0);
  const manifest = new Manifest();
  const [SCUSoftwareVerion, setSCUSoftwareVersion] = useState('0.0.0');

  const swipConfig = {
    delta: 10, // min distance(px) before a swipe starts
    preventScrollOnSwipe: true, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  };

  const swipeHandlers = useSwipeable({
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSwipedLeft: (eventData) => userHasSwiped(eventData),
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSwipedRight: (eventData) => userHasSwiped(eventData),
    ...swipConfig,
  });

  const userHasSwiped = (swipeEventData: SwipeEventData) => {
    console.log('swipeEventData:', swipeEventData);

    if (showMobile()) {
      const maxpages = layouts.length;
      let p = currentPageRef.current;

      if (swipeEventData.dir === 'Left') {
        p++;
      } else if (swipeEventData.dir === 'Right') {
        p--;
      }

      if (p < 0) p = 0;
      if (p > maxpages - 1) p = maxpages - 1;
      setCurrentPage(p);
    }
  };

  const getWidgetFromName = (widgetName: string): iWidgetEntry => {
    const lib = showMobile()
      ? commonWidgetLibrary.concat(mobileWidgetLibrary)
      : commonWidgetLibrary.concat(desktopWidgetLibrary);
    const widget = lib.find((w) => w.name == widgetName);
    if (widget == undefined)
      throw new Error(`Widget ${widgetName} does not exist`);
    return widget;
  };

  // if a view has been passed then attempt to load it
  if (view) {
    const lib = showMobile() ? mobileDashboards : desktopDashboards;
    const v: iLayoutView | undefined = lib.find((v) => v.name == view.name);
    // if there's a view and the user meets the minimum access level
    if (v && minimumAccessLevel(v.accessLevel)) {
      layouts = v.layouts;
      widgetProps = v.props;
    }
  }

  const getCorrectWidgetForSCUVersion = (
    widgetEntry: iWidgetEntry
  ): React.ReactNode | undefined => {
    const applicable = widgetEntry.widget.filter((s) =>
      semver.gte(
        SCUSoftwareVerion,
        s.minimumSCUSoftwareVersion // SCU version is greater than or equals the required SCU version by the widget
      )
    );
    const max = applicable.sort((a, b) =>
      semver.rcompare(a.minimumSCUSoftwareVersion, b.minimumSCUSoftwareVersion)
    )[0];
    //console.log(SCUSoftwareVerion, applicable, max);
    return max ? max.widget : undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadDataEvent = (e: eventDispatcher.iEventParams) => {
    setSCUSoftwareVersion(versionToString(manifest.swVersion));
    setIdent((oldident) => oldident++);
  };

  useEffect(() => {
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.PROCESSED,
      loadDataEvent
    );
    eventDispatcher.registerForEvent(
      eventDispatcher.systemEventTopics.MANIFEST,
      eventDispatcher.systemEventStates.UPDATED,
      loadDataEvent
    );

    return () => {
      // Remove registrations on exit

      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.PROCESSED,
        loadDataEvent
      );
      eventDispatcher.unRegisterForEvent(
        eventDispatcher.systemEventTopics.MANIFEST,
        eventDispatcher.systemEventStates.UPDATED,
        loadDataEvent
      );
    };
  });

  return (
    <Box overflow="auto" width="100%" height="100%">
      {/* Mobile swipe page based layout */}
      {showMobile() && layouts && (
        <div {...swipeHandlers}>
          <Box>
            {getWidgetFromName(layouts[currentPageRef.current].i).widget}
          </Box>
          <Box>{`currentPage: ${currentPageRef.current}`}</Box>
        </div>
      )}

      {/* Desktop Grid Based Layout */}
      {!showMobile() && (
        <ReactGridLayout
          {...props}
          className="layout"
          key={`${view.name}${ident}`}
          cols={38}
          //breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          compactType={'vertical'}
          rowHeight={30}
          layout={layouts}
          // Disabled the drag for now so we can select text
          isDraggable={false}
          // initialLayout={layouts}
          width={2000}
        >
          {layouts &&
            layouts.map((item, index) => {
              const widgetEntry = getWidgetFromName(item.i);
              const widget = getCorrectWidgetForSCUVersion(widgetEntry);
              if (
                widgetEntry &&
                minimumAccessLevel(widgetEntry.accessLevel) &&
                widget
              )
                return (
                  <div key={item.i}>
                    {widgetProps
                      ? React.cloneElement(widget as ReactElement, {
                          ...widgetProps[index],
                          view,
                        })
                      : React.cloneElement(widget as ReactElement, view)}
                  </div>
                );
            })}
        </ReactGridLayout>
      )}
    </Box>
  );
};

/** Add a new tile to the layout */
//export const addTiletoLayout = (): void => {};

/** Remove a tile from the layout */
//export const removeTilefromLayout = (): void => {};

/** Get a stored layout from local storage */
//export const recallLayoutfromLS = (): void => {};

/** Save a layout to local storage */
//export const saveLayouttoLS = (): void => {};
