import {
  teCLOUD_MESSAGE_TYPES,
  teCLOUD_ERROR_CODES,
} from './IoTMessageDefines';

import {
  teCLOUD_MSG_TY_CALL,
  tsE_CLOUD_MSG_CALL_DIAL_OFFSITE_REQ,
  tsE_CLOUD_MSG_CALL_DIAL_OFFSITE_RES,
  tsE_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_REQ,
  tsE_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_RES,
  tsE_CLOUD_MSG_CALL_CONNECT_RESIDENT_REQ,
  tsE_CLOUD_MSG_CALL_CONNECT_RESIDENT_RES,
  tsE_CLOUD_MSG_CALL_DISCONECT_RESIDENT_REQ,
  tsE_CLOUD_MSG_CALL_DISCONECT_RESIDENT_RES,
  tsE_CLOUD_MSG_CALL_ONLINE_HEARTBEAT,
  tsE_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT,
} from './IoTMessageDefines_CALL';

import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';

import { Message } from 'common/IoT/IotMessageHandler';

export { teCLOUD_ERROR_CODES } from './IoTMessageDefines';
export { teCLOUD_REMOTECALL_EVENTS_STATUS_CODES } from './IoTMessageDefines_CALL';
export type { tsE_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT } from './IoTMessageDefines_CALL';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

export enum TELEPHONE_LINES {
  TELEPHONE_LINE_FXO_1 = 101,
  TELEPHONE_LINE_SIP_1 = 201,
}

const _requestCallBack = async (
  calluid: string,
  username: string,
  telephonenumber: string,
  line: TELEPHONE_LINES
): Promise<iIotResponseEventDetail> => {
  console.info(
    `_requestCallBAck: calluid:${calluid} username:${username} telephonenumber:${telephonenumber} line:${line}`
  );

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CALL;
  const opcode = teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_DIAL_OFFSITE_REQ;
  const rplyopcode = teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_DIAL_OFFSITE_RES;
  const msg: tsE_CLOUD_MSG_CALL_DIAL_OFFSITE_REQ = {
    calluid: calluid,
    username: username,
    telephonenumber: telephonenumber,
    line: line,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    '_requestCallBack',
    undefined
  );
};

export const requestCallBack = async (
  calluid: string,
  username: string,
  telephonenumber: string,
  line: TELEPHONE_LINES
): Promise<teCLOUD_ERROR_CODES> => {
  let errorCode = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_TIMEOUT;
  const promise = new Promise<teCLOUD_ERROR_CODES>((resolve) => {
    (async () => {
      try {
        const resp = await _requestCallBack(
          calluid,
          username,
          telephonenumber,
          line
        );
        const scuRes = resp.msgHeader
          ?.msg as tsE_CLOUD_MSG_CALL_DIAL_OFFSITE_RES;
        if (scuRes) {
          errorCode = scuRes.errorcode;
        } else {
          console.error('requestCallBack no response');
        }
      } catch (error) {
        console.error('requestCallBack error:', error);
      }

      resolve(errorCode);
    })();
  });
  return promise;
};

const _disconnectRemoteCall = async (
  calluid: string,
  username: string
): Promise<iIotResponseEventDetail> => {
  console.info(
    `_disconnectRemoteCall: calluid:${calluid} username:${username}`
  );

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CALL;
  const opcode = teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_RES;
  const msg: tsE_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_REQ = {
    calluid: calluid,
    username: username,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    '_disconnectRemoteCall',
    undefined
  );
};

export const disconnectRemoteCall = async (
  username: string,
  calluid: string
): Promise<teCLOUD_ERROR_CODES> => {
  let errorCode = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_TIMEOUT;
  const promise = new Promise<teCLOUD_ERROR_CODES>((resolve) => {
    (async () => {
      try {
        const resp = await _disconnectRemoteCall(calluid, username);
        const scuRes = resp.msgHeader
          ?.msg as tsE_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_RES;
        if (scuRes) {
          errorCode = scuRes.errorcode;
        } else {
          console.error('disconnectRemoteCall no response');
        }
      } catch (error) {
        console.error('disconnectRemoteCall error:', error);
      }

      resolve(errorCode);
    })();
  });
  return promise;
};

const _connectResident = async (
  unitid: number,
  vrutext: string,
  username: string,
  calluid: string,
  overrideprivacy: boolean
): Promise<iIotResponseEventDetail> => {
  console.info(
    `_connectResident: calluid:${calluid} username:${username} unitid:${unitid}`
  );

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CALL;
  const opcode = teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_CONNECT_RESIDENT_REQ;
  const rplyopcode = teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_CONNECT_RESIDENT_RES;
  const msg: tsE_CLOUD_MSG_CALL_CONNECT_RESIDENT_REQ = {
    calluid: calluid,
    username: username,
    unitid: unitid,
    vrutext: vrutext,
    overrideprivacy: overrideprivacy ? 1 : 0,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    '_connectResident',
    undefined
  );
};

export const connectResident = async (
  unitid: number,
  vrutext: string,
  username: string,
  calluid: string,
  overrideprivacy?: boolean
): Promise<teCLOUD_ERROR_CODES> => {
  let errorCode = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_TIMEOUT;
  let override = false;

  if (overrideprivacy !== undefined) {
    override = overrideprivacy;
  }

  const promise = new Promise<teCLOUD_ERROR_CODES>((resolve) => {
    (async () => {
      try {
        const resp = await _connectResident(
          unitid,
          vrutext,
          username,
          calluid,
          override
        );
        const scuRes = resp.msgHeader
          ?.msg as tsE_CLOUD_MSG_CALL_CONNECT_RESIDENT_RES;
        if (scuRes) {
          errorCode = scuRes.errorcode;
        } else {
          console.error('connectResident no response');
        }
      } catch (error) {
        console.error('connectResident error:', error);
      }

      resolve(errorCode);
    })();
  });
  return promise;
};

const _disconnectResident = async (
  username: string,
  calluid: string
): Promise<iIotResponseEventDetail> => {
  console.info(`_disconnectResident: calluid:${calluid} username:${username} `);

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CALL;
  const opcode = teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_DISCONECT_RESIDENT_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_DISCONECT_RESIDENT_RES;
  const msg: tsE_CLOUD_MSG_CALL_DISCONECT_RESIDENT_REQ = {
    username: username,
    calluid: calluid,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    '_disconnectResident',
    undefined
  );
};

export const disconnectResident = async (
  username: string,
  calluid: string
): Promise<teCLOUD_ERROR_CODES> => {
  let errorCode = teCLOUD_ERROR_CODES.E_CLOUD_ERROR_TIMEOUT;
  const promise = new Promise<teCLOUD_ERROR_CODES>((resolve) => {
    (async () => {
      try {
        const resp = await _disconnectResident(username, calluid);
        const scuRes = resp.msgHeader
          ?.msg as tsE_CLOUD_MSG_CALL_DISCONECT_RESIDENT_RES;
        if (scuRes) {
          errorCode = scuRes.errorcode;
        } else {
          console.error('disconnectResident no response');
        }
      } catch (error) {
        console.error('disconnectResident error:', error);
      }

      resolve(errorCode);
    })();
  });
  return promise;
};

export const sendHeartbeat = async (
  calluid: string
): Promise<iIotResponseEventDetail> => {
  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CALL;
  const opcode = teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_ONLINE_HEARTBEAT;
  const rplyopcode = undefined;
  const msg: tsE_CLOUD_MSG_CALL_ONLINE_HEARTBEAT = {
    calluid: calluid,
  };
  const transId = MessageHandler.getNextGlobalTransactionId();
  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    '_disconnectResident',
    undefined
  );
};

/**
 *
 * @param callback
 * @returns The registration Index which can be used to unregister the event
 */
export const registerForDialStatusEvents = (
  callback: (msg: tsE_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT) => void
): number => {
  return SCUMessaging.registerMessageTypeCallback(
    teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CALL,
    async (msg: Message) => {
      if (
        msg.type == teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_CALL &&
        msg.opcode == teCLOUD_MSG_TY_CALL.E_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT
      ) {
        if (msg.msg) {
          callback(msg.msg as tsE_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT);
        }
      }
    },
    null
  );
};

export const unregisterDialStatusEventCallabck = (
  registrationIndex: number
): void => {
  SCUMessaging.unRegisterMessageTypeCallback(registrationIndex);
};
