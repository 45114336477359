import { tePROCESSOR } from './IoTMessageDefines_DEVICES';
import {
  teCLOUD_MSG_TY_SYSTEM,
  tsCLOUD_MSG_SYSTEM_CLEAR_ALARM_REQ,
  tsCLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP,
  tsCLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_REQ,
  tsCLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_RESP,
} from './IoTMessageDefines_SYSTEM';
import {
  teCLOUD_ERROR_CODES,
  teCLOUD_MESSAGE_TYPES,
} from './IoTMessageDefines';

import { IoTMessageHandler } from './IotMessageHandler';
import { SCUIoTMessaging, defaultTimeout } from './SCUIoTMessaging';
import { iIotResponseEventDetail } from 'common/IoT/IoTResponseEvent';

const SCUMessaging = SCUIoTMessaging.Instance;
const MessageHandler = IoTMessageHandler.Instance;

export { tePROCESSOR as tePROCESSOR };
export { teCLOUD_ERROR_CODES as teCLOUD_ERROR_CODES };
export type {
  tsCLOUD_MSG_SYSTEM_CLEAR_ALARM_REQ,
  tsCLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_REQ,
  tsCLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP,
  tsCLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_RESP,
} from './IoTMessageDefines_SYSTEM';

export async function getActiveAlarms(
  userData: unknown
): Promise<iIotResponseEventDetail> {
  console.info('getActiveAlarms');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM;
  const opcode = teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_GET_ALARMS_ACTIVE_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_GET_ALARMS_ACTIVE_RESP;

  const msg = {};

  const transId = MessageHandler.getNextGlobalTransactionId();

  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'getActiveAlarms',
    userData
  );
}

export async function clearAllAlarms(
  userData: unknown,
  autoTest?: boolean
): Promise<iIotResponseEventDetail> {
  console.info('clearAllAlarms');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM;
  const opcode = teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_RESP;

  const msg: tsCLOUD_MSG_SYSTEM_CLEAR_ALL_ALARMS_REQ = {
    autotest: autoTest !== undefined ? autoTest : false,
  };

  const transId = MessageHandler.getNextGlobalTransactionId();

  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'clearAllAlarms',
    userData
  );
}

async function _getAlarmQueue(
  userData?: unknown
): Promise<iIotResponseEventDetail> {
  console.info('getAlarmQueue');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM;
  const opcode =
    teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_REQ;
  const rplyopcode =
    teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP;

  const msg = {};
  const transId = MessageHandler.getNextGlobalTransactionId();

  const scuRes = await SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'getAlarmQueue',
    userData
  );

  return scuRes;
}

export const getAlarmQueue = async (
  userData?: unknown
): Promise<tsCLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP | void> => {
  let scuRes: tsCLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP | undefined =
    undefined;

  const promise = new Promise<
    tsCLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP | undefined
  >((resolve) => {
    (async () => {
      try {
        const resp = await _getAlarmQueue(userData);
        scuRes = resp?.msgHeader
          ?.msg as tsCLOUD_MSG_SYSTEM_GET_ALARM_Q_ENTRIES_RESP;
      } catch (error) {
        console.error('getAlarmQueueEntries error:', error);
        scuRes = undefined;
      }
      resolve(scuRes);
    })();
  });
  return promise;
};

export async function clearAlarm(
  alarmId: number,
  userData: unknown,
  autoTest?: boolean
): Promise<iIotResponseEventDetail> {
  // console.info('clearAlarm');

  const type = teCLOUD_MESSAGE_TYPES.E_CLOUD_TYPE_SYSTEM;
  const opcode = teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_CLEAR_ALARM_REQ;
  const rplyopcode = teCLOUD_MSG_TY_SYSTEM.E_CLOUD_MSG_SYSTEM_CLEAR_ALARM_RESP;

  const msg: tsCLOUD_MSG_SYSTEM_CLEAR_ALARM_REQ = {
    alarmid: alarmId,
    autotest: autoTest !== undefined ? autoTest : false,
  };

  console.info('********** autotest:', autoTest);

  const transId = MessageHandler.getNextGlobalTransactionId();

  return SCUMessaging.sendMessage(
    type,
    opcode,
    rplyopcode,
    transId,
    msg,
    defaultTimeout,
    'clearAlarm',
    userData
  );
}
