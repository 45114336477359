import TablePaged from 'components/tables/TablePaged';
import React, { useEffect, useState } from 'react';
import Tile, { iTileEvent } from 'components/dashboard/tile';
import * as utils from 'common/utils/dateUtils';
import { Text, Box, ThemeType, RadioButton } from 'grommet';
import { Room } from 'components/installation/room';
import * as eventDispatcher from 'store/eventDispatcher';
import { Manifest } from '../../../installation/manifest';
import styled, { useTheme } from 'styled-components';
import { Cell } from 'react-table';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';
import logger from 'common/logger';
import { useInterval } from 'hooks/useInterval';
import ResidentCalling from './residentCalling';
import { Phone, StatusGood } from 'grommet-icons';
import * as UserUtils from 'common/userUtils';
import SCUversion from 'components/wrappers/SCUversion';
import { globalOkStart, globalOkStop } from './residentUtils';
import { Schedule } from 'components/installation/schedule';
import { teSCHEDULES_OBJECT_TYPES } from 'types/manifest-enums';

interface iOKProps {
  enabled: boolean;
  empty: boolean;
  ok: boolean;
  away: boolean;
  inPeriod: boolean;
  afterPeriod: boolean;
  spokenTo: boolean; // have spoken to resiodent after monitoring period started
  seen: boolean; // have seen resident after moniroting period started
}

interface iMonitoringInfo {
  numberEnabled: number;
  numberDisabled: number;
  to: string;
  from: string;
}

interface iRoomAndSchedules {
  room: Room;
  schedules: Schedule[];
}

interface iStatusColour {
  background: string;
  text: string;
  statusText: string;
}

const OKSTATUS = styled.div<iStatusColour>`
  padding: '8px';
  background-color: ${(props) => props.background};
  color: ${(props) => props.text};
`;

const ResidentOK = (): JSX.Element => {
  const [resident, setResident] = useState(-1);
  const [callOpen, setCallOpen] = useState(false);
  const [selectedRB, setSelectedRB] = useState('Monitored Only');
  const [rooms, setRooms] = useState<iRoomAndSchedules[]>([]);
  const theme: ThemeType = useTheme();
  const colors = theme.global?.colors;

  const green = (colors?.green as string) ?? 'green';
  const red = (colors?.red as string) ?? 'red';
  const amber = (colors?.amber as string) ?? 'yellow';
  const blue = (colors?.blue as string) ?? 'blue';
  const aqua = (colors?.aqua as string) ?? 'aqua';
  const background = (colors?.background as string) ?? '#eeeeee';
  const text = (colors?.text as string) ?? '#222222';
  const textLighter = (colors?.lighterText as string) ?? '#888';
  const textWhite = '#eee';

  const [monitoringInfo, setMonitoringInfo] = useState<iMonitoringInfo>({
    numberEnabled: 0,
    numberDisabled: 0,
    to: '',
    from: '',
  });

  const [, SetChildEvent] = useState('');
  const [dataloaded, setDataloaded] = useState<number>(0);
  const manifest = new Manifest();

  const _globalOkStart = globalOkStart(manifest);
  const _globalOkStop = globalOkStop(manifest);

  function updateInfo() {
    if (
      manifest.rooms &&
      manifest.systemParameters &&
      manifest.rooms.length > 0
    ) {
      const r: iRoomAndSchedules[] = [];
      const info: iMonitoringInfo = {
        numberEnabled: 0,
        numberDisabled: 0,
        to: '',
        from: '',
      };
      for (const index in manifest.rooms) {
        const room = manifest.rooms[index];
        const schedules = manifest.getSchedulesByTypeAndId(
          teSCHEDULES_OBJECT_TYPES.SCHEDULES_OBJECT_TYPE_RESIDENT_OK,
          room.HomeDeviceId
        );

        const s = schedules
          .filter((f) => f.istoday())
          .sort((a, b) => a.StartTime - b.StartTime);
        r.push({ room: room, schedules: s });
        // console.log(s);
      }

      info.from = globalOkStart(manifest);
      info.to = globalOkStop(manifest);

      setRooms(r);
      setMonitoringInfo(info);
      setDataloaded(dataloaded + 1);
    }
  }

  // Update the status on a routine basis
  useInterval(() => {
    const ts = utils.formatDateWithSeconds(new Date());
    SetChildEvent(ts);
  }, 2000);

  const events: iTileEvent[] = [
    {
      topic: eventDispatcher.systemEventTopics.MANIFEST,
      state: eventDispatcher.systemEventStates.PROCESSED,
      callback: () => {
        updateInfo();
      },
      executeOnStartup: true,
    },
  ];

  // const dateOKStart = utils.dateTodaySetTimeHHMM(_globalOkStart);
  // const dateOKEnd = utils.dateTodaySetTimeHHMM(_globalOkStop);

  const residentScheduleText = (r: iRoomAndSchedules) => {
    if (!r.room.EnableOK) return '---';
    if (r.schedules && r.schedules.length > 0) {
      let text = '';

      r.schedules.forEach((s) => {
        if (s.AllDay) {
          text = 'All Day';
          return text;
        }

        text = text + ' ' + Schedule.getScheduleTextTimeOnly(s);
      });
      return text;
    } else {
      {
        return `${_globalOkStart} to ${_globalOkStop}`;
      }
    }
  };

  const residentInPeriod = (r: iRoomAndSchedules): boolean => {
    const sfm = utils.dateGetSecondsFromMidnight(new Date());
    let inP = false;
    if (r.schedules && r.schedules.length > 0) {
      r.schedules.forEach((s) => {
        if (sfm >= s.StartTime && sfm <= s.StopTime) {
          inP = true;
        }
      });
    } else {
      const dateOKStart = utils.dateTodaySetTimeHHMM(_globalOkStart);
      const dateOKEnd = utils.dateTodaySetTimeHHMM(_globalOkStop);
      inP = utils.dateWithinDateRange(dateOKStart, dateOKEnd, new Date());
    }
    return inP;
  };

  const residentAfterAPeriod = (r: iRoomAndSchedules): boolean => {
    const sfm = utils.dateGetSecondsFromMidnight(new Date());
    let afp = false; // after a period

    // we are after a period if we are not in a period and we are greater than the first start time
    const p = residentInPeriod(r);
    if (!p) {
      if (r.schedules && r.schedules.length > 0) {
        const firstStart = r.schedules[0].StartTime;
        afp = sfm > firstStart;
      } else {
        const dateOKEnd = utils.dateTodaySetTimeHHMM(_globalOkStop);
        afp = utils.dateIsAfterDateTime(dateOKEnd, new Date());
      }
    }
    return afp;
  };

  // TODO add in check to ensure we are on the same day
  // decide whether we only want to do that check for  last seen/spoke to or also for ok presses as well
  const residentAfterPreviousStart = (
    r: iRoomAndSchedules,
    checkDate: Date
  ): boolean => {
    const sfm = utils.dateGetSecondsFromMidnight(new Date());
    const check = utils.dateGetSecondsFromMidnight(checkDate);
    let relevantStart = 0; // the first start time

    if (r.schedules && r.schedules.length > 0) {
      r.schedules.forEach((s) => {
        if (s.StartTime <= sfm) relevantStart = s.StartTime;
      });

      return check >= relevantStart;
    } else {
      const dateOKStart = utils.dateTodaySetTimeHHMM(_globalOkStart);
      return utils.dateIsAfterDateTime(dateOKStart, checkDate);
    }
  };

  // const inPeriod = utils.withinDateRange(dateOKStart, dateOKEnd, new Date());

  // Handle the colour logic for the resident status
  const getStatusColours = (s: iOKProps): iStatusColour => {
    // If away then set to amber
    if (s.away) return { background: amber, text: text, statusText: 'Away' };

    // If not monitored then just return
    if (!s.enabled)
      return {
        background: background,
        text: text,
        statusText: 'Not Monitored',
      };

    // If an Empty Property then just return
    if (s.empty)
      return {
        background: background,
        text: text,
        statusText: 'Empty Room',
      };

    // If ok then set to green
    if (s.ok)
      return { background: green, text: textWhite, statusText: 'Ok Pressed' };

    // If seen or spoken to then set to Aqua
    if ((s.seen || s.spokenTo) && (s.inPeriod || s.afterPeriod))
      return {
        background: aqua,
        text: text,
        statusText: s.spokenTo ? 'Spoken To' : 'Seen',
      };

    // If inside the monitoring period then set to blue
    if (s.inPeriod)
      return {
        background: blue,
        text: textWhite,
        statusText: 'Awaiting OK',
      };

    // If beyond the monitoring period and the OK was not pressed then set to red
    if (s.afterPeriod && !s.ok)
      return { background: red, text: textWhite, statusText: 'Ok not pressed' };

    return { background: background, text: text, statusText: '---' };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Call',
        accessor: '',
        width: 50,
        Cell: (s: Cell<iRoomAndSchedules>) => (
          <Phone
            color={
              callOpen
                ? resident == s.row.original.room.RoomNumber
                  ? 'green'
                  : 'blue'
                : 'grey'
            }
            cursor={callOpen ? 'pointer' : 'not-allowed'}
            onClick={() => {
              if (resident == s.row.original.room.RoomNumber) {
                setResident(-1); // drop call
              } else {
                setResident(s.row.original.room.RoomNumber); // call resident
              }
            }}
          />
        ),
        disableFilters: true,
      },

      {
        id: 'RoomNumber',
        Header: 'Room',
        accessor: 'RoomNumber',
        Cell: (s: Cell<iRoomAndSchedules>) => (
          <OKSTATUS
            {...getStatusColours({
              enabled: s.row.original.room.EnableOK === 1,
              empty: s.row.original.room.Empty === 1,
              ok: s.row.original.room.FlagOK === 1,
              away: s.row.original.room.AwayState === 1,
              inPeriod: residentInPeriod(s.row.original),
              afterPeriod: residentAfterAPeriod(s.row.original),
              spokenTo: residentAfterPreviousStart(
                s.row.original,
                utils.getDateFromEpoch(s.row.original.room.LastSpokenTo)
              ),
              seen: residentAfterPreviousStart(
                s.row.original,
                utils.getDateFromEpoch(s.row.original.room.LastSeen)
              ),
            })}
          >
            <Text size="medium">{s.row.original.room.RoomNumber}</Text>
          </OKSTATUS>
        ),
        width: 60,
        disableFilters: true,
      },
      {
        Header: 'Name',
        accessor: 'Name',
        Cell: (s: Cell<iRoomAndSchedules>) => (
          <Text size="medium">{s.row.original.room.Name}</Text>
        ),
        disableFilters: true,
      },
      {
        Header: 'Monitoring',
        accessor: '',
        width: 80,
        Cell: (s: Cell<iRoomAndSchedules>) => (
          <Text size="medium">{residentScheduleText(s.row.original)}</Text>
        ),
        disableFilters: true,
      },

      {
        id: 'OK',
        Header: 'Status',
        accessor: (row: iRoomAndSchedules) => {
          const st = getStatusColours({
            enabled: row.room.EnableOK === 1,
            empty: row.room.Empty === 1,
            ok: row.room.FlagOK === 1,
            away: row.room.AwayState === 1,
            inPeriod: residentInPeriod(row),
            afterPeriod: residentAfterAPeriod(row),
            spokenTo: residentAfterPreviousStart(
              row,
              utils.getDateFromEpoch(row.room.LastSpokenTo)
            ),
            seen: residentAfterPreviousStart(
              row,
              utils.getDateFromEpoch(row.room.LastSeen)
            ),
          });
          return (
            <OKSTATUS {...st}>
              <Text>{st.statusText}</Text>
            </OKSTATUS>
          );
        },
        width: 90,
        disableFilters: true,
      },
      // {
      //   Header: 'Hours since pressed',
      //   accessor: (row: Room) => {
      //     if (row.LastOK > 0 && row.EnableOK) {
      //       const date = new Date();
      //       date.setTime(row.LastOK * 1000);
      //       const dateNow = new Date();
      //       const msSincePressed = dateNow.getTime() - date.getTime();

      //       return Math.round(msSincePressed / 1000 / 60 / 60);
      //     } else {
      //       return '';
      //     }
      //   },
      //   width: 100,
      // },
      {
        Header: 'Last Ok Press',
        accessor: (row: iRoomAndSchedules) => {
          const relevant = residentAfterPreviousStart(
            row,
            utils.getDateFromEpoch(row.room.LastOK)
          );
          if (!row.room.EnableOK) {
            return '';
          } else if (row.room.LastOK > 0) {
            return (
              <Text
                color={relevant ? text : textLighter}
                weight={relevant ? 'bold' : 'lighter'}
              >
                {utils.formatDateFromEpoch(row.room.LastOK)}
              </Text>
            );
          } else {
            return <Text>--</Text>;
          }
        },
        width: 100,
        disableFilters: true,
      },

      {
        Header: 'Last Spoken To',
        //width: 100,
        accessor: (row: iRoomAndSchedules) => {
          if (row.room.LastSpokenTo > 0) {
            return utils.formatDateFromEpoch(row.room.LastSpokenTo);
          } else {
            return '--';
          }
        },
        Cell: (s: Cell<iRoomAndSchedules>) => {
          const relevant = residentAfterPreviousStart(
            s.row.original,
            utils.getDateFromEpoch(s.row.original.room.LastSpokenTo)
          );
          return (
            <span>
              <SCUversion minVersion="5.10.0">
                <StatusGood
                  color={'green'}
                  style={{
                    marginRight: '10px',
                  }}
                  size="16px"
                  cursor="pointer"
                  onClick={async () => {
                    const room = manifest.rooms.find(
                      (e) => e.RoomNumber === s.row.original.room.RoomNumber
                    );
                    if (room !== undefined) {
                      //logger.info('resident.ok.setSpokenTo', { room: room });
                      await room.setSpokenTo(UserUtils.getCurrentUsersName());
                      setDataloaded((old) => old + 1);
                    }
                  }}
                />
                <Text
                  color={relevant ? text : textLighter}
                  weight={relevant ? 'bold' : 'lighter'}
                >
                  {s.value}
                </Text>
              </SCUversion>
            </span>
          );
        },
        disableFilters: true,
      },
      {
        Header: 'Last Seen',
        //width: 100,
        accessor: (row: iRoomAndSchedules) => {
          if (row.room.LastSeen > 0) {
            return utils.formatDateFromEpoch(row.room.LastSeen);
          } else {
            return '--';
          }
        },
        Cell: (s: Cell<iRoomAndSchedules>) => {
          const relevant = residentAfterPreviousStart(
            s.row.original,
            utils.getDateFromEpoch(s.row.original.room.LastSeen)
          );
          return (
            <span>
              <SCUversion minVersion="5.10.0">
                <StatusGood
                  color={'green'}
                  style={{
                    marginRight: '10px',
                  }}
                  size="16px"
                  cursor="pointer"
                  onClick={async () => {
                    const room = manifest.rooms.find(
                      (e) => e.RoomNumber === s.row.original.room.RoomNumber
                    );
                    if (room !== undefined) {
                      //logger.info('resident.ok.setSeen', { room: room });
                      await room.setSeen(UserUtils.getCurrentUsersName());
                      setDataloaded((old) => old + 1);
                    }
                  }}
                />
                <Text
                  color={relevant ? text : textLighter}
                  weight={relevant ? 'bold' : 'lighter'}
                >
                  {s.value}
                </Text>
              </SCUversion>
            </span>
          );
        },
        disableFilters: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callOpen, resident, monitoringInfo]
  );

  const initialState = React.useMemo(
    () => ({
      pageSize: 15,
      sortBy: [
        {
          id: 'RoomNumber',
          desc: false,
        },
      ],
      filters: [],
    }),
    []
  );

  async function requestUpdate() {
    try {
      await IoTSCUManifest.Instance.requestManifestUpload(null);
    } catch (error) {
      logger.error('Error waiting for manifest request, error:', error);
    }
  }

  useEffect(() => {
    requestUpdate();
  }, []);

  return (
    <Tile
      title="Resident OK Monitoring"
      eventListeners={events}
      setChildEvent={SetChildEvent}
    >
      <>
        {dataloaded > 0 && (
          <Box>
            <Box margin={{ top: '20px' }}>
              <Box direction="row" margin={{ left: '10px', bottom: '10px' }}>
                <Box margin={{ left: '10px', top: '-4px' }}>
                  <RadioButton
                    label={`Show Only Monitored Rooms`}
                    name="showMonitored"
                    value="Monitored Only"
                    checked={selectedRB === 'Monitored Only'}
                    onChange={(event) => setSelectedRB(event.target.value)}
                  />
                </Box>
                <Box margin={{ left: '10px', top: '-4px' }}>
                  <RadioButton
                    label="Show All Residents"
                    name="showNonCommunal"
                    value="NonCommunal"
                    checked={selectedRB === 'NonCommunal'}
                    onChange={(event) => setSelectedRB(event.target.value)}
                  />
                </Box>
                <Box margin={{ left: '10px', top: '-4px' }}>
                  <RadioButton
                    label="Show All Rooms"
                    name="showAll"
                    value="All"
                    checked={selectedRB === 'All'}
                    onChange={(event) => setSelectedRB(event.target.value)}
                  />
                </Box>
                <Box margin={{ left: '40px', top: '-15px' }}>
                  <ResidentCalling
                    resident={resident}
                    setResident={setResident}
                    setCallOpen={setCallOpen}
                  />
                </Box>
              </Box>

              <TablePaged
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={columns}
                initialState={initialState}
                data={
                  selectedRB == 'All'
                    ? rooms
                    : selectedRB == 'NonCommunal'
                    ? rooms.filter((r) => !r.room.isCommunal() && !r.room.Empty)
                    : rooms.filter((r) => r.room.EnableOK && !r.room.Empty)
                }
                showControlsExports={false}
                showCheckboxes={false}
                reportDescription={{
                  header: '',
                  filename: 'ResidentOk',
                }}
              />
            </Box>
          </Box>
        )}
      </>
    </Tile>
  );
};

export default ResidentOK;
