import React, { FC, useState } from 'react';
import { Box, CheckBox } from 'grommet';
import { CellProps } from './commonCellProps';

const CheckBoxCell: FC<CellProps> = ({
  id,
  valueProperty,
  hasChanged,
  getProperty,
  setProperty,
}) => {
  const value = getProperty(id, valueProperty);
  const [, setComponentRenderCount] = useState(0);

  //console.info('CheckBoxCell: render');

  return (
    <>
      <Box align="center" background={hasChanged ? 'gold' : 'grey-0'}>
        <CheckBox
          checked={value ? true : false}
          onChange={(event) => {
            setProperty(id, valueProperty, event.target.checked ? 1 : 0);
            setComponentRenderCount((old) => old + 1);
          }}
        />
      </Box>
    </>
  );
};

const memorised = React.memo(CheckBoxCell);

export default memorised;
