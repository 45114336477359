/** @module FloorPlans */

import { Cable, Terminal } from 'types/cable-types';
import { ProductCategory } from 'types/product-types';
import { Product } from './product';
import * as Site from './site';
import { CableSpec } from '../../types/cable-types';
import { cablesSpecs } from 'data/cable-definitions';
import { CABLENAMES } from '../../data/cable-definitions';

export let cableSchedule: Cable[] = [];

/**
 * Generate array of cables
 *
 * @oaram true to show cables, false to hide
 */

const getLocationName = (prod: Product): string => {
  return prod?.locationName ? ' (' + prod?.locationName + ')' : '';
};

export const makeCable = (
  from: Product,
  to: Product | undefined,
  prefix: string,
  id: number,
  type: 'Data' | 'POE' | 'Control',
  voltage: string,
  cableSpec: CableSpec | undefined,
  showOnPlan: boolean
): Cable => {
  // set the drom description dependent on whether its a product or a 3rdparty output on the plan
  // products show as UniqueID and Name
  // 3rd party outputs as UniqueID and locationname/notes
  const fromDescription =
    from.category == ProductCategory.THIRDPARTYOUTPUT
      ? `${from.uniqueID} ${from.locationName} ${from.designNotes}`
      : `${from.uniqueID} ${from.name}`;

  const termFrom: Terminal = {
    locationName:
      Site.siteData.plans[from.plan].planName + getLocationName(from),
    unitUniqueID: from.uniqueID,
    unitDescription: fromDescription,
    cableTag: to ? `${prefix}${from.plan}${id}-${to.uniqueID}` : '**ERROR**',
    position: { plan: from.plan, x: from.x, y: from.y },
  };

  const termTo: Terminal = {
    locationName: to
      ? Site.siteData.plans[to.plan].planName + getLocationName(to)
      : '',
    unitUniqueID: to ? to.uniqueID : '',
    unitDescription: to ? `${to.uniqueID} ${to.name}` : '',
    cableTag: to ? `${prefix}${from.plan}${id}-${from.uniqueID}` : '**ERROR**',
    position: to
      ? { plan: to.plan, x: to.x, y: to.y }
      : { plan: 0, x: 0, y: 0 },
  };

  // create the cable
  const cable: Cable = {
    cableID: `${prefix}${from.plan}${id}`,
    cableSpec: cableSpec,
    connected: to != undefined,
    terminalFrom: termFrom,
    terminalTo: termTo,
    type: type,
    voltage: voltage,
    showOnPlan: showOnPlan,
  };

  return cable;
};

/** Generate the list of cables for the site */
export const generateCables = (planNumber: number): void => {
  // erase any existing cables
  cableSchedule = [];
  let id = 1;
  const equip = Site.getEquipmentOnThisPlanOnly(planNumber);

  // Add POE Cabinets  First
  // decide what plan the SCU is on...
  const SCU = Site.siteData.equipment.find((f) => f.designator == 'SCU');
  const SCUPOE = Site.siteData.equipment.find(
    (f) => f.designator == 'POE' && f.plan == SCU?.plan
  );

  Site.siteData.equipment
    .filter((f) => f.designator == 'POE')
    .forEach((e) => {
      // add rows to connect all other POE cabinets to the SCU floor/plan
      if (SCUPOE && planNumber == SCU?.plan && e.plan != planNumber) {
        const cable = makeCable(
          SCUPOE, // from the SCU POE cabinet
          e, // to another POE cabinet
          'S',
          id,
          'Data',
          'N/A',
          cablesSpecs[CABLENAMES.SHIELDEDCAT5],
          false
        );
        cableSchedule.push(cable);
        id++;
      }
    });

  //**********Add MAP Connections for general inputs .. if any
  id = 1;
  equip.forEach((e) => {
    // add rows for all equipment with a MAP input connection
    if (e.MAPConnection && e.MAPRequired) {
      const cable = makeCable(
        e,
        Site.getProductFromUniqueID(e.MAPConnection.connectedTo),
        'MP',
        id,
        'Control',
        '12V',
        cablesSpecs[CABLENAMES.BASICCAT5],
        true
      );
      cableSchedule.push(cable);
      id++;
    }
  });

  //************Add MAI Connections for Lifts, VPC, Door etc
  id = 1;
  equip.forEach((e) => {
    // add rows for all equipment with a CAT5 connection
    if (e.MAIConnection && e.MAIRequiredNumber > 0) {
      for (let i = 0; i < e.MAIRequiredNumber; i++) {
        const cable = makeCable(
          e,
          Site.getProductFromUniqueID(e.MAIConnection.connectedTo),
          'MA',
          id,
          'Data',
          '12V',
          cablesSpecs[CABLENAMES.BASICCAT5],
          true
        );
        cableSchedule.push(cable);
        id++;
      }
    }
  });

  //************ Add CAT5 POE Connections for POE and MLS
  id = 1;
  equip.forEach((e) => {
    // add rows for all equipment with a CAT5 connection
    if (e.cat5Connection && e.cat5Required) {
      const cable = makeCable(
        e,
        Site.getProductFromUniqueID(e.cat5Connection.connectedTo),
        'P',
        id,
        'POE',
        '48V',
        cablesSpecs[CABLENAMES.SHIELDEDCAT5],
        e.cat5Connection.connectedTo.startsWith('MLS') // only show connections on plan to other MLS units
      );
      cableSchedule.push(cable);
      id++;
    }
  });
};
