import TablePaged, { dateFilter } from 'components/tables/TablePaged';

import React from 'react';
import useState from 'react-usestateref';
import Tile, { iTileEvent, iMenuProps } from '../tile';
import * as systemFaults from 'components/systemevents/faults';
import * as Utils from 'common/utils/dateUtils';
import { systemEventStates, systemEventTopics } from 'store/eventDispatcher';
import * as API from 'types/API';
import * as Icons from 'grommet-icons';
import { Text, Tip, Box } from 'grommet';
import * as UserUtils from 'common/userUtils';
import WaitDialogue, * as Wait from 'components/dialogues/waitDialogue';
import store from 'store/store';
import * as faultsslice from 'store/scuFaultsSlice';
import _ from 'lodash';
import { Column, Cell, Row } from 'react-table';
import { Manifest } from 'components/installation/manifest';
import { Product } from 'components/floorplan/product';

// clearedBy: null
// createdAt: "2022-01-05T19:39:36.812Z"
// dateAlertLastGenerated: null
// dateCleared: null
// dateRaised: "2022-01-05T19:39:31.000Z"
// deviceID: "6"
// expirationUnixTime: null
// faultSpecificText: "172.16.10.7"
// faultState: "FAULT_ACTIVE"
// faultType: "ETHERNET_FAIL"
// friendlyString: "Ethernet fail for device ID:6 IP Address:172.16.10.7"
// id: "9119a7e8-4dc9-451e-b462-2d3dc958c9bf"
// updatedAt: "2022-01-05T19:39:36.812Z"

const manifest = Manifest.Instance;

interface faultData extends API.OmniviaFaults {
  room?: number;
}

const RecentFaults = (): JSX.Element => {
  const [, SetChildEvent] = useState('');
  const [selectedRows, setSelectedRows] = useState<API.OmniviaFaults[]>([]);
  const [waitDiaglogueProps, setWaitDiaglogueProps] = useState<
    Wait.DialogueProps | undefined
  >();
  const [faults, setFaults] = useState<API.OmniviaFaults[]>([]);

  const clearFaults = async () => {
    console.info('faults to clear:', selectedRows);
    const promises: Promise<unknown>[] = [];
    for (const index in selectedRows) {
      const fault = selectedRows[index];

      if (fault.faultState !== API.OmniviaFaultState.FAULT_ACTIVE) {
        console.info('ignoring fault as not active:', fault);
        continue;
      }

      const faultChanges = {
        id: fault.id,
        // Need to provide the deviceID as this is used as part of the unique key
        deviceID: fault.deviceID,
        // Need to provide the faultType as this is used as part of the unique key
        faultType: fault.faultType,
        dateCleared: new Date().toISOString(),
        faultState: API.OmniviaFaultState.FAULT_FORCE_CLEARED_USER,
        clearedBy: UserUtils.getCurrentUsersName(),
      };

      console.info('recentFaults.clearFault:', faultChanges);
      promises.push(store.dispatch(faultsslice.update(faultChanges)));
    }

    await Promise.allSettled(promises);

    setWaitDiaglogueProps(undefined);
    setSelectedRows([]);
  };

  const getFaultToolTip = (fault: string) => {
    if (fault === 'Ethernet link fail') {
      return (
        <Text>
          <Box>
            The device is in a degraded mode.
            <br />
            The resident will not be able to see a video feed during a door
            call.
            <br />
            The alarm functionality is unaffected.
            <br />
            It is advised to verify functionality by performing a test alarm
            call.
            <br />
            <br />
            Please contact your support provider for advise.
          </Box>
        </Text>
      );
    } else if (fault === 'Poll fail') {
      return (
        <Text>
          <Box>
            The device has not been seen for a number of hours
            <br /> and may be offline/faulty.
            <br />
            Please test the device as soon as possible.
          </Box>
        </Text>
      );
    } else if (fault === 'Primary telephone line fail') {
      return (
        <Text>
          <Box>
            URGENT attention required
            <br />
            Alarm calls may not be possible to the control centre.
          </Box>
        </Text>
      );
    } else if (fault === 'Secondary telephone line fail') {
      return (
        <Text>
          <Box>
            URGENT attention required
            <br />
            Alarm calls may not be possible to the control centre.
          </Box>
        </Text>
      );
    } else if (fault === 'Main power fail') {
      return (
        <Text>
          <Box>
            The electrical main power feed to the system has failed.
            <br />
            The system is running on battery backup.
            <br />
            <br />
            Please contact your support provider as soon as possible.
          </Box>
        </Text>
      );
    } else if (fault === 'PSU battery low') {
      return (
        <Text>
          <Box>
            The electrical main power feed to the system has failed and
            <br />
            the battery backup is critically low. <br />
            <br />
            Please contact your support provider as soon as possible.
          </Box>
        </Text>
      );
    } else if (fault === 'Device battery low') {
      return (
        <Text>
          <Box>
            The device battery is low and should be scheduled
            <br />
            for replacement as soon as possible.
          </Box>
        </Text>
      );
    } else if (fault === 'Audio route fail') {
      return (
        <Text>
          <Box>
            An audio call was unable to be setup.
            <br />
            This is not an urgent issue but may impact speech calls.
            <br />
            Please contact your support provider for advise.
          </Box>
        </Text>
      );
    }
  };

  // EventType: number;
  //   RaisedDeviceID: number;
  //   AlarmID: number;
  //   RaisedTime: string;
  //   OwnerDeviceName: string;
  //   FriendlyString: string;

  const columns = React.useMemo<Column<API.OmniviaFaults>[]>(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        disableExport: true,
      },
      {
        Header: 'Date/Time',
        id: 'RaisedTime',
        width: 150,
        //accessor: (row: alarmEvents.iAlarmSummary) => formatDateWithSeconds(row.raisedAt),

        accessor: (row) => new Date(row.dateRaised),
        sortType: 'datetime',

        filter: dateFilter,

        // Using the cell access so that the sortType: 'datetime' works
        Cell: (data: Cell<API.OmniviaFaults>) => {
          const date = data.value;
          if (date) return date ? Utils.formatDateWithSeconds(date) : 'Unknown';
        },

        getCellExportValue: (row: Row<faultData>) => {
          return Utils.formatDateWithSeconds(row.original.dateRaised);
        },
      },
      {
        Header: 'Type',
        accessor: (row) => systemFaults.getFaultText(row.faultType),
        Cell: (data: Cell<API.OmniviaFaults>) => {
          return <Tip content={getFaultToolTip(data.value)}>{data.value}</Tip>;
        },
      },
      {
        Header: 'Room',
        width: 75,
        accessor: (row) => {
          const roomNUmber = manifest.getAssoaciatedRoomNumberForDeviceID(
            row.deviceID
          );
          return roomNUmber === undefined ? 'N/A' : roomNUmber;
        },
      },

      {
        id: 'State',
        Header: 'State',
        accessor: (row) => systemFaults.getStateText(row.faultState),
        width: 120,
      },
      {
        Header: 'Device ID',
        accessor: 'deviceID',
        width: 120,
      },
      {
        Header: 'Identity',
        accessor: (fault: API.OmniviaFaults) => {
          const ID = parseInt(fault.deviceID);
          const device = manifest.getDevice(ID);
          const trigger = manifest.triggers.find((e) => e.ID === ID);

          let macAddress = '';
          if (trigger !== undefined) {
            macAddress = trigger.getIdentity();
          } else {
            macAddress = device.MacAddress.toString(16);
          }
          return macAddress.substring(macAddress.length - 6, 6);
        },
        width: 120,
      },
      {
        Header: 'Description',
        accessor: (fault: API.OmniviaFaults) => {
          const device = manifest.getDevice(parseInt(fault.deviceID));
          return Product.getProductBaseName(
            Product.getProductfromManifestDeviceType(device)
          );
        },
        width: 160,
      },
      {
        Header: 'Cleared By',
        //accessor: 'clearedBy',
        accessor: (row) => {
          if (
            row.faultState !== API.OmniviaFaultState.FAULT_ACTIVE &&
            (row.clearedBy == '' || row.clearedBy === null)
          ) {
            return 'System';
          }

          return row.clearedBy;
        },
      },

      {
        Header: 'Date Cleared',
        accessor: (row) =>
          row.dateCleared ? new Date(row.dateCleared) : undefined,
        sortType: 'datetime',
        filter: dateFilter,
        /* eslint-disable react/prop-types */
        Cell: ({ ...props }) =>
          props.cell.value ? Utils.formatDateWithSeconds(props.cell.value) : '',
      },
    ],
    [faults] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const loadDataEvent = () => {
    if (manifest.rooms.length > 0) {
      // perform a deep copy so the compoent is re-rendered
      setFaults(_.cloneDeep(systemFaults.allFaults));
    }
  };

  const initialState = React.useMemo(
    () => ({
      hiddenColumns: ['id'],
      pageSize: 10,
      sortBy: [
        {
          id: 'RaisedTime',
          desc: true,
        },
      ],
      filters: [
        {
          id: 'State',
          value: 'Active',
        },
      ],
    }),
    []
  );

  const events: iTileEvent[] = [
    {
      topic: systemEventTopics.SITEEVENTS,
      state: systemEventStates.COMPLETE,
      callback: loadDataEvent,
      executeOnStartup: true,
    },

    {
      topic: systemEventTopics.MANIFEST,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
    {
      topic: systemEventTopics.FAULTS,
      state: systemEventStates.PROCESSED,
      callback: loadDataEvent,
      executeOnStartup: false,
    },
  ];

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Clear selected faults',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            setWaitDiaglogueProps({
              show: true,
              dialogueText: `You have selected ${selectedRows.length} faults to clear`,
              dialogueTextLine2: 'Are you sure?',
              showSpinner: false,
              showOkButton: true,
              okButtonPress: () => {
                clearFaults();
              },
              showCancelButton: true,
              cancelButtonPress: () => {
                setWaitDiaglogueProps(undefined);
              },
            });
          },
          disabled: selectedRows.length == 0,
        },
      ],
    }),
    [selectedRows] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Tile
      title="System Faults"
      eventListeners={events}
      setChildEvent={SetChildEvent}
      menuProps={menuItems}
      showLastEvent={true}
    >
      <>
        {waitDiaglogueProps && <WaitDialogue {...waitDiaglogueProps} />}
        <Text>
          Use the tick boxes to selected faults that are required to be forced
          cleared if no longer applicable.
        </Text>
        <TablePaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          initialState={initialState}
          data={faults}
          showCheckboxes={true}
          onSelectRow={(rows: API.OmniviaFaults[]) => {
            setSelectedRows(rows);
          }}
          reportDescription={{
            header: '',
            filename: 'RecentFaults',
          }}
        />
      </>
    </Tile>
  );
};

export default RecentFaults;
