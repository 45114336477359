const releaseNote = `
# Recent Change History

## 1.1.34 13th November 2024
Branding Changes.     

## 1.1.33 17th October 2024
Improvements to plan design tools.     

## 1.1.32 8th October 2024
Protected System Alarm types now accessible via admin level.     

## 1.1.31 7th October 2024
### Changes 
Added Bogus Caller, Door Closed and Door Contact options to VRU hardwired inputs.     
Identity of faulty items on the system faults page will be shown in hex apart from Tunstall triggers which will show in decimal.     

## 1.1.30 4th October 2024
### Changes 
Hardwired alarm types for SCU, MAI, MAP and VRUs are now product specific dropdown lists rather than generic lists of all alarm types. The top 5 most common input types are shown first, followed by the rest in alphabetical order.     

## 1.1.29 25th September 2024
### Changes 
Digital ARC configuration. Added seperate tickbox options to make all calls as audio (media stream calls).     
Added device management support for older carelocate gateway interfaces.     
Corrected the display of the identity on the Low Battery overview page for Tunstall peripherals.     

## 1.1.28 18th September 2024
### Changes
Added new audit message for alarm calls audit trail that point to empty care group.     
Added 1st generation gateway interface product (GWI) onto Insights.     
Corrected alarms and door overviews on the Room page to show only calls for that room.     
Removed Event Data from Audit Trail export.     

## 1.1.27 10th September 2024
### Changes
Allow Empty Call Sequences, alarms directed to empty call sequences will not make any sound at the room unit and simply auto-clear. They will still display on the Alarm log on Insights and "Empty Call Sequence" will be displayed in the answered by column.     
Included parameter 13 - no of fails on the FXO telephone line before an alarm is raised. A setting of 0 means no monitoring.     
Various changes to Tunstall peripheral defaults.     
Changing Digital ARC settings will now take effect without having to reset the system control unit.     
Added Date Assigned to peripeherals.     
Added Assigned By Name to peripeherals (when assigned using Insights).     
Amended Low Battery Text on Alarm History Screens.     

## 1.1.26 4th September 2024
### Changes
Enabled linking of two rooms units in the same room. When a door visitor call is made to one of the room units then both will ring. See the room page to confgure this.     
Enabled linking of a room unit to the managers handset. When a door visitor call is made to the manager then the room unit will also receive the call. See the room page to confgure this.     

## 1.1.25 2nd September 2024
### Changes
Digital Monitoring Centre screen now shows connection history (last 10 registrations/heartbeat). Green means GOOD, Amber means connection made but was over 2 hours ago. RED means an issue occured. The Status Reason is shown in the tip for further diagnosis.    
Added Routing Report on Call Sequences Screen.    

## 1.1.24 28th August 2024
### Changes
Added Digital Monitoring Centre Configuration Screens.     

## 1.1.23 23rd August 2024
### Changes
Extended Alarm Type, Codes and Priorities Functionality to include exporting and importing of excel sheets.     
Extended Alarm Types to allow setting of which alarms are technical.     
Various settings for the new Evolution+ handset.     
Allow users of level manager and above to use the announcements screen.     

## 1.1.22 29th July 2024
### Changes
Alterations to ARC equipment report.     
Added Evidence of actual Room Visits to the Alarm History and Audit (any visits upto 12 hours after the alarm will also be shown in the audit report).     

## 1.1.21 24th July 2024
### Changes
Added Equipment Details Report for submission to ARC.     

## 1.1.20 23rd July 2024
### Changes
Extended Coverage Report to include plan designators.     

## 1.1.19 23rd July 2024
### Changes
If a design plan showed a unit as communal then this will now carry across to the room unit settings once commissioned.     
Faults screen added to service menu to aid installers.      
Commissioned products now show on the design plan in green to distinguish them from items not yet installed/commissioned.

## 1.1.18 22nd July 2024
### Changes
Added Announcements page in Calls and Monitoring to allow spoken announcements to be made to some or all rooms.     
Added Residents Groups page to allow custom groups of rooms to be created.      
Renamed Care Groups to Call Sequences to avoid any confusion.  
Added new reports to group together useful reports.    
Added alarm coverage report to verify service and commissioning activity.    
Added back and forward navigation buttons to allow moving between recent pages.    
Added new service test mode screen to allow a group of rooms to be placed into service test mode.    

## 1.1.17 10th July 2024
### Changes
Internal changes for design plans and quotations

## 1.1.16 2nd July 2024
### Changes
Added the peripheral type and identity of the device that raised the alarm to the alarm audit trail header and a hover over tip on the recent alarms page.

## 1.1.15 1st July 2024
### Changes
Added door entry PIN access attempts to door calls log to show allowed and invalid PIN attempts

## 1.1.14 29th June 2024
### Changes
Door Entry Access PINs - button added to allow all panels to be added in one go.
Fixed minor issue with newly created door entry PINs sometimes not retaining name and assigned panel

## 1.1.12 28th June 2024
### Changes
Added Ability to Name a door entry access PIN. This allows easy identification of door entry PINs

## 1.1.11 30th April 2024
### Changes
Added Service Tab to room. Allows a room unit to be set into service mode for a chosen duration. Unit will time out after that duration or the sevice mode can be manually disabled.     
Added Screens to configure multiple door access PINs. An unlimited number can be assigned each with their own schedule. Doors that using the access PIN can be dragged and dropped from the sidebar.      
Support of Tunstall Wireless Peripherals - renaming of various types.  
Enabled Inactivity checkbox for devices attached to a room.    
Enabled Intruder checkbox for devices attached to a room.    
Added 'Alarm Types' to setting for configuration of alarm types, status fields and priorities.      
Added Location field for wireless devices.    
Added Door Call Divert options to Resident Screen.    
Enabled "Alarm Disabled" checkbox on a wired device. This is for devices that are used for inactivty or intruder monitoring only.    
Enabled Intruder Monitoring checkbox on resident and added allow home/away for arming/disarming.    
Intruder can be armed in different ways:-    
- By using the Away button. Whilst Away the Intruder Alarm is active.
- By using one or more key switches wired into the inputs of the room units.
- By using a wireless arm/disarm device or fob.        

## 1.1.10 11th April 2024
### Changes
Add Resident Schedules for Inactivity and I'm OK to GUI Room Page. Unlimited number of schedules for each.    
Add tabs to room screen to ensure it doesn't get too cluttered    
Enabled use of Resident OK Alarm Checkbox on Room Page    
Allow inactivity checkbox to be used to enable/disable inactivity for that resident    
Changed Resident OK settings Screen to include for both resident OK and Inactivity global parameters being set.     
If a resident has no individual schedules set then the global ones are used for that resident    
Show individual I'm ok schedules against a reesident on the I'm ok monitoring screen. Ensure the colour status follows multiple periods in one day.    

- No colour before the 1st period has started that day
- Blue whilst in a monitoring period
- Bright Green if ok button pressed during the monitoring period
- Pale Green if spoken to or seen is recorded either during the period or afterwards (but before the next monitoring period starts if there are multiple in one day)
- Red if a period ends and no OK button was pressed. This will reset on the next monitoring period if there are multiple periods in one day


## 1.1.09 7th April 2024
### Changes

Enabled hardwired inputs editing fields
Enabled wireless device editing fields
Added Assigning and Deleting wireless devices to the room page (normally done on the peripherals page)
Added additional I'm ok settings, regarding the reminders that were bit exposed in the room page
Added access to the schedules against a device or hardwired input
Tidied the schedule editing to state 'start and 'stop'. Improved use of icons.
Altered search boxes colours on dropdown selects to ensure search text is readable.


`;

export default releaseNote;
