import React, { useState } from 'react';
import 'types/event-enums';
import Tile from '../tile';
import { Text } from 'grommet';

const Blank = (): JSX.Element => {
  const [, SetUpdateEvent] = useState('');

  return (
    <Tile title="Mobile Page 1" setChildEvent={SetUpdateEvent}>
      <Text>Page 1</Text>
    </Tile>
  );
};

export default Blank;
