import React, { useState } from 'react';
import Tile, { iMenuProps, contextButtonStyle } from '../tile';
import { Form, Text, Button, Box } from 'grommet';
import _ from 'lodash';
import * as Icons from 'grommet-icons';
import i18next from 'i18n';
import * as FormFields from 'components/forms/formFieldsOld';
import * as sitesslice from 'store/sitesSlice';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import { getDifferences } from 'common/utils/miscUtils';

// We only use a subset of fields from the iUserConfig
interface iConfig {
  stuckAlarmMinAge: number;
  stuckAlarmMaxAge: number;
  stuckAlarmMinTimeBetweenSMS: number;
  siteAlertsEnabled: boolean;
}

const defaults: iConfig = {
  stuckAlarmMinAge: 15, // 15 minutes
  stuckAlarmMaxAge: 180, // 3 hours
  stuckAlarmMinTimeBetweenSMS: 10, // 10 minutes
  siteAlertsEnabled: true,
};

let unchangedConfig: iConfig = defaults;

const fields: FormFields.iField[] = [
  {
    label: 'Enable site alerts',
    id: 'siteAlertsEnabled',
    type: 'checkbox',
    tooltip: (
      <Text style={{ whiteSpace: 'pre-wrap' }}>
        {i18next.t('site alerts site info', {})}
      </Text>
    ),
  },
  {
    label: 'Alarm age to trigger sending alerts (minutes)',
    id: 'stuckAlarmMinAge',
    type: 'number',
    tooltip: (
      <Text style={{ whiteSpace: 'pre-wrap' }}>
        {i18next.t('site alerts min age', {})}
      </Text>
    ),
  },
  {
    label: 'Alarm age to stop sending alerts (minutes)',
    id: 'stuckAlarmMaxAge',
    type: 'number',
    tooltip: (
      <Text style={{ whiteSpace: 'pre-wrap' }}>
        {i18next.t('site alerts max age', {})}
      </Text>
    ),
  },
  {
    label: 'Minimum time between alerts (minutes)',
    id: 'stuckAlarmMinTimeBetweenSMS',
    type: 'number',
    tooltip: (
      <Text style={{ whiteSpace: 'pre-wrap' }}>
        {i18next.t('site alerts alert period', {})}
      </Text>
    ),
  },
];

const SiteAlertSettings = (): JSX.Element => {
  const seletedSite = useSelector(sitesslice.getSelected);

  const [config, setConfig] = useState<iConfig>(defaults);

  const [, SetChildEvent] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);

  async function updateInfo() {
    if (seletedSite === undefined) return;

    const selectedSiteCopy = _.cloneDeep(seletedSite);

    selectedSiteCopy.stuckAlarmMinAge =
      selectedSiteCopy.stuckAlarmMinAge ?? defaults.stuckAlarmMinAge * 60;

    selectedSiteCopy.stuckAlarmMaxAge =
      selectedSiteCopy.stuckAlarmMaxAge ?? defaults.stuckAlarmMaxAge * 60;

    selectedSiteCopy.stuckAlarmMinTimeBetweenSMS =
      selectedSiteCopy.stuckAlarmMinTimeBetweenSMS ??
      defaults.stuckAlarmMinTimeBetweenSMS * 60;

    selectedSiteCopy.siteAlertsEnabled =
      selectedSiteCopy.siteAlertsEnabled ?? defaults.siteAlertsEnabled;

    const data: iConfig = {
      stuckAlarmMinAge: selectedSiteCopy.stuckAlarmMinAge / 60,
      stuckAlarmMaxAge: selectedSiteCopy.stuckAlarmMaxAge / 60,

      stuckAlarmMinTimeBetweenSMS:
        selectedSiteCopy.stuckAlarmMinTimeBetweenSMS / 60,

      siteAlertsEnabled: selectedSiteCopy.siteAlertsEnabled,
    };

    unchangedConfig = _.cloneDeep(data);

    setConfig(unchangedConfig);
    setShowSpinner(false);
  }

  const save = async (): Promise<void> => {
    console.info('Saving user settings');
    if (seletedSite === undefined) return;

    const selectedSiteCopy = _.cloneDeep(seletedSite);

    selectedSiteCopy.stuckAlarmMinAge = config.stuckAlarmMinAge * 60;
    selectedSiteCopy.stuckAlarmMaxAge = config.stuckAlarmMaxAge * 60;
    selectedSiteCopy.stuckAlarmMinTimeBetweenSMS =
      config.stuckAlarmMinTimeBetweenSMS * 60;
    selectedSiteCopy.siteAlertsEnabled = config.siteAlertsEnabled;

    const diff = getDifferences(seletedSite, selectedSiteCopy);

    await store.dispatch(
      sitesslice.update({
        id: seletedSite.id,
        changes: diff,
      })
    );

    unchangedConfig = _.cloneDeep(config);
    setShowSpinner(false);
  };

  const configChanged = () => {
    return !_.isEqual(unchangedConfig, config);
  };

  const ContextButtons = (
    <Button
      plain={false}
      size="small"
      label="Save"
      color={configChanged() ? 'brand' : 'grey-4'}
      badge={configChanged()}
      tip="Save changes"
      disabled={configChanged() ? false : true}
      style={contextButtonStyle}
      onClick={() => {
        setShowSpinner(true);
        save();
      }}
    />
  );

  const menuItems = React.useMemo<iMenuProps>(
    () => ({
      disabled: false,
      items: [
        {
          label: 'Discard Changes',
          icon: <Icons.Clear size="medium" />,
          onClick: () => {
            updateInfo();
          },
          disabled: false,
        },
      ],
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  React.useEffect(() => {
    updateInfo();
    // UseEffect's cleanup function
    return () => {
      console.info('Unmount');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tile
      title={seletedSite?.name + ' Site Alert Settings'}
      setChildEvent={SetChildEvent}
      ContextButtons={ContextButtons}
      menuProps={menuItems}
      showSpinner={showSpinner}
      spinnerText=""
    >
      <>
        <Form<iConfig>
          value={config}
          onReset={() => setConfig(unchangedConfig)}
          onChange={(nextValue) => {
            setConfig(nextValue);
          }}
        >
          <Box direction="column">
            <FormFields.AlignedFormFields displayfields={fields} />
          </Box>
        </Form>
      </>
    </Tile>
  );
};

export default SiteAlertSettings;
