import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import * as dbAccess from './dbAccess';
import * as queries from '../graphql/queries';
import { createExtraReducers } from './commonReducers.js';
import { RootState } from 'store/store';
import { OmniviaSCU, OmniviaSite } from 'types/API';
import { systemEventTopics } from './eventDispatcher';
import { initialSliceState } from 'store/commonSlice';
import logger from 'common/logger';
import * as API from 'types/API';
import * as Amp from 'aws-amplify';
import _ from 'lodash';
import * as mutations from '../graphql/mutations';
import * as eventDispatcher from 'store/eventDispatcher';

const getOmniviaSCU = /* GraphQL */ `
  query GetOmniviaSCU($id: ID!) {
    getOmniviaSCU(id: $id) {
      id
      name
      description
      serialNumber
      localEthernetIP
      localWiFiIP
      ethernetMAC
      wifiMAC
      softwareVersion
      mainOutgoingPOTSNumber
      backupOutgoingPOTSNumber
      remoteAccessPort
      productNumber
      lastSeen
      unitTime
      modifiedBy
      createdAt
      updatedAt
      _deleted
      groupsCanAccess
    }
  }
`;

const dbAccessInfo = {
  DAOname: 'SCUs',
  allQuery: queries.getOmniviaSite,
  allSelector: 'getOmniviaSite',
  allSelector2: 'linkedSCUs',
  allSort: undefined, //'name'
  selectQuery: getOmniviaSCU,
  selectSelector: 'getOmniviaSCU',
};

const adapter = createEntityAdapter({
  //selectId: (object) => object.id, // id is the default
  sortComparer: (a: OmniviaSCU, b: OmniviaSCU) => a.name.localeCompare(b.name),
});

const initialState = adapter.getInitialState(initialSliceState);

// Thunk functions
export const fetch = createAsyncThunk(
  'scus/fetch',
  async (site: OmniviaSite) => {
    const response = await dbAccess.loadData(dbAccessInfo, {
      id: site.id,
      limit: 1,
    });
    return response;
  }
);

export const select = createAsyncThunk(
  'scus/select',
  async (SCU: { id: string }) => {
    const response = await dbAccess.select(dbAccessInfo, { id: SCU.id });
    logger.debug('scuSlice.select', { scuSelectedID: SCU.id });
    return response;
  }
);

export const update = createAsyncThunk(
  'scus/update',
  async (update: { id: string; changes: unknown }) => {
    const inputVars = _.cloneDeep(update.changes) as API.UpdateOmniviaSCUInput;
    _.set(inputVars, 'id', update.id);
    const variables = {
      input: inputVars,
    };

    console.info('scus/update vars:', variables);

    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(mutations.updateOmniviaSCU, variables)
    )) as { data: { updateOmniviaSCU: OmniviaSCU } };

    console.info('scus/update:', response.data.updateOmniviaSCU);
    return response.data.updateOmniviaSCU;
  }
);

export const getSCUInfo = createAsyncThunk(
  'scus/getSCUInfo',
  async (SCU: { id: string }) => {
    const response = (await Amp.API.graphql(
      Amp.graphqlOperation(getOmniviaSCU, { id: SCU.id })
    )) as { data: { getOmniviaSCU: OmniviaSCU } };
    return response.data.getOmniviaSCU;
  }
);

const slice = createSlice({
  name: 'scus',
  initialState,
  reducers: {
    // eslint-disable-next-line
    clearSelected(state, action) {
      state.selectedId = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(update.fulfilled, (state, action) => {
      const scu = action.payload;
      adapter.setOne(state, action.payload);

      eventDispatcher.emitEvent(
        eventDispatcher.systemEventTopics.SCU,
        eventDispatcher.systemEventStates.UPDATED,
        scu,
        true
      );
    });

    builder.addCase(update.rejected, (state, action) => {
      console.info('error:', action);
    });

    builder.addCase(getSCUInfo.fulfilled, (state, action) => {
      adapter.setOne(state, action.payload);
    });

    return createExtraReducers(
      systemEventTopics.SCU,
      builder,
      fetch,
      select,
      adapter
    );
  },
});

export const { selectAll, selectById } = adapter.getSelectors(
  (state: RootState) => state.scus
);
export const { clearSelected } = slice.actions;

export default slice.reducer;

const getState = (state: RootState) => state;
export const getSelected = createSelector([getState], (state) => {
  return selectById(state, state.scus.selectedId);
});

export const getAll = createSelector([getState], (state) => {
  return selectAll(state);
});

export const getSlice = createSelector([getState], (state) => {
  return state.scus;
});
