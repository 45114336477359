import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  emitEvent,
  systemEventStates,
  systemEventTopics,
} from './eventDispatcher';

import { getJSON, putJSON } from 'common/S3Bucket';
import { iSite } from 'types/plan-types';
import { RootState } from './store';
import { iSliceState } from 'store/commonSlice';

const getState = (state: RootState): RootState => state;

export interface iFloorPlanState extends iSliceState {
  dataLoaded: boolean;
  loadStatus: string;
  dataSaveComplete: boolean;
  saveStatus: string;
  planData: iSite;
}

const initialState: iFloorPlanState = {
  dataLoaded: false,
  loadStatus: 'idle',
  selectStatus: 'idle',
  selectedId: '',

  dataSaveComplete: true,
  saveStatus: 'idle',

  planData: {
    equipment: [],
    name: '',
    plans: [],
  },
};

export const getSlice = createSelector([getState], (state: RootState) => {
  return state.siteplan;
});

export const getPlanData = createSelector([getState], (state) => {
  return state.siteplan.planData;
});

// Thunk functions
export const fetch = createAsyncThunk(
  'sitePlan/fetch',
  async (siteName: string) => {
    console.info('downloading siteplan for :', siteName);
    const siteJSON = await getJSON(`floorplans/${siteName}/plandata.json`);
    console.info('siteplan download complete for :', siteName);
    return siteJSON;
  }
);

// Thunk functions
export const save = createAsyncThunk(
  'sitePlan/save',
  async (arg, { getState }) => {
    const rootState: RootState = getState() as RootState;
    const state: iFloorPlanState = rootState.siteplan as iFloorPlanState;

    const JSONdata = JSON.stringify(state.planData);
    await putJSON(`floorplans/${state.planData.name}/plandata.json`, JSONdata);
  }
);

const slice = createSlice({
  name: 'sitePlan',
  initialState,
  reducers: {
    // eslint-disable-next-line
    setLoading(state, action) {
      state.loadStatus = 'loading';
    },

    updatePlanData(
      state: iFloorPlanState,
      action: PayloadAction<{
        planData: iSite;
      }>
    ) {
      const api = action.payload;
      state.planData = api.planData;
      //console.info('Site Plan data updated');
    },
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line
      .addCase(fetch.pending, (state: iFloorPlanState, action) => {
        state.loadStatus = 'loading';
        state.dataLoaded = false;
        emitEvent(
          systemEventTopics.FLOORPLANS,
          systemEventStates.LOADING,
          null,
          true
        );
      })
      // eslint-disable-next-line
      .addCase(fetch.fulfilled, (state: iFloorPlanState, action) => {
        const siteJSON = action.payload as iSite;

        state.planData.equipment = []; //clear any existing equipment
        state.planData.name = '';
        state.planData.plans = [];

        if (siteJSON) {
          state.planData = siteJSON;
        }

        state.dataLoaded = true;
        emitEvent(
          systemEventTopics.FLOORPLANS,
          systemEventStates.LOADED,
          null,
          true
        );
        state.loadStatus = 'idle';
      })
      // eslint-disable-next-line
      .addCase(fetch.rejected, (state: iFloorPlanState, action) => {
        console.info('setting to error:', action);
        emitEvent(
          systemEventTopics.FLOORPLANS,
          systemEventStates.LOADERROR,
          null,
          true
        );
        state.loadStatus = 'error';
      })
      // eslint-disable-next-line
      .addCase(save.pending, (state: iFloorPlanState, action) => {
        state.dataSaveComplete = false;
        state.saveStatus = 'saving';
        emitEvent(
          systemEventTopics.FLOORPLANS,
          systemEventStates.SAVING,
          null,
          true
        );
      })
      // eslint-disable-next-line
      .addCase(save.fulfilled, (state: iFloorPlanState, action) => {
        state.dataSaveComplete = true;
        state.saveStatus = 'idle';
        emitEvent(
          systemEventTopics.FLOORPLANS,
          systemEventStates.SAVED,
          null,
          true
        );
      })
      // eslint-disable-next-line
      .addCase(save.rejected, (state: iFloorPlanState, action) => {
        console.info('store setting to error:', action);
        state.dataSaveComplete = true;
        state.saveStatus = 'error';
        emitEvent(
          systemEventTopics.FLOORPLANS,
          systemEventStates.SAVEERROR,
          null,
          true
        );
      });
  },
});

export const { updatePlanData } = slice.actions;
export default slice.reducer;
