/** @module FloorPlans */
import React, { useState } from 'react';

import { Equipment } from 'types/product-types';
import * as Site from 'components/floorplan/site';

import logger from 'common/logger';
import { Layer, Text, Button, Box, DataTable } from 'grommet';

type ConnectionCount = {
  equipment?: Equipment;
  connected: number;
  unconnected: number;
  total: number;
};

/** Cabling Dialog Box showing details of connections etc */
export const CablingDialogBox = (props: {
  currentPlan: number;
  visible: boolean;
  setVisible: (arg0: boolean) => void;
}): JSX.Element => {
  const [updateView, setUpdateView] = useState(0);

  /** find the POE cabinet on this floor */
  let poe = Site.siteData.equipment.find(
    (e) => e.plan == props.currentPlan && e.productCode == 'PROD-3162'
  );

  // if there is no POE on the floor then look to the floor above
  if (poe == undefined)
    poe = Site.siteData.equipment.find(
      (e) => e.plan == props.currentPlan + 1 && e.productCode == 'PROD-3162'
    );

  // if there is no POE on the floor above then look to the floor below
  if (poe == undefined)
    poe = Site.siteData.equipment.find(
      (e) => e.plan == props.currentPlan - 1 && e.productCode == 'PROD-3162'
    );

  let connectionCounts: ConnectionCount[] = [];

  let fulltotal: ConnectionCount = {
    connected: 0,
    unconnected: 0,
    total: 0,
  };

  /** returns the total number of connections to a POE unit*/
  const getCurrentPOEConnectionCount = (uniqueID: string): ConnectionCount => {
    const total: ConnectionCount = {
      connected: 0,
      unconnected: 0,
      total: 0,
    };
    connectionCounts = [];

    Site.getEquipmentOnThisPlanOnly(props.currentPlan).forEach((e) => {
      if (e.cat5Required) {
        // if we haven't already added this equipment type to the connections table then do so
        if (
          connectionCounts.find(
            (c) => c.equipment?.designator == e.designator
          ) == undefined
        ) {
          connectionCounts.push({
            equipment: e,
            connected: 0,
            unconnected: 0,
            total: 0,
          });
        }

        const conn: ConnectionCount | undefined = connectionCounts.find(
          (c) => c.equipment?.designator == e.designator
        );

        if (conn) {
          conn.total++;
          total.total++;

          // increment the connected or unnconnected totals
          if (e.cat5Connection && e.cat5Connection.connectedTo == uniqueID) {
            conn.connected++;
            total.connected++;
          }
          //TODO don't include devices connected to MLS units as unconnected
          else {
            conn.unconnected++;
            total.unconnected++;
          }
        }
      }
    });
    return total;
  };

  /** Wire a particular floor's POE to VRUS, MAPs, MLS, MAI ...
   * @param planIndex is the floorplan to be wired
   */
  const wireFloor = () => {
    // Check that we have a POE cabinet to connect to first!
    // TODO: consider multiple POE cbainets at a later date

    let updateStore = false;

    if (poe == undefined) {
      console.log('wireFloor: No POE PROD-3162 on this floor');
      logger.info('floorplan.cabling.wireFloor.noPOE');
    } else {
      // get the current total of connections first
      let connections = getCurrentPOEConnectionCount(poe.uniqueID);
      logger.info('floorplan.cabling.wireFloor', poe);
      const planEquip = Site.getEquipmentOnThisPlanOnly(props.currentPlan);
      // run through all VRUs first
      planEquip.forEach((e) => {
        if (
          e.productCode == 'PROD-3143' &&
          e.cat5Connection &&
          (e.cat5Connection.connectedTo == undefined ||
            e.cat5Connection.connectedTo.length < 2) &&
          poe &&
          connections.connected < poe.cat5Ports // make sure we still have some left
        ) {
          e.cat5Connection.connectedTo = poe.uniqueID;
          connections.connected++;
          if (connections.unconnected > 0) connections.unconnected--;
          updateStore = true;
        }
      });

      // run through all MLS
      planEquip.forEach((e) => {
        if (
          e.designator.startsWith('MLS') &&
          e.cat5Connection &&
          e.plan == props.currentPlan &&
          (e.cat5Connection.connectedTo == undefined ||
            e.cat5Connection.connectedTo.length < 2) &&
          poe &&
          connections.connected < poe.cat5Ports // make sure we still have some left
        ) {
          e.cat5Connection.connectedTo = poe.uniqueID;
          connections.connected++;
          if (connections.unconnected > 0) connections.unconnected--;
          updateStore = true;
        }
      });

      // run through all MAI
      planEquip.forEach((e) => {
        if (
          e.designator.startsWith('MAI') &&
          e.cat5Connection &&
          e.plan == props.currentPlan &&
          (e.cat5Connection.connectedTo == undefined ||
            e.cat5Connection.connectedTo.length < 2) &&
          poe &&
          connections.connected < poe.cat5Ports // make sure we still have some left
        ) {
          e.cat5Connection.connectedTo = poe.uniqueID;
          connections.connected++;
          if (connections.unconnected > 0) connections.unconnected--;
          updateStore = true;
        }
      });

      // run through all MAPs
      planEquip.forEach((e) => {
        if (
          e.designator.startsWith('MAP') &&
          e.cat5Connection &&
          e.plan == props.currentPlan &&
          (e.cat5Connection.connectedTo == undefined ||
            e.cat5Connection.connectedTo.length < 2) &&
          poe &&
          connections.connected < poe.cat5Ports // make sure we still have some left
        ) {
          e.cat5Connection.connectedTo = poe.uniqueID;
          connections.connected++;
          if (connections.unconnected > 0) connections.unconnected--;
          updateStore = true;
        }
      });

      connections = getCurrentPOEConnectionCount(poe.uniqueID);
    }

    // update the redux store if we need to
    if (updateStore) {
      Site.updateStore(true);
    }
    //update the view
    setUpdateView(updateView + 1);
  };

  if (props.visible && poe)
    fulltotal = getCurrentPOEConnectionCount(poe.uniqueID);

  return (
    <>
      {props.visible && props.currentPlan != -1 && (
        <Layer
          background="dialogBackground"
          onEsc={() => props.setVisible(false)}
        >
          <Box pad="small" gap="medium" align="center">
            <Text margin="none" size="large">
              CAT5 POE Connections
            </Text>
            <Text>
              POE Unit being used{' '}
              {poe ? poe.uniqueID : 'Warning No POE unit found!'}
              {poe
                ? `  (${fulltotal.connected} / ${poe.cat5Ports} Ports Used)`
                : ''}
            </Text>
            {poe && fulltotal.total > poe.cat5Ports && (
              <Text>
                There are two many devices for this POE unit, MLS units will
                need to be use to daisy chain some devices. Connections need to
                be reduced by {fulltotal.total - poe.cat5Ports}
              </Text>
            )}

            <DataTable
              columns={[
                {
                  property: 'equipment.designator',
                  header: <Text>Code</Text>,
                  primary: true,
                },
                {
                  property: 'equipment.name',
                  header: <Text>Name</Text>,
                },
                {
                  property: 'connected',
                  header: <Text>Connected</Text>,
                },
                {
                  property: 'unconnected',
                  header: <Text>Unconnected</Text>,
                },
                {
                  property: 'total',
                  header: <Text>Total</Text>,
                },
              ]}
              data={connectionCounts.concat(fulltotal)}
            />
          </Box>
          <span>
            <Button
              label="AutoWire"
              tip={{
                content: (
                  <Text>Automatically Wire up the unconnected units</Text>
                ),
              }}
              disabled={poe == undefined}
              size="medium"
              plain={false}
              margin="small"
              onClick={() => {
                wireFloor();
              }}
            />
            <Button
              label="Close"
              tip={{
                content: <Text>Close this box</Text>,
              }}
              size="medium"
              plain={false}
              margin="small"
              onClick={() => {
                props.setVisible(false);
              }}
            />
          </span>
        </Layer>
      )}
    </>
  );
};
