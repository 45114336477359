import React, { Dispatch } from 'react';
import { Layer, Box, Button, Paragraph } from 'grommet';
import QRCode from 'react-qr-code';
import CryptoJS from 'crypto-js';

interface iHandsetCommissionQRCode {
  show: boolean;
  setShow: Dispatch<boolean>;
  handsetId: number;
  mac: string;
}

const HandsetQRCode = (props: iHandsetCommissionQRCode): JSX.Element => {
  const config = {
    ipAddress: `192.168.4.${100 + props.handsetId}`,
    macaddress: props.mac,
    time: new Date().getTime(),
  };

  const encryptedString = CryptoJS.AES.encrypt(
    JSON.stringify(config),
    'duGYr*Kfd@PCFThK@Pi.AnGV_yPK4idD3@bbW2XLBpdMVRYKw.RDNJncVvRQAUcdBaCBU.@v4JU8QKe!8x3yVBUxzmccyF4ZB4GE'
  ).toString();

  return (
    <>
      {props.show && (
        <Layer
          className={'HandsetCommissionQRCode'}
          background="dialogBackground"
          style={{ whiteSpace: 'pre-wrap' }}
          modal={true}
        >
          <Box pad="medium" gap="large" align="center">
            <Paragraph textAlign="center">
              Please scan the QR Code with the Evolution+ Handset
            </Paragraph>
            <div
              style={{
                height: 'auto',
                margin: '0 auto',
                width: '100%',
              }}
            >
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={encryptedString}
              />
            </div>

            <Button
              label="OK"
              size="medium"
              plain={false}
              margin="small"
              onClick={() => {
                props.setShow(false);
              }}
            />
          </Box>
        </Layer>
      )}
    </>
  );
};

export default HandsetQRCode;
