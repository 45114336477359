/* eslint-disable no-undef */
import * as dataItems from '../common/GetDataItems';

export async function loadData(info, allVariables, recurse = true) {
  try {
    const response = await dataItems.GetDataItems({
      query: info.allQuery,
      variables: allVariables,
      recurse: recurse,
      log: false,
      selector: info.allSelector,
      selector2: info.allSelector2,
      sort: info.allSort,
    });

    return response;
  } catch (err) {
    console.info('db error:', err);
    return Promise.reject(err.message ? err.message : response);
  }
}

export async function select(info, selectVariables, recurse = false) {
  try {
    const response = await dataItems.GetDataItems({
      query: info.selectQuery,
      variables: selectVariables,
      recurse: recurse,
      log: false,
      selector: info.selectSelector,
      selector2: info.selectSelector2,
    });
    return response;
  } catch (err) {
    return Promise.reject(err.message ? err.message : response);
  }
}

export async function loadDataQuery(
  query,
  selector,
  selector2,
  variables,
  recurse = true
) {
  try {
    const response = await dataItems.GetDataItems({
      query: query,
      variables: variables,
      recurse: recurse,
      log: false,
      selector: selector,
      selector2: selector2,
      sort: null,
    });

    return response;
  } catch (err) {
    console.info('db error:', err);
    return Promise.reject(err.message ? err.message : response);
  }
}
