export enum teCLOUD_MSG_TY_CALL {
  E_CLOUD_MSG_CALL_DIAL_OFFSITE_REQ = 0,
  E_CLOUD_MSG_CALL_DIAL_OFFSITE_RES,

  E_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_REQ,
  E_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_RES,

  E_CLOUD_MSG_CALL_CONNECT_RESIDENT_REQ,
  E_CLOUD_MSG_CALL_CONNECT_RESIDENT_RES,

  E_CLOUD_MSG_CALL_DISCONECT_RESIDENT_REQ,
  E_CLOUD_MSG_CALL_DISCONECT_RESIDENT_RES,

  E_CLOUD_MSG_CALL_ONLINE_HEARTBEAT,
  E_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT,
}

export enum teCLOUD_REMOTECALL_EVENTS_STATUS_CODES {
  E_REMOTECALL_STATUS_EVT_DIALING = 0,
  E_REMOTECALL_STATUS_EVT_DIALING_TIMEOUT,
  E_REMOTECALL_STATUS_EVT_DIALING_FAILED_NO_RESOURCES,
  E_REMOTECALL_STATUS_EVT_CONNECTED_IDLE_TIMEOUT,
  E_REMOTECALL_STATUS_EVT_REMOTE_CALL_CONNECTED,
  E_REMOTECALL_STATUS_EVT_LINE_HUNGUP,
  E_REMOTECALL_STATUS_EVT_RESIDENT_CONNECTED,
  E_REMOTECALL_STATUS_EVT_RESIDENT_CLEAR_STARTED,
  E_REMOTECALL_STATUS_EVT_RESIDENT_CLEAR_COMPLETE,
  E_REMOTECALL_STATUS_EVT_RESIDENT_PRIVACY,
  E_REMOTECALL_STATUS_EVT_CLEARDOWN_HEARTBEAT_TIMEOUT,
}

export interface tsE_CLOUD_MSG_CALL_DIAL_OFFSITE_REQ {
  calluid: string;
  username: string;
  telephonenumber: string;
  line: number;
}

export interface tsE_CLOUD_MSG_CALL_DIAL_OFFSITE_RES {
  errorcode: number;
}

export interface tsE_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_REQ {
  calluid: string;
  username: string;
}
export interface tsE_CLOUD_MSG_CALL_DISCONNECT_OFFSITE_RES {
  errorcode: number;
}

export interface tsE_CLOUD_MSG_CALL_CONNECT_RESIDENT_REQ {
  calluid: string;
  username: string;
  unitid: number;
  vrutext: string;
  overrideprivacy: number;
}
export interface tsE_CLOUD_MSG_CALL_CONNECT_RESIDENT_RES {
  errorcode: number;
}

export interface tsE_CLOUD_MSG_CALL_DISCONECT_RESIDENT_REQ {
  calluid: string;
  username: string;
}

export interface tsE_CLOUD_MSG_CALL_DISCONECT_RESIDENT_RES {
  errorcode: number;
}

export interface tsE_CLOUD_MSG_CALL_ONLINE_HEARTBEAT {
  calluid: string;
}
export interface tsE_CLOUD_MSG_CALL_ONLINE_STATUS_EVENT {
  eventcode: teCLOUD_REMOTECALL_EVENTS_STATUS_CODES;
  calluid: string;
  eventdata: unknown | undefined;
}
