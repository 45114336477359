import EventEmitter from 'events';
import * as IoTMessageHandler from 'common/IoT/IotMessageHandler';
//import logger from 'common/logger';

// Handle registration of listeners for loading events
const eventTarget = new EventEmitter();

export enum IoTTopics {
  PENDANT_PAGE_DELIVERED_REPORT = 'PENDANT_PAGE_DELIVERED_REPORT',
  PENDANT_MSG_DELIVERY_REPORT = 'PENDANT_MSG_DELIVERY_REPORT',
  SCU_CONFIGURATION_CHANGE = 'SCU_CONFIGURATION_CHANGE',
  // + All IoT command function names
}

export enum IoTState {
  RESPONSE = 'RESPONSE',
  TIMEOUT = 'TIMEOUT',
}

export interface iIotResponseEventDetail {
  transId: number;
  topic: string | IoTTopics;
  userData: unknown;
  msgHeader: IoTMessageHandler.Message | null;
}

export interface iEventParams {
  // Event subject string
  subject: string;
  // Any associated object containing optional event information
  detail: iIotResponseEventDetail | undefined;
  // Timestamp of the event in ISO date format
  timestamp: string;
}

/** The string used to indentify the event subject */
export const subjectString = (
  topic: string | IoTTopics,
  state: IoTState
): string => {
  return `${topic}${state}`;
};

/** Register to listen to new events.
 * @param topic - the topic of interest:  For IoT commands this will be the name of the function
 * @param state - the state in that topic
 * @param callback - the function to call when the event occurs
 * @param once - set true if you only want one callback on the next occurence, false if you want a callback for each event that occurs
 */
export const registerForEvent = (
  topic: string | IoTTopics,
  state: IoTState,
  callback: (e: iEventParams) => void,
  once?: boolean
): void => {
  if (once) {
    eventTarget.once(subjectString(topic, state), callback);
  } else {
    eventTarget.on(subjectString(topic, state), callback);
  }
};

/** Remove registration of a listener - use the same parameters below that were used when orignally registering the event
 * @param topic - the topic of interest
 * @param state - the state in that topic
 * @param callback - the function to call when the event occurs
 * */
export const unRegisterForEvent = (
  topic: string | IoTTopics,
  state: IoTState,
  callback: (e: iEventParams) => void
): void => {
  eventTarget.off(subjectString(topic, state), callback);
};

/** Dispatch new events to registered listeners
 * @param topic - the topic of interest
 * @param state - the state in that topic
 * @param detail - optional object with additional info for that event, i.e. event specific information
 * @
 * */
export const emitEvent = (
  topic: string | IoTTopics,
  state: IoTState,
  detail?: iIotResponseEventDetail
): void => {
  const subject = `${topic}${state}`;
  const timestamp = new Date().toISOString();
  const params: iEventParams = {
    subject: subject,
    detail: detail,
    timestamp: timestamp,
  };
  //console.info('Iot event topic: ' + subject + ' detail:', detail);
  process.nextTick(() => eventTarget.emit(subject, params));
};
