import { teCLOUD_ERROR_CODES } from 'common/IoT/IoTMessageDefines';

export enum teCLOUD_MSG_TY_AUDIT {
  E_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_REQ = 0,
  E_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_RES,
  E_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_REQ,
  E_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_RES,
}

export interface tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_REQ {
  deviceid: number;
  username: string;
}

export interface tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_SEEN_RES {
  errorcode: teCLOUD_ERROR_CODES;
}

export interface tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_REQ {
  deviceid: number;
  username: string;
}

export interface tsE_CLOUD_MSG_AUDIT_RESIDENT_LAST_TALK_RES {
  errorcode: teCLOUD_ERROR_CODES;
}
