import * as IoTDeviceAddDelete from 'common/IoT/IoTAddDelete';
import * as Wait from 'components/dialogues/waitDialogue';
import { Dispatch } from 'react';
import { IoTSCUManifest } from 'common/IoT/IoTSCUManifest';
import { Device } from 'components/installation/device';
import { ALARM_TYPES } from 'types/event-enums';
import { sleep } from 'common/utils/miscUtils';

import { teDEVICE_TYPES, teDEVICE_MODELS } from 'types/manifest-enums';
import * as IoTMsgDefines_DEVICES from 'common/IoT/IoTMessageDefines_DEVICES';
import * as IoTMsgDefines from 'common/IoT/IoTMessageDefines';
import * as IoTMsgDefines_CONFIG_QUERY from 'common/IoT/IoTMessageDefines_CONFIG_QUERY';

export {
  teCLOUD_ERROR_CODES,
  getErrorText,
} from 'common/IoT/IoTMessageDefines';
export { teDEVICE_TYPES, teDEVICE_MODELS } from 'types/manifest-enums';
export type { tsCLOUD_MSG_DEVICES_ADD_RESP } from 'common/IoT/IoTMessageDefines_DEVICES';

import {
  DeviceParameters,
  teDeviceProcessors,
} from 'common/IoT/DeviceParameters';
import { manifest } from 'components/floorplan/site';

export {
  teVRUParameters,
  teHandsetParameters,
} from 'common/IoT/DeviceParameters';

const deviceParameters = DeviceParameters.Instance;

interface iAddDeviceStatus {
  ok: boolean;
  deviceId: number;
}

const addDevice = async (
  mac: number,
  unitId: number,
  parentId: number, // only used for battery devices
  name: string,
  type: IoTDeviceAddDelete.teDEVICE_TYPES,
  hwModel: IoTDeviceAddDelete.teDEVICE_MODELS,
  gwi: boolean,
  syncManifest: boolean,
  location: number,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined> | undefined
): Promise<iAddDeviceStatus> => {
  console.info('addDevice');

  const promise = new Promise<iAddDeviceStatus>((resolve) => {
    (async () => {
      if (setDialogueProps)
        setDialogueProps({
          show: true,
          dialogueText: 'Please wait...',
          showSpinner: true,
          showOkButton: false,
        });

      try {
        console.info('addDevice: Sending IoT Msg');

        const resp = await IoTDeviceAddDelete.addDevice(
          mac,
          parentId,
          unitId,
          type,
          hwModel,
          gwi,
          name,
          location
        );
        console.info('add device respone:', resp);

        const addRes = resp.msgHeader
          ?.msg as IoTDeviceAddDelete.tsCLOUD_MSG_DEVICES_ADD_RESP;
        if (
          addRes &&
          addRes.errorcode ==
            IoTDeviceAddDelete.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
        ) {
          console.info('Add device successfull');

          // No set the device as poll failed so that we do not get any poll failed alarms
          await Device.setIgnorePollFail(addRes.deviceId);

          if (syncManifest) {
            if (setDialogueProps)
              setDialogueProps({
                show: true,
                dialogueText: 'Device added, synchronising...',
                showSpinner: true,
                showOkButton: false,
              });

            // Delay added to ensure the change events have propergated
            // in all the SCU modules
            //
            // Without the delay I have seen the manifest request been ignored by
            // the SCU as it thought no DB changes has occured
            //
            await sleep(500);

            try {
              await IoTSCUManifest.Instance.requestManifestUpload(null);
            } catch (error) {
              console.error(
                'Error waiting for manifest request, error:',
                error
              );
            }
          }
          if (setDialogueProps) setDialogueProps(undefined);
          resolve({ ok: true, deviceId: addRes.deviceId });
        } else {
          if (setDialogueProps) {
            setDialogueProps({
              show: true,
              dialogueText: `Request Failed.\n${IoTDeviceAddDelete.getErrorText(
                addRes?.errorcode
              )}`,
              showSpinner: false,
              showOkButton: true,
              okButtonPress: () => {
                setDialogueProps(undefined);
                resolve({ ok: false, deviceId: 0 });
              },
            });
          } else {
            resolve({ ok: false, deviceId: 0 });
          }
        }

        // addDevice error codes:
        // E_CLOUD_ERROR_DEVICE_ALREADY_IN_DB
        // E_CLOUD_ERROR_INVALID_UNIT_ID
        // E_CLOUD_ERROR_UNIT_ID_IN_USE
        // E_CLOUD_ERROR_NOT_IN_DB // for triggers, parent id not in db
        // E_CLOUD_ERROR_GENERAL // db write issue

        // GWI:
        //   E_CLOUD_ERROR_NOT_AN_OMNIVIA_SYSTEM
        //   E_CLOUD_ERROR_INVALID_DEVICE  (wrong device type)
        //   E_CLOUD_ERROR_DEVICE_ALREADY_IN_DB
        //
        //
        //
      } catch (e) {
        if (setDialogueProps) {
          setDialogueProps({
            show: true,
            dialogueText: 'Request Failed.\nNo SCU response, it maybe offline',
            showSpinner: false,
            showOkButton: true,
            okButtonPress: () => {
              setDialogueProps(undefined);
              resolve({ ok: false, deviceId: 0 });
            },
          });
        } else {
          resolve({ ok: false, deviceId: 0 });
        }
      }
    })();
  });

  return promise;
};

export const addBatteryDevice = async (
  mac: number,
  parentId: number,
  type: IoTDeviceAddDelete.teDEVICE_TYPES,
  hwModel: IoTDeviceAddDelete.teDEVICE_MODELS,
  location: number,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>
): Promise<iAddDeviceStatus> => {
  return addDevice(
    mac,
    0,
    parentId,
    '',
    type,
    hwModel,
    false,
    true,
    location,
    setDialogueProps
  );
};

export const addFixedDevice = async (
  mac: number,
  unitRoomId: number,
  name: string,
  type: IoTDeviceAddDelete.teDEVICE_TYPES,
  hwModel: IoTDeviceAddDelete.teDEVICE_MODELS,
  location: number,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>
): Promise<iAddDeviceStatus> => {
  return addDevice(
    mac,
    unitRoomId,
    0,
    name,
    type,
    hwModel,
    false,
    true,
    location,
    setDialogueProps
  );
};

export const addUniversalSensor = async (
  mac: number,
  parentId: number,
  type: IoTDeviceAddDelete.teDEVICE_TYPES,
  hwModel: IoTDeviceAddDelete.teDEVICE_MODELS,
  input1: ALARM_TYPES,
  input2: ALARM_TYPES,
  location: number,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>
): Promise<iAddDeviceStatus> => {
  const promise = new Promise<iAddDeviceStatus>((resolve) => {
    (async () => {
      const response = await addDevice(
        mac,
        0,
        parentId,
        '',
        type,
        hwModel,
        false,
        false,
        location,
        setDialogueProps
      );

      if (response.ok) {
        await Device.setInputAlarmtypes(response.deviceId, input1, input2);

        if (setDialogueProps)
          setDialogueProps({
            show: true,
            dialogueText: 'Device added, synchronising...',
            showSpinner: true,
            showOkButton: false,
          });

        // Delay added to ensure the change events have propergated
        // in all the SCU modules
        //
        // Without the delay I have seen the manifest request been ignored by
        // the SCU as it thought no DB changes has occured
        //
        await sleep(500);
        try {
          await IoTSCUManifest.Instance.requestManifestUpload(null);
        } catch (error) {
          console.error('Error waiting for manifest request, error:', error);
        }

        if (setDialogueProps) setDialogueProps(undefined);
      }

      resolve(response);
    })();
  });
  return promise;
};

export const addEvolutionPlusHandset = async (
  mac: number,
  name: string,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>
): Promise<iAddDeviceStatus> => {
  return addDevice(
    mac,
    0,
    0,
    name,
    teDEVICE_TYPES.DEVICE_TYPE_WARDEN_HANDSET,
    teDEVICE_MODELS.DEVICE_MODEL_V3,
    false,
    true,
    0,
    setDialogueProps
  );
};

export const deleteDevice = async (
  mac: number,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>
): Promise<void> => {
  setDialogueProps({
    show: true,
    dialogueText: `Please wait...`,
    showSpinner: true,
    showOkButton: false,
  });

  let message = '';
  try {
    const resp = (await IoTDeviceAddDelete.deleteDevice(mac, null)).msgHeader
      ?.msg as IoTMsgDefines_DEVICES.tsCLOUD_MSG_DEVICES_DELETE_RESP;

    console.info('delete respone:', resp);

    if (
      resp.errorcode !== IoTMsgDefines.teCLOUD_ERROR_CODES.E_CLOUD_ERROR_NONE
    ) {
      message = `Failed to delete device with the identity ${Device.MACaddrToString(
        mac,
        16
      )}`;
    } else {
      try {
        setDialogueProps({
          show: true,
          dialogueText: `Device deleted, synchronising...`,
          showSpinner: true,
          showOkButton: false,
        });
        await IoTSCUManifest.Instance.requestManifestUpload(null);
      } catch (error) {
        console.error('Error waiting for manifest request, error:', error);
      }
    }
  } catch (e) {
    console.error('delete failed with error:', e);
    message = `The SCU did not respond. It maybe offline.`;
  }

  if (message !== '') {
    setDialogueProps({
      show: true,
      dialogueText: message,
      showSpinner: false,
      showOkButton: true,
      okButtonPress: () => setDialogueProps(undefined),
    });
  } else {
    setDialogueProps(undefined);
  }
};

export const setDeviceParameter = async (
  mac: number,
  parameterNumber: number,
  value: string,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>
): Promise<boolean> => {
  const device = manifest.devices.find((e) => e.MacAddress === mac);
  let status = false;

  if (device === undefined) return false;
  if (
    Device.isVRU(device) == false &&
    Device.isEvolutionPlusHandset(device) == false
  ) {
    console.error('Currently only supporting VRUs and Handset');
    return false;
  }

  setDialogueProps({
    show: true,
    dialogueText: `Please wait...`,
    showSpinner: true,
    showOkButton: false,
  });

  try {
    const resp = await deviceParameters.setDeviceParameter(
      mac,
      parameterNumber,
      Device.isVRU(device)
        ? teDeviceProcessors.PROCESSOR_VRU_APPLICATION
        : teDeviceProcessors.PROCESSOR_EVOLUTION_HANDSET_APPLICATION,
      value,
      null
    );
    if (resp.msgHeader && resp.msgHeader?.msg) {
      const rxmsg = resp.msgHeader
        ?.msg as IoTMsgDefines_CONFIG_QUERY.tsCLOUD_MSG_QUERY_CFG_SET_DEVICE_PARAM_RES;
      status = rxmsg.set ? true : false;

      console.info(
        `Got set response for param ${rxmsg.parameter} status:${rxmsg.set}`
      );
    }
  } catch (error) {
    status = false;
  }

  setDialogueProps(undefined);
  return status;
};

export const getDeviceParameter = async (
  mac: number,
  parameterNumber: number,
  setDialogueProps: Dispatch<Wait.DialogueProps | undefined>
): Promise<string | undefined> => {
  const device = manifest.devices.find((e) => e.MacAddress === mac);
  let value: undefined | string = undefined;

  if (device === undefined) return undefined;
  if (
    Device.isVRU(device) == false &&
    Device.isEvolutionPlusHandset(device) == false
  ) {
    console.error('Currently only supporting VRUs and Handset');
    return undefined;
  }

  setDialogueProps({
    show: true,
    dialogueText: `Please wait...`,
    showSpinner: true,
    showOkButton: false,
  });

  try {
    const resp = await deviceParameters.getDeviceParameter(
      mac,
      parameterNumber,
      Device.isVRU(device)
        ? teDeviceProcessors.PROCESSOR_VRU_APPLICATION
        : teDeviceProcessors.PROCESSOR_EVOLUTION_HANDSET_APPLICATION,
      null
    );
    if (resp.msgHeader && resp.msgHeader?.msg) {
      const rxmsg = resp.msgHeader
        ?.msg as IoTMsgDefines_CONFIG_QUERY.tsCLOUD_MSG_QUERY_CFG_GET_DEVICE_PARAM_RES;

      console.info(
        `Got get response for param ${rxmsg.parameter} found:${rxmsg.found} value:${rxmsg.value}`
      );
      if (rxmsg.found) {
        value = rxmsg.value;
      }
    }
  } catch (error) {
    value = undefined;
  }

  setDialogueProps(undefined);
  return value;
};
