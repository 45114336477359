import React from 'react';
import { Box, Text, Button, Layer, Spinner, Paragraph } from 'grommet';

export type DialogueProps = {
  show: boolean;
  dialogueText: string;
  dialogueTextLine2?: string;
  showSpinner: boolean;
  showOkButton?: boolean;
  okButtonPress?: () => void;
  showCancelButton?: boolean;
  cancelButtonPress?: () => void;
};

export const defaultDialogueProps: DialogueProps = {
  show: false,
  dialogueText: '',
  showSpinner: false,
  showOkButton: false,
  showCancelButton: false,
};

const WaitDialogue = (props: DialogueProps): JSX.Element => {
  return (
    <>
      {props.show && (
        <Layer
          className={'waitdialog'}
          background="dialogBackground"
          style={{ whiteSpace: 'pre-wrap' }}
          modal={true}
        >
          <Box pad="small" gap="small" align="center">
            <Paragraph textAlign="center">{props.dialogueText}</Paragraph>
            {props.dialogueTextLine2 && <Text>{props.dialogueTextLine2}</Text>}
            <Box direction="row" gap="medium">
              {props.showSpinner && <Spinner alignSelf="center" />}
              {props.showOkButton && (
                <Button
                  label="OK"
                  size="medium"
                  plain={false}
                  margin="small"
                  onClick={() => {
                    if (props.okButtonPress) props.okButtonPress();
                  }}
                />
              )}

              {props.showCancelButton && (
                <Button
                  label="Cancel"
                  size="medium"
                  plain={false}
                  margin="small"
                  onClick={() => {
                    if (props.cancelButtonPress) props.cancelButtonPress();
                  }}
                />
              )}
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default WaitDialogue;
